import { MAX_CALENDAR_YEARS } from "Constants"
import { addYears, formatISO, isValid, startOfMonth } from "date-fns"
import { useAvailability } from "hooks/availibility"
import { observer } from "mobx-react"
import React, { useEffect, useRef, useState } from "react"
import { Card, CardContent } from "../../../components/Card"
import CalendarController from "../../../core/components/dates/CalendarController/CalendarController"
import { DEFAULT_CALENDAR_PROPS } from "../../../core/components/dates/CalendarController/CalendarController.props"
import { DateRangeContext } from "../../../core/components/dates/state/DateRange.context"
import { isDateRangeAvailable } from "../../../core/components/dates/validation/DateValidation"
import { Primary, Secondary } from "../../../lib/components/Buttons/BaseButton"
import CalendarSubHeader from "../../../lib/components/Calendar/CalendarSubHeader"
import { useRootService } from "../../../services"
import styles from "./OwnerHolds.module.scss"
import { FormattedMessage, useIntl } from "react-intl"
import { useContactId } from "hooks/user"
import { useCurrentUnit, useUnitStatuses } from "hooks/units"

interface OwnerHoldsProps {
    placeHold: () => void
}

const OwnerHolds: React.FC<OwnerHoldsProps> = observer(({ placeHold }) => {
    const rootService = useRootService()
    const { calendarService } = rootService
    const intl = useIntl()
    const isMounted = useRef(false)
    const [disableContinueButton, setDisableContinueButton] = useState(true)
    const [hiddenClearButton, setHiddenClearButton] = useState(true)
    const { unitId } = useCurrentUnit()
    const { isActive, isFixedRent, isRevenuePooling } = useUnitStatuses()

    const allowHolds = !isFixedRent || isRevenuePooling
    const blockAllDays = !allowHolds || !isActive
    const { startDate, endDate } = calendarService

    useEffect(() => {
        if (isMounted.current) {
            calendarService.clearDates()
        } else {
            isMounted.current = true
        }
    }, [calendarService])

    const contactId = useContactId() ?? ""

    const availabilityQuery = useAvailability(
        contactId,
        unitId ?? undefined,
        formatISO(startOfMonth(new Date()), {
            representation: "date",
        }),
        formatISO(addYears(new Date(), MAX_CALENDAR_YEARS), {
            representation: "date",
        })
    )

    // Determines whether to enable or disable the continue button
    useEffect(() => {
        const areDatesSelected = !!startDate && !!endDate
        const dateRangeAvailable = isDateRangeAvailable(
            startDate ? startDate : null,
            endDate ? endDate : null,
            blockAllDays,
            availabilityQuery.data ?? {}
        )

        setDisableContinueButton(
            !areDatesSelected || !allowHolds || !isActive || !dateRangeAvailable
        )
        setHiddenClearButton(!isValid(startDate))
    }, [
        allowHolds,
        blockAllDays,
        isActive,
        startDate,
        endDate,
        availabilityQuery.data,
    ])

    return (
        <Card noBorderOnMobile>
            <CardContent noPaddingOnMobile>
                <h3 className="type-heading-medium-large">
                    <FormattedMessage
                        id="CalendarPage.OwnerHolds.heading"
                        defaultMessage="Owner holds"
                    />
                </h3>
                <CalendarSubHeader
                    allowHolds={allowHolds}
                    isCurrentUnitActive={isActive}
                />
                <DateRangeContext.Consumer>
                    {context => (
                        <>
                            <CalendarController
                                blockAllDays={blockAllDays}
                                initialStartDate={null}
                                initialEndDate={null}
                                handleDatesChange={(
                                    startDate: Date | null | string,
                                    endDate: Date | null | string
                                ) => {
                                    calendarService.setDateRange(
                                        typeof startDate === "string"
                                            ? null
                                            : startDate,
                                        typeof endDate === "string"
                                            ? null
                                            : endDate
                                    )
                                }}
                                showLegend={true}
                                {...DEFAULT_CALENDAR_PROPS}
                            >
                                <div className={styles.clearContinueButtons}>
                                    {allowHolds && !hiddenClearButton ? (
                                        <Secondary
                                            className={styles.clearDates}
                                            typeOf={"link"}
                                            onClick={() => {
                                                context.dispatchDateRangeAction(
                                                    {
                                                        type: "RESET_DATES",
                                                    }
                                                )
                                                calendarService.clearDates()
                                            }}
                                            inlineStyle={{
                                                visibility: hiddenClearButton
                                                    ? "hidden"
                                                    : "visible",
                                            }}
                                            ariaLabel={intl.formatMessage({
                                                id: "CalendarPage.OwnerHolds.clearDates",
                                                defaultMessage: "Clear Dates",
                                            })}
                                        >
                                            <FormattedMessage
                                                id="CalendarPage.OwnerHolds.clearDates"
                                                defaultMessage="Clear Dates"
                                            />
                                        </Secondary>
                                    ) : (
                                        <div />
                                    )}
                                    {allowHolds && (
                                        <Primary
                                            onClick={placeHold}
                                            disabled={disableContinueButton}
                                            ariaLabel={intl.formatMessage({
                                                id: "CalendarPage.OwnerHolds.ariaLabel",
                                                defaultMessage:
                                                    "Click to place owner hold",
                                            })}
                                            data-testid="ownerhold-continue"
                                        >
                                            <FormattedMessage
                                                id="CalendarPage.OwnerHolds.continue"
                                                defaultMessage="Continue"
                                            />
                                        </Primary>
                                    )}
                                </div>
                            </CalendarController>
                        </>
                    )}
                </DateRangeContext.Consumer>
            </CardContent>
        </Card>
    )
})

export default OwnerHolds
