const taxes = {
    title: "Impôts",

    fillOutW9: "Veuillez remplir la déclaration W-9",
    submitW9: "Télétransmettre la déclaration W-9",
    newTaxFormsWillShow:
        "Les nouveaux formulaires de déclaration fiscale s'afficheront ici au fur et à mesure de leur disponibilité.",
    goPaperless: "Déclaration dématérialisée",
    paperlessExplanation:
        "Cette option vous permet de recevoir vos déclarations fiscales par voie électronique uniquement. Vous ne recevrez pas de version papier de vos déclarations fiscales.",
    electronicForms:
        "Recevoir les formulaires de déclaration fiscale uniquement par voie électronique",
    getForms: "Obtenez vos formulaires de déclaration fiscale",
    irsFormsElectronically:
        "Recevoir les formulaires de déclaration fiscale de l'IRS par voie électronique",
    mustSubmit:
        "We must have one of the following taxpayer forms on file before we can provide your tax preparation documents. Please complete only the form that best describes your taxpayer status with the IRS. If your taxpayer information has changed, please submit a new form.",

    paperlessNotification:
        "Les formulaires de déclaration fiscale seront envoyés par voie électronique",
    paperFormNotification:
        "Les formulaires de déclaration fiscale seront envoyés par voie postale",

    noForms: "Vous n'avez aucun formulaire pour l'instant",

    ifExpecting: "Votre formulaire de déclaration fiscale",
    willBeAvailable: "sera disponible d'ici au",
    form1099title: "Déclaration 1099-MISC",
    form1042title: "Déclaration 1042-S",
    jan31: "31 janvier",
    march15: "15 mars",

    yourInformation: "Provide your taxpayer information",
    whoFor: "Qui est concerné",
    howToSubmit: "Comment envoyer la déclaration",
    w9title: "Déclaration fiscale W-9",
    w9whoFor:
        "A fournir si vous êtes citoyen américain ou étranger résident disposant de l'un des numéros fiscaux suivants : SSN,EIN ou ITIN. Les étrangers non résidents ne doivent pas remplir cette déclaration.",
    w8ECItitle: "Déclaration W-8ECI",
    w8ECIwhoFor:
        "A fournir si vous êtes un particulier étranger, un étranger non résident ou une entité possédant actuellement un numéro SSN, EIN ou ITIN et allez déclarer ce revenu lors de votre déclaration fiscale annuelle auprès de l'IRS.",
    w8BENtitle: "Déclaration W-8BEN",
    w8BENwhoFor:
        "A fournir si vous êtes un particulier étranger ou un étranger non résident ne déposant pas de déclaration fiscale annuelle auprès de l'IRS ou si vous souhaitez demander des avantages fiscaux relevant de traités entre les États-Unis et votre pays de résidence. Les avantages conférés par les traités ne s'appliquant pas aux revenus locatifs, les individus déposant une déclaration W-8BEN sont soumis à une retenue à la source étrangère de 30 % sur leurs revenus bruts.",
    w8BENEtitle: "Déclaration W-8BEN-E",
    w8BENEwhoFor:
        "À fournir par les bénéficiaires étrangers de revenus qui ne sont pas des individus et ne déposent pas de déclarations annuelles de revenus auprès de l'IRS. Les avantages conférés par les traités sur l'imposition des revenus peuvent également être demandés à la rubrique III de cette déclaration. Les avantages conférés par les traités ne s'appliquant pas aux revenus locatifs, les individus déposant une déclaration W-8BEN-E sont soumis à une retenue à la source étrangère de 30 % sur leurs revenus bruts.",

    learnMore: "Plus d'informations sur",
    mailForm: "Remplir la déclaration et l'envoyer à :",

    dwnld: "Télécharger",
    download: "Téléchargez vos données locatives",
    preparingNotification:
        "Préparation de vos données en vue du téléchargement",
    rentalData: "Données locatives",
    noDownloads: "Vous n'avez effectué encore aucun téléchargement.",
    description:
        "Les données de chaque année fiscale seront disponibles d'ici au",
    january15: "15 janvier",
    description2:
        "de l'année suivante. Si la propriété est partagée entre un ou plusieurs individus, chacun recevra personnellement les données afin de disposer des informations correctes.",
    instructions:
        "Les fichiers .xls peuvent être ouverts dans la plupart des applications de tableur, notamment Excel, Numbers et Google Sheets.",
    makeChanges: "Want to make changes?",
    w9Support:
        "To make edits to your W-9 details please reach out to the {{HOME_OWNER_SUPPORT}} at {{SUPPORT_NUMBER}}, {{SUPPORT_EMAIL}}",
    taxModal: {
        actionRequired: "Action Required!",
        title: "Enter Taxpayer Info",
        description:
            "We need your taxpayer information on file so we can provide you with accurate tax preparation documents for last year.",
        primaryButton: "Fill it Out",
        secondardButton: "Maybe Later",
    },
    paperless1099Modal: {
        title: "Paperless 1099",
        description:
            "As the new year approaches, be prepared for tax season by receiving your Vacasa 1099 digitally. We'll email you when it's ready.",
        goPaperless: "Go Paperless",
        skipForNow: "Skip for Now",
    },
    w9: {
        taxFormSelect: {
            info1: "Please provide your taxpayer information so we can provide your tax preparation documents. Provide information that best describes your taxpayer status with the IRS.",
            info2: "The name/entity that you provide should be the same name/entity that is listed on your contract with Vacasa.",
        },
        taxClassifications: {
            INP: "Individual/sole proprietor or single-member LLC",
            LLC: "Limited liability company",
            CCO: "C Corporation",
            SCO: "S Corporation",
            PRT: "Partnership",
            TRE: "Trust/estate",
            OTH: "Other",
        },
        llcTaxClassfications: {
            C: "C Corporation",
            S: "S Corporation",
            P: "Partnership",
        },
        taxIdTypes: {
            SSN: "SSN (Social Security Number)",
            EIN: "EIN (Employer Identification Number)",
        },
        forms: {
            agreeAndSign: {
                title: "Agree & Submit",
                termsOfAgreement: "Terms Of Agreement",
                terms: "Under penalties of perjury, I certify that:\n\n1. The number shown on this form is my correct taxpayer identification number (or I am waiting for a number to be issued to me); and \n\n2. I am not subject to backup withholding because: (a) I am exempt from backup withholding, or (b) I have not been notified by the Internal Revenue Service (IRS) that I am subject to backup withholding as a result of a failure to report all interest or dividends, or (c) the IRS has notified me that I am no longer subject to backup withholding; and \n\n3. I am a U.S. citizen or other U.S. person (defined below); and \n\n4. The FATCA code(s) entered on this form (if any) indicating that I am exempt from FATCA reporting is correct. Certification instructions. You must cross out item 2 above if you have been notified by the IRS that you are currently subject to backup withholding because you have failed to report all interest and dividends on your tax return. For real estate transactions, item 2 does not apply. For mortgage interest paid, acquisition or abandonment of secured property, cancellation of debt, contributions to an individual retirement arrangement (IRA), and generally, payments other than interest and dividends, you are not required to sign the certification, but you must provide your correct TIN. See the instructions for Part II, later.",
                iAgree: "I agree to these terms",
                signature: "Signature",
                signaturePlaceholder: "Type fullname",
                signatureHelperText:
                    "By signing this document with an electronic signature, I agree that such signature will be as valid as handwritten signatures to the extent allowed by local law.",
                signedOn: "Signed On",
                validation: {
                    agreeToTermsRequired:
                        "Please agree to our terms of agreement",
                    signatureRequired: "Please enter your signature",
                    signatureMaxLength: "Signature cannot exceed 50 letters",
                },
            },
            taxInfo: {
                validation: {
                    entityTypeRequired: "Entity type is required",
                    entityTypeEmpty: "Entity type must be empty",
                    entityNameRequired: "Entity name is required",
                    taxIDTypeRequired: "Tax ID type is required",
                    taxIDTypeValid: "Tax ID type must be either SSN or EIN",
                    taxIDTypeEIN: "Tax ID type must be EIN",
                    taxClassificationRequired:
                        "Please select a tax classification",
                    taxClassificationValid: "Tax classification invalid",
                    ssnRequired: "SSN is required",
                    ssnLength: "SSN must be 9 digit in XXX-XX-XXXX format",
                    ssnInvalid: "SSN must be 9 digit in XXX-XX-XXXX format",
                    ssnAllZero: "SSN cannot be all 0",
                    ssnEmpty: "SSN must be empty",
                    einRequired: "EIN is required",
                    einLength: "EIN must be 9 digit in XX-XXXXXXX format",
                    einInvalid: "EIN must be 9 digit in XX-XXXXXXX format",
                    einAllZero: "EIN cannot be all 0",
                    einEmpty: "EIN must be empty",
                    llcTypeRequired: "LLC type required",
                    llcTypeValid: "LLC type must be either C, S, or P",
                    llcTypeEmpty: "LLC type must be empty",
                },
            },
            mailingAddress: {
                label: {
                    address: "Address",
                    city: "City",
                    stateOrProvince: "State/Province",
                    zipCode: "Zip/Postal Code",
                },
                placeholder: {
                    addressLine1: "Address Line 1",
                    addressLine2: "Address Line 2",
                    city: "City",
                    stateProvince: "State/Province",
                    zipCode: "5-digit zipcode",
                },
                validation: {
                    addressRequired: "Address is required",
                    cityRequired: "Please enter city",
                    postalCodeRequired: "Postal code is required",
                    provinceRequired: "State/Province is required",
                    postalCodeMaxLength: "Postal code must be 5 digit length",
                    postalCodeAllZero: "Postal code cannot be all 0",
                },
            },
        },
    },
}

export default taxes
