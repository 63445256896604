import classnames from "classnames"
import React, { PropsWithChildren, useRef, useState } from "react"
import { CSSTransition } from "react-transition-group"
import { getClassStyle } from "utils/styles/styleWrapper"
import useOnClickOutside from "../../../hooks/common/useOnClickOutside"
import useWindowSize from "../../../hooks/common/useWindowSize"
import SVGIcon from "../SVGIcon"
import styles from "./Tooltip.module.scss"
import { X } from "react-feather"
import { trackTooltipClicked } from "services/segment/tooltips/tooltipsTracking"

export interface TooltipProps {
    name?: string
    title: string
    subTitle?: React.ReactNode
    body: React.ReactNode
    className?: string
    onOutsideClick?: () => void
    customTooltipStyle?: string
    segmentTrackParams?: Record<string, unknown>
}

export const Tooltip: React.FC<PropsWithChildren<TooltipProps>> = ({
    body,
    name,
    title,
    subTitle,
    className,
    onOutsideClick,
    children,
    customTooltipStyle,
    segmentTrackParams = {},
}) => {
    const [open, setOpen] = useState(false)
    const tooltipRef = useRef<HTMLDivElement>(null)
    const size = useWindowSize()

    const toggleOnClick = (): void => {
        if (!open) {
            trackTooltipClicked(name ?? title, segmentTrackParams)
        }
        setOpen(!open)
    }

    useOnClickOutside(tooltipRef, () => {
        setOpen(false)
        if (onOutsideClick) onOutsideClick()
    })
    let endAligned = false
    // TODO: Calculate tooltip orientation i.e. top, bottom, left or right of child component
    if (
        !!size &&
        !!tooltipRef.current &&
        size - tooltipRef.current?.getBoundingClientRect().right < 320
    ) {
        // not enough space for the tooltip to be fully visible
        endAligned = true
    }

    return (
        <div ref={tooltipRef}>
            <div data-testid="tooltip-closed" onClick={toggleOnClick}>
                {children}
            </div>
            <CSSTransition
                in={open}
                timeout={300}
                classNames={{
                    enter: styles["tooltip-enter"],
                    enterActive: styles["tooltip-enter-active"],
                    enterDone: styles["tooltip-enter-active"],
                    exit: styles["tooltip-exit"],
                    exitActive: styles["tooltip-exit-active"],
                }}
                unmountOnExit
            >
                <div
                    className={classnames(
                        className ?? styles.tooltip,
                        styles["tooltip-v2"],
                        styles["tooltip-enter-active"],
                        { [getClassStyle(styles["end-aligned"])]: endAligned },
                        customTooltipStyle,
                        "tooltip"
                    )}
                    data-testid="tooltip-open"
                >
                    <header className={styles["tooltip-v2__header"]}>
                        <div className={styles["tooltip-v2__icon"]}>
                            <SVGIcon
                                svg="icon-feather-info"
                                className={styles["info-icon"]}
                            />
                        </div>
                        <div className={styles["tooltip-v2__title"]}>
                            {title}
                            {subTitle && (
                                <span
                                    className={styles["tooltip-v2__subTitle"]}
                                >
                                    {subTitle}
                                </span>
                            )}
                        </div>

                        <button
                            className={styles["tooltip-v2__close"]}
                            onClick={(): void => setOpen(false)}
                        >
                            <X />
                        </button>
                    </header>
                    <div className={styles["tooltip-v2__body"]}>
                        <p>{body}</p>
                    </div>
                </div>
            </CSSTransition>
        </div>
    )
}
