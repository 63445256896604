import classnames from "classnames"
import React, { Fragment, ReactNode } from "react"
import styles from "./ReservationItems.module.scss"

interface HoldTypeProps {
    title: ReactNode
    subtext: string | JSX.Element | null
    pill?: JSX.Element | null
    primary?: boolean
    Tag?: ReactNode | null
}

const HoldType: React.FC<HoldTypeProps> = ({
    title,
    subtext,
    pill,
    primary,
    Tag,
}) => {
    return (
        <Fragment>
            <li>
                <div className={styles[`Title${primary ? "-primary" : ""}`]}>
                    <div className={styles.holdType}>{title}</div>
                    {pill}
                    <div className={styles.tag}> {Tag} </div>
                </div>
                <div
                    className={classnames(
                        styles[`Subtitle${primary ? "-primary" : ""}`],
                        styles["bookedOn"]
                    )}
                    data-testid="hold-type"
                >
                    {subtext}
                </div>
            </li>
        </Fragment>
    )
}

export default HoldType
