import { JSONAPIResource, Unit } from "@vacasa/owner-api-models"
import { useBookingProbability } from "hooks/booking-probability"
import React, { useEffect } from "react"
import { FormattedMessage, FormattedNumber } from "react-intl"
import { trackOwnerHoldCost } from "services/segment/ownerHold/ownerHoldTracking"
import { showBookingProbabilityMessage } from "utils/bookingProbability/bookingProbabilityUtil"
import calendarService from "../../../../../../services/calendar.service"
import { InfoSubpanel } from "../../InfoSubpanel/InfoSubpanel"

interface CostOfHoldProps {
    costOfHold: number
    unitInfo: {
        isFixedRent: boolean
        isCurrentUnitActive: boolean
        allowHolds: boolean
        unit: JSONAPIResource<Unit>
    }
    currency: string
}

const getUnitId = (
    unitId: string | null,
    startDate: Date | undefined,
    endDate: Date | undefined
) => {
    if (!startDate || !endDate) {
        return null
    }
    return unitId
}

export function CostOfHold({
    costOfHold,
    unitInfo,
    currency,
}: CostOfHoldProps): JSX.Element {
    const startDate = calendarService.startDate ?? undefined
    const endDate = calendarService.endDate ?? undefined
    const unit = unitInfo.unit

    const bookingProbabilityQuery = useBookingProbability(
        getUnitId(unit.id?.toString() ?? null, startDate, endDate),
        startDate ?? new Date(),
        endDate ?? new Date()
    )

    useEffect(() => {
        if (
            startDate &&
            endDate &&
            !bookingProbabilityQuery.isLoading &&
            !!costOfHold
        ) {
            trackOwnerHoldCost(
                String(unit.id),
                startDate,
                endDate,
                showBookingProbabilityMessage(bookingProbabilityQuery),
                costOfHold
            )
        }
    }, [startDate, endDate, bookingProbabilityQuery, costOfHold, unit.id])

    const shouldNotRender =
        unitInfo.isFixedRent || !costOfHold || bookingProbabilityQuery.isLoading

    return shouldNotRender ? (
        <></>
    ) : (
        <InfoSubpanel>
            <FormattedMessage
                id="CalendarPage.flyout.costOfHold"
                defaultMessage="You miss out on <b>{cost}</b> in gross rent by booking these dates."
                values={{
                    b: (chunks: React.ReactFragment) => <b>{chunks}</b>,
                    cost: (
                        <FormattedNumber
                            value={costOfHold}
                            currency={currency}
                            style="currency" // eslint-disable-line
                        />
                    ),
                }}
            />
        </InfoSubpanel>
    )
}
