import classNames from "classnames"
import { FC } from "react"
import styles from "./ToggleSwitch.module.scss"

interface Props {
    active?: boolean
    className?: string
    label?: string
    onChange: (checked: boolean) => void
    disabled?: boolean
}

export const ToggleSwitch: FC<Props> = ({
    active,
    className,
    label,
    onChange,
    disabled = false,
}) => {
    return (
        <label className={classNames(styles.wrap, className)}>
            <div className={disabled ? styles.disabled : styles.switch}>
                <input
                    checked={active}
                    type="checkbox"
                    onChange={e => onChange(e.target.checked)}
                    disabled={disabled}
                />
                <span className={styles.slider} />
            </div>
            {label && <span className="toggle-label">{label}</span>}
        </label>
    )
}
