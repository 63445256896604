const performance = {
    title: "Performance",
    chartTitle: "YTD Gross Rent",
    subtitle: "Jan 1, 2019 - Dec 31, 2019",
    emptyState: "You don’t have any current performance data.",
    notAllowed:
        "You are not the primary owner of this unit. Performance data is not accessible at this time.",
    grossRent: "YTD Gross REnt",
    grossRentTooltip: "The total gross rent for all bookings this year.",
    compareToPriorYear: "Compare to Previous Year",
    completedBookings: "Completed Bookings",
    upcomingBookings: "Upcoming Bookings",
    upcomingBookingsTooltip:
        "Includes in-progress and confirmed future bookings. Total may change as new bookings or modifications are made.",
    monthAbbreviations: {
        jan: "Jan",
        feb: "Feb",
        mar: "Mar",
        apr: "Apr",
        may: "May",
        jun: "Jun",
        jul: "Jul",
        aug: "Aug",
        sep: "Sep",
        oct: "Oct",
        nov: "Nov",
        dec: "Dec",
    },
    reviews: {
        heading: "Guest Reviews",
        reviewModalEmpty: "Your guest ratings and reviews will appear here.",
        viewReservation: "View reservation",
        seeAll: "See all",
        allRatings: "All Ratings",
        filterByRating: "Filter by Rating",
        reviewCount: "{total} Ratings",
        overallRating: "Overall rating",
        totalReviews: "Total reviews",
        learnMore: "Learn more",
        overallVacasaRating: "Overall Vacasa.com rating",
        overallVacasaRatingV2: "Overall rating",
        overallRatingTooltip:
            "Your overall rating is currently calculated based only on Vacasa.com reviews.",
        overallRatingTooltipV2:
            "Your overall rating is calculated based on Vacasa.com, Airbnb and Booking.com reviews. {br}{br} {learnMore} about review management.",
        reviewModalTooltipTitle: "Overall Rating & Review list",
        reviewModalTooltip:
            "Your overall rating is currently calculated based only on Vacasa.com reviews. The reviews shown in this list match what is shown on your unit page on Vacasa.com.",
        reviewModalTooltipV2:
            "Reviews and ratings are based on reservations booked on Vacasa.com, Airbnb, and Booking.com. {br}{br} {learnMore} about review management.",
        headingReviewsEmpty: "Avg. guest rating",
        reviewsEmpty:
            "Your average guest rating and guest review count will appear here.",
    },
    floatingNotification: {
        nightsOccupiedTitle: "New! Occupancy summary",
        nightsOccupiedBody:
            "You can click into Guest Stays or Owner Holds to see a summary of reservations for the current month and their associated revenue impact!",
        shareMore: "Thanks for the feedback! Care to share more?",
        nightsOccupiedFeedbackTitle: "New! Monthly summary",
        nightsOccupiedFeedback:
            "You can now see all of the current month's reservations, owner holds, and their impact to your revenue.\n\nDo you find this summary helpful?",
        coreMetricsFeedback:
            "You can now see the key metrics that help you understand how your home is priced and performing over the next year, with daily updates giving you the latest insight.\n\nHow do you feel about these performance indicators?",
        coreMetricsFeedbackTitle: "NEW! HOME PERFORMANCE METRICS",
    },
    nightsOccupied: {
        heading: "Nights occupied this month",
        guestNights: "Guest nights",
        ownerNights: "Owner nights",
        headingEmpty: "Nights occupied",
        nightsOccupiedEmpty:
            "Your guest and owner hold nights will appear here for the current month.",
        ownerHoldsThisMonth: "Owner holds this month",
        guestStaysThisMonth: "Guest stays this month",
        ownerHoldTooltipHeader: "Revenue impact",
        ownerHoldTooltipMessage:
            "We estimate net rent based on the current rate for the nights reserved with an owner hold. Holds labeled with a lightning bolt indicate there’s a high probability of being booked by a guest if you cancel your hold.",
        night: "Night",
        nights: "Nights",
        totalNights:
            "{nightCount, plural, one {# night} other {# nights}}{totalNightsText}",
        potentialImpact: "Potential impact",
        totalNetRent: "Total net rent",
        upcoming: "Upcoming",
        previous: "Previous",
        ownerHold: "Owner hold",
        guestStay: "Guest stay",
        netRent: "Net rent",
    },
    rentChart: {
        descriptionCurrentYear:
            "Total <strong>gross income</strong> from your bookings this year",
        descriptionPreviousYear:
            "Total <strong>gross income</strong> from your bookings",
        descriptionNetCurrentYear:
            "Total <strong>net income</strong> from your bookings this year",
        descriptionNetPreviousYear:
            "Total <strong>net income</strong> from your bookings",
        secondaryDescriptionNet:
            "<i>Note</i>: Net Rent data is only available for reservations booked with Vacasa.",
        descriptionNetCurrentAndFutureYear:
            "Total estimated <strong>net income</strong> from your bookings this year. Taxes and fees may change.",
        tooltip: {
            title: "Previous PM Data",
            body: "Inherited revenue data was provided by your previous property management company.",
        },
        footer: {
            keyLabels: {
                vacasa: "Vacasa",
                pastPm: "Previous PM",
            },
            inheritedDataInfo:
                "Inherited data was provided by your previous property management company.",
        },
    },
}

export default performance
