import { JSONAPIResource, ReservationGuest } from "@vacasa/owner-api-models"
import { authAxios } from "../../api/authAxios"

export type PatchReservationGuestRequest = {
    reservationId: string
    guestId: string
    guest: Omit<ReservationGuest, "guestID" | "reservationID">
    signal?: AbortSignal
}

const patchReservationGuest = ({
    reservationId,
    guestId,
    guest,
    signal,
}: PatchReservationGuestRequest): Promise<
    JSONAPIResource<ReservationGuest>
> => {
    const data = {
        data: {
            type: "reservation-guest",
            attributes: guest,
        },
    }

    return authAxios
        .patch<{ data: JSONAPIResource<ReservationGuest> }>(
            `/v1/reservations/${reservationId}/guests/${guestId}`,
            data,
            {
                signal,
            }
        )
        .then(response => response.data.data)
}

export { patchReservationGuest }
