import React from "react"
import classnames from "classnames"
import { FormattedMessage } from "react-intl"

import { ReactComponent as NeedHelpSVG } from "../../assets/icon-onboarding-need-help.svg"

import styles from "../../sass/onboarding.module.scss"
import { JSONAPIResource, Unit } from "@vacasa/owner-api-models"

interface NeedHelpPanelProps {
    unit: JSONAPIResource<Unit>
}

const NeedHelpPanel: React.FunctionComponent<NeedHelpPanelProps> = ({
    unit,
}) => {
    const salesperson = unit?.attributes.salesperson
    if (!salesperson || !salesperson.emailAddress) {
        return null
    }

    const email = (
        <a key="needHelpEmail" href={`mailto:${salesperson.emailAddress}`}>
            {salesperson.emailAddress}
        </a>
    )

    let contactSlug = null
    if (salesperson.firstName && salesperson.lastName) {
        contactSlug = (
            <FormattedMessage
                id="Onboarding.needHelp.contactWithName"
                defaultMessage="We’re here for you. Reach out to your dedicated contact {firstName} {lastName} at {email}"
                values={{
                    firstName: salesperson.firstName,
                    lastName: salesperson.lastName,
                    email,
                }}
            />
        )
    } else {
        contactSlug = (
            <FormattedMessage
                id="Onboarding.needHelp.contactWithoutName"
                defaultMessage="We’re here for you. Reach out to your dedicated contact at {email}"
                values={{
                    email,
                }}
            />
        )
    }

    return (
        <div
            className={classnames("subpanel--info", styles.help_panel)}
            id="need-help-panel"
        >
            <div className={styles.helpContentContainer}>
                <div className="flex align-end img__wrapper">
                    <NeedHelpSVG className={styles.help_image} />
                </div>
                <div className={styles.help_content}>
                    <h3 className="type-heading-small">
                        <FormattedMessage
                            id="Onboarding.needHelp.title"
                            defaultMessage="Need Help?"
                        />
                    </h3>
                    <span className="type-body-small">{contactSlug}</span>
                </div>
            </div>
        </div>
    )
}

export default NeedHelpPanel
