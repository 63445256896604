import classnames from "classnames"
import React, { Fragment, ReactNode } from "react"
import { FormattedMessage } from "react-intl"
import styles from "./ReservationItems.module.scss"
import Badge from "lib/components/Badge"

interface ReservationDateProps {
    title: ReactNode
    subtext: string | JSX.Element | null
    primary?: boolean
    showMonthCount?: boolean
    monthCount?: number
}

const ReservationDate: React.FC<ReservationDateProps> = ({
    title,
    subtext,
    primary,
    showMonthCount,
    monthCount,
}) => {
    return (
        <Fragment>
            <li>
                <div className={styles[`Title${primary ? "-primary" : ""}`]}>
                    <div className={styles.reservationDate}>{title}</div>
                </div>
                <div
                    className={classnames(
                        styles[`Subtitle${primary ? "-primary" : ""}`],
                        styles["nightCount"]
                    )}
                >
                    {subtext}

                    {showMonthCount && monthCount && (
                        <div className={styles.monthCount}>
                            <Badge icon="Calendar">
                                <FormattedMessage
                                    id="CalendarPage.flyout.monthCount"
                                    defaultMessage="Spans {monthCount, plural,=0 {}one {# month}other {# months}}"
                                    values={{
                                        monthCount: monthCount,
                                    }}
                                />
                            </Badge>
                        </div>
                    )}
                </div>
            </li>
        </Fragment>
    )
}

export default ReservationDate
