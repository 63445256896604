import { FC, useEffect } from "react"
import { Navigate } from "react-router-dom"
import StorageService from "services/storage.service"
import { ATTEMPTED_ROUTE, EMPLOYEE_LAST_VIEWED_OWNER_URL } from "../Constants"
import Search from "./search"
import { useIsEmployee } from "hooks/user"
import { useLoginInfo } from "contexts/login"
import { clearOwnerCache } from "utils/user"

const RedirectToSearch: FC = () => {
    const isEmployee = useIsEmployee()
    const { setContactIds } = useLoginInfo()

    StorageService.localStorage.removeItem(ATTEMPTED_ROUTE)
    StorageService.localStorage.removeItem(EMPLOYEE_LAST_VIEWED_OWNER_URL)

    useEffect(() => {
        // Only need to clear owner data when logged in as an employee and accessing the search page
        if (!isEmployee) return
        setContactIds([])
        clearOwnerCache()
    }, [isEmployee, setContactIds])

    if (isEmployee) {
        return <Search />
    }
    return <Navigate to="/login" />
}

export default RedirectToSearch
