import { FC, useRef } from "react"

// Components
import { Card, CardContent } from "../../components/Card"
import Loader from "../../lib/components/Loader"
import DataRowWrapper from "../../lib/containers/DataRow"

// Assets
import dogImg from "../../assets/dog.png"

// Constants
import { Owner } from "@vacasa/owner-api-models"
import { observer } from "mobx-react"
import { useIntl } from "react-intl"
import { trackPetFriendlyLearnMoreClicked } from "services/segment/homeInfo/homeInfoTracking"
import { VACASA_URL } from "../../Environment"
import { ListLayout } from "../../components/Layout/ListLayout"
import { useCurrentUnit } from "hooks/units"
import { useQueryParams } from "hooks/router"

interface PetsAllowedProps {
    user: Owner | null
}

const PetsAllowed: FC<PetsAllowedProps> = observer(props => {
    const intl = useIntl()
    const { user } = props
    const contactId = user?.contactId
    const queryParams = useQueryParams()
    const unitId = useRef<string>(queryParams.get("u") ?? "")
    const { unit } = useCurrentUnit()

    const publicLink = `${VACASA_URL}owner-library/perks-of-being-pet-friendly`
    return (
        <ListLayout
            title={intl.formatMessage({
                id: "PetsAllowed.title",
                defaultMessage: "Allow Dogs",
            })}
            goBackTo="/home-info"
            titleTestId="pets-allowed-title"
        >
            <div className="grid__header type-body-small">
                {intl.formatMessage({
                    id: "PetsAllowed.description",
                    defaultMessage:
                        "Makes your listing dog-friendly, enabling us to market your home to a wider audience. We provide dog supplies and always ensure your home is well cared for.",
                })}{" "}
                <a
                    href={publicLink}
                    onClick={() => {
                        trackPetFriendlyLearnMoreClicked()
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {intl.formatMessage({
                        id: "PetsAllowed.learnMore",
                        defaultMessage: "Learn more",
                    })}
                </a>
            </div>
            <Card>
                <CardContent>
                    <div className="flex">
                        <div>
                            <h3 className="type-heading-medium-large">
                                {intl.formatMessage({
                                    id: "PetsAllowed.earnMore",
                                    defaultMessage: "Earn up to 20% more",
                                })}
                            </h3>
                            <p className="type-body">
                                {intl.formatMessage({
                                    id: "PetsAllowed.earnMoreDescription",
                                    defaultMessage:
                                        "Allowing dogs can significantly increase your earnings and occupancy, especially in the off-season.",
                                })}
                            </p>
                        </div>
                        <img
                            alt="dog"
                            src={dogImg}
                            className="dog-img"
                            height={132}
                        />
                    </div>
                </CardContent>
            </Card>
            {unit && contactId ? (
                <DataRowWrapper unitQueryParam={unitId.current} />
            ) : (
                <Card>
                    <CardContent>
                        <Loader />
                    </CardContent>
                </Card>
            )}
            <div className="grid__footer">
                <span className="type-body-tiny">
                    {intl.formatMessage({
                        id: "PetsAllowed.disclaimer",
                        defaultMessage:
                            "Changes can take up to 24 hours to update on all of your listings and marketing channels. Please reach out to the contact for your rental if you have any questions or no longer wish to allow dogs.",
                    })}
                </span>
            </div>
        </ListLayout>
    )
})

export default PetsAllowed
