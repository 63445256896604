import segmentService from "../segment.service"
import {
    REFERRAL_EVENT,
    REFERRAL_SUCCESS_CLOSED_ACTION,
    REFERRAL_FORM_SUBMITTED_ACTION,
    REFERRAL_FORM_CLOSED_ACTION,
    REFER_ANOTHER_FRIEND_ACTION,
    PROFILE_MENU_REFERRAL_ACTION,
    REFERRAL_CARD_CLICKED_ACTION,
    REFERRAL_CARD_DISMISSED_ACTION,
} from "./referralTrackingConstants"

export const trackReferralFormSubmitted = (options: {
    first_name: string
    last_name: string
    email: string
    street: string
    city: string
    state: string
    country: string
    zipCode: string
    phone?: string
}): void => {
    segmentService.clickTrack(
        {
            ...options,
            action: REFERRAL_FORM_SUBMITTED_ACTION,
        },
        REFERRAL_EVENT
    )
}

export const trackReferralSuccessClosed = (): void => {
    segmentService.clickTrack(
        {
            action: REFERRAL_SUCCESS_CLOSED_ACTION,
        },
        REFERRAL_EVENT
    )
}

export const trackReferralFormClosed = (): void => {
    segmentService.clickTrack(
        {
            action: REFERRAL_FORM_CLOSED_ACTION,
        },
        REFERRAL_EVENT
    )
}

export const trackReferAnotherFriend = (): void => {
    segmentService.clickTrack(
        {
            action: REFER_ANOTHER_FRIEND_ACTION,
        },
        REFERRAL_EVENT
    )
}

export const trackProfileMenuReferral = (): void => {
    segmentService.clickTrack(
        {
            action: PROFILE_MENU_REFERRAL_ACTION,
        },
        REFERRAL_EVENT
    )
}

export const trackReferralCardClicked = (): void => {
    segmentService.clickTrack(
        {
            action: REFERRAL_CARD_CLICKED_ACTION,
        },
        REFERRAL_EVENT
    )
}

export const trackReferralCardDismissed = (): void => {
    segmentService.clickTrack(
        {
            action: REFERRAL_CARD_DISMISSED_ACTION,
        },
        REFERRAL_EVENT
    )
}
