import { CONTACT_ID, RESERVATION_ID_PARAM, UTM_MEDIUM } from "Constants"
import { useSearchParams } from "react-router-dom"

interface KnownParams {
    resId: string | null
    contactId: string | null
    utmMedium: string | null
}

export const useKnownSearchParams = (): KnownParams => {
    const [searchParams] = useSearchParams()
    return {
        resId: searchParams.get(RESERVATION_ID_PARAM),
        contactId: searchParams.get(CONTACT_ID),
        utmMedium: searchParams.get(UTM_MEDIUM),
    }
}
