import React, { useEffect } from "react"
import { Navigate, Link } from "react-router-dom"

import { ReactComponent as Illustration } from "./404.svg"
import styles from "./NotFound.module.scss"
import { FormattedMessage, useIntl } from "react-intl"
import { trackNotFoundPageViewed } from "services/segment/error/errorTracking"
import { useUser } from "hooks/user"

const NotFound: React.FC = () => {
    const { user } = useUser()

    useEffect(() => {
        trackNotFoundPageViewed()
    }, [])

    const intl = useIntl()

    if (!user) return <Navigate to="/login" />

    const email = intl.formatMessage({
        id: "NotFound.errorMessageContactEmail",
        defaultMessage: "homeowner.accounts@vacasa.com",
    })
    const phone = intl.formatMessage({
        id: "NotFound.errorMessagePhoneNumber",
        defaultMessage: "971-254-3001",
    })

    return (
        <div className={styles.container}>
            <Illustration className={styles.illustration} />
            <h1 className={styles.title}>
                <FormattedMessage
                    id="NotFound.message"
                    defaultMessage="Oops! We can’t find the page you’re looking for."
                />
            </h1>
            <p className={styles.text}>
                <FormattedMessage
                    id="NotFound.detail"
                    defaultMessage="You might try retyping the URL or we could take you to the calendar page to view your current reservations or book an owner hold."
                />
            </p>
            <Link to="/calendar" className={styles.link}>
                <FormattedMessage
                    id="NotFound.link"
                    defaultMessage="View Calendar"
                />
            </Link>
            <p className={styles.text}>
                <FormattedMessage
                    id="NotFound.needHelpNow"
                    defaultMessage="<b>Need help now?</b> Contact our account support team at {phone} or email {email}"
                    values={{
                        b: (chunks: React.ReactFragment) => <b>{chunks}</b>,
                        email: <a href={`mailto:${email}`}>{email}</a>,
                        phone: <a href={`tel:${phone}`}>{phone}</a>,
                    }}
                />
            </p>
        </div>
    )
}

export default NotFound
