import { Card, CardContent } from "components/Card"
import { EmptyState } from "components/EmptyState"
import BaseChart from "lib/components/Vacharts/BaseChart"
import { FC, Fragment, useCallback, useContext, useEffect, useRef } from "react"
import { FormattedMessage, IntlProvider, useIntl } from "react-intl"
import { ReactComponent as PerformanceChartPoints } from "assets/performance-chart-points.svg"
import { MOBILE_BREAKPOINT, MONTH_LIST } from "Constants"
import { Options, Tooltip } from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { LegendItem, LegendItemType } from "lib/components/Vacharts/Legend"
import ReactDOMServer from "react-dom/server"
import {
    getPointsForCurrentYearsMonth,
    getPointsForPreviousYearsMonth,
    isChartShowingPreviousYear,
    toColumnSeries,
    toLineSeries,
} from "utils/charts/chartsUtil"
import { RevenueData } from "views/performance/types"
import styles from "./Chart.module.scss"
import { ReactComponent as EmptyStateImage } from "./PerformanceEmptyState.svg"
import { NetRentToggleContext } from "views/performance/NetRentToggleContext"
import classnames from "classnames"
import ChartSkeleton from "views/performance/partials/ChartSkeleton"
import { useRevenueChartContext } from "contexts/performance"

type ChartWrapperProps = {
    data: RevenueData
    isEmpty: boolean
    chartHeader?: JSX.Element
    chartFooter?: JSX.Element
    currency: string
    isFetching: boolean
    showPreviousYear: boolean
}

const getTooltipLegendItem = ({
    label,
    key = LegendItemType.VacasaCurrentYear,
}: {
    key?: LegendItemType
    label: string
}) => {
    return (
        <LegendItem key={label} stack type={key}>
            {label}
        </LegendItem>
    )
}

export const ChartWrapper: FC<ChartWrapperProps> = ({
    data,
    isEmpty,
    chartHeader,
    chartFooter,
    currency,
    isFetching,
    showPreviousYear,
}): JSX.Element => {
    const intl = useIntl()
    const chartRef = useRef<HighchartsReact.RefObject>(null)

    const { netRentToggled, toggleVisible } = useContext(NetRentToggleContext)
    const { minYear } = useRevenueChartContext()

    // Add event listener to the div responsible for scrolling on mobile. The chart
    // needs to reflow as the positioning for click/touch events need to be reset
    useEffect(() => {
        const onChartScroll = () => {
            if (!chartRef.current) return
            chartRef.current.chart.reflow()
        }
        const container = chartRef.current?.container.current
        container?.addEventListener("scroll", onChartScroll)
        return () => {
            container?.removeEventListener("scroll", onChartScroll)
        }
    }, [])

    const chartOptions = useCallback(
        (currency: string): Options => {
            const options: Options = {
                accessibility: {
                    enabled: false,
                },
                xAxis: {
                    categories: MONTH_LIST,
                    labels: {
                        style: {
                            color: "var(--dusk-60)",
                            fontSize: "14px",
                            fontWeight: "bold",
                        },
                    },
                },
                yAxis: [
                    {
                        labels: {
                            style: {
                                color: "var(--dusk-60)",
                                fontSize: "14px",
                                fontWeight: "bold",
                            },
                            formatter() {
                                return `$${this.axis.defaultLabelFormatter.call(
                                    this
                                )}`
                            },
                        },
                        opposite: true,
                    },
                ],
                series: showPreviousYear
                    ? [
                          ...toColumnSeries(
                              {
                                  data: data.current,
                                  meta: data.meta,
                              },
                              netRentToggled && toggleVisible
                          ),
                          ...toLineSeries(
                              {
                                  data: data.previous,
                                  meta: data.meta,
                              },
                              netRentToggled && toggleVisible
                          ),
                      ]
                    : [
                          ...toColumnSeries(
                              {
                                  data: data.current,
                                  meta: data.meta,
                              },
                              netRentToggled && toggleVisible
                          ),
                      ],
                chart: {
                    type: "column",
                },
                tooltip: {
                    borderColor: "var(--white)",
                    borderRadius: 8,
                    useHTML: true,
                    shadow: {
                        width: 15,
                        opacity: 0.05,
                        color: "rgba(112, 117, 121, 0.2)",
                    },
                    backgroundColor: "var(--white)",
                    style: {
                        fontSize: "12",
                        fontWeight: "800",
                    },
                    formatter: (p: Tooltip) => {
                        const currentPoint = p.chart.hoverPoint
                        if (!currentPoint) return ""
                        const toolTips: JSX.Element[] = []

                        // Checks if the chart is showing the previous years data
                        const isShowingPreviousYear =
                            isChartShowingPreviousYear(currentPoint)

                        // Retrieve the data points for the selected month in the current year
                        // It's possible to have 2 points for the same month if acquistion data overlaps
                        // vacasa data in the same month
                        let currentYearPoints = getPointsForCurrentYearsMonth(
                            currentPoint,
                            isShowingPreviousYear
                        ).map(point => ({ ...point }))

                        // Retrieve the data points for the selected month in the previous year
                        const previousYearPoints =
                            getPointsForPreviousYearsMonth(currentPoint)

                        if (isShowingPreviousYear) {
                            if (currentYearPoints.length === 2) {
                                const total = currentYearPoints.reduce(
                                    (acc, point) => {
                                        return (acc += point.y ?? 0)
                                    },
                                    0
                                )

                                // Replaceing separate points for acquisition and vacasa data into a single data point
                                currentYearPoints = [
                                    {
                                        y: total,
                                        key: LegendItemType.CombinedCurrentYear,
                                    },
                                ]
                            }
                        }

                        ;[...currentYearPoints, ...previousYearPoints].forEach(
                            point => {
                                toolTips.push(
                                    getTooltipLegendItem({
                                        label: intl.formatNumber(point.y ?? 0, {
                                            style: "currency",
                                            currency,
                                        }),
                                        key: point.key,
                                    })
                                )
                            }
                        )

                        return ReactDOMServer.renderToString(
                            <IntlProvider
                                locale={intl.locale}
                                messages={intl.messages}
                                defaultLocale="en"
                            >
                                <div className={styles.tooltip}>{toolTips}</div>
                            </IntlProvider>
                        )
                    },
                },
                plotOptions: {
                    column: {
                        pointWidth: 53,
                        grouping: false,
                        borderColor: "transparent",
                    },

                    series: {
                        color: "var(--cafe)",
                        showInLegend: false,
                        borderRadiusTopLeft: "8px",
                        borderRadiusTopRight: "8px",
                        marker: {
                            symbol: "circle",
                        },
                    },
                },
                responsive: {
                    rules: [
                        {
                            condition: {
                                minWidth: MOBILE_BREAKPOINT,
                            },
                            chartOptions: {
                                xAxis: {
                                    labels: {
                                        style: {
                                            fontSize: "14",
                                        },
                                    },
                                },
                                yAxis: [
                                    {
                                        labels: {
                                            style: {
                                                fontSize: "14",
                                            },
                                        },
                                    },
                                ],
                                plotOptions: {
                                    column: {
                                        pointWidth: 53,
                                    },
                                    series: {
                                        borderRadiusTopLeft: "8px",
                                        borderRadiusTopRight: "8px",
                                    },
                                },
                            },
                        },
                    ],
                },
            }
            return options
        },
        [showPreviousYear, data, netRentToggled, toggleVisible, intl]
    )

    if (isEmpty && !isFetching && minYear === new Date().getFullYear()) {
        return (
            <EmptyState
                image={<EmptyStateImage />}
                title={
                    <FormattedMessage
                        id="Performance.emptyState.title"
                        defaultMessage="Revenue Data"
                    />
                }
                description={
                    <FormattedMessage
                        id="Performance.emptyState.description"
                        defaultMessage="Year-to-date gross revenue data will appear here once you receive reservations."
                    />
                }
            />
        )
    }
    return (
        <div className="content-section">
            <Card>
                <CardContent>
                    {isFetching && <ChartSkeleton />}

                    {!isFetching && (
                        <>
                            {chartHeader && chartHeader}
                            <Fragment>
                                <div
                                    className={classnames(
                                        styles["chart-wrapper"]
                                    )}
                                >
                                    {
                                        // This SVG is required for the chart styling, we can't use the svg_sprite.html as it hides the svg
                                        // with display:none which means patterns and gradients in the <def></def> section are not visible
                                        // outside of the svg scope
                                        // If we need more control over styling, we can use `chart.styledMode: true` but would require us to
                                        // create add styles for every css class manually.
                                    }
                                    <PerformanceChartPoints />
                                    <BaseChart
                                        ref={chartRef}
                                        options={chartOptions(currency)}
                                    />
                                </div>
                            </Fragment>
                            {chartFooter && chartFooter}
                        </>
                    )}
                </CardContent>
            </Card>
        </div>
    )
}
