import { FC } from "react"

import { ReactComponent as EmptyStateImage } from "../../../PerformanceEmptyState.svg"
import styles from "./ChartEmptyState.module.scss"

type ChartEmptyStateProps = {
    title: string
    message: string
}
export const ChartEmptyState: FC<ChartEmptyStateProps> = ({
    message,
    title,
}): JSX.Element => (
    <div className={styles.container}>
        <div className={styles.inner}>
            <EmptyStateImage />
            <h4 className={styles.title}>{title}</h4>
            <p>{message}</p>
        </div>
    </div>
)
