import {
    EarningsResponse,
    Unit,
} from "@vacasa/owner-api-models/src/legacy/Earnings"
import { StatementsResponse } from "@vacasa/owner-api-models/src/legacy/Statements"
import { authAxios } from "api/authAxios"
import { isEmpty } from "lodash"
import { filter, map, orderBy, pipe } from "lodash/fp"
import {
    action,
    extendObservable,
    makeObservable,
    observable,
    ObservableMap,
    toJS,
} from "mobx"
import "mobx-react"
import { trackOwnerEarningsReviewed } from "services/segment/statement/statementTracking"
import { convertBase64ToBlob } from "utils/statements/statementsUtil"
import { OWNER_API_URL } from "../../Environment"
import { isUnauthorizedOrCancelledRequest } from "../../utils/error/error"
import OwnerAPIService from "../api/owner.api.service"
import rootService from "../index"
import LoggingService from "../logging/logging.service"
import {
    endOfMonth,
    endOfYear,
    format,
    formatISO,
    getYear,
    parseISO,
    startOfMonth,
    startOfYear,
} from "date-fns"

type Statement = StatementsResponse["data"][0]
export type Earnings = EarningsResponse["data"] & { displaySingleUnit?: Unit }

class EarningsService {
    initialized?: boolean
    month?: string | null
    year?: string | number | null

    statement!: Earnings | null | ObservableMap<unknown, unknown>
    statements!: Record<string, Earnings | null>
    // definite assignment in init loop
    statementDates!: StatementsResponse["data"] | null
    // definite assignment in init loop
    monthKeys!: string[]
    // definite assignment in init loop
    isDefaultView!: boolean
    // definite assignment in init loop
    updatingSummary!: boolean
    // definite assignment in init loop
    displayAllUnits!: boolean
    // definite assignment in init loop
    isInitFetch!: boolean
    // definite assignment in init loop
    isYearEnd!: boolean
    // definite assignment in init loop
    currentRoute!: string | null
    // TODO remove expenses, no references
    // definite assignment in init loop
    expenses!: ObservableMap
    // definite assignment in init loop
    initialSummary!: boolean
    // definite assignment in init loop
    fetchingStatementDates!: boolean
    // definite assignment in init loop
    loadingSummary!: boolean
    // definite assignment in init loop
    unitSummary!: Unit | Unit[] | ObservableMap<unknown, unknown>
    // definite assignment in init loop
    // TODO remove summary, no references
    summary!: ObservableMap | Record<string | number | symbol, unknown>

    // TODO remove currentUnitSummary, no references
    currentUnitSummary!: undefined | null

    // temp variables
    @observable contactId: string | null = null
    @observable userId: string | null = null

    constructor() {
        makeObservable(this)
        this.init()
    }
    /*
        This function is used to initialize observable properties
        DO NOT initialize class properties outside of this function
    */
    @action init = async () => {
        const initialState = {
            month: null,
            year: null,
            statement: null,
            statements: {},
            statementDates: null,
            monthKeys: [],
            currentUnitSummary: null,
            isDefaultView: true,
            unitSummary: new ObservableMap(),
            loadingSummary: true,
            summary: new ObservableMap(),
            updatingSummary: false,
            displayAllUnits: false,
            isInitFetch: true,
            isYearEnd: false,
            expenses: new ObservableMap(),
            initialSummary: false,
            currentRoute: null,
            fetchingStatementDates: false,
        }

        if (this.initialized) {
            Object.assign(this, initialState)
            return
        }
        extendObservable(this, initialState)
        this.initialized = true
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @action setStatement = (
        statement: Earnings | null | ObservableMap<unknown, unknown>
    ) => {
        this.statement = statement
    }

    @action setUnitSummary = (unitSummary: Unit | Unit[]) => {
        this.unitSummary = unitSummary
    }

    @action setLoadingSummary = (loadingSummary: boolean) => {
        this.loadingSummary = loadingSummary
    }

    @action setStatementDates = (
        statementDates: StatementsResponse["data"] | null
    ) => {
        this.statementDates = statementDates
    }

    @action fetchStatementDates = async () => {
        if (this.statementDates) {
            return this.statementDates
        }

        this.fetchingStatementDates = true
        // TODO @see {@link https://vacasait.atlassian.net/browse/TFP-1174}
        rootService.calendarService.showGrid = false
        this.updatingSummary = true

        return OwnerAPIService.getStatements(
            // @ts-expect-error Object is possibly 'null
            // TODO @see {@link https://vacasait.atlassian.net/browse/TFP-1175}
            this.contactId,
            "40"
        )
            .then(response => {
                this.fetchingStatementDates = false
                this.setStatementDates(response.data)
                let setSelected = true
                // @ts-expect-error statementDates is possibly null
                // TODO @see {@link https://vacasait.atlassian.net/browse/TFP-1176}
                for (const date of this.statementDates) {
                    const year = date.Year
                    const month =
                        date.Month.toString().length === 1
                            ? "0" + date.Month.toString()
                            : date.Month.toString()
                    const monthKey = `${year}${month}`
                    this.monthKeys.push(monthKey)
                    this.statements[monthKey] = null
                    if (setSelected) {
                        this.year = year
                        this.month = month
                        rootService.calendarService.selectedYear = String(year)
                        rootService.calendarService.displayMonth = format(
                            new Date(year, Number(month) - 1, 1),
                            "MMMM"
                        )
                        setSelected = false
                    }
                }
                return this.statementDates
            })
            .catch(error => {
                if (!isUnauthorizedOrCancelledRequest(error)) {
                    LoggingService.error({
                        message: "Fetching statement dates failed",
                        error,
                        tags: {
                            // @ts-expect-error userId is possibly 'null'
                            // TODO @see {@link https://vacasait.atlassian.net/browse/TFP-1177}
                            userID: this.userId,
                        },
                    })
                }
            })
    }

    hasStatementDate(year: string, month?: string): boolean {
        const monthKey = `${year}${month}`
        return typeof this.statements[monthKey] !== "undefined"
    }

    getFirstAndLastDayFromYear = async (
        year: Date
    ): Promise<{
        // eslint-disable-next-line camelcase
        first_day: string
        // eslint-disable-next-line camelcase
        last_day: string
    }> => {
        // eslint-disable-next-line camelcase
        const first_day = formatISO(startOfYear(year), {
            representation: "date",
        })
        // eslint-disable-next-line camelcase
        let last_day = formatISO(endOfMonth(new Date()), {
            representation: "date",
        })
        const currentYear = new Date()
        const isProvidedYearBeforeCurrentYear =
            getYear(year) < getYear(currentYear)
        await this.fetchStatementDates()

        if (isProvidedYearBeforeCurrentYear) {
            // eslint-disable-next-line camelcase
            last_day = formatISO(endOfYear(year), {
                representation: "date",
            })
        } else if (
            formatISO(year, {
                representation: "date",
            }) >= format(new Date(), "y")
        ) {
            const orderCurrentYearStatementsByMonth = pipe(
                filter<Statement>(
                    statmentDate => statmentDate.Year === getYear(year)
                ),
                map<Statement, Statement>(d => d), // 'unpack' mobx observable array to concrete values
                orderBy<Statement>(["Month"], ["desc"])
            ) as (collection: Statement[] | null | undefined) => Statement[]
            const statementDatesByYear = orderCurrentYearStatementsByMonth(
                this.statementDates
            )
            if (statementDatesByYear.length > 0) {
                const validStatementMonthYear = statementDatesByYear[0]
                if (!validStatementMonthYear)
                    throw new Error("Invalid statement month year")
                const validDate = new Date(
                    validStatementMonthYear.Year,
                    validStatementMonthYear.Month - 1,
                    1
                )
                // eslint-disable-next-line camelcase
                last_day = formatISO(endOfMonth(validDate), {
                    representation: "date",
                })
            } else {
                const statementYear = Number(format(year, "y"))
                const statementMonth = Number(
                    rootService.earningsService.statementDates?.[0]?.Month
                )
                const lastMonth = new Date(statementYear, statementMonth, 1)
                // eslint-disable-next-line camelcase
                last_day = formatISO(endOfMonth(lastMonth), {
                    representation: "date",
                })
            }
        }

        // eslint-disable-next-line camelcase
        return { first_day: first_day, last_day: last_day }
    }

    @action fetchStatementByUnitID = async (
        contactID: string | undefined,
        unitID: string | null,
        // eslint-disable-next-line camelcase
        first_day: string | null,
        // eslint-disable-next-line camelcase
        last_day: string | null,
        year: Date | null = null,
        isEmployee: boolean
    ) => {
        const logInfo = {
            // eslint-disable-next-line camelcase
            first_day: first_day,
            // eslint-disable-next-line camelcase
            last_day: last_day,
            year,
            contactID: contactID,
        }
        LoggingService.log({
            message: `Earnings Viewed - contactId: ${logInfo.contactID}, unitId: ${unitID}`,
            context: {
                firstDay: logInfo.first_day,
                lastDay: logInfo.last_day,
                year: String(logInfo.year),
            },
        })
        // TODO @see {@link https://vacasait.atlassian.net/browse/TFP-1174}
        rootService.calendarService.showGrid = false
        this.updatingSummary = true
        // eslint-disable-next-line camelcase
        if (!year && (!first_day || !last_day)) {
            // eslint-disable-next-line camelcase
            first_day = formatISO(startOfMonth(new Date()), {
                representation: "date",
            })
            // eslint-disable-next-line camelcase
            last_day = formatISO(endOfMonth(new Date()), {
                representation: "date",
            })
        }
        if (year) {
            const days = await this.getFirstAndLastDayFromYear(year)
            // eslint-disable-next-line camelcase
            first_day = days.first_day
            // eslint-disable-next-line camelcase
            last_day = days.last_day
        }
        this.isYearEnd = year !== null
        // eslint-disable-next-line camelcase
        this.year = format(parseISO(first_day ?? ""), "yyyy")
        rootService.calendarService.selectedYear = this.year

        if (this.isYearEnd) {
            this.month = null
        } else {
            // eslint-disable-next-line camelcase
            this.month = format(parseISO(first_day ?? ""), "MM")
            rootService.calendarService.displayMonth =
                // eslint-disable-next-line camelcase
                format(parseISO(first_day ?? ""), "MMMM")
        }
        const currentStatements = this.statements[`${this.year}${this.month}`]
        if (currentStatements != null) {
            this.updatingSummary = false
            this.isInitFetch = false
            this.setStatement(currentStatements)
            // @ts-expect-error unitID is possibly 'null'
            // TODO @see {@link https://vacasait.atlassian.net/browse/TFP-1185}
            this.getUnitSummary(unitID)
        } else {
            this.setLoadingSummary(true)
            try {
                await OwnerAPIService.getEarnings(
                    // TODO @see {@link https://vacasait.atlassian.net/browse/TFP-1186}
                    this.contactId ?? "",
                    //@ts-expect-error first_day is possibly null
                    // TODO @see ${@link https://vacasait.atlassian.net/browse/TFP-1187}
                    first_day,
                    last_day
                )
                    .then(response => {
                        const data: Earnings = response.data
                        this.setStatement(data)
                        let singleUnit = null
                        let activeCount = 0
                        for (const unit of data.units) {
                            const isContractActive = unit.hasActiveContract
                            if (isContractActive) {
                                activeCount++
                                singleUnit = unit
                            }
                        }

                        if (activeCount === 1 && singleUnit) {
                            data.displaySingleUnit = singleUnit
                        }
                        this.updatingSummary = false
                        this.isInitFetch = false
                        this.setLoadingSummary(false)
                        const selectedMonth = `${this.year}${this.month}`
                        if (selectedMonth in this.statements) {
                            this.statements[selectedMonth] = data
                        }
                        this.setStatement(data)
                        return data
                    })
                    .catch(error => {
                        if (!isUnauthorizedOrCancelledRequest(error)) {
                            LoggingService.error({
                                message: "Fetching owner statement failed",
                                error,
                                tags: {
                                    //@ts-expect-error unitId is possibly null
                                    // TODO @see {@link https://vacasait.atlassian.net/browse/TFP-1188}
                                    unitID,
                                    contactID: this.contactId ?? "",
                                },
                            })
                        }
                        this.setLoadingSummary(false)
                    })
            } catch (e) {
                // invalid statement
            }

            //@ts-expect-error unitID is possibly null
            // TODO @see {@link https://vacasait.atlassian.net/browse/TFP-1189}
            this.getUnitSummary(unitID)
        }
        trackOwnerEarningsReviewed(isEmployee ? contactID ?? "" : "")
    }

    @action getUnitSummary = async (unitID: string) => {
        if (this.statement) {
            // TODO statement could be observable map
            const summary = toJS(this.statement) as Earnings
            if (summary.units) {
                const response = await summary.units.filter(
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (unit: any) => parseInt(unit.id) === parseInt(unitID)
                )
                if (response && response[0]) {
                    this.setUnitSummary(response[0])
                }
                return this.unitSummary
            }
        }
    }

    @action fetchAllUnitsSummary() {
        this.displayAllUnits = true
        return (this.statement as Earnings).units
    }

    // TODO remove getStatement no references
    @action getStatement() {
        return this.statement || false
    }

    async getYearlyRentalXLS(contactID: string, year: number) {
        LoggingService.log({ message: `Generating csv` })
        try {
            const requestData = { hours: [] }
            if (year === 2019) {
                const url = `${OWNER_API_URL}v1/maintenance-hours?cid=${contactID}`

                const maintenanceHours = await authAxios.get<{
                    data: {
                        units: never[]
                    }
                }>(url)
                if (!isEmpty(maintenanceHours.data)) {
                    requestData.hours = maintenanceHours.data.data.units
                }
            }

            const response = await authAxios.post<{
                data: {
                    attributes: {
                        rentalDataXLS: string
                    }
                }
            }>(
                `${OWNER_API_URL}v1/legacy/owners/${contactID}/rental-data-xls?year=${year}`,
                requestData
            )

            return convertBase64ToBlob(
                response.data.data.attributes.rentalDataXLS
            )
        } catch (error) {
            if (!isUnauthorizedOrCancelledRequest(error)) {
                LoggingService.error({
                    message: "Failed to get yearly rental data",
                    error,
                    tags: {
                        contactID,
                        // @ts-expect-error year is not a string
                        // TODO @see {@link https://vacasait.atlassian.net/browse/TFP-1192}
                        year,
                    },
                })
            }
            // propagate error up the chain
            throw error
        }
    }

    @action setUserVariables = (
        userId: string | null,
        contactId: string | null
    ) => {
        this.userId = userId
        this.contactId = contactId
    }

    // TODO remove reset, no references
    @action reset = () => {
        this.month = null
        this.year = null
        this.setStatement(new ObservableMap())
        this.setStatementDates(null)
        this.statements = {}
        this.monthKeys = []
        this.currentUnitSummary = null
        this.isDefaultView = true
        this.setUnitSummary([])
        this.setLoadingSummary(true)
        this.summary = {}
        this.updatingSummary = false
        this.displayAllUnits = false
        this.isInitFetch = true
        this.isYearEnd = false
        this.expenses = new ObservableMap()
        this.initialSummary = false
        this.currentRoute = null
        this.userId = null
    }
}

const earningsService = new EarningsService()

export default earningsService
