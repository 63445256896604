import { VACASA_URL } from "Environment"
import Promotion from "lib/components/Promotion"
import { FormattedMessage } from "react-intl"
import { trackLinenServiceLearnMoreClicked } from "services/segment/homeInfo/homeInfoTracking"
import { ReactComponent as LinenHandsSvg } from "../../../assets/linen-illustration.svg"
const LearnMore = (): JSX.Element => {
    const publicLink = `${VACASA_URL}owner-library/linens`
    const linenHandsSvg = <LinenHandsSvg />
    const actions = [
        {
            text: (
                <FormattedMessage
                    id="LinenService.infoPanel.learnMore"
                    defaultMessage="Learn more"
                />
            ),
            onClick: () => {
                trackLinenServiceLearnMoreClicked()
                const win = window.open(publicLink, "_blank")
                if (win) {
                    win.focus()
                }
            },
        },
    ]
    return (
        <Promotion
            title={
                <FormattedMessage
                    id="LinenService.infoPanel.learnMoreTitle"
                    defaultMessage="A simple, affordable solution"
                />
            }
            bodyText={
                <FormattedMessage
                    id="LinenService.infoPanel.bodyText"
                    defaultMessage="Our linen service provides you with hotel-quality linens at an industry discount. Fresh sheets and fluffy towels for every guest mean better reviews, more bookings, and more money for you."
                />
            }
            actions={actions}
            img={linenHandsSvg}
        />
    )
}

export default LearnMore
