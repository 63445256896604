import React, { Fragment } from "react"
import { Card, CardContent } from "../../../components/Card"
import { CardGroupTitle } from "../../../components/Card/CardGroupTitle/CardGroupTitle"

interface ServicePlanProps {
    title: JSX.Element
    cost: JSX.Element
    discount: JSX.Element | null
    nextMilestone: React.ReactNode
    prepend: React.ReactNode
    append: React.ReactNode
}

const ServicePlan: React.FC<ServicePlanProps> = (props: ServicePlanProps) => {
    return (
        <>
            <CardGroupTitle>{props.prepend}</CardGroupTitle>
            <Card>
                <CardContent>
                    <div className="flex">
                        <div className="content-zone">
                            <div className="type-heading-small">
                                {props.title}
                            </div>
                            {(props.cost || props.nextMilestone) && (
                                <React.Fragment>
                                    <div className="info type-body-small">
                                        <div className="cost">
                                            {props.cost}{" "}
                                            {props.discount && props.discount}
                                        </div>
                                    </div>
                                    <div className="milestone type-body-tiny">
                                        {props.nextMilestone}
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </CardContent>
            </Card>
            <div className="append type-body-tiny">{props.append}</div>
        </>
    )
}

export default ServicePlan
