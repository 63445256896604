import React, { useEffect, useRef, useState } from "react"
import IntlTelInput from "react-intl-tel-input"
import classNames from "classnames";
import { getClassStyle } from "../../../../../utils/styles/styleWrapper";
import styles from "./PhoneInput.module.scss";
import { FormattedMessage } from "react-intl";
import 'react-intl-tel-input/dist/main.css';
import { CountryData } from "react-intl-tel-input/dist/types"
import { containsOnlyValidPhoneCharacters } from "../../../../../utils/common/validPhoneCharacters"

export interface PhoneInputProps {
    phone?: string
    onChange?: (value: string) => void
    onValidate?: (isValid: boolean) => void
    readOnly?: boolean
}

export const PhoneInput: React.FC<PhoneInputProps> = ({ phone = '', onChange, onValidate, readOnly }) => {
    const telInputRef = useRef<HTMLDivElement>(null);
    const render = useRef<boolean>(false)

    const [isValidNumber, setIsValidNumber] = useState<boolean>(true)
    const [formattedPhone, setFormattedPhone] = useState<string>('')

    useEffect(() => {
        if (!phone && onValidate) {
            onValidate(false)
        }
    }, [phone, onValidate])

    const handlePhoneChange = (
        isValid: boolean,
        value: string,
        _selectedCountryData: CountryData,
        fullNumber: string,
        _extension: string,
    ) => {
        if (readOnly) setFormattedPhone(fullNumber)
        if (onValidate) onValidate(isValid)

        setIsValidNumber(isValid)

        // This logic was added because the 'value' contains only the number,
        // and when the component initializes, the onPhoneNumberChange method runs by default,
        // which causes the prefix to be lost.
        if(onChange && !render.current) {
            render.current = true
        } else if (onChange && (containsOnlyValidPhoneCharacters(value) || value === '')) {
            onChange(value)
        }
    };

    const handleBlur = (
        isValid: boolean,
        _value: string,
        _selectedCountryData: CountryData,
        fullNumber: string,
        _extension: string,
    ) => {
        setIsValidNumber(isValid)
        if (onValidate) onValidate(isValid)
        // This is necessary to save the phone number along with the prefix before clicking the button
        if (onChange && isValid) onChange(fullNumber)
    }

    const renderDescription = (phone?: string) => {
        if (!readOnly) {
            return (
                <FormattedMessage
                    id="CalendarPage.flyout.PhoneSections.smsDescription"
                    defaultMessage="Details will be sent to this number."
                />
            );
        }

        return phone && phone !== '' ? (
            <FormattedMessage
                id="CalendarPage.flyout.PhoneSections.smsEditDescription"
                defaultMessage="Details will be sent to <strong>{number}</strong>."
                values={{
                    number: phone,
                    strong: chunks => <strong>{chunks}</strong>,
                }}
            />
        ) : (
            <FormattedMessage id="CalendarPage.flyout.PhoneSections.noPhoneNumber" defaultMessage="No phone number provided" />
        );
    };

    return (
        <div
            className={classNames(styles.layout, {
                [getClassStyle(styles.error)]: !isValidNumber
            })}
        >
            <div className="type-heading-small">
                <FormattedMessage
                    id="CalendarPage.flyout.PhoneSections.smsTitle"
                    defaultMessage="Owner contact info"
                />
            </div>
            <div data-testid={'phone-description'} className="type-body-small">
                {renderDescription(formattedPhone)}
            </div>
            <div ref={telInputRef} >
                <IntlTelInput
                    value={phone || ''}
                    containerClassName={`intl-tel-input ${readOnly ? styles['iti__hidden-container'] : ''}`}
                    inputClassName="form-control"
                    onPhoneNumberChange={handlePhoneChange}
                    onPhoneNumberBlur={handleBlur}
                    preferredCountries={["us", "ca", "br", "mx"]}
                    defaultCountry={"ca"} // The component has issues detecting Canadian numbers; this change helps it detect them more efficiently.
                />
            </div>
            {!isValidNumber && !readOnly && (
                <div className={classNames(styles.errorText, "type-body-small")}>
                    <FormattedMessage
                        id="CalendarPage.flyout.PhoneSections.invalidPhoneMessage"
                        defaultMessage="The phone number is not valid"
                    />
                </div>
            )}
        </div>
    );
};
