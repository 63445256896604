const topbar = {
    myRentals: "Mis alquileres",
    myTrips: "Mis viajes",
    profile: "Perfil",
    changePassword: "Cambiar contraseña",
    logout: "Cerrar sesión",
    switchAccounts: "Switch Accounts",
    selectAccount: "Select An Account",
    referral: "Refer a friend",
}

export default topbar
