import { JSONAPIResource, Reservation } from "@vacasa/owner-api-models"
import { HOLD_CREATED } from "Constants"
import useSearchParams from "hooks/common/useSearchParams"
import { useAddReservationGuest } from "hooks/reservation-guests"
import { Button } from "lib/components/Button/Button"
import { FC, FormEvent } from "react"
import { useIntl } from "react-intl"
import LoggingService from "services/logging/logging.service"
import Layout, { FooterSecondaryButton } from "../Layout/Layout"
import InviteGuestForm, { useInviteGuestForm } from "./ReservationGuestForm"

interface Props {
    backToReservationView: () => void
    reservation: JSONAPIResource<Reservation>
}

export const InviteGuestView: FC<Props> = ({
    backToReservationView,
    reservation,
}) => {
    const intl = useIntl()
    const searchParams = useSearchParams()
    const addReservationGuest = useAddReservationGuest()
    const { firstName, email, lastName, onChange, isValid } =
        useInviteGuestForm()

    const onFormSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        addReservationGuest.mutate(
            {
                reservationId: reservation.id?.toString() ?? "",
                guest: {
                    firstName,
                    lastName,
                    email,
                },
            },
            {
                onSuccess: () => {
                    backToReservationView()
                },
                onError: err => {
                    LoggingService.error({
                        message: "Failed to add guest to reservation",
                        err,
                    })
                },
            }
        )
    }

    const holdCreated = searchParams.get(HOLD_CREATED) === "1"

    return (
        <Layout reservationId={reservation.id?.toString()} showBackButton>
            <Layout.Content form>
                <InviteGuestForm
                    formData={{
                        email,
                        firstName,
                        lastName,
                    }}
                    onSubmit={onFormSubmit}
                    onChange={onChange}
                    showForm={true}
                />
            </Layout.Content>
            <Layout.Footer>
                <Button
                    disabled={!isValid()}
                    type="submit"
                    form="invite-guests-form"
                    aria-label="Click this button to add a guest"
                    variant="primary"
                    className="full-width"
                    loading={addReservationGuest.isLoading}
                    data-testid="invite-guests-button"
                >
                    {intl.formatMessage({
                        id: "CalendarPage.flyout.inviteGuests",
                        defaultMessage: "Invite Guests",
                    })}
                </Button>

                {holdCreated && (
                    <FooterSecondaryButton
                        disabled={addReservationGuest.isLoading}
                        onClick={backToReservationView}
                        aria-label={intl.formatMessage({
                            id: "CalendarPage.flyout.skipAria",
                            defaultMessage:
                                "Click this button to skip adding guests",
                        })}
                        data-testid="skip-guests-button"
                    >
                        {intl.formatMessage({
                            id: "CalendarPage.flyout.skip",
                            defaultMessage: "Skip",
                        })}
                    </FooterSecondaryButton>
                )}
            </Layout.Footer>
        </Layout>
    )
}
