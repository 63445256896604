const login = {
    homeownerLogin: `Homeowner login`,
    clickHere: `click here`,
    guestsClickHereToAccessDetails: `Guests, please {{clickHere}} to access your trip details.`,
    username: `Username`,
    password: `Password`,
    forgotYourPassword: `Forgot your password?`,
    login: `Login`,
    employeeLogin: `Employee Login`,
    contractorLogin: `Contractor Login`,
}

export default login
