import { JSONAPIResource, Review } from "@vacasa/owner-api-models"
import { AxiosError } from "axios"
import { useQuery, UseQueryResult } from "react-query"
import { fetchReviews } from "./fetchReviews"

const REVIEWS_QUERY_KEY = "reviews" as const

const useReviews = (
    reservationId: string | number | undefined,
    contactId: string
): UseQueryResult<JSONAPIResource<Review>[] | undefined, AxiosError> => {
    return useQuery(
        [REVIEWS_QUERY_KEY, reservationId, contactId],
        ({ signal }) =>
            fetchReviews({
                reservationId: reservationId as string,
                contactId,
                signal,
            }),
        {
            enabled: reservationId !== undefined && !!contactId,
            refetchInterval: 1000 * 60 * 60, // 1 hour
            staleTime: 1000 * 60 * 10, // 10 minutes
        }
    )
}
export { useReviews }
