import { Alert } from "components/Alert"
import { useHomeAvailability } from "hooks/home-availability"
import { useUnits } from "hooks/units"
import { ReactNode, useEffect } from "react"
import { FormattedMessage } from "react-intl"
import { Link, LinkProps } from "react-router-dom"
import { ReactComponent as CalendarAddIcon } from "./calendar-add.svg"

import styles from "./HomeAvailabilityAlert.module.scss"
import {
    trackAlertDisplayed,
    trackSubmitAvailabilityAlertClicked,
} from "services/segment/onboarding/onboardingTracking"
import { useIsEmployee } from "hooks/user"

type HomeAvailabilityAlertProps = {
    unitId: number
} & LinkProps

const HomeAvailabilityAlert = ({
    unitId,
    ...linkProps
}: HomeAvailabilityAlertProps): JSX.Element | null => {
    const homeAvailabilityQuery = useHomeAvailability(unitId)
    const unitsQuery = useUnits()
    const unit = unitsQuery.data?.find(unit => unit.id === String(unitId))
    const showAlert =
        unit &&
        homeAvailabilityQuery.isSuccess &&
        // Only show the alert if home availability is not set
        !homeAvailabilityQuery.data.ticketId

    const disabled = useIsEmployee()

    useEffect(() => {
        if (showAlert) {
            trackAlertDisplayed(String(unit.id))
        }
    }, [unit, showAlert])
    if (!showAlert) {
        return null
    }

    const ownerLinkProps = !disabled
        ? {
              onClick: (): void => {
                  trackSubmitAvailabilityAlertClicked(String(unitId))
              },
              as: Link,
              ...linkProps,
          }
        : {}

    return (
        <div
            className={styles.alertContainer}
            data-testid="home-availability-alert"
        >
            <Alert
                variant="alertness"
                clickable
                disabled={disabled}
                {...ownerLinkProps}
            >
                <Alert.Icon icon={CalendarAddIcon} />
                <Alert.Description>
                    <FormattedMessage
                        id="HomeAvailabilityAlert.Description"
                        defaultMessage="<title>Submit Availability for {unitName}</title> Let us know when our onboarding team can come to your home to take professional photographs, complete inspections and more."
                        values={{
                            title: (chunks: ReactNode) => (
                                <div
                                    className="alertTitle"
                                    style={{
                                        textTransform: "uppercase",
                                        letterSpacing: "2px",
                                    }}
                                >
                                    <Alert.Title>
                                        {chunks}
                                        <br />
                                    </Alert.Title>
                                </div>
                            ),
                            unitName: unit?.attributes.name,
                        }}
                    />
                </Alert.Description>
                <Alert.LinkIcon />
            </Alert>
        </div>
    )
}

export { HomeAvailabilityAlert }
