const login = {
    homeownerLogin: "Connexion propriétaire",
    clickHere: "cliquer ici",
    guestsClickHereToAccessDetails:
        "Si vous êtes locataire, veuillez {{clickHere}} pour accéder à vos informations de voyage.",
    username: "Identifiant",
    password: "Mot de passe",
    forgotYourPassword: "Vous avez oublié votre mot de passe ?",
    login: "Connexion",
    employeeLogin: "Connexion collaborateur",
    contractorLogin: "Connexion prestataire",
}

export default login
