/* eslint-disable camelcase */
// Page Paths
export const STATEMENT_PATH = "/statements"
export const PAYMENT_SETTING_PATH = "/statements/settings"
// Actions
export const FAQ_SECTION_ACTION = "faq_section"
export const TOOL_TIP_CLICK_ACTION = "tooltip_click"
export const LINK_CLICK_ACTION = "link_click"
// Events
export const STATEMENT_EVENT = "Statements"
export const OWNER_EARNINGS_REVIEWED_EVENT = "Owner Earnings Reviewed"
export const OWNER_PORTAL_TAG = "owner_portal"
// Options
export const RESERVE_BALANCE_LINE_ENTRY_OPTION = "reserve_balance_line_entry"
export const BACK_TO_TOP_OPTION = "back_to_top"
export const PAYMENT_SETTINGS_OPTION = "payment_settings"
export const NEED_HELP_OPTION = "need_help"
// Click Track Event
export const CLICK_TRACK_BUTTON_CLICK_EVENT = "Click"
// Click Track Type
export const CLICK_TRACK_BUTTON_TYPE = "button"
// Click Track Label
export const UPDATE_RESERVE_BALANCE_LABEL = "update_reserve_balance"
export const SAVE_PAYMENT_METHOD_LABEL = "save_payment_method"
