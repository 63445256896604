import {
    JSONAPIDocument,
    JSONAPIResource,
    UserPreference,
} from "@vacasa/owner-api-models"
import { authAxios } from "../../api/authAxios"

type FetchOwnerUnitPreferencesRequest = {
    unitId: string
    signal?: AbortSignal
}

const fetchOwnerUnitPreferences = ({
    signal,
    unitId,
}: FetchOwnerUnitPreferencesRequest): Promise<
    JSONAPIResource<UserPreference>[]
> =>
    authAxios
        .get<JSONAPIDocument<UserPreference, []>>(
            `/v1/user-preferences/units/${unitId}`,
            {
                signal,
            }
        )
        .then(response => response.data.data)

export { fetchOwnerUnitPreferences }
