/** Environment variables */

export const ENV = process.env.REACT_APP_ENV
export const NODE_ENV = process.env.NODE_ENV

export const GIT_TAG = process.env.REACT_APP_GIT_TAG
export const GIT_BRANCH = process.env.REACT_APP_GIT_BRANCH

export const APP_URL = process.env.REACT_APP_APP_URL

export const OWNER_API_URL = process.env.REACT_APP_OWNER_API_URL
export const VACASA_URL = process.env.REACT_APP_VACASA_URL
export const ADMIN_URL = process.env.REACT_APP_ADMIN_URL
export const GUESTWORKS_URL = process.env.REACT_APP_GUESTWORKS_URL
export const MY_TRIPS_URL = `${VACASA_URL}my-trips`

export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN
export const SENTRY_RELEASE_NAME = process.env.REACT_APP_SENTRY_RELEASE_NAME

export const DATADOG_ENABLED = process.env.REACT_APP_DATADOG_ENABLED === "true"
export const DATADOG_CLIENT_TOKEN = process.env.REACT_APP_DATADOG_CLIENT_TOKEN
export const DATADOG_RUM_ENABLED =
    process.env.REACT_APP_DATADOG_RUM_ENABLED === "true"
export const DATADOG_RUM_CLIENT_TOKEN =
    process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN
export const DATADOG_RUM_APPLICATION_ID =
    process.env.REACT_APP_DATADOG_RUM_APPLICATION_ID
export const DATADOG_RUM_SESSION_SAMPLE_RATE = Number(
    process.env.REACT_APP_DATADOG_RUM_SESSION_SAMPLE_RATE
)
export const OPTIMIZELY_SDK_KEY = process.env.REACT_APP_OPTIMIZELY_SDK_KEY
export const OPTIMIZELY_DATAFILE_URL =
    process.env.REACT_APP_OPTIMIZELY_DATAFILE_URL
export const SEGMENT_KEY = process.env.REACT_APP_SEGMENT_KEY

export const IDP_URL = process.env.REACT_APP_IDP_URL
export const IDP_CLIENT_ID = process.env.REACT_APP_IDP_CLIENT_ID
export const IDP_SCOPES = process.env.REACT_APP_IDP_SCOPES
export const IDP_REDIRECT_URI = process.env.REACT_APP_IDP_REDIRECT_URI
export const IDP_AUDIENCE = process.env.REACT_APP_IDP_AUDIENCE

export const PR_ENVIRONMENT_REDIRECTION_ENABLED =
    process.env.REACT_APP_PR_ENVIRONMENT_REDIRECTION_ENABLED === "true"

export const SKIP_ONBOARDING_REDIRECT =
    process.env.REACT_APP_SKIP_ONBOARDING_REDIRECT === "true"

export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY

export const TWILIO_ACCOUNT_ID = process.env.REACT_APP_TWILIO_ACCOUNT_ID
export const TWILIO_FLOW_SID = process.env.REACT_APP_TWILIO_FLOW_SID
