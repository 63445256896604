import { useQuery, UseQueryOptions, UseQueryResult } from "react-query"
import type { AxiosError } from "axios"
import { useContactId } from "hooks/user"
import { fetchRevenue, RevenueResponse } from "./fetchRevenue"

const useRevenue = (
    unitId: string | null,
    year: number,
    options?: UseQueryOptions<RevenueResponse, AxiosError>
): UseQueryResult<RevenueResponse, AxiosError> => {
    const contactId = useContactId()
    return useQuery<RevenueResponse, AxiosError>(
        ["revenue", contactId, unitId, year],
        ({ signal }) => {
            return fetchRevenue({
                unitId: unitId as string,
                contactId: contactId as string,
                year,
                signal,
            })
        },
        {
            enabled: !!contactId && !!unitId,
            staleTime: 60000 * 10, // 10 minutes
            ...options,
        }
    )
}

export { useRevenue }
