/* eslint-disable camelcase */
import { Owner } from "@vacasa/owner-api-models"

export interface CommonFields {
    unit_id: string
    contact_id: string
    login_id: string
    user_type: string
    owner_id: string
}

export const buildCommonFields = (
    user: Owner | null,
    isEmployee: boolean,
    unitId: string | null
): CommonFields => {
    return {
        unit_id: unitId ?? "",
        contact_id: user?.contactId ?? "",
        login_id: (isEmployee ? user?.userId : user?.contactId) ?? "",
        user_type: isEmployee ? "employee" : "owner",
        owner_id: isEmployee && user ? user.contactId : "",
    }
}
