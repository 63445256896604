import PageRoute from "lib/router/PageRoute"
import PrivateRoute from "lib/router/PrivateRoute"
import { observer } from "mobx-react"
import { FC, memo } from "react"
import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom"
import { Card, CardContent } from "../components/Card"
import Loader from "../lib/components/Loader"
import RedirectToSearch from "./RedirectToSearch"
import RedirectToStatements from "./RedirectToStatements"
import Calendar from "./calendar"
import { ReservationFlyout } from "./calendar/ReservationFlyout/ReservationFlyout"
import Error from "./error"
import Home from "./home"
import HomeInfo from "./homeInfo/HomeInfo"
import Layout from "./layout"
import Login from "./login"
import Logout from "./logout"
import Maintenance from "./maintenance"
import Notfound from "./notfound"
import NoMatch from "./notfound/NotFound"
import { NotificationSettings } from "./notifications/NotificationSettings"
import Setup from "./onboarding"
import { HomeAvailabilityModal } from "./onboarding/HomeAvailabilityModal/HomeAvailabilityModal"
import { PerformancePageRoute } from "./performance"
import { Profile } from "./profile"
import SearchForward from "./searchForward"
import LinenService from "./settings/LinenService"
import PaymentSettings from "./settings/PaymentSettings"
import PaymentSettingsUpdate from "./settings/PaymentSettingsUpdate"
import PetsAllowed from "./settings/PetsAllowed"
import Statements from "./statements/Statements"
import Taxes from "./taxes/Taxes"
import { Verify } from "./onboarding/Verify"
import { useUser } from "hooks/user"
import { useUnits } from "hooks/units"
import { isOnboardingUnit } from "utils/unit"
import { SurveyFlyout } from "./performance/survey-flyout/SurveyFlyout"

const RenderSetup = (): JSX.Element => {
    const unitsQuery = useUnits()

    if (unitsQuery.isLoading)
        return (
            <div data-testid="unit-loading-panel" className="container-fluid">
                <Card>
                    <CardContent>
                        <Loader />
                    </CardContent>
                </Card>
            </div>
        )

    const hasUnits = !!(unitsQuery.data?.length ?? 0)
    const hasOnboardignUnits = unitsQuery.data?.some(isOnboardingUnit)

    if (!hasOnboardignUnits && hasUnits) {
        return <Navigate to={"/calendar"} />
    }

    return <Setup />
}

const RenderLinens = observer((): JSX.Element => {
    const { user } = useUser()
    let isLoading = true,
        ownerCountry
    if (user) {
        ownerCountry = user.accountInfo.accountingEntityCountry
        isLoading = !ownerCountry
    }
    if (isLoading) {
        return (
            <div className="container-fluid">
                <Card>
                    <CardContent>
                        <Loader />
                    </CardContent>
                </Card>
            </div>
        )
    }
    if (ownerCountry === "Canada") {
        return (
            <div className="container-fluid">
                <NoMatch />
            </div>
        )
    }
    return <LinenService />
})

const RenderStatements = observer((): JSX.Element => {
    const { user } = useUser()

    let isLoading = true,
        ownerCountry

    if (user) {
        ownerCountry =
            user.accountInfo.accountingEntityCountry ?? user.accountInfo.country // todo fix this
        isLoading = !ownerCountry
    }
    if (isLoading) {
        return (
            <div className="container-fluid">
                <Card>
                    <CardContent>
                        <Loader />
                    </CardContent>
                </Card>
            </div>
        )
    }
    if (ownerCountry === "Czechia") {
        return (
            <div className="container-fluid">
                <NoMatch />
            </div>
        )
    }
    return <Statements />
})

const RenderPetSettings = observer((): JSX.Element => {
    const { user } = useUser()
    return <PetsAllowed user={user} />
})

const RenderHomeInfoView = observer((): JSX.Element => <HomeInfo />)

interface Props {
    setShowReferralModal: (shouldShowReferralModal: boolean) => void
    showReferralModal: boolean
}

const AppRoutes: FC<Props> = ({
    setShowReferralModal,
    showReferralModal,
}): JSX.Element => {
    // prettier-ignore
    const router = createBrowserRouter([
        {
            element: <Layout setShowReferralModal={setShowReferralModal} showReferralModal={showReferralModal} />,
            children: [
                { 
                    // with auth
                    element: <PrivateRoute />, children: [
                      
                       { path: "/", element: <PageRoute page="" element={Home}  /> },
                       { path: "/calendar", element: <PageRoute page="calendar" element={Calendar} />, children: [
                           // react-router-dom v6 no longer uses `path-to-regexp` so this won't work e.g  /calendar/hold/:action(create)
                           // https://github.com/remix-run/react-router/discussions/8132
                           { path: "/calendar/hold/create", element: <PageRoute page="calendar" element={memo(() => <ReservationFlyout create />)} /> },
                           { path: "/calendar/hold/:reservationId/:action?/:guestId?", element: <PageRoute page="calendar" element={ReservationFlyout} /> },
                       ]},
                       { path: "/home-info", element: <PageRoute page="home-info" element={RenderHomeInfoView} /> },
                       { path: "/home-info/pets-allowed", element: <PageRoute page="petFriendly" element={RenderPetSettings} /> },
                       { path: "/home-info/linen-service/:unitId", element: <PageRoute page="linenService" element={RenderLinens} /> },
                       { path: "/maintenance", element: <PageRoute page="maintenance" element={Maintenance} /> },
                       { path: "/notification-settings", element: <PageRoute page="notifications" element={NotificationSettings} />  },
                       { path: "/performance", element: <PageRoute page="performance" element={PerformancePageRoute} onboardingRedirect={false} />, children: [
                           { path: "/performance/preference/create", element: <PageRoute page="performance" element={SurveyFlyout}  onboardingRedirect={false}/> }
                       ]},
                       { path: "/profile",  element: <PageRoute page="profile" element={Profile} /> },
                       { path: "/setup", element: <PageRoute page="setup" element={RenderSetup} />, children: [
                           { path:"/setup/:unitId/home-availability",  element: <PageRoute page="setup" element={HomeAvailabilityModal} /> }
                       ]},
                       { path: "/setup/verify", element: <PageRoute page="setup" element={Verify} /> },
                       { path: "/statements", element: <PageRoute page="statements" element={RedirectToStatements} onboardingRedirect={false} /> },
                       { path: "/statements/settings", element:  <PageRoute page="statements" element={PaymentSettings} onboardingRedirect={false} /> },
                       { path: "/statements/settings/update-payment", element:  <PageRoute page="statements" element={PaymentSettingsUpdate} onboardingRedirect={false} /> },
                       { path: "/statements/:year?/:month?", element: <PageRoute page="statements" element={RenderStatements} onboardingRedirect={false} /> },
                       { path: "/taxes", element: <PageRoute page="taxes" element={Taxes} onboardingRedirect={false} /> },
               ]},
       
               // Employee Routes
               { path: "/forward",element: <PageRoute page="search" element={SearchForward}  onboardingRedirect={false} /> },
               { path: "/search", element: <RedirectToSearch /> },
               
               // Global Routes
               { path: "/error", element: <Error /> },
               { path: "/login", element: <PageRoute page="login" element={Login} /> },
               { path: "/logout", element: <Logout /> },
               { path: "/404", element: <Notfound /> },
                
               // Redirect Routes 
               { path: "/activity",  element: <Navigate to="/statements" />  },
               { path: "/earnings", element: <Navigate to="/statements"/>  },
               { path: "/rental", element: <Navigate to="/"/>  },
               { path: "/rentals", element: <Navigate to="/search" />  },
               { path: "/taxforms", element: <Navigate to="/taxes"/>  },
               { path: "/services", element: <Navigate to="/"/>  },
               { path: "*", element: <Navigate to="/404" /> }
            ]
        }
    ])
    // prettier-ignore-end

    return <RouterProvider router={router} />
}

export default AppRoutes
