const survey = {
    title: "TARIFS & REVENUS",
    subTitle: "Définir la stratégie de revenus",
    surveyFollowUp: "Étape {step} sur {totalSteps}",
    errorTitle: "Enquête non disponible",
    loadingErrorMessage: "Désolé, l'enquête n'a pas pu être chargée. Veuillez réessayer plus tard.",
    submitErrorMessage: "Désolé, nous n'avons pas pu soumettre votre enquête pour le moment. Veuillez réessayer plus tard.",
    tanksTitle: "Félicitations pour avoir défini votre stratégie de revenus !",
    tanksMessage: "Notre équipe travaille à mettre en œuvre vos demandes."
}

export default survey
