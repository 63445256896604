const login = {
    homeownerLogin: "Tulajdonos bejelentkezése",
    clickHere: "kattints ide",
    guestsClickHereToAccessDetails:
        "Ha vendég vagy, {{clickHere}} az utaddal kapcsolatos részletekhez.",
    username: "Felhasználónév",
    password: "Jelszó",
    forgotYourPassword: "Elfelejtetted a jelszót?",
    login: "Belépés",
    employeeLogin: "Alkalmazott bejelentkezése",
    contractorLogin: "Vállalkozó bejelentkezése",
}

export default login
