import { FC } from "react"
import styles from "./TaxFormSelect.module.scss"
import { TaxCard } from "./TaxCard"
import { TaxFormTypes } from "./types"
import { FormattedMessage } from "react-intl"

type Props = {
    onClick: (taxForm: TaxFormTypes) => void
    selectedTaxForm: TaxFormTypes | undefined
}

export const TaxFormSelect: FC<Props> = ({ onClick, selectedTaxForm }) => {
    return (
        <div className={styles.taxFormSelect}>
            <span className={styles.info1}>
                <FormattedMessage
                    id="Taxes.w9.taxFormSelect.info1"
                    defaultMessage="Please provide your taxpayer information so we can provide your tax preparation documents. Provide information that best describes your taxpayer status with the IRS."
                />
            </span>
            <span className={styles.info2}>
                <FormattedMessage
                    id="Taxes.w9.taxFormSelect.info2"
                    defaultMessage="The name/entity that you provide should be the same name/entity that is listed on your contract with Vacasa."
                />
            </span>

            <div className={styles.taxFormsTitle}>Select your tax form*</div>

            <div className={styles.cards}>
                <TaxCard
                    title="W-9"
                    content={
                        "I'm a citizen or tax resident and receive payouts from listings in or outside the US. (Note: US SSN or EIN required)"
                    }
                    onClick={() => onClick(TaxFormTypes.w9)}
                    selected={selectedTaxForm === TaxFormTypes.w9}
                />
                {/* Cannot implement this flow as there is no way for us to track tasks  */}
                {/* <TaxCard
                    title="Other"
                    content={
                        "I am not a U.S. person (including resident alien, a partnership, corporation, company, or association created or organized under US laws, or domestic trust."
                    }
                    onClick={() => onClick(TaxFormTypes.w8)}
                    selected={selectedTaxForm === TaxFormTypes.w8}
                /> */}
            </div>
        </div>
    )
}
