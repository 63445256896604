import { ReservationType } from "@vacasa/owner-api-models"
import { ReservationDate } from "lib/components/ReservationDate"
import { FC } from "react"
import { FormattedMessage } from "react-intl"
import styles from "./ReservationTooltip.module.scss"
import classNames from "classnames"

export interface ReservationTooltipProps {
    date: Date
    occupancyType: ReservationType
    reservationId: string
    nights: number
    v2?: boolean
}

export const ReservationTooltip: FC<ReservationTooltipProps> = props => {
    const { date, nights, occupancyType, v2 } = props

    return (
        <div
            className={classNames(styles.container, {
                [styles.containerV2 as string]: v2,
            })}
        >
            <ReservationDate date={date} occupancyType={occupancyType} />
            <div>
                <div className={styles.reservationType}>
                    {occupancyType === ReservationType.RESERVATION && (
                        <FormattedMessage
                            id="Reservation.type.guestStay"
                            defaultMessage="Guest Stay"
                        />
                    )}
                    {occupancyType === ReservationType.OWNERHOLD && (
                        <FormattedMessage
                            id="Reservation.type.ownerHold"
                            defaultMessage="Owner Hold"
                        />
                    )}
                    {occupancyType === ReservationType.VACASAHOLD && (
                        <FormattedMessage
                            id="Reservation.type.vacasaHold"
                            defaultMessage="Vacasa Hold"
                        />
                    )}
                </div>
                <div className={styles.nights}>
                    <FormattedMessage
                        id="Reservation.nights"
                        defaultMessage="{nights, plural, =0 {} one {# night} other {# nights}}"
                        description="Number of nights for the reservation"
                        values={{
                            nights,
                        }}
                    />
                </div>
            </div>
            <div className={styles.actions}>
                {
                    // remove chart click interaction for now: https://vacasait.atlassian.net/browse/TFP-2539
                }
                {/* <a
                    target="_blank"
                    href={`/calendar/holds/${reservationId}`}
                    className={classNames(
                        styles.action,
                        "reservation-tooltip__action"
                    )}
                    rel="noreferrer"
                >
                    <ChevronRight size={18} />
                </a> */}
            </div>
        </div>
    )
}
