import React from "react"
import onboardingStyles from "../../../sass/onboarding.module.scss"
import Incrementer from "../Incrementer/Incrementer"
import SVGIcon from "../SVGIcon"
import { Tooltip } from "../Tooltip/Tooltip"
import styles from "./IncrementerRow.module.scss"

interface IncrementerRowProps {
    label: React.ReactNode
    subLabel?: React.ReactNode
    description?: string | JSX.Element
    tooltipBody?: React.ReactNode
    tooltipTitle?: string
    value?: number | null | undefined
    minValue?: number
    maxValue?: number
    id?: string
    onChangeHandler: (value: number, id?: string) => void
    v2?: boolean
}

const IncrementerRow: React.FunctionComponent<IncrementerRowProps> = ({
    label,
    subLabel,
    description,
    tooltipBody,
    tooltipTitle,
    value,
    minValue,
    maxValue,
    id,
    onChangeHandler,
}: IncrementerRowProps): JSX.Element => {
    return (
        <div>
            <div className={onboardingStyles.outer_row}>
                <div className={onboardingStyles.outer_column}>
                    <div>
                        <p className={onboardingStyles.type_body_inline}>
                            {label}
                        </p>
                        {tooltipBody && tooltipTitle && (
                            <span className={onboardingStyles.tooltip_wrapper}>
                                <Tooltip
                                    body={tooltipBody}
                                    title={tooltipTitle}
                                >
                                    <SVGIcon
                                        svg="icon-feather-info"
                                        colorName="gray-lightest"
                                        className="w-16 h-16 cursor-pointer"
                                        style={{ margin: "auto" }}
                                    />
                                </Tooltip>
                            </span>
                        )}
                        {subLabel && (
                            <span className={styles.sublabel}>{subLabel}</span>
                        )}
                        <span />
                    </div>
                </div>
                <Incrementer
                    initialValue={value ?? minValue ?? 0}
                    minValue={minValue}
                    maxValue={maxValue}
                    onChangeHandler={value => onChangeHandler(value, id)}
                />
            </div>
            {description && (
                <div className={onboardingStyles.row_description}>
                    <span className="type-body-small">{description}</span>
                </div>
            )}
        </div>
    )
}

export default IncrementerRow
