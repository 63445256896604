import React from "react"
import { Button } from "../../../lib/components/Button"
import { FooterSecondaryButton } from "../../calendar/ReservationFlyout/Layout/Layout"
import styles from './SurveyFlyout.module.scss'

interface NavigationButtonsProps {
    handleBack: () => void
    handleNext: () => void
    handleSubmit: () => void
    handleCancel: () => void
    currentStep: number
    isLastStep: boolean
    loading: boolean
    isNextDisabled: boolean
}

const NavigationButtons: React.FC<NavigationButtonsProps> = ({
    handleBack,
    handleNext,
    handleSubmit,
    handleCancel,
    currentStep,
    isLastStep,
    loading,
    isNextDisabled
}) => {
    return (
        <>
            <div className={styles["navigation-buttons"]}>
                {currentStep > 1 && (
                    <Button
                        disabled={loading}
                        variant="secondary"
                        className="full-width"
                        size={"small"}
                        data-testid="back-survey-button"
                        onClick={handleBack}
                    >
                        Back
                    </Button>
                )}
                {isLastStep ? (
                    <Button
                        disabled={isNextDisabled || loading}
                        variant="primary"
                        className="full-width"
                        size={"small"}
                        data-testid="submit-survey-button"
                        onClick={handleSubmit}
                        loading={loading}
                    >
                        Submit
                    </Button>
                ) : (
                    <Button
                        disabled={isNextDisabled || loading}
                        variant="primary"
                        className="full-width"
                        size={"small"}
                        data-testid="next-survey-button"
                        onClick={handleNext}
                    >
                        Next
                    </Button>
                )}
            </div>
            <FooterSecondaryButton
                disabled={loading}
                data-testid="cancel-survey-button"
                onClick={handleCancel}
            >
                Cancel
            </FooterSecondaryButton>
        </>
    )
}

export { NavigationButtons }