import { isEmpty } from "lodash"
import {
    FeatureFlag,
    FeatureFlagId,
} from "../../utils/feature-flag"
import { useFeature } from "@optimizely/react-sdk"

const useOwnerFeatureFlag = <FlagId extends FeatureFlagId>(
    featureFlagId: FlagId
): FeatureFlag<FlagId> => {
    const [isFeatureEnabled, variables, clientReady, didTimeout] =
        useFeature(featureFlagId)
    return {
        isEnabled: isFeatureEnabled,
        variables: !isEmpty(variables) ? variables : undefined,
        clientReady,
        didTimeout,
    } as FeatureFlag<FlagId>
}

export { useOwnerFeatureFlag }
