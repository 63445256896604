import { EMPLOYEE_LAST_VIEWED_OWNER_URL } from "Constants"
import { ADMIN_URL, VACASA_URL } from "Environment"
import Badge from "lib/components/Badge"
import UnitListRow from "lib/components/List/UnitListRow/UnitListRow"
import { useCallback } from "react"
import LoggingService from "services/logging/logging.service"
import storageService from "services/storage.service"
import {
    ActiveStatus,
    TerminatedStatus,
} from "utils/unit/unitStatus/unitStatus"
import { UnitSearchResult } from "./../types"
import styles from "./search.module.scss"
import { Secondary } from "lib/components/Buttons/BaseButton"

interface Props {
    unitSearchResult: UnitSearchResult
}

const VIEW_LISTING_URL = VACASA_URL + "unit.php?UnitID="
const MANAGE_UNIT_URL = ADMIN_URL + "admin/dashboard/units/edit?UnitID="

const SearchResultRow = ({ unitSearchResult }: Props) => {
    const onViewAsOwnerClick = () => {
        LoggingService.log({
            message: `View as Owner, Unit ID: "${unitSearchResult.id}" Contact ID: "${unitSearchResult.contactId}"`,
        })
        const forwardUrl = `/forward?view=calendar&UnitID=${unitSearchResult.id}&ContactID=${unitSearchResult.contactId}`
        window.open(forwardUrl, "_blank")
        storageService.localStorage.setItem(
            EMPLOYEE_LAST_VIEWED_OWNER_URL,
            forwardUrl
        )
    }

    const onViewPublicListingClick = useCallback(() => {
        window.open(`${VIEW_LISTING_URL}${unitSearchResult.id}`, "_blank")
    }, [unitSearchResult.id])

    const onManageUnitClick = useCallback(() => {
        window.open(
            `${MANAGE_UNIT_URL}${unitSearchResult.id}`,
            "_blank",
            "noreferrer"
        )
    }, [unitSearchResult.id])

    return (
        <UnitListRow title={""} details={""}>
            <div className={styles.actions}>
                <Secondary
                    onClick={onViewAsOwnerClick}
                    typeOf={"button"}
                    ariaLabel={"Click to view owner portal as owner"}
                >
                    View as Owner
                </Secondary>
                {unitSearchResult.active === ActiveStatus.Active &&
                    unitSearchResult.activeContract && (
                        <div>
                            <p>
                                <Secondary
                                    typeOf={"link"}
                                    onClick={onViewPublicListingClick}
                                    ariaLabel={
                                        "Click to view public listing on vacasa.com"
                                    }
                                >
                                    View Public Listing
                                </Secondary>
                            </p>
                        </div>
                    )}
                <p>
                    <Secondary
                        typeOf={"link"}
                        onClick={onManageUnitClick}
                        ariaLabel={"Click to edit unit on Admin"}
                    >
                        Manage Unit
                    </Secondary>
                </p>
            </div>
            <>
                <h3 className="type-heading-medium zero-margin">
                    {unitSearchResult.lastName}, {unitSearchResult.firstName}
                </h3>
                <p className="large">{unitSearchResult.name}</p>
                <p className="small">
                    {unitSearchResult.address}
                    <br />
                    {unitSearchResult.city}, {unitSearchResult.state}{" "}
                    {unitSearchResult.zip}
                </p>
                <p className={styles.badges}>
                    <Badge preset="default">
                        Unit ID: {unitSearchResult.id}
                    </Badge>

                    <Badge preset="default">
                        Unit Code: {unitSearchResult.unitCode}
                    </Badge>

                    <Badge preset="default">
                        Contact ID: {unitSearchResult.contactId}
                    </Badge>

                    {(unitSearchResult.active === ActiveStatus.Inactive ||
                        !unitSearchResult.activeContract) && (
                        <Badge preset="contract-inactive">Not Active</Badge>
                    )}
                    {unitSearchResult.active === ActiveStatus.Active &&
                        unitSearchResult.activeContract && (
                            <Badge preset="contract-active">Active</Badge>
                        )}
                    {unitSearchResult.active ===
                        ActiveStatus.MaintenanceOnly && (
                        <Badge preset="contract-maintenance">Maintenance</Badge>
                    )}
                    {unitSearchResult.terminated ===
                        TerminatedStatus.Terminated && (
                        <Badge preset="contract-terminated">Terminated</Badge>
                    )}
                    {unitSearchResult.terminated ===
                        TerminatedStatus.TerminationPending && (
                        <Badge preset="contract-maintenance">
                            Pending Termination
                        </Badge>
                    )}
                </p>
            </>
        </UnitListRow>
    )
}

export default SearchResultRow
