const profile = {
    title: "Profil",
    updateProfile: "Profil frissítése",
    update: "Frissítés",
    updateContractInfo: "Contract Information",
    updatedNotification: "Felhasználói profil frissítve",
    formFields: {
        name: "Név",
        phone: "Telefon",
        emailAddress: "E-mail-cím",
        password: "Current Password",
        country: "Ország",
        address: "Cím 1:",
        address2: "Cím 2:",
        city: "Város",
        state: "Állapot",
        zipCode: "Irányítószám",
        preferredLanguage: "Előnyben részesített nyelv",
        nameOnContract: "Name on Contract",
        mainPhone: "Main Phone",
    },
    placeholders: {
        firstName: "Utónév",
        lastName: "Vezetéknév",
        password: "password placeholder",
        address: "House number and street name",
        address2: "Apartment, suite, unit, etc.",
        city: "Enter your city",
        zipCode: "Zipcode",
        phone: "Phone number",
        select: "Select an option",
    },
    errors: {
        firstName: "Please enter your first name",
        lastName: "Please enter your last name",
        address: "Please enter your address",
        city: "Please enter your city",
        zipCode: "Please enter your zip code",
        phone: "Please enter your phone number",
        language: "Please select your preferred language",
        state: "Please select your state",
        country: "Please select a country",
        errorFetching:
            "We had trouble loading your page. If the problem persists, please try again later",
        errorPatching:
            "We had trouble updating your page. If the problem persists, please try again later",
        tryAgainButton: "Try Refreshing",
    },
    tooltips: {
        clickHere: "click here",
        password:
            "To change your password {{clickHere}}. You should receive instructions for resetting your password shortly. (You may need to check your spam folder.)",
        email: "The ability to change your email/username has been temporarily disabled. If you need help, please contact our support team at",
        phone: "To add or modify your multifactor cell phone number, {{clickHere}}. Note: this is used for authentication when you login.",
        accountInfo:
            "The contact information below has been pulled from the contract that you signed with Vacasa.",
        nameOnContract:
            "This name was pulled from the contract that you signed with Vacasa. It is not editable.",
    },
    employeeAlert:
        "<b>You are currently viewing this page as an employee.</b> We are unable to display the users first and last name from their IDP login account. Owners can view this information and edit it on this page.",
}

export default profile
