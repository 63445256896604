import { SHOW_MIN_RATE } from "constants/preferences.constants"
import { useOwnerUnitPreferencesMutation } from "hooks/owner-preferences"
import { FormattedMessage } from "react-intl"
import {
    trackAdjustMinRateClicked,
    trackMinRateRemindMeLaterClicked,
} from "services/segment/performance/performanceTracking"
import { ChangeView } from "./UnitMinRateNotification"
import styles from "./UnitMinRateNotification.module.scss"
import { useCurrentUnit } from "hooks/units"

export const AdjustView = (props: ChangeView): JSX.Element => {
    const { unitId } = useCurrentUnit()
    const ownerUnitPreferencesMutation = useOwnerUnitPreferencesMutation()
    return (
        <div className={styles.buttonContainer}>
            <button
                data-testid="button-adjust-minimum-rate"
                className={styles.adjustButton}
                onClick={() => {
                    trackAdjustMinRateClicked()
                    props.changeView("adjust")
                }}
            >
                <FormattedMessage
                    id="Performance.adjustMinRate"
                    defaultMessage="Adjust Your Minimum Rate"
                />
            </button>
            <button
                data-testid="button-remind-me-later"
                className={styles.remindButton}
                onClick={() => {
                    ownerUnitPreferencesMutation.mutate({
                        unitId: unitId ?? "",
                        id: SHOW_MIN_RATE,
                        value: false,
                    })
                    props.changeView("later")
                    trackMinRateRemindMeLaterClicked()
                }}
            >
                <FormattedMessage
                    id="Performance.remindMeLater"
                    defaultMessage="Remind Me Later"
                />
            </button>
        </div>
    )
}
