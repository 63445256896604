const topbar = {
    myRentals: "Le mie case vacanze",
    myTrips: "I miei viaggi",
    profile: "Profilo",
    changePassword: "Cambia password",
    logout: "Logout",
    switchAccounts: "Switch Accounts",
    selectAccount: "Select An Account",
    referral: "Refer a friend",
}

export default topbar
