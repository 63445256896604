import React from "react"
import { observer } from "mobx-react"
import classNames from "classnames"

import Loader from "../../lib/components/Loader"
import * as Button from "../../lib/components/Buttons/BaseButton"
import { ReactComponent as CheckCircle } from "../../assets/check-circle.svg"
import { ReactComponent as HomeIcon } from "../../assets/icon-home.svg"
import styles from "./settings.module.scss"

interface PaymentOptionProps {
    title: React.ReactNode
    subtitle: React.ReactNode
    description: React.ReactNode
    actionLabel: React.ReactNode
    actionHandler: () => void
    actionDisabled?: boolean | null
    actionLoading?: boolean | null
    selected?: boolean | null
    loading?: boolean
    address?: string | null
}

const PaymentOption = observer(
    ({
        title,
        subtitle,
        description,
        actionLabel,
        actionHandler,
        actionDisabled,
        actionLoading,
        loading,
        selected,
        address,
    }: PaymentOptionProps) => {
        return (
            <div
                className={classNames(
                    styles["method-props"],
                    !selected ? styles["method-props-unselected"] : ""
                )}
            >
                <h3 className={styles["large-heading"]}>
                    {title}
                    {!!selected && (
                        <CheckCircle
                            className={classNames(
                                styles["tick-icon"],
                                address ? styles["tick-icon-black"] : ""
                            )}
                        />
                    )}
                </h3>
                {loading ? (
                    <div className={styles.loaderContainer}>
                        <Loader />
                    </div>
                ) : (
                    <>
                        <span className={styles.subtitle}>{subtitle}</span>
                        <span className={styles.description}>
                            {description}
                        </span>
                        {address && actionDisabled ? (
                            <div className={styles.addressDiv}>
                                <div className={styles.homeIcon}>
                                    <HomeIcon />
                                </div>
                                {address}
                            </div>
                        ) : (
                            <div
                                className={styles["bottom-left-button"]}
                                data-testid={"enrolPaperCheckButton"}
                            >
                                <Button.Secondary
                                    id={"paymentOptionButton"}
                                    typeOf={"button"}
                                    onClick={actionHandler}
                                    ariaLabel={`Click this button to ${actionLabel}`}
                                    disabled={!!actionDisabled}
                                    isLoading={!!actionLoading}
                                >
                                    {actionLabel}
                                </Button.Secondary>
                            </div>
                        )}
                    </>
                )}
            </div>
        )
    }
)
export default PaymentOption
