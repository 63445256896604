import { ChangeEvent, FC, useEffect, useState } from "react"
import styles from "./Comments.module.scss"
import { NewButton } from "lib/components/Button"
import { TextareaAutosize } from "@material-ui/core"

import classnames from "classnames"
import { useIntl } from "react-intl"

interface CommentInputProps {
    handleCommentSubmitted: (commentText: string, resetOnSuccess: ()=>void  ) => void
    isLoadingComment: boolean
    isReopenMode?:boolean

}

export const CommentInput: FC<CommentInputProps> = (
    props: CommentInputProps
) => {
    const { handleCommentSubmitted,isLoadingComment,isReopenMode } = props
    const [commentText, setCommentText] = useState("")
    const [invalidComment, setInvalidComment] = useState(false)

    const ticketContent = document.querySelector(
        "[class^=maintenanceFlyoutUtil_maintenanceContent"
    )

    const onInputChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { value } = e.target
        setCommentText(value)
        if (ticketContent) {
            const scrolledToBottom =
                ticketContent.scrollHeight -
                    ticketContent.scrollTop -
                    ticketContent.clientHeight <=
                // Comment row margin 48px + comment note padding 24px = 72px
                72
            if (scrolledToBottom) {
                ticketContent.scrollTo(0, ticketContent.scrollHeight)
            }
        }
    }

    // const createCommentMutation = useTicketCommentMutation()

    const onClick = () => {

        handleCommentSubmitted(commentText, ()=>{
            setCommentText("")
        })
    }

    const intl = useIntl()

    useEffect(() => {
        if (commentText.length > 1000) {
            setInvalidComment(true)
        } else {
            setInvalidComment(false)
        }
    }, [commentText.length])

    const placeHolderText = isReopenMode ? 'To reopen this request, please submit a comment...':'Add a comment...'
    return (
        <div className={styles.commentInputWrapper}>
            <TextareaAutosize
                className={classnames(
                    styles.commentInput,
                    `${
                        invalidComment
                            ? styles.commentInputInvalid
                            : styles.commentInputValid
                    }`
                )}
                required
                value={commentText}
                id="commentText"
                onChange={onInputChange}
                placeholder={placeHolderText}
                minRows={isReopenMode? 4 :1 }
                maxRows={5}
            />
            {invalidComment && (
                <div className={styles.errorMessage}>
                    {intl.formatMessage({
                        id: "Maintenance.comments.overCharacterLengthLimit",
                        defaultMessage:
                            "The comment should not be over 1000 characters.",
                    })}
                </div>
            )}
            <NewButton
                variant="primary"
                icon="Send"
                className={classnames(
                    styles.sendButton,
                    `${invalidComment ? styles.sendButtonInvalid : ""}`
                )}
                disabled={!commentText || invalidComment}
                onClick={onClick}
                loading={isLoadingComment}
            />
        </div>
    )
}
