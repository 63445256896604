import { cloneDeep } from "lodash"

/**
 * Updates an array
 * - old items are removed
 * - existing items maintain their order
 * - new items are appended to the end of the array
 * @param existing
 * @param updates
 * @param predicate
 */
export function updateArray<T>(
    existing: T[],
    updates: T[],
    predicate: (a: T, b: T) => boolean
): T[] {
    const existingItems: T[] = []
    const newItems = cloneDeep(updates)
    existing.forEach(item => {
        const index = newItems.findIndex(update => predicate(item, update))
        if (index !== -1) {
            existingItems.push(item)
            newItems.splice(index, 1)
        }
    })

    return [...existingItems, ...newItems]
}
