import { AxiosError } from "axios"
import { useQuery, UseQueryResult } from "react-query"
import { useContactId } from "hooks/user"
import { fetchLegacyViewToken } from "./fetchLegacyViewToken"

const useLegacyViewToken = (): UseQueryResult<string, AxiosError> => {
    const contactId = useContactId()
    return useQuery(
        ["legacy-view-token", contactId],
        ({ signal }) =>
            fetchLegacyViewToken({ contactId: contactId as string, signal }),
        { enabled: contactId !== null }
    )
}

export { useLegacyViewToken }
