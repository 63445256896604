import React, { FunctionComponent, CSSProperties } from "react"
import classnames from "classnames"

import styles from "./OnboardingPanel.module.scss"

interface OnboardingPanelProps extends React.HTMLAttributes<HTMLElement> {
    shadowSize?: string
    borderRadius?: string
    popover?: boolean
    arrow?: boolean
    styleOverride?: CSSProperties
    size?: string
    classes?: string[]
    sideOfPage?: string
    topOrBottom?: string
}

const OnboardingPanel: FunctionComponent<OnboardingPanelProps> = ({
    shadowSize,
    borderRadius,
    popover,
    arrow,
    styleOverride,
    size,
    classes,
    sideOfPage,
    topOrBottom,
    children,
    ...rest
}): JSX.Element => {
    const classNames = classnames(
        styles.panel,
        shadowSize ? styles[`shadow--${shadowSize}`] : null,
        borderRadius ? styles[`border-radius--${borderRadius}`] : null,
        popover && !arrow ? styles[`panel--popover`] : null,
        popover && arrow ? styles[`panel--popover-arrow`] : null,
        popover && size ? styles[`panel--popover-${size}`] : null,
        sideOfPage && topOrBottom
            ? styles[`panel--tooltip-${sideOfPage}-${topOrBottom}-arrow`]
            : null,
        classes
    )

    return (
        <div className={classNames} style={styleOverride} {...rest}>
            {children}
        </div>
    )
}

export default OnboardingPanel
