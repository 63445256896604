const survey = {
    title: "CENY A PŘÍJMY",
    subTitle: "Nastavte strategii příjmů",
    surveyFollowUp: "Krok {step} z {totalSteps}",
    errorTitle: "Průzkum není k dispozici",
    loadingErrorMessage: "Je nám líto, průzkum se nepodařilo načíst. Zkuste to prosím později znovu.",
    submitErrorMessage: "Je nám líto, v tuto chvíli nebylo možné odeslat váš průzkum. Zkuste to prosím později znovu.",
    tanksTitle: "Gratulujeme k nastavení vaší strategie příjmů!",
    tanksMessage: "Náš tým pracuje na implementaci vašich požadavků."
}

export default survey
