import classNames from "classnames"
import styles from "./WeekHeader.module.scss"

type WeekHeaderProps = {
    /**
     * A localised string for the day of the week in a short form
     * for example in english "Su", "Mo", "Tu"
     *
     */
    dayOfWeek: string
    /**
     * TODO
     */
    daySize: number
}

/**
 * The day of the week header for a calendar
 *
 * Tied closely to the usage of the external library react-dates
 */
const WeekHeader = ({ dayOfWeek, daySize }: WeekHeaderProps): JSX.Element => (
    <span
        className={classNames(styles.weekHeader, "type-heading-extra-small")}
        style={{ width: daySize, height: daySize }}
    >
        {dayOfWeek}
    </span>
)

export { WeekHeader }
