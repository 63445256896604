import { useCurrentUnit } from "hooks/units"
import { FormattedMessage } from "react-intl"
import LoggingService from "services/logging/logging.service"
import {
    trackLinenServiceNeedHelpLomEmailClicked,
    trackLinenServiceNeedHelpLomPhoneClicked,
} from "services/segment/homeInfo/homeInfoTracking"
import { formatPhone } from "utils/phone/formatPhone"

const NeedHelp = ({ testId }: { testId?: string }): JSX.Element => {
    const { unit } = useCurrentUnit()

    if (!unit) {
        return <></>
    }

    const lomInfo = unit.attributes.localOperationsManager
    const formattedPhone =
        lomInfo && lomInfo.phone ? formatPhone(lomInfo.phone) : undefined

    return (
        <div data-testid={testId} className="help-block panel">
            <div className="content-zone">
                <h4 className="type-heading-small">
                    <FormattedMessage
                        id="LinenService"
                        defaultMessage="Need help?"
                    />
                </h4>
                <div>
                    <p className="type-body-small">
                        <FormattedMessage
                            id="LinenService.helpPanel.contactSentenceIfQuestions"
                            defaultMessage="Please reach out to your contact, {contact}, if you have any questions or wish to update your plan."
                            values={{
                                contact: lomInfo?.firstName,
                            }}
                        />
                    </p>
                    <br />
                    <p className="type-body-small">
                        {lomInfo?.email && (
                            <a
                                href={"mailto:" + lomInfo.email}
                                onClick={() => {
                                    LoggingService.log({
                                        message: `LOM Info Email clicked`,
                                    })
                                    trackLinenServiceNeedHelpLomEmailClicked(
                                        lomInfo?.email
                                    )
                                }}
                            >
                                {lomInfo.email}
                            </a>
                        )}
                        {formattedPhone && (
                            <span>
                                {" "}
                                <a
                                    href={"tel:" + formattedPhone}
                                    onClick={() => {
                                        LoggingService.log({
                                            message: `Phone number clicked`,
                                        })
                                        trackLinenServiceNeedHelpLomPhoneClicked(
                                            formattedPhone
                                        )
                                    }}
                                >
                                    {" "}
                                    {formattedPhone}
                                </a>
                            </span>
                        )}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default NeedHelp
