const topbar = {
    myRentals: "Bérleményeim",
    myTrips: "Utazásaim",
    profile: "Profil",
    changePassword: "Jelszó módosítása",
    logout: "Kijelentkezés",
    switchAccounts: "Switch Accounts",
    selectAccount: "Select An Account",
    referral: "Refer a friend",
}

export default topbar
