import {
    JSONAPIDocument,
    RoomOptions as RoomOptionsAPI,
} from "@vacasa/owner-api-models"
import { authAxios } from "api/authAxios"
import { RoomOptions } from "models/RoomOption"

export const fetchUnitRoomOptions = ({
    signal,
}: {
    signal?: AbortSignal
}): Promise<RoomOptions> =>
    authAxios
        .get<JSONAPIDocument<RoomOptionsAPI>>("v1/units/room-options", {
            signal,
        })
        .then(response => {
            return {
                bedSizes: response.data.data.attributes.bedSizes.sort((a, b) =>
                    a.name.localeCompare(b.name)
                ),
                bedTypes: response.data.data.attributes.bedTypes.sort((a, b) =>
                    a.name.localeCompare(b.name)
                ),
                roomTypes: response.data.data.attributes.roomTypes
                    .filter(roomType => roomType.id !== 10)
                    .sort((a, b) => {
                        // Put "Other" last
                        if (a.name === "Other" && a.name !== b.name) return 1
                        if (b.name === "Other" && b.name !== a.name) return -1
                        return a.name.localeCompare(b.name)
                    }),
            }
        })
