import { OwnerHoldType } from "@vacasa/owner-api-models"
import { useHousekeepingRequired } from "hooks/housekeeping-required/useHousekeepingRequired"
import { OnOffSwitch } from "lib/components/Toggle/OnOffSwitch"
import React, { useEffect, useRef, useState } from "react"
import { FormattedMessage } from "react-intl"
import { isDefined } from "utils/common"
import { Label } from ".."
import { InfoSubpanel } from "../../../../../../lib/components/FlyOut/Holds/InfoSubpanel/InfoSubpanel"
import Loader from "../../../../../../lib/components/Loader"
import {
    ActiveStatus,
    getUnitStatusTypeByStatusNumber,
} from "../../../../../../utils/unit/unitStatus/unitStatus"
import styles from "./HousekeepingSelect.module.scss"
import { isHousekeepingChecked } from "utils/reservations/reservationsUtil"

interface UnitAddress {
    state: string
}

interface HousekeepingSelectProps {
    unitStatusNumber: ActiveStatus
    housekeeping?: boolean
    acquisitionID: number | null | undefined
    holdType: OwnerHoldType | null
    unitAddress: UnitAddress
    selectChangeHandler: (checked: boolean) => void
    show?: boolean
    isGuestworksUnit: boolean
    handleHousekeepingAllowed: (value: boolean) => void
    handleHousekeepingRequired: (value: boolean) => void
}

const isHouseKeepingRequired = (
    reservationHoldType: OwnerHoldType | null,
    selectedHoldType: OwnerHoldType | null
): boolean => {
    return reservationHoldType === selectedHoldType
}

export const HousekeepingSelect: React.FunctionComponent<
    HousekeepingSelectProps
> = ({
    unitStatusNumber,
    housekeeping,
    holdType,
    acquisitionID,
    unitAddress,
    selectChangeHandler,
    show,
    isGuestworksUnit,
    handleHousekeepingAllowed,
    handleHousekeepingRequired,
}: HousekeepingSelectProps) => {
    const initialReservationHoldType = useRef(holdType)
    const initialReservationHousekeeping = useRef(housekeeping)
    const unitStatus = getUnitStatusTypeByStatusNumber(unitStatusNumber)
    const [housekeepingRequired, setHousekeepingRequired] = useState(false)
    const [housekeepingAllowed, setHousekeepingAllowed] = useState(false)

    const housekeepingRequiredQuery = useHousekeepingRequired({
        acquisitionID,
        holdType,
        state: unitAddress.state,
        unitStatus,
        isGuestworksUnit,
    })
    useEffect(() => {
        if (housekeepingRequiredQuery.isSuccess) {
            const required =
                isHouseKeepingRequired(
                    initialReservationHoldType.current,
                    holdType
                ) && isDefined(initialReservationHousekeeping.current)
                    ? initialReservationHousekeeping.current
                    : housekeepingRequiredQuery.data.attributes.required

            selectChangeHandler(required)
            setHousekeepingRequired(
                housekeepingRequiredQuery.data.attributes.required
            )
            setHousekeepingAllowed(
                housekeepingRequiredQuery.isSuccess
                    ? housekeepingRequiredQuery.data.attributes.allowed
                    : false
            )
        }
    }, [
        holdType,
        housekeepingRequiredQuery.data,
        housekeepingRequiredQuery.isSuccess,
        selectChangeHandler,
    ])

    useEffect(() => {
        handleHousekeepingAllowed(housekeepingAllowed)
        handleHousekeepingRequired(housekeepingRequired)
    }, [
        handleHousekeepingAllowed,
        handleHousekeepingRequired,
        housekeepingAllowed,
        housekeepingRequired,
    ])

    if (!show) return null

    const cleaningFee = housekeepingRequiredQuery.data?.attributes.cleaningFee

    return housekeepingRequiredQuery.isLoading ? (
        <div data-testid="housekeeping-required-loader">
            <Loader overlay={true} />
        </div>
    ) : (
        <div>
            <div className={styles["housekeeping-select-primary"]}>
                <Label>
                    <FormattedMessage
                        id="CalendarPage.flyout.housekeeping"
                        defaultMessage="Housekeeping"
                    />
                </Label>
                {housekeepingAllowed && !housekeepingRequired && (
                    <span
                        className={styles.housekeepingToggle}
                        data-testid="housekeeping-toggle-group"
                    >
                        <OnOffSwitch
                            checked={isHousekeepingChecked({
                                housekeeping: !!housekeeping,
                                housekeepingAllowed,
                                housekeepingRequired,
                            })}
                            onChange={checked => selectChangeHandler(checked)}
                            disabled={
                                housekeepingRequired || !housekeepingAllowed
                            }
                        />
                    </span>
                )}
            </div>
            <div className={styles["housekeeping-select-secondary"]}>
                <InfoSubpanel className="text-dusk">
                    {housekeepingRequired &&
                        (cleaningFee ? (
                            <FormattedMessage
                                id="CalendarPage.flyout.housekeepingRequiedWithCleaningFee"
                                // eslint-disable-next-line no-template-curly-in-string
                                defaultMessage="This owner hold requires a post-stay clean, which will be billed to your account after the stay in the amount of <strong>${amount}.</strong>"
                                values={{
                                    amount: cleaningFee.toFixed(2),
                                    strong: chunks => <strong>{chunks}</strong>,
                                }}
                            />
                        ) : (
                            <FormattedMessage
                                id="CalendarPage.flyout.housekeepingRequied"
                                defaultMessage="In order to maintain the highest quality guest experience, housekeeping is required for stays with overnight guests."
                            />
                        ))}
                    {!housekeepingRequired && !housekeepingAllowed && (
                        <FormattedMessage
                            id="CalendarPage.flyout.housekeepingNotAllowed"
                            defaultMessage="Housekeeping not available. You will be responsible for cleaning and preparing the home for the next guests."
                        />
                    )}
                    {!housekeepingRequired &&
                        housekeepingAllowed &&
                        housekeeping &&
                        (cleaningFee ? (
                            <FormattedMessage
                                id="CalendarPage.flyout.yesHousekeepingSubtitleWithCleaningFee"
                                // eslint-disable-next-line no-template-curly-in-string
                                defaultMessage="A Vacasa housekeeping visit will be scheduled after this hold and billed to your account in the amount of <strong>${amount}.</strong>"
                                values={{
                                    amount: cleaningFee.toFixed(2),
                                    strong: chunks => <strong>{chunks}</strong>,
                                }}
                            />
                        ) : (
                            <FormattedMessage
                                id="CalendarPage.flyout.yesHousekeepingSubtitle"
                                defaultMessage="A Vacasa housekeeping visit will be scheduled after this hold."
                            />
                        ))}
                    {!housekeepingRequired &&
                        housekeepingAllowed &&
                        !housekeeping && (
                            <FormattedMessage
                                id="CalendarPage.flyout.noHousekeepingSubtitle"
                                defaultMessage="You will be responsible for preparing the home for the next guests."
                            />
                        )}
                </InfoSubpanel>
            </div>
        </div>
    )
}
