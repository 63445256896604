import { Info } from "react-feather"
import { Tooltip } from "../../../lib/components/Tooltip/Tooltip"

import styles from "./ExtraInformationTooltip.module.scss"

/**
 * Display a tooltip with an info icon
 */
const ExtraInformationTooltip = ({
    title,
    children,
}: {
    title: string
    /**
     * The body content of the tooltip
     */
    children: string | JSX.Element
}): JSX.Element => (
    <Tooltip title={title} body={children}>
        <div className={styles.tooltipContent}>
            <Info className={styles.icon} aria-label="More information" />
        </div>
    </Tooltip>
)

export { ExtraInformationTooltip }
