import { JSONAPIResource, UserPreference } from "@vacasa/owner-api-models"
import { REFERRAL_NAV_CARD_DISMISSED } from "constants/preferences.constants"
import { useOwnerFeatureFlag } from "hooks/owner-feature-flag"
import {
    useOwnerPreferences,
    useOwnerPreferencesMutation,
} from "hooks/owner-preferences"
import { useCallback, useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import { isFeatureFlagEnabled } from "utils/feature-flag"
import styles from "./OwnerReferralCard.module.scss"

import { ReactComponent as Close } from "assets/close.svg"
import { ReactComponent as ArrowRight } from "assets/icon-arrow-right.svg"
import { ReactComponent as ReferralHand } from "assets/referral-hand.svg"
import {
    trackReferralCardClicked,
    trackReferralCardDismissed,
} from "services/segment/referral/referralTracking"

const isDataLoading = (requests: boolean[]) => {
    return requests.some(loading => loading)
}

const hasOwnerDismissedReferralCard = (
    preference?: JSONAPIResource<UserPreference>
): boolean => {
    if (!preference || preference.attributes.value === null) return false
    return true
}

const OwnerReferralCard = ({
    showReferralModal,
}: {
    showReferralModal: (shouldShowReferralModal: boolean) => void
}) => {
    const { mutate } = useOwnerPreferencesMutation()
    const ownerPreferences = useOwnerPreferences()
    const ownerReferralFeatureFlag = useOwnerFeatureFlag("owner-referrals")
    const isEnabled = isFeatureFlagEnabled(ownerReferralFeatureFlag)
    const isLoading = isDataLoading([
        !ownerReferralFeatureFlag.clientReady,
        ownerPreferences.isLoading,
    ])
    const [shouldDismiss, setShouldDismiss] = useState(false)

    useEffect(() => {
        if (!shouldDismiss) return

        mutate({
            id: REFERRAL_NAV_CARD_DISMISSED,
            value: true,
        })
    }, [shouldDismiss, mutate])

    const onLearnMoreClick = useCallback(() => {
        trackReferralCardClicked()
        showReferralModal(true)
    }, [showReferralModal])

    const onDismissClick = () => {
        trackReferralCardDismissed()
        setShouldDismiss(true)
    }

    const hasDimissedCard = hasOwnerDismissedReferralCard(
        (ownerPreferences.data ?? []).find(
            ownerPreference =>
                ownerPreference.id === REFERRAL_NAV_CARD_DISMISSED
        )
    )

    if (isLoading || !isEnabled || shouldDismiss || hasDimissedCard) return null

    return (
        <div className={styles["orc"]}>
            <button
                className={styles["orc__close-btn"]}
                onClick={onDismissClick}
                title="Close"
                type="button"
            >
                <Close />
            </button>
            <h3 className={styles["orc__title"]}>
                <FormattedMessage
                    id="Navigation.ownerReferralCard.title"
                    defaultMessage="Refer a friend"
                />
            </h3>
            <div className={styles["orc__content"]}>
                <ReferralHand className={styles["orc__icon"]} />
                <p className={styles["orc__copy"]}>
                    <FormattedMessage
                        id="Navigation.ownerReferralCard.body"
                        defaultMessage="You get $500, they get $500."
                    />
                </p>
            </div>
            <div className={styles["orc__content"]}>
                <button
                    className={styles["orc__action"]}
                    onClick={onLearnMoreClick}
                    data-testid="learn-more"
                >
                    <FormattedMessage
                        id="Navigation.ownerReferralCard.action"
                        defaultMessage="Learn more"
                    />{" "}
                    <ArrowRight className={styles["orc__action-arrow"]} />
                </button>
            </div>
        </div>
    )
}

export default OwnerReferralCard
