import { AxiosError } from "axios"
import { useCallback } from "react"
import {
    useInfiniteQuery,
    UseInfiniteQueryResult,
    useQueryClient,
} from "react-query"
import { fetchReservations, ReservationsResponse } from "./fetchReservations"
const RESERVATIONS_QUERY_KEY = "reservations" as const

/** The inclusive parameter (default: true on owner-api)
 * checks whether the startDate or endDate should be included in the return data from the api request
 * e.g. if a reservation is dated 2021-01-01 to 2021-01-05
 * and the startDate is 2021-01-03, the reservation will be returned if inclusive is true */
type UseReservationsParams = {
    contactId: string
    unitId: string
    pageLimit: number
    startDate?: Date
    endDate?: Date
    filterCancelledReservations?: number
    unitRelationshipId?: string
    inclusive?: boolean
    sort?: "asc" | "desc"
}

const queryFn =
    (params: UseReservationsParams) =>
    ({ pageParam }: { pageParam?: number }) =>
        fetchReservations({
            ...params,
            pageNumber: pageParam,
        })

const useReservations = (
    params: UseReservationsParams
): UseInfiniteQueryResult<ReservationsResponse, AxiosError> =>
    useInfiniteQuery(
        [
            RESERVATIONS_QUERY_KEY,
            params.contactId,
            params.unitId,
            params.pageLimit,
            params.startDate,
            params.endDate,
            params.filterCancelledReservations,
            params.unitRelationshipId,
            params.inclusive,
            params.sort,
        ],
        queryFn(params),
        {
            getNextPageParam: lastPage => {
                if (lastPage === undefined) {
                    return 1
                }
                const isLastPage =
                    lastPage.meta.currentPage >= lastPage.meta.lastPage
                if (isLastPage) return undefined
                return lastPage.meta.currentPage + 1
            },
            getPreviousPageParam: firstPage => {
                if (!firstPage) {
                    return undefined
                }
                const isFirstPage = firstPage.meta.currentPage <= 1
                if (isFirstPage) return undefined
                return firstPage.meta.currentPage - 1
            },
            refetchInterval: 1000 * 60 * 30, // 30 mins
            staleTime: 1000 * 60 * 30, // 30 mins,
        }
    )

const useInvalidateReservations = (): (() => Promise<void>) => {
    const queryClient = useQueryClient()
    return useCallback(
        () => queryClient.invalidateQueries([RESERVATIONS_QUERY_KEY]),
        [queryClient]
    )
}
export { useReservations, useInvalidateReservations }
