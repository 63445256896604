import { ArrowDown } from "assets/icons"
import React from "react"
import styles from "./YourMinRate.module.scss"

export const YourMinRate: React.FC = () => {
    return (
        <div className={styles.yourMinRate}>
            <div className={styles.title}>Your Minimum Rate</div>
            <ArrowDown className={styles.arrow} />
        </div>
    )
}
