import { datadogRum } from "@datadog/browser-rum"

class RealUserMonitoringService {
    initialized = false

    public init(opts: {
        environment?: string
        datadog?: {
            applicationId: string
            clientToken: string
        }
        release?: string
        sessionSampleRate: number
    }): void {
        if (opts.datadog) {
            datadogRum.init({
                applicationId: opts.datadog.applicationId,
                clientToken: opts.datadog.clientToken,
                site: "datadoghq.com",
                service: "owner-portal-web",
                env: opts.environment,
                sessionSampleRate: opts.sessionSampleRate,
                trackUserInteractions: true,
                version: opts.release,
            })
            this.initialized = true
        }
    }

    public setUser(
        idpSubID: string,
        legacyLoginId: string,
        contactIds: string[],
        loginType: string
    ): void {
        if (this.initialized) {
            datadogRum.setUser({
                id: idpSubID,
                legacyLoginId: legacyLoginId,
                contactIds: contactIds,
                loginType: loginType,
            })
        }
    }

    public clearUser(): void {
        if (this.initialized) {
            datadogRum.clearUser()
        }
    }
}

const realUserMonitoringService = new RealUserMonitoringService()

export default realUserMonitoringService
