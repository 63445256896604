import { Size } from "hooks/common/useElementSize"
import moment from "moment"
import {
    DayPickerRangeControllerShape,
    isInclusivelyAfterDay,
} from "react-dates"
import { HORIZONTAL_ORIENTATION } from "../../../../Constants"

export type CalendarControllerProps = {
    initialStartDate: Date | null
    initialEndDate: Date | null
    showInputs?: boolean
    blockAllDays: boolean
    handleDatesChange?: (
        startDate: Date | null | string,
        endDate: Date | null | string
    ) => void
    showLegend?: boolean
    maxNumMonths?: number // currently only supports up to 2 months
    onCalendarResize?: (size: Size) => void
}

export const DEFAULT_CALENDAR_PROPS: Partial<
    DayPickerRangeControllerShape & CalendarControllerProps
> = {
    initialStartDate: null,
    initialEndDate: null,
    showInputs: true,
    maxNumMonths: 2,

    // day presentation and interaction related props
    minimumNights: 1,

    isDayBlocked: () => false,
    isOutsideRange: day => !isInclusivelyAfterDay(day, moment()),
    isDayHighlighted: () => false,
    enableOutsideDays: false,

    // calendar presentation and interaction related props
    orientation: HORIZONTAL_ORIENTATION,
    withPortal: false,
    numberOfMonths: 2,
    keepOpenOnDateSelect: false,
    isRTL: false,
    noBorder: true,
}

export const OMITTED_PROPS = [
    "initialStartDate",
    "initialEndDate",
    "showInputs",
    "blockAllDays",
    "languageCode",
    "ownerCurrency",
    "textAssets",
    "handleDatesChange",
    "pillColorLegend",
    "showLegend",
    "maxNumMonths",
    "onCalendarResize",
    "children",
]
