import { JSONAPIResource, Unit } from "@vacasa/owner-api-models"
import { useUnitState } from "contexts/unit"
import { useUnits } from "hooks/units"
import Select from "lib/components/Select"
import { SelectSection } from "lib/components/Select/Select"
import { observer } from "mobx-react"
import { useCallback } from "react"
import { unitToSelectOptions } from "utils/select"
import { isActiveUnit } from "utils/unit"

const getUnitSelectSections = (
    units: JSONAPIResource<Unit>[]
): SelectSection[] => {
    const sections: SelectSection[] = []

    const activeUnits = units.filter(isActiveUnit)

    const inactiveUnits = units.filter(unit => !isActiveUnit(unit))

    const activeUnitSection = {
        title: `Active (${activeUnits.length})`,
        items: unitToSelectOptions(activeUnits),
    }

    sections.push(activeUnitSection)

    if (inactiveUnits.length > 0) {
        sections.push({
            title: `Inactive (${inactiveUnits.length})`,
            items: unitToSelectOptions(inactiveUnits),
        })
    }
    return sections
}

export const UnitSelectWrapper = observer(() => {
    const unitsQuery = useUnits()
    const { unitId, units, setUnit } = useUnitState()
    const unit = units[unitId ?? ""]
    const onUnitChanged = useCallback(
        (unitId: string) => {
            setUnit(unitId)
        },
        [setUnit]
    )

    return (
        <>
            {unit && unitsQuery.isSuccess && (
                <Select
                    onChange={onUnitChanged}
                    sections={getUnitSelectSections(unitsQuery.data)}
                    searchShow
                    searchPlaceholder="Search Units"
                    showItemImage
                    value={String(unit.id)}
                />
            )}
        </>
    )
})
