import { FormattedMessage } from "react-intl"
import { FC, useCallback } from "react"

interface Props {
    view: ViewType
    changeView: (showDetails: boolean, id: string) => void
    viewKey: string
}

export enum ViewType {
    Details = "details",
    Summary = "summary",
}

const UnitSummaryViewButtons: FC<Props> = ({ changeView, view, viewKey }) => {
    const onClick = useCallback(
        (showDetails: boolean) => {
            changeView(showDetails, viewKey)
        },
        [changeView, viewKey]
    )

    return (
        <div className="col-lg-4 float-lg-left">
            <div className="unit-details-switch-view-container btn-block unit-summary-switch-view-container">
                <button
                    className={
                        view === ViewType.Details
                            ? "unit-summary-details-view btn btn-default active"
                            : "unit-summary-details-view btn btn-default"
                    }
                    onClick={() => onClick(true)}
                >
                    <FormattedMessage
                        id="Statements.details"
                        defaultMessage="Details"
                    />
                </button>
                <button
                    className={
                        view === ViewType.Summary
                            ? "unit-summary-details-view btn btn-default active"
                            : "unit-summary-details-view btn btn-default"
                    }
                    onClick={() => onClick(false)}
                >
                    <FormattedMessage
                        id="Statements.summary"
                        defaultMessage="Summary"
                    />
                </button>
            </div>
        </div>
    )
}

export default UnitSummaryViewButtons
