import Select from "lib/components/Select"
import { SelectItem } from "lib/components/Select/Select"
import { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { trackGuestReviewFilterSelection } from "services/segment/performance/performanceTracking"
import styles from "./RatingFilter.module.scss"

interface RatingFilterProps {
    reviewCounts: number[]
    updateFilter: (filter: string) => void
    filter: string
}

const RatingFilter: React.FC<RatingFilterProps> = props => {
    const { reviewCounts, updateFilter, filter } = props
    const intl = useIntl()
    const allRatings = intl.formatMessage({
        id: "Performance.reviews.allRatings",
        defaultMessage: "All Ratings",
    })
    const ratingOptions: SelectItem[] = []
    reviewCounts.forEach((count, index) => {
        ratingOptions.push({
            text: index === 0 ? "1 Star" : `${(index + 1).toString()} Stars`,
            value: (index + 1).toString(),
            count: count.toString(),
        })
    })
    ratingOptions.push({ text: allRatings, value: allRatings })
    const [option, setOption] = useState(allRatings)

    useEffect(() => {
        setOption(filter)
    }, [filter])

    return (
        <div className={styles.ratingFilter}>
            <div
                className={
                    option === allRatings
                        ? styles.allRatings
                        : styles.filterSelected
                }
                data-testid="ratings-filter-button"
            >
                <Select
                    onChange={value => {
                        setOption(value)
                        updateFilter(value)
                        trackGuestReviewFilterSelection(value)
                    }}
                    showRatingIcon
                    sections={[
                        {
                            title: intl.formatMessage({
                                id: "Performance.reviews.filterByRating",
                                defaultMessage: "Filter by Rating",
                            }),
                            items: ratingOptions.reverse(),
                        },
                    ]}
                    value={option}
                />
            </div>
            {option !== allRatings && (
                <button
                    type="button"
                    className={styles.clearBtn}
                    onClick={() => {
                        setOption(allRatings)
                        updateFilter(allRatings)
                    }}
                >
                    Clear
                </button>
            )}
        </div>
    )
}

export { RatingFilter }
