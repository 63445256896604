import classnames from "classnames"
import { Property } from "react/node_modules/csstype"

import styles from "./Stack.module.css"

type StackProps = React.ComponentProps<"div"> & {
    justify?: Property.JustifyContent
    align?: Property.AlignItems
    flex?: Property.Flex
    gap?: Property.Gap
}

const Stack: React.FC<StackProps> = ({
    justify,
    align,
    flex,
    gap,
    className,
    ...rest
}) => (
    <div
        {...rest}
        className={classnames(styles.stack, className)}
        style={{ justifyContent: justify, alignItems: align, flex, gap }}
    />
)

export { Stack }
