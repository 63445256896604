import { useQuery } from "react-query"
import { useContactId } from "hooks/user"
import { fetchW9TaxSubmission } from "./fetchW9TaxSubmission"
import type { W9TaxSubmissionResponse } from "@vacasa/owner-api-models/src/legacy/W9TaxSubmission"

import type { UseQueryResult } from "react-query"
import { AxiosError } from "axios"

const useW9TaxSubmission = (): UseQueryResult<
    W9TaxSubmissionResponse["data"],
    AxiosError
> => {
    const contactId = useContactId()
    return useQuery(
        ["w9-tax-submission", contactId],
        ({ signal }) =>
            fetchW9TaxSubmission(
                // type safe to be a string be enabled check
                {
                    // type safe to be a string be enabled check
                    contactId: contactId as string,
                    signal,
                }
            ),
        {
            staleTime: 24 * 60 * 60 * 1000, // 1 day (24 hours)
            enabled: contactId !== null,
        }
    )
}

export { useW9TaxSubmission }
