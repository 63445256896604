import { UseMutationOptions, UseMutationResult, useMutation } from "react-query"
import { VerifyTokenResponse, postVerifyToken } from "./postVerifyToken"
import { AxiosError } from "axios"
import { isCancelledRequestError } from "utils/error/error"
import LoggingService from "services/logging/logging.service"
import storageService from "services/storage.service"
import { LAST_LOGIN_SESSION, LOGIN_TYPE, USER_ID } from "Constants"
import { getDecodedToken, setToken } from "utils/auth"
import realUserMonitoringService from "services/real-user-monitoring/realUserMonitoring.service"
import segmentService from "services/segment/segment.service"
import { handleError } from "utils/auth/idp"
import { updateSessionConfig } from "utils/session"

type Error = {
    code: string
    detail: string
    status: string
}

const useVerifyTokenMutation = (
    options?: UseMutationOptions<
        VerifyTokenResponse,
        AxiosError<{ errors: Error[] }>,
        string,
        void
    >
): UseMutationResult<
    VerifyTokenResponse,
    AxiosError<{ errors: Error[] }>,
    string,
    void
> => {
    return useMutation<
        VerifyTokenResponse,
        AxiosError<{ errors: Error[] }>,
        string,
        void
    >(
        (token: string): Promise<VerifyTokenResponse> =>
            postVerifyToken({
                token,
            }),
        {
            ...options,
            onSuccess: response => {
                const data = response.data

                storageService.localStorage.setItem(
                    USER_ID,
                    data.userId.toString()
                )
                storageService.localStorage.setItem(LOGIN_TYPE, data.loginType)

                // // Value of the previous login session of the user
                // // The current login session is called last-login and is stored in user-preferences
                storageService.localStorage.setItem(
                    LAST_LOGIN_SESSION,
                    data.lastLogin
                )

                updateSessionConfig({
                    contactIds: data.contactIds.map(String),
                })

                setToken(data.token)
                LoggingService.log({
                    message: `Login successful for user with userId:${data.userId}, loginType:${data.loginType}, contactIds:${data.contactIds}`,
                })

                const decodedToken = getDecodedToken(data.token)

                if (decodedToken.sub) {
                    segmentService.identify(decodedToken.sub, data.loginType)
                    realUserMonitoringService.setUser(
                        decodedToken.sub,
                        data.userId.toString(),
                        data.contactIds.map(contactId => contactId.toString()),
                        data.loginType
                    )
                }
            },
            onError: error => {
                if (!isCancelledRequestError(error)) {
                    LoggingService.error({
                        message: "Verify token failed",
                        error,
                        tags: {
                            userID: "",
                        },
                    })
                }

                const ownerAPIResponseErrors =
                    error.response?.data?.errors ?? null

                const errorDescription = ownerAPIResponseErrors
                    ? ownerAPIResponseErrors[0]?.code
                    : null

                handleError(
                    "Failed to verify IdP token",
                    errorDescription ?? "Owner API verify token failed",
                    null,
                    null
                )
            },
        }
    )
}

export { useVerifyTokenMutation }
