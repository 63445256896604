import { HOMEOWNER_ACCOUNTS_EMAIL } from "Constants"
import React, { useEffect } from "react"
import { FormattedMessage } from "react-intl"
import { Link } from "react-router-dom"
import { formatPhone } from "../../../../utils/phone/formatPhone"
import { FAQ } from "./FAQ"

export const WhenDoIGetMyStatement = (): JSX.Element => (
    <FAQ trackOption="When do I get my statement, and what’s on it?">
        <FAQ.Question>
            <FormattedMessage
                id="Statements.faq.whenDoIGetMyStatement.question"
                defaultMessage="When do I get my statement, and what’s on it?"
            />
        </FAQ.Question>
        <FAQ.Answer>
            <p>
                <FormattedMessage
                    id="Statements.faq.whenDoIGetMyStatement.answer.paragraphs.0"
                    defaultMessage="You’ll receive your statement for each month around the 10th of the following month. For example, your statement for February income and expenses will be ready on about March 10."
                />
            </p>
            <p>
                <FormattedMessage
                    id="Statements.faq.whenDoIGetMyStatement.answer.paragraphs.2"
                    defaultMessage="Your statement includes data and revenue for all reservations that checked out during the month. So the statement you receive in March lists income from all reservations that checked out sometime in February (even if a reservation started in the prior month, January in this case)."
                />
            </p>
        </FAQ.Answer>
    </FAQ>
)

export const HowDoIGetPaidFaq = (): JSX.Element => {
    return (
        <FAQ trackOption="How do I get paid?">
            <FAQ.Question>
                <FormattedMessage
                    id="Statements.faq.howDoIGetPaid.question"
                    defaultMessage="How do I get paid?"
                />
            </FAQ.Question>
            <FAQ.Answer>
                <p>
                    <FormattedMessage
                        id="Statements.faq.howDoIGetPaid.answer"
                        defaultMessage="The default payment method is a paper check mailed to you each month. If you prefer direct deposit, you can enroll by updating your <a>Payment Settings</a>."
                        values={{
                            a: (chunks: React.ReactNode) => (
                                <Link to="/statements/settings" target="_blank">
                                    {chunks}
                                </Link>
                            ),
                        }}
                    />
                </p>
            </FAQ.Answer>
        </FAQ>
    )
}

export const WhenWillMyPaymentArrive = (): JSX.Element => (
    <FAQ trackOption="When will my payment arrive?">
        <FAQ.Question>
            <FormattedMessage
                id="Statements.faq.whenWillMyPaymentArrive.question"
                defaultMessage="When will my payment arrive?"
            />
        </FAQ.Question>
        <FAQ.Answer>
            <p>
                <FormattedMessage
                    id="Statements.faq.whenWillMyPaymentArrive.answer.paragraphs.0"
                    defaultMessage="The timing depends on the payment method you’ve chosen. After you receive your monthly statement, you can usually expect to receive payment in:"
                />
            </p>
            <ul>
                <li>
                    <FormattedMessage
                        id="Statements.faq.whenWillMyPaymentArrive.answer.list.0"
                        defaultMessage="3 to 5 business days for direct deposit"
                    />
                </li>
                <li>
                    <FormattedMessage
                        id="Statements.faq.whenWillMyPaymentArrive.answer.list.1"
                        defaultMessage="Up to 10 business days for paper checks"
                    />
                </li>
            </ul>
        </FAQ.Answer>
    </FAQ>
)

export const WhatIsMyReserveBalanceFaq = ({
    open,
}: {
    open: boolean
}): JSX.Element => {
    const faqRef = React.useRef<HTMLDivElement>(null)
    useEffect(() => {
        if (open) faqRef.current?.scrollIntoView()
    }, [open])
    return (
        <div ref={faqRef}>
            <FAQ trackOption="What is my reserve balance?" open={open}>
                <FAQ.Question>
                    <FormattedMessage
                        id="Statements.faq.whatIsMyReserveBalance.question"
                        defaultMessage="What is my reserve balance?"
                    />
                </FAQ.Question>
                <FAQ.Answer>
                    <p>
                        <FormattedMessage
                            id="Statements.faq.whatIsMyReserveBalance.answer.paragraphs.0"
                            defaultMessage="Your reserve is funds set aside in your account in case your expenses (such as repairs) exceed net proceeds in a given month."
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id="Statements.faq.whatIsMyReserveBalance.answer.paragraphs.1"
                            defaultMessage="You can set a reserve balance of any amount you like, but we recommend at least $500 per home. We’ve set that amount as the default in your account, and you can change it any time in your <a>Settings</a>. Changes to the reserve amount will be reflected in the following month’s statement."
                            values={{
                                a: (chunks: React.ReactNode) => (
                                    <Link
                                        to="/statements/settings"
                                        target="_blank"
                                    >
                                        {chunks}
                                    </Link>
                                ),
                            }}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id="Statements.faq.whatIsMyReserveBalance.answer.paragraphs.2"
                            defaultMessage="Your reserve balance carries over from month to month. And if you use any of your reserve to pay for expenses, we’ll automatically replenish it from revenue you earn."
                        />
                    </p>
                </FAQ.Answer>
            </FAQ>
        </div>
    )
}

export const WhereDoISendPaymentsFaq = (): JSX.Element => {
    return (
        <FAQ trackOption="Where do I send payments to my Vacasa account?">
            <FAQ.Question>
                <FormattedMessage
                    id="Statements.faq.whereDoISendPayments.question"
                    defaultMessage="Where do I send payments to my Vacasa account?"
                />
            </FAQ.Question>
            <FAQ.Answer>
                <p>
                    <FormattedMessage
                        id="Statements.faq.whereDoISendPayments.answer.info"
                        defaultMessage="Please mail a check to one of the addresses below (corresponding to the location of your vacation home). Payments to your account will be reflected on the following month’s statement."
                    />
                </p>
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns:
                            "repeat(auto-fill, minmax(130px,max-content))",
                        fontSize: "14px",
                        columnGap: "60px",
                        rowGap: "16px",
                        marginTop: "16px",
                    }}
                >
                    <div>
                        <div className="font-italic">
                            <FormattedMessage
                                id="Statements.faq.whereDoISendPayments.answer.canada"
                                defaultMessage="If your home is in Canada"
                            />
                        </div>
                        <div>
                            <div>Vacasa Canada</div>
                            <div>202a-1002 Lynham Rd</div>
                            <div>Whistler BC, V8E 0K4</div>
                        </div>
                    </div>
                    <div>
                        <div className="font-italic">
                            <FormattedMessage
                                id="Statements.faq.whereDoISendPayments.answer.allOthers"
                                defaultMessage="All others"
                            />
                        </div>
                        <div>
                            <div>Vacasa LLC</div>
                            <div>PO Box 10130</div>
                            <div>Portland, OR 97296</div>
                        </div>
                    </div>
                </div>
            </FAQ.Answer>
        </FAQ>
    )
}

export const WhoCanIContactFaq = (): JSX.Element => {
    return (
        <FAQ trackOption="Who can I contact for help with my statements?">
            <FAQ.Question>
                <FormattedMessage
                    id="Statements.faq.whoCanIContact.question"
                    defaultMessage="Who can I contact for help with my statements?"
                />
            </FAQ.Question>
            <FAQ.Answer>
                <p>
                    <FormattedMessage
                        id="Statements.faq.whoCanIContact.answer.paragraphs.0"
                        defaultMessage="Reach out to our Homeowner Account Support team at {phoneNumber} or {email}."
                        values={{
                            phoneNumber: (
                                <a href="tel:971-254-3001">
                                    {formatPhone("971-254-3001")}
                                </a>
                            ),
                            email: (
                                <a
                                    href={`mailto:${HOMEOWNER_ACCOUNTS_EMAIL}`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {HOMEOWNER_ACCOUNTS_EMAIL}
                                </a>
                            ),
                        }}
                    />
                </p>
                <p>
                    <FormattedMessage
                        id="Statements.faq.whoCanIContact.answer.paragraphs.1"
                        defaultMessage="We’re available 7 days a week, from 6 a.m. to 6 p.m. PST."
                    />
                </p>
            </FAQ.Answer>
        </FAQ>
    )
}
