const unitSelector = {
    allRentals: "Tutte le proprietà",
    search: "ricerca",
    searchRentals: "Cerca le tue proprietà",
    allActive: "Tutte le proprietà attive",
    rentals: "proprietà",
    inactive: "Non attivo",
    inactiveRentals: "Proprietà non attive",
    unitSelectorAria:
        "Usa questa selezione di unità per cambiare l'unità attualmente visualizzata.",
}

export default unitSelector
