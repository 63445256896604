import {
    CSSProperties,
    ChangeEvent,
    FocusEvent,
    InputHTMLAttributes,
    RefObject,
    useCallback,
} from "react"
import styles from "./RangeInput.module.scss"
import classNames from "classnames"
import { Button } from "../Button"

export interface RangeInputProps extends InputHTMLAttributes<HTMLInputElement> {
    markers?: React.ReactNode
    containerClassName?: string
    currency?: boolean
    inputRef?: RefObject<HTMLInputElement>
    inputThumbRef?: RefObject<HTMLInputElement>
    onReset?: () => void
    rangeClassName?: string
}

export const RangeInput: React.FC<RangeInputProps> = ({
    containerClassName,
    currency,
    className,
    defaultValue,
    inputRef,
    markers,
    max,
    min,
    name,
    onBlur,
    onFocus,
    onChange,
    onReset,
    rangeClassName,
    ...inputProps
}) => {
    const onInputBlur = useCallback(
        (e: FocusEvent<HTMLInputElement>) => {
            if (onBlur) onBlur(e)
        },

        [onBlur]
    )

    const onInputFocus = useCallback(
        (e: FocusEvent<HTMLInputElement>) => {
            if (onFocus) onFocus(e)
        },
        [onFocus]
    )

    const onInputResetClick = useCallback(() => {
        onChange?.({
            target: {
                name: name,
                value: defaultValue ?? min,
            },
        } as ChangeEvent<HTMLInputElement>)
        onReset?.()
    }, [defaultValue, min, name, onChange, onReset])

    const calculateProgress = useCallback(
        (min: number, max: number, value: number): number => {
            return ((value - min) / (max - min)) * 100
        },
        []
    )

    // Required for chrome :(
    const getProgressBarStyle = useCallback(
        (progress: number): CSSProperties => {
            return {
                background: `linear-gradient(
            to right,
            #003349 0%,
            #003349 ${progress}%,
            #ccd6db ${progress}%,
            #ccd6db 100%
        )`,
            }
        },
        []
    )

    const formatRange = useCallback(
        (value: string | number | undefined) => {
            return currency ? `$${value}` : value
        },
        [currency]
    )

    const progress = calculateProgress(
        Number(min),
        Number(max),
        Number(inputProps.value)
    )

    return (
        <div
            className={classNames(
                styles.rangeInputContainer,
                containerClassName
            )}
        >
            <div className={classNames(styles.range, rangeClassName)}>
                {formatRange(min)}
            </div>
            <div className={styles.inputContainer}>
                {markers}
                <input
                    className={classNames(styles.rangeInput, className)}
                    max={max}
                    min={min}
                    name={name}
                    onBlur={onInputBlur}
                    onChange={onChange}
                    onFocus={onInputFocus}
                    ref={inputRef}
                    style={getProgressBarStyle(progress)}
                    type="range"
                    {...inputProps}
                />
            </div>
            <div className={classNames(styles.range, rangeClassName)}>
                {formatRange(max)}
            </div>
            <Button
                className={classNames(styles.resetButton)}
                disabled={inputProps.value === defaultValue}
                onClick={onInputResetClick}
                variant="text"
            >
                Reset
            </Button>
        </div>
    )
}
