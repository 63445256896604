import { HOLD_CREATED } from "Constants"
import { useDateRangeContext } from "core/components/dates/state/DateRange.context"
import { useOnboardingStatus } from "hooks/onboarding/use-onboarding-status"
import { useUnits } from "hooks/units"
import { Button } from "lib/components/Button/Button"
import { FC, useCallback } from "react"
import { FormattedMessage } from "react-intl"
import { useLocation, useNavigate } from "react-router-dom"
import { useRootService } from "services"
import {
    trackAddAnotherHoldFromOnboardingFooter,
    trackOwnerHoldBackToOnboardingClicked,
} from "services/segment/reservation/reservationTracking"
import {
    checkIfOnboardingAppUser,
    clearOnboardingRedirectConfig,
    getOnboardingRedirectConfig,
} from "utils/onboarding"
import styles from "./ViewReservationOnboardingFooter.module.scss"
import { useContactId } from "hooks/user"
import { isOnboardingUnit } from "utils/unit"

interface Props {
    unitID: number
}

const getReservationUnit = (
    id: number,
    unitsQuery: ReturnType<typeof useUnits>
) => {
    if (!unitsQuery.isSuccess) return
    return unitsQuery.data.find(unit => String(unit.id) === String(id))
}

const ViewReservationOnboardingFooter: FC<Props> = ({ unitID }) => {
    const { search } = useLocation()
    const navigate = useNavigate()
    const rootService = useRootService()
    const unitsQuery = useUnits()
    const unit = getReservationUnit(unitID, unitsQuery)
    const contactId = useContactId()

    const isOnboardingAppUser = checkIfOnboardingAppUser(useOnboardingStatus())

    const { dispatchDateRangeAction } = useDateRangeContext()

    const onAddOwnerHoldClick = useCallback(() => {
        trackAddAnotherHoldFromOnboardingFooter(
            String(unitID),
            String(contactId)
        )

        rootService.calendarService.clearDates()
        dispatchDateRangeAction({
            type: "RESET_DATES",
        })
        dispatchDateRangeAction({
            type: "SET_CAN_EDIT_START_DATE",
            payload: true,
        })
        navigate("/calendar")
    }, [
        contactId,
        dispatchDateRangeAction,
        navigate,
        rootService.calendarService,
        unitID,
    ])

    const onBackToOnboardingClick = useCallback(() => {
        trackOwnerHoldBackToOnboardingClicked(String(unitID), String(contactId))
        clearOnboardingRedirectConfig()
    }, [contactId, unitID])

    // If it's not an onboarding unit or an onboarding app user, don't show extra buttons
    if (!isOnboardingAppUser || !unit || !isOnboardingUnit(unit)) return null

    const urlParams = new URLSearchParams(search)

    const show = isOnboardingAppUser && urlParams.get(HOLD_CREATED) === "1"

    const { redirectOnboardingUrl, redirectToOwnerHolds } =
        getOnboardingRedirectConfig()

    if (!show || !redirectToOwnerHolds) return null

    return (
        <div className={styles.container}>
            <div className={styles["container__inner"]}>
                <h3 className={styles.title}>
                    <FormattedMessage
                        id="CalendarPage.flyout.onboardingFooter.title"
                        defaultMessage="What do you want to do next?"
                    />
                </h3>
                <div className={styles.actions}>
                    <Button
                        className={styles.action}
                        fullWidth
                        href={redirectOnboardingUrl}
                        onClick={onBackToOnboardingClick}
                        role="button"
                        variant="primary"
                    >
                        <FormattedMessage
                            id="CalendarPage.flyout.onboardingFooter.backToOnboarding"
                            defaultMessage="Go Back to Onboarding"
                        />
                    </Button>

                    <Button
                        className={styles.action}
                        fullWidth
                        onClick={onAddOwnerHoldClick}
                        role="button"
                        variant="secondary"
                    >
                        <FormattedMessage
                            id="CalendarPage.flyout.onboardingFooter.addAdditionalOwnerHolds"
                            defaultMessage="Add Another Owner Hold"
                        />
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default ViewReservationOnboardingFooter
