import { ReservationType } from "@vacasa/owner-api-models"
import { addYears, formatISOWithOptions, lastDayOfMonth } from "date-fns/fp"
import useRouter from "hooks/common/useRouter"
import { useKnownSearchParams } from "hooks/router"
import { pipe } from "lodash/fp"
import { useCallback, useEffect, useState } from "react"
import { MAX_CALENDAR_YEARS } from "../../../Constants"
import { useDateRangeContext } from "../../../core/components/dates/state/DateRange.context"
import { Flyout } from "../../../core/components/flyout/flyout"
import { useAvailability } from "../../../hooks/availibility"
import { useReservation } from "../../../hooks/reservation"
import Loader from "../../../lib/components/Loader"
import { useRootService } from "../../../services"
import { SwitchAccountsModal } from "../modal/switch-accounts-modal"
import { DeleteReservation } from "./DeleteReservation/DeleteReservation"
import { ReservationForm } from "./ReservationForm/ReservationForm"
import { EditGuestView, InviteGuestView } from "./ReservationGuests"
import { ViewReservation } from "./ViewReservation/ViewReservation"
import { isFixedRentUnit } from "utils/unit"
import { useContactId, useContactIds } from "hooks/user"
import { useCurrentUnit, useUnitStatuses } from "hooks/units"
import { parseISO } from "date-fns"
import { CancelHoldBottomSheet } from "./ReservationForm/CancelHoldBottomSheet"

const format = formatISOWithOptions({ representation: "date" })

const availabilityEndDate = pipe(
    lastDayOfMonth,
    addYears(MAX_CALENDAR_YEARS),
    format
)

interface Props {
    create?: boolean
}

const ReservationFlyout: React.FC<Props> = ({ create }) => {
    const router = useRouter()
    const { params } = router
    const rootService = useRootService()
    const [showFlyoutForGuestReview, setShowFlyoutForGuestReview] =
        useState(true)
    const [showCancelHoldAlert, setShowCancelHoldAlert] = useState(false)
    const [inInviteGuestFlow, setInInviteGuestFlow] = useState(false)

    const calendarService = rootService.calendarService

    const contactIds = useContactIds()
    const { unit, unitId } = useCurrentUnit()
    const { isActive, isRevenuePooling } = useUnitStatuses()

    const contactId = useContactId() ?? undefined
    const knownSearchParams = useKnownSearchParams()

    const utmMediumInUrl: string | null = knownSearchParams.utmMedium
    const contactIdInUrl: string | null = knownSearchParams.contactId

    useEffect(() => {
        // Don't launch flyout when contact ID in guest review cta url from Email is not under current login
        if (
            utmMediumInUrl &&
            utmMediumInUrl === "email" &&
            contactIdInUrl &&
            !contactIds?.includes(contactIdInUrl)
        ) {
            setShowFlyoutForGuestReview(false)
        }
    }, [contactIds, utmMediumInUrl, contactIdInUrl])

    const reservationQuery = useReservation(router.params.reservationId)
    const availabilityQuery = useAvailability(
        contactId,
        unitId ?? undefined,
        format(new Date()),
        availabilityEndDate(new Date())
    )

    const { dispatchDateRangeAction } = useDateRangeContext()

    const handleSetInInviteGuestFlow = (inInviteGuestFlow: boolean) =>
        setInInviteGuestFlow(inInviteGuestFlow)

    const handleClose = useCallback(() => {
        rootService.calendarService.clearDates()
        dispatchDateRangeAction({
            type: "RESET_DATES",
        })
        dispatchDateRangeAction({
            type: "SET_CAN_EDIT_START_DATE",
            payload: true,
        })
    }, [dispatchDateRangeAction, rootService.calendarService])

    if (!unitId) {
        return <></>
    }

    if (!unit) {
        return <></>
    }

    const isFixedRent = isFixedRentUnit(unit)

    const unitInfo = {
        isFixedRent,
        allowHolds: !isFixedRent || isRevenuePooling,
        isCurrentUnitActive: isActive,
        unit,
        unitId,
    }

    const { startDate, endDate } = calendarService

    const resStartDate =
        reservationQuery.isSuccess &&
        reservationQuery.data.data.attributes.startDate
    const resEndDate =
        reservationQuery.isSuccess &&
        reservationQuery.data.data.attributes.endDate
    const isVacasaHoldType =
        reservationQuery.data?.data.attributes.occupancyType ===
        ReservationType.VACASAHOLD
    const loading = reservationQuery.isLoading

    const backToReservationView = () => {
        if (!reservationQuery.isSuccess) return

        router.navigate(
            {
                pathname: `/calendar/hold/${reservationQuery.data.data.id}`,
                search: router.location.search,
                hash: router.location.hash,
            },
            {
                replace: true,
            }
        )
    }

    if (reservationQuery.isError) {
        return <></>
    }

    return (
        <>
            {showFlyoutForGuestReview ? (
                <Flyout
                    closeTo={
                        create && params.reservationId === undefined
                            ? undefined
                            : "/calendar"
                    }
                    closeOnClickOutside={!create || !inInviteGuestFlow}
                    onClose={
                        create && params.reservationId === undefined
                            ? () => setShowCancelHoldAlert(true)
                            : handleClose
                    }
                    hideCloseButton={
                        (create && inInviteGuestFlow) ||
                        params.action === "edit"
                    }
                >
                    {loading && <Loader />}

                    {/* Create */}
                    {create && params.reservationId === undefined && (
                        <ReservationForm
                            initialStartDate={startDate}
                            initialEndDate={endDate}
                            unitInfo={unitInfo}
                            availabilityDays={availabilityQuery.data ?? {}}
                            handleSetInInviteGuestFlow={
                                handleSetInInviteGuestFlow
                            }
                        />
                    )}
                    {create &&
                        params.reservationId === undefined &&
                        showCancelHoldAlert && (
                            <CancelHoldBottomSheet
                                handleFlyoutClose={handleClose}
                                onCancelClick={() =>
                                    setShowCancelHoldAlert(false)
                                }
                            />
                        )}
                    {/* Edit */}
                    {params.reservationId !== undefined &&
                        params.action === "edit" &&
                        !isVacasaHoldType &&
                        resStartDate &&
                        resEndDate && (
                            <ReservationForm
                                initialStartDate={parseISO(resStartDate)}
                                initialEndDate={parseISO(resEndDate)}
                                unitInfo={unitInfo}
                                availabilityDays={availabilityQuery.data ?? {}}
                                reservation={reservationQuery.data?.data}
                            />
                        )}

                    {/* Invite Guests */}
                    {reservationQuery.isSuccess &&
                        params.action === "invite" &&
                        (params.guestId ? (
                            <EditGuestView
                                backToReservationView={backToReservationView}
                                guestId={params.guestId}
                                reservation={reservationQuery.data.data}
                            />
                        ) : (
                            <InviteGuestView
                                backToReservationView={backToReservationView}
                                reservation={reservationQuery.data.data}
                            />
                        ))}

                    {/* View */}
                    {params.reservationId !== undefined &&
                        !params.action &&
                        reservationQuery.isSuccess && (
                            <ViewReservation
                                reservation={reservationQuery.data.data}
                                unitInfo={unitInfo}
                            />
                        )}
                    {/* Delete */}
                    {params.reservationId !== undefined &&
                        params.action === "delete" &&
                        !isVacasaHoldType &&
                        reservationQuery.isSuccess && (
                            <DeleteReservation
                                reservation={reservationQuery.data.data}
                                unitInfo={unitInfo}
                            />
                        )}
                </Flyout>
            ) : (
                <SwitchAccountsModal />
            )}
        </>
    )
}

export { ReservationFlyout }
