/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import { OWNER_API_URL } from "../../Environment"
import {
    JSONAPIDocument,
    JSONAPIResource,
    Reservation,
    UserPreference,
} from "@vacasa/owner-api-models"
import { EarningsResponse } from "@vacasa/owner-api-models/src/legacy/Earnings"
import { StatementsResponse } from "@vacasa/owner-api-models/src/legacy/Statements"
import { authAxios } from "api/authAxios"

/**
 * Service responsible for interacting with the Owner API.
 */
class OwnerAPIService {
    /**
     * @deprecated
     * Retrieve owner from the `/v1/user-preferences/units/{unitID}` endpoint.
     */
    public async getOwnerUnitPreferences(
        unitID: string
    ): Promise<JSONAPIResource<UserPreference>[]> {
        const url = this.getURL(`user-preferences/units/${unitID}`)
        const response = await authAxios.get<
            JSONAPIDocument<UserPreference, []>
        >(url)
        return response.data.data
    }

    /**
     * @deprecated
     * Patch owner preference from the `/v1/user-preferences/units/{unitID}` endpoint.
     */
    public async patchOwnerUnitPreference(
        unitID: string,
        data: JSONAPIResource<UserPreference>
    ): Promise<any> {
        const url = this.getURL(`user-preferences/units/${unitID}`)
        const response = await authAxios.patch(url, { data: data })
        return response.data
    }

    /**
     * @deprecated
     * Retrieve owner statements from the `/v1/legacy/owners/{contactID}/statements` endpoint.
     */
    public async getStatements(
        contactID: string,
        language: string
    ): Promise<StatementsResponse> {
        const url = this.getURL(
            `legacy/owners/${contactID}/statements?lang=${language}`
        )

        const response = await authAxios.get<StatementsResponse>(url)
        return response.data
    }

    /**
     * @deprecated
     * Get owner earnings using the `/v1/legacy/owners/{contactID}/earnings` endpoint.
     */
    public async getEarnings(
        contactID: string,
        firstDay: string,
        lastDay: string
    ): Promise<EarningsResponse> {
        const url = this.getURL(
            `legacy/owners/${contactID}/earnings?lang=40&first_day=${firstDay}&last_day=${lastDay}`
        )

        const response = await authAxios.get<EarningsResponse>(url)
        return response.data
    }

    private getURL(route: string): string {
        return `${OWNER_API_URL}v1/${route}`
    }
}

export default new OwnerAPIService()

export interface OwnerHoldResponse {
    data: {
        id: string
        type: number
        attributes: Reservation
    }
}
