const taxes = {
    title: "Daně",

    fillOutW9: "Vyplňte prosím formulář W-9",
    submitW9: "Předložte formulář W-9 on-line",
    newTaxFormsWillShow:
        "Nové daňové formuláře budou zobrazeny zde, jakmile budou k dispozici.",
    goPaperless: "Bezpapírová možnost",
    paperlessExplanation:
        "Bezpapírová možnost vám umožňuje obdržet formuláře daňového přiznání výhradně elektronickým způsobem. Nebudou vám zaslány papírové kopie vašich daňových formulářů.",
    electronicForms:
        "Obdržte formuláře daňového přiznání výhradně elektronickým způsobem",
    getForms: "Získejte formuláře daňového přiznání",
    irsFormsElectronically: "Obdržte IRS daňové formuláře elektronicky",
    mustSubmit:
        "We must have one of the following taxpayer forms on file before we can provide your tax preparation documents. Please complete only the form that best describes your taxpayer status with the IRS. If your taxpayer information has changed, please submit a new form.",

    paperlessNotification: "Daňové formuláře budou zasílány elektronicky",
    paperFormNotification: "Daňové formuláře budou zasílány poštou",

    noForms: "Ještě nemáte žádné daňové formuláře.",

    ifExpecting: "Pokud očekáváte",
    willBeAvailable: "bude k dispozici k",
    form1099title: "Formulář 1099-MISC",
    form1042title: "Formulář 1042-S",
    jan31: "31. lednu",
    march15: "15. březnu",

    yourInformation: "Provide your taxpayer information",
    whoFor: "Pro koho je formulář určen?",
    howToSubmit: "Jak formulář předložit",
    w9title: "Formulář W-9",
    w9whoFor:
        "Předložte tento formulář, pokud jste občanem USA nebo cizincem s trvalým pobytem, který má jeden z následujících daňových identifikačních čísel daňového poplatníka: SSN, EIN nebo ITIN. Cizinci bez trvalého pobytu by neměli tento formulář vyplňovat.",
    w8ECItitle: "Formulář W-8ECI",
    w8ECIwhoFor:
        "Předložte tento formulář, pokud jste zahraniční fyzickou osobou, cizincem bez trvalého pobytu, nebo subjektem, který je v současné době držitelem daňového identifikačního čísla SSN, EIN nebo ITIN a budete vykazovat příjem podáním ročního daňového přiznání úřadu IRS.",
    w8BENtitle: "Formulář W-8BEN",
    w8BENwhoFor:
        "Předložte tento formulář, pokud jste zahraniční fyzickou osobou nebo cizincem bez trvalého pobytu, nepodáváte roční daňové přiznání úřadu IRS, nebo byste chtěli požadovat výhody plynoucí ze smlouvy o daních z příjmu mezi Spojenými státy a zemí vašeho bydliště. Vzhledem k tomu, že se výhody plynoucí ze smluv nevztahují na příjmy z pronájmu, jednotlivci předkládající formulář W-8BEN podléhají srážkové dani pro zahraniční osoby, odečtené od hrubého příjmu, ve výši 30 %.",
    w8BENEtitle: "Formulář W-8BEN-E",
    w8BENEwhoFor:
        "Předložte tento formulář, pokud jste zahraničním příjemcem příjmu, nejste fyzickou osobou a nepodáváte roční daňové přiznání úřadu IRS. Výhody plynoucí ze smlouvy o daních z příjmu mohou být rovněž požadovány v části III tohoto formuláře. Vzhledem k tomu, že se výhody plynoucí ze smluv nevztahují na příjmy z pronájmu, příjemci předkládající formulář W-8BEN-E podléhají srážkové dani pro zahraniční osoby, odečtené od hrubého příjmu, ve výši 30 %.",

    learnMore: "Více informací najdete na",
    mailForm: "Vyplňte formulář a zašlete jej na adresu:",

    dwnld: "Stáhnout",
    download: "Stáhnout vaše údaje o pronájmu",
    preparingNotification: "Příprava vašich dat ke stažení",
    rentalData: "Údaje o pronájmu",
    noDownloads: "Ještě nemáte žádné položky ke stažení.",
    description: "Údaje za každý jednotlivý daňový rok budou k dispozici k",
    january15: "15. lednu",
    description2:
        "následujícího roku. Pokud sdílíte vlastnictví s jednou nebo více osobami, budete mít každý samostatné údaje ke stažení, abyste měli správné informace pro daňové přiznání.",
    instructions:
        "Soubory s příponou .xls je možné otevřít ve většině tabulkových procesorů, včetně Excelu, Numbers a Google Sheets.",
    makeChanges: "Want to make changes?",
    w9Support:
        "To make edits to your W-9 details please reach out to the {{HOME_OWNER_SUPPORT}} at {{SUPPORT_NUMBER}}, {{SUPPORT_EMAIL}}",
    taxModal: {
        actionRequired: "Action Required!",
        title: "Enter Taxpayer Info",
        description:
            "We need your taxpayer information on file so we can provide you with accurate tax preparation documents for last year.",
        primaryButton: "Fill it Out",
        secondardButton: "Maybe Later",
    },
    paperless1099Modal: {
        title: "Paperless 1099",
        description:
            "As the new year approaches, be prepared for tax season by receiving your Vacasa 1099 digitally. We'll email you when it's ready.",
        goPaperless: "Go Paperless",
        skipForNow: "Skip for Now",
    },
    w9: {
        taxFormSelect: {
            info1: "Please provide your taxpayer information so we can provide your tax preparation documents. Provide information that best describes your taxpayer status with the IRS.",
            info2: "The name/entity that you provide should be the same name/entity that is listed on your contract with Vacasa.",
        },
        taxClassifications: {
            INP: "Individual/sole proprietor or single-member LLC",
            LLC: "Limited liability company",
            CCO: "C Corporation",
            SCO: "S Corporation",
            PRT: "Partnership",
            TRE: "Trust/estate",
            OTH: "Other",
        },
        llcTaxClassfications: {
            C: "C Corporation",
            S: "S Corporation",
            P: "Partnership",
        },
        taxIdTypes: {
            SSN: "SSN (Social Security Number)",
            EIN: "EIN (Employer Identification Number)",
        },
        forms: {
            agreeAndSign: {
                title: "Agree & Submit",
                termsOfAgreement: "Terms Of Agreement",
                terms: "Under penalties of perjury, I certify that:\n\n1. The number shown on this form is my correct taxpayer identification number (or I am waiting for a number to be issued to me); and \n\n2. I am not subject to backup withholding because: (a) I am exempt from backup withholding, or (b) I have not been notified by the Internal Revenue Service (IRS) that I am subject to backup withholding as a result of a failure to report all interest or dividends, or (c) the IRS has notified me that I am no longer subject to backup withholding; and \n\n3. I am a U.S. citizen or other U.S. person (defined below); and \n\n4. The FATCA code(s) entered on this form (if any) indicating that I am exempt from FATCA reporting is correct. Certification instructions. You must cross out item 2 above if you have been notified by the IRS that you are currently subject to backup withholding because you have failed to report all interest and dividends on your tax return. For real estate transactions, item 2 does not apply. For mortgage interest paid, acquisition or abandonment of secured property, cancellation of debt, contributions to an individual retirement arrangement (IRA), and generally, payments other than interest and dividends, you are not required to sign the certification, but you must provide your correct TIN. See the instructions for Part II, later.",
                iAgree: "I agree to these terms",
                signature: "Signature",
                signaturePlaceholder: "Type fullname",
                signatureHelperText:
                    "By signing this document with an electronic signature, I agree that such signature will be as valid as handwritten signatures to the extent allowed by local law.",
                signedOn: "Signed On",
                validation: {
                    agreeToTermsRequired:
                        "Please agree to our terms of agreement",
                    signatureRequired: "Please enter your signature",
                    signatureMaxLength: "Signature cannot exceed 50 letters",
                },
            },
            taxInfo: {
                validation: {
                    entityTypeRequired: "Entity type is required",
                    entityTypeEmpty: "Entity type must be empty",
                    entityNameRequired: "Entity name is required",
                    taxIDTypeRequired: "Tax ID type is required",
                    taxIDTypeValid: "Tax ID type must be either SSN or EIN",
                    taxIDTypeEIN: "Tax ID type must be EIN",
                    taxClassificationRequired:
                        "Please select a tax classification",
                    taxClassificationValid: "Tax classification invalid",
                    ssnRequired: "SSN is required",
                    ssnLength: "SSN must be 9 digit in XXX-XX-XXXX format",
                    ssnInvalid: "SSN must be 9 digit in XXX-XX-XXXX format",
                    ssnAllZero: "SSN cannot be all 0",
                    ssnEmpty: "SSN must be empty",
                    einRequired: "EIN is required",
                    einLength: "EIN must be 9 digit in XX-XXXXXXX format",
                    einInvalid: "EIN must be 9 digit in XX-XXXXXXX format",
                    einAllZero: "EIN cannot be all 0",
                    einEmpty: "EIN must be empty",
                    llcTypeRequired: "LLC type required",
                    llcTypeValid: "LLC type must be either C, S, or P",
                    llcTypeEmpty: "LLC type must be empty",
                },
            },
            mailingAddress: {
                label: {
                    address: "Address",
                    city: "City",
                    stateOrProvince: "State/Province",
                    zipCode: "Zip/Postal Code",
                },
                placeholder: {
                    addressLine1: "Address Line 1",
                    addressLine2: "Address Line 2",
                    city: "City",
                    stateProvince: "State/Province",
                    zipCode: "5-digit zipcode",
                },
                validation: {
                    addressRequired: "Address is required",
                    cityRequired: "Please enter city",
                    postalCodeRequired: "Postal code is required",
                    provinceRequired: "State/Province is required",
                    postalCodeMaxLength: "Postal code must be 5 digit length",
                    postalCodeAllZero: "Postal code cannot be all 0",
                },
            },
        },
    },
}

export default taxes
