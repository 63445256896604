const petsAllowed = {
    title: "Allow Dogs",
    ariaLabel: "Click button to allow pet friendly.",
    description:
        "Makes your listing dog-friendly, enabling us to market your home to a wider audience. We provide dog supplies and always ensure your home is well cared for.",
    learnMore: "Learn more",
    earnMore: "Earn up to 20% more",
    earnMoreDescription:
        "Allowing dogs can significantly increase your earnings and occupancy, especially in the off-season.",
    untitledHome: "Untitled Home",
    showAllRentals: "Show All Rentals",
    showAllAriaLabel: "use this button to show all your rentals.",
    hideRentals: "Hide Rentals",
    allowed: "Allowed",
    allow: "Allow",
    modalHeader: "Great, you're all set!",
    modalText:
        "Your listing is being updated and your local team will be notified so they can ensure your home is ready to welcome dogs. It may take up to 24 hours for this change to update on all of your listings and marketing channels.",
    modalFooter: "Click this button to close the modal.",
    ok: "OK",
    disclaimer:
        "Changes can take up to 24 hours to update on all of your listings and marketing channels. Please reach out to the contact for your rental if you have any questions or no longer wish to allow dogs.",
}

export default petsAllowed
