import React from "react"
import { FormattedMessage, FormattedNumber } from "react-intl"
import styles from "./statements.module.scss"

interface Props {
    currency: string
    isFixedRent: boolean
    grossRent: number
    mgmtFee: number
    month: string
    netRent: number
    nights: number
}

const UnitSummaryRow: React.FC<Props> = ({
    currency,
    isFixedRent,
    grossRent,
    mgmtFee,
    month,
    netRent,
    nights,
}: Props) => {
    return (
        <div className={styles["unit-summary-overview-row"]}>
            <div className={styles["unit-summary-cell"]}>
                <span className={styles["header"]}>
                    <FormattedMessage
                        id="Statements.month"
                        defaultMessage="Month"
                    />
                </span>
                {month}
            </div>
            <div className={styles["unit-summary-cell"]}>
                <span className={styles["header"]}>
                    <FormattedMessage
                        id="Statements.nights"
                        defaultMessage="Nights"
                    />
                </span>
                {isFixedRent ? "--" : nights}
            </div>
            <div className={styles["unit-summary-cell"]}>
                <span className={styles["header"]}>
                    <FormattedMessage
                        id="Statements.grossRent"
                        defaultMessage="Gross Rent"
                    />
                </span>
                {isFixedRent ? (
                    "--"
                ) : (
                    <FormattedNumber
                        value={grossRent}
                        currency={currency}
                        style="currency" // eslint-disable-line react/style-prop-object
                    />
                )}
            </div>
            <div className={styles["unit-summary-cell"]}>
                <span className={styles["header"]}>
                    <FormattedMessage
                        id="Statements.mgmtFee"
                        defaultMessage="Mgmt Fee"
                    />
                </span>
                {isFixedRent ? (
                    "--"
                ) : (
                    <FormattedNumber
                        value={-mgmtFee}
                        currency={currency}
                        style="currency" // eslint-disable-line react/style-prop-object
                    />
                )}
            </div>
            <div className={styles["unit-summary-cell"]}>
                <span className={styles["header"]}>
                    <FormattedMessage
                        id="Statements.netRent"
                        defaultMessage="Net Rent"
                    />
                </span>
                <FormattedNumber
                    value={netRent}
                    currency={currency}
                    style="currency" // eslint-disable-line react/style-prop-object
                />
            </div>
        </div>
    )
}

export { UnitSummaryRow }
