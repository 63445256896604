import { observer } from "mobx-react"
import React from "react"
import { Navigate } from "react-router-dom"
import { useRootService } from "../services"
import { EmptyStatements } from "./statements/EmptyStatements/EmptyStatements"

const RedirectToStatements: React.FunctionComponent = observer(() => {
    const { earningsService } = useRootService()

    const today = new Date()
    const defaultYear = today.getFullYear()
    if (earningsService.fetchingStatementDates) return null
    if (
        earningsService.statementDates &&
        earningsService.statementDates.length < 1
    ) {
        return <EmptyStatements />
    }

    if (
        !!earningsService.statementDates?.[0]?.Month &&
        !!earningsService.statementDates?.[0].Year
    ) {
        const year = earningsService.statementDates?.[0].Year
        const month = earningsService.statementDates?.[0].Month
        const route = `/statements/${year}/${month}`
        return <Navigate to={route} />
    } else {
        return <Navigate to={`/statements/${defaultYear}`} />
    }
})

export default RedirectToStatements
