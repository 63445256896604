const statements = {
    faqAriaLabel: "View FAQ",
    title: "Elszámolások",
    emptyStateCTA: "További információk az elszámolásokkal kapcsolatban",
    emptyStateEarnings:
        "A rendszer az új elszámolásokat mindig az új hónap 10. napjáig hozza létre.",

    pdf: "PDF",
    pdfAriaLabel: "PDF megtekintése",
    pdfNotification: "PDF letöltésének előkészítése",
    fileName: "elszámolás",

    print: "Nyomtatás",
    printAriaLabel: "Nyomtatási nézeet",

    paymentSummary: "Fizetési összefoglaló: Összes nyaraló",
    beginningBalance: "Kezdő egyenleg",
    payment: "Fizetés",
    payments: "Kifizetések",
    proceeds: "Bevétel",
    proceedsBalance: "Bevétel egyenlege",
    coOwnerProceeds: "Társtulajdonos bevétele",
    endingBalance: "Záróegyenleg",
    details: "Részletek",

    summary: "Összegzés",
    nightsOccupied: "Lefoglalt éjszakák száma",
    occupancy: "Kihasználtság",
    reserveBalance: "Tartalékegyenleg",

    beginningReserveBalance: "Kezdő tartalékegyenleg",
    EndingReserveBalance: "Záró tartalékegyenleg",
    amountHeld: "Fenntartott összeg",
    amountUsed: "Felhasznált összeg",

    netRent: "Nettó lakbér",
    expense: "Kiadás",
    expenses: "Kiadások",

    month: "Hónap",
    nights: "Éjszakák száma",
    grossRent: "Bruttó lakbér",
    mgmtFee: "Kezelési költség",
    total: "Összesen",
    credits: "Jóváírások",

    checkNumber: "Csekk száma",
    paymentDescription: "Fizetés a következőben végződő számlára:",
    statementYearSummary: "{year} Year Summary",

    canadaSalesTaxesHeader: "Taxes - Expenses & Management Fees",

    tooltips: {
        proceeds: "Amount you'll be paid for this month",
        occupancy:
            "The percentage of available nights this month that you rented to guests (does not include your personal use dates and complimentary stays)",
        nightsOccupied:
            "How many nights this month your home hosted guests (only counts reservations with check-out dates within the month)",
    },
    paymentSettings: "Payment Settings",
    paymentSettingsSmall: "Settings",
    paymentSettingsNotification:
        "Manage your bank details and payment settings here.",
    paymentSettingsNotificationTitle: "Payment Settings",
    checkItOut: "Check it Out",
    settings: "Settings",
    backToTop: "Back to Top",
    needHelp: "Need Help?",
    faqAbbreviation: "FAQ",
    errorDownloading: "Error downloading",
    pdfDownloadError:
        "We were unable to create a PDF of your statement. Please try again later.",
    faq: {
        whereDoISendPayments: {
            answer: {
                canada: "If your home is in Canada",
                allOthers: "All others",
            },
        },
    },
    netRentNoteBegin: "NOTE: ",
    netRentNoteContent:
        "The entire stay spans across {reservationMonthSpan} months ({reservationPeriod}). Monthly revenue will appear on each month’s respective statement(s).",
}

export default statements
