import React from "react"
import { FormattedMessage } from "react-intl"
import { ReactComponent as OnboardingCalendarSVG } from "../../../assets/icon-calendar-tick.svg"
import { Home } from "react-feather"
import { ReactComponent as PercentageSVG } from "../../../assets/icon-percentage.svg"
import { ReactComponent as StarSVG } from "../../../assets/icon-star.svg"
import { ReactComponent as TextFileSVG } from "../../../assets/icon-text-file.svg"
import { ReactComponent as TrendingUpSVG } from "../../../assets/icon-trending-up.svg"
import { ReactComponent as WelcomeSvg } from "../../../assets/icon-welcome.svg"
import { ReactComponent as SprayBottleSVG } from "../../../assets/spray-bottle.svg"
import Panel from "../OnboardingPanel"
import styles from "./WelcomePanelV2.module.scss"
import classnames from "classnames"

const WelcomePanelV2: React.FC = () => {
    return (
        <Panel
            borderRadius="medium"
            id="welcome-panel-v2"
            shadowSize="large"
            styleOverride={{
                width: "100%",
                padding: "24px",
                marginBottom: "40px",
            }}
        >
            <div className={styles.welcome}>
                <WelcomeSvg />
                <h3 className="type-heading-medium-large">
                    <FormattedMessage
                        id="Onboarding.welcome.title"
                        description="Welcome"
                        defaultMessage="Welcome"
                    />
                </h3>
                <div className={styles.description}>
                    <p className="type-body">
                        <FormattedMessage
                            id="Onboarding.welcome.description"
                            description="Welcome Description"
                            defaultMessage="We're glad you're here."
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id="Onboarding.welcome.description2"
                            description="Welcome"
                            defaultMessage="This is your home base for managing your vacation rental listing."
                        />
                    </p>
                </div>
            </div>
            <hr />
            <p className={styles.onceReady}>
                <FormattedMessage
                    id="Onboarding.welcome.onceReady"
                    description="Once your home is ready"
                    defaultMessage="Once your home is ready, you can use your account to:"
                />
            </p>

            <div className={styles.onboardingActions}>
                <div className={styles.onboardingActionItem}>
                    <div className={styles.iconContainer}>
                        <OnboardingCalendarSVG />
                    </div>
                    <FormattedMessage
                        id="Onboarding.welcome.items.setHolds"
                        defaultMessage="Set holds"
                    />
                </div>
                <div className={styles.onboardingActionItem}>
                    <div className={styles.iconContainer}>
                        <TrendingUpSVG />
                    </div>
                    <FormattedMessage
                        id="Onboarding.welcome.items.trackPerformance"
                        defaultMessage="Track performance"
                    />
                </div>
                <div className={styles.onboardingActionItem}>
                    <div className={styles.iconContainer}>
                        <TextFileSVG />
                    </div>
                    <FormattedMessage
                        id="Onboarding.welcome.items.reviewStatements"
                        defaultMessage="Review financial statements"
                    />
                </div>

                <div className={styles.onboardingActionItem}>
                    <div className={styles.iconContainer}>
                        <SprayBottleSVG />
                    </div>
                    <FormattedMessage
                        id="Onboarding.welcome.items.requestMaintenance"
                        defaultMessage="Request maintenance"
                    />
                </div>

                <div className={styles.onboardingActionItem}>
                    <div
                        className={classnames(
                            styles.iconContainer,
                            styles.featherIcon
                        )}
                    >
                        <Home />
                    </div>
                    <FormattedMessage
                        id="Onboarding.welcome.items.viewHomeDetails"
                        defaultMessage="View home details"
                    />
                </div>
                <div className={styles.onboardingActionItem}>
                    <div className={styles.iconContainer}>
                        <PercentageSVG />
                    </div>
                    <FormattedMessage
                        id="Onboarding.welcome.items.reviewTaxForms"
                        defaultMessage="Review tax forms"
                    />
                </div>
                <div className={styles.onboardingActionItem}>
                    <div className={styles.iconContainer}>
                        <StarSVG />
                    </div>
                    <FormattedMessage
                        id="Onboarding.welcome.items.additionalServices"
                        defaultMessage="Review tax forms"
                    />
                </div>
            </div>
        </Panel>
    )
}

export { WelcomePanelV2 }
