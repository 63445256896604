import { JSONAPIResource, Reservation } from "@vacasa/owner-api-models"
import { RESERVATION_ID_PARAM } from "Constants"
import { Location } from "react-router-dom"

export interface BookingNotification {
    resIdInUrl: string | null
    hasError: boolean
}

class bookingNotificationUtil {
    private notificationOpened = false
    public reservation: JSONAPIResource<Reservation> | null | undefined = null
    public searchParams: URLSearchParams | null = null
    private fetchingReservation = false

    getShouldShowBookingNotification = (location: Location): boolean => {
        const resIdInUrl = this.getResIdInUrl(location)
        if (this.fetchingReservation) {
            return false
        }
        return (
            resIdInUrl != null &&
            (!this.reservation || !this.reservation.attributes.cancelled) &&
            !this.notificationOpened
        )
    }

    /**
     * Error when there is resId in url,
     * but no reservation with that ID was found.
     */
    getHasError = (location: Location): boolean => {
        if (this.fetchingReservation) {
            return false
        }
        const resIdInUrl = this.getResIdInUrl(location)
        return resIdInUrl !== null && !this.reservation
    }

    getResIdInUrl = (location: Location): string | null => {
        const params = new URLSearchParams(location.search)
        return params.get(RESERVATION_ID_PARAM)
    }

    setNotificationOpened = (value: boolean): void => {
        this.notificationOpened = value
    }

    setFetchingReservation = (value: boolean): void => {
        this.fetchingReservation = value
    }

    setReservation = (value: JSONAPIResource<Reservation> | null): void => {
        this.reservation = value
    }
}

const BookingNotificationUtil = new bookingNotificationUtil()
export default BookingNotificationUtil
