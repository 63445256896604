import classNames from "classnames"
import React, { useState, useEffect, ReactNode } from "react"
import styles from "./Accordion.module.scss"
import { ReactComponent as MinusCircleSVG } from "../../../assets/icon-minus-circle.svg"
import { ReactComponent as PlusCircleSVG } from "../../../assets/icon-plus-circle.svg"

interface AccordeonProps {
    partials: Partials
    open?: boolean
    onOpen?: () => void
    closeIcon?: ReactNode
    openIcon?: ReactNode
    animate?: boolean
}
interface Partials {
    top: React.ReactNode
    bottom: React.ReactNode
}

const Accordion: React.FC<AccordeonProps> = (props: AccordeonProps) => {
    const [open, setOpen] = useState(props.open)
    const [panelHeaderClass, setPanelHeaderClass] = useState(
        styles["accordion__header"]
    )
    const { closeIcon, openIcon, animate } = props

    useEffect(() => {
        setPanelHeaderClass(
            open
                ? classNames(
                      styles["accordion-header"],
                      styles["accordion-header--expanded"]
                  )
                : styles["accordion-header"]
        )
    }, [open])

    useEffect(() => {
        setOpen(props.open || open)
    }, [open, props.open])

    return (
        <div className={styles["accordion-wrapper"]}>
            <div
                className={panelHeaderClass}
                onClick={(): void => {
                    setOpen(!open)
                    if (props.onOpen) {
                        props.onOpen()
                    }
                }}
            >
                {props.partials.top}
                {open
                    ? closeIcon ?? (
                          <MinusCircleSVG className={styles["toggle"]} />
                      )
                    : openIcon ?? (
                          <PlusCircleSVG className={styles["toggle"]} />
                      )}
            </div>
            {open && !animate && (
                <div className={styles["accordion-content"]}>
                    {props.partials.bottom}
                </div>
            )}
            {animate && (
                <div
                    className={classNames(
                        styles["accordion-content-animated"],
                        {
                            [styles[
                                "accordion-content-animated--expanded"
                            ] as string]: open,
                            "accordion-content-animated--expanded": open,
                        }
                    )}
                >
                    <div>{props.partials.bottom}</div>
                </div>
            )}
        </div>
    )
}

export default Accordion
