import { ATTEMPTED_ROUTE } from "Constants"
import storageService from "services/storage.service"

/**
 * Get the query string value from the app start up url
 * @param key
 * @returns string | null - will check both the apps attemptedRoute and the current location
 */
export const getFromQsOrAttemptedRoute = (key: string) => {
    const attempedRoute =
        storageService.localStorage.getItem(ATTEMPTED_ROUTE) ?? "/"

    if (!isValidURL(attempedRoute)) {
        return null
    }

    const url = new URL(attempedRoute, window.location.href)
    const urlSearchParams = new URLSearchParams(window.location.search)
    return urlSearchParams.get(key) || url.searchParams.get(key)
}

/**
 * Ensure that the URL found in localstoage is a valid url
 * @param url
 * @returns true | false
 */
const isValidURL = (url: string) => {
    if (!url) return false
    try {
        new URL(url, window.location.href)
        return true
    } catch {
        return false
    }
}
