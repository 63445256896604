const login = {
    homeownerLogin: "Inicio de sesión del propietario",
    clickHere: "hagan clic aquí",
    guestsClickHereToAccessDetails: `Huéspedes, por favor {{clickHere}} para acceder a los detalles de su viaje.`,
    username: "Nombre de usuario",
    password: "Contraseña",
    forgotYourPassword: "¿Olvidaste tu contraseña?",
    login: "Inicio de sesión",
    employeeLogin: "Inicio de sesión para empleados",
    contractorLogin: "Inicio de sesión para contratistas",
}

export default login
