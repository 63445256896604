import { JSONAPIResource, Owner, Unit } from "@vacasa/owner-api-models"
import placeholderUnitImage from "assets/placeholder-home-image.png"
import {
    ActiveStatus,
    CalendarHolds,
    TerminatedStatus,
    isOnboardingUnit,
} from "./unitStatus/unitStatus"
import { zonedTimeToUtc } from "date-fns-tz"
import { isAfter } from "date-fns"
export * from "./unitStatus/unitStatus"
export * from "./onboarding/unit.onboarding.utils"

export const getUnitImage = (
    unit?: JSONAPIResource<Unit>,
    placeholder?: string
): string => {
    if (!unit) return placeholder ?? placeholderUnitImage
    return unit.attributes && unit.attributes.primaryImageID
        ? `https://d33zkqzv7i9ae0.cloudfront.net/images/400/${unit.attributes.primaryImageID}.jpg`
        : placeholderUnitImage
}

export const isUnitContractV5OrLater = (unit: JSONAPIResource<Unit>) => {
    return unit.attributes.contract.v5ContractOrLater ?? false
}

export const isUnitReserveBalanceDisabled = (
    unit: JSONAPIResource<Unit>
): boolean => {
    const { reserveBalance } = unit.attributes
    return isUnitContractV5OrLater(unit) && (reserveBalance ?? 0) === 0
}

export const getUnitReserveMinRequired = (
    unit: JSONAPIResource<Unit>,
    owner?: Owner | null
): number | undefined => {
    return isUnitContractV5OrLater(unit)
        ? owner?.reserveBalance?.minimumRequired ?? undefined
        : undefined
}

export const hasUnitReserveBalanceChanged = (
    reserveBalance: number,
    unit: JSONAPIResource<Unit>
) => {
    return (unit?.attributes.reserveBalance ?? 0) !== reserveBalance
}

export const getUnitById = (units: JSONAPIResource<Unit>[], id: string) => {
    return units.find(unit => String(unit.id) === id)
}

/**
 * Checks if the management fee for a contract is set. If it's set then it's a fixed rent contract
 * @param unit
 * @returns boolean
 */
export const isFixedRentUnit = (unit?: JSONAPIResource<Unit> | null) => {
    if (!unit) return false
    const { managementFeeFixed } = unit.attributes.contract
    return managementFeeFixed !== null && managementFeeFixed !== undefined
}

export const getCalendarHoldsStatus = (
    unit?: JSONAPIResource<Unit> | null
): CalendarHolds => {
    if (!unit) return CalendarHolds.NotAllowedPlaceholder

    const { active, terminated, contract, timezone } = unit.attributes
    const tz = timezone ?? "America/Los_Angeles"
    const endDate = zonedTimeToUtc(contract?.endDate, tz)

    // calendar holds are not allowed for units that are:
    // terminated or inactive or
    // contract has expired (end date is in the past)
    switch (true) {
        case isAfter(new Date(), endDate) ||
            terminated === TerminatedStatus.Terminated:
            return CalendarHolds.NotAllowedInactive
        case !contract || active === ActiveStatus.Inactive:
            return CalendarHolds.NotAllowedPlaceholder
        default:
            return CalendarHolds.Allowed
    }
}

export const getOnboardingUnits = (units?: JSONAPIResource<Unit>[]) => {
    if (!units) return []
    return units.filter(isOnboardingUnit)
}
