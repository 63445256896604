import classnames from "classnames"
import React, { Fragment, ReactNode } from "react"

import styles from "./buttons.module.scss"

export const TYPES = {
    BUTTON: {
        PRIMARY: "button--primary",
        SECONDARY: "button--secondary",
        UTILITY: "button--utility",
        GHOST: "button--ghost",
        ALERTNESS: "button--alertness",
        DISABLED: "button--disabled",
    },
    BUTTON__LINK: {
        SECONDARY: "button__link--secondary",
        UTILITY: "button__link--utility",
        ALERTNESS: "button__link--alertness",
    },
}

export const SIZES = {
    SMALL: "button--small",
    MEDIUM: "button--medium",
    LARGE: "button--large",
    HERO: "button--hero",
}

export interface BaseButtonProps {
    type?: "submit" | "button" | "reset" | undefined
    typeOf?: string
    onClick?:
        | ((e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
        | undefined
    className?: string
    disabled?: boolean
    buttonType?: string
    buttonSize?: string
    iconDirection?: string
    isLoading?: boolean
    loader?: JSX.Element
    selected?: boolean
    ariaLabel?: string
    tabIndex?: number
    href?: string
    target?: string
    children?: ReactNode
    inlineStyle?: { [key: string]: string | number }
    id?: string
    dataTestId?: string
}

const BaseButton: React.FC<BaseButtonProps> = (props: BaseButtonProps) => {
    const classNames = classnames(
        props.className ? props.className : "",
        props.typeOf === "link" ? styles.button__link : styles.button,
        styles[props.buttonType || TYPES.BUTTON.PRIMARY],
        props.buttonSize
            ? styles[`button--${props.buttonSize}`]
            : styles[SIZES.MEDIUM],
        props.disabled ? styles["button--disabled"] : "",
        props.iconDirection
            ? styles[`button__icon--${props.iconDirection}`]
            : ""
    )
    const className = props.selected
        ? classnames(classNames, styles["button--selected"])
        : classNames

    const commonProps = {
        disabled: props.disabled,
        className: className,
        tabIndex: props.tabIndex,
        onClick: props.isLoading ? undefined : props.onClick,
        style: props.inlineStyle,
        id: props.id,
        type: props.type,
    }

    const content = (
        <>
            <span
                style={
                    props.isLoading
                        ? { opacity: "0" }
                        : { opacity: "100", position: "relative" }
                }
            >
                {props.children}
            </span>
            {props.isLoading && props.loader}
        </>
    )

    const wrappedContent = (
        <button
            aria-label={props.ariaLabel}
            data-testid={props.dataTestId}
            {...commonProps}
        >
            {content}
        </button>
    )

    return <Fragment>{wrappedContent}</Fragment>
}

export default BaseButton

export const Primary = (props: BaseButtonProps): JSX.Element => (
    <BaseButton {...props} buttonType={TYPES.BUTTON.PRIMARY} />
)

export const Secondary = (props: BaseButtonProps): JSX.Element => (
    <BaseButton {...props} buttonType={TYPES.BUTTON.SECONDARY} />
)

export const Utility = (props: BaseButtonProps): JSX.Element => (
    <BaseButton {...props} buttonType={TYPES.BUTTON.UTILITY} />
)

export const Ghost = (props: BaseButtonProps): JSX.Element => (
    <BaseButton {...props} buttonType={TYPES.BUTTON.GHOST} />
)

export const Alertness = (props: BaseButtonProps): JSX.Element => (
    <BaseButton {...props} buttonType={TYPES.BUTTON.ALERTNESS} />
)

export const LinkSecondary = (props: BaseButtonProps): JSX.Element => (
    <BaseButton {...props} buttonType={TYPES.BUTTON__LINK.SECONDARY} />
)

export const LinkUtility = (props: BaseButtonProps): JSX.Element => (
    <BaseButton {...props} buttonType={TYPES.BUTTON__LINK.UTILITY} />
)

export const LinkAlertness = (props: BaseButtonProps): JSX.Element => (
    <BaseButton {...props} buttonType={TYPES.BUTTON__LINK.ALERTNESS} />
)
