import { IntlShape } from "react-intl"
import * as yup from "yup"

const agreeAndSign = (intl: IntlShape) =>
    yup.object({
        agreeToTerms: yup.boolean().oneOf(
            [true],
            intl.formatMessage({
                id: "Taxes.w9.forms.agreeAndSign.validation.agreeToTermsRequired",
                defaultMessage: "Please agree to our terms of agreement",
            })
        ),
        signature: yup
            .string()
            .required(
                intl.formatMessage({
                    id: "Taxes.w9.forms.agreeAndSign.validation.signatureRequired",
                    defaultMessage: "Please enter your signature",
                })
            )
            .max(
                50,
                intl.formatMessage({
                    id: "Taxes.w9.forms.agreeAndSign.validation.signatureMaxLength",
                    defaultMessage: "Signature cannot exceed 50 letters",
                })
            ),
    })

const mailingAddress = (intl: IntlShape) => {
    return yup.object({
        addressLine1: yup.string().required(
            intl.formatMessage({
                id: "Taxes.w9.forms.mailingAddress.validation.addressRequired",
                defaultMessage: "Address is required",
            })
        ),
        addressLine2: yup.string(),

        city: yup.string().required(
            intl.formatMessage({
                id: "Taxes.w9.forms.mailingAddress.validation.cityRequired",
                defaultMessage: "Please enter city",
            })
        ),

        stateOrProvince: yup.string().required(
            intl.formatMessage({
                id: "Taxes.w9.forms.mailingAddress.validation.provinceRequired",
                defaultMessage: "State/Province is required",
            })
        ),

        postalCode: yup
            .string()
            .required(
                intl.formatMessage({
                    id: "Taxes.w9.forms.mailingAddress.validation.postalCodeRequired",
                    defaultMessage: "Postal code is required",
                })
            )
            .test(
                "5-digit",
                intl.formatMessage({
                    id: "Taxes.w9.forms.mailingAddress.validation.postalCodeMaxLength",
                    defaultMessage: "Postal code must be 5 digit length",
                }),
                value => /^\d{5}$/.test(value as string)
            )
            .test(
                "not-zero",
                intl.formatMessage({
                    id: "Taxes.w9.forms.mailingAddress.validation.postalCodeAllZero",
                    defaultMessage: "Postal code cannot be all 0",
                }),
                value => Number(value) > 0
            ),
    })
}

const taxClassification = (intl: IntlShape) =>
    yup.object({
        type: yup
            .string()
            .required("Please select a tax classification")
            .test("valid", "Tax classification invalid", value =>
                /^(INP|LLC|CCO|SCO|PRT|TRE|OTH)$/.test(value as string)
            ),

        llcType: yup.string().when("type", {
            is: "LLC",
            then: () =>
                yup
                    .string()
                    .required("LLC type required")
                    .test("valid", "LLC type required", value =>
                        /^(C|S|P)$/.test(value as string)
                    ),
            otherwise: () => yup.string().length(0, "LLC type must be empty"),
        }),
    })

const taxInfo = (intl: IntlShape) =>
    yup.object({
        entityName: yup.string().required(
            intl.formatMessage({
                id: "Taxes.w9.forms.taxInfo.validation.entityNameRequired",
                defaultMessage: "Entity name is required",
            })
        ),
        businessName: yup.string().nullable(),
        taxIDType: yup
            .string()
            .required(
                intl.formatMessage({
                    id: "Taxes.w9.forms.taxInfo.validation.taxIDTypeRequired",
                    defaultMessage: "Tax ID type is required",
                })
            )
            .test(
                "valid",
                intl.formatMessage({
                    id: "Taxes.w9.forms.taxInfo.validation.taxIDTypeValid",
                    defaultMessage: "Tax ID type must be either SSN or EIN",
                }),
                value => /^(SSN|EIN)$/.test(value as string)
            )
            .when("taxClassification.type", {
                is: (value: string) =>
                    value === "LLC" ||
                    value === "CCO" ||
                    value === "SCO" ||
                    value === "PRT",
                then: () =>
                    yup.string().test(
                        "must-have-value-EIN",
                        intl.formatMessage({
                            id: "Taxes.w9.forms.taxInfo.validation.taxIDTypeEIN",
                            defaultMessage: "Tax ID type must be EIN",
                        }),
                        value => value === "EIN"
                    ),
            }),
        SSN: yup.string().when("taxIDType", {
            is: "SSN",
            then: () =>
                yup
                    .string()
                    .required(
                        intl.formatMessage({
                            id: "Taxes.w9.forms.taxInfo.validation.ssnRequired",
                            defaultMessage: "SSN is required",
                        })
                    )
                    .length(
                        11,
                        intl.formatMessage({
                            id: "Taxes.w9.forms.taxInfo.validation.ssnLength",
                            defaultMessage:
                                "SSN must be 9 digit in XXX-XX-XXXX format",
                        })
                    )
                    .test(
                        "in correct format",
                        intl.formatMessage({
                            id: "Taxes.w9.forms.taxInfo.validation.ssnInvalid",
                            defaultMessage:
                                "SSN must be 9 digit in XXX-XX-XXXX format",
                        }),
                        value => /^\d{3}-\d{2}-\d{4}$/.test(value as string)
                    )
                    .test(
                        "not-zero",
                        intl.formatMessage({
                            id: "Taxes.w9.forms.taxInfo.validation.ssnAllZero",
                            defaultMessage: "SSN cannot be all 0",
                        }),
                        value => value !== "000-00-0000"
                    ),
            otherwise: () =>
                yup.string().length(
                    0,
                    intl.formatMessage({
                        id: "Taxes.w9.forms.taxInfo.validation.ssnEmpty",
                        defaultMessage: "SSN must be empty",
                    })
                ),
        }),
        EIN: yup.string().when("taxIDType", {
            is: "EIN",
            then: () =>
                yup
                    .string()
                    .required(
                        intl.formatMessage({
                            id: "Taxes.w9.forms.taxInfo.validation.einRequired",
                            defaultMessage: "EIN is required",
                        })
                    )
                    .length(
                        10,
                        intl.formatMessage({
                            id: "Taxes.w9.forms.taxInfo.validation.einLength",
                            defaultMessage:
                                "EIN must be 9 digit in XX-XXXXXXX format",
                        })
                    )
                    .test(
                        "in correct format",
                        intl.formatMessage({
                            id: "Taxes.w9.forms.taxInfo.validation.einInvalid",
                            defaultMessage:
                                "EIN must be 9 digit in XX-XXXXXXX format",
                        }),
                        value => /^\d{2}-\d{7}$/.test(value as string)
                    )
                    .test(
                        "not-zero",
                        intl.formatMessage({
                            id: "Taxes.w9.forms.taxInfo.validation.einAllZero",
                            defaultMessage: "EIN cannot be all 0",
                        }),
                        value => value !== "00-0000000"
                    ),
            otherwise: () =>
                yup.string().length(
                    0,
                    intl.formatMessage({
                        id: "Taxes.w9.forms.taxInfo.validation.einEmpty",
                        defaultMessage: "EIN must be empty",
                    })
                ),
        }),
        entityType: yup.string().when("taxClassification.type", {
            is: "OTH",
            then: () =>
                yup.string().required(
                    intl.formatMessage({
                        id: "Taxes.w9.forms.taxInfo.validation.entityTypeRequired",
                        defaultMessage: "Entity type is required",
                    })
                ),
            otherwise: () =>
                yup.string().length(
                    0,
                    intl.formatMessage({
                        id: "Taxes.w9.forms.taxInfo.validation.entityTypeEmpty",
                        defaultMessage: "Entity type must be empty",
                    })
                ),
        }),
    })

export const w9 = {
    agreeAndSign,
    mailingAddress,
    taxClassification,
    taxInfo,
}
