import { FC } from "react"

import { EmptyState } from "components/EmptyState"
import { ReactComponent as EmptyStateImage } from "../PerformanceEmptyState.svg"
import { FormattedMessage } from "react-intl"

export const PerformanceEmptyState: FC = (): JSX.Element => (
    <EmptyState
        image={<EmptyStateImage />}
        title={
            <FormattedMessage
                id="Performance.emptyState.title"
                defaultMessage="Revenue Data"
            />
        }
        description={
            <FormattedMessage
                id="Performance.emptyState.description"
                defaultMessage="Year-to-date gross revenue data will appear here once you receive reservations."
            />
        }
    />
)
