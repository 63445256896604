const petsAllowed = {
    title: "Accepter les chiens",
    ariaLabel: "Cliquez pour accepter les chiens",
    description:
        "Accepter les chiens dans votre logement pour toucher un plus vaste public. Nous fournissons les articles nécessaires et nous assurons que votre maison est toujours bien entretenue.",
    learnMore: "En savoir plus",
    earnMore: "Jusqu'à 20 % de revenus en plus",
    earnMoreDescription:
        "En acceptant les chiens, vous pouvez considérablement augmenter vos revenus et votre taux d'occupation, surtout en période hors saison.",
    untitledHome: "Logement sans nom",
    showAllRentals: "Afficher toutes les locations",
    showAllAriaLabel: "Utilisez ce bouton pour afficher toutes vos locations.",
    hideRentals: "Masquer les locations",
    allowed: "Acceptés",
    allow: "Accepter",
    modalHeader: "Voilà, c'est fait !",
    modalText:
        "Votre fiche descriptive est en cours de mise à jour. Votre équipe locale sera avertie afin de pouvoir préparer votre logement en conséquence.  Un délai de 24 heures peut être nécessaire pour la mise à jour de cette modification sur toutes vos fiches descriptives et plateformes commerciales.",
    modalFooter: "Cliquez sur ce bouton pour fermer le module",
    ok: "OK",
    disclaimer:
        "Un délai de 24 heures peut être nécessaire pour la mise à jour de cette modification sur toutes vos fiches descriptives et plateformes commerciales. Veuillez contacter l'agent responsable de votre location pour toute question ou si vous ne souhaitez plus accepter les chiens.",
}

export default petsAllowed
