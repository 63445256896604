import {useMutation, UseMutationResult, useQueryClient} from "react-query";

import type {AxiosError} from "axios";

import {postTicketCommentReadReceipt} from "./postTicketCommentReadReceipt";

import {useContactId} from "../user";
import {TICKETS_QUERY_KEY} from "./useTickets";

interface TicketCommentReadReceiptMutationParam {
    unitId: string
    commentIds: string[];
}

const useTicketCommentReadReceipt = ():UseMutationResult<
    number,
    AxiosError,
    TicketCommentReadReceiptMutationParam,
    void
> =>{
    const contactId = useContactId()
    const queryClient = useQueryClient()
    return useMutation<
        number,
        AxiosError,
        TicketCommentReadReceiptMutationParam,
        void
    >(
        (
            ticketCommentReadReceiptMutationParam: TicketCommentReadReceiptMutationParam
        ): Promise<number> =>
            postTicketCommentReadReceipt({
                contactId: contactId ?? "",
                unitId: ticketCommentReadReceiptMutationParam.unitId,
                commentIds: ticketCommentReadReceiptMutationParam.commentIds,
            }),
        {
            onSuccess: async (data,variables) => {
                await queryClient.invalidateQueries([
                    TICKETS_QUERY_KEY,
                    variables.unitId
                ])
            },
        }
    )
}

export {useTicketCommentReadReceipt}