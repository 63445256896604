import { useEffect, useRef } from "react"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function usePrevious<T>(value: T): T | undefined {
    const ref = useRef<T>()
    useEffect(() => {
        ref.current = value
    })
    return ref.current
}
