import { JSONAPIDocument } from "@vacasa/owner-api-models"
import { authAxios } from "../../api/authAxios"

export type HomeAvailabilityResponse = { ticketId?: string }

type Response = JSONAPIDocument<HomeAvailabilityResponse>

type Request = {
    unitId: number
    signal?: AbortSignal
}

const fetchHomeAvailability = ({
    unitId,
    signal,
}: Request): Promise<HomeAvailabilityResponse> =>
    authAxios
        .get<Response>(`/v1/owners/units/${unitId}/home-availability`, {
            signal,
        })
        .then(response => response.data.data.attributes)

export { fetchHomeAvailability }
