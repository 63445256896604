const petsAllowed = {
    title: "Permitir perros",
    ariaLabel: "Haz clic en el botón para permitir mascotas.",
    description:
        "Incluir en tu anuncio que es apto para mascotas, nos permite ofrecer tu casa a una audiencia mayor. Brindamos productos para perros y te garantizamos que tu casa estará bien cuidada.",
    learnMore: "Más información",
    earnMore: "Obtén hasta un 20% más",
    earnMoreDescription:
        "Permitir mascotas puede aumentar tus ingresos y ocupación de manera significativa, especialmente fuera de temporada.",
    untitledHome: "Casa sin título",
    showAllRentals: "Mostrar todas las propiedades",
    showAllAriaLabel: "usa este botón para mostrar todas tus propiedades",
    hideRentals: "Ocultar propiedades",
    allowed: "Se permiten",
    allow: "Permitir",
    modalHeader: "¡Fantástico! Ya has terminado.",
    modalText:
        "Tu anuncio se está actualizando y nuestro equipo local será notificado para asegurar que tu casa esté lista para recibir perros. Puede llevar hasta 24 horas para que este cambio se refleje en todos tus anuncios y canales de marketing.",
    modalFooter: "Haz clic en este botón para cerrar la ventana modal.",
    ok: "OK",
    disclaimer:
        "Los cambios pueden llevar hasta 24 horas para reflejarse en todos tus anuncios y canales de marketing. Comunícate con el contacto para tu propiedad si tienes preguntas o ya no deseas permitir perros.",
}

export default petsAllowed
