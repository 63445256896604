import React, { PropsWithChildren, useEffect } from "react"
import { X } from "react-feather"
import { useNavigate } from "react-router-dom"
import style from "./flyout.module.scss"
import classNames from "classnames"

export const Flyout: React.FC<
    PropsWithChildren<{
        goBackOnClose?: boolean
        closeTo?: string
        closeOnClickOutside?: boolean
        onClose?: () => void
        hideCloseButton?: boolean
        conClassName?: string
        contentClassName?: string
    }>
> = ({
    children,
    goBackOnClose,
    closeTo,
    closeOnClickOutside,
    onClose,
    hideCloseButton,
    conClassName,
}): JSX.Element => {
    const navigate = useNavigate()

    const close = () => {
        if (goBackOnClose) {
            navigate(-1)
        } else if (closeTo) {
            navigate({
                pathname: closeTo,
            })
        }

        if (onClose) {
            onClose()
            document.body.style.overflow = "auto"
        }
    }

    const backdropClick = () => {
        if (closeOnClickOutside) {
            close()
        }
    }

    const hideBodyScrollBar = () => {
        document.body.style.overflow = "hidden"
    }

    useEffect(() => {
        hideBodyScrollBar()
    }, [])

    return (
        <>
            <button
                aria-hidden="true"
                className={style.flyoutBackdrop}
                onClick={backdropClick}
                data-testid="flyout-backdrop"
            />
            <div
                className={`${style.flyoutContainer} ${conClassName} flyoutContainer`}
                data-testid="Flyout_Container"
            >
                {!hideCloseButton && (
                    <div
                        className={classNames(
                            style.closeFlyoutContainer,
                            "flyoutCloseContainer"
                        )}
                    >
                        <button
                            id="closeFlyoutButton"
                            className={style.closeFlyout}
                            onClick={close}
                            data-testid="close-flyout-button"
                        >
                            <X aria-label="Close flyout" />
                        </button>
                    </div>
                )}
                <div
                    className={classNames(style.flyoutContent, "flyoutContent")}
                >
                    {children}
                </div>
            </div>
        </>
    )
}
