/* eslint-disable @typescript-eslint/no-explicit-any */
import { isEmpty } from "lodash"
import React, { useEffect, useState } from "react"
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl"

// Components
import { Blurb } from "components/Blurb"
import { LinkSecondary } from "../../Buttons/BaseButton"
import TempLockAccordionPartialTop from "../../TempLockPartials/tempLockAccordionPartialTop"
import GuestReviewListItem from "./GuestReviewListItem/GuestReviewListItem"
import StaticList from "./StaticList"
import StaticListItem from "./StaticListItem/StaticListItem"
import StaticListSubItem from "./StaticListSubItem"

// Assets
import { ReactComponent as DollarSignIcon } from "./../../../../assets/icon-dollar-sign.svg"
import { ReactComponent as LockIcon } from "./../../../../assets/icon-lock.svg"
import { ReactComponent as NotesIcon } from "./../../../../assets/icon-notes.svg"
import { ReactComponent as SprayBottleIcon } from "./../../../../assets/icon-spray-bottle.svg"
import { ReactComponent as UsersIcon } from "./../../../../assets/icon-users.svg"
import { ReactComponent as InheritedIcon } from "./../../../../assets/inherited.svg"

import { DYNAMIC_PRICING_URL } from "../../../../Constants"
import styles from "./flyOutReservation.module.scss"

// Utils
import {
    getVacasaHoldTypeIcon,
    isOwnerHoldType,
    parseReservationSubType,
} from "../../../../utils/holdType/holdTypeUtil"
import {
    bookingName,
    getGuestSummary,
    getReservationExternalNotes,
    reservationHasEnded,
    showNetRentOnFlyout,
} from "../../../../utils/reservations/reservationsUtil"

// Services
import {
    JSONAPIResource,
    OwnerHoldType,
    Reservation,
    ReservationType,
    Review,
    Unit,
    VacasaHoldType,
} from "@vacasa/owner-api-models"
import {
    differenceInDays,
    format,
    isBefore,
    isValid,
    parseISO,
    startOfToday,
} from "date-fns"
import { useOwnerFeatureFlag } from "hooks/owner-feature-flag"
import { ReactComponent as PlusCircleSVG } from "../../../../assets/icon-plus-circle.svg"
import { useReviews } from "../../../../hooks/review"
import { GuestReservationType } from "../../../../models/GuestReservationType"
import { HighBookingProbabilityMessage } from "../../../../views/calendar/ReservationFlyout/BookingProbabilityMessage/BookingProbabilityMessage"
import RentBreakdownListItem from "./RentInfoListItem/RentBreakdownListItem"
import { inheritedMessages } from "./messages"

import classnames from "classnames"
import useRouter from "hooks/common/useRouter"
import { useReservationGuests } from "hooks/reservation-guests/useReservationGuests"
import { ButtonType, MUIButton } from "lib/components/Buttons/MUIButton"
import { trackReservationDetailsLearnAboutRates } from "services/segment/reservation/reservationTracking"
import ReservationDate from "./ReservationDate"
import { isFeatureFlagEnabled } from "utils/feature-flag"
import RentInfoListItem from "./RentInfoListItem/RentInfoListItem"
import { useContactId } from "hooks/user"
import { Chip } from "components/Chip"
import SVGIcon from "lib/components/SVGIcon"
import { OwnerHoldBadge, ReservationBadge } from "lib/components/Badge"
import HoldType from "./HoldType"
import InviteGuestContent from "./InviteGuestItem/InviteGuestContent"
import { PhoneInput } from "./PhoneInput/PhoneInput"

interface Props {
    reservation: JSONAPIResource<Reservation>
    unitInfo: {
        isFixedRent: boolean
        isCurrentUnitActive: boolean
        allowHolds: boolean
        unit: JSONAPIResource<Unit>
    }
    currency?: string
    inviteGuest?: () => void
}

const elockActiveStatus = {
    inactive: 0,
    // legacy: no idea what it does. No documentation, and the entire db has 27 records,
    // all created before 2019, with a terminated status
    legacy: 1,
    active: 2,
} as const

const ReservationStaticDisplay: React.FC<Props> = props => {
    const intl = useIntl()
    const router = useRouter()
    const { reservation, unitInfo, currency, inviteGuest } = props
    const [review, setReview] = useState<Review>()
    const [shouldShowNetRent, setShouldShowNetRent] = useState(false)
    const [eLock, setELock] = useState<JSX.Element | null>(null)
    const contactId = useContactId()
    const useReviewQuery = useReviews(reservation.id, contactId || "")
    const ownerHoldsV3 = useOwnerFeatureFlag("owner-portal-add-phone-number-in-owner-hold-form")
    const showReservationNetRentFeatureFlagQuery = useOwnerFeatureFlag(
        "show-reservation-net-rent"
    )
    const inheritedFeatureFlag = useOwnerFeatureFlag(
        "owner-portal-guest-reservation-inherited"
    )
    const showInheritedTag = inheritedFeatureFlag.isEnabled

    const nightsOccupiedModalFeatureFlag = useOwnerFeatureFlag(
        "nights-occupied-modal"
    )
    const nightsOccupiedModalEnabled = isFeatureFlagEnabled(
        nightsOccupiedModalFeatureFlag
    )

    const holdType = parseReservationSubType(reservation)

    const guestsQuery = useReservationGuests(
        reservation.id,
        reservation.attributes.occupancyType === ReservationType.OWNERHOLD
    )

    useEffect(() => {
        if (useReviewQuery.data && useReviewQuery.data.length) {
            // first review
            setReview(useReviewQuery.data[0]?.attributes)
        }
        const showReservationNetRentEnabled =
            showReservationNetRentFeatureFlagQuery.clientReady &&
            showReservationNetRentFeatureFlagQuery.isEnabled
        setShouldShowNetRent(
            showNetRentOnFlyout(reservation, showReservationNetRentEnabled)
        )
    }, [
        reservation,
        showReservationNetRentFeatureFlagQuery.isEnabled,
        showReservationNetRentFeatureFlagQuery.clientReady,
        useReviewQuery.data,
    ])

    useEffect(() => {
        // prevent fetching TempSmartLockCode for multiple times
        const generateElockCode = (): JSX.Element | null => {
            const hasElock =
                unitInfo.unit.attributes.eLockActive ===
                elockActiveStatus.active
            const stayEnded = reservationHasEnded(reservation.attributes)
            if (hasElock && !stayEnded) {
                const lockPartial = (
                    <TempLockAccordionPartialTop
                        reservation={reservation}
                        unit={unitInfo.unit}
                    />
                )
                return (
                    <StaticListItem
                        title={
                            <FormattedMessage
                                id="CalendarPage.flyout.tempAccessCode"
                                defaultMessage="Temporary access code"
                            />
                        }
                        subtext={lockPartial}
                        Icon={LockIcon}
                    />
                )
            }
            return null
        }
        setELock(generateElockCode())
    }, [reservation, unitInfo.unit])

    const getHoldTypeTranslation = (
        holdType: OwnerHoldType | GuestReservationType | VacasaHoldType
    ) => {
        switch (holdType) {
            case "vacasa hold":
                return intl.formatMessage({
                    id: "CalendarPage.flyout.vacasaHold",
                    defaultMessage: "Vacasa hold",
                })
            case GuestReservationType.GUEST_RESERVATION:
                return intl.formatMessage({
                    id: "CalendarPage.flyout.reservation",
                    defaultMessage: "Reservation",
                })
            case "owner stay":
                return intl.formatMessage({
                    id: "CalendarPage.flyout.ownerStay",
                    defaultMessage: "Owner stay",
                })
            case "complimentary stay":
                return intl.formatMessage({
                    id: "CalendarPage.flyout.complimentaryStay",
                    defaultMessage: "Complimentary stay",
                })
            case "property care":
                return intl.formatMessage({
                    id: "CalendarPage.flyout.propertyCare",
                    defaultMessage: "Property care",
                })
            case "seasonal hold":
                return intl.formatMessage({
                    id: "CalendarPage.flyout.seasonalHold",
                    defaultMessage: "Seasonal hold",
                })
            case "home for sale":
                return intl.formatMessage({
                    id: "CalendarPage.flyout.homeForSale",
                    defaultMessage: "Home for sale",
                })
            case "block":
                return intl.formatMessage({
                    id: "CalendarPage.flyout.block",
                    defaultMessage: "Block",
                })
            case "termination hold":
                return intl.formatMessage({
                    id: "CalendarPage.flyout.terminationHold",
                    defaultMessage: "Termination hold",
                })
            case "other":
            default:
                return intl.formatMessage({
                    id: "CalendarPage.flyout.other",
                    defaultMessage: "Other",
                })
        }
    }

    function generateStayType(
        reservation: JSONAPIResource<Reservation>
    ): JSX.Element {
        // reservation static display
        const resType = reservation.attributes.occupancyType
        const isReservationCancelled = reservation.attributes.cancelled
        const creationDate = reservation.attributes.creationDate
            ? format(parseISO(reservation.attributes.creationDate), "M/d/yyyy")
            : null
        let subtext = (
            <FormattedMessage
                id="Calendar.flyout.madeOn"
                defaultMessage="Booked on {creationDate}"
                values={{
                    creationDate,
                }}
            />
        )
        if (isReservationCancelled) {
            subtext = (
                <Chip
                    size="small"
                    icon={
                        <SVGIcon
                            svg={`icon-feather-calendar-x`}
                            className="margin-right-font-spacing"
                            colorName="info-70"
                        />
                    }
                    label={
                        <FormattedMessage
                            id="Calendar.flyout.cancelledOn"
                            defaultMessage="Cancelled {date, date, short}"
                            values={{
                                date: parseISO(
                                    reservation.attributes.cancellationDate ??
                                        ""
                                ),
                            }}
                        />
                    }
                />
            )
        }
        if (resType === ReservationType.RESERVATION) {
            const Tag = showInheritedTag &&
                reservation.attributes.inherited && (
                    <ReservationBadge type="inherited" />
                )
            return (
                <HoldType
                    title={
                        <FormattedMessage
                            id="CalendarPage.reservationType.reservation"
                            defaultMessage="Guest Stay"
                        />
                    }
                    subtext={subtext}
                    primary
                    {...(Tag && { Tag })}
                />
            )
        } else {
            const holdType:
                | OwnerHoldType
                | VacasaHoldType
                | GuestReservationType =
                resType === ReservationType.VACASAHOLD
                    ? "vacasa hold"
                    : parseReservationSubType(reservation)

            const ownerHoldType = resType === ReservationType.OWNERHOLD

            const holdInfo = reservation.attributes.ownerHold
            if (resType === ReservationType.VACASAHOLD) {
                subtext = (
                    <FormattedMessage
                        id="CalendarPage.flyout.madeBy"
                        defaultMessage="Booked on {date} by {name}"
                        values={{
                            date: creationDate,
                            name: "Vacasa",
                        }}
                    />
                )
            } else if (!isEmpty(holdInfo?.holdWhoBooked)) {
                subtext = (
                    <FormattedMessage
                        id="CalendarPage.flyout.madeBy"
                        defaultMessage="Booked on {date} by {name}"
                        values={{
                            date: creationDate,
                            name: holdInfo?.holdWhoBooked,
                        }}
                    />
                )
            }

            let pill = null
            if (resType === ReservationType.VACASAHOLD) {
                const vacasaHoldSubType =
                    reservation.attributes.vacasaHold?.holdType
                const VacasaHoldSubTypeIcon =
                    getVacasaHoldTypeIcon(vacasaHoldSubType)

                pill =
                    vacasaHoldSubType && VacasaHoldSubTypeIcon ? (
                        <div
                            className={
                                styles["vacasa-hold-subtype-pill-static"]
                            }
                        >
                            {VacasaHoldSubTypeIcon && (
                                <VacasaHoldSubTypeIcon
                                    className={styles["icon"]}
                                />
                            )}
                            <span className={styles["label"]}>
                                {vacasaHoldSubType.toUpperCase()}
                            </span>
                        </div>
                    ) : null
            }

            const ownerHoldTag =
                resType === ReservationType.OWNERHOLD &&
                isOwnerHoldType(holdType)
                    ? OwnerHoldBadge({ type: holdType })
                    : null

            return (
                <HoldType
                    title={
                        ownerHoldType ? (
                            <FormattedMessage
                                id="Calendar.ownerHolds.ownerHold"
                                defaultMessage="Owner hold"
                            />
                        ) : (
                            getHoldTypeTranslation(holdType)
                        )
                    }
                    subtext={subtext}
                    pill={pill}
                    primary
                    Tag={ownerHoldTag}
                />
            )
        }
    }

    function generateDateDisplay(
        reservation: JSONAPIResource<Reservation>
    ): JSX.Element {
        const formatDate = (date: Date, dateFormat: string): string => {
            if (!isValid(date)) {
                return ""
            }
            return format(date, dateFormat)
        }

        const startDate = parseISO(reservation.attributes.startDate ?? "")
        const endDate = parseISO(reservation.attributes.endDate ?? "")
        const startDay = formatDate(startDate, "dd")
        const endDay = formatDate(endDate, "dd")
        const startMonth = formatDate(startDate, "MMM")
        const endMonth = formatDate(endDate, "MMM")
        const startYear = formatDate(startDate, "yy")
        const endYear = formatDate(endDate, "yy")
        const occupancyType = reservation.attributes.occupancyType

        let dateDisplay = `${startMonth} ${startDay} - ${endMonth} ${endDay}'${endYear}`

        if (startYear !== endYear) {
            dateDisplay = `${startMonth} ${startDay}'${startYear} - ${endMonth} ${endDay}'${endYear}`
        }
        const nightCount = differenceInDays(endDate, startDate)
        const displayProbabilityMessage =
            occupancyType === ReservationType.OWNERHOLD &&
            reservation.attributes.isHighBookingProbability

        return (
            <>
                <ReservationDate
                    title={dateDisplay}
                    subtext={
                        <span>
                            {nightCount === 1
                                ? `${nightCount} ${intl.formatMessage({
                                      id: "CalendarPage.flyout.night",
                                      defaultMessage: "night",
                                  })}`
                                : `${nightCount} ${intl.formatMessage({
                                      id: "CalendarPage.flyout.nights",
                                      defaultMessage: "nights",
                                  })}`}
                        </span>
                    }
                    showMonthCount={
                        shouldShowNetRent &&
                        nightsOccupiedModalEnabled &&
                        holdType === GuestReservationType.GUEST_RESERVATION
                    }
                    monthCount={
                        reservation.attributes.monthlyRentBreakdown.length
                    }
                />
                {displayProbabilityMessage && (
                    <HighBookingProbabilityMessage
                        className={styles.bookingProbabilityMessageOffset}
                    />
                )}
            </>
        )
    }

    function generateHousekeepingOption(
        reservation: JSONAPIResource<Reservation>
    ): JSX.Element {
        const houseKeeping = reservation.attributes.cleanAfterStay
        const houseKeepingLabel = houseKeeping
            ? intl.formatMessage({
                  id: "CalendarPage.OwnerHolds.yes",
                  defaultMessage: "Yes",
              })
            : intl.formatMessage({
                  id: "CalendarPage.OwnerHolds.no",
                  defaultMessage: "No",
              })

        const getCleaningFees = () => {
            if (!houseKeeping) return null
            const cleaningFees = reservation.attributes.cleaningFees
            if (cleaningFees === null) return null
            return `$${cleaningFees.toFixed(2)}`
        }

        return (
            <StaticListItem
                title={
                    <FormattedMessage
                        id="CalendarPage.flyout.housekeeping"
                        defaultMessage="Housekeeping"
                    />
                }
                subtext={<span>{getCleaningFees() ?? houseKeepingLabel}</span>}
                Icon={SprayBottleIcon}
            />
        )
    }

    function generateGuestsDisplay(
        reservation: JSONAPIResource<Reservation>
    ): JSX.Element | null {
        if (
            reservation.attributes.occupancyType === ReservationType.VACASAHOLD
        ) {
            return null
        }

        const { adults, children, pets } = reservation.attributes
        const guestSummary = getGuestSummary(adults, children, pets, intl)
        const onEditClick = (reservationId?: string, guestId?: string) => {
            router.navigate(
                {
                    pathname: `/calendar/hold/${reservationId}/invite/${guestId}`,
                    search: router.location.search,
                    hash: router.location.hash,
                },
                {
                    replace: true,
                }
            )
        }

        const guestItems = (guestsQuery.data ?? []).map((guest, i) => {
            const content = (
                <InviteGuestContent
                    firstName={guest.attributes.firstName}
                    lastName={guest.attributes.lastName}
                    email={guest.attributes.email}
                    onEditClick={() =>
                        onEditClick(
                            reservation.id?.toString(),
                            guest.attributes.guestID
                        )
                    }
                />
            )
            return (
                <StaticListSubItem
                    key={guest.attributes.guestID}
                    content={content}
                    id={`guest-${guest.attributes.guestID}`}
                />
            )
        })
        let guestDisplay = null
        if (
            reservation.attributes.occupancyType === ReservationType.OWNERHOLD
        ) {
            const inviteGuests = (
                <div className="flex items-center">
                    <MUIButton
                        type={ButtonType.xSmall_SecondaryIcon}
                        onClick={(): void => {
                            if (inviteGuest) inviteGuest()
                        }}
                        icon={<PlusCircleSVG />}
                        text={intl.formatMessage({
                            id: "CalendarPage.flyout.inviteGuests",
                            defaultMessage: "Invite Guests",
                        })}
                        containerClass={styles.inviteGuests}
                    />
                </div>
            )
            const inviteGuestsListItem = reservationHasEnded(
                reservation.attributes
            ) ? null : (
                <StaticListSubItem
                    classes={["action", "font-bold"]}
                    id={"invite_guests"}
                    content={inviteGuests}
                />
            )

            const guestDetails = [...guestItems, inviteGuestsListItem]
            guestDisplay = (
                <StaticList items={guestDetails} isEmbeddedList={true} />
            )
        }
        if (
            reservation.attributes.occupancyType === ReservationType.RESERVATION
        ) {
            guestDisplay = bookingName(reservation)

            return (
                <StaticListItem
                    title={
                        <FormattedMessage
                            id="CalendarPage.flyout.guestInfo"
                            defaultMessage="Guest info"
                        />
                    }
                    subtext={`${guestDisplay} • ${guestSummary.join(", ")}`}
                    Icon={UsersIcon}
                    customStyling={{
                        Icon: styles["reservation-flyout-icon"],
                    }}
                />
            )
        }

        return (
            <>
                <StaticListItem
                    title={
                        <FormattedMessage
                            id="CalendarPage.flyout.guestInfo"
                            defaultMessage="Guest info"
                        />
                    }
                    subtext={guestSummary.join(", ")}
                    Icon={UsersIcon}
                    customStyling={{
                        Icon: styles["reservation-flyout-icon"],
                        Wrapper: styles["guestInfo"],
                    }}
                />
                {guestDisplay}
            </>
        )
    }

    function generateRentInfo(
        reservation: JSONAPIResource<Reservation>,
        unitInfo: {
            isFixedRent: boolean
            isCurrentUnitActive: boolean
            allowHolds: boolean
            unit: JSONAPIResource<Unit>
        },
        currency: string | undefined
    ): JSX.Element {
        // While loading don't show any rent into to prevent flashing of the old and new version
        if (!nightsOccupiedModalFeatureFlag.clientReady) return <></>

        let grossRent = (
            <FormattedNumber
                value={reservation.attributes.totalRent ?? 0}
                currency={currency}
                style="currency" // eslint-disable-line
            />
        )
        const moreInfoLink = (
            <LinkSecondary
                typeOf={"link"}
                onClick={(): void => {
                    window.open(DYNAMIC_PRICING_URL, "_blank")
                    trackReservationDetailsLearnAboutRates(
                        String(reservation.id),
                        intl.formatMessage({
                            id: "CalendarPage.flyout.learnAboutRates",
                            defaultMessage: "Learn about rates",
                        })
                    )
                }}
                ariaLabel={"Click this to learn more about Dynamic Pricing."}
            >
                <FormattedMessage
                    id="CalendarPage.flyout.learnAboutRates"
                    defaultMessage="Learn about rates"
                />
            </LinkSecondary>
        )
        if (unitInfo.isFixedRent) {
            grossRent = (
                <span className={styles.linkSpan}>
                    <FormattedMessage
                        id="CalendarPage.flyout.fixedRent"
                        defaultMessage="Fixed rent"
                    />
                </span>
            )
        }
        if (shouldShowNetRent) {
            if (nightsOccupiedModalEnabled) {
                return (
                    <RentBreakdownListItem
                        reservation={reservation}
                        currency={currency}
                        customStyling={{
                            Icon: styles["reservation-flyout-icon"],
                        }}
                    />
                )
            } else {
                return (
                    <RentInfoListItem
                        reservation={reservation}
                        currency={currency}
                        link={moreInfoLink}
                        customStyling={{
                            Icon: styles["reservation-flyout-icon"],
                        }}
                    />
                )
            }
        }
        return (
            <StaticListItem
                title={
                    <FormattedMessage
                        id="CalendarPage.flyout.grossRent"
                        defaultMessage="Gross rent"
                    />
                }
                subtext={grossRent}
                Icon={DollarSignIcon}
                link={moreInfoLink}
            />
        )
    }

    function generateGuestReviews(
        reservation: JSONAPIResource<Reservation>,
        review?: Review
    ): JSX.Element {
        if (review?.displayRating && reservation.id) {
            return (
                <GuestReviewListItem
                    reservationId={reservation.id.toString()}
                    reviewText={
                        review.displayReview ? review.review : undefined
                    }
                    starRating={review.overall}
                    response={
                        review.displayReview ? review.response : undefined
                    }
                    firstName={review.firstName}
                    lastInitial={review.lastInitial}
                    reviewDate={review.reviewDate}
                />
            )
        }
        return <></>
    }

    function generateHoldNotes(
        reservation: JSONAPIResource<Reservation>
    ): JSX.Element {
        const notes =
            getReservationExternalNotes(reservation) ||
            intl.formatMessage({
                id: "CalendarPage.flyout.notes.none",
                defaultMessage: "N/A",
            })

        return (
            <StaticListItem
                title={
                    <FormattedMessage
                        id="CalendarPage.flyout.notes.title"
                        defaultMessage="Notes "
                    />
                }
                subtext={notes}
                Icon={NotesIcon}
            />
        )
    }

    function generateHeader(
        reservation: JSONAPIResource<Reservation>
    ): JSX.Element {
        const { startDate, endDate } = reservation.attributes
        const pastReservation =
            isBefore(parseISO(startDate ?? ""), startOfToday()) &&
            isBefore(parseISO(endDate ?? ""), startOfToday())
        if (pastReservation) {
            return (
                <p
                    className={classnames(
                        styles["flyout-header-label"],
                        "type-heading-small",
                        "caps"
                    )}
                >
                    <FormattedMessage
                        id="CalendarPage.flyout.pastBooking"
                        defaultMessage="Past booking"
                    />
                </p>
            )
        }
        return <></>
    }

    function informationBlurb() {
        const { inherited } = reservation.attributes
        const title = <FormattedMessage {...inheritedMessages.inheritedTitle} />
        const description = (
            <FormattedMessage {...inheritedMessages.inheritedDescription} />
        )
        return (
            (showInheritedTag && inherited && (
                <Blurb
                    title={title}
                    description={description}
                    icon={<InheritedIcon width="14" height="15" />}
                />
            )) ||
            null
        )
    }

    function generatePhoneModal() {
        // TODO: Change the type in owner-api
        const phone = (reservation.attributes as { phone?: string }).phone || ''
        return <PhoneInput phone={phone} readOnly={true} />
    }

    const header = generateHeader(reservation)
    const type = generateStayType(reservation)
    const dates = generateDateDisplay(reservation)
    const houseKeeping = generateHousekeepingOption(reservation)
    const guestsDisplay = generateGuestsDisplay(reservation)
    const holdNotes = generateHoldNotes(reservation)
    const reservationIsCancelled = reservation.attributes.cancelled
    const inheritedBlurb = informationBlurb()
    const phone = generatePhoneModal()

    let items = []
    switch (holdType) {
        case GuestReservationType.GUEST_RESERVATION:
            if (reservationIsCancelled) {
                items = [header, type, dates, guestsDisplay, inheritedBlurb]
            } else {
                items = [
                    header,
                    type,
                    dates,
                    guestsDisplay,
                    generateRentInfo(reservation, unitInfo, currency),
                    generateGuestReviews(reservation, review),
                    inheritedBlurb,
                ]
            }

            break
        case "owner stay":
            items = [type, dates, houseKeeping, eLock, guestsDisplay, holdNotes]
            if(ownerHoldsV3.isEnabled && phone){
                items.push(phone)
            }
            break
        case "complimentary stay":
            items = [type, dates, houseKeeping, eLock, guestsDisplay, holdNotes]
            break
        case "property care":
        case "seasonal hold":
            items = [type, dates, houseKeeping, eLock, holdNotes]
            break
        case "block":
        case "other":
        case "termination hold":
        case "home for sale":
        case "out of order":
        case "maintenance":
        case "photography":
        case "housekeeping":
            items = [type, dates, houseKeeping, holdNotes]
            break
        default:
            items = [type, dates, houseKeeping, eLock, guestsDisplay, holdNotes]
            break
    }

    return <StaticList items={items} />
}

export default ReservationStaticDisplay
