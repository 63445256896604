import { ReactComponent as IntroIcon } from "assets/icon-chat-intro.svg"
import classNames from "classnames"
import { FC } from "react"
import { Clock, Mail, Phone } from "react-feather"
import { FormattedMessage } from "react-intl"
import { getClassStyle } from "utils/styles/styleWrapper"
import styles from "./WelcomeMessage.module.scss"
import { format, parse } from "date-fns"

export const WelcomeMessage: FC<{
    online?: boolean
    openHours: { start: string; end: string }
}> = ({ online = true, openHours }) => {
    return (
        <div
            className={classNames(styles.container, {
                [getClassStyle(styles.containerWide)]: !online,
            })}
        >
            <IntroIcon className={styles.icon} />
            <div
                className={classNames("text-center", styles.message, "text-sm")}
            >
                {online && (
                    <FormattedMessage
                        id="Support.webChat.welcomeMessage"
                        defaultMessage="Live chat is available daily from {open} - {close} PST"
                        values={{
                            open: format(
                                parse(openHours.start, "H:mm", new Date()),
                                "haaa"
                            ),
                            close: format(
                                parse(openHours.end, "H:mm", new Date()),
                                "haaa"
                            ),
                        }}
                    />
                )}
                {!online && (
                    <>
                        <span>
                            <FormattedMessage
                                id="Support.webChat.offlineMessage"
                                defaultMessage="Unfortunately you have reached us outside of live chat hours. For immediate assistance reach out to account to the contact below"
                            />
                        </span>
                        <ul>
                            <li className="text-xs">
                                <div className={styles.iconWrap}>
                                    <Clock />
                                </div>{" "}
                                <FormattedMessage
                                    id="Support.webChat.liveChatHours"
                                    defaultMessage="{open} to {close} PST • 7 days a week"
                                    values={{
                                        open: format(
                                            parse(
                                                openHours.start,
                                                "H:mm",
                                                new Date()
                                            ),
                                            "h aaaa"
                                        ),
                                        close: format(
                                            parse(
                                                openHours.end,
                                                "H:mm",
                                                new Date()
                                            ),
                                            "h aaaa"
                                        ),
                                    }}
                                />
                            </li>
                            <li className="text-xs">
                                <div className={styles.iconWrap}>
                                    <Phone />
                                </div>{" "}
                                <a
                                    className="font-semibold text-xs"
                                    href="tel:+19712543001"
                                >
                                    +1 971 254 3001
                                </a>
                            </li>
                            <li className="text-xs">
                                <div className={styles.iconWrap}>
                                    <Mail />
                                </div>{" "}
                                <a
                                    className="font-semibold text-xs"
                                    href="mailto:homeowner.accounts@vacasa.com"
                                >
                                    homeowner.accounts@vacasa.com
                                </a>
                            </li>
                        </ul>
                    </>
                )}
            </div>
        </div>
    )
}
