const profile = {
    title: "Profile",
    updateProfile: "Update Profile",
    updateContractInfo: "Contract Information",
    update: "Save Changes",
    updatedNotification: "User profile updated",
    formFields: {
        name: "Name",
        phone: "Verification Phone",
        emailAddress: "Email Address",
        password: "Current Password",
        country: "Country",
        address: "Address 1",
        address2: "Address 2 (optional)",
        city: "City",
        state: "State",
        zipCode: "ZIP Code",
        preferredLanguage: "Preferred Language",
        nameOnContract: "Name on Contract",
        mainPhone: "Main Phone",
    },
    placeholders: {
        firstName: "First Name",
        lastName: "Last Name",
        password: "password placeholder",
        address: "House number and street name",
        address2: "Apartment, suite, unit, etc.",
        city: "Enter your city",
        zipCode: "Zipcode",
        phone: "Phone number",
        select: "Select an option",
    },
    errors: {
        firstName: "Please enter your first name",
        lastName: "Please enter your last name",
        address: "Please enter your address",
        city: "Please enter your city",
        zipCode: "Please enter your zip code",
        phone: "Please enter your phone number",
        language: "Please select your preferred language",
        state: "Please select your state",
        country: "Please select a country",
        errorFetching:
            "We had trouble loading your page. If the problem persists, please try again later",
        errorPatching:
            "We had trouble updating your page. If the problem persists, please try again later",
        tryAgainButton: "Try Refreshing",
    },
    employeeAlert:
        "<b>You are currently viewing this page as an employee.</b> We are unable to display the users first and last name from their IDP login account. Owners can view this information and edit it on this page.",
}

export default profile
