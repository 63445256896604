import classnames from "classnames"
import { FC, PropsWithChildren } from "react"
import styles from "./Layout.module.scss"

type Props = PropsWithChildren & {
    className?: string
}

const Header: FC<Props> = ({ children, className }) => (
    <div
        className={classnames(
            "reservation-flyout-layout__header",
            styles["reservation-flyout-layout__header"],
            className
        )}
    >
        {children}
    </div>
);

const Content: FC<Props> = ({
    children,
    className
}) => (
    <div
        className={classnames(
            "reservation-flyout-layout__content",
            styles["reservation-flyout-layout__content"],
            className
        )}
    >
        {children}
    </div>
)
const Footer: FC<Props & { noShadow?: boolean }> = ({
    children,
    className,
    noShadow,
}) => (
    <div
        className={classnames(
            "reservation-flyout-layout__footer",
            styles["reservation-flyout-layout__footer"],
            className,
            {
                [styles[
                    "reservation-flyout-layout__footer--no-shadow"
                ] as string]: noShadow,
            }
        )}
    >
        {children}
    </div>
)

const Layout: FC<Props> & {
    Header: FC<Props>;
    Content: FC<Props>;
    Footer: FC<Props>;
} = ({
         children,
         className,
         ...rest
     }) => {
    return (
        <div
            className={classnames(
                "reservation-flyout-layout",
                styles["reservation-flyout-layout"],
                className
            )}
            {...rest}
        >
            {children}
        </div>
    );
};

Layout.Header = Header;
Layout.Content = Content
Layout.Footer = Footer

export default Layout
