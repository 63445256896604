import { UnitAvailabilityDays } from "@vacasa/owner-api-models"
import { Dispatch, useReducer } from "react"
import { isDayBlocked } from "../validation/DateValidation"
import { DateRangeAction } from "./DateRange.actions"
import { DateRangeState, INITIAL_DATE_RANGE_STATE } from "./DateRange.state"
import { isAfter, isBefore, isValid, startOfDay } from "date-fns"

/**
 * Reducer that mutates DateRangeState
 */
export default function DateRangeReducer(
    state: DateRangeState,
    action: DateRangeAction
): DateRangeState {
    switch (action.type) {
        case "SET_DATE_RANGE_STATE":
            return {
                ...state,
                ...action.payload,
            }
        case "SET_CAN_EDIT_START_DATE":
            return {
                ...state,
                canEditStartDate: action.payload,
            }
        case "UPDATE_START_DATE":
            return validateStartDates(action.payload, state)
        case "UPDATE_END_DATE":
            return validateEndDates(state.startDate, action.payload, state)
        case "RESET_DATES":
            return {
                ...state,
                startDate: null,
                endDate: null,
                showStartDateError: false,
                showEndDateError: false,
                errorMessage: null,
                focusedInput: state.canEditStartDate ? "startDate" : null,
            }
        case "UPDATE_FOCUSED_INPUT":
            if (!state.canEditStartDate && action.payload === "startDate") {
                return {
                    ...state,
                    focusedInput: "endDate",
                }
            }
            return {
                ...state,
                focusedInput: action.payload,
            }
        case "UPDATE_ERROR_MESSAGE":
            return {
                ...state,
                errorMessage: action.payload,
            }
        default:
            return state
    }
}

export function useDateRangeState(
    initialState?: DateRangeState
): [DateRangeState, Dispatch<DateRangeAction>] {
    const [state, dispatch] = useReducer(
        DateRangeReducer,
        initialState || INITIAL_DATE_RANGE_STATE
    )
    return [state, dispatch]
}

function validateStartDates(
    startDate: Date | string | null,
    currentState: DateRangeState
): DateRangeState {
    const showStartDateError =
        currentState.canEditStartDate &&
        shouldShowStartDateError(
            startDate,
            currentState.blockAllDays,
            currentState.calendarAvailability
        )
    return {
        ...currentState,
        startDate: showStartDateError
            ? currentState.startDate
            : typeof startDate === "string"
            ? null
            : startDate,
        showStartDateError: showStartDateError,
    }
}

function validateEndDates(
    startDate: Date | null,
    endDate: Date | string | null,
    currentState: DateRangeState
): DateRangeState {
    const showEndDateError = shouldShowEndDateError(
        endDate,
        startDate,
        currentState.blockAllDays,
        currentState.calendarAvailability
    )
    return {
        ...currentState,
        endDate: showEndDateError
            ? currentState.endDate
            : typeof endDate === "string"
            ? null
            : endDate,
        showEndDateError: showEndDateError,
    }
}

function shouldShowStartDateError(
    startDate: Date | string | null,
    blockAllDays: boolean,
    calendarAvailability: UnitAvailabilityDays
): boolean {
    // If start date is cleared, hide error
    if (startDate === null) return false
    // If start date is string (invalid date), show error
    if (typeof startDate === "string") return true
    return !isDateValid(
        startDate,
        blockAllDays,
        calendarAvailability,
        startDate,
        undefined
    )
}

function shouldShowEndDateError(
    endDate: Date | string | null,
    startDate: Date | null,
    blockAllDays: boolean,
    calendarAvailability: UnitAvailabilityDays
): boolean {
    // If end date is cleared, hide error
    if (endDate === null) return false
    // If end date is string (invalid date), show error
    if (typeof endDate === "string") return true
    if (
        !isDateValid(
            endDate,
            blockAllDays,
            calendarAvailability,
            startDate,
            endDate
        )
    )
        return true
    // End date cannot be prior to start date
    return !!startDate && !isAfter(endDate, startDate)
}

/**
 * @param date
 * @param blockAllDays whether to block all calendar days
 * @param calendarAvailability available calendar days
 * @returns whether the date is valid based on the strict date formats.
 */
export function isDateValid(
    date: Date,
    blockAllDays: boolean,
    calendarAvailability: UnitAvailabilityDays,
    startDate: Date | null | undefined,
    endDate: Date | undefined
): boolean {
    return (
        isValid(date) &&
        !isBefore(date, startOfDay(new Date())) && // Cannot select prior to today
        !isDayBlocked(
            date,
            blockAllDays,
            calendarAvailability,
            startDate ? startDate : null,
            endDate ? endDate : null
        )
    )
}
