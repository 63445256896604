import {
    JSONAPIResource,
    Reservation,
    ReservationGuest,
    ReservationType,
} from "@vacasa/owner-api-models"
import classnames from "classnames"
import {
    useRemoveReservationGuest,
    useReservationGuests,
    useUpdateReservationGuest,
} from "hooks/reservation-guests"
import { Button } from "lib/components/Button/Button"
import { capitalize } from "lodash"
import {
    ButtonHTMLAttributes,
    FC,
    FormEvent,
    useCallback,
    useEffect,
    useState,
} from "react"
import { Trash2 } from "react-feather"
import { useIntl } from "react-intl"
import LoggingService from "services/logging/logging.service"
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog"
import Layout, { FooterSecondaryButton } from "../Layout/Layout"
import styles from "./EditGuestView.module.scss"
import InviteGuestForm, { useInviteGuestForm } from "./ReservationGuestForm"

interface Props {
    backToReservationView: () => void
    guestId: string
    reservation: JSONAPIResource<Reservation>
}

export const RemoveGuestButton: FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({
    className,
    ...rest
}) => {
    return (
        <button
            className={classnames(
                className,
                styles["edit-guest-view__remove-btn"],
                "font-bold text-sm text-midnight-70 leading-none"
            )}
            {...rest}
        >
            <Trash2 /> Remove Guest
        </button>
    )
}

export const EditGuestView: FC<Props> = ({
    backToReservationView,
    guestId,
    reservation,
}) => {
    const intl = useIntl()
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
    const [guest, setGuest] = useState<ReservationGuest>()
    const {
        email,
        firstName,
        isDirty,
        isValid,
        lastName,
        onChange,
        populateForm,
    } = useInviteGuestForm()

    const removeReservationGuest = useRemoveReservationGuest()
    const updateReservationGuest = useUpdateReservationGuest()
    const reservationGuests = useReservationGuests(
        reservation.id?.toString() ?? "",
        reservation.attributes.occupancyType === ReservationType.OWNERHOLD
    )

    useEffect(() => {
        if (reservationGuests.isSuccess && reservationGuests.data) {
            const currentGuest =
                getCurrentlyViewedGuest(
                    guestId,
                    reservation.id?.toString() ?? "",
                    reservationGuests.data
                ) ?? null

            if (currentGuest) {
                setGuest(currentGuest)
                populateForm(
                    currentGuest.firstName,
                    currentGuest.lastName,
                    currentGuest.email
                )
            } else {
                backToReservationView()
            }
        }
    }, [
        backToReservationView,
        guestId,
        populateForm,
        reservation.id,
        reservationGuests.data,
        reservationGuests.isSuccess,
    ])

    const onFormSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        updateReservationGuest.mutate(
            {
                reservationId: reservation.id?.toString() ?? "",
                guestId,
                guest: {
                    firstName,
                    lastName,
                    email,
                },
            },
            {
                onSuccess: () => {
                    backToReservationView()
                },
                onError: err => {
                    LoggingService.error({
                        message: "Failed to update guest",
                        err,
                    })
                },
            }
        )
    }

    const onRemoveClick = useCallback(() => {
        setShowDeleteConfirm(true)
    }, [])

    const onConfirmRemoveClick = () => {
        removeReservationGuest.mutate(
            {
                reservationId: reservation.id?.toString() ?? "",
                guestId,
                guest: {
                    firstName,
                    lastName,
                    email,
                },
            },
            {
                onSuccess: () => {
                    backToReservationView()
                },
                onError: error => {
                    LoggingService.error({
                        message: "Failed to remove owner hold guest",
                        error,
                    })
                },
            }
        )
    }

    return (
        <Layout reservationId={reservation.id?.toString()} showBackButton>
            <Layout.Content form>
                <InviteGuestForm
                    onSubmit={onFormSubmit}
                    onChange={onChange}
                    formData={{
                        firstName: firstName,
                        lastName: lastName,
                        email: email,
                    }}
                    showForm={true}
                />
                <RemoveGuestButton onClick={onRemoveClick} />
            </Layout.Content>
            <Layout.Footer>
                {!showDeleteConfirm && (
                    <>
                        <Button
                            disabled={!isValid() || !isDirty(guest)}
                            type="submit"
                            form="invite-guests-form"
                            aria-label="Click this button to add a guest"
                            variant="primary"
                            className="full-width"
                            loading={updateReservationGuest.isLoading}
                        >
                            {capitalize(
                                intl.formatMessage({
                                    id: "CalendarPage.flyout.saveChanges",
                                    defaultMessage: "Save Changes",
                                })
                            )}
                        </Button>

                        <FooterSecondaryButton
                            disabled={updateReservationGuest.isLoading}
                            onClick={backToReservationView}
                        >
                            {intl.formatMessage({
                                id: "CalendarPage.flyout.cancel",
                                defaultMessage: "Cancel",
                            })}
                        </FooterSecondaryButton>
                    </>
                )}
                {showDeleteConfirm && (
                    <ConfirmDialog
                        title="Remove Guest"
                        message="Are you sure you want to delete this?"
                        confirmText="Remove Guest"
                        onConfirmClick={onConfirmRemoveClick}
                        onCancelClick={() => setShowDeleteConfirm(false)}
                        loading={removeReservationGuest.isLoading}
                    />
                )}
            </Layout.Footer>
        </Layout>
    )
}

// Returns the currently viewed guest
const getCurrentlyViewedGuest = (
    id: string,
    reservationId: string,
    guests: JSONAPIResource<ReservationGuest>[]
) =>
    guests.find(
        guest =>
            guest.attributes.guestID === id &&
            guest.attributes.reservationID.toString() === reservationId
    )?.attributes
