import { authAxios } from "../../api/authAxios"
import { JSONAPIResource, Revenue, RevenueMeta } from "@vacasa/owner-api-models"

type FetchRevenueRequest = {
    unitId: string
    contactId: string
    year: number
    signal?: AbortSignal
}

export interface RevenueResponse {
    data: JSONAPIResource<Revenue>
    meta: RevenueMeta
}

const fetchRevenue = ({
    contactId,
    unitId,
    year,
    signal,
}: FetchRevenueRequest): Promise<RevenueResponse> =>
    authAxios
        .get<RevenueResponse>(`v1/revenue`, {
            params: {
                contactId,
                unitId,
                year,
            },
            signal,
        })
        .then(response => response.data)
        .catch(error => error.message)

export { fetchRevenue }
