import { AxiosError } from "axios"
import { useMutation, UseMutationResult } from "react-query"
import {
    putReceiveFormsElectronically,
    PutReceiveFormsElectronicallyRequest,
    PutReceiveFormsElectronicallyResponse,
} from "./putReceiveFormsElectronically"

export const useReceiveFormsElectronicallyMutation = (): UseMutationResult<
    PutReceiveFormsElectronicallyResponse,
    AxiosError,
    PutReceiveFormsElectronicallyRequest,
    void
> => {
    return useMutation<
        PutReceiveFormsElectronicallyResponse,
        AxiosError,
        PutReceiveFormsElectronicallyRequest,
        void
    >(payload => putReceiveFormsElectronically(payload))
}
