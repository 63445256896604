import { ISO_FORMAT_DATE_FNS } from "Constants"
import { endOfMonth, endOfYear, format, parseISO } from "date-fns"
import useRouter from "hooks/common/useRouter"

export const useStatementDates = () => {
    const router = useRouter()
    const params = router.location.pathname.split("/")
    const year = params[2]
    const month = params[3]

    if (!year || Number.isNaN(year)) {
        return undefined
    }

    if (month && !Number.isNaN(month)) {
        const formattedMonth = Number(month).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
        })
        const firstDay = parseISO(`${year}-${formattedMonth}-01`)
        try {
            return {
                firstDay: format(firstDay, ISO_FORMAT_DATE_FNS),
                lastDay: format(endOfMonth(firstDay), ISO_FORMAT_DATE_FNS),
                year,
                month: formattedMonth,
            }
        } catch (error) {
            return undefined
        }
    }

    const firstDay = parseISO(`${year}-01-01`)
    try {
        return {
            firstDay: format(firstDay, ISO_FORMAT_DATE_FNS),
            lastDay: format(endOfYear(firstDay), ISO_FORMAT_DATE_FNS),
            year,
        }
    } catch (error) {
        return undefined
    }
}
