import * as React from "react"
import styles from "./CoreMetricsWidget.module.scss"
import { Card, CardContent } from "../../../../components/Card"
import classNames from "classnames"
import { GeneralError } from "lib/components/GeneralError/GeneralError"
import { useIntl } from "react-intl"
import { CoreMetricsEmptyState } from "./CoreMetricsEmptyState"

interface CoreMetricsWidgetProps {
    title: string
    number?: string
    numberDescription?: string | JSX.Element
    description: string
    isLoading: boolean
    error: boolean
    retry: (() => void) | undefined
    emptyStateMessage: string
    showEmptyState?: boolean
}

export const CoreMetricsWidget: React.FC<CoreMetricsWidgetProps> = ({
    title,
    number,
    numberDescription,
    description,
    isLoading,
    error,
    retry,
    showEmptyState,
    emptyStateMessage,
}) => {
    const intl = useIntl()

    if (showEmptyState)
        return (
            <Card
                className={classNames(styles.panelCoreMetrics, {
                    shimmer: isLoading,
                })}
            >
                <CardContent
                    className={classNames(
                        styles.contentCoreMetrics,
                        styles.emptyState
                    )}
                >
                    <CoreMetricsEmptyState
                        title={title}
                        message={emptyStateMessage}
                    />
                </CardContent>
            </Card>
        )

    return (
        <Card
            className={classNames(styles.panelCoreMetrics, {
                shimmer: isLoading,
            })}
        >
            <CardContent className={styles.contentCoreMetrics}>
                {error && (
                    <GeneralError
                        errorHeading={title}
                        customContainerStyle={styles.error}
                        handleClick={retry}
                        errorMessage={intl.formatMessage(
                            {
                                id: "HomeInfo.widgets.program.loadingError",
                                defaultMessage:
                                    "We were unable to load your {program} status.",
                            },
                            {
                                program: title,
                            }
                        )}
                        hideHeading
                    />
                )}
                {!error && (
                    <>
                        <div className={styles.coreMetricsHeading}>{title}</div>
                        <div className={styles.coreMetricsNumber}>
                            {number}
                            {numberDescription && (
                                <div className={styles.numberDescription}>
                                    {numberDescription}
                                </div>
                            )}
                        </div>

                        <div className={styles.coreMetricsDescription}>
                            {description}
                        </div>
                    </>
                )}
            </CardContent>
        </Card>
    )
}
