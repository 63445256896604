// https://docs.sentry.io/platforms/node/typescript/
// This allows TypeScript to detect our global value
import ReactDOM from "react-dom"
import { StylesProvider } from "@material-ui/core"
import "core-js/stable"
import "react-dates/initialize"
import "react-dates/lib/css/_datepicker.css"
import "regenerator-runtime/runtime"
import AppWrap from "./AppWrap"
import {
    DATADOG_CLIENT_TOKEN,
    DATADOG_ENABLED,
    DATADOG_RUM_APPLICATION_ID,
    DATADOG_RUM_CLIENT_TOKEN,
    DATADOG_RUM_ENABLED,
    DATADOG_RUM_SESSION_SAMPLE_RATE,
    ENV,
    SENTRY_DSN,
    SENTRY_RELEASE_NAME,
} from "./Environment"
import "./index.scss"
import LoggingService, { IgnoreError } from "./services/logging/logging.service"
import realUserMonitoringService from "./services/real-user-monitoring/realUserMonitoring.service"
import "@formatjs/intl-getcanonicallocales/polyfill"
import "@formatjs/intl-locale/polyfill"
import "@formatjs/intl-pluralrules/polyfill"
import "@formatjs/intl-pluralrules/locale-data/en"
import "@formatjs/intl-numberformat/polyfill"
import "@formatjs/intl-numberformat/locale-data/en"
import "@formatjs/intl-datetimeformat/polyfill"
import "@formatjs/intl-datetimeformat/add-golden-tz"
import "@formatjs/intl-datetimeformat/locale-data/en"
import { gtmInitialize } from "utils/gtm"
import { QueryClient, QueryClientProvider } from "react-query"

gtmInitialize()

const queryClient = new QueryClient()

// Using React 17 render API for react-dates
const render = (): void => {
    ReactDOM.render(
        <StylesProvider injectFirst>
            <QueryClientProvider client={queryClient}>
                <AppWrap />
            </QueryClientProvider>
        </StylesProvider>,
        document.getElementById("root")
    )
}

LoggingService.init({
    environment: ENV,
    release: SENTRY_RELEASE_NAME,
    datadog:
        DATADOG_ENABLED && DATADOG_CLIENT_TOKEN
            ? {
                  clientToken: DATADOG_CLIENT_TOKEN,
              }
            : undefined,
    sentry:
        SENTRY_DSN && SENTRY_RELEASE_NAME
            ? {
                  dsn: SENTRY_DSN,
                  ignoreErrors: [
                      IgnoreError.NetworkError,
                      IgnoreError.SentrySDKError1,
                      IgnoreError.SentrySDKError2,
                  ],
              }
            : undefined,
})

realUserMonitoringService.init({
    environment: ENV,
    release: SENTRY_RELEASE_NAME,
    datadog:
        DATADOG_RUM_ENABLED &&
        DATADOG_RUM_APPLICATION_ID &&
        DATADOG_RUM_CLIENT_TOKEN
            ? {
                  applicationId: DATADOG_RUM_APPLICATION_ID,
                  clientToken: DATADOG_RUM_CLIENT_TOKEN,
              }
            : undefined,
    sessionSampleRate: DATADOG_RUM_SESSION_SAMPLE_RATE,
})

render()
