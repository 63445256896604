import { JSONAPIDocument } from "@vacasa/owner-api-models"
import { formatISO } from "date-fns"
import { authAxios } from "../../api/authAxios"

export type HomeAvailabilityRequest = {
    contactId: string
    unitId: number
    availableDates: readonly Date[]
    note: string
    assigneeId: number | null | undefined
}

export type HomeAvailabilitySetResponse = {
    ticketId: string
}

type Response = JSONAPIDocument<HomeAvailabilitySetResponse>

const setHomeAvailability = ({
    contactId,
    unitId,
    availableDates,
    note,
    assigneeId,
}: HomeAvailabilityRequest): Promise<HomeAvailabilitySetResponse> =>
    authAxios
        .post<Response>(`/v1/owners/units/${unitId}/home-availability`, {
            contactId,
            availableDates: availableDates.map(date =>
                formatISO(date, { representation: "date" })
            ),
            note,
            assigneeId,
        })
        .then(response => response.data.data.attributes)

export { setHomeAvailability }
