import { FC, useContext } from "react"
import styles from "../Performance.module.scss"
import { useIntl } from "react-intl"
import ToggleSwitch from "lib/components/ToggleSwitch"
import { LegendItem, LegendItemType } from "lib/components/Vacharts/Legend"
import { RevenueData } from "../types"
import { NetRentToggleContext } from "../NetRentToggleContext"
import { parseISO } from "date-fns"
import { Revenue } from "@vacasa/owner-api-models"
import classnames from "classnames"
import { useRevenueChartContext } from "contexts/performance"
import { useUserCurrencyCode } from "hooks/user"

interface ChartFooterProps {
    revenue: RevenueData
    showPreviousYear: boolean
    onShowPreviousYearChange: (_: boolean) => void
}

const showsVacasaData = (
    revenue: Revenue,
    acquisitionYear: number,
    useNetRent = false,
    showPreviousYear = false
) => {
    if (showPreviousYear || acquisitionYear === -1) return true

    const yearlyRent = useNetRent
        ? revenue.total.netRentAfterIntegrated
        : revenue.total.grossRentAfterIntegrated
    return yearlyRent > 0
}

const getVacasaLegendType = (
    acquisitionYear: number,
    year: number,
    showPreviousYear = false
) => {
    if (showPreviousYear) {
        const previousYear = year - 1

        if (acquisitionYear === previousYear || acquisitionYear === -1) {
            return LegendItemType.VacasaCombined
        }
    }

    return LegendItemType.VacasaCurrentYear
}

const getAcquisitionLegendType = (
    acquisitionYear: number,
    year: number,
    showPreviousYear = false,
    showNetRent = false
) => {
    if (acquisitionYear < year) {
        return LegendItemType.AcquisitionPreviousYear
    }

    if (showNetRent && showPreviousYear) {
        return LegendItemType.AcquisitionPreviousYear
    }

    return showPreviousYear
        ? LegendItemType.AcquisitionCombined
        : LegendItemType.AcquisitionCurrentYear
}

const getAcquisitionDateYear = (acquisitionDate?: string) => {
    return acquisitionDate ? parseISO(acquisitionDate).getFullYear() : -1
}

const getYearLegendType = (
    acquisitionYear: number,
    year: number,
    isPreviousYear = false,
    useNetRent = false
) => {
    if (useNetRent) {
        // Acquistion data shouldn't exist when net rent is enabled
        switch (true) {
            case acquisitionYear === year:
                return isPreviousYear
                    ? LegendItemType.CombinedPreviousYear
                    : LegendItemType.VacasaCurrentYear
            case acquisitionYear > year:
                return isPreviousYear
                    ? LegendItemType.AcquisitionPreviousYear
                    : LegendItemType.AcquisitionCurrentYear
            default:
                return isPreviousYear
                    ? LegendItemType.VacasaPreviousYear
                    : LegendItemType.VacasaCurrentYear
        }
    }

    switch (true) {
        case acquisitionYear === year:
            return isPreviousYear
                ? LegendItemType.CombinedPreviousYear
                : LegendItemType.CombinedCurrentYear
        case acquisitionYear > year:
            return isPreviousYear
                ? LegendItemType.AcquisitionPreviousYear
                : LegendItemType.AcquisitionCurrentYear
        default:
            return isPreviousYear
                ? LegendItemType.VacasaPreviousYear
                : LegendItemType.VacasaCurrentYear
    }
}

const ChartFooter: FC<ChartFooterProps> = ({
    revenue,
    onShowPreviousYearChange,
    showPreviousYear,
}): JSX.Element => {
    const { current, previous, meta } = revenue
    const intl = useIntl()
    const currency = useUserCurrencyCode()
    const { netRentToggled, toggleVisible } = useContext(NetRentToggleContext)

    const { minYear, year } = useRevenueChartContext()

    const acquisitionYear = getAcquisitionDateYear(
        meta.acquisitionUnitIntegrationDate
    )

    const showNetRent = netRentToggled && toggleVisible

    let showsAcquisitionData = year <= acquisitionYear && !showNetRent

    if (showPreviousYear) {
        showsAcquisitionData = year - 1 <= acquisitionYear
    }

    const currencyFormatOptions: {
        currency?: string
        style?: "currency" | "unit" | "decimal" | "percent" | undefined
    } = {
        currency,
        style: "currency",
    }

    return (
        <div className={styles.footer}>
            <div className={styles["footer__left-col"]}>
                {
                    <>
                        <div
                            className={classnames(
                                styles["legend"],
                                styles["footer__legend"]
                            )}
                        >
                            {showsVacasaData(
                                revenue.current,
                                acquisitionYear,
                                showNetRent,
                                showPreviousYear
                            ) && (
                                <LegendItem
                                    type={getVacasaLegendType(
                                        acquisitionYear,
                                        year,
                                        showPreviousYear
                                    )}
                                >
                                    <span
                                        className={classnames(
                                            styles["legend__text"],
                                            styles["legend__text--wide"],
                                            "uppercase"
                                        )}
                                    >
                                        {intl.formatMessage({
                                            id: "Performance.rentChart.footer.keyLabels.vacasa",
                                            defaultMessage: "Vacasa",
                                        })}
                                    </span>
                                </LegendItem>
                            )}
                            {showsAcquisitionData && (
                                <LegendItem
                                    type={getAcquisitionLegendType(
                                        acquisitionYear,
                                        year,
                                        showPreviousYear,
                                        showNetRent
                                    )}
                                >
                                    <span
                                        className={classnames(
                                            styles["legend__text"],
                                            styles["legend__text--wide"],
                                            "uppercase"
                                        )}
                                    >
                                        {intl.formatMessage({
                                            id: "Performance.rentChart.footer.keyLabels.pastPm",
                                            defaultMessage: "Previous PM",
                                        })}
                                    </span>
                                </LegendItem>
                            )}
                        </div>
                        {showPreviousYear && (
                            <div
                                className={classnames(
                                    styles["legend"],
                                    styles["footer__legend"]
                                )}
                            >
                                <LegendItem
                                    type={
                                        acquisitionYear !== -1
                                            ? getYearLegendType(
                                                  acquisitionYear,
                                                  year,
                                                  false,
                                                  netRentToggled &&
                                                      toggleVisible
                                              )
                                            : LegendItemType.VacasaCurrentYear
                                    }
                                >
                                    <>
                                        <span
                                            className={classnames(
                                                styles["legend__text"]
                                            )}
                                        >
                                            <time>{year}</time>
                                            {intl.formatNumber(
                                                netRentToggled && toggleVisible
                                                    ? current.total.netRentTotal
                                                    : current.total
                                                          .grossRentTotal,
                                                currencyFormatOptions
                                            )}
                                        </span>
                                    </>
                                </LegendItem>
                                <LegendItem
                                    type={
                                        acquisitionYear !== -1
                                            ? getYearLegendType(
                                                  acquisitionYear,
                                                  year - 1,
                                                  true,
                                                  netRentToggled &&
                                                      toggleVisible
                                              )
                                            : LegendItemType.VacasaPreviousYear
                                    }
                                >
                                    <>
                                        <span
                                            className={classnames(
                                                styles["legend__text"]
                                            )}
                                        >
                                            <time>{year - 1}</time>
                                            {intl.formatNumber(
                                                netRentToggled && toggleVisible
                                                    ? previous.total
                                                          .netRentTotal
                                                    : previous.total
                                                          .grossRentTotal,
                                                currencyFormatOptions
                                            )}
                                        </span>
                                    </>
                                </LegendItem>
                            </div>
                        )}
                        {showsAcquisitionData && (
                            <p className={styles["footer__note"]}>
                                {intl.formatMessage({
                                    id: "Performance.rentChart.footer.inheritedDataInfo",
                                    defaultMessage:
                                        "Inherited data was provided by your previous property management company.",
                                })}
                            </p>
                        )}
                    </>
                }
            </div>
            <div className={styles["footer__right-col"]}>
                {year > minYear && (
                    <ToggleSwitch
                        active={showPreviousYear}
                        className={styles["toggle-wrap"]}
                        label={intl.formatMessage({
                            id: "Performance.compareToPriorYear",
                            defaultMessage: "Compare to Previous Year",
                        })}
                        onChange={_ => onShowPreviousYearChange(_)}
                    />
                )}
            </div>
        </div>
    )
}

export default ChartFooter
