import { ReservationType } from "@vacasa/owner-api-models"
import { OccupancyMetadata } from "@vacasa/owner-api-models/src/MonthOccupancyMetadata"
import classnames from "classnames"
import { NIGHTS_OCCUPIED_MODAL_HINT_DISMISSED } from "constants/preferences.constants"
import { useMonthOccupancy } from "hooks/month-occupancy"
import { useOwnerFeatureFlag } from "hooks/owner-feature-flag"
import { useEffect, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import {
    Link,
    useLocation,
    useNavigate,
    useSearchParams,
} from "react-router-dom"
import {
    trackGuestStaysNightsOccupiedClicked,
    trackOwnerHoldsNightsOccupiedClicked,
} from "services/segment/performance/performanceTracking"
import { isFeatureFlagEnabled } from "utils/feature-flag"
import { ReactComponent as CalendarTickSVG } from "../../assets/icon-calendar-tick.svg"
import { ReactComponent as ChevronRightSVG } from "../../assets/icon-chevron-right.svg"
import { ReactComponent as NoNightsOccupiedSVG } from "../../assets/no-nights-occupied.svg"
import { Card, CardContent } from "../../components/Card"
import styles from "./NightsOccupied.module.scss"
import { NightsOccupiedModal } from "./modal/NightsOccupiedModal/NightsOccupiedModal"
import { useFeatureInteractionAPI } from "contexts/feature-feedback"
import { endOfMonth, startOfMonth } from "date-fns"
import { FeatureAlert } from "lib/components/FeatureAlert/FeatureAlert"
import { useUnitStatuses } from "hooks/units"

interface NightsOccupiedProps {
    unitId: string | null
    showNoAverageStarRating: boolean
}

export const NightsOccupied: React.FC<NightsOccupiedProps> = ({
    unitId,
    showNoAverageStarRating,
}) => {
    const { incrementInteractionCount } = useFeatureInteractionAPI()
    const [queryParams] = useSearchParams()
    const navigate = useNavigate()
    const location = useLocation()
    const { isFixedRent } = useUnitStatuses()
    const [nightsOccupiedModal, setNightsOccupiedModal] = useState<{
        showModal: boolean
        occupancyData: OccupancyMetadata | undefined
        reservationType: number | undefined
    }>({
        showModal: false,
        occupancyData: undefined,
        reservationType: undefined,
    })
    const monthOccupancyQuery = useMonthOccupancy(
        unitId,
        startOfMonth(new Date()),
        endOfMonth(new Date())
    )
    const nightsOccupiedModalFeatureFlag = useOwnerFeatureFlag(
        "nights-occupied-modal"
    )
    const nightsOccupiedModalEnabled = isFeatureFlagEnabled(
        nightsOccupiedModalFeatureFlag
    )

    useEffect(() => {
        const nightsOccupied = queryParams.get("nights-occupied")

        if (
            nightsOccupiedModal.showModal ||
            !nightsOccupied ||
            !monthOccupancyQuery.isSuccess ||
            !monthOccupancyQuery.data
        )
            return

        if (nightsOccupied === "owner") {
            setNightsOccupiedModal(prevState => ({
                ...prevState,
                showModal: true,
                occupancyData: monthOccupancyQuery.data.ownerNights,
                reservationType: ReservationType.OWNERHOLD,
            }))
        } else if (nightsOccupied === "guest") {
            setNightsOccupiedModal(prevState => ({
                ...prevState,
                showModal: true,
                occupancyData: monthOccupancyQuery.data.guestNights,
                reservationType: ReservationType.RESERVATION,
            }))
        }
    }, [
        monthOccupancyQuery.data,
        monthOccupancyQuery.isSuccess,
        nightsOccupiedModal.showModal,
        queryParams,
    ])

    const handleOccupancyCardOnClick = (reservationType: ReservationType) => {
        if (
            !monthOccupancyQuery.data ||
            !nightsOccupiedModalEnabled ||
            isFixedRent
        ) {
            return
        }
        if (
            reservationType === ReservationType.OWNERHOLD &&
            monthOccupancyQuery.data.ownerNights.total > 0
        ) {
            setNightsOccupiedModal(prevState => ({
                ...prevState,
                showModal: true,
                occupancyData: monthOccupancyQuery.data.ownerNights,
                reservationType: ReservationType.OWNERHOLD,
            }))
            trackOwnerHoldsNightsOccupiedClicked()
        }
        if (
            reservationType === ReservationType.RESERVATION &&
            monthOccupancyQuery.data.guestNights.total > 0
        ) {
            setNightsOccupiedModal(prevState => ({
                ...prevState,
                showModal: true,
                occupancyData: monthOccupancyQuery.data.guestNights,
                reservationType: ReservationType.RESERVATION,
            }))
            trackGuestStaysNightsOccupiedClicked()
        }
        incrementInteractionCount("nights-occupied-modal")
    }

    const closeNightsOccupiedModal = (): void => {
        setNightsOccupiedModal(prevState => ({
            ...prevState,
            showModal: false,
        }))
        navigate(
            {
                pathname: location.pathname,
            },
            {
                replace: true,
            }
        )
    }
    const intl = useIntl()

    return (
        <>
            {monthOccupancyQuery.isSuccess &&
                (monthOccupancyQuery.data.ownerNights.total > 0 ||
                monthOccupancyQuery.data.guestNights.total > 0 ? (
                    <div className={styles.panelNightsOccupied}>
                        {nightsOccupiedModalEnabled && (
                            <div className={styles.notificationWrap}>
                                <FeatureAlert
                                    feature="nights-occupied-modal"
                                    body={intl.formatMessage({
                                        id: "Performance.floatingNotification.nightsOccupiedBody",
                                        defaultMessage:
                                            "You can click into Guest Stays or Owner Holds to see a summary of reservations for the current month and their associated revenue impact!",
                                    })}
                                    title={intl.formatMessage({
                                        id: "Performance.floatingNotification.nightsOccupiedTitle",
                                        defaultMessage:
                                            "New! Occupancy summary",
                                    })}
                                    userPreference={
                                        NIGHTS_OCCUPIED_MODAL_HINT_DISMISSED
                                    }
                                    pointerPosition="top-right"
                                />
                            </div>
                        )}
                        <Link className={styles.heading} to={`/calendar`}>
                            <CalendarTickSVG />
                            <FormattedMessage
                                id="Performance.nightsOccupied.heading"
                                defaultMessage="Nights occupied this month"
                                description="Heading title for nights occupied normal state."
                            />
                        </Link>
                        <div className={styles.occupancyCardContainer}>
                            <div
                                onClick={() =>
                                    handleOccupancyCardOnClick(
                                        ReservationType.RESERVATION
                                    )
                                }
                            >
                                <Card
                                    className={
                                        nightsOccupiedModalEnabled &&
                                        !isFixedRent
                                            ? classnames(
                                                  styles.occupancyCard,
                                                  styles.occupancyCard__cursor
                                              )
                                            : styles.occupancyCard
                                    }
                                >
                                    <CardContent
                                        className={styles.occupancyCardContent}
                                    >
                                        <div className={styles.number}>
                                            {
                                                monthOccupancyQuery.data
                                                    .guestNights.total
                                            }
                                        </div>
                                        <div className={styles.description}>
                                            <div
                                                className={
                                                    styles.description__title
                                                }
                                            >
                                                <FormattedMessage
                                                    id="Performance.nightsOccupied.guestNights"
                                                    defaultMessage="Guest nights"
                                                />
                                            </div>
                                            {nightsOccupiedModalEnabled &&
                                                monthOccupancyQuery.data
                                                    .guestNights.total > 0 && (
                                                    <ChevronRightSVG />
                                                )}
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                            <div
                                onClick={() =>
                                    handleOccupancyCardOnClick(
                                        ReservationType.OWNERHOLD
                                    )
                                }
                            >
                                <Card
                                    className={
                                        nightsOccupiedModalEnabled &&
                                        !isFixedRent
                                            ? classnames(
                                                  styles.occupancyCard,
                                                  styles.occupancyCard__cursor
                                              )
                                            : styles.occupancyCard
                                    }
                                >
                                    <CardContent
                                        className={styles.occupancyCardContent}
                                    >
                                        <div className={styles.number}>
                                            {
                                                monthOccupancyQuery.data
                                                    .ownerNights.total
                                            }
                                        </div>
                                        <div className={styles.description}>
                                            <div
                                                className={
                                                    styles.description__title
                                                }
                                            >
                                                <FormattedMessage
                                                    id="Performance.nightsOccupied.ownerNights"
                                                    defaultMessage="Owner nights"
                                                />
                                            </div>
                                            {nightsOccupiedModalEnabled &&
                                                monthOccupancyQuery.data
                                                    .ownerNights.total > 0 && (
                                                    <ChevronRightSVG />
                                                )}
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                        </div>
                        {nightsOccupiedModalEnabled &&
                            nightsOccupiedModal.showModal &&
                            nightsOccupiedModal.occupancyData &&
                            nightsOccupiedModal.reservationType && (
                                <NightsOccupiedModal
                                    closeNightsOccupiedModal={
                                        closeNightsOccupiedModal
                                    }
                                    occupancyData={
                                        nightsOccupiedModal.occupancyData
                                    }
                                    reservationType={
                                        nightsOccupiedModal.reservationType
                                    }
                                />
                            )}
                    </div>
                ) : (
                    <div className={styles.panelNightsOccupiedEmpty}>
                        <NoNightsOccupiedSVG />
                        <div className={styles.headingEmpty}>
                            <FormattedMessage
                                id="Performance.nightsOccupied.headingEmpty"
                                defaultMessage="Nights occupied"
                                description="Heading title for nights occupied empty state."
                            />
                        </div>
                        <div className={styles.descriptionEmpty}>
                            <FormattedMessage
                                id="Performance.nightsOccupied.nightsOccupiedEmpty"
                                defaultMessage="Your guest and owner hold nights will appear here for the current month."
                                description="Description for nights occupied empty state."
                            />
                        </div>
                    </div>
                ))}
        </>
    )
}
