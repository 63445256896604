import React, { useEffect, useState } from "react"
import { Navigate } from "react-router-dom"

// Components
import FullScreenLoader from "../lib/components/Loader/FullScreenLoader"

// Constants, messages, errors
import { EMPLOYEE_LAST_VIEWED_OWNER_URL } from "../Constants"

import LoggingService from "../services/logging/logging.service"
import storageService from "services/storage.service"
import { trackLoginPageViewed } from "services/segment/login/loginTracking"
import { observer } from "mobx-react"
import { useIsEmployee } from "hooks/user"
import { useLoginInfo } from "contexts/login"
import { hasValues } from "utils/array"

const Login: React.FC = observer(() => {
    const [contactLoaded, setContactLoaded] = useState(false)
    const { contactIds, user, forwardTo } = useLoginInfo()
    const isEmployee = useIsEmployee()
    const pathWithQuery = (path: string): string => {
        const query = window.location.search
        if (query) {
            return path + query
        }
        return path
    }

    useEffect(() => {
        trackLoginPageViewed()
    }, [])

    useEffect(() => {
        if (user) {
            setContactLoaded(true)
        }
    }, [user])

    const employeePresent = isEmployee

    if (user) {
        let path = "/"

        if (forwardTo) {
            path = forwardTo
        }

        if (employeePresent) {
            LoggingService.log({
                message: `Employee login as owner, routing to "${path}"`,
            })
        } else {
            LoggingService.log({
                message: `Owner login, routing to "${path}"`,
            })
        }

        return <Navigate to={pathWithQuery(path)} />
    }

    if (employeePresent) {
        // If we're logged in as an employee and have contactIds we should wait until
        // the contacts have been fetched before deciding where we should redirect
        // Have to check for ["0"] as the default for employees returns that.
        if (
            hasValues(contactIds) &&
            !contactIds.includes("0") &&
            !contactLoaded
        ) {
            return <FullScreenLoader />
        }

        let lastViewedUrl = storageService.localStorage.getItem(
            EMPLOYEE_LAST_VIEWED_OWNER_URL
        )

        if (lastViewedUrl?.startsWith("forward")) {
            lastViewedUrl = `/${lastViewedUrl}`
        }

        const path = lastViewedUrl ?? "/search"

        LoggingService.log({
            message: `Employee login, routing to "${path}"`,
        })

        return <Navigate to={pathWithQuery(path)} />
    }
    return <FullScreenLoader />
})

export default Login
