const linenService = {
    title: "Textilszolgáltatás",

    notificationComponent: {
        planWillBeginWithInitialCharge:
            "Szerződés kezdő dátuma: <strong>{date, date, medium}</strong> kezdeti költség: <strong>{cost}</strong>.",
        planWillBeginWithCharge:
            "Szerződés kezdő dátuma: <strong>{date, date, medium}</strong> költség: <strong>{cost}</strong>.",
        planWillRenewFor:
            "Szerződés automatikus megújítási dátuma: <strong>{date, date, medium}</strong> költség: <strong>{cost}</strong>",
        planWillEndOn:
            "Szerződés záró dátuma: <strong>{date, date, medium}</strong> ezután megszűnik az ágyneműk és törülközők kedvezményes biztosítása.",
    },

    infoPanel: {
        learnMoreTitle: "Egyszerű, megfizethető megoldás",
        bodyText:
            "  Textilszolgáltatásunk szállodai minőségű textíliákat biztosít ipari kedvezménnyel. A friss ágynemű és a puha törülközők jobb értékeléseket, több foglalást és nagyobb bevételt jelent.",
        learnMore: "További információk",
    },

    servicePlan: {
        prepend: "Szerződés",
        standard: "Standard textilszolgáltatás",
        premium: "Prémium textilszolgáltatás",
        youAreNotEnrolled: "Nem regisztrált",
        year: "év",
        nextBillingDate: "Következő számlázási dátum",
        newPlanBegins: "Új szerződés kezdő dátuma:",
        nextBilling: "Következő számlázás",
        discount: "kedvezmény",
        setup: "Aktiválás",
        setupFee: "aktiválási díj",
        planHistory: "HU Plan history",
        ongoing: "folyamatban van",
        pending: "függőben van",
        append: "A szolgáltatásért felszámított költségek kiadásként jelennek meg a havi elszámolásban.",
    },

    helpPanel: {
        needHelp: "Segítségre van szükséged?",
        contactSentenceIfQuestions:
            "Ha kérdésed van, vagy módosítani szeretnél a szerződésen, lépj kapcsolatba a következő ügyintézővel: {contact}",
    },

    enrolled: "Regisztrált",
    notEnrolled: "Nem regisztrált",
    begins: "Kezdet:",
    renews: "Megújul:",
    expires: "Lejár:",
}

export default linenService
