import Button from "@material-ui/core/Button"
import ButtonGroup from "@material-ui/core/ButtonGroup"
import classnames from "classnames"
import { FunctionComponent } from "react"
import { getClassStyle } from "utils/styles/styleWrapper"
import { ReactComponent as MinusIcon } from "../../../assets/icon-minus-new.svg"
import { ReactComponent as PlusIcon } from "../../../assets/icon-plus-new.svg"
import styles from "./incrementer.module.scss"

interface IncrementerProps {
    initialValue?: number | null | undefined
    minValue?: number
    maxValue?: number
    onChangeHandler: (value: number) => void
    v2?: boolean
}

const Incrementer: FunctionComponent<IncrementerProps> = ({
    initialValue,
    minValue = 0,
    maxValue = 99,
    onChangeHandler,
    v2,
}: IncrementerProps): JSX.Element => {
    const counter = initialValue ?? minValue
    const decrementButtonDisabled = counter === minValue
    const incrementButtonDisabled = counter === maxValue

    function handleIncrement(): void {
        if (counter >= maxValue) return
        handleOnChange(counter + 1)
    }

    function handleDecrement(): void {
        if (counter <= minValue) return
        handleOnChange(counter - 1)
    }

    function handleOnChange(value: number): void {
        onChangeHandler(value)
    }

    return (
        <ButtonGroup
            className={classnames({
                [getClassStyle(styles["incrementer--new"])]: v2,
            })}
        >
            <Button
                disabled={decrementButtonDisabled}
                onClick={handleDecrement}
                className={classnames(
                    styles.incrementer,
                    "incrementer__action"
                )}
                data-testid="minus-button"
            >
                <MinusIcon />
            </Button>
            <Button
                className={classnames(
                    styles.incrementer,
                    "incrementer__display",
                    {
                        "font-semibold": v2,
                    }
                )}
                disabled
            >
                {counter}
            </Button>
            <Button
                disabled={incrementButtonDisabled}
                onClick={handleIncrement}
                className={classnames(
                    styles.incrementer,
                    "incrementer__action"
                )}
                data-testid="plus-button"
            >
                <PlusIcon />
            </Button>
        </ButtonGroup>
    )
}

export default Incrementer
