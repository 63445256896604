import styles from "./UnitMinRateNotification.module.scss"
import { useUnitMinRate } from "hooks/unit-min-rate"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { CompletedView } from "./CompletedView"
import { ContactView } from "./ContactView"
import { AdjustView } from "./AdjustView"
import { MainSection } from "./MainSection"
import { SHOW_MIN_RATE } from "constants/preferences.constants"
import {
    useOwnerUnitPreferences,
    useOwnerUnitPreferencesMutation,
} from "hooks/owner-preferences"
import { experimentTrackMinRateNotification } from "services/segment/performance/performanceTracking"
import { useUserCurrencyCode } from "hooks/user"
import { useCurrentUnit, useUnitStatuses } from "hooks/units"

const ONE_MONTH_IN_SECONDS = 60 * 60 * 24 * 30
export interface ChangeView {
    changeView: Dispatch<SetStateAction<DisplayStateProps>>
}

export type DisplayStateProps =
    | "main"
    | "adjust"
    | "completed"
    | "later"
    | "none"

export const UnitMinRateNotification = (): JSX.Element | null => {
    const [currentDisplay, setCurrentDisplay] =
        useState<DisplayStateProps>("main")

    const [displayNotification, setDisplayNotification] =
        useState<boolean>(true)

    const currency = useUserCurrencyCode()
    const { unitId } = useCurrentUnit()
    const { isRevenuePooling } = useUnitStatuses()

    const unitMinRate = useUnitMinRate(unitId)

    const ownerUnitPreferencesQuery = useOwnerUnitPreferences(unitId)
    const { mutate: mutateOwnerPreference } = useOwnerUnitPreferencesMutation()
    useEffect(() => {
        if (ownerUnitPreferencesQuery.isLoading) setDisplayNotification(false)
        if (ownerUnitPreferencesQuery.isError) setDisplayNotification(false)
        if (ownerUnitPreferencesQuery.isSuccess) {
            const ownerMinRatePreference = ownerUnitPreferencesQuery.data?.find(
                pref => pref.id === SHOW_MIN_RATE
            )
            if (ownerMinRatePreference?.attributes.value) {
                setDisplayNotification(true)
            } else {
                if (currentDisplay === "completed") {
                    setDisplayNotification(true)
                } else if (ownerMinRatePreference?.attributes.value === null) {
                    setDisplayNotification(true)
                } else {
                    if (
                        ownerMinRatePreference?.attributes.updated &&
                        ownerMinRatePreference?.attributes.updated +
                            ONE_MONTH_IN_SECONDS <= // remind after 1 month
                            Date.now() / 1000 // current timestamp in seconds
                    ) {
                        mutateOwnerPreference({
                            unitId: unitId ?? "",
                            id: SHOW_MIN_RATE,
                            value: true,
                        })
                        setDisplayNotification(true)
                    } else {
                        setDisplayNotification(false)
                    }
                }
            }
        }
    }, [
        ownerUnitPreferencesQuery.data,
        ownerUnitPreferencesQuery.isError,
        ownerUnitPreferencesQuery.isLoading,
        ownerUnitPreferencesQuery.isSuccess,
        mutateOwnerPreference,
        unitId,
        currentDisplay,
    ])

    const displayMinRateNotification =
        displayNotification &&
        unitMinRate.isSuccess &&
        unitMinRate.data.attributes !== undefined &&
        unitMinRate.data.attributes.showMinRateNotification &&
        !isRevenuePooling

    useEffect(() => {
        if (displayMinRateNotification) {
            experimentTrackMinRateNotification()
        }
    }, [displayMinRateNotification])

    if (unitMinRate.isLoading) return null
    if (!unitMinRate.isSuccess) return null

    if (!displayMinRateNotification) return null

    switch (currentDisplay) {
        case "main":
            return (
                <div className={styles.alertBorder}>
                    <MainSection
                        minRate={unitMinRate.data.attributes.minRate ?? 0}
                        impactRevenue={
                            unitMinRate.data.attributes.impactRevenue
                        }
                        currency={currency}
                    />
                    <AdjustView changeView={setCurrentDisplay} />
                </div>
            )

        case "adjust":
            return (
                <div className={styles.alertBorder}>
                    <MainSection
                        minRate={unitMinRate.data.attributes.minRate ?? 0}
                        impactRevenue={
                            unitMinRate.data.attributes.impactRevenue
                        }
                        currency={currency}
                    />
                    <ContactView changeView={setCurrentDisplay} />
                </div>
            )

        case "completed":
            return <CompletedView />

        case "later":
        case "none":
        default:
            return null
    }
}
