import { Tooltip } from "lib/components/Tooltip/Tooltip"
import React from "react"
import { Info } from "react-feather"
import styles from "./MinRateAnalyzed.module.scss"
import classNames from "classnames"
import { FormattedMessage, useIntl } from "react-intl"

export interface MinRateAnalyzedProps {
    isLoading?: boolean
    minRate?: number
}

export const MinRateAnalyzed: React.FC<MinRateAnalyzedProps> = ({
    isLoading,
    minRate,
}) => {
    const intl = useIntl()

    const renderTooltip = () => {
        return (
            <div className={styles.tooltipContainer}>
                <Tooltip
                    title={intl.formatMessage({
                        id: "Dashboard.rateCalculator.minRate.tooltip.title",
                        defaultMessage: "Minimum Rate",
                    })}
                    body={intl.formatMessage({
                        id: "Dashboard.rateCalculator.minRate.tooltip.body",
                        defaultMessage:
                            "This number represents the minimum rate for your specific home being analyzed by this tool.",
                    })}
                >
                    <Info
                        className={styles.tooltipTrigger}
                        width={16}
                        height={16}
                    />
                </Tooltip>
            </div>
        )
    }

    return (
        <div className={styles.minRateAnalyzed}>
            <div
                className={classNames(
                    "type-heading-extra-small-caps",
                    styles.header
                )}
            >
                <FormattedMessage
                    id="Dashboard.rateCalculator.minRate.title"
                    defaultMessage="Minimum Rate"
                />
                {renderTooltip()}
            </div>
            {isLoading && (
                <div
                    className={classNames("shimmer", styles.impactSkeletion)}
                />
            )}
            {!isLoading && (
                <div
                    className={classNames(
                        "type-heading-medium",
                        styles.minRate
                    )}
                >
                    {minRate ? `$${minRate}` : "n/a"}
                </div>
            )}
        </div>
    )
}
