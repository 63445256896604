import { authAxios } from "../../api/authAxios"
import { LANGUAGES } from "Constants"

export type PatchContactRequest = {
    contactId: string

    address1?: string
    address2?: string
    city?: string
    state?: string
    countryCode?: string
    zip?: string
    phone?: string
    languageId?: number
    firstName?: string
    lastName?: string
    email?: string
}

const patchContact = ({
    contactId,
    ...patch
}: PatchContactRequest): Promise<void> =>
    authAxios.patch(`v1/owners/${contactId}`, {
        data: {
            type: "owner-details",
            attributes: {
                ...patch,
                language:
                    Object.values(LANGUAGES).find(
                        l => l.id === patch.languageId
                    )?.code ?? undefined,
            },
        },
    })

export { patchContact }
