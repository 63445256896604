import {
    FC,
    PropsWithChildren,
    createContext,
    useMemo,
    useReducer,
} from "react"
import LoggingService from "services/logging/logging.service"
import { FlyoutAPI, FlyoutState as TaxInformationFlyoutState } from "../types"
import { OptimizelyFeature } from "@optimizely/react-sdk"
import { TaxInformationFlyout } from "../TaxInformationFlyout"

export const TaxInformationFlyoutProvider: FC<PropsWithChildren> = ({
    children,
}) => {
    const [state, dispatch] = useReducer(reducer, {
        modalOpen: false,
    })

    const api = useMemo(() => {
        return {
            close: () => {
                dispatch({ type: "close" })
            },
            open: () => {
                dispatch({ type: "open" })
            },
        }
    }, [])

    return (
        <TaxInformationFlyoutAPIContext.Provider value={api}>
            <TaxInformationFlyoutStateContext.Provider value={state}>
                {children}
                <OptimizelyFeature feature="w9-flyout-workflow">
                    {isEnabled => isEnabled && <TaxInformationFlyout />}
                </OptimizelyFeature>
            </TaxInformationFlyoutStateContext.Provider>
        </TaxInformationFlyoutAPIContext.Provider>
    )
}

export const TaxInformationFlyoutStateContext =
    createContext<TaxInformationFlyoutState>({
        modalOpen: false,
    })
export const TaxInformationFlyoutAPIContext = createContext<FlyoutAPI>({
    close: () => {
        LoggingService.error({
            message: "W9FlyoutAPIContext.close not implemented",
        })
    },
    open: () => {
        LoggingService.error({
            message: "W9FlyoutAPIContext.open not implemented",
        })
    },
})

type Actions = { type: "open" } | { type: "close" }
const reducer = (
    prevState: TaxInformationFlyoutState,
    action: Actions
): TaxInformationFlyoutState => {
    switch (action.type) {
        case "open": {
            return {
                modalOpen: true,
            }
        }
        case "close": {
            return {
                modalOpen: false,
            }
        }
        default:
            return prevState
    }
}
