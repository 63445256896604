const linenService = {
    title: "Linen service",

    infoPanel: {
        learnMoreTitle: "A simple, affordable solution",
        bodyText:
            "Our linen service provides you with hotel-quality linens at an industry discount. Fresh sheets and fluffy towels for every guest mean better reviews, more bookings, and more money for you.",
        learnMore: "Learn More",
    },

    servicePlan: {
        prepend: "Your Plan",
        standard: "Standard linen service",
        premium: "Premium linen service",
        youAreNotEnrolled: "You are not enrolled",
        year: "year",
        nextBillingDate: "Next billing date",
        newPlanBegins: "New plan begins date",
        nextBilling: "Next billing",
        discount: "discount",
        setup: "Setup",
        setupFee: "setup fee",
        planHistory: "Plan history",
        ongoing: "ongoing",
        pending: "Pending",
        append: "Any charges for this service will be billed as expenses on your monthly statements.",
    },

    helpPanel: {
        needHelp: "Need help?",
    },

    enrolled: "Enrolled",
    notEnrolled: "Not enrolled",
    begins: "Begins",
    renews: "Renews",
    expires: "Expires",
}

export default linenService
