import { useIsEmployee } from "hooks/user"
import { useUserNotificationPreferenceMutation } from "hooks/user-notification-preference/useUserNotificationPreference"
import { FloatingTooltip } from "lib/components/FloatingTooltip/FloatingTooltip"
import Loader from "lib/components/Loader"
import { useToastNotification } from "lib/components/ToastNotification"
import ToggleSwitch from "lib/components/ToggleSwitch"
import { FC, useCallback, useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import {
    trackUserNotificationPreferenceToggled,
    trackUserNotificationTooltipOpen,
} from "services/segment/notificationSettings/notificationSettingsTracking"
import { NOTIFICATION_SETTINGS_ACTION } from "services/segment/notificationSettings/notificationSettingsTrackingConstants"

interface PreferenceToggleProp {
    defaultValue: boolean
    readOnly: boolean
    preferenceId: string
    preferenceKey: "email" | "push" | "sms"
    preferenceTitle: string
}

export const PreferenceToggle: FC<PreferenceToggleProp> = ({
    defaultValue,
    readOnly,
    preferenceId,
    preferenceKey,
    preferenceTitle,
}): JSX.Element => {
    const isEmployee = useIsEmployee()
    const [active, setActive] = useState(defaultValue)
    const updatePreferenceMutation = useUserNotificationPreferenceMutation()
    const { addToastNotification } = useToastNotification()

    const handleToggle = useCallback(
        (buttonChecked: boolean) => {
            if (buttonChecked) {
                updatePreferenceMutation.mutate({
                    body: {
                        preferenceId,
                        preferenceKey,
                        preferenceValue: true,
                    },
                })
                setActive(true)
                trackUserNotificationPreferenceToggled(
                    NOTIFICATION_SETTINGS_ACTION.SUBSCRIBED,
                    preferenceId,
                    preferenceTitle,
                    preferenceKey
                )
            } else {
                updatePreferenceMutation.mutate({
                    body: {
                        preferenceId,
                        preferenceKey,
                        preferenceValue: false,
                    },
                })
                setActive(false)
                trackUserNotificationPreferenceToggled(
                    NOTIFICATION_SETTINGS_ACTION.UNSUBSCRIBED,
                    preferenceId,
                    preferenceTitle,
                    preferenceKey
                )
            }
        },
        [preferenceId, preferenceKey, preferenceTitle, updatePreferenceMutation]
    )

    useEffect(() => {
        if (updatePreferenceMutation.isSuccess) {
            addToastNotification({
                autoHideDuration: 1500,
                content: (
                    <FormattedMessage
                        id="Notification.changesSaved"
                        defaultMessage="Changes saved"
                    />
                ),
            })
        } else if (updatePreferenceMutation.isError) {
            setActive(current => !current)
        }
    }, [
        updatePreferenceMutation.isError,
        updatePreferenceMutation.isLoading,
        updatePreferenceMutation.isSuccess,
        updatePreferenceMutation.isIdle,
        addToastNotification,
    ])

    return (
        <>
            {updatePreferenceMutation.isLoading && <Loader overlay={true} />}
            <FloatingTooltip
                title="Required Alert"
                disabled={!readOnly}
                formattedMsg={
                    <FormattedMessage
                        id="Notification.requiredAlert"
                        defaultMessage="Opting out of these transactional notices are not
                        available."
                    />
                }
                notificationSegmentTrack={trackUserNotificationTooltipOpen}
            >
                <label data-testid="toggle-switch">
                    <ToggleSwitch
                        active={active}
                        label={""}
                        onChange={_ => handleToggle(_)}
                        disabled={readOnly || isEmployee}
                    />
                </label>
            </FloatingTooltip>
            {/* <Notification
                snippet={
                    <FormattedMessage
                        id="Notification.changesSaved"
                        defaultMessage="Changes saved"
                    />
                }
            /> */}
        </>
    )
}
