import React from "react"
import Button from "@material-ui/core/Button"
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined"

import Panel from "./OnboardingPanel"
import styles from "../../sass/onboarding.module.scss"
import { FormattedMessage } from "react-intl"

interface ErrorComponentProps {
    errorMessage?: React.ReactNode
    buttonLabel?: React.ReactNode
    onClick: () => void
}

const ErrorComponent: React.FunctionComponent<ErrorComponentProps> = ({
    errorMessage,
    buttonLabel,
    onClick,
}: ErrorComponentProps): JSX.Element => {
    return (
        <Panel className={styles.error_message}>
            <div className={styles.error_message_content}>
                <ReportProblemOutlinedIcon className={styles.icon} />
                <p>
                    {errorMessage ?? (
                        <FormattedMessage
                            id="Onboarding.error.savingFailedErrorMessage"
                            defaultMessage="We had trouble saving your changes. If the problem persists, please try again later."
                        />
                    )}
                </p>
                <Button
                    variant="outlined"
                    className={styles.save_button}
                    onClick={onClick}
                >
                    {buttonLabel ?? (
                        <FormattedMessage
                            id="Onboarding.error.trySavingAgainButtonTitle"
                            defaultMessage="Try Saving Again"
                        />
                    )}
                </Button>
            </div>
        </Panel>
    )
}

export default ErrorComponent
