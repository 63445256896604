/* eslint-disable no-template-curly-in-string */
const calendar = {
    vacasa: "Vacasa",
    title: "Kalendář",
    interiorDesignBannerText:
        "Získejte větší výdělek využitím služeb interiérového designu",
    covidBannerText:
        "Coronavirus homeowner resource guide - what we're doing to navigate COVID-19 together.",
    covidCtaDestination:
        "https://www.vacasa.com/support/coronavirus-homeowner-resource-guide",
    learnMore: "Více informací",
    emptyState:
        "You’ll be able to reserve dates for your personal use and view upcoming reservations.",

    OwnerHolds: {
        heading: "Owner holds",
        nonfixedHelperText:
            "Use this calendar to reserve dates for your own personal use.",
        fixedHelperText:
            "To reserve dates for your own personal use, please contact Homeowner Account Support at +1 (971) 254-3001 or",
        inactiveHelperText:
            "Tento rekreační pronájem není aktivní. Rezervace vlastníka mohou být přidány pouze k aktivním rekreačním pronájmům.",
        ifHoldQuestions:
            "Máte-li jakékoli dotazy ohledně této rezervace, neváhejte nás kontaktovat:",
        toChangeHold:
            "Pokud potřebujete změnit datumy nebo zrušit tuto rezervaci, zašlete prosím e-mailovou zprávu s vaší žádostí na adresu",
        continue: "Pokračovat",
        ariaLabel: "Klikněte k provedení rezervace vlastníka",
        checkIn: "Příjezd",
        checkOut: "Odjezd",
        notSelected: "Nevybrán",
        clearDates: "Smazat datumy",
        employeeOptions: "Další možnosti zaměstnanců",
        holdType: "Hold Reason",
        ownerHold: "Rezervace vlastníka",
        guestStay: "Guest Stay",
        vacasaHold: "Rezervace společnosti Vacasa",
        selectOwner: "Vybrat vlastníka",
        scheduleClean:
            "Chcete, abychom se na konci vašeho pobytu postarali o úklid?",
        yes: "Ano",
        no: "Ne",
        or: "nebo",
        warningBanner:
            "One or more of the dates for your hold are unavailable. Please check your selection and try again.",
        datesPlaceholder: "MM/DD/YYYY",
    },

    flyout: {
        vacasaContact: "Vacasa contact",
        contactUsFooter:
            "If you have any questions about this hold, please reach out.",
        back: "Back",
        clear: "Clear",
        holdPlaced: "Hold placed",
        holdUpdated: "Hold updated",
        tempAccessCode: "Dočasný přístupový kód",
        smartLockAria:
            "Kliknutím na tento odkaz odhalíte dočasný Smart Lock kód.",
        employeesMustClickToView: `Zaměstnanci musí <button>kliknout sem, aby byl tento kód zobrazen</button>. Z bezpečnostních důvodů bude tato akce zaznamenána.`,
        smartLockCode: "Smart Lock kód",
        notAvailable: "Není k dispozici",
        selectOption: "Vybrat možnost",
        reservation: "Rezervace",
        ownerStay: "Pobyt vlastníka",
        ownerStaySubtitle: "Rezervace pro vaše osobní použití.",
        complimentaryStay: "Bezplatný pobyt",
        complimentaryStaySubtitle: "Rezervace pro přátele nebo rodinu.",
        vacasaHold: "Rezervace společnosti Vacasa",
        vacasaHoldSubtitle:
            "Tato možnost je k dispozici pouze pro zaměstnance.",
        block: "Zablokovat",
        blockSubtitle: "Nikdo nebude využívat této nemovitosti.",
        propertyCare: "Péče o nemovitost",
        propertyCareSubtitle: "Údržba, vylepšení, dodávky, atd.",
        seasonalHold: "Seasonal hold",
        homeForSale: "Home for sale",
        homeForSaleSubtitle: "Activities related to a home sale.",
        other: "Other",
        otherSubtitle: "Does not fit the other categories.",
        terminationHold: "Termination hold",
        terminationHoldSubtitle: "Option only available for employees",
        notes: {
            title: "Notes ",
            titleRequired: "Notes* ",
            optional: "(optional)",
            required: "(required)",
            placeholder:
                "Help us improve our category options by providing a note about this hold",
            none: "N/A",
        },
        housekeeping: "Úklidové služby",
        yesHousekeepingSubtitle:
            "A Vacasa housekeeping visit will be scheduled after this hold.",
        yesHousekeepingSubtitleWithCleaningFee:
            "A Vacasa housekeeping visit will be scheduled after this hold and billed to your account in the amount of <strong>${amount}.</strong>",
        housekeepingNotAvailable:
            "Housekeeping not available. You will be responsible for cleaning and preparing the home for the next guests.",
        noHousekeepingSubtitle:
            "You will be responsible for cleaning and preparing the home for the next guests.",
        ownerResponsibleForCleaning: "Owner responsible for cleaning",
        housekeepingScheduled: "Naplánovaný úklid",
        noHousekeeping: "Žádný úklid",
        numberGuestsMax: `Maximální počet hostů: {{number}}`,
        noPets: "Domácí zvířata nejsou povolena",
        restrictionsDontApply: "Omezení pro hosty se nevztahují na vlastníky.",
        night: "night",
        nights: "noci",
        madeOn: "Booked on {creationDate}",
        madeBy: "Booked on {date} by {name}",
        monthCount:
            "Spans {monthCount, plural,=0 {}one {# month}other {# months}}",
        date: "Date",
        dates: "Datumy",
        guests: "Hosté",
        createHold: "Create Hold",
        next: "Next",
        summary: "Summary",
        cancelOwnerHold: "Cancel owner hold",
        cancelAreYouSure:
            "Are you sure you want to cancel? Your hold has not been scheduled.",
        deleteOwnerHold: "Delete owner hold",
        editHold: "Upravit rezervaci",
        edit: "Upravit",
        editAria: "Chcete-li údaje upravit, klikněte sem.",
        pleaseContactToRequestChange:
            "Kontaktujte prosím následující osobu: {{contact}} se žádostí o změnu termínu.",
        saveChanges: "Uložit změny",
        save: "Uložit",
        saveAria: "Chcete-li údaje uložit, klikněte sem.",

        delete: "Zrušit",
        removeHold: "Odstranit rezervaci",
        deleteHold: "Zrušit rezervaci",
        deleteAria: "Chcete-li údaje vymazat, klikněte sem.",
        holdRemoved: "Rezervace odstraněna",
        deleteAreYouSure: "Are you sure you want to delete this?",
        deleteHelperText: "Tuto akci nelze vrátit zpět.",
        deleteConfirm: "Ano, zrušit rezervaci",
        cancelDelete: "Ne, nezrušit",
        approvalNeeded: "Je vyžadováno schválení.",
        fixedRentDeletionApproval:
            "Během trvání smlouvy s pevným nájemným musí být zrušení rezervace schváleno naším týmem podpory.",
        request: "Request",
        requestDelete: "Žádost o zrušení",
        deleteRequestConfirmation:
            "Toto je žádost o zrušení rezervace vlastníka, která vznikla během trvání smlouvy s pevným nájemným.",
        holdNumberForRequest: "Žádost o zrušení rezervace číslo:",
        holdNumber: "Rezervace číslo:",
        startDate: "Termín zahájení:",
        endDate: "Termín ukončení:",

        cancel: "Zrušit",
        done: "Provedeno",
        totalNetRent: "Total Net rent",
        revenueBreakdown: "Revenue breakdown",
        fixedRent: "Pevné nájemné",
        grossRent: "Hrubé nájemné",
        guestReview: "Guest review",
        guestReviewCollapseLabel: "See less",
        guestReviewExpandLabel: "See more",

        inviteGuest: "Pozvat hosta",
        inviteGuests: "Invite Guests",
        inviteGuestsOptional: "Invite Guests (Optional)",
        inviteAnotherGuest: "Pozvat dalšího hosta",
        inviteGuestsSubtitle:
            "Add guests to share your trip details and home access information.",
        firstName: "Křestní jméno",
        lastName: "Příjmení",
        emailAddress: "E-mailová adresa",
        invitationSent: "Pozvánka odeslána",
        invitationsSent: "pozvánky odeslány",
        fiveOrMoreInvitationsSent: "pozvánek odesláno",
        guestRemoved: "Host odstraněn",
        skip: "Skip",
        skipAria: "Kliknutím na toto tlačítko přeskočíte přidávání hostů",
        inviteAnotherGuestAria:
            "Kliknutím na toto tlačítko můžete pozvat dalšího hosta",
        noGuestInfo: "Žádné informace o hostovi",
        guestInfo: "Guest info",
        adults: "dospělí",
        fiveOrMoreAdults: "dospělých",
        children: "děti",
        fiveOrMoreChildren: "dětí",
        dogs: "psi",
        fiveOrMoreDogs: "psů",
        housekeepingNotAllowed:
            "Housekeeping not available. You will be responsible for cleaning and preparing the home for the next guests.",
        newBooking: "New booking",
        pastBooking: "Past booking",
        invalidDates: "The selected dates are not available.",
        learnAboutRates: "Learn about rates",
        guestBookingLikely:
            "These nights are highly likely to be booked by guests.",
        response: "Manager response",
        onboardingFooter: {
            title: "What do you want to do next?",
            backToOnboarding: "Go Back to Onboarding",
            addAdditionalOwnerHolds: "Add Another Owner Hold",
        },
        costOfHold:
            "You miss out on <b>{cost}</b> in gross rent by booking these dates.",
        housekeepingRequired:
            "In order to maintain the highest quality guest experience, housekeeping is required for stays with overnight guests.",
        housekeepingRequiedWithCleaningFee:
            "This owner hold requires a post-stay clean, which will be billed to your account after the stay in the amount of <strong>${amount}.</strong>",
        PhoneSections: {
            smsTitle: "Owner contact info",
            smsDescription: 'Details will be sent to this number.',
            smsEditDescription: "Details will be sent to <strong>{number}</strong>.",
            invalidPhoneMessage: 'The phone number is not valid',
            noPhoneNumber: 'No phone number provided'
        }
    },

    UpcomingStays: {
        title: "Reservations",
        emptyStateUpcoming:
            "New reservations and owner holds will show up here.",
        emptyStatePast:
            "You have no previous reservations. When upcoming stays have concluded, they will appear here.",
        upcomingDescription:
            "Upcoming guest reservations and owner holds will be listed below. Click a row to view more details. If you wish to modify an owner hold you can do so by clicking on the corresponding row.",
        pastDescription:
            "Your past reservations and holds in the last 45 days are listed out below.  If you would like to view more details around each stay, click on the detail row. ",
        toModify:
            "Chcete-li změnit rezervaci vlastníka, můžete tak učinit níže.",
        fixedRentModify:
            "Pokud potřebujete upravit rezervaci vaší rekreační nemovitosti během období s pevně stanoveným nájemným, zašlete prosím e-mailovou zprávu na adresu",
        details: "Detaily",
        Night: "Noc",
        adult: "dospělá osoba",
        child: "dítě",
        dog: "pes",
        at: "pro",
        upcomingTabTitle: "Upcoming ({total})",
        previousTabTitle: "Previous",
        adults: "adults",
        children: "children",
    },

    errorMessages: {
        generalError:
            "Nepodařilo se nám zpracovat váš požadavek. Zkuste to prosím znovu později. Pokud bude tento problém přetrvávat, kontaktujte následující osobu: {name} na čísle {phone}",
        createHoldError: "Nepodařilo se vytvořit vaši rezervaci vlastníka.",
        deleteHoldError: "Nepodařilo se odstranit vaši rezervaci vlastníka.",
        updateHoldError: "Nepodařilo se aktualizovat vaši rezervaci vlastníka.",
        updateHoldCleanError: "Nepodařilo se aktualizovat váš stav úklidu.",
        addGuestToHoldError: "Nepodařilo se přidat vašeho hosta.",
        removeGuestFromHoldError: "Nepodařilo se odstranit vašeho hosta.",
        datesNotAvailableError:
            "Jeden nebo více termínů vaší rezervace není k dispozici. Zkontrolujte prosím svůj výběr a zkuste to znovu.",
        modalTitle: "Oops!",
        modalMessage:
            "It looks like this reservation is no longer in our system.",
        invalidDateError: "This date is unavailable",
        unableToLoadReservations: "We were unable to load your reservations.",
    },

    reservations: {
        reservation: "Reservation",
        vacasaHold: "Vacasa hold",
    },

    switchAccounts: {
        notification: "This reservation is associated with another account",
        description:
            "Please login to the appropriate homeowner account and click the new booking email notification to view the new reservation details.",
        button: "Switch Accounts",
        maybeLater: "Maybe Later",
        descriptionButtom:
            "Interested in linking your accounts into one login? Call <strong>Owner Support</strong> at {phone} to get it set-up.",
    },

    reservationType: {
        ownerHold: "Owner Hold",
        vacasaHold: "Vacasa Hold",
        reservation: "Guest Stay",
    },
}

export default calendar
