import { Owner } from "@vacasa/owner-api-models"
import { MOBILE_BREAKPOINT } from "Constants"
import classNames from "classnames"
import { useOwnerFeatureFlag } from "hooks/owner-feature-flag"
import { observer } from "mobx-react"
import { useCallback, useState } from "react"
import { FormattedMessage } from "react-intl"
import { useNavigate } from "react-router-dom"
import { trackProfileMenuReferral } from "services/segment/referral/referralTracking"
import { isFeatureFlagEnabled } from "utils/feature-flag"
import { APP_URL } from "../../../Environment"
import { ReactComponent as BackIcon } from "../../../assets/go-back.svg"
import { ReactComponent as SwitchUserIcon } from "../../../assets/icon-switch-user.svg"
import { ReactComponent as UserCheckIcon } from "../../../assets/icon-user-check.svg"
import { ReactComponent as UserPlusIcon } from "../../../assets/icon-user-plus.svg"
import { ReactComponent as VacasaLogo } from "../../../assets/vacasa-logo-horizontal-blue-140x32.svg"
import { ReactComponent as ProfileSelectedIcon } from "../../../assets/vector.svg"
import useWindowWidth from "../../../hooks/common/useWindowSize"
import { useChangeUserMutation } from "../../../hooks/owner-preferences"
import { useMobileNavigation } from "../../../views/navigation"
import { Dropdown, DropdownItem } from "../Dropdown/Dropdown"
import SVGIcon from "../SVGIcon/index"
import { useLoginInfo } from "contexts/login"
import { logout } from "utils/auth"
import { useUser } from "hooks/user"

enum DropdownStatus {
    CURRENT_PROFILE,
    SWITCH_ACCOUNT,
}

const UserSelect = ({
    name,
    selected = false,
}: {
    name: string
    selected?: boolean
}): JSX.Element => {
    const LeftIcon = selected ? UserCheckIcon : UserPlusIcon
    return (
        <div className={selected ? "selectedUser" : "unselectedUser"}>
            <LeftIcon className="icon-svg w-16 h-16" />
            <span>{name}</span>
            {selected && <ProfileSelectedIcon className="icon-svg w-16 h-16" />}
        </div>
    )
}

const userIcon = (
    <SVGIcon
        svg="icon-feather-user"
        className="inline__svg icon-svg w-16 h-16"
        colorName="midnight"
    />
)
const closeIcon = (
    <SVGIcon
        svg="icon-feather-x"
        className="inline__svg icon-svg w-16 h-16"
        colorName="midnight"
    />
)

const CurrentProfileDropdown = ({
    setDropdownStatus,
    showReferralModal,
    user,
}: {
    setDropdownStatus: (status: DropdownStatus) => void
    showReferralModal: (shouldShowReferralModal: boolean) => void
    user: Owner | null
}): JSX.Element | null => {
    const navigate = useNavigate()
    const { contactIds } = useLoginInfo()
    const ownerReferralFeatureFlag = useOwnerFeatureFlag("owner-referrals")
    const isEnabled = isFeatureFlagEnabled(ownerReferralFeatureFlag)
    const userNotificationPreferenceUIFlag = useOwnerFeatureFlag(
        "user-notification-preference-ui"
    )
    const isUserNotificationPreferenceUIEnabled = isFeatureFlagEnabled(
        userNotificationPreferenceUIFlag
    )

    const handleOnClick = useCallback(() => {
        trackProfileMenuReferral()
        showReferralModal(true)
    }, [showReferralModal])

    return (
        <>
            <div
                className={classNames("dropdown-item-text", {
                    "dropdown-item-text--spacer": isEnabled,
                })}
            >
                {user?.accountInfo.firstName ?? ""}{" "}
                {user?.accountInfo.lastName ?? ""}
            </div>
            {contactIds && contactIds.length > 1 && (
                <>
                    <DropdownItem
                        onClick={event => {
                            setDropdownStatus(DropdownStatus.SWITCH_ACCOUNT)
                            event.stopPropagation()
                        }}
                    >
                        <SwitchUserIcon className="icon-svg w-16 h-16" />
                        <FormattedMessage
                            id="Topbar.switchAccounts"
                            description="Switch between multiple accounts"
                            defaultMessage="Switch Accounts"
                        />
                    </DropdownItem>
                    <DropdownItem line={true} />
                </>
            )}
            {isUserNotificationPreferenceUIEnabled && (
                <DropdownItem
                    className={isEnabled ? "item--slim" : ""}
                    onClick={() => {
                        navigate(`/notification-settings`)
                    }}
                >
                    <SVGIcon
                        svg="icon-feather-bell"
                        className="icon-svg w-16 h-16"
                    />
                    <FormattedMessage
                        id="Topbar.Notifications"
                        description="Redirect to Notifications page"
                        defaultMessage="Notifications"
                    />
                </DropdownItem>
            )}
            <DropdownItem
                className={isEnabled ? "item--slim" : ""}
                onClick={() => {
                    navigate(`/profile`)
                }}
            >
                <SVGIcon
                    svg="icon-feather-user"
                    className="icon-svg w-16 h-16"
                />
                <FormattedMessage
                    id="Topbar.profile"
                    description="Redirect to Profile page"
                    defaultMessage="Profile"
                />
            </DropdownItem>
            {isEnabled && (
                <DropdownItem
                    className={"item--slim"}
                    onClick={() => {
                        handleOnClick()
                    }}
                    testId="refer-a-friend"
                >
                    <SVGIcon
                        svg="icon-feather-heart"
                        className="icon-svg w-16 h-16"
                    />
                    <FormattedMessage
                        id="Topbar.referral"
                        defaultMessage="Refer a friend"
                    />
                </DropdownItem>
            )}
            <DropdownItem line={true} />
            <DropdownItem onClick={logout}>
                <SVGIcon
                    svg="icon-feather-log-out"
                    className="icon-svg w-16 h-16"
                />
                <FormattedMessage
                    id="Topbar.logout"
                    description="Logout of the app"
                    defaultMessage="Logout"
                />
            </DropdownItem>
        </>
    )
}

const SwitchAccountDropdown = ({
    setDropdownStatus,
}: {
    setDropdownStatus: (status: DropdownStatus) => void
}): JSX.Element => {
    const loginInfo = useLoginInfo()
    const changeUserMutation = useChangeUserMutation()
    return (
        <div className="accountSelector">
            <DropdownItem
                onClick={event => {
                    setDropdownStatus(DropdownStatus.CURRENT_PROFILE)
                    event.stopPropagation()
                }}
            >
                <BackIcon className="icon-svg w-16 h-16" />
            </DropdownItem>
            <div className="select-account">
                <FormattedMessage
                    id="Topbar.selectAccount"
                    description="Choose an account from a list"
                    defaultMessage="Select An Account"
                />
            </div>
            {loginInfo.user && (
                <DropdownItem>
                    <UserSelect
                        name={`${loginInfo.user.accountInfo.firstName} ${loginInfo.user.accountInfo.lastName}`}
                        selected
                    />
                </DropdownItem>
            )}
            {loginInfo.users
                ?.filter(user => user.contactId !== loginInfo.user?.contactId)
                .map(user => (
                    <DropdownItem
                        key={user.contactId}
                        onClick={() =>
                            changeUserMutation.mutate({
                                contactId: user.contactId,
                            })
                        }
                    >
                        <UserSelect
                            name={`${user.accountInfo.firstName} ${user.accountInfo.lastName}`}
                        />
                    </DropdownItem>
                ))}
        </div>
    )
}

const TopBar = observer(
    ({
        showReferralModal,
    }: {
        showReferralModal: (shouldShowReferralModal: boolean) => void
    }) => {
        const [dropdownStatus, setDropdownStatus] = useState(
            DropdownStatus.CURRENT_PROFILE
        )
        const { setOpen: setMobileNavigationOpen, open: mobileNavigationOpen } =
            useMobileNavigation()
        const screenWidth = useWindowWidth()
        const isMobileWidth = screenWidth < MOBILE_BREAKPOINT

        const toggleNavigation = useCallback(() => {
            setMobileNavigationOpen(open => !open)
        }, [setMobileNavigationOpen])
        const { user } = useUser()
        const [accountDropdownIcon, setAccountDropdownIcon] = useState(userIcon)
        const handleActiveChange = useCallback(
            (active: boolean) => {
                if (!active) {
                    setDropdownStatus(DropdownStatus.CURRENT_PROFILE)
                }
                setMobileNavigationOpen(false)
                setAccountDropdownIcon(
                    active && screenWidth && screenWidth < 480
                        ? closeIcon
                        : userIcon
                )
            },
            [screenWidth, setMobileNavigationOpen]
        )

        if (user === null) return null

        return (
            <div className="top-bar-container">
                <div className={`top-bar border-none`}>
                    {isMobileWidth && (
                        <button
                            data-testid="mobile-menu"
                            className={
                                mobileNavigationOpen
                                    ? "mobile-menu open"
                                    : "mobile-menu"
                            }
                            onClick={() => {
                                toggleNavigation()
                                setAccountDropdownIcon(userIcon)
                            }}
                        >
                            <span className="mobile-menu-open">
                                <SVGIcon
                                    svg="icon-feather-menu"
                                    className="icon-svg w-24 h-24"
                                    colorName="midnight"
                                />
                            </span>
                            <span className="mobile-menu-close">
                                <SVGIcon
                                    svg="icon-feather-x"
                                    className="icon-svg w-24 h-24"
                                    colorName="midnight"
                                />
                            </span>
                        </button>
                    )}

                    <div className="flex mobile-center-align">
                        <a className="logo-horizontal" href={APP_URL}>
                            <VacasaLogo className={"logo"} />
                        </a>
                    </div>
                    <div
                        className="user"
                        style={{ cursor: "pointer" }}
                        data-testid="topbar-dropdown"
                    >
                        <Dropdown
                            onActiveChange={handleActiveChange}
                            label={
                                <div className="user-first-name regular">
                                    {user?.accountInfo.firstName ?? ""}{" "}
                                    {user?.accountInfo.lastName ?? ""}
                                    <span className="caret" />
                                </div>
                            }
                            iconComponent={accountDropdownIcon}
                            onItemClicked={() => {
                                handleActiveChange(false)
                            }}
                        >
                            {dropdownStatus ===
                                DropdownStatus.CURRENT_PROFILE && (
                                <CurrentProfileDropdown
                                    user={user}
                                    setDropdownStatus={setDropdownStatus}
                                    showReferralModal={showReferralModal}
                                />
                            )}
                            {dropdownStatus ===
                                DropdownStatus.SWITCH_ACCOUNT && (
                                <SwitchAccountDropdown
                                    setDropdownStatus={setDropdownStatus}
                                />
                            )}
                        </Dropdown>
                    </div>
                </div>
                <div
                    className={
                        mobileNavigationOpen
                            ? "mobile-overlay-background open"
                            : "mobile-overlay-background"
                    }
                />
            </div>
        )
    }
)

export default TopBar
