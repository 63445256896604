import React, { ChangeEvent, useCallback, useState } from "react"
import { OwnerHoldType, UnitAvailabilityDays } from "@vacasa/owner-api-models"
import HoldType from "lib/components/FlyOut/Holds/HoldType"
import { FormattedMessage, IntlShape, useIntl } from "react-intl"
import { OwnerHoldBadge } from "lib/components/Badge"
import StaticList from "lib/components/FlyOut/Holds/StaticList"
import styles from "./ReservationSummary.module.scss"
import { format, isValid } from "date-fns"
import StaticListItem from "lib/components/FlyOut/Holds/StaticListItem/StaticListItem"
import { ReactComponent as DateIcon } from "./../../../../assets/icon-calendar-new.svg"
import { ReactComponent as NotesIcon } from "./../../../../assets/icon-notes.svg"
import { ReactComponent as UsersIcon } from "./../../../../assets/icon-users.svg"
import { ReactComponent as SprayBottleIcon } from "./../../../../assets/icon-spray-bottle.svg"
import StaticListSubItem from "lib/components/FlyOut/Holds/StaticListSubItem"
import { useHousekeepingRequired } from "hooks/housekeeping-required/useHousekeepingRequired"
import { UnitInfo } from "../UnitInfo"
import { ActiveStatus, getUnitStatusTypeByStatusNumber } from "utils/unit"
import InviteGuestContent from "lib/components/FlyOut/Holds/InviteGuestItem/InviteGuestContent"
import {
    getGuestSummary,
    isHousekeepingChecked,
} from "utils/reservations/reservationsUtil"
import DateRangeInputPicker from "core/components/dates/DateRangeInputPicker/DateRangeInputPicker"
import { DEFAULT_DRP_PROPS } from "core/components/dates/DateRangeInputPicker/DateRangeInputPicker.props"
import { useUserCurrencyCode } from "hooks/user"
import { trackCreateHoldEditButtonClicked } from "services/segment/ownerHold/ownerHoldTracking"
import { isEmpty } from "lodash"
import { ActionName } from "lib/components/FlyOut/Holds/StaticListItem/StaticListActionItem"
import { useOwnerFeatureFlag } from "hooks/owner-feature-flag"
import { isDefined } from "utils/common"
import { PhoneInput } from "../../../../lib/components/FlyOut/Holds/PhoneInput/PhoneInput"

type ReservationSummaryProps = {
    startDate: Date | null
    endDate: Date | null
    holdType: OwnerHoldType
    unitInfo: UnitInfo
    cleanAfterStay: boolean
    adults: number
    children: number
    pets: number
    guests: GuestInfo[]
    notes: string | undefined
    onEditGuestClick: () => void
    availability: UnitAvailabilityDays
    handleDatesChange: (startDate: Date | null, endDate: Date | null) => void
    handleNotesChange: (notes: string | undefined) => void
    housekeepingAllowed: boolean
    housekeepingRequired: boolean
    onHousekeepingSelectChange: (value: boolean) => void
    phone: string
    handlePhoneChange: (value: string) => void
    handlePhoneValid: (valid: boolean) => void
}

export type GuestInfo = {
    firstName: string | null
    lastName: string | null
    email: string | null
}

const generateStayType = (holdType: OwnerHoldType): JSX.Element => {
    return (
        <HoldType
            title={
                <FormattedMessage
                    id="Calendar.ownerHolds.ownerHold"
                    defaultMessage="Owner hold"
                />
            }
            subtext={null}
            pill={null}
            primary
            Tag={OwnerHoldBadge({ type: holdType })}
        />
    )
}

const generateDateDisplay = ({
    startDate,
    endDate,
    onEditDatesClick,
    changeDates,
    unitInfo,
    currencyCode,
    availability,
    handleDatesChange,
    saveLink,
    onSaveClick,
    showEditButton,
}: {
    startDate: Date | null
    endDate: Date | null
    onEditDatesClick: () => void
    changeDates: boolean
    unitInfo: UnitInfo
    currencyCode: string
    availability: UnitAvailabilityDays
    handleDatesChange: (startDate: Date | null, endDate: Date | null) => void
    saveLink: boolean
    onSaveClick: () => void
    showEditButton: boolean
}): JSX.Element => {
    const formatDate = (date: Date | null, dateFormat: string): string => {
        if (!date || !isValid(date)) {
            return ""
        }
        return format(date, dateFormat)
    }
    const startDay = formatDate(startDate, "dd")
    const endDay = formatDate(endDate, "dd")
    const startMonth = formatDate(startDate, "MMM")
    const endMonth = formatDate(endDate, "MMM")
    const startYear = formatDate(startDate, "yy")
    const endYear = formatDate(endDate, "yy")

    let dateDisplay = `${startMonth} ${startDay} - ${endMonth} ${endDay}'${endYear}`
    if (startYear !== endYear) {
        dateDisplay = `${startMonth} ${startDay}'${startYear} - ${endMonth} ${endDay}'${endYear}`
    }

    return (
        <>
            <StaticListItem
                title={
                    <FormattedMessage
                        id="CalendarPage.flyout.dates"
                        defaultMessage="Dates"
                    />
                }
                subtext={<span>{dateDisplay}</span>}
                Icon={DateIcon}
                displayAction={showEditButton || saveLink}
                displayActionItem={saveLink ? ActionName.Save : ActionName.Edit}
                onEditClick={onEditDatesClick}
                onSaveClick={onSaveClick}
            />
            {changeDates && (
                <div className={styles.dateSelector}>
                    <DateRangeInputPicker
                        {...DEFAULT_DRP_PROPS}
                        disableStartDate={false}
                        initialStartDate={startDate}
                        initialEndDate={endDate}
                        blockAllDays={
                            !unitInfo.allowHolds ||
                            !unitInfo.isCurrentUnitActive
                        }
                        ownerCurrency={currencyCode}
                        calendarAvailability={availability}
                        handleDatesChange={handleDatesChange}
                    />
                </div>
            )}
        </>
    )
}

const getHouseKeepingOption = (
    housekeeping: boolean,
    cleaningFees: number | null,
    housekeepingAllowed: boolean,
    housekeepingRequired: boolean,
    onHousekeepingSelectChange: (value: boolean) => void
) => {
    const getHousekeepingDisplayMessage = () => {
        if (
            !housekeepingRequired &&
            (!housekeepingAllowed || (housekeepingAllowed && !housekeeping))
        ) {
            return (
                <FormattedMessage
                    id="CalendarPage.flyout.ownerResponsibleForCleaning"
                    defaultMessage="Owner responsible for cleaning"
                />
            )
        } else {
            return cleaningFees ? (
                `$${cleaningFees.toFixed(2)}`
            ) : (
                <FormattedMessage
                    id="CalendarPage.OwnerHolds.yes"
                    defaultMessage="Yes"
                />
            )
        }
    }

    const isHousekeepingOptional = housekeepingAllowed && !housekeepingRequired

    return (
        <StaticListItem
            title={
                <FormattedMessage
                    id="CalendarPage.flyout.housekeeping"
                    defaultMessage="Housekeeping"
                />
            }
            subtext={<span>{getHousekeepingDisplayMessage()}</span>}
            Icon={SprayBottleIcon}
            displayAction={isHousekeepingOptional}
            displayActionItem={ActionName.Switch}
            isSwitchChecked={isHousekeepingChecked({
                housekeeping: !!housekeeping,
                housekeepingAllowed,
                housekeepingRequired,
            })}
            isSwitchDisabled={housekeepingRequired || !housekeepingAllowed}
            onOnOffSwitchClick={checked => onHousekeepingSelectChange(!checked)}
        />
    )
}

export const generateGuestDisplay = ({
    adults,
    children,
    pets,
    guests,
    intl,
    editButton,
    onEditGuestClick,
    onEditClick,
}: {
    adults: number
    children: number
    pets: number
    guests: GuestInfo[]
    intl: IntlShape
    editButton: boolean
    onEditGuestClick?: () => void
    onEditClick?: (guest: GuestInfo) => void
}) => {
    const guestSummary = getGuestSummary(adults, children, pets, intl)
    const guestItems = guests.map(guest => {
        const onEditGuestClick = onEditClick
            ? useCallback(() => {
                  onEditClick(guest)
              }, [guest])
            : undefined
        const content = (
            <InviteGuestContent
                firstName={guest.firstName}
                lastName={guest.lastName}
                email={guest.email}
                onEditClick={onEditGuestClick}
            />
        )
        return <StaticListSubItem content={content} />
    })
    const guestDisplay = (
        <div className={guestItems.length > 0 ? styles.guestDisplay : ""}>
            <StaticList items={guestItems} />
        </div>
    )
    return (
        <>
            <StaticListItem
                title={
                    <FormattedMessage
                        id="CalendarPage.flyout.guestInfo"
                        defaultMessage="Guest info"
                    />
                }
                subtext={guestSummary.join(", ")}
                Icon={UsersIcon}
                displayAction={editButton}
                displayActionItem={ActionName.Edit}
                onEditClick={onEditGuestClick}
                customStyling={{
                    Icon: styles["reservation-flyout-icon"],
                    Wrapper: guests.length > 0 ? styles["guestInfo"] : "",
                }}
            />
            {guestDisplay}
        </>
    )
}

const generateHoldNotes = (
    notes: string | undefined,
    onEditNotesClick: () => void,
    editNotes: boolean,
    handleNotesUpdate: (notes: string) => void,
    saveNotesLink: boolean,
    onSaveNotesClick: () => void,
    holdType: OwnerHoldType,
    isSaveDisabled: boolean,
    handleIsSaveDisabled: (disabled: boolean) => void,
    showEditButton: boolean
) => {
    const displayNotes =
        notes && !isEmpty(notes) ? (
            notes
        ) : (
            <FormattedMessage
                id="CalendarPage.flyout.notes.none"
                defaultMessage="N/A"
            />
        )

    const onInputChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { value } = e.target
        handleNotesUpdate(value)
        if (holdType === "other") {
            handleIsSaveDisabled(!value || isEmpty(value))
        }
    }
    return (
        <>
            <StaticListItem
                title={
                    <FormattedMessage
                        id="CalendarPage.flyout.notes.title"
                        defaultMessage="Notes"
                    />
                }
                subtext={editNotes ? null : displayNotes}
                Icon={NotesIcon}
                displayAction={saveNotesLink || showEditButton}
                displayActionItem={
                    saveNotesLink ? ActionName.Save : ActionName.Edit
                }
                onEditClick={onEditNotesClick}
                onSaveClick={onSaveNotesClick}
                isSaveDisabled={isSaveDisabled}
            />
            {editNotes && (
                <textarea
                    className={styles.notesInput}
                    onChange={onInputChange}
                    value={notes}
                    rows={3}
                    maxLength={255}
                />
            )}
        </>
    )
}

const ReservationSummary: React.FC<ReservationSummaryProps> = ({
    startDate,
    endDate,
    holdType,
    unitInfo,
    cleanAfterStay,
    adults,
    children,
    pets,
    guests,
    notes,
    phone,
    onEditGuestClick,
    availability,
    handleDatesChange,
    handleNotesChange,
    housekeepingAllowed,
    housekeepingRequired,
    onHousekeepingSelectChange,
    handlePhoneChange,
    handlePhoneValid
}) => {
    const intl = useIntl()
    const currencyCode = useUserCurrencyCode()

    const [showEditButton, setShowEditButton] = useState(true)
    const [changeDates, setChangeDates] = useState(false)
    const [saveDatesLink, setSaveDatesLink] = useState(false)
    const [selectedStartDate, setSelectedStartDate] = useState(startDate)
    const [selectedEndDate, setSelectedEndDate] = useState(endDate)
    const [editNotes, setEditNotes] = useState(false)
    const [saveNotesLink, setSaveNotesLink] = useState(false)
    const [updatedNotes, setUpdatedNotes] = useState(notes)
    const [isSaveDisabled, setIsSaveDisabled] = useState(false)

    const onEditDatesClick = useCallback(() => {
        setChangeDates(true)
        setSaveDatesLink(true)
        setShowEditButton(false)
        trackCreateHoldEditButtonClicked("dates")
    }, [])

    const onSaveDatesClick = useCallback(() => {
        setSaveDatesLink(false)
        setChangeDates(false)
        setShowEditButton(true)
        handleDatesChange(selectedStartDate, selectedEndDate)
    }, [handleDatesChange, selectedEndDate, selectedStartDate])

    const handleDatesSelect = (
        startDate: Date | null,
        endDate: Date | null
    ) => {
        setSelectedStartDate(startDate)
        setSelectedEndDate(endDate)
    }

    const onEditNotesClick = useCallback(() => {
        setEditNotes(true)
        setSaveNotesLink(true)
        setShowEditButton(false)
        trackCreateHoldEditButtonClicked("notes")
    }, [])

    const handleNotesUpdate = (notes: string) => {
        setUpdatedNotes(notes)
    }

    const onSaveNotesClick = useCallback(() => {
        setSaveNotesLink(false)
        setEditNotes(false)
        setShowEditButton(true)
        handleNotesChange(updatedNotes)
    }, [handleNotesChange, updatedNotes])

    const ownerHoldsV3 = useOwnerFeatureFlag("owner-portal-add-phone-number-in-owner-hold-form")

    const handleIsSaveDisabled = (disabled: boolean) =>
        setIsSaveDisabled(disabled)

    const acquisitionID = unitInfo.unit.attributes.acquisitionId
    const state = unitInfo.unit.attributes.address.state
    const unitStatusNumber: ActiveStatus = unitInfo.unit.attributes?.active
    const unitStatus = getUnitStatusTypeByStatusNumber(unitStatusNumber)
    const isGuestworksUnit =
        unitInfo.unit.attributes.status.guestworksApp.isGuestworksUnit

    const housekeepingRequiredQuery = useHousekeepingRequired({
        acquisitionID,
        holdType,
        state,
        unitStatus,
        isGuestworksUnit,
    })

    const cleaningFee = housekeepingRequiredQuery.data?.attributes.cleaningFee

    const type = generateStayType(holdType)
    const phoneInput = ownerHoldsV3.isEnabled && ( holdType === "owner stay") ? (
        <PhoneInput
            phone={phone}
            onChange={handlePhoneChange}
            onValidate={handlePhoneValid}
        />
    ) : undefined

    const dates = generateDateDisplay({
        startDate,
        endDate,
        onEditDatesClick,
        changeDates,
        unitInfo,
        currencyCode,
        availability,
        handleDatesChange: handleDatesSelect,
        saveLink: saveDatesLink,
        onSaveClick: onSaveDatesClick,
        showEditButton,
    })
    const houseKeeping = getHouseKeepingOption(
        cleanAfterStay,
        cleaningFee ?? null,
        housekeepingAllowed,
        housekeepingRequired,
        onHousekeepingSelectChange
    )
    const guestDisplay = generateGuestDisplay({
        adults,
        children,
        pets,
        guests,
        intl,
        editButton: showEditButton,
        onEditGuestClick,
    })
    const holdNotes = generateHoldNotes(
        updatedNotes,
        onEditNotesClick,
        editNotes,
        handleNotesUpdate,
        saveNotesLink,
        onSaveNotesClick,
        holdType,
        isSaveDisabled,
        handleIsSaveDisabled,
        showEditButton
    )
    let items = []
    switch (holdType) {
        case "owner stay":
            items = [
                type,
                phoneInput,
                dates,
                houseKeeping,
                guestDisplay,
                holdNotes,
            ].filter(isDefined)
            break
        case "complimentary stay":
        default:
            items = [type, dates, houseKeeping, guestDisplay, holdNotes]
            break
        case "property care":
        case "seasonal hold":
        case "block":
        case "other":
        case "termination hold":
        case "home for sale":
            items = [type, dates, houseKeeping, holdNotes]
            break
    }

    return (
        <div>
            <div className={styles.title}>
                <FormattedMessage
                    id="CalendarPage.flyout.summary"
                    defaultMessage="Summary"
                />
            </div>
            <StaticList items={items} />
        </div>
    )
}

export { ReservationSummary }
