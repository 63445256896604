import classNames from "classnames"
import {
    ChangeEvent,
    FocusEvent,
    Ref,
    TextareaHTMLAttributes,
    forwardRef,
    useCallback,
    useState,
} from "react"
import { XCircleInverse } from "../../../assets"
import styles from "./Textarea.module.scss"
import { Label } from "../Label"

export interface InputProps
    extends TextareaHTMLAttributes<HTMLTextAreaElement> {
    containerClassName?: string
    labelText?: string
    labelClassName?: string
    error?: boolean
    hint?: string | React.ReactNode
    inputHintClassName?: string
}

export interface BaseInputProps<T extends string | number> {
    parseEventValue: (v: string) => T
    type: "string" | "number"
}

export const Textarea = forwardRef(
    (
        {
            containerClassName,
            className,
            defaultValue = "",
            error,
            hint,
            inputHintClassName,
            labelText,
            labelClassName,
            onBlur,
            onFocus,
            onChange,
            readOnly,
            disabled,
            ...textareaProps
        }: InputProps,
        ref: Ref<HTMLTextAreaElement>
    ) => {
        const [focused, setFocused] = useState(false)

        const onInputFocus = useCallback(
            (e: FocusEvent<HTMLTextAreaElement>) => {
                setFocused(true)
                if (onFocus) onFocus(e)
            },
            [onFocus]
        )

        const onInputBlur = useCallback(
            (e: FocusEvent<HTMLTextAreaElement>) => {
                setFocused(false)
                if (onBlur) onBlur(e)
            },

            [onBlur]
        )

        const onInputClearClick = useCallback(() => {
            onChange?.({
                target: {
                    name: textareaProps.name,
                    value: defaultValue,
                },
            } as ChangeEvent<HTMLTextAreaElement>)
        }, [defaultValue, textareaProps.name, onChange])

        const isDisabledOrReadonly = disabled || readOnly

        return (
            <div className={classNames(styles.container, containerClassName)}>
                {labelText && (
                    <Label
                        className={classNames(
                            labelClassName,
                            styles.inputLabel
                        )}
                        htmlFor={textareaProps?.id ?? textareaProps.name}
                        error={error}
                    >
                        {labelText}
                    </Label>
                )}
                <div
                    className={classNames(styles.inputWrapper, "inputWrapper")}
                >
                    <textarea
                        ref={ref}
                        className={classNames(styles.input, className, {
                            [styles.inputError as string]: error,
                        })}
                        onFocus={onInputFocus}
                        onBlur={onInputBlur}
                        onChange={onChange}
                        readOnly={readOnly}
                        disabled={disabled}
                        {...textareaProps}
                    />
                    {focused &&
                        textareaProps.value !== defaultValue &&
                        !isDisabledOrReadonly && (
                            <button
                                className={styles.inputClear}
                                onMouseDown={onInputClearClick}
                                tabIndex={-1}
                                type="button"
                            >
                                <XCircleInverse />
                            </button>
                        )}
                </div>
                {!!hint && (
                    <div
                        className={classNames(
                            styles.inputHint,
                            inputHintClassName,
                            {
                                [styles.inputHintError as string]: error,
                            }
                        )}
                    >
                        {hint}
                    </div>
                )}
            </div>
        )
    }
)
