import { Unit } from "@vacasa/owner-api-models/src/legacy/Earnings"
import {
    displayFormattedNumber,
    formatDate,
} from "utils/statements/statementsUtil"
import { FormattedMessage } from "react-intl"
import styles from "../statements.module.scss"

const displayCoOwnerProceeds = (currency: string, unit: Unit) => {
    if (
        !unit.co_owner_proceeds ||
        !unit.co_owner_proceeds.total_co_owner_proceeds
    ) {
        return null
    }
    const postDate = formatDate(unit.co_owner_proceeds.posting_date ?? "")
    return (
        <tr className="">
            <td className="gutter" />
            <td className="date">{postDate}</td>
            <td colSpan={3} className="desc-span">
                <FormattedMessage
                    id="Statements.coOwnerProceeds"
                    defaultMessage="Co-Owner Proceeds"
                />
            </td>
            <td className="text-right amount">
                {displayFormattedNumber(
                    Number(unit.co_owner_proceeds.total_co_owner_proceeds),
                    currency
                )}
            </td>
            <td className={styles["gutter-end"]} />
        </tr>
    )
}

interface UnitDetailsCoOwnerProceedsProps {
    currency: string
    unit: Unit
}

const UnitDetailsCoOwnerProceeds: React.FC<
    UnitDetailsCoOwnerProceedsProps
> = props => {
    const { currency, unit } = props
    return (
        <>
            <div className="table-responsive">
                <table className="open table">
                    <thead className={styles["uppercase-header"]}>
                        <tr>
                            <td colSpan={5}>
                                <FormattedMessage
                                    id="Statements.coOwnerProceeds"
                                    defaultMessage="Co-Owner Proceeds"
                                />
                            </td>
                            <td colSpan={2} className="text-right">
                                {displayFormattedNumber(
                                    Number(
                                        unit.co_owner_proceeds
                                            .total_co_owner_proceeds
                                    ),
                                    currency
                                )}
                            </td>
                            <td className={styles["inset"]} />
                        </tr>
                    </thead>
                </table>
            </div>
            <div className="table-responsive">
                <table className="open table">
                    <tbody className={"show"}>
                        {displayCoOwnerProceeds(currency, unit)}
                    </tbody>
                    <tfoot className={"show"}>
                        <tr>
                            <td className="gutter" />
                            <td colSpan={4} className="total-span">
                                <strong>Total</strong>
                            </td>
                            <td className="text-right">
                                <strong>
                                    {displayFormattedNumber(
                                        Number(
                                            unit.co_owner_proceeds
                                                .total_co_owner_proceeds
                                        ),
                                        currency
                                    )}
                                </strong>
                            </td>
                            <td className={styles["gutter-end"]} />
                        </tr>
                    </tfoot>
                </table>
            </div>
        </>
    )
}

export default UnitDetailsCoOwnerProceeds
