import { JSONAPIResource, Unit } from "@vacasa/owner-api-models"
import { useMemo, useState } from "react"
import styles from "./OnboardingProgressBarV2.module.scss"
import { useAvailability } from "../../hooks/availibility"
import { addDays, formatISO } from "date-fns"
import { FormattedMessage, useIntl } from "react-intl"
import { ReactComponent as CheckCircle } from "../../assets/check-circle.svg"
import { GeneralError } from "lib/components/GeneralError/GeneralError"
import { useTickets } from "hooks/ticket"
import { useContactId } from "hooks/user"
interface OnboardingProgressBarV2Props {
    onboardingUnit?: JSONAPIResource<Unit>
}

type ProgressStatus = "notStarted" | "inProgress" | "completed"

const ProgressBarContents = ({
    titleText,
    status,
    testId,
}: {
    titleText: JSX.Element
    status: ProgressStatus
    testId?: string
}) => {
    return (
        <div className={styles.individualGrids} data-testid={`${testId}-card`}>
            <div className={styles.gridTitleText}>{titleText}</div>

            <div className={styles.gridProgressBar}>
                {status === "notStarted" && null}
                {status === "inProgress" && (
                    <div className={styles.inProgress} />
                )}
                {status === "completed" && <div className={styles.completed} />}
            </div>
            <div className={styles.gridStatusText}>
                {status === "notStarted" && (
                    <FormattedMessage
                        id="Onboarding.verifyListing.progressStatus.notStarted"
                        description="Progress notStarted"
                        defaultMessage="Not yet started"
                    />
                )}
                {status === "inProgress" && (
                    <FormattedMessage
                        id="Onboarding.verifyListing.progressStatus.inProgress"
                        description="In Progress"
                        defaultMessage="In progress"
                    />
                )}
                {status === "completed" && (
                    <FormattedMessage
                        id="Onboarding.verifyListing.progressStatus.completed"
                        description="Progress Complete"
                        defaultMessage="Complete"
                    />
                )}
                {status === "completed" && <CheckCircle />}
            </div>
        </div>
    )
}

const OnboardingProgressBarV2 = ({
    onboardingUnit,
}: OnboardingProgressBarV2Props): JSX.Element => {
    const unit = onboardingUnit?.attributes

    // Checks to see if rates are set to true based on availability data
    const contactId = useContactId() ?? ""
    const [date] = useState(new Date())
    const [today, oneWeekLater] = useMemo(
        () => [
            formatISO(date, { representation: "date" }),
            formatISO(addDays(date, 6), { representation: "date" }),
        ],
        [date]
    )
    const availability = useAvailability(
        contactId,
        onboardingUnit?.id?.toString(),
        today,
        oneWeekLater
    )

    const photographyTicketQuery = useTickets(String(onboardingUnit?.id), [
        "132",
        "133",
    ])

    const intl = useIntl()

    if (availability.isError) {
        return (
            <GeneralError
                errorMessage={intl.formatMessage({
                    id: "Onboarding.error.availabilityQueryError",
                    defaultMessage:
                        "We were unable to fetch your availability.",
                })}
                customContainerStyle={styles.queryError}
            />
        )
    }

    if (photographyTicketQuery.isError) {
        return (
            <GeneralError
                errorMessage={intl.formatMessage({
                    id: "Onboarding.error.photographyTicketQueryError",
                    defaultMessage:
                        "We were unable to fetch your photography tickets.",
                })}
                customContainerStyle={styles.queryError}
            />
        )
    }

    if (!availability.isSuccess || !photographyTicketQuery.isSuccess) {
        return <></>
    }

    const isPhotographiesComplete =
        photographyTicketQuery.data &&
        photographyTicketQuery.data.tickets.length > 0 &&
        photographyTicketQuery.data.tickets.every(ticket => {
            return ticket.attributes.isComplete
        })

    const photographyStatus = isPhotographiesComplete
        ? "completed"
        : "inProgress"

    const descriptionComplete =
        unit?.description && unit?.description2 && unit?.unitTitle
    const descriptionStatus = isPhotographiesComplete
        ? descriptionComplete
            ? "completed"
            : "inProgress"
        : "notStarted"

    const rateExists = availability.data
        ? Object.values(availability.data).some(data => data.rate > 0)
        : false

    const rateStatus = isPhotographiesComplete
        ? rateExists
            ? "completed"
            : "inProgress"
        : "notStarted"

    return (
        <div
            className={styles.outerContainer}
            data-testid="progress-bar-container"
        >
            <ProgressBarContents
                titleText={
                    <FormattedMessage
                        id="Onboarding.verifyListing.professionalPhotos"
                        description="Professional Photo"
                        defaultMessage="1. Professional photos"
                    />
                }
                status={photographyStatus}
                testId={"photography"}
            />
            <ProgressBarContents
                titleText={
                    <FormattedMessage
                        id="Onboarding.verifyListing.customDescription"
                        description="Custom Description"
                        defaultMessage="2. Custom description"
                    />
                }
                status={descriptionStatus}
                testId={"description"}
            />
            <ProgressBarContents
                titleText={
                    <FormattedMessage
                        id="Onboarding.verifyListing.settingYourRates"
                        description="Rates"
                        defaultMessage="3. Setting your rates"
                    />
                }
                status={rateStatus}
                testId={"rate"}
            />
        </div>
    )
}

export { OnboardingProgressBarV2 }
