import { MOBILE_BREAKPOINT, MONTH_LIST } from "Constants"
import { ReactComponent as PerformanceChartPoints } from "assets/performance-chart-points.svg"
import Highcharts, { Options } from "highcharts"
import HighchartsBoost from "highcharts/modules/boost"
import HighchartsReact from "highcharts-react-official"
import HighchartsRounded from "highcharts-rounded-corners"
import { useRevenue } from "hooks/revenue"
import { LegendItem, LegendItemType } from "lib/components/Vacharts/Legend"
import { FC, useMemo, useState } from "react"
import ReactDOMServer from "react-dom/server"
import { IntlProvider, MessageDescriptor, useIntl } from "react-intl"
import {
    getPointsForCurrentYearsMonth,
    getPointsForPreviousYearsMonth,
    isChartShowingPreviousYear,
    toColumnSeries,
    toLineSeries,
    toRevenueData,
} from "utils/charts/chartsUtil"
import styles from "./RevenueChart.module.scss"
import RevenueChartHeader from "./RevenueChartHeader"
import { isValid, parseISO } from "date-fns"
import RevenueChartSkeleton from "./RevenueChartSkeleton"
import RevenueChartFooter from "./RevenueChartFooter"
import { useUserCurrencyCode } from "hooks/user"
import { ChartEmptyState } from "../ChartEmptyState"
import { useCurrentUnit, useUnitStatuses } from "hooks/units"

HighchartsRounded(Highcharts)
HighchartsBoost(Highcharts)

type RevenueChartProps = {
    activeChart: "gross-rent" | "net-rent"
}

export const RevenueChart: FC<RevenueChartProps> = ({ activeChart }) => {
    const intl = useIntl()
    const [showPreviousYear, setShowPreviousYear] = useState(false)
    const [year, setYear] = useState(new Date().getFullYear())
    const { isOnboarding } = useUnitStatuses()
    const { unitId } = useCurrentUnit()
    const currency = useUserCurrencyCode()

    const selectedYearRevenue = useRevenue(unitId, year)
    const priorYearRevenue = useRevenue(unitId, year - 1)

    const data = toRevenueData(selectedYearRevenue, priorYearRevenue)

    const isLoading =
        selectedYearRevenue.isLoading || priorYearRevenue.isLoading

    const showNetRent = activeChart === "net-rent"

    const yearlyRevenue = `${intl.formatNumber(
        data.current.total.grossRentTotal,
        {
            style: "currency",
            currency,
        }
    )}${currency === "USD" ? ` ${currency}` : ""}`

    const netRentYearlyRevenue = `${intl.formatNumber(
        data.current.total.netRentAfterIntegrated,
        {
            style: "currency",
            currency,
        }
    )}${currency === "USD" ? ` ${currency}` : ""}`

    const upcomingRevenue = intl.formatNumber(
        data.current.upcoming.grossRentTotal,
        {
            style: "currency",
            currency,
        }
    )

    const netRentUpcomingRevenue = intl.formatNumber(
        data.current.upcoming.netRentAfterIntegrated,
        {
            style: "currency",
            currency,
        }
    )

    const completedRevenue = intl.formatNumber(
        data.current.completed.grossRentTotal,
        {
            style: "currency",
            currency,
        }
    )

    const netRentCompletedRevenue = intl.formatNumber(
        data.current.completed.netRentAfterIntegrated,
        {
            style: "currency",
            currency,
        }
    )

    const showPastPerformanceTooltip =
        !!data.current.total.grossRentBeforeIntegrated ||
        (showPreviousYear && !!data.previous.total.grossRentBeforeIntegrated)

    const options: Options = useMemo(
        () => ({
            title: undefined,
            series: showPreviousYear
                ? [
                      ...toColumnSeries(
                          {
                              data: data.current,
                              meta: data.meta,
                          },
                          showNetRent
                      ),
                      ...toLineSeries(
                          {
                              data: data.previous,
                              meta: data.meta,
                          },
                          showNetRent
                      ),
                  ]
                : [
                      ...toColumnSeries(
                          {
                              data: data.current,
                              meta: data.meta,
                          },
                          showNetRent
                      ),
                  ],
            xAxis: {
                categories: MONTH_LIST,
                labels: {
                    style: {
                        color: "var(--dusk-60)",
                        fontSize: "14px",
                        fontWeight: "bold",
                    },
                },
                title: undefined,
            },
            yAxis: [
                {
                    labels: {
                        style: {
                            color: "var(--dusk-60)",
                            fontSize: "14px",
                            fontWeight: "bold",
                        },
                        formatter() {
                            return `$${this.axis.defaultLabelFormatter.call(
                                this
                            )}`
                        },
                    },
                    opposite: true,
                    title: undefined,
                },
            ],
            chart: {
                type: "column",
                style: {
                    fontFamily: `"Public Sans", Helvetica, Arial, sans-serif`,
                },
                width: 840,
            },
            plotOptions: {
                column: {
                    pointWidth: 53,
                    grouping: false,
                    borderColor: "transparent",
                },

                series: {
                    color: "var(--cafe)",
                    showInLegend: false,
                    borderRadiusTopLeft: "8px",
                    borderRadiusTopRight: "8px",
                    marker: {
                        symbol: "circle",
                    },
                },
            },
            credits: undefined,
            tooltip: {
                borderColor: "var(--white)",
                borderRadius: 8,
                useHTML: true,
                shadow: {
                    width: 15,
                    opacity: 0.05,
                    color: "rgba(112, 117, 121, 0.2)",
                },
                backgroundColor: "var(--white)",
                style: {
                    fontSize: "12",
                    fontWeight: "800",
                },
                formatter: p => {
                    const currentPoint = p.chart.hoverPoint
                    if (!currentPoint) return ""
                    const toolTips: JSX.Element[] = []

                    // Checks if the chart is showing the previous years data
                    const isShowingPreviousYear =
                        isChartShowingPreviousYear(currentPoint)

                    // Retrieve the data points for the selected month in the current year
                    // It's possible to have 2 points for the same month if acquistion data overlaps
                    // vacasa data in the same month
                    let currentYearPoints = getPointsForCurrentYearsMonth(
                        currentPoint,
                        isShowingPreviousYear
                    ).map(point => ({ ...point }))

                    // Retrieve the data points for the selected month in the previous year
                    const previousYearPoints =
                        getPointsForPreviousYearsMonth(currentPoint)

                    if (isShowingPreviousYear) {
                        if (currentYearPoints.length === 2) {
                            const total = currentYearPoints.reduce(
                                (acc, point) => {
                                    return (acc += point.y ?? 0)
                                },
                                0
                            )

                            // Replaceing separate points for acquisition and vacasa data into a single data point
                            currentYearPoints = [
                                {
                                    y: total,
                                    key: LegendItemType.CombinedCurrentYear,
                                },
                            ]
                        }
                    }

                    ;[...currentYearPoints, ...previousYearPoints].forEach(
                        point => {
                            const label = intl.formatNumber(point.y ?? 0, {
                                style: "currency",
                                currency,
                            })
                            toolTips.push(
                                <LegendItem key={label} stack type={point.key}>
                                    {label}
                                </LegendItem>
                            )
                        }
                    )

                    return ReactDOMServer.renderToString(
                        <IntlProvider
                            locale={intl.locale}
                            messages={intl.messages}
                            defaultLocale="en"
                        >
                            <div className={styles.tooltip}>{toolTips}</div>
                        </IntlProvider>
                    )
                },
            },
            responsive: {
                rules: [
                    {
                        condition: {
                            minWidth: MOBILE_BREAKPOINT,
                        },
                        chartOptions: {
                            xAxis: {
                                labels: {
                                    style: {
                                        fontSize: "14",
                                    },
                                },
                            },
                            yAxis: [
                                {
                                    labels: {
                                        style: {
                                            fontSize: "14",
                                        },
                                    },
                                },
                            ],
                            plotOptions: {
                                column: {
                                    pointWidth: 53,
                                },
                                series: {
                                    borderRadiusTopLeft: "8px",
                                    borderRadiusTopRight: "8px",
                                },
                            },
                        },
                    },
                ],
            },
        }),
        [currency, data, intl, showNetRent, showPreviousYear]
    )

    const onSetYear = (year: number) => {
        setYear(year)
        setShowPreviousYear(false)
    }

    if (isLoading) return <RevenueChartSkeleton />

    const minYear = getPerformanceStartYear({
        acquisitionDate: data.meta.acquisitionUnitIntegrationDate,
        defaultStartYear: data.meta.availablePerformanceYearsRange.start,
    })

    if (isOnboarding || !unitId) {
        const title: MessageDescriptor = !showNetRent
            ? {
                  id: "Dashboard.charts.grossRent.title",
                  defaultMessage: "Gross Rent",
              }
            : {
                  id: "Dashboard.charts.netRent.title",
                  defaultMessage: "Net Rent",
              }

        const message: MessageDescriptor = !showNetRent
            ? {
                  id: "Dashboard.charts.grossRent.emptyMessage",
                  defaultMessage:
                      "YTD gross revenue data will appear here once you receive reservations.",
              }
            : {
                  id: "Dashboard.charts.netRent.emptyMessage",
                  defaultMessage:
                      "YTD net revenue data will appear here once you receive reservations.",
              }

        return (
            <ChartEmptyState
                title={intl.formatMessage(title)}
                message={intl.formatMessage(message)}
            />
        )
    }

    return (
        <>
            <PerformanceChartPoints />
            <RevenueChartHeader
                yearlyRevenue={
                    showNetRent ? netRentYearlyRevenue : yearlyRevenue
                }
                upcomingRevenue={
                    showNetRent ? netRentUpcomingRevenue : upcomingRevenue
                }
                completedRevenue={
                    showNetRent ? netRentCompletedRevenue : completedRevenue
                }
                showRevenueBreakdown={!showPreviousYear}
                showPastPerformanceTooltip={showPastPerformanceTooltip}
                minYear={minYear}
                maxYear={data.meta.availablePerformanceYearsRange.end}
                setYear={onSetYear}
                year={year}
                showNetRent={showNetRent}
            />
            <div className={styles.revenueChart}>
                <HighchartsReact highcharts={Highcharts} options={options} />
            </div>
            <RevenueChartFooter
                onShowPreviousYearChange={setShowPreviousYear}
                showPreviousYear={showPreviousYear}
                revenue={data}
                minYear={minYear}
                year={year}
                showNetRent={showNetRent}
            />
        </>
    )
}

const getPerformanceStartYear = ({
    acquisitionDate,
    defaultStartYear,
}: {
    acquisitionDate?: string
    defaultStartYear: number
}): number => {
    if (!acquisitionDate) return defaultStartYear
    const date = parseISO(acquisitionDate)
    if (!isValid(date)) return defaultStartYear
    return date.getFullYear()
}
