import segmentService from "../segment.service"

export const trackSupportSubmitButtonClicked = () => {
    segmentService.track("Support Submit Button - Clicked", {})
}

export const trackCreateTicketFlyoutClosed = () => {
    segmentService.track("Close Submit Ticket Selection - Clicked", {})
}

export const trackCreateTicketBackArrowClicked = (options: {
    location: "category_selection" | "request_form" | "leaving_alert"
}) => {
    segmentService.track(
        "Submit Ticket Selection Back Arrow - Clicked",
        options
    )
}

export const trackTicketCategorySelected = (options: {
    category_id: string
    disposition_id: string
}) => {
    segmentService.track("Ticket Category - Selected", options)
}

export const trackTicketCategoryEdited = (options: {
    category_id: string
    disposition_id: string
}) => {
    segmentService.track("Edit Category Selection - Clicked", options)
}

export const trackTicketSubmitted = (options: {
    category_id: string
    disposition_id: string
    title: string
    description: string
}) => {
    segmentService.track("Submit Ticket Request - Clicked", options)
}

export const trackTicketCleared = () => {
    segmentService.track("Clear Ticket Request - Clicked", {})
}

export const trackTicketCreated = (options: {
    ticket_id: string
    category_id: string
    disposition_id: string
    title: string
    description: string
}) => {
    segmentService.track("Submitted Ticket Request - Success", options)
}

export const trackSubmissionContinued = () => {
    segmentService.track("Continue with Submission - Clicked", {})
}

export const trackTicketRequestCancelled = () => {
    segmentService.track("Cancel Request - Clicked", {})
}

export const trackTicketDetailsOpened = (options: { ticket_id: string }) => {
    segmentService.track("Ticket Details - Opened", options)
}

export const trackTicketCommentSubmitted = (options: {
    ticket_id: string
    disposition_id: string
}) => {
    segmentService.track("Ticket Comment - Submitted", {
        ...options,
        // eslint-disable-next-line camelcase
        account_name: `${segmentService.user?.firstName} ${segmentService.user?.lastName}`,
    })
}
