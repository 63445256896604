import React from "react"
import { Row, Col } from "reactstrap"
import classnames from "classnames"
import { Button, CircularProgress } from "@material-ui/core"
import { FormattedMessage } from "react-intl"

import styles from "../../../sass/onboarding.module.scss"

export enum ButtonState {
    Disabled,
    Enabled,
    Loading,
}

interface SubmitButtonRowProps {
    onSubmit?: () => void
    onCancel?: () => void
    buttonState: ButtonState
}

const SubmitButtonRow: React.FC<SubmitButtonRowProps> = ({
    buttonState,
    onSubmit,
    onCancel,
}: SubmitButtonRowProps): JSX.Element => {
    return (
        <Row>
            <Col>
                <div
                    className={classnames(
                        styles.button_row,
                        buttonState === ButtonState.Enabled
                            ? styles.enabled
                            : ""
                    )}
                >
                    {buttonState === ButtonState.Enabled && (
                        <Button
                            variant="outlined"
                            onClick={onCancel}
                            className={styles.cancel_button}
                        >
                            <FormattedMessage
                                id="Onboarding.cancelButton"
                                defaultMessage="Cancel"
                            />
                        </Button>
                    )}
                    <Button
                        variant="outlined"
                        onClick={onSubmit}
                        startIcon={
                            buttonState === ButtonState.Loading ? (
                                <CircularProgress
                                    className={styles.button_spinner}
                                />
                            ) : null
                        }
                        disabled={buttonState !== ButtonState.Enabled}
                        className={classnames(
                            styles.save_button,
                            buttonState === ButtonState.Enabled
                                ? styles.enabled
                                : ""
                        )}
                    >
                        <FormattedMessage
                            id="Onboarding.saveButton"
                            defaultMessage="Save"
                        />
                    </Button>
                </div>
            </Col>
        </Row>
    )
}
export default SubmitButtonRow
