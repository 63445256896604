import React, { Fragment, useState } from "react"
import classnames from "classnames"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"

import ActionRow from "../../components/ActionRow"
import * as BaseButton from "../../components/Buttons/BaseButton"
import * as Loader from "../../components/Loader/ButtonLoader/ButtonLoader"

import styles from "./datarow.module.scss"
import SVGIcon from "../../components/SVGIcon"
import UnitPicture from "../../components/Unit/UnitPicture"
import LoggingService from "../../../services/logging/logging.service"
import { isUnauthorizedOrCancelledRequest } from "../../../utils/error/error"
import { JSONAPIResource, Unit } from "@vacasa/owner-api-models"
import { Card } from "../../../components/Card"
import { CardContent } from "@material-ui/core"
import { trackPetFriendlyClicked } from "services/segment/homeInfo/homeInfoTracking"
import { useIntl } from "react-intl"
import { useContactId } from "hooks/user"
import { useUnitAllowPetsMutation, useUnits } from "hooks/units"

interface DataRowWrapperProps {
    unitQueryParam?: string
}

const DataRowWrapper: React.FC<DataRowWrapperProps> = props => {
    const intl = useIntl()
    const contactId = useContactId() ?? ""
    const [modalOpen, setModalOpen] = useState(false)
    const [showAllRows, setShowAllRows] = useState(false)
    const unitsQuery = useUnits()
    const allowPetsMutation = useUnitAllowPetsMutation()
    const showActionRow = (unitsQuery.data?.length ?? 0) > 1

    const content = !showAllRows
        ? intl.formatMessage({
              id: "PetsAllowed.showAllRentals",
              defaultMessage: "Show All Rentals",
          })
        : intl.formatMessage({
              id: "PetsAllowed.hideRentals",
              defaultMessage: "Hide Rentals",
          })

    function renderCTA(isPetFriendly: boolean, unit: JSONAPIResource<Unit>) {
        return isPetFriendly ? (
            <div className={styles.completed__status}>
                <span className="type-body-small">
                    {intl.formatMessage({
                        id: "PetsAllowed.allowed",
                        defaultMessage: "Allowed",
                    })}
                </span>
                <SVGIcon
                    svg="icon-feather-check-circle"
                    className="h-24 w-24 zero-margin"
                />
            </div>
        ) : (
            <BaseButton.Primary
                onClick={() => changePetStatus(unit)}
                isLoading={allowPetsMutation.isLoading}
                disabled={allowPetsMutation.isLoading}
                buttonSize={"small"}
                loader={
                    <Loader.ButtonLoader
                        indicator={"dot"}
                        indicatorCount={3}
                        animation={"blink"}
                    />
                }
                tabIndex={0}
                ariaLabel={intl.formatMessage({
                    id: "PetsAllowed.ariaLabel",
                    defaultMessage: "Click button to allow pet friendly.",
                })}
            >
                {intl.formatMessage({
                    id: "PetsAllowed.allow",
                    defaultMessage: "Allow",
                })}
            </BaseButton.Primary>
        )
    }
    function renderDataRow() {
        return unitsQuery.data?.map((unit, key) => {
            const isPetFriendly =
                unit.attributes.amenities.petsFriendly ?? false
            const displayHR = key + 1 !== unitsQuery.data.length
            const ctaComponent = renderCTA(isPetFriendly, unit)
            const unitName =
                unit.attributes.name !== ""
                    ? unit.attributes.name
                    : intl.formatMessage({
                          id: "PetsAllowed.untitledHome",
                          defaultMessage: "Untitled Home",
                      })
            return (
                <div
                    key={unit.id}
                    className={
                        unit.id === props.unitQueryParam || showAllRows
                            ? "show"
                            : "hide"
                    }
                >
                    <div className={styles.row__wrapper}>
                        <div className={styles.wayfinder__img}>
                            <UnitPicture unit={unit} className="w-32 h-32" />
                        </div>
                        <span className={styles.content}>{unitName}</span>
                        <div className={styles.cta__wrapper}>
                            {ctaComponent}
                        </div>
                    </div>
                    {displayHR && (
                        <hr
                            className="data__row--hr"
                            style={{ marginRight: "-2em" }}
                        />
                    )}
                </div>
            )
        })
    }

    const changePetStatus = (unit: JSONAPIResource<Unit>) => {
        trackPetFriendlyClicked(String(unit.id))
        allowPetsMutation.mutate(
            {
                unitId: String(unit.id),
                payload: {
                    // Hard coding the status to true because we currently don't give owners the ability to opt out of dog friendly in the UI.
                    ChangePetStatus: true,
                },
            },
            {
                onError: error => {
                    if (!isUnauthorizedOrCancelledRequest(error)) {
                        LoggingService.error({
                            message: "Failed to change pet status",
                            error,
                            tags: {
                                unitID: String(unit.id),
                                contactID: contactId,
                            },
                        })
                    }
                },
                onSuccess: () => {
                    setModalOpen(true)
                },
            }
        )
    }

    function renderSuccessAlert() {
        const closeBtn = (
            <div className="close" onClick={() => setModalOpen(!modalOpen)}>
                <SVGIcon
                    svg="icon-feather-x"
                    className="h-24 w-24 zero-margin fill-dusk-lighter cursor-pointer"
                    style={{ marginBottom: "2px" }}
                />
            </div>
        )
        // @TODO remove reactstrap modal with reusable component.
        return (
            <div>
                <Modal
                    isOpen={modalOpen}
                    toggle={() => setModalOpen(!modalOpen)}
                    centered={true}
                >
                    <ModalHeader
                        toggle={() => setModalOpen(!modalOpen)}
                        style={{
                            borderBottom: "1px solid #eaeaea",
                            paddingBottom: 20,
                            paddingTop: 20,
                            marginBottom: 20,
                        }}
                        close={closeBtn}
                    >
                        {intl.formatMessage({
                            id: "PetsAllowed.modalHeader",
                            defaultMessage: "Great, you're all set!",
                        })}
                    </ModalHeader>
                    <ModalBody style={{ paddingBottom: 40 }}>
                        <p>
                            {intl.formatMessage({
                                id: "PetsAllowed.modalText",
                                defaultMessage:
                                    "Your listing is being updated and your local team will be notified so they can ensure your home is ready to welcome dogs. It may take up to 24 hours for this change to update on all of your listings and marketing channels.",
                            })}
                        </p>
                    </ModalBody>
                    <ModalFooter>
                        <BaseButton.Primary
                            onClick={() => setModalOpen(!modalOpen)}
                            tabIndex={0}
                            ariaLabel={intl.formatMessage({
                                id: "PetsAllowed.modalFooter",
                                defaultMessage:
                                    "Click this button to close the modal.",
                            })}
                        >
                            {intl.formatMessage({
                                id: "PetsAllowed.ok",
                                defaultMessage: "OK",
                            })}
                        </BaseButton.Primary>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }

    return (
        <Fragment>
            <Card
                className={classnames(
                    styles.data__row,
                    showActionRow ? styles["data__row--has-action"] : "",
                    "panel"
                )}
            >
                <CardContent>
                    {modalOpen && renderSuccessAlert()}
                    {unitsQuery.data && renderDataRow()}
                    {showActionRow && (
                        <Fragment>
                            <hr
                                style={{
                                    marginRight: "-2em",
                                    marginLeft: "-2em",
                                    marginBottom: "0",
                                    marginTop: "0",
                                }}
                            />
                            <ActionRow
                                buttonOpts={{
                                    typeOf: "link",
                                    ariaLabel: intl.formatMessage({
                                        id: "PetsAllowed.showAllAriaLabel",
                                        defaultMessage:
                                            "use this button to show all your rentals.",
                                    }),
                                }}
                                action={() => setShowAllRows(!showAllRows)}
                                content={content}
                            />
                        </Fragment>
                    )}
                </CardContent>
            </Card>
        </Fragment>
    )
}
export default DataRowWrapper
