/* eslint-disable camelcase */
import { SegmentLocation } from "../constants"
import segmentService from "../segment.service"
import { GUESTWORKS_LINK_CLICKED_EVENT } from "./guestworksTrackingConstants"

export const trackGuestworksLinkClicked = (
    location: SegmentLocation,
    unitId?: string
): void => {
    const props: Record<string, unknown> = {
        location,
    }

    if (unitId) {
        props.unitId = unitId
    }

    segmentService.track(GUESTWORKS_LINK_CLICKED_EVENT, props)
}
