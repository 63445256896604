import { JSONAPIResource, Reservation } from "@vacasa/owner-api-models"
import { MonthRentBreakdown } from "@vacasa/owner-api-models/src/Reservation"
import classnames from "classnames"
import { format, parse } from "date-fns"
import React from "react"
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl"
import { ReactComponent as DollarSignIcon } from "./../../../../../assets/icon-dollar-sign.svg"
import styles from "./RentBreakdownListItem.module.scss"
import { Calendar } from "react-feather"

interface RentBreakdownListItemProps {
    reservation: JSONAPIResource<Reservation>
    currency: string | undefined
    customStyling?: {
        Icon?: string | null
    }
}

interface MonthlyRentBreakdownProps {
    netRent: MonthRentBreakdown
    currency: string | undefined
}

const getReservationNetRent = (rent: MonthRentBreakdown | Reservation) => {
    const { netRentAfterDiscount, netRent } = rent
    return netRentAfterDiscount ?? netRent ?? 0
}

const getReservationManagementFee = (rent: MonthRentBreakdown) => {
    const { managementFee, managementFeeAfterDiscount } = rent
    return managementFeeAfterDiscount ?? managementFee ?? 0
}

const MonthlyRentBreakdown: React.FC<MonthlyRentBreakdownProps> = ({
    netRent,
    currency,
}) => {
    const intl = useIntl()

    const getMonth = (): string => {
        const date = parse(
            `${netRent.year}-${netRent.month}-01`,
            "yyyy-M-dd",
            new Date()
        )
        return format(date, "LLL")
    }

    const nightText = ` (${netRent.nights} ${
        netRent.nights === 1
            ? intl.formatMessage({
                  id: "CalendarPage.flyout.night",
                  defaultMessage: "night",
              })
            : intl.formatMessage({
                  id: "CalendarPage.flyout.nights",
                  defaultMessage: "nights",
              })
    })`

    return (
        <div className={styles.monthlyRentBreakdown}>
            <Calendar className={styles.calendarIcon} />
            <div className={styles.month}>{`${getMonth()}${nightText}`}</div>
            <div className={styles.grossRent}>
                <FormattedMessage
                    id="CalendarPage.flyout.grossRent"
                    defaultMessage="Gross Rent"
                />
            </div>
            <div className={styles.grossRentValue}>
                <FormattedNumber
                    value={netRent.grossRent ?? 0}
                    currency={currency}
                    style="currency" // eslint-disable-line
                />
            </div>
            <div className={styles.mgmtFee}>
                <FormattedMessage
                    id="CalendarPage.flyout.mgmtFee"
                    defaultMessage="Mgmt Fee"
                />
            </div>
            <div className={styles.mgmtFeeValue}>
                {`-`}
                <FormattedNumber
                    value={getReservationManagementFee(netRent)}
                    currency={currency}
                    style="currency" // eslint-disable-line
                />
            </div>
            <div className={styles.mgmtFeePercent}>
                {netRent.managementFeePercentAfterDiscount && (
                    <>
                        <span className={styles.mgmtFeePercentDefault}>
                            <FormattedNumber
                                value={netRent.managementFeePercent ?? 0}
                            />
                            {`%`}
                        </span>{" "}
                    </>
                )}

                <FormattedNumber
                    value={
                        netRent.managementFeePercentAfterDiscount ??
                        netRent.managementFeePercent ??
                        0
                    }
                />
                {`%`}

                {netRent.managementFeeDiscountType &&
                    ` (${netRent.managementFeeDiscountType} discount)`}
            </div>
            <hr className={styles.dash} />
            <div className={styles.netRent}>
                <FormattedMessage
                    id="CalendarPage.flyout.netRent"
                    defaultMessage="Net Rent"
                />
            </div>
            <div className={styles.netRentValue}>
                <FormattedNumber
                    value={getReservationNetRent(netRent)}
                    currency={currency}
                    style="currency" // eslint-disable-line
                />
            </div>
        </div>
    )
}

const RentBreakdownListItem: React.FC<RentBreakdownListItemProps> = ({
    reservation,
    currency,
    customStyling,
}) => {
    return (
        <li key="rentInfoListItem" className={styles.container}>
            <DollarSignIcon
                className={classnames(styles.icon, customStyling?.Icon ?? "")}
            />
            <div className={styles.title}>
                <FormattedMessage
                    id="CalendarPage.flyout.revenueBreakdown"
                    defaultMessage="Revenue breakdown"
                />
            </div>
            <div className={styles.rentBreakdown}>
                {reservation.attributes.monthlyRentBreakdown.map(netRent => (
                    <MonthlyRentBreakdown
                        netRent={netRent}
                        currency={currency}
                    />
                ))}
            </div>
            <div className={styles.totalRent}>
                <div>
                    <FormattedMessage
                        id="CalendarPage.flyout.totalNetRent"
                        defaultMessage="Total Net rent"
                    />
                </div>
                <div className={styles.totalRentValue}>
                    <FormattedNumber
                        value={getReservationNetRent(reservation.attributes)}
                        currency={currency}
                        style="currency" // eslint-disable-line
                    />
                </div>
            </div>
            <div className={styles.notes}>
                <FormattedMessage
                    id="CalendarPage.flyout.netRentBreakdownNotes"
                    defaultMessage="Additional fees and expenses related to this reservation may appear on your monthly statement."
                />
            </div>
        </li>
    )
}

export default RentBreakdownListItem
