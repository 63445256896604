import { CalendarDayShape } from "react-dates"

import CustomizableCalendarDay from "react-dates/lib/components/CustomizableCalendarDay"

const defaultStyles = {
    border: "none",
    borderRadius: "50%",
    color: "black",
}

const selectedStyles = {
    background: "#E5EBED",
}

const blockedStyles = {
    color: "#707579",
}

/**
 * Tied closely to react-dates
 */
const CustomCalendarDay = (props: CalendarDayShape): JSX.Element => {
    return (
        <CustomizableCalendarDay
            {...props}
            defaultStyles={defaultStyles}
            selectedStyles={selectedStyles}
            blockedCalendarStyles={blockedStyles}
        />
    )
}

export { CustomCalendarDay }
