import classNames from "classnames"
import {
    GeneralError,
    GeneralErrorProps,
} from "lib/components/GeneralError/GeneralError"
import { FC } from "react"
import { BaseWidget } from "./BaseWidget"
import styles from "./WidgetError.module.scss"

interface WidgetErrorProps extends GeneralErrorProps {
    hideButton?: boolean
}

export const WidgetError: FC<WidgetErrorProps> = props => {
    const { hideButton, customContainerStyle, ...rest } = props
    return (
        <BaseWidget>
            <div className={styles.container}>
                <GeneralError
                    {...rest}
                    customContainerStyle={classNames(
                        customContainerStyle,
                        styles.errorContainer,
                        {
                            [styles.errorRetryDisabled as string]: hideButton,
                        }
                    )}
                />
            </div>
        </BaseWidget>
    )
}
