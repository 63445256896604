import { cs, de, enUS, es, fr, it, hu } from "date-fns/locale"
import {
    differenceInHours,
    differenceInMinutes,
    format,
    getUnixTime,
    isAfter,
    isBefore,
    Locale,
    parse as parseDateFn,
    parseISO,
} from "date-fns"

/**
 * Return date-fn locale from language code
 * @param code
 * @returns
 */
const getLocaleFromLanguageCode = (code: string): Locale => {
    switch (code) {
        case "cs":
            return cs
        case "de":
            return de
        case "es-419":
        case "es-ES":
            return es
        case "fr":
            return fr
        case "it":
            return it
        case "hu":
            return hu
        case "en":
        default:
            return enUS
    }
}

const isSameOrBefore = (date: Date, toCompare: Date): boolean => {
    return (
        isBefore(date, toCompare) ||
        getUnixTime(date) === getUnixTime(toCompare)
    )
}

const isSameOrAfter = (date: Date, toCompare: Date): boolean => {
    return (
        isAfter(date, toCompare) || getUnixTime(date) === getUnixTime(toCompare)
    )
}

/**
 * Mimic the behaviour from moment if parsing in a possibly undefined date
 * @param date
 * @returns
 */
const parse = (date?: string | null, format = ""): Date => {
    if (!date) {
        date = new Date().toISOString()
    }
    const parsedDate = format
        ? parseDateFn(date, format, new Date())
        : parseISO(date)
    return parsedDate
}

export const getHumanReadableDistance = (date: Date) => {
    const now = new Date()
    if (differenceInMinutes(now, date) <= 59)
        // if 0 minutes, return 1 minute
        return `${differenceInMinutes(now, date) || 1}m ago`

    if (differenceInHours(now, date) < 24)
        return `${differenceInHours(now, date)}h ago`

    return `${format(date, "M/d/yy")} at ${format(date, "h:mma")}`
}

export { getLocaleFromLanguageCode, isSameOrAfter, isSameOrBefore, parse }
