import { W8Forms } from "./W8Forms"
import styles from "./W8.module.scss"
import { W8FormsDownload } from "./W8FormsDownload"
import { W8Submit } from "./W8Submit"

export const W8 = () => {
    return (
        <div className={styles.container}>
            <W8Forms />
            <W8FormsDownload />
            <W8Submit />
        </div>
    )
}
