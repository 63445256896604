import React, { memo } from "react"
import { uniqueId } from "lodash"

export interface StaticListSubItemProps {
    content?: JSX.Element
    id?: string | number
    classes?: string[]
}

const StaticListSubItem: React.FC<StaticListSubItemProps> = props => {
    const classes = ["sub-item", ...(props.classes ?? [])]
    return (
        <li
            key={props.id ?? uniqueId("static-list-sub-item")}
            className={classes.join(" ")}
        >
            {props.content ?? "list sub item"}
        </li>
    )
}

export default memo(StaticListSubItem)
