import { Unit } from "@vacasa/owner-api-models/src/legacy/Earnings"
import classnames from "classnames"
import SVGIcon from "lib/components/SVGIcon"
import { Tooltip } from "lib/components/Tooltip/Tooltip"
import { FormattedMessage, useIntl } from "react-intl"
import {
    displayFormattedNumber,
    isFixedRent,
} from "utils/statements/statementsUtil"

import styles from "../statements.module.scss"

interface UnitDetailsOverviewProps {
    unit: Unit
    currency: string
}

const UnitDetailsOverview: React.FC<UnitDetailsOverviewProps> = props => {
    const { unit, currency } = props
    const intl = useIntl()
    const fixedRent = isFixedRent(unit)

    const renderDetailsBox = (
        body: string,
        title: string,
        value: string | JSX.Element,
        tooltipTitle: string | JSX.Element
    ) => {
        return (
            <div className="unit-details-overview-cell">
                <span
                    className={classnames(
                        "unit-details-overview-header small",
                        styles["tooltip-title"],
                        styles["box"]
                    )}
                >
                    {body}
                    <span className={styles["tooltip-icon"]}>
                        <Tooltip title={title} body={tooltipTitle}>
                            <SVGIcon
                                svg="icon-feather-info"
                                colorName="gray-lightest"
                                className={styles.info_icon}
                            />
                        </Tooltip>
                    </span>
                </span>
                <p className="unit-details-overview-body large">{value}</p>
            </div>
        )
    }

    return (
        <div className="unit-details-overview">
            {renderDetailsBox(
                intl.formatMessage({
                    id: "Statements.proceeds",
                    defaultMessage: "Proceeds",
                }),
                "PROCEEDS",
                displayFormattedNumber(Number(unit.net_proceeds), currency),
                intl.formatMessage({
                    id: "Statements.tooltips.proceeds",
                    defaultMessage: "Amount you'll be paid for this month",
                })
            )}
            {renderDetailsBox(
                intl.formatMessage({
                    id: "Statements.occupancy",
                    defaultMessage: "Occupancy",
                }),
                "GUEST OCCUPANCY",
                fixedRent ? "--" : `${unit.occupancy_percent} %`,
                intl.formatMessage({
                    id: "Statements.tooltips.occupancy",
                    defaultMessage:
                        "The percentage of available nights this month that you rented to guests (does not include your personal use dates and complimentary stays)",
                })
            )}
            {renderDetailsBox(
                intl.formatMessage({
                    id: "Statements.nightsOccupied",
                    defaultMessage: "Nights Occupied",
                }),
                "NIGHTS OCCUPIED",
                fixedRent ? "--" : unit.nights.toString(),
                intl.formatMessage({
                    id: "Statements.tooltips.nightsOccupied",
                    defaultMessage:
                        "How many nights this month your home hosted guests (only counts reservations with check-out dates within the month)",
                })
            )}
            {renderDetailsBox(
                intl.formatMessage({
                    id: "Statements.reserveBalance",
                    defaultMessage: "Reserve Balance",
                }),
                "RESERVE BALANCE",
                displayFormattedNumber(
                    unit.reserve ? unit.reserve.ending_balance : 0,
                    currency
                ),
                <FormattedMessage
                    id="Statements.tooltips.reserveBalance"
                    defaultMessage="Amount you've set aside for expenses if they exceed monthly proceeds. Change your reserve amount any time in your <a>Settings</a>"
                    values={{
                        a: chunks => (
                            <a
                                key={"link"}
                                href={`/statements/settings`}
                                rel="noreferrer"
                            >
                                {chunks}
                            </a>
                        ),
                    }}
                />
            )}
        </div>
    )
}

export default UnitDetailsOverview
