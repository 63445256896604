import { FC } from "react"
import styles from "./RatesTooltip.module.scss"
import classNames from "classnames"
import { useIntl } from "react-intl"
type RatesTooltipProps = {
    unit: number
    min: number
    max: number
    guestStay?: boolean
    analysis?: string | null
}

export const RatesTooltip: FC<RatesTooltipProps> = props => {
    const { analysis, max, min, unit, guestStay } = props
    const intl = useIntl()
    return (
        <div className={classNames(styles.container)}>
            <div className={styles.rates}>
                <RateField
                    label={
                        guestStay
                            ? intl.formatMessage({
                                  id: "Dashboard.charts.marketRate.tooltips.rates.bookedRate",
                                  defaultMessage: "Booked Rate",
                              })
                            : intl.formatMessage({
                                  id: "Dashboard.charts.marketRate.tooltips.rates.yourRate",
                                  defaultMessage: "Your Rate",
                              })
                    }
                    value={`$${Math.trunc(unit)}`}
                    type="unit"
                />
                <RateField
                    label={intl.formatMessage({
                        id: "Dashboard.charts.marketRate.tooltips.rates.similarHomes",
                        defaultMessage: "Similar Homes",
                    })}
                    value={`$${Math.trunc(min)}-${Math.trunc(max)}`}
                    type="market"
                />
            </div>
            {analysis && (
                <div className={styles.analysis}>
                    <span className={styles.analysisTitle}>Analysis</span>
                    <span className={styles.analysisPipe}> | </span>
                    {analysis}
                </div>
            )}
        </div>
    )
}

type RateFieldProps = {
    label: string
    value: string
    type: "unit" | "market"
}

const RateField: FC<RateFieldProps> = ({ label, value, type }) => {
    return (
        <div className={styles.rateField}>
            <div
                className={classNames(styles.bullet, {
                    [styles.bulletUnit as string]: type === "unit",
                    [styles.bulletMarket as string]: type === "market",
                })}
            />
            <div>
                <div className={styles.label}>{label}</div>
                <div className={styles.value}>{value}</div>
            </div>
        </div>
    )
}
