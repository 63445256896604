import { InputHTMLAttributes, ReactNode, forwardRef } from "react"
import styles from "./Checkbox.module.scss"
import classNames from "classnames"
import { Check } from "react-feather"

export type CheckboxProps = {
    input: InputHTMLAttributes<HTMLInputElement>
    children: ReactNode
    error?: boolean
    errorMessage?: string
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
    (props, ref) => {
        const { children, input } = props
        const { error, errorMessage } = props

        return (
            <div className={styles.container}>
                <label
                    id={input.id ?? input.name}
                    className={classNames("flex pointer relative")}
                >
                    <div
                        className={classNames(
                            "flex",
                            "relative",
                            "pointer",
                            styles.inner,
                            {
                                [styles.error as string]: error,
                            }
                        )}
                    >
                        <input
                            {...input}
                            className={classNames(styles.input)}
                            type="checkbox"
                            ref={ref}
                        />
                        <div
                            className={classNames(styles.checkbox, {
                                [styles.checkboxChecked as string]:
                                    input?.checked,
                            })}
                        >
                            {input?.checked && <Check />}
                        </div>
                        <span className="type-body-small pointer">
                            {children}
                        </span>
                    </div>
                </label>
                {error && (
                    <div
                        className={classNames(
                            "type-body-small-semibold",
                            "text-sunset"
                        )}
                    >
                        {errorMessage}
                    </div>
                )}
            </div>
        )
    }
)
