import styles from "./ProgressBarStep.module.scss"
import classnames from "classnames"
import { ReactComponent as CheckCircle } from "../../../../assets/check-circle.svg"

interface ProgressBarStepProps {
    checked: boolean
    solid: boolean
    stepNumber: number
    text: string
}

/**
 * Renders a single step in the progress bar.
 */
const ProgressBarStep: React.FC<ProgressBarStepProps> = props => (
    <div
        className={classnames([
            styles.step,
            props.solid ? styles["solid-filled"] : "",
        ])}
    >
        <div className={styles.circle}>
            {props.checked ? <CheckCircle /> : props.stepNumber}
        </div>
        <div className={styles.bar} />
        <div className={styles.text}>{props.text}</div>
    </div>
)
export default ProgressBarStep
