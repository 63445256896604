import { AxiosError } from "axios"
import { useQuery, UseQueryResult } from "react-query"
import {
    fetchRateTransparencyData,
    RateTransparencyResponse,
} from "./fetchRateTransparencyData"

const RATE_TRANSPARENCY_QUERY_KEY = "rateTransparency" as const

export const useRateTransparencyData = (
    unitId?: string,
    startDate?: string,
    endDate?: string
): UseQueryResult<RateTransparencyResponse, AxiosError> => {
    return useQuery(
        [RATE_TRANSPARENCY_QUERY_KEY, unitId],
        ({ signal }) =>
            fetchRateTransparencyData({
                unitId: unitId as string,
                signal,
            }),
        {
            enabled: !!unitId && !!startDate && !!endDate,
            refetchInterval: 1000 * 60 * 30, // 30 mins
            staleTime: 1000 * 60 * 30, // 30 mins,
        }
    )
}
