const settings = {
    title: "Payment Settings",
    reserveBalance: "Tartalékegyenleg",
    paymentSettings: "Fizetési beállítások",
    directDepositTitle: "Bank Account",
    checksByMailTitle: "Paper Checks",
    checksByMailEnrolled: "You are enrolled in paper checks.",
    checksByMailNotEnrolled: "You are not enrolled in paper checks.",
    paymentMethod: "Payment Method",
    paymentMethodText:
        "Payment delivery depends on the owner's selected payment method.",
    directDepositFineprint:
        "Deposits and withdrawals usually appear in the account 2-5 business days after the monthly statement.",
    checksByMailFineprint:
        "Paper checks usually take 5-10 business days to arrive by mail. Please confirm your mailing address in your {ProfileLink}.",
    reserveBalanceEmptyState:
        "Itt megtekintheted és frissítheted a tartalékegyenlegedet.",
    reserveUpdateAria:
        "Kattints erre a gombra a tartalékegyenleged frissítéséhez.",
    reserveUpdatedNotification: "Tartalékegyenleg frissítve",
    reserveMinimumRequired: "This unit requires at least {amount} reserve",
    directDepositEnrolled: "You are enrolled in direct deposit.",
    achDirectDepositEnrolled:
        "You are enrolled in ACH direct deposit and ACH expense debit.",
    directDepositNotEnrolled:
        "Jelenleg nem használod a közvetlen befizetés lehetőséget.",
    checkingAccount: "Folyószámla",
    savingsAccount: "Megtakarítási számla",
    updatePayment: "Fizetési mód frissítése",
    updateAction: "Frissítés",
    updatedNotification: "Fizetési adatok frissítve",
    updatePaymentSub: "Közvetlen befizetés bankszámlája",
    receiveByMailSub: "Enroll in Checks",
    receiveByMailText:
        "If selected, your checks will be mailed to your address on file.",
    directDepositRemoved: "Eltávolítva a közvetlen befizetésből",

    addBankAccount: "Bankszámla hozzáadása",
    checking: "Folyószámla",
    savings: "Megtakarítások",
    routingNumber: "Bankazonosító kód",
    nineDigitNumber:
        "A csekk bal alsó sarkán lévő, kilenc számjegyből álló szám",
    mustBeginWith:
        "A bankazonosító kód kötelezően a következő számok egyikével kezdődik: 0, 1, 2 vagy 3",
    mustBe9Digits: "Írd be a kilenc számjegyből álló bankazonosító kódot",
    mustBeValid9Digits: "Please enter a valid 9-digit routing number",
    accountNumber: "Számlaszám",
    accountHolderName: "Számlatulajdonos neve",
    enterBankAccount: "Please enter your bank account number",
    mustBe5to17Digits: "Please enter a 5 to 17-digit account number",
    byClickingYouAuthorize:
        "Because you chose to pay for expenses via ACH Debit, this account will also be charged if your expenses exceed your rental revenue for the month. By clicking 'Agree and Save', you authorize {accountingEntity} to make payments electronically via direct deposit and to debit the identified account for up to $10,000 per transaction per day. This authority will remain in effect until you change or update your payment method.",
    cancel: "mégse",
    agreeAndSave: "Egyetértés és mentés",
    savePaymentMethod: "Fizetési mód mentése",
    clickToSaveAria: "A gombra kattintva mentheted a fizetési módot.",
    clickToCancelAria: "Kattints erre a gombra a visszavonáshoz.",
    clickToRequestChecksAria:
        "Kattints erre a gombra a csekkek postán történő kéréséhez.",
    requestChecksByMail: "Csekkek kérése postán",
    getYourPaymentsFaster:
        "Ha gyorsabban szeretnéd megkapni a kifizetéseket, használd a közvetlen befizetést.",
    errorMessages: {
        updateReserveBalanceError: "A tartalékegyenleg értéke túl magas.",
        updateReserveBalanceNegativeError:
            "A tartalékegyenleg értéke nem lehet negatív.",
        updateReserveBalanceMissingError:
            "A tartalékegyenleg összegének megadása kötelező.",
        sendDirectDepositEmailError:
            "A közvetlen befizetéssel kapcsolatos regisztrációt lemondó e-mail elküldése sikertelen.",
        paymentAccountGeneralError:
            "Hiba lépett fel a fiókadatok frissítése során, próbáld meg újból később.",
        paymentAccountNameError:
            "A számlatulajdonos nevének megadása kötelező.",
        paymentAccountNameLengthError:
            "A számlatulajdonos neve hosszabb mint 100 karakter.",
        paymentAccountRoutingNumError: "Érvénytelen bankazonosító kód.",
        paymentAccountNumError: "Érvénytelen számlaszám.",
        paymentAccountTypeError: "Érvénytelen számlatípus.",
        sendACHUpdateEmailError:
            "Az ACH frissítésével kapcsolatos e-mail elküldése sikertelen.",
        somethingWentWrong: "Hiba lépett fel, próbáld meg újból.",
    },
    emptyState:
        "This is where you can update your reserve balance and payment method. Be sure to set up direct deposit before your first statement posts in order to get your payments faster.",
    linkToStatements: "Statements",
}

export default settings
