import React from "react"
import styles from "./Chip.module.scss"
import classnames from "classnames"

type ChipVariant = "default" | "outlined"

type ChipSize = "medium" | "small"

export type ChipProps = {
    /**
     * text to be used
     */
    label: string | JSX.Element
    /**
     * Prop to display icon
     */
    icon?: JSX.Element
    /**
     * pass in a testId for unit and e2e test
     */
    testId?: string
    /**
     * The size of the button
     */
    size?: ChipSize
    /**
     * visual style of the Chip
     */
    variant?: ChipVariant
}

export const Chip: React.FC<ChipProps> = ({
    label,
    icon,
    testId = "chip",
    size = "medium",
    variant = "default",
}) => {
    const sizeClassName = styles[`size-${size}`]
    const variantClassName = styles[`variant-${variant}`]
    const chipIconClassName = styles[`chip-icon-${size}`]

    const classNames = classnames(
        styles.chipRoot,
        "type-heading-extra-small-caps",
        sizeClassName,
        variantClassName
    )

    return (
        <div className={classNames} data-testid={testId}>
            {icon && (
                <div
                    className={classnames(
                        chipIconClassName,
                        "flex",
                        "items-center"
                    )}
                >
                    {icon}
                </div>
            )}
            <span>{label}</span>
        </div>
    )
}
