import { JSONAPIResource } from "@vacasa/owner-api-models"
import { AxiosError } from "axios"
import { UseQueryResult, useQuery, UseMutationResult, useQueryClient, useMutation } from "react-query"
import { fetchSurvey } from "./fetchSurvey"
import { Survey } from "./customSurveyType"
import { postSurvey, SurveyRequest } from "./postSurvey"
import { useCurrentUnit } from "../units"
import { OWNER_PREFERENCE_V2_QUERY_KEY } from "../user-preferences/useUserPreferences"
import storageService from "../../services/storage.service"
import { REVENUE_PERSONA_QUESTIONS_COMPLETED } from "../../constants/preferences.constants"

export interface SurveyMutationParam {
    surveyId: string
    contactId: string
    unitId: string
    surveyRequest: SurveyRequest
}

const useSurvey = (surveyId: string): UseQueryResult<JSONAPIResource<Survey>, AxiosError> => {
    const { unitId } = useCurrentUnit()
    return useQuery(
        ["survey", surveyId, unitId],
        () =>
            fetchSurvey(surveyId, unitId),
        {
            refetchOnWindowFocus: false
        }
    )
}

const useSurveyMutation = (
    unitId: string,
): UseMutationResult<
    number,
    AxiosError,
    SurveyMutationParam,
    void
> => {
    const queryClient = useQueryClient()
    return useMutation<
        number,
        AxiosError,
        SurveyMutationParam,
        void
    >((
            surveyMutationParam: SurveyMutationParam
        ): Promise<number> => {
            return postSurvey({
                surveyId: surveyMutationParam.surveyId,
                unitId: surveyMutationParam.unitId,
                contactId: surveyMutationParam.contactId,
                postSurveyData: surveyMutationParam.surveyRequest,
            })
        },
        {
            onSuccess: () => {
                const storedArray =  storageService.localStorage.getItem(REVENUE_PERSONA_QUESTIONS_COMPLETED)
                const historyArray = storedArray ? JSON.parse(storedArray) as Array<string> : [];
                historyArray.push(unitId)
                storageService.localStorage.setItem(REVENUE_PERSONA_QUESTIONS_COMPLETED, JSON.stringify(historyArray))
                queryClient.invalidateQueries([OWNER_PREFERENCE_V2_QUERY_KEY, unitId])
            },
        }
    )
}

export { useSurvey, useSurveyMutation }
