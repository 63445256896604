import { useContactId } from "hooks/user"
import { useEffect, useRef } from "react"
import { useQueryClient } from "react-query"

// Opens the w9 window and keeps track if it is closed
export const useW9Window = () => {
    const intervalRef = useRef<NodeJS.Timer | null>(null)
    const contactId = useContactId()
    const client = useQueryClient()
    const resetInterval = () => {
        if (intervalRef.current) clearInterval(intervalRef.current)
    }

    // Clean up interval timer when unmounting
    useEffect(() => {
        return () => {
            resetInterval()
        }
    }, [])

    const onIntervalTick = (win: Window | null) => {
        if (!win || !win.closed || !contactId) return
        resetInterval()
        client.invalidateQueries({
            queryKey: ["w9-tax-submission", contactId],
        })
    }

    const open = (url: string) => {
        const win = window.open(url, "_blank")
        resetInterval()
        intervalRef.current = setInterval(() => onIntervalTick(win), 500)
    }

    return {
        open,
    }
}
