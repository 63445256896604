const homeInfo = {
    title: "Informace o nemovitosti",
    viewListingAction: "Zobrazit nabídku",
    viewOnboardingAction: "Switch to Onboarding",
    active: "aktivní",
    inactive: "neaktivní",
    onboarding: "onboarding",
    dueToMaintenance: "V důsledku údržby",
    since: "Od",
    until: "Do",
    emptyState:
        "Details like your local Vacasa contacts and a link to your listing will show up here.",

    ownerGuide: {
        ownerGuideTitle: "Owner Resource Library",
        viewLink: "View Owner Library",
    },

    dogFriendly: {
        dogsAllowed: "Psi povoleni",
        yes: "Ano",
        no: "Ne",
        edit: "Upravit",
    },

    homeNotReady: {
        title: "Home not ready",
        description:
            "We’ll let you know as soon as your home’s information is ready to edit.",
    },
    help: {
        haveQuestions: "Have questions? {{emailLink}}",
        contactConsultant: "Contact a home consultant",
    },
    protectionProgram: {
        enrolled: "Enrolled",
        enrolledDescription:
            "You are enrolled with the Accommodations Protection Program. To change this, call {phoneNumber} or email {email}.",
        learnMore: "Learn more",
        notEnrolled: "Not enrolled",
        notEnrolledDescription:
            "You are not enrolled with Accommodations Protection Program. If you want to learn more about enrolling in this program, contact {email}.",
        title: "Accommodations\nProtection Program",
        unableToLoadProgramStatus:
            "We were unable to load your rental program status.",
    },
}

export default homeInfo
