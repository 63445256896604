import React, { useCallback, useEffect, useState } from "react"
import {
    JSONAPIResource,
    OwnerHold,
    OwnerHoldType,
    Reservation,
    ReservationType,
    UnitAvailabilityDays,
} from "@vacasa/owner-api-models"
import { formatISO, isBefore, subDays } from "date-fns"
import { utcToZonedTime } from "date-fns-tz"
import deepEqual from "deep-equal"
import useRouter from "hooks/common/useRouter"
import { useContactId } from "hooks/user"
import {
    CreateOwnerHoldRequest,
    UpdateOwnerHoldRequest,
    useCreateOwnerHoldMutation,
    useUpdateOwnerHoldMutation,
} from "hooks/owner-holds"
import { useToastNotification } from "lib/components/ToastNotification"
import { capitalize } from "lodash"
import pick from "lodash/pick"
import { useIntl } from "react-intl"
import {
    trackCreateHoldButtonClicked,
    trackCreateHoldEditButtonClicked,
    trackCreateHoldNextButtonClicked,
    trackOwnerHoldCreated,
    trackOwnerHoldUpdated,
} from "services/segment/ownerHold/ownerHoldTracking"
import { isUnauthorizedError } from "utils/error/error"
import { HOLD_CREATED } from "../../../../Constants"
import { useDateRangeContext } from "../../../../core/components/dates/state/DateRange.context"
import { isDateRangeAvailable } from "../../../../core/components/dates/validation/DateValidation"
import { useRootService } from "../../../../services"
import LoggingService from "../../../../services/logging/logging.service"
import { calculateCostOfHold } from "../../../../utils/reservations/reservationsUtil"
import { ActiveStatus } from "../../../../utils/unit"
import Layout, { FooterSecondaryButton } from "../Layout/Layout"
import { UnitInfo } from "../UnitInfo"
import { ReservationFormFooter } from "./ReservationFormFooter"
import { excludeReservationFromAvailability } from "./availabilityHelper"
import { DropdownValue } from "./components/HoldTypeSelect/HoldTypeSelect"
import { useUserCurrencyCode, useUser } from "hooks/user"
import { useCurrentUnit } from "hooks/units"
import InviteGuestForm, {
    OccupancyInfo,
    useInviteGuestForm,
} from "../ReservationGuests/ReservationGuestForm"
import { Button } from "lib/components/Button/Button"
import { GuestInfo, ReservationSummary } from "./ReservationSummary"
import { useAddReservationGuest } from "hooks/reservation-guests"
import { ReservationFormContent, ReservationFormContentProps } from "./ReservationFormContent"
import { useOwnerFeatureFlag } from "../../../../hooks/owner-feature-flag"
import { useContact } from "../../../../hooks/contact"
import { extractNumbersFromString } from "../../../../utils/common/extractNumbersFromString"

type ReservationFormProps = {
    initialStartDate?: Date | null
    initialEndDate?: Date | null
    unitInfo: UnitInfo
    availabilityDays: UnitAvailabilityDays
    reservation?: JSONAPIResource<Reservation>
    handleSetInInviteGuestFlow?: (inInviteGuestFlow: boolean) => void
}

export const getHoldExternalNote = (
    reservation?: Reservation
): string | undefined => {
    if (!reservation) return undefined
    return (
        (reservation.occupancyType === ReservationType.OWNERHOLD
            ? reservation.ownerHold?.holdExternalNote
            : reservation.vacasaHold?.holdExternalNote) ?? undefined
    )
}

/**
 * Form responsible for creating a new reservation and editing an existing one.
 */
const ReservationForm: React.FC<ReservationFormProps> = ({
    initialStartDate,
    initialEndDate,
    unitInfo,
    availabilityDays,
    reservation,
    handleSetInInviteGuestFlow,
}) => {
    const intl = useIntl()
    const currencyCode = useUserCurrencyCode()
    const { user, isEmployee } = useUser()
    const { dateRangeState, dispatchDateRangeAction } = useDateRangeContext()
    const router = useRouter()

    const { errorService } = useRootService()

    const createOwnerHoldMutation = useCreateOwnerHoldMutation()
    const addReservationGuest = useAddReservationGuest()
    const updateOwnerHoldMutation = useUpdateOwnerHoldMutation()
    const contactId = useContactId() ?? ""
    const contact = useContact(contactId)
    const { unitId, unit } = useCurrentUnit()
    const { addToastNotification } = useToastNotification()
    const {
        firstName,
        email,
        lastName,
        onChange,
        isValid,
        isDirty,
        populateForm,
    } = useInviteGuestForm()

    const timezone = unit?.attributes.timezone
    const isHoldInProgress =
        typeof reservation?.attributes.startDate === "string" &&
        typeof timezone === "string" &&
        isBefore(
            utcToZonedTime(reservation?.attributes.startDate, timezone),
            utcToZonedTime(new Date(), timezone)
        )

    const startDate = initialStartDate ?? null
    const endDate = initialEndDate ?? null

    const ownerPhone = contact.data?.phone || ""
    // TODO: Change the type in owner-api
    const reservationPhone = (reservation?.attributes as { phone?: string })?.phone || ownerPhone
    const attributes = pick(reservation?.attributes, [
        "startDate",
        "endDate",
        "ownerHold",
        "cleanAfterStay",
        "adults",
        "children",
        "pets",
    ])

    // Other variables
    const unitStatusNumber: ActiveStatus = unitInfo.unit.attributes?.active
    const defaultPhoneNumber = !reservation ? ownerPhone : reservationPhone

    // Optimizely
    const ownerHoldsV3 = useOwnerFeatureFlag("owner-portal-add-phone-number-in-owner-hold-form")

    // Get availability without the reservation being modified
    const availability = reservation?.id
        ? excludeReservationFromAvailability(
              reservation.id.toString(),
              availabilityDays
          )
        : availabilityDays

    // form values to submit
    const [selectedStartDate, setSelectedStartDate] = useState(startDate)
    const [selectedEndDate, setSelectedEndDate] = useState(endDate)
    const [phone, setPhone] = useState(defaultPhoneNumber)
    const [holdType, setHoldType] = useState(
        reservation?.attributes.ownerHold?.holdType ?? null
    )

    const [cleanAfterStay, setCleanAfterStay] = useState(
        reservation?.attributes.cleanAfterStay
    )

    const [occupancyInfo, setOccupancyInfo] = useState<OccupancyInfo>({
        adults: attributes.adults ?? 1,
        children: attributes.children ?? 0,
        pets: attributes.pets ?? 0,
    })
    const [holdNotes, setHoldNotes] = useState<string | undefined>(
        getHoldExternalNote(reservation?.attributes)
    )

    // invite guest form states
    const [showInviteGuestView, setShowInviteGuestView] = useState(false)
    const [showSkipButton, setShowSkipButton] = useState(true)
    const [guests, setGuests] = useState<GuestInfo[]>([])
    const [nextDisabled, setNextDisabled] = useState(false)
    const [showEditGuestView, setShowEditGuestView] = useState(false)
    const [showForm, setShowForm] = useState(false)
    const [editedGuest, setEditedGuest] = useState<GuestInfo | undefined>(
        undefined
    )

    // other form states
    const [notesValid, setNotesValid] = useState(true)
    const [datesValid, setDatesValid] = useState(true)
    const [phoneValid, setPhoneValid] = useState(true)
    const [isDifferentPhone, setIsDifferentPhone] = useState(false);
    const [costOfHold, setCostOfHold] = useState(0)
    const [changeDates, setChangeDates] = useState(false)
    const [showSummary, setShowSummary] = useState(false)
    const [housekeepingRequired, setHousekeepingRequired] = useState(false)
    const [housekeepingAllowed, setHousekeepingAllowed] = useState(false)

    const formValues: Partial<Reservation> = {
        startDate: selectedStartDate
            ? formatISO(selectedStartDate, { representation: "date" })
            : null,
        endDate: selectedEndDate
            ? formatISO(selectedEndDate, { representation: "date" })
            : null,
        ownerHold: {
            ...reservation?.attributes?.ownerHold,
            holdExternalNote: holdNotes,
            holdType,
        } as OwnerHold,
        cleanAfterStay,
        adults: occupancyInfo.adults,
        children: occupancyInfo.children,
        pets: occupancyInfo.pets,
    }

    const updateOccupancy = (id: string, count: number) => {
        setOccupancyInfo(prevState => ({
            ...prevState,
            [id]: count,
        }))
    }

    const handleHousekeepingAllowed = useCallback((value: boolean) => {
        setHousekeepingAllowed(value)
    }, [])

    const handleHousekeepingRequired = useCallback((value: boolean) => {
        setHousekeepingRequired(value)
    }, [])

    const onHoldTypeChange = (stayType?: DropdownValue): void => {
        setHoldType(stayType as OwnerHoldType)
        setHoldNotes(getHoldExternalNote(reservation?.attributes))
    }

    const onHoldNotesChange = (notes: string | undefined) => setHoldNotes(notes)

    const onChangeDatesClick = () => {
        setChangeDates(prevState => !prevState)
    }

    const onHousekeepingSelectChange = useCallback((value: boolean): void => {
        setCleanAfterStay(value)
    }, [])

    const onClearDatesClick = useCallback(() => {
        if (!reservation) {
            return
        }
        setSelectedStartDate(initialStartDate ?? null)
        setSelectedEndDate(initialEndDate ?? null)
        dispatchDateRangeAction({
            type: "UPDATE_START_DATE",
            payload: initialStartDate ?? null,
        })
        dispatchDateRangeAction({
            type: "UPDATE_END_DATE",
            payload: initialEndDate ?? null,
        })
    }, [dispatchDateRangeAction, initialEndDate, initialStartDate, reservation])

    const handleDatesChange = useCallback(
        (startDate: Date | null, endDate: Date | null) => {
            setSelectedStartDate(startDate)
            setSelectedEndDate(endDate)

            if (!startDate || !endDate) return

            setDatesValid(
                isDateRangeAvailable(
                    isHoldInProgress ? new Date() : startDate,
                    endDate,
                    false,
                    availability
                )
            )
        },
        [availability, isHoldInProgress]
    )

    const handlePhoneChange = useCallback(
        (phone: string) => {
            setIsDifferentPhone(extractNumbersFromString(phone) !== extractNumbersFromString(defaultPhoneNumber) )
            setPhone(phone)
        },
        [defaultPhoneNumber]
    )

    const handlePhoneValid = useCallback(
        (isValid: boolean) => {
            setPhoneValid(isValid)
        },
        [setPhoneValid]
    )

    const saveGuests = (reservationId?: string) => {
        guests.forEach(guestInfo => {
            addReservationGuest.mutate(
                {
                    reservationId: reservationId?.toString() ?? "",
                    guest: {
                        firstName: guestInfo.firstName ?? "",
                        lastName: guestInfo.lastName ?? "",
                        email: guestInfo.email ?? "",
                    },
                },
                {
                    onError: err => {
                        LoggingService.error({
                            message: "Failed to add guest to reservation",
                            err,
                        })
                    },
                }
            )
        })
    }

    const onCreateOwnerHoldClick = () => {
        trackCreateHoldButtonClicked()
        if (!holdType || !selectedEndDate || !selectedStartDate || !user) return

        const data: CreateOwnerHoldRequest = {
            FirstNight: formatISO(selectedStartDate, {
                representation: "date",
            }),
            LastNight: formatISO(subDays(selectedEndDate, 1), {
                representation: "date",
            }),
            CleanAfterStay:
                !!cleanAfterStay && unitStatusNumber === ActiveStatus.Active,
            Type: holdType.toLowerCase() === "vacasa hold" ? 3 : 2,
            FirstName: user.accountInfo.firstName ?? "",
            LastName: user.accountInfo.lastName ?? "",
            Email: user.email,
            HoldType: capitalize(holdType),
            Adults: occupancyInfo.adults,
            Children: occupancyInfo.children,
            Pets: occupancyInfo.pets,
            HoldExternalNote: holdNotes ?? "",
            // eslint-disable-next-line camelcase
            booked_currency_code: currencyCode,
            ContactID: user.contactId,
            CreateBy: user.id,
            HoldCreatedBy: user.userId,
            HoldUpdatedBy: user.userId,
            HoldWhoBooked: isEmployee
                ? "Vacasa"
                : `${user?.firstName} ${user?.lastName}`,
            // eslint-disable-next-line camelcase
            reservation_source_id: 7,
            UnitID: unitId ?? "",
            UserID: user.userId,
            Phone: ownerHoldsV3.isEnabled && holdType === "owner stay" ? phone : undefined
        }

        createOwnerHoldMutation.mutate(
            {
                data,
            },
            {
                onError: error => {
                    if (!isUnauthorizedError(error)) {
                        LoggingService.error({
                            message: `Failed to placeOwnerHold for contactId: ${contactId}, unitId: ${
                                unitId ?? ""
                            }, firstNight: ${data.FirstNight}, lastNight: ${
                                data.LastNight
                            }, holdType: ${data.HoldType}`,
                            error,
                        })
                    }

                    errorService.mapErrorCodeToString(
                        error?.response?.data?.errors?.code ?? 0
                    )
                    errorService.displayError()
                },
                onSuccess: (data, variables) => {
                    trackOwnerHoldCreated(
                        String(data.id),
                        variables.data.FirstNight,
                        variables.data.LastNight,
                        variables.data.CleanAfterStay
                    )

                    saveGuests(data.id?.toString())

                    addToastNotification({
                        content: intl.formatMessage({
                            id: "CalendarPage.flyout.holdPlaced",
                            defaultMessage: "Hold placed",
                        }),
                    })

                    const urlParams = new URLSearchParams(
                        router.location.search
                    )
                    urlParams.set(HOLD_CREATED, "1")

                    router.navigate(
                        {
                            pathname: `/calendar/hold/${data.id}`,
                            search: urlParams.toString(),
                            hash: router.location.hash,
                        },
                        { replace: true }
                    )
                },
            }
        )
    }

    const onUpdateClick = () => {
        if (
            !reservation ||
            !holdType ||
            !selectedEndDate ||
            !selectedStartDate ||
            !user
        )
            return

        const holdId = reservation.attributes.ownerHold?.holdID

        const data: UpdateOwnerHoldRequest = {
            ReservationID: String(reservation.id),
            Adults: occupancyInfo.adults,
            Kids: occupancyInfo.children,
            Pets: occupancyInfo.pets,
            OwnerHoldID: holdId,
            FirstNight: formatISO(selectedStartDate, {
                representation: "date",
            }),
            LastNight: formatISO(subDays(selectedEndDate, 1), {
                representation: "date",
            }),
            CleanAfterStay:
                !!cleanAfterStay && unitStatusNumber === ActiveStatus.Active,
            HoldType: capitalize(holdType),
            Type: holdType.toLowerCase() === "vacasa hold" ? 3 : 2,
            UnitID: unitId ?? "",
            UserID: user.id,
            HoldWhoBooked: isEmployee
                ? "Vacasa"
                : `${user?.firstName} ${user?.lastName}`,
            HoldUpdatedBy: user.userId,
            HoldCreatedBy: holdId ? undefined : user.userId,
            HoldExternalNote: holdNotes ?? "",
            Phone: ownerHoldsV3.isEnabled && holdType === "owner stay" ? phone : undefined
        }

        updateOwnerHoldMutation.mutate(
            {
                contactId,
                unitId: unitId ?? "",
                data,
            },
            {
                onError: error => {
                    if (!isUnauthorizedError(error)) {
                        LoggingService.error({
                            message: `Failed to updateOwnerHold: ${
                                data.OwnerHoldID
                            }, for contactId: ${contactId}, unitId: ${
                                unitId ?? ""
                            }, firstNight: ${data.FirstNight}, lastNight: ${
                                data.LastNight
                            }, holdType: ${data.HoldType}`,
                            error,
                        })
                    }
                    errorService.mapErrorCodeToString(
                        error?.response?.data?.errors?.code ?? 0
                    )
                    errorService.displayError()
                },
                onSuccess: data => {
                    trackOwnerHoldUpdated(
                        String(data.id),
                        data.attributes.clean_after_stay
                    )

                    addToastNotification({
                        content: intl.formatMessage({
                            id: "CalendarPage.flyout.holdUpdated",
                            defaultMessage: "Hold updated",
                        }),
                    })

                    router.navigate(
                        {
                            pathname: `/calendar/hold/${data.id}`,
                            search: router.location.search,
                            hash: router.location.hash,
                        },
                        { replace: true }
                    )
                },
            }
        )
    }

    const onNextClick = () => {
        trackCreateHoldNextButtonClicked("reservation_form")
        const showInviteView =
            holdType === "owner stay" || holdType === "complimentary stay"

        if (showInviteView) {
            setShowInviteGuestView(true)
            setShowForm(false)
            setShowSkipButton(true)
        } else {
            setShowSummary(true)
        }
    }

    const onBackClick = () => {
        const showInviteView =
            holdType === "owner stay" || holdType === "complimentary stay"
        setShowSummary(false)
        if (showInviteView) {
            setShowInviteGuestView(true)
            setShowForm(false)
        }
    }

    const handleNextDisabled = (nextDisabled: boolean) =>
        setNextDisabled(nextDisabled)

    const handleShowSkipButton = useCallback(
        (shouldShowSkipButton: boolean) =>
            setShowSkipButton(shouldShowSkipButton),
        []
    )

    const handleShowEditGuestView = useCallback(
        (shouldShowEditGuestView: boolean) => {
            setShowEditGuestView(shouldShowEditGuestView)
        },
        []
    )

    const onEditGuestInfoClick = () => {
        trackCreateHoldEditButtonClicked("guest_info")
        onBackClick()
    }

    const updateGuests = (guests: GuestInfo[]) => setGuests(guests)

    const removeGuest = (guest: GuestInfo) => {
        setGuests(existingGuests => {
            const guestIndex = existingGuests.indexOf(guest)
            const updatedGuests = existingGuests.splice(guestIndex, 1)
            return updatedGuests
        })
    }

    const onCancelEditGuestClick = () => {
        setShowEditGuestView(false)
        setShowForm(false)
    }

    const onInviteGuestNextClick = () => {
        trackCreateHoldNextButtonClicked("invite_guest_form")
        setShowSummary(true)
    }

    const handleShowForm = useCallback((shouldShowForm: boolean) => {
        setShowForm(shouldShowForm)
    }, [])

    const handleEditedGuest = useCallback((updatedGuest: GuestInfo) => {
        setEditedGuest(updatedGuest)
    }, [])

    const updateExistingGuest = () => {
        setShowEditGuestView(false)
        setShowForm(false)
        if (!editedGuest) return
        const updatedGuest = {
            firstName,
            lastName,
            email,
        }
        setGuests(existingGuests => {
            const guestIndex = existingGuests.indexOf(editedGuest)
            const updatedGuests = existingGuests.splice(
                guestIndex,
                1,
                updatedGuest
            )
            return updatedGuests
        })
    }

    const datesSelected = !!dateRangeState.startDate && !!dateRangeState.endDate

    useEffect(() => {
        setCostOfHold(
            calculateCostOfHold(
                selectedStartDate ?? 0,
                selectedEndDate ?? 0,
                availability
            )
        )
    }, [selectedStartDate, selectedEndDate, availability])

    useEffect(
        () => setNotesValid(!(holdType === "other" && !holdNotes)),
        [holdNotes, holdType]
    )

    useEffect(() => {
        dispatchDateRangeAction({
            type: "UPDATE_START_DATE",
            payload: initialStartDate ?? null,
        })
        dispatchDateRangeAction({
            type: "UPDATE_END_DATE",
            payload: initialEndDate ?? null,
        })
    }, [dispatchDateRangeAction]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (handleSetInInviteGuestFlow) {
            handleSetInInviteGuestFlow(showInviteGuestView && !showSummary)
        }
    }, [handleSetInInviteGuestFlow, showInviteGuestView, showSummary])

    const inviteGuestProps = {
        handleShowForm,
        handleShowSkipButton,
        inCreateHoldFlow: true,
        occupancyInfo,
        guests,
        updateGuests,
        removeGuest,
        isValid,
        populateForm,
        handleNextDisabled,
        showEditGuestView,
        handleShowEditGuestView,
        editedGuest,
        handleEditedGuest,
    }

    const reservationFormContentProps: ReservationFormContentProps = {
        unitInfo,
        changeDates,
        onChangeDatesClick,
        isHoldInProgress,
        startDate,
        endDate,
        dateRangeState,
        availability,
        handleDatesChange,
        datesValid,
        costOfHold,
        holdType,
        onHoldTypeChange,
        holdNotes,
        onHoldNotesChange,
        cleanAfterStay,
        onHousekeepingSelectChange,
        selectedStartDate,
        selectedEndDate,
        occupancyInfo,
        updateOccupancy,
        handleHousekeepingRequired,
        handleHousekeepingAllowed,
        phone,
        handlePhoneChange,
        handlePhoneValid,
        process: reservation ? "editing" : "creation"
    }

    const isFormChanged = ownerHoldsV3.isEnabled && holdType === "owner stay" ? !deepEqual(attributes, formValues) || isDifferentPhone : !deepEqual(attributes, formValues)

    return (
        <>
            {showSummary && holdType ? (
                <Layout showBackButton customOnBackClick={onBackClick}>
                    <Layout.Content>
                        <ReservationSummary
                            startDate={selectedStartDate}
                            endDate={selectedEndDate}
                            holdType={holdType}
                            unitInfo={unitInfo}
                            cleanAfterStay={cleanAfterStay ?? false}
                            adults={occupancyInfo.adults}
                            children={occupancyInfo.children}
                            pets={occupancyInfo.pets}
                            guests={guests}
                            notes={holdNotes}
                            phone={phone}
                            onEditGuestClick={onEditGuestInfoClick}
                            availability={availability}
                            handleDatesChange={handleDatesChange}
                            handleNotesChange={onHoldNotesChange}
                            housekeepingAllowed={housekeepingAllowed}
                            housekeepingRequired={housekeepingRequired}
                            onHousekeepingSelectChange={
                                onHousekeepingSelectChange
                            }
                            handlePhoneChange={handlePhoneChange}
                            handlePhoneValid={handlePhoneValid}
                        />
                    </Layout.Content>
                    <Layout.Footer>
                        <Button
                            variant="primary"
                            className="full-width"
                            data-testid="createhold-button"
                            onClick={onCreateOwnerHoldClick}
                            disabled={ownerHoldsV3.isEnabled && holdType === "owner stay" ? !phoneValid : false}
                            loading={
                                createOwnerHoldMutation.isLoading ||
                                addReservationGuest.isLoading
                            }
                        >
                            {intl.formatMessage({
                                id: "CalendarPage.flyout.createHold",
                                defaultMessage: "Create Hold",
                            })}
                        </Button>
                    </Layout.Footer>
                </Layout>
            ) : showInviteGuestView ? (
                <Layout
                    showBackButton
                    customOnBackClick={() => setShowInviteGuestView(false)}
                >
                    <Layout.Content form>
                        <InviteGuestForm
                            formData={{
                                email,
                                firstName,
                                lastName,
                            }}
                            onChange={onChange}
                            showForm={showForm}
                            inviteGuestProps={inviteGuestProps}
                        />
                    </Layout.Content>
                    <Layout.Footer>
                        {showEditGuestView && (
                            <>
                                <Button
                                    disabled={
                                        !isValid() ||
                                        !isDirty({
                                            email: editedGuest?.email ?? "",
                                            firstName:
                                                editedGuest?.firstName ?? "",
                                            lastName:
                                                editedGuest?.lastName ?? "",
                                        })
                                    }
                                    aria-label="Click this button to add a guest"
                                    variant="primary"
                                    className="full-width"
                                    onClick={updateExistingGuest}
                                >
                                    {capitalize(
                                        intl.formatMessage({
                                            id: "CalendarPage.flyout.saveChanges",
                                            defaultMessage: "Save Changes",
                                        })
                                    )}
                                </Button>

                                <FooterSecondaryButton
                                    onClick={onCancelEditGuestClick}
                                >
                                    {intl.formatMessage({
                                        id: "CalendarPage.flyout.cancel",
                                        defaultMessage: "Cancel",
                                    })}
                                </FooterSecondaryButton>
                            </>
                        )}
                        {!showEditGuestView &&
                            (showSkipButton && guests.length === 0 ? (
                                <Button
                                    aria-label={intl.formatMessage({
                                        id: "CalendarPage.flyout.skipAria",
                                        defaultMessage:
                                            "Click this button to skip adding guests",
                                    })}
                                    variant="primary"
                                    className="full-width"
                                    data-testid="skip-guests-button"
                                    onClick={() => setShowSummary(true)}
                                >
                                    {intl.formatMessage({
                                        id: "CalendarPage.flyout.skip",
                                        defaultMessage: "Skip",
                                    })}
                                </Button>
                            ) : (
                                <Button
                                    variant="primary"
                                    className="full-width"
                                    onClick={onInviteGuestNextClick}
                                    disabled={nextDisabled}
                                >
                                    {intl.formatMessage({
                                        id: "CalendarPage.flyout.next",
                                        defaultMessage: "Next",
                                    })}
                                </Button>
                            ))}
                    </Layout.Footer>
                </Layout>
            ) : (
                <Layout>
                    <Layout.Content reservationForm>
                        <ReservationFormContent
                            {...reservationFormContentProps}
                        />
                    </Layout.Content>
                    <Layout.Footer>
                        <ReservationFormFooter
                            loading={
                                createOwnerHoldMutation.isLoading ||
                                updateOwnerHoldMutation.isLoading
                            }
                            reservation={reservation}
                            onClearDatesClick={onClearDatesClick}
                            onSaveClick={onUpdateClick}
                            onNextClick={onNextClick}
                            createHoldEnabled={
                                !reservation &&
                                !!holdType &&
                                notesValid &&
                                datesValid &&
                                datesSelected
                            }
                            saveChangesEnabled={
                                isFormChanged &&
                                phoneValid &&
                                notesValid &&
                                datesValid &&
                                datesSelected
                            }
                            changeDates={changeDates}
                        />
                    </Layout.Footer>
                </Layout>
            )}
        </>
    )
}

export { ReservationForm }
