import {
    CSSProps,
    DeepPartial,
    Theme as TwilioTheme,
} from "@twilio/flex-webchat-ui"

const fontFamily: CSSProps = {
    fontFamily: "Public Sans, sans-serif !important",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "16px",
    letterSpacing: "0em",
}

const brandMessageBubbleColors: CSSProps = (textColor: string) => ({
    Container: {
        display: "none",
    },
    BubbleContainer: {
        maxWidth: "100%",
        maxHeight: "100%",
        margin: "0",
    },
    ContentContainer: {},
    Bubble: {
        ...fontFamily,
        background: "transparent",
        width: "auto",
        color: textColor,
        height: "100%",
    },
    Avatar: {
        display: "none",
    },
    Header: {
        ...fontFamily,
        color: textColor,
        letterSpacing: "0.02em",
    },
    Content: {
        display: "none",
    },
})

export const Theme = (online: boolean): DeepPartial<TwilioTheme> => ({
    MainContainer: {
        ...fontFamily,
        width: "280px",
        height: online ? "556px" : "472px",
        borderRadius: "8px",
        background: "var(--white)",
    },
    Chat: {
        MessagingCanvas: {
            Container: {
                ...fontFamily,
                background: "var(--white)",
            },
        },
        MessageList: {
            DateSeparatorLine: {},
            TypingIndicator: {},
            DateSeparatorText: {
                ...fontFamily,
            },
        },
        WelcomeMessage: {
            Container: {
                ...fontFamily,
                align: "center",
                margin: online ? "0 0 0 0" : "0 0 -15px",
            },
            Icon: {
                display: "none",
            },
        },
        MessageListItem: {
            FromOthers: brandMessageBubbleColors("var(--chat-text)"),
            FromMe: brandMessageBubbleColors("var(--white)"),
            ReadStatus: {
                ...fontFamily,
                fontSize: 10,
            },
        },
        MessageInput: {
            AttachFileButton: {
                background: "none !important",
                opacity: "1 !important",
                height: "24px",
                width: "24px",
                transform: "translateY(-4px)",
            },
            Container: {
                ...fontFamily,
                background: "var(--chat-primary)",
                borderRadius: "20px !important",
                textarea: {
                    minHeight: 32,
                },
            },
            Button: {
                backgroundColor: "var(--chat-primary)",
                opacity: "1 !important",
                width: 32,
                height: 32,
                "&:hover": {
                    backgroundColor: "var(--chat-primary)",
                    backgroundBlendMode: "color",
                },
            },
        },

        MessageCanvasTray: {
            Button: {
                width: "200px",
                height: "40px",
                ...fontFamily,
                fontWeight: "700",
                background: "var(--chat-icon-background)",
                borderRadius: "100px",
                padding: "4px 0",
                margin: "0",
            },
            Container: {
                height: "146px",
                width: "280px",
                ...fontFamily,
                background: "var(--white)",
                "& .Twilio-MessageCanvasTray-default": {
                    width: "100%",
                    fontSize: 14,
                },
                "& .MessageCanvasTrayContent": {
                    margin: "40px 40px 8px 40px !important",
                },
            },
        },
    },

    MainHeader: {
        Container: {
            ...fontFamily,
            "& .Twilio-Icon-Close": {
                color: "var(--black)",
                cursor: "pointer",
                position: "absolute",
                right: 12,
                top: 12,
                width: 20,
                height: 20,
                "& svg": {
                    display: "none",
                },
            },
            "&:hover": {
                cursor: "default",
            },
        },
    },

    EntryPoint: {
        Container: {
            overflow: "initial !important",
            "& .Twilio-Icon-Custom": {
                svg: {
                    paddingTop: "2px",
                    paddingLeft: "2.5px",
                    width: "71px",
                    height: "70px",
                },
            },
            background: "var(--chat-icon-background)",
            ...fontFamily,
            width: "50px",
            height: "50px",
            "&:hover": {
                background: "var(--midnight-30)",
                backgroundBlendMode: "color",
                ".Twilio-Icon-Custom": {
                    "svg path": {},
                },
            },
        },
    },
    PendingEngagementCanvas: {
        Container: {
            background: "var(--white)",
        },
    },
})
