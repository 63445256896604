import React from "react"
import {
    JSONAPIResource,
    Reservation,
    ReservationType,
} from "@vacasa/owner-api-models"
import useRouter from "hooks/common/useRouter"
import { Button, ButtonProps } from "lib/components/Button/Button"
import { FormattedMessage } from "react-intl"
import { FooterSecondaryButton } from "../Layout/Layout"
import styles from "../ReservationFlyout.module.scss"

type ReservationFormFooterProps = {
    reservation?: JSONAPIResource<Reservation>
    loading: boolean
    createHoldEnabled: boolean
    saveChangesEnabled: boolean
    onClearDatesClick: () => void
    onSaveClick: () => void
    onNextClick: () => void
    changeDates?: boolean
}

const ReservationFormFooter: React.FC<ReservationFormFooterProps> = ({
    reservation,
    onClearDatesClick,
    createHoldEnabled,
    saveChangesEnabled,
    loading,
    onSaveClick,
    onNextClick,
    changeDates,
}): JSX.Element | null => {
    const router = useRouter()

    const onCancelClick = () => {
        router.navigate(
            {
                pathname: `/calendar/hold/${reservation?.id}`,
                search: router.location.search,
                hash: router.location.hash,
            },
            {
                replace: true,
            }
        )
    }

    if (reservation?.attributes.cancelled) {
        return null
    }

    const primaryButtonProps: ButtonProps = reservation
        ? {
              variant: "primary",
              disabled: !saveChangesEnabled || loading,
              loading,
              onClick: onSaveClick,
          }
        : {
              variant: "primary",
              disabled: !createHoldEnabled || loading,
              loading,
              onClick: onNextClick,
          }

    const flyoutFooter = (
        <>
            <div className={styles["button-row"]}>
                <Button
                    {...primaryButtonProps}
                    style={{
                        height: reservation ? "50px" : "40px",
                    }}
                    data-testid={reservation ? "" : "next-button"}
                >
                    {reservation ? (
                        <FormattedMessage
                            id="CalendarPage.flyout.saveChanges"
                            defaultMessage="Save changes"
                        />
                    ) : (
                        <FormattedMessage
                            id="CalendarPage.flyout.next"
                            defaultMessage="Next"
                        />
                    )}
                </Button>
            </div>
            {reservation && !changeDates && (
                <FooterSecondaryButton
                    onClick={onCancelClick}
                    disabled={loading}
                >
                    <FormattedMessage
                        id="CalendarPage.flyout.cancel"
                        defaultMessage="Cancel"
                    />
                </FooterSecondaryButton>
            )}

            {reservation && changeDates && (
                <FooterSecondaryButton
                    onClick={onClearDatesClick}
                    disabled={loading}
                >
                    <FormattedMessage
                        id="CalendarPage.flyout.clearDates"
                        defaultMessage="Clear Dates"
                    />
                </FooterSecondaryButton>
            )}
        </>
    )

    if (reservation?.attributes.occupancyType !== ReservationType.RESERVATION) {
        return flyoutFooter
    }

    return null
}

export { ReservationFormFooter }
