import { IconLargeDiamondBlue, IconLargeDocumentBlue } from "assets"
import classNames from "classnames"
import { useHousekeeping } from "hooks/housekeeping"
import SVGIcon from "lib/components/SVGIcon"
import { Tooltip } from "lib/components/Tooltip/Tooltip"
import { FC, useEffect, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import styles from "./AverageCleanScore.module.scss"
import { BaseWidget, EmptyState } from "./BaseWidget"
import { WidgetError } from "./WidgetError"

export interface AverageCleanScoreProps {
    contactId: string
    unitId: string
}

export const AverageCleanScore: FC<AverageCleanScoreProps> = ({
    contactId,
    unitId,
}) => {
    const intl = useIntl()
    const [queryKey, setQueryKey] = useState("")

    const housekeeping = useHousekeeping(
        {
            contactId,
            unitId,
        },
        { queryKey }
    )

    // Allow widgets to invidiually be refreshed on error
    useEffect(() => {
        if (housekeeping.failureCount) {
            setQueryKey("avgCleanScore")
        }
    }, [housekeeping.failureCount])

    const isEnabled = !!contactId && !!unitId

    const title = intl.formatMessage({
        id: "HomeInfo.widgets.averageCleanScore.title",
        defaultMessage: "Avg Guest Clean Score",
    })

    const showEmptyState =
        (!isEnabled || housekeeping.data?.attributes.inspectionsCount === 0) &&
        !housekeeping.isLoading

    if (housekeeping.isError) {
        return (
            <WidgetError
                errorHeading={title}
                errorMessage={intl.formatMessage({
                    id: "HomeInfo.widgets.averageCleanScore.errorMessage",
                    defaultMessage:
                        "We're having trouble loading this content.",
                })}
                handleClick={housekeeping.refetch}
            />
        )
    }

    if (showEmptyState)
        return (
            <EmptyState
                icon="diamond"
                title={intl.formatMessage({
                    id: "HomeInfo.widgets.averageCleanScore.title",
                    defaultMessage: "Avg Guest Clean Score",
                })}
                message={intl.formatMessage({
                    id: "HomeInfo.widgets.averageCleanScore.emptyStateMessage",
                    defaultMessage:
                        "When available, your average guest clean score over the last 6 months will appear here.",
                })}
                footer={
                    <div style={{ margin: "0 auto -13px 0" }}>
                        <Inspections />
                    </div>
                }
            />
        )

    return (
        <BaseWidget>
            <header className={classNames(styles.header)}>
                <div
                    className={classNames(styles.iconBox, {
                        shimmer: housekeeping.isLoading,
                    })}
                >
                    <IconLargeDiamondBlue className={styles.icon} />
                </div>
                <h3
                    className={classNames(styles.title, {
                        shimmer: housekeeping.isLoading,
                    })}
                    style={{
                        maxWidth: housekeeping.isLoading ? "200px" : undefined,
                    }}
                >
                    {title}
                </h3>
                {!housekeeping.isLoading && (
                    <div className={classNames(styles.headerTooltip)}>
                        <Tooltip
                            title={intl.formatMessage({
                                id: "HomeInfo.widgets.averageCleanScore.tooltipTitle",
                                defaultMessage: "Avg Guest Clean Score",
                            })}
                            subTitle={intl.formatMessage({
                                id: "HomeInfo.widgets.averageCleanScore.tooltipSubTitle",
                                defaultMessage: "over the last 6 months",
                            })}
                            body={intl.formatMessage({
                                id: "HomeInfo.widgets.averageCleanScore.tooltipBody",
                                defaultMessage:
                                    "Inspections are required on all cleans at Vacasa. Our detailed home inspections ensure that Vacasa maintain a high quality housekeeping and unit appearance standard. They serve to provide confidence knowing your homes are safe and guest-ready.",
                            })}
                        >
                            <SVGIcon
                                svg="icon-feather-info"
                                colorName="gray-lightest"
                                className="zero-margin cursor-pointer"
                            />
                        </Tooltip>
                    </div>
                )}
            </header>
            <div className={styles.content}>
                <SegmentedProgressBar
                    rating={housekeeping.data?.attributes.averageCleanScore}
                    loading={housekeeping.isLoading}
                />
                <Inspections
                    count={housekeeping.data?.attributes.inspectionsCount ?? 0}
                    loading={housekeeping.isLoading}
                />
                <div
                    className={classNames(styles.periodNote, {
                        shimmer: housekeeping.isLoading,
                    })}
                >
                    <FormattedMessage
                        id="HomeInfo.widgets.averageCleanScore.periodMessage"
                        defaultMessage="over the last 6 months"
                    />
                </div>
            </div>
        </BaseWidget>
    )
}

type SegmentedProgressBarProps = {
    rating?: number
    loading: boolean
}
const SegmentedProgressBar: FC<SegmentedProgressBarProps> = ({
    loading,
    rating,
}) => {
    const intl = useIntl()

    const getFillValue = (value: number) => {
        if (value <= 0) return 0
        if (value >= 100) return 100
        return value
    }

    if (!rating && !loading) {
        return (
            <p className={styles.noAverageCleanMessage}>
                {intl.formatMessage({
                    id: "HomeInfo.widgets.averageCleanScore.emptyStateMessage",
                    defaultMessage:
                        "When available, your average guest clean score over the last 6 months will appear here.",
                })}
            </p>
        )
    }

    const bars = Array.from({ length: 5 }).map((_, index) => {
        return (
            <div
                key={index}
                className={classNames(styles.bar, {
                    shimmer: loading,
                })}
            >
                <div
                    className={styles.barInner}
                    style={{
                        width: `${getFillValue(
                            ((rating ?? 0) - index) * 100
                        )}%`,
                    }}
                />
            </div>
        )
    })

    return (
        <div className={classNames(styles.segmentedProgressBar)}>
            <div className={styles.barWrap}>{bars}</div>
            <div
                className={classNames(styles.rating, {
                    shimmer: loading,
                })}
            >
                {rating}/5
            </div>
        </div>
    )
}

type InspectionsProps = {
    count?: number
    loading?: boolean
}
const Inspections: FC<InspectionsProps> = ({ count, loading }) => {
    const intl = useIntl()
    return (
        <div
            className={classNames(styles.inspections, {
                loading,
                shimmer: loading,
            })}
        >
            <div className={classNames(styles.inspectionsIconWrap)}>
                <IconLargeDocumentBlue />
            </div>
            <div className={classNames(styles.inspectionsContent)}>
                <h4
                    className={classNames(styles.inspectionsTitle, "uppercase")}
                >
                    {intl.formatMessage({
                        id: "HomeInfo.widgets.inspetionsTooltipBody.inspetions",
                        defaultMessage: "Inspections",
                    })}
                </h4>
                {count ? (
                    <span className={classNames(styles.inspectionsCount)}>
                        {count}
                    </span>
                ) : (
                    <span className={classNames(styles.noInspections)}>
                        No inspections reported
                    </span>
                )}
            </div>
            {!loading && count && (
                <div className={classNames(styles.inspectionsTooltip)}>
                    <Tooltip
                        title={intl.formatMessage({
                            id: "HomeInfo.widgets.averageCleanScore.inspetionsTooltipTitle",
                            defaultMessage: "Inspections",
                        })}
                        subTitle={intl.formatMessage({
                            id: "HomeInfo.widgets.averageCleanScore.inspetionsTooltipSubTitle",
                            defaultMessage: "over the last 6 months",
                        })}
                        body={intl.formatMessage({
                            id: "HomeInfo.widgets.averageCleanScore.inspetionsTooltipBody",
                            defaultMessage:
                                "Inspections are required on all cleans at Vacasa. Our detailed home inspections ensure that Vacasa maintain a high quality housekeeping and unit appearance standard. They serve to provide confidence knowing your homes are safe and guest-ready.",
                        })}
                    >
                        <SVGIcon
                            svg="icon-feather-info"
                            colorName="gray-lightest"
                            className="zero-margin cursor-pointer"
                        />
                    </Tooltip>
                </div>
            )}
        </div>
    )
}
