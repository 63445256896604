/**
 * Cleans a string by removing all non-numeric characters and returns it as a number or a string.
 * @param inputString - The string to be cleaned.
 * @returns The numeric value as a number.
 */
export const extractNumbersFromString = (inputString: string): number => {
    // Use a regular expression to replace all non-numeric characters with an empty string
    const cleanedString = inputString.replace(/\D/g, '')

    return cleanedString !== '' ? parseInt(cleanedString, 10) : 0
}