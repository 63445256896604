const maintenance = {
    title: "Manutenzione",
    supportHub: "Support",
    newRequest: "Nuova richiesta",
    open: "Apri",
    closed: "Chiudi",
    requestNumber: "Richiesta n.",
    status: "Stato",
    inProgress: "In corso",
    rental: "Proprietà",
    allRentals: "Tutte le proprietà",
    search: "ricerca",
    submitted: "Inviata",
    updated: "Aggiornata",

    selectRental: "Seleziona una proprietà",
    newMaintenanceRequest: "New Maintenance Request",
    needSomeHelp: "Need some help?",
    categorySubtitle:
        "Let us know how we can support you by selecting an option below:",
    requestTitle: "Maintenance request title",
    titlePlaceholder: "Clogged drain",
    describeYourRequest: "Describe your request",
    requestDetails: "Describe your request in detail",
    note: "Note",
    notes: "Notes",
    detailsPlaceholder:
        "We noticed a big drainage issue with the primary kitchen sink. It's a super slow drain or it doesn't even drain at all. Please help!",
    clear: "Clear",
    submitRequest: "Invia richiesta",
    areYouSure: "Are you sure?",
    leavingAlertText:
        "Leaving the submission form will cause you to lose any data you may have entered.",
    continueWithSubmission: "Continue with Submission",
    cancelRequest: "Cancel Request",
    requestSubmitted: "Request submitted",
    requestSaved: "Richiesta salvata",

    submitNewRequest: "Invia una nuova richiesta",
    noOpenRequests: "No Open Requests",
    newRequestWill: "New requests will appear here until they are addressed.",
    noClosedRequests: "No Closed Requests",
    closedRequestsWill:
        "Once an open request is addressed, it will be closed out and will appear here.",

    ariaLabel: "Fai clic qui per inviare una richiesta di manutenzione",

    needHelp: "Serve aiuto?",
    contactSentenceIfQuestions:
        "Se hai domande o desideri aggiornare la tua richiesta rivolgiti al tuo contatto, {name}.",
    or: "o",

    failedFetchError: `Abbiamo avuto dei problemi con il caricamento delle tue richieste di manutenzione. Se il problema persiste, ti invitiamo a riprovare più tardi.`,
    failedCreateError: `Non abbiamo potuto salvare la tua richiesta di manutenzione. Ti invitiamo a verificare la tua scelta e a riprovare.`,
    reload: `Ricarica`,
    emptyState:
        "Need help with home maintenance? You’ll be able to submit requests here.",

    submittedOn: "Submitted on {date}",
    closedOn: "Closed on {date}",
    cancelled: "Cancelled",
    comments: {
        comments: "Comments",
        empty: "When Vacasa responds to this request, their comments will appear here.",
        overCharacterLengthLimit:
            "The comment should not be over 1000 characters.",
        failedCreateCommentError:
            "We were unable to save your comment. Please try again later.",
    },
    featureAlertTitle: "NEW! Two-way Ticket Comments",
    featureAlertBody:
        "Add a comment to your ticket to provide more information or follow up with a question, which will streamline communication about a specific ticket.",
}

export default maintenance
