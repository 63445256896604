import { OwnerLibraryLocation, SegmentLocation } from "../constants"
import segmentService from "../segment.service"
import { OWNER_LIBRARY_LINK_CLICKED_EVENT } from "./ownerLibraryTrackingConstants"

export const trackOwnerLibraryLinkClicked = (
    location: SegmentLocation,
    linkTo: OwnerLibraryLocation
): void => {
    segmentService.track(OWNER_LIBRARY_LINK_CLICKED_EVENT, { location, linkTo })
}
