/* eslint-disable camelcase */
import segmentService from "../segment.service"
import {
    PRODUCT_TOUR_DISMISSED,
    PRODUCT_TOUR_DISPLAYED,
    PRODUCT_TOUR_FINISH_CLICKED,
    PRODUCT_TOUR_NEXT_CLICKED,
    PRODUCT_TOUR_PAGINATION_CLICKED,
    PRODUCT_TOUR_PREVIOUS_CLICKED,
} from "./productTourTrackingConstants"

export const trackProductTourPresented = (productTourName: string) => {
    segmentService.track(PRODUCT_TOUR_DISPLAYED, {
        product_tour: productTourName,
    })
}

export const trackProductTourPreviousClicked = (productTourName: string) => {
    segmentService.track(PRODUCT_TOUR_PREVIOUS_CLICKED, {
        product_tour: productTourName,
    })
}

export const trackProductTourNextClicked = (productTourName: string) => {
    segmentService.track(PRODUCT_TOUR_NEXT_CLICKED, {
        product_tour: productTourName,
    })
}

export const trackProductTourFinishClicked = (productTourName: string) => {
    segmentService.track(PRODUCT_TOUR_FINISH_CLICKED, {
        product_tour: productTourName,
    })
}

export const trackProductTourDismissed = (productTourName: string) => {
    segmentService.track(PRODUCT_TOUR_DISMISSED, {
        product_tour: productTourName,
    })
}

export const trackProductTourPaginationClicked = (
    productTourName: string,
    index: number
) => {
    segmentService.track(PRODUCT_TOUR_PAGINATION_CLICKED, {
        product_tour: productTourName,
        product_tour_slide_number: index,
    })
}
