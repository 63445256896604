import { AxiosError } from "axios"
import { useMutation } from "react-query"
import { downloadStatementsPDF } from "./downloadStatementsPDF"
import { DownloadStatementsPDFRequest } from "./useDownloadStatementsPDFRequest"

export const useDownloadStatementsPDF = () => {
    return useMutation<
        {
            data: string
            filename: string
        },
        AxiosError,
        DownloadStatementsPDFRequest,
        unknown
    >(request => downloadStatementsPDF(request))
}
