import React, { ReactNode, useEffect } from "react"

// Services

// Components
import { TaxesModal } from "./TaxesModal"
import AnnualDataDownload from "./AnnualDataDownload"
import FormsFromUs from "./FormsFromUs"
import FormsFromYou from "./FormsFromYou"

import { FormattedMessage } from "react-intl"
import { Alert } from "../../components/Alert"
import { ListLayout } from "../../components/Layout/ListLayout"
import { useW9TaxSubmission } from "../../hooks/taxes-w9-submission"

import { useOnboardingStatus } from "hooks/onboarding/use-onboarding-status"
import { useHasTaxObligation } from "hooks/taxes"
import { trackTaxesPageViewed } from "services/segment/taxes/taxesTracking"
import { checkIfOnboardingAppUser } from "utils/onboarding"
import styles from "./Taxes.module.css"
import { useUser } from "hooks/user"
import { TaxInformationFlyoutProvider } from "views/taxes/TaxInformationFlyout"

const W9Alert = () => {
    const w9TaxSubmissionQuery = useW9TaxSubmission()
    const { user } = useUser()
    if (!w9TaxSubmissionQuery.isSuccess) return null
    if (user?.accountInfo.country !== "US") return null
    if (w9TaxSubmissionQuery.data.hasSubmittedW9) return null

    return (
        <div className={styles.alertContainer}>
            <Alert variant="alertness">
                <Alert.Icon />
                <Alert.Description>
                    <FormattedMessage
                        id="Taxes.w9Alert"
                        defaultMessage="<b>Submit a taxpayer form.</b> We must receive this in order to provide year-end tax documents and yearly rental data. "
                        values={{
                            b: (chunks: ReactNode) => (
                                <Alert.Title>{chunks}</Alert.Title>
                            ),
                        }}
                    />
                </Alert.Description>
            </Alert>
        </div>
    )
}

const Taxes: React.FC = (): JSX.Element => {
    const onboardingStatus = useOnboardingStatus()
    const isOnboardingAppUser = checkIfOnboardingAppUser(onboardingStatus)
    const { isLoading, hasTaxObligation } = useHasTaxObligation()

    useEffect(() => {
        trackTaxesPageViewed()
    }, [])

    const isLoadingOrOnboardingAppUser =
        onboardingStatus.isLoading || isOnboardingAppUser || isLoading

    const showTaxesAlerts = hasTaxObligation && !isLoadingOrOnboardingAppUser

    return (
        <TaxInformationFlyoutProvider>
            {showTaxesAlerts && <TaxesModal />}
            {showTaxesAlerts && <W9Alert />}
            <ListLayout
                title={
                    <FormattedMessage id="Taxes.title" defaultMessage="Taxes" />
                }
            >
                {isLoadingOrOnboardingAppUser ? null : <FormsFromYou />}
                <AnnualDataDownload />
                <FormsFromUs />
            </ListLayout>
        </TaxInformationFlyoutProvider>
    )
}

export default Taxes
