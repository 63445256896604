import { JSONAPIResource } from "@vacasa/owner-api-models"
import { Reservation as LegacyReservation } from "@vacasa/owner-api-models/src/legacy"
import { authAxios } from "api/authAxios"
import { CreateOwnerHoldRequest } from "."

export const postOwnerHold = async (
    params: {
        data: CreateOwnerHoldRequest
        signal?: AbortSignal
    },
    acceptVersion?: string
) => {
    const { data, signal } = params
    return authAxios
        .post<{
            data: JSONAPIResource<LegacyReservation>
        }>(
            `/v1/legacy/owners/${data.ContactID}/units/${data.UnitID}/hold`,
            data,
            {
                signal,
                params: { acceptVersion },
            }
        )
        .then(response => response.data.data)
}
