import { Button } from "lib/components/Button"
import { FC } from "react"
import { Card, CardContent } from "../../../components/Card"
import List from "../../../lib/components/List/List"
import { UnitSearchResult } from "./../types"
import SearchResultRow from "./SearchResultRow"
import styles from "./search.module.scss"
interface Props {
    backToSearch: () => void
    unitSearchResults: UnitSearchResult[]
}

const SearchResults: FC<Props> = ({
    backToSearch,
    unitSearchResults,
}): JSX.Element => {
    return (
        <Card>
            <CardContent>
                <div
                    className="flex"
                    style={{ justifyContent: "space-between" }}
                >
                    <h3 className="type-heading-medium-large">
                        {unitSearchResults.length} Results
                    </h3>
                    <Button
                        className={styles.btnNewSearch}
                        onClick={backToSearch}
                        variant="primary"
                    >
                        New Search
                    </Button>
                </div>
                <br />
                <List className="search-list">
                    {unitSearchResults.map((result, i) => (
                        <div
                            key={`${result.id}-${result.contactId}`}
                            className="search-results"
                        >
                            <SearchResultRow
                                key={i}
                                unitSearchResult={result}
                            />
                        </div>
                    ))}
                </List>
            </CardContent>
        </Card>
    )
}

export default SearchResults
