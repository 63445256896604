import { Pill } from "components/Pill/Pill"
import SVGIcon from "lib/components/SVGIcon"
import { useCallback } from "react"
import { FormattedMessage } from "react-intl"
import { Link } from "react-router-dom"
import { trackEditPetFriendlyViewed } from "services/segment/homeInfo/homeInfoTracking"
import loggingService from "../../../../services/logging/logging.service"
import { ListImage, ListItem, ListTitle } from "../List"
import { Stack } from "../Stack/Stack"
import { ReactComponent as Dog } from "./Dog.svg"
import styles from "./DogsAllowed.module.scss"

type DogsAllowedProps = {
    unitId: string
    allowed: boolean
}

const DogsAllowed = ({ unitId, allowed }: DogsAllowedProps): JSX.Element => {
    const handleViewPetFriendlyClicked = useCallback(() => {
        loggingService.log({ message: `View listing for unit ${unitId}` })
        trackEditPetFriendlyViewed()
    }, [unitId])
    return (
        <ListItem>
            <ListImage
                aria-hidden
                fallbackIcon={<Dog height={20} />}
                className={styles.icon}
            />
            <Stack flex={1}>
                <ListTitle className={styles.title}>
                    <FormattedMessage
                        id="HomeInfo.dogFriendly.dogsAllowed"
                        defaultMessage="Dogs Allowed"
                    />
                </ListTitle>
                <Pill
                    text={allowed ? "Allowed" : "Not Allowed"}
                    enabledStyle={allowed}
                />
            </Stack>
            <Stack justify="center" align="flex-end">
                <Link
                    to={{
                        pathname: "/home-info/pets-allowed",
                        search: new URLSearchParams({ u: unitId }).toString(),
                    }}
                    onClick={handleViewPetFriendlyClicked}
                    data-testid="dogs-allowed-edit"
                >
                    <SVGIcon
                        svg="icon-feather-edit"
                        className={styles.svgIcon}
                    />
                </Link>
            </Stack>
        </ListItem>
    )
}

export { DogsAllowed }
