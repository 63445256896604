import { JSONAPIResource } from "@vacasa/owner-api-models"
import { AxiosError } from "axios"
import { UseQueryResult, useQuery } from "react-query"
import { useCurrentUnit } from "hooks/units"
import PerformanceMetrics from "@vacasa/owner-api-models/src/PerformanceMetrics"
import { fetchPerformanceMetrics } from "./fetchPerformanceMetrics"

export const usePerformanceMetrics = (
    intervals?: string
): UseQueryResult<JSONAPIResource<PerformanceMetrics>, AxiosError> => {
    const { unitId } = useCurrentUnit()

    return useQuery(
        ["performance-metrics",intervals, unitId],
        ({ signal }) =>
            fetchPerformanceMetrics({
                unitId: unitId ?? undefined,
                intervals,
                signal,
            }),
        {
            enabled: !!unitId,
            refetchOnWindowFocus:false,

        }
    )
}
