import { LANGUAGES } from "Constants"

export const getLanguageFromId = (
    languageId: number | undefined
): string | undefined => {
    if (!!languageId && languageId in LANGUAGES) {
        return LANGUAGES[languageId]?.code
    }
    return undefined
}
