import classNames from "classnames"
import { useStatementYears } from "hooks/finance"
import { useContactId } from "hooks/user"
import { GeneralError } from "lib/components/GeneralError/GeneralError"
import { useToastNotification } from "lib/components/ToastNotification"
import { observer } from "mobx-react"
import {
    FC,
    MouseEvent,
    ReactNode,
    useCallback,
    useEffect,
    useState,
} from "react"
import { FormattedMessage } from "react-intl"
import {
    trackAnnualDataDownloadXLS,
    trackAnnualStatementsErrorPresented,
    trackAnnualStatementsErrorSupportNumberClicked,
    trackAnnualStatementsRetryClicked,
    trackRentalDataViewed,
} from "services/segment/taxes/taxesTracking"
import { Card, CardContent } from "../../components/Card"
import EmptyState from "../../lib/components/EmptyState"
import Loader from "../../lib/components/Loader"
import { useRootService } from "../../services"
import LoggingService from "../../services/logging/logging.service"
import { isMobileBrowser } from "../../utils/browser/browserUtil"
import sharedStyles from "./Taxes.module.scss"
import { GeneralErrorModal } from "lib/components/GeneralError/GeneralErrorModal"

const showFile = (
    filename: string,
    type: string,
    extension: string,
    blob?: Blob
) => {
    if (!blob) {
        return
    }

    if (!type) {
        type = "application/pdf"
        extension = ".pdf"
    }

    const newBlob = new Blob([blob], { type: type })
    const data = window.URL.createObjectURL(newBlob)
    const link = document.createElement("a")
    link.href = data
    link.style.cssText = "display: none"
    if (!isMobileBrowser()) {
        link.target = "_self"
    }
    link.download = filename + extension
    document.body.appendChild(link)
    link.click()

    setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        document.body.removeChild(link)
        window.URL.revokeObjectURL(data)
    }, 100)
}

const AnnualDataDownload = () => {
    const [showError, setShowError] = useState(false)
    const [year, setYear] = useState(0)
    const { earningsService } = useRootService()
    const statementYearsQuery = useStatementYears()
    const contactId = useContactId()
    const { addToastNotification, removeToastNotifcation } =
        useToastNotification()

    useEffect(() => {
        if (!showError) return
        trackAnnualStatementsErrorPresented()
    }, [showError])

    const downloadXLS = useCallback(
        async (year: number) => {
            setYear(year)
            const filename = "year-summary-statement-" + year
            trackAnnualDataDownloadXLS()
            const toastId = addToastNotification({
                autoHide: false,
                closeButton: false,
                content: (
                    <>
                        <FormattedMessage
                            id="Taxes.preparingNotification"
                            defaultMessage="Preparing your data for download"
                        />
                        <Loader
                            className={"primary show spinner-white"}
                            overlay={false}
                            spinnerSize={"spinner-sm"}
                        />
                    </>
                ),
            })

            await earningsService
                // @ts-expect-error contactId is possibly undefined
                // TODO @see {@link https://vacasait.atlassian.net/browse/TFP-1178}
                .getYearlyRentalXLS(contactId, year)
                .then(blob => {
                    showFile(
                        filename,
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                        ".xlsx",
                        blob
                    )
                    return blob
                })
                .catch(err => {
                    setShowError(true)
                })
                .finally(() => removeToastNotifcation(toastId))
        },
        [
            addToastNotification,
            contactId,
            earningsService,
            removeToastNotifcation,
        ]
    )

    const onRetryDownload = () => {
        trackAnnualStatementsRetryClicked()
        setShowError(false)
        downloadXLS(year)
    }

    const onSupportNumberClicked = () => {
        trackAnnualStatementsErrorSupportNumberClicked()
    }

    return (
        <>
            <Card>
                <CardContent>
                    <h3 className="type-heading-medium">
                        <FormattedMessage
                            id="Taxes.download"
                            defaultMessage="Downlaod your rental data"
                        />
                    </h3>
                    {statementYearsQuery.isLoading && (
                        <div className={sharedStyles.loadingContainer}>
                            <Loader />
                        </div>
                    )}
                    {statementYearsQuery.isError && (
                        <div className="container-fluid">
                            <div className="content-section">
                                <GeneralError
                                    handleClick={() =>
                                        statementYearsQuery.refetch()
                                    }
                                />
                            </div>
                        </div>
                    )}

                    {statementYearsQuery.isSuccess && (
                        <RentalData
                            statementYears={statementYearsQuery.data ?? []}
                            onDownloadClick={downloadXLS}
                        />
                    )}
                </CardContent>
            </Card>
            {showError && (
                <GeneralErrorModal
                    handleClose={() => setShowError(false)}
                    handleClick={onRetryDownload}
                    errorHeading="Download Error"
                    errorMessage={
                        <>
                            There was a problem downloading your statement.
                            Please try again, or call Owner Support on{" "}
                            <strong>
                                <a
                                    onClick={onSupportNumberClicked}
                                    href="tel:+19712543001"
                                >
                                    971-254-3001
                                </a>
                            </strong>
                            .
                        </>
                    }
                    buttonText="Retry"
                />
            )}
        </>
    )
}

const RentalData: FC<{
    onDownloadClick: (year: number) => void
    statementYears: number[]
}> = ({ onDownloadClick, statementYears }) => {
    const noDownloadsAvailable = statementYears.length === 0

    const onRowClick = useCallback(
        (e: MouseEvent<HTMLAnchorElement>, year: number) => {
            e.preventDefault()
            LoggingService.log({
                message: `Viewed ${year} rental data spreadsheet.`,
            })
            trackRentalDataViewed(String(year))
            onDownloadClick(year)
        },
        [onDownloadClick]
    )

    const dataRows = statementYears.map((year, i) => {
        const isFinalRow = i === statementYears.length - 1
        return (
            <div
                className={classNames(
                    "accordion__header",
                    "align-flex-horizontal",
                    {
                        "border-bottom": isFinalRow,
                    }
                )}
                key={year}
            >
                {`${year}`}
                <a
                    style={{ marginLeft: "16px" }}
                    href="/#"
                    onClick={e => onRowClick(e, year)}
                >
                    Rental Data (.xls)
                </a>
            </div>
        )
    })

    return (
        <>
            <div className="panel-box__subheader">
                <p>
                    <FormattedMessage
                        id="Taxes.dataAvailability"
                        defaultMessage="Data for each tax year will be available by <b>January 31</b> of the following year. If you share ownership with one or more individuals, you'll each have separate data to download so you have the correct information to report."
                        values={{
                            b: (chunks: ReactNode) => <strong>{chunks}</strong>,
                        }}
                    />
                </p>
            </div>
            {noDownloadsAvailable ?? (
                <EmptyState
                    classes={["panel-empty", "panel-empty--small"]}
                    snippet={
                        <FormattedMessage
                            id="Taxes.noDownloads"
                            defaultMessage="You have no downloads yet."
                        />
                    }
                    fontSizeClass={"type-body-medium"}
                />
            )}
            {!noDownloadsAvailable && dataRows}
            <p className="small" style={{ maxWidth: "100%" }}>
                <FormattedMessage
                    id="Taxes.instructions"
                    defaultMessage=".xls files can be opened with most spreadsheet applications, including Excel, Numbers, and Google Sheets."
                />
            </p>
        </>
    )
}

export default observer(AnnualDataDownload)
