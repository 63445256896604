import { FeatureId } from "contexts/feature-feedback"
import segmentService from "../segment.service"

export type FeatureRating = "thumbs up" | "thumbs down"

export type FeatureAlertId =
    | "nights-occupied-modal"
    | "payment-settings"
    | "market-rate-comparison-chart"
    | "owner-created-comment-alert-dismissed"
    | "core-metrics"

const FEATURE_FEEDBACK = "Feature Feedback"
const FEATURE_ALERT = "Feature Alert"

export const trackFeatureFeedbackDisplayed = (feature: FeatureId) => {
    segmentService.track(FEATURE_FEEDBACK, {
        action: "presentation",
        // eslint-disable-next-line camelcase
        feature_name: feature,
    })
}

export const trackFeatureFeedbackRating = (
    feature: FeatureId,
    featureRating: FeatureRating
) => {
    segmentService.track(FEATURE_FEEDBACK, {
        action: "vote",
        experience: "standard",
        /* eslint-disable camelcase */
        feature_name: feature,
        feature_rating: featureRating,
        /* eslint-enable camelcase */
    })
}

export const trackFeatureFeedbackComment = (
    feature: FeatureId,
    feedback: string
) => {
    segmentService.track(FEATURE_FEEDBACK, {
        action: "comment",
        experience: "standard",
        /* eslint-disable camelcase */
        feature_name: feature,
        feature_feedback: feedback,
        /* eslint-enable camelcase */
    })
}

export const trackFeatureFeedbackDismissed = (feature: FeatureId) => {
    segmentService.track(FEATURE_FEEDBACK, {
        action: "dismissed",
        experience: "standard",
        /* eslint-disable camelcase */
        feature_name: feature,
        /* eslint-enable camelcase */
    })
}

export const trackFeatureAlertDisplayed = (feature: FeatureAlertId) => {
    segmentService.track(FEATURE_ALERT, {
        action: "presentation",
        // eslint-disable-next-line camelcase
        feature_name: feature,
    })
}

export const trackFeatureAlertDismissed = (feature: FeatureAlertId) => {
    segmentService.track(FEATURE_ALERT, {
        action: "dismissed",
        experience: "standard",
        /* eslint-disable camelcase */
        feature_name: feature,
        /* eslint-enable camelcase */
    })
}
