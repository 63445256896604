import { JSONAPIResource, UserPreference } from "@vacasa/owner-api-models"
import { authAxios } from "../../api/authAxios"
const fetchUserPreferencesByUnit = (unitId: string): Promise<
    JSONAPIResource<UserPreference>[]
> =>
    authAxios
        .get<{ data: JSONAPIResource<UserPreference>[] }>(`/v1/user-preferences/units/${unitId}`, {
            params: {
                unitId
            }
        })
        .then(response => response.data.data)

export { fetchUserPreferencesByUnit }
