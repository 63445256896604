import Select from "lib/components/Select"
import { useIntl } from "react-intl"
import { unitToSelectOptions } from "utils/select"
import styles from "./UnitFilter.module.scss"
import { useUnits } from "hooks/units"
import { isActiveUnit } from "utils/unit"

interface UnitFilterProps {
    unitId: string
    updateUnitId: (unitId: string) => void
    updateFilter: (filter: string) => void
}

const UnitFilter: React.FC<UnitFilterProps> = props => {
    const { unitId, updateUnitId, updateFilter } = props
    const intl = useIntl()
    const unitsQuery = useUnits()

    const activeUnits = unitsQuery.data?.filter(isActiveUnit) ?? []

    const activeUnitSection = {
        title: `Active (${activeUnits?.length})`,
        items: unitToSelectOptions(activeUnits),
    }

    if (unitsQuery.isLoading) return null

    return (
        <div className={styles.unitFilter}>
            <Select
                onChange={unitId => {
                    updateUnitId(unitId)
                    updateFilter(
                        intl.formatMessage({
                            id: "Performance.reviews.allRatings",
                            defaultMessage: "All Ratings",
                        })
                    )
                }}
                sections={[activeUnitSection]}
                searchShow
                searchPlaceholder="Search Units"
                showItemImage
                value={unitId ?? ""}
                dropdownEdge="left"
            />
        </div>
    )
}

export { UnitFilter }
