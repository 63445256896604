import { Snackbar } from "@material-ui/core"
import { CSSProperties, FC, ReactNode, useEffect, useState } from "react"
import { X } from "react-feather"
import styles from "./ToastNotification.module.scss"

export type ToastNotificationProps = {
    autoHide?: boolean
    autoHideDuration?: number
    id: string
    closeButton?: boolean
    content: ReactNode
    hide?: boolean
    onClose?: () => void
    styles?: CSSProperties
}

export const ToastNotification: FC<ToastNotificationProps> = props => {
    const [show, setShow] = useState(true)
    const {
        autoHide = true,
        autoHideDuration,
        closeButton = true,
        content,
        hide,
        onClose,
    } = props

    useEffect(() => {
        if (!autoHide) return
        const timeoutId = setTimeout(() => {
            setShow(false)
        }, autoHideDuration ?? 4500)
        return () => {
            clearTimeout(timeoutId)
        }
    }, [autoHide, autoHideDuration])

    useEffect(() => {
        if (hide) {
            setShow(false)
        }
    }, [hide])

    const onCloseClick = () => {
        setShow(false)
        if (onClose) onClose()
    }

    return (
        <Snackbar
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            className={styles.container}
            open={show}
            onClose={onCloseClick}
            message={content}
            autoHideDuration={autoHide ? autoHideDuration ?? 3500 : null}
            action={
                closeButton && (
                    <X className="cursor-pointer" onClick={onCloseClick} />
                )
            }
        />
    )
}
