import classnames from "classnames"
import { useContext, useEffect } from "react"
import { NetRentToggleContext } from "../NetRentToggleContext"
import { Tooltip } from "lib/components/Tooltip/Tooltip"
import { RentTypeToggle } from "./RentTypeToggle"
import { useRevenueChartContext } from "contexts/performance"
import Select from "lib/components/Select"
import { FC } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import SVGIcon from "../../../lib/components/SVGIcon"
import styles from "../Performance.module.scss"
import { ReactComponent as CalendarIcon } from "assets/icon-calendar-new.svg"
import {
    isCurrentOrFutureYearSelected,
    isCurrentYearSelected,
} from "utils/charts/chartsUtil"
import { useUnitStatuses } from "hooks/units"

interface ChartHeaderProps {
    yearlyRevenue: string
    upcomingRevenue: string
    completedRevenue: string
    showRevenueBreakdown?: boolean
    showPastPerformanceTooltip?: boolean
    noNetRentData?: boolean
}

const ChartHeader: FC<ChartHeaderProps> = ({
    yearlyRevenue,
    upcomingRevenue,
    completedRevenue,
    showPastPerformanceTooltip,
    showRevenueBreakdown,
    noNetRentData,
}): JSX.Element => {
    const intl = useIntl()

    const { minYear, maxYear, year, setYear } = useRevenueChartContext()
    const { isFixedRent } = useUnitStatuses()

    const showNetRentToggle = !isFixedRent && !noNetRentData
    const { netRentToggled, toggleVisible, setToggleVisible } =
        useContext(NetRentToggleContext)

    useEffect(() => {
        setToggleVisible(!!showNetRentToggle)
    }, [setToggleVisible, showNetRentToggle])

    const secondaryDescriptionFlag =
        netRentToggled && toggleVisible && showPastPerformanceTooltip

    const grossDescCurrentYear = (
        <FormattedMessage
            id="Performance.rentChart.descriptionCurrentYear"
            defaultMessage="Total <strong>gross income</strong> from your bookings this year"
            values={{
                strong: chunks => <strong>{chunks}</strong>,
            }}
        />
    )
    const grossDescPreviousYear = (
        <FormattedMessage
            id="Performance.rentChart.descriptionPreviousYear"
            defaultMessage="Total <strong>gross income</strong> from your bookings"
            values={{
                strong: chunks => <strong>{chunks}</strong>,
            }}
        />
    )
    const netDescCurrentYear = (
        <FormattedMessage
            id="Performance.rentChart.descriptionNetCurrentAndFutureYear"
            defaultMessage="Total estimated <strong>net income</strong> from your bookings this year. Taxes and fees may change."
            values={{
                strong: chunks => <strong>{chunks}</strong>,
            }}
        />
    )
    const netDescPreviousYear = (
        <FormattedMessage
            id="Performance.rentChart.descriptionNetPreviousYear"
            defaultMessage="Total <strong>net income</strong> from your bookings"
            values={{
                strong: chunks => <strong>{chunks}</strong>,
            }}
        />
    )

    const isShowingNetRent = netRentToggled && toggleVisible

    return (
        <div className={styles.header}>
            <div className={styles["header__col"]}>
                {showNetRentToggle ? (
                    <RentTypeToggle />
                ) : (
                    <header className={styles["chart-header__title"]}>
                        {intl.formatMessage({
                            id: "Performance.chartTitle",
                            defaultMessage: "YTD Gross Rent",
                        })}
                    </header>
                )}
                <h2 className={classnames(styles["chart-header__revenue"])}>
                    {yearlyRevenue}
                </h2>
                <div
                    className={classnames(
                        "type-body-small",
                        styles["chart-header__description"]
                    )}
                >
                    {/* descriptionRow is flex row for alignment with tooltip */}
                    <span className={styles["chart-header__descriptionRow"]}>
                        {/* Wrap message with display block to preserve spacing */}
                        <span
                            className={
                                styles["chart-header__descriptionMessageWrap"]
                            }
                        >
                            {/** Net Rent Description Text */}
                            {isShowingNetRent &&
                                (isCurrentOrFutureYearSelected(year)
                                    ? netDescCurrentYear
                                    : netDescPreviousYear)}

                            {/** Gross Rent Description Text */}
                            {!isShowingNetRent &&
                                (isCurrentYearSelected(year)
                                    ? grossDescCurrentYear
                                    : grossDescPreviousYear)}
                        </span>
                        {showPastPerformanceTooltip && (
                            <span
                                className={
                                    styles["chart-header__title-tooltip-wrap"]
                                }
                            >
                                <Tooltip
                                    title={intl.formatMessage({
                                        id: "Performance.rentChart.tooltip.title",
                                        defaultMessage: "Previous PM Data",
                                    })}
                                    body={intl.formatMessage({
                                        id: "Performance.rentChart.tooltip.body",
                                        defaultMessage:
                                            "Inherited revenue data was provided by your previous property management company.",
                                    })}
                                >
                                    <SVGIcon
                                        svg="icon-feather-info"
                                        colorName="gray-lightest"
                                        className="zero-margin cursor-pointer"
                                    />
                                </Tooltip>
                            </span>
                        )}
                    </span>
                    {secondaryDescriptionFlag && (
                        <FormattedMessage
                            id="Performance.rentChart.secondaryDescriptionNet"
                            defaultMessage="<i>Note</i>: Net Rent data is only available for reservations booked with Vacasa."
                            values={{
                                i: chunks => <i>{chunks}</i>,
                            }}
                        >
                            {text => (
                                <span
                                    className={
                                        styles[
                                            "chart-header__secondaryDescription"
                                        ]
                                    }
                                >
                                    {text}
                                </span>
                            )}
                        </FormattedMessage>
                    )}
                </div>
            </div>
            <div className={styles["header__col"]}>
                {showRevenueBreakdown && isCurrentYearSelected(year) && (
                    <div
                        className={classnames(
                            styles["legend"],
                            styles["chart-header__legend"]
                        )}
                    >
                        <div
                            className={
                                styles["chart-header__revenue-breakdown-item"]
                            }
                        >
                            Completed:
                            <span>{completedRevenue}</span>
                        </div>
                        <div
                            className={
                                styles["chart-header__revenue-breakdown-item"]
                            }
                        >
                            Upcoming:
                            <span>{upcomingRevenue}</span>
                        </div>
                    </div>
                )}
                <div className={styles["chart-year"]}>
                    {minYear !== maxYear ? (
                        <Select
                            icon={<CalendarIcon />}
                            onChange={value => setYear(Number(value))}
                            sections={[
                                {
                                    title: "Select a Year",
                                    items: Array(maxYear - minYear + 1)
                                        .fill(0)
                                        .map((_, idx) => ({
                                            text: (minYear + idx).toString(),
                                            value: (minYear + idx).toString(),
                                        }))
                                        .reverse(),
                                },
                            ]}
                            value={year.toString()}
                            width={130}
                        />
                    ) : (
                        <div className={styles["chart-year__label"]}>
                            <CalendarIcon />
                            {year}
                        </div>
                    )}

                    {!isCurrentYearSelected(year) && (
                        <button
                            type="button"
                            className={styles["chart-year__clear-btn"]}
                            onClick={() => setYear(new Date().getFullYear())}
                        >
                            Clear Selection
                        </button>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ChartHeader
