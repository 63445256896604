import { observer } from "mobx-react"
import { FC } from "react"
import { Link, useLocation } from "react-router-dom"
import { clearOwnerCache } from "utils/user"
import { useIsEmployee } from "hooks/user"
import { useLoginInfo } from "contexts/login"

export const EmployeeBox: FC = observer(() => {
    const isEmployee = useIsEmployee()
    const location = useLocation()
    const { setContactIds } = useLoginInfo()
    const backToSearch = () => {
        setContactIds([])
        clearOwnerCache()
    }

    if (!isEmployee) return null

    if (location.pathname.indexOf("/search") > -1) return null

    return (
        <div className="employee-widget">
            <Link onClick={backToSearch} to="/search">
                Search Owners
            </Link>
        </div>
    )
})

export default EmployeeBox
