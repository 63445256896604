import React, { useEffect, useState } from "react"
import classnames from "classnames"
import deepEqual from "deep-equal"
import { Amenities } from "@vacasa/owner-api-models"

import Panel from "../OnboardingPanel"
import SubmitButtonRow, {
    ButtonState,
} from "../../../lib/components/Onboarding/SubmitButtonRow"
import ConfirmButtonRow from "../../../lib/components/Onboarding/ConfirmButtonRow"
import RowWrapper, {
    RowType,
} from "../../../lib/components/Onboarding/RowWrapper"
import ErrorComponent from "../ErrorComponent"
import { Generic as DropdownOption } from "../../../models/Generic"

import { ReactComponent as KitchenSVG } from "../../../assets/icon-kitchen.svg"
import styles from "../../../sass/onboarding.module.scss"
import { FormattedMessage, useIntl } from "react-intl"
import { trackUpdateKitchen } from "services/segment/onboarding/onboardingTracking"

interface KitchenSectionProps {
    apiModel: Amenities
    confirmedKitchen: boolean
    getOptionByValue: (
        options: DropdownOption[],
        value?: number | null
    ) => DropdownOption | null
    patchPreference: (preferenceName: string) => Promise<void>
    patchUnit: (data: any) => Promise<void> // eslint-disable-line @typescript-eslint/no-explicit-any
    saveKitchenHandler: (enabled: boolean) => void
    unitId: string
}

const KitchenSection: React.FC<KitchenSectionProps> = ({
    apiModel,
    confirmedKitchen,
    getOptionByValue,
    patchPreference,
    patchUnit,
    saveKitchenHandler,
    unitId,
}: KitchenSectionProps): JSX.Element => {
    const [errorSavingKitchen, setErrorSavingKitchen] = useState(false)
    const [saveKitchenEnabled, setSaveKitchenEnabled] = useState(
        ButtonState.Disabled
    )
    const apiKitchen = apiModel.rooms.kitchen
    const [tempKitchen, setTempKitchen] = useState(apiKitchen)
    const intl = useIntl()

    // TODO seperate value from intl strings, this causes issues when changing languages
    const kitchenTypes: DropdownOption[] = [
        {
            name: intl.formatMessage({
                id: "Onboarind.kitchenTypes.noKitchen",
                defaultMessage: "No kitchen",
            }),
            id: 0,
        },
        {
            name: intl.formatMessage({
                id: "Onboarind.kitchenTypes.kitchenette",
                defaultMessage: "Kitchenette",
            }),
            id: 1,
        },
        {
            name: intl.formatMessage({
                id: "Onboarind.kitchenTypes.full",
                defaultMessage: "Full kitchen",
            }),
            id: 2,
        },
        {
            name: intl.formatMessage({
                id: "Onboarind.kitchenTypes.gourmet",
                defaultMessage: "Gourmet kitchen",
            }),
            id: 3,
        },
    ]

    useEffect(() => {
        setSaveKitchenEnabled(
            !deepEqual(tempKitchen, apiKitchen)
                ? ButtonState.Enabled
                : ButtonState.Disabled
        )
    }, [apiKitchen, tempKitchen])

    useEffect(() => {
        saveKitchenHandler(!deepEqual(tempKitchen, apiKitchen))
    }, [apiKitchen, saveKitchenHandler, tempKitchen])

    const hasData = tempKitchen !== undefined
    const noChanges = saveKitchenEnabled === ButtonState.Disabled
    const shouldShowConfirmButton = hasData && noChanges && !confirmedKitchen

    function resetKitchen(): void {
        setSaveKitchenEnabled(ButtonState.Disabled)
        setTempKitchen(apiKitchen)
    }

    return (
        <div>
            <h5>
                <FormattedMessage
                    id="Onboarding.sectionTitles.kitchen"
                    defaultMessage="Kitchen"
                />
            </h5>
            <p className={styles.section_description}>
                <FormattedMessage
                    id="Onboarding.sectionDescriptions.kitchen"
                    defaultMessage="Does your home have a kitchen? Let your guests know below."
                />
            </p>
            <div className={styles.user_selection_group}>
                <div
                    className={classnames([
                        styles.user_selection,
                        hasData ? "" : styles.not_set,
                    ])}
                >
                    <KitchenSVG className={styles.amenity_icon} />
                    {hasData ? (
                        `${getOptionByValue(kitchenTypes, tempKitchen)?.name}`
                    ) : (
                        <FormattedMessage
                            id="Onboarding.notSelected"
                            defaultMessage="Not selected"
                        />
                    )}
                </div>
            </div>

            {errorSavingKitchen ? (
                <ErrorComponent
                    onClick={(): void => setErrorSavingKitchen(false)}
                />
            ) : (
                <Panel className={styles.panel_content}>
                    <RowWrapper
                        type={RowType.Drowdown}
                        title={
                            <FormattedMessage
                                id="Onboarding.rooms.kitchen"
                                defaultMessage="Kitchen"
                            />
                        }
                        options={kitchenTypes}
                        selectedValue={getOptionByValue(
                            kitchenTypes,
                            tempKitchen
                        )}
                        onChangeHandler={(
                            value: DropdownOption | null | undefined
                        ): void => setTempKitchen(value?.id)}
                        lastRow={true}
                    />
                    {shouldShowConfirmButton ? (
                        <ConfirmButtonRow
                            buttonState={ButtonState.Enabled}
                            onSubmit={(): void => {
                                patchPreference("onboarding-confirmed-kitchen")
                            }}
                        />
                    ) : (
                        <SubmitButtonRow
                            buttonState={saveKitchenEnabled}
                            onSubmit={(): void => {
                                setSaveKitchenEnabled(ButtonState.Loading)
                                Promise.all([
                                    patchUnit({
                                        rooms: {
                                            ...apiModel.rooms,
                                            kitchen: tempKitchen,
                                        },
                                    }),
                                    patchPreference(
                                        "onboarding-confirmed-kitchen"
                                    ),
                                ])
                                    .then(() => {
                                        return trackUpdateKitchen(
                                            kitchenTypes,
                                            unitId,
                                            tempKitchen,
                                            apiKitchen
                                        )
                                    })
                                    .catch(() => {
                                        setErrorSavingKitchen(true)
                                        // reset tempModel to the original value as patch failed
                                        resetKitchen()
                                    })
                            }}
                            onCancel={(): void => resetKitchen()}
                        />
                    )}
                </Panel>
            )}
        </div>
    )
}

export default KitchenSection
