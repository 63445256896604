import TagManager from "react-gtm-module"
import { GTM_ID } from "./constants"

export const gtmInitialize = () => {
    const gtmProps = {
        gtmId: GTM_ID,
    }
    TagManager.initialize(gtmProps)
}

export const gtmPageView = (page: string, userId?: string) => {
    const tagManagerArgs = {
        gtmId: GTM_ID,
        dataLayer: {
            userId,
            page,
        },
        dataLayerName: "PageDataLayer",
    }
    TagManager.dataLayer(tagManagerArgs)
}
