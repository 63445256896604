import { ArrowBack } from "@material-ui/icons"
import classnames from "classnames"
import useRouter from "hooks/common/useRouter"
import { FC, PropsWithChildren, useCallback, ButtonHTMLAttributes } from "react"
import styles from "./Layout.module.scss"

type Props = PropsWithChildren & {
    className?: string
    reservationId?: string
    showBackButton?: boolean
    customOnBackClick?: () => void
}
type ContentProps = PropsWithChildren<{
    form?: boolean
    overlayBlocker?: boolean
    reservationForm?: boolean
}> &
    Props

export const FooterSecondaryButton: FC<
    ButtonHTMLAttributes<HTMLButtonElement>
> = ({ className, disabled, onClick, ...rest }) => (
    <button
        className={classnames(
            className,
            styles["footer-secondary-btn"],
            "font-extrabold",
            "cursor-pointer",
            "text-sm"
        )}
        disabled={disabled}
        onClick={onClick}
        {...rest}
    />
)

const Content: FC<ContentProps> = ({
    children,
    className,
    form,
    overlayBlocker,
    reservationForm,
}) => (
    <div
        className={classnames(
            "reservation-flyout-layout__content",
            styles["reservation-flyout-layout__content"],
            className,
            {
                [styles["reservation-flyout-layout__content--form"] as string]:
                    form,
                [styles[
                    "reservation-flyout-layout__content--reservation-form"
                ] as string]: reservationForm,
            }
        )}
    >
        {children}
        {overlayBlocker && (
            <div className="generic-blocking-overlay absolute" />
        )}
    </div>
)
const Footer: FC<Props & { noShadow?: boolean }> = ({
    children,
    className,
    noShadow,
}) => (
    <div
        className={classnames(
            "reservation-flyout-layout__footer",
            styles["reservation-flyout-layout__footer"],
            className,
            {
                [styles[
                    "reservation-flyout-layout__footer--no-shadow"
                ] as string]: noShadow,
            }
        )}
    >
        {children}
    </div>
)

const Layout: FC<Props> & {
    Content: FC<ContentProps>
    Footer: FC<Props & { noShadow?: boolean }>
} = ({
    children,
    className,
    reservationId,
    showBackButton,
    customOnBackClick,
    ...rest
}) => {
    const router = useRouter()

    const backToReservation = useCallback(() => {
        router.navigate(
            {
                pathname: `/calendar/hold/${reservationId}`,
                search: router.location.search,
                hash: router.location.hash,
            },
            { replace: true }
        )
    }, [router, reservationId])

    return (
        <div
            className={classnames(
                "reservation-flyout-layout",
                styles["reservation-flyout-layout"],
                className
            )}
            {...rest}
        >
            {showBackButton && (reservationId || customOnBackClick) && (
                <button
                    className={classnames(
                        "cursor-pointer",
                        styles["reservation-flyout-layout__back-btn"]
                    )}
                >
                    <ArrowBack
                        onClick={customOnBackClick ?? backToReservation}
                    />
                </button>
            )}
            {children}
        </div>
    )
}

Layout.Content = Content
Layout.Footer = Footer

export default Layout
