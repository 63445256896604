import { Avatar } from "@material-ui/core"
import classNames from "classnames"
import React, { PropsWithChildren, forwardRef, useCallback } from "react"
import { NavLink } from "react-router-dom"
import { useMobileNavigation } from "."
import SVGIcon from "../../lib/components/SVGIcon"

interface NavItemProps {
    to: string
    icon: string
    label: React.ReactNode
    notificationCount?: number
    show?: boolean
    divider?: boolean
    testid?: string
}
const NavItem = forwardRef<HTMLLIElement, PropsWithChildren<NavItemProps>>(
    (
        {
            label,
            to,
            icon,
            notificationCount,
            show = true,
            divider = false,
            testid,
            children,
        },
        ref
    ) => {
        const mobileNavigation = useMobileNavigation()

        const handleClick = useCallback(() => {
            mobileNavigation.setOpen(false)
        }, [mobileNavigation])

        if (!show) {
            return null
        }
        return (
            <li data-testid={testid} ref={ref}>
                <NavLink
                    to={to}
                    className={({ isActive }) =>
                        classNames("type-body-small label", {
                            active: isActive,
                        })
                    }
                    onClick={handleClick}
                >
                    <SVGIcon
                        svg={icon}
                        className="w-100"
                        colorName="midnight"
                    />
                    {label}
                    {typeof notificationCount === "number" &&
                    notificationCount > 0 ? (
                        <Avatar
                            className="notification__badge"
                            variant="circular"
                            data-testid={testid + "-badge"}
                        >
                            <span>{notificationCount}</span>
                        </Avatar>
                    ) : null}
                </NavLink>
                {divider ? <hr /> : null}
                {children}
            </li>
        )
    }
)

export default NavItem
