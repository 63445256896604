const statements = {
    faqAriaLabel: "View FAQ",
    title: "Abrechnungen",
    emptyStateCTA: "Weitere Informationen zu Abrechnungen",
    emptyStateEarnings:
        "Neue Abrechnungen stehen jeweils am 10. jedes Monats zur Verfügung.",

    pdf: "PDF",
    pdfAriaLabel: "PDF ansehen",
    pdfNotification: "PDF wird zum Download vorbereitet",
    fileName: "Abrechnung",

    print: "Drucken",
    printAriaLabel: "Druckansicht",

    paymentSummary: "Zahlungsübersicht: Alle Ferienimmobilien",
    beginningBalance: "Anfangssaldo",
    payment: "Zahlung",
    payments: "Zahlungen",
    proceeds: "Einnahmen",
    proceedsBalance: "Einnahmen-Saldo",
    coOwnerProceeds: "Einnahmen der Miteigentümer",
    endingBalance: "Endsaldo",
    details: "Details",
    summary: "Übersicht",
    nightsOccupied: "Nächte belegt",
    occupancy: "Auslastung",
    reserveBalance: "Höhe der Rückstellung",

    beginningReserveBalance: "Höhe der Rückstellung am Anfang",
    EndingReserveBalance: "Höhe der Rückstellung am Ende",
    amountHeld: "Vorhandener Betrag",
    amountUsed: "Verwendeter Betrag",

    netRent: "Nettomiete",
    expense: "Kosten",
    expenses: "Kosten",

    month: "Monat",
    nights: "Nächte",
    grossRent: "Bruttomiete",
    mgmtFee: "Management-Gebühr",
    total: "Gesamt",
    credits: "Credits",

    checkNumber: "Scheck-Nr.",
    paymentDescription: "Zahlung an Konto mit den Endnummern",
    statementYearSummary: "{year} Year Summary",

    canadaSalesTaxesHeader: "Taxes - Expenses & Management Fees",

    tooltips: {
        proceeds: "Amount you'll be paid for this month",
        occupancy:
            "The percentage of available nights this month that you rented to guests (does not include your personal use dates and complimentary stays)",
        nightsOccupied:
            "How many nights this month your home hosted guests (only counts reservations with check-out dates within the month)",
    },
    paymentSettings: "Payment Settings",
    paymentSettingsSmall: "Settings",
    paymentSettingsNotification:
        "Manage your bank details and payment settings here.",
    paymentSettingsNotificationTitle: "Payment Settings",
    checkItOut: "Check it Out",
    settings: "Settings",
    backToTop: "Back to Top",
    needHelp: "Need Help?",
    faqAbbreviation: "FAQ",
    errorDownloading: "Error downloading",
    pdfDownloadError:
        "We were unable to create a PDF of your statement. Please try again later.",
    faq: {
        whereDoISendPayments: {
            answer: {
                canada: "If your home is in Canada",
                allOthers: "All others",
            },
        },
    },
    netRentNoteBegin: "NOTE: ",
    netRentNoteContent:
        "The entire stay spans across {reservationMonthSpan} months ({reservationPeriod}). Monthly revenue will appear on each month’s respective statement(s).",
}

export default statements
