import React, {FC, useState} from "react"
import {FormattedMessage, useIntl} from "react-intl";
import * as Button from "../../lib/components/Buttons/BaseButton";
import styles from "./ReopenInput.module.scss"
import {CommentInput} from "./CommentInput";
import {Secondary, SIZES} from "../../lib/components/Buttons/BaseButton";
import {useReopenTicketMutation} from "../../hooks/ticket";
import {isUnauthorizedOrCancelledRequest} from "../error/error";
import LoggingService from "../../services/logging/logging.service";
import errorService from "../../services/error.service";
interface ReopenInputProps {
    ticketId:number,
    unitId:string,
}


export const ReopenInput: FC<ReopenInputProps> = (props:ReopenInputProps) => {
    const {ticketId,unitId} = props

    const intl = useIntl()
    const [isReopenActive, setIsReopenActive] = useState(false)


    const reopenTicketMutation = useReopenTicketMutation()

    const handleReopenTicket = (commentText:string,resetOnSuccess: ()=>void)=>{
        reopenTicketMutation.mutate(
            {
                unitId,
                ticketId,
                reopenTicketRequest:{
                    comment:commentText,
                }
            },
            {
                onSuccess: ()=>{
                    resetOnSuccess()
                },
                onError: error => {
                    if (!isUnauthorizedOrCancelledRequest(error)) {
                        LoggingService.error({
                            message: "Reopen ticket failed",
                            error,
                            tags: {
                                unitID: `${unitId}`,
                                ticketId: `${ticketId}`,
                            },
                        })
                    }
                    errorService.setErrorMsg(
                        intl.formatMessage({
                            id: "Maintenance.comments.failedReopenTicketError",
                            defaultMessage:
                                "We were unable to reopen this ticket. Please try again later.",
                        })
                    )
                    errorService.displayError()
                },
            }
        )
    }

    return (
        <div className={`${styles.reopenInputContainer}`}>
            {!isReopenActive ?


            <div className={styles.reopenMessageContainer}>

                <div>
                    <div className={`${styles.title}  type-heading-small`}>
                        <FormattedMessage
                            id="Maintenance.resolvedRequestTitle"
                            defaultMessage="Marked as resolved."
                        />

                    </div>
                    <div className={`${styles.message} type-body-small`}>
                        <FormattedMessage
                            id="Maintenance.resolvedRequestText"
                            defaultMessage="if you would like additional assistance regarding this request, please reopen."
                        />
                    </div>
                </div>

                <div className={styles.reopenButtonContainer} >
                    <Button.Utility
                        onClick={ ()=> setIsReopenActive(true)}
                        ariaLabel={intl.formatMessage({
                            id: "Maintenance.ariaLabel",
                            defaultMessage:
                                "Click here to reopen a maintenance ticket",
                        })}
                        className={ `flex-horizontal-right display-inline ${styles.reopenButton}`}
                    >
                        <p
                            className={`zero-margin zero-padding ${styles.text} `}
                            data-testid="reopen-request-text"
                        >
                            <FormattedMessage
                                id="Maintenance.reopenRequest"
                                defaultMessage="Reopen Request"
                            />
                        </p>

                    </Button.Utility>

                </div>
            </div>
                    :

            <div className={styles.reopenCommentInputContainer}>
                <div className={styles.commentInput} >


                    <CommentInput
                        handleCommentSubmitted={handleReopenTicket}
                        isLoadingComment={reopenTicketMutation.isLoading}
                        isReopenMode={true}
                    />

                </div>
                <div className={`${styles.resetMessage}`}>

                    <Secondary
                       typeOf={'link'}
                        onClick={ () => setIsReopenActive(false)}
                        buttonSize={SIZES.SMALL}
                        ariaLabel={intl.formatMessage({
                            id:"Maintenance.nevermindTitle",
                            defaultMessage:"Nevermind, it’s resolved."
                        })}
                    >

                            <FormattedMessage
                                id="Maintenance.nevermindTitle"
                                defaultMessage="Nevermind, it’s resolved."

                            />

                    </Secondary>
                </div>

            </div>
            }

        </div>
    )

}