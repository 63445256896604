const maintenance = {
    title: "Entretien",
    supportHub: "Support",
    newRequest: "Nouvelle demande",
    open: "Ouverte",
    closed: "Fermée",
    requestNumber: "Demande n°",
    status: "État",
    inProgress: "En cours",
    rental: "Location",
    allRentals: "Toutes les locations",
    search: "chercher",
    submitted: "Transmis",
    updated: "Actualisé",

    selectRental: "Sélectionner une location",
    newMaintenanceRequest: "New Maintenance Request",
    needSomeHelp: "Need some help?",
    categorySubtitle:
        "Let us know how we can support you by selecting an option below:",
    requestTitle: "Maintenance request title",
    titlePlaceholder: "Clogged drain",
    describeYourRequest: "Describe your request",
    requestDetails: "Describe your request in detail",
    note: "Note",
    notes: "Notes",
    detailsPlaceholder:
        "We noticed a big drainage issue with the primary kitchen sink. It's a super slow drain or it doesn't even drain at all. Please help!",
    clear: "Clear",
    submitRequest: "Déposer la demande",
    areYouSure: "Are you sure?",
    leavingAlertText:
        "Leaving the submission form will cause you to lose any data you may have entered.",
    continueWithSubmission: "Continue with Submission",
    cancelRequest: "Cancel Request",
    requestSubmitted: "Request submitted",
    requestSaved: "Demande enregistrée",

    submitNewRequest: "Déposer une nouvelle demande",
    noOpenRequests: "No Open Requests",
    newRequestWill: "New requests will appear here until they are addressed.",
    noClosedRequests: "No Closed Requests",
    closedRequestsWill:
        "Once an open request is addressed, it will be closed out and will appear here.",

    ariaLabel: "Cliquez ici pour déposer une demande d'entretien",

    needHelp: "Besoin d'aide ?",
    contactSentenceIfQuestions:
        "Veuillez vous adresser à votre contact {name} pour toute question ou mise à jour de votre demande.",
    or: "ou",

    failedFetchError: `Une anomalie a empêché le chargement de vos demandes d'entretien. Si le problème persiste, veuillez réessayer ultérieurement.`,
    failedCreateError: `Nous n'avons pas pu enregistrer votre demande d'entretien. Veuillez vérifier votre sélection et réessayer.`,
    reload: `Recommencer`,
    emptyState:
        "Need help with home maintenance? You’ll be able to submit requests here.",

    submittedOn: "Submitted on {date}",
    closedOn: "Closed on {date}",
    cancelled: "Cancelled",
    comments: {
        comments: "Comments",
        empty: "When Vacasa responds to this request, their comments will appear here.",
        overCharacterLengthLimit:
            "The comment should not be over 1000 characters.",
        failedCreateCommentError:
            "We were unable to save your comment. Please try again later.",
    },
    featureAlertTitle: "NEW! Two-way Ticket Comments",
    featureAlertBody:
        "Add a comment to your ticket to provide more information or follow up with a question, which will streamline communication about a specific ticket.",
}

export default maintenance
