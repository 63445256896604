import {
    BookingProbability,
    JSONAPIDocument,
    JSONAPIResource,
} from "@vacasa/owner-api-models"
import { formatISO } from "date-fns"
import { authAxios } from "../../api/authAxios"

type FetchBookingProbabilityRequest = {
    unitId: string
    startDate: Date
    endDate: Date
    signal?: AbortSignal
}

const fetchBookingProbability = ({
    unitId,
    startDate,
    endDate,
    signal,
}: FetchBookingProbabilityRequest): Promise<
    JSONAPIResource<BookingProbability>
> =>
    authAxios
        .get<JSONAPIDocument<BookingProbability>>(
            `/v1/owners/units/${unitId}/booking-probability`,
            {
                params: {
                    startDate: formatISO(startDate, { representation: "date" }),
                    endDate: formatISO(endDate, { representation: "date" }),
                },
                signal,
            }
        )
        .then(response => response.data.data)

export { fetchBookingProbability }
