import { JSONAPIResource } from "@vacasa/owner-api-models"
import { Reservation as LegacyReservation } from "@vacasa/owner-api-models/src/legacy"
import { AxiosError } from "axios"
import { useInvalidateAvailability } from "hooks/availibility/useAvailability"
import { useInvalidateMonthOccupancy } from "hooks/month-occupancy/useMonthOccupancy"
import { useInvalidateReservation } from "hooks/reservation/useReservation"
import { useInvalidateReservations } from "hooks/reservations/useReservations"
import { useOwnerFeatureFlag } from "../owner-feature-flag"
import { useMutation } from "react-query"
import { useRootService } from "services"
import { deleteOwnerHold } from "./deleteOwnerHold"
import { postOwnerHold } from "./postOwnerHold"
import { putOwnerHold } from "./putOwnerHold"
import {
    CreateOwnerHoldRequest,
    LegacyErrorResponse,
    RemoveOwnerHoldRequest,
    UpdateOwnerHoldRequest,
} from "./types"

export const useCreateOwnerHoldMutation = () => {
    const invalidateReservation = useInvalidateReservation()
    const invalidateAvailability = useInvalidateAvailability()
    const invalidateReservations = useInvalidateReservations()
    const invalidateMonthOccupancy = useInvalidateMonthOccupancy()
    const { calendarService } = useRootService()
    const ownerHoldsV3 = useOwnerFeatureFlag("owner-portal-add-phone-number-in-owner-hold-form")

    return useMutation<
        JSONAPIResource<LegacyReservation>,
        AxiosError<LegacyErrorResponse>,
        { data: CreateOwnerHoldRequest; signal?: AbortSignal }
    >(payload => postOwnerHold(payload, ownerHoldsV3.isEnabled ? "v3" : undefined), {
        onSuccess: data => {
            calendarService.clearDates()
            invalidateAvailability()
            invalidateReservations()
            invalidateMonthOccupancy()
            invalidateReservation(String(data.id))
        },
    })
}

export const useDeleteOwnerHoldMutation = () => {
    const invalidateReservation = useInvalidateReservation()
    const invalidateAvailability = useInvalidateAvailability()
    const invalidateReservations = useInvalidateReservations()
    const invalidateMonthOccupancy = useInvalidateMonthOccupancy()
    const { calendarService } = useRootService()

    return useMutation<
        JSONAPIResource<LegacyReservation>,
        AxiosError<LegacyErrorResponse>,
        {
            contactId: string
            data: RemoveOwnerHoldRequest
            signal?: AbortSignal
            unitId: string
        }
    >(payload => deleteOwnerHold(payload), {
        onSuccess: data => {
            calendarService.clearDates()
            invalidateAvailability()
            invalidateReservations()
            invalidateMonthOccupancy()
            invalidateReservation(String(data.id))
        },
    })
}

export const useUpdateOwnerHoldMutation = () => {
    const invalidateReservation = useInvalidateReservation()
    const invalidateAvailability = useInvalidateAvailability()
    const invalidateReservations = useInvalidateReservations()
    const invalidateMonthOccupancy = useInvalidateMonthOccupancy()
    const { calendarService } = useRootService()
    const ownerHoldsV3 = useOwnerFeatureFlag("owner-portal-add-phone-number-in-owner-hold-form")

    return useMutation<
        JSONAPIResource<LegacyReservation>,
        AxiosError<LegacyErrorResponse>,
        {
            contactId: string
            data: UpdateOwnerHoldRequest
            unitId: string
        }
    >(payload => putOwnerHold(payload, ownerHoldsV3.isEnabled ? "v3" : undefined), {
        onSuccess: data => {
            calendarService.clearDates()
            invalidateAvailability()
            invalidateReservations()
            invalidateMonthOccupancy()
            invalidateReservation(String(data.id))
        },
    })
}
