import classnames from "classnames"
import { Input } from "lib/components/Input/Input"
import { capitalize, isEmpty } from "lodash"
import React, {
    ChangeEvent,
    FormEvent,
    memo,
    useCallback,
    useEffect,
    useState,
} from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { validateEmail } from "../../../../utils/calendar/util"
import styles from "./ReservationGuestForm.module.scss"
import * as Button from "lib/components/Buttons/BaseButton"
import { GuestInfo } from "../ReservationForm/ReservationSummary"
import AddGuestSummary from "./AddGuestSummary"
import { Secondary } from "lib/components/Buttons/BaseButton"
import { RemoveGuestButton } from "./EditGuestView"
import {
    trackCreateHoldCancelButtonClicked,
    trackCreateHoldSaveButtonClicked,
} from "services/segment/ownerHold/ownerHoldTracking"

export interface InviteGuestFormState {
    isValid: boolean
    isDirty: boolean
    data: {
        firstName: string
        lastName: string
        email: string
    }
}

export interface OccupancyInfo {
    adults: number
    children: number
    pets: number
}

const checkFieldsAreDirty = (
    firstName: { old: string; new: string },
    lastName: { old: string; new: string },
    email: { old: string; new: string }
) => {
    return (
        firstName.old !== firstName.new ||
        lastName.old !== lastName.new ||
        email.old !== email.new
    )
}

const isFormValid = (firstName: string, lastName: string, email: string) => {
    const emailIsValid = !email || validateEmail(email)
    const fieldsPopulated =
        !isEmpty(firstName) && !isEmpty(lastName) && !isEmpty(email)
    return fieldsPopulated && emailIsValid
}

export const useInviteGuestForm = () => {
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        switch (name) {
            case "firstName":
                setFirstName(value)
                break
            case "lastName":
                setLastName(value)
                break
            case "email":
                setEmail(value)
                break
        }
    }

    const populateForm = useCallback(
        (firstName: string, lastName: string, email: string) => {
            setFirstName(firstName)
            setLastName(lastName)
            setEmail(email)
        },
        []
    )

    const isDirty = (data?: {
        firstName: string
        lastName: string
        email: string
    }) => {
        if (!data) return true

        return checkFieldsAreDirty(
            {
                old: data.firstName,
                new: firstName,
            },
            {
                old: data.lastName,
                new: lastName,
            },
            {
                old: data.email,
                new: email,
            }
        )
    }

    const isValid = useCallback(
        () => isFormValid(firstName, lastName, email),
        [email, firstName, lastName]
    )

    return {
        firstName,
        lastName,
        email,
        isDirty,
        isValid,
        onChange,
        populateForm,
    }
}

interface InviteGuestProps {
    handleShowForm: (shouldShowForm: boolean) => void
    handleShowSkipButton: (shouldShowSkipButton: boolean) => void
    inCreateHoldFlow: boolean
    occupancyInfo: OccupancyInfo
    guests: GuestInfo[]
    updateGuests: (guests: GuestInfo[]) => void
    removeGuest: (guest: GuestInfo) => void
    isValid: () => boolean
    populateForm: (firstName: string, lastName: string, email: string) => void
    handleNextDisabled: (nextDisabled: boolean) => void
    showEditGuestView: boolean
    handleShowEditGuestView: (shouldShowEditGuestView: boolean) => void
    editedGuest?: GuestInfo
    handleEditedGuest: (updatedGuest: GuestInfo) => void
}

interface InviteGuestFormProps {
    formId?: string
    formData: { firstName: string; lastName: string; email: string }
    onSubmit?: (e: FormEvent<HTMLFormElement>) => void
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void
    showForm: boolean
    inviteGuestProps?: InviteGuestProps
}

const InviteGuestForm: React.FC<InviteGuestFormProps> = memo(
    ({
        formId,
        formData,
        onSubmit,
        onChange,
        showForm,
        inviteGuestProps,
    }: InviteGuestFormProps): JSX.Element => {
        const intl = useIntl()
        const [emailValidationError, setEmailValidationError] = useState(false)
        const [guestsInfo, setGuestsInfo] = useState<GuestInfo[]>(
            inviteGuestProps?.guests ?? []
        )

        const handleBlur = () => {
            const isInvalidEmail =
                !!formData.email && !validateEmail(formData.email)
            setEmailValidationError(isInvalidEmail)
        }

        const saveDisabled =
            !inviteGuestProps?.isValid || !inviteGuestProps?.isValid()

        const onSaveGuestsClick = useCallback(() => {
            trackCreateHoldSaveButtonClicked()
            if (inviteGuestProps?.handleShowForm) {
                inviteGuestProps.handleShowForm(false)
            }
            setGuestsInfo(guests => {
                guests.push(formData)
                return guests
            })
        }, [formData, inviteGuestProps])

        const onCancelClick = useCallback(() => {
            trackCreateHoldCancelButtonClicked()
            if (inviteGuestProps) {
                inviteGuestProps.handleShowForm(false)
                inviteGuestProps.handleShowSkipButton(true)
            }
        }, [inviteGuestProps])

        const handleHideForm = useCallback(
            (shouldHideForm: boolean) => {
                if (inviteGuestProps) {
                    inviteGuestProps.handleShowForm(!shouldHideForm)
                }
            },
            [inviteGuestProps]
        )

        const handleSetNewForm = useCallback(() => {
            if (inviteGuestProps) {
                inviteGuestProps.populateForm("", "", "")
            }
        }, [inviteGuestProps])

        const handleEditGuest = useCallback(
            (guestInfo: GuestInfo) => {
                if (inviteGuestProps) {
                    inviteGuestProps.populateForm(
                        guestInfo.firstName ?? "",
                        guestInfo.lastName ?? "",
                        guestInfo.email ?? ""
                    )
                    inviteGuestProps.handleEditedGuest(guestInfo)
                    inviteGuestProps.handleShowForm(true)
                    inviteGuestProps.handleShowEditGuestView(true)
                }
            },
            [inviteGuestProps]
        )

        const onRemoveClick = useCallback(() => {
            if (inviteGuestProps && inviteGuestProps.editedGuest) {
                inviteGuestProps.removeGuest(inviteGuestProps.editedGuest)
                inviteGuestProps.handleShowForm(false)
                inviteGuestProps.handleShowEditGuestView(false)
                if (inviteGuestProps.guests.length === 0) {
                    inviteGuestProps.handleShowSkipButton(true)
                }
            }
        }, [inviteGuestProps])

        useEffect(() => {
            if (inviteGuestProps) {
                inviteGuestProps.updateGuests(guestsInfo)
            }
        }, [guestsInfo, inviteGuestProps])

        useEffect(() => {
            if (inviteGuestProps) {
                inviteGuestProps.handleNextDisabled(showForm)
            }
        }, [inviteGuestProps, showForm])

        return (
            <>
                <div>
                    <h3 className="font-bold text-sm uppercase tracking-wider">
                        {inviteGuestProps?.inCreateHoldFlow
                            ? capitalize(
                                  intl.formatMessage({
                                      id: "CalendarPage.flyout.inviteGuestsOptional",
                                      defaultMessage:
                                          "Invite Guests (Optional)",
                                  })
                              )
                            : capitalize(
                                  intl.formatMessage({
                                      id: "CalendarPage.flyout.inviteGuests",
                                      defaultMessage: "Invite Guests",
                                  })
                              )}
                    </h3>
                    <p>
                        <FormattedMessage
                            id="CalendarPage.flyout.inviteGuestsSubtitle"
                            defaultMessage="Add guests to share your trip details and home access information."
                        />
                    </p>
                </div>
                {!showForm && inviteGuestProps ? (
                    <AddGuestSummary
                        guests={inviteGuestProps.guests}
                        occupancyInfo={inviteGuestProps.occupancyInfo}
                        handleShowSkipButton={
                            inviteGuestProps.handleShowSkipButton
                        }
                        handleHideForm={handleHideForm}
                        handleSetNewForm={handleSetNewForm}
                        handleEditGuest={handleEditGuest}
                    />
                ) : (
                    <form
                        id={formId ?? "invite-guests-form"}
                        onSubmit={onSubmit}
                        style={{ marginTop: "24px" }}
                        className={styles.addGuestsForm}
                    >
                        <div className={styles.row}>
                            <div className={styles.col}>
                                <label
                                    className={classnames(
                                        styles.label,
                                        "font-extrabold",
                                        "text-xs",
                                        "uppercase",
                                        "tracking-wider"
                                    )}
                                >
                                    <FormattedMessage
                                        id="CalendarPage.flyout.firstName"
                                        defaultMessage="First name"
                                    />
                                </label>
                                <Input
                                    required
                                    value={formData.firstName}
                                    name="firstName"
                                    onChange={onChange}
                                    maxLength={20}
                                    type="text"
                                />
                            </div>
                            <div className={styles.col}>
                                <label
                                    className={classnames(
                                        styles.label,
                                        "font-extrabold",
                                        "text-xs",
                                        "uppercase",
                                        "tracking-wider"
                                    )}
                                >
                                    <FormattedMessage
                                        id="CalendarPage.flyout.lastName"
                                        defaultMessage="Last name"
                                    />
                                </label>
                                <Input
                                    required
                                    name="lastName"
                                    value={formData.lastName}
                                    onChange={onChange}
                                    maxLength={20}
                                    type="text"
                                />
                            </div>
                        </div>
                        <div className={styles.row}>
                            <div className={styles.col}>
                                <label
                                    className={classnames(
                                        styles.label,
                                        "font-extrabold",
                                        "text-xs",
                                        "uppercase",
                                        "tracking-wider"
                                    )}
                                >
                                    <FormattedMessage
                                        id="CalendarPage.flyout.emailAddress"
                                        defaultMessage="Email address"
                                    />
                                </label>
                                <Input
                                    required
                                    name="email"
                                    type="email"
                                    value={formData.email}
                                    onChange={onChange}
                                    maxLength={128}
                                    onBlur={handleBlur}
                                    error={emailValidationError}
                                />
                            </div>
                        </div>
                        {inviteGuestProps &&
                            inviteGuestProps.inCreateHoldFlow &&
                            !inviteGuestProps.showEditGuestView && (
                                <div className={styles.row}>
                                    <div className={styles.col}>
                                        <div
                                            className={
                                                styles.saveGuestsButtonContainer
                                            }
                                        >
                                            <Button.Utility
                                                className={classnames(
                                                    styles.saveGuestsButton,
                                                    "flex-horizontal-right display-inline button-box"
                                                )}
                                                onClick={onSaveGuestsClick}
                                                disabled={saveDisabled}
                                            >
                                                <p className="zero-margin zero-padding text type-button font-extrabold">
                                                    <FormattedMessage
                                                        id="CalendarPage.flyout.save"
                                                        defaultMessage="Save"
                                                    />
                                                </p>
                                            </Button.Utility>
                                        </div>
                                    </div>
                                    <div className={styles.col}>
                                        <Secondary
                                            className={styles.cancel}
                                            typeOf={"link"}
                                            onClick={onCancelClick}
                                        >
                                            <FormattedMessage
                                                id="CalendarPage.flyout.cancel"
                                                defaultMessage="Cancel"
                                            />
                                        </Secondary>
                                    </div>
                                </div>
                            )}
                        {inviteGuestProps?.showEditGuestView && (
                            <RemoveGuestButton onClick={onRemoveClick} />
                        )}
                    </form>
                )}
            </>
        )
    }
)

export default InviteGuestForm
