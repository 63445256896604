import { JSONAPIResource, Reservation } from "@vacasa/owner-api-models"
import { formatISO } from "date-fns"
import { authAxios } from "../../api/authAxios"

type FetchReservationsRequest = {
    contactId: string
    unitId: string
    startDate?: Date
    endDate?: Date
    pageLimit?: number
    pageNumber?: number
    filterCancelledReservations?: number
    unitRelationshipId?: string
    inclusive?: boolean
    sort?: "asc" | "desc"
    signal?: AbortSignal
}

/**
 * JSON:API is typed incorrectly so we have to create a custom type here
 *
 * @see {@link https://vacasait.atlassian.net/browse/TFP-1156}
 */
export type ReservationsResponse = {
    data: JSONAPIResource<Reservation>[]
    meta: {
        total: number
        currentPage: number
        lastPage: number
    }
}

const fetchReservations = ({
    contactId,
    unitId,
    startDate,
    endDate,
    pageLimit,
    pageNumber = 1,
    filterCancelledReservations,
    unitRelationshipId,
    inclusive,
    sort,
    signal,
}: FetchReservationsRequest): Promise<ReservationsResponse> =>
    authAxios
        .get<ReservationsResponse>(
            `/v1/owners/${contactId}/units/${unitId}/reservations`,
            {
                params: {
                    startDate: startDate
                        ? formatISO(startDate, { representation: "date" })
                        : undefined,
                    endDate: endDate
                        ? formatISO(endDate, { representation: "date" })
                        : undefined,
                    "page[limit]": pageLimit,
                    "page[number]": pageNumber,
                    filterCancelledReservations,
                    unitRelationshipId,
                    inclusive,
                    sort,
                    acceptVersion: "v2",
                },
                signal,
            }
        )
        .then(response => response.data)

export { fetchReservations }
