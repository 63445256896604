import { FC } from "react"
import styles from "./SupportContacts.module.scss"
import { IconLargePersonBlue } from "assets"
import classNames from "classnames"
import { Unit } from "@vacasa/owner-api-models"
import { ContactCard } from "./ContactCard"

export type SupportContactsProps = {
    contacts: Unit["contacts"]
}

export const SupportContacts: FC<SupportContactsProps> = ({ contacts }) => {
    if (contacts.length === 0) return null
    return (
        <div className={classNames(styles.container)}>
            <header>
                <IconLargePersonBlue /> Support Team
            </header>
            <div className={classNames(styles.cards)}>
                {contacts.map(unitContact => {
                    return (
                        <ContactCard
                            key={`${unitContact.id}-${unitContact.title}`}
                            {...unitContact}
                        />
                    )
                })}
            </div>
        </div>
    )
}
