import { UserNotification } from "@vacasa/owner-api-models"

export interface Channel {
    name: string
    value: boolean
    readOnly: boolean
}

export interface Preference {
    id: string
    title: string
    description: string
    channels: Channel[]
}

export interface PreferenceGroupData {
    groupName: string
    preferences: Preference[]
}

export const transformUserPreference = (
    preferenceData: UserNotification | undefined
): PreferenceGroupData[] => {
    return preferenceData && preferenceData.childNotifications
        ? preferenceData.childNotifications.map(group => {
              const preferenceGroup: PreferenceGroupData = {
                  groupName: group.displayName,
                  preferences: group.childNotifications.map(preference => {
                      return {
                          id: preference.id,
                          title: preference.displayName,
                          description: preference.description,
                          channels: preference.metadata.map(channel => {
                              return {
                                  name: channel.key,
                                  value: channel.value ?? false,
                                  readOnly: channel.readOnly,
                              }
                          }),
                      }
                  }),
              }
              return preferenceGroup
          })
        : []
}
