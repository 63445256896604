import classnames from "classnames"
import React, { PropsWithChildren, ReactNode, useRef, useState } from "react"
import { CSSTransition } from "react-transition-group"
import useOnClickOutside from "../../../hooks/common/useOnClickOutside"
import styles from "./FloatingTooltip.module.scss"
import { ReactComponent as ToolTipInfoIcon } from "../../../assets/info.svg"
import { ReactComponent as CancelIcon } from "../../../assets/cancel-icon.svg"
import { NOTIFICATION_SETTINGS_ACTION_TOOLTIP } from "services/segment/notificationSettings/notificationSettingsTrackingConstants"

export interface FloatingTooltipProps {
    title: React.ReactNode
    formattedMsg?: ReactNode
    disabled: boolean
    notificationSegmentTrack?: (
        action: NOTIFICATION_SETTINGS_ACTION_TOOLTIP
    ) => void
}

export const FloatingTooltip: React.FC<
    PropsWithChildren<FloatingTooltipProps>
> = ({ formattedMsg, title, children, disabled, notificationSegmentTrack }) => {
    const [open, setOpen] = useState(false)
    const tooltipRef = useRef<HTMLDivElement>(null)

    const toggleOnClick = (): void => {
        setOpen(!open)
        if (notificationSegmentTrack) {
            notificationSegmentTrack(
                open
                    ? NOTIFICATION_SETTINGS_ACTION_TOOLTIP.CLOSED
                    : NOTIFICATION_SETTINGS_ACTION_TOOLTIP.OPENED
            )
        }
    }

    useOnClickOutside(tooltipRef, () => {
        setOpen(false)
    })

    return disabled ? (
        <>{children}</>
    ) : (
        <div ref={tooltipRef}>
            <div data-testid="tooltip-closed" onClick={toggleOnClick}>
                {children}
            </div>
            <CSSTransition
                in={open}
                timeout={300}
                classNames={{
                    enter: styles["tooltip-enter"],
                    enterActive: styles["tooltip-enter-active"],
                    enterDone: styles["tooltip-enter-active"],
                    exit: styles["tooltip-exit"],
                    exitActive: styles["tooltip-exit-active"],
                }}
                unmountOnExit
            >
                <div
                    className={classnames(styles.tooltip)}
                    data-testid="tooltip-open"
                >
                    <ToolTipInfoIcon className={styles["info-icon"]} />
                    <div className={styles.title}>{title}</div>
                    <div onClick={toggleOnClick}>
                        <CancelIcon className={styles["cancel-icon"]} />
                    </div>
                    <div className={styles.body}>{formattedMsg}</div>
                </div>
            </CSSTransition>
        </div>
    )
}
