import { useIntl } from "react-intl"
import {
    useOwnerPreferences,
    useOwnerPreferencesMutation,
} from "hooks/owner-preferences"
import { MARKET_COMP_FEATURE_ALERT_DISMISSED } from "constants/preferences.constants"
import { useOwnerFeatureFlag } from "hooks/owner-feature-flag"
import { isFeatureFlagEnabled } from "utils/feature-flag"
import { OWNER_PREFERENCE_QUERY_KEY } from "hooks/owner-preferences/useOwnerPreferences"
import { useQueryClient } from "react-query"
import { FC, useEffect } from "react"
import { OptimizelyFeature } from "@optimizely/react-sdk"
import { DynamicAlertPositioner } from "./DynamicAlertPositioner"
import useWindowWidth from "hooks/common/useWindowSize"
import { FeatureAlert } from "lib/components/FeatureAlert/FeatureAlert"
import { useCurrentUnit } from "hooks/units"

type MarketRateCompAlertProps = {
    navItem?: HTMLLIElement | null
    scrollContainer?: HTMLDivElement | null
}

export const MarketRateCompAlert: FC<MarketRateCompAlertProps> = ({
    navItem,
    scrollContainer,
}) => {
    const { unit } = useCurrentUnit()
    const intl = useIntl()
    const windowWidth = useWindowWidth()
    const { data, isLoading } = useOwnerPreferences()
    const marketCompFeatureFlag = useOwnerFeatureFlag("market-rate-comparison")
    const dashboardFeatureFlag = useOwnerFeatureFlag("dashboard")
    const queryClient = useQueryClient()
    const { mutate: mutateOwnerPreference } = useOwnerPreferencesMutation({
        onSuccess: () => {
            queryClient.invalidateQueries(OWNER_PREFERENCE_QUERY_KEY)
        },
    })

    const unitMarketCompChartEnabled = unit?.attributes.marketCompChartEnabled

    const marketCompFeatureAlertUserPreference = data?.find(
        pref => pref.id === MARKET_COMP_FEATURE_ALERT_DISMISSED
    )

    const marketCompFeatureFlagEnabled = isFeatureFlagEnabled(
        marketCompFeatureFlag
    )

    const dashboardFeatureFlagEnabled =
        isFeatureFlagEnabled(dashboardFeatureFlag)

    // if the homecare feature alerts havent been dismissed, dismiss them
    useEffect(() => {
        if (isLoading) return
    }, [
        isLoading,
        marketCompFeatureAlertUserPreference,
        marketCompFeatureFlagEnabled,
        mutateOwnerPreference,
    ])

    // if dashboard feature flag is enabled, dismiss it
    // if still showing the old performance page, don't dismiss just hide it
    useEffect(() => {
        if (isLoading) return
    }, [
        dashboardFeatureFlagEnabled,
        isLoading,
        marketCompFeatureAlertUserPreference,
        marketCompFeatureFlagEnabled,
        mutateOwnerPreference,
    ])

    if (!unitMarketCompChartEnabled) return null
    if (!marketCompFeatureAlertUserPreference) return null
    if (marketCompFeatureAlertUserPreference.attributes.value) return null
    if (!scrollContainer || !navItem) return null
    return (
        <OptimizelyFeature feature="market-rate-comparison">
            {isEnabled =>
                isEnabled && (
                    <OptimizelyFeature feature="market-rates-v2-features">
                        {isEnabled =>
                            !isEnabled && (
                                <DynamicAlertPositioner
                                    navItem={navItem}
                                    scrollContainer={scrollContainer}
                                >
                                    <FeatureAlert
                                        feature="market-rate-comparison-chart"
                                        body={intl.formatMessage({
                                            id: "Dashboard.charts.marketRate.alert.body",
                                            defaultMessage:
                                                "You've been invited to the beta of a brand new account feature.\n\nSee how your home's rates compare with other similar vacation rentals in your market.",
                                        })}
                                        title={intl.formatMessage({
                                            id: "Dashboard.charts.marketRate.alert.title",
                                            defaultMessage: "NEW! Market Rates",
                                        })}
                                        userPreference={
                                            MARKET_COMP_FEATURE_ALERT_DISMISSED
                                        }
                                        pointerPosition={
                                            windowWidth > 768
                                                ? "left-top"
                                                : "top-left"
                                        }
                                    />
                                </DynamicAlertPositioner>
                            )
                        }
                    </OptimizelyFeature>
                )
            }
        </OptimizelyFeature>
    )
}
