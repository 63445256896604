import { JSONAPIResource, Unit } from "@vacasa/owner-api-models"

export const formatUnitAddess = (
    unit: JSONAPIResource<Unit>
): {
    addressOne: string | null
    addressTwo: string | null
} => {
    const UNIT_NAME_TBD = "tbd"
    const { name, address } = unit.attributes
    if (!address || !address.address_1 || !address.city || !address.state) {
        return {
            addressOne: null,
            addressTwo: null,
        }
    }

    const fullAddress = address.address_2
        ? `${address.address_1}, ${address.address_2}`
        : `${address.address_1}`

    if (name && name.toLowerCase() !== UNIT_NAME_TBD) {
        return {
            addressOne: name,
            addressTwo: `${fullAddress}, ${address.city}, ${address.state}`,
        }
    }
    return {
        addressOne: fullAddress,
        addressTwo: `${address.city}, ${address.state}`,
    }
}
