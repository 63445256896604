/** Global constants that DO NOT change per environment */

// TODO: Move these into individual constants files per module

// Hotjar
export const HOTJAR_SITE_ID = 169794
export const HOTJAR_VERSION = 6

// IDs in URLs
export const RESERVATION_ID_PARAM = "resId"
export const UNIT_ID_PARAM = "u"
export const CONTACT_ID = "contact_id"

// The parameter to identify if the url comes from email
// Currently it only accepts the value "email"
export const UTM_MEDIUM = "utm_medium"

// Owner support phone number
export const HOMEOWNER_ACCOUNTS_EMAIL = "homeowner.accounts@vacasa.com"
export const ownerSupportPhoneNum = "+19702543001"

// URLs
export const VACASA_PROD_URL = "https://vacasa.com/"
export const OWNER_LIBRARY_URL = "https://www.vacasa.com/owner-library/"
export const DYNAMIC_PRICING_URL =
    "https://www.vacasa.com/owner-library/dynamic-pricing"
export const RENTAL_INSURANCE_URL =
    "https://www.vacasa.com/property-management/accommodation-protection-program"
export const RENTAL_INSURANCE_GW_URL = "https://insurestays.com/guestworks/"
export const GUESTWORKS_URL = "https://www.vacasa.com/guestworks"

// Support
export const OWNER_HOLD_SUPPORT_EMAIL = "ownerholds@vacasa.com"

// Calendar
// TODO import directly from react-dates
export const HORIZONTAL_ORIENTATION = "horizontal"
export const VERTICAL_ORIENTATION = "vertical"
export const VERTICAL_SCROLLABLE = "verticalScrollable"
export const DAY_SIZE = 39
export const BLOCKED_MODIFIER = "blocked"

// Calendar & dates
// TODO import directly from react-dates
export const DATE_INPUT = "MM/DD/YYYY"
export const ISO_FORMAT_DATE_FNS = "yyyy-MM-dd"
export const START_DATE = "startDate"
export const END_DATE = "endDate"

// Custom consts for react-dates
export const ISO_YEAR_FORMAT = "YYYY"
export const MAX_CALENDAR_MONTHS = 60
export const PAST_RESERVATION_DAYS = 45
export const MONTH_WIDTH_SIZE = 90
export const MONTH_HEIGHT_SIZE = 39
export const MAX_CALENDAR_YEARS = 5

// Owner Holds
export const OWNER_HOLD = "Owner hold"
export const OWNER_CANCELLATION_REASON = "Cancelled by owner"
export const OWNER_STAY = "owner stay"
export const COMPLIMENTARY_STAY = "complimentary stay"
export const BLOCK_STAY = "block"
export const PROPERTY_CARE_STAY = "property care"
export const VACASA_HOLD = "vacasa hold"

// Misc
export const KEYS_TO_FILTERS = [
    "attributes.name",
    "attributes.address.city",
    "attributes.address.address_1",
    "attributes.address.zip",
]

export const PR_S3_BUCKET_URI = (pr: number): string =>
    `http://owner-portal-web-pr-${pr}.s3-website-us-west-2.amazonaws.com`

// local storage key
export const USER = "user"
export const USER_ID = "userId"
export const LAST_GOOD_SESSION = "lastGoodSession"
export const JWT = "jwt"
export const ATTEMPTED_ROUTE = "attempted-route"
export const LOGIN_TYPE = "loginType"
export const EMPLOYEE = "employee"
export const EMPLOYEE_LAST_VIEWED_OWNER_URL = "employeeLastViewedOwnerUrl"
export const LAST_LOGIN_SESSION = "lastLogin"
export const MOBILE_SESSION = "mobileSession"
export const TWILIO_WEB_CHAT = "twilio-flex-cf"
export const FEAUTRE_TRACKING_INTERACTIONS = "featureTrackingInteractions"

// session storage key
export const CONTACT_IDS = "contactIds" // shared key with /onboarding
export const OWNER_MIGRATION_COMPLETE = "owner_migration_complete"

// Reserveration Check in/out defualt times
export const DEFAULT_CHECK_IN_TIME = "16:00:00"
export const DEFAULT_CHECK_OUT_TIME = "10:00:00"

// Onboarding Hold Created
export const HOLD_CREATED = "hold_created"
export const REDIRECT_ONBOARDING = "redirectOnboarding" // shared key with /onboarding

// Mobile width breakpoint
export const MOBILE_BREAKPOINT = 768

// Rent Performance Chart
export const MONTH_LIST = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
]

export const LANGUAGES: Record<
    number,
    {
        name: string
        code: string
        id: number
    }
> = {
    36: { name: "česky", code: "cs", id: 36 },
    50: { name: "Deutsch", code: "de", id: 50 },
    40: { name: "English", code: "en", id: 40 },
    173: { name: "español (América Latina)", code: "es-419", id: 173 },
    170: { name: "español (Europa)", code: "es-ES", id: 170 },
    47: { name: "français", code: "fr", id: 47 },
    67: { name: "italiano", code: "it", id: 67 },
    60: { name: "Magyar", code: "hu", id: 60 },
}
