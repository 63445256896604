import { useStatementDates } from "./useStatementDates"
import { useContactId } from "hooks/user"

export type DownloadStatementsPDFRequest = {
    contactId: string
    filename: string
    firstDay: string
    lastDay: string
    lang: string
    showLogo: boolean
    signal?: AbortSignal
}

export const useDownloadPDFStatementsRequest = ():
    | DownloadStatementsPDFRequest
    | undefined => {
    const dateRange = useStatementDates()
    const contactId = useContactId()

    if (!dateRange || !contactId) {
        return undefined
    }

    return {
        contactId,
        firstDay: dateRange.firstDay,
        lastDay: dateRange.lastDay,
        lang: "en-US",
        showLogo: true,
        filename: `statement-${dateRange.firstDay}`,
    }
}
