const statements = {
    faqAriaLabel: "View FAQ",
    title: "Výkazy",
    emptyStateCTA: "Více informací o výkazech",
    emptyStateEarnings: "Nové výkazy budou k dispozici 10. dne každého měsíce.",

    pdf: "PDF",
    pdfAriaLabel: "Zobrazit PDF",
    pdfNotification: "Příprava souboru PDF ke stažení",
    fileName: "výkaz",

    print: "Tisk",
    printAriaLabel: "Zobrazení náhledu tisku",

    paymentSummary: "Přehled plateb: všechny rekreační pronájmy",
    beginningBalance: "Počáteční zůstatek",
    payment: "Platba",
    payments: "Platby",
    proceeds: "Výnosy",
    proceedsBalance: "Bilance výnosů",
    coOwnerProceeds: "Výnosy spoluvlastníka",
    endingBalance: "Konečný zůstatek",
    details: "Detaily",
    summary: "Přehled",
    nightsOccupied: "Počet obsazených nocí:",
    occupancy: "Obsazení",
    reserveBalance: "Zůstatek rezervy",

    beginningReserveBalance: "Počáteční zůstatek rezervy",
    EndingReserveBalance: "Konečný zůstatek rezervy",
    amountHeld: "Držená částka",
    amountUsed: "Použitá částka",

    netRent: "Čisté nájemné",
    expense: "Výdaj",
    expenses: "Výdaje",

    month: "Měsíc",
    nights: "Počet nocí",
    grossRent: "Hrubé nájemné",
    guestReview: "Guest review",
    guestReviewCollapseLabel: "See less",
    guestReviewExpandLabel: "See more",
    mgmtFee: "Poplatek za správu",
    total: "Celkem",
    credits: "Kredity",

    checkNumber: "Číslo šeku:",
    paymentDescription: "Platba na účet končící",
    statementYearSummary: "{year} Year Summary",

    canadaSalesTaxesHeader: "Taxes - Expenses & Management Fees",

    tooltips: {
        proceeds: "Amount you'll be paid for this month",
        occupancy:
            "The percentage of available nights this month that you rented to guests (does not include your personal use dates and complimentary stays)",
        nightsOccupied:
            "How many nights this month your home hosted guests (only counts reservations with check-out dates within the month)",
    },
    paymentSettings: "Payment Settings",
    paymentSettingsSmall: "Settings",
    paymentSettingsNotification:
        "Manage your bank details and payment settings here.",
    paymentSettingsNotificationTitle: "Payment Settings",
    checkItOut: "Check it Out",
    settings: "Settings",
    backToTop: "Back to Top",
    needHelp: "Need Help?",
    faqAbbreviation: "FAQ",
    errorDownloading: "Error downloading",
    pdfDownloadError:
        "We were unable to create a PDF of your statement. Please try again later.",
    faq: {
        whereDoISendPayments: {
            answer: {
                canada: "If your home is in Canada",
                allOthers: "All others",
            },
        },
    },
    netRentNoteBegin: "NOTE: ",
    netRentNoteContent:
        "The entire stay spans across {reservationMonthSpan} months ({reservationPeriod}). Monthly revenue will appear on each month’s respective statement(s).",
}

export default statements
