import { JSONAPIResource, Reservation } from "@vacasa/owner-api-models"
import React, { useCallback, useState } from "react"
import { FormattedMessage, FormattedNumber } from "react-intl"
import { ReactComponent as RentInfoDropdownDownButton } from "./../../../../../assets/rent-info-dropdown-button-down.svg"
import { ReactComponent as RentInfoDropdownUpButton } from "./../../../../../assets/rent-info-dropdown-button-up.svg"
import { ReactComponent as DollarSignIcon } from "./../../../../../assets/icon-dollar-sign.svg"
import styles from "./RentInfoListItem.module.scss"
import classnames from "classnames"
import { trackReservationDetailsNetRentBreakdown } from "services/segment/reservation/reservationTracking"

interface RentInfoListItemProps {
    reservation: JSONAPIResource<Reservation>
    currency: string | undefined
    link: JSX.Element
    customStyling?: {
        Icon?: string | null
    }
}

const getReservationNetRent = (reservation: JSONAPIResource<Reservation>) => {
    const { netRentAfterDiscount, netRent } = reservation.attributes
    return netRentAfterDiscount ?? netRent ?? 0
}

const getReservationManagementFee = (
    reservation: JSONAPIResource<Reservation>
) => {
    const { managementFee, managementFeeAfterDiscount } = reservation.attributes
    return managementFeeAfterDiscount ?? managementFee ?? 0
}

const RentInfoListItem: React.FC<RentInfoListItemProps> = ({
    reservation,
    currency,
    link,
    customStyling,
}) => {
    const [showBreakdown, setShowBreakdown] = useState(false)

    const onToggleRentBreakdown = useCallback(() => {
        trackOnClick(showBreakdown, reservation)
        setShowBreakdown(prevState => !prevState)
    }, [reservation, showBreakdown])

    return (
        <li key="rentInfoListItem" className={styles.container}>
            <DollarSignIcon
                className={classnames(styles.icon, customStyling?.Icon ?? "")}
            />
            <div className={styles.title}>
                <FormattedMessage
                    id="CalendarPage.flyout.netRent"
                    defaultMessage="Net Rent"
                />
            </div>
            <div className={styles.rentAmount}>
                <FormattedNumber
                    value={getReservationNetRent(reservation)}
                    currency={currency}
                    style="currency" // eslint-disable-line
                />
            </div>
            <div className={styles.learnAboutRates}>{link}</div>
            <div className={styles.dropdownButton}>
                <button
                    type="button"
                    className={styles.button}
                    onClick={onToggleRentBreakdown}
                >
                    {showBreakdown && <RentInfoDropdownUpButton />}
                    {!showBreakdown && <RentInfoDropdownDownButton />}
                </button>
            </div>
            {showBreakdown && (
                <>
                    <div className={styles.rentBreakdown}>
                        <div className={styles.grossRent}>
                            <FormattedMessage
                                id="CalendarPage.flyout.grossRent"
                                defaultMessage="Gross Rent"
                            />
                        </div>
                        <div className={styles.grossRentValue}>
                            <FormattedNumber
                                value={reservation.attributes.totalRent ?? 0}
                                currency={currency}
                                style="currency" // eslint-disable-line
                            />
                        </div>
                        <div className={styles.mgmtFee}>
                            <FormattedMessage
                                id="CalendarPage.flyout.mgmtFee"
                                defaultMessage="Mgmt Fee"
                            />
                        </div>
                        <div className={styles.mgmtFeeValue}>
                            {`-`}
                            <FormattedNumber
                                value={getReservationManagementFee(reservation)}
                                currency={currency}
                                style="currency" // eslint-disable-line
                            />
                        </div>
                        <div className={styles.mgmtFeePercent}>
                            {reservation.attributes
                                .managementFeePercentAfterDiscount && (
                                <>
                                    <span
                                        className={styles.mgmtFeePercentDefault}
                                    >
                                        <FormattedNumber
                                            value={
                                                reservation.attributes
                                                    .managementFeePercent ?? 0
                                            }
                                        />
                                        {`%`}
                                    </span>{" "}
                                </>
                            )}

                            <FormattedNumber
                                value={
                                    reservation.attributes
                                        .managementFeePercentAfterDiscount ??
                                    reservation.attributes
                                        .managementFeePercent ??
                                    0
                                }
                            />
                            {`%`}

                            {reservation.attributes.managementFeeDiscountType &&
                                ` (${reservation.attributes.managementFeeDiscountType} discount)`}
                        </div>
                        <hr className={styles.dash} />
                        <div className={styles.netRent}>
                            <FormattedMessage
                                id="CalendarPage.flyout.netRent"
                                defaultMessage="Net Rent"
                            />
                        </div>
                        <div className={styles.netRentValue}>
                            <FormattedNumber
                                value={getReservationNetRent(reservation)}
                                currency={currency}
                                style="currency" // eslint-disable-line
                            />
                        </div>
                    </div>
                    <div className={styles.notes}>
                        <FormattedMessage
                            id="CalendarPage.flyout.netRentBreakdownNotes"
                            defaultMessage="Additional fees and expenses related to this reservation may appear on your monthly statement."
                        />
                    </div>
                </>
            )}
        </li>
    )
}

const trackOnClick = (
    showBreakdown: boolean,
    reservation: JSONAPIResource<Reservation>
) => {
    trackReservationDetailsNetRentBreakdown(
        String(reservation.attributes.unitID),
        String(reservation.id),
        showBreakdown
            ? "Reservation Details Net Rent Breakdown Closed"
            : "Reservation Details Net Rent Breakdown Opened"
    )
}

export default RentInfoListItem
