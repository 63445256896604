import { Alert } from "components/Alert"
import { ListLayout } from "components/Layout/ListLayout"
import { useUnitPrograms } from "hooks/unit-programs"
import Loader from "lib/components/Loader"
import ServicePlan from "lib/components/ServicePlan"
import { FormattedMessage } from "react-intl"
import { useParams } from "react-router-dom"
import {
    generateNotificationString,
    generatePlanHistory,
    generatePricingString,
} from "utils/programs/programsUtil"
import { Card, CardContent } from "../../../components/Card"
import LearnMore from "./LearnMore"
import NeedHelp from "./NeedHelp"
import { PlanHistory } from "./PlanHistory"
import { useLanguage } from "contexts/language"
import { useUserCurrencyCode } from "hooks/user"

const LinenService = (): JSX.Element => {
    const { unitId = "" } = useParams<{ unitId: string }>()
    const { locale } = useLanguage()
    const { data, isLoading, isError, isSuccess, error } = useUnitPrograms(
        unitId,
        "linen",
        true
    )
    const currency = useUserCurrencyCode()

    if (isLoading) {
        return (
            <ListLayout
                title={
                    <FormattedMessage
                        id="LinenService.title"
                        defaultMessage="Linen service"
                    />
                }
                goBackTo="/home-info"
            >
                <Card>
                    <CardContent className="relative">
                        <Loader />
                    </CardContent>
                </Card>
            </ListLayout>
        )
    }

    if (isError) {
        return (
            <ListLayout
                title={
                    <FormattedMessage
                        id="LinenService.title"
                        defaultMessage="Linen service"
                    />
                }
                goBackTo="/home-info"
            >
                <Card>
                    <CardContent>
                        <p>
                            {`Error loading linen program information: ${error?.message}`}
                        </p>
                    </CardContent>
                </Card>
            </ListLayout>
        )
    }

    const linenUnitPrograms =
        !isLoading && isSuccess && data
            ? data.data.map(document => document.attributes)
            : []

    const servicePlanComponentInfo = generatePricingString(
        linenUnitPrograms,
        currency,
        locale
    )
    const notificationComponentInfo = generateNotificationString(
        linenUnitPrograms,
        currency
    )
    const planHistoryComponentInfo = generatePlanHistory(
        linenUnitPrograms,
        currency,
        locale
    )

    return (
        <ListLayout
            title={
                <FormattedMessage
                    id="LinenService.title"
                    defaultMessage="Linen service"
                />
            }
            goBackTo="/home-info"
        >
            {notificationComponentInfo.variant &&
                notificationComponentInfo.text && (
                    <Alert variant={notificationComponentInfo.variant}>
                        <Alert.Icon />
                        <Alert.Description>
                            {notificationComponentInfo.text}
                        </Alert.Description>
                    </Alert>
                )}
            <LearnMore />
            <ServicePlan
                prepend={
                    <FormattedMessage
                        id="LinenService.servicePlan.prepend"
                        defaultMessage="Your Plan"
                    />
                }
                append={
                    <FormattedMessage
                        id="LinenService.servicePlan.append"
                        defaultMessage="Any charges for this service will be billed as expenses on your monthly statements."
                    />
                }
                title={servicePlanComponentInfo.display}
                cost={servicePlanComponentInfo.pricing ?? <></>}
                discount={servicePlanComponentInfo.discount}
                nextMilestone={servicePlanComponentInfo.nextMilestone}
            />
            <PlanHistory plans={planHistoryComponentInfo} />
            <NeedHelp />
        </ListLayout>
    )
}

export default LinenService
