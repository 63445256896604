import { Component, Fragment } from "react"
import { observer } from "mobx-react"
import { toJS } from "mobx"
import rootService, { useRootService } from "../../../services"

// eslint-disable-next-line @typescript-eslint/no-var-requires
import { ReactComponent as VacasaLogoHorizontalBW } from "../../../assets/vacasa-logo-horizontal-bw.svg"
import { Earnings } from "../../../services/earnings/earnings.service"
import { withHookHocTyped } from "core/hoc/with-hook-hoc"
import { useUser } from "hooks/user"
import { JSONAPIResource, Owner, Unit } from "@vacasa/owner-api-models"
import { useCurrentUnit } from "hooks/units"
import { endOfMonth, format, parseISO, startOfMonth } from "date-fns"

type HookProps = {
    unit: JSONAPIResource<Unit> | null
    user: Owner | null
    rootService: typeof rootService
}
type PrintHeaderProps = {
    header: string
    renderDateRange: boolean
    renderAcctEntity: boolean
}

type Props = PrintHeaderProps & HookProps

@observer
class PrintHeader extends Component<Props> {
    constructor(props: Props) {
        super(props)

        this.renderAcctEntity = this.renderAcctEntity.bind(this)
        this.renderDateRange = this.renderDateRange.bind(this)
        this.renderOwnerData = this.renderOwnerData.bind(this)
    }

    renderAcctEntity(): JSX.Element | undefined {
        const earnService = this.props.rootService.earningsService

        const fetchedStatementData =
            !earnService.loadingSummary &&
            earnService.statementDates &&
            earnService.statementDates.length > 0
        let entity: Earnings["units"][0]["accounting_entity"] | undefined
        if (fetchedStatementData && this.props.unit) {
            entity = this.props.unit.attributes
                .accountingEntity as Earnings["units"][0]["accounting_entity"]
        } else if (
            fetchedStatementData &&
            !this.props.unit &&
            earnService.statement
        ) {
            const statement = toJS(earnService.statement)
            entity = (statement as Earnings).units?.[0]?.accounting_entity
        }
        if (!entity) {
            return
        }
        return (
            <div className="hide" id="vacasa-entity">
                <p>
                    <strong>{entity.name}</strong>
                    <br />
                    {entity.address}
                    <br />
                    {entity.city},&nbsp;
                    {entity.state}&nbsp;
                    {entity.zip}
                    <br />
                    {entity.country}
                </p>
            </div>
        )
    }

    renderDateRange(): string {
        const earnService = this.props.rootService.earningsService
        let displayYearRange = false
        if (!earnService.month) displayYearRange = true
        const currentMonthDate = `${earnService.year}-${earnService.month}-01`
        const startOfYear = `${earnService.year}-01-01`
        const endOfYear = `${earnService.year}-12-31`
        const formattedDate = `${format(
            startOfMonth(
                displayYearRange
                    ? parseISO(startOfYear)
                    : parseISO(currentMonthDate)
            ),
            "MMMM dd"
        )}-${format(
            endOfMonth(
                displayYearRange
                    ? parseISO(endOfYear)
                    : parseISO(currentMonthDate)
            ),
            displayYearRange ? "MMMM dd, yyyy" : "dd, yyyy"
        )}`
        return formattedDate
    }
    renderOwnerData(): JSX.Element {
        const { user } = this.props
        return (
            <div className="print-only" id="owner-address">
                <p className="text-align-right">
                    <strong>
                        {user?.accountInfo?.firstName}{" "}
                        {user?.accountInfo?.lastName}
                    </strong>
                    <br />
                    {user?.accountInfo?.address1}
                    <br />
                    {user?.accountInfo?.city},&nbsp;
                    {user?.accountInfo?.state}&nbsp;
                    {user?.accountInfo?.zip}
                </p>
            </div>
        )
    }

    render(): JSX.Element {
        return (
            <Fragment>
                <div className="print-header">
                    <div>
                        <VacasaLogoHorizontalBW className="print-only vacasa-logo" />
                    </div>
                    <div>
                        <h3>{this.props.header}</h3>
                        <p>
                            {this.props.renderDateRange
                                ? this.renderDateRange()
                                : null}
                        </p>
                    </div>
                </div>
                <hr />
                <div className="print-header">
                    <div>
                        {this.props.renderAcctEntity
                            ? this.renderAcctEntity()
                            : null}
                    </div>
                    <div>{this.renderOwnerData()}</div>
                </div>
            </Fragment>
        )
    }
}

export default withHookHocTyped<HookProps, PrintHeaderProps>(
    PrintHeader,
    () => ({
        rootService: useRootService(),
        unit: useCurrentUnit().unit,
        user: useUser().user,
    })
)
