import { memo, useEffect, forwardRef, useState } from "react"
import Highcharts, { Options } from "highcharts"
import HighchartsReact from "highcharts-react-official"
import isEqual from "lodash/isEqual"
import merge from "lodash/merge"

interface Props {
    options: Options
}

const DEFAULT_CHART_OPTIONS: Options = {
    title: undefined,
    series: [
        {
            type: "column",
            data: [],
            name: "",
        },
    ],
    xAxis: {
        title: undefined,
    },
    yAxis: [
        {
            opposite: true,
            title: undefined,
        },
    ],
    chart: {
        type: "column",
        style: {
            fontFamily: `"Public Sans", Helvetica, Arial, sans-serif`,
        },
        width: 840,
    },
    plotOptions: {
        series: {
            showInLegend: false,
        },
    },
    credits: undefined,
}

const BaseChart = forwardRef<HighchartsReact.RefObject, Props>(
    ({ options }, ref) => {
        const [chartOptions, setChartOptions] = useState<Options>(
            merge({}, DEFAULT_CHART_OPTIONS, options)
        )
        useEffect(() => {
            setChartOptions({
                series: options.series,
            })
        }, [options.series])

        return (
            <HighchartsReact
                ref={ref}
                highcharts={Highcharts}
                options={chartOptions}
            />
        )
    }
)

export default memo(BaseChart, (prevProps, nextProps) =>
    isEqual(prevProps.options.series, nextProps.options.series)
)
