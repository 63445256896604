import { useCallback } from "react"
import FloatingNotification, {
    FloatingNotificationProps,
} from "../FloatingNotification/FloatingNotification"
import {
    FeatureAlertId,
    trackFeatureAlertDisplayed,
    trackFeatureAlertDismissed,
} from "services/segment/floating-notification/floatingNotificationTracking"
import { useOwnerPreferences } from "hooks/owner-preferences"

interface FeatureAlertProps {
    feature: FeatureAlertId
    pointerPosition: FloatingNotificationProps["pointerPosition"]
    title: string
    body: string
    userPreference: string
    linkString?: string
    redirectLink?: string
}

export const FeatureAlert = ({
    feature,
    pointerPosition,
    title,
    body,
    userPreference,
    linkString,
    redirectLink,
}: FeatureAlertProps) => {
    const userPreferencesQuery = useOwnerPreferences()

    const onFeatureAlertPresented = useCallback(() => {
        trackFeatureAlertDisplayed(feature)
    }, [feature])

    const onFeatureAlertDismissed = useCallback(() => {
        trackFeatureAlertDismissed(feature)
    }, [feature])

    if (userPreferencesQuery.isLoading) return null
    if (!userPreferencesQuery.isSuccess) return null

    const preference = userPreferencesQuery.data.find(
        p => p.id === userPreference
    )
    if (!preference) return null

    return (
        <FloatingNotification
            title={title}
            body={body}
            pointerPosition={pointerPosition}
            userPreference={preference}
            trackFloatingNotificationDisplayed={onFeatureAlertPresented}
            trackFloatingNotificationDismissed={onFeatureAlertDismissed}
            linkString={linkString}
            redirectLink={redirectLink}
        />
    )
}
