import * as React from "react"
import styles from "./EnvironmentDebug.module.scss"

const EnvironmentDebug: React.FC = () => {
    const env = process.env.REACT_APP_ENV
    const releaseName =
        process.env.REACT_APP_GIT_TAG !== ""
            ? process.env.REACT_APP_GIT_TAG
            : "not released"
    const branchName =
        process.env.REACT_APP_GIT_BRANCH !== ""
            ? process.env.REACT_APP_GIT_BRANCH
            : "unknown"

    if (env === "production") {
        return null
    }
    return (
        <p className={styles.debug}>
            <strong>Environment: </strong>
            {env}
            <br />
            <strong>Release: </strong>
            {releaseName}
            <br />
            <strong>Branch: </strong>
            {branchName}
        </p>
    )
}

export default EnvironmentDebug
