const jumpToPrevYear = "Move backward to switch to the previous year"
const jumpToNextYear = "Move forward to switch to the next year"
const keyboardShortcuts = "Keyboard Shortcuts"
const showKeyboardShortcutsPanel = "Open the keyboard shortcuts panel"
const hideKeyboardShortcutsPanel = "Close the shortcuts panel"
const openThisPanel = "Open this panel"
const enterKey = "Enter key"
const leftArrowRightArrow = "Right and left arrow keys"
const upArrowDownArrow = "up and down arrow keys"
const pageUpPageDown = "page up and page down keys"
const homeEnd = "Home and end keys"
const escape = "Escape key"
const questionMark = "Question mark"
const selectFocusedDate = "Select the date in focus"
const moveFocusByOneDay = "Move backward (left) and forward (right) by one day"
const moveFocusByOneWeek = "Move backward (up) and forward (down) by one week"
const moveFocusByOneMonth = "Switch months"
const moveFocusByOneYear = "Switch years"
const moveFocustoStartOfMonthsRow = "Move to the first month of the row"
const moveFocusByThreeMonths =
    "Move backward (up) and forward (down) by three months"
const moveFocustoStartAndEndOfWeek = "Go to the first or last day of a week"
const returnFocusToInput = "Return to the date input field"

const chooseAvailableStartDate = ({ date }: { date: string }): string =>
    `Choose ${date} as your check-in date. It's available.`

const chooseAvailableEndDate = ({ date }: { date: string }): string =>
    `Choose ${date} as your check-out date. It's available.`
const chooseAvailableDate = ({ date }: { date: string }): string => date
const dateIsUnavailable = ({ date }: { date: string }): string =>
    `Not available. ${date}`

export const MonthPickerNavigationPhrases = {
    jumpToPrevYear,
    jumpToNextYear,
}

export const MonthPickerKeyboardShortcutsPhrases = {
    keyboardShortcuts,
    showKeyboardShortcutsPanel,
    hideKeyboardShortcutsPanel,
    openThisPanel,
    enterKey,
    leftArrowRightArrow,
    upArrowDownArrow,
    pageUpPageDown,
    homeEnd,
    escape,
    questionMark,
    selectFocusedDate,
    moveFocusByOneDay,
    moveFocusByOneWeek,
    moveFocusByOneMonth,
    moveFocustoStartAndEndOfWeek,
    returnFocusToInput,
}

export const MonthPickerPhrases = {
    jumpToPrevYear,
    jumpToNextYear,
    keyboardShortcuts,
    showKeyboardShortcutsPanel,
    hideKeyboardShortcutsPanel,
    openThisPanel,
    enterKey,
    leftArrowRightArrow,
    upArrowDownArrow,
    pageUpPageDown,
    homeEnd,
    escape,
    questionMark,
    selectFocusedDate,
    moveFocusByOneMonth,
    moveFocusByOneYear,
    moveFocustoStartOfMonthsRow,
    moveFocusByThreeMonths,
    returnFocusToInput,
    chooseAvailableStartDate,
    chooseAvailableEndDate,
    chooseAvailableDate,
    dateIsUnavailable,
}

export const CalendarDayPhrases = {
    chooseAvailableDate,
    dateIsUnavailable,
}

export const CalendarMonthPhrases = {
    chooseAvailableDate,
    dateIsUnavailable,
}
