import { FC, PropsWithChildren, useEffect, useRef } from "react"
import styles from "./DynamicAlertPositioner.module.scss"
import classNames from "classnames"

type DynamicAlertPositionerProps = {
    navItem: HTMLLIElement | null | undefined
    scrollContainer: HTMLDivElement | null | undefined
}

export const DynamicAlertPositioner: FC<
    PropsWithChildren<DynamicAlertPositionerProps>
> = props => {
    const { children, navItem, scrollContainer } = props
    const containerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (!containerRef.current || !navItem || !scrollContainer) return

        const positionContainer = () => {
            if (!containerRef.current) return

            if (window.innerWidth > 768) {
                containerRef.current.style.cssText = `
                    left: ${navItem.clientWidth}px;
                    top: ${navItem.offsetTop - 45}px;
                    transform: translateY(-${scrollContainer.scrollTop}px);
                `
            } else {
                containerRef.current.style.cssText = `
                    left: 20px;
                    top: ${navItem.offsetTop + 45}px;
                    transform: translateY(-${scrollContainer.scrollTop}px);
                `
            }
        }
        scrollContainer.addEventListener("scroll", positionContainer)
        window.addEventListener("resize", positionContainer)
        positionContainer()

        return () => {
            scrollContainer.removeEventListener("scroll", positionContainer)
            window.removeEventListener("resize", positionContainer)
        }
    }, [navItem, scrollContainer])

    return (
        <div
            className={classNames(styles.container, "dynamic-mobile-alert")}
            ref={containerRef}
        >
            {children}
        </div>
    )
}
