import { REVENUE_SIMULATOR_FEATURE_FEEDBACK_DISMISSED } from "constants/preferences.constants"
import { useOwnerPreferences } from "hooks/owner-preferences"
import { FeatureFeedback } from "lib/components/FeatureFeedback/FeatureFeedback"
import { useIntl } from "react-intl"
import styles from "./RateCalculatorChartFeatureFeedback.module.scss"
export const RateCalculatorChartFeatureFeedback = () => {
    const ownerPreferences = useOwnerPreferences()
    const intl = useIntl()
    const revenueSimulatorFeatureFeedbackDismissedPreference =
        ownerPreferences.data?.find(
            pref => pref.id === REVENUE_SIMULATOR_FEATURE_FEEDBACK_DISMISSED
        )

    if (ownerPreferences.isLoading) {
        return null
    }

    return (
        <FeatureFeedback
            featureId="minimum-rate-simulator"
            ratingBody={intl.formatMessage({
                id: "Dashboard.charts.rateCalculator.feedback.ratingsBody",
                defaultMessage: "Do you find this new feature helpful?",
            })}
            commentsBody={intl.formatMessage({
                id: "Dashboard.charts.rateCalculator.feedback.commentsBody",
                defaultMessage:
                    "Thanks for your feedback!\nCare to share more?",
            })}
            requiredInteractionCount={5}
            title={intl.formatMessage({
                id: "Dashboard.charts.rateCalculator.feedback.title",
                defaultMessage: "New! Explore Minimum Rates",
            })}
            userPreference={revenueSimulatorFeatureFeedbackDismissedPreference}
            pointerPosition="bottom-left"
            wrapper={children => (
                <div className={styles.notificationWrap}>{children}</div>
            )}
        />
    )
}
