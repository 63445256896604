import { UnitAvailabilityDays } from "@vacasa/owner-api-models"
import moment from "moment"
import { DateRangePickerShape, isInclusivelyAfterDay } from "react-dates"

export type DateRangeInputPickerProps = {
    calendarAvailability: UnitAvailabilityDays
    initialStartDate: Date | null
    initialEndDate: Date | null
    ownerCurrency: string
    blockAllDays: boolean
    handleDatesChange?: (startDate: Date | null, endDate: Date | null) => void
    disableStartDate?: boolean
}

export const DEFAULT_DRP_PROPS: Partial<
    DateRangePickerShape & DateRangeInputPickerProps
> = {
    initialStartDate: null,
    initialEndDate: null,
    isOutsideRange: day => !isInclusivelyAfterDay(day, moment()),
    noBorder: true,
}

export const OMITTED_PROPS = [
    "initialStartDate",
    "initialEndDate",
    "calendarAvailability",
    "blockAllDays",
    "languageCode",
    "ownerCurrency",
    "handleDatesChange",
    "textAssets", // TODO is this needed
    "disableStartDate",
]
