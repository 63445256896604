import type { AxiosError } from "axios"
import {
    UseMutationResult,
    useMutation,
} from "react-query"
import { useContactId } from "hooks/user"
import { TicketCommentRequest, postTicketComment } from "./postTicketComment"

interface TicketCommentMutationParam {
    unitId: string
    ticketCommentRequest: TicketCommentRequest
}
const useTicketCommentMutation = (): UseMutationResult<
    number,
    AxiosError,
    TicketCommentMutationParam,
    void
> => {
    const contactId = useContactId()
    return useMutation<
        number,
        AxiosError,
        TicketCommentMutationParam,
        void
    >(
        (
            ticketCommentMutationParam: TicketCommentMutationParam
        ): Promise<number> =>
            postTicketComment({
                contactId: contactId ?? "",
                unitId: ticketCommentMutationParam.unitId,
                postTicketCommentData: ticketCommentMutationParam.ticketCommentRequest,
            })
    )
}

export { useTicketCommentMutation }
