/* eslint-disable camelcase */
// Event
export const NOTIFICATION_SETTINGS_EVENT = "Notification Settings"
// Action
export const enum NOTIFICATION_SETTINGS_ACTION {
    SUBSCRIBED = "Subscribed",
    UNSUBSCRIBED = "Unsubscribed",
}

export const enum NOTIFICATION_SETTINGS_ACTION_TOOLTIP {
    OPENED = "Required Preference Tooltip Info Opened",
    CLOSED = "Required Preference Tooltip Info Closed",
}
