import React, { ReactNode, useEffect, useMemo, useState } from "react"

// components
import { Flyout } from "../../core/components/flyout/flyout"
import DetailsBox from "views/maintenance/DetailsBox"
import {
    MaintenanceTicket,
    TicketCategoryId,
    TicketResponse,
    TicketCategory,
    TicketCategoryName,
    TicketDispositionIdType,
    Comment,
} from "@vacasa/owner-api-models"

import styles from "./maintenanceFlyoutUtil.module.scss"
import {useTicketCategories, useTicketCommentMutation, useTicketCommentReadReceipt} from "hooks/ticket"
import { CreateTicketCategory } from "lib/components/FlyOut/Maintenance/CreateTicketCategory"
import { useOwnerFeatureFlag } from "hooks/owner-feature-flag"
import { isFeatureFlagEnabled } from "utils/feature-flag"
import { Comments } from "./Comments"
import { useIntl} from "react-intl"
import CreateTicketDetails from "lib/components/FlyOut/Maintenance/CreateTicketDetails"
import { ReactComponent as HomeIcon } from "../../assets/icon-lrg-house-blue-3.svg"
import { ReactComponent as FinanceIcon } from "../../assets/icon-lrg-payment-blue-6.svg"
import { ReactComponent as AccountIcon } from "../../assets/icon-lrg-laptop-blue-3.svg"
import { ReactComponent as OtherIcon } from "../../assets/icon-lrg-speech-blue-1.svg"
import { ReactComponent as HousekeepingIcon } from "../../assets/icon-lrg-cleanliness-blue-8.svg"
import { ReactComponent as PermittingIcon } from "../../assets/icon-lrg-computer-blue-1.svg"
import { ReactComponent as StatementsIcon } from "../../assets/icon-lrg-payment-blue-6.svg"
import { ReactComponent as PricingIcon } from "../../assets/icon-lrg-house-blue-4.svg"
import { ReactComponent as TaxesIcon } from "../../assets/icon-lrg-document-blue-6.svg"
import { ReactComponent as ListingIcon } from "../../assets/icon-lrg-computer-blue-3.svg"
import { ReactComponent as MobileIcon } from "../../assets/icon-lrg-settings-blue-2.svg"
import { ReactComponent as CheckCircle } from "../../assets/check-circle.svg"
import { ReactComponent as ContractInfoIcon } from "../../assets/icon-lrg-document-blue-5.svg"
import { ReactComponent as OwnershipIcon } from "../../assets/icon-lrg-person-blue-7.svg"
import {
    MaintenanceTicketTypes,
    TicketTypes,
} from "views/maintenance/Maintenance"
import {
    trackCreateTicketFlyoutClosed,
    trackTicketCategorySelected, trackTicketCommentSubmitted,
} from "services/segment/supportHub/supportHubTracking"
import { toTicketCategoriesKeyedObject } from "utils/tickets/ticketsUtil"
import { useLocation, useNavigate } from "react-router-dom"
import { useCurrentUnit, useUnitStatuses } from "hooks/units"
import { CommentInput } from "./CommentInput"
import { useIsEmployee } from "hooks/user"
import {ReopenInput} from "./ReopenInput";
import {isUnauthorizedOrCancelledRequest} from "../error/error";
import LoggingService from "../../services/logging/logging.service";
import errorService from "../../services/error.service";

interface CreateTicketFlyoutProps {
    updateShowTicketSubmitted: (shouldShowTicketSubmitted: boolean) => void
    updateShowFlyout: (shouldShowFlyout: boolean) => void
    tickets: TicketTypes
    maintenanceTickets: MaintenanceTicketTypes
    updateMaintenanceTickets: (key: string, value: MaintenanceTicket[]) => void
    triggerDetailsBox: (ticket: TicketResponse) => void
    category?: TicketCategoryId
}

interface TicketDetailsFlyoutProps {
    closeHandler: () => void
    ticket: TicketResponse | null
    showTicketSubmitted: boolean
    handleCommentSubmitted: () => void
}

export interface TicketDetails {
    groupId: TicketCategoryId
    groupName: string
    categoryId: string
    dispositionId?: number
    displayName: string
    categoryDescription: string
    title: string
    description: string
}

enum FlyoutContent {
    Groups = "Groups",
    Categories = "Categories",
    Details = "Details",
}

const getIcon = (categoryId: string): ReactNode => {
    switch (categoryId) {
        case TicketCategoryId.Home:
        case TicketCategoryId.Maintenance:
            return <HomeIcon />
        case TicketCategoryId.Finance:
            return <FinanceIcon />
        case TicketCategoryId.Account:
        case TicketCategoryId.WebPortal:
            return <AccountIcon />
        case TicketCategoryId.Housekeeping:
            return <HousekeepingIcon />
        case TicketCategoryId.Permitting:
            return <PermittingIcon />
        case TicketCategoryId.Statements:
            return <StatementsIcon />
        case TicketCategoryId.Pricing:
            return <PricingIcon />
        case TicketCategoryId.Taxes:
            return <TaxesIcon />
        case TicketCategoryId.Listing:
            return <ListingIcon />
        case TicketCategoryId.MobileApp:
            return <MobileIcon />
        case TicketCategoryId.ContractInformation:
            return <ContractInfoIcon />
        case TicketCategoryId.ContractOwnershipUpdate:
            return <OwnershipIcon />
        case TicketCategoryId.Other:
        default:
            return <OtherIcon />
    }
}

export const CreateTicketFlyout: React.FC<CreateTicketFlyoutProps> = (
    props: CreateTicketFlyoutProps
) => {
    const intl = useIntl()
    const { isGuestworks } = useUnitStatuses()

    const ticketCategoriesQuery = useTicketCategories()
    const ticketCategoriesData = ticketCategoriesQuery.isSuccess
        ? ticketCategoriesQuery.data
        : undefined

    const [category, setCategory] = useState(TicketCategoryId.Root)
    const [flyoutContent, setFlyoutContent] = useState(FlyoutContent.Groups)
    const [showLeavingAlert, setShowLeavingAlert] = useState(false)
    const navigate = useNavigate()
    const location = useLocation()

    // Prepopulate the ticket details if category is passed in
    useEffect(() => {
        if (!ticketCategoriesData || !props.category) return

        const categoriesMap = toTicketCategoriesKeyedObject(
            ticketCategoriesData.childCategories
        )

        const category = categoriesMap[props.category]
        const parentCategory = categoriesMap[category?.parentId ?? ""]

        if (category && parentCategory) {
            setFlyoutContent(FlyoutContent.Details)
            setCategory(category.id)
            setTicketDetails(prevState => ({
                ...prevState,
                categoryId: category.id,
                dispositionId: Number(category.dispositionId),
                displayName: category.displayName,
                categoryDescription: category.description,
                groupId: parentCategory.id,
                groupName: parentCategory.displayName,
            }))

            navigate(
                {
                    pathname: location.pathname,
                },
                {
                    replace: true,
                }
            )
        }
    }, [location.pathname, navigate, props.category, ticketCategoriesData])

    const hideLeavingAlert = () => {
        setShowLeavingAlert(false)
    }

    const childCategories = useMemo(
        () =>
            ticketCategoriesData?.childCategories.flatMap(
                c => c.childCategories
            ) ?? [],
        [ticketCategoriesData?.childCategories]
    )

    const updateCategory = (categoryId: TicketCategoryId | undefined) => {
        if (categoryId) {
            setCategory(categoryId)
        }
        if (categoryId === TicketCategoryId.Other) {
            trackTicketCategorySelected({
                // eslint-disable-next-line camelcase
                category_id: TicketCategoryId.Other,
                // eslint-disable-next-line camelcase
                disposition_id: TicketDispositionIdType.OwnerOther,
            })
        }
        const selectedChildCategory = childCategories.find(
            childCategory => childCategory.id === categoryId
        )
        if (selectedChildCategory) {
            trackTicketCategorySelected({
                // eslint-disable-next-line camelcase
                category_id: categoryId ?? "",
                // eslint-disable-next-line camelcase
                disposition_id: selectedChildCategory.dispositionId ?? "",
            })
        }
    }

    const emptyTicketDetails = {
        groupId: TicketCategoryId.Root,
        groupName: "",
        categoryId: "",
        dispositionId: undefined,
        displayName: "",
        categoryDescription: "",
        title: "",
        description: "",
    }

    const [ticketDetails, setTicketDetails] =
        useState<TicketDetails>(emptyTicketDetails)

    const getDisplayChildCategories = (
        categoryData: TicketCategory
    ): TicketCategory[] | undefined => {
        const childCategories = categoryData.childCategories.find(
            c => c.id === category
        )?.childCategories

        const guestWorkChildCategories = childCategories?.filter(
            c => c.visibleToGuestworksUnits
        )
        const fullServiceChildCategories = childCategories?.filter(
            c => c.visibleToFullServiceUnits
        )

        return isGuestworks
            ? guestWorkChildCategories
            : fullServiceChildCategories
    }

    useEffect(() => {
        if (category === TicketCategoryId.Root) {
            setFlyoutContent(FlyoutContent.Groups)
        } else if (category === TicketCategoryId.Other) {
            setFlyoutContent(FlyoutContent.Details)
            setTicketDetails(prevState => ({
                ...prevState,
                groupName: TicketCategoryName.Other,
                categoryId: TicketCategoryId.Other,
                dispositionId: Number(TicketDispositionIdType.OwnerOther),
                displayName:
                    ticketCategoriesData?.childCategories.find(
                        c => c.id === TicketCategoryId.Other
                    )?.displayName ?? "",
                categoryDescription:
                    ticketCategoriesData?.childCategories.find(
                        c => c.id === TicketCategoryId.Other
                    )?.description ?? "",
            }))
        } else if (childCategories.find(c => c.id === category)) {
            setFlyoutContent(FlyoutContent.Details)
            setTicketDetails(prevState => ({
                ...prevState,
                categoryId: category,
                dispositionId: Number(
                    childCategories.find(c => c.id === category)?.dispositionId
                ),
                displayName:
                    childCategories.find(c => c.id === category)?.displayName ??
                    "",
                categoryDescription:
                    childCategories.find(c => c.id === category)?.description ??
                    "",
            }))
        } else {
            setFlyoutContent(FlyoutContent.Categories)
            setTicketDetails(prevState => ({
                ...prevState,
                groupId: category,
                groupName:
                    ticketCategoriesData?.childCategories.find(
                        c => c.id === category
                    )?.displayName ?? "",
            }))
        }
    }, [
        category,
        childCategories,
        ticketCategoriesData?.childCategories,
        ticketDetails.displayName,
        ticketDetails.dispositionId,
    ])

    const onFlyoutClose = () => {
        if (
            flyoutContent === FlyoutContent.Details &&
            !showLeavingAlert &&
            (ticketDetails.title || ticketDetails.description)
        ) {
            setShowLeavingAlert(true)
        } else {
            props.updateShowFlyout(false)
            trackCreateTicketFlyoutClosed()
        }
    }

    const updateTicketDetails = (name: string, value: string) => {
        setTicketDetails(prevState => ({
            ...prevState,
            [name]: value,
        }))
    }

    const flyout = (
        <div className={styles.maintenanceFlyoutContainer}>
            <Flyout
                closeOnClickOutside={true}
                onClose={onFlyoutClose}
                conClassName={styles.maintenanceFlyout}
            >
                {ticketCategoriesData &&
                    flyoutContent === FlyoutContent.Groups && (
                        <CreateTicketCategory
                            ticketCategories={
                                ticketCategoriesData.childCategories
                            }
                            updateCategory={updateCategory}
                            showArrow={false}
                            ticketCategoryTitle={intl.formatMessage({
                                id: "Maintenance.needSomeHelp",
                                defaultMessage: "Need some help?",
                            })}
                            getIcon={getIcon}
                        />
                    )}
                {ticketCategoriesData &&
                    flyoutContent === FlyoutContent.Categories && (
                        <CreateTicketCategory
                            ticketCategories={
                                getDisplayChildCategories(
                                    ticketCategoriesData
                                ) ?? []
                            }
                            updateCategory={updateCategory}
                            showArrow={true}
                            ticketCategoryTitle={ticketDetails.groupName}
                            getIcon={getIcon}
                        />
                    )}
                {flyoutContent === FlyoutContent.Details && (
                    <CreateTicketDetails
                        ticketDetails={ticketDetails}
                        updateCategory={updateCategory}
                        getIcon={getIcon}
                        showLeavingAlert={showLeavingAlert}
                        hideLeavingAlert={hideLeavingAlert}
                        updateTicketDetails={updateTicketDetails}
                        updateShowTicketSubmitted={
                            props.updateShowTicketSubmitted
                        }
                        updateShowFlyout={props.updateShowFlyout}
                        triggerDetailsBox={props.triggerDetailsBox}
                    />
                )}
            </Flyout>
        </div>
    )
    return flyout
}

export const TicketDetailsFlyout: React.FC<TicketDetailsFlyoutProps> = (
    props: TicketDetailsFlyoutProps
) => {
    const {
        closeHandler,
        ticket,
        showTicketSubmitted,
        handleCommentSubmitted,
    } = props
    const intl = useIntl()
    const isEmployee = useIsEmployee()
    const { unitId } = useCurrentUnit()

    const createCommentMutation = useTicketCommentMutation()
    const commentReadReceiptMutation = useTicketCommentReadReceipt()
    const { mutate: commentReadReceiptMutate } = commentReadReceiptMutation;

    useEffect(() => {
        const ticketContent = document.querySelector(
            "[class^=maintenanceFlyoutUtil_maintenanceContent]"
        )
        if(ticket && unitId  ){
            const unreadComment = ticket.attributes.comments?.filter(
                c=> !c.readTimestamp && c.vacasaCreated && c.commentId
            ).map(c=> c.commentId!) || []

            if(unreadComment && unreadComment.length >0){

                commentReadReceiptMutate({unitId,commentIds:unreadComment})
            }
        }

        const isCompletedTicket = !!ticket?.attributes?.isComplete

        if(ticketContent && isCompletedTicket){
            ticketContent.scrollTo(0, ticketContent.scrollHeight)
        }
    }, [ticket, unitId,commentReadReceiptMutate]);

    const ownerCreatedCommentsFeatureFlag = useOwnerFeatureFlag(
        "owner-created-ticket-comments"
    )
    const ownerCreatedCommentsEnabled = isFeatureFlagEnabled(
        ownerCreatedCommentsFeatureFlag
    )

    const ownerReopenTicketFeatureFlag = useOwnerFeatureFlag(
        "owner-portal-reopen-ticket"
    )
    const ownerReopenTicketEnabled = isFeatureFlagEnabled(
        ownerReopenTicketFeatureFlag
    )



    // Check if ticket exists and the ticket belongs to the current unit
    if (!ticket || String(ticket.attributes.unitId) !== unitId) return null

    const handleCommentInputSubmitted = (commentText:string,resetOnSuccess: ()=>void)=>{

        const ticketId =   Number(ticket.id)
        const dispositionId =  ticket.attributes.dispositionId
        createCommentMutation.mutate(
            {
                unitId,
                ticketCommentRequest: {
                    ticketId,
                    note: commentText,
                },
            },
            {
                onSuccess: () => {
                    resetOnSuccess()
                    handleCommentSubmitted()
                    trackTicketCommentSubmitted({
                        // eslint-disable-next-line camelcase
                        ticket_id: ticketId.toString(),
                        // eslint-disable-next-line camelcase
                        disposition_id: dispositionId?.toString() ?? "",
                    })
                },
                onError: error => {
                    if (!isUnauthorizedOrCancelledRequest(error)) {
                        LoggingService.error({
                            message: "Creating ticket comment failed",
                            error,
                            tags: {
                                unitID: `${unitId}`,
                            },
                        })
                    }
                    errorService.setErrorMsg(
                        intl.formatMessage({
                            id: "Maintenance.comments.failedCreateCommentError",
                            defaultMessage:
                                "We were unable to save your comment. Please try again later.",
                        })
                    )
                    errorService.displayError()
                },
            }
        )
    }

    const filterComments = (ticket: TicketResponse): Comment[] =>
        ticket.attributes.comments?.filter(comment => comment.external) ?? []

    const canBeReopened = ticket.attributes.canBeReopened


    return (
        <div className={styles.maintenanceFlyoutContainer}>
            <Flyout
                closeOnClickOutside={true}
                onClose={closeHandler}
                conClassName={styles.maintenanceFlyout}
            >
                <div className={styles.ticketDetails}>
                    <div className={styles.maintenanceContent}>
                        <div className={styles.detailsBox}>
                            <DetailsBox ticket={ticket} />
                        </div>
                        <div className={styles.commentsRow}>
                            <hr />
                            <Comments
                                comments={filterComments(ticket)}
                                ownerCreatedCommentsEnabled={
                                    ownerCreatedCommentsEnabled
                                }
                            />
                        </div>
                        {showTicketSubmitted && (
                            <div className={styles.requestSubmitted}>
                                <CheckCircle />
                                {intl.formatMessage({
                                    id: "Maintenance.requestSubmitted",
                                    defaultMessage: "Request submitted",
                                })}
                            </div>
                        )}
                    </div>
                    {ownerCreatedCommentsEnabled &&
                        !isEmployee &&
                        !ticket.attributes.isComplete && (
                            <div className={styles.commentInputContainer}>
                                <CommentInput
                                    handleCommentSubmitted={
                                        handleCommentInputSubmitted
                                    }
                                    isLoadingComment={createCommentMutation.isLoading}
                                />
                            </div>
                        )}
                    {ownerReopenTicketEnabled &&
                        !isEmployee &&
                        canBeReopened &&(
                            <div  data-testid={"reopen-container"} className={""}>
                                <ReopenInput
                                    ticketId={Number(ticket.id)}
                                    unitId={unitId}
                                />

                            </div>
                        )
                    }
                </div>
            </Flyout>
        </div>
    )
}
