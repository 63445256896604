import { GuestworksLink } from "components/GuestworksLink/GuestworksLink"
import { SHOW_MIN_RATE } from "constants/preferences.constants"
import { useOnboardingStatus } from "hooks/onboarding/use-onboarding-status"
import { useOwnerUnitPreferences } from "hooks/owner-preferences"
import { useReservation } from "hooks/reservation"
import { useKnownSearchParams } from "hooks/router"
import { useHasTaxObligation } from "hooks/taxes"
import { useUnitMinRate } from "hooks/unit-min-rate"
import { observer } from "mobx-react"
import React, {
    Fragment,
    PropsWithChildren,
    useEffect,
    useRef,
    useState,
} from "react"
import { FormattedMessage } from "react-intl"
import BookingNotificationUtil from "utils/bookingNotification/bookingNotificationUtil"
import useOnClickOutside from "../../hooks/common/useOnClickOutside"
import { useOwnerFeatureFlag } from "../../hooks/owner-feature-flag"
import { useW9TaxSubmission } from "../../hooks/taxes-w9-submission"
import { useRootService } from "../../services"
import EnvironmentDebug from "./EnvironmentDebug"
import NavItem from "./NavItem"
import OnboardingNavItem from "./OnboardingNavItem"
// eslint-disable-next-line camelcase
import { useLocation } from "react-router-dom"
import { isFeatureFlagEnabled } from "utils/feature-flag"
import { checkIfOnboardingAppUser } from "utils/onboarding"
import OwnerReferralCard from "./owner-referral-card"
import { useUser } from "hooks/user"
import { isOwnerInEurope } from "utils/user"
import { MarketRateCompAlert } from "./components/MarketRateCompAlert"
import { useCurrentUnit, useUnitStatuses } from "hooks/units"
import {
    useUnitsOnboardingInProgressCount,
    useUnits,
    useUnitsOnboarding,
} from "hooks/units"
import { isGuestworksUnit } from "utils/unit"

type MobileNavigationState = {
    /**
     * Side navigation is open in mobile
     * Always open on desktop
     */
    open: boolean
    /**
     * Set the side navigaiton to be open when in mobile
     */
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const MobileNavigationOpen = React.createContext<MobileNavigationState>({
    open: false,
    setOpen: () => null,
})

export const MobileNavigationProvider: React.FC<PropsWithChildren> = ({
    children,
}) => {
    const [open, setOpen] = React.useState(false)
    return (
        <MobileNavigationOpen.Provider value={{ open, setOpen }}>
            {children}
        </MobileNavigationOpen.Provider>
    )
}
export const useMobileNavigation = (): MobileNavigationState =>
    React.useContext(MobileNavigationOpen)

const Navigation = observer(
    ({
        showReferralModal,
    }: {
        showReferralModal: (shouldShowReferralModal: boolean) => void
    }) => {
        const unitsQuery = useUnits()
        const { isRevenuePooling } = useUnitStatuses()
        const { earningsService } = useRootService()
        const location = useLocation()
        const { user } = useUser()
        const knownSearchParams = useKnownSearchParams()
        const reservationQuery = useReservation(knownSearchParams.resId)
        const { hasTaxObligation } = useHasTaxObligation()
        const w9TaxSubmissionQuery = useW9TaxSubmission()
        const showTaxNotifcation =
            w9TaxSubmissionQuery.isSuccess &&
            !w9TaxSubmissionQuery.data.hasSubmittedW9 &&
            user?.accountInfo.country === "US" &&
            hasTaxObligation

        const { data: onboardingUnitsCount } =
            useUnitsOnboardingInProgressCount()
        const onboardingUnits = useUnitsOnboarding()

        // Onboarding Wizard Beta Feature Flag, hides setup nav if on
        const isOnboardingAppUser = checkIfOnboardingAppUser(
            useOnboardingStatus()
        )
        const dashboardFeatureFlag = useOwnerFeatureFlag("dashboard")

        const showOnboardingPage =
            unitsQuery.isSuccess &&
            (onboardingUnits.length > 0 || unitsQuery.data.length === 0) &&
            !isOnboardingAppUser

        const scrollContainerRef = useRef<HTMLDivElement>(null)
        const dashboardItemRef = useRef<HTMLLIElement>(null)
        const performanceItemRef = useRef<HTMLLIElement>(null)
        const homeInfoItemRef = useRef<HTMLLIElement>(null)

        const minRateFeatureFlag = useOwnerFeatureFlag(
            "owner-portal-unit-min-rate"
        )

        const { unitId } = useCurrentUnit()
        const ownerUnitPreferencesQuery = useOwnerUnitPreferences(unitId)
        const userPrefMinRateValue = ownerUnitPreferencesQuery.data?.find(
            pref => pref.id === SHOW_MIN_RATE
        )?.attributes.value
        // Show Min rate when the user preference is either undefined or true value
        const showMinRate = userPrefMinRateValue !== false
        const unitMinRate = useUnitMinRate(unitId)

        const [
            performanceMinRateNotificationCount,
            setPerformanceMinRateNotificationCount,
        ] = useState(0)

        const dashboardEnabled = isFeatureFlagEnabled(dashboardFeatureFlag)

        const mobileNavigation = useMobileNavigation()
        const navigationRef = useRef<HTMLDivElement>(null)

        useOnClickOutside(
            navigationRef,
            event => {
                const tempElement = event.target as HTMLElement
                if (
                    tempElement &&
                    tempElement.classList.contains("mobile-menu")
                ) {
                    return
                }
                mobileNavigation.setOpen(false)
            },
            () => mobileNavigation.open
        )

        // Sets statement dates for Statements route to use.
        useEffect(() => {
            earningsService.fetchStatementDates()
        }, [earningsService])

        const [notificationCount, setNotificationCount] = useState(0)

        useEffect(() => {
            if (knownSearchParams.resId) {
                if (!reservationQuery.isLoading && reservationQuery.isSuccess) {
                    setNotificationCount(
                        BookingNotificationUtil.getShouldShowBookingNotification(
                            location
                        ) && !BookingNotificationUtil.getHasError(location)
                            ? 1
                            : 0
                    )
                }
            } else {
                setNotificationCount(0)
            }
        }, [
            knownSearchParams.resId,
            location,
            notificationCount,
            reservationQuery.isLoading,
            reservationQuery.isSuccess,
        ])

        useEffect(() => {
            setPerformanceMinRateNotificationCount(
                ownerUnitPreferencesQuery.isSuccess &&
                    showMinRate &&
                    minRateFeatureFlag.isEnabled &&
                    unitMinRate.data &&
                    unitMinRate.data.attributes &&
                    !isRevenuePooling &&
                    unitMinRate.data.attributes.showMinRateNotification
                    ? 1
                    : 0
            )
        }, [
            minRateFeatureFlag.isEnabled,
            ownerUnitPreferencesQuery,
            unitMinRate,
            showMinRate,
            isRevenuePooling,
        ])

        // don't display if we're not logged in!
        if (user === null || !dashboardFeatureFlag.clientReady) {
            return null
        }
        const showGuestworksLink =
            unitsQuery.isSuccess && unitsQuery.data.some(isGuestworksUnit)

        return (
            <Fragment>
                <div
                    className={`navigation ${
                        mobileNavigation.open ? "open" : ""
                    }`}
                    id="navigation"
                    ref={navigationRef}
                >
                    <div className="nav__items">
                        <div
                            className={"nav__scrollable_container"}
                            ref={scrollContainerRef}
                        >
                            <ul>
                                <OnboardingNavItem
                                    show={isOnboardingAppUser}
                                    label={
                                        <FormattedMessage
                                            id="Navigation.betaonboarding"
                                            defaultMessage="Switch to Onboarding"
                                        />
                                    }
                                    showBadge={onboardingUnitsCount > 0}
                                />
                                <NavItem
                                    show={showOnboardingPage}
                                    to="/setup"
                                    icon="icon-feather-check-square"
                                    label={
                                        <FormattedMessage
                                            id="Navigation.onboarding"
                                            defaultMessage="Setup"
                                        />
                                    }
                                    notificationCount={onboardingUnitsCount}
                                />
                                {dashboardEnabled && (
                                    <NavItem
                                        show={!isOwnerInEurope(user)}
                                        to="/performance"
                                        icon="icon-bar-chart-2"
                                        notificationCount={
                                            performanceMinRateNotificationCount
                                        }
                                        ref={dashboardItemRef}
                                        label={
                                            <FormattedMessage
                                                id="Navigation.dashboard"
                                                defaultMessage="Dashboard"
                                            />
                                        }
                                    />
                                )}
                                <NavItem
                                    to="/calendar"
                                    icon="icon-feather-calendar"
                                    label={
                                        <FormattedMessage
                                            id="Navigation.calendar"
                                            defaultMessage="Calendar"
                                        />
                                    }
                                    notificationCount={notificationCount}
                                    testid="calendar-nav-item"
                                />
                                {!dashboardEnabled && (
                                    <NavItem
                                        show={!isOwnerInEurope(user)}
                                        to="/performance"
                                        icon="icon-feather-trending-up"
                                        notificationCount={
                                            performanceMinRateNotificationCount
                                        }
                                        label={
                                            <FormattedMessage
                                                id="Navigation.performance"
                                                defaultMessage="Performance"
                                            />
                                        }
                                        ref={performanceItemRef}
                                    />
                                )}
                                <NavItem
                                    show={
                                        user?.accountInfo
                                            .accountingEntityCountry !==
                                        "Czechia"
                                    }
                                    to="/statements"
                                    icon="icon-feather-file-text"
                                    label={
                                        <FormattedMessage
                                            id="Navigation.statements"
                                            defaultMessage="Statements"
                                        />
                                    }
                                    testid="statements-nav-item"
                                />
                                <NavItem
                                    to="/home-info"
                                    icon="icon-feather-home"
                                    label={
                                        <FormattedMessage
                                            id="Navigation.homeInfo"
                                            defaultMessage="Home Info"
                                        />
                                    }
                                    ref={homeInfoItemRef}
                                />

                                <NavItem
                                    to="/taxes"
                                    icon="icon-feather-percent"
                                    label={
                                        <FormattedMessage
                                            id="Navigation.taxes"
                                            defaultMessage="Taxes"
                                        />
                                    }
                                    notificationCount={
                                        showTaxNotifcation &&
                                        !isOnboardingAppUser
                                            ? 1
                                            : undefined
                                    }
                                />
                                <NavItem
                                    to="/maintenance"
                                    icon="icon-hand-heart"
                                    label={
                                        <FormattedMessage
                                            id="Navigation.supportHub"
                                            defaultMessage="Support"
                                        />
                                    }
                                />
                                {showGuestworksLink && (
                                    <li>
                                        <GuestworksLink />
                                    </li>
                                )}
                                <li>
                                    <OwnerReferralCard
                                        showReferralModal={showReferralModal}
                                    />
                                </li>
                                <li>
                                    <hr />
                                    <EnvironmentDebug />
                                </li>
                            </ul>
                        </div>
                        {scrollContainerRef.current && (
                            <>
                                <MarketRateCompAlert
                                    scrollContainer={scrollContainerRef.current}
                                    navItem={
                                        dashboardEnabled
                                            ? dashboardItemRef.current
                                            : performanceItemRef.current
                                    }
                                />
                            </>
                        )}
                    </div>
                </div>
            </Fragment>
        )
    }
)

export default Navigation
