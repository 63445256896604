import { PostTicketResponse } from "@vacasa/owner-api-models"
import { authAxios } from "../../api/authAxios"

export type PostTicketsRequest = {
    contactId: string
    unitId: string
    postTicketData: TicketRequest
    signal?: AbortSignal
}

export interface TicketRequest {
    title: string
    description: string
    dispositionId: string
    assigneeId?: number
    status?: number
    severity?: number
    requestedBy?: string
    reservationId?: number
    startDate?: string
    completionDate?: string
    completedDate?: string
}

export type CreateTicketResponse = { data: PostTicketResponse }

const postTicket = ({
    contactId,
    unitId,
    postTicketData,
    signal,
}: PostTicketsRequest): Promise<PostTicketResponse> => {
    const ticketData = {
        data: {
            type: "ticket",
            attributes: postTicketData,
        },
    }

    return authAxios
        .post<CreateTicketResponse>(`/v1/ticket`, ticketData, {
            params: {
                contactId,
                unitId,
            },
            signal,
        })
        .then(response => response.data.data)
}

export { postTicket }
