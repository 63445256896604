import React, { Fragment } from "react"
import { sumBy } from "lodash"

// Components
import { FormattedMessage, FormattedNumber } from "react-intl"
import UnitSumaryViewButtons, { ViewType } from "./UnitSummaryViewButtons"
import { ReactComponent as ChartSVG } from "../../assets/icon-chart.svg"
// Services
import { useParams } from "react-router-dom"

import styles from "./statements.module.scss"
import { UnitSummaryRow } from "./UnitSummaryRow"
import { Unit } from "@vacasa/owner-api-models/src/legacy/Earnings"
import { getUnitAddress } from "utils/statements/statementsUtil"
import { format, setMonth } from "date-fns"

const UnitSummaryHeader = () => (
    <div className={styles["unit-summary-header-row"]}>
        <div className={styles["unit-summary-cell"]}>
            <FormattedMessage id="Statements.month" defaultMessage="Month" />
        </div>
        <div className={styles["unit-summary-cell"]}>
            <FormattedMessage id="Statements.nights" defaultMessage="Nights" />
        </div>
        <div className={styles["unit-summary-cell"]}>
            <FormattedMessage
                id="Statements.grossRent"
                defaultMessage="Gross Rent"
            />
        </div>
        <div className={styles["unit-summary-cell"]}>
            <FormattedMessage
                id="Statements.mgmtFee"
                defaultMessage="Mgmt Fee"
            />
        </div>
        <div className={styles["unit-summary-cell"]}>
            <FormattedMessage
                id="Statements.netRent"
                defaultMessage="Net Rent"
            />
        </div>
    </div>
)

interface UnitSummaryListProps {
    unit: Unit
    currency: string
}

const UnitSummaryList = ({ unit, currency }: UnitSummaryListProps) => {
    let fixedRent: boolean
    return (
        <div className={styles["unit-summary-overview-list"]}>
            {Object.entries(unit.summary).map(([key, summary]) => {
                const netRent = summary.rent - summary.mgmt_fee
                const month = format(setMonth(new Date(), Number(key)), "MMMM")
                return (
                    <UnitSummaryRow
                        currency={currency}
                        isFixedRent={fixedRent}
                        grossRent={summary.rent}
                        mgmtFee={summary.mgmt_fee}
                        month={month}
                        netRent={netRent}
                        nights={summary.nights_booked}
                        key={key}
                    />
                )
            })}
        </div>
    )
}

interface UnitSummaryTotalProps {
    currency: string
    unit: Unit
}

const UnitSummaryTotal = ({ currency, unit }: UnitSummaryTotalProps) => {
    const nights = sumBy(unit.summary, "nights_booked")
    const grossRent = sumBy(unit.summary, "rent")
    const mgmntFee = sumBy(unit.summary, "mgmt_fee")
    const netRent = sumBy(unit.summary, "net_rent")

    return (
        <div className={styles["unit-summary-total-row"]}>
            <div className={styles["unit-summary-cell"]}>
                <FormattedMessage
                    id="Statements.total"
                    defaultMessage="Total"
                />
            </div>
            <div className={styles["unit-summary-cell"]}>
                <span className={styles["header"]}>
                    <FormattedMessage
                        id="Statements.nights"
                        defaultMessage="Nights"
                    />
                </span>
                {nights}
            </div>
            <div className={styles["unit-summary-cell"]}>
                <span className={styles["header"]}>
                    <FormattedMessage
                        id="Statements.grossRent"
                        defaultMessage="Gross Rent"
                    />
                </span>
                <FormattedNumber
                    value={grossRent}
                    currency={currency}
                    style="currency" // eslint-disable-line react/style-prop-object
                />
            </div>
            <div className={styles["unit-summary-cell"]}>
                <span className={styles["header"]}>
                    <FormattedMessage
                        id="Statements.mgmtFee"
                        defaultMessage="Mgmt Fee"
                    />
                </span>
                <FormattedNumber
                    value={-mgmntFee}
                    currency={currency}
                    style="currency" // eslint-disable-line react/style-prop-object
                />
            </div>
            <div className={styles["unit-summary-cell"]}>
                <span className={styles["header"]}>
                    <FormattedMessage
                        id="Statements.netRent"
                        defaultMessage="Net Rent"
                    />
                </span>
                <FormattedNumber
                    value={netRent}
                    currency={currency}
                    style="currency" // eslint-disable-line react/style-prop-object
                />
            </div>
        </div>
    )
}

interface UnitSummarySummaryProps {
    currency: string
    changeView: (showDetails: boolean, id: string) => void
    unit: Unit
    viewKey: string
}

const UnitSummarySummaryView: React.FC<UnitSummarySummaryProps> = ({
    changeView,
    currency,
    unit,
    viewKey,
}: UnitSummarySummaryProps) => {
    const unitImage = unit.image
    const routeDate: Record<string, string | undefined> = useParams()
    const summaryTitle =
        routeDate.month === undefined ? (
            <FormattedMessage
                id="Statements.yearSummaryTitle"
                defaultMessage="Yearly Summary"
            />
        ) : (
            <FormattedMessage
                id="Statements.monthSummaryTitle"
                defaultMessage="Monthly Summary"
            />
        )
    const unitAddress = getUnitAddress(unit)
    return (
        <Fragment>
            <div className="unit-summary-header row">
                <div className="col-lg-8">
                    <div className="unit-image">
                        <img
                            alt="Unit details"
                            className="unit-details-img"
                            src={unitImage}
                        />
                    </div>
                    <div className="unit-details-header-left">
                        <h3 className="unit-details-primary">
                            <span>{unit.unit_name}</span>
                        </h3>
                        <div className="unit-details-secondary">
                            <p>{unitAddress}</p>
                        </div>
                    </div>
                </div>
                <UnitSumaryViewButtons
                    changeView={changeView}
                    view={ViewType.Summary}
                    viewKey={viewKey}
                />
            </div>
            <div className={styles["summary-title"]}>
                <ChartSVG />
                <span className={styles["title"]}>{summaryTitle}</span>
            </div>
            <UnitSummaryHeader />
            <UnitSummaryList unit={unit} currency={currency} />
            <UnitSummaryTotal currency={currency} unit={unit} />
        </Fragment>
    )
}

export default UnitSummarySummaryView
