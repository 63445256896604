const petsAllowed = {
    title: "Povolit psy",
    ariaLabel: "Klikněte na tlačítko pro povolení domácích zvířat.",
    description:
        "Vaše nabídka bude atraktivní pro majitele psích miláčků a umožní nám nabídnout vaši nemovitost širší veřejnosti. Poskytujeme potřeby pro psy a zaručujeme, že je o vaši nemovitost vždy dobře postaráno.",
    learnMore: "Více informací",
    earnMore: "Vydělejte si až o 20 % více",
    earnMoreDescription:
        "Pokud povolíte psí kamarády, můžete významně zvýšit vaše příjmy a obsazení, a to zejména v období mimo sezónu.",
    untitledHome: "Nemovitost bez názvu",
    showAllRentals: "Zobrazit všechny rekreační pronájmy",
    showAllAriaLabel:
        "pomocí tohoto tlačítka zobrazíte všechny vaše rekreační pronájmy.",
    hideRentals: "Skrýt rekreační pronájmy",
    allowed: "Povoleno",
    allow: "Povolit",
    modalHeader: "Skvělé, vše je připraveno!",
    modalText:
        "Vaše nabídka je právě aktualizována a místní tým bude informován, aby zajistil, že je vaše nemovitost připravena na přivítání psích miláčků. Může trvat až 24 hodin, než se tato změna projeví a budou aktualizovány všechny vaše nabídky a marketingové kanály.",
    modalFooter: "Klikněte na toto tlačítko pro zavření modálního okna.",
    ok: "OK",
    disclaimer:
        "Může trvat až 24 hodin, než se změny projeví a budou aktualizovány všechny vaše nabídky a marketingové kanály. V případě jakýchkoli otázek nebo pokud si již nepřejete povolit psy, obraťte se prosím na svou kontaktní osobu zodpovědnou za váš rekreační pronájem.",
}

export default petsAllowed
