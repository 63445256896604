import {
    CircularProgress,
    Button as MuiButton,
    ButtonProps as MuiButtonProps,
} from "@material-ui/core"
import { default as classNames, default as classnames } from "classnames"
import { ButtonHTMLAttributes, FC, MouseEvent, PropsWithChildren } from "react"
import { Icon, IconName } from "../Icon/Icon"
import styles from "./Button.module.scss"

export type ButtonProps = Omit<MuiButtonProps, "variant"> & {
    className?: string
    disabled?: boolean
    fullWidth?: boolean
    loading?: boolean
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
    variant:
        | "primary"
        | "secondary"
        | "secondary-icon"
        | "primary-alert"
        | "text"
}

const LoadingSpinner = () => {
    return (
        <div className={styles.loadingSpinner}>
            <div />
            <div />
            <div />
            <div />
        </div>
    )
}

export const Button: FC<PropsWithChildren<ButtonProps>> = ({
    children,
    className,
    disabled,
    variant,
    fullWidth,
    loading,
    ...rest
}) => {
    return (
        <MuiButton
            className={classnames(className, styles.button, {
                [styles["primary"] as string]: variant === "primary",
                [styles["secondary"] as string]: variant === "secondary",
                [styles["primary-alert"] as string]:
                    variant === "primary-alert",
                [styles["text"] as string]: variant === "text",
                [styles["button--full-width"] as string]: fullWidth,
            })}
            {...rest}
            variant="contained"
            disabled={disabled || loading}
        >
            {loading && (
                <CircularProgress
                    style={{ width: 14, height: 14, color: "white" }}
                />
            )}
            {!loading && children}
        </MuiButton>
    )
}

/**
 * New button which if adopted should be renamed
 */

export interface NewButtonProps
    extends PropsWithChildren,
        ButtonHTMLAttributes<HTMLButtonElement> {
    disabled?: boolean
    icon?: IconName
    loading?: boolean
    size?: "normal" | "small" | "x-small"
    variant:
        | "primary"
        | "secondary"
        | "subtle"
        | "text"
        | "primary-alert"
        | "secondary-alert"
        | "white"
        | "white-text"
        | "inline"
}

export const NewButton: FC<NewButtonProps> = ({
    children,
    className,
    disabled,
    icon,
    loading,
    size = "normal",
    variant,
    onClick,
    ...props
}) => {
    const createRipple = (event: MouseEvent<HTMLButtonElement>) => {
        const button = event.currentTarget

        const { top, left } = button.getBoundingClientRect()

        const circle = document.createElement("span")
        const diameter = Math.max(button.clientWidth, button.clientHeight)
        const radius = diameter / 2

        circle.style.width = circle.style.height = `${diameter}px`
        circle.style.left = `${event.clientX - left - radius}px` //`${event.clientX - button.offsetLeft - radius}px`
        circle.style.top = `${event.clientY - top - radius}px` //`${event.clientY - button.offsetTop - radius}px`

        if (styles.ripple) {
            circle.classList.add(styles.ripple)
            circle.addEventListener("animationend", () => {
                button.removeChild(circle)
            })
            button.appendChild(circle)
        }
    }

    const onButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
        if (loading) return
        if (onClick) onClick(e)
    }

    return (
        <button
            onClick={onButtonClick}
            onMouseDown={createRipple}
            className={classNames(styles.button, className, {
                //variants
                [styles.variantPrimary as string]: variant === "primary",
                [styles.variantPrimaryAlert as string]:
                    variant === "primary-alert",
                [styles.variantSecondary as string]: variant === "secondary",
                [styles.variantSecondaryAlert as string]:
                    variant === "secondary-alert",
                [styles.variantSubtle as string]: variant === "subtle",
                [styles.variantText as string]: variant === "text",
                [styles.variantWhite as string]: variant === "white",
                [styles.variantWhiteText as string]: variant === "white-text",
                [styles.variantLink as string]: variant === "inline",

                // sizes
                [styles.sizeNormal as string]: size === "normal",
                [styles.sizeSmall as string]: size === "small",
                [styles.sizeXSmall as string]: size === "x-small",
            })}
            disabled={disabled}
            {...props}
        >
            {!loading && (
                <>
                    {icon && <Icon className={styles.icon} name={icon} />}
                    {children}
                </>
            )}
            {loading &&
                (variant.includes("text") ? "Loading..." : <LoadingSpinner />)}
        </button>
    )
}
