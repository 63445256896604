import {
    addDays,
    addMonths,
    addWeeks,
    addYears,
    eachDayOfInterval,
    format,
    parseISO,
    startOfDay,
    subDays,
} from "date-fns"
import { Options, Point } from "highcharts"
import ReactDOMServer from "react-dom/server"
import { BookingProbabilityTooltip } from "./BookingProbabilityTooltip"
import { IntlProvider, IntlShape } from "react-intl"
import { RateTooltip } from "./RateTooltip"
import { ReservationTooltip } from "./ReservationTooltip"
import { RangeOptions } from "../types"
import { MOBILE_BREAKPOINT } from "Constants"
import { isDefined } from "utils/common"
import { RatesTooltip } from "./RatesTooltip"
import { RESERVATION_SERIES } from "./constants"
import classNames from "classnames"
import { trackMarketCompTooltipViewed } from "services/segment/dashboard/dashboardTracking"

/**
 * Draw the markers on the chart for the min, max and unit series
 * @param x
 * @param chart
 */
export const drawMarkers = (
    x: number,
    chart: Highcharts.Chart,
    v2?: boolean
) => {
    const points: Point[] = []

    if (!chart.markers) {
        chart.markers = []
    }
    // Ensure we remove any existing markers
    removeMarkers(chart)

    // Draw custom markers for the max, min and unit range
    chart.series.forEach(series => {
        if (!["max", "min", "unit"].includes(series.name)) return
        const point = series.points.find(point => point.x === x)
        if (point) points.push(point)
    })

    points.forEach(point => {
        if (point.y === undefined) return

        const x = chart.xAxis[0]?.toPixels(point.x, false) ?? 0
        const y = chart.yAxis[0]?.toPixels(point.y, false) ?? 0

        const isUnitMarker = point.series.name === "unit"

        if (v2 && !isUnitMarker) return

        const marker = chart.renderer
            .circle(x, y, isUnitMarker ? 10 : 4.5)
            .attr({
                stroke: v2 ? "var(--white)" : "var(--dusk)",
                fill: v2
                    ? {
                          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                          stops: [
                              [0, "var(--gulf-90)"],
                              [1, "var(--midnight)"],
                          ],
                      }
                    : "var(--white)",
                "stroke-width": 2,
            })
            .addClass(
                isUnitMarker
                    ? "highcharts-marker highcharts-marker--unit"
                    : "highcharts-marker highcharts-marker--minmax"
            )
            .add()
            .toFront()

        chart.markers?.push(marker)
    })

    // force the unit marker to appear on the front until logic is added to not overlap the points
    if (chart.markers[0]) {
        chart.markers[0].toFront()
    }
}

export const drawInitialMarkers = (chart: Highcharts.Chart, v2?: boolean) => {
    const today = startOfDay(new Date()).getTime()

    const xAxis = chart.xAxis[0]

    // Get the next reservation point
    const reservationPoint = chart.series
        .find(x => x.name === "reservation-dates")
        ?.points.find(point => point.x >= today)

    if (!reservationPoint) {
        removeMarkers(chart)
        chart.tooltip.hide(0)
        chart.xAxis[0]?.hideCrosshair()
        return
    }
    const reservationX = reservationPoint.x

    // if outside range we shouldn't draw anything
    if (reservationX > (xAxis?.max ?? 0) || reservationX < (xAxis?.min ?? 0))
        return

    // Get all the points to trigger all tooltips
    const allPoints = chart.series
        .map(x => x.points.find(x => x.x === reservationPoint.x))
        .filter(isDefined)

    chart.tooltip.refresh(allPoints)
    chart.xAxis[0]?.drawCrosshair(undefined, reservationPoint)
    drawMarkers(reservationPoint.x ?? 0, chart, v2)
}

/**
 * Removes markers
 * @param chart
 * @returns
 */
export const removeMarkers = (chart: Highcharts.Chart): void => {
    if (!chart.markers) return
    for (const marker of chart.markers) {
        marker.destroy()
    }
    chart.markers = []
}

/**
 * Returns the ranges available for the market comparison chart
 */
export const getMarketCompChartRanges = (): {
    [key in RangeOptions]: { min: number; max: number }
} => {
    const today = startOfDay(new Date()).getTime()
    return {
        "1D": {
            min: today,
            max: startOfDay(addDays(new Date(), 1)).getTime(),
        },
        "1W": {
            min: today,
            max: startOfDay(addDays(new Date(), 6)).getTime(),
        },
        "1M": {
            min: today,
            max: startOfDay(addWeeks(new Date(), 4)).getTime(),
        },
        "3M": {
            min: today,
            max: startOfDay(addMonths(new Date(), 3)).getTime(),
        },
        "6M": {
            min: today,
            max: startOfDay(addMonths(new Date(), 6)).getTime(),
        },
        "1Y": {
            min: today,
            max: startOfDay(subDays(addYears(new Date(), 1), 1)).getTime(),
        },
    }
}

export const getChartTicks = (max: number) => {
    const ranges = getMarketCompChartRanges()

    switch (max) {
        case ranges["1Y"].max:
            return [
                ranges["1Y"].min,
                startOfDay(addMonths(new Date(), 3)).getTime(),
                startOfDay(addMonths(new Date(), 6)).getTime(),
                startOfDay(addMonths(new Date(), 9)).getTime(),
                ranges["1Y"].max,
            ]
        case ranges["6M"].max:
            return [
                ranges["6M"].min,
                startOfDay(addMonths(new Date(), 2)).getTime(),
                startOfDay(addMonths(new Date(), 4)).getTime(),
                ranges["6M"].max,
            ]
        case ranges["3M"].max:
            return [
                ranges["3M"].min,
                startOfDay(addMonths(new Date(), 1)).getTime(),
                startOfDay(addMonths(new Date(), 2)).getTime(),
                ranges["3M"].max,
            ]
        case ranges["1M"].max: {
            const intervals = [7, 14, 21]
            return [
                ranges["1M"].min,
                ...intervals.map(days =>
                    addDays(ranges["1M"].min, days).getTime()
                ),
                ranges["1M"].max,
            ]
        }
        case ranges["1W"].max: {
            const days = eachDayOfInterval({
                start: ranges["1W"].min,
                end: ranges["1W"].max,
            })
            return days.map(day => day.getTime())
        }
        case ranges["1D"].max: {
            const days = eachDayOfInterval({
                start: ranges["1D"].min,
                end: ranges["1D"].max,
            })
            return days.map(day => day.getTime())
        }
    }
    return []
}

export const getChartXAxisForYear = (): {
    x: number
}[] => {
    const today = startOfDay(new Date())
    const days = eachDayOfInterval({
        start: today,
        end: subDays(addYears(today, 1), 1),
    })

    return days.map(day => {
        return {
            x: day.getTime(),
        }
    })
}

/**
 * Returns either the min or max values from the ranges
 * @param ranges
 * @param minOrMax
 * @returns
 */
export const getSeriesDataFromAreaRange = (
    ranges: [number, number, number][],
    minOrMax: "min" | "max"
) => {
    if (minOrMax === "min") {
        return ranges.map(p => [p[0], p[1]])
    }
    return ranges.map(p => [p[0], p[2]])
}

const CHART_AREA_HALF_OFFSET = 47

export const getChartBaseConfig = ({
    chartHeight,
    locale,
    messages,
    v2,
    chartType = "market-comp",
    tooltipEnabled = true,
    chartAnimation = true,
}: {
    locale: string
    messages: IntlShape["messages"]
    v2?: boolean
    chartHeight?: number
    chartType?: "market-comp" | "revenue-simulator"
    tooltipEnabled?: boolean
    chartAnimation?: boolean
}): Options => {
    return {
        chart: {
            animation: chartAnimation,
            height: chartHeight ?? (v2 ? 400 : 500),
            spacingTop: 40,
            style: {
                fontFamily: `"Public Sans", Helvetica, Arial, sans-serif`,
            },
            zooming: {
                mouseWheel: false,
            },
            panning: {
                enabled: false,
            },
            events: {
                load: function () {
                    this.container.addEventListener("mouseleave", () =>
                        removeMarkers(this)
                    )
                    drawInitialMarkers(this, v2)
                },
                redraw: function () {
                    drawInitialMarkers(this, v2)
                },
            },
        },

        accessibility: {
            enabled: false,
        },
        plotOptions: {
            series: {
                tooltip: {
                    distance: 8,
                },
                point: {
                    events: {
                        mouseOver: function () {
                            // Draw our custom markers to fix layering issues
                            drawMarkers(this.x ?? 0, this.series.chart, v2)

                            if (v2) {
                                highlightReservationsPoint(
                                    this.x ?? 0,
                                    this.series.chart
                                )
                            }
                        },
                        mouseOut: function () {
                            removeMarkers(this.series.chart)
                            if (v2) {
                                resetReservationPointHighlight(
                                    this.series.chart
                                )
                            }
                        },
                    },
                },
            },
        },
        credits: undefined,
        scrollbar: {
            enabled: false,
        },
        navigator: {
            enabled: false,
        },
        rangeSelector: {
            enabled: false,
        },
        legend: {
            enabled: false,
        },
        time: {
            useUTC: false,
        },
        tooltip: {
            enabled: tooltipEnabled,
            borderColor: "none",
            borderRadius: 8,
            split: true,
            outside: true,
            useHTML: true,
            shadow: v2
                ? {
                      width: 20,
                      offsetY: 0,
                      color: "rgba(112, 117, 121, 0.2)",
                  }
                : {
                      width: 20,
                      color: "rgba(112, 117, 121, 0.2)",
                  },
            backgroundColor: "var(--white)",
            padding: 0,
            style: {
                fontFamily: `"Public Sans", Helvetica, Arial, sans-serif`,
                fontSize: "10",
                color: "var(--white)",
            },
            hideDelay: v2 ? 50 : 200,
            xDateFormat: "%d %b",
            formatter: function () {
                const xPosition = this.point.plotX ?? 0
                const chartWidth = this.point.series.chart.plotWidth

                const isLeft =
                    xPosition <
                    Math.ceil((chartWidth + CHART_AREA_HALF_OFFSET) / 2)

                return [
                    ReactDOMServer.renderToString(
                        <div
                            className={classNames({
                                antialiased: v2,
                            })}
                            style={
                                v2
                                    ? {
                                          height: 20,
                                          padding: "0 4px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                      }
                                    : { padding: 8 }
                            }
                        >
                            {format(new Date(this.x ?? 0), "MMM dd")}
                            {v2 && <div />}
                        </div>
                    ), // this is the xaxis label date tooltip
                    ...(this.points?.map(point => {
                        switch (point.series.name) {
                            case "min":
                                return v2
                                    ? ""
                                    : ReactDOMServer.renderToString(
                                          <RateTooltip
                                              rate={point.y ?? 0}
                                              type="min"
                                              side={isLeft ? "left" : "right"}
                                          />
                                      )
                            case "max":
                                return v2
                                    ? ""
                                    : ReactDOMServer.renderToString(
                                          <RateTooltip
                                              rate={point.y ?? 0}
                                              type="max"
                                              side={isLeft ? "left" : "right"}
                                          />
                                      )
                            case "unit": {
                                if (v2) {
                                    if (
                                        this.point.series.chart.tooltipViewTimer
                                    ) {
                                        clearTimeout(
                                            this.point.series.chart
                                                .tooltipViewTimer
                                        )
                                    }

                                    this.point.series.chart.tooltipViewTimer =
                                        setTimeout(() => {
                                            if (this?.point?.options?.data)
                                                trackMarketCompTooltipViewed(
                                                    this.point.options.data,
                                                    chartType
                                                )
                                        }, 1000)

                                    return ReactDOMServer.renderToString(
                                        <IntlProvider
                                            locale={locale}
                                            messages={messages}
                                            defaultLocale="en"
                                        >
                                            <RatesTooltip
                                                unit={point.y ?? 0}
                                                max={
                                                    point.point.options.data
                                                        ?.avgCompMaxRate ?? 0
                                                }
                                                min={
                                                    point.point.options.data
                                                        ?.avgCompMinRate ?? 0
                                                }
                                                guestStay={
                                                    !!point.point.options.data
                                                        ?.reservation &&
                                                    point.point.options.data
                                                        .reservation?.type ===
                                                        "guest"
                                                }
                                                analysis={
                                                    point.point.options.data
                                                        ?.info
                                                }
                                            />
                                        </IntlProvider>
                                    )
                                }

                                return ReactDOMServer.renderToString(
                                    <RateTooltip
                                        rate={point.y ?? 0}
                                        type="current"
                                        side={isLeft ? "left" : "right"}
                                    />
                                )
                            }
                            case "booking-probability": {
                                let level:
                                    | "low"
                                    | "medium"
                                    | "high"
                                    | undefined = undefined

                                if (
                                    v2 &&
                                    isValidBookingProbabiltyType(
                                        point.point.options.bookingProbability
                                    )
                                ) {
                                    level =
                                        point.point.options.bookingProbability
                                } else if (
                                    !v2 &&
                                    point.point.options.highBookingProbability
                                ) {
                                    level = "high"
                                }

                                if (!level) return ""

                                return ReactDOMServer.renderToString(
                                    <IntlProvider
                                        locale={locale}
                                        messages={messages}
                                        defaultLocale="en"
                                    >
                                        <div style={{ height: 12 }}>
                                            <BookingProbabilityTooltip
                                                level={level}
                                            />
                                        </div>
                                    </IntlProvider>
                                )
                            }
                            case "reservation-dates":
                                if (!point.point.options.reservation) return ""

                                return ReactDOMServer.renderToString(
                                    <IntlProvider
                                        locale={locale}
                                        messages={messages}
                                        defaultLocale="en"
                                    >
                                        <div style={{ height: 12 }}>
                                            <ReservationTooltip
                                                date={parseISO(
                                                    point.point.options
                                                        .reservation.firstNight
                                                )}
                                                reservationId={
                                                    point.point.options
                                                        .reservation.id
                                                }
                                                occupancyType={
                                                    point.point.options
                                                        .reservation.type
                                                }
                                                nights={
                                                    point.point.options
                                                        .reservation.nights
                                                }
                                                v2={v2}
                                            />
                                        </div>
                                    </IntlProvider>
                                )
                            default:
                                return ""
                        }
                    }) ?? []),
                ]
            },
            positioner: function (labelWidth, labelHeight, point) {
                const xPosition = point.plotX ?? 0
                const chartWidth = this.chart.plotWidth

                const isLeft =
                    xPosition <
                    Math.ceil((chartWidth + CHART_AREA_HALF_OFFSET) / 2)

                const x = point.plotX + this.chart.plotLeft - labelWidth / 2
                let plotY = point.plotY - labelHeight / 2
                if (point.isHeader) {
                    const y =
                        this.chart.yAxis[0]?.toPixels(point.y ?? 0, false) ?? 0

                    return {
                        x: x < 0 ? 0 : x,
                        y,
                    }
                }

                if (["max", "min"].includes(point.series.name)) {
                    if (this.chart.unitTooltipPosition) {
                        const { above, isWithinRange } = isWithUnitPlotY(
                            this.chart.unitTooltipPosition.plotY,
                            point.plotY
                        )

                        if (isWithinRange) {
                            const unitPlotYNearBottom =
                                this.chart.plotHeight -
                                    this.chart.unitTooltipPosition.plotY <
                                57

                            const isHigherRate =
                                (point.y ?? 0) >
                                this.chart.unitTooltipPosition.y

                            plotY =
                                this.chart.unitTooltipPosition.plotY +
                                (above || unitPlotYNearBottom || isHigherRate
                                    ? -labelHeight
                                    : labelHeight)
                        }
                    }

                    return {
                        x: point.plotX + (!isLeft ? -(labelWidth - 5) : 15),
                        y: plotY,
                    }
                }

                if (point.series.name === "unit") {
                    point.series.chart.unitTooltipPosition = {
                        plotY,
                        y: point.y ?? 0,
                    }

                    if (v2) {
                        return {
                            x:
                                point.plotX +
                                (!isLeft ? -(labelWidth + 10) : 30),
                            y: plotY < 20 ? 40 : plotY,
                        }
                    }

                    return {
                        x: point.plotX + (!isLeft ? -(labelWidth - 5) : 15),
                        y: plotY,
                    }
                }

                if (
                    ["booking-probability", "reservation-dates"].includes(
                        point.series.name
                    )
                ) {
                    if (v2) {
                        let xPoint = x < 0 ? 0 : x
                        if (xPoint + labelWidth > this.chart.plotWidth) {
                            xPoint = this.chart.plotWidth - (labelWidth - 30)
                        }

                        return {
                            x: xPoint,
                            y: 0,
                        }
                    }
                    return {
                        x: x < 0 ? 0 : x,
                        y: 0,
                    }
                }

                return {
                    x: point.plotX + 20,
                    y: point.plotY - labelHeight / 2,
                }
            },
        },
        xAxis: [
            {
                type: "datetime",
                overscroll: 0,
                zoomEnabled: false,
                gridLineWidth: 0,
                lineColor: "transparent",
                className: "x-axis",
                ...(v2
                    ? {
                          minRange: 1,
                      }
                    : {}),

                labels: {
                    useHTML: true,
                    style:
                        chartType === "revenue-simulator"
                            ? {
                                  color: "var(--dusk-light)",
                                  fontSize: "12px",
                                  fontWeight: "400",
                                  fontFamily: `"Public Sans", Helvetica, Arial, sans-serif`,
                              }
                            : {
                                  color: "var(--dusk-light)",
                                  fontSize: "8px",
                                  fontWeight: "500",
                                  fontFamily: `"Public Sans", Helvetica, Arial, sans-serif`,
                              },
                    formatter: function () {
                        const ranges = getMarketCompChartRanges()

                        // Last data label
                        if (this.value === this.axis.max) {
                            switch (this.axis.max) {
                                case ranges["1D"].max:
                                    return "Tomorrow"
                                case ranges["1W"].max:
                                    return "1 Week"
                                case ranges["1M"].max:
                                    return "1 Month"
                                case ranges["3M"].max:
                                    return "3 Months"
                                case ranges["6M"].max:
                                    return "6 Months"
                                case ranges["1Y"].max:
                                    return "1 Year"
                            }
                        }

                        if (this.value === this.axis.min) {
                            return "Today"
                        }

                        if (
                            ranges["1W"].max === this.axis.max ||
                            ranges["1M"].max === this.axis.max
                        ) {
                            return format(new Date(this.value), "MMM d")
                        }

                        return format(new Date(this.value), "MMM yyyy")
                    },
                },
                crosshair: {
                    color: "var(--dusk)",
                    width: 1,
                    zIndex: 10,
                },
                tickLength: 0,
                startOnTick: false,
                endOnTick: false,

                tickPositioner: function () {
                    const axis = this as Highcharts.Axis
                    const max = axis.max
                        ? startOfDay(new Date(axis.max)).getTime()
                        : startOfDay(addMonths(new Date(), 12)).getTime()

                    return getChartTicks(max)
                },
                min: getMarketCompChartRanges()["1Y"].min,
                max: getMarketCompChartRanges()["1Y"].max,
            },
        ],
        yAxis: [
            {
                title: undefined,
                labels:
                    v2 && chartType === "market-comp"
                        ? { enabled: false }
                        : {
                              useHTML: true,
                              align: "left",
                              style:
                                  chartType === "revenue-simulator"
                                      ? {
                                            color: "var(--dusk-light)",
                                            fontSize: "12px",
                                            fontWeight: "400",
                                            fontFamily: `"Public Sans", Helvetica, Arial, sans-serif`,
                                        }
                                      : {
                                            color: "var(--dusk-light)",
                                            fontSize: "8px",
                                            fontWeight: "500",
                                            fontFamily: `"Public Sans", Helvetica, Arial, sans-serif`,
                                        },
                              formatter(): string {
                                  return `$${this.axis.defaultLabelFormatter.call(
                                      this
                                  )}`
                              },
                          },
                tickAmount: v2 ? undefined : 5, // allow highcharts to automatically determine the interval based on number of ticks
                gridLineColor: "var(--midnight-10)",
                gridLineWidth: v2 ? 0 : 2,
                showLastLabel: true,
                ...(v2
                    ? {
                          height: "98%",
                          top: "2%",
                      }
                    : {}),
            },
            {
                id: "reservations",
                min: -95,
                max: 1,
                tickLength: 0,
                labels: {
                    enabled: false,
                },
                lineWidth: 0,
                gridLineWidth: 0,
                gridLineColor: "var(--midnight-10)",
            },
        ],
        series: [],
        responsive: {
            rules: [
                {
                    condition: {
                        minWidth: MOBILE_BREAKPOINT,
                    },
                    chartOptions: {
                        xAxis: [
                            {
                                labels: {
                                    style: {
                                        fontSize: "14px",
                                    },
                                },
                            },
                        ],
                        yAxis: [
                            {
                                labels: {
                                    style: {
                                        fontSize: "14px",
                                    },
                                },
                            },
                            {},
                            {},
                        ],
                    },
                },
            ],
        },
    }
}

const isWithUnitPlotY = (
    unitPlotY: number,
    seriesPlotY: number
): {
    isWithinRange: boolean
    above: boolean
} => {
    const above = seriesPlotY <= unitPlotY
    return {
        isWithinRange: above
            ? unitPlotY - seriesPlotY < 23
            : seriesPlotY - unitPlotY < 45,
        above,
    }
}

const isValidBookingProbabiltyType = (
    value: string | undefined
): value is "low" | "medium" | "high" => {
    return ["low", "medium", "high"].includes(value ?? "")
}

const highlightReservationsPoint = (x: number, chart: Highcharts.Chart) => {
    const points =
        chart.series.find(series => series.name === RESERVATION_SERIES)
            ?.points ?? []
    points.forEach(point => {
        // within reservations point
        if (point.x <= x && (point.x2 ?? 0) > x) {
            point.setState("hover")
            document
                .querySelector(".highcharts-point-hover")
                ?.classList.add("highcharts-point-hover-filter")
        } else {
            point.setState("normal")
        }
    })
}

const resetReservationPointHighlight = (chart: Highcharts.Chart) => {
    const points =
        chart.series.find(series => series.name === RESERVATION_SERIES)
            ?.points ?? []
    points.forEach(point => {
        point.setState("normal")
        document
            .querySelector(".highcharts-point-hover-filter")
            ?.classList.remove("highcharts-point-hover-filter")
    })
}

export const chartOptionsHasData = (options: Options) =>
    options.series && options.series.length > 0
