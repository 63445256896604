import classnames from "classnames"
import React, { Fragment } from "react"

// Components
import Loader from "../../../lib/components/Loader/index"
import UnitSumaryViewButtons, { ViewType } from "../UnitSummaryViewButtons"

import styles from "../statements.module.scss"

// Constants
import { Unit } from "@vacasa/owner-api-models/src/legacy/Earnings"
import UnitPicture from "lib/components/Unit/UnitPicture"
import { ObservableMap } from "mobx"
import { useRootService } from "services"
import { Earnings } from "services/earnings/earnings.service"
import {
    displayFormattedNumber,
    getUnitAddress,
    isCanadaSalesTaxExpense,
    shouldShowExpense,
} from "utils/statements/statementsUtil"
import { Summary } from "../UnitSummary"
import UnitDetailsCanadaSalesTaxes from "./UnitDetailsCanadaSalesTaxes"
import UnitDetailsCoOwnerProceeds from "./UnitDetailsCoOwnerProceeds"
import UnitDetailsCredits from "./UnitDetailsCredits"
import UnitDetailsExpenses from "./UnitDetailsExpenses"
import UnitDetailsOverview from "./UnitDetailsOverview"
import UnitDetailsPayments from "./UnitDetailsPayments"
import UnitDetailsRent from "./UnitDetailsRent"
import UnitDetailsReserveLineItem from "./UnitDetailsReserveLineItem"
import { FormattedMessage } from "react-intl"
import { useUnits } from "hooks/units"

const expensesExist = (unit: Unit) => {
    if (!unit.expenses || unit.expenses.length === 0) {
        return false
    }
    return unit.expenses.some(expense => shouldShowExpense(expense))
}

const canadaSalesTaxesExist = (unit: Unit) => {
    if (!unit.expenses || unit.expenses.length === 0) {
        return false
    }
    return unit.expenses.some(expense => isCanadaSalesTaxExpense(expense))
}

const creditsExist = (unit: Unit) => {
    if (!unit.expenses || unit.expenses.length === 0) {
        return false
    }
    return unit.expenses.some(
        expense => expense.type === "Other" && expense.credit !== null
    )
}

const renderSingleUnitBefore = (summary: Summary, currency: string) => {
    if (!summary) return null

    return (
        <Fragment>
            <div className="table-responsive">
                <table className="open table">
                    <thead className={styles["uppercase-header"]}>
                        <tr>
                            <td className={styles["inset"]} />
                            <td colSpan={3}>
                                <FormattedMessage
                                    id="Statements.beginningBalance"
                                    defaultMessage="Beginning Balance"
                                />
                            </td>
                            <td className={styles["text-right"]}>
                                {displayFormattedNumber(
                                    Number(
                                        summary.single_unit_beginning_balance ??
                                            0
                                    ),
                                    currency
                                )}
                            </td>
                            <td className={styles["inset"]} />
                        </tr>
                    </thead>
                </table>
            </div>
            <UnitDetailsPayments currency={currency} summary={summary} />
        </Fragment>
    )
}

const renderSingleUnitAfter = (summary: Summary, currency: string) => {
    if (!summary) return null
    return (
        <Fragment>
            <div className="table-responsive">
                <table className="open table">
                    <thead className={styles["uppercase-header"]}>
                        <tr
                            className={classnames(
                                "table-row-border-top-medium",
                                styles["medium-gray"]
                            )}
                        >
                            <td className={styles["inset"]} />
                            <td colSpan={3}>
                                <FormattedMessage
                                    id="Statements.endingBalance"
                                    defaultMessage="Ending Balance"
                                />
                            </td>
                            <td className="text-right">
                                {displayFormattedNumber(
                                    Number(
                                        summary.single_unit_ending_balance ?? 0
                                    ),
                                    currency
                                )}
                            </td>
                            <td className={styles["inset"]} />
                        </tr>
                    </thead>
                </table>
            </div>
        </Fragment>
    )
}

const isSingleUnit = (
    statement: Earnings | ObservableMap<unknown, unknown> | null
) => {
    if (statement && (statement as Earnings).displaySingleUnit) return true
    return false
}

interface UnitSummaryDetailsViewProps {
    unit: Unit
    changeView: (showDetails: boolean, id: string) => void
    viewKey: string
    summary: Summary
    currency: string
    scrollToFAQ: () => void
    onFAQOpen: () => void
}

const UnitSummaryDetailsView: React.FC<UnitSummaryDetailsViewProps> = props => {
    const {
        unit,
        changeView,
        viewKey,
        summary,
        currency,
        scrollToFAQ,
        onFAQOpen,
    } = props

    const unitsQuery = useUnits()

    const {
        earningsService: { loadingSummary, statement },
    } = useRootService()
    const singleUnitOwner = isSingleUnit(statement)
    const unitAddress = getUnitAddress(unit)

    if (loadingSummary) {
        return (
            <Fragment>
                <Loader />
            </Fragment>
        )
    }
    return (
        <Fragment>
            <div className="unit-summary-header row">
                <div className="col-lg-8">
                    <UnitPicture
                        unit={unitsQuery.data?.find(
                            u => String(u.id) === String(unit.id)
                        )}
                        className="unit-details-img"
                    />
                    <div className="unit-details-header-left">
                        <h3 className="unit-details-primary">
                            <span>{unit.unit_name}</span>
                        </h3>
                        <div className="unit-details-secondary">
                            <p>{unitAddress}</p>
                        </div>
                    </div>
                </div>
                <UnitSumaryViewButtons
                    changeView={changeView}
                    view={ViewType.Details}
                    viewKey={viewKey}
                />
            </div>
            <UnitDetailsOverview unit={unit} currency={currency} />
            {/* Single Unit Owner stuff. */}
            {singleUnitOwner && renderSingleUnitBefore(summary, currency)}
            <UnitDetailsRent unit={unit} currency={currency} />
            {expensesExist(unit) && (
                <UnitDetailsExpenses unit={unit} currency={currency} />
            )}

            {canadaSalesTaxesExist(unit) && (
                <UnitDetailsCanadaSalesTaxes unit={unit} currency={currency} />
            )}

            {creditsExist(unit) && (
                <UnitDetailsCredits unit={unit} currency={currency} />
            )}

            {summary && summary.is_co_owner && (
                <UnitDetailsCoOwnerProceeds unit={unit} currency={currency} />
            )}
            {/* Single Unit Owner stuff. */}
            {singleUnitOwner && renderSingleUnitAfter(summary, currency)}
            <UnitDetailsReserveLineItem
                currency={currency}
                unit={unit}
                singleUnitOwner={singleUnitOwner}
                scrollToFAQ={scrollToFAQ}
                onFAQOpen={onFAQOpen}
            />
            <div className="table-responsive">
                <table className="table">
                    <tbody className={classnames(styles["total"])}>
                        <tr>
                            <td className={styles["inset"]} />
                            <td>
                                {singleUnitOwner ? (
                                    <FormattedMessage
                                        id="Statements.proceedsBalance"
                                        defaultMessage="Proceeds Balance"
                                    />
                                ) : (
                                    <FormattedMessage
                                        id="Statements.proceeds"
                                        defaultMessage="Proceeds"
                                    />
                                )}
                            </td>
                            <td className={styles["text-right"]}>
                                {displayFormattedNumber(
                                    Number(
                                        singleUnitOwner && summary
                                            ? summary.ending_balance
                                            : unit.net_proceeds ||
                                                  unit.ending_balance
                                    ),
                                    currency
                                )}
                            </td>
                            <td className={styles["inset"]} />
                        </tr>
                    </tbody>
                </table>
            </div>
        </Fragment>
    )
}

export default UnitSummaryDetailsView
