import { ChatChannelState } from "@twilio/flex-webchat-ui"
import { IconChatOwnerAvatar } from "assets"
import { ReactComponent as IconChatSupportAvatar } from "assets/icon-chat-support-avatar.svg"
import classNames from "classnames"
import { format } from "date-fns"
import { getClassStyle } from "utils/styles/styleWrapper"
import styles from "./MessageBubble.module.scss"

interface MessageBubbleProps {
    message?: ChatChannelState.MessageState
    member?: ChatChannelState.MemberState
}

export const MessageBubble: React.FC<MessageBubbleProps> = ({
    member,
    message,
}) => {
    const time: string | undefined =
        message && message.source.timestamp
            ? format(message.source.timestamp, "hh:mm a")
            : undefined

    return (
        <div className={classNames(styles["message-bubble"])}>
            {!message?.isFromMe && (
                <span className={styles["message-bubble__icon-left"]}>
                    <IconChatSupportAvatar />
                </span>
            )}
            <div
                className={classNames(styles["message-bubble__content"], {
                    [getClassStyle(styles["message-bubble__content--left"])]:
                        !message?.isFromMe,
                    [getClassStyle(styles["message-bubble__content--right"])]:
                        message?.isFromMe,
                })}
            >
                <div
                    className={classNames(
                        styles["message-bubble__meta"],
                        "font-bold",
                        "text-xxs"
                    )}
                >
                    <span className={styles["message-bubble__author"]}>
                        {member?.friendlyName}
                    </span>
                    <span
                        className={classNames(
                            "font-normal",
                            "leading-none",
                            styles["message-bubble__time"],
                            {
                                [getClassStyle(
                                    styles["message-bubble__time--left"]
                                )]: !message?.isFromMe,
                                [getClassStyle(
                                    styles["message-bubble__time--right"]
                                )]: message?.isFromMe,
                            }
                        )}
                    >
                        {time}
                    </span>
                </div>
                <div className={styles["message-bubble__message"]}>
                    {message?.source.body}
                </div>
            </div>
            {message?.isFromMe && (
                <span className={styles["message-bubble__icon-right"]}>
                    <IconChatOwnerAvatar />
                </span>
            )}
        </div>
    )
}
