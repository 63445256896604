import {
    createContext,
    memo,
    useContext,
    useEffect,
    useMemo,
    useReducer,
    useState,
} from "react"
import { ChartSelector } from "./ChartSelector"
import { ChartAPI, ChartState, ChartType } from "./types"
import { useOwnerFeatureFlag } from "hooks/owner-feature-flag"
import { Card, CardContent } from "components/Card"
import { RevenueChart } from "./RevenueChart"
import {
    MarketRateComparisonChart,
    MarketRateComparisonChartV2,
} from "./MarketRateComparisonChart"
import LoggingService from "services/logging/logging.service"
import { useCurrentUnit } from "hooks/units"
import { OptimizelyFeature } from "@optimizely/react-sdk"
import { useSearchParams } from "react-router-dom"
import { LastRefreshedOn } from "./MarketRateComparisonChart/LastRefreshedOn"
import styles from "./Chart.module.scss"
import { SubmitQuestion } from "./MarketRateComparisonChart/SubmitQuestion"
import classNames from "classnames"

export const Chart = memo(() => {
    const { unit } = useCurrentUnit()
    const [searchParams, setSearchParams] = useSearchParams()
    const unitMarketCompChartEnabled =
        unit?.attributes.marketCompChartEnabled ?? false

    const queryParamChartType = searchParams.get("chart")

    const queryParamChart =
        queryParamChartType && validateChartType(queryParamChartType)
            ? queryParamChartType
            : undefined

    const [state, dispatch] = useReducer(reducer, {
        chartSelector: {
            activeChart:
                queryParamChart ?? "gross-rent",
        },
    })

    const api = useMemo(() => {
        return {
            setType: (type: ChartType) => {
                // clear the old chart type from query param
                if (searchParams.has("chart")) {
                    searchParams.delete("chart")
                    setSearchParams(searchParams)
                }
                dispatch({ type: "setChartType", chart: type })
            },
        }
    }, [searchParams, setSearchParams])

    useEffect(() => {
        if (unitMarketCompChartEnabled) return
        if (state.chartSelector.activeChart === "market-comp") {
            // clear the old chart type from query param
            if (searchParams.has("chart")) {
                searchParams.delete("chart")
                setSearchParams(searchParams)
            }
            dispatch({ type: "setChartType", chart: "gross-rent" })
        }
    }, [
        searchParams,
        setSearchParams,
        state.chartSelector.activeChart,
        unitMarketCompChartEnabled,
    ])

    const [lastRefreshedOn, setLastRefreshedOn] = useState<string>("")

    const isMarketRateV2 = useOwnerFeatureFlag("market-rates-v2-features")
        ?.isEnabled

    return (
        <ChartAPIContext.Provider value={api}>
            <ChartStateContext.Provider value={state}>
                <Card>
                    <CardContent>
                        <div className={styles.header}>
                            <ChartSelector />
                            {isMarketRateV2 &&
                                state.chartSelector.activeChart ===
                                    "market-comp" && (
                                    <OptimizelyFeature feature="core-metrics">
                                        {isEnabled =>
                                            isEnabled ? (
                                                <div
                                                    className={classNames(
                                                        styles.submitQuestion,
                                                        "antialised",
                                                        {
                                                            hide: !lastRefreshedOn,
                                                        }
                                                    )}
                                                >
                                                    <SubmitQuestion />
                                                    {lastRefreshedOn && (
                                                        <LastRefreshedOn
                                                            date={
                                                                lastRefreshedOn
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            ) : (
                                                <></>
                                            )
                                        }
                                    </OptimizelyFeature>
                                )}
                        </div>

                        {(state.chartSelector.activeChart === "gross-rent" ||
                            state.chartSelector.activeChart === "net-rent") && (
                            <RevenueChart
                                activeChart={state.chartSelector.activeChart}
                            />
                        )}
                        {state.chartSelector.activeChart === "market-comp" &&
                            (isMarketRateV2 ? (
                                <MarketRateComparisonChartV2
                                    onLoaded={data =>
                                        setLastRefreshedOn(
                                            data.meta.lastRefreshedOn ?? ""
                                        )
                                    }
                                />
                            ) : (
                                <MarketRateComparisonChart />
                            ))}
                    </CardContent>
                </Card>
            </ChartStateContext.Provider>
        </ChartAPIContext.Provider>
    )
})

const ChartStateContext = createContext<ChartState>({
    chartSelector: {
        activeChart: "gross-rent",
    },
})
const ChartAPIContext = createContext<ChartAPI>({
    setType: () => {
        LoggingService.error({ message: "ChartAPIContext not implemented" })
    },
})

export const useChartState = () => useContext<ChartState>(ChartStateContext)

export const useChartAPI = () => useContext<ChartAPI>(ChartAPIContext)

type Actions = { type: "setChartType"; chart: ChartType }
const reducer = (prevState: ChartState, action: Actions): ChartState => {
    switch (action.type) {
        case "setChartType": {
            return {
                ...prevState,
                chartSelector: {
                    ...prevState.chartSelector,
                    activeChart: action.chart,
                },
            }
        }
    }
}

const validateChartType = (type: string | null): type is ChartType => {
    if (!type) return false
    return ["market-comp", "gross-rent", "net-rent"].includes(type)
}
