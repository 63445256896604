import classNames from "classnames"
import { omit } from "lodash"
import React, { useEffect, useRef } from "react"
import { DayPickerRangeController, FocusedInputShape } from "react-dates"
import { useIntl } from "react-intl"
import { Alert } from "../../../../components/Alert"
import { useDateRangeContext } from "../state/DateRange.context"
import {
    isDateRangeAvailable,
    isDayBlocked,
} from "../validation/DateValidation"
import styles from "./DateRangeInputPicker.module.scss"
import {
    DateRangeInputPickerProps,
    OMITTED_PROPS,
} from "./DateRangeInputPicker.props"
import { addDays, formatISO, getDate, getMonth } from "date-fns"
import moment from "moment"

const BLOCKED_MONTHS_BUFFER = 3 // Block the current and next 2 months of unavailable dates

function DateRangeInputPicker(props: DateRangeInputPickerProps): JSX.Element {
    const intl = useIntl()

    // Share context with main calendar
    const { dateRangeState: state, dispatchDateRangeAction: dispatch } =
        useDateRangeContext()

    useEffect(() => {
        dispatch({
            payload: !props.disableStartDate,
            type: "SET_CAN_EDIT_START_DATE",
        })

        dispatch({
            payload:
                props.disableStartDate || (!!state.startDate && !state.endDate)
                    ? "endDate"
                    : "startDate",
            type: "UPDATE_FOCUSED_INPUT",
        })
    }, [dispatch]) // eslint-disable-line react-hooks/exhaustive-deps

    // TODO remove props overlapping with state from Date Range context
    const { blockAllDays, calendarAvailability, handleDatesChange } = props

    function onDatesChange({
        startDate,
        endDate,
    }: {
        startDate: Date | null
        endDate: Date | null
    }) {
        dispatch({
            type: "UPDATE_START_DATE",
            payload: props.disableStartDate
                ? props.initialStartDate
                : startDate,
        })
        dispatch({
            type: "UPDATE_END_DATE",
            payload: endDate,
        })
        dispatch({
            type: "UPDATE_ERROR_MESSAGE",
            payload:
                startDate &&
                endDate &&
                !state.focusedInput && // error should be determined after selecting a start and end date
                !isDateRangeAvailable(
                    startDate,
                    endDate,
                    blockAllDays,
                    calendarAvailability
                )
                    ? `${intl.formatMessage({
                          id: "CalendarPage.OwnerHolds.warningBanner",
                          defaultMessage:
                              "One or more of the dates for your hold are unavailable. Please check your selection and try again.",
                      })}`
                    : null,
        })
    }

    useEffect(() => {
        if (!handleDatesChange) {
            return
        }
        handleDatesChange(
            state.canEditStartDate ? state.startDate : props.initialStartDate,
            state.endDate
        )
    }, [
        handleDatesChange,
        state.startDate,
        state.endDate,
        props.initialStartDate,
        state.canEditStartDate,
    ])

    function onFocusChange(focusedInput: FocusedInputShape | null) {
        // Force the focusedInput to always be truthy so that dates are always selectable
        dispatch({
            type: "UPDATE_FOCUSED_INPUT",
            payload: focusedInput ?? "startDate",
        })
        // Clear endDate if already selected, to make it obvious which day is being selected on hover
        if (focusedInput === "endDate" && state.startDate && state.endDate) {
            dispatch({
                type: "UPDATE_END_DATE",
                payload: null,
            })
        }
    }

    function calendarDayContentsRenderer(day: Date): React.ReactNode {
        const availability =
            props.calendarAvailability[
                formatISO(day, { representation: "date" })
            ]
        const blocked = isDayBlocked(day, blockAllDays, calendarAvailability)
        const currency = props.ownerCurrency || "USD"

        let rate: number | string = availability?.rate || 0
        if (!blocked) {
            rate = intl.formatNumber(rate, {
                style: "currency",
                currency: currency,
                maximumSignificantDigits: 4,
            })
        }

        const clonedDay = new Date(day.valueOf()) // prevent memory leak
        const isDayBeforeCheckOut =
            props.calendarAvailability[
                formatISO(addDays(clonedDay, 1), { representation: "date" })
            ]?.isCheckoutDay

        return (
            <>
                <div
                    className={classNames("CustomCalendarDay", {
                        checkInDay: availability?.isCheckinDay,
                        checkOutDay: availability?.isCheckoutDay,
                        dayBeforeCheckOut: isDayBeforeCheckOut,
                    })}
                >
                    <div className={classNames("CustomCalendarDay__content")}>
                        <span className="CustomCalendarDay__date">
                            {day && getDate(day)}
                        </span>
                        {!blocked && !availability?.isCheckinDay && (
                            <span
                                className={classNames(
                                    "CustomCalendarDay__rate",
                                    styles.drpFormattedRate
                                )}
                            >
                                {rate}
                            </span>
                        )}
                    </div>
                </div>
                <div
                    className={classNames(
                        "daySelectedCircleBorder",
                        styles.drpCircleBorder
                    )}
                />
            </>
        )
    }

    const omitted = omit(props, OMITTED_PROPS)

    // Renders error message below the calendar
    const calendarInfoRenderer = state.errorMessage
        ? () => (
              <Alert variant="alertness">
                  <Alert.Icon />
                  <Alert.Description>{state.errorMessage}</Alert.Description>
              </Alert>
          )
        : () => <></>

    // Calendar sizing
    const drpContainerRef = useRef<HTMLDivElement>(null)
    const numMonths = 1

    return (
        <div
            ref={drpContainerRef}
            className={classNames(
                "drpContainer",
                {
                    endDateSelected: state.endDate,
                    showBorder: state.focusedInput,
                },
                styles.drpContainer
            )}
        >
            <DayPickerRangeController
                {...omitted}
                daySize={46}
                initialVisibleMonth={null}
                numberOfMonths={numMonths}
                onDatesChange={({ startDate, endDate }) =>
                    onDatesChange({
                        startDate: startDate?.toDate() ?? null,
                        endDate: endDate?.toDate() ?? null,
                    })
                }
                onFocusChange={onFocusChange}
                focusedInput={state.focusedInput}
                startDate={
                    props.disableStartDate
                        ? props.initialStartDate
                            ? moment(props.initialStartDate)
                            : null
                        : state.startDate
                        ? moment(state.startDate)
                        : null
                }
                endDate={state.endDate ? moment(state.endDate) : null}
                renderDayContents={day =>
                    calendarDayContentsRenderer(day.toDate())
                }
                isDayBlocked={(date): boolean =>
                    isDayBlocked(
                        date.toDate(),
                        blockAllDays,
                        calendarAvailability,
                        state.startDate,
                        state.endDate,
                        getMonth(state.startDate ?? 0),
                        BLOCKED_MONTHS_BUFFER,
                        props.disableStartDate
                    )
                }
                hideKeyboardShortcutsPanel={true}
                navPrev={
                    <div className="CustomNavButton CustomNavButton__Prev" />
                }
                navNext={
                    <div className="CustomNavButton CustomNavButton__Next" />
                }
                renderCalendarInfo={calendarInfoRenderer}
                disabled={props.disableStartDate ? "startDate" : undefined}
                horizontalMonthPadding={0}
            />
        </div>
    )
}

export default DateRangeInputPicker
