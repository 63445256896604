import { observer } from "mobx-react"
import NoMatch from "../notfound/NotFound"
import { Performance } from "./Performance"
import { isOwnerInEurope } from "utils/user"
import { useUser } from "hooks/user"
import React from "react"
import { Outlet } from "react-router-dom"

export const PerformancePageRoute = observer(() => {
    const { user } = useUser()

    if (!user) return null

    if (isOwnerInEurope(user)) {
        return (
            <div className="container-fluid">
                <NoMatch />
            </div>
        )
    }
    return (
        <>
            <Outlet />
            <Performance />
        </>
    )
})

export { Performance }
