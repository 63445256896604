import segmentService from "../segment.service"
import {
    GO_PAPERLESS_MODAL_GO_PAPERLESS_CLICKED,
    GO_PAPERLESS_MODAL_DISMISSED,
    GO_PAPERLESS_MODAL_PRESENTED,
    GO_PAPERLESS_MODAL_SKIP_FOR_NOW_CLICKED,
    GO_PAPERLESS_MODAL_GO_PAPERLESS_STATUS_UPDATED,
} from "./paperless1099TrackingConstants"

export const trackModalDismissed = () => {
    segmentService.track(GO_PAPERLESS_MODAL_DISMISSED, {})
}

export const trackModalPresented = () => {
    segmentService.track(GO_PAPERLESS_MODAL_PRESENTED, {})
}

export const track1099NotificationSkipped = () => {
    segmentService.track(GO_PAPERLESS_MODAL_SKIP_FOR_NOW_CLICKED, {})
}

export const trackGoPaperlessClicked = () => {
    segmentService.track(GO_PAPERLESS_MODAL_GO_PAPERLESS_CLICKED, {})
}

export const trackGoPaperlessStatusUpdated = () => {
    segmentService.track(GO_PAPERLESS_MODAL_GO_PAPERLESS_STATUS_UPDATED, {})
}
