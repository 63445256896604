import { JSONAPIResource } from "@vacasa/owner-api-models"
import { authAxios } from "../../api/authAxios"
import { RevenueImpactData } from "@vacasa/owner-api-models"

export type RevenueImpactRequest = {
    unitId: string
    startDate: string
    endDate: string
    minRate: number
    signal?: AbortSignal
}

export type RevenueImpactResponse = {
    data: JSONAPIResource<RevenueImpactData>
}

const fetchRevenueImpact = ({
    unitId,
    startDate,
    endDate,
    minRate,
    signal,
}: RevenueImpactRequest): Promise<RevenueImpactData> =>
    authAxios
        .get<RevenueImpactResponse>(`/v1/revenue-impact`, {
            params: {
                unitId,
                startDate,
                endDate,
                minRate,
            },
            signal,
        })
        .then(response => response.data.data.attributes)

export { fetchRevenueImpact }
