import { useRef, Key } from "react"
import { format, getYear } from "date-fns"
import cx from "classnames"
import { observer } from "mobx-react"

import { CalendarMonthPhrases } from "../../../defaultPhrases"
import CalendarYearMonth from "./CalendarYearMonth"
import { useRootService } from "../../../services"
import {
    isSameMonth,
    getCalendarYearMonths,
} from "../../../utils/calendar/calendarUtil"
import {
    MONTH_WIDTH_SIZE,
    MONTH_HEIGHT_SIZE,
    HORIZONTAL_ORIENTATION,
    VERTICAL_ORIENTATION,
    VERTICAL_SCROLLABLE,
} from "../../../Constants"
import { useNavigate } from "react-router-dom"
import { useContactId, useIsEmployee } from "hooks/user"
import { useCurrentUnit } from "hooks/units"

interface CalendarYearProps {
    year: Date
    isVisible?: boolean
    orientation?: string
    monthWidthSize?: number
    monthHeightSize?: number
    onMonthClick?: (day: Date) => void
    renderMonth?: (month: Date) => JSX.Element
    focusedDate?: Date | undefined | null // indicates focusable day
    isFocused?: boolean // indicates whether or not to move focus to focusable day
    phrases: typeof CalendarMonthPhrases
}

const CalendarYear: React.FC<CalendarYearProps> = ({
    year = new Date(),
    isVisible = true,
    orientation = HORIZONTAL_ORIENTATION,
    monthWidthSize = MONTH_WIDTH_SIZE,
    monthHeightSize = MONTH_HEIGHT_SIZE,
    onMonthClick,
    renderMonth,
    focusedDate,
    isFocused = false,
    phrases = CalendarMonthPhrases,
}) => {
    const isEmployee = useIsEmployee()
    const contactId = useContactId() ?? ""
    const months = getCalendarYearMonths(year)
    const calendarMonthClasses = cx("CalendarYear", {
        "CalendarYear--horizontal": orientation === HORIZONTAL_ORIENTATION,
        "CalendarYear--vertical": orientation === VERTICAL_ORIENTATION,
        "CalendarYear--vertical-scrollable":
            orientation === VERTICAL_SCROLLABLE,
    })
    const { earningsService } = useRootService()
    const { unitId } = useCurrentUnit()

    let isYearSummaryDisabled = true
    if (
        earningsService.statementDates &&
        earningsService.statementDates.length > 0
    ) {
        for (const date of earningsService.statementDates) {
            if (date.Year === getYear(year)) {
                isYearSummaryDisabled = false
                break
            }
        }
    }

    const yearTitle = useRef(null)
    const navigate = useNavigate()

    return (
        <div
            className={calendarMonthClasses}
            data-visible={isVisible}
            data-testid={"calendar-year"}
        >
            <table>
                <caption className="CalendarYear__caption js-CalendarYear__caption">
                    <strong ref={yearTitle}>{format(year, "yyyy")}</strong>
                </caption>
                <tbody className="js-CalendarYear__grid">
                    {months.map(
                        (monthBlock: Date[], i: Key | null | undefined) => (
                            <tr key={i}>
                                {monthBlock.map((month, index) => {
                                    return (
                                        <CalendarYearMonth
                                            month={month}
                                            monthWidthSize={monthWidthSize}
                                            monthHeightSize={monthHeightSize}
                                            tabIndex={
                                                isVisible &&
                                                isSameMonth(
                                                    month,
                                                    focusedDate ?? undefined
                                                )
                                                    ? 0
                                                    : -1
                                            }
                                            isFocused={isFocused}
                                            key={index}
                                            onMonthClick={onMonthClick}
                                            renderMonth={renderMonth}
                                            phrases={phrases}
                                        />
                                    )
                                })}
                            </tr>
                        )
                    )}
                    <tr>
                        <td>
                            <span>
                                <button
                                    aria-label="YearSummary"
                                    className={
                                        "link primary year-summary" +
                                        (isYearSummaryDisabled
                                            ? " disabled"
                                            : "")
                                    }
                                    disabled={isYearSummaryDisabled}
                                    onClick={() => {
                                        if (!isYearSummaryDisabled) {
                                            earningsService.fetchStatementByUnitID(
                                                contactId,
                                                unitId,
                                                null,
                                                null,
                                                year,
                                                isEmployee
                                            )
                                        }
                                        navigate(
                                            `/statements/${format(
                                                year,
                                                "yyyy"
                                            )}`
                                        )
                                    }}
                                >
                                    View Year Summary
                                </button>
                            </span>
                        </td>
                    </tr>
                    <tr />
                </tbody>
            </table>
        </div>
    )
}

export default observer(CalendarYear)
