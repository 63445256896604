const guestworks = {
    homeInfo: {
        footer: "As a Vacasa Guestworks homeowner, you have access to automated housekeeping tools and more for your vacation rental.",
        cta: "Go to Guestworks",
    },
    maintenance: {
        unavailable: {
            title: "Maintenance Unavailable",
            description:
                "In Guestworks you have access to features like housekeeping management, ticket viewing, door code generation, and more.",
            cta: "Go to Guestworks",
        },
    },
    guestworks: "Guestworks",
    protectionProgram: {
        homeownerGuide: "Homeowner Guide",
        enrolledDescription:
            "You are enrolled with Accommodations Protection Program.",
        notEnrolledDescription:
            "You are not enrolled with Accommodations Protection Program.",
    },
}

export default guestworks
