import { SHOW_MIN_RATE } from "constants/preferences.constants"
import { useOwnerUnitPreferencesMutation } from "hooks/owner-preferences"
import { FormattedMessage, useIntl } from "react-intl"
import { useUnitMinRateChangeMutation } from "hooks/unit-min-rate/useUnitMinRate"
import { ChangeView } from "./UnitMinRateNotification"
import styles from "./UnitMinRateNotification.module.scss"
import {
    trackMinRateEmailUsClicked,
    trackMinRatePhoneNumClicked,
    trackMinRateRemindMeLaterClicked,
} from "services/segment/performance/performanceTracking"
import { useCurrentUnit } from "hooks/units"

export const ContactView = (props: ChangeView): JSX.Element => {
    const { unitId } = useCurrentUnit()
    const ownerUnitPreferencesMutation = useOwnerUnitPreferencesMutation()
    const intl = useIntl()

    const OwnerAccountPhoneNumber = intl.formatMessage({
        id: "Shared.ownerAccountsPhoneNumber",
        defaultMessage: "+1 971 254 3001",
        description: "Phone number for owner account support",
    })
    const supportNumberLink = `tel:${OwnerAccountPhoneNumber}`

    const minRateChangeMutation = useUnitMinRateChangeMutation()
    return (
        <div className={styles.minRateInfoContainer} data-testid="contact-view">
            <div className={styles.adjustMinRateInfo}>
                <FormattedMessage
                    id="Performance.adjustMinRateInfo"
                    defaultMessage="Call {phoneNumber} or {submitTicket} to adjust your minimum rate."
                    values={{
                        phoneNumber: (
                            <strong>
                                <a
                                    href={supportNumberLink}
                                    onClick={() => {
                                        props.changeView("none")
                                        trackMinRatePhoneNumClicked()
                                        ownerUnitPreferencesMutation.mutate({
                                            unitId: unitId ?? "",
                                            id: SHOW_MIN_RATE,
                                            value: false,
                                        })
                                    }}
                                >
                                    {OwnerAccountPhoneNumber}
                                </a>
                            </strong>
                        ),
                        submitTicket: (
                            <strong>
                                <button
                                    className={styles.submitTicket}
                                    onClick={() => {
                                        minRateChangeMutation.mutate(
                                            {
                                                unitId: unitId ?? "",
                                            },
                                            {
                                                onSuccess: () => {
                                                    ownerUnitPreferencesMutation.mutate(
                                                        {
                                                            unitId:
                                                                unitId ?? "",
                                                            id: SHOW_MIN_RATE,
                                                            value: false,
                                                        }
                                                    )
                                                    props.changeView(
                                                        "completed"
                                                    )
                                                },
                                            }
                                        )
                                        trackMinRateEmailUsClicked()
                                    }}
                                >
                                    <FormattedMessage
                                        id="Performance.submitticket"
                                        defaultMessage="submit a ticket"
                                    />
                                </button>
                            </strong>
                        ),
                    }}
                />
            </div>
            <button
                data-testid="button-remind-me-later"
                className={styles.remindButton}
                onClick={() => {
                    ownerUnitPreferencesMutation.mutate({
                        unitId: unitId ?? "",
                        id: SHOW_MIN_RATE,
                        value: false,
                    })
                    trackMinRateRemindMeLaterClicked()
                    props.changeView("later")
                }}
            >
                <FormattedMessage
                    id="Performance.remindMeLater"
                    defaultMessage="Remind Me Later"
                />
            </button>
        </div>
    )
}
