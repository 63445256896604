import LoggingService from "services/logging/logging.service"
import { UserSession } from "./types"
import storageService from "services/storage.service"
import { merge } from "lodash"
import { CONTACT_IDS } from "Constants"

const SESSION_CONFIG_KEY = "owners-portal:owner"

/**
 * Get the session config from storage
 * @returns
 */
export const getSessionConfig = (): UserSession => {
    try {
        const sessionConfig =
            storageService.sessionStorage.getItem(SESSION_CONFIG_KEY)
        if (!sessionConfig) {
            return DEFAULT_SESSION_CONFIG
        }
        return merge({}, DEFAULT_SESSION_CONFIG, JSON.parse(sessionConfig))
    } catch (e) {
        LoggingService.error({
            error: e,
            message: "Failed to parse stored session config",
        })
        return DEFAULT_SESSION_CONFIG
    }
}

/**
 *  Update the session config
 * @param config
 */
export const updateSessionConfig = (config: Partial<UserSession>) => {
    try {
        const currentConfig = getSessionConfig()

        if (config.contactIds !== undefined) {
            if (config.contactIds) {
                storageService.sessionStorage.setItem(
                    CONTACT_IDS,
                    JSON.stringify(config.contactIds)
                )
            } else {
                storageService.sessionStorage.removeItem(CONTACT_IDS)
            }
        }

        storageService.sessionStorage.setItem(
            SESSION_CONFIG_KEY,
            JSON.stringify(merge({}, currentConfig, config))
        )
    } catch (e) {
        LoggingService.error({
            error: e,
            message: "Failed to update session config",
        })
    }
}

/**
 * Reset the session config to the default
 */
export const resetSessionConfig = () => {
    storageService.sessionStorage.setItem(
        SESSION_CONFIG_KEY,
        JSON.stringify(DEFAULT_SESSION_CONFIG)
    )
    storageService.sessionStorage.removeItem(CONTACT_IDS)
}

/**
 * Remove the session config from storage
 */
export const clearSessionConfig = () => {
    storageService.sessionStorage.removeItem(SESSION_CONFIG_KEY)
    storageService.sessionStorage.removeItem(CONTACT_IDS)
}

const DEFAULT_SESSION_CONFIG: UserSession = {
    contactIds: null,
    unitId: null,
}
