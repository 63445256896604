import {
    JSONAPIDocument,
    ProgramType,
    UnitProgram,
} from "@vacasa/owner-api-models"

import { authAxios } from "../../api/authAxios"

type FetchUnitProgramsRequest = {
    unitId: string
    programType?: ProgramType
    signal?: AbortSignal
    includeNotEnrolled?: boolean
}

const fetchUnitPrograms = ({
    unitId,
    programType,
    signal,
    includeNotEnrolled,
}: FetchUnitProgramsRequest): Promise<JSONAPIDocument<UnitProgram, []>> =>
    authAxios
        .get<JSONAPIDocument<UnitProgram, []>>(
            `/v1/owners/units/${unitId}/programs`,
            {
                params: { programType, includeNotEnrolled },
                signal,
            }
        )
        .then(response => response.data)

export { fetchUnitPrograms }
