import styles from "./Legend.module.scss"
import classNames from "classnames"
import { Tooltip } from "lib/components/Tooltip/Tooltip"
import { FormattedMessage, useIntl } from "react-intl"
import { Info } from "react-feather"

export const Legend: React.FC = () => {
    const intl = useIntl()

    return (
        <div className={styles.legend}>
            <div className={styles.legendKeyItem}>
                <div
                    className={classNames(
                        styles.legendKey,
                        styles.legendKeyHome
                    )}
                />
                <div className="type-heading-extra-small-caps">
                    <div className="flex items-center">
                        <FormattedMessage
                            id="Dashboard.charts.marketRate.legend.yourHome"
                            defaultMessage="Your Home"
                        />
                        <div
                            className={classNames(
                                styles.tooltipContainer,
                                styles.yourHomeTooltip
                            )}
                        >
                            <Tooltip
                                title={intl.formatMessage({
                                    id: "Dashboard.charts.marketRate.tooltips.yourHome.title",
                                    defaultMessage: "Your Home",
                                })}
                                body={
                                    <FormattedMessage
                                        id="Dashboard.charts.marketRate.tooltips.yourHome.body"
                                        defaultMessage="The blue line represents your home's daily rate. It may be higher or lower than comparable homes based on factors like your home's minimum rate, local market conditions and demand, along with many others."
                                    />
                                }
                            >
                                <Info
                                    className={styles.tooltipTrigger}
                                    width={16}
                                    height={16}
                                />
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.legendKeyItem}>
                <div
                    className={classNames(
                        styles.legendKey,
                        styles.legendKeyMarket
                    )}
                />
                <div className="type-heading-extra-small-caps">
                    <div
                        className={classNames(
                            "flex items-center",
                            styles.marketRangeLegend
                        )}
                    >
                        <FormattedMessage
                            id="Dashboard.charts.marketRate.legend.marketRange"
                            defaultMessage="Market Range"
                        />
                        <div
                            className={classNames(
                                styles.tooltipContainer,
                                styles.marketRangeTooltip
                            )}
                        >
                            <Tooltip
                                title={intl.formatMessage({
                                    id: "Dashboard.charts.marketRate.tooltips.marketRange.title",
                                    defaultMessage: "Market Range",
                                })}
                                body={
                                    <FormattedMessage
                                        id="Dashboard.charts.marketRate.tooltips.marketRange.body"
                                        defaultMessage="The shaded blue area shows the minimum and maximum nightly rates for homes in your area that guests are most likely to compare to yours. This data updates daily."
                                    />
                                }
                            >
                                <Info
                                    className={styles.tooltipTrigger}
                                    width={16}
                                    height={16}
                                />
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.legendKeyItem}>
                <div
                    className={classNames(
                        styles.legendKey,
                        styles.legendKeyReservation,
                        styles.legendKeyOwnerHold
                    )}
                />
                <div className="type-heading-extra-small-caps">
                    <span>
                        <FormattedMessage
                            id="Dashboard.charts.marketRate.legend.ownerHold"
                            defaultMessage="Owner Hold"
                        />
                    </span>
                    <span>
                        <FormattedMessage
                            id="Dashboard.charts.marketRate.legend.owner"
                            defaultMessage="Owner"
                        />
                    </span>
                </div>
            </div>
            <div className={styles.legendKeyItem}>
                <div
                    className={classNames(
                        styles.legendKey,
                        styles.legendKeyReservation,
                        styles.legendKeyGuestStay
                    )}
                />
                <div className="type-heading-extra-small-caps">
                    <span>
                        <FormattedMessage
                            id="Dashboard.charts.marketRate.legend.guestStay"
                            defaultMessage="Guest Stay"
                        />
                    </span>
                    <span>
                        <FormattedMessage
                            id="Dashboard.charts.marketRate.legend.guest"
                            defaultMessage="Guest"
                        />
                    </span>
                </div>
            </div>
            <div className={styles.legendKeyItem}>
                <div
                    className={classNames(
                        styles.legendKey,
                        styles.legendKeyReservation,
                        styles.legendKeyVacasaHold
                    )}
                />
                <div className="type-heading-extra-small-caps">
                    <span>
                        <FormattedMessage
                            id="Dashboard.charts.marketRate.legend.vacasaHold"
                            defaultMessage="Vacasa Hold"
                        />
                    </span>
                    <span>
                        <FormattedMessage
                            id="Dashboard.charts.marketRate.legend.vacasa"
                            defaultMessage="Vacasa"
                        />
                    </span>
                </div>
            </div>
        </div>
    )
}
