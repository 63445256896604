import { AxiosError } from "axios"
import { useQuery, UseQueryResult } from "react-query"
import { useContactId } from "hooks/user"
import { fetchStatementYears } from "./fetchStatementYears"

export const useStatementYears = (): UseQueryResult<number[], AxiosError> => {
    const contactId = useContactId()

    return useQuery(
        ["statement-years", contactId],
        ({ signal }) =>
            fetchStatementYears({ contactId: contactId as string, signal }),
        { enabled: !!contactId }
    )
}
