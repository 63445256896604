import React, { useRef, useState, useEffect } from "react"
import classNames from "classnames"
import { LinkSecondary } from "../Buttons/BaseButton"
import styles from "./ExpandableText.module.scss"
import { determineIsTruncated } from "utils/text/utils"
import { FormattedMessage } from "react-intl"
import { ChevronRight } from "react-feather"
import { trackGuestReviewViewReservationClicked } from "services/segment/performance/performanceTracking"

interface TextProps {
    numberOfLines?: number
    text: string
    labelRef: React.RefObject<HTMLSpanElement>
    expanded: boolean
}

interface ViewReservationLinkProps {
    reservationId: number
}

interface ExpandableTextProps {
    collapseLabel: JSX.Element
    expandLabel: JSX.Element
    numberOfLines?: number
    text: string
    onExpandHandler?: () => void
    showViewReservation?: boolean
    reservationUrl?: string
    reservationId?: number
}

const Text: React.FC<TextProps> = ({
    text,
    numberOfLines,
    labelRef,
    expanded,
}: TextProps) => {
    return (
        <span
            className={classNames(styles.label, "small", "regular")}
            style={!expanded ? { WebkitLineClamp: numberOfLines } : {}}
            ref={labelRef}
        >
            {/* replace all breaks with the actual empty line, 
                then strip all the rest of html formatting */}
            {text
                .split("<br />")
                .join(String.fromCharCode(13, 10))
                .replace(/<[^>]*>?/gm, "")}
        </span>
    )
}

const ViewReservationLink: React.FC<ViewReservationLinkProps> = ({
    reservationId,
}: ViewReservationLinkProps) => {
    return (
        <LinkSecondary
            className={classNames(styles.button, styles.viewReservation)}
            typeOf={"link"}
            onClick={() => {
                window.open(`/calendar/hold/${reservationId}`)
                trackGuestReviewViewReservationClicked(String(reservationId))
            }}
        >
            <FormattedMessage
                id="Performance.reviews.viewReservation"
                defaultMessage="View reservation"
            />
            <ChevronRight aria-hidden size={16} />
        </LinkSecondary>
    )
}

const ExpandableText: React.FC<ExpandableTextProps> = ({
    text,
    numberOfLines = 2,
    expandLabel,
    collapseLabel,
    onExpandHandler,
    reservationId,
}: ExpandableTextProps) => {
    const [expanded, setExpanded] = useState(false)
    const expandButtonLabel = expanded ? collapseLabel : expandLabel
    const [showExpandLabel, setShowExpandLabel] = useState(false)
    const labelRef = useRef<HTMLSpanElement>(null)
    useEffect(() => {
        if (labelRef.current) {
            setShowExpandLabel(determineIsTruncated(labelRef.current))
        }
    }, [labelRef])

    return (
        <div>
            <Text
                text={text}
                numberOfLines={numberOfLines}
                labelRef={labelRef}
                expanded={expanded}
            />
            <div className={styles.links}>
                {showExpandLabel && (
                    <LinkSecondary
                        className={classNames(styles.button)}
                        typeOf={"link"}
                        onClick={() => {
                            if (onExpandHandler && !expanded) {
                                onExpandHandler()
                            }
                            setExpanded(!expanded)
                        }}
                    >
                        {expandButtonLabel}
                    </LinkSecondary>
                )}
                {reservationId && (
                    <ViewReservationLink reservationId={reservationId} />
                )}
            </div>
        </div>
    )
}

export default ExpandableText
