import React, { Fragment } from "react"
import { OWNER_HOLD_SUPPORT_EMAIL } from "../../../Constants"
import { FormattedMessage, useIntl } from "react-intl"

export interface CalendarSubHeaderProps {
    allowHolds: boolean
    isCurrentUnitActive: boolean
}

const CalendarSubHeader: React.FC<CalendarSubHeaderProps> = (
    props: CalendarSubHeaderProps
): JSX.Element => {
    const intl = useIntl()

    return (
        <p>
            {!props.allowHolds && (
                <Fragment>
                    <span>
                        <FormattedMessage
                            id="CalendarPage.OwnerHolds.fixedHelperText"
                            defaultMessage="To reserve dates for your own personal use, please contact Homeowner Account Support at +1 (971) 254-3001 or"
                        />
                    </span>
                    <span>
                        {" "}
                        <a href={`mailto:${OWNER_HOLD_SUPPORT_EMAIL}`}>
                            {OWNER_HOLD_SUPPORT_EMAIL}
                        </a>
                        .
                    </span>
                </Fragment>
            )}
            {props.allowHolds && (
                <span>
                    {!props.isCurrentUnitActive
                        ? intl.formatMessage({
                              id: "CalendarPage.OwnerHolds.inactiveHelperText",
                              defaultMessage:
                                  "This rental is inactive. Owner holds can only be added to active rentals.",
                          })
                        : intl.formatMessage({
                              id: "CalendarPage.OwnerHolds.nonfixedHelperText",
                              defaultMessage:
                                  "Use this calendar to reserve dates for your own personal use.",
                          })}
                </span>
            )}
        </p>
    )
}

export default CalendarSubHeader
