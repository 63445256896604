/* eslint-disable camelcase */
// PAGE PATH
export const HOME_INFO_PAGE_PATH = "/home-info"
// Event
export const OWNER_LISTING_VIEWED_EVENT = "Owner Listing Viewed"
export const BUTTON_CLICK_EVENT = "Click"
export const EDIT_PET_FRIENDLY_VIEWED_EVENT = "Edit Pet Friendly Viewed"
export const LINEN_SERVICE_LEARN_MORE_EVENT = "Linen Service learn more"
// Action
export const HOME_INFO_CLICK_ACTION = "home_info_click"
// Click Track Type
export const HOME_INFO_BUTTON_CLICK_TYPE = "button"
export const PHONE_NUMBER_TYPE = "phone_number"
export const EMAIL_LINK_TYPE = "email_link"
export const LINK_TYPE = "link"
export const SMS_LINK_TYPE = "sms"

// Click Track Label
export const PET_FRIENDLY_LABEL = "pet_friendly"
export const PET_FRIENDLY_LEARN_MORE_LABEL = "pet_friendly_learn_more"

export const INSPECTION_PHOTO_CLICKED = "Inspection photo clicked"
export const VIEW_ALL_PHOTOS_CLICKED = "View all photos clicked"
export const SEE_ALL_SPACES_CLICKED = "See all spaces clicked"
export const GUEST_REVIEWS_CLICKED = "Guest reviews clicked"
