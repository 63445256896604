import React from "react"
import classnames from "classnames"

import LoaderIndicator from "../LoaderIndicator"

import styles from "../loaders.module.scss"

export const TYPES = {
    PRIMARY: "button__loader--primary",
    SECONDARY: "button__loader--secondary",
    UTILITY: "button__loader--utility",
    GHOST: "button__loader--ghost",
    ALERTNESS: "button__loader--alertness",
    DISABLED: "button__loader--disabled",
}

export const SIZES = {
    SMALL: "button__loader--small",
    MEDIUM: "button__loader--medium",
    LARGE: "button__loader--large",
    HERO: "button__loader--hero",
}

interface ButtonLoaderProps {
    indicator: string
    indicatorCount: number
    indicatorSize?: string
    indicatorColor?: string
    animation: string
    styleOverride?: string
}

export const ButtonLoader: React.FC<ButtonLoaderProps> = (
    props: ButtonLoaderProps
) => {
    const indicators = []
    let animationClass = styles[props.animation]
    for (let i = 0; i < props.indicatorCount; i++) {
        animationClass = classnames(
            animationClass,
            styles[props.animation + i || ""],
            styles[props.indicatorSize || SIZES.MEDIUM],
            styles[props.indicatorColor || TYPES.PRIMARY]
        )
        const domEl = `<div class="${animationClass}"></div>`
        indicators.push(domEl)
    }
    const indicatorClass = classnames(
        props.indicator ? styles[props.indicator] : ""
    )
    return (
        <>
            {props.styleOverride ? (
                <div
                    className={classnames(props.styleOverride, indicatorClass)}
                >
                    <LoaderIndicator indicators={indicators} />
                </div>
            ) : (
                <div className={indicatorClass}>
                    <LoaderIndicator indicators={indicators} />
                </div>
            )}
        </>
    )
}
