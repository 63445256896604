import { authAxios } from "../../api/authAxios"

export type UnitMinRateChangeRequest = {
    unitId: string | number
}

export const postUnitMinRateChange = ({
    unitId,
}: UnitMinRateChangeRequest): Promise<void> =>
    authAxios.post(`v1/units/${unitId}/min-rate-change`, {
        data: {},
    })
