/* eslint-disable camelcase */
import { ChartType } from "views/performance/components/Chart/types"
import segmentService from "../segment.service"
import {
    BETA_TOOLTIP_RATES_SPECIALIST_CLICKED,
    MARKET_COMP_RANGE_CHANGED,
    ASK_A_RATES_SPECIALIST_CLICKED,
    MARKET_RATE_COMPARISON_CHART_PRESENTED,
    MARKET_RATE_COMPARISON_CHART_TOOLTIP_VIEWED,
    REVENUE_SIMULATOR_RESULT,
    REVENUE_SUMULATOR_CHART_TOOLTIP_VIEWED,
    REVENUE_SIMULATOR_FEATURE_PRESENTED,
} from "./dashboardTrackingConstants"
import {
    RateTransparencyDataVersion2,
    RevenueImpactData,
} from "@vacasa/owner-api-models"

export const trackMarketCompRangeChanged = (
    range: "1 Day" | "1 Week" | "1 Month" | "3 Months" | "6 Months" | "1 Year"
): void => {
    segmentService.track(MARKET_COMP_RANGE_CHANGED, {
        range,
    })
}

export const trackSubmitARequestClicked = (): void => {
    segmentService.track(ASK_A_RATES_SPECIALIST_CLICKED, {})
}

export const trackBetaTooltipRatesSpecialistClicked = (): void => {
    segmentService.track(BETA_TOOLTIP_RATES_SPECIALIST_CLICKED, {})
}

export const trackChartSelectionChanged = (chartType: ChartType) => {
    segmentService.track("Chart Selection Changed", {
        chart_type: chartType,
    })
}

export const trackMarketCompChartPresented = (hasRatesData: boolean) => {
    segmentService.track(MARKET_RATE_COMPARISON_CHART_PRESENTED, {
        has_transparancy_data: hasRatesData,
    })
}
export const trackRevenueSimulatorFeaturePresented = (
    hasRatesData: boolean
) => {
    segmentService.track(REVENUE_SIMULATOR_FEATURE_PRESENTED, {
        has_transparancy_data: hasRatesData,
    })
}

export const trackMarketCompTooltipViewed = (
    data: RateTransparencyDataVersion2,
    eventType?: "market-comp" | "revenue-simulator"
) => {
    segmentService.track(
        eventType === "revenue-simulator"
            ? REVENUE_SUMULATOR_CHART_TOOLTIP_VIEWED
            : MARKET_RATE_COMPARISON_CHART_TOOLTIP_VIEWED,
        {
            avg_max: data.avgCompMaxRate,
            avg_min: data.avgCompMinRate,
            date: data.date,
            info: data.info,
            reservation_type: data.reservation?.type,
            rate: data.rateBasedOnRes,
        }
    )
}

export const trackRevenueSimulatorResult = (data: RevenueImpactData) => {
    segmentService.track(REVENUE_SIMULATOR_RESULT, {
        adjusted_impact: data.adjustedImpact,
        annual_potential_revenue: data.annualPotentialRevenue,
        nights_affected: data.nightsAffected,
        min_rate: data.minRate,
    })
}
