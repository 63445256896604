import { JSONAPIResource, Unit } from "@vacasa/owner-api-models"

export const relatedUnits = (unit?: JSONAPIResource<Unit> | null): string =>
    unit?.attributes
        ? unit.attributes.unitOverlaps
              .map(unitOverlap =>
                  "attributes" in unitOverlap
                      ? unitOverlap.attributes.unitId2
                      : unitOverlap.unitId2
              )
              .join(",")
        : ""
