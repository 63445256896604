/* eslint-disable no-template-curly-in-string */
const calendar = {
    vacasa: "Vacasa",
    title: "Calendrier",
    interiorDesignBannerText: "Gagnez plus en relookant votre déco",
    covidBannerText:
        "Coronavirus homeowner resource guide - what we're doing to navigate COVID-19 together.",
    covidCtaDestination:
        "https://www.vacasa.com/support/coronavirus-homeowner-resource-guide",
    learnMore: "En savoir plus",
    emptyState:
        "You’ll be able to reserve dates for your personal use and view upcoming reservations.",

    OwnerHolds: {
        heading: "Owner holds",
        nonfixedHelperText:
            "Use this calendar to reserve dates for your own personal use.",
        fixedHelperText:
            "To reserve dates for your own personal use, please contact Homeowner Account Support at +1 (971) 254-3001 or",
        inactiveHelperText:
            "Cette location est inactive. L'ajout de période bloquée concerne seulement les locations actives.",
        ifHoldQuestions:
            "Pour toute question concernant cette période bloquée, veuillez contacter",
        toChangeHold:
            "Si vous souhaitez modifier vos dates ou annuler cette période bloquée, veuillez transmettre votre demande à",
        continue: "Continuer",
        ariaLabel: "Cliquez pour placer la période bloquée propriétaire",
        checkIn: "Arrivée",
        checkOut: "Départ",
        notSelected: "Non sélectionnée",
        clearDates: "Effacer les dates",
        employeeOptions: "Options supplémentaires pour les collaborateurs",
        holdType: "Hold Reason",
        ownerHold: "Période bloquée pour le propriétaire",
        guestStay: "Guest Stay",
        vacasaHold: "Période bloquée pour Vacasa",
        selectOwner: "Choisir un propriétaire",
        scheduleClean:
            "Aimeriez-vous programmer une prestation ménage après votre séjour ?",
        yes: "Oui",
        no: "Non",
        or: "ou",
        warningBanner:
            "One or more of the dates for your hold are unavailable. Please check your selection and try again.",
        datesPlaceholder: "MM/DD/YYYY",
    },

    flyout: {
        vacasaContact: "Vacasa contact",
        contactUsFooter:
            "If you have any questions about this hold, please reach out.",
        back: "Back",
        clear: "Clear",
        holdPlaced: "Hold placed",
        holdUpdated: "Hold updated",
        tempAccessCode: "Code d'accès temporaire",
        smartLockAria:
            "Cliquez sur ce lien pour afficher le code Smart lock temporaire.",
        employeesMustClickToView: `Les collaborateurs doivent <button>cliquer pour voir ce code</button. Par mesure de sécurité, cette action sera enregistrée.`,
        smartLockCode: "Code Smart lock",
        notAvailable: "Non disponible",
        selectOption: "Sélectionner une option",
        reservation: "Réservation",
        ownerStay: "Séjour du propriétaire",
        ownerStaySubtitle: "Une réservation pour votre usage personnel.",
        complimentaryStay: "Séjour gratuit",
        complimentaryStaySubtitle: "Une réservation pour des amis ou parents.",
        vacasaHold: "Période bloquée pour Vacasa",
        vacasaHoldSubtitle:
            "Option uniquement disponible pour les collaborateurs.",
        block: "Bloquer",
        blockSubtitle: "Personne n'utilisera la maison.",
        propertyCare: "Entretien de la propriété",
        propertyCareSubtitle:
            "Entretien, travaux de valorisation, livraisons, etc.",
        seasonalHold: "Seasonal hold",
        homeForSale: "Home for sale",
        homeForSaleSubtitle: "Activities related to a home sale.",
        other: "Other",
        otherSubtitle: "Does not fit the other categories.",
        terminationHold: "Termination hold",
        terminationHoldSubtitle: "Option only available for employees",
        notes: {
            title: "Notes ",
            titleRequired: "Notes* ",
            optional: "(optional)",
            required: "(required)",
            placeholder:
                "Help us improve our category options by providing a note about this hold",
            none: "N/A",
        },
        housekeeping: "Entretien ménager",
        yesHousekeepingSubtitle:
            "A Vacasa housekeeping visit will be scheduled after this hold.",
        yesHousekeepingSubtitleWithCleaningFee:
            "A Vacasa housekeeping visit will be scheduled after this hold and billed to your account in the amount of <strong>${amount}.</strong>",
        noHousekeepingSubtitle:
            "You will be responsible for cleaning and preparing the home for the next guests.",
        ownerResponsibleForCleaning: "Owner responsible for cleaning",
        housekeepingScheduled: "Ménage programmé",
        noHousekeeping: "Pas de ménage",
        numberGuestsMax: `{{number}} locataires au maximum`,
        noPets: "Les animaux de compagnie ne sont pas autorisés",
        restrictionsDontApply:
            "Les restrictions imposées aux locataires ne s'appliquent pas aux propriétaires",
        night: "night",
        nights: "nuits",
        madeOn: "Booked on {creationDate}",
        madeBy: "Booked on {date} by {name}",
        monthCount:
            "Spans {monthCount, plural,=0 {}one {# month}other {# months}}",
        date: "Date",
        dates: "Dates",
        guests: "Locataires",
        createHold: "Create Hold",
        next: "Next",
        summary: "Summary",
        cancelOwnerHold: "Cancel owner hold",
        cancelAreYouSure:
            "Are you sure you want to cancel? Your hold has not been scheduled.",
        deleteOwnerHold: "Delete owner hold",
        editHold: "Modifier période bloquée",
        edit: "Modifier",
        editAria: "Cliquez ici pour modifier",
        pleaseContactToRequestChange:
            "Veuillez contacter {{contact}} pour demander une modification de date.",
        saveChanges: "Enregistrer les modifications",
        save: "Enregistrer",
        saveAria: "Cliquez ici pour enregistrer",

        delete: "Supprimer",
        removeHold: "Effacer la période bloquée",
        deleteHold: "Supprimer la période bloquée",
        deleteAria: "Cliquez ici pour supprimer",
        holdRemoved: "Période bloquée supprimée",
        deleteAreYouSure: "Are you sure you want to delete this?",
        deleteHelperText: "Cette action ne peut pas être supprimée.",
        deleteConfirm: "Oui, supprimer la période bloquée",
        cancelDelete: "Non, ne pas supprimer",
        approvalNeeded: "Un accord est nécessaire.",
        fixedRentDeletionApproval:
            "Pour les contrats à loyers fixes, la suppression d'une période bloquée doit obtenir l'accord de l'équipe d'assistance.",
        request: "Request",
        requestDelete: "Demander la suppression",
        deleteRequestConfirmation:
            "Cette demande concerne la suppression d'une période bloquée de propriétaire au cours d'un contrat à loyer fixe.",
        holdNumberForRequest: "Demande de suppression de la période bloquée n°",
        holdNumber: "Période bloquée n°",
        startDate: "Date de début :",
        endDate: "Date de fin :",

        cancel: "Supprimer",
        done: "Fait",
        totalNetRent: "Total Net rent",
        revenueBreakdown: "Revenue breakdown",
        fixedRent: "Loyer fixe",
        grossRent: "Loyer brut",
        guestReview: "Guest review",
        guestReviewCollapseLabel: "See less",
        guestReviewExpandLabel: "See more",

        inviteGuest: "Inviter le locataire",
        inviteGuests: "Invite Guests",
        inviteGuestsOptional: "Invite Guests (Optional)",
        inviteAnotherGuest: "Inviter un autre locataire",
        inviteGuestsSubtitle:
            "Add guests to share your trip details and home access information.",
        firstName: "Prénom",
        lastName: "Nom",
        emailAddress: "Adresse e-mail",
        invitationSent: "Invitation envoyée",
        invitationsSent: "invitations envoyées",
        fiveOrMoreInvitationsSent: "invitations envoyées",
        guestRemoved: "Locataire supprimé",
        skip: "Skip",
        skipAria: "Cliquez pour passer l'ajout de locataires",
        inviteAnotherGuestAria: "Cliquez pour  inviter un autre locataire",
        noGuestInfo: "Aucune information locataire",
        guestInfo: "Guest info",
        adults: "adultes",
        fiveOrMoreAdults: "adultes",
        children: "enfants",
        fiveOrMoreChildren: "enfants",
        dogs: "chiens",
        fiveOrMoreDogs: "chiens",
        housekeepingNotAllowed:
            "Housekeeping not available. You will be responsible for cleaning and preparing the home for the next guests.",
        newBooking: "New booking",
        pastBooking: "Past booking",
        invalidDates: "The selected dates are not available.",
        learnAboutRates: "Learn about rates",
        guestBookingLikely:
            "These nights are highly likely to be booked by guests.",
        response: "Manager response",
        onboardingFooter: {
            title: "What do you want to do next?",
            backToOnboarding: "Go Back to Onboarding",
            addAdditionalOwnerHolds: "Add Another Owner Hold",
        },
        costOfHold:
            "You miss out on <b>{cost}</b> in gross rent by booking these dates.",
        housekeepingRequired:
            "In order to maintain the highest quality guest experience, housekeeping is required for stays with overnight guests.",
        housekeepingRequiedWithCleaningFee:
            "This owner hold requires a post-stay clean, which will be billed to your account after the stay in the amount of <strong>${amount}.</strong>",
        PhoneSections: {
            smsTitle: "Owner contact info",
            smsDescription: 'Details will be sent to this number.',
            smsEditDescription: "Details will be sent to <strong>{number}</strong>.",
            invalidPhoneMessage: 'The phone number is not valid',
            noPhoneNumber: 'No phone number provided'
        }
    },

    UpcomingStays: {
        title: "Reservations",
        emptyStateUpcoming:
            "New reservations and owner holds will show up here.",
        emptyStatePast:
            "You have no previous reservations. When upcoming stays have concluded, they will appear here.",
        upcomingDescription:
            "Upcoming guest reservations and owner holds will be listed below. Click a row to view more details. If you wish to modify an owner hold you can do so by clicking on the corresponding row.",
        pastDescription:
            "Your past reservations and holds in the last 45 days are listed out below.  If you would like to view more details around each stay, click on the detail row. ",
        toModify:
            "Ci-dessous, vous pouvez modifier une période bloquée du propriétaire.",
        fixedRentModify:
            "Si vous souhaitez modifier une période bloquée de votre location durant une période d'application de loyer fixe, veuillez envoyer un e-mail à",
        details: "Informations",
        Night: "Nuit",
        adult: "adulte",
        child: "enfant",
        dog: "chien",
        at: "de",
        upcomingTabTitle: "Upcoming ({total})",
        previousTabTitle: "Previous",
        adults: "adults",
        children: "children",
    },

    errorMessages: {
        generalError:
            "Un problème nous a empêchés de traiter votre demande. Veuillez réessayer plus tard. Si le problème persiste, veuillez appeler {name} au {phone}",
        datesNotAvailableError:
            "Une ou plusieurs dates ne sont pas disponibles pour la période bloquée. Veuillez vérifier les dates sélectionnées et réessayer.",
        createHoldError: "Votre période bloquée n'a pas pu être créée.",
        deleteHoldError: "Votre période bloquée n'a pas pu être supprimée.",
        updateHoldError: "Votre période bloquée n'a pas pu être mise à jour.",
        updateHoldCleanError: "Votre statut ménage n'a pas pu être mis à jour.",
        addGuestToHoldError: "Votre locataire n'a pas pu être ajouté.",
        removeGuestFromHoldError: "Votre locataire n'a pas pu être supprimé.",
        modalTitle: "Oops!",
        modalMessage:
            "It looks like this reservation is no longer in our system.",
        invalidDateError: "This date is unavailable",
        unableToLoadReservations: "We were unable to load your reservations.",
    },

    reservations: {
        reservation: "Reservation",
        vacasaHold: "Vacasa hold",
    },

    switchAccounts: {
        notification: "This reservation is associated with another account",
        description:
            "Please login to the appropriate homeowner account and click the new booking email notification to view the new reservation details.",
        button: "Switch Accounts",
        maybeLater: "Maybe Later",
        descriptionButtom:
            "Interested in linking your accounts into one login? Call <strong>Owner Support</strong> at {phone} to get it set-up.",
    },

    reservationType: {
        ownerHold: "Owner Hold",
        vacasaHold: "Vacasa Hold",
        reservation: "Guest Stay",
    },
}

export default calendar
