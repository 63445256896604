import { JSONAPIResource, ReservationGuest } from "@vacasa/owner-api-models"
import { AxiosError } from "axios"
import { useMutation, UseMutationResult, useQueryClient } from "react-query"
import { trackOwnerHoldGuestUpdated } from "services/segment/ownerHold/ownerHoldTracking"
import { RESERVATION_GUESTS_QUERY_KEY } from "./constants"
import { patchReservationGuest } from "./patchReservationGuest"

export const useUpdateReservationGuest = (): UseMutationResult<
    JSONAPIResource<ReservationGuest>,
    AxiosError,
    {
        reservationId: string
        guestId: string
        guest: Omit<ReservationGuest, "guestID" | "reservationID">
        signal?: AbortSignal
    }
> => {
    const queryClient = useQueryClient()

    return useMutation(data => patchReservationGuest(data), {
        onSuccess: (data, { reservationId }) => {
            trackOwnerHoldGuestUpdated(String(reservationId), data.attributes)
            return queryClient.invalidateQueries([
                RESERVATION_GUESTS_QUERY_KEY,
                reservationId,
            ])
        },
    })
}
