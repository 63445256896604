import {
    JSONAPIDocument,
    JSONAPIResource,
    UserPreference,
} from "@vacasa/owner-api-models"
import { authAxios } from "../../api/authAxios"

type FetchOwnerPreferencesRequest = {
    signal?: AbortSignal
}

const fetchOwnerPreferences = ({
    signal,
}: FetchOwnerPreferencesRequest): Promise<JSONAPIResource<UserPreference>[]> =>
    authAxios
        .get<JSONAPIDocument<UserPreference, []>>("/v1/user-preferences", {
            signal,
        })
        .then(response => response.data.data) // TODO, remove the JSONAPIDocument part of this

export { fetchOwnerPreferences }
