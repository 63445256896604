import { HOMEOWNER_ACCOUNTS_EMAIL } from "./../../Constants"

const notFound = {
    backToAccount: "Retourner à mon compte",
    message:
        "Nous sommes désolés, mais la page que vous recherchez semble avoir pris des vacances.",
    detail: "You might try retyping the URL or we could take you to the calendar page to view your current reservations or book an owner hold.",
    link: "View Calendar",
    needHelpNow:
        "<b>Need help now?</b> Contact our account support team at {phone} or email {email}",
    errorCode: "Erreur 404",
    unknownErrorTitle: "Your account has no rental properties",
    unknownErrorMessage: "Try logging in again, or using a different account",
    noLegacyIdErrorTitle: "Oops, looks like we’re missing something",
    noLegacyIdErrorMessage:
        "To see your properties in your owner account, please contact us at Owner Support.",
    errorMessageContactEmail: HOMEOWNER_ACCOUNTS_EMAIL,
    errorMessagePhoneNumber: "971-254-3001",
    noOwnerScopeErrorTitle: "You’re signed in with a guest account.",
    noOwnerScopeErrorMessage:
        "If you have an owner account, sign in again with that account. Need help? Contact Owner Support at",
    genericLoginErrorTitle:
        "Hmm, there was an error displaying the owner account.",
    genericLoginErrorContact: "Still having trouble? Contact Owner Support at",
    genericLoginErrorMessage: "Error Message: ",
    tryAgainButtonTitle: "Try again",
    GoToLoginButton: "Go to login",
}

export default notFound
