import * as React from "react"
import styles from "./AverageStarRating.module.scss"
import { ReactComponent as ReviewsSVG } from "../../assets/reviews.svg"
import { FormattedMessage } from "react-intl"
import { Card, CardContent } from "../../components/Card"

export const NoAverageStarRating: React.FC = () => {
    return (
        <Card testId="noAverageStarRating" className={styles.panelReviewsEmpty}>
            <CardContent>
                <div className={styles.ratingContainer}>
                    <ReviewsSVG className={styles.reviewSvg} />
                    <div className={styles.reviewsEmptyHeading}>
                        <FormattedMessage
                            id="Performance.reviews.headingReviewsEmpty"
                            defaultMessage="Avg. guest rating"
                            description="Heading title for average guest rating."
                        />
                    </div>
                    <div className={styles.reviewsEmptyParagraph}>
                        <FormattedMessage
                            id="Performance.reviews.reviewsEmpty"
                            defaultMessage="Your average guest rating and guest review count will appear here."
                            description="Empty review paragraph."
                        />
                    </div>
                </div>
            </CardContent>
        </Card>
    )
}
