const unitSelector = {
    allRentals: "All Rentals",
    search: "search",
    searchRentals: "Search Your Rentals",
    allActive: "All Active Rentals",
    rentals: "rentals",
    inactive: "Inactive",
    inactiveRentals: "Inactive Rentals",
    unitSelectorAria:
        "Use this unit selector to change which unit is in the current view.",
}

export default unitSelector
