import { useContactId } from "hooks/user"
import { useQuery } from "react-query"
import { fetchWebChatStatus } from "./fetchWebChatStatus"
import { AxiosError } from "axios"
import { WebChatStatus } from "@vacasa/owner-api-models"

export const useWebChatStatus = () => {
    const contactId = useContactId()
    return useQuery<WebChatStatus, AxiosError>(
        ["web-chat-status", contactId],
        () => fetchWebChatStatus({ contactId: contactId as string }),
        {
            enabled: !!contactId,
            refetchInterval: 1000 * 60 * 10, // 10 minutes
            staleTime: 1000 * 60 * 10, // 10 minutes
        }
    )
}
