import { FC } from "react"
import {
    Navigate,
    NavigateFunction,
    Outlet,
    useNavigate,
} from "react-router-dom"
import storageService from "services/storage.service"
import { ATTEMPTED_ROUTE } from "Constants"
import {
    checkMigrationComplete,
    checkStoredMigrationComplete,
} from "utils/auth/idp"
import { observer } from "mobx-react"
import { useUser } from "hooks/user"

const handleMigration = (navigate: NavigateFunction) => {
    if (checkStoredMigrationComplete()) {
        navigate({
            search: "?owner_migration_complete=true",
        })
    } else {
        checkMigrationComplete()
    }
}

const handleUserPresent = (navigate: NavigateFunction) => {
    let attemptedRoute = storageService.localStorage.getItem(ATTEMPTED_ROUTE)
    if (attemptedRoute) {
        storageService.localStorage.removeItem(ATTEMPTED_ROUTE)
        if (checkStoredMigrationComplete()) {
            attemptedRoute += "?owner_migration_complete=true"
        } else {
            checkMigrationComplete()
        }
        return <Navigate to={attemptedRoute} />
    }
    handleMigration(navigate)
    return <Outlet />
}

const PrivateRoute: FC = () => {
    const navigate = useNavigate()
    const { user } = useUser()
    const userPresent = user !== null
    if (userPresent) {
        return handleUserPresent(navigate)
    }

    let goTo = "/login"
    if (checkStoredMigrationComplete()) {
        goTo += "?owner_migration_complete=true"
    } else {
        checkMigrationComplete()
    }

    return <Navigate to={goTo} />
}

export default observer(PrivateRoute)
