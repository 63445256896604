import React, { PropsWithChildren, ReactNode } from "react"
import classnames from "classnames"
import SVGIcon from "../SVGIcon"
import { useIntl } from "react-intl"

export enum PanelType {
    Calendar,
    HomeInfo,
    Maintenance,
    PerformanceNotAvailable,
    PerformanceNotAllowed,
    Settings,
}

export const NoUnitStatusPanel = ({ panelType }: { panelType: PanelType }) => {
    const intl = useIntl()

    let snippet = ""
    let iconName = ""

    switch (panelType) {
        case PanelType.Calendar:
            snippet = intl.formatMessage({
                id: "CalendarPage.emptyState",
                defaultMessage:
                    "You’ll be able to reserve dates for your personal use and view upcoming reservations.",
            })
            iconName = "icon-feather-calendar"
            break
        case PanelType.HomeInfo:
            snippet = intl.formatMessage({
                id: "HomeInfo.emptyState",
                defaultMessage:
                    "Details like your local Vacasa contacts and a link to your listing will show up here.",
            })
            iconName = "icon-feather-file"
            break
        case PanelType.Maintenance:
            snippet = intl.formatMessage({
                id: "Maintenance.emptyState",
                defaultMessage:
                    "Need help with home maintenance? You’ll be able to submit requests here.",
            })
            iconName = "icon-feather-spray-bottle"
            break
        case PanelType.PerformanceNotAvailable:
            snippet = intl.formatMessage({
                id: "Performance.emptyState",
                defaultMessage: "You don’t have any current performance data.",
            })
            iconName = "icon-feather-trending-up"
            break
        case PanelType.PerformanceNotAllowed:
            snippet = intl.formatMessage({
                id: "Performance.notAllowed",
                defaultMessage:
                    "You are not the primary owner of this unit. Performance data is not accessible at this time.",
            })
            iconName = "icon-feather-lock"
            break
        case PanelType.Settings:
            snippet = intl.formatMessage({
                id: "Settings.emptyState",
                defaultMessage:
                    "This is where you can update your reserve balance and payment method. Be sure to set up direct deposit before your first statement posts in order to get your payments faster.",
            })
            iconName = "icon-feather-calendar"
            break
    }

    return (
        <EmptyState
            snippet={snippet}
            classes={["panel", "panel-empty", "empty-state-rounded"]}
        >
            <SVGIcon
                className="w-56 h-56 empty-state__svg"
                colorName="info-70"
                svg={iconName}
            />
        </EmptyState>
    )
}

interface Props {
    classes?: string[]
    fontSizeClass?: string
    fontColorClass?: string
    CTA?: CTA
    snippet?: ReactNode
}

interface CTA {
    href: string
    snippet: string
}
/**
 * @deprecated use Empty State in `src/components/EmptyState` instead
 */
const EmptyState: React.FC<PropsWithChildren<Props>> = ({
    classes,
    fontSizeClass,
    fontColorClass,
    CTA,
    snippet,
    children,
}) => {
    const classNames = classnames(classes ? classes : "panel-empty")
    const fontClasses = classnames(
        fontSizeClass ? fontSizeClass : "type-body-large",
        fontColorClass ? fontColorClass : "type-midnight"
    )
    return (
        <div className={classNames}>
            <div
                className="empty-state-container"
                data-testid="empty-state-children-container"
            >
                {children}
            </div>
            <div
                className="empty-state-container"
                data-testid="empty-state-snippet-container"
            >
                <p className={fontClasses}>{snippet}</p>
            </div>
            {CTA && (
                <a href={CTA.href} target="_blank" rel="noopener noreferrer">
                    {CTA.snippet}
                </a>
            )}
        </div>
    )
}

export default EmptyState
