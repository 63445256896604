import { useDownloadStatementsPDF } from "hooks/statements/useDownloadStatementsPDF"
import { useDownloadPDFStatementsRequest } from "hooks/statements/useDownloadStatementsPDFRequest"
import { LinkSecondary } from "lib/components/Buttons/BaseButton"
import Loader from "lib/components/Loader"
import SVGIcon from "lib/components/SVGIcon"
import { useToastNotification } from "lib/components/ToastNotification"
import React from "react"
import { useIntl } from "react-intl"
import errorService from "services/error.service"
import { trackButtonClicked } from "services/segment/statement/statementTracking"
import { convertBase64ToBlob, showFile } from "utils/statements/statementsUtil"
import styles from "../statements.module.scss"

export const PDFDownloadLink: React.FC = () => {
    const intl = useIntl()
    const request = useDownloadPDFStatementsRequest()
    const mutation = useDownloadStatementsPDF()
    const { addToastNotification, hideToastNotification } =
        useToastNotification()

    const onError = () => {
        errorService.setErrorMsg(
            "We were unable to create a PDF of your statement. Please try again later."
        )
        errorService.displayError()
    }

    const downloadPdf = async (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined
    ) => {
        if (!event) {
            return
        }

        const id = addToastNotification({
            autoHide: false,
            closeButton: false,
            content: (
                <>
                    {intl.formatMessage({
                        id: "Statements.pdfNotification",
                        defaultMessage: "Preparing to download PDF",
                    })}
                    <Loader
                        className={"primary show spinner-white"}
                        overlay={false}
                        spinnerSize={"spinner-sm"}
                    />
                </>
            ),
        })

        trackButtonClicked({
            type: "link",
            label: "pdf",
        })

        if (!request) {
            onError()
            return
        }

        try {
            const { data, filename } = await mutation.mutateAsync(request)
            const blob = convertBase64ToBlob(data, "application/pdf")
            showFile(blob, `${filename}.pdf`)
        } catch {
            onError()
        } finally {
            hideToastNotification(id)
        }
    }

    return (
        <LinkSecondary
            className={styles["need-help-button"]}
            ariaLabel={intl.formatMessage({
                id: "Statements.pdfAriaLabel",
                defaultMessage: "View PDF",
            })}
            id={"generate-statement-pdf-button"}
            data-testid="generate-statement-pdf-button"
            typeOf={"link"}
            onClick={downloadPdf}
        >
            <SVGIcon
                svg="icon-feather-file"
                colorName="lake"
                className={styles["svg-icon"]}
            />
            <>
                {intl.formatMessage({
                    id: "Statements.pdf",
                    defaultMessage: "PDF",
                })}
            </>
        </LinkSecondary>
    )
}
