import classNames from "classnames"
import styles from "./AgreeAndSignForm.module.scss"
import { useTaxInformationForm } from "../hooks"
import { useValidation, w9 } from "hooks/validation"
import {
    ChangeEvent,
    Ref,
    forwardRef,
    useImperativeHandle,
    useState,
} from "react"
import { FormRef } from "../types"
import { FormattedMessage, useIntl } from "react-intl"
import { Textarea } from "lib/components/Textarea"
import { Checkbox } from "lib/components/Form"
import { Input } from "lib/components/Input"
import { format } from "date-fns"

export const AgreeAndSignForm = forwardRef((_, ref: Ref<FormRef>) => {
    const { data, setData } = useTaxInformationForm()
    const [errors, setErrors] = useState(new Map<string, string>())
    const intl = useIntl()
    const { validate } = useValidation(w9.agreeAndSign(intl))

    const isFormValid = async (): Promise<boolean> => {
        const formErrors = await validate(data.agreeAndSign)

        setErrors(formErrors)
        return formErrors.size === 0
    }

    useImperativeHandle(ref, () => ({
        isFormValid,
    }))

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setData(prevState => ({
            ...prevState,
            agreeAndSign: {
                ...prevState.agreeAndSign,
                [e.target.name]:
                    e.target.type === "checkbox"
                        ? e.target.checked
                        : e.target.value,
            },
        }))
    }

    const onBlurValidation = async (e: ChangeEvent<HTMLInputElement>) => {
        const fieldErrors = await validate(data.agreeAndSign, e.target.name)
        setErrors(fieldErrors)
    }

    return (
        <div className={classNames(styles.agreeAndSign, styles.container)}>
            <div
                className={classNames(
                    "type-heading-extra-small-caps text-midnight-70 font-extrabold",
                    styles.title
                )}
            >
                <FormattedMessage
                    id="Taxes.w9.forms.agreeAndSign.title"
                    defaultMessage="Agree & Submit"
                />
            </div>
            <div className="flex flex-column" style={{ gap: "8px" }}>
                <Textarea
                    labelText={intl.formatMessage({
                        id: "Taxes.w9.forms.agreeAndSign.termsOfAgreement",
                        defaultMessage: "Terms Of Agreement",
                    })}
                    rows={6}
                    value={intl.formatMessage({
                        id: "Taxes.w9.forms.agreeAndSign.terms",
                        defaultMessage:
                            "Under penalties of perjury, I certify that:\n\n1. The number shown on this form is my correct taxpayer identification number (or I am waiting for a number to be issued to me); and \n\n2. I am not subject to backup withholding because: (a) I am exempt from backup withholding, or (b) I have not been notified by the Internal Revenue Service (IRS) that I am subject to backup withholding as a result of a failure to report all interest or dividends, or (c) the IRS has notified me that I am no longer subject to backup withholding; and \n\n3. I am a U.S. citizen or other U.S. person (defined below); and \n\n4. The FATCA code(s) entered on this form (if any) indicating that I am exempt from FATCA reporting is correct. Certification instructions. You must cross out item 2 above if you have been notified by the IRS that you are currently subject to backup withholding because you have failed to report all interest and dividends on your tax return. For real estate transactions, item 2 does not apply. For mortgage interest paid, acquisition or abandonment of secured property, cancellation of debt, contributions to an individual retirement arrangement (IRA), and generally, payments other than interest and dividends, you are not required to sign the certification, but you must provide your correct TIN. See the instructions for Part II, later.",
                    })}
                    readOnly
                    className={classNames("type-body-small", styles.terms)}
                />
                <Checkbox
                    input={{
                        onChange,
                        checked: data.agreeAndSign.agreeToTerms,
                        name: "agreeToTerms",
                    }}
                    error={errors.has("agreeToTerms")}
                    errorMessage={errors.get("agreeToTerms")}
                >
                    <FormattedMessage
                        id="Taxes.w9.forms.agreeAndSign.iAgree"
                        defaultMessage="I agree to these terms"
                    />
                </Checkbox>
            </div>
            <div>
                <Input
                    name="signature"
                    value={data.agreeAndSign.signature}
                    onChange={onChange}
                    className={classNames(styles.signature, {
                        [styles.signatureFilled as string]:
                            !!data.agreeAndSign.signature,
                    })}
                    placeholder={intl.formatMessage({
                        id: "Taxes.w9.forms.agreeAndSign.signaturePlaceholder",
                        defaultMessage: "Type fullname",
                    })}
                    onBlur={onBlurValidation}
                    hint={
                        errors.has("signature")
                            ? errors.get("signature")
                            : intl.formatMessage({
                                  id: "Taxes.w9.forms.agreeAndSign.signatureHelperText",
                                  defaultMessage:
                                      "By signing this document with an electronic signature, I agree that such signature will be as valid as handwritten signatures to the extent allowed by local law.",
                              })
                    }
                    error={errors.has("signature")}
                />
            </div>
            <div className="type-heading-small-caps uppercase text-dusk-lighter">
                Signed {format(new Date(), "MM/dd/yyyy")}
            </div>
        </div>
    )
})
