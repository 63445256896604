const linenService = {
    title: "Service linge de maison",

    notificationComponent: {
        planWillBeginWithInitialCharge:
            "Le programme commence le <strong>{date, date, medium}</strong> et sera facturé au tarif initial de <strong>{cost}</strong>.",
        planWillBeginWithCharge:
            "Le programme commence le <strong>{date, date, medium}</strong> et sera facturé au tarif de <strong>{cost}</strong>.",
        planWillRenewFor:
            "Le programme sera automatiquement renouvelé le <strong>{date, date, medium}</strong> et sera facturé <strong>{cost}</strong>",
        planWillEndOn:
            "Le programme se terminera le <strong>{date, date, medium}</strong> date à partir de laquelle vous ne bénéficierez plus du remplacement des draps et serviettes de toilette à tarif réduit.",
    },

    infoPanel: {
        learnMoreTitle: "Une solution simple et peu onéreuse",
        bodyText:
            "Notre service linge de maison vous fournit un linge de maison impeccable à tarif réduit. Des draps propres et frais, des serviettes toutes douces, cela se traduit par de meilleurs commentaires, plus de réservations et au final, plus de revenus pour vous.",
        learnMore: "En savoir plus",
    },

    servicePlan: {
        prepend: "Votre programme",
        standard: "Service linge de maison standard",
        premium: "Service linge de maison supérieur",
        youAreNotEnrolled: "Vous n'êtes pas inscrit",
        year: "an",
        nextBillingDate: "Date de la prochaine facture",
        newPlanBegins: "Le nouveau programme commence le",
        nextBilling: "Prochaine facture",
        discount: "réduction",
        setup: "Création",
        setupFee: "frais de création",
        planHistory: "Historique des programmes",
        ongoing: "en cours",
        pending: "En attente",
        append: "Les frais facturés pour ce service figureront sur vos relevés mensuels, à la rubrique dépenses.",
    },

    helpPanel: {
        needHelp: "Besoin d'aide ?",
        contactSentenceIfQuestions:
            "Veuillez contacter votre agent {contact} isi vous avez la moindre question ou souhaitez modifier votre programme.",
    },

    enrolled: "Inscrit",
    notEnrolled: "Non inscrit",
    begins: "Début",
    renews: "Renouvellement",
    expires: "Expiration",
}

export default linenService
