const survey = {
    title: "ÁRAK ÉS BEVÉTELEK",
    subTitle: "Mesélj a céljaidról",
    surveyFollowUp: "Lépés {step} a(z) {totalSteps} közül",
    errorTitle: "A felmérés nem elérhető",
    loadingErrorMessage: "Sajnáljuk, a felmérést nem sikerült betölteni. Kérjük, próbálja meg később újra.",
    submitErrorMessage: "Sajnáljuk, a felmérést jelenleg nem tudtuk beküldeni. Kérjük, próbálja meg később újra.",
    tanksTitle: "Gratulálunk a bevételi stratégiád beállításához!",
    tanksMessage: "Csapatunk dolgozik a kéréseid megvalósításán."
}

export default survey
