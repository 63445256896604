import { useIntl } from "react-intl"
import styles from "./GuestReviewModal.module.scss"
import { ReviewList } from "./ReviewList"
import { ReactComponent as StarIcon } from "../../../../assets/icon-star-filled.svg"
import { RatingChart } from "./RatingChart"
import { RatingFilter } from "./RatingFilter"
import { useUnitReviews } from "hooks/review"
import { ReviewsResponse } from "hooks/review/fetchReviews"
import { UnitFilter } from "./UnitFilter"

interface ReviewDetailsProps {
    unitId: string
    filter: string
    updateUnitId: (unitId: string) => void
    updateFilter: (ratingFilter: string) => void
}

const countReviews = (rating: number, reviewData: ReviewsResponse): number => {
    return reviewData.data.filter(
        review => review.attributes.overall === rating
    ).length
}

const reviewCounts = (reviewData: ReviewsResponse): number[] => {
    const counts = [1, 2, 3, 4, 5].map(rating =>
        countReviews(rating, reviewData)
    )
    return counts
}

const ReviewDetails: React.FC<ReviewDetailsProps> = props => {
    const { unitId, filter, updateUnitId, updateFilter } = props
    const unitReviews = useUnitReviews(unitId)
    const intl = useIntl()

    return (
        <>
            <div className={styles.body}>
                <div className={styles.selector}>
                    <UnitFilter
                        unitId={unitId}
                        updateUnitId={updateUnitId}
                        updateFilter={updateFilter}
                    />
                </div>
                {!unitReviews.isLoading && unitReviews.isSuccess && (
                    <>
                        <div className={styles.ratingChart}>
                            <div className={styles.chartHeader}>
                                <div className={styles.headerItem}>
                                    <div className={styles.count}>
                                        <StarIcon />
                                        {unitReviews.data?.meta.rating
                                            ?.average ?? "--"}
                                    </div>
                                    <div className={styles.description}>
                                        {intl.formatMessage({
                                            id: "Performance.reviews.overallRating",
                                            defaultMessage: "Overall rating",
                                        })}
                                    </div>
                                </div>
                                <div className={styles.headerItem}>
                                    <div className={styles.count}>
                                        {unitReviews.data?.meta.filteredTotal}
                                    </div>
                                    <div className={styles.description}>
                                        {intl.formatMessage({
                                            id: "Performance.reviews.totalReviews",
                                            defaultMessage: "Total reviews",
                                        })}
                                    </div>
                                </div>
                            </div>
                            <RatingChart
                                total={unitReviews.data.meta.filteredTotal}
                                reviewCounts={reviewCounts(unitReviews.data)}
                                updateFilter={updateFilter}
                            />
                            {unitReviews.data.meta.filteredTotal > 0 && (
                                <RatingFilter
                                    reviewCounts={reviewCounts(
                                        unitReviews.data
                                    )}
                                    updateFilter={updateFilter}
                                    filter={filter}
                                />
                            )}
                        </div>
                        <div className={styles.reviewList}>
                            {filter !==
                            intl.formatMessage({
                                id: "Performance.reviews.allRatings",
                                defaultMessage: "All Ratings",
                            }) ? (
                                <ReviewList
                                    reviews={unitReviews.data?.data ?? []}
                                    filter={filter}
                                />
                            ) : (
                                <ReviewList
                                    reviews={unitReviews.data?.data ?? []}
                                />
                            )}
                        </div>
                    </>
                )}
            </div>
        </>
    )
}

export { ReviewDetails }
