import {
    HousekeepingRequired,
    JSONAPIResource,
    OwnerHoldType,
} from "@vacasa/owner-api-models"
import { UnitStatusType } from "utils/unit"
import { authAxios } from "../../api/authAxios"

type FetchReservationRequest = {
    params: {
        unitStatus: UnitStatusType | undefined
        holdType: OwnerHoldType
        state: string
        acquisitionID: number | null | undefined
        isGuestworksUnit: boolean
        unitId?: string
    }
    signal?: AbortSignal
}

const fetchHousekeepingRequired = ({
    params,
    signal,
}: FetchReservationRequest): Promise<JSONAPIResource<HousekeepingRequired>> =>
    authAxios
        .get<{ data: JSONAPIResource<HousekeepingRequired> }>(
            `/v2/housekeeping-required`,
            {
                params,
                signal,
            }
        )
        .then(response => response.data.data)

export { fetchHousekeepingRequired }
