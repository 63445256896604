import React from "react"
import IconButton from "@material-ui/core/IconButton"
import classnames from "classnames"
import { FormattedMessage, useIntl } from "react-intl"

import Dropdown from "./Dropdown/Dropdown"
import { Generic as RoomOption } from "../../../models/Generic"

import { ReactComponent as TrashIcon } from "../../../assets/icon-trash.svg"
import styles from "../../../sass/onboarding.module.scss"

interface Props {
    id: number
    selectedBedSize: RoomOption | null | undefined
    selectedBedType: RoomOption | null | undefined
    bedSizes: RoomOption[]
    bedTypes: RoomOption[]
    unassignedBedSizes: RoomOption[] | null | undefined
    isNewBed: boolean
    onChangeBedSizeHandler: (index: number, bedSize: RoomOption) => void
    onChangeBedTypeHandler: (index: number, bedType: RoomOption) => void
    onDeleteHandler: (index: number) => void
    shouldValidate: boolean
    firstBedItem: boolean
}

const BedRow: React.FC<Props> = ({
    id,
    selectedBedSize,
    selectedBedType,
    bedSizes,
    bedTypes,
    unassignedBedSizes,
    isNewBed,
    onChangeBedSizeHandler,
    onChangeBedTypeHandler,
    onDeleteHandler,
    shouldValidate,
    firstBedItem,
}: Props) => {
    const intl = useIntl()
    const BED_SIZE_CHILD = 5
    const BED_SIZE_CRIB = 6

    const catergorizedBedSizes =
        unassignedBedSizes && unassignedBedSizes.length > 0
            ? [
                  {
                      title: intl.formatMessage({
                          id: "Onboarding.sleepingArrangements.unassignedCategory",
                          defaultMessage: "Unassigned",
                      }),
                      options: unassignedBedSizes,
                  },
                  {
                      title: intl.formatMessage({
                          id: "Onboarding.sleepingArrangements.newCategory",
                          defaultMessage: "New",
                      }),
                      options: bedSizes,
                  },
              ]
            : null

    return (
        <div
            className={classnames(
                isNewBed ? styles.new_bed : "",
                styles.bed_row,
                firstBedItem ? styles.first_bed : ""
            )}
        >
            <div className={styles.content}>
                <div className={styles.dropdown_container}>
                    <Dropdown
                        className={classnames(styles.dropdown, styles.item)}
                        placeholder={
                            <FormattedMessage
                                id="Onboarding.sleepingArrangements.bedSizePlaceholder"
                                defaultMessage="Select a bed"
                            />
                        }
                        selectedValue={selectedBedSize}
                        onChangeHandler={(option: RoomOption): void =>
                            onChangeBedSizeHandler(id, option)
                        }
                        options={bedSizes}
                        categorizedOptions={catergorizedBedSizes}
                        shouldValidate={shouldValidate}
                        errorText={
                            <FormattedMessage
                                id="Onboarding.sleepingArrangements.bedSizeError"
                                defaultMessage="Please select a bed"
                            />
                        }
                    />
                </div>
                <div className={styles.dropdown_container}>
                    <Dropdown
                        className={classnames(styles.dropdown, styles.item)}
                        placeholder={
                            <FormattedMessage
                                id="Onboarding.sleepingArrangements.bedTypePlaceholder"
                                defaultMessage="Select bed type"
                            />
                        }
                        selectedValue={selectedBedType}
                        onChangeHandler={(option: RoomOption): void =>
                            onChangeBedTypeHandler(id, option)
                        }
                        options={bedTypes}
                        shouldValidate={shouldValidate}
                        disabled={
                            selectedBedSize?.id === BED_SIZE_CHILD ||
                            selectedBedSize?.id === BED_SIZE_CRIB
                        }
                        errorText={
                            <FormattedMessage
                                id="Onboarding.sleepingArrangements.bedTypeError"
                                defaultMessage="Please select bed type"
                            />
                        }
                    />
                </div>
                <IconButton
                    className={styles.remove_bed_icon_button}
                    onClick={(): void => onDeleteHandler(id)}
                >
                    <TrashIcon className={styles.icon} />
                </IconButton>
            </div>
        </div>
    )
}

export default BedRow
