const settings = {
    title: "Payment Settings",
    reserveBalance: "Reserve Balance",
    paymentSettings: "Payment Settings",
    paymentSettingsSmall: "Payment",
    directDepositTitle: "Bank Account",
    checksByMailTitle: "Paper Checks",
    checksByMailEnrolled: "You are enrolled in paper checks.",
    checksByMailNotEnrolled: "You are not enrolled in paper checks.",
    paymentMethod: "Payment Method",
    paymentMethodText:
        "Payment delivery depends on the owner's selected payment method.",
    directDepositFineprint:
        "Deposits and withdrawals usually appear in the account 2-5 business days after the monthly statement.",
    checksByMailFineprint:
        "Paper checks usually take 5-10 business days to arrive by mail. Please confirm your mailing address in your {ProfileLink}.",
    reserveBalanceEmptyState:
        "You'll be able to view and update your reserve balance here.",
    reserveBalanceText:
        "If your expenses exceed your revenue in a given month, your Reserve Account will be used to cover the difference. If your reserve account balance is below {balance}, we'll automatically replenish it from revenue you earn.",
    reserveUpdateAria: "Click this button to update your reserve balance.",
    reserveUpdatedNotification: "Reserve balance updated",
    reserveMinimumRequired: "This unit requires at least {amount} reserve",
    directDepositEnrolled: "You are enrolled in direct deposit.",
    achDirectDepositEnrolled:
        "You are enrolled in ACH direct deposit and ACH expense debit.",
    directDepositNotEnrolled: "You are not enrolled in direct deposit.",
    checkingAccount: "Checking Account",
    savingsAccount: "Savings Account",
    updatePayment: "Add a new account",
    updateAction: "Update",
    updatedNotification: "Payment information updated",
    updatePaymentSub: "Direct Deposit Bank Account",
    receiveByMailSub: "Enroll in Checks",
    receiveByMailText:
        "If selected, your checks will be mailed to your address on file.",
    directDepositRemoved: "Removed from direct deposit",

    addBankAccount: "Add bank account",
    checking: "Checking",
    savings: "Savings",
    routingNumber: "Routing Number",
    nineDigitNumber: "The 9-digit number at the lower left corner of a check",
    mustBeginWith: "Routing number must begin with a 0, 1, 2, or 3",
    mustBe9Digits: "Please enter a 9-digit routing number",
    mustBeValid9Digits: "Please enter a valid 9-digit routing number",
    accountNumber: "Account Number",
    accountHolderName: "Account Holder Name",
    enterBankAccount: "Please enter your bank account number",
    mustBe5to17Digits: "Please enter a 5 to 17-digit account number",
    byClickingYouAuthorize:
        "Because you chose to pay for expenses via ACH Debit, this account will also be charged if your expenses exceed your rental revenue for the month. By clicking 'Agree and Save', you authorize {accountingEntity} to make payments electronically via direct deposit and to debit the identified account for up to $10,000 per transaction per day. This authority will remain in effect until you change or update your payment method.",
    cancel: "cancel",
    agreeAndSave: "Agree and Save",
    savePaymentMethod: "Save Payment Method",
    clickToSaveAria: "Click this button to save your payment method.",
    clickToCancelAria: "Click this button to cancel.",
    clickToRequestChecksAria: "Click this button to request checks by mail.",
    requestChecksByMail: "Request checks by mail instead",
    getYourPaymentsFaster:
        "Get your payments faster by enrolling in direct deposit.",

    errorMessages: {
        updateReserveBalanceError: "Reserve balance too large.",
        updateReserveBalanceNegativeError: "Reserve balance can't be negative.",
        updateReserveBalanceMissingError: "Reserve balance amount is required.",
        sendDirectDepositEmailError:
            "Failed to send Direct Deposit Unenroll email.",
        paymentAccountGeneralError:
            "Something went wrong updating your account information, please try again later.",
        paymentAccountNameError: "Account holder name must be set.",
        paymentAccountNameLengthError:
            "Account holder name is greater than 100 characters.",
        paymentAccountRoutingNumError: "Routing number is invalid.",
        paymentAccountNumError: "Account number is invalid",
        paymentAccountTypeError: "Account type is invalid",
        sendACHUpdateEmailError: "Failed to send ACH update email.",
        somethingWentWrong: "Something went wrong, please try again.",
    },
    emptyState:
        "This is where you can update your reserve balance and payment method. Be sure to set up direct deposit before your first statement posts in order to get your payments faster.",
    linkToStatements: "Statements",
}

export default settings
