import {
    UnitAvailabilityDay,
    UnitAvailabilityDays,
} from "@vacasa/owner-api-models"
import classNames from "classnames"
import {
    addDays,
    format,
    formatISO,
    getDate,
    isBefore,
    subDays,
} from "date-fns"
import { useRef } from "react"
import { useNavigate } from "react-router-dom"
import { trackCalendarReservationDayClicked } from "services/segment/calendar/calendarTracking"
import { isDayBlocked } from "../../validation/DateValidation"
import { DayBookingProbability } from "./DayBookingProbability/DayBookingProbability"
import { Pill } from "./Pill/Pill"

export type DayContentsProps = {
    day: Date
    languageCode: string
    currencyCode: string
    blockAllDays: boolean
    calendarAvailability: UnitAvailabilityDays
    reservationsRatesToggle: "reservations" | "rates"
    daySize: number
    isSelectingDates?: boolean
}

const DayContents = ({
    day,
    languageCode,
    currencyCode,
    blockAllDays,
    calendarAvailability,
    reservationsRatesToggle,
    daySize,
    isSelectingDates,
}: DayContentsProps): JSX.Element => {
    const navigate = useNavigate()
    const ref = useRef<HTMLDivElement>(null)
    const availability =
        calendarAvailability[formatISO(day, { representation: "date" })]
    const blocked = isDayBlocked(day, blockAllDays, calendarAvailability)

    let rate: number | string = availability?.rate || 0
    if (!blocked && languageCode) {
        rate = new Intl.NumberFormat(languageCode, {
            style: "currency",
            currency: currencyCode || "USD",
            maximumSignificantDigits: 4,
        }).format(rate)
    }

    // prevent memory leak, create separate variables as add and subtract methods modify the original value
    const prevFormattedDay = formatISO(subDays(new Date(day.valueOf()), 1), {
        representation: "date",
    })
    const nextFormattedDay = formatISO(addDays(new Date(day.valueOf()), 1), {
        representation: "date",
    })

    const prevDayAvailability: UnitAvailabilityDay | undefined =
        calendarAvailability[prevFormattedDay]
    const isCheckInDay =
        availability?.isCheckinDay ||
        // API returned only the days that are booked
        (prevDayAvailability === undefined && !availability?.isAvailable)

    const nextDayAvailability: UnitAvailabilityDay | undefined =
        calendarAvailability[nextFormattedDay]
    const isCheckoutDay =
        availability?.isCheckoutDay ||
        // API returned only the days that are booked
        (nextDayAvailability === undefined && !availability?.isAvailable)

    const isPastDay = isBefore(day, new Date())

    const leftReservationId = prevDayAvailability?.reservationType
        ? prevDayAvailability?.reservationID
        : undefined

    const rightReservationId = availability?.reservationType
        ? availability?.reservationID
        : undefined

    const onDayClick = (e: React.MouseEvent<HTMLDivElement>) => {
        // If day isn't blocked or if selecting the endDate
        // follow normal flow of selecting dates and not show reservation when clicked
        if (!blocked || isSelectingDates) return

        const isLeftSide = getIsLeftSideClick(
            ref.current?.getBoundingClientRect().width ?? 0,
            e.nativeEvent.offsetX
        )

        let resId = rightReservationId ?? leftReservationId

        if (isLeftSide && leftReservationId) {
            resId = leftReservationId
        }

        if (!resId) return

        trackCalendarReservationDayClicked(resId, format(day, "yyyy-MM-dd"))

        navigate(
            {
                pathname: `/calendar/hold/${resId}`,
            },
            {
                replace: true,
            }
        )
    }

    return (
        <>
            <div
                className={classNames("CustomCalendarDay", {
                    checkInDay: isCheckInDay,
                    checkOutDay: isCheckoutDay,
                    pointer:
                        blocked &&
                        (leftReservationId || rightReservationId) &&
                        !isSelectingDates,
                })}
                onClick={onDayClick}
                ref={ref}
            >
                <span className={isPastDay ? "PastDay" : ""}>
                    {day && getDate(day)}
                </span>
                {reservationsRatesToggle === "rates" && (
                    <span
                        style={{
                            visibility:
                                !blocked && !isCheckInDay
                                    ? "visible"
                                    : "hidden",
                        }}
                        data-testid="rates-day"
                        className="formattedRate"
                    >
                        {rate}
                    </span>
                )}

                {availability && reservationsRatesToggle === "reservations" && (
                    <Pill
                        isCheckInDay={isCheckInDay}
                        isCheckoutDay={isCheckoutDay}
                        prevDayAvailability={prevDayAvailability}
                        availability={availability}
                        isPastDay={isPastDay}
                    />
                )}
            </div>
            <div className="daySelectedCircleBorder" />
            {availability && reservationsRatesToggle === "rates" && (
                <DayBookingProbability
                    size={daySize / 3.5}
                    visible={!blocked}
                    availabilityDay={availability}
                />
            )}
        </>
    )
}

/**
 * Wrapper that returns a function that defines contents of individual calendar days.
 * Intended to be passed as renderDayContents prop of DayPickerRangeController,
 * as part of the calendar from react-dates library.
 * The wrapping is because react-dates does not accept a React.FC type for the renderDayContents prop,
 * meaning hooks cannot be called within this function and any dependencies need to be arguments.
 */
function GetDayContents(
    languageCode: string,
    currencyCode: string,
    blockAllDays: boolean,
    calendarAvailability: UnitAvailabilityDays,
    reservationsRatesToggle: "reservations" | "rates",
    daySize: number,
    isSelectingDates?: boolean
): (day: Date) => JSX.Element {
    // The actual function to return
    const dayContents = (day: Date) => {
        return (
            <>
                <DayContents
                    day={day}
                    languageCode={languageCode}
                    currencyCode={currencyCode}
                    blockAllDays={blockAllDays}
                    calendarAvailability={calendarAvailability}
                    reservationsRatesToggle={reservationsRatesToggle}
                    daySize={daySize}
                    isSelectingDates={isSelectingDates}
                />
            </>
        )
    }
    return dayContents
}

const getIsLeftSideClick = (dayWidth: number, clickOffset: number) => {
    if (dayWidth === 0) return true
    const halfDivWidth = dayWidth / 2
    const mouseXPos = clickOffset
    return mouseXPos <= halfDivWidth
}

export { GetDayContents }
