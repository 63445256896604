import { JSONAPIResource, Reservation } from "@vacasa/owner-api-models"
import { OWNER_CANCELLATION_REASON } from "Constants"
import useRouter from "hooks/common/useRouter"
import { useContactId } from "hooks/user"
import { useDeleteOwnerHoldMutation } from "hooks/owner-holds"
import { useToastNotification } from "lib/components/ToastNotification"
import { FC } from "react"
import { useIntl } from "react-intl"
import LoggingService from "services/logging/logging.service"
import { trackOwnerHoldRemoved } from "services/segment/ownerHold/ownerHoldTracking"
import { isUnauthorizedOrCancelledRequest } from "utils/error/error"
import ReservationStaticDisplay from "../../../../lib/components/FlyOut/Holds/ReservationStaticDisplay"
import { useRootService } from "../../../../services"
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog"
import Layout from "../Layout/Layout"
import { UnitInfo } from "../UnitInfo"
import styles from "./DeleteReservation.module.scss"
import { useUserCurrencyCode, useUser } from "hooks/user"
import { useCurrentUnit } from "hooks/units"

type DeleteReservationProps = {
    reservation: JSONAPIResource<Reservation>
    unitInfo: UnitInfo
}

const DeleteReservation: FC<DeleteReservationProps> = ({
    reservation,
    unitInfo,
}) => {
    const intl = useIntl()
    const router = useRouter()
    const { errorService } = useRootService()

    const { addToastNotification } = useToastNotification()

    const deleteOwnerHoldMutation = useDeleteOwnerHoldMutation()
    const contactId = useContactId() ?? ""
    const { unitId } = useCurrentUnit()
    const currencyCode = useUserCurrencyCode()
    const { user } = useUser()

    const goToViewReservation = () =>
        router.navigate(
            {
                pathname: `/calendar/hold/${reservation.id}`,
                search: router.location.search,
                hash: router.location.hash,
            },
            { replace: true }
        )

    const onDeleteClick = () => {
        if (!user || !unitId) return

        deleteOwnerHoldMutation.mutate(
            {
                contactId,
                data: {
                    Cancelled: 1,
                    ExtraCleaningDays: 0,
                    reason: OWNER_CANCELLATION_REASON,
                    UserID: user.userId,
                    ReservationID: String(reservation.id),
                    UnitID: unitId,
                },
                unitId: unitId,
            },
            {
                onError: (error, payload) => {
                    if (!isUnauthorizedOrCancelledRequest(error)) {
                        LoggingService.error({
                            message: `Failed to remove owner hold: ${payload.data.ReservationID}`,
                            error,
                            tags: {
                                unitID: payload.unitId,
                                contactID: payload.contactId,
                            },
                        })
                    }
                    errorService.mapErrorCodeToString(Number(error.code ?? 0))
                    errorService.displayError()
                },
                onSuccess: data => {
                    addToastNotification({
                        content: intl.formatMessage({
                            id: "CalendarPage.flyout.holdRemoved",
                            defaultMessage: "Hold removed",
                        }),
                    })

                    trackOwnerHoldRemoved(
                        String(data.id),
                        data.attributes.clean_after_stay
                    )

                    router.navigate("/calendar")
                },
            }
        )
    }

    return (
        <Layout>
            <Layout.Content overlayBlocker>
                <ReservationStaticDisplay
                    reservation={reservation}
                    unitInfo={unitInfo}
                    currency={currencyCode}
                />
            </Layout.Content>
            <Layout.Footer className={styles["delete-reservation__footer"]}>
                <ConfirmDialog
                    title={"Delete Owner Hold"}
                    message={intl.formatMessage({
                        id: "CalendarPage.flyout.deleteAreYouSure",
                        defaultMessage: "Are you sure you want to delete this?",
                    })}
                    confirmText="Delete Owner Hold"
                    onCancelClick={goToViewReservation}
                    onConfirmClick={onDeleteClick}
                    loading={deleteOwnerHoldMutation.isLoading}
                />
            </Layout.Footer>
        </Layout>
    )
}
export { DeleteReservation }
