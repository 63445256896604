import { AxiosError } from "axios"
import { UseMutationResult, useMutation, useQueryClient } from "react-query"
import { W9Request } from "./types"
import { useContactId } from "hooks/user"
import { patchW9 } from "./patchW9"

export const useW9UpdateMutation = (): UseMutationResult<
    void,
    AxiosError,
    W9Request
> => {
    const queryClient = useQueryClient()
    const contactId = useContactId() ?? ""
    return useMutation(data => patchW9(contactId, data), {
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ["w9-tax-submission", contactId],
            })
        },
    })
}
