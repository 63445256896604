import { JSONAPIResource } from "@vacasa/owner-api-models"
import { authAxios } from "../../api/authAxios"
import { Survey } from "./customSurveyType"

const fetchSurvey = (surveyId: string, unitId: string | null): Promise<
    JSONAPIResource<Survey>
> =>
    authAxios
        .get<{ data: JSONAPIResource<Survey> }>('/v1/surveys',{
            params: {
                id: surveyId,
                unitId
            }
        })
        .then(response => response.data.data)

export { fetchSurvey }
