const statements = {
    faqAriaLabel: "View FAQ",
    title: "Statements",
    emptyStateCTA: "Learn More About Statements",
    emptyStateEarnings:
        "New statements will be available by the 10th of each month.",

    pdf: "PDF",
    pdfAriaLabel: "View PDF",
    pdfNotification: "Preparing to download PDF",
    fileName: "statement",

    print: "Print",
    printAriaLabel: "Print View",

    paymentSummary: "Payment Summary: All Rentals",
    beginningBalance: "Beginning Balance",
    payment: "Payment",
    payments: "Payments",
    proceeds: "Proceeds",
    proceedsBalance: "Proceeds Balance",
    coOwnerProceeds: "Co-Owner Proceeds",
    endingBalance: "Ending Balance",
    details: "Details",
    summary: "Summary",
    nightsOccupied: "Nights Occupied",
    occupancy: "Occupancy",
    reserveBalance: "Reserve Balance",

    beginningReserveBalance: "Beginning Reserve Balance",
    EndingReserveBalance: "Ending Reserve Balance",
    amountHeld: "Amount Held",
    amountUsed: "Amount Used",

    netRent: "Net Rent",
    expense: "Expense",
    expenses: "Expenses",

    month: "Month",
    nights: "Nights",
    grossRent: "Gross Rent",
    mgmtFee: "Mgmt Fee",
    total: "Total",
    credits: "Credits",

    checkNumber: "Check #",
    paymentDescription: "Payment to account ending in",
    statementYearSummary: "{year} Year Summary",

    canadaSalesTaxesHeader: "Taxes - Expenses & Management Fees",

    tooltips: {
        proceeds: "Amount you'll be paid for this month",
        occupancy:
            "The percentage of available nights this month that you rented to guests (does not include your personal use dates and complimentary stays)",
        nightsOccupied:
            "How many nights this month your home hosted guests (only counts reservations with check-out dates within the month)",
    },
    paymentSettings: "Payment Settings",
    paymentSettingsSmall: "Settings",
    paymentSettingsNotification:
        "Manage your bank details and payment settings here.",
    paymentSettingsNotificationTitle: "Payment Settings",
    checkItOut: "Check it Out",
    settings: "Settings",
    backToTop: "Back to Top",
    needHelp: "Need Help?",
    faqAbbreviation: "FAQ",
    errorDownloading: "Error downloading",
    pdfDownloadError:
        "We were unable to create a PDF of your statement. Please try again later.",

    emptyStateUnit: "Rental was not active during this period.",
    faq: {
        whereDoISendPayments: {
            answer: {
                canada: "If your home is in Canada",
                allOthers: "All others",
            },
        },
    },
    netRentNoteBegin: "NOTE: ",
    netRentNoteContent:
        "The entire stay spans across {reservationMonthSpan} months ({reservationPeriod}). Monthly revenue will appear on each month’s respective statement(s).",
}

export default statements
