/* eslint-disable camelcase */
import { Owner } from "@vacasa/owner-api-models"
import LoggingService from "services/logging/logging.service"
import { isJestTestEnvironment } from "utils/environment"
import { SEGMENT_KEY } from "../../Environment"
import rootService from "../index"
import { buildCommonFields } from "./utils"

interface CustomSegmentAnalytics extends SegmentAnalytics.AnalyticsJS {
    methods: (keyof CustomSegmentAnalytics)[]
    SNIPPET_VERSION: string
    initialize?: boolean
    invoked?: boolean
    factory?: (method: string) => () => CustomSegmentAnalytics
    _loadOptions?: SegmentAnalytics.SegmentOpts
    // Below are unused analytics.methods separated out for visibility.
    trackSubmit?: unknown
    trackClick?: unknown
    pageview?: unknown
    once?: unknown
    off?: unknown
    addSourceMiddleware?: unknown
    addIntegrationMiddleware?: unknown
    addDestinationMiddleware?: unknown
}

declare global {
    interface Window {
        analytics: CustomSegmentAnalytics
    }
}

class SegmentService {
    constructor() {
        this.init()
    }

    public unitId: string | null = null
    public user: Owner | null = null
    public isEmployee = false
    /*
        This function is used to initialize observable properties
        DO NOT initialize class properties outside of this function
    */
    init = async () => {
        const segmentKey = SEGMENT_KEY
        // Create a queue, but don't obliterate an existing one!
        const analytics = (window.analytics = window.analytics || [])

        // If the real analytics.js is already on the page return.
        if (analytics.initialize) return

        // If the snippet was invoked already then just ignore it.
        if (analytics.invoked) {
            return
        }

        // Invoked flag, to make sure the snippet
        // is never invoked twice.
        analytics.invoked = true

        // A list of the methods in Analytics.js to stub.
        analytics.methods = [
            "trackSubmit",
            "trackClick",
            "trackLink",
            "trackForm",
            "pageview",
            "identify",
            "reset",
            "group",
            "track",
            "ready",
            "alias",
            "debug",
            "page",
            "once",
            "off",
            "on",
            "addSourceMiddleware",
            "addIntegrationMiddleware",
            "setAnonymousId",
            "addDestinationMiddleware",
        ]

        // Define a factory to create stubs. These are placeholders
        // for methods in Analytics.js so that you never have to wait
        // for it to load to actually record data. The `method` is
        // stored as the first argument, so we can replay the data.
        analytics.factory = function (method) {
            return function (...args: string[]) {
                args.unshift(method)
                if (Array.isArray(analytics)) {
                    analytics.push(args)
                }
                return analytics
            }
        }

        // For each of our methods, generate a queueing stub.
        for (let i = 0; i < analytics.methods.length; i++) {
            const key = analytics.methods[i]
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            analytics[key] = analytics.factory(key)
        }

        // Define a method to load Analytics.js from our CDN,
        // and that will be sure to only ever load it once.
        analytics.load = function (
            key,
            options?: SegmentAnalytics.SegmentOpts
        ) {
            // Create an async script element based on your key.
            const script = document.createElement("script")
            script.type = "text/javascript"
            script.async = true
            script.src =
                "https://cdn.segment.com/analytics.js/v1/" +
                key +
                "/analytics.min.js"

            // Insert our script next to the first script element.
            const first = document.getElementsByTagName("script")[0]
            if (first && first.parentNode) {
                first.parentNode.insertBefore(script, first)
                analytics._loadOptions = options
            }
        }

        // Add a version to keep track of what's in the wild.
        analytics.SNIPPET_VERSION = "5.2.0"

        if (segmentKey === undefined) {
            // Reduce terminal output when running tests
            if (isJestTestEnvironment() || !LoggingService) return

            LoggingService.warn({ message: "Segment Key is undefined" })
            return
        }

        // Load Analytics.js with your key, which will automatically
        // load the tools you've enabled for your account. Boosh!
        analytics.load(segmentKey)

        // Make the first page call to load the integrations. If
        // you'd like to manually name or tag the page, edit or
        // move this call however you'd like.
        analytics.page()
    }

    /**
     * This method is used to set up event tracking in segment
     * @argument string evt name of event that is tracked in segment.io dashboard
     * @argument object properties additional information that would like to be tracked in the segment dashboard.
     */
    track = (evt: string, props: Record<string, unknown>): void => {
        const eventProps = {
            ...buildCommonFields(this.user, this.isEmployee, this.unitId),
            ...props,
        }
        try {
            window.analytics.track(evt, eventProps)
        } catch (error) {
            window.analytics.track(evt, eventProps)
            rootService.errorService.captureException(
                error,
                this.user?.contactId,
                this.user?.accountInfo.email
            )
        }
    }
    /**
     * This method is used to send click tracking information to segment.io
     * @argument object props properties additional information that would like to be tracked in the segment dashboard.
     * @argument string evt event name of event that is tracked in segment.io dashboard
     */
    clickTrack = (props: Record<string, unknown> | false, evt?: string) => {
        const eventProps = {
            ...buildCommonFields(this.user, this.isEmployee, this.unitId),
            ...props,
        }
        try {
            window.analytics.track(evt ? evt : "Click", eventProps)
        } catch (error) {
            rootService.errorService.captureException(
                error,
                this.user?.contactId,
                this.user?.accountInfo.email
            )
        }
    }
    /**
     * This method sets up segment to tie a user to their actions and record traits about them.
     * @argument string UID unique identifier
     * @argument object traits Free-form dictionary of traits of the user, like email or name.
     */
    identify = (
        uid: string,
        loginType: string,
        traits?: Record<string, unknown>
    ) => {
        let eventProps = {
            ...buildCommonFields(this.user, this.isEmployee, this.unitId),
            legacy_user_id: this.user?.userId,
            email: this?.user ? this.user.email : "",
            user_type: loginType,
        }
        if (traits) eventProps = { ...eventProps, ...traits }
        try {
            window.analytics.identify(uid, eventProps)
        } catch (error) {
            rootService.errorService.captureException(
                error,
                this.user?.contactId,
                this.user?.accountInfo.email
            )
        }
    }

    page(
        opts: string | Record<string, unknown> | SegmentAnalytics.SegmentOpts
    ) {
        window.analytics.page(opts)
    }

    experimentTracking = (experimentName: string, variation: string) => {
        const props = {
            ...buildCommonFields(this.user, this.isEmployee, this.unitId),
            experiment: experimentName,
            variation,
        }
        try {
            window.analytics.track("Split Experiment", props)
        } catch (error) {
            rootService.errorService.captureException(
                error,
                this.user?.contactId,
                this.user?.accountInfo.email
            )
        }
    }
}

const segmentService = new SegmentService()

export default segmentService
