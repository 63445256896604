import {
    JSONAPIDocument,
    ProgramType,
    UnitProgram,
} from "@vacasa/owner-api-models"
import { AxiosError } from "axios"
import { useQuery, UseQueryResult } from "react-query"
import { fetchUnitPrograms } from "./fetchUnitPrograms"

const useUnitPrograms = (
    unitId: string,
    programType?: ProgramType,
    includeNotEnrolled?: boolean
): UseQueryResult<JSONAPIDocument<UnitProgram, []>, AxiosError> =>
    useQuery(
        ["unit-programs", unitId, programType],
        () => fetchUnitPrograms({ unitId, programType, includeNotEnrolled }),
        {
            refetchInterval: 1000 * 60 * 60, // 1 hour
            staleTime: 1000 * 60 * 60, // 1 hour
        }
    )

export { useUnitPrograms }
