import { FormattedMessage, useIntl } from "react-intl"
import { Label } from ".."
import { TextField } from "../../../../../../lib/components/TextField/TextField"
import styles from "./HoldNotes.module.scss"

type NotesProps = {
    notes?: string
    required: boolean
    onChange: (notes: string) => void
}

const HoldNotes: React.FC<NotesProps> = ({ notes, required, onChange }) => {
    const intl = useIntl()
    return (
        <div>
            <Label data-testid="holdnotes-label">
                {required ? (
                    <FormattedMessage
                        id="Calendar.flyout.notes.titleRequired"
                        defaultMessage="Notes* "
                    />
                ) : (
                    <FormattedMessage
                        id="Calendar.flyout.notes.title"
                        defaultMessage="Notes "
                    />
                )}
                <span className="text-dusk-lighter">
                    {required ? (
                        <FormattedMessage
                            id="Calendar.flyout.notes.required"
                            defaultMessage="(required)"
                        />
                    ) : (
                        <FormattedMessage
                            id="Calendar.flyout.notes.optional"
                            defaultMessage="(optional)"
                        />
                    )}
                </span>
            </Label>
            <div className={styles.textareaRow}>
                <TextField
                    required={required}
                    value={notes ?? ""}
                    placeholder={
                        required
                            ? intl.formatMessage({
                                  id: "Calendar.flyout.notes.placeholder",
                                  defaultMessage:
                                      "Help us improve our category options by providing a note about this hold",
                              })
                            : undefined
                    }
                    multiline
                    maxLength={255}
                    onChange={(event): void => {
                        onChange(event.target.value)
                    }}
                />
            </div>
        </div>
    )
}

export { HoldNotes }
