import { ReactElement, useEffect, useState } from "react"
import DefaultError from "../../lib/components/ErrorBoundaries/DefaultError"
import styles from "./Maintenance.module.scss"

import { ReferralBanner } from "components/ReferralBanner"
import Loader from "lib/components/Loader/index"
import { observer } from "mobx-react"
import { UnitSelectWrapper } from "views/UnitSelectWrapper"
import { NoUnitState } from "./EmptyState"
import { MaintenanceTickets } from "./MaintenanceTickets"
import { useOwnerFeatureFlag } from "hooks/owner-feature-flag"
import { isFeatureFlagEnabled } from "utils/feature-flag"
import { SupportContacts } from "./components/SupportContacts/SupportContacts"
import { FormattedMessage, useIntl } from "react-intl"
import { isEmpty } from "lodash"
import { MaintenanceTicket, TicketResponse } from "@vacasa/owner-api-models"
import { CreateTicketFlyout } from "utils/flyout/maintenanceFlyoutUtil"
import { ReactComponent as PlusCircleSVG } from "../../assets/icon-plus-circle.svg"
import * as Button from "lib/components/Buttons/BaseButton"
import { useTickets } from "hooks/ticket"
import { trackMaintenancePageViewed } from "services/segment/maintenance/maintenanceTracking"
import { OWNER_CREATED_COMMENTS_ALERT_DISMISSED } from "constants/preferences.constants"
import { trackSupportSubmitButtonClicked } from "services/segment/supportHub/supportHubTracking"
import { useIsEmployee } from "hooks/user"
import { useLocation } from "react-router-dom"
import { getPrepoulatedTicketParams } from "utils/tickets/ticketsUtil"
import { FeatureAlert } from "lib/components/FeatureAlert/FeatureAlert"
import { useCurrentUnit } from "hooks/units"
import { ActiveStatus, TerminatedStatus } from "utils/unit"

export type TicketTypes = {
    filteredOpenTickets: TicketResponse[]
    filteredClosedTickets: TicketResponse[]
}

export type MaintenanceTicketTypes = {
    filteredOpenTickets: MaintenanceTicket[]
    filteredClosedTickets: MaintenanceTicket[]
}

export const MAINTENANCE_TEST_ID = "maintenance_test_id"
export const Maintenance = observer((): ReactElement => {
    const { unit, unitId } = useCurrentUnit()
    const showNoUnitState =
        !unit ||
        (unit.attributes.active === ActiveStatus.Inactive &&
            !unit.attributes.display &&
            unit.attributes.terminated === TerminatedStatus.NotTerminated)

    const intl = useIntl()
    const isEmployee = useIsEmployee()

    const isRequestDisabled = () => {
        if (isEmployee) {
            return true
        }
        if (!isEmpty(unit) && unit?.attributes.status.active) {
            return false
        }
        return true
    }

    const [maintenanceTickets, setMaintenanceTickets] =
        useState<MaintenanceTicketTypes>({
            filteredOpenTickets: [],
            filteredClosedTickets: [],
        })

    const updateMaintenanceTickets = (
        key: string,
        value: MaintenanceTicket[]
    ) => {
        setMaintenanceTickets(prevState => ({
            ...prevState,
            [key]: value,
        }))
    }

    const [showTicketSubmitted, setShowTicketSubmitted] = useState(false)
    const location = useLocation()
    const [showFlyout, setShowFlyout] = useState(
        getPrepoulatedTicketParams(location.search)?.action === "create" ??
            false
    )
    const [isTicketSelected, setIsTicketSelected] = useState(false)
    const [ticket, setTicket] = useState<TicketResponse | null>(null)
    const [isOpenViewSelected, setIsOpenViewSelected] = useState(true)

    const openButtonHandler = () => {
        setIsOpenViewSelected(true)
        setIsTicketSelected(false)
        setTicket(null)
    }

    const closedButtonHandler = () => {
        setIsOpenViewSelected(false)
        setIsTicketSelected(false)
        setTicket(null)
    }

    const updateShowTicketSubmitted = (shouldShowTicketSubmitted: boolean) =>
        setShowTicketSubmitted(shouldShowTicketSubmitted)

    const updateShowFlyout = (shouldShowFlyout: boolean) =>
        setShowFlyout(shouldShowFlyout)

    const triggerDetailsBox = (ticket: TicketResponse) => {
        setIsTicketSelected(true)
        setTicket(ticket)
    }

    const renderNewRequestButton = (mobile = false) => {
        const disabled = isRequestDisabled()

        // no reason to fix a disabled button to the bottom of a mobile screen
        if (disabled && mobile) {
            return null
        }

        const buttonStyle = mobile ? { width: "100%" } : undefined
        let classNames =
            "flex-horizontal-right display-inline button new-request button-box new-request"
        classNames = mobile
            ? `${classNames} new-request-mobile`
            : `${classNames} new-request-desktop`

        const onSubmitButtonClick = () => {
            setShowFlyout(true)
            trackSupportSubmitButtonClicked()
        }

        return (
            <div style={buttonStyle} data-testid="new-request-button">
                <Button.Utility
                    className={classNames}
                    onClick={onSubmitButtonClick}
                    ariaLabel={intl.formatMessage({
                        id: "Maintenance.ariaLabel",
                        defaultMessage:
                            "Click here to submit a maintenance request",
                    })}
                    inlineStyle={buttonStyle}
                    disabled={disabled}
                >
                    <p
                        className="zero-margin zero-padding button new-request text"
                        data-testid="new-request-text"
                    >
                        <PlusCircleSVG />
                        <FormattedMessage
                            id="Maintenance.submitRequest"
                            defaultMessage="Submit Request"
                        />
                    </p>
                </Button.Utility>
            </div>
        )
    }

    const closeDetailsBox = () => {
        setIsTicketSelected(false)
        setTicket(null)
        setShowTicketSubmitted(false)
    }

    const ownerCreatedCommentsFeatureFlag = useOwnerFeatureFlag(
        "owner-created-ticket-comments"
    )
    const ownerCreatedCommentsEnabled = isFeatureFlagEnabled(
        ownerCreatedCommentsFeatureFlag
    )

    const ownerReopenTicketFeatureFlag = useOwnerFeatureFlag(
        "owner-portal-reopen-ticket"
    )
    const ownerReopenTicketEnabled = isFeatureFlagEnabled(
        ownerReopenTicketFeatureFlag
    )

    const ticketsQuery = useTickets(unitId ?? "")

    const tickets: TicketTypes = ticketsQuery.isSuccess
        ? {
              filteredOpenTickets: ticketsQuery.data.tickets.filter(
                  ticket => ticket.attributes.status < 9
              ),
              filteredClosedTickets: ticketsQuery.data.tickets.filter(
                  ticket => ticket.attributes.status >= 9
              ),
          }
        : {
              filteredOpenTickets: [],
              filteredClosedTickets: [],
          }

    const unreadCompletedTickets = ticketsQuery.data?.meta?.unreadCompletedTickets ?? 0
    const shouldShowClosedAlert = ownerReopenTicketEnabled && ticketsQuery.isSuccess && unreadCompletedTickets > 0 && isOpenViewSelected;


    useEffect(() => {
        trackMaintenancePageViewed()
    }, [])

    useEffect(() => {
        if (ticketsQuery.isSuccess && ticket) {
            const newTicketData = ticketsQuery.data.tickets.find(
                updatedTicket => updatedTicket.id === ticket.id
            )
            if (newTicketData) {
                setTicket(newTicketData)
            }
        }
    }, [ticket, ticketsQuery.data, ticketsQuery.isSuccess])

    const fetchError = ticketsQuery.isError
    const isFetchingData = ticketsQuery.isLoading

    const fetchTicketData = ticketsQuery.refetch

    const handleCommentSubmitted = () => {
        ticketsQuery.refetch()
    }

    const maintenanceTicketsProps = {
        isOpenViewSelected,
        fetchError,
        isTicketSelected,
        triggerDetailsBox,
        closeDetailsBox,
        fetchTicketData,
        ticket,
        showTicketSubmitted,
        tickets,
        maintenanceTickets,
        handleCommentSubmitted,
    }

    const isLoading = isFetchingData && !fetchError

    const renderMaintenanceHeader = () => {
        return (
            <div className="header">
                <div className={styles.titleContainer}>
                    <h1 className={styles.titleText}>
                        <FormattedMessage
                            id="Maintenance.supportHub"
                            defaultMessage="Support"
                        />
                    </h1>

                    <div className={styles.submitRequestButton}>
                        {renderNewRequestButton(false)}
                        <div className={styles.notificationWrap}>
                            {ownerCreatedCommentsEnabled && (
                                <FeatureAlert
                                    feature="owner-created-comment-alert-dismissed"
                                    body={intl.formatMessage({
                                        id: "Maintenance.featureAlertBody",
                                        defaultMessage:
                                            "Add a comment to your ticket to provide more information or follow up with a question, which will streamline communication about a specific ticket.",
                                    })}
                                    title={intl.formatMessage({
                                        id: "Maintenance.featureAlertTitle",
                                        defaultMessage:
                                            "NEW! Two-way Ticket Comments",
                                    })}
                                    userPreference={
                                        OWNER_CREATED_COMMENTS_ALERT_DISMISSED
                                    }
                                    pointerPosition="top-left"
                                />
                            )}
                        </div>
                    </div>
                    <div className={styles.maintenanceUnitSelector}>
                        <UnitSelectWrapper />
                    </div>
                </div>
                <div className="button-section">
                    <div
                        className="flex-horizontal-left display-inline"
                        style={{ paddingRight: "24px" }}
                    >
                        <div className="flex btn-block unit-summary-switch-view-container tab-container">
                            <button
                                className={
                                    isOpenViewSelected
                                        ? "tab open active"
                                        : "tab open"
                                }
                                onClick={openButtonHandler}
                            >
                                <FormattedMessage
                                    id="Maintenance.open"
                                    defaultMessage="Open"
                                />
                            </button>
                            <div className={styles.closedButtonContainer} >
                                <button
                                    className={
                                        isOpenViewSelected
                                            ? "tab closed"
                                            : "tab closed active"
                                    }
                                    onClick={closedButtonHandler}
                                >
                                    <FormattedMessage
                                        id="Maintenance.closed"
                                        defaultMessage="Closed"
                                    />
                                </button>
                                {shouldShowClosedAlert && (
                                        <div className={styles.alertCircle}>
                                            {unreadCompletedTickets}
                                            </div>

                                    )
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }

    if (showNoUnitState) {
        return <NoUnitState />
    }

    return (
        <>
            <ReferralBanner />
            <div
                className={`container-fluid content-container ${
                    !isRequestDisabled() ? "with-mobile-button" : null
                }`}
                data-testid={MAINTENANCE_TEST_ID}
            >
                {showFlyout && (
                    <CreateTicketFlyout
                        updateShowTicketSubmitted={updateShowTicketSubmitted}
                        updateShowFlyout={updateShowFlyout}
                        tickets={tickets}
                        maintenanceTickets={maintenanceTickets}
                        updateMaintenanceTickets={updateMaintenanceTickets}
                        triggerDetailsBox={triggerDetailsBox}
                        category={
                            getPrepoulatedTicketParams(location.search)
                                ?.category
                        }
                    />
                )}
                {renderMaintenanceHeader()}
                {isLoading && <Loader className={"maintenance-loader"} />}
                {!isLoading && (
                    <>
                        <MaintenanceTickets {...maintenanceTicketsProps} />
                        <div style={{ margin: "0 0 36px" }}>
                            <SupportContacts
                                contacts={unit?.attributes.contacts ?? []}
                            />
                        </div>
                        <div>
                            <DefaultError />
                        </div>
                    </>
                )}
            </div>
        </>
    )
})
