import classnames from "classnames"
import {
    ChangeEvent,
    CSSProperties,
    DetailedHTMLProps,
    FocusEvent,
    InputHTMLAttributes,
    TextareaHTMLAttributes,
    useMemo,
} from "react"
import SVGIcon from "../SVGIcon"
import styles from "./BaseInput.module.scss"

enum TYPES {
    DEFAULT = "input",
    FOCUS = "input--focus",
    PRISTINE = "input--pristine",
    INVALID = "input--invalid",
    VALID = "input--valid",
    DISABLED = "input--disabled",
    FLOAT_ANIMATION = "input--float",
}

enum SIZE {
    SMALL = "small",
    MEDIUM = "medium",
    LARGE = "large",
    EXTRA_LARGE = "extra--large",
    TEXT_AREA = "text-area",
}

enum LABELTYPES {
    DEFAULT = "label",
    FLOAT_LABEL = "floating--label",
    HELPER = "label--helper",
    FLOAT_INVALID = "label--invalid",
    LABEL_DARK_BACKGROUND = "label--dark--background",
}

type InputProps = DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
>
type TextareaProps = DetailedHTMLProps<
    TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
>
type CommonProps = Omit<InputProps | TextareaProps, "onChange" | "onBlur">

type Props = CommonProps & {
    floatLabel?: LABELTYPES
    inlineStyle?: CSSProperties
    inputType?: TYPES
    inputSize: SIZE
    isBlurred?: boolean
    isValid?: boolean
    showIconOnValidate?: boolean
    textArea?: boolean
    onBlur?: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    onChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
} & typeof DEFAULT_PROPS

const BaseInput = (props: Props): JSX.Element => {
    const {
        floatLabel,
        inlineStyle,
        inputType,
        inputSize,
        isBlurred,
        isValid,
        showIconOnValidate,
        textArea,
        ...commonProps
    } = props

    const displaySVG = useMemo(() => {
        const validStyle = { marginTop: "0px", height: "42px" }
        const invalidStyle = { marginTop: "0px", height: "44px" }
        const validSVG = (
            <SVGIcon
                svg="icon-feather-check-circle"
                className="w-32"
                style={validStyle}
            />
        )
        const invalidSVG = (
            <SVGIcon
                svg="icon-feather-x-circle"
                className="w-32"
                style={invalidStyle}
            />
        )
        if (isValid) return validSVG
        else if (!isValid && isBlurred) return invalidSVG
        return
    }, [isValid, isBlurred])

    let inputClassNames = classnames(
        styles.input,
        styles[inputType || TYPES.FOCUS],
        styles[`input--${inputSize}`],
        commonProps.disabled ? styles["input--disabled"] : "",
        styles[floatLabel ? TYPES.FLOAT_ANIMATION : LABELTYPES.DEFAULT],
        styles[showIconOnValidate ? "icon--right" : ""],
        commonProps.value ? styles["not--empty"] : "",
        "type-body"
    )
    let iconRightClassName = classnames(
        isValid
            ? styles[`input__icon--valid--right--${inputSize}`]
            : styles[`input__icon--right--${inputSize}`]
    )
    if (isValid) {
        inputClassNames = classnames(inputClassNames, styles[TYPES.VALID])
    } else if (!isValid && isBlurred) {
        inputClassNames = classnames(inputClassNames, styles[TYPES.INVALID])
    } else if (!isBlurred) {
        iconRightClassName = classnames(styles["hide"])
    }
    const inputWrapper = classnames(styles["wrapper__input"])
    const containerStyle = textArea ? { height: "160px" } : {}

    return (
        <div style={containerStyle}>
            <div className={inputWrapper} style={inlineStyle}>
                <span>
                    {!textArea && (
                        <input
                            {...(commonProps as InputProps)}
                            className={classnames(
                                commonProps.className,
                                inputClassNames
                            )}
                        />
                    )}
                    {textArea && (
                        <textarea
                            {...(commonProps as TextareaProps)}
                            className={classnames(
                                commonProps.className,
                                inputClassNames
                            )}
                            rows={7}
                        />
                    )}
                    {floatLabel && (
                        <span
                            className={
                                styles[
                                    floatLabel
                                        ? LABELTYPES.FLOAT_LABEL
                                        : LABELTYPES.DEFAULT
                                ]
                            }
                        >
                            {commonProps.placeholder}
                        </span>
                    )}
                    {showIconOnValidate && !!commonProps.value && (
                        <div className={iconRightClassName}>{displaySVG}</div>
                    )}
                </span>
            </div>
        </div>
    )
}

const DEFAULT_PROPS = {
    inputSize: SIZE.MEDIUM,
    type: "text",
}

BaseInput.defaultProps = DEFAULT_PROPS
BaseInput.Size = SIZE
BaseInput.Types = TYPES
BaseInput.LabelTypes = LABELTYPES

export default BaseInput
