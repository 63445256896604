import { DayPicker } from "react-dates"
import { formatISO, eachDayOfInterval, startOfMonth, format } from "date-fns"
import { isSameDay } from "date-fns/fp"

import { WeekHeader } from "../shared/WeekHeader/WeekHeader"
import * as NavButtons from "../shared/NavButtons"
import { CalendarDay } from "../shared/CalendarDay"
import { useState, useCallback, useEffect } from "react"
import { useMediaQuery } from "@material-ui/core"
import { endOfMonth } from "date-fns"
import { trackSubmitAvailabilityCalendarDateClicked } from "services/segment/onboarding/onboardingTracking"

type IsDayBlocked = (date: Date) => boolean

type MultiDayHookResult = {
    handleDayClicked: (day: Date) => void
    selectedDates: readonly Date[]
    clearDates: () => void
}

/**
 * Companion hook for {@link MultiDayPicker}
 */
function useMultiDay(
    isDayBlocked: IsDayBlocked,
    unitId: string
): MultiDayHookResult {
    const [selectedDates, setSelectedDates] = useState<Date[]>([])
    const handleDayClicked = useCallback(
        (day: Date) => {
            if (isDayBlocked(day)) return
            trackSubmitAvailabilityCalendarDateClicked(unitId)
            setSelectedDates(prev => {
                const dateIndex = prev.findIndex(d => isSameDay(d, day))
                if (dateIndex === -1) return [...prev, day]
                return [
                    ...prev.slice(0, dateIndex),
                    ...prev.slice(dateIndex + 1),
                ]
            })
        },
        [isDayBlocked, unitId]
    )

    const clearDates = useCallback(() => {
        setSelectedDates([])
    }, [])

    return {
        handleDayClicked,
        selectedDates,
        clearDates,
    }
}

export type MultiDayPickerProps = {
    selectedDates: readonly Date[]
    onDayClicked: (date: Date) => void
    isDayBlocked?: IsDayBlocked
}

/**
 * A calendar that a user can select a discrete list of dates from
 *
 * Use with the companion hook for simple selection use {@link useMultiDay}
 */
const MultiDayPicker = ({
    onDayClicked,
    selectedDates,
    isDayBlocked,
}: MultiDayPickerProps): JSX.Element => {
    const [currentMonth, setCurrentMonth] = useState<Date>(new Date())
    const smallScreen = useMediaQuery("(max-width: 767px)")
    const daySize = smallScreen ? 38 : 57

    const [modifiers, setModifiers] = useState<
        Record<string, Record<string, Set<string>>>
    >({})
    const getModifiersForDay = useCallback(
        (date: Date) => {
            const set = new Set<string>()
            if (isDayBlocked?.(date)) {
                set.add("blocked-calendar")
            }
            if (selectedDates.find(isSameDay(date))) {
                set.add("selected")
            }
            return set
        },
        [isDayBlocked, selectedDates]
    )

    useEffect(() => {
        const currentMonthString = format(currentMonth, "yyyy-MM")
        const eachDayOfMonth = eachDayOfInterval({
            start: startOfMonth(currentMonth),
            end: endOfMonth(currentMonth),
        })
        const toISOModiferEntry = (day: Date) => [
            formatISO(day, { representation: "date" }),
            getModifiersForDay(day),
        ]

        const currentMonthModifiers = Object.fromEntries(
            eachDayOfMonth.map(toISOModiferEntry)
        )

        setModifiers(prev => ({
            ...prev,
            [currentMonthString]: currentMonthModifiers,
        }))
    }, [currentMonth, getModifiersForDay])

    return (
        <DayPicker
            onPrevMonthClick={month => {
                setCurrentMonth(month.clone().toDate())
            }}
            onNextMonthClick={month => {
                setCurrentMonth(month.clone().toDate())
            }}
            modifiers={modifiers}
            noBorder={true}
            daySize={daySize}
            numberOfMonths={1}
            hideKeyboardShortcutsPanel
            monthFormat="MMM yyyy"
            navPrev={<NavButtons.Previous />}
            navNext={<NavButtons.Next />}
            onDayClick={day => onDayClicked(day.toDate())}
            renderCalendarDay={props => <CalendarDay {...props} />}
            renderWeekHeaderElement={dayOfWeek => (
                <WeekHeader dayOfWeek={dayOfWeek} daySize={daySize} />
            )}
        />
    )
}

export { useMultiDay, MultiDayPicker }
