import { FC, useEffect, useState } from "react"
import { ChartWrapper } from "lib/containers/Chart"
import ChartHeader from "../partials/ChartHeader"
import ChartFooter from "../partials/ChartFooter"
import { useRootService } from "services"
import { useIntl } from "react-intl"
import { RevenueData } from "../types"
import { NetRentToggleContext } from "../NetRentToggleContext"
import storageService from "services/storage.service"
import { useUserCurrencyCode } from "hooks/user"

type ChartProps = {
    data: RevenueData
    isFetching: boolean
    onShowPreviousYearChange: (filter: boolean) => void
    showPreviousYear: boolean
}

/**
 * Checks the revenue data meets the empty state criteria
 */
const isEmptyState = (currentGrossRent: number, previousGrossRent: number) => {
    return currentGrossRent <= 0 && previousGrossRent <= 0
}

export const Chart: FC<ChartProps> = ({
    data,
    isFetching,
    onShowPreviousYearChange,
    showPreviousYear,
}): JSX.Element => {
    useRootService()
    const currency = useUserCurrencyCode()
    const intl = useIntl()

    const [netRentToggled, setNetRentToggled] = useState(false)
    const [toggleVisible, setToggleVisible] = useState(false)

    const [initialized, setInitialized] = useState(false)
    useEffect(() => {
        // sync with session storage on first load
        if (!initialized) {
            setInitialized(true)
            setNetRentToggled(
                storageService.sessionStorage.getItem("netRentToggle") ===
                    "true"
            )
        }
        storageService.sessionStorage.setItem(
            "netRentToggle",
            netRentToggled.toString()
        )
    }, [netRentToggled, initialized])

    const displayEmptyState = isEmptyState(
        data.current.total.grossRentTotal,
        data.previous.total.grossRentTotal
    )

    const noNetRentData =
        data.current.total.netRentTotal <= 0 &&
        data.previous.total.netRentTotal <= 0

    const yearlyRevenue = `${intl.formatNumber(
        data.current.total.grossRentTotal,
        {
            style: "currency",
            currency,
        }
    )}${currency === "USD" ? ` ${currency}` : ""}`

    const netRentYearlyRevenue = `${intl.formatNumber(
        data.current.total.netRentAfterIntegrated,
        {
            style: "currency",
            currency,
        }
    )}${currency === "USD" ? ` ${currency}` : ""}`

    const upcomingRevenue = intl.formatNumber(
        data.current.upcoming.grossRentTotal,
        {
            style: "currency",
            currency,
        }
    )

    const netRentUpcomingRevenue = intl.formatNumber(
        data.current.upcoming.netRentAfterIntegrated,
        {
            style: "currency",
            currency,
        }
    )

    const completedRevenue = intl.formatNumber(
        data.current.completed.grossRentTotal,
        {
            style: "currency",
            currency,
        }
    )

    const netRentCompletedRevenue = intl.formatNumber(
        data.current.completed.netRentAfterIntegrated,
        {
            style: "currency",
            currency,
        }
    )

    const showPastPerformanceTooltip =
        !!data.current.total.grossRentBeforeIntegrated ||
        (showPreviousYear && !!data.previous.total.grossRentBeforeIntegrated)

    return (
        <NetRentToggleContext.Provider
            value={{
                netRentToggled,
                setNetRentToggled,
                toggleVisible,
                setToggleVisible,
            }}
        >
            <ChartWrapper
                isFetching={isFetching}
                data={data}
                isEmpty={displayEmptyState}
                showPreviousYear={showPreviousYear}
                currency={currency}
                chartHeader={
                    <ChartHeader
                        yearlyRevenue={
                            netRentToggled
                                ? netRentYearlyRevenue
                                : yearlyRevenue
                        }
                        upcomingRevenue={
                            netRentToggled
                                ? netRentUpcomingRevenue
                                : upcomingRevenue
                        }
                        completedRevenue={
                            netRentToggled
                                ? netRentCompletedRevenue
                                : completedRevenue
                        }
                        showRevenueBreakdown={!showPreviousYear}
                        showPastPerformanceTooltip={showPastPerformanceTooltip}
                        noNetRentData={noNetRentData}
                    />
                }
                chartFooter={
                    <ChartFooter
                        onShowPreviousYearChange={onShowPreviousYearChange}
                        showPreviousYear={showPreviousYear}
                        revenue={data}
                    />
                }
            />
        </NetRentToggleContext.Provider>
    )
}
