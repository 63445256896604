import { useContactId } from "hooks/user"
import { useOnboardingStatus } from "hooks/onboarding/use-onboarding-status"
import { useOwnerFeatureFlag } from "hooks/owner-feature-flag"
import { useHasTaxObligation } from "hooks/taxes"
import { useW9TaxSubmission } from "hooks/taxes-w9-submission"
import { observer } from "mobx-react"
import { FC, useEffect } from "react"
import { Navigate } from "react-router-dom"
import { trackSplitExperiment } from "services/segment/experiment/experimentTracking"
import {
    DASHBOARD_LANDING_EXPERIMENT,
    DASHBOARD_LANDING_VARIATION,
} from "services/segment/experiment/experimentTrackingConstants"
import { isFeatureFlagEnabled } from "utils/feature-flag"
import { checkIfOnboardingAppUser } from "utils/onboarding"
import { Card, CardContent } from "../../components/Card"
import Loader from "../../lib/components/Loader"
import LoggingService from "../../services/logging/logging.service"
import { useUserCurrencyCode } from "hooks/user"
import { useUnits } from "hooks/units"
import { isOnboardingUnit } from "utils/unit"
import { useUnitStatuses } from "hooks/units"

const Home: FC = (): JSX.Element => {
    const onboardingStatus = useOnboardingStatus()
    const isOnboardingAppUser = checkIfOnboardingAppUser(onboardingStatus)
    const dashboardFeatureFlag = useOwnerFeatureFlag("dashboard")
    const dashboardEnabled = isFeatureFlagEnabled(dashboardFeatureFlag)
    const w9TaxSubmissionQuery = useW9TaxSubmission()
    const contactId = useContactId()
    const { isLoading, hasTaxObligation } = useHasTaxObligation()
    const currencyCode = useUserCurrencyCode()
    const unitsQuery = useUnits()
    const { isOnboarding } = useUnitStatuses()

    useEffect(() => {
        if (!dashboardEnabled) return
        trackSplitExperiment(
            DASHBOARD_LANDING_EXPERIMENT,
            DASHBOARD_LANDING_VARIATION
        )
    }, [dashboardEnabled])

    if (
        !contactId ||
        onboardingStatus.isLoading ||
        isLoading ||
        !dashboardFeatureFlag.clientReady ||
        w9TaxSubmissionQuery.isLoading ||
        unitsQuery.isLoading
    ) {
        return (
            <div className="container-fluid">
                <Card>
                    <CardContent>
                        <Loader />
                    </CardContent>
                </Card>
            </div>
        )
    }

    if (
        unitsQuery.isSuccess &&
        ((unitsQuery.data.some(isOnboardingUnit) && isOnboarding) ||
            unitsQuery.data.length === 0) &&
        !isOnboardingAppUser
    ) {
        LoggingService.log({
            message: `Home - Found onboarding user, redirecting to Setup`,
        })
        return <Navigate to="/setup" />
    } else if (
        hasTaxObligation &&
        !w9TaxSubmissionQuery.data?.hasSubmittedW9 &&
        currencyCode === "USD"
    ) {
        return <Navigate to="/taxes" />
    }

    LoggingService.log({
        message: dashboardEnabled
            ? `Home - Redirecting to Dashboard`
            : `Home - Redirecting to Calendar`,
    })
    return <Navigate to={dashboardEnabled ? "/performance" : "/calendar"} />
}

export default observer(Home)
