const maintenance = {
    title: "Údržba",
    supportHub: "Support",
    newRequest: "Nová žádost",
    open: "Otevřené",
    closed: "Uzavřené",
    requestNumber: "Žádost číslo:",
    status: "Stav",
    inProgress: "Probíhající",
    rental: "Rekreační pronájem",
    allRentals: "Všechny rekreační pronájmy",
    search: "vyhledat",
    submitted: "Odeslána",
    updated: "Aktualizována",

    selectRental: "Vybrat rekreační pronájem",
    newMaintenanceRequest: "New Maintenance Request",
    needSomeHelp: "Need some help?",
    categorySubtitle:
        "Let us know how we can support you by selecting an option below:",
    requestTitle: "Maintenance request title",
    titlePlaceholder: "Clogged drain",
    describeYourRequest: "Describe your request",
    requestDetails: "Describe your request in detail",
    note: "Note",
    notes: "Notes",
    detailsPlaceholder:
        "We noticed a big drainage issue with the primary kitchen sink. It's a super slow drain or it doesn't even drain at all. Please help!",
    clear: "Clear",
    submitRequest: "Odeslat žádost",
    areYouSure: "Are you sure?",
    leavingAlertText:
        "Leaving the submission form will cause you to lose any data you may have entered.",
    continueWithSubmission: "Continue with Submission",
    cancelRequest: "Cancel Request",
    requestSubmitted: "Request submitted",
    requestSaved: "Žádost uložena",

    submitNewRequest: "Odeslat novou žádost",
    noOpenRequests: "No Open Requests",
    newRequestWill: "New requests will appear here until they are addressed.",
    noClosedRequests: "No Closed Requests",
    closedRequestsWill:
        "Once an open request is addressed, it will be closed out and will appear here.",

    ariaLabel: "Chcete-li odeslat žádost o údržbu, klikněte sem",

    needHelp: "Potřebujete pomoc?",
    contactSentenceIfQuestions:
        "Obraťte se prosím na svou kontaktní osobu: {name}, pokud máte jakékoli dotazy nebo si přejete aktualizovat svou žádost.",
    or: "nebo na",

    failedFetchError: `Nepodařilo se nám načíst vaše žádosti o údržbu. Pokud bude problém přetrvávat, zkuste to prosím znovu později.`,
    failedCreateError: `Nepodařilo se nám uložit vaši žádost o údržbu. Zkontrolujte prosím svůj výběr a zkuste to znovu.`,
    reload: `Znovu načíst`,
    emptyState:
        "Need help with home maintenance? You’ll be able to submit requests here.",

    submittedOn: "Submitted on {date}",
    closedOn: "Closed on {date}",
    cancelled: "Cancelled",
    comments: {
        comments: "Comments",
        empty: "When Vacasa responds to this request, their comments will appear here.",
        overCharacterLengthLimit:
            "The comment should not be over 1000 characters.",
        failedCreateCommentError:
            "We were unable to save your comment. Please try again later.",
    },
    featureAlertTitle: "NEW! Two-way Ticket Comments",
    featureAlertBody:
        "Add a comment to your ticket to provide more information or follow up with a question, which will streamline communication about a specific ticket.",
}

export default maintenance
