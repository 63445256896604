import styles from "./CoreMetrics.module.scss"
import { CoreMetricsWidget } from "./CoreMetricsWidget"
import { useIntl } from "react-intl"
import { useCurrentUnit } from "hooks/units"
import { useUnitMinRate } from "hooks/unit-min-rate"
import { usePerformanceMetrics } from "hooks/performance-metrics"
import { useOwnerPreferences } from "hooks/owner-preferences"
import { CORE_METRICS_FEATURE_FEEDBACK_DISMISSED } from "constants/preferences.constants"
import { FeatureFeedback } from "lib/components/FeatureFeedback/FeatureFeedback"
import { useFeatureInteractionAPI } from "contexts/feature-feedback"
import { useEffect } from "react"
import { trackCoreMetricsWidgetsLoaded } from "services/segment/performance/performanceTracking"
import { isNullOrUndefined } from "utils/common"

export const CoreMetrics = () => {
    const { unitId } = useCurrentUnit()
    const intl = useIntl()

    const unitMinRateQuery = useUnitMinRate(unitId ?? "")
    const performanceMetrics = usePerformanceMetrics()

    const { incrementInteractionCount } = useFeatureInteractionAPI()
    useEffect(
        () => incrementInteractionCount("core-metrics"),
        [incrementInteractionCount]
    )

    const ownerPreferences = useOwnerPreferences()
    const coreMetricsFeedbackDismissedPreference = ownerPreferences.data?.find(
        preference => preference.id === CORE_METRICS_FEATURE_FEEDBACK_DISMISSED
    )

    const currentMinRate = unitMinRateQuery.data?.attributes.minRate
    const averageDailyRate =
        performanceMetrics.data?.attributes.averageDailyRate["12m"]
    const occupancy =
        performanceMetrics.data?.attributes.occupancyPercentage["12m"]

    useEffect(() => {
        if (
            (unitMinRateQuery.data || unitMinRateQuery.error) &&
            (performanceMetrics.data || performanceMetrics.error)
        ) {
            trackCoreMetricsWidgetsLoaded(
                currentMinRate,
                averageDailyRate,
                occupancy
            )
        }
    }, [
        unitMinRateQuery.data,
        unitMinRateQuery.error,
        performanceMetrics.data,
        performanceMetrics.error,
        currentMinRate,
        averageDailyRate,
        occupancy,
    ])

    if (!unitId) return null

    return (
        <div className={styles.container}>
            <CoreMetricsWidget
                title={intl.formatMessage({
                    id: "Dashboard.coreMetrics.minRate",
                    defaultMessage: "Minimum rate",
                })}
                number={`$${currentMinRate}`}
                description={intl.formatMessage({
                    id: "Dashboard.coreMetrics.minRateDescription",
                    defaultMessage:
                        "This is the lowest rate we will offer for your home to attract bookings at the last minute or in periods of decreased demand.",
                })}
                isLoading={unitMinRateQuery.isLoading}
                error={unitMinRateQuery.isError}
                retry={unitMinRateQuery.refetch}
                showEmptyState={!currentMinRate && unitMinRateQuery.isSuccess}
                emptyStateMessage={intl.formatMessage({
                    id: "Dashboard.coreMetrics.minRateEmptyStateDescription",
                    defaultMessage:
                        "Your home’s minimum rate is currently unavailable",
                })}
            />
            <div className={styles.adrWrapper}>
                <FeatureFeedback
                    featureId="core-metrics"
                    ratingBody={intl.formatMessage({
                        id: "Performance.floatingNotification.coreMetricsFeedback",
                        defaultMessage:
                            "You can now see the key metrics that help you understand how your home is priced and performing over the next year, with daily updates giving you the latest insight.\n\nHow do you feel about these performance indicators?",
                    })}
                    commentsBody={intl.formatMessage({
                        id: "Performance.floatingNotification.shareMore",
                        defaultMessage:
                            "Thanks for the feedback! Care to share more?",
                    })}
                    requiredInteractionCount={3}
                    title={intl.formatMessage({
                        id: "Performance.floatingNotification.coreMetricsFeedbackTitle",
                        defaultMessage: "NEW! HOME PERFORMANCE METRICS",
                    })}
                    userPreference={coreMetricsFeedbackDismissedPreference}
                    pointerPosition="top-right"
                    wrapper={children => (
                        <div className={styles.notificationWrap}>
                            {children}
                        </div>
                    )}
                />
                <CoreMetricsWidget
                    title={intl.formatMessage({
                        id: "Dashboard.coreMetrics.avgBookedRate",
                        defaultMessage: "Avg daily rate",
                    })}
                    number={
                        !isNullOrUndefined(averageDailyRate)
                            ? `$${averageDailyRate}`
                            : undefined
                    }
                    description={intl.formatMessage({
                        id: "Dashboard.coreMetrics.avgBookedRateDescription",
                        defaultMessage:
                            "This is the average total rent paid for nights booked over the next 12 months, including nightly rates and guest fees.",
                    })}
                    isLoading={performanceMetrics.isLoading}
                    error={performanceMetrics.isError}
                    retry={performanceMetrics.refetch}
                    showEmptyState={
                        isNullOrUndefined(averageDailyRate) &&
                        performanceMetrics.isSuccess
                    }
                    emptyStateMessage={intl.formatMessage({
                        id: "Dashboard.coreMetrics.genericEmptyStateDescription",
                        defaultMessage:
                            "Local market data is currently unavailable.",
                    })}
                />
            </div>
            <CoreMetricsWidget
                title={intl.formatMessage({
                    id: "Dashboard.coreMetrics.occupancy",
                    defaultMessage: "Occupancy",
                })}
                number={
                    !isNullOrUndefined(occupancy) ? `${occupancy}%` : undefined
                }
                description={intl.formatMessage({
                    id: "Dashboard.coreMetrics.occupancyDescription",
                    defaultMessage:
                        "This is the ratio of nights booked to the number of nights available over the next 12 months, excluding owner holds.",
                })}
                isLoading={performanceMetrics.isLoading}
                error={performanceMetrics.isError}
                retry={performanceMetrics.refetch}
                showEmptyState={
                    isNullOrUndefined(occupancy) && performanceMetrics.isSuccess
                }
                emptyStateMessage={intl.formatMessage({
                    id: "Dashboard.coreMetrics.genericEmptyStateDescription",
                    defaultMessage:
                        "Local market data is currently unavailable.",
                })}
            />
        </div>
    )
}
