import { UnitAvailabilityDays } from "@vacasa/owner-api-models"
import { AxiosError } from "axios"
import { useCallback } from "react"
import { useQuery, useQueryClient, UseQueryResult } from "react-query"
import { isDefined } from "utils/common"
import { fetchAvailability } from "./fetchAvailability"

/**
 * Query key for availability hooks
 */
const AVAILABILITY_QUERY_KEY = "availability" as const

const useAvailability = (
    contactId: string | undefined,
    unitId: string | undefined,
    startDate: string | undefined,
    endDate: string | undefined
): UseQueryResult<UnitAvailabilityDays, AxiosError> =>
    useQuery<UnitAvailabilityDays, AxiosError>(
        [AVAILABILITY_QUERY_KEY, contactId, unitId, startDate, endDate],
        ({ signal }) =>
            fetchAvailability({
                contactId: contactId as string, // Guaranteed to be a string because of then `enabled` check
                unitId: unitId as string, // Guaranteed to be a string because of then `enabled` check
                startDate: startDate as string, // Guaranteed to be a string because of then `enabled` check
                endDate: endDate as string, // Guaranteed to be a string because of then `enabled` check
                signal,
            }),
        {
            enabled: [unitId, contactId, startDate, endDate].every(isDefined),
            refetchInterval: 1000 * 60 * 60, // 1 hour
            staleTime: 1000 * 60 * 5, // 5 minutes
        }
    )

/**
 * Invalidate all availability Queries
 */
const useInvalidateAvailability = (): (() => Promise<void>) => {
    const queryClient = useQueryClient()
    return useCallback(
        () => queryClient.invalidateQueries(AVAILABILITY_QUERY_KEY),
        [queryClient]
    )
}
/**
 *
 * TODO Fix type safety https://vacasait.atlassian.net/browse/TFP-1430
 */
const usePrefetchAvailability = (
    contactId: string | undefined,
    unitId: string | undefined,
    startDate: string | undefined,
    endDate: string | undefined
): Promise<void> =>
    useQueryClient().prefetchQuery(
        [AVAILABILITY_QUERY_KEY, contactId, unitId, startDate, endDate],
        () => {
            if ([unitId, contactId, startDate, endDate].every(isDefined)) {
                return fetchAvailability({
                    contactId: contactId as string,
                    unitId: unitId as string,
                    startDate: startDate as string,
                    endDate: endDate as string,
                })
            }
        },
        {
            staleTime: 1000 * 60 * 5, // 5 minutes
        }
    )

export { useAvailability, useInvalidateAvailability, usePrefetchAvailability }
