/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMediaQuery } from "@material-ui/core"
import { toJS } from "mobx"
import { observer } from "mobx-react"
import React, { Component, Fragment, ReactNode } from "react"
import { Link, Navigate } from "react-router-dom"

// Services
import earningsService, {
    Earnings,
} from "../../services/earnings/earnings.service"
import { useRootService } from "services"
import loggingService from "../../services/logging/logging.service"

// Components
import MonthPickerWrapper from "./MonthPickerWrapper"
import PaymentSummary from "./PaymentSummary"
import UnitSummary from "./UnitSummary"

import { ReactComponent as ArrowRight } from "../../assets/icon-arrow-right.svg"
import { ReactComponent as ChevronUp } from "../../assets/icon-chevron-up.svg"
import { ReactComponent as NeedHelpSVG } from "../../assets/icon-help.svg"
import { LinkSecondary } from "../../lib/components/Buttons/BaseButton"
import EmptyState from "../../lib/components/EmptyState"
import DefaultError from "../../lib/components/ErrorBoundaries/DefaultError"
import Loader from "../../lib/components/Loader"
import PrintHeader from "../../lib/components/Print/PrintHeader"
import SVGIcon from "../../lib/components/SVGIcon"

import { NEW_PAYMENT_SETTINGS_VIEWED } from "../../constants/preferences.constants"
import { StatementsFAQ } from "./StatementsFAQ/StatementsFAQ"

import { default as classnames, default as classNames } from "classnames"
import withRouter, { WithRouterProps } from "core/hoc/withRouter"
import {
    endOfMonth,
    formatISO,
    isValid as isValidDate,
    parse as parseDate,
} from "date-fns"
import { Responsive } from "lib/components/Responsive/Responsive"
import {
    trackBackToTopClicked,
    trackButtonClicked,
    trackNeedHelpClicked,
    trackOwnerEarningsReviewed,
    trackPaymentSettingClicked,
    trackStatementPageViewed,
    trackTooltipClicked,
} from "services/segment/statement/statementTracking"
import { Card, CardContent } from "../../components/Card"
import { Spinner } from "../../components/Spinner"
import LoggingService from "../../services/logging/logging.service"
import { PDFDownloadLink } from "./PDFDownloadLink/PDFDownloadLink"
import { StatementRefresh } from "./StatementRefresh/StatementRefresh"
import styles from "./statements.module.scss"
import { FormattedMessage, IntlShape, useIntl } from "react-intl"
import { withHookHocTyped } from "core/hoc/with-hook-hoc"
import {
    useContactId,
    useIsEmployee,
    useUser,
    useUserCurrencyCode,
} from "hooks/user"
import { Owner } from "@vacasa/owner-api-models"
import { FeatureAlert } from "lib/components/FeatureAlert/FeatureAlert"
import { isOwnerApp } from "utils/browser/browserUtil"
import { useCurrentUnit } from "hooks/units"

type StatementProps = WithRouterProps & HookProps

type HookProps = {
    contactId: string | null
    currencyCode: string
    intl: IntlShape
    isEmployee: boolean
    rootService: ReturnType<typeof useRootService>
    user: Owner | null
    unitId: string | null
}

interface StatementState {
    fetchedSummary: boolean
    dropdownOpen: boolean
    fetchingUnitSummary: boolean
    backToTopButtonHidden: boolean
    showNotification: boolean
    openFAQ?: "WhatIsMyReserveBalanceFaq"
    statementsLoaded: boolean
    statementsError: boolean
    viewOptions: {
        [key: string]: {
            [key: string]: boolean
        }
    }
}

interface WrapperProps {
    children: ReactNode
    fetchedStatementData: boolean
    renderNotification: boolean
}

const DEFAULT_STATEMENT_YEAR = new Date().getFullYear().toString()

@observer
class Statements extends Component<StatementProps, StatementState> {
    private FAQRef: any
    constructor(props: StatementProps) {
        super(props)
        this.state = {
            fetchedSummary: false,
            dropdownOpen: false,
            fetchingUnitSummary: false,
            backToTopButtonHidden: true,
            showNotification: false,
            statementsLoaded: false,
            statementsError: false,
            viewOptions: {},
        }
        this.loadStatements = this.loadStatements.bind(this)
        this.getDateRange = this.getDateRange.bind(this)
        this.trackClickEvent = this.trackClickEvent.bind(this)
        this.trackStatementViewEvent = this.trackStatementViewEvent.bind(this)
        this.handleScroll = this.handleScroll.bind(this)
        this.getStatementRouteDate = this.getStatementRouteDate.bind(this)
        this.hasRouteMonth = this.hasRouteMonth.bind(this)
        this.getStatementRouteDateObj = this.getStatementRouteDateObj.bind(this)

        this.FAQRef = React.createRef()
    }

    componentDidMount() {
        this.loadStatements()
        setTimeout(() => {
            trackStatementPageViewed()
        }, 3000)
        window.addEventListener("scroll", this.handleScroll)
    }

    componentWillUnmount(): void {
        window.removeEventListener("scroll", this.handleScroll)
    }
    handleScroll(): void {
        this.setState({ backToTopButtonHidden: window.pageYOffset < 200 })
    }

    trackStatementViewEvent(): void {
        const { contactId, isEmployee } = this.props
        trackOwnerEarningsReviewed(isEmployee ? contactId ?? "" : "")
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    trackClickEvent(props: { type: string; label: string }): void {
        trackButtonClicked(props)
    }
    loadStatements(): void {
        const range = this.getDateRange()
        const unitID = this.props.unitId
        const user = this.props.user
        if (this.props.router.params.month === undefined) {
            const newDate = new Date(
                Number(this.props.router.params.year),
                1,
                2
            )
            this.setState({ statementsLoaded: false, statementsError: false })
            earningsService
                .fetchStatementByUnitID(
                    user?.contactId,
                    unitID,
                    null,
                    null,
                    newDate,
                    false
                )
                .then(() => {
                    earningsService.setLoadingSummary(false)
                    earningsService.initialSummary = true
                    earningsService.isInitFetch = false
                    this.setState({
                        statementsLoaded: true,
                        statementsError: false,
                    })
                    return
                })
                .catch(reason => {
                    this.setState({
                        statementsLoaded: false,
                        statementsError: true,
                    })
                    LoggingService.error({
                        message: "Failed to load fetchStatementByUnitId",
                        error: reason,
                    })
                })
        } else {
            this.setState({ statementsLoaded: false, statementsError: false })
            earningsService
                .fetchStatementByUnitID(
                    user?.contactId,
                    unitID,
                    range.firstDay,
                    range.lastDay,
                    null,
                    false
                )
                .then(() => {
                    earningsService.setLoadingSummary(false)
                    earningsService.initialSummary = true
                    earningsService.isInitFetch = false
                    this.setState({
                        statementsLoaded: true,
                        statementsError: false,
                    })
                    return
                })
                .catch(reason => {
                    this.setState({
                        statementsLoaded: false,
                        statementsError: true,
                    })
                    LoggingService.error({
                        message: "Failed to load fetchStatementByUnitId",
                        error: reason,
                    })
                })
        }
    }
    getDateRange(): {
        firstDay: string | null
        lastDay: string | null
        year: Date | null
    } {
        let { month, year } = this.getStatementRouteDateObj()
        if (year && !month) {
            return {
                firstDay: null,
                lastDay: null,
                year: new Date(Number(year), 0, 1),
            }
        }
        if (year && month) {
            const date = new Date(Number(year), Number(month) - 1, 1)
            const firstDay = formatISO(date, { representation: "date" })
            const lastDay = formatISO(endOfMonth(date), {
                representation: "date",
            })
            return { firstDay: firstDay, lastDay: lastDay, year: null }
        }
        if (
            (!year && !month) ||
            !earningsService.hasStatementDate(year, month)
        ) {
            //@ts-expect-error statementDates possibly 'null'
            // TODO @see @{link https://vacasait.atlassian.net/browse/TFP-1180}
            year = earningsService.statementDates[0].Year
            month =
                //@ts-expect-error statementDates possibly 'null'
                // TODO @see @{link https://vacasait.atlassian.net/browse/TFP-1180}
                earningsService.statementDates[0].Month.toString().length === 1
                    ? //@ts-expect-error statementDates possibly 'null'
                      // TODO @see @{link https://vacasait.atlassian.net/browse/TFP-1180}
                      "0" + earningsService.statementDates[0].Month.toString()
                    : //@ts-expect-error statementDates possibly 'null'
                      // TODO @see @{link https://vacasait.atlassian.net/browse/TFP-1180}
                      earningsService.statementDates[0].Month.toString()
        }
        const date = new Date(Number(year), Number(month) - 1, 1)
        const firstDay = formatISO(date, { representation: "date" })
        const lastDay = formatISO(endOfMonth(date), { representation: "date" })
        return { firstDay: firstDay, lastDay: lastDay, year: null }
    }

    // Requires to be -1 as it becomes an array
    hasRouteMonth(): boolean {
        const { month } = this.props.router.params
        return month !== undefined
    }

    // Requires to be -1 as it becomes an array
    getStatementRouteDate(): Date {
        const { month, year = DEFAULT_STATEMENT_YEAR } =
            this.props.router.params

        if (month === undefined) {
            return parseDate(year, "yyyy", new Date())
        }
        return parseDate(`${year}${month}`, "yyyyM", new Date())
    }
    // Date cannot return null or undefined months, so this is required for route param matching
    getStatementRouteDateObj() {
        const year = this.props.router.params.year ?? DEFAULT_STATEMENT_YEAR

        if (this.props.router.params.month === undefined) {
            return {
                year: year,
                month: this.props.router.params.month,
            }
        }
        return {
            year: year,
            month:
                this.props.router.params.month?.length === 1
                    ? "0" + this.props.router.params.month
                    : this.props.router.params.month,
        }
    }

    toggle(): void {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen,
        })
    }

    render(): JSX.Element | null {
        const { intl, user } = this.props
        if (user === null) {
            return <Navigate to="/login" />
        }
        if (this.state.statementsError)
            return (
                <p>
                    Something went wrong. Please refresh the page, or try again
                    later.
                </p>
            )
        if (!this.state.statementsLoaded)
            return (
                <div className={styles["loading-spinner"]}>
                    <Spinner />
                </div>
            )
        const months = earningsService.statementDates
            ? earningsService.statementDates
            : null
        const fetchedStatementData =
            !earningsService.loadingSummary &&
            earningsService.statementDates &&
            earningsService.statementDates.length > 0

        const statementRouteDate = this.getStatementRouteDate()

        if (!isValidDate(statementRouteDate)) {
            return <Navigate to="/statements" />
        }

        let pdfButton = null
        if (earningsService.statement) {
            pdfButton = <PDFDownloadLink />
        }
        const faqButton = (
            <LinkSecondary
                className={styles["need-help-button"]}
                ariaLabel={intl.formatMessage({
                    id: "Statements.faqAriaLabel",
                    defaultMessage: "View FAQ",
                })}
                typeOf={"link"}
                onClick={() => {
                    trackNeedHelpClicked()
                    this.FAQRef.current.scrollIntoView()
                }}
            >
                <NeedHelpSVG className={styles["svg-icon"]} />
                <FormattedMessage
                    id="Statements.faqAbbreviation"
                    defaultMessage="FAQ"
                />
            </LinkSecondary>
        )
        const printButton = (
            <LinkSecondary
                className={styles["need-help-button"]}
                ariaLabel={intl.formatMessage({
                    id: "Statements.printAriaLabel",
                    defaultMessage: "Print View",
                })}
                id={"print-statement-button"}
                typeOf={"link"}
                onClick={e => {
                    loggingService.log({ message: `Print view` })
                    this.trackClickEvent({ type: "link", label: "print" })
                    // This doesn't work in debug builds https://github.com/facebook/react/issues/16734
                    window.print()
                }}
            >
                <SVGIcon
                    svg="icon-feather-printer"
                    colorName="lake"
                    className={styles["svg-icon"]}
                />
                <FormattedMessage
                    id="Statements.print"
                    defaultMessage="Print"
                />
            </LinkSecondary>
        )
        const emptyStateCTA = {
            href: `https://www.vacasa.com/owner-library/finances`,
            snippet: intl.formatMessage({
                id: "Statements.emptyStateCTA",
                defaultMessage: "Learn more about your earnings",
            }),
        }
        const currency = this.props.currencyCode
        if (
            earningsService.statementDates &&
            earningsService.statementDates.length > 0 &&
            this.props.router.params.year === undefined &&
            this.props.router.params.month === undefined
        ) {
            const latestStatementDate = earningsService.statementDates[0]
            return (
                <Navigate
                    to={`/statements/${latestStatementDate?.Year}/${latestStatementDate?.Month}`}
                />
            )
        }

        const routeHasStatement =
            (earningsService.statementDates?.some(
                statementDate =>
                    statementDate.Year ===
                        Number(this.props.router.params.year) &&
                    (statementDate.Month ===
                        Number(this.props.router.params.month) ||
                        this.props.router.params.month === undefined)
            ) ||
                earningsService.statementDates?.some(
                    statementDate =>
                        statementDate.Year ===
                            Number(this.props.router.params.year) &&
                        this.props.router.params.month === undefined
                )) ??
            false

        if (
            earningsService.statementDates &&
            earningsService?.statementDates.length > 0 &&
            !routeHasStatement
        ) {
            return <Navigate to="/statements" />
        }

        const updateUnitViewOption = (
            unitId: string,
            key: string,
            value: boolean
        ) => {
            this.setState(prevState => ({
                viewOptions: {
                    ...prevState.viewOptions,
                    [unitId]: {
                        ...prevState.viewOptions[unitId],
                        [key]: value,
                    },
                },
            }))
        }

        return (
            <Wrapper
                // @ts-expect-error fetchedStatementData possibly 'null'
                // TODO @see @{link https://vacasait.atlassian.net/browse/TFP-1179}
                fetchedStatementData={fetchedStatementData}
                renderNotification={this.state.showNotification}
            >
                {fetchedStatementData ? (
                    <Fragment>
                        <div className="print-only">
                            <PrintHeader
                                header={"Statements"}
                                renderAcctEntity={true}
                                renderDateRange={true}
                            />
                        </div>
                        <div className="earnings-options">
                            {months ? (
                                <MonthPickerWrapper
                                    statementDate={statementRouteDate}
                                    rootService={this.props.rootService}
                                    showSummary={!this.hasRouteMonth()}
                                />
                            ) : null}
                            <div className="print-buttons">
                                {!isOwnerApp() && (
                                    <div
                                        className="no-print print-button"
                                        id="print-statement-button-container"
                                    >
                                        {printButton}
                                    </div>
                                )}
                                <div
                                    className="no-print print-button"
                                    id="generate-statement-pdf-button-container"
                                >
                                    {pdfButton}
                                </div>

                                <div
                                    className="no-print print-button"
                                    id="faq-button-container"
                                >
                                    {faqButton}
                                </div>
                            </div>
                        </div>
                        {!(
                            earningsService.statement &&
                            (earningsService.statement as Earnings)
                                .displaySingleUnit
                        ) ? (
                            <div className={styles.row}>
                                <Card>
                                    <CardContent>
                                        <PaymentSummary
                                            summary={toJS(
                                                earningsService.statement
                                            )}
                                            rootService={this.props.rootService}
                                            currency={currency}
                                        />
                                    </CardContent>
                                </Card>
                            </div>
                        ) : null}
                        <div className={styles.row}>
                            <Fragment>
                                {earningsService.loadingSummary ? (
                                    <Fragment>
                                        <Loader />
                                    </Fragment>
                                ) : (
                                    <UnitSummary
                                        scrollToFAQ={() => {
                                            trackTooltipClicked()
                                            this.setState({
                                                openFAQ:
                                                    "WhatIsMyReserveBalanceFaq",
                                            })
                                        }}
                                        faqOpenCallback={() =>
                                            this.setState({
                                                openFAQ: undefined,
                                            })
                                        }
                                        currency={currency}
                                        viewOptions={this.state.viewOptions}
                                        updateViewOptions={updateUnitViewOption}
                                    />
                                )}
                            </Fragment>
                        </div>
                    </Fragment>
                ) : null}
                {!earningsService.loadingSummary &&
                (!earningsService.statementDates ||
                    earningsService.statementDates.length < 1) ? (
                    <EmptyState
                        snippet={intl.formatMessage({
                            id: "Statements.emptyStateEarnings",
                            defaultMessage:
                                "New statements will be available by the 10th of each month.",
                        })}
                        CTA={emptyStateCTA}
                    >
                        <SVGIcon
                            svg="icon-feather-file-text"
                            colorName="info-70"
                            className="w-56 h-56 empty-state__svg"
                        />
                    </EmptyState>
                ) : null}
                {earningsService.loadingSummary ? (
                    <Card>
                        <Loader />
                    </Card>
                ) : null}
                <StatementsFAQ ref={this.FAQRef} openFAQ={this.state.openFAQ} />
                <button
                    className={classnames(
                        styles["back-to-top-button"],
                        "regular"
                    )}
                    hidden={this.state.backToTopButtonHidden}
                    onClick={() => {
                        trackBackToTopClicked()
                        window.scrollTo({ top: 0, behavior: "smooth" })
                    }}
                >
                    <FormattedMessage
                        id="Statements.backToTop"
                        defaultMessage="Back to Top"
                    />
                    <ChevronUp className={styles["chevron-icon"]} />
                </button>
                <DefaultError />
            </Wrapper>
        )
    }
}

const Wrapper = ({
    children,
    fetchedStatementData,
    renderNotification,
}: WrapperProps): JSX.Element => {
    const smallScreen = useMediaQuery("(max-width: 520px)")
    const intl = useIntl()
    return (
        <Fragment>
            <div
                className={classNames(
                    "container-fluid",
                    "print-container",
                    styles["statements-container"]
                )}
            >
                <div className={styles["page-title-container"]}>
                    <h1 className={styles["page-title"]}>
                        <FormattedMessage
                            id="Statements.title"
                            defaultMessage="Statements"
                        />
                        <StatementRefresh />
                    </h1>
                    {fetchedStatementData ? (
                        <Link
                            className={styles["settings-button"]}
                            to={`/statements/settings`}
                            onClick={() => {
                                trackPaymentSettingClicked()
                            }}
                            data-testid="payment-settings-button"
                        >
                            {smallScreen ? (
                                <FormattedMessage
                                    id="Statements.paymentSettingsSmall"
                                    defaultMessage="Settings"
                                />
                            ) : (
                                <FormattedMessage
                                    id="Statements.paymentSettings"
                                    defaultMessage="Payment Settings"
                                />
                            )}
                            <ArrowRight className={styles["arrow-icon"]} />
                        </Link>
                    ) : null}
                    {renderNotification && fetchedStatementData && (
                        <div className={styles["notification-wrap"]}>
                            <Responsive>
                                {windowWidth => (
                                    <FeatureAlert
                                        feature="payment-settings"
                                        title={intl.formatMessage({
                                            id: "Statements.paymentSettingsNotificationTitle",
                                            defaultMessage: "Payment Settings",
                                        })}
                                        body={intl.formatMessage({
                                            id: "Statements.paymentSettingsNotification",
                                            defaultMessage:
                                                "Manage your bank details and payment settings here.",
                                        })}
                                        linkString={intl.formatMessage({
                                            id: "Statements.checkItOut",
                                            defaultMessage: "Check it Out",
                                        })}
                                        redirectLink="/statements/settings"
                                        userPreference={
                                            NEW_PAYMENT_SETTINGS_VIEWED
                                        }
                                        pointerPosition={
                                            windowWidth < 768
                                                ? "top-left"
                                                : "right-top"
                                        }
                                    />
                                )}
                            </Responsive>
                        </div>
                    )}
                </div>
                {children}
            </div>
        </Fragment>
    )
}
export default withHookHocTyped<HookProps>(
    withRouter<StatementProps>(Statements),
    () => ({
        contactId: useContactId(),
        currencyCode: useUserCurrencyCode(),
        intl: useIntl(),
        isEmployee: useIsEmployee(),
        rootService: useRootService(),
        unitId: useCurrentUnit().unitId,
        user: useUser().user,
    })
)
