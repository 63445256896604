import { Reservation } from "@vacasa/owner-api-models"
import JSONAPIResource from "@vacasa/owner-api-models/src/JSONAPIResource"
import { default as classNames, default as classnames } from "classnames"
import { BookingProbabilityLegend } from "core/components/dates/CalendarController/BookingProbabilityLegend/BookingProbabilityLegend"
import { startOfDay, subDays } from "date-fns"
import { useOwnerFeatureFlag } from "hooks/owner-feature-flag"
import { useReservation } from "hooks/reservation"
import { useKnownSearchParams } from "hooks/router"
import { uniqBy } from "lodash"
import {
    MouseEventHandler,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react"
import { FormattedMessage } from "react-intl"
import { trackReservationTabsClicked } from "services/segment/reservation/reservationTracking"
import { reservationHasEnded } from "utils/reservations/reservationsUtil"
import { getClassStyle } from "utils/styles/styleWrapper"
import { Card, CardContent } from "../../../components/Card"
import { useReservations } from "../../../hooks/reservations"
import { InfiniteReservationsList } from "./InfiniteReservationsList"
import styles from "./Reservations.module.scss"
import { isFeatureFlagEnabled } from "utils/feature-flag"

type ReservationsProps = {
    contactId: string
    unitId: string
    unitRelationshipId: string
    updatingCalendar: boolean
    clickHandler: (reservation: JSONAPIResource<Reservation>) => void
}

export enum BookingType {
    Upcoming = "upcoming",
    Previous = "previous",
}

export type StayType = BookingType.Upcoming | BookingType.Previous

const Reservations = ({
    contactId,
    unitId,
    unitRelationshipId,
    clickHandler,
    updatingCalendar,
}: ReservationsProps): JSX.Element => {
    const [activeTab, setActiveTab] = useState<StayType>(BookingType.Upcoming)

    const [today] = useState(startOfDay(new Date()))
    const knownParams = useKnownSearchParams()

    const queryReservation = useReservation(knownParams.resId)

    useEffect(() => {
        if (
            !queryReservation.data ||
            queryReservation.isLoading ||
            queryReservation.data.data.attributes.cancelled
        )
            return
        const isPreviousRes = reservationHasEnded(
            queryReservation.data.data.attributes
        )
        if (isPreviousRes && activeTab === BookingType.Upcoming) {
            setActiveTab(BookingType.Previous)
        }
    }, [activeTab, queryReservation.data, queryReservation.isLoading])

    /**
     * Upcoming tab gets reservations from today onwards in ascending order
     */
    const upcomingQueryParams = useMemo(
        () => ({
            contactId,
            unitId,
            pageLimit: 10,
            startDate: today,
            sort: "asc" as const,
            filterCancelledReservations: 0,
            unitRelationshipId,
        }),
        [contactId, today, unitId, unitRelationshipId]
    )
    const upcomingQuery = useReservations(upcomingQueryParams)

    /**
     * Previous Tab gets reservations from 45 days ago until yesterday in descending order
     */
    const previousQueryParams = useMemo(
        () => ({
            contactId,
            unitId,
            pageLimit: 10,
            endDate: subDays(today, 1),
            sort: "desc" as const,
            inclusive: false,
            filterCancelledReservations: 0,
            unitRelationshipId,
        }),
        [contactId, today, unitId, unitRelationshipId]
    )
    const previousQuery = useReservations(previousQueryParams)

    const handleTabClicked = useCallback(
        (newStayType: StayType): MouseEventHandler<HTMLElement> =>
            event => {
                setActiveTab(newStayType)
                trackReservationTabsClicked(newStayType)
                event.currentTarget.blur()
            },
        [setActiveTab]
    )

    /**
     * Currently checks if a unit is as super unit by seeing if there are multiple unitIds in reservations
     * This is a problem as we are only getting a subset of reservations
     * TODO move superunit onto owner-api, this check is highly inaccurate and should be computed based on unit information
     * @see {@link https://vacasait.atlassian.net/browse/TFP-1157}
     */
    const isSuperUnit =
        previousQuery.isSuccess &&
        upcomingQuery.isSuccess &&
        uniqBy(
            [
                ...(previousQuery.data?.pages ?? []),
                ...(upcomingQuery.data?.pages ?? []),
            ].flatMap(page => page?.data ?? []),
            res => res.attributes.unitID
        ).length > 1

    // Booking Probability Legend feature flag
    const upcomingListBookingProbabilityFeatureFlag = useOwnerFeatureFlag(
        "owner-portal-upcoming-list-booking-probability"
    )

    const showUpcomingListBookingProbabilityEnabled = isFeatureFlagEnabled(
        upcomingListBookingProbabilityFeatureFlag
    )

    let highlightedReservationId = queryReservation.data?.data.id

    if (queryReservation.data?.data.attributes.cancelled) {
        highlightedReservationId = undefined
    }

    return (
        <Card noBorderOnMobile>
            <CardContent noPaddingOnMobile>
                <h3 className="type-heading-medium-large">
                    <FormattedMessage
                        id="CalendarPage.UpcomingStays.title"
                        defaultMessage="Reservations"
                        description="Load more reservationss"
                    />
                </h3>
                <p className={styles.description}>
                    {activeTab === BookingType.Upcoming && (
                        <FormattedMessage
                            id="CalendarPage.UpcomingStays.upcomingDescription"
                            defaultMessage="Upcoming guest reservations and owner holds will be listed below. Click a row to view more details. If you wish to modify an owner hold you can do so by clicking on the corresponding row."
                            description="Describes what reservations are displayed when the upcoming tab is active"
                        />
                    )}
                    {activeTab === BookingType.Previous && (
                        <FormattedMessage
                            id="CalendarPage.UpcomingStays.pastDescription"
                            defaultMessage="Your past reservations and holds in the last 45 days are listed out below.  If you would like to view more details around each stay, click on the detail row."
                            description="Describes what reservations are displayed when the upcoming tab is active"
                        />
                    )}
                </p>
                {showUpcomingListBookingProbabilityEnabled &&
                    activeTab === BookingType.Upcoming && (
                        <BookingProbabilityLegend
                            legendStyleOverride={
                                styles.bookingProbabilityLegendOverride
                            }
                            zapStyleOverride={styles.zapOverride}
                        />
                    )}
                {upcomingQuery.isSuccess && previousQuery.isSuccess && (
                    <div className={styles.tabs}>
                        <div className={styles.tabContainer}>
                            <button
                                className={classnames(
                                    styles.tabBarButton,
                                    "type-heading-extra-small",
                                    "caps",
                                    {
                                        [getClassStyle(styles.selected)]:
                                            activeTab === BookingType.Upcoming,
                                        "tabBarButton--selected":
                                            activeTab === BookingType.Upcoming,
                                    }
                                )}
                                onClick={handleTabClicked(BookingType.Upcoming)}
                            >
                                <FormattedMessage
                                    id="CalendarPage.UpcomingStays.upcomingTabTitle"
                                    defaultMessage="Upcoming ({total})"
                                    description="Show upcoming reservations with a count of total reservation in a list button"
                                    values={{
                                        total:
                                            upcomingQuery.data?.pages?.[0]?.meta
                                                .total ?? "-",
                                    }}
                                />
                            </button>
                        </div>

                        <div
                            className={classNames(
                                styles.tabNotificationContainer,
                                styles.tabContainer
                            )}
                        >
                            <button
                                className={classnames(
                                    styles.tabBarButton,
                                    "type-heading-extra-small",
                                    "caps",
                                    {
                                        [getClassStyle(styles.selected)]:
                                            activeTab === BookingType.Previous,
                                        "tabBarButton--selected":
                                            activeTab === BookingType.Previous,
                                    }
                                )}
                                onClick={handleTabClicked(BookingType.Previous)}
                                data-testid="previous-button"
                            >
                                <FormattedMessage
                                    id="CalendarPage.UpcomingStays.previousTabTitle"
                                    defaultMessage="previous"
                                    description="show previous reservation in a list button"
                                />
                            </button>
                        </div>
                    </div>
                )}
                {activeTab === BookingType.Upcoming && (
                    <InfiniteReservationsList
                        stayType={BookingType.Upcoming}
                        query={upcomingQuery}
                        onReservationClicked={clickHandler}
                        updatingCalendar={updatingCalendar}
                        isSuperUnit={isSuperUnit}
                        bookingProbabilityEnabled={
                            showUpcomingListBookingProbabilityEnabled
                        }
                        selectedResId={highlightedReservationId}
                    />
                )}
                {activeTab === BookingType.Previous && (
                    <InfiniteReservationsList
                        stayType={BookingType.Previous}
                        query={previousQuery}
                        onReservationClicked={clickHandler}
                        updatingCalendar={updatingCalendar}
                        isSuperUnit={isSuperUnit}
                        bookingProbabilityEnabled={
                            showUpcomingListBookingProbabilityEnabled
                        }
                        selectedResId={highlightedReservationId}
                    />
                )}
            </CardContent>
        </Card>
    )
}

export { Reservations }
