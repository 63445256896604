import type { AxiosError } from "axios"
import {
    UseMutationResult,
    UseQueryResult,
    useMutation,
    useQuery,
    useQueryClient,
} from "react-query"
import { fetchTickets } from "./fetchTickets"
import { PostTicketResponse, TicketResponse } from "@vacasa/owner-api-models"
import { useContactId } from "hooks/user"
import { TicketRequest, postTicket } from "./postTicket"

interface TicketMutationParam {
    unitId: string
    ticketRequest: TicketRequest
}

export const TICKETS_QUERY_KEY = "ticket"

const useTickets = (
    unitId: string,
    dispositionIds?: string[]
): UseQueryResult<{ tickets:TicketResponse[],meta: {unreadCompletedTickets:number} }, AxiosError> => {
    const contactId = useContactId()

    return useQuery(
        [TICKETS_QUERY_KEY, unitId],
        ({ signal }) =>
            fetchTickets({
                contactId: String(contactId),
                unitId,
                dispositionIds,
                signal,
            }),
        {
            enabled: !!unitId && !!contactId,
            refetchInterval: 1000 * 60 * 30, // 30 mins
            staleTime: 1000 * 60 * 30, // 30 mins,
            select: (data) => {
                return {
                    tickets: data.data,
                    meta: data.meta
                };
            }

        }
    )
}

const useTicketsMutation = (): UseMutationResult<
    PostTicketResponse,
    AxiosError,
    TicketMutationParam,
    void
> => {
    const contactId = useContactId()
    const queryClient = useQueryClient()
    return useMutation<
        PostTicketResponse,
        AxiosError,
        TicketMutationParam,
        void
    >(
        (
            ticketMutationParam: TicketMutationParam
        ): Promise<PostTicketResponse> =>
            postTicket({
                contactId: contactId ?? "",
                unitId: ticketMutationParam.unitId,
                postTicketData: ticketMutationParam.ticketRequest,
            }),
        {
            onSuccess: async data => {
                await queryClient.invalidateQueries([
                    TICKETS_QUERY_KEY,
                    data.attributes.unitId,
                ])
            },
        }
    )
}

export { useTickets, useTicketsMutation }
