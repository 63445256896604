const taxes = {
    title: "Taxes",

    fillOutW9: "Please fill out Form W-9",
    submitW9: "Submit W-9 Online",
    newTaxFormsWillShow:
        "New tax forms will show up here as they become available.",
    goPaperless: "Go paperless",
    paperlessExplanation:
        "Paperless enables you to receive your tax preparation forms via electronic delivery only. You will not be mailed paper copies of your tax forms.",
    electronicForms:
        "Receive tax preparation forms via electronic delivery only",
    getForms: "Get your tax preparation forms",
    irsFormsElectronically: "Receive IRS tax forms electronically",
    mustSubmit:
        "We must have one of the following taxpayer forms on file before we can provide your tax preparation documents. Please complete only the form that best describes your taxpayer status with the IRS. If your taxpayer information has changed, please submit a new form.",

    paperlessNotification: "Tax forms will be sent electronically",
    paperFormNotification: "Tax forms will be mailed",

    noForms: "You have no forms yet.",

    ifExpecting: "If you're expecting a",
    willBeAvailable: "it will be available by",
    form1099title: "Form 1099-MISC",
    form1042title: "Form 1042-S",
    jan31: "January 31",
    march15: "March 15",

    yourInformation: "Provide your taxpayer information",
    whoFor: "Who it's for",
    howToSubmit: "How to submit it",
    w9title: "Form W-9",
    w9whoFor:
        "Provide if you are a U.S. citizen or resident alien who has one of the following types of taxpayer identification numbers: SSN, EIN, or ITIN. Nonresident aliens should not complete this form.",
    w8ECItitle: "Form W-8ECI",
    w8ECIwhoFor:
        "Provide if you are a Foreign individual, nonresident alien, or entity who currently possesses a SSN, EIN, or ITIN and will be reporting this income by filing an annual tax return with the IRS.",
    w8BENtitle: "Form W-8BEN",
    w8BENwhoFor:
        "Provide if you are a Foreign individual or nonresident alien who does not file an annual tax return with the IRS or would like to claim income tax treaty benefits between the U.S. and your country of residence. Since treaty benefits do not apply to rental income, individuals providing Form W‐8BEN are subject to foreign withholding, deducted from gross income, at a rate of 30%.",
    w8BENEtitle: "Form W-8BEN-E",
    w8BENEwhoFor:
        "Provide if you are a Foreign recipient of income, who is not an individual, and does not file an annual tax return with the IRS. Income tax treaty benefits can also be claimed on Part III of this form. Since treaty benefits do not apply to rental income, recipients providing Form W-8BEN-E are subject to foreign withholding, deducted from gross income, at a rate of 30%.",

    learnMore: "Learn more at",
    mailForm: "Fill out the form and mail it to:",

    dwnld: "Download",
    download: "Download your rental data",
    preparingNotification: "Preparing your data for download",
    rentalData: "Rental Data",
    noDownloads: "You have no downloads yet.",
    description: "Data for each tax year will be available by",
    january15: "January 15",
    description2:
        "of the following year. If you share ownership with one or more individuals, you'll each have separate data to download so you have the correct information to report.",
    instructions:
        ".xls files can be opened with most spreadsheet applications, including Excel, Numbers, and Google Sheets.",
    makeChanges: "Want to make changes?",
    w9Support:
        "To make edits to your W-9 details please reach out to the {{HOME_OWNER_SUPPORT}} at {{SUPPORT_NUMBER}}, {{SUPPORT_EMAIL}}",
    taxModal: {
        actionRequired: "Action Required!",
        title: "Enter Taxpayer Info",
        description:
            "We need your taxpayer information on file so we can provide you with accurate tax preparation documents for last year.",
        primaryButton: "Fill it Out",
        secondardButton: "Maybe Later",
    },
    paperless1099Modal: {
        title: "Paperless 1099",
        description:
            "As the new year approaches, be prepared for tax season by receiving your Vacasa 1099 digitally. We'll email you when it's ready.",
        goPaperless: "Go Paperless",
        skipForNow: "Skip for Now",
    },
    w9: {
        taxFormSelect: {
            info1: "Please provide your taxpayer information so we can provide your tax preparation documents. Provide information that best describes your taxpayer status with the IRS.",
            info2: "The name/entity that you provide should be the same name/entity that is listed on your contract with Vacasa.",
        },
        taxClassifications: {
            INP: "Individual/sole proprietor or single-member LLC",
            LLC: "Limited liability company",
            CCO: "C Corporation",
            SCO: "S Corporation",
            PRT: "Partnership",
            TRE: "Trust/estate",
            OTH: "Other",
        },
        llcTaxClassfications: {
            C: "C Corporation",
            S: "S Corporation",
            P: "Partnership",
        },
        taxIdTypes: {
            SSN: "SSN (Social Security Number)",
            EIN: "EIN (Employer Identification Number)",
        },
        forms: {
            agreeAndSign: {
                title: "Agree & Submit",
                termsOfAgreement: "Terms Of Agreement",
                terms: "Under penalties of perjury, I certify that:\n\n1. The number shown on this form is my correct taxpayer identification number (or I am waiting for a number to be issued to me); and \n\n2. I am not subject to backup withholding because: (a) I am exempt from backup withholding, or (b) I have not been notified by the Internal Revenue Service (IRS) that I am subject to backup withholding as a result of a failure to report all interest or dividends, or (c) the IRS has notified me that I am no longer subject to backup withholding; and \n\n3. I am a U.S. citizen or other U.S. person (defined below); and \n\n4. The FATCA code(s) entered on this form (if any) indicating that I am exempt from FATCA reporting is correct. Certification instructions. You must cross out item 2 above if you have been notified by the IRS that you are currently subject to backup withholding because you have failed to report all interest and dividends on your tax return. For real estate transactions, item 2 does not apply. For mortgage interest paid, acquisition or abandonment of secured property, cancellation of debt, contributions to an individual retirement arrangement (IRA), and generally, payments other than interest and dividends, you are not required to sign the certification, but you must provide your correct TIN. See the instructions for Part II, later.",
                iAgree: "I agree to these terms",
                signature: "Signature",
                signaturePlaceholder: "Type fullname",
                signatureHelperText:
                    "By signing this document with an electronic signature, I agree that such signature will be as valid as handwritten signatures to the extent allowed by local law.",
                signedOn: "Signed On",
                validation: {
                    agreeToTermsRequired:
                        "Please agree to our terms of agreement",
                    signatureRequired: "Please enter your signature",
                    signatureMaxLength: "Signature cannot exceed 50 letters",
                },
            },
            taxInfo: {
                validation: {
                    entityTypeRequired: "Entity type is required",
                    entityTypeEmpty: "Entity type must be empty",
                    entityNameRequired: "Entity name is required",
                    taxIDTypeRequired: "Tax ID type is required",
                    taxIDTypeValid: "Tax ID type must be either SSN or EIN",
                    taxIDTypeEIN: "Tax ID type must be EIN",
                    taxClassificationRequired:
                        "Please select a tax classification",
                    taxClassificationValid: "Tax classification invalid",
                    ssnRequired: "SSN is required",
                    ssnLength: "SSN must be 9 digit in XXX-XX-XXXX format",
                    ssnInvalid: "SSN must be 9 digit in XXX-XX-XXXX format",
                    ssnAllZero: "SSN cannot be all 0",
                    ssnEmpty: "SSN must be empty",
                    einRequired: "EIN is required",
                    einLength: "EIN must be 9 digit in XX-XXXXXXX format",
                    einInvalid: "EIN must be 9 digit in XX-XXXXXXX format",
                    einAllZero: "EIN cannot be all 0",
                    einEmpty: "EIN must be empty",
                    llcTypeRequired: "LLC type required",
                    llcTypeValid: "LLC type must be either C, S, or P",
                    llcTypeEmpty: "LLC type must be empty",
                },
            },
            mailingAddress: {
                label: {
                    address: "Address",
                    city: "City",
                    stateOrProvince: "State/Province",
                    zipCode: "Zip/Postal Code",
                },
                placeholder: {
                    addressLine1: "Address Line 1",
                    addressLine2: "Address Line 2",
                    city: "City",
                    stateProvince: "State/Province",
                    zipCode: "5-digit zipcode",
                },
                validation: {
                    addressRequired: "Address is required",
                    cityRequired: "Please enter city",
                    postalCodeRequired: "Postal code is required",
                    provinceRequired: "State/Province is required",
                    postalCodeMaxLength: "Postal code must be 5 digit length",
                    postalCodeAllZero: "Postal code cannot be all 0",
                },
            },
        },
    },
}

export default taxes
