import { JSONAPIResource } from "@vacasa/owner-api-models"
import { Reservation as LegacyReservation } from "@vacasa/owner-api-models/src/legacy"
import { authAxios } from "api/authAxios"
import { UpdateOwnerHoldRequest } from "."

export const putOwnerHold = async (
    params: {
        contactId: string
        unitId: string
        data: UpdateOwnerHoldRequest
    },
    acceptVersion?: string
) => {
    const { contactId, data, unitId } = params

    return authAxios
        .put<{ data: JSONAPIResource<LegacyReservation> }>(
            `/v1/legacy/owners/${contactId}/units/${unitId}/hold`,
            data,
            { params: { acceptVersion } }
        )
        .then(res => res.data.data)
}
