const unitSelector = {
    allRentals: "Všechny rekreační pronájmy",
    search: "vyhledat",
    searchRentals: "Vyhledat mé rekreační pronájmy",
    allActive: "Všechny aktivní rekreační pronájmy",
    rentals: "rekreační pronájmy",
    inactive: "Neaktivní",
    inactiveRentals: "Neaktivní rekreační pronájmy",
    unitSelectorAria:
        "Pomocí tohoto výběru jednotky můžete změnit, která jednotka je aktuálně zobrazena.",
}

export default unitSelector
