import { OwnerHoldType } from "@vacasa/owner-api-models"
import Incrementer from "lib/components/Incrementer/Incrementer"
import upperFirst from "lodash/upperFirst"
import { FormattedMessage, useIntl } from "react-intl"
import { Label } from ".."
import styles from "./GuestSelect.module.scss"

interface GuestSelectProps {
    holdType: OwnerHoldType | null
    onChange: (id: string, value: number) => void
    occupancyInfo?: OccupancyInfo
}

type OccupancyType = "adults" | "children" | "pets"

type OccupancyInfo = Record<OccupancyType, number>

export const GuestSelect = ({
    holdType,
    onChange,
    occupancyInfo,
}: GuestSelectProps): JSX.Element => {
    const intl = useIntl()

    const guestStayHolds =
        holdType === "owner stay" || holdType === "complimentary stay"

    if (!guestStayHolds || !occupancyInfo) {
        return <></>
    }

    const incrementerRowProps: Array<{
        label: string
        subLabel: string | null
        id: OccupancyType
        minValue: number
        maxValue: number
    }> = [
        {
            label: `${upperFirst(
                intl.formatMessage({
                    id: "Calendar.flyout.adults",
                    defaultMessage: "adults",
                })
            )}`,
            subLabel: null,
            id: "adults",
            minValue: 1,
            maxValue: 99,
        },
        {
            label: `${upperFirst(
                intl.formatMessage({
                    id: "Calendar.flyout.children",
                    defaultMessage: "children",
                })
            )}`,
            subLabel: null,
            id: "children",
            minValue: 0,
            maxValue: 99,
        },
        {
            label: `${upperFirst(
                intl.formatMessage({
                    id: "Calendar.flyout.dogs",
                    defaultMessage: "dogs",
                })
            )}`,
            subLabel: null,
            id: "pets",
            minValue: 0,
            maxValue: 99,
        },
    ]

    return (
        <div>
            <Label>
                <FormattedMessage
                    id="Calendar.flyout.guests"
                    defaultMessage="Guests"
                />
            </Label>
            {incrementerRowProps.map(incrementerProps => (
                <div
                    className={styles.incrementerRow}
                    key={incrementerProps.id}
                >
                    <div className={styles.label}>{incrementerProps.label}</div>
                    <Incrementer
                        initialValue={occupancyInfo[incrementerProps.id]}
                        minValue={incrementerProps.minValue}
                        maxValue={incrementerProps.maxValue}
                        onChangeHandler={value =>
                            onChange(incrementerProps.id, value)
                        }
                        v2
                    />
                </div>
            ))}
        </div>
    )
}
