import React from "react"
import ProgressBarStep from "./ProgressBarStep/ProgressBarStep"
import styles from "./ProgressBar.module.scss"

interface ProgressBarProps {
    steps: string[]
    currentStepIndex: number
}

/**
 * Stateless functional component that renders progress bar with clickable steps.
 * Since it's not a pure component, it will rerender when the parent rerenders,
 * perhaps this should be wrapped in React.memo().
 */
const ProgressBar: React.FC<ProgressBarProps> = props => {
    return (
        <div className={styles.container}>
            {props.steps.map((text, stepIndex) => (
                <ProgressBarStep
                    key={text.replace(" ", "")}
                    solid={stepIndex <= props.currentStepIndex}
                    checked={stepIndex < props.currentStepIndex}
                    stepNumber={stepIndex + 1} // begin step at 1
                    text={text}
                />
            ))}
        </div>
    )
}
export default ProgressBar
