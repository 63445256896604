import * as React from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { DisplayStatus, TicketResponse } from "@vacasa/owner-api-models"
import { TicketStatusBadge, TicketStatusProps } from "lib/components/Badge"
import { format, parseISO } from "date-fns"
import { getLocaleFromLanguageCode } from "utils/date"
import { trackTicketDetailsOpened } from "services/segment/supportHub/supportHubTracking"
import { ReactComponent as SpeechBubbleIcon } from "../../assets/speech-bubble.svg"
import {ReactComponent as BellIcon} from "../../assets/icon-bell.svg";
import { getVacasaCommentsCount } from "utils/tickets/ticketsUtil"
import {useOwnerFeatureFlag} from "../../hooks/owner-feature-flag";
import {isFeatureFlagEnabled} from "../../utils/feature-flag";

type TicketListProps = {
    tickets: TicketResponse[]
    triggerDetailsBox: (ticket: TicketResponse) => void
}

const getStatusClassname = (
    status: DisplayStatus | null | undefined
): string => {
    switch (status) {
        case DisplayStatus.InProgress:
            return "in-progress"
        case DisplayStatus.Closed:
            return "closed"
        case DisplayStatus.Cancelled:
            return "cancelled"
        case DisplayStatus.Submitted:
        default:
            return "submitted"
    }
}

const getStatus = (
    status: DisplayStatus | null | undefined
): TicketStatusProps["status"] => {
    switch (status) {
        case DisplayStatus.InProgress:
            return "in-progress"
        case DisplayStatus.Closed:
            return "closed"
        case DisplayStatus.Cancelled:
            return "cancelled"
        case DisplayStatus.Submitted:
        default:
            return "submitted"
    }
}

/**
 * A list of tickets
 */
const TicketList: React.FC<TicketListProps> = ({
    tickets,
    triggerDetailsBox,
}) => {
    const intl = useIntl()

    const ownerReopenTicketFeatureFlag = useOwnerFeatureFlag(
        "owner-portal-reopen-ticket"
    )
    const ownerReopenTicketEnabled = isFeatureFlagEnabled(
        ownerReopenTicketFeatureFlag
    )

    const formatDate = (date: string) =>
        format(parseISO(date), "LLL d", {
            locale: getLocaleFromLanguageCode(intl.locale),
        })

    const getTicketDate = (ticket: TicketResponse) => {

        const isReopenedTicket= !!ticket.attributes.lastReopen

        switch (ticket.attributes.displayStatus) {
            case DisplayStatus.Closed:
                return (
                    <FormattedMessage
                        id="Maintenance.closedOn"
                        defaultMessage="Closed on {date}"
                        values={{
                            date: formatDate(
                                ticket.attributes.completedDate ?? ""
                            ),
                        }}
                    />
                )
            case DisplayStatus.Cancelled:
                return (
                    <FormattedMessage
                        id="Maintenance.cancelled"
                        defaultMessage="Cancelled"
                    />
                )
            case DisplayStatus.Submitted:
            case DisplayStatus.InProgress:
            default:
                return (
                    isReopenedTicket?
                        <FormattedMessage
                            id="Maintenance.reopenedOn"
                            defaultMessage="Reopened on {date}"
                            values={{
                                date: formatDate(ticket.attributes.lastReopen ?? ""),
                            }}
                        />:
                        <FormattedMessage
                            id="Maintenance.submittedOn"
                            defaultMessage="Submitted on {date}"
                            values={{
                                date: formatDate(ticket.attributes.creationDate),
                            }}
                        />
                )
        }
    }

    const getUnreadCommentCount= (ticket: TicketResponse):number =>{
        return ticket.attributes.unreadComments ? ticket.attributes.unreadComments : 0
    }
    return (
        <React.Fragment>
            <div
                className="table maintenance-table"
                data-testid="maintenance_table"
            >
                <div
                    className="clickable prevent-flyout-close"
                    style={{
                        paddingBottom: "50px",
                    }}
                >
                    {tickets.map(ticket => {
                        const status = getStatusClassname(
                            ticket.attributes.displayStatus
                        )

                        const commentCount = getVacasaCommentsCount(
                            ticket.attributes.comments ?? []
                        )

                        return (
                            <div
                                key={ticket.id}
                                className="ticket-container not-active hover-gray"
                                onClick={() => {
                                    triggerDetailsBox(ticket)
                                    trackTicketDetailsOpened({
                                        // eslint-disable-next-line camelcase
                                        ticket_id: ticket.id,
                                    })
                                }}
                            >
                                <div className={`status-bar ${status}`} />
                                <div className="ticket-content">
                                    <div className="ticket-list status">
                                        <TicketStatusBadge
                                            status={getStatus(
                                                ticket.attributes.displayStatus
                                            )}
                                        />
                                    </div>
                                    {!!commentCount && (
                                        <>
                                            <div
                                                className="ticket-list commentCounter"
                                                data-testid="comment_counter"
                                            >
                                                {commentCount}
                                            </div>
                                            <div
                                                className="ticket-list commentIcon"
                                                data-testid="comment_icon"
                                            >
                                                <SpeechBubbleIcon />
                                            </div>
                                        </>
                                    )}
                                    <div
                                        className={`ticket-list title ellipsis`}
                                    >
                                        {ticket.attributes.title}
                                    </div>
                                    <div className={`ticket-list description`}>
                                        {ticket.attributes.description}
                                    </div>
                                    <div data-testid="ticket_date_text" className="ticket-date">
                                        {getTicketDate(ticket)}
                                    </div>
                                    {
                                       ( ownerReopenTicketEnabled  && getUnreadCommentCount(ticket)) >0 &&
                                    <div data-testid="alert_bell_comment" className={`alert-bell-circle`}>
                                        <BellIcon/>
                                    </div>

                                    }
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </React.Fragment>
    )
}

export { TicketList }
