import { Flyout } from "../../../core/components/flyout/flyout"
import Layout from "./Layout/Layout"
import { Model } from "survey-core"

import React, { useEffect, useMemo, useRef, useState } from "react"
import styles from "./SurveyFlyout.module.scss"
import { useSurvey } from "../../../hooks/survey"
import Loader from "../../../lib/components/Loader"
import { useCurrentUnit } from "../../../hooks/units"
import { Survey } from "./Survey/Survey"
import { FormattedMessage } from "react-intl"
import { useContactId } from "../../../hooks/user"
import { useDecision } from "@optimizely/react-sdk"
import useRouter from "../../../hooks/common/useRouter"
import {
    trackRevenuePreferenceOpen,
    trackRevenuePreferenceSteps,
} from "../../../services/segment/survey/surveyTracking"
import { useSearchParams } from "react-router-dom"
import LoggingService from "../../../services/logging/logging.service"

/**
 * SurveyFlyout Component
 *
 * This component represents a survey that can be accessed multiple times via URL ({base}//performance/preference/create?UnitID=123).
 * The survey responses are updated each time the survey is accessed.
 * The availability of this functionality is controlled with feature flag.
 *
 */
const SurveyFlyout = () => {
    const router = useRouter()

    const [submitError, setSubmitError] = useState<boolean>(false)
    const surveyQuery = useSurvey("revenue-persona-questions")
    const [decision] = useDecision("owner-portal-survey")
    const tracked = useRef<boolean>(false);
    const contactId = useContactId()
    const { unitId } = useCurrentUnit()
    const [searchParams] = useSearchParams();
    const isWebView = searchParams.get('webview');
    const { enabled } = decision

    const loading = surveyQuery.isLoading
    const error = surveyQuery.isError
    const success = surveyQuery.isSuccess

    const surveyModel = useMemo(() => {
        return new Model(surveyQuery.data?.attributes?.survey || {})
    }, [surveyQuery.data])

    useEffect(() => {
        if(!tracked.current && !loading && !error) {
            trackRevenuePreferenceOpen()
            trackRevenuePreferenceSteps(1)
            LoggingService.log({
                message: "The revenue preference survey was successfully accessed.",
                context: {
                    unitId,
                    contactId
                }
            })
            tracked.current = true
        }
    }, [tracked, loading, error]) // eslint-disable-line react-hooks/exhaustive-deps

    if(!unitId || !contactId) {
        return <div data-testid={'error-modal'} />
    }

    if(!enabled) {
        document.body.style.overflow = "auto";

        // Note: Mobile apps may not detect redirects using `useRouter` in WebViews,
        // as client-side navigation doesn't change the browser in a detectable way.
        if(isWebView) {
            window.location.href = `/forward?view=preference&UnitID=${unitId}&ContactID=${contactId}`;
        }

        router.navigate({
            pathname: "/performance",
            search: router.location.search,
            hash: router.location.hash,
        });
    }

    return (
        <>
            <Flyout
                closeTo={"/performance"}
                closeOnClickOutside={true}
                onClose={() => document.body.style.overflow = "auto"}
                conClassName={styles["survey-flyout-container"]}
                hideCloseButton={Boolean(isWebView)}
            >
                { loading && <Loader testId={'loader'} />}
                { (error || submitError) &&
                    <Layout>
                        <Layout.Header>
                            <h3>
                                <FormattedMessage
                                    id="Survey.error_title"
                                    defaultMessage="Survey Not Available"
                                />
                            </h3>
                        </Layout.Header>
                        <Layout.Content>
                            <p>
                                {submitError ?
                                    <FormattedMessage
                                        id="Survey.submit_error_message"
                                        defaultMessage="Sorry, we could not submit your survey at this time. Please try again later."
                                    />
                                    :
                                    <FormattedMessage
                                        id="Survey.loading_error_message"
                                        defaultMessage="Sorry, the survey could not be loaded. Please try again later."
                                    />
                                }
                            </p>
                        </Layout.Content>
                    </Layout>
                }
                { success &&
                    <Survey
                        surveyModel={surveyModel}
                        unitId={unitId}
                        contactId={contactId}
                        submitError={setSubmitError}
                        isWebView={Boolean(isWebView)}
                    />
                }
            </Flyout>
        </>
    )
}

export { SurveyFlyout }