const login = {
    homeownerLogin: "Eigentümer-Login",
    clickHere: "hier",
    guestsClickHereToAccessDetails:
        "Gäste klicken bitte {{clickHere}}, um auf Reisedetails zugreifen zu können.",
    username: "Nutzername",
    password: "Passwort",
    forgotYourPassword: "Passwort vergessen?",
    login: "Login",
    employeeLogin: "Mitarbeiter-Login",
    contractorLogin: "Vertragspartner-Login",
}

export default login
