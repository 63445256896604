import React from "react"
import classnames from "classnames"

import styles from "./Banner.module.scss"
import { getClassStyle } from "utils/styles/styleWrapper"

/**
 * All properties must be included or all are undefined
 */
type AllOrNone<T> = T | Partial<{ [P in keyof T]: undefined }>

export type BannerProps = {
    /**
     * Visual style of the banner
     */
    variant?: "primary" | "secondary" | "warning" | "not-supported"
    /**
     * Text to display
     */
    copy: string | JSX.Element
    /**
     * Icon indicating the type of banner, shown on the left
     */
    bannerIcon?: JSX.Element
} & AllOrNone<{
    /**
     * The copy for the cta
     */
    ctaLabel: string
    /**
     * URL to navigate to on cta click
     */
    ctaDestination: string

    /**
     * Run an handler when the cta is clicked
     */
    interactionEvent?: () => void

    /**
     * Open the cta link in a new window
     */
    newWindow?: boolean
    /**
     * Highlight the cta
     */
    destinationHighlight?: boolean
}> &
    AllOrNone<{
        /**
         * Content of the close action
         */
        closeButton: JSX.Element
        /**
         * Action to take when closeButton is clicked
         *
         */
        onDismiss: () => void
    }>

const Banner: React.FC<BannerProps> = props => {
    const { variant = "primary", bannerIcon, copy } = props
    return (
        <div
            className={classnames(styles.banner, {
                [getClassStyle(styles.primary)]: variant === "primary",
                [getClassStyle(styles.secondary)]: variant === "secondary",
                [getClassStyle(styles.warning)]: variant === "warning",
                [getClassStyle(styles.notSupported)]:
                    variant === "not-supported",
            })}
        >
            {bannerIcon && (
                <div className={styles.bannerIcon}>{bannerIcon}</div>
            )}
            <div className={styles.copyWrapper}>
                <span>{copy}</span>
                {props.ctaLabel && (
                    <a
                        className={classnames(styles.cta, {
                            [getClassStyle(styles.ctaHighlighted)]:
                                props.destinationHighlight,
                        })}
                        onClick={props.interactionEvent}
                        href={props.ctaDestination}
                        target={props.newWindow ? "__blank" : undefined}
                    >
                        {props.ctaLabel}
                    </a>
                )}
            </div>
            {props.onDismiss && (
                <button
                    className={styles.closeButton}
                    onClick={props.onDismiss}
                >
                    {props.closeButton}
                </button>
            )}
        </div>
    )
}

export { Banner }
