import React from "react"
import { FormattedMessage } from "react-intl"
import { ReactComponent as StarIcon } from "../../../../../assets/icon-star.svg"
import { GuestReviewResponse } from "./GuestReviewResponse"
import ExpandableText from "../../../ExpandableText/ExpandableText"
import styles from "./GuestReviewListItem.module.scss"
import { observer } from "mobx-react"
import { trackReservationDetailsSeeMoreClicked } from "services/segment/reservation/reservationTracking"
import { format, parseISO } from "date-fns"
import { GuestStarReview } from "lib/components/List/UpcomingStays/GuestStarReview"

interface ItemProps {
    reservationId: string
    reviewText?: string
    starRating?: number
    response?: string
    firstName?: string
    lastInitial?: string
    reviewDate?: string
}

const GuestReviewListItem: React.FC<ItemProps> = observer(
    ({
        reservationId,
        reviewText,
        starRating,
        response,
        firstName,
        lastInitial,
        reviewDate,
    }) => {
        const formattedDate = reviewDate
            ? format(parseISO(reviewDate), "M/d/yyyy")
            : ""
        return (
            <li key="guestReviewListItem" className={styles.container}>
                <StarIcon className={styles.icon} />
                <div className={styles.title}>
                    <FormattedMessage
                        id="CalendarPage.flyout.guestReview"
                        defaultMessage="Guest review"
                    />
                </div>
                {firstName && lastInitial && reviewDate && (
                    <div className={styles.reviewerAndDate}>
                        <div>
                            {firstName} {lastInitial}.
                        </div>
                        <div className={styles.reviewDate}>{formattedDate}</div>
                    </div>
                )}
                <div>
                    {starRating !== undefined && (
                        <div className={styles.starRating}>
                            <GuestStarReview
                                reservationId={Number(reservationId)}
                            />
                        </div>
                    )}
                    {reviewText && (
                        <>
                            <ExpandableText
                                collapseLabel={
                                    <FormattedMessage
                                        id="CalendarPage.flyout.guestReviewCollapseLabel"
                                        defaultMessage="See less"
                                    />
                                }
                                expandLabel={
                                    <FormattedMessage
                                        id="CalendarPage.flyout.guestReviewExpandLabel"
                                        defaultMessage="See more"
                                    />
                                }
                                text={reviewText}
                                onExpandHandler={() => {
                                    trackReservationDetailsSeeMoreClicked(
                                        reservationId
                                    )
                                }}
                            />
                            {response && (
                                <GuestReviewResponse response={response} />
                            )}
                        </>
                    )}
                </div>
            </li>
        )
    }
)

export default GuestReviewListItem
