const maintenance = {
    title: "Mantenimiento",
    supportHub: "Support",
    newRequest: "Nueva solicitud",
    open: "Abierto",
    closed: "Cerrado",
    requestNumber: "Nº de Solicitud",
    status: "Estado",
    inProgress: "En progreso",
    rental: "Propiedad",
    allRentals: "Todas las propiedades",
    search: "buscar",
    submitted: "Enviado",
    updated: "Actualizado",

    selectRental: "Seleccionar una propiedad",
    newMaintenanceRequest: "New Maintenance Request",
    needSomeHelp: "Need some help?",
    categorySubtitle:
        "Let us know how we can support you by selecting an option below:",
    requestTitle: "Maintenance request title",
    titlePlaceholder: "Clogged drain",
    describeYourRequest: "Describe your request",
    requestDetails: "Describe your request in detail",
    note: "Note",
    notes: "Notes",
    detailsPlaceholder:
        "We noticed a big drainage issue with the primary kitchen sink. It's a super slow drain or it doesn't even drain at all. Please help!",
    clear: "Clear",
    submitRequest: "Enviar solicitud",
    areYouSure: "Are you sure?",
    leavingAlertText:
        "Leaving the submission form will cause you to lose any data you may have entered.",
    continueWithSubmission: "Continue with Submission",
    cancelRequest: "Cancel Request",
    requestSubmitted: "Request submitted",
    requestSaved: "Solicitud guardada",

    submitNewRequest: "Enviar una nueva solicitud",
    noOpenRequests: "No Open Requests",
    newRequestWill: "New requests will appear here until they are addressed.",
    noClosedRequests: "No Closed Requests",
    closedRequestsWill:
        "Once an open request is addressed, it will be closed out and will appear here.",

    ariaLabel: "Haga clic aquí para enviar una solicitud de mantenimiento",

    needHelp: "¿Necesitas ayuda?",
    contactSentenceIfQuestions:
        "Comuníquese con su contacto, {name}, si tiene preguntas o desea actualizar su solicitud.",
    or: "o",

    failedFetchError: `Tivemos dificuldades para carregar os seus pedidos de manutenção. Se o problema persistir, tente novamente mais tarde.`,
    failedCreateError: `Não nos foi possível guardar o seu pedido de manutenção. Verifique a sua seleção e tente novamente.`,
    reload: `Atualizar`,
    emptyState:
        "Need help with home maintenance? You’ll be able to submit requests here.",

    submittedOn: "Submitted on {date}",
    closedOn: "Closed on {date}",
    cancelled: "Cancelled",
    comments: {
        comments: "Comments",
        empty: "When Vacasa responds to this request, their comments will appear here.",
        overCharacterLengthLimit:
            "The comment should not be over 1000 characters.",
        failedCreateCommentError:
            "We were unable to save your comment. Please try again later.",
    },
    featureAlertTitle: "NEW! Two-way Ticket Comments",
    featureAlertBody:
        "Add a comment to your ticket to provide more information or follow up with a question, which will streamline communication about a specific ticket.",
}

export default maintenance
