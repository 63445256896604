/* eslint-disable camelcase */
import segmentService from "../segment.service"
import { SPLIT_EXPERIMENT } from "./experimentTrackingConstants"

export const trackSplitExperiment = (
    experiment: string,
    variation: string
): void => {
    segmentService.track(SPLIT_EXPERIMENT, {
        experiment,
        variation,
    })
}
