/* eslint-disable camelcase */
import { Unit, UnitExpense } from "@vacasa/owner-api-models/src/legacy/Earnings"
import classnames from "classnames"
import { FormattedMessage, FormattedNumber } from "react-intl"
import styles from "../statements.module.scss"

// Utils
import classNames from "classnames"
import { differenceInCalendarMonths, parseISO } from "date-fns"
import {
    advanceDepositPayeeSubAccountId,
    buildExpenseDescription,
    displayFormattedNumber,
    formatDate,
    getExpensesWithSameResId,
    getReservationPeriod,
    isFixedRent,
    revenueSharePayeeSubAccountId,
} from "utils/statements/statementsUtil"

const displayNetRent = (
    currency: string,
    expense: UnitExpense,
    expensesWithSameResId: number[]
) => {
    if (expense.type !== "Reservation") {
        return null
    }
    const reservationMonthSpan =
        expense.reservation?.first_night && expense.reservation?.last_night
            ? differenceInCalendarMonths(
                  parseISO(expense.reservation?.last_night),
                  parseISO(expense.reservation?.first_night)
              ) + 1
            : 1
    let description, reservation, reservationPeriod
    const postDate = formatDate(expense.posting_date)
    if (expense.payee_sub_accountID === revenueSharePayeeSubAccountId) {
        description = expense.description
        reservation = {
            rent: expense.debit,
            net_rent: expense.debit,
            mgmt_fee_amount: 0,
        }
    } else if (
        expense.payee_sub_accountID === advanceDepositPayeeSubAccountId &&
        expense.reservation
    ) {
        description = expense.description
        reservation = expense.reservation
    } else if (expense.reservation) {
        reservation = expense.reservation
        description = buildExpenseDescription(
            expense,
            reservation,
            expensesWithSameResId,
            reservationMonthSpan
        )
        reservationPeriod = getReservationPeriod(
            reservation.first_night,
            reservation.check_out
        )
    }
    if (!reservation) {
        return null
    }

    return (
        <tr key={expense.id} className="net-rent">
            <td className="gutter" />
            <td className="date">{postDate}</td>
            <td colSpan={2} className="desc">
                {description}
                {reservationMonthSpan > 1 && expense.is_occupied_nights && (
                    <div className={classNames(styles["net-rent-note"])}>
                        <strong>
                            <FormattedMessage
                                id="Statements.netRentNoteBegin"
                                defaultMessage="NOTE: "
                            />
                        </strong>
                        <i
                            className={classNames(
                                styles["net-rent-note-content"]
                            )}
                        >
                            <FormattedMessage
                                id="Statements.netRentNoteContent"
                                defaultMessage="The entire stay spans across {reservationMonthSpan} months ({reservationPeriod}). Monthly revenue will appear on each month’s respective statement(s)."
                                values={{
                                    reservationMonthSpan,
                                    reservationPeriod: reservationPeriod ?? "",
                                }}
                            />
                        </i>
                    </div>
                )}
            </td>
            <td
                className={classnames(
                    "text-right gross-rent",
                    styles["medium-gray"]
                )}
            >
                {displayFormattedNumber(Number(reservation.rent), currency)}
            </td>
            <td
                className={classnames(
                    "text-righ mgmt-fee",
                    styles["medium-gray"],
                    styles["text-right"]
                )}
            >
                {displayFormattedNumber(
                    // Display the management fee amount as a negative value
                    Number(-(reservation.mgmt_fee_amount ?? 0)),
                    currency
                )}
            </td>
            <td className="text-right amount">
                {displayFormattedNumber(Number(reservation.net_rent), currency)}
            </td>
            <td className={styles["gutter-end"]} />
        </tr>
    )
}

const displayFixedRent = (
    currency: string,
    expense: UnitExpense,
    reservation: boolean
) => {
    if (expense.type !== "Fixed Rent") {
        return null
    }
    reservation = false
    const postDate = formatDate(expense.posting_date)
    return (
        <tr key={expense.id} className="">
            <td className="gutter" />
            <td className="date">{postDate}</td>
            <td colSpan={4} className="desc-span">
                {expense.description}
            </td>
            <td className="text-right amount">
                <span
                    className={classnames("text-right", styles["medium-gray"])}
                >
                    {reservation
                        ? displayFormattedNumber(
                              Number(expense.credit),
                              currency
                          )
                        : ""}
                </span>
                <span
                    className={classnames("text-right", styles["medium-gray"])}
                >
                    {reservation ? "$0.00" : ""}
                </span>
                <span className="text-right">
                    {expense.credit
                        ? displayFormattedNumber(
                              Number(expense.credit),
                              currency
                          )
                        : displayFormattedNumber(
                              Number(expense.debit),
                              currency
                          )}
                </span>
            </td>
            <td className={styles["gutter-end"]} />
        </tr>
    )
}

const displayFixedRentAndNetRent = (currency: string, unit: Unit) => {
    let reservation
    if (!unit.expenses || unit.expenses.length === 0) {
        return null
    }
    const expensesWithSameResId = getExpensesWithSameResId(unit.expenses)
    // would be good to use toSorted but it was only released early 2023: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/toSorted
    const sortedExpenses = [...unit.expenses].sort((a, b) =>
        a.posting_date > b.posting_date ? 1 : -1
    )
    return sortedExpenses.map(expense => {
        if (expense.type === "Reservation") {
            return displayNetRent(currency, expense, expensesWithSameResId)
        }
        if (expense.type === "Fixed Rent") {
            reservation = false
            return displayFixedRent(currency, expense, reservation)
        }
        return null
    })
}

interface UnitDetailsRentProps {
    currency: string
    unit: Unit
}

const UnitDetailsRent: React.FC<UnitDetailsRentProps> = props => {
    const { currency, unit } = props
    const fixedRent = isFixedRent(unit)
    return (
        <>
            <div className="table-responsive">
                <table className="open table">
                    <thead className={styles["uppercase-header"]}>
                        <tr>
                            <td className={styles["inset"]} />
                            <td colSpan={5}>
                                <FormattedMessage
                                    id="Statements.netRent"
                                    defaultMessage="Net Rent"
                                />
                            </td>
                            <td colSpan={2} className="text-right">
                                {displayFormattedNumber(
                                    Number(unit.net_rent),
                                    currency
                                )}
                            </td>
                            <td className={styles["inset"]} />
                        </tr>
                    </thead>
                </table>
            </div>
            <div className="table-responsive">
                <table className="open table">
                    <tbody
                        className={
                            parseInt(unit.gross_rent) !== 0
                                ? "show"
                                : styles["hide"]
                        }
                    >
                        {!fixedRent && (
                            <tr className="net-rent-header">
                                <td className="gutter" />
                                <td className="date" />
                                <td colSpan={2} className="desc" />
                                <td
                                    className={classnames(
                                        "text-right gross-rent",
                                        styles["medium-gray"],
                                        styles["row-title"]
                                    )}
                                >
                                    <span>
                                        <FormattedMessage
                                            id="Statements.grossRent"
                                            defaultMessage="Gross Rent"
                                        />
                                    </span>
                                </td>
                                <td
                                    className={classnames(
                                        "text-right-gray mgmt-fee",
                                        styles["medium-gray"],
                                        styles["text-right"],
                                        styles["row-title"]
                                    )}
                                >
                                    <span>
                                        <FormattedMessage
                                            id="Statements.mgmtFee"
                                            defaultMessage="Mgmt Fee"
                                        />
                                    </span>
                                </td>
                                <td
                                    className={classnames(
                                        "amount",
                                        styles["medium-gray"],
                                        styles["text-right"],
                                        styles["row-title"]
                                    )}
                                >
                                    <span>
                                        <FormattedMessage
                                            id="Statements.netRent"
                                            defaultMessage="Net Rent"
                                        />
                                    </span>
                                </td>
                                <td className={styles["gutter-end"]} />
                            </tr>
                        )}
                        {displayFixedRentAndNetRent(currency, unit)}
                    </tbody>
                    <tfoot
                        className={
                            parseInt(unit.gross_rent) !== 0
                                ? "show"
                                : styles["hide"]
                        }
                    >
                        <tr className="net-rent">
                            <td className="gutter" />
                            <td colSpan={3} className="net-rent-desc-span">
                                <strong>
                                    <FormattedMessage
                                        id="Statements.total"
                                        defaultMessage="Total"
                                    />
                                </strong>
                            </td>
                            <td
                                className={classnames(
                                    "text-right gross-rent",
                                    styles["medium-gray"],
                                    styles["subtotal-numbers"]
                                )}
                            >
                                {fixedRent
                                    ? ""
                                    : displayFormattedNumber(
                                          Number(unit.gross_rent),
                                          currency
                                      )}
                            </td>
                            <td
                                className={classnames(
                                    "text-right mgmt-fee",
                                    styles["medium-gray"],
                                    styles["subtotal-numbers"]
                                )}
                            >
                                {!fixedRent && (
                                    <FormattedNumber
                                        // Display the management fee as a negative value
                                        value={-unit.mgmt_fee}
                                        // eslint-disable-next-line react/style-prop-object
                                        style="currency"
                                        currency={currency}
                                    />
                                )}
                            </td>
                            <td className="text-right">
                                <strong>
                                    {displayFormattedNumber(
                                        Number(unit.net_rent),
                                        currency
                                    )}
                                </strong>
                            </td>
                            <td className={styles["gutter-end"]} />
                        </tr>
                    </tfoot>
                </table>
            </div>
        </>
    )
}

export default UnitDetailsRent
