const unitSelector = {
    allRentals: "Alle Ferienimmobilien",
    search: "Suchen",
    searchRentals: "Meine Ferienimmmobilien durchsuchen",
    allActive: "Ferienimmobilien",
    rentals: "Ferienimmobilien",
    inactive: "Inaktiv",
    inactiveRentals: "Inaktive Ferienimmobilien",
    unitSelectorAria:
        "Mit dieser Immobilien-Auswahl kannst du festlegen, welche Immobilie aktuell angezeigt wird.",
}

export default unitSelector
