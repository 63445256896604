import calendar from "./calendar.js"
import dashboard from "./dashboard"
import errors from "./errors"
import guestworks from "./guestworks.js"
import homeInfo from "./homeInfo.js"
import linenService from "./linenService.js"
import login from "./login.js"
import maintenance from "./maintenance.js"
import navigation from "./navigation.js"
import notFound from "./notFound.js"
import notification from "./notification"
import onboarding from "./onboarding.js"
import ownerReferral from "./ownerReferral.js"
import performance from "./performance.js"
import petsAllowed from "./petsAllowed.js"
import profile from "./profile.js"
import settings from "./settings.js"
import shared from "./shared.js"
import statementRefresh from "./statementRefresh"
import statements from "./statements.js"
import support from "./support"
import taxes from "./taxes.js"
import topbar from "./topbar.js"
import unitSelector from "./unitSelector.js"
import badges from "./badges.js"
import reservation from "./reservation.js"

const txt = {
    Badges: badges,
    Navigation: navigation,
    Topbar: topbar,
    Profile: profile,
    Login: login,
    UnitSelector: unitSelector,
    CalendarPage: calendar,
    Performance: performance,
    Statements: statements,
    HomeInfo: homeInfo,
    PetsAllowed: petsAllowed,
    LinenService: linenService,
    Maintenance: maintenance,
    Taxes: taxes,
    Settings: settings,
    NotFound: notFound,
    Onboarding: onboarding,
    Shared: shared,
    Errors: errors,
    OwnerReferral: ownerReferral,
    StatementRefresh: statementRefresh,
    Notification: notification,
    Dashboard: dashboard,
    Guestworks: guestworks,
    Support: support,
    Reservation: reservation,
}

export default txt
