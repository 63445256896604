import { JSONAPIResource, UserPreference } from "@vacasa/owner-api-models"
import { AxiosError } from "axios"
import {
    useMutation,
    UseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryResult,
} from "react-query"
import { fetchOwnerUnitPreferences } from "./fetchOwnerUnitPreferences"
import {
    patchOwnerUnitPreferences,
    PatchOwnerUnitPreferencesRequest,
} from "./patchOwnerUnitPreferences"

const useOwnerUnitPreferences = (
    unitId: string | null | undefined
): UseQueryResult<JSONAPIResource<UserPreference>[], AxiosError> =>
    useQuery(
        ["owner_unit_preference", unitId],
        ({ signal }) => {
            if (typeof unitId !== "string") {
                throw new Error("unitId was not defined")
            }
            return fetchOwnerUnitPreferences({ signal, unitId })
        },
        {
            refetchInterval: 1000 * 60 * 60, // 1 hour
            staleTime: 1000 * 60 * 60, // 1 hour,
            enabled: !!unitId,
        }
    )

const useOwnerUnitPreferencesMutation = (): UseMutationResult<
    void,
    AxiosError,
    PatchOwnerUnitPreferencesRequest,
    unknown
> => {
    const queryClient = useQueryClient()
    return useMutation<
        void,
        AxiosError,
        PatchOwnerUnitPreferencesRequest,
        unknown
    >(owner => patchOwnerUnitPreferences(owner), {
        onSuccess: (_data, variables) => {
            queryClient.invalidateQueries([
                "owner_unit_preference",
                variables.unitId,
            ])
        },
    })
}
export { useOwnerUnitPreferences, useOwnerUnitPreferencesMutation }
