import { JSONAPIResource, Reservation } from "@vacasa/owner-api-models"
import { authAxios } from "../../api/authAxios"

type FetchReservationRequest = {
    id: string
    contactId: string
    signal?: AbortSignal
}

const fetchReservation = ({
    id,
    contactId,
    signal,
}: FetchReservationRequest): Promise<JSONAPIResource<Reservation>> =>
    authAxios
        .get<JSONAPIResource<Reservation>>(`/v1/reservations/${id}`, {
            params: {
                contactID: contactId,
                acceptVersion: "v3",
            },
            signal,
        })
        .then(response => response.data)

export { fetchReservation }
