import * as React from "react"
import { FormattedMessage, useIntl } from "react-intl"
import styles from "./AverageStarRating.module.scss"

import { Rating } from "@material-ui/lab"
import { OWNER_LIBRARY_URL } from "Constants"
import { useOwnerFeatureFlag } from "hooks/owner-feature-flag"

import SVGIcon from "lib/components/SVGIcon"
import { Tooltip } from "lib/components/Tooltip/Tooltip"
import { useState } from "react"
import {
    OwnerLibraryLocation,
    SegmentLocation,
} from "services/segment/constants"
import { trackOwnerLibraryLinkClicked } from "services/segment/owner-library/ownerLibraryTracking"
import { trackGuestReviewSeeAllClicked } from "services/segment/performance/performanceTracking"
import { ReactComponent as StarIcon } from "../../assets/icon-star-filled.svg"
import { ReactComponent as ThumbsUpIcon } from "../../assets/icon-thumbs-up.svg"
import { Card, CardContent } from "../../components/Card"
import starStyles from "./StarOnReviewCard.module.scss"
import { GuestReviewModal } from "./modal/GuestReviewModal/GuestReviewModal"
interface AverageStarRatingProps {
    average: number
    total: number
    testId?: string
}

export const AverageStarRating: React.FC<AverageStarRatingProps> = ({
    average,
    total,
    testId,
}) => {
    const featureFlag = useOwnerFeatureFlag("channel-reviews")
    const channelReviewsEnabled = featureFlag.isEnabled
    const intl = useIntl()
    const averageRating = average.toFixed(1)

    const learnMore = intl.formatMessage({
        id: "Performance.reviews.learnMore",
        defaultMessage: "Learn more",
    })

    const onLearnMore = () => {
        trackOwnerLibraryLinkClicked(
            SegmentLocation.Performance,
            OwnerLibraryLocation.GuestReviews
        )
    }

    const tooltipMessage = (
        <FormattedMessage
            id={
                channelReviewsEnabled
                    ? "Performance.reviews.overallRatingTooltipV2"
                    : "Performance.reviews.overallRatingTooltip"
            }
            defaultMessage="Your overall rating is currently calculated based only on Vacasa.com reviews."
            values={{
                br: <br />,
                learnMore: (
                    <a
                        className={styles.learnMore}
                        onClick={onLearnMore}
                        href={`${OWNER_LIBRARY_URL}reviews`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {learnMore}
                    </a>
                ),
            }}
        />
    )
    const [showGuestReviewModal, setShowGuestReviewModal] = useState(false)
    const closeGuestReviewModal = (): void => {
        setShowGuestReviewModal(false)
    }
    const onSeeAllClick = () => {
        setShowGuestReviewModal(true)
        trackGuestReviewSeeAllClicked()
    }

    return (
        <Card testId={testId} className={styles.panelStarRating}>
            <CardContent className={styles.contentStarRating}>
                <div className={styles.ratingContainer}>
                    <div className={styles.headingStarRating}>
                        <ThumbsUpIcon />
                        <div className={styles.headingDescription}>
                            <FormattedMessage
                                id="Performance.reviews.heading"
                                defaultMessage="Guest reviews"
                                description="Heading title for average guest rating."
                            />
                        </div>
                        <div
                            data-testid="performance-see-all-reviews-button"
                            className={styles.seeAll}
                            onClick={onSeeAllClick}
                        >
                            <FormattedMessage
                                id="Performance.reviews.seeAll"
                                defaultMessage="See all"
                            />
                        </div>
                    </div>
                    <div className={styles.ratingParagraph}>
                        <StarIcon className={styles.starIcon} />
                        <div className={styles.averageRating}>
                            {averageRating}
                        </div>
                        <div className={styles.ratingDescription}>
                            <FormattedMessage
                                id={
                                    channelReviewsEnabled
                                        ? "Performance.reviews.overallVacasaRatingV2"
                                        : "Performance.reviews.overallVacasaRating"
                                }
                                defaultMessage="Overall Vacasa.com rating"
                                description="Overall average rating"
                            />
                        </div>
                        <Tooltip title={"OVERALL RATING"} body={tooltipMessage}>
                            <SVGIcon
                                svg="icon-feather-info"
                                colorName="gray-lightest"
                                className="w-16 h-16 zero-margin cursor-pointer"
                            />
                        </Tooltip>
                    </div>
                    <div className={styles.rating}>
                        {average >= 1 && (
                            <Rating
                                className="ReviewCardStarRating"
                                style={{ ...starStyles }}
                                precision={0.5}
                                size={"small"}
                                value={average}
                                readOnly
                            />
                        )}
                        {total > 0 && (
                            <div className={styles.starParagraph}>
                                <FormattedMessage
                                    id="Performance.reviews.reviewCount"
                                    defaultMessage="{total} Ratings"
                                    description="Total number of reviews."
                                    values={{ total }}
                                />
                            </div>
                        )}
                    </div>
                </div>
                {showGuestReviewModal && (
                    <GuestReviewModal
                        closeGuestReviewModal={closeGuestReviewModal}
                    />
                )}
            </CardContent>
        </Card>
    )
}
