import { JSONAPIResource, Unit } from "@vacasa/owner-api-models"
import unitSelectorPlaceholder from "../../assets/icon-unitselector-house.png"
import { getUnitImage } from "utils/unit"

export const unitToSelectOptions = (units: JSONAPIResource<Unit>[]) => {
    const mapSelectItem = (unit: JSONAPIResource<Unit>) => {
        return {
            imageURL: getUnitImage(unit, unitSelectorPlaceholder),
            text: unit.attributes.name ?? "",
            value: String(unit.id),
            attributes: {
                isGuestworksUnit:
                    unit.attributes.status.guestworksApp.isGuestworksUnit,
                isOnboarding:
                    unit.attributes.status.ownerOnboardingApp.isOnboarding,
            },
        }
    }
    return units.map(unit => mapSelectItem(unit))
}
