/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import {
    JSONAPIResource,
    Reservation,
    ReservationType,
} from "@vacasa/owner-api-models"
import {
    addDays,
    formatISO,
    isAfter,
    isBefore,
    parseISO,
    setHours,
    setMinutes,
    startOfDay,
} from "date-fns"
import { isEmpty, isString } from "lodash"
import { IntlShape, MessageDescriptor } from "react-intl"
import getInitialFromName from "../../utils/name/getInitialFromName"

export function getReservationExternalNotes(
    reservation: JSONAPIResource<Reservation>
): string {
    return (
        (reservation.attributes.occupancyType === ReservationType.VACASAHOLD
            ? reservation.attributes.vacasaHold?.holdExternalNote
            : reservation.attributes.ownerHold?.holdExternalNote) ?? ""
    )
}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export function calculateCostOfHold(
    startDate: Date | number,
    endDate: Date | number,
    availability: any
): number {
    let cost = 0
    while (isBefore(startDate, endDate)) {
        const rate =
            availability[formatISO(startDate, { representation: "date" })]?.rate
        cost += rate ?? 0
        startDate = addDays(startDate, 1)
    }
    return cost
}

export const reservationHasEnded = (reservation: Reservation): boolean => {
    if (!reservation.endDate) return false
    const endDate = startOfDay(parseISO(reservation.endDate))
    const now = startOfDay(new Date())
    return isBefore(endDate, now)
}

export function reservationHasStarted(reservation: Reservation): boolean {
    if (!reservation.startDate || !reservation.endDate) {
        return false
    }
    const startDate = startOfDay(parseISO(reservation.startDate))
    const now = startOfDay(new Date())
    return !isAfter(startDate, now)
}

// Private

// Returns true if the current time is the same or before 9.30am on the check-out day.
export function reservationBeforeCheckOutTime(
    reservation: JSONAPIResource<Reservation>
): boolean {
    const resData = reservation.attributes
    if (!resData.startDate || !resData.endDate) {
        return false
    }
    let maxCheckOutTime = setHours(parseISO(resData.endDate), 9)
    maxCheckOutTime = setMinutes(maxCheckOutTime, 30)

    const now = new Date()
    return !isAfter(now, maxCheckOutTime)
}

export function reservationIsInProgress(
    reservation?: JSONAPIResource<Reservation>
): boolean {
    if (!reservation) return false
    return (
        reservationHasStarted(reservation.attributes) &&
        !reservationHasEnded(reservation.attributes)
    )
}

export function bookingName(reservation: JSONAPIResource<Reservation>): string {
    const { firstName, lastName } = reservation.attributes
    const lastNameInitial = getInitialFromName(lastName) ?? ""
    const isNameSet = isString(firstName) && firstName !== "NULL"
    return isNameSet ? `${firstName} ${lastNameInitial}` : ""
}

/**
 * Format a time string to 12 hour time
 *
 * @param time time formatted as  `hour:minutes:seconds`
 * @returns time in 12 hour `H:MM AM` or ``H:MM PM`
 */
export function format12HourTime(time: string): string {
    const [hours, minutes, seconds] = time.split(":").map(Number)
    const timeFormat = Intl.DateTimeFormat("default", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
        // We only care about time so timezone is irrelevent
        timeZone: "UTC",
    })
    const date = Date.UTC(0, 0, 0, hours, minutes, seconds, 0)
    return timeFormat.format(date)
}

/**
 * Determine whether to show net rent on reservations list
 *
 * @param reservations reservations data
 * @param showReservationNetRentEnabled feature flag "show-reservation-net-rent"
 * @returns boolean
 */
export function showNetRent(
    reservations: JSONAPIResource<Reservation>[],
    showReservationNetRentEnabled: boolean
): boolean {
    return (
        showReservationNetRentEnabled &&
        reservations.some(
            reservation =>
                typeof reservation.attributes.managementFee === "number" &&
                typeof reservation.attributes.managementFeePercent ===
                    "number" &&
                typeof reservation.attributes.netRent === "number"
        )
    )
}

/**
 * Determine whether to show net rent on reservations flyout
 *
 * @param reservation reservation data
 * @param showReservationNetRentEnabled feature flag "show-reservation-net-rent"
 * @returns boolean
 */
export function showNetRentOnFlyout(
    reservation: JSONAPIResource<Reservation>,
    showReservationNetRentEnabled: boolean
): boolean {
    return (
        showReservationNetRentEnabled &&
        typeof reservation.attributes.managementFee === "number" &&
        typeof reservation.attributes.managementFeePercent === "number" &&
        typeof reservation.attributes.netRent === "number"
    )
}

/**
 * Returns the display name based on the occupancy type
 */
export const getDisplayNameFromOccupancyType = (
    occupancyType?: ReservationType
): MessageDescriptor => {
    switch (occupancyType) {
        case ReservationType.RESERVATION:
            return {
                id: "CalendarPage.reservationType.reservation",
                defaultMessage: "Guest Stay",
            }
        case ReservationType.VACASAHOLD:
            return {
                id: "CalendarPage.reservationType.vacasaHold",
                defaultMessage: "Vacasa Hold",
            }
        case ReservationType.OWNERHOLD:
        default:
            return {
                id: "CalendarPage.reservationType.ownerHold",
                defaultMessage: "Owner Hold",
            }
    }
}

export const getGuestSummary = (
    adults: number,
    children: number,
    pets: number,
    intl: IntlShape
) => {
    const guestSummary = []
    if (adults) {
        const multipleAdults =
            adults >= 5
                ? intl.formatMessage({
                      id: "CalendarPage.flyout.fiveOrMoreAdults",
                      defaultMessage: "adults",
                  })
                : intl.formatMessage({
                      id: "CalendarPage.flyout.adults",
                      defaultMessage: "adults",
                  })
        const adultText =
            adults === 1
                ? intl.formatMessage({
                      id: "CalendarPage.UpcomingStays.adult",
                      defaultMessage: "adult",
                  })
                : multipleAdults
        guestSummary.push(`${adults} ${adultText}`)
    }
    if (children) {
        const multipleKids =
            children >= 5
                ? intl.formatMessage({
                      id: "CalendarPage.flyout.fiveOrMoreChildren",
                      defaultMessage: "children",
                  })
                : intl.formatMessage({
                      id: "CalendarPage.flyout.children",
                      defaultMessage: "children",
                  })

        const kidText =
            children === 1
                ? intl.formatMessage({
                      id: "CalendarPage.UpcomingStays.child",
                      defaultMessage: "child",
                  })
                : multipleKids
        guestSummary.push(`${children} ${kidText}`)
    }
    if (pets) {
        const multipleDogs =
            pets >= 5
                ? intl.formatMessage({
                      id: "CalendarPage.flyout.fiveOrMoreDogs",
                      defaultMessage: "dogs",
                  })
                : intl.formatMessage({
                      id: "CalendarPage.flyout.dogs",
                      defaultMessage: "dogs",
                  })

        const dogText =
            pets === 1
                ? intl.formatMessage({
                      id: "CalendarPage.UpcomingStays.dog",
                      defaultMessage: "dog",
                  })
                : multipleDogs
        guestSummary.push(`${pets} ${dogText}`)
    }
    if (isEmpty(guestSummary)) {
        guestSummary.push(
            intl.formatMessage({
                id: "CalendarPage.flyout.noGuestInfo",
                defaultMessage: "No guest information",
            })
        )
    }

    return guestSummary
}

export const isHousekeepingChecked = ({
    housekeeping,
    housekeepingAllowed,
    housekeepingRequired,
}: {
    housekeepingRequired: boolean
    housekeepingAllowed: boolean
    housekeeping: boolean
}) => {
    return housekeepingRequired || (housekeepingAllowed && !!housekeeping)
}
