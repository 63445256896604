import { ComponentType } from "react"

/**
 * Allows a hook to be used with a class component.
 * Bridges between legacy class components and hooks.
 * @param Component
 * @param hook
 * HookProps is the type of the hook's return value
 * PassedProps are the props expected to be prop drilled into the component
 * @returns
 */
export const withHookHocTyped =
    <HookProps extends object, PassedProps = object>(
        Component: ComponentType<HookProps & PassedProps>,
        hook: () => HookProps
    ): ComponentType<PassedProps> =>
    props => {
        if (!hook) {
            throw Error("withHookHoc requires hookCallerFn")
        }
        return <Component {...props} {...hook()} />
    }
