import { default as classnames } from "classnames"
import { useOwnerPreferencesMutation } from "hooks/owner-preferences"
import { ChangeEvent, FC, useCallback, useState } from "react"
import { ReactComponent as ThumbsDownIcon } from "../../../assets/icon-thumbs-down.svg"
import { ReactComponent as ThumbsUpIcon } from "../../../assets/icon-thumbs-up.svg"
import { Primary } from "../Buttons/BaseButton"
import FloatingNotification, {
    FloatingNotificationProps,
} from "../FloatingNotification/FloatingNotification"
import styles from "./FeatureFeedback.module.scss"
import {
    trackFeatureFeedbackDisplayed,
    FeatureRating,
    trackFeatureFeedbackRating,
    trackFeatureFeedbackComment,
    trackFeatureFeedbackDismissed,
} from "services/segment/floating-notification/floatingNotificationTracking"
import { JSONAPIResource, UserPreference } from "@vacasa/owner-api-models"
import { useQueryClient } from "react-query"
import { OWNER_PREFERENCE_QUERY_KEY } from "hooks/owner-preferences/useOwnerPreferences"
import {
    FeatureId,
    useFeatureInteractionState,
} from "contexts/feature-feedback"

interface FeedbackContentProps {
    ratingBody: string
    commentsBody: string
    hideNotification: () => void
    trackFeedbackRating: (rating: FeatureRating) => void
    trackFeedbackComments: (comments: string) => void
    handleHideBell: () => void
}

const FeedbackContent = ({
    ratingBody,
    commentsBody,
    hideNotification,
    trackFeedbackRating,
    trackFeedbackComments,
    handleHideBell,
}: FeedbackContentProps): JSX.Element => {
    const [comments, setComments] = useState<string>("")
    const [showRating, setShowRating] = useState(true)
    const [showComments, setShowComments] = useState(false)

    const onInputChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { value } = e.target
        setComments(value)
    }

    const handleFeedbackClick = useCallback(
        (rating: FeatureRating) => {
            setShowRating(false)
            setShowComments(true)
            trackFeedbackRating(rating)
            handleHideBell()
        },
        [handleHideBell, trackFeedbackRating]
    )

    const onSubmit = () => {
        if (trackFeedbackComments) {
            trackFeedbackComments(comments)
            hideNotification()
        }
    }

    return (
        <>
            {showRating && (
                <div
                    className={classnames(
                        styles.notification__body,
                        "font-normal",
                        "text-sm"
                    )}
                >
                    {ratingBody}
                    <div className={styles.feedback}>
                        <div
                            className={styles.feedbackIconContainer}
                            onClick={() => handleFeedbackClick("thumbs down")}
                        >
                            <ThumbsDownIcon className={styles.feedbackIcon} />
                        </div>
                        <div
                            className={styles.feedbackIconContainer}
                            onClick={() => handleFeedbackClick("thumbs up")}
                        >
                            <ThumbsUpIcon className={styles.feedbackIcon} />
                        </div>
                    </div>
                </div>
            )}
            {showComments && (
                <div
                    className={classnames(
                        styles.notification__body,
                        "font-normal",
                        "text-sm"
                    )}
                >
                    {commentsBody}
                    <div>
                        <textarea
                            className={styles.feedbackInput}
                            value={comments}
                            onChange={onInputChange}
                        />
                        <Primary
                            className={styles.submit}
                            onClick={onSubmit}
                            disabled={!comments}
                        >
                            Submit
                        </Primary>
                    </div>
                </div>
            )}
        </>
    )
}

type FeatureFeedbackProps = {
    commentsBody: string
    featureId: FeatureId
    pointerPosition: FloatingNotificationProps["pointerPosition"]
    ratingBody: string
    requiredInteractionCount?: number
    title: string
    userPreference: JSONAPIResource<UserPreference> | undefined
    wrapper?: (children: JSX.Element) => JSX.Element
}

/**
 * Generic version of the original FloatingFeedback with preset Segement Tracking Events
 * @param param0
 * @returns
 */
export const FeatureFeedback: FC<FeatureFeedbackProps> = ({
    commentsBody,
    featureId,
    pointerPosition = "bottom-center",
    ratingBody,
    requiredInteractionCount,
    title,
    userPreference,
    wrapper,
}) => {
    const [hideBell, setHideBell] = useState(false)
    const queryClient = useQueryClient()
    const interactionCount = useFeatureInteractionState()[featureId]
    const userPreferenceMutation = useOwnerPreferencesMutation({
        onSuccess: () => {
            queryClient.invalidateQueries(OWNER_PREFERENCE_QUERY_KEY)
        },
    })

    const hideNotification = useCallback(() => {
        if (userPreference?.id) {
            userPreferenceMutation.mutate({
                id: userPreference.id.toString(),
                value: true,
            })
        }
    }, [userPreference, userPreferenceMutation])

    const handleHideBell = () => {
        setHideBell(true)
    }

    const onFeedbackRatingCaptured = useCallback(
        (rating: FeatureRating) => {
            trackFeatureFeedbackRating(featureId, rating)
        },
        [featureId]
    )

    const onFeedbackDisplayed = useCallback(() => {
        trackFeatureFeedbackDisplayed(featureId)
    }, [featureId])

    const onFeedbackCommentsCaptured = useCallback(
        (feedback: string) => {
            trackFeatureFeedbackComment(featureId, feedback)
        },
        [featureId]
    )

    const onFeedbackDismissed = useCallback(() => {
        trackFeatureFeedbackDismissed(featureId)
    }, [featureId])

    if (userPreference?.attributes.value) return null
    if (requiredInteractionCount && interactionCount < requiredInteractionCount)
        return null

    const FeedbackComponent = (
        <FloatingNotification
            title={title}
            userPreference={userPreference}
            pointerPosition={pointerPosition}
            feedbackContent={() => (
                <FeedbackContent
                    ratingBody={ratingBody}
                    commentsBody={commentsBody}
                    hideNotification={hideNotification}
                    trackFeedbackRating={onFeedbackRatingCaptured}
                    trackFeedbackComments={onFeedbackCommentsCaptured}
                    handleHideBell={handleHideBell}
                />
            )}
            trackFloatingNotificationDisplayed={onFeedbackDisplayed}
            trackFloatingNotificationDismissed={onFeedbackDismissed}
            hideBell={hideBell}
        />
    )

    if (wrapper) {
        return wrapper(FeedbackComponent)
    }
    return FeedbackComponent
}
