import {
    OwnerSearchResult,
    PostOwnerSearchRequest,
} from "@vacasa/owner-api-models/src/legacy"
import { authAxios } from "../../api/authAxios"

export type PostSearchRequest = {
    data: PostOwnerSearchRequest
    signal?: AbortSignal
}

const postOwnerSearch = ({
    data,
    signal,
}: PostSearchRequest): Promise<OwnerSearchResult[]> =>
    authAxios
        .post<{ data: OwnerSearchResult[] }>(`v1/legacy/owners/search`, data, {
            signal,
        })
        .then(response => response.data.data)

export { postOwnerSearch }
