import { Zap } from "react-feather"
import styles from "./BookingProbabilityTooltip.module.scss"
import Badge from "lib/components/Badge"
import { FormattedMessage } from "react-intl"
import { FC } from "react"
import classNames from "classnames"

type BookingProbabilityTooltipProps = {
    level: "low" | "medium" | "high"
}

export const BookingProbabilityTooltip: FC<BookingProbabilityTooltipProps> = ({
    level,
}) => {
    return (
        <div className={classNames(styles.tooltip)}>
            <div className={styles.icon}>
                <Zap width={24} height={24} />
            </div>
            <div className={styles.text}>
                <FormattedMessage
                    id="Dashboard.charts.marketRate.tooltips.bookingProbability.title"
                    defaultMessage="Probability of Booking"
                />
            </div>
            <div>
                <Badge
                    backgroundColor="var(--white)"
                    borderColor={
                        level === "high"
                            ? "var(--moss)"
                            : level === "medium"
                            ? "var(--awareness)"
                            : "var(--alert)"
                    }
                    textColor={
                        level === "high"
                            ? "var(--moss)"
                            : level === "medium"
                            ? "var(--awareness)"
                            : "var(--alert)"
                    }
                >
                    {level === "high" && (
                        <FormattedMessage
                            id="Dashboard.charts.marketRate.tooltips.bookingProbability.high"
                            defaultMessage="High"
                        />
                    )}
                    {level === "medium" && (
                        <FormattedMessage
                            id="Dashboard.charts.marketRate.tooltips.bookingProbability.medium"
                            defaultMessage="Med"
                        />
                    )}
                    {level === "low" && (
                        <FormattedMessage
                            id="Dashboard.charts.marketRate.tooltips.bookingProbability.low"
                            defaultMessage="Low"
                        />
                    )}
                </Badge>
            </div>
        </div>
    )
}
