import { PostOwnerSearchRequest } from "@vacasa/owner-api-models/src/legacy"
import { Input } from "lib/components/Input"
import { observer } from "mobx-react"
import { ChangeEvent, FormEvent, useCallback, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { Card, CardContent } from "../../../components/Card"
import styles from "./search.module.scss"

import { Button } from "lib/components/Button"

interface Fields {
    email: string
    name: string
    phone: string
    rentalAddress: string
    rentalCode: string
    rentalId: string
    rentalName: string
    username: string
}

const FIELDS_DEFAULT: Fields = {
    email: "",
    name: "",
    phone: "",
    rentalAddress: "",
    rentalCode: "",
    rentalId: "",
    rentalName: "",
    username: "",
}

interface Props {
    isLoading: boolean
    onSearch: (data: PostOwnerSearchRequest) => Promise<void>
}

const SearchForm = ({ isLoading, onSearch }: Props) => {
    const location = useLocation()
    const [fields, setFields] = useState<Fields>(FIELDS_DEFAULT)

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search)
        setFields({
            email: searchParams.get("search_email") ?? "",
            name: searchParams.get("search_name") ?? "",
            phone: searchParams.get("search_phone") ?? "",
            rentalAddress: searchParams.get("search_address") ?? "",
            rentalCode: searchParams.get("search_rental_code") ?? "",
            rentalId: searchParams.get("search_rental_id") ?? "",
            rentalName: searchParams.get("search_rental_name") ?? "",
            username: searchParams.get("search_username") ?? "",
        })
    }, [location.search])

    const onFormSubmit = useCallback(
        (e: FormEvent) => {
            e.preventDefault()
            const {
                email,
                name,
                phone,
                rentalAddress,
                rentalCode,
                rentalId,
                rentalName,
                username,
            } = fields
            onSearch({
                /* eslint-disable camelcase */
                search_address: rentalAddress,
                search_email: email,
                search_name: name,
                search_rental_code: rentalCode,
                search_rental_id: rentalId,
                search_rental_name: rentalName,
                search_username: username,
                search_phone: phone,
                /* eslint-enable camelcase */
            })
        },
        [onSearch, fields]
    )

    const onInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setFields(prevState => ({
            ...prevState,
            [name]: value,
        }))
    }, [])

    return (
        <Card>
            <CardContent>
                <h1 className="type-display-small">Search</h1>
                <p>View the Owner Portal as any Vacasa Homeowner sees it.</p>
                <br />
                <form onSubmit={onFormSubmit}>
                    <div className={styles["row"]}>
                        <div className={styles["col"]}>
                            <h4 className="type-heading-medium">
                                Search By Owner
                            </h4>
                            <Input
                                containerClassName={styles["form-group"]}
                                labelText="Owner Name"
                                id="name"
                                name="name"
                                onChange={onInputChange}
                                value={fields.name}
                                type="text"
                            />

                            <Input
                                containerClassName={styles["form-group"]}
                                labelText="Owner Username"
                                id="username"
                                name="username"
                                onChange={onInputChange}
                                value={fields.username}
                                type="text"
                            />

                            <Input
                                containerClassName={styles["form-group"]}
                                labelText="Owner Email Address"
                                id="email"
                                name="email"
                                type="email"
                                onChange={onInputChange}
                                value={fields.email}
                            />

                            <Input
                                containerClassName={styles["form-group"]}
                                labelText="Owner Phone Number"
                                id="phone"
                                name="phone"
                                onChange={onInputChange}
                                value={fields.phone}
                                type="text"
                            />
                        </div>
                        <div className={styles["col"]}>
                            <h4 className="type-heading-medium">
                                Search By Unit
                            </h4>

                            <Input
                                containerClassName={styles["form-group"]}
                                labelText="Unit Name"
                                id="rentalName"
                                name="rentalName"
                                onChange={onInputChange}
                                value={fields.rentalName}
                                type="text"
                            />

                            <Input
                                containerClassName={styles["form-group"]}
                                labelText="Unit ID"
                                id="rentalId"
                                name="rentalId"
                                onChange={onInputChange}
                                value={fields.rentalId}
                                type="text"
                            />

                            <Input
                                containerClassName={styles["form-group"]}
                                labelText="Unit Code"
                                id="rentalCode"
                                name="rentalCode"
                                onChange={onInputChange}
                                value={fields.rentalCode}
                                type="text"
                            />

                            <Input
                                containerClassName={styles["form-group"]}
                                labelText="Unit Address"
                                id="rentalAddress"
                                name="rentalAddress"
                                onChange={onInputChange}
                                value={fields.rentalAddress}
                                type="text"
                            />
                        </div>
                    </div>
                    <div className={styles.formFooter}>
                        <Button
                            type="submit"
                            loading={isLoading}
                            variant="primary"
                            aria-label={"Click to Search"}
                            disabled={isLoading}
                        >
                            Search
                        </Button>
                    </div>
                </form>
            </CardContent>
        </Card>
    )
}

export default observer(SearchForm)
