/**
 * Utility File for App Related Functions used during start up
 */

import {
    JSONAPIResource,
    Owner,
    UserPreference,
} from "@vacasa/owner-api-models"
import { LAST_ACCESSED_CONTACT_ID } from "constants/preferences.constants"
import { getFromQsOrAttemptedRoute } from "utils/query-string"
import { getSessionConfig, updateSessionConfig } from "utils/session"

/**
 * Helper method to set a session storage variable 'contactIds' when impersonating an owner
 * as an employee. Only sets if we are not currently impersonating another owner.
 * @param param0
 * @returns
 */
export const checkEmployeeContactIdURL = ({
    contactId,
    isEmployee,
}: {
    contactId: string | null
    isEmployee: boolean
}) => {
    if (!contactId || !isEmployee) return

    updateSessionConfig({
        contactIds: [contactId],
    })
}

/**
 * Checks to ensure that the contact id in the query string matches the one in session storage
 * @returns
 */
export const checkForwardMatchesSessionStorage = () => {
    if (!isSearchForward()) return

    const forwardContactId = getFromQsOrAttemptedRoute("ContactID")
    if (!forwardContactId) return

    const session = getSessionConfig()

    if (!session.contactIds) return

    // If the contact id is not found in session storage clear storage to enable a reset
    if (session.contactIds.indexOf(forwardContactId) === -1) {
        updateSessionConfig({
            contactIds: null,
        })
    }
}

/**
 * Returns the user based on last accessed or contact query string
 * @param contacts
 * @param preferences
 * @param queryContactId
 * @returns
 */
export const getCurrentUser = (
    contacts: Owner[],
    preferences: JSONAPIResource<UserPreference>[] = [],
    queryContactId: string | null
): {
    hasLastAccessedContact: boolean
    user: Owner | null
} => {
    if (queryContactId) {
        const user = contacts.find(
            contact => contact.contactId === queryContactId
        )
        if (user) {
            return {
                hasLastAccessedContact: true,
                user,
            }
        }
    }
    const preference = preferences?.find(
        preference => preference.id === LAST_ACCESSED_CONTACT_ID
    )

    const preferredContactId = preference?.attributes.value

    const matchedUser =
        contacts.find(contact => contact?.contactId === preferredContactId) ??
        contacts[0] ??
        null

    return {
        hasLastAccessedContact:
            !!preferredContactId &&
            matchedUser?.contactId === preferredContactId,
        user:
            contacts.find(
                contact => contact?.contactId === preferredContactId
            ) ??
            contacts[0] ??
            null,
    }
}

/**
 * Checks if the the current request is for an employee owner impersonating a contact
 * @returns
 */
export const isSearchForward = (): boolean =>
    window.location.href.includes("/forward")

/**
 * Redirects the user to the onboarding app
 */
export const redirectToOnboardingApp = (): void => {
    const params = new URLSearchParams(window.location.search)
    const uri = params.get("onboarding_redirect_path") ?? "/onboarding"
    window.location.replace(uri)
}

/**
 * Checks if the onboarding app should be shown instead
 * @returns
 */
export const renderOnboardingApp = (): boolean => {
    const params = new URLSearchParams(window.location.search)
    return !!params.get("onboarding_redirect_path")
}
