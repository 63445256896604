import {
    FC,
    PropsWithChildren,
    createContext,
    useContext,
    useEffect,
    useState,
} from "react"
import { IntlProvider, useIntl } from "react-intl"
import { useRootService } from "services"
import { injectLanguage } from "strings"
import { flatten } from "flat"
import moment from "moment"
import { getLanguageFromId } from "utils/language"
import LoggingService from "services/logging/logging.service"
import { useUser } from "hooks/user"

type LanguageProviderContext = {
    setLocale: (locale: string) => void
    locale: string
}

const LanguageContext = createContext<LanguageProviderContext>({
    locale: "en",
    setLocale: () => null,
})

export const useLanguage = () =>
    useContext<LanguageProviderContext>(LanguageContext)

export const LanguageContextProvider: FC<PropsWithChildren> = ({
    children,
}) => {
    const [locale, setLocale] = useState("en")
    const { user } = useUser()

    // Update locale if user changes
    useEffect(() => {
        if (!user) return
        let userLocale = getLanguageFromId(user.language ?? undefined)
        if (userLocale === "en" && user.accountInfo.currencyCode === "CAD") {
            userLocale = "en-150"
        }

        if (!userLocale) return

        setLocale(userLocale)
    }, [user])

    // Update moment locale if locale changes
    useEffect(() => {
        try {
            // The 'en' locale doesn't exist as it's built in so don't attempt to load it
            if (locale !== "en") {
                require(`moment/locale/${locale.slice(0, 2)}`)
            }
            moment.locale(locale)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            LoggingService.log({
                message: "Failed to set moment locale",
                error,
            })
        }
    }, [locale])

    return (
        <LanguageContext.Provider
            value={{
                locale,
                setLocale,
            }}
        >
            <IntlProvider
                locale={locale}
                messages={flatten(injectLanguage(locale))}
                defaultLocale="en"
            >
                <ErrorServiceIntlProvider />
                {children}
            </IntlProvider>
        </LanguageContext.Provider>
    )
}

// Provides the global `intlShape` to the error service
const ErrorServiceIntlProvider = () => {
    const intl = useIntl()
    const { errorService } = useRootService()
    useEffect(() => {
        errorService.intl = intl
    }, [errorService, intl])

    return <></>
}
