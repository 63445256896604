const badges = {
    contact: {
        active: "active",
        inactive: "inactive",
        maintenance: "maintenance",
        onboarding: "onboarding",
        terminated: "terminated",
    },
    ownerHold: {
        complimentaryStay: "comp",
        homeForSale: "for sale",
        other: "other",
        ownerStay: "owner",
        propertyCare: "property",
        seasonalHold: "seasonal",
        terminationHold: "terminate",
    },
    reservation: {
        inherited: "inherited",
    },
    ticket: {
        closed: "closed",
        inProgress: "in progress",
        submitted: "submitted",
    },
    unitType: {
        guestworks: "Guestworks",
    },
    vacasaHold: {
        housekeeping: "housekeeping",
        maintenance: "maintenance",
        outOfOrder: "out of order",
        photography: "photography",
    },
}

export default badges
