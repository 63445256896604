import { forwardRef } from "react"
import { FormattedMessage } from "react-intl"
import {
    HowDoIGetPaidFaq,
    WhatIsMyReserveBalanceFaq,
    WhenDoIGetMyStatement,
    WhenWillMyPaymentArrive,
    WhereDoISendPaymentsFaq,
    WhoCanIContactFaq,
} from "./faqs/faqs"

import classNames from "classnames"
import styles from "./StatementsFAQ.module.scss"
export interface StatementsFAQProps {
    openFAQ?: "WhatIsMyReserveBalanceFaq" | null
}

export const StatementsFAQ = forwardRef<HTMLDivElement, StatementsFAQProps>(
    ({ openFAQ }, ref) => {
        const faqs: JSX.Element[] = [
            <WhenDoIGetMyStatement />,
            <HowDoIGetPaidFaq />,
            <WhenWillMyPaymentArrive />,
            <WhatIsMyReserveBalanceFaq
                open={openFAQ === "WhatIsMyReserveBalanceFaq"}
            />,
            <WhereDoISendPaymentsFaq />,
            <WhoCanIContactFaq />,
        ]

        return (
            <div
                className={classNames(styles.container)}
                ref={ref}
                key={"statements-faq"}
            >
                <div className={styles.title}>
                    <FormattedMessage
                        id="Statements.faq.title"
                        defaultMessage="Statements FAQ"
                    />
                </div>
                {faqs.map((faq, i) => (
                    <div className={styles.statementsFaq} key={i}>
                        {faq}
                    </div>
                ))}
            </div>
        )
    }
)
