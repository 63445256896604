import classnames from "classnames"
import React, { FC } from "react"
import { FormattedMessage } from "react-intl"

import { ADMIN_URL } from "../../../Environment"
import { ReactComponent as BellSVG } from "../../../assets/icon-bell.svg"
import taxAlertImg from "../../../assets/tax-w9-alert.png"
import { useLegacyViewToken } from "../../../hooks/legacy-view-token"
import { useW9TaxSubmission } from "../../../hooks/taxes-w9-submission"
import { Secondary } from "../../../lib/components/Buttons/BaseButton"
import Modal from "../../../lib/components/Modal/Modal"

import { useW9Window } from "hooks/taxes"
import styles from "./TaxesModal.module.scss"
import { useUser } from "hooks/user"
import { OptimizelyFeature } from "@optimizely/react-sdk"
import { useTaxInformationFlyoutAPI } from "../TaxInformationFlyout"

const adminW9URL = (token: string): string => {
    const w9URL = new URL("admin/ownerW9.php", ADMIN_URL)
    w9URL.searchParams.append("viewToken", token)
    return w9URL.toString()
}

const TaxesModal: FC = (): JSX.Element | null => {
    const { user } = useUser()
    const { open: openW9Flyout } = useTaxInformationFlyoutAPI()
    const legacyViewTokenQuery = useLegacyViewToken()
    const w9TaxSubmissionQuery = useW9TaxSubmission()
    const { open } = useW9Window()

    const [modalDismissed, setModalDismissed] = React.useState(false)

    const handleClose = React.useCallback(() => {
        setModalDismissed(true)
    }, [])

    const handleOpenW9 = React.useCallback(() => {
        if (!legacyViewTokenQuery.isSuccess) {
            throw new Error("Taxes modal button clicked before data ready")
        }
        const w9Url = adminW9URL(legacyViewTokenQuery.data)
        open(w9Url)
    }, [legacyViewTokenQuery.data, legacyViewTokenQuery.isSuccess, open])

    const handleOpenW9Flyout = () => {
        setModalDismissed(true)
        openW9Flyout?.()
    }

    // Loading or Error
    if (!w9TaxSubmissionQuery.isSuccess) {
        return null
    }

    if (modalDismissed) return null
    if (user?.accountInfo.country !== "US") return null
    if (w9TaxSubmissionQuery.data.hasSubmittedW9) return null

    return (
        <Modal
            onCloseHandler={handleClose}
            closeButtonStyleOverride={styles["modal-close"]}
        >
            <div className={styles["modal-content"]}>
                <div className={styles["modal-header"]}>
                    <div className={styles["action-required"]}>
                        <BellSVG className={styles["icon"]} />
                        <span className={styles["text"]}>
                            <FormattedMessage
                                id="Taxes.taxModal.actionRequired"
                                defaultMessage="Action Required!"
                            />
                        </span>
                    </div>

                    <div
                        className={classnames(styles.image, styles.regular)}
                        data-testid="modal-icon"
                    >
                        <img
                            alt="W9 Tax Alert"
                            src={taxAlertImg}
                            className={styles["modal-icon"]}
                        />
                    </div>
                </div>
                <div className={styles["modal-row"]}>
                    <h3
                        className={classnames(
                            styles["modal-title"],
                            "type-heading-medium-large"
                        )}
                        data-testid="tax-modal-title"
                    >
                        <FormattedMessage
                            id="Taxes.taxModal.title"
                            defaultMessage="Enter Taxpayer Info"
                        />
                    </h3>
                    <span className={styles["modal-description"]}>
                        <FormattedMessage
                            id="Taxes.taxModal.description"
                            defaultMessage="We need your taxpayer information on file so we can provide you with accurate tax preparation documents for last year."
                        />
                    </span>
                    <div className={styles["modal-action-buttons"]}>
                        <OptimizelyFeature feature="w9-flyout-workflow">
                            {isEnabled => (
                                <Secondary
                                    typeOf={"button"}
                                    onClick={
                                        isEnabled
                                            ? handleOpenW9Flyout
                                            : handleOpenW9
                                    }
                                >
                                    <FormattedMessage
                                        id="Taxes.taxModal.primaryButton"
                                        defaultMessage="Fill it Out"
                                    />
                                </Secondary>
                            )}
                        </OptimizelyFeature>
                        <Secondary typeOf={"link"} onClick={handleClose}>
                            <FormattedMessage
                                id="Taxes.taxModal.secondardButton"
                                defaultMessage="Maybe Later"
                            />
                        </Secondary>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default TaxesModal
