/* eslint-disable camelcase */
// Event
export const RESERVATION_DETAILS_EVENT = "Reservation Details"
export const SHOW_CANCELLATION_FLYOUT_VIEWED_EVENT =
    "Show cancellation flyout viewed"
export const CALENDAR_EVENT = "Calendar"
// Action
export const RESERVATION_DETAILS_VIEWED_ACTION = "reservation_details_viewed"
export const LINK_CLICK_ACTION = "link_click"
export const BUTTON_CLICK_ACTION = "Click"
export const CALCELLATION_FLYOUT_ACTION = "cancellation_flyout"
export const ADD_ANOTHER_HOLD_ACTION = "add_another_hold"
export const OWNER_HOLD_CLICK_ACTION = "owner_hold_click"
// Option
export const SEE_MORE_OPTION = "see_more"
