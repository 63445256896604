import { RefObject, useEffect, useMemo, useState } from "react"

/**
 * Watch for changes emitted by MutationObserver.
 * @param target reference to the element to keep track of
 * @param options required configuration on which details to observe
 * @param callback optional callback when a mutation happens
 * @param childSelector optional child selector to watch a child element of the target
 * @returns
 */
function useMutationObserver(
    target: RefObject<HTMLElement> | null,
    options: MutationObserverInit,
    callback?: MutationCallback,
    childSelector?: string
): MutationRecord[] | undefined {
    const [mutations, setMutations] = useState<MutationRecord[]>()

    const observer = useMemo(() => {
        if (isSSR) {
            return null
        }
        return new MutationObserver(
            (mutations: MutationRecord[], observer: MutationObserver) => {
                if (callback) {
                    callback(mutations, observer)
                }
                setMutations(mutations)
            }
        )
    }, [callback])

    useEffect(() => {
        let element = getRefElement(target)
        if (observer && element) {
            if (childSelector) {
                element = element.querySelector(childSelector) ?? element
            }

            // Start observing the target node for configured mutations
            observer.observe(element, options)
            return () => {
                observer.disconnect()
            }
        }
    }, [target, observer, options, childSelector])

    return mutations
}

/**
 * Return the "current" property on the given argument.
 * If there is no such property, return the original argument.
 * @param element RefObject or some other type
 * @returns "current" property, or the original argument.
 */
function getRefElement(
    element: RefObject<HTMLElement> | HTMLElement | null
): HTMLElement | null {
    if (element && "current" in element) {
        return element.current
    }

    return element
}

// Check is server side rendered
const isSSR = !(typeof window !== "undefined" && window.document?.createElement)

export { useMutationObserver }
