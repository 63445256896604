/* eslint-disable camelcase */
import storageService from "services/storage.service"
import { v4 as uuid } from "uuid"
import { OWNER_MIGRATION_COMPLETE } from "../../Constants"
import {
    APP_URL,
    IDP_URL,
    IDP_REDIRECT_URI,
    IDP_CLIENT_ID,
    IDP_SCOPES,
    IDP_AUDIENCE,
} from "../../Environment"
import LoggingService from "../../services/logging/logging.service"
import { clearUserCache } from "utils/user"

const migrationValue = "true"

const IDP_LOGOUT_URL = `${IDP_URL}logout?redirect_uri=${IDP_REDIRECT_URI}`

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getAuthorizeQueryParams = (params?: any): string => {
    params = {
        directory_hint: "email",
        owner_migration_needed: true,
        client_id: IDP_CLIENT_ID,
        response_type: "token+id_token",
        redirect_uri: IDP_REDIRECT_URI,
        scope: IDP_SCOPES && encodeURIComponent(IDP_SCOPES),
        audience: IDP_AUDIENCE,
        state: uuid(),
        nonce: uuid(),
        mode: "owner",
        ...params,
    }

    return Object.keys(params)
        .map(k => `${k}=${params[k]}`)
        .join("&")
}

export const getPasswordResetURL = (email: string): string => {
    const next = `authorize?${getAuthorizeQueryParams()}`
    return `${IDP_URL}account/recover?email=${encodeURIComponent(
        email
    )}&next=/${encodeURIComponent(next)}`
}

export const getIdpAuthorizeUrl = (): string =>
    `${IDP_URL}authorize?${getAuthorizeQueryParams()}`

export const getEmployeeIdpAuthorizeUrl = (): string => {
    clearUserCache()
    const next = `authorize?${getAuthorizeQueryParams()}`
    return `${IDP_URL}federate/onelogin?next=/${encodeURIComponent(next)}`
}

export const getIdpRefreshUrl = (): string =>
    `${getIdpAuthorizeUrl()}&prompt=none`

export const redirectToIdp = (): void | boolean => {
    if (
        window.location.href.includes("mock-idp") ||
        window.location.href.includes("error") ||
        (window.location.hash && window.location.hash.includes("access_token"))
    ) {
        return false
    }
    LoggingService.log({ message: "redirecting to idp" })
    window.location.href = getIdpAuthorizeUrl()
}

export const logoutToIdp = (): void => {
    window.location.href = IDP_LOGOUT_URL
}

/**
 * Get the access token from the URL (`#access_token=`).
 */
export const getAccessTokenHash = (): string | null => {
    const hash = window.location.hash ? window.location.hash.substring(1) : ""
    const hashToken = hash.split("=")[1]
    if (!hashToken) return null
    const token = hashToken.split("&")[0]
    if (!token) return null
    return token
}

export const checkStoredMigrationComplete = (): boolean => {
    // check for migration complete in session storage
    if (storageService.sessionStorage.getItem(OWNER_MIGRATION_COMPLETE)) {
        storageService.sessionStorage.removeItem(OWNER_MIGRATION_COMPLETE)
        return true
    }
    return false
}

export const checkMigrationComplete = (): void => {
    const urlParams = new URLSearchParams(window.location.search)
    const migrationComplete = urlParams.get(OWNER_MIGRATION_COMPLETE)
    if (migrationComplete === migrationValue) {
        storageService.sessionStorage.setItem(
            OWNER_MIGRATION_COMPLETE,
            migrationValue
        )
    }
}

export const handleError = (
    error: string,
    description: string,
    state?: string | null,
    innerError?: string | null
): void => {
    const datadogError = {
        error,
        description,
        state,
        innerError,
    }
    let errorCode = ""
    if (typeof description == "string") {
        errorCode = description
    }
    const idpErrorUrl = `${APP_URL}error?error=${error}&error_description=${errorCode}&error_type=login`
    LoggingService.log({ message: error, context: datadogError })
    window.location.href = idpErrorUrl
}
