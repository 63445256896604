import { authAxios } from "../../api/authAxios"

type FetchStatementYearsRequest = {
    contactId?: string | null
    signal?: AbortSignal
}

export const fetchStatementYears = ({
    contactId,
    signal,
}: FetchStatementYearsRequest): Promise<number[]> => {
    return authAxios
        .get<{ data: number[] }>(
            `/v1/legacy/owners/${contactId}/statement-years`,
            {
                signal,
            }
        )
        .then(response => response.data.data)
}
