import {
    JSONAPIResource,
    Reservation,
    ReservationType,
    Unit,
} from "@vacasa/owner-api-models"
import { FormattedMessage } from "react-intl"
import { ReactComponent as MailIcon } from "../../../../assets/mail.svg"
import { ReactComponent as PhoneIcon } from "../../../../assets/phone.svg"
import FlyOutActionPartial from "../../../../lib/components/FlyOut/FlyOutActionPartial"
import {
    ExtendButton,
    ExtendButtonProps,
    extendButtonDefaults,
} from "../../../../utils/calendar/calendarUtil"
import { formatPhone } from "../../../../utils/phone/formatPhone"
import {
    reservationHasEnded,
    reservationIsInProgress,
} from "../../../../utils/reservations/reservationsUtil"
import styles from "../ReservationFlyout.module.scss"
import { useIsEmployee } from "hooks/user"

type ViewReservationFooterProps = {
    reservation?: JSONAPIResource<Reservation>
    unitInfo: {
        isFixedRent: boolean
        isCurrentUnitActive: boolean
        allowHolds: boolean
        unit: JSONAPIResource<Unit>
    }
    onDeleteClick: () => void
    onEditClick: () => void
}

const ViewReservationFooter: React.FC<ViewReservationFooterProps> = ({
    reservation,
    unitInfo,
    onDeleteClick,
    onEditClick,
}): JSX.Element | null => {
    const isEmployee = useIsEmployee()

    if (reservation?.attributes.cancelled) {
        return null
    }

    const editButtonProps: ExtendButtonProps = {
        isLoading: false,
        disabled: false,
        onClick: () => {
            onEditClick()
        },
        text: "Edit",
        type: "edit",
    }
    const deleteButtonProps: ExtendButtonProps = {
        isLoading: false,
        disabled: false,
        onClick: () => {
            onDeleteClick()
        },
        text: "Create hold",
        type: "delete",
    }

    // TODO this is for vacasa hold and termination hold for non-employees
    // extract to own component when implementing new design
    const { unit } = unitInfo
    const unitContactName = `${unit.attributes?.localOperationsManager?.firstName} ${unit.attributes?.localOperationsManager?.lastName}`
    const unitEmailSlug = unit.attributes?.localOperationsManager?.email
    const unitPhoneSlug = formatPhone(
        unit.attributes?.localOperationsManager?.phone
    )

    const email = (
        <a key={"flyout-email"} href={`mailto:${unitEmailSlug}`}>
            {unitEmailSlug}
        </a>
    )
    const phone = unitPhoneSlug && (
        <a key={"flyout-phone"} href={`tel:${unitPhoneSlug}`}>
            {unitPhoneSlug}
        </a>
    )
    const contactUsFooter = (
        <p className={styles.contactUsFooter}>
            <div className={styles.title}>
                <FormattedMessage
                    id="Calendar.flyout.vacasaContact"
                    defaultMessage="Vacasa contact"
                />
            </div>
            <div className={styles.unitContactName}>{unitContactName}</div>
            <div className={styles.description}>
                <FormattedMessage
                    id="Calendar.flyout.contactUsFooter"
                    defaultMessage="If you have any questions about this hold, please reach out."
                />
            </div>
            {phone && (
                <div className={styles.contact}>
                    <div className={styles.contactIcon}>
                        <PhoneIcon />
                    </div>
                    {phone}
                </div>
            )}
            <div className={styles.contact}>
                <div className={styles.contactIcon}>
                    <MailIcon />
                </div>
                {email}
            </div>
        </p>
    )

    const editBtn = extendButtonDefaults(editButtonProps)
    const deleteBtn = extendButtonDefaults(deleteButtonProps)

    let presets: (ExtendButton | JSX.Element)[] = []
    if (!reservation) {
        presets = []
    } else if (
        reservation?.attributes.occupancyType !== ReservationType.VACASAHOLD &&
        reservation?.attributes.ownerHold?.holdType !== "termination hold" &&
        !reservationHasEnded(reservation?.attributes)
    ) {
        presets = [editBtn]
        if (!reservationIsInProgress(reservation)) {
            presets.push(deleteBtn)
        }
    } else if (
        reservation?.attributes.occupancyType === ReservationType.VACASAHOLD
    ) {
        presets = unit.attributes.status.guestworksApp.isGuestworksUnit
            ? []
            : [contactUsFooter]
    } else if (
        reservation?.attributes.ownerHold?.holdType === "termination hold"
    ) {
        presets = isEmployee
            ? [editBtn, deleteBtn]
            : unit.attributes.status.guestworksApp.isGuestworksUnit
            ? []
            : [contactUsFooter]
    }

    const flyoutFooter = (
        <div>
            <FlyOutActionPartial items={presets} showDivider={false} />
        </div>
    )

    if (
        reservation?.attributes.occupancyType !== ReservationType.RESERVATION &&
        presets.length
    ) {
        return flyoutFooter
    }

    return null
}

export { ViewReservationFooter }
