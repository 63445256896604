import { Owner } from "@vacasa/owner-api-models"
import { createContext, useContext } from "react"
import LoggingService from "services/logging/logging.service"

export type LoginInfo = {
    // legacy id
    userId: string | null
    idpId: string | null
    contactIds: string[]
    forwardTo: string | null
    loginType: string | null
    user: Owner | null
    users: Owner[]
    setContactIds: (contactIds: string[]) => void
    unitId: string | null
}

export const DEFAULT_LOGIN_INFO: LoginInfo = {
    userId: null,
    idpId: null,
    contactIds: [],
    forwardTo: null,
    loginType: null,
    user: null,
    users: [],
    unitId: null,
    setContactIds: () => {
        LoggingService.error({
            message: "LoginInfoContext setContactIds not implemented",
        })
    },
}

export const LoginInfoContext = createContext<LoginInfo>(DEFAULT_LOGIN_INFO)

export const useLoginInfo = () => useContext<LoginInfo>(LoginInfoContext)
