import React, {
    CSSProperties,
    FC,
    Fragment,
    PropsWithChildren,
    ReactNode,
} from "react"
import classnames from "classnames"
import SVGIcon from "../SVGIcon"
import * as Button from "../Buttons/BaseButton"
import { ExtendButton } from "utils/calendar/calendarUtil"
import styles from "./FlyOutActionPartial.module.scss"
import * as Loader from "../Loader/ButtonLoader/ButtonLoader"
import { ButtonType, MUIButton } from "../Buttons/MUIButton"
import { IntlShape, useIntl } from "react-intl"

const buttonContainerStyle: CSSProperties = {
    position: "relative",
    display: "inline-block",
}

const GenericLoader = (): JSX.Element => (
    <Loader.ButtonLoader
        indicator={"dot"}
        indicatorCount={3}
        animation={"blink"}
    />
)

// TODO: Validate if this method is really required as it the core logic is to pass the BaseButtonProps
// into each button component. The edit/edit cases appear to only be used once. Also adds each parmeters
// being passed through which are overwritten for the button type.
const setPresetValues = (
    item: ExtendButton,
    intl: IntlShape
): Button.BaseButtonProps & {
    icon?: JSX.Element
    text?: string
    wide?: boolean
} => {
    let buttonProps: Button.BaseButtonProps & {
        icon?: JSX.Element
        text?: string
        wide?: boolean
    } = {}
    switch (item.preset) {
        case "edit":
            buttonProps = {
                ...item,
                type: "submit",
                typeOf: "link",
                buttonType: "edit",
                wide: false,
                icon: <SVGIcon svg="icon-feather-edit-2" />,
                text: intl.formatMessage({
                    id: "CalendarPage.flyout.edit",
                    defaultMessage: "Edit",
                }),
                ariaLabel: "Click here to edit.",
            }
            break
        case "delete":
            buttonProps = {
                ...item,
                type: "submit",
                typeOf: "link",
                buttonType: "delete",
                wide: false,
                icon: <SVGIcon svg="icon-feather-trash-2" />,
                text: intl.formatMessage({
                    id: "CalendarPage.flyout.delete",
                    defaultMessage: "Delete",
                }),
                ariaLabel: "Click here to delete.",
            }
            break
        case "primary":
            buttonProps = {
                ...item,
                type: "submit",
                buttonType: "primary",
            }
            break
        case "secondary":
            buttonProps = {
                ...item,
                type: "submit",
                buttonType: "secondary",
            }
            break
        case "link":
            buttonProps = {
                ...item,
                type: "submit",
                typeOf: "link",
                buttonType: "link",
            }
            break
        case "link-alertness":
            buttonProps = {
                ...item,
                type: "submit",
                typeOf: "link",
                buttonType: "link-alertness",
            }
            break
        default:
            return item
    }
    return {
        ...buttonProps,
        wide: buttonProps.wide === undefined ? true : buttonProps.wide,
        loader: GenericLoader(),
    }
}

const renderButton = (
    item: Button.BaseButtonProps & {
        icon?: JSX.Element
        text?: string
        wide?: boolean
    }
): JSX.Element => {
    const { icon, text, wide, ...baseButtonProps } = item

    switch (item.buttonType) {
        case "primary":
            return (
                <Button.Primary {...baseButtonProps}>
                    {icon}
                    {text}
                </Button.Primary>
            )
        case "secondary":
            return (
                <Button.Secondary {...baseButtonProps}>
                    {icon}
                    {text}
                </Button.Secondary>
            )
        case "link":
            return (
                <Button.LinkSecondary {...baseButtonProps}>
                    {icon}
                    {text}
                </Button.LinkSecondary>
            )
        case "link-alertness":
            return (
                <Button.LinkAlertness {...baseButtonProps}>
                    {icon}
                    {text}
                </Button.LinkAlertness>
            )
        case "edit":
            return (
                <MUIButton
                    type={ButtonType.Default_SecondaryIcon}
                    icon={icon}
                    text={text}
                    loading={false}
                    disabled={false}
                    containerClass={styles.editButton}
                    onClick={item.onClick}
                />
            )
        case "delete":
            return (
                <MUIButton
                    type={ButtonType.Default_SecondaryIcon}
                    icon={icon}
                    text={text}
                    containerClass={styles.deleteButton}
                    onClick={item.onClick}
                />
            )
        default:
            return <></>
    }
}

const renderItem = (
    item: FlyoutActionItem,
    index: number,
    intl: IntlShape
): ReactNode => {
    const btnClass = "flyout-button"
    if (React.isValidElement(item)) {
        return (
            <div className={btnClass} key={index}>
                {item}
            </div>
        )
    }

    const buttonProps = setPresetValues(item as ExtendButton, intl)
    const button = renderButton(buttonProps)

    if (buttonProps.wide) {
        return (
            <div className={btnClass} key={index}>
                {button}
            </div>
        )
    }

    return (
        <span style={buttonContainerStyle} key={index}>
            {button}
        </span>
    )
}

const renderItemList = (
    items: FlyoutActionItem[],
    intl: IntlShape
): React.ReactNode => {
    if (items) {
        return items.map((item, index) => {
            return (
                <Fragment key={index}>{renderItem(item, index, intl)}</Fragment>
            )
        })
    }
}

interface Props {
    items: FlyoutActionItem[]
    showDivider: boolean
}

type FlyoutActionItem = JSX.Element | ExtendButton

// TODO: Investigate if this component is really needed. Currently the main logic is to render buttons with a wrapper or JSX.Element
// but this could be done in another component as a lot of the logic located above is used once only.
const FlyOutActionPartial: FC<PropsWithChildren<Props>> = (
    props
): JSX.Element => {
    const { items, children, showDivider } = props
    const intl = useIntl()
    return (
        <Fragment>
            {showDivider && (
                <div className={"border-top button__container--full-width"} />
            )}
            <div className={classnames("flyout")}>
                {renderItemList(items, intl)}
                {children}
            </div>
        </Fragment>
    )
}

export default FlyOutActionPartial
