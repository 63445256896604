import { AxiosError } from "axios"
import { useQuery, UseQueryResult } from "react-query"
import { useContactId } from "hooks/user"
import { fetchTaxForms, TaxForm } from "./fetchTaxForms"

export const useTaxForms = (): UseQueryResult<TaxForm[], AxiosError> => {
    const contactId = useContactId()

    return useQuery(
        ["tax-forms", contactId],
        ({ signal }) =>
            fetchTaxForms({ contactId: contactId as string, signal }),
        { enabled: !!contactId }
    )
}
