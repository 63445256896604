import React, { memo } from "react"

export interface StaticListProps {
    isEmbeddedList?: boolean
    items: Array<JSX.Element | null>
}

const StaticList: React.FC<StaticListProps> = ({ isEmbeddedList, items }) => {
    const inlineStyle = isEmbeddedList ? { padding: "0" } : undefined
    const classes = ["staticList"]
    return (
        <ul className={classes.join(", ")} style={inlineStyle}>
            {items.map((item, index) => (
                <React.Fragment key={index}>{item}</React.Fragment>
            ))}
        </ul>
    )
}

export default memo(StaticList)
