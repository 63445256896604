import classnames from "classnames"
import React, { PropsWithChildren, useState } from "react"
import { ReactComponent as IconClose } from "../../../assets/icon-close.svg"
import styles from "./modal.module.scss"

const Modal: React.FC<
    PropsWithChildren<{
        onCloseHandler: () => void
        closeButtonStyleOverride?: string
        modalContentStyleOverride?: string
        modalPanelStyleOverride?: string
        modalOverlayStyleOverride?: string
    }>
> = ({
    onCloseHandler,
    closeButtonStyleOverride,
    modalContentStyleOverride,
    modalPanelStyleOverride,
    modalOverlayStyleOverride,
    children,
}): JSX.Element => {
    const [isOpen, setIsOpen] = useState(true)

    const handleCloseModal = () => {
        setIsOpen(false)
        onCloseHandler()
    }

    if (!isOpen) return <></>

    return (
        <>
            <div
                className={classnames(
                    styles.modal_overlay,
                    modalOverlayStyleOverride
                )}
                data-testid="modal-overlay"
            />
            <div
                className={classnames(
                    styles.modal_content,
                    modalContentStyleOverride
                )}
                data-testid="modal-content"
            >
                <div
                    className={classnames(
                        styles.panel,
                        modalPanelStyleOverride
                    )}
                >
                    {children}
                    <div
                        data-testid="modal-close-button"
                        onClick={handleCloseModal}
                        className={classnames(
                            styles.modalCloseButton,
                            "modal__close"
                        )}
                    >
                        <IconClose
                            className={classnames(
                                styles.icon_close,
                                closeButtonStyleOverride
                            )}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Modal
