import EmployeeBox from "EmployeeBox"
import classNames from "classnames"
import { ReferralModal } from "lib/components/Modal/ReferralModal/ReferralModal"
import TopBar from "lib/components/TopBar/TopBar"
import { WebChat } from "lib/components/WebChat"
import { observer } from "mobx-react"
import { FC, useCallback, useEffect } from "react"
import { Outlet, useLocation } from "react-router-dom"
import loggingService from "services/logging/logging.service"
import { trackKeyboardPrint } from "services/segment/keyboard/keyboardTracking"
import {
    BrowserNotSupportedBanner,
    isOwnerApp,
} from "utils/browser/browserUtil"
import Navigation from "./navigation"
import { useUser } from "hooks/user"
import { useWebChatStatus } from "hooks/web-chat"
import { useCurrentUnit } from "hooks/units"
import { Paperless1099Modal } from "lib/components/Paperless1099Modal"

const Layout: FC<{
    setShowReferralModal: (shouldShowReferralModal: boolean) => void
    showReferralModal: boolean
}> = observer(({ setShowReferralModal, showReferralModal }) => {
    const location = useLocation()
    const { user } = useUser()
    const { unitId } = useCurrentUnit()
    const userAgent = window.navigator.userAgent
    const isUsingOwnerApp = isOwnerApp()
    const webChatStatusQuery = useWebChatStatus()

    const handleKeyDown = useCallback(
        () => (e: React.KeyboardEvent) => {
            const cmdKey = e.metaKey
            if ((e.ctrlKey && e.key === "p") || (cmdKey && e.key === "p")) {
                trackKeyboardPrint()
            }
        },
        []
    )

    useEffect(() => {
        loggingService.log({
            message: `Navigate to ${location.pathname}${location.search}${location.hash}`,
        })
    }, [location])

    return (
        <>
            <div
                className={
                    userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)
                        ? "point-body"
                        : "default-body"
                }
            >
                {!isUsingOwnerApp && (
                    <TopBar showReferralModal={setShowReferralModal} />
                )}
                {user !== null && (
                    <Navigation showReferralModal={setShowReferralModal} />
                )}
                {showReferralModal && (
                    <ReferralModal showReferralModal={setShowReferralModal} />
                )}
                <div
                    className={classNames("App", {
                        isOwnerApp: isUsingOwnerApp,
                    })}
                    style={{
                        paddingLeft: user === null ? "0" : undefined,
                    }}
                    onKeyDown={handleKeyDown}
                    tabIndex={0}
                >
                    <Outlet />
                    <BrowserNotSupportedBanner />
                    <EmployeeBox />
                    {webChatStatusQuery.isSuccess && user && (
                        <WebChat
                            status={webChatStatusQuery.data}
                            user={{
                                contactId: user.contactId,
                                email: user.email,
                                name: `${user.firstName} ${user.lastName}`,
                            }}
                            unitId={unitId}
                        />
                    )}
                    {user && unitId && <Paperless1099Modal />}
                </div>
            </div>
        </>
    )
})

export default Layout
