import classNames from "classnames"
import { FC, PropsWithChildren } from "react"
import { FormattedMessage } from "react-intl"
import { Icon, IconName } from "../Icon/Icon"
import styles from "./Badge.module.scss"
import { OwnerHoldType, VacasaHoldType } from "@vacasa/owner-api-models"

type Preset =
    | "contract-active"
    | "contract-inactive"
    | "contract-maintenance"
    | "contract-onboarding"
    | "contract-terminated"
    | "ticket-cancelled"
    | "ticket-closed"
    | "ticket-submitted"
    | "ticket-in-progress"
    | "unit-type-guestworks"
    | "default"

export type BadgeProps = PropsWithChildren<{
    backgroundColor?: string
    borderColor?: string
    className?: string
    icon?: IconName
    textColor?: string
    textSize?: "normal" | "small"
    preset?: Preset
}>

const Badge: FC<BadgeProps> = ({
    backgroundColor,
    borderColor,
    children,
    className,
    icon,
    textColor,
    textSize = "normal",
    preset = "default",
}) => {
    // If any custom settings are being used, ignore the preset
    const customSettings = !!backgroundColor || !!borderColor || !!textColor

    return (
        <span
            style={{
                backgroundColor,
                borderColor,
                color: textColor,
            }}
            className={classNames(
                styles.badge,
                className,
                {
                    // Text Size
                    [styles.textNormal as string]: textSize === "normal",
                    [styles.textSmall as string]: textSize === "small",
                },
                !customSettings && getBadgePresetClassName(preset)
            )}
        >
            {icon && <Icon className={styles.icon} name={icon} />}
            {children}
        </span>
    )
}
export default Badge

/**
 * Get Presets Badge ClassName
 * @param preset
 * @returns string | undefined
 */
const getBadgePresetClassName = (preset?: Preset): string | undefined => {
    switch (preset) {
        case "contract-active": {
            return styles["preset-contract-active"]
        }
        case "contract-inactive": {
            return styles["preset-contract-inactive"]
        }
        case "contract-maintenance": {
            return styles["preset-contract-maintenance"]
        }
        case "contract-onboarding": {
            return styles["preset-contract-onboarding"]
        }
        case "contract-terminated": {
            return styles["preset-contract-terminated"]
        }
        case "ticket-cancelled": {
            return styles["preset-ticket-cancelled"]
        }
        case "ticket-closed": {
            return styles["preset-ticket-closed"]
        }
        case "ticket-in-progress": {
            return styles["preset-ticket-in-progress"]
        }
        case "ticket-submitted": {
            return styles["preset-ticket-submitted"]
        }
        case "unit-type-guestworks": {
            return styles["preset-unit-type-guestworks"]
        }
        case "default":
            return styles["preset-default"]
    }
}

// Predefined set of badges

// Owner Hold Badges
export type OwnerHoldBadgeProps = PropsWithChildren<{
    type: OwnerHoldType
}>
export const OwnerHoldBadge: FC<OwnerHoldBadgeProps> = ({ type }) => {
    switch (type) {
        case "complimentary stay":
            return (
                <Badge preset="default" icon="VacasaComplimentaryStay">
                    <FormattedMessage
                        id="Badges.ownerHold.complimentaryStay"
                        defaultMessage="comp"
                    />
                </Badge>
            )
        case "home for sale":
            return (
                <Badge preset="default" icon="VacasaHomeForSale">
                    <FormattedMessage
                        id="Badges.ownerHold.homeForSale"
                        defaultMessage="for sale"
                    />
                </Badge>
            )
        case "other":
            return (
                <Badge preset="default" icon="VacasaOther">
                    <FormattedMessage
                        id="Badges.ownerHold.other"
                        defaultMessage="other"
                    />
                </Badge>
            )
        case "property care":
            return (
                <Badge preset="default" icon="VacasaPropertyCare">
                    <FormattedMessage
                        id="Badges.ownerHold.propertyCare"
                        defaultMessage="property"
                    />
                </Badge>
            )
        case "seasonal hold":
            return (
                <Badge preset="default" icon="VacasaSeasonalHold">
                    <FormattedMessage
                        id="Badges.ownerHold.seasonalHold"
                        defaultMessage="seasonal"
                    />
                </Badge>
            )
        case "owner stay":
            return (
                <Badge preset="default" icon="VacasaOwnerHold">
                    <FormattedMessage
                        id="Badges.ownerHold.ownerStay"
                        defaultMessage="owner"
                    />
                </Badge>
            )
        case "termination hold":
            return (
                <Badge preset="default" icon="VacasaTermination">
                    <FormattedMessage
                        id="Badges.ownerHold.terminationHold"
                        defaultMessage="terminate"
                    />
                </Badge>
            )
        default:
            return null
    }
}

// Vacasa Hold Badges
export type VacasaHoldBadgeProps = PropsWithChildren<{
    type: VacasaHoldType
}>
export const VacasaHoldBadge: FC<VacasaHoldBadgeProps> = ({ type }) => {
    switch (type) {
        case "housekeeping":
            return (
                <Badge preset="default" icon="VacasaHousekeeping">
                    <FormattedMessage
                        id="Badges.vacasaHold.housekeeping"
                        defaultMessage="housekeeping"
                    />
                </Badge>
            )
        case "maintenance":
            return (
                <Badge preset="default" icon="VacasaMaintenance">
                    <FormattedMessage
                        id="Badges.vacasaHold.maintenance"
                        defaultMessage="maintenance"
                    />
                </Badge>
            )
        case "other":
            return (
                <Badge preset="default" icon="VacasaOther">
                    <FormattedMessage
                        id="Badges.vacasaHold.other"
                        defaultMessage="other"
                    />
                </Badge>
            )
        case "out of order":
            return (
                <Badge preset="default" icon="VacasaOutOfOrder">
                    <FormattedMessage
                        id="Badges.vacasaHold.outOfOrder"
                        defaultMessage="out of order"
                    />
                </Badge>
            )
        case "photography":
            return (
                <Badge preset="default" icon="VacasaPhotography">
                    <FormattedMessage
                        id="Badges.vacasaHold.photography"
                        defaultMessage="photography"
                    />
                </Badge>
            )
        default:
            return null
    }
}

// Ticket Statuses Badges
export type TicketStatusProps = PropsWithChildren<{
    status: "submitted" | "in-progress" | "closed" | "cancelled"
}>
export const TicketStatusBadge: FC<TicketStatusProps> = ({ status }) => {
    switch (status) {
        case "cancelled":
            return (
                <Badge preset="ticket-cancelled">
                    <FormattedMessage
                        id="Badges.ticket.cancelled"
                        defaultMessage="cancelled"
                    />
                </Badge>
            )
        case "closed":
            return (
                <Badge preset="ticket-closed">
                    <FormattedMessage
                        id="Badges.ticket.closed"
                        defaultMessage="closed"
                    />
                </Badge>
            )
        case "in-progress":
            return (
                <Badge preset="ticket-in-progress">
                    <FormattedMessage
                        id="Badges.ticket.inProgress"
                        defaultMessage="in progress"
                    />
                </Badge>
            )
        case "submitted":
            return (
                <Badge preset="ticket-submitted">
                    <FormattedMessage
                        id="Badges.ticket.submitted"
                        defaultMessage="submitted"
                    />
                </Badge>
            )
        default:
            return null
    }
}

// Reservation Badges
export type ReservationBadgeProps = PropsWithChildren<{
    type: "inherited"
}>
export const ReservationBadge: FC<ReservationBadgeProps> = ({ type }) => {
    switch (type) {
        case "inherited":
            return (
                <Badge preset="default" icon="Inherited">
                    <FormattedMessage
                        id="Badges.reservation.inherited"
                        defaultMessage="inherited"
                    />
                </Badge>
            )
        default:
            return null
    }
}

// Contract Status Badges
export type ContractStatusBadgeProps = PropsWithChildren<{
    status: "active" | "inactive" | "maintenance" | "onboarding" | "terminated"
}>
export const ContractStatusBadge: FC<ContractStatusBadgeProps> = ({
    status,
}) => {
    switch (status) {
        case "active":
            return (
                <Badge preset="contract-active">
                    <FormattedMessage
                        id="Badges.contract.active"
                        defaultMessage="active"
                    />
                </Badge>
            )
        case "inactive":
            return (
                <Badge preset="contract-inactive">
                    <FormattedMessage
                        id="Badges.contract.inactive"
                        defaultMessage="inactive"
                    />
                </Badge>
            )
        case "maintenance":
            return (
                <Badge preset="contract-maintenance">
                    <FormattedMessage
                        id="Badges.contract.maintenance"
                        defaultMessage="maintenance"
                    />
                </Badge>
            )
        case "onboarding":
            return (
                <Badge preset="contract-onboarding">
                    <FormattedMessage
                        id="Badges.contract.onboarding"
                        defaultMessage="onboarding"
                    />
                </Badge>
            )
        case "terminated":
            return (
                <Badge preset="contract-terminated">
                    <FormattedMessage
                        id="Badges.contract.terminated"
                        defaultMessage="terminated"
                    />
                </Badge>
            )
        default:
            return null
    }
}

// Unit Type Badges
export type UnitTypeBadgeProps = PropsWithChildren<{
    type: "guestworks"
}>
export const UnitTypeBadge: FC<UnitTypeBadgeProps> = ({ type }) => {
    switch (type) {
        case "guestworks":
            return (
                <Badge preset="unit-type-guestworks">
                    <FormattedMessage
                        id="Badges.unitType.guestworks"
                        defaultMessage="Guestworks"
                    />
                </Badge>
            )
    }
}
