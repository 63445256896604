import { useUnits } from "hooks/units"
import {
    hasLiveUnits,
    shouldRedirectToOnboarding,
} from "../../utils/onboarding"

export interface OnboardingResponse {
    hasUnitsOnboarding: boolean
    isLoading: boolean
    isOnboardingAppUser: boolean
    redirectToOnboarding: boolean
}

export const useOnboardingStatus = (): OnboardingResponse => {
    const units = useUnits()

    const isLoading = units.isLoading
    if (isLoading || !units.isSuccess) {
        return {
            hasUnitsOnboarding: false,
            isLoading,
            isOnboardingAppUser: false,
            redirectToOnboarding: false,
        }
    }
    const onboardingUnits = units.data.filter(
        unit => unit.attributes.status.ownerOnboardingApp.isOnboarding
    )

    const hasOnboardingAppUnits = onboardingUnits.some(
        unit => unit.attributes.status.ownerOnboardingApp.showOnboardingWizard
    )

    return {
        hasUnitsOnboarding: !!onboardingUnits.length,
        isLoading: false,
        isOnboardingAppUser: hasOnboardingAppUnits,
        redirectToOnboarding: shouldRedirectToOnboarding(
            hasLiveUnits(units.data),
            hasOnboardingAppUnits
        ),
    }
}
