import React from "react"

import ToggleRow from "./ToggleRow"
import IncrementerRow from "./IncrementerRow"
import DropDownRow from "./Dropdown/DropdownRow/DropdownRow"
import { Generic as DropdownOption } from "../../../models/Generic"
import styles from "../../../sass/onboarding.module.scss"

export enum RowType {
    Toggle,
    Drowdown,
    Incrementer,
}

interface SharedRowWrapperProps {
    title: React.ReactNode
    description?: string | JSX.Element
    tooltipBody?: React.ReactNode
    tooltipTitle?: string
    lastRow?: boolean
}

interface DropdownRowProps extends SharedRowWrapperProps {
    type: RowType.Drowdown
    selectedValue: DropdownOption | null | undefined
    options: DropdownOption[]
    onChangeHandler: (value: DropdownOption | null | undefined) => void
}

interface ToggleRowProps extends SharedRowWrapperProps {
    type: RowType.Toggle
    selectedValue: boolean | null | undefined
    onChangeHandler: (value: boolean | null | undefined) => void
}

interface IncrementerRowProps extends SharedRowWrapperProps {
    type: RowType.Incrementer
    selectedValue: number | null | undefined
    minValue?: number
    maxValue?: number
    onChangeHandler: (value: number | null | undefined) => void
}

type RowWrapperProps = DropdownRowProps | ToggleRowProps | IncrementerRowProps

const RowWrapper: React.FunctionComponent<RowWrapperProps> = (
    props: RowWrapperProps
): JSX.Element => {
    let component: JSX.Element
    const { title, description, lastRow, tooltipBody, tooltipTitle } = props

    switch (props.type) {
        case RowType.Toggle:
            component = (
                <ToggleRow
                    title={title}
                    description={description}
                    tooltipBody={tooltipBody}
                    tooltipTitle={tooltipTitle}
                    selectedValue={props.selectedValue}
                    onChangeHandler={props.onChangeHandler}
                />
            )
            break
        case RowType.Drowdown:
            component = (
                <DropDownRow
                    title={title}
                    description={description}
                    tooltipBody={tooltipBody}
                    tooltipTitle={tooltipTitle}
                    selectedOption={props.selectedValue}
                    options={props.options}
                    onChangeHandler={props.onChangeHandler}
                />
            )
            break
        case RowType.Incrementer:
            component = (
                <IncrementerRow
                    label={title}
                    description={description}
                    tooltipBody={tooltipBody}
                    tooltipTitle={tooltipTitle}
                    value={props.selectedValue}
                    minValue={props.minValue}
                    maxValue={props.maxValue}
                    onChangeHandler={props.onChangeHandler}
                />
            )
            break
    }
    return (
        <div
            className={`${styles.onboarding_row} ${
                lastRow && lastRow ? styles.last_row : ""
            }`}
        >
            {component}
        </div>
    )
}

export default RowWrapper
