import { ChangeEvent, useCallback, useState } from "react"
import styles from "./settings.module.scss"

import getSymbolFromCurrency from "currency-symbol-map"
import { JSONAPIResource, Unit } from "@vacasa/owner-api-models"
import { useUnitReserveBalanceMutation } from "hooks/finance"
import { useContactId } from "hooks/user"
import { Input } from "lib/components/Input/Input"
import { useToastNotification } from "lib/components/ToastNotification"
import UnitPicture from "lib/components/Unit/UnitPicture"
import { observer } from "mobx-react"
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl"
import LoggingService from "services/logging/logging.service"
import { trackUpdateReserveBalanceButtonClicked } from "services/segment/statement/statementTracking"
import { isUnauthorizedOrCancelledRequest } from "utils/error/error"
import * as Button from "../../lib/components/Buttons/BaseButton"
import * as Loader from "../../lib/components/Loader/ButtonLoader/ButtonLoader"
import {
    getUnitReserveMinRequired,
    hasUnitReserveBalanceChanged,
    isUnitReserveBalanceDisabled,
} from "utils/unit"
import { useUser } from "hooks/user"

interface IndividualUnitProps {
    unit: JSONAPIResource<Unit>
    currency: string
    title: string
    address: string
}

const IndividualUnits = observer((props: IndividualUnitProps) => {
    const { unit, currency, title, address } = props
    const contactId = useContactId() ?? ""
    const unitReserveBalanceMutation = useUnitReserveBalanceMutation()
    const { addToastNotification } = useToastNotification()

    const intl = useIntl()
    const symbol = getSymbolFromCurrency(currency || "USD")

    const { user, isEmployee } = useUser()
    const [reserveBalance, setReserveBalance] = useState(
        unit?.attributes.reserveBalance ?? 0
    )

    const minReserve = getUnitReserveMinRequired(unit, user)
    const isReserveDisabled = !isEmployee && isUnitReserveBalanceDisabled(unit)
    const reserveTooLow = !!minReserve && reserveBalance < minReserve
    const isMinReserveError = !isEmployee && !isReserveDisabled && reserveTooLow
    const isUpdateReserveDisabled =
        (!isEmployee && reserveTooLow) ||
        isNaN(Number(reserveBalance)) ||
        !hasUnitReserveBalanceChanged(reserveBalance, unit)

    const handleChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setReserveBalance(Number(e.target.value))
        },
        [setReserveBalance]
    )

    const onUpdateClick = () => {
        if (!unit.id) return
        LoggingService.log({
            message: `Updating reserve balance - unitId: ${unit.id}, reserveBalance: ${reserveBalance}`,
        })
        unitReserveBalanceMutation.mutate(
            {
                reserveBalance,
                unitId: unit.id,
                contactId,
            },
            {
                onError: (error, payload) => {
                    if (!isUnauthorizedOrCancelledRequest(error)) {
                        LoggingService.error({
                            message: "Updating reserve balance failed",
                            error,
                            tags: {
                                contactId: payload.contactId as string,
                                unitID: payload.unitId as string,
                            },
                        })
                    }
                    addToastNotification({
                        content: (
                            <FormattedMessage
                                id="Settings.reserveUpdatedFailedNotification"
                                defaultMessage="Unable to update reserve balance, please try again later"
                            />
                        ),
                    })
                },
                onSuccess: (_, payload) => {
                    LoggingService.log({
                        message: `Updated reserve balance`,
                    })
                    unit.attributes.reserveBalance = payload.reserveBalance
                    addToastNotification({
                        content: (
                            <FormattedMessage
                                id="Settings.reserveUpdatedNotification"
                                defaultMessage="Reserve balance updated"
                            />
                        ),
                    })
                },
            }
        )
        trackUpdateReserveBalanceButtonClicked()
    }

    return (
        <div className={styles["reserve-balance-card"]}>
            <div className={styles["unit-details"]}>
                <UnitPicture unit={unit} className={styles["unit-picture"]} />
                <div className={styles["details-container"]}>
                    <div className={styles["unit-title-bold"]}>{title}</div>
                    <div className={styles["text-overflow"]}>{address}</div>
                </div>
            </div>
            <div className={styles["input-group"]}>
                <span className={styles["input-section"]}>
                    <span className={styles["symbol-section"]}>{symbol}</span>
                    <Input
                        className={styles.inputOverride}
                        defaultValue={unit?.attributes.reserveBalance}
                        disabled={isReserveDisabled}
                        min="0"
                        type="number"
                        value={reserveBalance}
                        onChange={handleChange}
                        data-testid="reserveBalanceInput"
                        error={isMinReserveError}
                        hint={
                            isMinReserveError && (
                                <FormattedMessage
                                    id="Settings.reserveMinimumRequired"
                                    defaultMessage="This unit requires at least {amount} reserve"
                                    values={{
                                        amount: (
                                            <FormattedNumber
                                                value={minReserve ?? 500}
                                                currency={currency || "USD"}
                                                style="currency" // eslint-disable-line
                                                minimumFractionDigits={0}
                                                maximumFractionDigits={0}
                                            />
                                        ),
                                    }}
                                />
                            )
                        }
                    />
                </span>
                <div>
                    <Button.Utility
                        className={styles["update-button"]}
                        typeOf="button"
                        onClick={onUpdateClick}
                        disabled={isUpdateReserveDisabled}
                        isLoading={unitReserveBalanceMutation.isLoading}
                        ariaLabel={intl.formatMessage({
                            id: "Settings.reserveUpdateAria",
                            defaultMessage:
                                "Click this button to update your reserve balance.",
                        })}
                        loader={
                            <Loader.ButtonLoader
                                indicator={"dot"}
                                indicatorCount={3}
                                animation={"blink"}
                            />
                        }
                    >
                        <FormattedMessage
                            id="Settings.updateAction"
                            defaultMessage="Update"
                        />
                    </Button.Utility>
                </div>
            </div>
        </div>
    )
})

export default IndividualUnits
