const taxes = {
    title: "Adók",

    fillOutW9: "Töltsd ki a W-9 adózási formanyomtatványt",
    submitW9: "W-9 adózási formanyomtatvány benyújtása online",
    newTaxFormsWillShow:
        "Itt jelennek meg az új adózási formanyomtatványok, amint elérhetővé válnak.",
    goPaperless: "Papírmentes adózás",
    paperlessExplanation:
        "A papírmentes adózás lehetővé teszi az adóbevallási formanyomtatványok kizárólag elektronikus úton történő fogadását. Az adóbevallási formanyomtatványokról nem küldünk papíralapú példányokat.",
    electronicForms:
        "Adóbevallási formanyomtatványok fogadása kizárólag elektronikus úton",
    getForms: "Adóbevallási formanyomtatványok beszerzése",
    irsFormsElectronically: "IRS-formanyomtatványok fogadása elektronikus úton",
    mustSubmit:
        "We must have one of the following taxpayer forms on file before we can provide your tax preparation documents. Please complete only the form that best describes your taxpayer status with the IRS. If your taxpayer information has changed, please submit a new form.",

    paperlessNotification:
        "Az adózási formanyomtatványok küldése elektronikus úton történik.",
    paperFormNotification: "Adózási formanyomtatványok küldése:",

    noForms: "Még nincsenek formanyomtatványok.",

    ifExpecting: "Az alábbi formanyomtatvány",
    willBeAvailable: "a következő dátumon lesz elérhető:",
    form1099title: "1099-MISC adózási formanyomtatvány",
    form1042title: "1042-S adózási formanyomtatvány",
    jan31: "január 31-ig",
    march15: "március 15-ig",

    yourInformation: "Provide your taxpayer information",
    whoFor: "Kinek?",
    howToSubmit: "Benyújtás",
    w9title: "W-9 adózási formanyomtatvány",
    w9whoFor:
        "Az Egyesült Államok állampolgára vagy itt tartózkodó külföldi állampolgár, aki az alábbi adóazonosító számok egyikének típusával rendelkezik: SSN, EIN vagy ITIN. A tartózkodási engedéllyel nem rendelkező külföldi állampolgárok nem tölthetik ki ezt a formanyomtatványt.",
    w8ECItitle: "W-8ECI adózási formanyomtatvány",
    w8ECIwhoFor:
        "Külföldi állampolgár, tartózkodási engedéllyel nem rendelkező külföldi állampolgár vagy olyan gazdálkodó egység, aki/amely jelenleg SSN, EIN vagy ITIN típusú adóazonosító számmal rendelkezik, és a jövedelmet az IRS-nek benyújtandó éves adóbevallás révén jelenti be.",
    w8BENtitle: "W-8BEN adózási formanyomtatvány",
    w8BENwhoFor:
        "Külföldi állampolgár vagy tartózkodási engedéllyel nem rendelkező külföldi állampolgár, aki nem nyújt be éves adóbevallást az IRS-nek, vagy aki az Egyesült Államok és az illetőség szerinti állam között létrejött, a jövedelemadóra vonatkozó szerződés előnyeit szeretné kihasználni. Mivel az adózással kapcsolatos szerződések nem vonatkoznak a bérbeadásból származó bevételre, a W‐8BEN formanyomtatványt benyújtó személyek esetében 30%-os külföldi forrásadó érvényes, amelyet a bruttó jövedelem alapján számolnak ki.",
    w8BENEtitle: "W-8BEN-E adózási formanyomtatvány",
    w8BENEwhoFor:
        "Jövedelem külföldi kedvezményezettje, aki nem magánszemély, és nem nyújt be éves adóbevallást az IRS-nek. A formanyomtatvány III. részében a jövedelemadóra vonatkozó szerződés előnyei is érvényesíthetők. Mivel az adózással kapcsolatos szerződések nem vonatkoznak a bérbeadásból származó bevételre, a W-8BEN-E formanyomtatványt benyújtó kedvezményezettek esetében 30%-os külföldi forrásadó érvényes, amelyet a bruttó jövedelem alapján számolnak ki.",

    learnMore: "További információk",
    mailForm: "Formanyomtatvány kitöltése és elküldése e-mailben:",

    dwnld: "Letöltés",
    download: "Bérleti adatok letöltése",
    preparingNotification: "Adatok előkészítése letöltésre",
    rentalData: "Bérleti adatok",
    noDownloads: "Még nincsenek letöltések.",
    description: "Az egyes adóévekkel kapcsolatos adatok a következő év",
    january15: "január 15-ig",
    description2:
        "lesznek elérhetőek. Amennyiben egy vagy több személlyel közösen társtulajdonos vagy, a rendszer mindenki számára különálló letölthető dokumentumot hoz létre, így helyes információkat tüntetsz fel a bevallásodon.",
    instructions:
        "Az .xls kiterjesztésű fájlokat a leggyakoribb táblázatkezelő alkalmazásokkal megnyithatod, ideértve a következőket: Excel, Numbers és Google Táblázatok.",
    makeChanges: "Want to make changes?",
    w9Support:
        "To make edits to your W-9 details please reach out to the {{HOME_OWNER_SUPPORT}} at {{SUPPORT_NUMBER}}, {{SUPPORT_EMAIL}}",
    taxModal: {
        actionRequired: "Action Required!",
        title: "Enter Taxpayer Info",
        description:
            "We need your taxpayer information on file so we can provide you with accurate tax preparation documents for last year.",
        primaryButton: "Fill it Out",
        secondardButton: "Maybe Later",
    },
    paperless1099Modal: {
        title: "Paperless 1099",
        description:
            "As the new year approaches, be prepared for tax season by receiving your Vacasa 1099 digitally. We'll email you when it's ready.",
        goPaperless: "Go Paperless",
        skipForNow: "Skip for Now",
    },
    w9: {
        taxFormSelect: {
            info1: "Please provide your taxpayer information so we can provide your tax preparation documents. Provide information that best describes your taxpayer status with the IRS.",
            info2: "The name/entity that you provide should be the same name/entity that is listed on your contract with Vacasa.",
        },
        taxClassifications: {
            INP: "Individual/sole proprietor or single-member LLC",
            LLC: "Limited liability company",
            CCO: "C Corporation",
            SCO: "S Corporation",
            PRT: "Partnership",
            TRE: "Trust/estate",
            OTH: "Other",
        },
        llcTaxClassfications: {
            C: "C Corporation",
            S: "S Corporation",
            P: "Partnership",
        },
        taxIdTypes: {
            SSN: "SSN (Social Security Number)",
            EIN: "EIN (Employer Identification Number)",
        },
        forms: {
            agreeAndSign: {
                title: "Agree & Submit",
                termsOfAgreement: "Terms Of Agreement",
                terms: "Under penalties of perjury, I certify that:\n\n1. The number shown on this form is my correct taxpayer identification number (or I am waiting for a number to be issued to me); and \n\n2. I am not subject to backup withholding because: (a) I am exempt from backup withholding, or (b) I have not been notified by the Internal Revenue Service (IRS) that I am subject to backup withholding as a result of a failure to report all interest or dividends, or (c) the IRS has notified me that I am no longer subject to backup withholding; and \n\n3. I am a U.S. citizen or other U.S. person (defined below); and \n\n4. The FATCA code(s) entered on this form (if any) indicating that I am exempt from FATCA reporting is correct. Certification instructions. You must cross out item 2 above if you have been notified by the IRS that you are currently subject to backup withholding because you have failed to report all interest and dividends on your tax return. For real estate transactions, item 2 does not apply. For mortgage interest paid, acquisition or abandonment of secured property, cancellation of debt, contributions to an individual retirement arrangement (IRA), and generally, payments other than interest and dividends, you are not required to sign the certification, but you must provide your correct TIN. See the instructions for Part II, later.",
                iAgree: "I agree to these terms",
                signature: "Signature",
                signaturePlaceholder: "Type fullname",
                signatureHelperText:
                    "By signing this document with an electronic signature, I agree that such signature will be as valid as handwritten signatures to the extent allowed by local law.",
                signedOn: "Signed On",
                validation: {
                    agreeToTermsRequired:
                        "Please agree to our terms of agreement",
                    signatureRequired: "Please enter your signature",
                    signatureMaxLength: "Signature cannot exceed 50 letters",
                },
            },
            taxInfo: {
                validation: {
                    entityTypeRequired: "Entity type is required",
                    entityTypeEmpty: "Entity type must be empty",
                    entityNameRequired: "Entity name is required",
                    taxIDTypeRequired: "Tax ID type is required",
                    taxIDTypeValid: "Tax ID type must be either SSN or EIN",
                    taxIDTypeEIN: "Tax ID type must be EIN",
                    taxClassificationRequired:
                        "Please select a tax classification",
                    taxClassificationValid: "Tax classification invalid",
                    ssnRequired: "SSN is required",
                    ssnLength: "SSN must be 9 digit in XXX-XX-XXXX format",
                    ssnInvalid: "SSN must be 9 digit in XXX-XX-XXXX format",
                    ssnAllZero: "SSN cannot be all 0",
                    ssnEmpty: "SSN must be empty",
                    einRequired: "EIN is required",
                    einLength: "EIN must be 9 digit in XX-XXXXXXX format",
                    einInvalid: "EIN must be 9 digit in XX-XXXXXXX format",
                    einAllZero: "EIN cannot be all 0",
                    einEmpty: "EIN must be empty",
                    llcTypeRequired: "LLC type required",
                    llcTypeValid: "LLC type must be either C, S, or P",
                    llcTypeEmpty: "LLC type must be empty",
                },
            },
            mailingAddress: {
                label: {
                    address: "Address",
                    city: "City",
                    stateOrProvince: "State/Province",
                    zipCode: "Zip/Postal Code",
                },
                placeholder: {
                    addressLine1: "Address Line 1",
                    addressLine2: "Address Line 2",
                    city: "City",
                    stateProvince: "State/Province",
                    zipCode: "5-digit zipcode",
                },
                validation: {
                    addressRequired: "Address is required",
                    cityRequired: "Please enter city",
                    postalCodeRequired: "Postal code is required",
                    provinceRequired: "State/Province is required",
                    postalCodeMaxLength: "Postal code must be 5 digit length",
                    postalCodeAllZero: "Postal code cannot be all 0",
                },
            },
        },
    },
}

export default taxes
