import { IconClipboardText, IconSprayBottle2 } from "assets"
import classNames from "classnames"
import { format, parseISO } from "date-fns"
import { useHousekeeping } from "hooks/housekeeping"
import { FC, useEffect, useState } from "react"
import { Activity } from "react-feather"
import { useIntl } from "react-intl"
import { BaseWidget, EmptyState } from "./BaseWidget"
import styles from "./HomeActivity.module.scss"
import { WidgetError } from "./WidgetError"

export const HomeActivity: FC<Props> = ({ contactId, unitId }) => {
    const intl = useIntl()

    const [queryKey, setQueryKey] = useState("")

    const housekeeping = useHousekeeping(
        {
            contactId,
            unitId,
        },
        { queryKey }
    )

    // Allow widgets to invidiually be refreshed on error
    useEffect(() => {
        if (housekeeping.failureCount) {
            setQueryKey("homeActivity")
        }
    }, [housekeeping.failureCount])

    const isEnabled = !!contactId && !!unitId

    const title = intl.formatMessage({
        id: "HomeInfo.widgets.homeActivity.title",
        defaultMessage: "Home Activity",
    })

    const nextCleanDate = housekeeping.data?.attributes.nextClean?.dueDateLocalTime
        ? parseISO(housekeeping.data?.attributes.nextClean?.dueDateLocalTime)
        : undefined

    const lastCompletedInspectionDate =
        housekeeping.data?.attributes.lastClean?.inspectionCompletedAtLocalTime

    const showEmptyState =
        (!isEnabled ||
            (!housekeeping.data?.attributes.lastClean &&
                !housekeeping.data?.attributes.nextClean)) &&
        !housekeeping.isLoading

    if (housekeeping.isError)
        return (
            <WidgetError
                errorHeading={title}
                errorMessage={intl.formatMessage({
                    id: "HomeInfo.widgets.homeActivity.errorMessage",
                    defaultMessage:
                        "We're having trouble loading this content.",
                })}
                handleClick={housekeeping.refetch}
                hideButton={!isEnabled}
            />
        )

    if (showEmptyState)
        return (
            <EmptyState
                icon="cleanliness"
                title={title}
                message={intl.formatMessage({
                    id: "HomeInfo.widgets.homeActivity.emptyStateMessage",
                    defaultMessage:
                        "Information on the last inspection and next clean of your home will appear where when it becomes available.",
                })}
            />
        )

    return (
        <BaseWidget>
            <header
                className={classNames(styles.header, {
                    shimmer: housekeeping.isLoading,
                })}
            >
                <Activity className={styles.icon} />
                <h3 className={styles.title}>{title}</h3>
            </header>
            <div className={styles.content}>
                <HomeActivityItem
                    icon={<IconClipboardText />}
                    title={intl.formatMessage({
                        id: "HomeInfo.widgets.homeActivity.lastCleanTitle",
                        defaultMessage: "Last Clean Inspection",
                    })}
                    details={
                        lastCompletedInspectionDate
                            ? format(
                                  parseISO(lastCompletedInspectionDate),
                                  "MMM d, yyyy"
                              )
                            : intl.formatMessage({
                                  id: "HomeInfo.widgets.homeActivity.lastCleanEmpty",
                                  defaultMessage:
                                      "No inspection has been completed",
                              })
                    }
                    loading={housekeeping.isLoading}
                />
                <HomeActivityItem
                    icon={<IconSprayBottle2 />}
                    title={intl.formatMessage({
                        id: "HomeInfo.widgets.homeActivity.nextCleanTitle",
                        defaultMessage: "Next Clean Scheduled",
                    })}
                    details={
                        nextCleanDate
                            ? format(nextCleanDate, "MMM d, yyyy")
                            : intl.formatMessage({
                                  id: "HomeInfo.widgets.homeActivity.nextCleanEmpty",
                                  defaultMessage: "No upcoming inspections",
                              })
                    }
                    loading={housekeeping.isLoading}
                />
            </div>
        </BaseWidget>
    )
}

interface HomeActivityItemProps {
    icon: React.ReactNode
    title: string
    details: string
    loading: boolean
}
const HomeActivityItem: FC<HomeActivityItemProps> = ({
    icon,
    title,
    details,
    loading,
}) => {
    return (
        <div className={styles.homeActivityItem}>
            <div
                className={classNames(styles.homeActivityItemIcon, {
                    shimmer: loading,
                })}
            >
                {icon}
            </div>
            <div className={styles.homeActivityItemContent}>
                <div
                    className={classNames(styles.homeActivityItemTitle, {
                        shimmer: loading,
                    })}
                >
                    {title}
                </div>
                <span
                    className={classNames({
                        shimmer: loading,
                    })}
                    style={{
                        maxWidth: loading ? "100px" : "none",
                    }}
                >
                    {details}
                </span>
            </div>
        </div>
    )
}

interface Props {
    contactId: string
    unitId: string
}
