import { IconLargeSymbolBlue } from "assets"
import styles from "./SubmitQuestion.module.scss"
import { FormattedMessage } from "react-intl"
import { Link } from "react-router-dom"
import { TicketCategoryId } from "@vacasa/owner-api-models"
import { trackSubmitARequestClicked } from "services/segment/dashboard/dashboardTracking"

export const SubmitQuestion = () => {
    return (
        <div className={styles.submitQuestion}>
            <div>
                <IconLargeSymbolBlue />
                <FormattedMessage
                    id="Dashboard.charts.marketRate.submitQuestion.text"
                    defaultMessage="Have a question?"
                />
                <Link
                    to={`/maintenance?action=create&category=${TicketCategoryId.Pricing}`}
                    onClick={trackSubmitARequestClicked}
                >
                    <FormattedMessage
                        id="Dashboard.charts.marketRate.submitQuestion.action"
                        defaultMessage="Ask a Rates Specialist"
                    />
                </Link>
            </div>
        </div>
    )
}
