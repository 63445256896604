import { ChangeEvent, FC } from "react"
import styles from "./Select.module.scss"
import { ReactComponent as SelectionSearchIcon } from "../../../assets/selection-search-icon.svg"

interface SelectSearchProps {
    onSearchValueChange: (value: string) => void
    placeholder?: string
    show?: boolean
    value: string
}

const SelectSearch: FC<SelectSearchProps> = props => {
    const { onSearchValueChange, placeholder, show, value } = props
    if (!show) return <></>
    return (
        <div className={styles["select__search"]}>
            <SelectionSearchIcon className={styles["select__search-icon"]} />
            <input
                placeholder={placeholder}
                className={styles["select__search-input"]}
                value={value}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    onSearchValueChange(e.target.value)
                }
            />
        </div>
    )
}

export default SelectSearch
