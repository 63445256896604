import React, { memo } from "react"

import BaseButton from "../Buttons/BaseButton"
import styles from "./actionrow.module.scss"

interface ActionRowProps {
    buttonOpts: ButtonOpts
    action: () => void
    content: string
}
interface ButtonOpts {
    typeOf: string
    ariaLabel: string
}

const ActionRow: React.FC<ActionRowProps> = (props: ActionRowProps) => {
    return (
        <div className={styles.action__row}>
            <BaseButton
                typeOf={props.buttonOpts.typeOf}
                ariaLabel={props.buttonOpts.ariaLabel}
                onClick={props.action}
            >
                {props.content}
            </BaseButton>
        </div>
    )
}

export default memo(ActionRow)
