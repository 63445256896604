import { useMediaQuery } from "@material-ui/core"
import {
    JSONAPIResource,
    OwnerHoldType,
    Reservation,
    VacasaHoldType,
} from "@vacasa/owner-api-models"
import classNames from "classnames"
import { differenceInDays, parseISO } from "date-fns"
import React from "react"
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl"
import { getClassStyle } from "utils/styles/styleWrapper"
import styles from "./ReservationRow.module.scss"
import {
    ReservationRowTypes,
    useReservationRow,
} from "./hooks/useReservationRow"

import { ReactComponent as ListingIcon } from "assets/icon-caret-right.svg"
import { GuestStarReview } from "lib/components/List/UpcomingStays/GuestStarReview"
import { getDisplayNameFromOccupancyType } from "utils/reservations/reservationsUtil"
import {
    OwnerHoldBadge,
    ReservationBadge,
    VacasaHoldBadge,
} from "lib/components/Badge"
import { useUserCurrencyCode } from "hooks/user"
import { ReservationDate } from "lib/components/ReservationDate"

interface Props {
    isSuperUnit: boolean
    onClick: (reservation: JSONAPIResource<Reservation>) => void
    reservation: JSONAPIResource<Reservation>
    isFixedRent: boolean
    currency: string
    showGuestRating: boolean
    stayType: string
    showNetRent: boolean
    bookingProbabilityEnabled: boolean
}

const renderReservationTags = ({
    ownerHoldType,
    showInheritedTag,
    vacasaHoldType,
}: {
    ownerHoldType?: OwnerHoldType
    showInheritedTag: boolean
    vacasaHoldType?: VacasaHoldType | null
}) => (
    <>
        {ownerHoldType && <OwnerHoldBadge type={ownerHoldType} />}
        {showInheritedTag && <ReservationBadge type="inherited" />}
        {vacasaHoldType && <VacasaHoldBadge type={vacasaHoldType} />}
    </>
)

const ReservationRow: React.FC<Props> = ({
    isSuperUnit,
    onClick,
    reservation,
    isFixedRent,
    showGuestRating,
    stayType,
    showNetRent,
    bookingProbabilityEnabled,
}: Props) => {
    const showNarrowView = useMediaQuery("(max-width: 849px)")
    const intl = useIntl()
    const {
        bookingName,
        isReservation,
        isRowHighlighted,
        ownerHoldType,
        handleClick,
        rowRef,
        startDate,
        endDate,
        rent,
        occupancyType,
        showZap,
        showInheritedTag,
        unitName,
    }: ReservationRowTypes = useReservationRow({
        reservation,
        onClick,
        showNetRent,
        stayType,
    })

    const currency = useUserCurrencyCode()

    if (!startDate || !endDate) {
        return null
    }

    const showBookingProbabilityIcon = bookingProbabilityEnabled && showZap
    const bookingDetails = intl
        .formatMessage(
            {
                id: "CalendarPage.UpcomingStays.occupancyCount",
                defaultMessage:
                    "{adultCount, plural,=0 {}one {# adult,}other {# adults,}} {childCount, plural,=0 {}one {# child,}other {# children,}} {dogCount, plural,=0 {} one {# dog}other {# dogs}}",
            },
            {
                adultCount: reservation.attributes.adults ?? 0,
                childCount: reservation.attributes.children ?? 0,
                dogCount: reservation.attributes.pets ?? 0,
            }
        )
        .replace(/,\s*$/, "")

    const nightsText = intl.formatMessage(
        {
            id: "CalendarPage.UpcomingStays.totalNights",
            defaultMessage:
                "{nightCount, plural, one {# night} other {# nights}}",
        },
        {
            nightCount: differenceInDays(
                parseISO(endDate),
                parseISO(startDate)
            ),
        }
    )
    const tags = renderReservationTags({
        showInheritedTag,
        ownerHoldType,
        vacasaHoldType: reservation.attributes.vacasaHold?.holdType,
    })
    const reservationTypeName = intl.formatMessage(
        getDisplayNameFromOccupancyType(occupancyType)
    )

    return (
        <div
            className={classNames(styles.reservationRow, {
                [getClassStyle(styles.highlighted)]: isRowHighlighted,
            })}
            onClick={handleClick}
            id={reservation.id?.toString()}
            ref={rowRef}
            data-testid={
                isRowHighlighted
                    ? "reservation-row-highlighted"
                    : "reservation-row"
            }
        >
            <div className={classNames(styles.rowContent)}>
                <div className={classNames(styles.col)}>
                    <ReservationDate
                        date={parseISO(startDate ?? "")}
                        occupancyType={occupancyType}
                        showBookingProbabilityIcon={showBookingProbabilityIcon}
                    />

                    <div className={classNames(styles.summary, "text-dusk")}>
                        <div className={styles.summaryInner}>
                            <div
                                className={classNames(
                                    styles.occupancyType,
                                    "leading-none"
                                )}
                            >
                                {reservationTypeName}
                            </div>
                            <div
                                className={classNames(
                                    "type-body-small",
                                    "leading-none"
                                )}
                            >
                                {nightsText}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classNames(styles.col)}>
                    {showNarrowView && (
                        <div className={classNames(styles.occupancyType)}>
                            {reservationTypeName}
                            {showGuestRating && (
                                <GuestStarReview
                                    reservationId={Number(reservation.id)}
                                />
                            )}
                        </div>
                    )}
                    <div className={styles.infoMobile}>
                        {isSuperUnit ? (
                            <span
                                className={classNames(
                                    "uppercase",
                                    "type-heading-tiny",
                                    "text-midnight-70",
                                    "font-extrabold",
                                    "ellipsis"
                                )}
                            >
                                {unitName}
                            </span>
                        ) : (
                            <span
                                className={classNames(
                                    "type-body-small",
                                    "ellipsis"
                                )}
                            >
                                {nightsText}
                                {isReservation && <> &#x2022; {bookingName}</>}
                            </span>
                        )}

                        <div className={styles.tags}>{tags}</div>
                    </div>

                    <div
                        className={classNames(styles.infoDesktop, {
                            "justify-between": isSuperUnit && !isReservation,
                        })}
                    >
                        <div className="flex items-center">
                            {isSuperUnit && (
                                <span
                                    className={classNames(
                                        "uppercase",
                                        "type-heading-tiny",
                                        "text-midnight-70",
                                        "font-extrabold",
                                        "ellipsis",
                                        styles.unitName
                                    )}
                                >
                                    {unitName}
                                </span>
                            )}

                            {showGuestRating && (
                                <GuestStarReview
                                    reservationId={Number(reservation.id)}
                                />
                            )}
                        </div>

                        <div className={classNames("flex")}>
                            {isReservation && (
                                <span
                                    className={classNames(
                                        "type-body-small",
                                        "ellipsis"
                                    )}
                                >
                                    {bookingName} &#x2022; {bookingDetails}
                                </span>
                            )}

                            <div
                                className={classNames(styles.tags, {
                                    [getClassStyle(
                                        styles.nonReservationSuperUnitTags
                                    )]: isSuperUnit && !isReservation,
                                    [getClassStyle(styles.nonReservationTags)]:
                                        !isSuperUnit && !isReservation,
                                })}
                            >
                                {tags}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.col}>
                    <ListingIcon className={styles.chevron} />
                    <div className={styles.rentWrap}>
                        <span
                            className={classNames(styles.rent, "font-medium", {
                                "text-midnight": !isFixedRent || isReservation,
                                "text-dusk-lighter":
                                    isFixedRent || !isReservation,
                            })}
                        >
                            {isReservation ? (
                                <>
                                    {isFixedRent && (
                                        <FormattedMessage
                                            id="CalendarPage.flyout.fixedRent"
                                            defaultMessage="Fixed rent"
                                        />
                                    )}
                                    {!isFixedRent && (
                                        <FormattedNumber
                                            value={Number(rent)}
                                            currency={currency}
                                            // eslint-disable-next-line react/style-prop-object
                                            style="currency"
                                        />
                                    )}
                                </>
                            ) : (
                                <>--</>
                            )}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { ReservationRow }
