import { authAxios } from "api/authAxios"
import { DownloadStatementsPDFRequest } from "./useDownloadStatementsPDFRequest"

type DownloadStatementsPDFResponse = {
    data: {
        type: "statement-pdf"
        attributes: {
            pdf: string
            filename: string
        }
    }
}

const postV2 = (request: DownloadStatementsPDFRequest) => {
    return authAxios.post<DownloadStatementsPDFResponse>(
        `v1/statements/pdf`,
        {
            data: {
                type: "statement-pdf",
                attributes: {
                    firstDay: request.firstDay,
                    lastDay: request.lastDay,
                    lang: request.lang,
                    showLogo: request.showLogo,
                },
            },
        },
        {
            params: {
                contactId: request.contactId,
            },
            signal: request.signal,
        }
    )
}

export const downloadStatementsPDF = async (
    request: DownloadStatementsPDFRequest
) => {
    return await postV2(request).then(({ data }) => {
        return {
            data: data.data.attributes.pdf,
            filename: request.filename,
        }
    })
}
