import { JSONAPIResource, ReservationGuest } from "@vacasa/owner-api-models"
import { authAxios } from "../../api/authAxios"

type FetchReservationRequest = {
    reservationId: string | number | undefined
    signal?: AbortSignal
}

const fetchReservationGuests = ({
    reservationId,
    signal,
}: FetchReservationRequest): Promise<JSONAPIResource<ReservationGuest>[]> =>
    authAxios
        .get<{ data: JSONAPIResource<ReservationGuest>[] }>(
            `/v1/reservations/${reservationId}/guests`,
            {
                signal,
            }
        )
        .then(response => response.data.data)

export { fetchReservationGuests }
