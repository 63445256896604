const maintenance = {
    title: "Karbantartás",
    supportHub: "Support",
    newRequest: "Új kérelem",
    open: "Megnyitás",
    closed: "Lezárt",
    requestNumber: "Kérelem száma",
    status: "Állapot",
    inProgress: "Folyamatban",
    rental: "Ingatlan",
    allRentals: "Összes nyaraló",
    search: "keresés",
    submitted: "Elküldve",
    updated: "Frissítve",

    selectRental: "Ingatlan kiválasztása",
    newMaintenanceRequest: "New Maintenance Request",
    needSomeHelp: "Need some help?",
    categorySubtitle:
        "Let us know how we can support you by selecting an option below:",
    requestTitle: "Maintenance request title",
    titlePlaceholder: "Clogged drain",
    describeYourRequest: "Describe your request",
    requestDetails: "Describe your request in detail",
    note: "Note",
    notes: "Notes",
    detailsPlaceholder:
        "We noticed a big drainage issue with the primary kitchen sink. It's a super slow drain or it doesn't even drain at all. Please help!",
    clear: "Clear",
    submitRequest: "Kérelem küldése",
    areYouSure: "Are you sure?",
    leavingAlertText:
        "Leaving the submission form will cause you to lose any data you may have entered.",
    continueWithSubmission: "Continue with Submission",
    cancelRequest: "Cancel Request",
    requestSubmitted: "Request submitted",
    requestSaved: "Kérelem mentve",

    submitNewRequest: "Új kérelem küldése",
    noOpenRequests: "No Open Requests",
    newRequestWill: "New requests will appear here until they are addressed.",
    noClosedRequests: "No Closed Requests",
    closedRequestsWill:
        "Once an open request is addressed, it will be closed out and will appear here.",

    ariaLabel: "Kattints ide a karbantartási kérelem elküldéséhez",

    needHelp: "Segítségre van szükséged?",
    contactSentenceIfQuestions:
        "Lépj kapcsolatba a kapcsolattartódal ({name}), ha kérdésed van, vagy módosítani szeretnél a kérelmeden.",
    or: "vagy",

    failedFetchError: `Hiba lépett fel a karbantartási kéréseid betöltése során. Ha a probléma továbbra is fennáll, próbálkozz újra később.`,
    failedCreateError: `Karbantartási kérelmed mentése sikertelen volt. Ellenőrizd a kiválasztást és próbáld meg újból.`,
    reload: `Újratöltés`,
    emptyState:
        "Need help with home maintenance? You’ll be able to submit requests here.",

    submittedOn: "Submitted on {date}",
    closedOn: "Closed on {date}",
    cancelled: "Cancelled",
    comments: {
        comments: "Comments",
        empty: "When Vacasa responds to this request, their comments will appear here.",
        overCharacterLengthLimit:
            "The comment should not be over 1000 characters.",
        failedCreateCommentError:
            "We were unable to save your comment. Please try again later.",
    },
    featureAlertTitle: "NEW! Two-way Ticket Comments",
    featureAlertBody:
        "Add a comment to your ticket to provide more information or follow up with a question, which will streamline communication about a specific ticket.",
}

export default maintenance
