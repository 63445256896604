import axios from "axios"

import { OWNER_API_URL } from "../Environment"
import { addAuthorizationHeader, checkAuthorization } from "./authInterceptor"
import { logErrorToSentry } from "./loggingInterceptor"

const authAxios = axios.create({ baseURL: OWNER_API_URL })

authAxios.interceptors.request.use(addAuthorizationHeader, undefined)
authAxios.interceptors.response.use(undefined, logErrorToSentry)
authAxios.interceptors.response.use(undefined, checkAuthorization)

export { authAxios }
