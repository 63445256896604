import { authAxios } from "api/authAxios"

export type PatchUnitArgs = {
    data: {
        type: "unit"
        id: string
        attributes: {
            petsFriendly?: boolean | null
            hotTub?: number | null
            rooms:
                | {
                      bedrooms?: number | null
                      bathrooms?:
                          | {
                                full?: number | null
                                half?: number | null
                            }
                          | null
                          | undefined
                      loft?: boolean | null
                      kitchen?: number | null
                  }
                | null
                | undefined
            beds:
                | {
                      king?: number | null
                      queen?: number | null
                      full?: number | null
                  }
                | null
                | undefined
            parking:
                | {
                      total?: number | null
                      notes?: string | null
                      fourWheelDriveRequired?: number | null
                      paid?: boolean | null
                      valet?: boolean | null
                      accessible?: boolean | null
                      street?: boolean | null
                  }
                | null
                | undefined
        }
    }
    contactId: string
    unitId: string
}
export const patchUnit = ({
    data,
    contactId,
    unitId,
}: PatchUnitArgs): Promise<void> =>
    authAxios.patch(`v1/owners/${contactId}/units/${unitId}`, {
        data,
    })
