import { Owner } from "@vacasa/owner-api-models"
import { useLoginInfo } from "contexts/login"

export const useContactId = (): string | null => {
    const { user } = useLoginInfo()
    return user?.contactId ?? null
}

/**
 * Returns a list of contact ids linked to the current user
 * @returns
 */
export const useContactIds = (): string[] => {
    const { contactIds } = useLoginInfo()
    return contactIds
}

export const useUserCurrencyCode = (): string => {
    const { user } = useLoginInfo()
    return user?.accountInfo.currencyCode ?? "USD"
}

export const useIsEmployee = (): boolean => {
    const { loginType } = useLoginInfo()
    return loginType === "employee"
}

export const useUser = (): { user: Owner | null; isEmployee: boolean } => {
    const { user, loginType } = useLoginInfo()
    return { user, isEmployee: loginType === "employee" }
}

export const useLegacyUserId = (): string | null => {
    const { user } = useLoginInfo()
    return user?.userId ?? null
}
