/* eslint-disable camelcase */
// Event
export const REFERRAL_EVENT = "Referral"
// Action
export const REFERRAL_FORM_SUBMITTED_ACTION = "referral_form_submitted"
export const REFERRAL_SUCCESS_CLOSED_ACTION = "referral_success_closed"
export const REFERRAL_FORM_CLOSED_ACTION = "referral_form_closed"
export const REFER_ANOTHER_FRIEND_ACTION = "refer_another_friend"
export const PROFILE_MENU_REFERRAL_ACTION = "profile_menu_referral"
export const REFERRAL_CARD_CLICKED_ACTION = "referral_card_clicked"
export const REFERRAL_CARD_DISMISSED_ACTION = "referral_card_dismissed"
