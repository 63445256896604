import { JSONAPIResource, Unit } from "@vacasa/owner-api-models"
import classnames from "classnames"
import React from "react"
import { getUnitImage } from "utils/unit"
import placeholder from "assets/placeholder-home-image.png"
interface Props {
    unit?: JSONAPIResource<Unit>
    className?: string
}

const UnitPicture: React.FunctionComponent<Props> = ({ unit, className }) => {
    const unitImage = getUnitImage(unit, placeholder)
    return <img alt="Unit" src={unitImage} className={classnames(className)} />
}

export default UnitPicture
