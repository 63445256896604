import { authAxios } from "../../api/authAxios"

export type PutUnitReserveBalanceRequest = {
    contactId: string
    unitId: number | string
    reserveBalance: number
    signal?: AbortSignal
}

export type PutUnitReserveBalanceResponse = {
    data: {
        message: string
    }
}

export const putUnitReserveBalance = ({
    contactId,
    unitId,
    reserveBalance,
    signal,
}: PutUnitReserveBalanceRequest): Promise<PutUnitReserveBalanceResponse> => {
    return authAxios
        .put<PutUnitReserveBalanceResponse>(
            `/v1/legacy/owners/${contactId}/units/${unitId}/reserve-balance`,
            {
                // eslint-disable-next-line camelcase
                reserve_balance: String(reserveBalance),
            },
            {
                signal,
            }
        )
        .then(response => response.data)
}
