import React, { ReactNode, useEffect, useState } from "react"
import classnames from "classnames"
import deepEqual from "deep-equal"
import { Amenities } from "@vacasa/owner-api-models"

import Panel from "../OnboardingPanel"
import SubmitButtonRow, {
    ButtonState,
} from "../../../lib/components/Onboarding/SubmitButtonRow"
import ConfirmButtonRow from "../../../lib/components/Onboarding/ConfirmButtonRow"
import RowWrapper, {
    RowType,
} from "../../../lib/components/Onboarding/RowWrapper"
import ErrorComponent from "../ErrorComponent"
import { Generic as DropdownOption } from "../../../models/Generic"

import { ReactComponent as DogSVG } from "../../../assets/icon-dog-friendly.svg"
import { ReactComponent as HottubSVG } from "../../../assets/icon-hot-tub.svg"
import styles from "../../../sass/onboarding.module.scss"
import { FormattedMessage, useIntl } from "react-intl"
import { trackUpdateAmenities } from "services/segment/onboarding/onboardingTracking"

interface AmenitiesSectionProps {
    apiModel: Amenities
    confirmedAmenities: boolean
    getOptionByValue: (
        options: DropdownOption[],
        value?: number | null
    ) => DropdownOption | null
    patchPreference: (preferenceName: string) => Promise<void>
    patchUnit: (data: any) => Promise<void> // eslint-disable-line @typescript-eslint/no-explicit-any
    saveAmenitiesHandler: (enabled: boolean) => void
    unitId: string
}

const AmenitiesSection: React.FC<AmenitiesSectionProps> = ({
    apiModel,
    confirmedAmenities,
    getOptionByValue,
    patchPreference,
    patchUnit,
    saveAmenitiesHandler,
    unitId,
}: AmenitiesSectionProps): JSX.Element => {
    const [errorSavingAmenities, setErrorSavingAmenities] = useState(false)
    const [saveAmenitiesEnabled, setSaveAmenitiesEnabled] = useState(
        ButtonState.Disabled
    )
    const apiAmenities = apiModel
    const [tempAmenities, setTempAmenities] = useState(apiAmenities)
    const intl = useIntl()
    const hotTubOptions: DropdownOption[] = [
        {
            name: intl.formatMessage({
                id: "Onboarding.hotTubTypes.noHotTub",
                defaultMessage: "No hot tub",
            }),
            id: -1,
        },
        {
            name: intl.formatMessage({
                id: "Onboarding.hotTubTypes.private",
                defaultMessage: "Private hot tub",
            }),
            id: 1,
        },
        {
            name: intl.formatMessage({
                id: "Onboarding.hotTubTypes.shared",
                defaultMessage: "Shared hot tub",
            }),
            id: 2,
        },
    ]

    useEffect(() => {
        setSaveAmenitiesEnabled(
            !deepEqual(tempAmenities, apiAmenities)
                ? ButtonState.Enabled
                : ButtonState.Disabled
        )
    }, [apiAmenities, tempAmenities])

    useEffect(() => {
        saveAmenitiesHandler(!deepEqual(tempAmenities, apiAmenities))
    }, [apiAmenities, saveAmenitiesHandler, tempAmenities])

    const hasData =
        !!tempAmenities?.hotTub && tempAmenities?.petsFriendly !== undefined
    const noChanges = saveAmenitiesEnabled === ButtonState.Disabled
    const shouldShowConfirmButton = hasData && noChanges && !confirmedAmenities

    function resetAmenities(): void {
        setSaveAmenitiesEnabled(ButtonState.Disabled)
        setTempAmenities(apiAmenities)
    }
    return (
        <div>
            <h5>
                <FormattedMessage
                    id="Onboarding.sectionTitles.amenities"
                    defaultMessage="Amenities"
                />
            </h5>
            <p className={styles.section_description}>
                <FormattedMessage
                    id="Onboarding.sectionDescriptions.amenities"
                    defaultMessage="Let us know what extra amenities your rental home includes. This information can help increase occupancy."
                />
            </p>
            <div className={styles.user_selection_group}>
                <div
                    className={classnames([
                        styles.user_selection,
                        tempAmenities?.petsFriendly !== undefined
                            ? ""
                            : styles.not_set,
                    ])}
                >
                    <DogSVG className={styles.amenity_icon} />
                    {tempAmenities?.petsFriendly ? (
                        <FormattedMessage
                            id="Onboarding.amenities.dogs.dogFriendly"
                            defaultMessage="Dog-friendly"
                        />
                    ) : tempAmenities?.petsFriendly === undefined ? (
                        <FormattedMessage
                            id="Onboarding.notSelected"
                            defaultMessage="Not selected"
                        />
                    ) : (
                        <FormattedMessage
                            id="Onboarding.amenities.dogs.noDogs"
                            defaultMessage="Not dog-friendly"
                        />
                    )}
                </div>
                <div
                    className={classnames([
                        styles.user_selection,
                        tempAmenities?.hotTub !== undefined
                            ? ""
                            : styles.not_set,
                    ])}
                >
                    <HottubSVG className={styles.amenity_icon} />
                    {tempAmenities?.hotTub !== undefined ? (
                        `${
                            getOptionByValue(
                                hotTubOptions,
                                tempAmenities?.hotTub
                            )?.name
                        }`
                    ) : (
                        <FormattedMessage
                            id="Onboarding.notSelected"
                            defaultMessage="Not selected"
                        />
                    )}
                </div>
            </div>
            {errorSavingAmenities ? (
                <ErrorComponent
                    onClick={(): void => setErrorSavingAmenities(false)}
                />
            ) : (
                <Panel className={styles.panel_content}>
                    <RowWrapper
                        type={RowType.Toggle}
                        title={
                            <FormattedMessage
                                id="Onboarding.amenities.dogs.title"
                                defaultMessage="Dogs"
                            />
                        }
                        selectedValue={tempAmenities?.petsFriendly}
                        description={
                            <FormattedMessage
                                id="Onboarding.amenities.dogs"
                                defaultMessage="We can market your home to an even wider audience if it’s listed as dog-friendly. Plus, we’ll provide pet supplies and keep your home looking great. <a>Learn more</a>"
                                values={{
                                    a: (chunks: ReactNode) => (
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://www.vacasa.com/owner-library/pet-friendly"
                                        >
                                            {chunks}
                                        </a>
                                    ),
                                }}
                            />
                        }
                        onChangeHandler={(
                            value: boolean | null | undefined
                        ): void =>
                            setTempAmenities({
                                ...tempAmenities,
                                petsFriendly: value,
                            })
                        }
                    />
                    <RowWrapper
                        type={RowType.Drowdown}
                        title={
                            <FormattedMessage
                                id="Onboarding.amenities.hotTub"
                                defaultMessage="Hot tub"
                            />
                        }
                        options={hotTubOptions}
                        selectedValue={getOptionByValue(
                            hotTubOptions,
                            tempAmenities?.hotTub
                        )}
                        onChangeHandler={(
                            value: DropdownOption | null | undefined
                        ): void =>
                            setTempAmenities({
                                ...tempAmenities,
                                hotTub: value?.id,
                            })
                        }
                        lastRow={true}
                    />
                    {shouldShowConfirmButton ? (
                        <ConfirmButtonRow
                            buttonState={ButtonState.Enabled}
                            onSubmit={(): void => {
                                patchPreference(
                                    "onboarding-confirmed-amenities"
                                )
                            }}
                        />
                    ) : (
                        <SubmitButtonRow
                            buttonState={saveAmenitiesEnabled}
                            onSubmit={(): void => {
                                setSaveAmenitiesEnabled(ButtonState.Loading)
                                Promise.all([
                                    patchUnit({
                                        petsFriendly:
                                            tempAmenities?.petsFriendly,
                                        hotTub: tempAmenities?.hotTub,
                                    }),
                                    patchPreference(
                                        "onboarding-confirmed-amenities"
                                    ),
                                ])
                                    .then(() => {
                                        return trackUpdateAmenities(
                                            tempAmenities,
                                            apiAmenities,
                                            hotTubOptions,
                                            unitId
                                        )
                                    })
                                    .catch(() => {
                                        setErrorSavingAmenities(true)
                                        // reset tempModel to the original value as patch failed
                                        resetAmenities()
                                    })
                            }}
                            onCancel={(): void => resetAmenities()}
                        />
                    )}
                </Panel>
            )}
        </div>
    )
}

export default AmenitiesSection
