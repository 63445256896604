import {
    MouseEventHandler,
    RefObject,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react"
import { useLocation } from "react-router-dom"

import BookingNotificationUtil from "../../../../utils/bookingNotification/bookingNotificationUtil"

import {
    JSONAPIResource,
    OwnerHoldType,
    Reservation,
    ReservationType,
} from "@vacasa/owner-api-models"

import { parseISO } from "date-fns"
import { useBookingProbability } from "hooks/booking-probability"
import useOnClickOutside from "hooks/common/useOnClickOutside"
import { useOwnerFeatureFlag } from "hooks/owner-feature-flag"
import { useKnownSearchParams } from "hooks/router"
import { trackReservationDetailsViewed } from "services/segment/reservation/reservationTracking"
import {
    showBookingProbabilityIndicator,
    showBookingProbabilityMessage,
} from "utils/bookingProbability/bookingProbabilityUtil"
import { bookingName } from "utils/reservations/reservationsUtil"
import { useContactId } from "hooks/user"

export type ReservationRowTypes = {
    bookingName: string
    isRowHighlighted: boolean
    handleClick: MouseEventHandler<Element>
    id: string | number | undefined
    rowRef: RefObject<HTMLDivElement>
    startDate: string | null | undefined
    endDate: string | null | undefined
    occupancyType: ReservationType
    ownerHoldType?: OwnerHoldType
    contactId: string | null
    showInheritedTag: boolean
    isReservation: boolean
    rent: number
    showZap: boolean
    unitName: string
}

type useReservationRowProps = {
    reservation: JSONAPIResource<Reservation>
    onClick: (reservation: JSONAPIResource<Reservation>) => void
    showNetRent: boolean
    stayType: string
}

export const getReservationRent = (
    reservation: JSONAPIResource<Reservation>,
    showNetRent: boolean
) => {
    if (showNetRent) {
        return (
            reservation.attributes.netRentAfterDiscount ??
            reservation.attributes.netRent ??
            0
        )
    }
    return reservation.attributes.totalRent ?? 0
}

export const useReservationRow = ({
    reservation,
    onClick,
    showNetRent,
    stayType,
}: useReservationRowProps): ReservationRowTypes => {
    const { id, attributes } = reservation
    const { startDate, endDate, inherited, occupancyType } = attributes
    const { OWNERHOLD, RESERVATION } = ReservationType
    const ownerHoldType = attributes.ownerHold?.holdType

    const bookingProbabilityQuery = useBookingProbability(
        occupancyType === OWNERHOLD ? attributes.unitID?.toString() : null,
        parseISO(reservation.attributes.startDate ?? ""),
        parseISO(reservation.attributes.endDate ?? "")
    )

    const showZap = showBookingProbabilityIndicator(bookingProbabilityQuery)
    const knownSearchParams = useKnownSearchParams()
    const resId = knownSearchParams.resId
    const isSearchedReservation = id === resId
    const location = useLocation()

    const [isRowHighlighted, setRowHighlighted] = useState(
        isSearchedReservation &&
            BookingNotificationUtil.getShouldShowBookingNotification(location)
    )

    const rowRef = useRef<HTMLDivElement>(null)

    const contactId = useContactId()

    const inheritedFeatureFlag = useOwnerFeatureFlag(
        "owner-portal-guest-reservation-inherited"
    )

    // When there is a resID matching, scroll to the row
    useEffect(() => {
        if (
            isSearchedReservation &&
            BookingNotificationUtil.getShouldShowBookingNotification(location)
        ) {
            /**
             * Delay scroll into view as calendar ui pops in at the moment causing
             * the scroll postion to move
             **/
            setTimeout(
                () => rowRef.current?.scrollIntoView({ behavior: "smooth" }),
                2000
            )
        }
    }, [isSearchedReservation, resId, id, rowRef, location])

    // When clicking outside of the row, set the notifcation as opened and remove highlight
    useOnClickOutside(
        rowRef,
        () => {
            setRowHighlighted(false)
            BookingNotificationUtil.setNotificationOpened(true)
        },
        () =>
            isSearchedReservation &&
            BookingNotificationUtil.getShouldShowBookingNotification(location)
    )

    const handleClick = useCallback<MouseEventHandler>(() => {
        onClick(reservation)

        const willShowProbabilityMessage =
            attributes.occupancyType === ReservationType.OWNERHOLD &&
            showBookingProbabilityMessage(bookingProbabilityQuery)
        trackReservationDetailsViewed(
            id?.toString() ?? "",
            String(attributes.unitID),
            `${stayType.toLowerCase()}_stay`,
            willShowProbabilityMessage
        )
    }, [
        onClick,
        reservation,
        stayType,
        attributes.unitID,
        id,
        attributes.occupancyType,
        bookingProbabilityQuery,
    ])

    const unitName = reservation.attributes.unitName ?? ""
    return {
        bookingName: bookingName(reservation),
        isRowHighlighted,
        handleClick,
        id,
        rowRef,
        startDate,
        endDate,
        ownerHoldType,
        contactId,
        isReservation: occupancyType === RESERVATION,
        occupancyType:
            reservation.attributes.occupancyType ?? ReservationType.OWNERHOLD,
        rent: getReservationRent(reservation, showNetRent),
        showInheritedTag: Boolean(inherited && inheritedFeatureFlag.isEnabled),
        showZap,
        unitName,
    }
}
