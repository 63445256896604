import { Component, ReactNode } from "react"
import { observer, inject } from "mobx-react"
import styles from "./statements.module.scss"

// Components
import { ReactComponent as IconMinus } from "../../assets/icon-minus.svg"
import { ReactComponent as IconPlus } from "../../assets/icon-plus.svg"
import Loader from "../../lib/components/Loader"

// Constants
import rootService from "../../services"
import {
    displayFormattedNumber,
    formatDate,
    isACHExpense,
} from "utils/statements/statementsUtil"
import { FormattedMessage } from "react-intl"
import classNames from "classnames"
import { DataExpense } from "@vacasa/owner-api-models"

interface PaymentSummaryProps {
    rootService: typeof rootService
    currency?: string
    summary: any // eslint-disable-line @typescript-eslint/no-explicit-any
}

interface PaymentSummaryState {
    openPayments: boolean
    openProceeds: boolean
}
@inject("rootService")
@observer
export default class PaymentSummary extends Component<
    PaymentSummaryProps,
    PaymentSummaryState
> {
    _isMounted = false
    private lastOpenState: any // eslint-disable-line @typescript-eslint/no-explicit-any
    private printing: boolean
    private rootService: typeof rootService
    private currency?: string
    private summary: any // eslint-disable-line @typescript-eslint/no-explicit-any
    constructor(props: PaymentSummaryProps) {
        super(props)
        this.state = {
            openPayments: false,
            openProceeds: false,
        }
        this.displayPayments = this.displayPayments.bind(this)
        this.displayProceeds = this.displayProceeds.bind(this)
        this.renderLoader = this.renderLoader.bind(this)
        this.lastOpenState = Object.assign({}, this.state)
        this.printing = false
        this.rootService = props.rootService
        this.currency = props.currency
        this.summary = props.summary
    }

    componentDidMount(): void {
        // listen for print media
        const mediaQueryList = window.matchMedia("print")
        mediaQueryList.addListener(mql => {
            if (mql.matches) {
                if (!this.printing) {
                    this.lastOpenState = Object.assign({}, this.state)
                    this.setState({ openPayments: true, openProceeds: true })
                    this.printing = true
                }
            } else {
                if (this.printing) {
                    this.setState(Object.assign({}, this.lastOpenState))
                    this.printing = false
                }
            }
        })

        window.onbeforeprint = () => {
            if (!this.printing) {
                this.lastOpenState = Object.assign({}, this.state)
                this.setState({ openPayments: true, openProceeds: true })
                this.printing = true

                this.forceUpdate()
            }
        }

        window.onafterprint = () => {
            if (this.printing) {
                this.printing = false
                this.setState(Object.assign({}, this.lastOpenState))
            }
        }

        this._isMounted = true
    }

    componentWillUnmount(): void {
        this._isMounted = false
    }

    /* Payment Summary - Payments Body  */
    displayPayments(): null | undefined | JSX.Element {
        this.summary = this.props.summary
        let paymentType
        let description: string | ReactNode
        if (this.summary.length < 1) {
            return null
        }
        if (
            this.summary &&
            this.summary.expenses &&
            this.summary.expenses.length > 0
        ) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return this.summary.expenses.map((expense: any, key: string) => {
                const postDate = formatDate(expense.posting_date)
                if (
                    expense.payee_account_id === 0 &&
                    (expense.debit || isACHExpense(expense))
                ) {
                    if (isACHExpense(expense)) {
                        description = expense.description
                        paymentType = ""
                    } else if (expense.account_num) {
                        description = (
                            <FormattedMessage
                                id="Statements.paymentDescription"
                                defaultMessage="Payment to account ending in"
                            />
                        )
                        paymentType = expense.account_num
                    } else if (expense.check_number) {
                        description = (
                            <FormattedMessage
                                id="Statements.checkNumber"
                                defaultMessage="Check #"
                            />
                        )
                        paymentType = expense.check_number
                    } else {
                        paymentType = expense.description
                    }
                    return (
                        <tr key={key}>
                            <td className="gutter" />
                            <td className="date">{postDate}</td>
                            <td className="payment-sum-desc-span">
                                {description} {paymentType}
                            </td>
                            <td className="text-right">
                                {displayFormattedNumber(
                                    expense.debit ?? expense.credit,
                                    this.currency
                                )}
                            </td>
                            <td className="gutter-end" />
                        </tr>
                    )
                } else {
                    return null
                }
            })
        }
    }
    /* Payment Summary - Proceeds Body  */
    displayProceeds(): null | undefined | JSX.Element {
        if (this.summary && this.summary.expenses) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return this.summary.expenses
                .filter((expense: DataExpense) => !isACHExpense(expense))
                .map((expense: DataExpense, key: string) => {
                    const postDate = formatDate(expense.posting_date)
                    if (expense.credit) {
                        return (
                            <tr key={key}>
                                <td className="gutter" />
                                <td className="date">{postDate}</td>
                                <td className="payment-sum-desc-span">
                                    {expense.description}
                                </td>
                                <td className="text-right">
                                    {displayFormattedNumber(
                                        Number(expense.credit),
                                        this.currency
                                    )}
                                </td>
                                <td className="gutter-end" />
                            </tr>
                        )
                    }
                    return null
                })
        } else {
            return null
        }
    }
    renderLoader(): JSX.Element | undefined {
        if (this.rootService.earningsService.updatingSummary) return <Loader />
    }
    render(): JSX.Element {
        this.summary = this.props.summary
        if (!this.summary) return <Loader />
        const totalCredits = this.summary.expenses
            .filter((expense: DataExpense) => !isACHExpense(expense)) // ACH expenses are credits but we want to display them under payments
            .map((expense: DataExpense) => expense.credit) // eslint-disable-line @typescript-eslint/no-explicit-any
            .filter((credit: any) => credit) // eslint-disable-line @typescript-eslint/no-explicit-any
            .reduce((a: any, b: any) => Number(a) + Number(b), 0) // eslint-disable-line @typescript-eslint/no-explicit-any
        return (
            <div className="payment-summary">
                {this.renderLoader()}
                <h4 className="type-heading-medium">
                    <FormattedMessage
                        id="Statements.paymentSummary"
                        defaultMessage="Payment Summary: All Rentals"
                    />
                </h4>
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>
                                    {" "}
                                    <FormattedMessage
                                        id="Statements.beginningBalance"
                                        defaultMessage="Beginning Balance"
                                    />
                                </th>
                                <th>
                                    {displayFormattedNumber(
                                        this.summary.beginning_balance,
                                        this.currency
                                    )}
                                </th>
                            </tr>
                        </thead>
                    </table>{" "}
                </div>
                <div className="table-responsive">
                    <table
                        className={classNames(
                            "table",
                            this.state.openPayments || this.printing
                                ? "open"
                                : ""
                        )}
                    >
                        {/* Payment Summary - Payments Haeder  */}
                        <thead>
                            <tr
                                onClick={() =>
                                    this.setState({
                                        openPayments: !this.state.openPayments,
                                    })
                                }
                                className="pointer"
                            >
                                <td colSpan={4}>
                                    {this.state.openPayments ||
                                    this.printing ? (
                                        <IconMinus className="icon-svg plus-minus" />
                                    ) : (
                                        <IconPlus className="icon-svg plus-minus" />
                                    )}
                                    <FormattedMessage
                                        id="Statements.payments"
                                        defaultMessage="Payments"
                                    />
                                </td>
                                <td colSpan={2} className="text-right">
                                    {displayFormattedNumber(
                                        this.summary.total_owner_payments
                                            ? this.summary.total_owner_payments
                                            : this.summary.total_payments,
                                        this.currency
                                    )}
                                </td>
                            </tr>
                        </thead>
                        <tbody
                            className={
                                this.state.openPayments || this.printing
                                    ? "show"
                                    : "hide"
                            }
                        >
                            {this.displayPayments()}
                        </tbody>
                        {this.state.openPayments || this.printing ? (
                            /* Payment Summary - Payments Total  */
                            <tfoot>
                                <tr>
                                    <td className="gutter" />
                                    <td colSpan={2} className="total-span">
                                        <strong>
                                            <FormattedMessage
                                                id="Statements.total"
                                                defaultMessage="Total"
                                            />
                                        </strong>
                                    </td>
                                    <td className="text-right">
                                        <strong>
                                            {displayFormattedNumber(
                                                this.summary
                                                    .total_owner_payments
                                                    ? this.summary
                                                          .total_owner_payments
                                                    : this.summary
                                                          .total_payments,
                                                this.currency
                                            )}
                                        </strong>
                                    </td>
                                    <td className="gutter-end" />
                                </tr>
                            </tfoot>
                        ) : null}
                    </table>
                </div>
                <div className="table-responsive">
                    <table
                        className={classNames(
                            "table",
                            this.state.openProceeds || this.printing
                                ? "open"
                                : ""
                        )}
                    >
                        <thead>
                            <tr
                                onClick={() =>
                                    this.setState({
                                        openProceeds: !this.state.openProceeds,
                                    })
                                }
                                className="pointer"
                            >
                                <td colSpan={4}>
                                    {this.state.openProceeds ||
                                    this.printing ? (
                                        <IconMinus className="icon-svg plus-minus" />
                                    ) : (
                                        <IconPlus className="icon-svg plus-minus" />
                                    )}
                                    <FormattedMessage
                                        id="Statements.proceeds"
                                        defaultMessage="Proceeds"
                                    />
                                </td>
                                <td colSpan={2} className="text-right">
                                    {displayFormattedNumber(
                                        totalCredits,
                                        this.currency
                                    )}
                                </td>
                            </tr>
                        </thead>
                        <tbody
                            className={
                                this.state.openProceeds || this.printing
                                    ? "show"
                                    : "hide"
                            }
                        >
                            {this.displayProceeds()}
                        </tbody>
                        {this.state.openProceeds ? (
                            <tfoot>
                                <tr>
                                    <td className="gutter" />
                                    <td colSpan={2} className="total-span">
                                        <strong>
                                            <FormattedMessage
                                                id="Statements.total"
                                                defaultMessage="Total"
                                            />
                                        </strong>
                                    </td>
                                    <td className="text-right">
                                        <strong>
                                            {displayFormattedNumber(
                                                totalCredits,
                                                this.currency
                                            )}
                                        </strong>
                                    </td>
                                    <td className="gutter-end" />
                                </tr>
                            </tfoot>
                        ) : null}
                    </table>
                </div>
                <div className="table-responsive">
                    <table className="table">
                        <tbody>
                            <tr className={styles["uppercase-header"]}>
                                <td>
                                    <FormattedMessage
                                        id="Statements.endingBalance"
                                        defaultMessage="Ending Balance"
                                    />
                                </td>
                                <td>
                                    {displayFormattedNumber(
                                        this.summary.ending_balance,
                                        this.currency
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}
