import { AxiosError } from "axios"
import { useMutation, UseMutationResult } from "react-query"
import {
    putDirectDepositUnenroll,
    PutDirectDepositUnenrollRequest,
    PutDirectDepositUnenrollResponse,
} from "./putDirectDepositUnenroll"

export const useDirectDepositUnenrollMutation = (): UseMutationResult<
    PutDirectDepositUnenrollResponse,
    AxiosError,
    PutDirectDepositUnenrollRequest,
    void
> => {
    return useMutation<
        PutDirectDepositUnenrollResponse,
        AxiosError,
        PutDirectDepositUnenrollRequest,
        void
    >(payload => putDirectDepositUnenroll(payload))
}
