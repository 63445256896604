const topbar = {
    myRentals: "Mes locations",
    myTrips: "Mes voyages",
    profile: "Profil",
    changePassword: "Modifier le mot de passe",
    logout: "Déconnexion",
    switchAccounts: "Switch Accounts",
    selectAccount: "Select An Account",
    referral: "Refer a friend",
}

export default topbar
