import classnames from "classnames"
import type { ReactNode } from "react"
import { getClassStyle } from "utils/styles/styleWrapper"

import styles from "./CardContent.module.scss"

type CardContentProps = {
    children: ReactNode
    className?: string
    noPaddingOnMobile?: boolean
    "data-testid"?: string
}

const CardContent = ({
    children,
    className,
    noPaddingOnMobile,
    "data-testid": testId,
}: CardContentProps): JSX.Element => (
    <div
        className={classnames(
            styles.content,
            {
                [getClassStyle(styles.noPaddingOnMobile)]: noPaddingOnMobile,
            },
            className
        )}
        data-testid={testId}
    >
        {children}
    </div>
)

export { CardContent }
export type { CardContentProps }
