import classNames from "classnames"
import styles from "./TaxClassificationForm.module.scss"
import { Label } from "../../../../lib/components/Label"
import { MenuItem, Select } from "@material-ui/core"
import { ChevronDown } from "react-feather"
import { w9, useValidation } from "hooks/validation"
import {
    FocusEvent,
    Ref,
    forwardRef,
    useImperativeHandle,
    useState,
} from "react"
import { FormRef } from "../types"
import { useIntl } from "react-intl"
import { useTaxInformationForm } from "../hooks"
import {
    getLLCTaxClassificationMessageDescriptor,
    getTaxClassificationMessageDescriptor,
} from "../utils"
export const TaxClassificationForm = forwardRef((_, ref: Ref<FormRef>) => {
    const {
        data: { taxClassification },
        setData,
    } = useTaxInformationForm()

    const [errors, setErrors] = useState(new Map<string, string>())
    const intl = useIntl()
    const { validate } = useValidation(w9.taxClassification(intl))

    const isFormValid = async (): Promise<boolean> => {
        const formErrors = await validate(taxClassification)
        setErrors(formErrors)
        return formErrors.size === 0
    }

    useImperativeHandle(ref, () => ({
        isFormValid,
    }))

    const onClassificationTypeChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        setData(prev => ({
            ...prev,
            taxClassification: {
                type: event.target.value as string,
                llcType: "",
            },
        }))
    }

    const onLLCClassificationTypeChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        setData(prev => ({
            ...prev,
            taxClassification: {
                ...prev.taxClassification,
                llcType: event.target.value as string,
            },
        }))
    }

    const onBlurValidation = async (e: FocusEvent<HTMLInputElement>) => {
        const fieldErrors = await validate(taxClassification, e.target.name)
        setErrors(fieldErrors)
    }

    return (
        <div className={classNames(styles.taxClassification, styles.container)}>
            <div
                className={classNames(
                    "type-heading-extra-small-caps text-midnight-70 font-extrabold",
                    styles.title
                )}
            >
                Tax information
            </div>
            <div className={styles.entityMessage}>
                The name/entity that you provide should be the same name/entity
                that is listed on your contract with Vacasa.
            </div>
            <div className={styles.infoMessage}>
                Please provide your taxpayer information so we can provide your
                tax preparation documents. Provide information that best
                describes your taxpayer status with the IRS.
            </div>
            <Label>Tax classification*</Label>
            <div>
                <Select
                    displayEmpty
                    value={taxClassification.type}
                    onChange={onClassificationTypeChange}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                        },
                        getContentAnchorEl: null,
                    }}
                    disableUnderline
                    IconComponent={ChevronDown}
                    error={!!errors.get("type")}
                    onBlur={onBlurValidation}
                >
                    <MenuItem value="">
                        <span className="text-dusk-lighter">
                            Select an option
                        </span>
                    </MenuItem>
                    {taxClassifications.map(value => (
                        <MenuItem value={value} key={value}>
                            {intl.formatMessage(
                                getTaxClassificationMessageDescriptor(value)
                            )}
                        </MenuItem>
                    ))}
                </Select>
                {errors.get("type") && (
                    <div
                        className={classNames(
                            "type-body-small-semibold",
                            "text-sunset"
                        )}
                    >
                        {errors.get("type")}
                    </div>
                )}
            </div>

            {taxClassification.type === "LLC" && (
                <div style={{ margin: "8px 0 0" }}>
                    <Select
                        displayEmpty
                        value={taxClassification.llcType}
                        onChange={onLLCClassificationTypeChange}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                            },
                            getContentAnchorEl: null,
                        }}
                        disableUnderline
                        IconComponent={ChevronDown}
                        error={!!errors.get("llcType")}
                        onBlur={onBlurValidation}
                    >
                        <MenuItem value="">
                            <span className="text-dusk-lighter">
                                Select an option
                            </span>
                        </MenuItem>
                        {llcTaxClassfications.map(value => (
                            <MenuItem value={value} key={value}>
                                {intl.formatMessage(
                                    getLLCTaxClassificationMessageDescriptor(
                                        value
                                    )
                                )}
                            </MenuItem>
                        ))}
                    </Select>
                    {errors.get("llcType") && (
                        <div
                            className={classNames(
                                "type-body-small-semibold",
                                "text-sunset"
                            )}
                        >
                            {errors.get("llcType")}
                        </div>
                    )}
                </div>
            )}
        </div>
    )
})

const taxClassifications: string[] = [
    "INP",
    "LLC",
    "CCO",
    "SCO",
    "PRT",
    "TRE",
    "OTH",
]

const llcTaxClassfications: string[] = ["C", "S", "P"]
