import { Rating } from "@material-ui/lab"
import classnames from "classnames"
import { REFERRAL_BANNER_DISMISSED } from "constants/preferences.constants"
import { useEffect, useState } from "react"
import {
    trackReferralBannerDismissClicked,
    trackReferralBannerLoaded,
    trackReferralBannerViewClicked,
} from "services/segment/referralBanner/referralBannerTracking"
import storageService from "services/storage.service"
import { ReactComponent as AppIcon } from "../../assets/app-icon.svg"
import { ReactComponent as CloseIcon } from "../../assets/icon-close.svg"
import { isMobileBrowser } from "../../utils/browser/browserUtil"
import styles from "./ReferralBanner.module.scss"

const ReferralBanner = (): JSX.Element => {
    const [showReferral, setShowReferral] = useState(false)

    const dynamicLink = `https://vacasaowners.page.link/?link=https://owners.vacasa.com/calendar&apn=com.vacasa.owners&ibi=com.vacasa.owners&isi=1572325511&efr=1`

    useEffect(() => {
        const usingMobileBrowser = isMobileBrowser()
        const wasDismissed =
            storageService.localStorage.getItem(REFERRAL_BANNER_DISMISSED) !==
            "true"
                ? false
                : true
        setShowReferral(usingMobileBrowser && !wasDismissed)
        if (usingMobileBrowser && !wasDismissed) {
            trackReferralBannerLoaded()
        }
    }, [])

    const onCloseClicked = () => {
        setShowReferral(false)
        storageService.localStorage.setItem(REFERRAL_BANNER_DISMISSED, "true")
        trackReferralBannerDismissClicked()
    }

    return (
        <>
            {showReferral && (
                <div className={classnames(styles.container)}>
                    <div className={classnames(styles.secondaryContainer)}>
                        <CloseIcon
                            className={classnames(styles.closeIcon)}
                            onClick={onCloseClicked}
                        />
                        <div className={classnames(styles.vacasaIconContainer)}>
                            <AppIcon />
                        </div>
                        <div className={classnames(styles.textContainer)}>
                            <span className={classnames(styles.title)}>
                                Homeowner App
                            </span>
                            <span className={classnames(styles.subTitle)}>
                                Vacasa LLC
                            </span>
                            <Rating
                                className={classnames(styles.stars)}
                                name="size-small"
                                defaultValue={5}
                                size="small"
                                readOnly
                            />
                            <span className={classnames(styles.description)}>
                                GET - On the App Store
                            </span>
                        </div>
                        <div className={classnames(styles.linkContainer)}>
                            <a
                                href={dynamicLink}
                                className={classnames(styles.link)}
                                onClick={trackReferralBannerViewClicked}
                            >
                                View
                            </a>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export { ReferralBanner }
