import { ChangeEvent, ReactNode, useCallback, useEffect, useState } from "react"
// Services
import loggingService from "../../services/logging/logging.service"

// Components
import EmptyState from "../../lib/components/EmptyState"
import Loader from "../../lib/components/Loader"
import Toggle from "../../lib/components/Toggle/Toggle"

import classnames from "classnames"
import {
    useReceiveFormsElectronicallyMutation,
    useTaxForms,
} from "hooks/finance"
import { useContactId } from "hooks/user"
import { GeneralError } from "lib/components/GeneralError/GeneralError"
import { useToastNotification } from "lib/components/ToastNotification"
import { GoPaperlessToggle } from "lib/components/Toggle/ToggleType"
import { observer } from "mobx-react"
import { FormattedMessage } from "react-intl"
import {
    trackOwnerTaxFormViewed,
    trackOwnerTaxFormsDeliverMethod,
} from "services/segment/taxes/taxesTracking"
import { Card, CardContent } from "../../components/Card"
import styles from "./FormsFromUs.module.scss"
import sharedStyles from "./Taxes.module.scss"
import { useUser } from "hooks/user"
import { useInvalidateContact } from "hooks/contact/useContact"
const FormsFromUs = () => {
    const { user } = useUser()
    const invalidateContact = useInvalidateContact()
    const { addToastNotification } = useToastNotification()
    const goPaperless = !user?.accountInfo?.tax1099EmailOptOut ?? false
    const [paperless, setPaperless] = useState(goPaperless)

    const taxFormsQuery = useTaxForms()
    const contactId = useContactId() ?? ""
    const { mutate } = useReceiveFormsElectronicallyMutation()

    // force update when user is refreshed
    useEffect(() => {
        setPaperless(!user?.accountInfo?.tax1099EmailOptOut ?? false)
    }, [user])

    const receiveElectronicallyReaction = useCallback(
        (receiveElectronically: boolean) => {
            loggingService.log({
                message: `Recieve IRS tax forms electronically: ${
                    receiveElectronically ? "yes" : "no"
                }`,
            })
            trackOwnerTaxFormsDeliverMethod(Boolean(receiveElectronically))

            // Note the inverted value: there is a bug in
            // api/v1/legacy/owners/{id}/receive-forms-electronically endpoint where the value is inverted
            mutate(
                {
                    contactId,
                    receiveElectronically: !receiveElectronically,
                },
                {
                    onSuccess: () => {
                        addToastNotification({
                            content: receiveElectronically ? (
                                <FormattedMessage
                                    id="Taxes.paperlessNotification"
                                    defaultMessage="Tax forms will be sent electronically"
                                />
                            ) : (
                                <FormattedMessage
                                    id="Taxes.paperFormNotification"
                                    defaultMessage="Tax forms will be mailed"
                                />
                            ),
                        })
                        invalidateContact(contactId)
                    },
                }
            )
        },
        [addToastNotification, contactId, invalidateContact, mutate]
    )

    const onPaperlessToggled = (e: ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.target
        setPaperless(checked)
        receiveElectronicallyReaction(checked)
    }

    const toggleView = (
        <div
            className={classnames(
                styles["toggle-container"],
                "align-flex-horizontal"
            )}
        >
            <Toggle
                checked={paperless}
                toggleInfo={GoPaperlessToggle}
                onChange={onPaperlessToggled}
            />
            <p style={{ margin: "0 0 0 8px" }}>
                {paperless ? (
                    <FormattedMessage
                        id="Taxes.paperlessOn"
                        defaultMessage="On"
                    />
                ) : (
                    <FormattedMessage
                        id="Taxes.paperlessOff"
                        defaultMessage="Off"
                    />
                )}
            </p>
        </div>
    )

    const goPaperlessView = (
        <div className={styles["go-paperless"]}>
            <h3
                className={classnames(
                    styles["go-paperless-title"],
                    "type-heading-small"
                )}
            >
                <FormattedMessage
                    id="Taxes.goPaperless"
                    defaultMessage="Go paperless"
                />
            </h3>
            <p>
                <FormattedMessage
                    id="Taxes.paperlessExplanation"
                    defaultMessage="Paperless enables you to receive your tax preparation forms via electronic delivery only. You will not be mailed paper copies of your tax forms."
                />
            </p>
            {toggleView}
        </div>
    )

    const taxFormsView = (
        <ul
            className="list-no-style"
            style={{ borderTop: "1px solid rgba(0,0,0,0.1)" }}
        >
            {taxFormsQuery.isSuccess && taxFormsQuery.data
                ? taxFormsQuery.data.map(taxForm => {
                      return (
                          <li
                              key={taxForm.formID}
                              style={{
                                  lineHeight: "57px",
                                  borderBottom: "1px solid rgba(0,0,0,0.1)",
                              }}
                          >
                              {taxForm.year}
                              <a
                                  href={taxForm.url}
                                  onClick={() => {
                                      loggingService.log({
                                          message: `Viewed ${taxForm.year} ${taxForm.formName}`,
                                      })
                                      trackOwnerTaxFormViewed(
                                          taxForm.year.toString(),
                                          taxForm.formName
                                      )
                                  }}
                                  style={{ marginLeft: "17px" }}
                              >
                                  {taxForm.formName}
                              </a>
                          </li>
                      )
                  })
                : null}
        </ul>
    )

    const formsToBeElectronicView = (
        <>
            {taxFormsQuery.isSuccess && taxFormsQuery.data.length > 0 ? (
                taxFormsView
            ) : (
                <EmptyState
                    snippet={
                        <FormattedMessage
                            id="Taxes.noForms"
                            defaultMessage="You have no forms yet."
                        />
                    }
                    classes={["panel-empty", "panel-empty--small"]}
                    fontSizeClass={"type-body-medium"}
                />
            )}
        </>
    )

    return (
        <>
            <Card>
                <CardContent>
                    <h3 className="type-heading-medium">
                        <FormattedMessage
                            id="Taxes.getForms"
                            defaultMessage="Get your tax preparation forms"
                        />
                    </h3>
                    {taxFormsQuery.isLoading && (
                        <div className={sharedStyles.loadingContainer}>
                            <Loader />
                        </div>
                    )}

                    {taxFormsQuery.isError && (
                        <div className="container-fluid">
                            <div className="content-section">
                                <GeneralError
                                    handleClick={() => taxFormsQuery.refetch()}
                                />
                            </div>
                        </div>
                    )}

                    {taxFormsQuery.isSuccess && (
                        <>
                            <div className="panel-box__subheader">
                                <p>
                                    <FormattedMessage
                                        id="Taxes.expecting1099"
                                        defaultMessage="If you're expecting a <b>Form 1099-MISC</b>, it will be available by <b>January 31</b>. "
                                        values={{
                                            b: (chunks: ReactNode) => (
                                                <strong>{chunks}</strong>
                                            ),
                                        }}
                                    />
                                    <FormattedMessage
                                        id="Taxes.expecting1042"
                                        defaultMessage="If you're expecting a <b>Form 1042-S</b>, it will be available by <b>March 15</b>. "
                                        values={{
                                            b: (chunks: ReactNode) => (
                                                <strong>{chunks}</strong>
                                            ),
                                        }}
                                    />
                                </p>
                            </div>
                            <div style={{ marginBottom: 40 }}>
                                {formsToBeElectronicView}
                            </div>
                            {goPaperlessView}
                        </>
                    )}
                </CardContent>
            </Card>
        </>
    )
}

export default observer(FormsFromUs)
