import { UnitMinRate, JSONAPIDocument } from "@vacasa/owner-api-models"
import { authAxios } from "../../api/authAxios"

interface FetchUnitMinRateRequest {
    unitId: string | undefined | null
    signal?: AbortSignal
}

export type UnitMinRateResponse = JSONAPIDocument<UnitMinRate>

const fetchUnitMinRate = ({
    unitId,
    signal,
}: FetchUnitMinRateRequest): Promise<UnitMinRateResponse> =>
    authAxios
        .get<UnitMinRateResponse>(`v1/units/${unitId}/min-rate`, { signal })
        .then(response => response.data)

export { fetchUnitMinRate }
