const support = {
    webChat: {
        messageCanvasTrayButton: "Start New Chat",
        messageCanvasTrayContent: "Thanks for chatting with us!",
        predefinedChatMessageAuthorName: "Vacasa Owner Service",
        typingIndicator: "Typing...",
        welcomeMessage:
            "Live chat is available daily from {open} - {close} PST",
        offlineMessage:
            "Unfortunately you have reached us outside of live chat hours. For immediate assistance reach out to account to the contact below",
        liveChatHours: "{open} to {close} PST • 7 days a week",
    },
}

export default support
