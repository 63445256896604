import { TicketResponse } from "@vacasa/owner-api-models"
import { DisplayStatus } from "@vacasa/owner-api-models"
import { FC, memo } from "react"
import { useIntl } from "react-intl"
import styles from "./DetailsBox.module.scss"
import classnames from "classnames"
import { ReactComponent as NotesIcon } from "./../../assets/icon-notes.svg"
import { ReactComponent as SprayBottleIcon } from "./../../assets/icon-spray-bottle.svg"
import StaticListItem from "lib/components/FlyOut/Holds/StaticListItem/StaticListItem"
import { format, parseISO } from "date-fns"
import { TicketStatusBadge, TicketStatusProps } from "lib/components/Badge"
import { useCurrentUnit } from "hooks/units"

interface Props {
    ticket: TicketResponse
}

const DetailsBox: FC<Props> = ({ ticket }) => {
    const intl = useIntl()
    const { unit } = useCurrentUnit()

    const getRentalName = () => {
        return unit?.attributes.name
    }

    const getStatusClassname = (): string | undefined => {
        if (!ticket.attributes.displayStatus) {
            return styles.submitted
        }
        switch (ticket.attributes.displayStatus) {
            case DisplayStatus.Submitted:
                return styles.submitted
            case DisplayStatus.InProgress:
                return styles.inProgress
            case DisplayStatus.Closed:
                return styles.closed
            case DisplayStatus.Cancelled:
                return styles.cancelled
        }
    }

    const getStatus = (): TicketStatusProps["status"] => {
        switch (ticket.attributes.displayStatus) {
            case DisplayStatus.InProgress:
                return "in-progress"
            case DisplayStatus.Closed:
                return "closed"
            case DisplayStatus.Cancelled:
                return "cancelled"
            case DisplayStatus.Submitted:
            default:
                return "submitted"
        }
    }

    const formatFullDateTime = (dateTime: string): string => {
        return format(parseISO(dateTime), "MMM d, yyyy • h:mmaaa")
    }

    const getTicketTime = (ticket: TicketResponse) => {
        const isReopenedTicket= !!ticket.attributes.lastReopen

        switch (ticket.attributes.displayStatus) {
            case DisplayStatus.Closed:
                return intl.formatMessage(
                    {
                        id: "Maintenance.closedOn",
                        defaultMessage: "Closed on {date}",
                    },
                    {
                        date: formatFullDateTime(
                            ticket.attributes.completedDate ?? ""
                        ),
                    }
                )
            case DisplayStatus.Cancelled:
                return intl.formatMessage({
                    id: "Maintenance.cancelled",
                    defaultMessage: "Cancelled",
                })
            case DisplayStatus.Submitted:
            case DisplayStatus.InProgress:
            default:
                return (
                    isReopenedTicket?
                        intl.formatMessage(
                            {
                                id: "Maintenance.reopenedOn",
                                defaultMessage: "Reopened on {date}",
                            },
                            {
                                date: formatFullDateTime(
                                    ticket.attributes.lastReopen ?? ""
                                ),
                            }
                        ):
                        intl.formatMessage(
                            {
                                id: "Maintenance.submittedOn",
                                defaultMessage: "Submitted on {date}",
                            },
                            {
                                date: formatFullDateTime(
                                    ticket.attributes.creationDate ?? ""
                                ),
                            }
                        )
                )
        }
    }

    if (String(unit?.id) !== String(ticket.attributes.unitId)) return null

    return (
        <>
            <div className={styles.header}>
                <div className={styles.unitName}>{getRentalName()}</div>
                <div
                    className={classnames(styles.status, getStatusClassname())}
                >
                    <TicketStatusBadge status={getStatus()} />
                </div>
                <div className={styles.ticketTime}>{getTicketTime(ticket)}</div>
                <div className={styles.ticketTitle}>
                    {ticket.attributes.title}
                </div>
            </div>

            <StaticListItem
                title={intl.formatMessage({
                    id: "Maintenance.requestNumber",
                    defaultMessage: "Request #",
                })}
                subtext={String(ticket.id)}
                Icon={SprayBottleIcon}
            />
            <StaticListItem
                title={intl.formatMessage({
                    id: "Maintenance.note",
                    defaultMessage: "Note",
                })}
                subtext={ticket.attributes.description}
                Icon={NotesIcon}
            />
        </>
    )
}

export default memo(DetailsBox)
