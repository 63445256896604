import { Reservation, ReservationType } from "@vacasa/owner-api-models"
import classNames from "classnames"
import { differenceInDays, format, parseISO } from "date-fns"
import { useBookingProbability } from "hooks/booking-probability"
import React from "react"
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl"
import { trackReservationRowClicked } from "services/segment/performance/performanceTracking"
import { showBookingProbabilityIndicator } from "utils/bookingProbability/bookingProbabilityUtil"
import getInitialFromName from "utils/name/getInitialFromName"
import { getDisplayNameFromOccupancyType } from "utils/reservations/reservationsUtil"
import styles from "./ReservationRow.module.scss"
import { ReservationDate } from "lib/components/ReservationDate"

interface Props {
    reservation: Reservation
    bookingProbabilityEnabled: boolean
    currency: string
}

const ReservationRow: React.FC<Props> = ({
    reservation,
    bookingProbabilityEnabled,
    currency,
}: Props) => {
    const intl = useIntl()
    const {
        id,
        unitID,
        firstName,
        lastName,
        startDate,
        endDate,
        occupancyType,
        monthlyRentBreakdown,
    } = reservation
    const bookingProbabilityQuery = useBookingProbability(
        occupancyType === ReservationType.OWNERHOLD ? unitID?.toString() : null,
        parseISO(startDate ?? ""),
        parseISO(endDate ?? "")
    )
    const showZap = showBookingProbabilityIndicator(bookingProbabilityQuery)
    const lastNameInitial = getInitialFromName(lastName) ?? ""
    const bookingName = firstName ? `${firstName} ${lastNameInitial}.` : ""
    const showBookingProbabilityIcon = bookingProbabilityEnabled && showZap
    const reservationTypeName = intl.formatMessage(
        getDisplayNameFromOccupancyType(occupancyType ?? undefined)
    )

    if (!startDate || !endDate) {
        return null
    }

    const currentMonth = format(new Date(), "M")
    const rentBreakdownForCurrentMonth = monthlyRentBreakdown.find(
        rent => rent.month === Number(currentMonth)
    )
    const nightsForCurrentMonth = rentBreakdownForCurrentMonth?.nights
    const netRent = rentBreakdownForCurrentMonth?.netRent
    const totalNights = differenceInDays(parseISO(endDate), parseISO(startDate))
    const totalNightsText =
        nightsForCurrentMonth && totalNights > nightsForCurrentMonth
            ? ` (${totalNights} total)`
            : ""

    const nightsText = intl.formatMessage(
        {
            id: "Performance.nightsOccupied.totalNights",
            defaultMessage:
                "{nightCount, plural, one {# night} other {# nights}}{totalNightsText}",
        },
        {
            nightCount: nightsForCurrentMonth,
            totalNightsText: totalNightsText,
        }
    )

    return (
        <div
            className={styles.reservationRow}
            id={id.toString()}
            onClick={() => {
                window.open(`/calendar/hold/${id}`)
                trackReservationRowClicked(id)
            }}
        >
            <div className={classNames(styles.rowContent)}>
                <div className={classNames(styles.col)}>
                    <ReservationDate
                        date={parseISO(startDate)}
                        occupancyType={
                            occupancyType ?? ReservationType.OWNERHOLD
                        }
                        showBookingProbabilityIcon={showBookingProbabilityIcon}
                    />

                    <div className={styles.summary}>
                        <div
                            className={classNames(
                                styles.occupancyType,
                                "leading-none"
                            )}
                        >
                            {reservationTypeName}
                        </div>
                        <div
                            className={classNames(
                                styles.nightsText,
                                "leading-none"
                            )}
                        >
                            {nightsText}
                            {occupancyType === ReservationType.RESERVATION &&
                                bookingName && <> &#x2022; {bookingName}</>}
                        </div>
                    </div>
                </div>
                <div className={styles.col}>
                    <div className={styles.rentWrap}>
                        <span className={styles.rent}>
                            <FormattedMessage
                                id="Performance.nightsOccupied.netRent"
                                defaultMessage="Net rent"
                            />
                        </span>
                        <span className={styles.rent__number}>
                            <FormattedNumber
                                value={netRent ?? 0}
                                currency={currency}
                                // eslint-disable-next-line react/style-prop-object
                                style="currency"
                            />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { ReservationRow }
