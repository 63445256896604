import classnames from "classnames"
import type { ReactNode } from "react"

import styles from "./CardGroupTitle.module.scss"

type CardGroupTitleProps = {
    children: ReactNode
    className?: string
}

const CardGroupTitle = ({
    children,
    className,
}: CardGroupTitleProps): JSX.Element => (
    <div
        className={classnames(
            styles.groupTitle,
            "type-heading-extra-small-caps",
            className
        )}
    >
        {children}
    </div>
)

export { CardGroupTitle }
