import { JSONAPIResource, Reservation } from "@vacasa/owner-api-models"
import classNames from "classnames"
import { useOwnerFeatureFlag } from "hooks/owner-feature-flag"
import { FormattedMessage } from "react-intl"
import Loader from "../../../lib/components/Loader"
import {
    reservationHasEnded,
    showNetRent,
} from "../../../utils/reservations/reservationsUtil"
import { ReservationRow } from "./ReservationRow"
import { StayType } from "./Reservations"
import styles from "./ReservationsList.module.scss"
import { isFeatureFlagEnabled } from "utils/feature-flag"
import { useUserCurrencyCode } from "hooks/user"
import { useUnitStatuses } from "hooks/units"

interface ReservationListProps {
    clickHandler: (reservation: JSONAPIResource<Reservation>) => void
    isSuperUnit: boolean
    reservations: JSONAPIResource<Reservation>[]
    stayType: StayType
    updatingCalendar: boolean
    bookingProbabilityEnabled: boolean
}

const ReservationList = ({
    stayType,
    updatingCalendar,
    reservations,
    isSuperUnit,
    clickHandler,
    bookingProbabilityEnabled,
}: ReservationListProps): JSX.Element => {
    const currency = useUserCurrencyCode()
    const { isFixedRent } = useUnitStatuses()
    const showReservationNetRentFeatureFlag = useOwnerFeatureFlag(
        "show-reservation-net-rent"
    )
    const showReservationNetRentEnabled = isFeatureFlagEnabled(
        showReservationNetRentFeatureFlag
    )
    const shouldShowNetRent = showNetRent(
        reservations,
        showReservationNetRentEnabled
    )

    return (
        <div className={styles.container}>
            {updatingCalendar && (
                <div className="generic-blocking-overlay absolute">
                    <Loader />
                </div>
            )}

            <div className={styles.list}>
                <div className={styles.listHeadings}>
                    <h5 className={styles.listHeading}>
                        <FormattedMessage
                            id="CalendarPage.flyout.date"
                            defaultMessage="Date"
                            description="Reservation date"
                        />
                    </h5>
                    <h5
                        className={classNames(
                            styles.listHeading,
                            styles.hideMobileView
                        )}
                    >
                        <FormattedMessage
                            id="CalendarPage.UpcomingStays.details"
                            defaultMessage="Details"
                            description="Reservation Details"
                        />
                    </h5>
                    {shouldShowNetRent ? (
                        <h5
                            className={classNames(
                                styles.listHeading,
                                styles.hideMobileView
                            )}
                        >
                            <FormattedMessage
                                id="CalendarPage.UpcomingStays.netRent"
                                defaultMessage="Net rent"
                                description="Reservation Net rent"
                            />
                        </h5>
                    ) : (
                        <h5
                            className={classNames(
                                styles.listHeading,
                                styles.hideMobileView
                            )}
                        >
                            <FormattedMessage
                                id="CalendarPage.UpcomingStays.grossRent"
                                defaultMessage="Gross rent"
                                description="Reservation Gross rent"
                            />
                        </h5>
                    )}
                </div>

                {reservations.map(reservation => (
                    <ReservationRow
                        key={reservation.id}
                        reservation={reservation}
                        onClick={clickHandler}
                        isSuperUnit={isSuperUnit}
                        currency={currency}
                        isFixedRent={isFixedRent}
                        showGuestRating={reservationHasEnded(
                            reservation.attributes
                        )}
                        stayType={stayType}
                        showNetRent={shouldShowNetRent}
                        bookingProbabilityEnabled={bookingProbabilityEnabled}
                    />
                ))}
            </div>
        </div>
    )
}

export default ReservationList
