export interface ToggleInfo {
    inputID: string
    inputValue: string
    inputName: string
    labelID: string
    ariaLabel: string // Required for accessibility purposes
}
export const GoPaperlessToggle: ToggleInfo = {
    inputID: "goPaperlessToggle",
    inputValue: "on",
    inputName: "goPaperlessToggle",
    labelID: "goPaperlessToggleLabel",
    ariaLabel: "paperless toggle",
}
