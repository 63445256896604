/* eslint-disable camelcase */
// PAGE PATH
export const CALENDAR_PAGE_PATH = "/calendar"
// Event
export const BUTTON_CLICK_EVENT = "Click"
export const LIVE_UNIT_CONGRATS_EVENT = "Live Unit Congrats"
// Action
export const MODAL_DISMISS_ACTION = "modal_dismiss"
export const MODAL_POPUP_ACTION = "modal_popup"
export const VIEW_LISTING_ACTION = "view_listing"
// Click Track Type
export const LINK_TYPE = "link"
// Click Track Label
export const HOMEOWNER_WEBINAR_BANNER = "homeowner_webinar_banner"

// Click reservation on calendar
export const CALENDAR_RESERVATION_DATE_CLICK = "calendar_reservation_date_click"
