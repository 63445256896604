import { JSONAPIDocument } from "@vacasa/owner-api-models"
import Passcode from "@vacasa/owner-api-models/src/Passcode"
import { authAxios } from "../../api/authAxios"

type FetchLockCodeRequest = {
    reservationId: string
    unitId: string
    signal?: AbortSignal
}
export type PasscodeResponse = JSONAPIDocument<Passcode>

const fetchSmartHomeLockCode = ({
    reservationId,
    unitId,
    signal,
}: FetchLockCodeRequest): Promise<PasscodeResponse> =>
    authAxios
        .get<PasscodeResponse>(
            `v1/reservations/${reservationId}/owner-holds/passcode`,
            {
                params: {
                    unitID: unitId,
                },
                signal,
            }
        )
        .then(response => response.data)
        .catch(error => error.message)

export { fetchSmartHomeLockCode }
