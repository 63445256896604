import { isFinite } from "lodash"
import { ButtonLoader } from "../../lib/components/Loader/ButtonLoader/ButtonLoader"
import {
    addMonths,
    addYears,
    endOfYear,
    getMonth,
    getYear,
    isValid,
    startOfYear,
} from "date-fns"
const CALENDAR_YEAR_PADDING = 9

export function getCalendarYearWidth(monthWidthSize: string | number): number {
    const monthWidthInt =
        typeof monthWidthSize === "string"
            ? parseInt(monthWidthSize, 10)
            : monthWidthSize
    // handle edge case
    if (!isFinite(monthWidthInt)) return 0

    return 3 * (monthWidthInt + 1) + 2 * (CALENDAR_YEAR_PADDING + 1)
}

export function getCalendarYearMonths(year: Date): Date[][] {
    // handle edge case
    if (!isValid(year)) return []

    // set utc offset to get correct dates in future (when timezone changes)
    const baseDate = new Date(year.getTime())
    const firstMonth = startOfYear(baseDate)
    const lastMonth = endOfYear(baseDate)

    let currentMonth = new Date(firstMonth.getTime())
    let currentLine = []
    const monthsInYear = []

    let offset = 0
    while (currentMonth < lastMonth) {
        currentLine.push(new Date(currentMonth.getTime()))
        currentMonth = addMonths(currentMonth, 1)
        offset += 1

        if (offset === 3) {
            monthsInYear.push(currentLine)
            currentLine = []
            offset = 0
        }
    }

    return monthsInYear
}

export function isSameMonth(a?: Date, b?: Date): boolean {
    if (!a || !b || !isValid(a) || !isValid(b)) return false
    // Compare least significant, most likely to change units first
    return getMonth(a) === getMonth(b) && getYear(a) === getYear(b)
}

export function isBeforeMonth(a?: Date, b?: Date): boolean {
    if (!a || !b || !isValid(a) || !isValid(b)) return false

    const aYear = getYear(a)
    const aMonth = getMonth(a)

    const bYear = getYear(b)
    const bMonth = getMonth(b)

    const isSameYear = aYear === bYear

    if (isSameYear) return aMonth < bMonth
    return aYear < bYear
}

export function isAfterMonth(a?: Date, b?: Date): boolean {
    if (!a || !b || !isValid(a) || !isValid(b)) return false
    return !isBeforeMonth(a, b) && !isSameMonth(a, b)
}

export function isMonthVisible(
    month: Date,
    year: Date,
    numberOfYears: number
): boolean {
    const firstDayOfYear = startOfYear(new Date(year))
    if (isBeforeMonth(month, firstDayOfYear)) return false

    const lastDayOfLastYear = endOfYear(
        addYears(new Date(year), numberOfYears - 1)
    )
    return !isAfterMonth(month, lastDayOfLastYear)
}

export interface ExtendButtonProps {
    isLoading: boolean
    disabled: boolean
    onClick?: () => void
    text: string
    type?: string
}

export interface ExtendButton {
    isLoading: boolean
    disabled: boolean
    text: string
    preset: string
    onClick?: () => void
    loader: JSX.Element
}

export function extendButtonDefaults(props: ExtendButtonProps): ExtendButton {
    let btnText = "Save"
    let btnType = "primary"
    if (props.text) {
        btnText = props.text
    }
    if (props.type) {
        btnType = props.type
    }
    const defaults = {
        preset: btnType,
        text: btnText,
        disabled: props.disabled,
        isLoading: props.isLoading,
        loader: (
            <ButtonLoader
                indicator={"dot"}
                indicatorCount={3}
                animation={"blink"}
            />
        ),
        onClick: props.onClick,
    }
    return defaults
}
