import { createContext, useContext } from "react"
import { DateRangeAction } from "./DateRange.actions"
import { useDateRangeState } from "./DateRange.reducer"
import { DateRangeState, INITIAL_DATE_RANGE_STATE } from "./DateRange.state"

/**
 * Expose DateRangeState and dispatch methods,
 * so that any component consuming the context has access to calendar functionality
 * without need for refs.
 */

interface DateRangeContextItems {
    dispatchDateRangeAction: React.Dispatch<DateRangeAction>
    dateRangeState: DateRangeState
}

export const DateRangeContext = createContext<DateRangeContextItems>({
    dispatchDateRangeAction: () => null,
    dateRangeState: INITIAL_DATE_RANGE_STATE,
})

export function useDateRangeContext(): DateRangeContextItems {
    return useContext(DateRangeContext)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function DateRangeProvider({ ...props }): JSX.Element {
    const [dateRangeState, dispatchDateRangeAction] = useDateRangeState(
        INITIAL_DATE_RANGE_STATE
    )
    return (
        <DateRangeContext.Provider
            {...props}
            value={{ dateRangeState, dispatchDateRangeAction }}
        />
    )
}
