import { useOnboardingStatus } from "hooks/onboarding/use-onboarding-status"
import { useUnitsOnboarding, useUnits } from "hooks/units"
import { observer } from "mobx-react"
import { FC, ReactNode, useEffect } from "react"
import { FormattedMessage } from "react-intl"
import { Link, Outlet } from "react-router-dom"
import { trackSetupPage } from "services/segment/onboarding/onboardingTracking"
import { checkIfOnboardingAppUser } from "utils/onboarding"
import { OnboardingStatus, getUnitOnboardingStatus } from "utils/unit"
import { Alert } from "../../components/Alert"
import { DISPLAY_CONGRATS_MODAL } from "../../constants/preferences.constants"
import { useW9TaxSubmission } from "../../hooks/taxes-w9-submission/useW9TaxSubmission"
import styles from "../../sass/onboarding.module.scss"
import { useRootService } from "../../services"
import { GettingStartedPanelV2 } from "./GettingStartedPanelV2"
import { HomeAvailabilityAlert } from "./HomeAvailabilityAlert/HomeAvailabilityAlert"
import NeedHelpPanel from "./NeedHelpPanel"
import ToDoListPanel from "./ToDoListPanel"
import { WelcomePanelV2 } from "./WelcomePanel/WelcomePanelV2"
import { useUser } from "hooks/user"

const Setup: FC = observer(() => {
    const unitsQuery = useUnits()
    const { preferenceService } = useRootService()
    const onboardingUnits = useUnitsOnboarding()
    const { user } = useUser()
    const w9TaxSubmissionQuery = useW9TaxSubmission()

    const onboardingStatus = useOnboardingStatus()
    const isOnboardingAppUser = checkIfOnboardingAppUser(onboardingStatus)

    useEffect(() => {
        if (!isOnboardingAppUser) return
        window.location.replace("/onboarding")
    }, [isOnboardingAppUser])

    useEffect(() => {
        setShouldShowCongratsModal()
        trackSetupPage()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onboardingStatuses = onboardingUnits.map(unit =>
        getUnitOnboardingStatus(unit)
    )
    // TODO this logic belongs in separate service,
    // perhaps a new onboarding service or existing unit service
    const showUnderReview =
        onboardingUnits.find(unit => unit.attributes.hasOwnerEditAccess) !==
        undefined
    const showWelcomeView =
        !showUnderReview &&
        (onboardingUnits.length === 0 ||
            onboardingStatuses.find(
                status => status === OnboardingStatus.PreparingListing
            ) !== undefined)
    // Checks if there is more than 1 unit so the progress bar shows correctly
    const isMultipleUnits = onboardingUnits.length > 1
    function setShouldShowCongratsModal(): void {
        onboardingUnits.forEach(unit => {
            const preferences = preferenceService.getOwnerUnitPreferences(
                String(unit.id)
            )
            const showModalPref = preferences[String(unit.id)]?.find(pref => {
                return pref.id === DISPLAY_CONGRATS_MODAL
            })
            if (showModalPref?.attributes.value === null) {
                const updatedPreference = {
                    ...showModalPref,
                    id: DISPLAY_CONGRATS_MODAL,
                    attributes: {
                        updated: null,
                        value: true,
                    },
                }
                preferenceService.patchOwnerUnitPreference(
                    String(unit.id),
                    updatedPreference
                )
            }
        })
    }

    function renderToDoPanels(): JSX.Element[] {
        if (onboardingUnits.length === 0) {
            return [<ToDoListPanel key={"noUnits"} unit={undefined} />]
        }
        return onboardingUnits.map((unit, key) => (
            <ToDoListPanel
                key={key}
                unit={unit}
                showProgressBar={isMultipleUnits}
            />
        ))
    }

    const showW9TaxAlert =
        w9TaxSubmissionQuery.isSuccess &&
        !w9TaxSubmissionQuery.data.hasSubmittedW9 &&
        user?.accountInfo.country === "US"

    if (onboardingStatus.isLoading || isOnboardingAppUser) return <></>

    return (
        <div className={styles.onboardingContainer} id="setup-page">
            <div className={styles.container}>
                <Outlet />
                {unitsQuery.isSuccess &&
                    unitsQuery.data
                        .filter(
                            unit =>
                                unit.attributes.active &&
                                unit.attributes.status.onboarding !==
                                    OnboardingStatus.None
                        )
                        .map(unit => (
                            <HomeAvailabilityAlert
                                key={unit.id}
                                unitId={
                                    unit.id as number // TODO
                                }
                                to={`/setup/${unit.id}/home-availability`}
                            />
                        ))}
                {showW9TaxAlert && (
                    <div className={styles.alertContainer}>
                        <Alert
                            variant="alertness"
                            as={Link}
                            to="/taxes"
                            clickable
                        >
                            <Alert.Icon />
                            <Alert.Description>
                                <FormattedMessage
                                    id="Setup.submitTaxPayerForm"
                                    defaultMessage="<title>Submit a taxpayer form.</title> We must receive this in order to provide year-end tax documents and yearly rental data."
                                    values={{
                                        title: (chunks: ReactNode) => (
                                            <Alert.Title>{chunks}</Alert.Title>
                                        ),
                                    }}
                                />
                            </Alert.Description>
                            <Alert.LinkIcon />
                        </Alert>
                    </div>
                )}

                <h1 className={styles.onboardingTitle}>
                    <FormattedMessage
                        id="Onboarding.title"
                        defaultMessage="Setup"
                    />
                </h1>

                {showWelcomeView ? (
                    <WelcomePanelV2 />
                ) : (
                    <GettingStartedPanelV2
                        isUnderReview={showUnderReview}
                        onboardingUnits={onboardingUnits}
                        showProgressBar={!isMultipleUnits}
                    />
                )}
                {renderToDoPanels()}
                {onboardingUnits[0] && (
                    <NeedHelpPanel unit={onboardingUnits[0]} />
                )}
            </div>
        </div>
    )
})

export default Setup
