import { FormattedMessage } from "react-intl"
import { format, setYear, setMonth, startOfMonth } from "date-fns"

interface MonthYearDisplayProps {
    year?: number
    month?: number
}
/**
 * Shows Month & Year formatted for the Month Picker
 * @param year Selected year
 * @param month Selected month, zero indexed. If omitted, year only display is assumed
 */
const MonthYearDisplay = (props: MonthYearDisplayProps): JSX.Element => {
    const { month, year } = props
    let displayDate = startOfMonth(new Date())
    if (year) displayDate = setYear(displayDate, year)
    const displayYear = format(displayDate, "yyyy")
    if (month !== undefined) {
        displayDate = setMonth(displayDate, month)
        const displayMonth = format(displayDate, "MMM")
        return (
            <span
                data-testid={"month-year-display"}
            >{`${displayMonth} ${displayYear}`}</span>
        )
    } else {
        return (
            <span data-testid="year-end-statement-display">
                <FormattedMessage
                    id="Statements.statementYearSummary"
                    defaultMessage="{year} Year Summary"
                    values={{ year: displayYear }}
                />
            </span>
        )
    }
}

export default MonthYearDisplay
