import {
    JSONAPIResource,
    Reservation,
    ReservationGuest,
} from "@vacasa/owner-api-models"
import { AxiosError } from "axios"
import { useQuery, UseQueryResult } from "react-query"
import { RESERVATION_GUESTS_QUERY_KEY } from "./constants"
import { fetchReservationGuests } from "./fetchReservationGuests"

export type ReservationResponse = {
    data: JSONAPIResource<Reservation>
}

export const useReservationGuests = (
    reservationId: string | number | undefined,
    enabled = true
): UseQueryResult<JSONAPIResource<ReservationGuest>[], AxiosError> => {
    return useQuery(
        [RESERVATION_GUESTS_QUERY_KEY, reservationId],
        ({ signal }) =>
            fetchReservationGuests({
                reservationId,
                signal,
            }),
        {
            enabled: !!reservationId && enabled,
            refetchInterval: 1000 * 60 * 30, // 30 mins
            staleTime: 1000 * 60 * 30, // 30 mins,
        }
    )
}
