import { FC } from "react"
import Badge, { BadgeProps } from "../Badge"
import styles from "./ProductTourCard.module.scss"
import classNames from "classnames"

export type ProductTourCardProps = {
    page: string
    badge?: BadgeProps
    title: string
    body: string
    image: string
    largeImage?: boolean
    className?: string
}

export const ProductTourCard: FC<ProductTourCardProps> = props => {
    const { page, title, badge, body, image, className, largeImage } = props
    return (
        <div className={classNames(styles.container, className)}>
            <div className={styles.media}>
                <img
                    className={classNames({
                        [styles.largeImage as string]: largeImage,
                    })}
                    src={image}
                    alt={title}
                />
            </div>
            <div className={styles.content}>
                <div className={styles.meta}>
                    <Badge {...badge} />
                    <span className={styles.page}>{page}</span>
                </div>
                <h2 className={styles.title}>{title}</h2>
                <p>{body}</p>
            </div>
        </div>
    )
}
