const ownerReferral = {
    title: "Share the service you love. Get rewarded.",
    headerDescription: "Refer a friend: you get $500, they get $500.",
    bodyParagraph1:
        "Appreciate what Vacasa does for you and your vacation home — from marketing to cleaning, maintenance to 24/7 guest service?",
    bodyParagraph2:
        "Then tell your friends. To say thanks, we'll add a $500 credit to your next statement and a $500 credit to their first statement when they sign up.",
    subtitle: "Give us the details:",
    required: "* Required",
    firstName: "Friend's first name",
    lastName: "Friend's last name",
    email: "Friend's email",
    phone: "Friend's phone number",
    address: "Friend's vacation home address",
    city: "City/Region",
    state: "State",
    zip: "Zip code",
    country: "Country",
    button: "Submit",
    warning: `The state where your referral is located prohibits Vacasa from compensating on the referral unless you have an active real estate license.  To acknowledge that you have read that a referral cannot be granted please select "yes".`,
    footerDescription:
        "While we welcome referrals of homes in any area, this offer is not available for referrals of homes located in AL, AZ, AR, CT, DE, D.C., HI, IA, KY, LA, MI, MN, MO, MT, NC, ND, NH, NJ, NM, NV, NY, OH, PA, PR, RI, SC, TN, VA, WV, WI, WY or any other location or circumstance where a real estate license is required to provide property management for the referred home. All referred properties that sign up for Guestworks will be compensated a flat rate of $250 once the property is live for booking on Guestworks.com. Click <a>here</a> for full terms and conditions.",
    successTitle: "Congratulations, your referral is on the way!",
    successDescription:
        "You'll receive your $500 credit 30 days after your friend's home goes live on Vacasa.com.",
    referAnotherFriend: "Refer Another Friend",
    notRightNow: "Not Right Now",
    termsAndConditions: "Terms and Conditions Apply",
    invalidEmail: "Please enter a valid email.",
    errorTitle: "Error loading",
    errorDescription:
        "We had an issue submitting your responses. Please try again.",
    tryAgain: "Try Again",
    backToForm: "Back to Form",
}

export default ownerReferral
