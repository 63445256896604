import classNames from "classnames"
import { useDirectDepositUnenrollMutation } from "hooks/finance"
import { useContactId } from "hooks/user"
import { observer } from "mobx-react"
import React, { useEffect, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import LoggingService from "services/logging/logging.service"
import PaymentOption from "./PaymentOption"
import { OwnerPaymentDetails } from "./PaymentSettings"
import styles from "./settings.module.scss"
import { useUnits } from "hooks/units"
import { useIsEmployee } from "hooks/user"

interface PaymentOptionsProps {
    owner: OwnerPaymentDetails
    onDirectDepositUnenrollSuccess: () => void
}

const PaymentOptions: React.FC<PaymentOptionsProps> = observer(
    (props: PaymentOptionsProps) => {
        const { onDirectDepositUnenrollSuccess, owner } = props
        const [directDeposit, setDirectDeposit] = useState(owner.directDeposit)
        const directDepositUnenroll = useDirectDepositUnenrollMutation()
        const contactId = useContactId() ?? ""
        const navigate = useNavigate()
        const unitQuery = useUnits()
        const units = unitQuery.data
        const isEmployee = useIsEmployee()

        const isExpenseDebitMethodACH =
            units &&
            units.every(
                unit =>
                    unit.attributes.contract.expenseDebitMethod?.name === "ACH"
            )

        useEffect(() => {
            setDirectDeposit(owner.directDeposit && !!owner.accountNumberLast4)
        }, [owner])

        const intl = useIntl()

        const accountType = owner.isSavingsAccount
            ? intl.formatMessage({
                  id: "Settings.savingsAccount",
                  defaultMessage: "Savings Account",
              })
            : intl.formatMessage({
                  id: "Settings.checkingAccount",
                  defaultMessage: "Checking Account",
              })
        const directDepositDescription =
            directDeposit && owner.accountNumberLast4
                ? `${accountType} **** ${owner.accountNumberLast4} | ${owner.accountHolderName}`
                : intl.formatMessage({
                      id: "Settings.getYourPaymentsFaster",
                      defaultMessage:
                          "Get your payments faster by enrolling in direct deposit.",
                  })

        const onPaperChecksClick = () => {
            directDepositUnenroll.mutate(
                { contactId },
                {
                    onError: error => {
                        LoggingService.error({
                            message: "Failed to unenrollDirectDeposit",
                            error,
                        })
                    },
                    onSuccess: onDirectDepositUnenrollSuccess,
                }
            )
        }

        const getPaymentOptionText = (): JSX.Element => {
            if (directDeposit && owner.accountNumberLast4) {
                if (isExpenseDebitMethodACH) {
                    return (
                        <FormattedMessage
                            id="Settings.achDirectDepositEnrolled"
                            defaultMessage="You are enrolled in ACH direct deposit and ACH expense debit."
                        />
                    )
                }
                return (
                    <FormattedMessage
                        id="Settings.directDepositEnrolled"
                        defaultMessage="You are enrolled in direct deposit"
                    />
                )
            }
            return (
                <FormattedMessage
                    id="Settings.directDepositNotEnrolled"
                    defaultMessage="You are not enrolled in direct deposit."
                />
            )
        }

        const paymentOptionText = getPaymentOptionText()

        return (
            <>
                <div className={styles.optionsContainer}>
                    <div className={styles.directDeposit}>
                        <PaymentOption
                            title={
                                <FormattedMessage
                                    id="Settings.directDepositTitle"
                                    defaultMessage="Bank Account"
                                />
                            }
                            subtitle={paymentOptionText}
                            description={directDepositDescription}
                            actionLabel={
                                <FormattedMessage
                                    id="Settings.updatePayment"
                                    defaultMessage="Add a new account"
                                />
                            }
                            actionHandler={() =>
                                navigate("/statements/settings/update-payment")
                            }
                            actionDisabled={isEmployee}
                            loading={Object.keys(owner).length === 0}
                            selected={directDeposit}
                        />
                    </div>
                    {!isExpenseDebitMethodACH && (
                        <div className={styles.receiveByMail}>
                            <PaymentOption
                                title={
                                    <FormattedMessage
                                        id="Settings.checksByMailTitle"
                                        defaultMessage="Paper Checks"
                                    />
                                }
                                subtitle={
                                    !directDeposit ? (
                                        <FormattedMessage
                                            id="Settings.checksByMailEnrolled"
                                            defaultMessage="You are enrolled in paper checks."
                                        />
                                    ) : (
                                        <FormattedMessage
                                            id="Settings.checksByMailNotEnrolled"
                                            defaultMessage="You are not enrolled in paper checks."
                                        />
                                    )
                                }
                                description={
                                    <FormattedMessage
                                        id="Settings.receiveByMailText"
                                        defaultMessage="If selected, your checks will be mailed to your address on file."
                                    />
                                }
                                actionLabel={
                                    <FormattedMessage
                                        id="Settings.receiveByMailSub"
                                        defaultMessage="Enroll in Checks"
                                    />
                                }
                                actionHandler={onPaperChecksClick}
                                actionDisabled={!directDeposit || isEmployee}
                                selected={!directDeposit}
                                address={
                                    owner.address1 ?? "Address Unavailable"
                                }
                            />
                        </div>
                    )}
                    <p
                        className={classNames(
                            styles["fineprint-font"],
                            styles.column,
                            styles.directDepositDescription
                        )}
                    >
                        <FormattedMessage
                            id="Settings.directDepositFineprint"
                            defaultMessage="Deposits and withdrawals usually appear in the account 2-5 business days after the monthly statement."
                        />
                    </p>
                    {!isExpenseDebitMethodACH && (
                        <p
                            className={classNames(
                                styles["fineprint-font"],
                                styles.column,
                                styles.receiveByMailDescription
                            )}
                        >
                            <FormattedMessage
                                id="Settings.checksByMailFineprint"
                                defaultMessage="Paper checks usually take 5-10 business days to arrive by mail. Please confirm your mailing address in your {ProfileLink}."
                                values={{
                                    ProfileLink: (
                                        <a
                                            id="checksByMailFineprint-profilelink"
                                            href="/profile"
                                        >
                                            Profile
                                        </a>
                                    ),
                                }}
                            />
                        </p>
                    )}
                </div>
            </>
        )
    }
)

export default PaymentOptions
