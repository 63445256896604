import styles from "./W8Submit.module.scss"

export const W8Submit = () => {
    return (
        <div className={styles.container}>
            <span className="type-heading-small-caps">Submit your form</span>
            <span className="type-body-small">
                Fill out the form and mail it to:
            </span>
            <span className="type-body-small">
                Vacasa <br />
                PO BOX 10130
                <br />
                Portland, OR 97296
            </span>
        </div>
    )
}
