import { VACASA_PROD_URL } from "Constants"
import { ReactComponent as StarIcon } from "assets/icon-star-filled.svg"
import classNames from "classnames"
import { useContactId } from "hooks/user"
import { useUnitReviews } from "hooks/review"
import { useUnit } from "hooks/units"
import { ConditionalWrap } from "lib/components/ConditionalWrap"
import { FC, useCallback, useState } from "react"
import { ExternalLink, MapPin } from "react-feather"
import { FormattedMessage } from "react-intl"
import {
    trackGuestReviewsClicked,
    trackOwnerListingViewedClicked,
    trackSwitchToOnboardingClicked,
} from "services/segment/homeInfo/homeInfoTracking"
import { getUnitImage, isGuestworksUnit, isOnboardingUnit } from "utils/unit"
import { formatUnitAddess } from "utils/unit/unitAddress/unitAddress"
import { GuestReviewModal } from "views/performance/modal/GuestReviewModal/GuestReviewModal"
import { ReactComponent as EmptyStateIcon } from "./../HomeInfoEmptyState.svg"
import { BaseWidget, EmptyState } from "./BaseWidget"
import styles from "./UnitDetails.module.scss"
import { WidgetError } from "./WidgetError"
import { ContractStatusBadge, UnitTypeBadge } from "lib/components/Badge"

interface Props {
    unitId: string
}

export const UnitDetails: FC<Props> = ({ unitId }) => {
    const contactId = useContactId() ?? ""
    const reviews = useUnitReviews(unitId)
    const unitQuery = useUnit(unitId)

    const [showGuestReviewModal, setShowGuestReviewModal] = useState(false)
    const closeGuestReviewModal = (): void => {
        setShowGuestReviewModal(false)
    }
    const onSeeAllClick = () => {
        setShowGuestReviewModal(true)
        trackGuestReviewsClicked(unitId)
    }
    const unit = unitQuery.data

    const isLoading = reviews.isLoading || unitQuery.isLoading

    const address = unitQuery.isSuccess
        ? formatUnitAddess({ attributes: unitQuery.data.attributes })
        : null

    const hideLiveListing =
        !!unit?.attributes.status.ownerOnboardingApp.showOnboardingWizard &&
        !!unit?.attributes.status.ownerOnboardingApp.isOnboarding

    const handleViewListingClick = useCallback(() => {
        trackOwnerListingViewedClicked()
    }, [])
    const handleViewOnboardingClick = useCallback(() => {
        trackSwitchToOnboardingClicked()
    }, [])

    if (!unitId || !contactId) {
        return (
            <EmptyState
                header={
                    <div className={styles.emptyStateIconWrap}>
                        <EmptyStateIcon />
                    </div>
                }
                title="Unit Details"
                message="When available, your reviews and a link to your live listing will show up here."
            />
        )
    }

    if (unitQuery.isError) {
        return (
            <WidgetError
                errorHeading="Unit Details"
                errorMessage="We're having trouble loading this content."
                handleClick={unitQuery.refetch}
            />
        )
    }

    return (
        <BaseWidget>
            <div className={styles.wrap}>
                <div
                    className={classNames(styles.unitImg, {
                        shimmer: isLoading,
                    })}
                >
                    <img alt="Unit" src={getUnitImage(unit)} />
                </div>
                <div className={styles.content}>
                    <div className={classNames(styles.info)}>
                        <div
                            className={classNames(styles.row, styles.rowFirst)}
                        >
                            <h3
                                className={classNames(styles.unitName, {
                                    shimmer: isLoading,
                                })}
                            >
                                {unit?.attributes.name}
                            </h3>
                            {!isLoading && unit && (
                                <div className={styles.badges}>
                                    {isGuestworksUnit(unit) && (
                                        <UnitTypeBadge type="guestworks" />
                                    )}
                                    <ContractStatusBadge
                                        status={
                                            isOnboardingUnit(unit)
                                                ? "onboarding"
                                                : unit.attributes.status.active
                                                ? "active"
                                                : "inactive"
                                        }
                                    />
                                </div>
                            )}
                            {reviews.isSuccess &&
                                !isLoading &&
                                !!reviews.data.meta.filteredTotal && (
                                    <div className={classNames(styles.reviews)}>
                                        <div>
                                            <StarIcon
                                                className={styles.starIcon}
                                            />
                                            <div
                                                className={styles.averageRating}
                                            >
                                                {
                                                    reviews.data?.meta.rating
                                                        ?.average
                                                }
                                            </div>
                                            <span
                                                className={styles.reviewsLink}
                                                onClick={onSeeAllClick}
                                            >
                                                (
                                                {
                                                    reviews.data?.meta
                                                        .filteredTotal
                                                }{" "}
                                                reviews)
                                            </span>
                                        </div>
                                    </div>
                                )}
                        </div>

                        <div className={classNames(styles.row)}>
                            <MapPin
                                aria-hidden
                                size={16}
                                color="#2C3439"
                                className={classNames(styles.infoIcon, {
                                    shimmer: isLoading,
                                })}
                            />

                            <span
                                className={classNames(
                                    styles.infoText,
                                    styles.unitAddress,
                                    "ellipsis",
                                    {
                                        shimmer: isLoading,
                                    }
                                )}
                            >
                                {address?.addressTwo}
                            </span>
                        </div>
                        <div className={classNames(styles.row)}>
                            <ExternalLink
                                aria-hidden
                                size={16}
                                color="#2C3439"
                                className={classNames(styles.infoIcon, {
                                    shimmer: isLoading,
                                })}
                            />

                            <ConditionalWrap
                                condition={!isLoading}
                                wrapper={children => (
                                    <a
                                        onClick={
                                            hideLiveListing
                                                ? handleViewOnboardingClick
                                                : handleViewListingClick
                                        }
                                        href={
                                            hideLiveListing
                                                ? "/onboarding"
                                                : `${VACASA_PROD_URL}unit/${unit?.id}`
                                        }
                                        rel="noreferrer"
                                        className={classNames(styles.link)}
                                    >
                                        {children}
                                    </a>
                                )}
                            >
                                <span
                                    className={classNames(
                                        styles.infoText,
                                        styles.linkText,
                                        {
                                            shimmer: isLoading,
                                        }
                                    )}
                                >
                                    {hideLiveListing ? (
                                        <FormattedMessage
                                            id="HomeInfo.viewOnboardingAction"
                                            defaultMessage="Switch to Onboarding"
                                        />
                                    ) : (
                                        <FormattedMessage
                                            id="HomeInfo.viewListingAction"
                                            defaultMessage="View live listing"
                                        />
                                    )}
                                </span>
                            </ConditionalWrap>
                        </div>
                    </div>
                </div>
            </div>
            {showGuestReviewModal && (
                <GuestReviewModal
                    closeGuestReviewModal={closeGuestReviewModal}
                />
            )}
        </BaseWidget>
    )
}
