import {
    MonthlyRevenue,
    RateTransparencyDataVersion2,
    ReservationType,
    Revenue,
    RevenueMeta,
    RevenueSummary,
} from "@vacasa/owner-api-models"
import {
    Point,
    PointOptionsObject,
    SeriesColumnOptions,
    SeriesLineOptions,
} from "highcharts"
import merge from "lodash/merge"
import { LegendItemType } from "lib/components/Vacharts/Legend"
import { UseQueryResult } from "react-query"
import { AxiosError } from "axios"
import { RevenueResponse } from "hooks/revenue/fetchRevenue"
import { RevenueData } from "views/performance/types"

export enum ChartStyleClasses {
    VacsaaPreviousYearPoint = "highcharts-point--vacasa-previous-year",
    AcquisitionCurrentYearColumn = "highcharts-column--acquisition-current-year",
    AcquisitionPreviousYearPoint = "highcharts-point--acquisition-previous-year",
    CombinedPreviousYearPoint = "highcharts-point--combined-previous-year",
}

// Ensure that we always have a years range of plot data
const DEFAULT_PLOT_OPTIONS: PointOptionsObject[] = [
    {
        y: 0,
        x: 0,
    },
    {
        y: 0,
        x: 1,
    },
    {
        y: 0,
        x: 2,
    },
    {
        y: 0,
        x: 3,
    },
    {
        y: 0,
        x: 4,
    },
    {
        y: 0,
        x: 5,
    },
    {
        y: 0,
        x: 6,
    },
    {
        y: 0,
        x: 7,
    },
    {
        y: 0,
        x: 8,
    },
    {
        y: 0,
        x: 9,
    },
    {
        y: 0,
        x: 10,
    },
    {
        y: 0,
        x: 11,
    },
]

export const toLineSeriesData = (
    revenue: MonthlyRevenue[],
    {
        acquisitionDate,
        useNetRent,
        mixedDataVisible,
    }: {
        acquisitionDate?: Date
        useNetRent?: boolean
        mixedDataVisible?: boolean
    }
): PointOptionsObject[] => {
    const defaults = [...DEFAULT_PLOT_OPTIONS]

    defaults.forEach((point, index) => {
        const plotData = revenue.find(x => x.month - 1 === point.x)

        // Set the default styling to vacasa previous year
        point.key = LegendItemType.VacasaPreviousYear
        point.className = ChartStyleClasses.VacsaaPreviousYearPoint

        if (!plotData) {
            // If for any reason we don't have the plotData for the month,
            // use the same styling as the previous point to continue the flow
            const previousPoint = defaults[index - 1]
            if (previousPoint) {
                point.key = previousPoint.key
                point.className = previousPoint.className
            }
            return
        }

        const rentTotal = useNetRent
            ? plotData.netRentTotal
            : plotData.grossRentTotal
        point.y = rentTotal

        // hide data if vacasa/previous management visible and no net rent for this month
        const shouldHideMonth =
            useNetRent && mixedDataVisible && plotData.netRentTotal <= 0

        if (shouldHideMonth) {
            point.y = 0
        }

        const acquisitionRent = useNetRent
            ? plotData.netRentBeforeIntegrated
            : plotData.grossRentBeforeIntegrated

        const vacasaRent = useNetRent
            ? plotData.netRentAfterIntegrated
            : plotData.grossRentAfterIntegrated

        if (acquisitionDate) {
            const acquisitionYear = acquisitionDate.getFullYear()
            const acquisitionMonth = acquisitionDate.getMonth() + 1
            if (acquisitionYear === plotData.year) {
                if (plotData.month < acquisitionMonth) {
                    point.key = LegendItemType.AcquisitionPreviousYear
                    point.className =
                        ChartStyleClasses.AcquisitionPreviousYearPoint
                } else if (plotData.month === acquisitionMonth) {
                    if (!!acquisitionRent && !!vacasaRent) {
                        point.key = LegendItemType.CombinedPreviousYear
                        point.className =
                            ChartStyleClasses.CombinedPreviousYearPoint
                    } else if (acquisitionRent) {
                        point.key = LegendItemType.AcquisitionPreviousYear
                        point.className =
                            ChartStyleClasses.AcquisitionPreviousYearPoint
                    }
                }
            } else if (acquisitionYear > plotData.year) {
                point.key = LegendItemType.AcquisitionPreviousYear
                point.className = ChartStyleClasses.AcquisitionPreviousYearPoint
            }
        }
    })

    return defaults
}

const hasAcquisitionMonthOverlap = (
    monthlyRevenue: MonthlyRevenue,
    month: number
) => {
    return (
        monthlyRevenue.month === month &&
        monthlyRevenue.grossRentBeforeIntegrated > 0
    )
}

export const toColumnSeriesData = (
    revenue: MonthlyRevenue[],
    {
        acquisitionDate,
        useNetRent,
        mixedDataVisible,
    }: {
        acquisitionDate?: Date
        useNetRent?: boolean
        mixedDataVisible?: boolean
    } = {}
): PointOptionsObject[] => {
    const points: PointOptionsObject[] = []

    const acquisitionYear = acquisitionDate?.getFullYear() ?? 0
    const acquisitionMonth = acquisitionDate
        ? acquisitionDate.getMonth() + 1
        : -1

    const defaults = [...DEFAULT_PLOT_OPTIONS]

    defaults.forEach((defaultPoint, index) => {
        const point: PointOptionsObject = {
            className: undefined,
            x: defaultPoint.x,
            y: 0,
            color: "var(--cafe)",
            key: LegendItemType.VacasaCurrentYear,
        }

        const plotData = revenue.find(x => x.month - 1 === defaultPoint.x)

        if (!plotData) {
            // If for any reason we don't have the plotData for the month,
            // use the same styling as the previous point to continue the flow
            const previousPoint = points[index - 1]
            if (previousPoint) {
                point.key = previousPoint.key
                point.className = previousPoint.className
            }
            points.push(point)
            return
        }

        const acquisitionRentRevenue = useNetRent
            ? plotData.netRentBeforeIntegrated
            : plotData.grossRentBeforeIntegrated

        const vacasaRentRevenue = useNetRent
            ? plotData.netRentAfterIntegrated
            : plotData.grossRentAfterIntegrated

        let crossOverMonth = false

        // By default use vacasa rent revenue
        point.y = vacasaRentRevenue

        // hide data if vacasa/previous management visible and no net rent for this month
        const shouldHideMonth =
            useNetRent && mixedDataVisible && plotData.netRentTotal <= 0

        if (shouldHideMonth) {
            point.y = 0
        }

        let isAcquisitionDataPoint = plotData.year < acquisitionYear

        if (acquisitionYear === plotData.year) {
            // Check if the month overlaps with acquisition data
            if (hasAcquisitionMonthOverlap(plotData, acquisitionMonth)) {
                crossOverMonth = true

                // Add an extra point to the series with the overlap data
                points.push({
                    ...point,
                    y: acquisitionRentRevenue,
                    className: ChartStyleClasses.AcquisitionCurrentYearColumn,
                    key: LegendItemType.AcquisitionCurrentYear,
                })
            } else {
                isAcquisitionDataPoint = plotData.month < acquisitionMonth
            }
        }

        point.className = isAcquisitionDataPoint
            ? ChartStyleClasses.AcquisitionCurrentYearColumn
            : ""
        point.y = isAcquisitionDataPoint
            ? acquisitionRentRevenue
            : vacasaRentRevenue

        if (crossOverMonth && !isAcquisitionDataPoint) {
            point.y = useNetRent
                ? plotData.netRentTotal
                : plotData.grossRentTotal
            point.tooltipYOverride = vacasaRentRevenue
        }

        point.key = isAcquisitionDataPoint
            ? LegendItemType.AcquisitionCurrentYear
            : LegendItemType.VacasaCurrentYear

        points.push(point)
    })

    // Sort the points by y amount to ensure overlaps are visible
    return points.sort((a, b) => {
        if (a.y === b.y) return 0
        return (a.y ?? 0) < (b.y ?? 0) ? 1 : -1
    })
}

export const REVENUE_DATA_DEFAULT: RevenueData = {
    current: {
        completed: {
            grossRentAfterIntegrated: 0,
            grossRentBeforeIntegrated: 0,
            grossRentTotal: 0,
            netRentAfterIntegrated: 0,
            netRentBeforeIntegrated: 0,
            netRentTotal: 0,
        },
        monthly: [],
        total: {
            grossRentAfterIntegrated: 0,
            grossRentBeforeIntegrated: 0,
            grossRentTotal: 0,
            netRentAfterIntegrated: 0,
            netRentBeforeIntegrated: 0,
            netRentTotal: 0,
        },
        upcoming: {
            grossRentAfterIntegrated: 0,
            grossRentBeforeIntegrated: 0,
            grossRentTotal: 0,
            netRentAfterIntegrated: 0,
            netRentBeforeIntegrated: 0,
            netRentTotal: 0,
        },
    },
    meta: {
        availablePerformanceYearsRange: {
            end: new Date().getFullYear(),
            start: new Date().getFullYear(),
        },
        hasGrossRentBeforeIntegrated: false,
        hasNetRentBeforeIntegrated: false,
        isFixedRentPeriod: false,
        performanceDisplayYear: new Date().getFullYear(),
    },
    previous: {
        completed: {
            grossRentAfterIntegrated: 0,
            grossRentBeforeIntegrated: 0,
            grossRentTotal: 0,
            netRentAfterIntegrated: 0,
            netRentBeforeIntegrated: 0,
            netRentTotal: 0,
        },
        monthly: [],
        total: {
            grossRentAfterIntegrated: 0,
            grossRentBeforeIntegrated: 0,
            grossRentTotal: 0,
            netRentAfterIntegrated: 0,
            netRentBeforeIntegrated: 0,
            netRentTotal: 0,
        },
        upcoming: {
            grossRentAfterIntegrated: 0,
            grossRentBeforeIntegrated: 0,
            grossRentTotal: 0,
            netRentAfterIntegrated: 0,
            netRentBeforeIntegrated: 0,
            netRentTotal: 0,
        },
    },
}

const hasQueryData = (
    query: UseQueryResult<RevenueResponse, AxiosError>
): boolean => !!query.data && !!query.data.data

export const toRevenueData = (
    currentYear: UseQueryResult<RevenueResponse, AxiosError>,
    previousYear: UseQueryResult<RevenueResponse, AxiosError>
): RevenueData => {
    const REVENUE_SUMMARY_DEFAULT: RevenueSummary = {
        grossRentAfterIntegrated: 0,
        grossRentBeforeIntegrated: 0,
        grossRentTotal: 0,
        netRentAfterIntegrated: 0,
        netRentBeforeIntegrated: 0,
        netRentTotal: 0,
    }

    const REVENUE_DEFAULT: Revenue = {
        completed: REVENUE_SUMMARY_DEFAULT,
        monthly: [],
        total: REVENUE_SUMMARY_DEFAULT,
        upcoming: REVENUE_SUMMARY_DEFAULT,
    }

    return {
        current:
            currentYear.isSuccess && hasQueryData(currentYear)
                ? currentYear.data.data.attributes
                : REVENUE_DEFAULT,
        previous:
            previousYear.isSuccess && hasQueryData(previousYear)
                ? previousYear.data.data.attributes
                : REVENUE_DEFAULT,
        meta:
            currentYear.isSuccess && hasQueryData(currentYear)
                ? currentYear.data.meta
                : {
                      availablePerformanceYearsRange: {
                          end: new Date().getFullYear(),
                          start: new Date().getFullYear(),
                      },
                      hasGrossRentBeforeIntegrated: false,
                      hasNetRentBeforeIntegrated: false,
                      isFixedRentPeriod: false,
                      performanceDisplayYear: new Date().getFullYear(),
                  },
    }
}

export const toRateTransparencyDataMap = (
    data?: RateTransparencyDataVersion2[]
) => {
    return (
        data?.reduce<{
            [key: string]: RateTransparencyDataVersion2
        }>((acc, curr) => {
            acc[curr.date] = curr
            return acc
        }, {}) ?? {}
    )
}

export const mapReservationType = (reservationType?: string) => {
    switch (reservationType) {
        case "owner":
            return ReservationType.OWNERHOLD
        case "vacasa":
            return ReservationType.VACASAHOLD
        default: // "reservation"
            return ReservationType.RESERVATION
    }
}

export const toColumnSeries = (
    {
        data,
        meta,
    }: {
        data: Revenue
        meta: RevenueMeta
    },

    useNetRent?: boolean
): SeriesColumnOptions[] => {
    const combinedSeries: SeriesColumnOptions[] = []

    const series = toColumnSeriesData(data.monthly, {
        acquisitionDate: meta.acquisitionUnitIntegrationDate
            ? new Date(meta.acquisitionUnitIntegrationDate)
            : undefined,
        useNetRent,
    })

    // Vacasa Data
    combinedSeries.push({
        type: "column",
        data: series,
        color: "var(--cafe)",
        states: {
            inactive: {
                enabled: false,
            },
            hover: {
                enabled: false,
            },
        },
    })

    return combinedSeries
}

const getIntegratedMonth = (acquisitionDate: Date, year: number) => {
    const previousYear = year - 1

    if (acquisitionDate.getFullYear() === previousYear) {
        return acquisitionDate.getMonth()
    }

    if (acquisitionDate.getFullYear() < previousYear) {
        return 0
    }

    return undefined
}

export const toLineSeries = (
    {
        data,
        meta,
    }: {
        data: Revenue
        meta: RevenueMeta
    },
    useNetRent?: boolean
): SeriesLineOptions[] => {
    const combinedSeries: SeriesLineOptions[] = []

    const baseConfig: SeriesLineOptions = {
        type: "line",
        data: [],
        color: "var(--grape)",
        states: {
            inactive: {
                enabled: false,
            },
            hover: {
                enabled: false,
            },
        },
        marker: {
            radius: 6,
        },
    }
    const mixedDataVisible =
        data.total.grossRentBeforeIntegrated > 0 && data.total.netRentTotal > 0

    const acquisitionDate = meta.acquisitionUnitIntegrationDate
        ? new Date(meta.acquisitionUnitIntegrationDate)
        : undefined

    const seriesData = toLineSeriesData(data.monthly, {
        acquisitionDate,
        useNetRent,
        mixedDataVisible,
    })

    const integratedMonth = acquisitionDate
        ? getIntegratedMonth(acquisitionDate, meta.performanceDisplayYear)
        : undefined

    combinedSeries.push(
        merge({}, baseConfig, {
            color: "var(--grape)",
            data: seriesData,
            zoneAxis: "x",
            zones: [
                {
                    value: integratedMonth,
                    color: "var(--grape-light)",
                },
            ],
        })
    )

    return combinedSeries
}

export const isChartShowingPreviousYear = (point: Point) => {
    return point.series.chart.series.some(series => series.type === "line")
}

interface TooltipPointData {
    y: number
    key?: LegendItemType
}

export const getPointsForCurrentYearsMonth = (
    selectedPoint: Point,
    isShowingPreviousYear: boolean
): TooltipPointData[] => {
    return selectedPoint.series.chart.series
        .filter(series => series.type === "column")
        .reduce<Point[]>((acc, series) => {
            return [
                ...acc,
                ...series.points.filter(
                    point =>
                        point.category === selectedPoint.category &&
                        (point.y !== null || isShowingPreviousYear)
                ),
            ]
        }, [])
        .map<TooltipPointData>(point => ({
            y: point.options.tooltipYOverride ?? point.y ?? 0,
            key: point.options.key,
        }))
        .sort((a, b) =>
            (a.key ?? LegendItemType.VacasaCurrentYear) >
            (b.key ?? LegendItemType.VacasaCurrentYear)
                ? 1
                : -1
        )
}

export const getPointsForPreviousYearsMonth = (
    selectedPoint: Point
): TooltipPointData[] => {
    return selectedPoint.series.chart.series
        .filter(series => series.type === "line")
        .reduce<Point[]>((acc, series) => {
            return [
                ...acc,
                ...series.points.filter(
                    point => point.category === selectedPoint.category
                ),
            ]
        }, [])
        .map<TooltipPointData>(point => ({
            y: point.y ?? 0,
            key: point.options.key,
        }))
}

export const isCurrentYearSelected = (year: number) =>
    year === new Date().getFullYear()

export const isCurrentOrFutureYearSelected = (year: number) =>
    year >= new Date().getFullYear()
