import React, { useState, Fragment } from "react"
import classnames from "classnames"
import ActionModal from "../../lib/components/ActionModal/ActionModal"
import UnitAddress from "./UnitAddress"
import { ReactComponent as ChevronRightSVG } from "../../assets/icon-chevron-right.svg"
import { ReactComponent as RequestAccessSvg } from "../../assets/request-access.svg"
import { ReactComponent as LockIcon } from "../../assets/icon-lock.svg"
import { ReactComponent as ListingIcon } from "../../assets/icon-onboarding-listing.svg"
import { ReactComponent as CalendarIcon } from "../../assets/icon-onboarding-calendar.svg"

import Tooltip from "@material-ui/core/Tooltip"

import styles from "../../sass/onboarding.module.scss"
import { ActiveStatus } from "../../utils/unit/unitStatus/unitStatus"
import { JSONAPIResource, Unit } from "@vacasa/owner-api-models"
import { OnboardingProgressBarV2 } from "./OnboardingProgressBarV2"
import { FormattedMessage } from "react-intl"
import { getUnitOnboardingStatus, OnboardingStatus } from "utils/unit"
import { trackCalendarViewedFromSetup } from "services/segment/onboarding/onboardingTracking"
import { useNavigate } from "react-router-dom"

interface ToDoListItemProps {
    toDoState: OnboardingStatus
    itemLabel: React.ReactNode
    availableLabel: React.ReactNode
    itemIcon: JSX.Element
    lockable: boolean
    description?: JSX.Element
    onSelectHandler?: () => void
}

const ToDoListItem: React.FC<ToDoListItemProps> = ({
    toDoState,
    itemLabel,
    availableLabel,
    itemIcon,
    lockable,
    description,
    onSelectHandler,
}) => {
    let label
    let trailingIcon
    switch (toDoState) {
        case OnboardingStatus.InformationRequired:
            label = availableLabel
            trailingIcon = <ChevronRightSVG className={styles.todo_list_icon} />
            break
        case OnboardingStatus.PreparingListing:
            label = (
                <FormattedMessage
                    id="Onboarding.toDoList.items.comingSoon"
                    defaultMessage="Coming soon"
                />
            )
            trailingIcon = null
            break
        case OnboardingStatus.UnderReview:
            label = (
                <FormattedMessage
                    id="Onboarding.toDoList.items.locked"
                    defaultMessage="Under Review"
                />
            )
            trailingIcon = lockable ? (
                <Tooltip
                    title={
                        <FormattedMessage
                            id="Onboarding.toDoList.items.notEditable"
                            defaultMessage="This is no longer editable."
                        />
                    }
                    placement="top"
                    arrow
                >
                    <div>
                        <LockIcon className={styles.todo_list_icon_disabled} />
                    </div>
                </Tooltip>
            ) : (
                <ChevronRightSVG className={styles.todo_list_icon} />
            )
            break
    }
    return (
        <button
            className={classnames(
                styles.setup_row,
                styles.disabled,
                onSelectHandler &&
                    toDoState === OnboardingStatus.InformationRequired
                    ? styles.selectable
                    : ""
            )}
            id="todo-item"
            onClick={onSelectHandler}
        >
            <div className={styles.iconContainer}>{itemIcon}</div>
            <div className={styles.detailsContainer}>
                <div className={styles.titleAndDescription}>
                    <div className={styles.titleText}>{itemLabel}</div>
                    <div className={styles.descriptionText}>{description}</div>
                </div>
                <div className={styles.trailingIcon}>
                    <strong>{label}</strong>
                    {trailingIcon}
                </div>
            </div>
        </button>
    )
}

interface ToDoPanelProps {
    unit?: JSONAPIResource<Unit>
    showProgressBar?: boolean
}

const ToDoPanelPanel: React.FunctionComponent<ToDoPanelProps> = ({
    unit,
    showProgressBar,
}) => {
    const navigate = useNavigate()
    const [showCalendarModal, setShowCalendarModal] = useState(false)
    const onboardingStatus = getUnitOnboardingStatus(unit)
    const listingStatus = unit?.attributes.hasOwnerEditAccess
        ? onboardingStatus
        : OnboardingStatus.UnderReview

    function renderTitle(): JSX.Element {
        if (unit?.attributes.active === ActiveStatus.Active) {
            return (
                <Fragment>
                    <UnitAddress className={styles.unit_address} unit={unit} />
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h3 className="type-heading-medium-large">
                        <FormattedMessage
                            id="Onboarding.toDoList.homeNotReady"
                            defaultMessage="Preparing your listing"
                        />
                    </h3>
                    <p>
                        <FormattedMessage
                            id="Onboarding.toDoList.homeNotReadyDescription"
                            defaultMessage="Stay tuned! We’ll let you know once your listing details and booking calendar are ready for editing."
                        />
                    </p>
                </Fragment>
            )
        }
    }

    return (
        <Fragment>
            <div className={styles.unitDivider} />
            <div className={styles.outerPanelBox}>
                {showCalendarModal && (
                    <ActionModal
                        onCloseHandler={(): void => setShowCalendarModal(false)}
                        title={
                            <FormattedMessage
                                id="Onboarding.revenueReminder.title"
                                defaultMessage="Heads up!"
                            />
                        }
                        description={
                            <FormattedMessage
                                id="Onboarding.revenueReminder.description"
                                defaultMessage="Before your listing goes live, you can block off nights from being rented as guests (such as dates you’d like to use your home)."
                            />
                        }
                        boldDescription={
                            <FormattedMessage
                                id="Onboarding.revenueReminder.blockingCalendar"
                                defaultMessage="Blocking as few nights as possible helps maximize your revenue."
                            />
                        }
                        icon={<RequestAccessSvg />}
                        primaryButtonTitle={
                            <FormattedMessage
                                id="Onboarding.revenueReminder.goToCalendarButton"
                                defaultMessage="Go to Calendar"
                            />
                        }
                        primaryButtonHandler={(): void => {
                            trackCalendarViewedFromSetup(
                                String(unit?.id) ??
                                    String(unit?.attributes.contract.unitID)
                            )
                            navigate(
                                `/calendar?u=${
                                    unit?.id ?? unit?.attributes.contract.unitID
                                }`
                            )
                        }}
                        secondaryButtonTitle={
                            <FormattedMessage
                                id="Onboarding.revenueReminder.maybeLaterButton"
                                defaultMessage="Maybe Later"
                            />
                        }
                        secondaryButtonHandler={(): void =>
                            setShowCalendarModal(false)
                        }
                    />
                )}
                <div className={styles.unitDetails}>{renderTitle()}</div>
                {showProgressBar && (
                    <OnboardingProgressBarV2 onboardingUnit={unit} />
                )}

                <div className={styles.thingsYouCanDo}>
                    <FormattedMessage
                        id="Onboarding.verifyListing.thingsYouCanDoNow"
                        description="Things you can do now"
                        defaultMessage="Things you can do now:"
                    />
                </div>

                <ToDoListItem
                    toDoState={listingStatus}
                    itemLabel={
                        <FormattedMessage
                            id="Onboarding.toDoList.items.listing"
                            defaultMessage="Listing"
                        />
                    }
                    availableLabel={
                        <FormattedMessage
                            id="Onboarding.toDoList.items.edit"
                            defaultMessage="Edit"
                        />
                    }
                    itemIcon={
                        <ListingIcon className={styles.todo_list_icon_large} />
                    }
                    lockable={true}
                    description={
                        <FormattedMessage
                            id="Onboarding.verifyListing.listingDetails"
                            description="Details for ToDoList Listing section"
                            defaultMessage="Help onboard your unit faster by editing your listing details"
                        />
                    }
                    onSelectHandler={
                        listingStatus ===
                            OnboardingStatus.InformationRequired &&
                        onboardingStatus ===
                            OnboardingStatus.InformationRequired
                            ? (): void => {
                                  navigate(
                                      `/setup/verify?u=${
                                          unit?.id ??
                                          unit?.attributes.contract.unitID
                                      }`
                                  )
                              }
                            : undefined
                    }
                />
                <div className={styles.unitListingDivider} />
                <ToDoListItem
                    toDoState={onboardingStatus}
                    itemLabel={
                        <FormattedMessage
                            id="Onboarding.toDoList.items.calendar"
                            defaultMessage="Calendar"
                        />
                    }
                    availableLabel={
                        <FormattedMessage
                            id="Onboarding.toDoList.items.edit"
                            defaultMessage="Edit"
                        />
                    }
                    itemIcon={
                        <CalendarIcon className={styles.todo_list_icon_large} />
                    }
                    lockable={false}
                    description={
                        <FormattedMessage
                            id="Onboarding.verifyListing.calendarDetails"
                            description="Details for ToDoList Calendar section"
                            defaultMessage="Reserve dates for your own personal use"
                        />
                    }
                    onSelectHandler={
                        onboardingStatus ===
                        OnboardingStatus.InformationRequired
                            ? (): void => {
                                  setShowCalendarModal(true)
                              }
                            : undefined
                    }
                />
            </div>
        </Fragment>
    )
}

export default ToDoPanelPanel
