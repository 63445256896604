import classnames from "classnames"
import {
    PropsWithChildren,
    ReactEventHandler,
    useCallback,
    useEffect,
    useState,
} from "react"
import { getClassStyle } from "utils/styles/styleWrapper"

import styles from "./List.module.scss"
export const List: React.FC<PropsWithChildren> = ({ children }) => (
    <ul className={styles.list}>{children}</ul>
)

export const ListItem: React.FC<PropsWithChildren> = ({ children }) => (
    <li className={styles.listItem}>{children}</li>
)

export const ListImage = ({
    className,
    onError,
    fallbackIcon,
    ...rest
}: React.ComponentProps<"img"> & {
    fallbackIcon: React.ReactNode
}): JSX.Element => {
    const [errored, setErrored] = useState(false)
    const handleError = useCallback<ReactEventHandler<HTMLImageElement>>(
        e => {
            onError?.(e)
            setErrored(true)
        },
        [onError]
    )
    useEffect(() => {
        if (rest.src) setErrored(false)
    }, [rest.src])

    return errored ? (
        // Alt text will be provided with the rest spread
        // eslint-disable-next-line jsx-a11y/alt-text
        <img
            className={classnames(styles.listImage, className)}
            onError={handleError}
            {...rest}
        />
    ) : (
        <div className={classnames(styles.listImage, className)}>
            {fallbackIcon}
        </div>
    )
}

export const ListTitle: React.FC<PropsWithChildren<{ className?: string }>> = ({
    children,
    className,
}) => (
    <h4 className={classnames(styles.listItemTitle, className)}>{children}</h4>
)

export const ListSubtitle: React.FC<
    PropsWithChildren<{ smallerMobile?: boolean }>
> = ({ children, smallerMobile }) => (
    <p
        className={classnames(styles.listItemSubtitle, {
            [getClassStyle(styles.smallerMobile)]: smallerMobile,
        })}
    >
        {children}
    </p>
)

export const ListInfo: React.FC<PropsWithChildren<{ className?: string }>> = ({
    children,
    className,
}) => <div className={classnames(styles.listInfo, className)}>{children}</div>

export const ListEndLinks: React.FC<PropsWithChildren> = ({ children }) => (
    <div className={styles.listEndLinks}>{children}</div>
)
