import * as icons from "assets/icons"

export type IconName = keyof typeof icons

export type IconProps = {
    name: IconName
    className?: string
    width?: number
    height?: number
}

export const Icon = ({ name, ...rest }: IconProps) => {
    const IconComponent = icons[name]
    return <IconComponent {...rest} />
}
