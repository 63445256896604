import React, { useEffect } from "react"
import { trackCancelledReservationFlyout } from "services/segment/reservation/reservationTracking"
import { useNavigate } from "react-router-dom"

interface ReservationCancelledProps {
    reservationID: string | null
    unitID: string | null
}

export const ReservationCancelled: React.FC<ReservationCancelledProps> = ({
    reservationID,
    unitID,
}) => {
    const navigate = useNavigate()

    useEffect(() => {
        navigate(
            `/calendar/hold/${reservationID}?cancelled&unitID=${unitID}&reservationID=${reservationID}`,
            {
                replace: true,
            }
        )
        trackCancelledReservationFlyout(String(unitID), String(reservationID))
    }, [navigate, reservationID, unitID])

    return null
}
