import { authAxios } from "../../api/authAxios"

export type PutReceiveFormsElectronicallyRequest = {
    contactId: string
    receiveElectronically: boolean
    signal?: AbortSignal
}

export type PutReceiveFormsElectronicallyResponse = {
    data: {
        message: string
    }
}

export const putReceiveFormsElectronically = ({
    contactId,
    receiveElectronically,
    signal,
}: PutReceiveFormsElectronicallyRequest): Promise<PutReceiveFormsElectronicallyResponse> => {
    return authAxios
        .put<PutReceiveFormsElectronicallyResponse>(
            `/v1/legacy/owners/${contactId}/receive-forms-electronically`,
            {
                receiveElectronically: receiveElectronically ? 1 : 0,
            },
            {
                signal,
            }
        )
        .then(response => response.data)
}
