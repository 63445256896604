import { AxiosError } from "axios"

export function isUnauthorizedError(
    error: AxiosError | unknown
): error is AxiosError {
    return (error as AxiosError).response?.status === 401
}

export function isCancelledRequestError(
    error: Error | unknown
): error is Error {
    const message = (error as Error).message
    return message === "Request cancelled" || message === "Request aborted"
}

export function isUnauthorizedOrCancelledRequest(error: unknown): boolean {
    return isUnauthorizedError(error) || isCancelledRequestError(error)
}
