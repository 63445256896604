/**
 * What user sees is the client height when it's truncated
 * The scroll height which is the actual height includes the hidden text
 *
 *
 * @param element The element to be rendered.
 * @returns {boolean} Whether the element is truncated or not.
 */

export const determineIsTruncated = (element: HTMLElement): boolean => {
    if (!element) return false
    return element.scrollHeight > element.clientHeight
}
