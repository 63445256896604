import {
    DataExpense,
    Reservation,
    Unit,
    UnitExpense,
} from "@vacasa/owner-api-models/src/legacy/Earnings"
import { format, isBefore, isValid, parseISO } from "date-fns"
import { FormattedNumber } from "react-intl"
import { isMobileBrowser } from "utils/browser/browserUtil"
import getInitialFromName from "utils/name/getInitialFromName"

export const advanceDepositPayeeSubAccountId = 16
export const revenueSharePayeeSubAccountId = 20
export const unitPayeeAccountID = 2

export function displayFormattedNumber(
    value: number,
    currency: string | undefined
): JSX.Element {
    return (
        <FormattedNumber
            value={value}
            currency={currency}
            style="currency" // eslint-disable-line
        />
    )
}

/**
 * Format date to statements format. Display date as is ignoring
 * timezone offsets as offset changes how it's displayed locally
 * @param date
 * @returns
 */
export function formatDate(date: string): string {
    if (!isValid(parseISO(date))) {
        return ""
    }
    return format(parseISO(date), "MM/dd")
}

export function isCanadaSalesTaxExpense(expense: UnitExpense): boolean {
    return expense.type === "CANADA_SALES_TAX"
}

export function shouldShowExpense(expense: UnitExpense): boolean {
    if (isCanadaSalesTaxExpense(expense)) {
        return false
    }
    if (expense.credit) {
        return false
    }
    const hiddenExpenseTypes = [
        "Management Fees",
        "Reservation",
        "Proceeds",
        "Fixed Rent",
    ]
    return hiddenExpenseTypes.indexOf(expense.type) === -1
}

export function shouldShowCredit(expense: UnitExpense): boolean {
    if (
        expense.credit &&
        expense.type !== "Reservation" &&
        expense.type !== "Proceeds" &&
        expense.type !== "Fixed Rent"
    ) {
        return true
    }
    return false
}

export function isFixedRent(unit: Unit): boolean {
    let fixedRent = false

    if (unit.expenses) {
        unit.expenses.forEach(expense => {
            if (expense.type === "Fixed Rent") fixedRent = true
        })
    }

    return fixedRent
}

export function getUnitAddress(unit: Unit): string {
    return unit.unit_address_2
        ? `${unit.unit_address ?? ""}, ${unit.unit_address_2}`
        : `${unit.unit_address ?? ""}`
}

export function showFile(newBlob: Blob, filename: string): void {
    const data = window.URL.createObjectURL(newBlob)
    const link = document.createElement("a")
    link.href = data
    link.style.cssText = "display: none"
    if (!isMobileBrowser()) {
        link.target = "_self"
    }
    link.download = filename
    document.body.appendChild(link)
    link.click()

    setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        document.body.removeChild(link)
        window.URL.revokeObjectURL(data)
    }, 100)
}

/**
 * Converts base64 string to blob
 * @param {string} base64Data Base64 encoded string
 * @param {string} contentType A string indicating the MIME type of the data contained in the Blob.
 */
export function convertBase64ToBlob(
    base64Data:
        | WithImplicitCoercion<string>
        | { [Symbol.toPrimitive](hint: "string"): string },
    contentType?: string
): Blob {
    const sliceSize = 512
    const byteCharacters = Buffer.from(base64Data, "base64")
    const byteArrays = []
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize)
        const byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice[i]
        }
        const byteArray = new Uint8Array(byteNumbers)
        byteArrays.push(byteArray)
    }
    return new Blob(byteArrays, { type: contentType })
}

export function getExpensesWithSameResId(expenses: UnitExpense[]): number[] {
    const reservationIds = expenses
        .map(expense => expense.reservation?.id)
        .filter(Number)

    return reservationIds.filter(
        (e, index) => reservationIds.indexOf(e) !== index
    ) as number[]
}

export function buildExpenseDescription(
    expense: UnitExpense,
    reservation: Reservation,
    expensesWithSameResId: number[],
    reservationMonthSpan: number
): string {
    // Check expense relationship for last_name and if it exists get the first initial for the view.
    const lastNameInitial = getInitialFromName(reservation.last_name) ?? ""
    const formattedFirstNight = formatDateRange(reservation.first_night)
    const formattedCheckout = formatDateRange(reservation.check_out)
    const formattedName = `${reservation.first_name} ${lastNameInitial}`
    const formattedFirstNightOccupied = formatDateRange(
        reservation.first_night_occupied
    )

    // Use first night and checkout date as before for
    // 1. Legacy revenue process cases
    // 2. Reservation not span multiple months
    if (!expense.is_occupied_nights || reservationMonthSpan < 2) {
        return `${formattedFirstNight} - ${formattedCheckout}: ${formattedName}`
    }

    if (expensesWithSameResId.includes(reservation.id)) {
        return buildGoLiveCasesDescription(
            expense.posting_date,
            reservation.first_night_occupied,
            formattedFirstNight,
            formattedName,
            formattedFirstNightOccupied,
            reservation.check_out
        )
    }

    // e.g. Feb statement
    // 1. an reservation in previous month e.g. 1/15-1/17 with postingDate 2/1
    // 2. an reservation span Feb and March e.g. 02/26 - 03/02 with postingDate 2/28
    if (
        reservation.first_night_occupied &&
        reservation.last_night_occupied &&
        (isBefore(
            parseISO(reservation.last_night_occupied),
            parseISO(reservation.first_night_occupied)
        ) ||
            isBefore(
                parseISO(reservation.last_night_occupied),
                parseISO(reservation.last_night)
            ))
    ) {
        const formattedLastNightOccupied = formatDateRange(
            reservation.last_night_occupied
        )
        return formattedFirstNight === formattedLastNightOccupied
            ? `${formattedFirstNight}: ${formattedName}`
            : `${formattedFirstNight} - ${formattedLastNightOccupied}: ${formattedName}`
    }
    // Normal occupied night cases
    return `${formattedFirstNightOccupied} - ${formattedCheckout}: ${formattedName}`
}

export function formatDateRange(date: string | undefined): string {
    return date ? date.slice(-5).replace("-", "/") : ""
}

export function getReservationPeriod(
    firstNight: string,
    checkOutDate: string
): string {
    const formattedFirstNight = formatDateRange(firstNight)
    const formattedCheckOutDate = formatDateRange(checkOutDate)
    return `${formattedFirstNight} - ${formattedCheckOutDate}`
}

/**
 * Modified due to the bug defined in task GOE-264.
 * This method was adjusted to ensure that whenever a reservation is split across two months
 * (i.e., the first part ends in one month and the other part starts in another month),
 * this happens because statements are generated monthly and must be divided accordingly.
 * The full duration range of the reservation should be displayed (first night - checkout).
 *
 * Note: This patch does not affect the statement logic created for "2023-02-01" as described in task TFP-1648.
 *
 * Example of expected output:
 * 01/31 - 02/04: Trisha M
 */
function buildGoLiveCasesDescription(
    _postingDate: string,
    _firstNightOccupied: string | undefined,
    formattedFirstNight: string,
    formattedName: string,
    _formattedFirstNightOccupied: string,
    checkoutDate: string
): string {
    return `${formattedFirstNight} - ${formatDateRange(checkoutDate)}: ${formattedName}`
}

/**
 * Check if a payment expense is an ACH expense
 * @param expense
 * @returns boolean
 */
export const isACHExpense = (expense: DataExpense): boolean =>
    expense.description.includes("ACH Debit") ||
    expense.description.includes("Reversal of ACH Payment")
