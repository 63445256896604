/* eslint-disable camelcase */
import { BedModel } from "../../../models/Bed"
import { RoomModel } from "../../../models/Room"

export const getAddedRemovedCount = (
    id: number,
    existingRooms: RoomModel[],
    rooms: RoomModel[]
): [number, number] => {
    const count = getBedTypeCount(id, rooms)
    const existingCount = getBedTypeCount(id, existingRooms)
    const added = count > existingCount ? count - existingCount : 0
    const removed = existingCount > count ? existingCount - count : 0
    return [added, removed]
}

export const getBedTypeCount = (id: number, rooms: RoomModel[]): number => {
    let count = 0
    rooms.forEach((room: RoomModel) => {
        room.attributes.beds.forEach((bed: BedModel) => {
            if (bed.bedSize?.id === id) {
                count++
            }
        })
    })
    return count
}
