import { ReactElement } from "react"
import { FormattedMessage } from "react-intl"

import { EmptyState } from "components/EmptyState"
import { ReactComponent as EmptyStateImage } from "./MaintenanceEmptyState.svg"

export const NoUnitState = (): ReactElement => (
    <div className={"container-fluid content-container"}>
        <h1 className="type-display-small">
            <FormattedMessage
                id="Maintenance.title"
                defaultMessage="Maintenance"
            />
        </h1>
        <EmptyState
            image={<EmptyStateImage />}
            title={
                <FormattedMessage
                    id="Maintenance.emptyState.title"
                    defaultMessage="Maintenance"
                />
            }
            description={
                <FormattedMessage
                    id="Maintenance.emptyState.description"
                    defaultMessage="Need help with some home maintenance? You’ll be able to submit and view requests here."
                />
            }
        />
    </div>
)
