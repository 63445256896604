import React, { ReactNode } from "react"

import { MOBILE_SESSION } from "Constants"
import { FormattedMessage } from "react-intl"
import storageService from "services/storage.service"
import { ReactComponent as AlertIcon } from "../../assets/icon-alert-triangle.svg"
import { Banner } from "../../components/Banner"

export function isAndroid(): boolean {
    return (
        /Android/i.test(navigator.userAgent) &&
        !navigator.userAgent.match(/Vacasa Android Owner App/)
    )
}

export function isIPhone(): boolean {
    return (
        /iPhone/i.test(navigator.userAgent) &&
        !navigator.userAgent.match(/Vacasa iOS Owner App/)
    )
}

export function isMobileBrowser(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
    )
}

export function isOwnerApp(): boolean {
    const isiOsMobileSession =
        storageService.localStorage.getItem(MOBILE_SESSION) === "true"
    return (
        isiOsMobileSession ||
        !!navigator.userAgent.match(
            /Vacasa Android Owner App|Vacasa iOS Owner App/
        )
    )
}

export function isIEBrowser(): boolean {
    return !!window.navigator.userAgent.match(/MSIE|Trident|IEMobile/)
}

export const BrowserNotSupportedBanner: React.FC = () => {
    if (!isIEBrowser()) {
        return null
    }
    return (
        <Banner
            variant="not-supported"
            bannerIcon={<AlertIcon />}
            copy={
                <FormattedMessage
                    id="NotFound.notSupportedWarningMessage"
                    defaultMessage="Internet Explorer is not a supported browser as of January 12, 2021. For the best experience please {{updateBrowserLink}}."
                    values={{
                        a: (chunks: ReactNode) => (
                            <a href="/unsupported.html">{chunks}</a>
                        ),
                    }}
                />
            }
        />
    )
}
