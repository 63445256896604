import { default as classnames, default as classNames } from "classnames"
import { MouseEvent, useCallback, useEffect, useState } from "react"

import { JSONAPIResource, UserPreference } from "@vacasa/owner-api-models"
import { useOwnerPreferencesMutation } from "hooks/owner-preferences"
import { Bell } from "react-feather"
import { ReactComponent as ChevronRight } from "../../../assets/icon-chevron-right.svg"
import { ReactComponent as CloseX } from "../../../assets/icon-close.svg"
import styles from "./FloatingNotification.module.scss"
import { useQueryClient } from "react-query"

export interface FloatingNotificationProps {
    body?: string
    linkString?: string
    onClickHandler?: () => void
    redirectLink?: string
    title?: string
    userPreference?: JSONAPIResource<UserPreference>
    pointerPosition?:
        | "top-left"
        | "top-center"
        | "top-right"
        | "right-top"
        | "right-center"
        | "right-bottom"
        | "bottom-left"
        | "bottom-center"
        | "bottom-right"
        | "left-top"
        | "left-center"
        | "left-bottom"
    feedbackContent?: () => JSX.Element
    trackFloatingNotificationDisplayed?: () => void
    trackFloatingNotificationDismissed?: () => void
    hideBell?: boolean
}

/**
 * This is a floating notification that will appear in an absolute position within a parent div if the parent div has a position.
 * It will check a preference type, and then check a boolean || null value
 * If the value is false || null, it will display the notification,
 * and if the hideNotification is used, the value will be set to true, and not display the notification
 * @param notificationString The string that will appear in the notification details section
 * @param linkString The string that will appear on the link
 * @param onClickHandler A function which handles onClick functions that adds on top of the hideNotification
 * @param redirectLink Link that redirects the user when the notification link is clicked
 * @param userPreference An API response which contains the user preference
 * @param position The position to place the caret pointing to the element
 */

const FloatingNotification = ({
    body,
    linkString,
    onClickHandler,
    redirectLink,
    title,
    userPreference,
    pointerPosition = "bottom-center",
    feedbackContent,
    trackFloatingNotificationDisplayed,
    trackFloatingNotificationDismissed,
    hideBell,
}: FloatingNotificationProps): JSX.Element => {
    const [showNotification, setShowNotification] = useState(false)
    const queryClient = useQueryClient()
    const userPreferenceMutation = useOwnerPreferencesMutation({
        onSuccess: () => {
            queryClient.invalidateQueries("owner_preference")
        },
    })

    useEffect(() => {
        if (userPreference?.id && !userPreference.attributes.value) {
            setShowNotification(true)
            if (trackFloatingNotificationDisplayed) {
                trackFloatingNotificationDisplayed()
            }
        } else {
            setShowNotification(false)
        }
    }, [trackFloatingNotificationDisplayed, userPreference])

    // Notification will set the preference property to TRUE and hide the notification
    const hideNotification = useCallback(() => {
        if (userPreference?.id) {
            userPreferenceMutation.mutate({
                id: userPreference.id.toString(),
                value: true,
            })
        }

        setShowNotification(false)
    }, [userPreference, userPreferenceMutation])

    // Handle click event for CTA function
    const onCTAClick = useCallback(
        (e: MouseEvent<HTMLDivElement>) => {
            hideNotification()
            if (onClickHandler) onClickHandler()
            if (redirectLink) window.location.href = redirectLink
            e.stopPropagation()
            e.preventDefault()
            // This combination and order of stopPropagation and preventDefault is the only one that works
        },
        [hideNotification, onClickHandler, redirectLink]
    )

    const onCloseClick = useCallback(
        (e: MouseEvent<HTMLButtonElement>) => {
            hideNotification()
            e.stopPropagation()
            if (trackFloatingNotificationDismissed) {
                trackFloatingNotificationDismissed()
            }
        },
        [hideNotification, trackFloatingNotificationDismissed]
    )

    if (!showNotification) return <></>

    return (
        <div
            className={classNames(
                "floating-notification",
                styles["notification"],
                styles[`notification--${pointerPosition}`]
            )}
        >
            {!hideBell && (
                <div className={styles["notification__bell"]}>
                    <Bell />
                </div>
            )}
            <button
                onClick={onCloseClick}
                className={styles["notification__close"]}
            >
                <CloseX />
            </button>
            {title && (
                <div
                    className={classNames(
                        styles["notification__title"],
                        "text-xs",
                        "font-extrabold",
                        "tracking-wider"
                    )}
                >
                    {title}
                </div>
            )}
            {feedbackContent && feedbackContent()}
            {!feedbackContent && body && (
                <div
                    className={classnames(
                        styles["notification__body"],
                        "font-normal",
                        "text-sm"
                    )}
                >
                    {body}
                    {linkString && (
                        <div
                            className={styles["notification__cta"]}
                            onClick={onCTAClick}
                        >
                            <span>{linkString}</span>
                            <ChevronRight className={styles.chevronRight} />
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default FloatingNotification
