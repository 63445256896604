import classNames from "classnames"
import {
    ChangeEvent,
    FocusEvent,
    InputHTMLAttributes,
    Ref,
    forwardRef,
    useCallback,
    useState,
} from "react"
import { XCircleInverse } from "../../../assets"
import styles from "./Input.module.scss"
import { Label } from "../Label"

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    containerClassName?: string
    labelText?: string
    labelClassName?: string
    error?: boolean
    hint?: string | React.ReactNode
    inputHintClassName?: string
}

export const Input = forwardRef(
    (
        {
            containerClassName,
            className,
            defaultValue = "",
            error,
            hint,
            inputHintClassName,
            labelText,
            labelClassName,
            onBlur,
            onFocus,
            onChange,
            ...inputProps
        }: InputProps,
        ref: Ref<HTMLInputElement>
    ) => {
        const [focused, setFocused] = useState(false)

        const onInputFocus = useCallback(
            (e: FocusEvent<HTMLInputElement>) => {
                setFocused(true)
                if (onFocus) onFocus(e)
            },
            [onFocus]
        )

        const onInputBlur = useCallback(
            (e: FocusEvent<HTMLInputElement>) => {
                setFocused(false)
                if (onBlur) onBlur(e)
            },

            [onBlur]
        )

        const onInputClearClick = useCallback(() => {
            onChange?.({
                target: {
                    name: inputProps.name,
                    value: defaultValue,
                },
            } as ChangeEvent<HTMLInputElement>)
        }, [defaultValue, inputProps.name, onChange])

        return (
            <div className={classNames(styles.container, containerClassName)}>
                {labelText && (
                    <Label
                        className={classNames(
                            labelClassName,
                            styles.inputLabel
                        )}
                        htmlFor={inputProps?.id ?? inputProps.name}
                        error={error}
                    >
                        {labelText}
                    </Label>
                )}
                <div
                    className={classNames(styles.inputWrapper, "inputWrapper")}
                >
                    <input
                        ref={ref}
                        className={classNames(styles.input, className, {
                            [styles.inputError as string]: error,
                        })}
                        onFocus={onInputFocus}
                        onBlur={onInputBlur}
                        onChange={onChange}
                        {...inputProps}
                    />
                    {focused && inputProps.value !== defaultValue && (
                        <button
                            className={styles.inputClear}
                            onMouseDown={onInputClearClick}
                            tabIndex={-1}
                            type="button"
                        >
                            <XCircleInverse />
                        </button>
                    )}
                </div>
                {!!hint && (
                    <div
                        className={classNames(
                            styles.inputHint,
                            inputHintClassName,
                            {
                                [styles.inputHintError as string]: error,
                            }
                        )}
                    >
                        {hint}
                    </div>
                )}
            </div>
        )
    }
)
