import { FC, useEffect } from "react"
import { FormattedMessage } from "react-intl"
import { EmptyState } from "../../components/EmptyState"
import { ListLayout } from "../../components/Layout/ListLayout"
import DefaultError from "../../lib/components/ErrorBoundaries/DefaultError"
import { ReactComponent as EmptyStateImage } from "./HomeInfoEmptyState.svg"

import { ReferralBanner } from "components/ReferralBanner"
import { trackHomeInfoPageViewed } from "services/segment/homeInfo/homeInfoTracking"
import { UnitSelectWrapper } from "views/UnitSelectWrapper"
import styles from "./HomeInfo.module.scss"
import {
    AverageCleanScore,
    HomeActivity,
    InspectionReportWidget,
    UnitDetails,
    YourProgramsWidget,
} from "./widgets"
import { Card, CardContent } from "components/Card"
import { List } from "./UnitDetails/List"
import { OwnerResourceLibrary } from "./UnitDetails/OwnerResourceLibrary/OwnerResourceLibrary"
import { DogsAllowed } from "./UnitDetails/DogsAllowed/DogsAllowed"
import { useContactId, useUser } from "hooks/user"
import { useCurrentUnit, useUnitStatuses } from "hooks/units"

const HomeInfo: FC = () => {
    const { unit } = useCurrentUnit()
    const { isActive } = useUnitStatuses()
    const contactId = useContactId() ?? ""

    useEffect(() => {
        trackHomeInfoPageViewed()
    }, [])

    const { user } = useUser()
    const accountingEntityCountry = user?.accountInfo.accountingEntityCountry

    const renderProgramsWidget =
        isActive && accountingEntityCountry !== "Canada"

    return (
        <>
            <ReferralBanner />
            <ListLayout
                title={
                    <FormattedMessage
                        id="HomeInfo.title"
                        defaultMessage="Home Info"
                    />
                }
                size="lg"
                actions={<UnitSelectWrapper />}
                className={styles.homeInfoList}
            >
                {!unit && (
                    <DefaultError>
                        <EmptyState
                            image={<EmptyStateImage />}
                            title={
                                <FormattedMessage
                                    id="HomeInfo.emptyState.title"
                                    defaultMessage="Home information"
                                />
                            }
                            description={
                                <FormattedMessage
                                    id="HomeInfo.emptyState.description"
                                    defaultMessage="Details like your local Vacasa contacts and a link to your live listing will show up here."
                                />
                            }
                        />
                    </DefaultError>
                )}

                {unit && <UnitDetails unitId={String(unit.id ?? "")} />}
                <div className={styles.widgetGrid}>
                    <>
                        {unit &&
                            !unit.attributes.status.guestworksApp
                                .isGuestworksUnit && (
                                <>
                                    <HomeActivity
                                        unitId={String(unit?.id ?? "")}
                                        contactId={contactId}
                                    />

                                    <AverageCleanScore
                                        unitId={String(unit?.id ?? "")}
                                        contactId={contactId}
                                    />
                                    <InspectionReportWidget
                                        unitId={String(unit?.id ?? "")}
                                        contactId={contactId}
                                    />
                                </>
                            )}
                        {renderProgramsWidget && (
                            <>
                                <YourProgramsWidget
                                    unitId={String(unit?.id ?? "")}
                                />
                            </>
                        )}
                        {unit && (
                            <Card className={styles.sandBG}>
                                <CardContent>
                                    <List>
                                        <OwnerResourceLibrary
                                            unitId={String(unit?.id ?? "")}
                                            isGuestworks={
                                                !!unit?.attributes
                                                    .guestworksSupport
                                            }
                                        />
                                        <DogsAllowed
                                            unitId={String(unit?.id ?? "")}
                                            allowed={
                                                unit?.attributes.amenities
                                                    .petsFriendly ?? false
                                            }
                                        />
                                    </List>
                                </CardContent>
                            </Card>
                        )}
                    </>
                </div>
            </ListLayout>
        </>
    )
}

export default HomeInfo
