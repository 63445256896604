import React, { Component, PropsWithChildren } from "react"
import { observer, inject } from "mobx-react"
import rootService from "../../../services"
import LoggingService from "../../../services/logging/logging.service"
import { GeneralErrorModal } from "../GeneralError/GeneralErrorModal"
import { ErrorInfo } from "react-dom/client"
import withRouter, { WithRouterProps } from "core/hoc/withRouter"

interface DefaultErrorProps {
    rootService?: typeof rootService
}

@inject("rootService")
@observer
export default class DefaultError extends Component<
    PropsWithChildren<DefaultErrorProps>
> {
    componentDidCatch(error: Error, info: ErrorInfo): boolean {
        if (error) {
            const errorMessage = rootService.errorService.parseMessage(error)
            const errorStatus = rootService.errorService.parseStatus(error)
            rootService.errorService.setErrorStatus(errorStatus)
            rootService.errorService.setErrorMsg(errorMessage)
            rootService.errorService.displayError()
            LoggingService.error({
                message: `Rendering Failure Captured: ${info}`,
                error,
            })
        }
        return false
    }
    handleClick(): void {
        this.props.rootService?.errorService.hideError()
    }
    displayError(): React.ReactNode {
        if (
            this.props.rootService?.errorService.errorMessage &&
            this.props.rootService?.errorService.showError
        ) {
            return (
                <GeneralErrorModal
                    handleClose={() => {
                        this.handleClick()
                    }}
                    errorHeading="Error"
                    errorMessage={
                        this.props.rootService.errorService.errorMessage
                    }
                    buttonText="OK"
                    handleClick={() => {
                        this.handleClick()
                    }}
                />
            )
        }
        return this.props.children
    }
    render(): React.ReactNode {
        return <div>{this.displayError()}</div>
    }
}

interface RouteErrorBoundaryState {
    hasError: boolean
    errorMessage?: string
}

type RouteErrorBoundaryProps = WithRouterProps & PropsWithChildren

export const RouteErrorBoundary = withRouter(
    class RouteErrorBoundary extends Component<
        RouteErrorBoundaryProps,
        RouteErrorBoundaryState
    > {
        state: RouteErrorBoundaryState = {
            hasError: false,
        }

        static getDerivedStateFromError(error: Error) {
            // Update state so the next render will show the fallback UI.
            const errorMessage = error.message ?? "An error has occured."
            return {
                hasError: true,
                errorMessage,
            }
        }

        componentDidCatch(error: Error, errorInfo: ErrorInfo) {
            LoggingService.error({
                message: `Rendering Failure Captured: ${errorInfo}`,
                error,
            })
        }

        onModalClose = () => {
            this.setState({
                hasError: false,
            })
            // navigate back to the root page to avoid a loop after dismissing the error
            this.props.router.navigate("/")
        }

        render() {
            if (this.state.hasError) {
                return (
                    <GeneralErrorModal
                        handleClose={this.onModalClose}
                        errorHeading="Error"
                        errorMessage={this.state.errorMessage}
                        buttonText="OK"
                        handleClick={this.onModalClose}
                    />
                )
            }

            return this.props.children
        }
    }
)
