import classnames from "classnames"
import { FC, PropsWithChildren } from "react"
import { getClassStyle } from "utils/styles/styleWrapper"
import styles from "./LegendItem.module.scss"

interface Props {
    label?: string | JSX.Element
    stack?: boolean
    type?: LegendItemType
}

export enum LegendItemType {
    VacasaCombined,
    VacasaCurrentYear,
    VacasaPreviousYear,
    AcquisitionCurrentYear,
    AcquisitionPreviousYear,
    AcquisitionCombined,
    CombinedCurrentYear,
    CombinedPreviousYear,
}

/**
 * Return the id of the definition in the performance-chart-points.svg
 * @param type
 * @returns string
 */
const getSvgId = (type: LegendItemType) => {
    switch (type) {
        case LegendItemType.VacasaCombined:
            return "performance-chart-points__vacasa-combined"
        case LegendItemType.VacasaCurrentYear:
            return "performance-chart-points__vacasa-current-year"
        case LegendItemType.VacasaPreviousYear:
            return "performance-chart-points__vacasa-previous-year"
        case LegendItemType.AcquisitionCombined:
            return "performance-chart-points__acquisition-combined"
        case LegendItemType.AcquisitionCurrentYear:
            return "performance-chart-points__acquisition-current-year"
        case LegendItemType.AcquisitionPreviousYear:
            return "performance-chart-points__acquisition-previous-year"
        case LegendItemType.CombinedCurrentYear:
            return "performance-chart-points__combined-current-year"
        case LegendItemType.CombinedPreviousYear:
            return "performance-chart-points__combined-previous-year"
        default:
            return ""
    }
}

export const LegendItem: FC<PropsWithChildren<Props>> = ({
    children,
    stack,
    type,
}) => {
    if (type === undefined) return null

    return (
        <div
            className={classnames(styles["legend-item"], "legend-item", {
                [getClassStyle(styles["legend-item--stack"])]: stack,
            })}
        >
            <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                xmlns="http://www.w3.org/2000/svg"
                className={classnames(
                    styles["legend-item__key"],
                    "legend-item__key"
                )}
            >
                <circle cx="6" cy="6" r="6" fill={`url(#${getSvgId(type)})`} />
            </svg>
            {children}
        </div>
    )
}
