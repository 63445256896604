/* eslint-disable camelcase */

// Page path
export const TAXES_PAGE_PATH = "/taxes"

// Click Track Type
export const LINK_TYPE = "link"
// Click Track Label
export const CSV_LABEL = "csv"
// Events
export const OWNER_TAX_FORMS_EVENT = "Owner Tax Forms"

export const ANNUAL_STATEMENTS_ERROR_PRESENTED =
    "Annual Statements Error Presented"

export const ANNUAL_STATEMENTS_ERROR_SUPPORT_NUMBER_CLICKED =
    "Annual Statements Error Support Number Clicked"

export const ANNUAL_STATEMENTS_RETRY_CLICKED = "Annual Statements Retry Clicked"
