import React, { useCallback, useEffect, useState } from "react"
import cx from "classnames"
import CalendarYearGrid from "../CalendarYear/CalendarYearGrid"
import MonthPickerNavigation from "./MonthPickerNavigation"

import {
    isMonthVisible,
    getCalendarYearWidth,
} from "../../../utils/calendar/calendarUtil"
import {
    HORIZONTAL_ORIENTATION,
    VERTICAL_ORIENTATION,
    VERTICAL_SCROLLABLE,
    MONTH_WIDTH_SIZE,
    MONTH_HEIGHT_SIZE,
} from "../../../Constants"
import { addYears, startOfMonth, subYears } from "date-fns"

const YEAR_PADDING = 23
const PREV_TRANSITION = "prev"
const NEXT_TRANSITION = "next"

function getActiveElement() {
    return typeof document !== "undefined" && document.activeElement
}

function applyTransformStyles(
    el: HTMLElement,
    transform: string,
    opacity: null | string
) {
    el.style.transform = transform
    el.style.webkitTransform = transform
    if (opacity) {
        el.style.opacity = opacity
    }
}

function calculateDimension(
    el: HTMLElement | null,
    axis: string,
    borderBox = false,
    withMargin = false
) {
    if (!el) {
        return 0
    }

    const axisStart = axis === "width" ? "Left" : "Top"
    const axisEnd = axis === "width" ? "Right" : "Bottom"

    // Only read styles if we need to
    const style = !borderBox || withMargin ? window.getComputedStyle(el) : null

    // Offset includes border and padding
    const { offsetWidth, offsetHeight } = el
    let size = axis === "width" ? offsetWidth : offsetHeight

    // Get the inner size
    if (!borderBox && !!style) {
        size -=
            parseFloat(style[`padding${axisStart}`]) +
            parseFloat(style[`padding${axisEnd}`]) +
            parseFloat(style[`border${axisStart}Width`]) +
            parseFloat(style[`border${axisEnd}Width`])
    }

    // Apply margin
    if (withMargin && !!style) {
        size +=
            parseFloat(style[`margin${axisStart}`]) +
            parseFloat(style[`margin${axisEnd}`])
    }

    return size
}

function getMonthHeight(el: Element) {
    const caption = el.querySelector(".js-CalendarYear__caption") as HTMLElement
    const grid = el.querySelector(".js-CalendarYear__grid") as HTMLElement

    // Need to separate out table children for FF
    // Add an additional +1 for the border
    return (
        calculateDimension(caption, "height", true, true) +
        calculateDimension(grid, "height") +
        1
    )
}

interface MonthPickerProps {
    // calendar presentation props
    numberOfYears?: number
    orientation?: string
    withPortal?: boolean
    hidden?: boolean
    initialVisibleYear: () => Date
    monthWidthSize?: number
    monthHeightSize?: number
    isRTL?: boolean

    // navigation props
    navPrev?: React.ReactNode | undefined
    navNext?: React.ReactNode | undefined

    // month props
    renderMonth?: (month: Date) => JSX.Element
    onMonthClick?: (day: Date) => void
    onClick: React.MouseEventHandler

    // accessibility props
    isFocused?: boolean
}

const MonthPicker: React.FC<MonthPickerProps> = ({
    numberOfYears = 0,
    orientation = HORIZONTAL_ORIENTATION,
    withPortal = false,
    hidden = false,
    initialVisibleYear,
    monthWidthSize = MONTH_WIDTH_SIZE,
    monthHeightSize = MONTH_HEIGHT_SIZE,
    isRTL = false,
    // month props
    renderMonth,
    onMonthClick,
    onClick,
    // accessibility props
    isFocused = false,
}) => {
    const isHorizontal = orientation === HORIZONTAL_ORIENTATION
    const isVertical =
        orientation === VERTICAL_ORIENTATION ||
        orientation === VERTICAL_SCROLLABLE
    // current year, Calendar display year
    const [currentYear, setCurrentYear] = useState<Date>(
        hidden ? new Date() : initialVisibleYear()
    )
    const [yearTransition, setYearTransition] = useState<string | null>("")
    const [translationValue, setTranslationValue] = useState<
        number | undefined
    >(isRTL && isHorizontal ? monthWidthSize : 0)
    const [focusedDate, setFocusedDate] = useState<Date | null | undefined>(
        !hidden || isFocused
            ? startOfMonth(new Date(currentYear.valueOf()))
            : undefined
    )
    const [monthPickerWidth, setMonthPickerWidth] = useState<number>(
        getCalendarYearWidth(monthWidthSize)
    )
    const [withMouseInteractions, setWithMouseInteractions] =
        useState<boolean>(true)
    const containerRef = React.createRef<HTMLDivElement>()
    const transitionContainer = React.createRef<HTMLDivElement>()

    const initializeMonthPickerWidth = useCallback(() => {
        if (transitionContainer) {
            const transitionContainerDOM = transitionContainer.current
            if (transitionContainerDOM) {
                const calendarYear = transitionContainerDOM.querySelector(
                    ".CalendarYear"
                ) as HTMLElement
                if (calendarYear) {
                    setMonthPickerWidth(
                        calculateDimension(calendarYear, "width", true)
                    )
                }
            }
        }
    }, [transitionContainer])

    const getFocusedMonth = useCallback(
        (newYear: Date) => {
            if (
                newYear &&
                (!focusedDate ||
                    !isMonthVisible(focusedDate, newYear, numberOfYears))
            ) {
                setFocusedDate(startOfMonth(new Date(newYear.valueOf())))
            }
            return focusedDate
        },
        [focusedDate, numberOfYears]
    )

    const adjustMonthPickerHeight = useCallback(() => {
        const heights: number[] = []
        const transitionContainerDOM = transitionContainer.current
        Array.prototype.forEach.call(
            transitionContainerDOM?.querySelectorAll(".CalendarYear"),
            el => {
                if (el.getAttribute("data-visible") === "true") {
                    heights.push(getMonthHeight(el))
                }
            }
        )

        const newYearHeight = Math.max(...heights) + YEAR_PADDING

        if (
            newYearHeight !==
            calculateDimension(transitionContainerDOM, "height")
        ) {
            if (transitionContainerDOM) {
                transitionContainerDOM.style.height = `${newYearHeight}px`
            }
        }
    }, [transitionContainer])

    useEffect(() => {
        if (isHorizontal && !monthPickerWidth) {
            adjustMonthPickerHeight()
            initializeMonthPickerWidth()
        }
    }, [
        isHorizontal,
        monthPickerWidth,
        adjustMonthPickerHeight,
        initializeMonthPickerWidth,
    ])

    function getMonthHeightByIndex(i: number) {
        const transitionContainerDOM = transitionContainer.current
        if (transitionContainerDOM) {
            const calendarYear =
                transitionContainerDOM.querySelectorAll(".CalendarYear")[i]
            return getMonthHeight(calendarYear as HTMLElement)
        } else {
            return 0
        }
    }

    function onPrevYearClick() {
        let translationValue = isVertical
            ? getMonthHeightByIndex(0)
            : monthPickerWidth

        if (isRTL && isHorizontal) {
            translationValue = 2 * monthPickerWidth
        }
        translateFirstMonthPickerForAnimation(translationValue)
        setYearTransition(PREV_TRANSITION)
        setTranslationValue(translationValue)
        setFocusedDate(null)
    }

    function onNextYearClick() {
        let translationValue = isVertical
            ? -getMonthHeightByIndex(1)
            : -monthPickerWidth

        if (isRTL && isHorizontal) {
            translationValue = -2 * monthPickerWidth
        }
        translateFirstMonthPickerForAnimation(translationValue)
        setYearTransition(NEXT_TRANSITION)
        setTranslationValue(translationValue)
        setFocusedDate(null)
    }

    function updateStateAfterYearTransition() {
        if (!yearTransition) return

        let newYear = new Date(currentYear.valueOf())
        if (yearTransition === PREV_TRANSITION) {
            if (onPrevYearClick) onPrevYearClick()
            newYear = subYears(newYear, 1)
        } else if (yearTransition === NEXT_TRANSITION) {
            if (onNextYearClick) onNextYearClick()
            newYear = addYears(newYear, 1)
        }

        const newFocusedDate = getFocusedMonth(newYear)

        if (transitionContainer) {
            const transitionContainerDOM = transitionContainer.current
            if (transitionContainerDOM) {
                const calendarYearGrid = transitionContainerDOM.querySelector(
                    ".CalendarYearGrid"
                ) as HTMLElement
                if (calendarYearGrid) {
                    applyTransformStyles(calendarYearGrid, "none", "")
                }
            }
        }
        setCurrentYear(newYear)
        setYearTransition(null)
        setTranslationValue(isRTL && isHorizontal ? -monthPickerWidth : 0)
        setFocusedDate(newFocusedDate)

        if (withMouseInteractions) {
            const activeElement = getActiveElement()
            if (activeElement && activeElement !== document.body) {
                ;(activeElement as HTMLElement).blur()
            }
        }
    }

    function translateFirstMonthPickerForAnimation(translationValue: number) {
        let convertedTranslationValue = translationValue
        if (isRTL && isHorizontal) {
            const positiveTranslationValue = Math.abs(
                translationValue + monthPickerWidth
            )
            convertedTranslationValue = positiveTranslationValue
        }
        const transformType = isVertical ? "translateY" : "translateX"
        const transformValue = `${transformType}(${convertedTranslationValue}px)`
        const transitionContainerDOM = transitionContainer.current
        if (transitionContainerDOM) {
            const calendarYearGrid = transitionContainerDOM.querySelector(
                ".CalendarYearGrid"
            ) as HTMLElement
            if (calendarYearGrid) {
                applyTransformStyles(calendarYearGrid, transformValue, "100%")
            }
        }
    }

    function renderNavigation() {
        return (
            <MonthPickerNavigation
                onPrevYearClick={onPrevYearClick}
                onNextYearClick={onNextYearClick}
                orientation={orientation}
            />
        )
    }

    let firstVisibleYearIndex = 1
    if (yearTransition === PREV_TRANSITION) {
        firstVisibleYearIndex -= 1
    } else if (yearTransition === NEXT_TRANSITION) {
        firstVisibleYearIndex += 1
    }
    const verticalScrollable = orientation === VERTICAL_SCROLLABLE
    if (verticalScrollable) firstVisibleYearIndex = 0
    const MonthPickerClassNames = cx("MonthPicker", {
        "MonthPicker--horizontal": isHorizontal,
        "MonthPicker--vertical": isVertical,
        "MonthPicker--vertical-scrollable": verticalScrollable,
        "MonthPicker--portal": withPortal,
    })
    const transitionContainerClasses = cx("transition-container", {
        "transition-container--horizontal": isHorizontal,
        "transition-container--vertical": isVertical,
    })
    const isCalendarYearGridAnimating = yearTransition !== null
    const transformType = isVertical ? "translateY" : "translateX"
    const transformValue = `${transformType}(${translationValue}px)`

    const shouldFocusDate = !isCalendarYearGridAnimating && isFocused

    return (
        <div className={MonthPickerClassNames} data-testid="month-picker">
            <div // eslint-disable-line jsx-a11y/no-noninteractive-element-interactions
                className="MonthPicker__focus-region"
                ref={containerRef}
                onClick={e => {
                    onClick(e)
                    e.stopPropagation()
                }}
                onMouseUp={(): void => setWithMouseInteractions(true)}
                role="region"
                tabIndex={-1}
            >
                {!verticalScrollable && renderNavigation()}

                <div
                    className={transitionContainerClasses}
                    ref={transitionContainer}
                >
                    <CalendarYearGrid
                        transformValue={transformValue}
                        firstVisibleYearIndex={firstVisibleYearIndex}
                        initialYear={currentYear}
                        isAnimating={isCalendarYearGridAnimating}
                        orientation={orientation}
                        numberOfYears={numberOfYears}
                        onMonthClick={onMonthClick}
                        renderMonth={renderMonth}
                        onYearTransitionEnd={updateStateAfterYearTransition}
                        monthWidthSize={monthWidthSize}
                        monthHeightSize={monthHeightSize}
                        isFocused={shouldFocusDate}
                        focusedDate={focusedDate}
                    />
                    {verticalScrollable && renderNavigation()}
                </div>
            </div>
        </div>
    )
}

export default MonthPicker
