import { Comment } from "@vacasa/owner-api-models"
import { IconLargePersonBlue3, IconVacasaLogoWhite } from "assets"
import classNames from "classnames"
import { FC } from "react"
import { FormattedMessage } from "react-intl"
import styles from "./Comments.module.scss"
import { parseISO } from "date-fns"
import { getHumanReadableDistance } from "utils/date"
import { Parser } from "html-to-react"
import getInitialFromName from "utils/name/getInitialFromName"

interface CommentsProps {
    comments: Comment[]
    ownerCreatedCommentsEnabled: boolean
}

export const Comments: FC<CommentsProps> = ({
    comments,
    ownerCreatedCommentsEnabled,
}) => {
    if (!comments.length) return <NoComments />

    return (
        <>
            <h3 className={styles.title}>
                <FormattedMessage
                    id="Maintenance.comments.comments"
                    defaultMessage="Comments"
                />
            </h3>
            <div className={styles.commentList}>
                {comments.map(comment => (
                    <div>
                        <TicketComment
                            key={comment.commentId}
                            comment={comment}
                            ownerCreatedCommentsEnabled={
                                ownerCreatedCommentsEnabled
                            }
                        />
                    </div>
                ))}
            </div>
        </>
    )
}

export const NoComments = () => {
    return (
        <div className={classNames("card-box", styles.noComments)}>
            <IconLargePersonBlue3 />
            <h3 className={styles.title}>
                <FormattedMessage
                    id="Maintenance.comments.comments"
                    defaultMessage="Comments"
                />
            </h3>
            <p className="type-body-small">
                <FormattedMessage
                    id="Maintenance.comments.empty"
                    defaultMessage="When Vacasa responds to this request, their comments will appear here."
                />
            </p>
        </div>
    )
}

interface TicketCommentProps {
    comment: Comment
    ownerCreatedCommentsEnabled: boolean
}
export const TicketComment: FC<TicketCommentProps> = ({
    comment,
    ownerCreatedCommentsEnabled,
}) => {
    return (
        <div className={styles.comment}>
            {comment.vacasaCreated ? (
                <div className={classNames(styles.icon, styles.iconVacasa)}>
                    <IconVacasaLogoWhite />
                </div>
            ) : (
                <div className={classNames(styles.icon, styles.iconOwner)}>
                    {getInitialFromName(comment.displayName)}
                </div>
            )}

            <div className={styles.content}>
                <div className={styles.contentAuthor}>
                    {comment.displayName ?? ""}
                </div>
                <div className={styles.contentTimestamp}>
                    {comment.timestamp &&
                        getHumanReadableDistance(parseISO(comment.timestamp))}
                </div>
                <div
                    className={classNames(
                        styles.contentNote,
                        `${
                            comment.vacasaCreated && ownerCreatedCommentsEnabled
                                ? styles.contentNoteVacasa
                                : styles.contentNoteOwner
                        }`,
                        "type-body-small"
                    )}
                >
                    {Parser().parse(comment.note)}
                </div>
            </div>
        </div>
    )
}
