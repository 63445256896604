/* eslint-disable no-console */
import * as Sentry from "@sentry/browser"
import { datadogLogs } from "@datadog/browser-logs"
import { Context } from "@datadog/browser-core"
import { isError } from "lodash"
import { DATADOG_ENABLED } from "../../Environment"
import { JWT, USER_ID } from "Constants"
import { JwtPayload } from "jwt-decode"
import storageService from "services/storage.service"
import { Owner } from "@vacasa/owner-api-models"
import { getDecodedToken } from "utils/auth"

export interface LoggingOptions {
    message: string
    tags?: { [key: string]: string }
    context?: Context
    error?: unknown
    [key: string]: unknown
}

export enum IgnoreError {
    NetworkError = "Network Error",
    // Ignore some random errors caused by Sentry SDK
    // Refer to https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/13
    SentrySDKError1 = "Non-Error exception captured",
    SentrySDKError2 = "Non-Error promise rejection captured",
}

enum LogType {
    Error,
    Warning,
    Log,
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export default class LoggingService {
    private static logPrefix = "owner-portal-web"

    public static user: Owner | null = null
    public static unitId: string | null = null

    public static init(opts: {
        environment?: string
        datadog?: {
            clientToken: string
        }
        sentry?: {
            dsn: string
            ignoreErrors?: Array<string | RegExp>
        }
        release?: string
    }): void {
        if (opts.datadog) {
            datadogLogs.init({
                clientToken: opts.datadog.clientToken,
                site: "datadoghq.com",
                forwardErrorsToLogs: true,
                sessionSampleRate: 100,
            })
            datadogLogs.setGlobalContext({
                team: "flightless-phoenix",
                service: "owner-portal-web",
                env: opts.environment,
                environment: opts.environment,
                release: opts.release,
            })
        }

        if (opts.sentry) {
            Sentry.init({
                ignoreErrors: opts.sentry.ignoreErrors,
                dsn: opts.sentry.dsn,
                environment: opts.environment,
                release: opts.release,
            })
        }
    }

    public static log(opts: { message: string } & Context): void {
        this.logToConsole(opts, LogType.Log)

        if (DATADOG_ENABLED === true) {
            datadogLogs.logger.log(opts.message, {
                ...opts,
                ...this.createUserContextObject(),
            })
        }
    }

    public static warn(opts: LoggingOptions): void {
        this.logToConsole(opts, LogType.Warning)

        if (DATADOG_ENABLED === true) {
            datadogLogs.logger.warn(opts.message, {
                ...opts.tags,
                ...opts.context,
                ...this.createUserContextObject(),
            })
        }

        LoggingService.addSentryTags(opts.tags)

        if (opts.error) {
            LoggingService.addSentryBreadcrumb({
                level: "info",
                message: "Error",
                data: { error: JSON.stringify(opts.error) },
                type: "error",
            })
        }

        Sentry.captureMessage(opts.message, "warning")
    }

    public static error(opts: LoggingOptions): void {
        this.logToConsole(opts, LogType.Error)

        if (DATADOG_ENABLED) {
            datadogLogs.logger.error(
                opts.message,
                {
                    ...opts.tags,
                    ...opts.context,
                    ...this.createUserContextObject(),
                },
                opts.error instanceof Error ? opts.error : undefined
            )
        }

        LoggingService.addSentryTags(opts.tags)

        if (opts.error) {
            if (isError(opts.error)) {
                opts.error.name = opts.message
                Sentry.captureException(opts.error)
                return
            }

            LoggingService.addSentryBreadcrumb({
                level: "info",
                message: "Error",
                data: { error: JSON.stringify(opts.error) },
                type: "error",
            })
        }

        Sentry.captureMessage(opts.message, "error")
    }

    public static addSentryBreadcrumb(breadcrumb: Sentry.Breadcrumb): void {
        Sentry.addBreadcrumb(breadcrumb)
    }

    private static addSentryTags(tags?: { [key: string]: string }): void {
        // TODO: Consolidate UserService.contactID & UserService.user.contactID
        const contactID = this.user?.contactId
        const userId = this.user?.userId
        const currentUnitID = this.unitId
        if (contactID) {
            Sentry.setTag("contactID", contactID)
        }

        if (userId) {
            Sentry.setTag("userID", userId)
        }

        if (currentUnitID) {
            Sentry.setTag("unitID", currentUnitID)
        }

        if (tags) {
            for (const key of Object.keys(tags)) {
                Sentry.setTag(key, tags[key])
            }
        }
    }

    /**
     * Get userID and token to output to DataDog just as OwnerAPI does
     */
    public static createUserContextObject(): object {
        let idpSubID: string | null = null
        const userID = storageService.localStorage.getItem(USER_ID)
        const token = storageService.localStorage.getItem(JWT)
        if (token) {
            const decodedToken: JwtPayload = getDecodedToken(token)
            idpSubID = decodedToken.sub
                ? decodedToken.sub
                : JSON.stringify(decodedToken)
        }

        return {
            // naming conventions match ownerAPI for searchability in DataDog
            user: {
                id: idpSubID,
                // eslint-disable-next-line camelcase
                legacy_id: userID,
            },
        }
    }

    /**
     * Log opts to console based on LogType
     */
    private static logToConsole(opts: LoggingOptions, type: LogType): void {
        if (process.env.NODE_ENV === "production") {
            return
        }

        const logs = Object.values(opts).filter(v => v)
        switch (type) {
            case LogType.Error:
                console.error(`[${this.logPrefix}:error]`, ...logs)
                break
            case LogType.Warning:
                console.warn(`[${this.logPrefix}:warn]`, ...logs)
                break
            case LogType.Log:
                console.log(`[${this.logPrefix}:log]`, ...logs)
                break
            default:
                break
        }
    }
}
