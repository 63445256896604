import { useContactId } from "hooks/user"
import { useQuery, UseQueryResult } from "react-query"
import { LockCode } from "@vacasa/owner-api-models"
import { fetchLockcode } from "./fetchLockcode"
import { AxiosError } from "axios"

const LOCK_CODE_QUERY_KEY = "lockcode" as const

const useLockcode = (
    reservationId: string
): UseQueryResult<LockCode, AxiosError> => {
    const contactId = useContactId()

    return useQuery(
        [LOCK_CODE_QUERY_KEY, reservationId],
        ({ signal }) =>
            fetchLockcode({
                reservationId: reservationId,
                contactId: String(contactId),
                signal,
            }),
        {
            enabled: !!reservationId && !!contactId,
            refetchInterval: 1000 * 60 * 30, // 30 mins
            staleTime: 1000 * 60 * 30, // 30 mins,
        }
    )
}

export { useLockcode }
