import { AxiosError } from "axios"
import { useMutation, UseMutationResult } from "react-query"
import {
    patchRefreshStatement,
    PatchRefreshStatementRequest,
} from "./patchRefreshStatement"

export const useRefreshStatementMutation = (): UseMutationResult<
    void,
    AxiosError,
    PatchRefreshStatementRequest,
    unknown
> =>
    useMutation<void, AxiosError, PatchRefreshStatementRequest, unknown>(
        request => patchRefreshStatement(request)
    )
