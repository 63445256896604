import { useQuery } from "react-query"
import { fetchBookingProbability } from "./fetchBookingProbability"

const BOOKING_PROBABILITY_KEY = "bookingProbability" as const

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useBookingProbability = (
    unitId: string | null,
    startDate: Date,
    endDate: Date
) =>
    useQuery(
        [BOOKING_PROBABILITY_KEY, unitId, startDate, endDate],
        ({ signal }) =>
            fetchBookingProbability({
                unitId: unitId as string,
                startDate,
                endDate,
                signal,
            }),
        {
            enabled: !!unitId,
            refetchInterval: 1000 * 60 * 60, // 1 hour
            staleTime: 1000 * 60 * 10, // 10 minutes
        }
    )

export { useBookingProbability }
