import classNames from "classnames"
import styles from "./RangeSelector.module.scss"
import { RangeOptions } from "../types"
import { FC } from "react"
import Select from "lib/components/Select"
import useWindowWidth from "hooks/common/useWindowSize"
import { SelectSection } from "lib/components/Select/Select"

type Props = {
    onRangeSelected: (range: RangeOptions) => void
    selected: RangeOptions
    v2?: boolean
}

export const RangeSelector: FC<Props> = ({ onRangeSelected, selected, v2 }) => {
    const windowWidth = useWindowWidth()

    const sections: SelectSection[] = v2
        ? [
              {
                  title: "Range",
                  items: [
                      {
                          text: "1 Day",
                          displayValue: "1D",
                          value: "1D",
                      },
                      {
                          text: "1 Week",
                          displayValue: "1W",
                          value: "1W",
                      },
                      {
                          text: "1 Month",
                          displayValue: "1M",
                          value: "1M",
                      },
                      {
                          text: "3 Months",
                          displayValue: "3M",
                          value: "3M",
                      },
                      {
                          text: "6 Months",
                          displayValue: "6M",
                          value: "6M",
                      },
                      {
                          text: "1 Year",
                          displayValue: "1Y",
                          value: "1Y",
                      },
                  ],
              },
          ]
        : [
              {
                  title: "Range",
                  items: [
                      {
                          text: "1 Week",
                          displayValue: "1W",
                          value: "1W",
                      },
                      {
                          text: "1 Month",
                          displayValue: "1M",
                          value: "1M",
                      },
                      {
                          text: "3 Months",
                          displayValue: "3M",
                          value: "3M",
                      },
                      {
                          text: "1 Year",
                          displayValue: "1Y",
                          value: "1Y",
                      },
                  ],
              },
          ]

    /**
     * Dynamically calculate snap point based on content height
     * @param maxHeight
     */
    const getSnapPoints = (
        maxHeight: number,
        minHeight: number,
        showSearch?: boolean
    ) => {
        const searchBuffer = showSearch ? 50 : 0
        const SECTION_TITLE_HEIGHT = 20
        const SECTION_ITEM_HEIGHT = 40
        const contentHeight = sections.reduce<number>((acc, section) => {
            return (
                acc +
                section.items.length * SECTION_ITEM_HEIGHT +
                SECTION_TITLE_HEIGHT
            )
        }, searchBuffer)

        if (minHeight > contentHeight) {
            return [minHeight]
        }

        if (maxHeight > contentHeight) {
            return [contentHeight]
        }

        // default currently used
        return [maxHeight - maxHeight / 10, maxHeight / 4, maxHeight * 0.6]
    }

    return (
        <>
            {windowWidth < 768 ? (
                <div className={styles.mobileRange}>
                    <Select
                        onChange={value => {
                            onRangeSelected(value as RangeOptions)
                        }}
                        dropdownEdge="left"
                        width={75}
                        snapPoints={({ maxHeight, minHeight }) =>
                            getSnapPoints(maxHeight, minHeight)
                        }
                        sections={sections}
                        value={selected}
                    />
                </div>
            ) : (
                <div
                    className={classNames("antialiased", {
                        [styles.rangeButtons as string]: !v2,
                        [styles.rangeButtonsV2 as string]: v2,
                    })}
                >
                    {sections[0]?.items.map(range => {
                        return (
                            <button
                                key={range.value}
                                className={classNames({
                                    [styles.selected as string]:
                                        selected === range.value,
                                })}
                                onClick={() =>
                                    onRangeSelected(range.value as RangeOptions)
                                }
                            >
                                {range.displayValue}
                            </button>
                        )
                    })}
                </div>
            )}
        </>
    )
}
