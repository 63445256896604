import { MultiDayPicker } from "components/Calendar"
import { addDays, isBefore, startOfDay } from "date-fns"
import { useHomeAvailabilityMutation } from "hooks/home-availability"
import { useContactId } from "hooks/user"
import { Secondary, Primary } from "lib/components/Buttons/BaseButton"
import Modal from "lib/components/Modal/Modal"
import { FC, useCallback, useState } from "react"
import { FormattedDate, FormattedMessage, useIntl } from "react-intl"
import styles from "./HomeAvailabilityModal.module.scss"
import { ReactComponent as AvailabilitySentImage } from "assets/onboarding-confirmation.svg"
import { useUnits } from "hooks/units"
import { ButtonLoader } from "lib/components/Loader/ButtonLoader/ButtonLoader"
import { AlertTriangle } from "react-feather"
import { useMultiDay } from "components/Calendar/MultiDayPicker/MultiDayPicker"
import { sortBy } from "lodash"
import { TextField } from "lib/components/TextField/TextField"
import { useOwnerFeatureFlag } from "hooks/owner-feature-flag/useOwnerFeatureFlag"
import {
    trackEditNotes,
    trackSubmitAvailability,
} from "services/segment/onboarding/onboardingTracking"
import useRouter from "hooks/common/useRouter"
import { isFeatureFlagEnabled } from "utils/feature-flag"
import { useIsEmployee } from "hooks/user"

const HomeAvailabilitySuccess = ({
    onClose,
}: {
    onClose: () => void
}): JSX.Element => {
    return (
        <div className={styles.success}>
            <AvailabilitySentImage />
            <h3 className={styles.smallHeading}>
                <FormattedMessage
                    id="Onboarding.homeAvailibility.sent"
                    defaultMessage="AVAILABILITY SENT"
                />
            </h3>
            <p className={styles.text}>
                <FormattedMessage
                    id="Onboarding.homeAvailibility.sentDescription"
                    defaultMessage="Your availablity was sent successfully! A member of our onboarding team will reach out shortly to schedule a time to come to your vacation home."
                />
            </p>
            <Primary onClick={onClose}>
                <FormattedMessage
                    id="Onboarding.homeAvailibility.done"
                    defaultMessage="Done"
                />
            </Primary>
        </div>
    )
}

const HomeAvailabilityError = ({
    message,
    onClose,
}: {
    message: React.ReactNode
    onClose: () => void
}): JSX.Element => {
    return (
        <div className={styles.error}>
            <AlertTriangle className={styles.image} />
            <p className={styles.text}>{message}</p>
            <Primary onClick={onClose}>
                <FormattedMessage
                    id="Onboarding.homeAvailibility.backToSetup"
                    defaultMessage="Back to setup"
                />
            </Primary>
        </div>
    )
}

const HomeAvailabilityModal: FC = (): JSX.Element => {
    const { params, navigate } = useRouter()
    const unitId = params.unitId ?? ""
    const intl = useIntl()
    const unitsQuery = useUnits()
    const unit = unitsQuery.data?.find(unit => unit.id === unitId)
    const featureFlag = useOwnerFeatureFlag(
        "ob-availability-ticket-assignee-logic-update"
    )
    const isEmployee = useIsEmployee()
    const assigneeId = isFeatureFlagEnabled(featureFlag)
        ? unit?.attributes.onboardingTicketAssigneeId
        : undefined

    const contactId = useContactId()
    const mutation = useHomeAvailabilityMutation({
        unitId: Number(unitId),
        contactId: contactId as string,
        assigneeId: assigneeId ?? undefined,
    })

    const handleClose = useCallback(() => {
        navigate("/setup")
    }, [navigate])
    const [today] = useState(new Date())
    const isDayBlocked = useCallback(
        (date: Date | number) =>
            mutation.isLoading || isBefore(date, addDays(startOfDay(today), 1)),
        [mutation.isLoading, today]
    )
    const { selectedDates, handleDayClicked, clearDates } = useMultiDay(
        isDayBlocked,
        unitId
    )

    const [note, setNote] = useState("")

    const handleSubmit = useCallback(() => {
        trackSubmitAvailability(unitId)
        mutation.mutate({ availableDates: selectedDates, note })
    }, [mutation, selectedDates, note, unitId])

    const handleClear = useCallback(() => {
        setNote("")
        clearDates()
    }, [clearDates])

    const disableSubmit =
        isEmployee || selectedDates.length < 1 || mutation.isLoading

    return (
        <Modal
            onCloseHandler={handleClose}
            modalContentStyleOverride={styles.modalContent}
        >
            {mutation.isSuccess && (
                <HomeAvailabilitySuccess onClose={handleClose} />
            )}
            {mutation.isError && (
                <HomeAvailabilityError
                    message={mutation.error.message}
                    onClose={handleClose}
                />
            )}
            {(mutation.isIdle || mutation.isLoading) && (
                <div className={styles.container}>
                    <div className={styles.header}>
                        <h2 className={styles.title}>
                            <FormattedMessage
                                id="Onboarding.availability.submitAvailability"
                                defaultMessage="Submit Availability for {unitName}."
                                values={{
                                    unitName: unit?.attributes.name,
                                }}
                            />
                        </h2>
                        <p className={styles.description}>
                            <FormattedMessage
                                id="Onboarding.availability.lettingUsKnow"
                                defaultMessage="Letting us know when your house is free helps us onboard your home faster."
                            />
                        </p>
                    </div>
                    <div className={styles.calendar}>
                        <MultiDayPicker
                            isDayBlocked={isDayBlocked}
                            onDayClicked={handleDayClicked}
                            selectedDates={selectedDates}
                        />
                    </div>
                    <div className={styles.rightColumn}>
                        <div className={styles.selectedDates}>
                            <label
                                htmlFor="selectedDates"
                                className={styles.label}
                            >
                                <FormattedMessage
                                    id="Onboarding.availability.selectedDates"
                                    defaultMessage="Selected dates"
                                />
                            </label>
                            <div className={styles.datesScrollbox}>
                                {selectedDates.length ? (
                                    <ul
                                        id="selectedDates"
                                        className={styles.dates}
                                    >
                                        {sortBy(selectedDates).map(d => (
                                            <li key={d.toISOString()}>
                                                <FormattedDate
                                                    value={d}
                                                    dateStyle="medium"
                                                />
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <p className={styles.dateDescription}>
                                        <FormattedMessage
                                            id="Onboarding.availability.dateDescription"
                                            defaultMessage="Provide your home’s availability by
                                            selecting dates on the calendar. You can
                                            also add any additional notes regarding your
                                            home below."
                                        />
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className={styles.notes}>
                            <label htmlFor="note" className={styles.label}>
                                Notes (optional)
                            </label>
                            <TextField
                                id="note"
                                value={note}
                                placeholder={intl.formatMessage({
                                    id: "Onboarding.availability.notePlaceholder",
                                    defaultMessage:
                                        "Use this field to let us know any additional info. If you prefer a morning or afternoon visit, a lockbox code is needed to access the home, etc. ",
                                })}
                                onChange={(event): void => {
                                    setNote(event.target.value)
                                    trackEditNotes(unitId)
                                }}
                                multiline
                                maxLength={255}
                            />
                        </div>
                        <div className={styles.buttons}>
                            <Primary
                                className={styles.submitbutton}
                                onClick={handleSubmit}
                                disabled={disableSubmit}
                                isLoading={mutation.isLoading}
                                loader={
                                    <ButtonLoader
                                        indicator={"dot"}
                                        indicatorCount={3}
                                        animation={"blink"}
                                    />
                                }
                            >
                                <FormattedMessage
                                    id="Onboarding.availability.submit"
                                    defaultMessage="Submit"
                                />
                            </Primary>
                            {(selectedDates.length > 0 || note !== "") &&
                                !mutation.isLoading && (
                                    <Secondary
                                        onClick={handleClear}
                                        className={styles.clearbutton}
                                    >
                                        <FormattedMessage
                                            id="Onboarding.availability.clear"
                                            defaultMessage="Clear"
                                        />
                                    </Secondary>
                                )}
                        </div>
                    </div>
                </div>
            )}
        </Modal>
    )
}

export { HomeAvailabilityModal }
