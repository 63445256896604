import React, { useEffect, useState } from "react"
import deepEqual from "deep-equal"
import { Parking } from "@vacasa/owner-api-models"

import Panel from "../OnboardingPanel"
import SubmitButtonRow, {
    ButtonState,
} from "../../../lib/components/Onboarding/SubmitButtonRow"
import ConfirmButtonRow from "../../../lib/components/Onboarding/ConfirmButtonRow"
import RowWrapper, {
    RowType,
} from "../../../lib/components/Onboarding/RowWrapper"
import ErrorComponent from "../ErrorComponent"
import { Generic as DropdownOption } from "../../../models/Generic"

import styles from "../../../sass/onboarding.module.scss"
import { FormattedMessage, useIntl } from "react-intl"
import { trackUpdateParking } from "services/segment/onboarding/onboardingTracking"

interface ParkingSectionProps {
    apiModel: Parking
    confirmedParking: boolean
    getOptionByValue: (
        options: DropdownOption[],
        value?: number | null
    ) => DropdownOption | null
    patchPreference: (preferenceName: string) => Promise<void>
    patchUnit: (data: any) => Promise<void> // eslint-disable-line @typescript-eslint/no-explicit-any
    saveParkingHandler: (enabled: boolean) => void
    unitId: string
}

const ParkingSection: React.FC<ParkingSectionProps> = ({
    apiModel,
    confirmedParking,
    getOptionByValue,
    patchPreference,
    patchUnit,
    saveParkingHandler,
    unitId,
}: ParkingSectionProps): JSX.Element => {
    const [errorSavingParking, setErrorSavingParking] = useState(false)
    const [saveParkingEnabled, setSaveParkingEnabled] = useState(
        ButtonState.Disabled
    )
    const apiParking = apiModel
    const [tempParking, setTempParking] = useState(apiParking)
    const intl = useIntl()

    // TODO seperate value and name, this causes issues when changing languages
    const fourWDRequiredOptions: DropdownOption[] = [
        {
            name: intl.formatMessage({
                id: "Onboarding.parking.fourWD.options.notRequired",
                defaultMessage: "Not required",
            }),
            id: -1,
        },
        {
            name: intl.formatMessage({
                id: "Onboarding.parking.fourWD.options.inWinter",
                defaultMessage: "Required in winter",
            }),
            id: 1,
        },
        {
            name: intl.formatMessage({
                id: "Onboarding.parking.fourWD.options.required",
                defaultMessage: "Required",
            }),
            id: 2,
        },
    ]

    useEffect(() => {
        setSaveParkingEnabled(
            !deepEqual(tempParking, apiParking)
                ? ButtonState.Enabled
                : ButtonState.Disabled
        )
    }, [tempParking, apiParking])

    useEffect(() => {
        saveParkingHandler(!deepEqual(tempParking, apiParking))
    }, [tempParking, apiParking, saveParkingHandler])

    const hasData =
        tempParking.paid !== undefined &&
        tempParking.accessible !== undefined &&
        tempParking.valet !== undefined &&
        tempParking.street !== undefined &&
        !!tempParking?.fourWheelDriveRequired &&
        !!tempParking?.total
    const noChanges = saveParkingEnabled === ButtonState.Disabled
    const shouldShowConfirmButton = hasData && noChanges && !confirmedParking

    function resetParking(): void {
        setSaveParkingEnabled(ButtonState.Disabled)
        setTempParking(apiParking)
    }

    return (
        <div>
            <h5>
                <FormattedMessage
                    id="Onboarding.sectionTitles.parking"
                    defaultMessage="Parking"
                />
            </h5>

            <p className={styles.section_description}>
                <FormattedMessage
                    id="Onboarding.sectionDescriptions.parking"
                    defaultMessage="Providing parking information for your property can help guests plan ahead for their trip and increase your booking and occupancy rates."
                />
            </p>

            {errorSavingParking ? (
                <ErrorComponent
                    onClick={(): void => setErrorSavingParking(false)}
                />
            ) : (
                <Panel className={styles.panel_content}>
                    <RowWrapper
                        type={RowType.Incrementer}
                        title={
                            <FormattedMessage
                                id="Onboarding.parking.spaces"
                                defaultMessage="Parking spaces"
                            />
                        }
                        selectedValue={tempParking.total}
                        maxValue={10}
                        onChangeHandler={(
                            value: number | null | undefined
                        ): void =>
                            setTempParking({
                                ...tempParking,
                                total: value,
                            })
                        }
                    />
                    <RowWrapper
                        type={RowType.Drowdown}
                        title={
                            <FormattedMessage
                                id="Onboarding.parking.fourWD.title"
                                defaultMessage="4x4 Required"
                            />
                        }
                        tooltipTitle={"4X4 REQUIRED"}
                        tooltipBody={intl.formatMessage({
                            id: "Onboarding.parking.fourWD.tooltip",
                            defaultMessage:
                                "Accessing the home requires a car with four-wheel drive.",
                        })}
                        selectedValue={getOptionByValue(
                            fourWDRequiredOptions,
                            tempParking.fourWheelDriveRequired
                        )}
                        options={fourWDRequiredOptions}
                        onChangeHandler={(
                            value: DropdownOption | null | undefined
                        ): void =>
                            setTempParking({
                                ...tempParking,
                                fourWheelDriveRequired: value?.id,
                            })
                        }
                    />
                    <RowWrapper
                        type={RowType.Toggle}
                        title={
                            <FormattedMessage
                                id="Onboarding.parking.paid.title"
                                defaultMessage="Paid parking only"
                            />
                        }
                        tooltipTitle={"PAID PARKING ONLY"}
                        tooltipBody={intl.formatMessage({
                            id: "Onboarding.parking.paid.tooltip",
                            defaultMessage:
                                "Guests must pay for parking on the property.",
                        })}
                        selectedValue={tempParking.paid}
                        onChangeHandler={(
                            value: boolean | null | undefined
                        ): void =>
                            setTempParking({
                                ...tempParking,
                                paid: value,
                            })
                        }
                    />
                    <RowWrapper
                        type={RowType.Toggle}
                        title={
                            <FormattedMessage
                                id="Onboarding.parking.valet.title"
                                defaultMessage="Valet parking"
                            />
                        }
                        tooltipTitle={"VALET PARKING"}
                        tooltipBody={intl.formatMessage({
                            id: "Onboarding.parking.valet.tooltip",
                            defaultMessage:
                                "Valet parking is available at this home or complex. Fees may apply.",
                        })}
                        selectedValue={tempParking.valet}
                        onChangeHandler={(
                            value: boolean | null | undefined
                        ): void =>
                            setTempParking({
                                ...tempParking,
                                valet: value,
                            })
                        }
                    />
                    <RowWrapper
                        type={RowType.Toggle}
                        title={
                            <FormattedMessage
                                id="Onboarding.parking.accessible"
                                defaultMessage="Accessible (disability) parking"
                            />
                        }
                        selectedValue={tempParking.accessible}
                        onChangeHandler={(
                            value: boolean | null | undefined
                        ): void =>
                            setTempParking({
                                ...tempParking,
                                accessible: value,
                            })
                        }
                    />
                    <RowWrapper
                        type={RowType.Toggle}
                        title={
                            <FormattedMessage
                                id="Onboarding.parking.street"
                                defaultMessage="Street parking only"
                            />
                        }
                        selectedValue={tempParking.street}
                        onChangeHandler={(
                            value: boolean | null | undefined
                        ): void =>
                            setTempParking({
                                ...tempParking,
                                street: value,
                            })
                        }
                        lastRow={true}
                    />
                    {shouldShowConfirmButton ? (
                        <ConfirmButtonRow
                            buttonState={ButtonState.Enabled}
                            onSubmit={(): void => {
                                patchPreference("onboarding-confirmed-parking")
                            }}
                        />
                    ) : (
                        <SubmitButtonRow
                            buttonState={saveParkingEnabled}
                            onSubmit={(): void => {
                                setSaveParkingEnabled(ButtonState.Loading)
                                Promise.all([
                                    patchUnit({
                                        parking: tempParking,
                                    }),
                                    patchPreference(
                                        "onboarding-confirmed-parking"
                                    ),
                                ])
                                    .then(() => {
                                        return trackUpdateParking(
                                            tempParking,
                                            apiParking,
                                            fourWDRequiredOptions,
                                            unitId
                                        )
                                    })
                                    .catch(() => {
                                        setErrorSavingParking(true)
                                        // reset tempModel to the original value as patch failed
                                        resetParking()
                                    })
                            }}
                            onCancel={(): void => {
                                resetParking()
                            }}
                        />
                    )}
                </Panel>
            )}
        </div>
    )
}

export default ParkingSection
