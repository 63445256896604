import {
    HousekeepingPhoto,
    HousekeepingSpace,
} from "@vacasa/owner-api-models"
import {
    IconHomecareBath,
    IconHomecareBed,
    IconHomecareCooking,
    IconHomecareCouch,
    IconHomecareDoorClose,
    IconHomecareInspected,
    IconHomecareMaintenance,
    IconHomecareNotInspected,
    IconHomecareSun,
    IconHomecareWasher,
} from "assets"
import PlaceholderImage from "assets/placeholder-home-image.png"
import classNames from "classnames"
import { format, parseISO } from "date-fns"
import { useHousekeeping } from "hooks/housekeeping"
import { useUnit } from "hooks/units"
import Modal from "lib/components/Modal/Modal"
import { capitalize } from "lodash"
import { FC, useCallback, useMemo, useState } from "react"
import { ChevronLeft, ChevronRight } from "react-feather"
import { useIntl } from "react-intl"
import { Keyboard, Navigation, Pagination } from "swiper"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import { Swiper, SwiperSlide } from "swiper/react"
import { InspectionCategoryType, mapCategoryToLabel } from "utils/homecare"
import { WidgetItem } from "./BaseWidget"
import styles from "./InspectionReportWidget.module.scss"
import { WidgetError } from "./WidgetError"
import { Image } from "lib/components/Image"
import {
    trackInspectionPhotoClicked,
    trackSeeAllSpacesClicked,
    trackViewAllPhotosClicked,
} from "services/segment/homeInfo/homeInfoTracking"
export interface InspectionReportWidgetProps {
    contactId: string
    unitId: string
}
export const InspectionReportWidget: FC<InspectionReportWidgetProps> = ({
    contactId,
    unitId,
}) => {
    const intl = useIntl()
    const [showSpacesModal, setShowSpacesModal] = useState(false)
    const [photoModalOptions, setShowPhotoModalOptions] = useState({
        show: false,
        initialSlide: 0,
    })

    const unitsQuery = useUnit(unitId)
    const housekeepingQuery = useHousekeeping(
        { contactId, unitId },
        { queryKey: "" }
    )
       
    const isLoading = housekeepingQuery.isLoading || unitsQuery.isLoading

    const lastClean = housekeepingQuery.data?.attributes.lastClean

    const inspectedCount = lastClean
        ? lastClean.spaces.reduce((count, space) => {
              return count + space.itemsInspectedCount
          }, 0)
        : 0

    const onShowSpacesModal = () => {
        setShowSpacesModal(true)
        trackSeeAllSpacesClicked(unitId)
    }

    const onCloseSpacesModal = useCallback(() => {
        setShowSpacesModal(false)
    }, [])

    const showPhotosModal = (activeSlide: number | undefined = 1) => {
        setShowPhotoModalOptions({
            show: true,
            initialSlide: activeSlide,
        })
        trackInspectionPhotoClicked(unitId, activeSlide)
    }

    const onClosePhotosModal = useCallback(() => {
        setShowPhotoModalOptions({
            show: false,
            initialSlide: 0,
        })
    }, [])

    const onViewAllPhotosClick = () => {
        trackViewAllPhotosClicked(unitId)
        setShowPhotoModalOptions({
            show: true,
            initialSlide: 0,
        })
    }

    if (housekeepingQuery.isError || unitsQuery.isError) {
        const onRetryClick = () => {
            if (unitsQuery.isError) unitsQuery.refetch()
            if (housekeepingQuery.isError) housekeepingQuery.refetch()
        }

        return (
            <WidgetItem gridColumn="1/3">
                <WidgetError
                    errorHeading={intl.formatMessage({
                        id: "HomeInfo.widgets.inspectionReport.title",
                        defaultMessage: "Latest Inspection Report",
                    })}
                    errorMessage={intl.formatMessage({
                        id: "HomeInfo.widgets.inspectionReport.errorMessage",
                        defaultMessage:
                            "We're having trouble loading this content.",
                    })}
                    handleClick={onRetryClick}
                />
            </WidgetItem>
        )
    }

    const renderPhotosAction = isLoading || !!lastClean?.photos.length

    if (!isLoading && !lastClean) return <></>

    return (
        <WidgetItem gridColumn="1/3">
            <div
                className={classNames(styles.container, {
                    loading: isLoading,
                })}
            >
                <div className={styles.content}>
                    <h3 className={styles.title}>
                        {!isLoading &&
                            intl.formatMessage({
                                id: "HomeInfo.widgets.inspectionReport.title",
                                defaultMessage: "Latest Inspection Report",
                            })}
                    </h3>
                    <div className={styles.lastInspectionDate}>
                        {!isLoading &&
                            lastClean?.inspectionCompletedAtLocalTime &&
                            format(
                                parseISO(lastClean.inspectionCompletedAtLocalTime),
                                "MMM dd, yyyy • h:mmaaa"
                            )}
                    </div>
                    <div className={styles.inspectedDetails}>
                        {!isLoading &&
                            lastClean &&
                            `${inspectedCount} items inspected in 8 spaces`}
                    </div>
                    <div className={styles.spaces}>
                        {[
                            "entrance",
                            "kitchen",
                            "bedroom",
                            "bathroom",
                            "backyard",
                            "living",
                            "laundry",
                            "general",
                        ].map(spaceName => {
                            const filteredSpaces =
                                lastClean?.spaces.filter(
                                    space =>
                                        mapCategoryToLabel(
                                            space.category as InspectionCategoryType
                                        ) === spaceName
                                ) ?? []

                            const inspected =
                                filteredSpaces.length === 0
                                    ? false
                                    : filteredSpaces.every(
                                          space => space.inspected
                                      )

                            return (
                                <div
                                    key={spaceName}
                                    className={styles.spaceItem}
                                >
                                    <HomecareIcon
                                        icon={
                                            spaceName as HomecareIconProps["icon"]
                                        }
                                        inspected={inspected}
                                    />
                                    <div className={styles.spaceName}>
                                        {!isLoading && capitalize(spaceName)}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <button
                        onClick={onShowSpacesModal}
                        className={styles.seeAllLink}
                    >
                        {!isLoading && (
                            <>
                                See all spaces <ChevronRight />
                            </>
                        )}
                    </button>
                    {renderPhotosAction && (
                        <div className={styles.viewImagesWrap}>
                            <div>
                                <Image
                                    loading="lazy"
                                    alt="Photos"
                                    src={lastClean?.photos[0]?.imageURL}
                                    fallbackImage={PlaceholderImage}
                                />
                                <button
                                    onClick={onViewAllPhotosClick}
                                >{`+ ${lastClean?.photos.length}`}</button>
                            </div>
                            <button onClick={onViewAllPhotosClick}>
                                View all Photos
                            </button>
                        </div>
                    )}
                </div>
                <div className={styles.galleryGrid}>
                    <div className={styles.galleryContainer}>
                        <div className={styles.galleryGridInner}>
                            {isLoading && (
                                <>
                                    <div />
                                    <div />
                                    <div />
                                    <div />
                                    <div />
                                    <div />
                                    <div />
                                    <div />
                                    <div />
                                </>
                            )}
                            {!isLoading &&
                                lastClean?.photos.map((photo, index) => (
                                    <div key={photo.id}>
                                        <Image
                                            loading="lazy"
                                            src={photo.imageURL}
                                            alt={`${photo.id}`}
                                            onClick={() =>
                                                showPhotosModal(index)
                                            }
                                            fallbackImage={PlaceholderImage}
                                        />
                                    </div>
                                ))}
                            {!!lastClean?.photos.length && (
                                <div className={styles.imgSpacer} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {showSpacesModal && (
                <SpacesModal
                    onModalClose={onCloseSpacesModal}
                    spaces={lastClean?.spaces ?? []}
                />
            )}
            {photoModalOptions.show && (
                <PhotosModal
                    onModalClose={onClosePhotosModal}
                    photos={lastClean?.photos ?? []}
                    initialSlide={photoModalOptions.initialSlide}
                />
            )}
        </WidgetItem>
    )
}

const getIcon = (icon: string) => {
    switch (icon) {
        case "entrance":
            return <IconHomecareDoorClose />
        case "kitchen":
            return <IconHomecareCooking />
        case "bedroom":
            return <IconHomecareBed />
        case "bathroom":
            return <IconHomecareBath />
        case "backyard":
            return <IconHomecareSun />

        case "laundry":
        case "washer_dryer":
            return <IconHomecareWasher />
        case "living":
        case "living_room":
            return <IconHomecareCouch />
        case "general":
        default:
            return <IconHomecareMaintenance />
    }
}

interface HomecareIconProps {
    icon:
        | "entrance"
        | "kitchen"
        | "bedroom"
        | "bathroom"
        | "backyard"
        | "living"
        | "laundry"
        | "general"
    inspected: boolean
}
const HomecareIcon: FC<HomecareIconProps> = ({ icon, inspected }) => {
    const iconComponent = useMemo(() => getIcon(icon), [icon])

    return (
        <div className={styles.homecareIcon}>
            <div className={styles.homecareIconStatus}>
                {inspected ? (
                    <IconHomecareInspected />
                ) : (
                    <IconHomecareNotInspected />
                )}
            </div>
            {iconComponent}
        </div>
    )
}

interface SpacesModalProps {
    onModalClose: () => void
    spaces: HousekeepingSpace[]
}
const SpacesModal: FC<SpacesModalProps> = ({ onModalClose, spaces }) => {
    return (
        <Modal
            onCloseHandler={onModalClose}
            modalPanelStyleOverride={styles.spacesModal}
            modalContentStyleOverride={styles.spacesModalContent}
            closeButtonStyleOverride={styles.spaceModalClose}
        >
            <div className={styles.title}>
                Inspected Spaces ({spaces.length})
            </div>
            <div className={styles.content}>
                {spaces.map(space => (
                    <div key={space.id} className={styles.row}>
                        <div className={styles.category}>
                            {getIcon(
                                space.category as HomecareIconProps["icon"]
                            )}
                        </div>
                        {space.name}
                        <div className={styles.status}>
                            {space.inspected ? (
                                <IconHomecareInspected />
                            ) : (
                                <IconHomecareNotInspected />
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </Modal>
    )
}

interface PhotosModalProps {
    onModalClose: () => void
    photos: HousekeepingPhoto[]
    initialSlide?: number
}
const PhotosModal: FC<PhotosModalProps> = ({
    initialSlide,
    onModalClose,
    photos,
}) => {
    const [activeSlideIndex, setActiveSlideIndex] = useState(initialSlide ?? 0)

    return (
        <Modal
            onCloseHandler={onModalClose}
            modalPanelStyleOverride={styles.photosModalPanel}
            modalContentStyleOverride={styles.photosModalContent}
            modalOverlayStyleOverride={styles.photoModalOverlay}
            closeButtonStyleOverride={styles.spaceModalClose}
        >
            <div className={styles.photosModalContainer}>
                <header className={styles.photosModalHeader}>
                    {!!photos.length &&
                        `${activeSlideIndex + 1} of ${photos.length}`}
                </header>
                <div className={styles.swiperContainer}>
                    <Swiper
                        navigation={{
                            nextEl: ".swiper-button-next-custom",
                            prevEl: ".swiper-button-prev-custom",
                        }}
                        pagination={{
                            dynamicBullets: true,
                            dynamicMainBullets: 5,
                        }}
                        keyboard
                        modules={[Keyboard, Navigation, Pagination]}
                        initialSlide={initialSlide}
                        onActiveIndexChange={swiper =>
                            setActiveSlideIndex(swiper.activeIndex)
                        }
                    >
                        {photos.map(photo => (
                            <SwiperSlide key={photo.id}>
                                <Image
                                    loading="lazy"
                                    src={photo.imageURL}
                                    alt={`${photo.id}`}
                                    fallbackImage={PlaceholderImage}
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <div
                        className={classNames(
                            styles.swiperNavigation,
                            styles.swiperNavigationPrev,
                            "swiper-button-prev-custom"
                        )}
                    >
                        <ChevronLeft />
                    </div>
                    <div
                        className={classNames(
                            styles.swiperNavigation,
                            styles.swiperNavigationNext,
                            "swiper-button-next-custom"
                        )}
                    >
                        <ChevronRight />
                    </div>
                </div>
            </div>
        </Modal>
    )
}
