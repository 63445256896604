import { authAxios } from "../../api/authAxios"

export type PutDirectDepositUnenrollRequest = {
    contactId: string
    signal?: AbortSignal
}

export type PutDirectDepositUnenrollResponse = {
    data: {
        message: string
    }
}

export const putDirectDepositUnenroll = ({
    contactId,
    signal,
}: PutDirectDepositUnenrollRequest): Promise<PutDirectDepositUnenrollResponse> => {
    return authAxios
        .put<PutDirectDepositUnenrollResponse>(
            `/v1/legacy/owners/${contactId}/direct-deposit-unenroll`,
            undefined,
            {
                signal,
            }
        )
        .then(response => response.data)
}
