import { createContext, FC, PropsWithChildren, useState } from "react"
import { FormState, W9Data } from "../types"
import { defaultW9Data } from "../W9/constants"

export const TaxInformationFormStateContext = createContext<FormState>({
    data: defaultW9Data,
    setData: () => {},
})

export const TaxInformationFormProvider: FC<PropsWithChildren> = ({
    children,
}) => {
    const [data, setData] = useState<W9Data>(defaultW9Data)

    return (
        <TaxInformationFormStateContext.Provider
            value={{
                data,
                setData,
            }}
        >
            {children}
        </TaxInformationFormStateContext.Provider>
    )
}
