import { JSONAPIResource, UserPreference } from "@vacasa/owner-api-models"
import { AxiosError } from "axios"
import { UseQueryResult, useQuery } from "react-query"
import { fetchUserPreferencesByUnit } from "./fetchUserPreferencesByUnit"

export const OWNER_PREFERENCE_V2_QUERY_KEY = "user-preferences-v2"

const useUserPreferencesByUnitId = (unitId: string): UseQueryResult<JSONAPIResource<UserPreference>[], AxiosError> => {
    return useQuery(
        [OWNER_PREFERENCE_V2_QUERY_KEY, unitId],
        () =>
            fetchUserPreferencesByUnit(unitId),
        {
            refetchOnWindowFocus: false,
            refetchInterval: 1000 * 60 * 60, // 1 hour
            staleTime: 1000 * 60 * 60, // 1 hour
        }
    )
}

export { useUserPreferencesByUnitId }
