import { UserNotification } from "@vacasa/owner-api-models"
import { authAxios } from "../../api/authAxios"
import LoggingService from "services/logging/logging.service"

export type PutUserNotificationPreferenceRequest = {
    body?: {
        preferenceId: string
        preferenceKey: "email" | "push" | "sms"
        preferenceValue: boolean
    }
    action?:
        | "subscribeAllEmail"
        | "subscribeAllPush"
        | "subscribeAllSms"
        | "unsubscribeAllEmail"
        | "unsubscribeAllPush"
        | "unsubscribeAllSms"
}

export const putUserNotificationPreference = async ({
    body,
    action,
}: PutUserNotificationPreferenceRequest): Promise<UserNotification> => {
    if (action) {
        const response = await authAxios.put(
            "v1/user-notification-preferences",
            undefined,
            {
                params: {
                    action,
                },
            }
        )
        return response.data.data.attributes.notification
    }
    if (!body) {
        LoggingService.error({
            message: "putUserNotificationPreference - Missing request body",
        })
        throw new Error("putUserNotificationPreference - Missing request body")
    }
    const response = await authAxios.put("v1/user-notification-preferences", {
        data: [
            {
                id: body.preferenceId,
                type: "user-preferences-tree",
                attributes: {
                    key: body.preferenceKey,
                    value: body.preferenceValue,
                },
            },
        ],
    })
    return response.data.data.attributes.notification
}
