import { ChevronLeft, ChevronRight } from "react-feather"
import { useSwiper } from "swiper/react"
import styles from "./ProductTour.module.scss"
import { FC } from "react"
import {
    trackProductTourFinishClicked,
    trackProductTourNextClicked,
    trackProductTourPreviousClicked,
} from "services/segment/product-tour/productTourTracking"
import { FormattedMessage } from "react-intl"

type ProductTourNavigationProps = {
    isFirst: boolean
    isLast: boolean
    name: string
    onFinishClick: (finished?: boolean) => void
}

export const ProductTourNavigation: FC<ProductTourNavigationProps> = ({
    isFirst,
    isLast,
    name,
    onFinishClick,
}) => {
    const swiper = useSwiper()

    const onPreviousClick = () => {
        swiper.slidePrev()
        trackProductTourPreviousClicked(name)
    }

    const onNextClick = () => {
        if (isLast) {
            trackProductTourFinishClicked(name)
            onFinishClick(true)
        } else {
            swiper.slideNext()
            trackProductTourNextClicked(name)
        }
    }

    return (
        <div className={styles.actions}>
            {!isFirst && (
                <div className={styles.actionLeft}>
                    <button className={styles.action} onClick={onPreviousClick}>
                        <ChevronLeft />
                        <FormattedMessage
                            id="Shared.productTour.previous"
                            defaultMessage="Previous"
                        />
                    </button>
                </div>
            )}

            <div className={styles.actionRight}>
                <button className={styles.action} onClick={onNextClick}>
                    {isLast ? (
                        <FormattedMessage
                            id="Shared.productTour.finish"
                            defaultMessage="Finish"
                        />
                    ) : (
                        <FormattedMessage
                            id="Shared.productTour.next"
                            defaultMessage="Next"
                        />
                    )}
                    <ChevronRight />
                </button>
            </div>
        </div>
    )
}
