import { forwardRef, ReactNode, useState } from "react"
import { ChevronDown } from "react-feather"
import { FieldError } from "react-hook-form"
import { v4 as uuidV4 } from "uuid"
import styles from "./Select.module.scss"

const Select = forwardRef<
    HTMLSelectElement,
    JSX.IntrinsicElements["select"] & { label: ReactNode; error?: FieldError }
>(({ label, error, ...rest }, ref) => {
    const [id] = useState(rest.id ?? uuidV4())
    return (
        <div className={styles.container}>
            <label className={styles.label} htmlFor={id}>
                {label} {rest.required && "*"}
            </label>
            <div className={styles.selectContainer}>
                <select className={styles.select} {...rest} ref={ref} id={id} />
                <ChevronDown className={styles.chevron} aria-hidden />
            </div>
            {error !== undefined && (
                <span className={styles.errorMessage}>{error.message}</span>
            )}
        </div>
    )
})

export { Select }
