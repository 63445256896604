export const CALENDAR_TOUR_VIEWED = "calendar-tour-viewed"
export const DISPLAY_CONGRATS_MODAL = "display-congrats-modal"
export const LAST_LOGIN = "last-login"
export const NEW_PAYMENT_SETTINGS_VIEWED = "new-payment-settings-viewed"
export const ONBOARDING_COMPLETED = "onboarding-completed"
export const USER_PREFERENCE = "user-preference"
export const LAST_ACCESSED_CONTACT_ID = "last-accessed-contact-id"
export const SHOW_MIN_RATE = "performance-show-min-rate"
export const REFERRAL_NAV_CARD_DISMISSED = "referral-nav-card-dismissed"
export const REFERRAL_BANNER_DISMISSED = "referral-banner-dismissed"
export const NIGHTS_OCCUPIED_MODAL_HINT_DISMISSED =
    "nights-occupied-modal-hint-dismissed"
export const NIGHTS_OCCUPIED_MODAL_FEEDBACK_DISMISSED =
    "nights-occupied-modal-feedback-dismissed"
export const OWNER_CREATED_COMMENTS_ALERT_DISMISSED =
    "owner-created-comment-alert-dismissed"
export const MARKET_COMP_FEATURE_ALERT_DISMISSED =
    "market-comp-feature-alert-dismissed"
export const MARKET_COMP_FEATURE_FEEDBACK_DISMISSED =
    "market-comp-feature-feedback-dismissed"
export const MARKET_RATES_PRODUCT_TOUR_VIEWED =
    "market-rates-product-tour-viewed"
export const PAPERLESS_1099_NOTIFICATION_DISMISSED =
    "paperless-1099-notification-dismissed"
export const REVENUE_SIMULATOR_FEATURE_FEEDBACK_DISMISSED =
    "revenue-simulator-feature-feedback-dismissed"
export const CORE_METRICS_FEATURE_FEEDBACK_DISMISSED =
    "core-metrics-feature-feedback-dismissed"
export const REVENUE_PERSONA_QUESTIONS_COMPLETED = "owner-surveys/revenue-persona-questions-completed"
export const REVENUE_PERSONA_QUESTIONS = "owner-surveys/revenue-persona-questions"
