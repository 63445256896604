const topbar = {
    myRentals: "My Rentals",
    myTrips: "My Trips",
    profile: "Profile",
    changePassword: "Change Password",
    logout: "Logout",
    switchAccounts: "Switch Accounts",
    selectAccount: "Select An Account",
    referral: "Refer a friend",
}

export default topbar
