import classnames from "classnames"
import { format } from "date-fns"
import { FC } from "react"
import styles from "./ReservationDates.module.scss"

const getLabelStyles = (holdInProgress = false) =>
    classnames(
        "uppercase",
        "font-extrabold",
        styles["reservation-date-label"],
        {
            "text-dusk-lightest": holdInProgress,
        }
    )

const getDateClasses = (holdInProgress = false) =>
    classnames("font-extrabold", styles["reservation-date"], {
        "text-dusk-lightest": holdInProgress,
    })

interface Props {
    checkinDate: Date | null
    checkoutDate: Date | null
    holdInProgress?: boolean
}

const ReservationDates: FC<Props> = ({
    checkinDate,
    checkoutDate,
    holdInProgress,
}) => {
    return (
        <div className={styles["wrap"]}>
            <div className={styles["date-col"]}>
                <div className={getLabelStyles(holdInProgress)}>Check In</div>
                {checkinDate && (
                    <time
                        className={getDateClasses(holdInProgress)}
                        dateTime={format(checkinDate, "yyyy-MM-dd")}
                    >
                        {format(checkinDate, "MMM d")}
                    </time>
                )}
            </div>
            <div className={classnames(styles["date-col"], "text-right")}>
                <div className={getLabelStyles()}>Check Out</div>
                {checkoutDate && (
                    <time
                        className={getDateClasses()}
                        dateTime={format(checkoutDate, "yyyy-MM-dd")}
                    >
                        {format(checkoutDate, "MMM d")}
                    </time>
                )}
            </div>
        </div>
    )
}

export { ReservationDates }
