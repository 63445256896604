import React, { useEffect, useRef, useState } from "react"
import { RevenuePreferencesWidget } from "./RevenuePreferencesWidget"
import {
    REVENUE_PERSONA_QUESTIONS,
    REVENUE_PERSONA_QUESTIONS_COMPLETED,
} from "../../../../constants/preferences.constants"
import { useUserPreferencesByUnitId } from "../../../../hooks/user-preferences/useUserPreferences"
import { trackRevenuePreferenceShow } from "../../../../services/segment/survey/surveyTracking"

import styles from "./RevenuePreferences.module.scss"
import storageService from "../../../../services/storage.service"

interface RevenuePreferencesProps {
    unitId: string
}

const RevenuePreferences = ({ unitId }: RevenuePreferencesProps) => {
    const [hideBox, setHideBox] = useState<boolean>(false);
    const tracked = useRef<boolean>(false);
    const preferencesQuery = useUserPreferencesByUnitId(unitId)
    const surveySubmitted = preferencesQuery.data?.some((p) =>
        p.id === REVENUE_PERSONA_QUESTIONS &&
        p.attributes.value &&
        typeof p.attributes.value === "string" &&
        JSON.parse(p.attributes.value as string)?.answered !== "no"
    );
    const storedArray =  storageService.localStorage.getItem(REVENUE_PERSONA_QUESTIONS_COMPLETED)
    const historyArray = storedArray ? JSON.parse(storedArray) as Array<string> : [];
    const haveToShowThanksBanner = historyArray.includes(unitId)
    const loading = preferencesQuery.isLoading
    const error = preferencesQuery.isError

    useEffect(() => {
        if (!tracked.current && !loading && !error && !surveySubmitted) {
            trackRevenuePreferenceShow()
            tracked.current = true
        }
    }, [loading, error, surveySubmitted])

    useEffect(() => {
        // Update visibility based on survey submission
        if (surveySubmitted && !haveToShowThanksBanner) {
            setHideBox(true);
        } else {
            setHideBox(false);
        }
    }, [haveToShowThanksBanner, surveySubmitted]);

    if(error) return <div data-testid={'error-modal'} />

    if (surveySubmitted && !hideBox && !haveToShowThanksBanner) {
        setHideBox(true);
    }

    return (
        <div className={`${styles['animation-box']} ${hideBox ? styles['hidden'] : ''}`} data-testid="animation-box" >
            <RevenuePreferencesWidget
                loading={loading}
                thanksBanner={haveToShowThanksBanner}
                handleClose={setHideBox}
                unitId={unitId}
            />
        </div>
    )
}

export { RevenuePreferences }