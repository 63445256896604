import { useContactId } from "hooks/user"
import { useUnits } from "hooks/units"

export type HasTaxObligation = {
    isLoading: boolean
    hasTaxObligation: boolean
}

export const useHasTaxObligation = (): HasTaxObligation => {
    const unitsQuery = useUnits()
    const contactId = useContactId()

    if (unitsQuery.isLoading || !unitsQuery.isSuccess) {
        return {
            isLoading: unitsQuery.isLoading,
            hasTaxObligation: false,
        }
    }

    if (!unitsQuery.data)
        return {
            isLoading: false,
            hasTaxObligation: false,
        }

    return {
        isLoading: unitsQuery.isLoading,
        hasTaxObligation: unitsQuery.data.some(unit =>
            unit.attributes.contract.owners
                .filter(owner => owner.contactID.toString() === contactId)
                .some(
                    owner =>
                        parseFloat(owner.percentageOwnership) > 0 ||
                        parseFloat(owner.taxOwnership) > 0
                )
        ),
    }
}
