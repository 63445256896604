import defaultLanguage from "./en"

export type LanguageStrings = typeof defaultLanguage
export type NavigationStrings = typeof defaultLanguage.Navigation
export type TopbarStrings = typeof defaultLanguage.Topbar
export type ProfileStrings = typeof defaultLanguage.Profile
export type LoginStrings = typeof defaultLanguage.Login
export type UnitSelectorStrings = typeof defaultLanguage.UnitSelector
export type CalendarPageStrings = typeof defaultLanguage.CalendarPage
export type PerformanceStrings = typeof defaultLanguage.Performance
export type StatementsStrings = typeof defaultLanguage.Statements
export type HomeInfoStrings = typeof defaultLanguage.HomeInfo
export type PetsAllowedStrings = typeof defaultLanguage.PetsAllowed
export type LinenServiceStrings = typeof defaultLanguage.LinenService
export type MaintenanceStrings = typeof defaultLanguage.Maintenance
export type TaxesStrings = typeof defaultLanguage.Taxes
export type SettingsStrings = typeof defaultLanguage.Settings
export type NotFoundStrings = typeof defaultLanguage.NotFound
export type ErrorStrings = typeof defaultLanguage.Errors
export type StatementRefreshStrings = typeof defaultLanguage.StatementRefresh
export type NotificationStrings = typeof defaultLanguage.Notification
export type DashboardStrings = typeof defaultLanguage.Dashboard
export type GuestworksStrings = typeof defaultLanguage.Guestworks

export const injectLanguage = (language: string): LanguageStrings => {
    try {
        /* eslint-disable @typescript-eslint/no-var-requires */
        return require(`./${language}`).default
    } catch (e) {
        return defaultLanguage
    }
}
