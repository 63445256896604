import { JSONAPIResource, W9Address } from "@vacasa/owner-api-models"
import { authAxios } from "api/authAxios"

export const fetchMailingAddress = (
    contactId: string,
    opts?: { signal?: AbortSignal }
) => {
    return authAxios
        .get<{ data: JSONAPIResource<{ address: W9Address }> }>(
            `/v1/finances/${contactId}/w9`,
            {
                signal: opts?.signal,
            }
        )
        .then(res => res.data.data)
}
