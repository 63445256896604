import {
    Actions,
    AppConfig,
    ContextProvider,
    Manager,
    MessageList,
    RootContainer,
    MessageBubble as TwilioMessageBubble,
} from "@twilio/flex-webchat-ui"
import VacasaLogo from "assets/icon-chat-vacasa.svg"
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react"

import { APP_URL, TWILIO_ACCOUNT_ID, TWILIO_FLOW_SID } from "Environment"
import { IconChatOffline, IconChatOnline } from "assets"
import classNames from "classnames"
import usePrevious from "hooks/common/usePrevious"
import { useIntl } from "react-intl"
import LoggingService from "services/logging/logging.service"
import {
    trackEntryPointClicked,
    trackWebchatClosed,
    trackWebchatLoaded,
} from "services/segment/webchat/webchatTracking"
import { getClassStyle } from "utils/styles/styleWrapper"
import styles from "./WebChat.module.scss"
import { MessageBubble } from "./components/MessageBubble"
import { WelcomeMessage } from "./components/WelcomeMessage"
import { Theme } from "./config/theme"
import { WebChatStatus } from "@vacasa/owner-api-models"
import { isOwnerApp } from "utils/browser/browserUtil"
import { useQueryClient } from "react-query"

interface Props {
    status: WebChatStatus
    user: {
        contactId: string
        name: string
        email: string
    }
    unitId: string | null
}

export const WebChat: FC<Props> = ({ status, user, unitId }) => {
    const [managerState, setManagerState] = useState<Manager>()
    const countRef = useRef(0)
    const previous = usePrevious(status)
    const [activeChat, setActiveChat] = useState<boolean | undefined>(undefined)
    const [loading, setLoading] = useState(true)
    const intl = useIntl()
    const isUsingOwnerApp = isOwnerApp()
    const queryClient = useQueryClient()

    const onIconClick = useCallback(
        (expanded: boolean) => {
            trackEntryPointClicked({
                expanded,
                withinSupportHours: status.isOnline,
            })
        },
        [status.isOnline]
    )

    const onChatCloseClick = useCallback(() => {
        trackWebchatClosed({
            withinSupportHours: status.isOnline,
        })
        Actions.invokeAction("MinimizeChat")
    }, [status.isOnline])

    const config: AppConfig.Config = useMemo(
        () => ({
            available: true,
            accountSid: TWILIO_ACCOUNT_ID,
            flexFlowSid: TWILIO_FLOW_SID,
            fileAttachment: {
                enabled: true,
            },
            colorTheme: {
                overrides: Theme(status.isOnline || !!activeChat),
            },
            context: {
                friendlyName: user.name,
                contactId: user.contactId,
                email: user.email,
                portalURL: `${APP_URL}search?search_email=${encodeURIComponent(
                    user.email ?? ""
                )}&search_rental_id=${unitId}`,
            },
            componentProps: {
                EntryPoint: {
                    iconClosed: status.isOnline ? (
                        <IconChatOnline onClick={() => onIconClick(true)} />
                    ) : (
                        <IconChatOffline onClick={() => onIconClick(true)} />
                    ),
                    iconExpanded: status.isOnline ? (
                        <IconChatOnline onClick={() => onIconClick(false)} />
                    ) : (
                        <IconChatOffline onClick={() => onIconClick(false)} />
                    ),
                    tagline: "",
                },
                MainHeader: {
                    imageUrl: VacasaLogo,
                    titleText: "",
                    closeCallback: onChatCloseClick,
                },
            },
        }),
        [
            activeChat,
            onChatCloseClick,
            onIconClick,
            status.isOnline,
            unitId,
            user.contactId,
            user.email,
            user.name,
        ]
    )

    // React query will automatically refetch the web chat status every 10 minutes but to make sure
    // the status changes on the hour if it closes/opens we need to force a refetch on the hour
    useEffect(() => {
        let interval: ReturnType<typeof setInterval>
        setTimeout(() => {
            queryClient.invalidateQueries(["web-chat-status", user.contactId])
            interval = setInterval(() => {
                queryClient.invalidateQueries([
                    "web-chat-status",
                    user.contactId,
                ])
            }, 1000 * 60 * 60) // Force refetch of web chat status on the hour every hour
        }, 3600000 - (new Date().getTime() % 3600000)) // Start the interval at the next hour

        return () => clearInterval(interval)
    }, [queryClient, user.contactId])

    useEffect(() => {
        if (managerState) {
            managerState.updateConfig(config)
        }
    }, [config, managerState])

    useEffect(() => {
        const online = status.isOnline || activeChat
        MessageList.WelcomeMessage.Content.replace(
            <WelcomeMessage
                key="content"
                online={online}
                openHours={status.openHours}
            />,
            {
                sortOrder: 0,
            }
        )

        if (!online) {
            TwilioMessageBubble.Content.remove(
                `MessageBubble-${countRef.current}`
            )
            countRef.current++
        }
    }, [status, activeChat])

    useEffect(() => {
        if (previous?.isOnline === false && status.isOnline) {
            TwilioMessageBubble.Content.replace(
                <MessageBubble key={`MessageBubble-${countRef.current}`} />,
                { sortOrder: 0 }
            )
            Actions.invokeAction("RestartEngagement")
        }
    }, [previous?.isOnline, status.isOnline])

    useEffect(() => {
        if (!TWILIO_ACCOUNT_ID || !TWILIO_FLOW_SID) return

        Manager.create(config)
            .then(manager => {
                // Remove default Twilio Components
                TwilioMessageBubble.Content.remove("body")
                TwilioMessageBubble.Content.remove("header")
                MessageList.WelcomeMessage.Content.remove("icon")
                MessageList.WelcomeMessage.Content.remove("text")
                TwilioMessageBubble.Content.remove("MessageBubble")

                TwilioMessageBubble.Content.add(
                    <MessageBubble key={`MessageBubble-0`} />,
                    { sortOrder: 0 }
                )

                manager.strings.PredefinedChatMessageAuthorName =
                    intl.formatMessage({
                        id: "Support.webChat.predefinedChatMessageAuthorName",
                        defaultMessage: "Vacasa Owner Service",
                    })

                manager.strings.MessageCanvasTrayContent = intl.formatMessage({
                    id: "Support.webChat.messageCanvasTrayContent",
                    defaultMessage: "Thanks for chatting with us!",
                })

                manager.strings.TypingIndicator = intl.formatMessage({
                    id: "Support.webChat.typingIndicator",
                    defaultMessage: "Typing...",
                })

                manager.strings.MessageCanvasTrayButton = intl.formatMessage({
                    id: "Support.webChat.messageCanvasTrayButton",
                    defaultMessage: "Start New Chat",
                })

                setManagerState(manager)

                // Track when webchat is loaded and is visible
                if (status.isEnabled) {
                    trackWebchatLoaded()
                }

                manager.store.subscribe(() => {
                    const state = manager.store.getState()

                    const activeChannel =
                        state.flex.chat.messageList.activeChatChannel
                    const channel = state.flex.chat.channels[activeChannel]
                    const isCurrentChat =
                        !!state.flex.chat.channels[activeChannel]?.messages
                            .length

                    const isLoadingChat =
                        !!channel?.isLoadingChannel ||
                        !!channel?.isLoadingMessages ||
                        !!channel?.isLoadingMembers ||
                        !channel

                    setLoading(isLoadingChat)
                    setActiveChat(isCurrentChat)
                })
            })
            .catch(error => {
                LoggingService.error({
                    message: "Failed to initialise Twilio Flex Chat",
                    error,
                })
            })

        // We only want to run the initialisation the first time only
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (isUsingOwnerApp) return null

    return status.isEnabled && managerState ? (
        <>
            <div
                className={classNames(styles.container, {
                    [getClassStyle(styles.containerLoading)]:
                        loading || (!status.isOnline && !activeChat),
                })}
            >
                <ContextProvider manager={managerState}>
                    <RootContainer />
                </ContextProvider>
            </div>
        </>
    ) : null
}
