export function calculatePercentages(values: number[], total: number) {
    let remainingPercentage = 100
    const calculatePercentage = (count: number) => {
        if (count === 0) {
            return count
        }

        const percentage = Math.round((count / total) * 100)

        // Ensure that values greater than zero display at least 1%
        if (percentage === 0) {
            --remainingPercentage
            return 1
        }

        const newRemainder = remainingPercentage - percentage
        if (newRemainder < 0) {
            return remainingPercentage
        }

        remainingPercentage = newRemainder
        return percentage
    }

    return values
        .map((count, index) => ({ index, count }))
        .sort((a, b) => a.count - b.count)
        .map(({ index, count }) => ({
            index,
            percentage: calculatePercentage(count),
        }))
        .sort((a, b) => a.index - b.index)
        .map(({ percentage }) => percentage)
}
