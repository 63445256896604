import { FC, PropsWithChildren, useMemo } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { BaseWidget, TitleWidget } from "./BaseWidget"
import styles from "./YourProgramsWidget.module.scss"
import { ChevronRight, IconTowels, Info, Shield } from "assets"
import { Pill } from "components/Pill/Pill"
import classNames from "classnames"
import { hasEnrolledProgram } from "utils/programs/programsUtil"
import { Link } from "react-router-dom"
import { useUnitPrograms } from "hooks/unit-programs"
import { Tooltip } from "lib/components/Tooltip/Tooltip"
import { RENTAL_INSURANCE_GW_URL, RENTAL_INSURANCE_URL } from "Constants"
import { trackRentalInsuranceLearnMoreClicked } from "services/segment/homeInfo/homeInfoTracking"
import {
    FullServiceEnrolledDescription,
    FullServiceNotEnrolledDescription,
} from "../UnitDetails/RentalInsurance/RentalInsurance"
import { GeneralError } from "lib/components/GeneralError/GeneralError"
import { useCurrentUnit, useUnitStatuses } from "hooks/units"
type YourProgramsWidgetProps = {
    unitId: string
}

export const YourProgramsWidget: FC<YourProgramsWidgetProps> = ({ unitId }) => {
    return (
        <div style={{ gridColumn: "1 / 3" }}>
            <TitleWidget>
                <FormattedMessage
                    id="HomeInfo.programsTitle"
                    defaultMessage="Your Programs"
                />
            </TitleWidget>
            <BaseWidget>
                <div className={styles.container}>
                    <LinenServiceProgram unitId={unitId} />
                    <AccommodationProtectionProgram unitId={unitId} />
                </div>
            </BaseWidget>
        </div>
    )
}

const Program: FC<
    PropsWithChildren<{ className?: string; loading?: boolean }>
> & {
    Actions: FC<ProgramActionsProps>
    Details: FC<ProgramDetailsProps>
    Icon: FC<ProgramIconProps>
} = ({ children, className, loading }) => {
    return (
        <div
            className={classNames(styles.programCard, className, {
                loading,
            })}
        >
            {children}
        </div>
    )
}

type ProgramIconProps = {
    icon: "insurance" | "linen"
}
const ProgramIcon: FC<ProgramIconProps> = ({ icon }) => {
    const iconComponent = useMemo(() => {
        switch (icon) {
            case "insurance":
                return <Shield />
            case "linen":
                return <IconTowels />
        }
    }, [icon])

    return <div className={styles.icon}>{iconComponent}</div>
}
Program.Icon = ProgramIcon

type ProgramDetailsProps = {
    name: string
    enrolled?: boolean
    error?: boolean
    retry?: () => void
}
const ProgramDetails: FC<ProgramDetailsProps> = ({
    enrolled,
    error,
    name,
    retry,
}) => {
    const intl = useIntl()
    return (
        <div className={styles.details}>
            {error && (
                <GeneralError
                    errorHeading={name}
                    customContainerStyle={styles.error}
                    handleClick={retry}
                    errorMessage={intl.formatMessage(
                        {
                            id: "HomeInfo.widgets.program.loadingError",
                            defaultMessage:
                                "We were unable to load your {program} status.",
                        },
                        {
                            program: name,
                        }
                    )}
                />
            )}
            {!error && (
                <>
                    <div className={styles.name}>{name}</div>
                    <div className={styles.status}>
                        <Pill
                            text={enrolled ? "Enrolled" : "Not Enrolled"}
                            enabledStyle={enrolled ?? false}
                        />
                    </div>
                </>
            )}
        </div>
    )
}
Program.Details = ProgramDetails

type ProgramActionsProps = PropsWithChildren<{ show?: boolean }>
const ProgramActions: FC<ProgramActionsProps> = ({ children, show = true }) => {
    if (!show) return null
    return <div className={styles.actions}>{children}</div>
}
Program.Actions = ProgramActions

/**
 * Linen Service Program Status Row
 */
export const LinenServiceProgram: FC<{ unitId: string }> = ({ unitId }) => {
    const linenPlansQuery = useUnitPrograms(unitId, "linen")
    const intl = useIntl()
    const { unit } = useCurrentUnit()

    if (!unit || unit.attributes.guestworksSupport) return null
    return (
        <Program loading={linenPlansQuery.isLoading}>
            <Program.Icon icon="linen" />
            <Program.Details
                name={
                    linenPlansQuery.isLoading
                        ? ""
                        : intl.formatMessage({
                              id: "HomeInfo.linenAndTerry.linenService",
                              defaultMessage: "Linen Service",
                          })
                }
                enrolled={hasEnrolledProgram(linenPlansQuery.data?.data)}
                error={linenPlansQuery.isError}
                retry={linenPlansQuery.refetch}
            />
            {!linenPlansQuery.isError && (
                <Program.Actions show={!linenPlansQuery.isError}>
                    <Link
                        to={{
                            pathname: `/home-info/linen-service/${unitId}`,
                        }}
                    >
                        <ChevronRight width={24} height={24} />
                    </Link>
                </Program.Actions>
            )}
        </Program>
    )
}

/**
 * Accommodation Protection Program Status Row
 */
export const AccommodationProtectionProgram: FC<{ unitId: string }> = ({
    unitId,
}) => {
    const insuranceProgramsQuery = useUnitPrograms(unitId, "insurance")
    const intl = useIntl()
    const { unit } = useCurrentUnit()
    const { isGuestworks } = useUnitStatuses()
    if (!unit) return null

    const isAssuranceProgramEnrolled = hasEnrolledProgram(
        insuranceProgramsQuery.data?.data
    )

    return (
        <Program loading={insuranceProgramsQuery.isLoading}>
            <Program.Icon icon="insurance" />
            <Program.Details
                name={
                    insuranceProgramsQuery.isLoading
                        ? ""
                        : intl.formatMessage({
                              id: "HomeInfo.protectionProgram.title",
                              defaultMessage:
                                  "Accommodations\nProtection Program",
                          })
                }
                enrolled={isAssuranceProgramEnrolled}
                error={insuranceProgramsQuery.isError}
                retry={insuranceProgramsQuery.refetch}
            />
            <Program.Actions show={!insuranceProgramsQuery.isError}>
                <Tooltip
                    title={intl.formatMessage({
                        id: "HomeInfo.protectionProgram.title",
                        defaultMessage: "Accommodations\nProtection Program",
                    })}
                    body={
                        <>
                            {isAssuranceProgramEnrolled ? (
                                <FullServiceEnrolledDescription
                                    isGuestworksUnit={isGuestworks}
                                />
                            ) : (
                                <FullServiceNotEnrolledDescription
                                    isGuestworksUnit={isGuestworks}
                                />
                            )}
                            <br />
                            <br />
                            <a
                                href={
                                    isGuestworks
                                        ? RENTAL_INSURANCE_GW_URL
                                        : RENTAL_INSURANCE_URL
                                }
                                target="_blank"
                                rel="noreferrer"
                                className={styles.learnMoreLink}
                                onClick={trackRentalInsuranceLearnMoreClicked}
                            >
                                <FormattedMessage
                                    id="HomeInfo.protectionProgram.learnMore"
                                    defaultMessage="Learn more"
                                    description="Link to external Vacasa documentation about the rental insurance program"
                                />
                            </a>
                        </>
                    }
                    segmentTrackParams={{
                        unitId,
                    }}
                >
                    <Info
                        className={classNames(
                            styles.tooltipToggle,
                            "cursor-pointer"
                        )}
                        width={16}
                        height={16}
                    />
                </Tooltip>
            </Program.Actions>
        </Program>
    )
}
