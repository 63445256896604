import { upperCase } from "lodash"
import { FC } from "react"
import { PreferenceToggle } from "./PreferenceToggle"
import styles from "./PreferenceGroup.module.scss"
import { Preference } from "utils/notification-center/notificationCenterUtil"

interface PreferenceGroupProp {
    groupName: string
    preferences: Preference[]
}

export const PreferenceGroup: FC<PreferenceGroupProp> = ({
    groupName,
    preferences,
}): JSX.Element => {
    // Only display email for now
    // SMS data aren't returned from owner API,
    // unless "user-preference-sms" feature flag is turned on
    const validChannels = preferences[0]?.channels.filter(
        channel => channel.name === "email" || channel.name === "sms"
    )
    const size = validChannels?.length ? validChannels?.length : 0
    const gridTemplateColumns = `minmax(200px, 490px) repeat(${size},45px)`

    return (
        <div className={styles.container}>
            <div
                className={styles.groupRow}
                style={{
                    gridTemplateColumns,
                }}
            >
                <div className={styles.groupName}>{groupName}</div>

                {validChannels?.map(channel => {
                    return (
                        <div key={channel.name} className={styles.channelName}>
                            {upperCase(channel.name)}
                        </div>
                    )
                })}
            </div>
            <div>
                {preferences.map(preference => {
                    return (
                        <div
                            key={preference.id}
                            className={styles.groupRow}
                            style={{
                                gridTemplateColumns,
                            }}
                        >
                            <div className={styles.preferenceName}>
                                {preference.title}
                            </div>
                            {preference.channels.map(channel => {
                                return (
                                    (channel.name === "sms" ||
                                        channel.name === "email") && (
                                        <div
                                            key={channel.name}
                                            className={styles.preferenceToggle}
                                        >
                                            <PreferenceToggle
                                                defaultValue={channel.value}
                                                readOnly={channel.readOnly}
                                                preferenceId={preference.id}
                                                preferenceKey={channel.name}
                                                preferenceTitle={
                                                    preference.title
                                                }
                                            />
                                        </div>
                                    )
                                )
                            })}
                            <div className={styles.preferenceDescription}>
                                {preference.description}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
