import * as React from "react"
import { FormattedMessage, FormattedNumber } from "react-intl"

interface MinRateInformationProps {
    minRate: number
    impactRevenue: number
    currency: string
}

export const MinRateMessage = (props: MinRateInformationProps): JSX.Element => (
    <div data-testid="min-rate-message">
        <FormattedMessage
            id="Performance.minRateInfo"
            defaultMessage="<strong>Your minimum rate of {minRent} could be decreasing your revenue by as much as {impactRevenue}.</strong>
                When minimum rates are too high guests are less likely to book, which limits your potential revenue."
            values={{
                minRent: (
                    <FormattedNumber
                        value={props.minRate}
                        currency={props.currency}
                        style="currency" // eslint-disable-line
                    />
                ),
                impactRevenue: (
                    <FormattedNumber
                        value={props.impactRevenue}
                        currency={props.currency}
                        style="currency" // eslint-disable-line
                    />
                ),
                strong: (chunks: React.ReactNode) => <strong>{chunks}</strong>,
            }}
        />
    </div>
)
