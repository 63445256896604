import { Ref, forwardRef } from "react"
import { W8Steps, FormRef, W9Steps } from "../types"
import { ThemeProvider, createTheme } from "@material-ui/core"
import { AgreeAndSignForm } from "./AgreeAndSignForm"
import { MailingAddressForm } from "./MailingAddressForm"
import { TaxClassificationForm } from "./TaxClassificationForm"
import { TaxInfoForm } from "./TaxInfoForm"

type Props = {
    step: W9Steps | W8Steps | undefined
}

export const W9 = forwardRef((props: Props, ref: Ref<FormRef>) => {
    const { step } = props
    return (
        <ThemeProvider
            theme={createTheme({
                overrides: {
                    MuiInputBase: {
                        root: {
                            width: "100%",
                            border: "1px solid var(--midnight-10)",
                            borderRadius: "8px !important",
                            "&.Mui-error": {
                                borderColor: "var(--sunset)",
                            },
                        },
                        input: {
                            padding: "0 16px",
                            lineHeight: "48px",
                        },
                    },
                    MuiSelect: {
                        root: {
                            width: "100%",
                        },
                        select: {
                            "&:focus": {
                                backgroundColor: "transparent",
                            },
                        },
                        selectMenu: {
                            height: 48,
                        },
                        icon: {
                            right: 10,
                            color: "var(--dusk)",
                        },
                    },
                },
            })}
        >
            <div>
                {step === W9Steps.TaxClassification && (
                    <TaxClassificationForm ref={ref} />
                )}
                {step === W9Steps.TaxInfo && <TaxInfoForm ref={ref} />}
                {step === W9Steps.MailingAddress && (
                    <MailingAddressForm ref={ref} />
                )}
                {step === W9Steps.AgreeAndSign && (
                    <AgreeAndSignForm ref={ref} />
                )}
            </div>
        </ThemeProvider>
    )
})
