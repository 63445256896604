import {
    HousekeepingRequired,
    JSONAPIResource,
    OwnerHoldType,
} from "@vacasa/owner-api-models"
import { AxiosError } from "axios"
import { UseQueryResult, useQuery } from "react-query"
import { UnitStatusType } from "utils/unit"
import { fetchHousekeepingRequired } from "./fetchHousekeepingRequired"
import { useCurrentUnit } from "hooks/units"

export const useHousekeepingRequired = (params: {
    unitStatus: UnitStatusType | undefined
    holdType: OwnerHoldType | null
    state: string
    acquisitionID: number | null | undefined
    isGuestworksUnit: boolean
}): UseQueryResult<JSONAPIResource<HousekeepingRequired>, AxiosError> => {
    const { unitId } = useCurrentUnit()

    return useQuery(
        ["housekeeping-required", params],
        ({ signal }) =>
            fetchHousekeepingRequired({
                params: {
                    ...params,
                    unitId: unitId ?? undefined,
                    holdType: params.holdType as OwnerHoldType, // When fetched, this will also be set as the enabled flag should prevent it from firing
                },
                signal,
            }),
        {
            enabled: !!params.holdType,
        }
    )
}
