const survey = {
    title:"RATES & REVENUE",
    subTitle:"Set revenue strategy",
    surveyFollowUp: "Step {step} of {totalSteps}",
    errorTitle: "Survey Not Available",
    loadingErrorMessage: "Sorry, the survey could not be loaded. Please try again later.",
    submitErrorMessage: "Sorry, we could not submit your survey at this time. Please try again later.",
    tanksTitle: "Congrats on setting your revenue strategy!",
    tanksMessage: "Our team is working to implement your requests."
}

export default survey