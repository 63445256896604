import {
    useMutation,
    UseMutationResult,
    useQueries,
    useQuery,
    useQueryClient,
    UseQueryOptions,
    UseQueryResult,
} from "react-query"
import type { AxiosError } from "axios"
import { Owner } from "@vacasa/owner-api-models"
import { fetchContact } from "./fetchContact"
import { patchContact, PatchContactRequest } from "./patchContact"

const getContactQuerykey = (contactId?: string) => ["contact", contactId]

const useInvalidateContact = () => {
    const queryClient = useQueryClient()
    return (contactId?: string) =>
        queryClient.invalidateQueries(getContactQuerykey(contactId))
}

const useContact = (contactId?: string): UseQueryResult<Owner, AxiosError> =>
    useQuery(
        getContactQuerykey(contactId),
        ({ signal }) =>
            fetchContact({
                contactId: contactId as string, // confirmed to be string by enabled
                signal,
            }).then(response => response.data.attributes),
        {
            enabled: contactId !== undefined,
            cacheTime: 1000 * 60 * 45, // 45 mins
            refetchInterval: 1000 * 60 * 30, // 30 mins
            staleTime: 1000 * 60 * 30, // 30 mins
        }
    )

const useContacts = (contactIds: string[]) => {
    return useQueries(
        contactIds.map<UseQueryOptions<Owner, AxiosError>>(contactId => {
            return {
                queryKey: getContactQuerykey(contactId),
                queryFn: () =>
                    fetchContact({
                        contactId,
                    }).then(response => response.data.attributes),
                cacheTime: 1000 * 60 * 45, // 45 mins
                refetchInterval: 1000 * 60 * 30, // 30 mins
                staleTime: 1000 * 60 * 30, // 30 mins
            }
        })
    )
}

const useContactMutation = (): UseMutationResult<
    void,
    AxiosError,
    PatchContactRequest,
    unknown
> => {
    const queryClient = useQueryClient()
    return useMutation<void, AxiosError, PatchContactRequest, unknown>(
        owner => patchContact(owner),
        {
            onSuccess: (_data, variables) => {
                queryClient.invalidateQueries(
                    getContactQuerykey(variables.contactId)
                )
            },
        }
    )
}

export { useInvalidateContact, useContact, useContacts, useContactMutation }
