import { ReservationGuest } from "@vacasa/owner-api-models"
import { AxiosError } from "axios"
import { useMutation, UseMutationResult, useQueryClient } from "react-query"
import { trackOwnerHoldGuestRemoved } from "services/segment/ownerHold/ownerHoldTracking"
import { RESERVATION_GUESTS_QUERY_KEY } from "./constants"
import { deleteReservationGuests } from "./deleteReservationGuests"

export const useRemoveReservationGuest = (): UseMutationResult<
    void,
    AxiosError,
    {
        reservationId: string
        guestId: string
        guest: Omit<ReservationGuest, "guestID" | "reservationID">
        signal?: AbortSignal
    }
> => {
    const queryClient = useQueryClient()
    return useMutation(
        data =>
            deleteReservationGuests({
                reservationId: data.reservationId,
                guestId: data.guestId,
            }),
        {
            onSuccess: (_, { reservationId, guest }) => {
                trackOwnerHoldGuestRemoved(
                    String(reservationId),
                    guest.firstName,
                    guest.lastName,
                    guest.email
                )
                return queryClient.refetchQueries([
                    RESERVATION_GUESTS_QUERY_KEY,
                    reservationId,
                ])
            },
        }
    )
}
