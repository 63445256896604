import { isEmpty } from "lodash"
import { ReactElement } from "react"

import SVGIcon from "lib/components/SVGIcon"
import { ReactComponent as IllustrationsSVG } from "../../assets/support-hub-Illustration.svg"
import * as Button from "lib/components/Buttons/BaseButton"

import classNames from "classnames"
import { TicketDetailsFlyout } from "utils/flyout/maintenanceFlyoutUtil"
import { TicketResponse } from "@vacasa/owner-api-models"
import { FormattedMessage, useIntl } from "react-intl"
import { TicketList } from "./TicketList"
import { TicketTypes } from "./Maintenance"

export type MaintenanceTicketsProps = {
    fetchError: boolean
    isTicketSelected: boolean
    triggerDetailsBox: (ticket: TicketResponse) => void
    closeDetailsBox: () => void
    ticket: TicketResponse | null
    isOpenViewSelected: boolean
    fetchTicketData: () => void
    showTicketSubmitted: boolean
    tickets: TicketTypes
    handleCommentSubmitted: () => void
}

type EmptyErrorStateProps = {
    fetchTicketData: () => void
}

type EmptyStateProps = {
    isOpenViewSelected: boolean
}

export const MaintenanceTickets = (
    props: MaintenanceTicketsProps
): ReactElement => {
    const {
        isOpenViewSelected,
        fetchError,
        isTicketSelected,
        triggerDetailsBox,
        closeDetailsBox,
        fetchTicketData,
        ticket,
        showTicketSubmitted,
        tickets,
        handleCommentSubmitted,
    } = props

    const ticketDetailsFlyoutProps = {
        closeHandler: closeDetailsBox,
        ticket,
        showTicketSubmitted,
        handleCommentSubmitted,
    }

    const selectedTickets = isOpenViewSelected
        ? tickets.filteredOpenTickets
        : tickets.filteredClosedTickets

    if (fetchError) {
        return <EmptyErrorState fetchTicketData={fetchTicketData} />
    }

    if (isEmpty(selectedTickets)) {
        return (
            <div className="flex maintenance-container">
                <EmptyState isOpenViewSelected={isOpenViewSelected} />
                {isTicketSelected && (
                    <TicketDetailsFlyout {...ticketDetailsFlyoutProps} />
                )}
            </div>
        )
    }

    return (
        <div className="flex maintenance-container">
            <div
                className={classNames(
                    `maintenance-tickets`,
                    `align-left`,
                    `list-box`,
                    "wide-list"
                )}
            >
                <TicketList
                    tickets={selectedTickets}
                    triggerDetailsBox={triggerDetailsBox}
                />
            </div>
            {isTicketSelected && (
                <TicketDetailsFlyout {...ticketDetailsFlyoutProps} />
            )}
        </div>
    )
}

const EmptyErrorState = ({ fetchTicketData }: EmptyErrorStateProps) => {
    const intl = useIntl()
    return (
        <div className="panel-empty empty-state empty-state-open--tickets">
            <SVGIcon
                svg="icon-feather-alert-triangle"
                className="w-56 h-56 empty-state__svg  error-alert"
                colorName="info-30"
            />
            <div className="empty-state-container ">
                <p className="type-heading-medium">
                    <FormattedMessage
                        id="Maintenance.failedFetchError"
                        defaultMessage="We had trouble loading your maintenance requests. If the problem persists, please try again later."
                    />
                </p>
            </div>
            <div className="button empty-state button-box">
                <Button.Utility
                    onClick={() => fetchTicketData()}
                    ariaLabel={intl.formatMessage({
                        id: "Maintenance.reload",
                        defaultMessage: "Reload",
                    })}
                >
                    <p
                        style={{ color: "rgb(0, 51, 73)" }}
                        className="zero-margin zero-padding button empty-state text"
                    >
                        <FormattedMessage
                            id="Maintenance.reload"
                            defaultMessage="Reload"
                        />
                    </p>
                </Button.Utility>
            </div>
        </div>
    )
}

const EmptyState = ({ isOpenViewSelected }: EmptyStateProps) =>
    isOpenViewSelected ? (
        <div className="panel-empty empty-state empty-state-open--tickets open">
            <div className="empty-state-container">
                <IllustrationsSVG />
                <div className="title">
                    <FormattedMessage
                        id="Maintenance.noOpenRequests"
                        defaultMessage="No Open Requests"
                    />
                </div>
                <div className="description">
                    <FormattedMessage
                        id="Maintenance.newRequestWill"
                        defaultMessage="New requests will appear here until they are addressed."
                    />
                </div>
            </div>
        </div>
    ) : (
        <div className="panel-empty empty-state empty-state-closed--tickets closed">
            <div className="empty-state-container">
                <IllustrationsSVG />
                <div className="title">
                    <FormattedMessage
                        id="Maintenance.noClosedRequests"
                        defaultMessage="No Closed Requests"
                    />
                </div>
                <div className="description">
                    <FormattedMessage
                        id="Maintenance.closedRequestsWill"
                        defaultMessage="Once an open request is addressed, it will be closed out and will appear here."
                    />
                </div>
            </div>
        </div>
    )
