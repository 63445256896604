const settings = {
    title: "Payment Settings",
    reserveBalance: "Solde de réserve",
    paymentSettings: "Paramètres de paiement",
    directDepositTitle: "Bank Account",
    checksByMailTitle: "Paper Checks",
    checksByMailEnrolled: "You are enrolled in paper checks.",
    checksByMailNotEnrolled: "You are not enrolled in paper checks.",
    paymentMethod: "Payment Method",
    paymentMethodText:
        "Payment delivery depends on the owner's selected payment method.",
    directDepositFineprint:
        "Deposits and withdrawals usually appear in the account 2-5 business days after the monthly statement.",
    checksByMailFineprint:
        "Paper checks usually take 5-10 business days to arrive by mail. Please confirm your mailing address in your {ProfileLink}.",
    reserveBalanceEmptyState:
        "Vous pouvez consulter et mettre à jour votre solde de réserve ici.",
    reserveBalanceText:
        "If your expenses exceed your revenue in a given month, your Reserve Account will be used to cover the difference. If your reserve account balance is below {balance}, we'll automatically replenish it from revenue you earn.",
    reserveUpdateAria: "Cliquez ici pour mettre à jour votre solde de réserve.",
    reserveUpdatedNotification: "Solde de réserve mis à jour",
    reserveMinimumRequired: "This unit requires at least {amount} reserve",
    directDepositEnrolled: "You are enrolled in direct deposit.",
    achDirectDepositEnrolled:
        "You are enrolled in ACH direct deposit and ACH expense debit.",
    directDepositNotEnrolled:
        "Vous n'avez pas souscrit au virement automatique.",
    checkingAccount: "Compte courant",
    savingsAccount: "Compte épargne",
    updatePayment: "Mettre à jour le mode de paiement",
    updateAction: "Mettre à jour",
    updatedNotification: "Informations de paiement mises à jour",
    updatePaymentSub: "Compte bancaire pour le virement automatique",
    receiveByMailSub: "Enroll in Checks",
    receiveByMailText:
        "If selected, your checks will be mailed to your address on file.",
    directDepositRemoved: "Supprimé du virement automatique",

    addBankAccount: "Ajouter compte bancaire",
    checking: "Compte courant",
    savings: "Compte épargne",
    routingNumber: "Numéro d'acheminement",
    nineDigitNumber:
        "Numéro à neuf chiffres situé dans le coin à gauche et en bas du chèque",
    mustBeginWith: "Ce numéro d'acheminement commence par 0, 1, 2 ou 3",
    mustBe9Digits: "Veuillez saisir le numéro d'acheminement à 9 chiffres",
    mustBeValid9Digits: "Please enter a valid 9-digit routing number",
    accountNumber: "Numéro de compte",
    accountHolderName: "Nom du titulaire du compte",
    enterBankAccount: "Please enter your bank account number",
    mustBe5to17Digits: "Please enter a 5 to 17-digit account number",
    byClickingYouAuthorize:
        "Because you chose to pay for expenses via ACH Debit, this account will also be charged if your expenses exceed your rental revenue for the month. By clicking 'Agree and Save', you authorize {accountingEntity} to make payments electronically via direct deposit and to debit the identified account for up to $10,000 per transaction per day. This authority will remain in effect until you change or update your payment method.",
    cancel: "supprimer",
    agreeAndSave: "Accepter et enregistrer",
    savePaymentMethod: "Enregistrer le mode de paiement",
    clickToSaveAria: "Cliquez pour enregistrer votre mode de paiement.",
    clickToCancelAria: "Cliquez pour supprimer.",
    clickToRequestChecksAria:
        "Cliquez pour demander l'envoi des chèques par voie postale.",
    requestChecksByMail: "Demandez les chèques par voie postale à la place",
    getYourPaymentsFaster:
        "Recevez vos paiements plus vite en souscrivant au virement automatique.",
    errorMessages: {
        updateReserveBalanceError: "Le solde de réserve est trop élevé.",
        updateReserveBalanceNegativeError:
            "Le solde de réserve ne peut pas être négatif.",
        updateReserveBalanceMissingError:
            "Le montant du solde de réserve est requis.",
        sendDirectDepositEmailError:
            "L'e-mail de désinscription du virement automatique n'a pas pu être envoyé.",
        paymentAccountGeneralError:
            "Une anomalie s'est produite lors de la mise à jour de vos informations de compte. Veuillez réessayer plus tard.",
        paymentAccountNameError:
            "Le nom du titulaire du compte doit être défini.",
        paymentAccountNameLengthError:
            "Le nom du titulaire du compte dépasse 100 caractères.",
        paymentAccountRoutingNumError:
            "Le numéro d'acheminement n'est pas valide.",
        paymentAccountNumError: "Le numéro de compte n'est pas valide.",
        paymentAccountTypeError: "Le type de compte n'est pas valide.",
        sendACHUpdateEmailError:
            "L'e-mail d'actualisation ACH n'a pas pu être envoyé.",
        somethingWentWrong: "Une anomalie s'est produite. Veuillez réessayer.",
    },
    emptyState:
        "This is where you can update your reserve balance and payment method. Be sure to set up direct deposit before your first statement posts in order to get your payments faster.",
    linkToStatements: "Statements",
}

export default settings
