import classnames from "classnames"
import { Fragment } from "react"
import { Edit } from "react-feather"
import styles from "./InviteGuestContent.module.scss"

interface InviteGuestContentProps {
    firstName: string | null
    lastName: string | null
    email: string | null
    onEditClick?: () => void
}

const InviteGuestContent: React.FC<InviteGuestContentProps> = ({
    firstName,
    lastName,
    email,
    onEditClick,
}: InviteGuestContentProps): JSX.Element => {
    return (
        <Fragment>
            <div className={styles["guestItems"]}>
                <div className={styles["guestItemDetails"]}>
                    <span
                        className={classnames(
                            styles["guestName"],
                            "text-sm",
                            "font-semibold",
                            "text-dusk"
                        )}
                    >
                        {firstName} {lastName}
                    </span>
                    <span
                        className={classnames(
                            styles["guestEmail"],
                            "font-bold",
                            "text-xs"
                        )}
                    >
                        {email}
                    </span>
                </div>
                {onEditClick && (
                    <button
                        className={classnames(
                            "flex",
                            "justify-center",
                            "items-center",
                            "cursor-pointer",
                            styles["guestEditBtn"]
                        )}
                        onClick={onEditClick}
                    >
                        <Edit />
                    </button>
                )}
            </div>
        </Fragment>
    )
}

export default InviteGuestContent
