import { useCallback } from "react"
import { Book } from "react-feather"
import { FormattedMessage } from "react-intl"
import {
    OwnerLibraryLocation,
    SegmentLocation,
} from "services/segment/constants"
import { trackOwnerLibraryLinkClicked } from "services/segment/owner-library/ownerLibraryTracking"
import { GUESTWORKS_URL, OWNER_LIBRARY_URL } from "../../../../Constants"
import loggingService from "../../../../services/logging/logging.service"
import { ListImage, ListItem, ListTitle } from "../List"
import { Stack } from "../Stack/Stack"
import styles from "./OwnerResourceLibrary.module.scss"
import { trackGuestworksLinkClicked } from "services/segment/guestworks/guestworksTracking"

type OwnerResourceLibraryProps = {
    unitId: string
    isGuestworks: boolean
}

const OwnerResourceLibrary = ({
    unitId: id,
    isGuestworks,
}: OwnerResourceLibraryProps): JSX.Element => {
    const handleOwnerLibraryClicked = useCallback(() => {
        loggingService.log({
            message: `View Owner library for unit ${id}`,
        })
        trackOwnerLibraryLinkClicked(
            SegmentLocation.HomeInfo,
            OwnerLibraryLocation.Default
        )
    }, [id])
    const handleHomeownerGuideClicked = useCallback(() => {
        loggingService.log({
            message: `View Homeowner Guide for unit ${id}`,
        })
        trackGuestworksLinkClicked(SegmentLocation.HomeInfo, id)
    }, [id])

    return (
        <ListItem>
            <ListImage
                aria-hidden
                fallbackIcon={<Book size={20} />}
                className={styles.icon}
            />
            <Stack flex={1} className={styles.stack}>
                <ListTitle className={styles.libraryTitle}>
                    {isGuestworks ? (
                        <FormattedMessage
                            id="HomeInfo.ownerGuide.guestworksGuideTitle"
                            defaultMessage="Homeowner Guide"
                        />
                    ) : (
                        <FormattedMessage
                            id="HomeInfo.ownerGuide.ownerGuideTitle"
                            defaultMessage="Owner Resource Library"
                        />
                    )}
                </ListTitle>
                <a
                    href={isGuestworks ? GUESTWORKS_URL : OWNER_LIBRARY_URL}
                    target="_blank"
                    rel="noreferrer"
                    onClick={
                        isGuestworks
                            ? handleOwnerLibraryClicked
                            : handleHomeownerGuideClicked
                    }
                    className={styles.libraryLink}
                >
                    {isGuestworks ? (
                        <FormattedMessage
                            id="HomeInfo.ownerGuide.guestworksViewLink"
                            defaultMessage="View Homeowner Guide"
                        />
                    ) : (
                        <FormattedMessage
                            id="HomeInfo.ownerGuide.viewLink"
                            defaultMessage="View Owner Library"
                        />
                    )}
                </a>
            </Stack>
        </ListItem>
    )
}
export { OwnerResourceLibrary }
