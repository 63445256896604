import { TextField as MaterialUITextField } from "@material-ui/core"
import classnames from "classnames"
import React from "react"

import styles from "./TextField.module.scss"

export interface TextFieldProps {
    value?: string
    placeholder?: string
    onChange: (
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => void
    required?: boolean
    multiline?: boolean
    maxLength?: number
    id?: string
}

export const TextField: React.FC<TextFieldProps> = ({
    value,
    placeholder,
    onChange,
    required,
    multiline,
    maxLength,
    id,
}) => {
    const showError = maxLength && value && value.length > maxLength - 1

    return (
        <MaterialUITextField
            id={id}
            className={styles.root}
            InputProps={{
                disableUnderline: true,
                classes: {
                    root: styles.input_root,
                    focused: styles.selected,
                },
            }}
            inputProps={{
                maxLength,
            }}
            value={value}
            placeholder={placeholder}
            helperText={maxLength ? `${value?.length ?? 0}/${maxLength}` : ""}
            FormHelperTextProps={{
                classes: {
                    root: classnames(
                        styles.helperText,
                        showError ? styles.error_state : ""
                    ),
                },
            }}
            onChange={onChange}
            required={required}
            multiline={multiline}
            data-testid="mui-text-field"
        />
    )
}
