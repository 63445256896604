import React from "react"
import { Row, Col } from "reactstrap"

import styles from "../../../../../sass/onboarding.module.scss"
import Dropdown from "../Dropdown"
import { Generic as DropdownOption } from "../../../../../models/Generic"
import { Tooltip } from "../../../Tooltip/Tooltip"
import SVGIcon from "../../../SVGIcon"
import { FormattedMessage } from "react-intl"
import { getClassStyle } from "utils/styles/styleWrapper"

interface DropdownRowProps {
    title: React.ReactNode
    description?: string | JSX.Element
    tooltipBody?: React.ReactNode
    tooltipTitle?: string
    selectedOption?: DropdownOption | null | undefined
    options: DropdownOption[]
    onChangeHandler: (value: DropdownOption | null | undefined) => void
}

const DropdownRow: React.FunctionComponent<DropdownRowProps> = ({
    title,
    description,
    tooltipBody,
    tooltipTitle,
    selectedOption,
    options,
    onChangeHandler,
}: DropdownRowProps): JSX.Element => {
    return (
        <Row>
            <Col>
                <div className={styles.outer_row}>
                    <div className={styles.outer_column}>
                        <div>
                            <p className={styles.type_body_inline}>{title}</p>
                            {tooltipBody && tooltipTitle && (
                                <span className={styles.tooltip_wrapper}>
                                    <Tooltip
                                        body={tooltipBody}
                                        title={tooltipTitle}
                                    >
                                        <SVGIcon
                                            svg="icon-feather-info"
                                            colorName="gray-lightest"
                                            className="w-16 h-16 cursor-pointer"
                                            style={{ margin: "auto" }}
                                        />
                                    </Tooltip>
                                </span>
                            )}
                        </div>
                    </div>
                    <div className={styles.dropdown_container}>
                        <Dropdown
                            placeholder={
                                <FormattedMessage
                                    id="Onboarding.notSet"
                                    defaultMessage="Not set"
                                />
                            }
                            selectedValue={selectedOption}
                            options={options}
                            onChangeHandler={onChangeHandler}
                            className={getClassStyle(styles.dropdown)}
                        />
                    </div>
                </div>
                {description && (
                    <div className={styles.row_description}>
                        <span className="type-body-small">{description}</span>
                    </div>
                )}
            </Col>
        </Row>
    )
}

export default DropdownRow
