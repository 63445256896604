import { ButtonBase, CircularProgress } from "@material-ui/core"
import classnames from "classnames"
import styles from "./MUIButton.module.scss"

export enum ButtonType {
    Default_SecondaryIcon,
    xSmall_SecondaryIcon,
}

interface Props {
    type: ButtonType
    onClick?:
        | ((e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
        | undefined
    icon?: JSX.Element
    text?: string
    containerClass?: string
    buttonClass?: string
    textClass?: string
    iconClass?: string
    loading?: boolean
    disabled?: boolean
}

interface ButtonClasses {
    container?: string
    button?: string
    icon?: string
    text?: string
    disabled?: string
}

export const MUIButton = (props: Props): JSX.Element => {
    const {
        container: containerClass,
        button: buttonClass,
        icon: iconClass,
        text: textClass,
        disabled: disabledClass,
    } = GetButtonClasses(props.type)

    return (
        <ButtonBase
            classes={{ disabled: disabledClass }}
            className={classnames(
                containerClass,
                props.containerClass,
                props.loading ? styles.loading : null
            )}
            disabled={props.disabled}
            onClick={props.onClick}
        >
            {props.loading ? (
                <CircularProgress
                    className={styles.button_spinner}
                    thickness={7}
                />
            ) : (
                <div className={classnames(buttonClass, props.buttonClass)}>
                    <div className={classnames(iconClass, props.iconClass)}>
                        {props.icon}
                    </div>
                    <div className={classnames(textClass, props.textClass)}>
                        {props.text}
                    </div>
                </div>
            )}
        </ButtonBase>
    )
}

function GetButtonClasses(type: ButtonType): ButtonClasses {
    switch (type) {
        case ButtonType.Default_SecondaryIcon:
            return {
                container: styles.default_secondaryContainer,
                button: styles.default_secondaryButton,
                icon: styles.default_secondaryIcon,
                text: styles.default_secondaryText,
                disabled: styles.default_secondaryDisabled,
            }

        case ButtonType.xSmall_SecondaryIcon:
            return {
                container: styles.xsmall_secondaryContainer,
                button: styles.default_secondaryButton,
                icon: styles.xsmall_secondaryIcon,
                text: styles.xsmall_secondaryText,
                disabled: styles.default_secondaryDisabled,
            }

        default:
            return {
                container: undefined,
                button: undefined,
                icon: undefined,
                text: undefined,
                disabled: undefined,
            }
    }
}
