import {
    JSONAPIDocument,
    JSONAPIResource,
    Unit,
} from "@vacasa/owner-api-models"
import { AxiosError } from "axios"
import {
    QueryKey,
    UseQueryOptions,
    UseQueryResult,
    useMutation,
    useQuery,
    useQueryClient,
} from "react-query"
import { fetchUnit } from "./fetchUnits"
import { PostUnitRoomsParameters, postUnitRooms } from "./postUnitRooms"
import { RoomModel } from "models/Room"
import { useContactId } from "hooks/user"
import { fetchUnitRooms } from "./fetchUnitRooms"
import { RoomOptions } from "models/RoomOption"
import { fetchUnitRoomOptions } from "./fetchUnitRoomOptions"
import { PatchUnitArgs, patchUnit } from "./patchUnit"
import {
    PostUnitAllowPetsRequest,
    postUnitAllowPets,
} from "./postUnitAllowPets"
import { useUnitState } from "contexts/unit"
import {
    CalendarHolds,
    getCalendarHoldsStatus,
    isActiveUnit,
    isFixedRentUnit,
    isGuestworksUnit,
    isOnboardingUnit,
    isRevenuePoolingUnit,
} from "utils/unit"

export const useCurrentUnit = (): {
    unitId: string | null
    unit: JSONAPIResource<Unit> | null
} => {
    const { unitId, units } = useUnitState()
    return {
        unitId,
        unit: units[unitId ?? ""] ?? null,
    }
}

export type UnitStatuses = {
    calendarHoldStatus: CalendarHolds
    isActive: boolean
    isGuestworks: boolean
    isFixedRent: boolean
    isOnboarding: boolean
    isRevenuePooling: boolean
}

export const useUnitStatuses = (): UnitStatuses => {
    const { unitId, units } = useUnitState()
    const unit = units[unitId ?? ""]
    return {
        calendarHoldStatus: getCalendarHoldsStatus(unit),
        isActive: isActiveUnit(unit),
        isGuestworks: isGuestworksUnit(unit),
        isFixedRent: isFixedRentUnit(unit),
        isOnboarding: isOnboardingUnit(unit),
        isRevenuePooling: isRevenuePoolingUnit(unit),
    }
}

// this should be used when displaying and updating values via mutation
export const useUnit = (
    unitId: string,
    options?: UseQueryOptions<JSONAPIDocument<Unit>["data"], AxiosError>
): UseQueryResult<JSONAPIDocument<Unit>["data"], AxiosError> => {
    const contactId = useContactId() ?? ""
    return useQuery<JSONAPIDocument<Unit>["data"], AxiosError>(
        ["unit", contactId, unitId],
        ({ signal }) =>
            fetchUnit({
                contactId,
                unitId,
                signal,
            }).then(response => response.data),
        {
            enabled: !!contactId && !!unitId,
            ...options,
        }
    )
}

export const useUnitAllowPetsMutation = () => {
    const queryClient = useQueryClient()
    const contactId = useContactId() ?? ""
    return useMutation<
        void,
        AxiosError,
        Omit<PostUnitAllowPetsRequest, "contactId">
    >(request => postUnitAllowPets({ ...request, contactId }), {
        onSuccess: (_, variables) => {
            // Update cache for that 1 unit instead of refetching all
            const existingData = queryClient.getQueryData<
                JSONAPIResource<Unit>[]
            >(["units", contactId])

            if (existingData) {
                existingData.forEach(unit => {
                    if (String(unit.id) !== variables.unitId) return
                    unit.attributes.amenities.petsFriendly = true
                })
                queryClient.setQueryData(["units", contactId], existingData)
            }
        },
    })
}

export const useUnitMutation = () => {
    const queryClient = useQueryClient()
    const contactId = useContactId() ?? ""
    return useMutation<void, AxiosError, Omit<PatchUnitArgs, "contactId">>(
        request => patchUnit({ ...request, contactId }),
        {
            onSuccess: async (_, { unitId }) => {
                await queryClient.invalidateQueries(["unit", contactId, unitId])
            },
        }
    )
}

export const useUnitRooms = (
    unitId: string,
    options:
        | Omit<
              UseQueryOptions<RoomModel[], AxiosError, RoomModel[], QueryKey>,
              "queryKey" | "queryFn" | "enabled"
          >
        | undefined = {}
) => {
    const contactId = useContactId() ?? ""
    return useQuery<RoomModel[], AxiosError>(
        ["unit", contactId, unitId, "rooms"],
        ({ signal }) => fetchUnitRooms({ contactId, unitId, signal }),
        {
            enabled: !!contactId && !!unitId,
            staleTime: 1000 * 60 * 10,
            ...options,
        }
    )
}

export const useUnitRoomsMutation = () => {
    const queryClient = useQueryClient()
    return useMutation<void, AxiosError, PostUnitRoomsParameters>(
        request => postUnitRooms(request),
        {
            onSuccess: async (_, { contactId, unitId }) => {
                await queryClient.invalidateQueries([
                    "unit",
                    contactId,
                    unitId,
                    "rooms",
                ])
            },
        }
    )
}

export const useUnitRoomOptions = (
    options:
        | Omit<
              UseQueryOptions<RoomOptions, AxiosError, RoomOptions, QueryKey>,
              "queryKey" | "queryFn" | "enabled"
          >
        | undefined = {}
) => {
    return useQuery<RoomOptions, AxiosError>(
        ["unit-room-options"],
        ({ signal }) => fetchUnitRoomOptions({ signal }),
        {
            staleTime: 1000 * 60 * 10,
            ...options,
        }
    )
}
