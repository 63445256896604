import React from "react"
import SVGIcon from "../../lib/components/SVGIcon"
import { useMobileNavigation } from "."
import { useCallback } from "react"
import classnames from "classnames"
import styles from "./OnboardingNavItem.module.scss"
import useWindowWidth from "hooks/common/useWindowSize"
import { clearOnboardingRedirectConfig } from "utils/onboarding"
import { trackNavigateToOnboardingClicked } from "services/segment/onboarding/onboardingTracking"
import { MOBILE_BREAKPOINT } from "Constants"

interface Props {
    label: React.ReactNode
    divider?: boolean
    showBadge?: boolean
    show?: boolean
}
const OnboardingNavItem: React.FC<Props> = ({ label, show, showBadge }) => {
    // svg names
    const notificationIcon = "icon-feather-alert-circle"
    const arrowIcon = "icon-feather-arrow-right-white"

    // css/scss classes
    const navLinkClasses = classnames(
        "type-body-small label",
        styles["priority-label"]
    )
    const firstIconClasses = classnames("w-100", styles["priority-svg__left"])
    const secondIconClasses = classnames("w-100", styles["priority-svg__right"])

    const screenWidth = useWindowWidth()
    const mobileNavigation = useMobileNavigation()

    // flags
    const isMobileWidth = screenWidth < MOBILE_BREAKPOINT
    const showFirstIcon = showBadge && isMobileWidth
    const showSecondIcon = isMobileWidth || showBadge

    const handleClick = useCallback(() => {
        mobileNavigation.setOpen(false)
        trackNavigateToOnboardingClicked()
        clearOnboardingRedirectConfig()
    }, [mobileNavigation])

    if (!show) {
        return null
    }
    return (
        <li>
            <a
                href="/onboarding"
                className={classnames(navLinkClasses)}
                onClick={handleClick}
            >
                {showFirstIcon && (
                    <SVGIcon
                        svg={notificationIcon}
                        className={firstIconClasses}
                    />
                )}
                {label}
                {showSecondIcon && (
                    <SVGIcon
                        svg={isMobileWidth ? arrowIcon : notificationIcon}
                        className={secondIconClasses}
                    />
                )}
            </a>
        </li>
    )
}

export default OnboardingNavItem
