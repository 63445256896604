import { JSONAPIResource, Unit } from "@vacasa/owner-api-models"

export enum UnitStatusType {
    Inactive = "inactive",
    Active = "active",
    MaintenanceOnly = "maintenance",
}

export enum ActiveStatus {
    Inactive = 0,
    Active = 1,
    MaintenanceOnly = 2,
}

export enum TerminatedStatus {
    TerminationPending = -1,
    NotTerminated = 0,
    Terminated = 1,
}

/**
 * Temp: Should look at compiling the owner-api-models package to enable the use of enums
 */
export enum OnboardingStatus {
    None = "ONBOARDING_NOT_REQUIRED",
    InformationRequired = "INFORMATION_REQUIRED",
    PreparingListing = "PREPARING_LISTING",
    UnderReview = "UNDER_REVIEW",
}

export enum CalendarHolds {
    Allowed = 0,
    NotAllowedPlaceholder = 1,
    NotAllowedInactive = 2,
}

/**
 * Convert unit status number (0, 1 or 2) to UnitStatusType
 */
export const getUnitStatusTypeByStatusNumber = (
    statusNumber: number
): UnitStatusType | undefined => {
    switch (statusNumber) {
        case 0:
            return UnitStatusType.Inactive
        case 1:
            return UnitStatusType.Active
        case 2:
            return UnitStatusType.MaintenanceOnly
        default:
            return undefined
    }
}

/**
 * Checks if a unit is live
 * @param unit
 * @returns bool
 */
export const isUnitLive = (unit?: JSONAPIResource<Unit> | null): boolean => {
    if (!unit) return false
    return (
        !unit.attributes.status.ownerOnboardingApp.isOnboarding &&
        unit.attributes.display &&
        unit.attributes.active === ActiveStatus.Active &&
        unit.attributes.terminated === TerminatedStatus.NotTerminated
    )
}

/**
 * Checks if a unit is terminated
 * @param unit
 * @returns bool
 */
export const isTerminatedUnit = (unit: JSONAPIResource<Unit> | null) => {
    if (!unit) return false
    return unit.attributes.terminated === TerminatedStatus.Terminated
}

export const isGuestworksUnit = (unit?: JSONAPIResource<Unit> | null) => {
    if (!unit) return false
    return unit.attributes.status.guestworksApp.isGuestworksUnit
}

export const isOnboardingUnit = (unit?: JSONAPIResource<Unit> | null) => {
    if (!unit) return false
    return (
        unit.attributes.status.onboarding !== OnboardingStatus.None ||
        unit.attributes.status.ownerOnboardingApp.isOnboarding
    )
}

export const isActiveUnit = (unit?: JSONAPIResource<Unit> | null) => {
    if (!unit) return false
    return unit.attributes.status.active
}

export const isRevenuePoolingUnit = (unit?: JSONAPIResource<Unit> | null) => {
    if (!unit) return false
    return !!unit.attributes.contract.revenuePooling
}
