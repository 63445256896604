import Modal from "lib/components/Modal/Modal"
import React, { ReactNode } from "react"
import styles from "./GeneralError.module.scss"
import { GeneralError } from "./GeneralError"

interface GeneralErrorModalProps {
    handleClose: () => void
    errorHeading?: string
    errorMessage?: string | ReactNode
    buttonText?: string
    handleClick?: () => void
}

const GeneralErrorModal: React.FC<GeneralErrorModalProps> = props => {
    const { handleClose, errorHeading, errorMessage, buttonText, handleClick } =
        props

    return (
        <Modal
            onCloseHandler={handleClose}
            modalPanelStyleOverride={styles.modalContainer}
        >
            <GeneralError
                errorHeading={errorHeading}
                errorMessage={errorMessage}
                handleClick={handleClick}
                buttonText={buttonText}
                customContainerStyle={styles.customErrorContainer}
            />
        </Modal>
    )
}

export { GeneralErrorModal }
