import { useHasTaxObligation } from "hooks/taxes"
import { FC, useEffect, useState } from "react"
import { hasDismissed1099ModalThisPeriod, isBetweenWindow } from "./utils"
import {
    useOwnerPreferences,
    useOwnerPreferencesMutation,
} from "hooks/owner-preferences"
import Modal from "../Modal/Modal"
import { OWNER_PREFERENCE_QUERY_KEY } from "hooks/owner-preferences/useOwnerPreferences"
import { useQueryClient } from "react-query"
import { PAPERLESS_1099_NOTIFICATION_DISMISSED } from "constants/preferences.constants"
import styles from "./Paperless1099Modal.module.scss"
import { useContactId, useUser } from "hooks/user"
import Media from "./assets/media.png"
import { NewButton as Button } from "../Button"
import classNames from "classnames"
import { useReceiveFormsElectronicallyMutation } from "hooks/finance"
import { FormattedMessage } from "react-intl"
import { useInvalidateContact } from "hooks/contact/useContact"
import { useToastNotification } from "../ToastNotification"
import {
    track1099NotificationSkipped,
    trackGoPaperlessClicked,
    trackGoPaperlessStatusUpdated,
    trackModalDismissed,
    trackModalPresented,
} from "services/segment/paperless-1099"
import { isOwnerApp } from "utils/browser/browserUtil"

export const Paperless1099Modal: FC = () => {
    const { user } = useUser()
    const [show, setShow] = useState(false)
    const contactId = useContactId() ?? ""
    const { addToastNotification } = useToastNotification()
    const invalidateContact = useInvalidateContact()
    const { hasTaxObligation, isLoading: isLoadingHasTaxObligation } =
        useHasTaxObligation()
    const userPreferencesQuery = useOwnerPreferences()
    const queryClient = useQueryClient()
    const {
        mutate: mutateOwnerPreference,
        isLoading: isLoadingOwnerPreferenceMutation,
    } = useOwnerPreferencesMutation({
        onSuccess: () => {
            queryClient.invalidateQueries(OWNER_PREFERENCE_QUERY_KEY)
            track1099NotificationSkipped()
        },
    })

    const {
        mutate: mutateReceiveFormsElectronically,
        isLoading: isLoadingReceiveFormsElectronically,
    } = useReceiveFormsElectronicallyMutation()
    const preference = userPreferencesQuery.data?.find(
        preference => preference.id === PAPERLESS_1099_NOTIFICATION_DISMISSED
    )

    const onGoPaperlessClick = () => {
        trackGoPaperlessClicked()

        // Note the inverted value: there is a bug in
        // api/v1/legacy/owners/{id}/receive-forms-electronically endpoint where the value is inverted
        mutateReceiveFormsElectronically(
            {
                contactId,
                receiveElectronically: false,
            },
            {
                onSuccess: () => {
                    trackGoPaperlessStatusUpdated()
                    addToastNotification({
                        content: (
                            <FormattedMessage
                                id="Taxes.paperlessNotification"
                                defaultMessage="Tax forms will be sent electronically"
                            />
                        ),
                    })
                    invalidateContact(contactId)
                    // hide the notification until the next window if they switch again
                    mutateOwnerPreference({
                        id: PAPERLESS_1099_NOTIFICATION_DISMISSED,
                        value: true,
                    })
                    setShow(false)
                },
            }
        )
    }

    useEffect(() => {
        const goPaperless = !user?.accountInfo?.tax1099EmailOptOut ?? false
        if (goPaperless) return

        // while preferences are loading show nothing
        if (userPreferencesQuery.isLoading) return

        // 0% tax obligation, no need to show
        if (!hasTaxObligation || isLoadingHasTaxObligation) return

        // not between the allowed dates to show the notification
        if (!isBetweenWindow()) return

        // if the user has dismissed the notification for this current tax period don't show it again
        if (hasDismissed1099ModalThisPeriod(preference?.attributes)) return

        // show the modal
        setShow(true)
        trackModalPresented()
    }, [
        hasTaxObligation,
        isLoadingHasTaxObligation,
        preference?.attributes,
        user?.accountInfo?.tax1099EmailOptOut,
        userPreferencesQuery.isLoading,
    ])

    useEffect(() => {
        if (hasDismissed1099ModalThisPeriod(preference?.attributes))
            setShow(false)
    }, [preference?.attributes])

    const onModalClose = () => {
        setShow(false)
        trackModalDismissed()
    }

    const onSkipForNowClick = () => {
        mutateOwnerPreference({
            id: PAPERLESS_1099_NOTIFICATION_DISMISSED,
            value: true,
        })
    }

    if (!show || isOwnerApp() || !user?.accountInfo?.tax1099EmailOptOut)
        return null

    return (
        <Modal
            onCloseHandler={onModalClose}
            modalPanelStyleOverride={styles.modal}
            modalContentStyleOverride={styles.modalContent}
        >
            <div className={classNames(styles.container, "antialiased")}>
                <div className={styles.media}>
                    <img src={Media} alt="Paperless 1099" />
                </div>
                <div className={styles.main}>
                    <div className={styles.title}>
                        <FormattedMessage
                            id="Taxes.paperless1099Modal.title"
                            defaultMessage="Paperless 1099"
                        />
                    </div>
                    <p>
                        <FormattedMessage
                            id="Taxes.paperless1099Modal.description"
                            defaultMessage="As the new year approaches, be prepared for tax season by receiving your Vacasa 1099 digitally. We'll email you when it's ready."
                        />
                    </p>
                    <div className={styles.actions}>
                        <Button
                            variant="primary"
                            onClick={onGoPaperlessClick}
                            loading={isLoadingReceiveFormsElectronically}
                            disabled={isLoadingReceiveFormsElectronically}
                        >
                            <FormattedMessage
                                id="Taxes.paperless1099Modal.goPaperless"
                                defaultMessage="Go Paperless"
                            />
                        </Button>
                        <Button
                            variant="secondary"
                            onClick={onSkipForNowClick}
                            disabled={isLoadingOwnerPreferenceMutation}
                            loading={isLoadingOwnerPreferenceMutation}
                        >
                            <FormattedMessage
                                id="Taxes.paperless1099Modal.skipForNow"
                                defaultMessage=" Skip for Now"
                            />
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
