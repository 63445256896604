import { authAxios } from "../../api/authAxios"

export type PatchRefreshStatementRequest = {
    contactId: string
    type: "monthly" | "yearly"
    year: number
    month?: number
}

export const patchRefreshStatement = ({
    contactId,
    type,
    year,
    month,
}: PatchRefreshStatementRequest): Promise<void> => {
    const params = month
        ? {
              type,
              year,
              month,
          }
        : {
              type,
              year,
          }
    return authAxios.patch(
        `/v1/owners/${contactId}/historical-statement`,
        undefined,
        {
            params,
        }
    )
}
