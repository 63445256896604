const statements = {
    faqAriaLabel: "View FAQ",
    title: "Estados",
    emptyStateCTA: "Conocer más acerca de los estados",
    emptyStateEarnings:
        "Los nuevos estados estarán disponibles el 10 de cada mes.",

    pdf: "PDF",
    pdfAriaLabel: "Ver PDF",
    pdfNotification: "Preparando para descargar PDF",
    fileName: "estado",

    print: "Imprimir",
    printAriaLabel: "Vista de impresión",

    paymentSummary: "Resumen de pago: Todas las propiedades",
    beginningBalance: "Saldo al inicio",
    payment: "Pago",
    payments: "Pagos",
    proceeds: "Ingresos",
    proceedsBalance: "Saldo de los ingresos",
    coOwnerProceeds: "Ingresos del copropietario",
    endingBalance: "Saldo final",
    details: "Detalles",
    summary: "Resumen",
    nightsOccupied: "Noches ocupadas",
    occupancy: "Ocupación",
    reserveBalance: "Saldo de reserva",

    beginningReserveBalance: "Saldo de reserva al inicio",
    EndingReserveBalance: "Saldo de reserva final",
    amountHeld: "Monto retenido",
    amountUsed: "Monto usado",

    netRent: "Renta neta",
    expense: "Gasto",
    expenses: "Gastos",

    month: "Mes",
    nights: "Noches",
    grossRent: "Renta bruta",
    mgmtFee: "Tarifa de gestión",
    total: "Total",
    credits: "Créditos",

    checkNumber: "Nº de cheque",
    paymentDescription: "Pago a la cuenta terminada en ",
    statementYearSummary: "{year} Year Summary",

    canadaSalesTaxesHeader: "Taxes - Expenses & Management Fees",

    tooltips: {
        proceeds: "Amount you'll be paid for this month",
        occupancy:
            "The percentage of available nights this month that you rented to guests (does not include your personal use dates and complimentary stays)",
        nightsOccupied:
            "How many nights this month your home hosted guests (only counts reservations with check-out dates within the month)",
    },
    paymentSettings: "Payment Settings",
    paymentSettingsSmall: "Settings",
    paymentSettingsNotification:
        "Manage your bank details and payment settings here.",
    paymentSettingsNotificationTitle: "Payment Settings",
    checkItOut: "Check it Out",
    settings: "Settings",
    backToTop: "Back to Top",
    needHelp: "Need Help?",
    faqAbbreviation: "FAQ",
    errorDownloading: "Error downloading",
    pdfDownloadError:
        "We were unable to create a PDF of your statement. Please try again later.",
    faq: {
        whereDoISendPayments: {
            answer: {
                canada: "If your home is in Canada",
                allOthers: "All others",
            },
        },
    },
    netRentNoteBegin: "NOTE: ",
    netRentNoteContent:
        "The entire stay spans across {reservationMonthSpan} months ({reservationPeriod}). Monthly revenue will appear on each month’s respective statement(s).",
}

export default statements
