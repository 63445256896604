import { useContactId } from "hooks/user"
import { UseQueryResult, useQuery } from "react-query"
import { fetchTicketCategories } from "./fetchTicketCategories"
import { AxiosError } from "axios"
import { TicketCategory } from "@vacasa/owner-api-models"

export const useTicketCategories = (): UseQueryResult<
    TicketCategory,
    AxiosError
> => {
    const contactId = useContactId()
    return useQuery(
        ["ticket-categories", contactId],
        () => fetchTicketCategories(),
        {
            enabled: !!contactId,
            refetchInterval: 1000 * 60 * 10, // 10 minutes
            staleTime: 1000 * 60 * 10, // 10 minutes
        }
    )
}
