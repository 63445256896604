import Hotjar from "@hotjar/browser"
import LoggingService from "services/logging/logging.service"

export function identifyHotjar(userInfo: {
    contactId: string
    email: string
    legacyUserId: string
    userId: string
}) {
    const success = Hotjar.identify(userInfo.userId, {
        contactId: userInfo.contactId,
        email: userInfo.email,
        legacyUserId: userInfo?.legacyUserId,
    })

    if (success) {
        LoggingService.log({
            message: "Successfully identified Hotjar user.",
        })
    } else {
        LoggingService.warn({
            message: "Failed to identify Hotjar user.",
        })
    }

    return success
}
