import { InspectionCategoryType, InspectionLabelType } from "./homecare.types"

export const mapCategoryToLabel = (
    category: InspectionCategoryType
): InspectionLabelType => {
    switch (category) {
        case "backyard":
            return "backyard"
        case "bathroom":
            return "bathroom"
        case "bedroom":
            return "bedroom"
        case "entrance":
            return "entrance"
        case "kitchen":
            return "kitchen"
        case "living_room":
            return "living"
        case "washer_dryer":
            return "laundry"
        case "dc_garage":
        case "dc_inventory":
        case "dc_kitchen_appliances":
        case "dc_kitchen_stove_oven":
        case "dc_kitchen_surfaces":
        case "dc_outside":
        case "hv_general":
        case "general":
        case "hv_kitchen":
        case "hv_others":
        case "hv_safety":
        default:
            return "general"
    }
}
