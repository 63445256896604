import { useMemo } from "react"
import { Params, useLocation, useNavigate, useParams } from "react-router-dom"

export interface RouterState {
    params: Readonly<Params<string>>
    location: ReturnType<typeof useLocation>
    navigate: ReturnType<typeof useNavigate>
}

/**
 * Aggregate react-router-dom hooks
 */
export default function useRouter(): RouterState {
    const params = useParams()
    const location = useLocation()
    const navigate = useNavigate()

    return useMemo<RouterState>(() => {
        return {
            params,
            location,
            navigate,
        }
    }, [params, location, navigate])
}
