import * as React from "react"
import styles from "./RevenuePreferencesWidget.module.scss"
import classNames from "classnames"
import { Card, CardContent } from "../../../../components/Card"
import SVGIcon from "../../../../lib/components/SVGIcon"
import { FormattedMessage, useIntl } from "react-intl"
import { Button } from "../../../../lib/components/Button"
import useRouter from "../../../../hooks/common/useRouter"
import { X } from "react-feather"
import storageService from "../../../../services/storage.service"
import { REVENUE_PERSONA_QUESTIONS_COMPLETED } from "../../../../constants/preferences.constants"

interface RevenuePreferencesWidgetProps {
    loading: boolean
    thanksBanner: boolean
    handleClose: (value: boolean) => void
    unitId: string
}

const RevenuePreferencesWidget = ({ loading, handleClose, thanksBanner, unitId }: RevenuePreferencesWidgetProps) => {
    const router = useRouter()
    const intl = useIntl()

    const beginSurvey = () => {
        router.navigate({
            pathname: "/performance/preference/create",
            search: router.location.search,
            hash: router.location.hash,
        });
    }

    const onClose = () => {
        const storedArray =  storageService.localStorage.getItem(REVENUE_PERSONA_QUESTIONS_COMPLETED)
        const historyArray = storedArray ? JSON.parse(storedArray) as Array<string> : [];
        const updateHistoryArray = historyArray.filter((id) => id !== unitId)
        storageService.localStorage.setItem(REVENUE_PERSONA_QUESTIONS_COMPLETED, JSON.stringify(updateHistoryArray))
        handleClose(true)
    }

    return (
        <>
            <Card
                className={classNames(styles.revenue_references_container, {
                    shimmer: loading,
                })}
                testId={"revenue-references-container"}
            >
                <CardContent
                    className={styles.content_revenue_preferences}
                >
                    <SVGIcon
                        svg={"icon-feather-target-2"}
                        className={styles.target_icon}
                        colorName="midnight"
                    />
                    {thanksBanner ?
                        <>
                            <div className={styles.content_wrapper}>
                                <h3>
                                    <h3>
                                        <FormattedMessage
                                            id="Dashboard.revenuePreferences.tanksTitle"
                                            defaultMessage="Congrats on setting your revenue strategy!"
                                        />
                                    </h3>
                                </h3>
                                <p>
                                    <FormattedMessage
                                        id="Dashboard.revenuePreferences.tanksMessage"
                                        defaultMessage="Our team is working to implement your requests."
                                    />
                                </p>
                            </div>
                            <div
                                className={styles["close-container"]}
                            >
                                <button
                                    id="closeButton"
                                    className={styles.close}
                                    onClick={onClose}
                                    data-testid="close-button"
                                >
                                    <X aria-label="Close Revenue Preference Modal" />
                                </button>
                            </div>
                        </>
                        :
                        <>
                            <div className={styles.content_wrapper}>
                                <h3>
                                    <FormattedMessage
                                        id="Dashboard.revenuePreferences.modalTitle"
                                        defaultMessage="Set your revenue strategy"
                                    />
                                </h3>
                                <p>
                                    <FormattedMessage
                                        id="Dashboard.revenuePreferences.description"
                                        defaultMessage="Help us understand your goals and expectations regarding revenue, pricing, and occupancy, so we can make sure your unit is set up for success."
                                    />
                                </p>
                            </div>
                            <Button
                                className={styles.button}
                                type="submit"
                                variant="primary"
                                aria-label={intl.formatMessage({
                                    id: "Dashboard.revenuePreferences.takeSurvey",
                                    defaultMessage: "Set strategy",
                                })}
                                onClick={beginSurvey.bind(this)}
                            >
                                <FormattedMessage
                                    id="Dashboard.revenuePreferences.takeSurvey"
                                    defaultMessage="Set strategy"
                                />
                            </Button>
                        </>
                    }
                </CardContent>
            </Card>
        </>
    )
}

export { RevenuePreferencesWidget }