import { FormattedMessage } from "react-intl"
import { trackLiveUnitCongratsViewListing } from "services/segment/calendar/calendarTracking"
import { VACASA_PROD_URL } from "../../../Constants"
import ActionModal, {
    SpecialModalType,
} from "../../../lib/components/ActionModal/ActionModal"

interface CongratsModalProps {
    unitId: string
    listingLive: string
    onClose: () => void
}

export function CongratsModal(props: CongratsModalProps): JSX.Element {
    return (
        <ActionModal
            onCloseHandler={props.onClose}
            title={
                <FormattedMessage
                    id="Onboarding.congrats.title"
                    defaultMessage="Your listing is live!"
                />
            }
            description={
                <FormattedMessage
                    id="Onboarding.congrats.description"
                    defaultMessage="Good news—your home is live on Vacasa.com, and you're all set to start getting bookings."
                />
            }
            icon={<img src={props.listingLive} alt="Listing is live" />}
            modalType={SpecialModalType.congrats}
            unitId={props.unitId}
            primaryButtonTitle={
                <FormattedMessage
                    id="Onboarding.congrats.buttonText"
                    defaultMessage="Check It Out"
                />
            }
            primaryButtonHandler={() => {
                trackLiveUnitCongratsViewListing()
                window.open(`${VACASA_PROD_URL}unit/${props.unitId}`, "_blank")
            }}
            secondaryButtonTitle={
                <FormattedMessage
                    id="Onboarding.revenueReminder.maybeLaterButton"
                    defaultMessage="Maybe Later"
                />
            }
            secondaryButtonHandler={props.onClose}
        />
    )
}
