import { JSONAPIResource, Reservation } from "@vacasa/owner-api-models"
import ReservationStaticDisplay from "../../../../lib/components/FlyOut/Holds/ReservationStaticDisplay"
import { UnitInfo } from "../UnitInfo"
import { ViewReservationFooter } from "./ViewReservationFooter"
import { ViewReservationOnboardingFooter } from "./ViewReservationOnboardingFooter"
import Layout from "../Layout/Layout"
import styles from "./ViewReservation.module.scss"
import useRouter from "hooks/common/useRouter"
import { useUserCurrencyCode } from "hooks/user"

type ViewReservationProps = {
    reservation: JSONAPIResource<Reservation>
    unitInfo: UnitInfo
}

const ViewReservation: React.FC<ViewReservationProps> = ({
    reservation,
    unitInfo,
}) => {
    const currencyCode = useUserCurrencyCode()
    const router = useRouter()

    return (
        <Layout>
            <Layout.Content>
                {reservation && (
                    <ReservationStaticDisplay
                        reservation={reservation}
                        unitInfo={unitInfo}
                        currency={currencyCode}
                        inviteGuest={() =>
                            router.navigate(
                                {
                                    pathname: `/calendar/hold/${reservation.id}/invite`,
                                    search: router.location.search,
                                    hash: router.location.hash,
                                },
                                { replace: true }
                            )
                        }
                    />
                )}
            </Layout.Content>
            <Layout.Footer
                noShadow
                className={styles["view-reservation__footer"]}
            >
                <ViewReservationFooter
                    reservation={reservation}
                    unitInfo={unitInfo}
                    onDeleteClick={() =>
                        router.navigate(
                            {
                                pathname: `/calendar/hold/${reservation.id}/delete`,
                                search: router.location.search,
                                hash: router.location.hash,
                            },
                            { replace: true }
                        )
                    }
                    onEditClick={() =>
                        router.navigate(
                            {
                                pathname: `/calendar/hold/${reservation.id}/edit`,
                                search: router.location.search,
                                hash: router.location.hash,
                            },
                            { replace: true }
                        )
                    }
                />
                <ViewReservationOnboardingFooter
                    unitID={reservation.attributes.unitID}
                />
            </Layout.Footer>
        </Layout>
    )
}

export { ViewReservation }
