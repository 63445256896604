import { authAxios } from "../../api/authAxios"

export type PatchUserPreferencesRequest = {
    id: string
    value: string | boolean
}

export const patchOwnerUserPreferences = ({
    id,
    ...patch
}: PatchUserPreferencesRequest): Promise<void> =>
    authAxios.patch("v1/user-preferences", {
        data: {
            type: "user-preference",
            id,
            attributes: {
                ...patch,
            },
        },
    })
