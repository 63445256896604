import React from "react"
import Loader from "./index"

const FullScreenLoader: React.FC = () => (
    <div
        className="container full-screen-loader"
        style={{
            height: "100%",
            maxWidth: "100%",
            top: 0,
            position: "fixed",
        }}
    >
        <Loader />
    </div>
)

export default FullScreenLoader
