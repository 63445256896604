import { optimizely } from "services/optimizely.service"

export type FeatureExperimentId = "revenue-simulator-impact-variations"

export type FeatureExperiment = {
    variation: string | null
    clientReady: boolean
    didTimeout: boolean
}

export type OptimizelyEventId = "revenue-simulation-completed"

/**
 * Returns the variation key for an experiment.
 * @param featureExperiment
 * @returns
 */
export const getExperimentVariation = (
    featureExperiment: FeatureExperiment
) => {
    return featureExperiment.variation
}

/**
 * method to trigger Optimizely tacking event
 * @param {OptimizelyEventId} optimizelyEventId the event key defined on Optimizely
 */
export const trackOptimizelyEvent = (optimizelyEventId: OptimizelyEventId) =>
    optimizely.track(optimizelyEventId)
