import React, { FC, PropsWithChildren, useEffect } from "react"
import { Row, Col } from "reactstrap"
import Modal from "../Modal/Modal"
import { Secondary } from "../Buttons/BaseButton"
import classnames from "classnames"
import loggingService from "../../../services/logging/logging.service"
import styles from "../../../sass/onboarding.module.scss"

export enum SpecialModalType {
    congrats,
}

interface Props {
    onCloseHandler: () => void
    primaryButtonHandler?: () => void
    secondaryButtonHandler?: () => void
    icon?: JSX.Element
    modalType?: SpecialModalType
    title: React.ReactNode
    description: string | JSX.Element
    boldDescription?: React.ReactNode
    primaryButtonTitle?: React.ReactNode
    secondaryButtonTitle?: React.ReactNode
    unitId?: string
}

const ActionModal: FC<PropsWithChildren<Props>> = (
    props: Props
): JSX.Element => {
    useEffect(() => {
        if (props.modalType === SpecialModalType.congrats && props.unitId) {
            loggingService.log({
                message: `Congrats modal is shown for unit id: ${props.unitId}`,
            })
        }
    }, [props.modalType, props.unitId])

    let modalStyleClassName
    switch (props.modalType) {
        case SpecialModalType.congrats:
            modalStyleClassName = styles.congrats
            break
        default:
            modalStyleClassName = styles.regular
            break
    }
    const classNames = classnames(styles.image, modalStyleClassName)

    return (
        <Modal onCloseHandler={props.onCloseHandler}>
            <Row className={styles.modal}>
                {props.icon && (
                    <Col md="auto">
                        <div className={classNames} data-testid="modal-icon">
                            {props.icon}
                        </div>
                    </Col>
                )}
                <Col>
                    <h3 className="type-heading-medium-large">{props.title}</h3>
                    <p>{props.description}</p>
                    {props.boldDescription && (
                        <p>
                            <strong>{props.boldDescription}</strong>
                        </p>
                    )}
                    {props.primaryButtonTitle || props.secondaryButtonTitle ? (
                        <Row>
                            <Col md="auto">
                                <Secondary
                                    typeOf={"button"}
                                    onClick={props.primaryButtonHandler}
                                >
                                    {props.primaryButtonTitle}
                                </Secondary>
                            </Col>
                            <Col md="auto">
                                <Secondary
                                    typeOf={"link"}
                                    onClick={props.secondaryButtonHandler}
                                >
                                    {props.secondaryButtonTitle}
                                </Secondary>
                            </Col>
                        </Row>
                    ) : null}
                </Col>
            </Row>
        </Modal>
    )
}

export default ActionModal
