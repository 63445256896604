import { ReactNode, useCallback } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { observer } from "mobx-react"
import classnames from "classnames"

import { Card, CardContent } from "../../components/Card"
import { ListLayout } from "../../components/Layout/ListLayout"
import { useContact, useContactMutation } from "hooks/contact"
import { useCountries } from "../../hooks/countries"
import { useContactId } from "hooks/user"
import BaseButton from "../../lib/components/Buttons/BaseButton"

import { statesInCanada, statesInUSA } from "../../utils/array/statesArray"
import { Spinner } from "../../components/Spinner"
import { Input } from "./Form/Input"
import { Select } from "./Form/Select"
import { ButtonLoader } from "../../lib/components/Loader/ButtonLoader/ButtonLoader"
import { getPasswordResetURL } from "../../utils/auth/idp"

import styles from "./Profile.module.scss"
import { EmployeeAlert } from "./EmployeeAlert"
import { ExtraInformationTooltip } from "./ExtraInformationTooltip"
import { HOMEOWNER_ACCOUNTS_EMAIL, LANGUAGES } from "Constants"
import { SupportedPages } from "./EmployeeAlert/EmployeeAlert"
import { useLanguage } from "contexts/language"
import { getLanguageFromId } from "utils/language"
import { useIsEmployee } from "hooks/user"

const statesByCountry = new Map([
    ["US", statesInUSA],
    ["CA", statesInCanada],
])

type FormInputs = {
    firstName: string
    lastName: string
    country: string
    address1: string
    address2: string
    city: string
    state?: string
    zipCode: string
    preferredLanguage: number
    mainPhone: string
}

const Profile = observer((): JSX.Element | null => {
    const intl = useIntl()
    const isEmployee = useIsEmployee()

    const contactId = useContactId()
    const countriesQuery = useCountries()
    const contactQuery = useContact(contactId ?? undefined)
    const contactMutation = useContactMutation()
    const language = useLanguage()

    const {
        register,
        watch,
        formState: { errors, isDirty },
        handleSubmit,
    } = useForm<FormInputs>({
        shouldUnregister: true,
    })

    const onSubmit = useCallback<SubmitHandler<FormInputs>>(
        data =>
            contactMutation.mutate(
                {
                    contactId: contactQuery.data?.contactId as string,
                    firstName: isEmployee ? undefined : data.firstName,
                    lastName: isEmployee ? undefined : data.lastName,
                    address1: data.address1,
                    address2: data.address2,
                    city: data.city,
                    state: statesByCountry.has(data.country)
                        ? data.state ?? ""
                        : "",
                    countryCode: data.country,
                    zip: data.zipCode,
                    phone: data.mainPhone,
                    languageId: Number(data.preferredLanguage),
                },
                {
                    onSuccess: (_, data) => {
                        language.setLocale(
                            getLanguageFromId(data.languageId) ?? "en"
                        )
                    },
                }
            ),
        [contactMutation, contactQuery.data?.contactId, isEmployee, language]
    )

    const watchCountry = watch("country")
    const states = statesByCountry.get(watchCountry)

    const isLoading = contactQuery.isLoading || countriesQuery.isLoading

    return (
        <ListLayout
            title={
                <FormattedMessage id="Profile.title" defaultMessage="Profile" />
            }
            size="sm"
        >
            {isEmployee && <EmployeeAlert page={SupportedPages.Profile} />}
            {isLoading && (
                <Card noBorderOnMobile>
                    <CardContent noPaddingOnMobile>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Spinner />
                        </div>
                    </CardContent>
                </Card>
            )}
            {(contactQuery.isError || countriesQuery.isError) && (
                <Card noBorderOnMobile>
                    <CardContent noPaddingOnMobile>
                        <FormattedMessage
                            id="Profile.errors.errorFetching"
                            defaultMessage="We had trouble loading your page. If the problem persists, please try again later"
                        />
                    </CardContent>
                </Card>
            )}
            {contactQuery.isSuccess && countriesQuery.isSuccess && (
                <Card noBorderOnMobile>
                    <CardContent noPaddingOnMobile>
                        <form
                            className={styles.form}
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <div className={styles.rowInputContainer}>
                                {!isEmployee && (
                                    <Input
                                        label={
                                            <FormattedMessage
                                                id="Profile.formFields.firstName"
                                                defaultMessage="First Name"
                                            />
                                        }
                                        placeholder={intl.formatMessage({
                                            id: "Profile.placeholders.firstName",
                                            defaultMessage: "First Name",
                                        })}
                                        defaultValue={
                                            contactQuery.data.firstName ??
                                            undefined
                                        }
                                        {...register("firstName", {
                                            required: intl.formatMessage({
                                                id: "Profile.errors.name",
                                                defaultMessage:
                                                    "Please enter your first name",
                                            }),
                                        })}
                                        required
                                        type="text"
                                        disabled={isEmployee}
                                        error={errors.firstName}
                                    />
                                )}
                                {!isEmployee && (
                                    <Input
                                        label={
                                            <FormattedMessage
                                                id="Profile.formFields.lastName"
                                                defaultMessage="Last Name"
                                            />
                                        }
                                        defaultValue={
                                            contactQuery.data.lastName ??
                                            undefined
                                        }
                                        {...register("lastName", {
                                            required: intl.formatMessage({
                                                id: "Profile.errors.lastName",
                                                defaultMessage:
                                                    "Please enter your last name",
                                            }),
                                        })}
                                        placeholder={intl.formatMessage({
                                            id: "Profile.placeholders.lastName",
                                            defaultMessage: "Last Name",
                                        })}
                                        required
                                        type="text"
                                        disabled={isEmployee}
                                        error={errors.lastName}
                                    />
                                )}
                            </div>
                            <Input
                                label={
                                    <FormattedMessage
                                        id="Profile.formFields.emailAddress"
                                        defaultMessage="Email Address"
                                    />
                                }
                                placeholder={intl.formatMessage({
                                    id: "Profile.placeholders.email",
                                    defaultMessage: "Email",
                                })}
                                defaultValue={
                                    contactQuery.data.email ?? undefined
                                }
                                right={
                                    <ExtraInformationTooltip
                                        title={"UPDATE EMAIL ADDRESS"}
                                    >
                                        <FormattedMessage
                                            id="Profile.tooltips.email"
                                            defaultMessage="The ability to change your email/username has been temporarily disabled. If you need help, please contact our support team at {phone} or {email}."
                                            values={{
                                                phone: (
                                                    <a href="tel:971-254-3001">
                                                        971-254-3001
                                                    </a>
                                                ),
                                                email: (
                                                    <a
                                                        href={`mailto:${HOMEOWNER_ACCOUNTS_EMAIL}`}
                                                    >
                                                        {
                                                            HOMEOWNER_ACCOUNTS_EMAIL
                                                        }
                                                    </a>
                                                ),
                                            }}
                                        />
                                    </ExtraInformationTooltip>
                                }
                                disabled
                            />
                            <Input
                                label={
                                    <FormattedMessage
                                        id="Profile.formFields.password"
                                        defaultMessage="Current Password"
                                    />
                                }
                                right={
                                    <ExtraInformationTooltip
                                        title={"RESET PASSWORD"}
                                    >
                                        <FormattedMessage
                                            id="Profile.tooltips.password"
                                            defaultMessage="To change your password <a>click here</a>. You should receive instructions for resetting your password shortly. (You may need to check your spam folder.)"
                                            values={{
                                                a: (chunks: ReactNode) => (
                                                    <a
                                                        target="_blank"
                                                        href={getPasswordResetURL(
                                                            contactQuery.data
                                                                .email
                                                        )}
                                                        rel="noreferrer"
                                                    >
                                                        {chunks}
                                                    </a>
                                                ),
                                            }}
                                        />
                                    </ExtraInformationTooltip>
                                }
                                type="password"
                                value="this is a password"
                                disabled
                            />
                            <Select
                                label={
                                    <FormattedMessage
                                        id="Profile.formFields.country"
                                        defaultMessage="Country"
                                    />
                                }
                                defaultValue={
                                    contactQuery.data.accountInfo.country ??
                                    undefined
                                }
                                {...register("country", {
                                    required: intl.formatMessage({
                                        id: "Profile.errors.country",
                                        defaultMessage:
                                            "Please select a country",
                                    }),
                                })}
                                required
                                error={errors.country}
                            >
                                {countriesQuery.data.map(country => (
                                    <option
                                        key={country.abbr}
                                        value={country.abbr}
                                    >
                                        {country.name}
                                    </option>
                                ))}
                            </Select>

                            <Input
                                label={
                                    <FormattedMessage
                                        id="Profile.formFields.address"
                                        defaultMessage="Address 1"
                                    />
                                }
                                defaultValue={
                                    contactQuery.data.accountInfo.address1 ??
                                    undefined
                                }
                                placeholder={intl.formatMessage({
                                    id: "Profile.placeholders.address",
                                    defaultMessage:
                                        "House number and street name",
                                })}
                                {...register("address1", {
                                    required: intl.formatMessage({
                                        id: "Profile.errors.address",
                                        defaultMessage:
                                            "Please enter your address",
                                    }),
                                })}
                                required
                                error={errors.address1}
                            />
                            <Input
                                label={
                                    <FormattedMessage
                                        id="Profile.formFields.address2"
                                        defaultMessage="Address 2 (optional)"
                                    />
                                }
                                placeholder={intl.formatMessage({
                                    id: "Profile.placeholders.address2",
                                    defaultMessage:
                                        "Apartment, suite, unit, etc.",
                                })}
                                defaultValue={
                                    contactQuery.data.accountInfo.address2 ??
                                    undefined
                                }
                                {...register("address2")}
                                error={errors.address2}
                            />

                            <Input
                                label={
                                    <FormattedMessage
                                        id="Profile.formFields.city"
                                        defaultMessage="City"
                                    />
                                }
                                placeholder={intl.formatMessage({
                                    id: "Profile.placeholders.city",
                                    defaultMessage: "City",
                                })}
                                defaultValue={
                                    contactQuery.data.accountInfo.city ??
                                    undefined
                                }
                                {...register("city", {
                                    required: intl.formatMessage({
                                        id: "Profile.errors.city",
                                        defaultMessage:
                                            "Please enter your city",
                                    }),
                                })}
                                required
                                error={errors.city}
                            />
                            <div
                                className={classnames(
                                    styles.rowInputContainer,
                                    styles.small
                                )}
                            >
                                {states !== undefined && states.length > 0 && (
                                    <Select
                                        label={
                                            <FormattedMessage
                                                id="Profile.formFields.state"
                                                defaultMessage="State"
                                            />
                                        }
                                        placeholder={intl.formatMessage({
                                            id: "Profile.placeholders.select",
                                            defaultMessage: "Select an option",
                                        })}
                                        defaultValue={
                                            contactQuery.data.accountInfo
                                                .state ?? undefined
                                        }
                                        {...register("state", {
                                            required: intl.formatMessage({
                                                id: "Profile.errors.state",
                                                defaultMessage:
                                                    "Please select your state",
                                            }),
                                        })}
                                        required
                                        error={errors.state}
                                    >
                                        {states !== undefined &&
                                            states.length > 0 &&
                                            states.map(state => (
                                                <option
                                                    key={state.abbreviation}
                                                    value={state.abbreviation}
                                                >
                                                    {state.name}
                                                </option>
                                            ))}
                                    </Select>
                                )}
                                <Input
                                    label={
                                        <FormattedMessage
                                            id="Profile.formFields.zipCode"
                                            defaultMessage="ZIP Code"
                                        />
                                    }
                                    placeholder={intl.formatMessage({
                                        id: "Profile.placeholders.zipCode",
                                        defaultMessage: "Zipcode",
                                    })}
                                    defaultValue={
                                        contactQuery.data.accountInfo.zip ??
                                        undefined
                                    }
                                    {...register("zipCode", {
                                        required: intl.formatMessage({
                                            id: "Profile.errors.zipCode",
                                            defaultMessage:
                                                "Please select your zip code",
                                        }),
                                    })}
                                    required
                                    error={errors.zipCode}
                                />
                            </div>
                            <div className={styles.preferredLanguage}>
                                <Select
                                    label={
                                        <FormattedMessage
                                            id="Profile.formFields.preferredLanguage"
                                            defaultMessage="Preferred Language"
                                        />
                                    }
                                    placeholder={intl.formatMessage({
                                        id: "Profile.placeholders.select",
                                        defaultMessage: "Select an option",
                                    })}
                                    defaultValue={
                                        contactQuery.data.language ?? undefined
                                    }
                                    {...register("preferredLanguage", {
                                        required: intl.formatMessage({
                                            id: "Profile.errors.language",
                                            defaultMessage:
                                                "Please select your preferred language",
                                        }),
                                    })}
                                    required
                                    error={errors.preferredLanguage}
                                >
                                    {Object.values(LANGUAGES).map(language => {
                                        return (
                                            <option
                                                key={language.code}
                                                value={language.id}
                                            >
                                                {language.name}
                                            </option>
                                        )
                                    })}
                                </Select>
                            </div>

                            <div className={styles.contractInformation}>
                                <div className={styles.titleContainer}>
                                    <h3 className={styles.title}>
                                        <FormattedMessage
                                            id="Profile.contractInformation"
                                            defaultMessage="Contract Information"
                                        />
                                    </h3>
                                    <ExtraInformationTooltip
                                        title={"CONTACT INFORMATION"}
                                    >
                                        <FormattedMessage
                                            id="Profile.tooltips.accountInfo"
                                            defaultMessage="The contact information below has been pulled from the contract that you signed with Vacasa."
                                        />
                                    </ExtraInformationTooltip>
                                </div>
                                <Input
                                    label={
                                        <FormattedMessage
                                            id="Profile.formFields.nameOnContract"
                                            defaultMessage="Name on Contract"
                                        />
                                    }
                                    defaultValue={`${contactQuery.data.accountInfo.firstName} ${contactQuery.data.accountInfo.lastName}`}
                                    disabled
                                    right={
                                        <ExtraInformationTooltip
                                            title={"NAME ON CONTRACT"}
                                        >
                                            <FormattedMessage
                                                id="Profile.tooltips.nameOnContract"
                                                defaultMessage="This name was pulled from the contract that you signed with Vacasa. It is not editable."
                                            />
                                        </ExtraInformationTooltip>
                                    }
                                />
                                <Input
                                    label={
                                        <FormattedMessage
                                            id="Profile.formFields.mainPhone"
                                            defaultMessage="Main Phone"
                                        />
                                    }
                                    placeholder={intl.formatMessage({
                                        id: "Profile.placeholders.phone",
                                        defaultMessage: "Phone number",
                                    })}
                                    type="tel"
                                    defaultValue={
                                        contactQuery.data.phone ?? undefined
                                    }
                                    {...register("mainPhone", {
                                        required: intl.formatMessage({
                                            id: "Profile.errors.phone",
                                            defaultMessage:
                                                "Please enter your phone number",
                                        }),
                                    })}
                                    error={errors.mainPhone}
                                />
                            </div>
                            {contactMutation.isError && (
                                <p className={styles.errorMessage}>
                                    <FormattedMessage
                                        id="Profile.errors.errorPatching"
                                        defaultMessage="We had trouble updating your page. If the problem persists, please try again later"
                                    />
                                </p>
                            )}
                            <div className={styles.submitContainer}>
                                <BaseButton
                                    disabled={
                                        !isDirty || contactMutation.isLoading
                                    }
                                    isLoading={contactMutation.isLoading}
                                    loader={
                                        <ButtonLoader
                                            indicator="dot"
                                            indicatorCount={3}
                                            animation="blink"
                                        />
                                    }
                                >
                                    <FormattedMessage
                                        id="Profile.update"
                                        defaultMessage="Save Changes"
                                    />
                                </BaseButton>
                            </div>
                        </form>
                    </CardContent>
                </Card>
            )}
        </ListLayout>
    )
})

export { Profile }
