import { JSONAPIResource, Unit } from "@vacasa/owner-api-models"
import React, { useMemo, useState } from "react"
import { formatUnitAddess } from "../../utils/unit/unitAddress/unitAddress"
import styles from "../../sass/onboarding.module.scss"
import { useAvailability } from "../../hooks/availibility"
import { addDays, formatISO } from "date-fns"
import { ReactComponent as OnboardingUnit } from "../../assets/icon-onboarding-unit.svg"
import { FormattedMessage, useIntl } from "react-intl"
import { GeneralError } from "lib/components/GeneralError/GeneralError"
import { useTickets } from "hooks/ticket"
import { useContactId } from "hooks/user"

interface Props {
    unit: JSONAPIResource<Unit>
    className?: string
}

const UnitAddress: React.FunctionComponent<Props> = ({ unit, className }) => {
    const formattedAddress = formatUnitAddess(unit)

    const contactId = useContactId() ?? ""
    const [date] = useState(new Date())
    const [today, oneWeekLater] = useMemo(
        () => [
            formatISO(date, { representation: "date" }),
            formatISO(addDays(date, 6), { representation: "date" }),
        ],
        [date]
    )
    const availability = useAvailability(
        contactId,
        unit.id?.toString(),
        today,
        oneWeekLater
    )

    const photographyTicketQuery = useTickets(String(unit.id), ["132", "133"])


    const intl = useIntl()

    if (availability.isError) {
        return (
            <GeneralError
                customContainerStyle={styles.queryError}
                errorMessage={intl.formatMessage({
                    id: "Onboarding.error.availabilityQueryError",
                    defaultMessage:
                        "We were unable to fetch your availability.",
                })}
            />
        )
    }
    if (photographyTicketQuery.isError) {
        return (
            <GeneralError
                errorMessage={intl.formatMessage({
                    id: "Onboarding.error.photographyTicketQueryError",
                    defaultMessage:
                        "We were unable to fetch your photography tickets.",
                })}
                customContainerStyle={styles.queryError}
            />
        )
    }
    if (!availability.isSuccess || !photographyTicketQuery.isSuccess) {
        return <></>
    }

    const isPhotographiesComplete =
        photographyTicketQuery.data &&
        photographyTicketQuery.data.tickets.length > 0 &&
        photographyTicketQuery.data.tickets.every(ticket => {
            return ticket.attributes.isComplete
        })

    const rateExists = Object.values(availability.data).some(
        data => data.rate > 0
    )
    const descriptionComplete =
        unit?.attributes.description &&
        unit?.attributes.description2 &&
        unit?.attributes.unitTitle

    const OnboardingIndicator = () => {
        return isPhotographiesComplete &&
            rateExists &&
            descriptionComplete ? null : (
            <div className={styles.onboardingIndicator}>ONBOARDING</div>
        )
    }

    return (
        <div className={className}>
            <OnboardingUnit />
            <div className={styles.titleIndicatorAddress}>
                <div className={styles.titleAndIndicator}>
                    <h3>
                        {formattedAddress.addressOne ?? (
                            <FormattedMessage
                                id="Onboarding.toDoList.homeNotReady"
                                defaultMessage="Preparing your listing"
                            />
                        )}
                    </h3>
                    <div className={styles.iconContainer}>
                        <OnboardingIndicator />
                    </div>
                </div>
                <p>
                    {formattedAddress.addressTwo ?? (
                        <FormattedMessage
                            id="Onboarding.toDoList.homeNotReadyDescription"
                            defaultMessage="Stay tuned! We’ll let you know once your listing details and booking calendar are ready for editing."
                        />
                    )}
                </p>
            </div>
        </div>
    )
}

export default UnitAddress
