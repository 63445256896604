import { ReservationType } from "@vacasa/owner-api-models"
import { OccupancyMetadata } from "@vacasa/owner-api-models/src/MonthOccupancyMetadata"
import classNames from "classnames"
import { NIGHTS_OCCUPIED_MODAL_FEEDBACK_DISMISSED } from "constants/preferences.constants"
import { format } from "date-fns"
import { useOwnerFeatureFlag } from "hooks/owner-feature-flag"
import { useOwnerPreferences } from "hooks/owner-preferences"
import { useUserCurrencyCode } from "hooks/user"
import { FeatureFeedback } from "lib/components/FeatureFeedback/FeatureFeedback"
import Modal from "lib/components/Modal/Modal"
import SVGIcon from "lib/components/SVGIcon"
import { Tooltip } from "lib/components/Tooltip/Tooltip"
import { useCallback } from "react"
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl"
import { isFeatureFlagEnabled } from "utils/feature-flag"
import { ReactComponent as NetRentSVG } from "../../../../assets/icon-lrg-payment.svg"
import { ReactComponent as LostNetRentSVG } from "../../../../assets/icon-trending-down.svg"
import styles from "./NightsOccupiedModal.module.scss"
import { ReservationRow } from "./ReservationRow"

interface NightsOccupiedModalProps {
    closeNightsOccupiedModal: () => void
    occupancyData: OccupancyMetadata
    reservationType: ReservationType.OWNERHOLD | ReservationType.RESERVATION
}

const NightsOccupiedModal: React.FC<NightsOccupiedModalProps> = props => {
    const { closeNightsOccupiedModal, occupancyData, reservationType } = props
    const intl = useIntl()

    const content =
        reservationType === ReservationType.OWNERHOLD
            ? {
                  header: intl.formatMessage({
                      id: "Performance.nightsOccupied.ownerHoldsThisMonth",
                      defaultMessage: "Owner holds this month",
                  }),
                  totalNetRentTitle: intl.formatMessage({
                      id: "Performance.nightsOccupied.potentialImpact",
                      defaultMessage: "Potential impact",
                  }),
              }
            : {
                  header: intl.formatMessage({
                      id: "Performance.nightsOccupied.guestStaysThisMonth",
                      defaultMessage: "Guest stays this month",
                  }),
                  totalNetRentTitle: intl.formatMessage({
                      id: "Performance.nightsOccupied.totalNetRent",
                      defaultMessage: "Total net rent",
                  }),
              }

    const handleClose = useCallback(() => {
        closeNightsOccupiedModal()
    }, [closeNightsOccupiedModal])

    const currency = useUserCurrencyCode()

    // Booking Probability Legend feature flag
    const upcomingListBookingProbabilityFeatureFlag = useOwnerFeatureFlag(
        "owner-portal-upcoming-list-booking-probability"
    )

    const nightsOccupiedModalFeedbackFeatureFlag = useOwnerFeatureFlag(
        "nights-occupied-modal-feedback"
    )

    const feedbackEnabled = isFeatureFlagEnabled(
        nightsOccupiedModalFeedbackFeatureFlag
    )

    const showUpcomingListBookingProbabilityEnabled = isFeatureFlagEnabled(
        upcomingListBookingProbabilityFeatureFlag
    )

    const ownerPreferences = useOwnerPreferences()
    const nightsOccupiedModalFeedbackDismissedPreference =
        ownerPreferences.data?.find(
            preference =>
                preference.id === NIGHTS_OCCUPIED_MODAL_FEEDBACK_DISMISSED
        )

    const upcomingReservations = occupancyData.reservations.filter(
        r => r.startDate && r.startDate >= format(new Date(), "yyyy-MM-dd")
    )

    const previousReservations = occupancyData.reservations
        .filter(
            r => r.startDate && r.startDate < format(new Date(), "yyyy-MM-dd")
        )
        .sort((a, b) => ((a.startDate ?? "") > (b.startDate ?? "") ? -1 : 1))

    return (
        <Modal
            onCloseHandler={handleClose}
            modalPanelStyleOverride={styles.modalContainer}
        >
            {feedbackEnabled && (
                <FeatureFeedback
                    featureId="nights-occupied-modal"
                    ratingBody={intl.formatMessage({
                        id: "Performance.floatingNotification.nightsOccupiedFeedback",
                        defaultMessage:
                            "You can now see all of the current month's reservations, owner holds, and their impact to your revenue.\n\nDo you find this summary helpful?",
                    })}
                    commentsBody={intl.formatMessage({
                        id: "Performance.floatingNotification.shareMore",
                        defaultMessage:
                            "Thanks for the feedback! Care to share more?",
                    })}
                    requiredInteractionCount={3}
                    title={intl.formatMessage({
                        id: "Performance.floatingNotification.nightsOccupiedFeedbackTitle",
                        defaultMessage: "New! Monthly summary",
                    })}
                    userPreference={
                        nightsOccupiedModalFeedbackDismissedPreference
                    }
                    pointerPosition="top-right"
                    wrapper={children => (
                        <div className={styles.notificationWrap}>
                            {children}
                        </div>
                    )}
                />
            )}
            <div className={styles.content}>
                <div className={styles.header}>
                    <div className={styles.header__title}>{content.header}</div>
                    {reservationType === ReservationType.OWNERHOLD && (
                        <Tooltip
                            title={intl.formatMessage({
                                id: "Performance.nightsOccupied.ownerHoldTooltipTitle",
                                defaultMessage: "Revenue impact",
                            })}
                            body={intl.formatMessage({
                                id: "Performance.nightsOccupied.ownerHoldTooltipMessage",
                                defaultMessage:
                                    "We estimate net rent based on the current rate for the nights reserved with an owner hold. Holds labeled with a lightning bolt indicate there’s a high probability of being booked by a guest if you cancel your hold.",
                            })}
                            customTooltipStyle={styles.customTooltip}
                        >
                            <SVGIcon
                                svg="icon-feather-info"
                                colorName="dusk"
                                className={classNames(
                                    "w-16.5 h-16.5 zero-margin cursor-pointer",
                                    styles.infoIcon
                                )}
                            />
                        </Tooltip>
                    )}
                </div>
                <div className={styles.body}>
                    <div className={styles.totalNights}>
                        <div className={styles.totalNights__number}>
                            {occupancyData.total}
                        </div>
                        <div className={styles.totalNights__text}>
                            {occupancyData.total === 1 ? (
                                <FormattedMessage
                                    id="Performance.nightsOccupied.night"
                                    defaultMessage="Night"
                                />
                            ) : (
                                <FormattedMessage
                                    id="Performance.nightsOccupied.nights"
                                    defaultMessage="Nights"
                                />
                            )}
                        </div>
                    </div>
                    <div className={styles.totalNetRent}>
                        <div className={styles.totalNetRent__icon}>
                            {reservationType === ReservationType.OWNERHOLD ? (
                                <LostNetRentSVG />
                            ) : (
                                <NetRentSVG />
                            )}
                        </div>
                        <div className={styles.totalNetRent__title}>
                            {content.totalNetRentTitle}
                        </div>
                        <div className={styles.totalNetRent__body}>
                            <FormattedNumber
                                value={occupancyData.netRent}
                                currency={currency}
                                // eslint-disable-next-line react/style-prop-object
                                style="currency"
                            />
                        </div>
                    </div>
                    <div className={styles.reservations}>
                        {upcomingReservations.length > 0 && (
                            <div>
                                <div className={styles.reservations__title}>
                                    <FormattedMessage
                                        id="Performance.nightsOccupied.upcoming"
                                        defaultMessage="Upcoming"
                                    />
                                </div>
                                {upcomingReservations.map(reservation => (
                                    <ReservationRow
                                        key={reservation.id}
                                        reservation={reservation}
                                        bookingProbabilityEnabled={
                                            showUpcomingListBookingProbabilityEnabled
                                        }
                                        currency={currency}
                                    />
                                ))}
                            </div>
                        )}
                        {previousReservations.length > 0 && (
                            <div>
                                <div
                                    className={
                                        upcomingReservations.length > 0
                                            ? classNames(
                                                  styles.reservations__title,
                                                  styles.reservations__border
                                              )
                                            : styles.reservations__title
                                    }
                                >
                                    <FormattedMessage
                                        id="Performance.nightsOccupied.previous"
                                        defaultMessage="Previous"
                                    />
                                </div>
                                <div className={styles.reservations__body}>
                                    {previousReservations.map(reservation => (
                                        <ReservationRow
                                            key={reservation.id}
                                            reservation={reservation}
                                            bookingProbabilityEnabled={
                                                showUpcomingListBookingProbabilityEnabled
                                            }
                                            currency={currency}
                                        />
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export { NightsOccupiedModal }
