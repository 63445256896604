import { W9Data } from "../types"

export const defaultW9Data: W9Data = {
    taxClassification: {
        type: "",
        llcType: "",
    },
    taxInfo: {
        entityName: "",
        businessName: "",
        taxIDType: "",
        SSN: "",
        EIN: "",
        entityType: "",
    },
    mailingAddress: {
        addressLine1: "",
        addressLine2: "",
        city: "",
        stateOrProvince: "",
        postalCode: "",
    },
    agreeAndSign: {
        agreeToTerms: false,
        signature: "",
    },
}
