import { RefObject, useState, useEffect, useCallback } from "react"
import useEventListener from "./useEventListener"

export interface Size {
    width: number
    height: number
}

/**
 * Get the width and the height of an HTML element.
 * Dimensions are updated when resizing the window.
 * https://usehooks-typescript.com/react-hook/use-element-size
 * @param elementRef target element
 * @returns {Size} width and height of the element
 */
function useElementSize<T extends HTMLElement = HTMLDivElement>(
    elementRef: RefObject<T>
): Size {
    const [size, setSize] = useState<Size>({
        width: 0,
        height: 0,
    })

    // Prevent too many rendering using useCallback
    const updateSize = useCallback(() => {
        const node = elementRef?.current
        if (node) {
            setSize({
                width: node.offsetWidth || 0,
                height: node.offsetHeight || 0,
            })
        }
    }, [elementRef])

    // Initial size on mount
    useEffect(() => {
        updateSize()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEventListener("resize", updateSize)

    return size
}

export default useElementSize
