import {useMutation, UseMutationResult, useQueryClient} from "react-query";
import {PostTicketResponse} from "@vacasa/owner-api-models";
import type {AxiosError} from "axios";
import {reopenTicket, ReopenTicketRequestData} from "./reopenTicket";
import {useContactId} from "../user";
import {TICKETS_QUERY_KEY} from "./useTickets";



interface ReopenTicketMutationParam {
    unitId:string
    ticketId:number
    reopenTicketRequest:ReopenTicketRequestData
}

const useReopenTicketMutation = ():UseMutationResult<
    PostTicketResponse,
    AxiosError,
    ReopenTicketMutationParam,
    void
> => {
    const contactId = useContactId()
    const queryClient = useQueryClient()

    return useMutation<
        PostTicketResponse,
        AxiosError,
        ReopenTicketMutationParam,
        void
    >(
        (
            reopenTicketMutationParam:ReopenTicketMutationParam
        ):Promise<PostTicketResponse>=>
            reopenTicket({
                contactId:contactId ?? "",
                unitId: reopenTicketMutationParam.unitId,
                ticketId: reopenTicketMutationParam.ticketId,
                reopenTicketCommentData:reopenTicketMutationParam.reopenTicketRequest,
            }),
        {
            onSuccess: async data => {
                await queryClient.invalidateQueries([
                    TICKETS_QUERY_KEY,
                    data.attributes.unitId,
                ])
            },
        }
    )

}

export {useReopenTicketMutation}