import { UnitAvailabilityDay } from "@vacasa/owner-api-models"
import React from "react"
import { Zap } from "react-feather"
import styles from "./DayBookingProbability.module.scss"
import classNames from "classnames"

type DayBookingProbabilityProps = {
    size: number
    availabilityDay: UnitAvailabilityDay
    visible: boolean
}

const DayBookingProbability: React.FC<DayBookingProbabilityProps> = ({
    size,
    availabilityDay,
    visible,
}) => {
    return (
        <Zap
            size={size}
            className={classNames(styles.zap, "zap")}
            style={{
                visibility:
                    visible && availabilityDay.isHighBookingProbability
                        ? "visible"
                        : "hidden",
                padding: size / 5,
                left: size / 5,
            }}
        />
    )
}

export { DayBookingProbability }
