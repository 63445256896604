import { RangeInput } from "lib/components/RangeInput/RangeInput"
import React, {
    CSSProperties,
    ChangeEvent,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react"

import styles from "./MinRateInput.module.scss"
import classNames from "classnames"
import { YourMinRate } from "./YourMinRate"

export interface MinRateInputProps {
    defaultValue?: number
    max: number
    min: number
    onChange: (value: number) => void
    onThumbDown?: () => void
    onThumbUp?: () => void
    value: number
}

export const MinRateInput: React.FC<MinRateInputProps> = ({
    defaultValue,
    max,
    min,
    onChange,
    onThumbDown,
    onThumbUp,
    value,
}) => {
    const onInputThumbDown = useCallback(() => {
        setShowTooltip(true)
        return onThumbDown?.()
    }, [onThumbDown])
    const onInputThumbUp = useCallback(() => {
        setShowTooltip(false)
        return onThumbUp?.()
    }, [onThumbUp])

    const onInputChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setShowTooltip(true)
            return onChange(Number(e.target.value))
        },
        [onChange]
    )

    const inputRef = useRef<HTMLInputElement | null>(null)

    const calculateMinRateX = useCallback(
        (val: number): number | undefined => {
            if (!inputRef.current) {
                return undefined
            }

            const ratio = (val - min) / (max - min)
            const thumbWidth = 32
            const thumbHalfWidth = thumbWidth / 2
            const width =
                inputRef.current.getBoundingClientRect().width - thumbWidth

            return ratio * width + thumbHalfWidth
        },
        [max, min]
    )

    const getTooltipStyle = useCallback((left: number): CSSProperties => {
        return {
            left,
        }
    }, [])
    const minRateX = calculateMinRateX(value)

    const [yourMinRateX, setYourMinRateX] = useState<number | undefined>()
    useEffect(() => {
        if (defaultValue !== undefined) {
            setYourMinRateX(calculateMinRateX(defaultValue))
        }
    }, [inputRef, defaultValue, calculateMinRateX])

    const [showTooltip, setShowTooltip] = useState(false)

    return (
        <div className={classNames(styles.minRateInput)}>
            <RangeInput
                currency={true}
                defaultValue={defaultValue}
                inputRef={inputRef}
                max={max}
                min={min}
                onChange={onInputChange}
                onMouseDown={onInputThumbDown}
                onMouseUp={onInputThumbUp}
                onTouchStart={onInputThumbDown}
                onTouchEnd={onInputThumbUp}
                onReset={() => setShowTooltip(false)}
                value={value}
                markers={
                    <>
                        {defaultValue && yourMinRateX ? (
                            <div
                                style={{
                                    position: "absolute",
                                    left: yourMinRateX,
                                    bottom: 10,
                                }}
                            >
                                <YourMinRate />
                            </div>
                        ) : (
                            <></>
                        )}
                        {showTooltip && minRateX && (
                            <div
                                className={classNames(styles.tooltipContainer)}
                                style={getTooltipStyle(minRateX)}
                            >
                                <div className={classNames(styles.tooltip)}>
                                    <span>${value}</span>
                                </div>
                                <div className={styles.chevron} />
                            </div>
                        )}
                    </>
                }
            />
        </div>
    )
}
