import React from "react"

import { ADMIN_URL } from "Environment"
import { format, isValid, parseISO } from "date-fns"
import { useLegacyViewToken } from "hooks/legacy-view-token"
import { useW9TaxSubmission } from "hooks/taxes-w9-submission"
import { observer } from "mobx-react"
import { FormattedMessage } from "react-intl"
import { Card, CardContent } from "../../components/Card"
import Accordion from "../../lib/components/Accordion/Accordion"
import { useRootService } from "../../services"
import TaxAccordionPartialBottom, {
    SlugsProps,
} from "./TaxAccordionPartialBottom"

const FormsFromYou: React.FC = (): JSX.Element => {
    const rootService = useRootService()
    const { segmentService } = rootService
    const w9TaxSubmissionQuery = useW9TaxSubmission()
    const viewToken = useLegacyViewToken()

    const forms = [
        {
            title: "Form W-9",
            whoItsFor: (
                <FormattedMessage
                    id="Taxes.w9whoFor"
                    defaultMessage="Provide if you are a U.S. citizen or resident alien who has one of the following types of taxpayer identification numbers: SSN, EIN, or ITIN. Nonresident aliens should not complete this form."
                    description="Explanation of who should be filling out a W-9 tax form"
                />
            ),
            irsInfoLink: "https://www.irs.gov/pub/irs-pdf/iw9.pdf",
            buttonText: (
                <FormattedMessage
                    id="Taxes.submitW9"
                    defaultMessage="Submit W-9 Online"
                />
            ),
            w9check: true,
        },

        {
            title: (
                <FormattedMessage
                    id="Taxes.w8ECItitle"
                    defaultMessage="Form W-8ECI"
                />
            ),
            whoItsFor: (
                <FormattedMessage
                    id="Taxes.w8ECIwhoFor"
                    defaultMessage="Provide if you are a Foreign individual, nonresident alien, or entity who currently possesses a SSN, EIN, or ITIN and will be reporting this income by filing an annual tax return with the IRS."
                    description="Explanation of who should be filling out a W-8ECI tax form"
                />
            ),
            irsInfoLink: "https://www.irs.gov/pub/irs-pdf/iw8eci.pdf",
            irsFormLink: "https://www.irs.gov/pub/irs-pdf/fw8eci.pdf",
        },

        {
            title: (
                <FormattedMessage
                    id="Taxes.w8BENtitle"
                    defaultMessage="Form W-8BEN"
                />
            ),
            whoItsFor: (
                <FormattedMessage
                    id="Taxes.w8BENwhoFor"
                    defaultMessage="Provide if you are a Foreign individual or nonresident alien who does not file an annual tax return with the IRS or would like to claim income tax treaty benefits between the U.S. and your country of residence. Since treaty benefits do not apply to rental income, individuals providing Form W‐8BEN are subject to foreign withholding, deducted from gross income, at a rate of 30%."
                    description="Explanation of who should be filling out a W-8BEN tax form"
                />
            ),
            irsInfoLink: "https://www.irs.gov/pub/irs-pdf/iw8ben.pdf",
            irsFormLink: "https://www.irs.gov/pub/irs-pdf/fw8ben.pdf",
        },

        {
            title: (
                <FormattedMessage
                    id="Taxes.w8BENEtitle"
                    defaultMessage="Form W-8BEN-E"
                />
            ),
            whoItsFor: (
                <FormattedMessage
                    id="Taxes.w8BENEwhoFor"
                    defaultMessage="Provide if you are a Foreign recipient of income, who is not an individual, and does not file an annual tax return with the IRS. Income tax treaty benefits can also be claimed on Part III of this form. Since treaty benefits do not apply to rental income, recipients providing Form W-8BEN-E are subject to foreign withholding, deducted from gross income, at a rate of 30%."
                    description="Explanation of who should be filling out a W-8BEN-E tax form"
                />
            ),
            irsInfoLink: "https://www.irs.gov/pub/irs-pdf/iw8bene.pdf",
            irsFormLink: "https://www.irs.gov/pub/irs-pdf/fw8bene.pdf",
        },
    ]

    function renderAccordions(forms: SlugsProps[]): JSX.Element[] | null {
        const accordions = forms.map((form: SlugsProps, i: number) => {
            const isW9 = form.w9check === true
            const partials = {
                top: (
                    <div>
                        <p className="large zero-margin">{form.title}</p>
                        <p className="small zero-margin dusk-lighter">
                            {isW9 &&
                            w9TaxSubmissionQuery.isSuccess &&
                            w9TaxSubmissionQuery.data.signedDate !== null &&
                            isValid(
                                parseISO(w9TaxSubmissionQuery.data.signedDate)
                            )
                                ? `Submitted on ${format(
                                      parseISO(
                                          w9TaxSubmissionQuery.data.signedDate
                                      ),
                                      "MMMM dd yyyy"
                                  )}`
                                : null}
                        </p>
                    </div>
                ),
                bottom: (
                    <TaxAccordionPartialBottom
                        slugs={form}
                        w9Url={
                            isW9 && viewToken.isSuccess
                                ? `${ADMIN_URL}admin/ownerW9.php?viewToken=${viewToken.data}`
                                : null
                        }
                        w9Submitted={
                            isW9 && w9TaxSubmissionQuery.isSuccess
                                ? w9TaxSubmissionQuery.data.hasSubmittedW9
                                : false
                        }
                        trackClickEvent={
                            isW9 ? segmentService.clickTrack : null
                        }
                    />
                ),
            }
            return <Accordion key={i} partials={partials} />
        })
        return accordions
    }

    return (
        <Card>
            <CardContent>
                <h3 className="type-heading-medium">
                    <FormattedMessage
                        id="Taxes.yourInformation"
                        defaultMessage="Provide your taxpayer information"
                    />
                </h3>

                <div className="tax-forms-from-you-container">
                    <p>
                        <FormattedMessage
                            id="Taxes.mustSubmit"
                            defaultMessage="We must have one of the following taxpayer forms on file before we can provide your tax preparation documents. Please complete only the form that best describes your taxpayer status with the IRS. If your taxpayer information has changed, please submit a new form."
                            description="Requirements before tax preparations can be done"
                        />
                    </p>
                    {renderAccordions(forms)}
                </div>
            </CardContent>
        </Card>
    )
}

export default observer(FormsFromYou)
