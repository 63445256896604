import { authAxios } from "../../api/authAxios"

type LegacyViewRequest = {
    contactId: string
    signal?: AbortSignal
}

type LegacyViewTokenResponse = {
    data: {
        token: string
    }
}

const fetchLegacyViewToken = ({
    contactId,
    signal,
}: LegacyViewRequest): Promise<string> =>
    authAxios
        .get<LegacyViewTokenResponse>(
            `v1/legacy/owners/${contactId}/view-token`,
            { signal }
        )
        .then(response => response.data.data.token)

export { fetchLegacyViewToken }
