const navigation = {
    onboarding: "Setup",
    supportHub: "Support",
    betaonboarding: "Switch to Onboarding",
    calendar: "Kalender",
    performance: "Auslastung",
    statements: "Abrechnungen",
    maintenance: "Wartung",
    homeInfo: "Informationen zur Immobilie",
    taxes: "Steuern",
    settings: "Einstellungen",
    new: "Neu",
    navigationConfirmationDialog: {
        title: "Before you go",
        message: "Looks like you've made unsaved changes on this page",
        stayButton: "Keep Editing",
        leaveButton: "Discard Changes",
    },
    ownerReferralCard: {
        title: "Refer a friend",
        body: "You get $500, they get $500.",
        action: "Learn more",
    },
    dashboard: "Dashboard",
}

export default navigation
