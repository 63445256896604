import * as React from "react"
import { AlertTriangle } from "react-feather"
import { MinRateMessage } from "./MinRateMessage"
import styles from "./UnitMinRateNotification.module.scss"

interface MainSectionProps {
    minRate: number
    impactRevenue: number
    currency: string
}

export const MainSection = (props: MainSectionProps): JSX.Element => {
    return (
        <div className={styles.iconAndMessage}>
            <div className={styles.iconCircle}>
                <AlertTriangle
                    className={styles.alertnessIcon}
                    data-testid="main-section-icon"
                />
            </div>

            <div>
                <MinRateMessage
                    minRate={Number(props.minRate.toFixed(2))}
                    impactRevenue={Number(props.impactRevenue.toFixed(2))}
                    currency={props.currency}
                />
            </div>
        </div>
    )
}
