import { ownerSupportPhoneNum } from "Constants"
import { Primary } from "lib/components/Buttons/BaseButton"
import Modal from "lib/components/Modal/Modal"
import React, { ReactNode } from "react"
import { FormattedMessage } from "react-intl"
import { formatPhone } from "utils/phone/formatPhone"
import { ReactComponent as MultiHouseSVG } from "../../../assets/multi-house.svg"
import { ReactComponent as NotificationSVG } from "../../../assets/notification-icon-alertness.svg"
import styles from "./switch-accounts-modal.module.scss"
import { logout } from "utils/auth"

const SwitchAccountsModal = (): JSX.Element | null => {
    const [modalDismissed, setModalDismissed] = React.useState(false)

    const handleClose = React.useCallback(() => {
        setModalDismissed(true)
    }, [])

    const formattedPhone = formatPhone(ownerSupportPhoneNum)

    if (modalDismissed) return null

    return (
        <Modal
            onCloseHandler={handleClose}
            closeButtonStyleOverride={styles.close}
            modalPanelStyleOverride={styles.modalContainer}
        >
            <div
                data-testid="switch-account-modal-icon"
                className={styles.iconContainer}
            >
                <MultiHouseSVG className={styles.multiHouseIcon} />
            </div>
            <div className={styles.container}>
                <div className={styles.notificationContainer}>
                    <NotificationSVG />
                    <div className={styles.notification}>
                        <FormattedMessage
                            id="Calendar.switchAccounts.notification"
                            defaultMessage="This reservation is associated with another account"
                        />
                    </div>
                </div>
                <div className={styles.description}>
                    <FormattedMessage
                        id="Calendar.switchAccounts.description"
                        defaultMessage="Please login to the appropriate homeowner account and click the new booking email notification to view the new reservation details."
                    />
                </div>
                <div className={styles.buttons}>
                    <Primary
                        className={styles.switchAccountsButton}
                        onClick={logout}
                        dataTestId="switch-accounts-button"
                    >
                        <FormattedMessage
                            id="Calendar.switchAccounts.button"
                            defaultMessage="Switch Accounts"
                        />
                    </Primary>
                    <Primary
                        className={styles.maybeLaterButton}
                        typeOf={"link"}
                        onClick={handleClose}
                        dataTestId="switch-accounts-maybe-later-button"
                    >
                        <FormattedMessage
                            id="Calendar.switchAccounts.maybeLater"
                            defaultMessage="Maybe Later"
                        />
                    </Primary>
                </div>
                <div className={styles.descriptionBottomContainer}>
                    <p className={styles.descriptionBottom}>
                        <FormattedMessage
                            id="Calendar.switchAccounts.descriptionButtom"
                            defaultMessage="Interested in linking your accounts into one login? Call <strong>Owner Support</strong> at {phone} to get it set-up."
                            values={{
                                strong: (chunks: ReactNode) => (
                                    <strong>{chunks}</strong>
                                ),
                                phone: (
                                    <a
                                        className={styles.phoneNumber}
                                        href={`tel:${formattedPhone}`}
                                    >
                                        {formattedPhone}
                                    </a>
                                ),
                            }}
                        />
                    </p>
                </div>
            </div>
        </Modal>
    )
}

export { SwitchAccountsModal }
