import { JSONAPIResource, Room } from "@vacasa/owner-api-models"
import { authAxios } from "api/authAxios"
import { BedModel } from "models/Bed"
import { RoomModel } from "models/Room"

export const fetchUnitRooms = async (args: {
    contactId: string
    unitId: string
    signal?: AbortSignal
}): Promise<RoomModel[]> => {
    return authAxios
        .get<{ data: JSONAPIResource<Room>[] }>(
            `v1/owners/${args.contactId}/units/${args.unitId}/rooms`,
            {
                signal: args.signal,
            }
        )
        .then(response => response.data)
        .then(response => {
            const rooms = response.data.map<RoomModel>(mapToRoomModel)

            // Order rooms alphabetically, with bedrooms at the front, other at the end
            const BEDROOM_TYPE = 8
            const OTHER_TYPE = 9
            const bedrooms = rooms
                .filter(room => room.attributes.roomType?.id === BEDROOM_TYPE)
                .sort(
                    (a, b) =>
                        (a.attributes.roomNumber ?? 0) -
                        (b.attributes.roomNumber ?? 0)
                )

            const otherRooms = rooms
                .filter(room => room.attributes.roomType?.id !== BEDROOM_TYPE)
                .sort((a, b) => {
                    if (a.attributes.roomType?.id === OTHER_TYPE) return 1
                    if (b.attributes.roomType?.id === OTHER_TYPE) return -1

                    return (a.attributes.roomType?.name ?? "").localeCompare(
                        b.attributes.roomType?.name ?? ""
                    )
                })

            return [...bedrooms, ...otherRooms]
        })
}

const mapToRoomModel = (room: JSONAPIResource<Room>): RoomModel => ({
    type: "unit-room",
    id: room.id ? String(room.id) : undefined,
    attributes: {
        beds: room.attributes.beds.map<BedModel>(bed => ({
            ...bed,
            isNewBed: false,
        })),
        roomNumber: room.attributes.roomNumber,
        roomType: room.attributes.roomType,
    },
})
