import { ChangeEvent, FormEvent, ReactNode, useEffect, useState } from "react"
import { useIntl } from "react-intl"
import BaseInput from "../../Input/BaseInput"
import styles from "./CreateTicketDetails.module.scss"
import { ReactComponent as ArrowLeft } from "../../../../assets/icon-arrow-left.svg"
import { ReactComponent as Edit } from "../../../../assets/edit.svg"
import { TicketDetails } from "utils/flyout/maintenanceFlyoutUtil"
import {
    DisplayStatus,
    TicketCategoryId,
    TicketResponse,
} from "@vacasa/owner-api-models"
import classnames from "classnames"
import { NewButton } from "lib/components/Button"
import { useTicketsMutation } from "hooks/ticket"
import errorService from "services/error.service"
import { isUnauthorizedOrCancelledRequest } from "utils/error/error"
import LoggingService from "services/logging/logging.service"
import {
    trackTicketRequestCancelled,
    trackSubmissionContinued,
    trackTicketCategoryEdited,
    trackTicketCreated,
    trackTicketSubmitted,
    trackCreateTicketBackArrowClicked,
    trackTicketCleared,
} from "services/segment/supportHub/supportHubTracking"
import { useCurrentUnit } from "hooks/units"

interface CreateTicketDetailsProps {
    ticketDetails: TicketDetails
    updateCategory: (categoryId: TicketCategoryId | undefined) => void
    getIcon: (categoryId: string) => ReactNode
    showLeavingAlert: boolean
    hideLeavingAlert: () => void
    updateTicketDetails: (name: string, value: string) => void
    updateShowTicketSubmitted: (shouldShowTicketSubmitted: boolean) => void
    updateShowFlyout: (shouldShowFlyout: boolean) => void
    triggerDetailsBox: (ticket: TicketResponse) => void
}

interface FormState {
    unitId: string
    title: string
    description: string
    dispositionId: number | undefined
}

const CreateTicketDetails = (props: CreateTicketDetailsProps): JSX.Element => {
    const {
        ticketDetails,
        updateCategory,
        getIcon,
        showLeavingAlert,
        hideLeavingAlert,
        updateTicketDetails,
        updateShowTicketSubmitted,
        updateShowFlyout,
        triggerDetailsBox,
    } = props

    const { unitId, unit } = useCurrentUnit()

    const intl = useIntl()
    const createTicketMutation = useTicketsMutation()
    const emptyFormState: FormState = {
        title: "",
        description: "",
        unitId: unitId ?? "",
        dispositionId: ticketDetails.dispositionId,
    }
    const savedFormState: FormState = {
        title: ticketDetails.title ?? "",
        description: ticketDetails.description ?? "",
        unitId: unitId ?? "",
        dispositionId: ticketDetails.dispositionId,
    }
    const [isValid, setIsValid] = useState<boolean>(false)
    const [formFields, setFormFields] = useState<FormState>(savedFormState)

    useEffect(() => {
        const handleFormValidation = () => {
            const { title, description, unitId } = formFields
            const titleIsValid = title.length > 0 && title.length <= 160
            const descriptionIsValid =
                description.length > 0 && description.length <= 2000
            const isValid = !!unitId && titleIsValid && descriptionIsValid
            setIsValid(isValid)
        }
        handleFormValidation()
    }, [formFields])

    const onFormSubmit = async (e: FormEvent) => {
        e.preventDefault()
        createTicketMutation.mutate(
            {
                unitId: formFields.unitId,
                ticketRequest: {
                    title: formFields.title,
                    description: formFields.description,
                    dispositionId: String(formFields.dispositionId ?? ""),
                },
            },
            {
                onError: error => {
                    if (!isUnauthorizedOrCancelledRequest(error)) {
                        LoggingService.error({
                            message: "Creating maintenance ticket failed",
                            error,
                            tags: {
                                unitID: `${formFields.unitId}`,
                            },
                        })
                    }
                    errorService.setErrorMsg(
                        intl.formatMessage({
                            id: "Maintenance.failedCreateError",
                            defaultMessage:
                                "We were unable to save your maintenance request. Please check your selection and try again.",
                        })
                    )
                    errorService.displayError()
                    updateShowFlyout(false)
                },
                onSuccess: data => {
                    updateShowFlyout(false)
                    const newTicket: TicketResponse = {
                        type: "ticket",
                        id: data?.id ?? "",
                        attributes: {
                            description: data?.attributes.description ?? "",
                            title: data?.attributes.title ?? "",
                            status: data?.attributes.status ?? 1,
                            isComplete: false,
                            displayStatus: DisplayStatus.Submitted,
                            dispositionId: data?.attributes.dispositionId,
                            unitId: data?.attributes.unitId ?? "",
                            createdBy: data?.attributes.createdBy ?? "",
                            creationDate: data?.attributes.creationDate ?? "",
                            completedDate: null,
                            requestedBy: null,
                            assignedToFirstName: "",
                            assignedToLastName: "",
                            assignedToEmailAddress: "",
                            assignedToPhone: null,
                            comments: null,
                            canBeReopened:false,
                            unreadComments:0,
                            lastReopen:null
                        },
                    }
                    if (data?.attributes.unitId === unitId) {
                        triggerDetailsBox(newTicket)
                        updateShowTicketSubmitted(true)
                    }

                    trackTicketCreated({
                        // eslint-disable-next-line camelcase
                        ticket_id: data?.id ?? "",
                        // eslint-disable-next-line camelcase
                        category_id: ticketDetails.categoryId,
                        // eslint-disable-next-line camelcase
                        disposition_id: String(
                            data.attributes.dispositionId ?? ""
                        ),
                        title: data.attributes.title,
                        description: data.attributes.description,
                    })
                },
            }
        )
        trackTicketSubmitted({
            // eslint-disable-next-line camelcase
            category_id: ticketDetails.categoryId,
            // eslint-disable-next-line camelcase
            disposition_id: String(formFields.dispositionId),
            title: formFields.title,
            description: formFields.description,
        })
    }

    const onInputChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target
        setFormFields(prevState => ({
            ...prevState,
            [name]: value,
        }))
        updateTicketDetails(name, value)
    }

    const showClearButton = formFields.title || formFields.description

    const onClear = () => {
        setFormFields(emptyFormState)
        updateTicketDetails("title", "")
        updateTicketDetails("description", "")
        trackTicketCleared()
    }

    const onCategoryEdit = () => {
        if (ticketDetails.categoryId === TicketCategoryId.Other) {
            updateCategory(TicketCategoryId.Root)
        } else {
            updateCategory(ticketDetails.groupId)
        }
    }

    return (
        <>
            {showLeavingAlert ? (
                <div className={styles.ticketDetailsContent}>
                    <div
                        className={styles.arrowLeft}
                        onClick={() => {
                            hideLeavingAlert()
                            trackCreateTicketBackArrowClicked({
                                location: "leaving_alert",
                            })
                        }}
                    >
                        <ArrowLeft />
                    </div>
                    <div className={styles.leavingAlertContent}>
                        <div className={styles.title}>
                            {intl.formatMessage({
                                id: "Maintenance.areYouSure",
                                defaultMessage: "Are you sure?",
                            })}
                        </div>
                        <div className={styles.description}>
                            {intl.formatMessage({
                                id: "Maintenance.leavingAlertText",
                                defaultMessage:
                                    "Leaving the submission form will cause you to lose any data you may have entered.",
                            })}
                        </div>
                        <NewButton
                            className={styles.button}
                            onClick={() => {
                                hideLeavingAlert()
                                trackSubmissionContinued()
                            }}
                            variant="primary"
                        >
                            {intl.formatMessage({
                                id: "Maintenance.continueWithSubmission",
                                defaultMessage: "Continue with Submission",
                            })}
                        </NewButton>
                        <NewButton
                            className={classnames(styles.button, styles.cancel)}
                            variant="secondary"
                            onClick={() => {
                                updateShowFlyout(false)
                                trackTicketRequestCancelled()
                            }}
                        >
                            {intl.formatMessage({
                                id: "Maintenance.cancelRequest",
                                defaultMessage: "Cancel Request",
                            })}
                        </NewButton>
                    </div>
                </div>
            ) : (
                <div className={styles.ticketDetailsContent}>
                    <div
                        className={styles.arrowLeft}
                        onClick={() => {
                            onCategoryEdit()
                            trackCreateTicketBackArrowClicked({
                                location: "request_form",
                            })
                        }}
                    >
                        <ArrowLeft />
                    </div>
                    <form
                        className={styles.newRequestForm}
                        onSubmit={onFormSubmit}
                    >
                        <div
                            className={styles.unitName}
                            data-testid="new-request-unit-name"
                        >
                            {unit?.attributes.name ?? ""}
                        </div>
                        <div className={styles.subtitle}>
                            {ticketDetails.groupName}
                        </div>
                        <div className={styles.category}>
                            <div className={styles.icon}>
                                {ticketDetails.categoryId &&
                                    getIcon(ticketDetails.categoryId)}
                            </div>
                            <div className={styles.name}>
                                {ticketDetails.displayName ?? ""}
                            </div>
                            <div className={styles.description}>
                                {ticketDetails.categoryDescription ?? ""}
                            </div>
                            <div
                                className={styles.edit}
                                onClick={() => {
                                    onCategoryEdit()
                                    trackTicketCategoryEdited({
                                        // eslint-disable-next-line camelcase
                                        category_id: ticketDetails.categoryId,
                                        // eslint-disable-next-line camelcase
                                        disposition_id: String(
                                            ticketDetails.dispositionId
                                        ),
                                    })
                                }}
                            >
                                <Edit />
                            </div>
                        </div>
                        <div className={styles.inputTitle}>
                            {intl.formatMessage({
                                id: "Maintenance.describeYourRequest",
                                defaultMessage: "Describe your request",
                            })}
                            <div className={styles.required}>*</div>
                        </div>
                        <div className={styles.inputBox}>
                            <BaseInput
                                className={classnames(
                                    styles.titleInput,
                                    styles.newRequestInput
                                )}
                                required
                                value={formFields.title}
                                name="title"
                                onChange={onInputChange}
                                maxLength={160}
                                inlineStyle={{
                                    width: "100%",
                                }}
                                isValid={160 - formFields.title.length >= 0}
                                isBlurred={true}
                            />
                            <div className={styles.characterCount}>
                                <span
                                    className={`type-heading-tiny-caps maintenance-helper-text--${
                                        formFields.title.length >= 0
                                            ? "valid"
                                            : "error"
                                    }`}
                                >
                                    {formFields.title.length}/160
                                </span>
                            </div>
                        </div>
                        <div className={styles.inputTitle}>
                            {intl.formatMessage({
                                id: "Maintenance.requestDetails",
                                defaultMessage:
                                    "Describe your request in detail",
                            })}
                            <div className={styles.required}>*</div>
                        </div>
                        <div className={styles.inputBox}>
                            <BaseInput
                                className={styles.newRequestInput}
                                required
                                value={formFields.description}
                                name="description"
                                onChange={onInputChange}
                                maxLength={2000}
                                textArea={true}
                                inlineStyle={{
                                    width: "100%",
                                }}
                                inputSize={BaseInput.Size.TEXT_AREA}
                                isValid={
                                    2000 - formFields.description.length >= 0
                                }
                            />
                            <div className={styles.characterCount}>
                                <span
                                    className={`type-heading-tiny-caps maintenance-helper-text--${
                                        2000 - formFields.description.length >=
                                        0
                                            ? "valid"
                                            : "error"
                                    }`}
                                >
                                    {formFields.description.length}/2000
                                </span>
                            </div>
                        </div>
                        <div className={styles.buttonsContainer}>
                            <NewButton
                                className={styles.submit}
                                variant="primary"
                                disabled={
                                    !isValid || createTicketMutation.isLoading
                                }
                                loading={createTicketMutation.isLoading}
                                type="submit"
                            >
                                {intl.formatMessage({
                                    id: "Maintenance.submitRequest",
                                    defaultMessage: "Submit Request",
                                })}
                            </NewButton>
                            {showClearButton && (
                                <div className={styles.clear} onClick={onClear}>
                                    {intl.formatMessage({
                                        id: "Maintenance.clear",
                                        defaultMessage: "Clear",
                                    })}
                                </div>
                            )}
                        </div>
                    </form>
                </div>
            )}
        </>
    )
}

export default CreateTicketDetails
