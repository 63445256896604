import { AxiosError } from "axios"
import { MutateOptions, useMutation, UseMutationResult } from "react-query"

import {
    putPaymentAccount,
    PutPaymentAccountRequest,
    PutPaymentAccountResponse,
} from "./putPaymentAccount"

export const usePaymentAccountMutation = (
    options?: MutateOptions<
        PutPaymentAccountResponse,
        AxiosError,
        PutPaymentAccountRequest,
        void
    >
): UseMutationResult<
    PutPaymentAccountResponse,
    AxiosError,
    PutPaymentAccountRequest,
    void
> => {
    return useMutation<
        PutPaymentAccountResponse,
        AxiosError,
        PutPaymentAccountRequest,
        void
    >(payload => putPaymentAccount(payload), options)
}
