import classnames from "classnames"
import { Button } from "lib/components/Button/Button"
import { FC } from "react"
import { Trash2 } from "react-feather"
import styles from "./ConfirmDialog.module.scss"

interface Props {
    title: string
    message: string
    loading?: boolean
    cancelText?: string
    confirmText?: string
    onConfirmClick?: () => void
    onCancelClick?: () => void
}

const ConfirmDialog: FC<Props> = ({
    cancelText,
    confirmText,
    loading,
    message,
    onCancelClick,
    onConfirmClick,
    title,
}) => (
    <div className={styles["confirm-dialog"]}>
        <div
            className={classnames(
                "font-extrabold text-sm uppercase tracking-wider text-center flex items-center justify-center",
                styles["confirm-dialog__title"]
            )}
        >
            <Trash2 />
            {title}
        </div>
        <div className="text-center">{message}</div>
        <Button
            className={styles["confirm-dialog__confirm-btn"]}
            color="inherit"
            variant="primary-alert"
            fullWidth
            loading={loading}
            disabled={loading}
            onClick={onConfirmClick}
        >
            {confirmText ?? "Confirm"}
        </Button>

        <button
            disabled={loading}
            onClick={onCancelClick}
            className={classnames(
                styles["confirm-dialog__cancel-btn"],
                "font-extrabold",
                "tracking-sm",
                "text-links",
                "cursor-pointer",
                "text-sm"
            )}
        >
            {cancelText ?? "Cancel"}
        </button>
    </div>
)

export default ConfirmDialog
