import { observer } from "mobx-react"

import { useUnits } from "hooks/units"
import { FormattedMessage, useIntl } from "react-intl"
import { ReactComponent as MoneyBag } from "../../assets/icon-money-bag.svg"
import EmptyState from "../../lib/components/EmptyState"
import Loader from "../../lib/components/Loader/index"
import SVGIcon from "../../lib/components/SVGIcon"
import IndividualUnits from "./IndividualUnits"
import styles from "./settings.module.scss"

interface ReservedBalanceProps {
    currency: string
}

const ReserveBalance = observer((props: ReservedBalanceProps) => {
    const { currency } = props
    const unitsQuery = useUnits()
    const intl = useIntl()

    return (
        <div>
            <div>
                <span className={styles["inline-container"]}>
                    <MoneyBag className={styles["container-icon"]} />
                    <h3>
                        <FormattedMessage
                            id="Settings.reserveBalance"
                            defaultMessage="Reserve Balance"
                        />
                    </h3>
                </span>
                <p>
                    <FormattedMessage
                        id="Settings.reserveBalanceText"
                        defaultMessage="If your expenses exceed your revenue in a given month, your Reserve Account will be used to cover the difference. If your reserve account balance is below {balance}, we'll automatically replenish it from revenue you earn."
                        values={{
                            balance: intl.formatNumber(500, {
                                currency,
                                style: "currency",
                            }),
                        }}
                    />
                </p>
            </div>

            {unitsQuery.isSuccess && unitsQuery.data.length > 0 && (
                <div className={styles["reserve-balance-grid"]}>
                    {unitsQuery.data
                        .sort(
                            (a, b) =>
                                Number(b.attributes.status.active) -
                                Number(a.attributes.status.active)
                        )
                        .map(unit => {
                            const title = unit.attributes.name
                            const address = unit.attributes.address.address_1
                            return (
                                <IndividualUnits
                                    key={unit.id}
                                    unit={unit}
                                    title={title ?? ""}
                                    address={address ?? ""}
                                    currency={currency}
                                />
                            )
                        })}
                </div>
            )}

            <div>
                {unitsQuery.isLoading && <Loader />}
                {unitsQuery.isSuccess && unitsQuery.data.length === 0 && (
                    <EmptyState
                        snippet={
                            <FormattedMessage
                                id="Settings.reserveBalanceEmptyState"
                                defaultMessage="You'll be able to view and update your reserve balance here."
                            />
                        }
                    >
                        <SVGIcon
                            svg="icon-feather-gear"
                            className="empty-state__svg w-56 h-56"
                        />
                    </EmptyState>
                )}
            </div>
        </div>
    )
})

export default ReserveBalance
