import React, { useEffect, useState } from "react"
import cx from "classnames"

import { CalendarMonthPhrases } from "../../../defaultPhrases"

import CalendarYear from "./CalendarYear"
import { getCalendarYearWidth } from "../../../utils/calendar/calendarUtil"
import {
    MONTH_WIDTH_SIZE,
    MONTH_HEIGHT_SIZE,
    HORIZONTAL_ORIENTATION,
    VERTICAL_ORIENTATION,
    VERTICAL_SCROLLABLE,
} from "../../../Constants"
import { getYear, subYears } from "date-fns"

function getTransformStyles(transformValue: string) {
    return {
        transform: transformValue,
        msTransform: transformValue,
        MozTransform: transformValue,
        WebkitTransform: transformValue,
    }
}

interface CalendarYearGridProps {
    firstVisibleYearIndex: number
    initialYear: Date
    isAnimating: boolean
    numberOfYears: number
    orientation?: string
    onMonthClick?: (day: Date) => void
    onYearTransitionEnd: () => void
    renderMonth?: (month: Date) => JSX.Element
    transformValue: string
    monthWidthSize?: number
    monthHeightSize?: number
    focusedDate?: Date | null // indicates focusable day
    isFocused: boolean // indicates whether or not to move focus to focusable day
    phrases?: typeof CalendarMonthPhrases
}

const CalendarYearGrid: React.FC<CalendarYearGridProps> = ({
    firstVisibleYearIndex = 0,
    initialYear = new Date(),
    isAnimating = false,
    numberOfYears = 1,
    orientation = HORIZONTAL_ORIENTATION,
    onMonthClick,
    onYearTransitionEnd,
    renderMonth,
    transformValue = "none",
    monthWidthSize = MONTH_WIDTH_SIZE,
    monthHeightSize = MONTH_HEIGHT_SIZE,
    focusedDate,
    isFocused = false,
    phrases = CalendarMonthPhrases,
}: CalendarYearGridProps) => {
    const isVertical = orientation === VERTICAL_ORIENTATION
    const isVerticalScrollable = orientation === VERTICAL_SCROLLABLE
    const isHorizontal = orientation === HORIZONTAL_ORIENTATION
    const newYear = new Date(initialYear.valueOf())
    const prevYear = subYears(new Date(newYear.valueOf()), 1)
    const [years, setYears] = useState([prevYear, newYear])
    const [initialYearState, setInitialYearState] = useState(initialYear)
    const [numberOfYearsState, setNumberOfYearsState] = useState(numberOfYears)

    useEffect(() => {
        const hasYearChanged =
            getYear(initialYear) !== getYear(initialYearState)
        const hasNumberOfYearsChanged = numberOfYears !== numberOfYearsState

        if (hasYearChanged && !hasNumberOfYearsChanged) {
            setYears([
                subYears(new Date(initialYear.valueOf()), 1),
                new Date(initialYear.valueOf()),
            ])
        }
        setNumberOfYearsState(numberOfYears)
        setInitialYearState(initialYear)
    }, [
        initialYear,
        initialYearState,
        numberOfYears,
        numberOfYearsState,
        orientation,
        years,
    ])

    const className = cx("CalendarYearGrid", {
        "CalendarYearGrid--horizontal": isHorizontal,
        "CalendarYearGrid--vertical": isVertical,
        "CalendarYearGrid--vertical-scrollable": isVerticalScrollable,
        "CalendarYearGrid--animating": isAnimating,
    })

    const calendarYearWidth = getCalendarYearWidth(monthWidthSize)

    const width =
        isVertical || isVerticalScrollable
            ? calendarYearWidth
            : (numberOfYears + 2) * calendarYearWidth

    const transformStyles = getTransformStyles(transformValue)
    const style = {
        transformStyles,
        width,
    }

    return (
        <div
            data-testid={"calendar-year-grid"}
            className={className}
            style={style}
            onTransitionEnd={() => {
                onYearTransitionEnd()
            }}
        >
            {years.map((year: Date, i: number) => {
                const isVisible =
                    i >= firstVisibleYearIndex &&
                    i < firstVisibleYearIndex + numberOfYears
                return (
                    <CalendarYear
                        key={year.valueOf()}
                        year={year}
                        isVisible={isVisible}
                        orientation={orientation}
                        monthWidthSize={monthWidthSize}
                        monthHeightSize={monthHeightSize}
                        onMonthClick={onMonthClick}
                        renderMonth={renderMonth}
                        focusedDate={isVisible ? focusedDate : undefined}
                        isFocused={isFocused}
                        phrases={phrases}
                    />
                )
            })}
        </div>
    )
}

export default CalendarYearGrid
