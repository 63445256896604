import styles from "./Blurb.module.scss"
import React, { ReactElement } from "react"

type BlurbProps = {
    icon: ReactElement
    title: string | ReactElement
    description: string | ReactElement
}

export const Blurb = ({
    icon,
    title,
    description,
}: BlurbProps): ReactElement => (
    <div className={styles.container}>
        <div className={styles.heading}>
            <div className={styles.icon}>{icon}</div>
            <div className={styles.title}>{title}</div>
        </div>
        <div className={styles.description}>{description}</div>
    </div>
)
