import {
    FeatureExperiment,
    FeatureExperimentId,
} from "utils/feature-experiment"
import { useExperiment } from "@optimizely/react-sdk"

const useFeatureExperiment = (
    experimentId: FeatureExperimentId
): FeatureExperiment => {
    const [variation, clientReady, didTimeout] = useExperiment(experimentId)
    return {
        variation,
        clientReady,
        didTimeout,
    }
}

export { useFeatureExperiment }
