const homeInfo = {
    title: "Home Info",
    viewListingAction: "View live listing",
    viewOnboardingAction: "Switch to Onboarding",
    active: "active",
    inactive: "inactive",
    onboarding: "onboarding",
    dueToMaintenance: "Due to Maintenance",
    since: "Since",
    until: "Until",
    emptyState:
        "Details like your local Vacasa contacts and a link to your listing will show up here.",
    ownerGuide: {
        ownerGuideTitle: "Owner Resource Library",
        viewLink: "View Owner Library",
    },
    dogFriendly: {
        dogsAllowed: "Dogs Allowed",
        yes: "Yes",
        no: "No",
        edit: "Edit",
    },

    homeNotReady: {
        title: "Home not ready",
        description:
            "We’ll let you know as soon as your home’s information is ready to edit.",
    },
    help: {
        haveQuestions: "Have questions? {{emailLink}}",
        contactConsultant: "Contact a home consultant",
    },
    protectionProgram: {
        enrolled: "Enrolled",
        enrolledDescription:
            "You are enrolled with the Accommodations Protection Program. To change this, call {phoneNumber} or email {email}.",
        learnMore: "Learn more",
        notEnrolled: "Not enrolled",
        notEnrolledDescription:
            "You are not enrolled with Accommodations Protection Program. If you want to learn more about enrolling in this program, contact {email}.",
        title: "Accommodations\nProtection Program",
        unableToLoadProgramStatus:
            "We were unable to load your rental program status.",
    },
    widgets: {
        averageCleanScore: {
            errorMessage: "We're having trouble loading this content.",
            emptyStateMessage:
                "When available, your average guest clean score over the last 6 months will appear here.",
            periodMessage: "over the last 6 months",
            inspections: "Inspections",
            inspetionsTooltipTitle: "Inspections",
            inspetionsTooltipSubTitle: "over the last 6 months",
            inspetionsTooltipBody:
                "Inspections are required on all cleans at Vacasa. Our detailed home inspections ensure that Vacasa maintain a high quality housekeeping and unit appearance standard. They serve to provide confidence knowing your homes are safe and guest-ready.",
            title: "Avg Guest Clean Score",
            tooltipTitle: "Avg Guest Clean Score",
            tooltipSubTitle: "over the last 6 months",
            tooltipBody:
                "After a guest has completed a stay in your home, Vacasa prompts them to review their experience by asking detailed questions and ratings about the cleanliness and conditions of their home. Their feedback is then calculated into your average guest clean score.",
        },
        homeActivity: {
            errorMessage: "We're having trouble loading this content.",
            emptyStateMessage:
                "Information on the last inspection and next clean of your home will appear where when it becomes available.",
            lastCleanEmpty: "No inspection has been completed",
            lastCleanTitle: "Last Clean Inspection",
            nextCleanEmpty: "No upcoming inspections",
            nextCleanTitle: "Next Clean Scheduled",
            title: "Home Activity",
        },
        inspectionReport: {
            errorMessage: "We're having trouble loading this content.",
            title: "Latest Inspection Report",
        },
    },
}

export default homeInfo
