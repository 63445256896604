import { useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import { Link, Navigate } from "react-router-dom"
import PaymentOptions from "./PaymentOptions"
import ReserveBalance from "./ReserveBalance"
import styles from "./settings.module.scss"

import { useToastNotification } from "lib/components/ToastNotification"
import { observer } from "mobx-react"
import { trackPaymentSettingPageViewed } from "services/segment/statement/statementTracking"
import { ReactComponent as ArrowLeft } from "../../assets/icon-arrow-left.svg"
import { ReactComponent as ChevronRight } from "../../assets/icon-chevron-right.svg"
import { ReactComponent as CreditCard } from "../../assets/icon-credit-card.svg"
import LoggingService from "../../services/logging/logging.service"
import { Owner } from "@vacasa/owner-api-models"
import { useUser, useUserCurrencyCode } from "hooks/user"
import { useInvalidateContact } from "hooks/contact/useContact"

export interface OwnerPaymentDetails {
    directDeposit?: boolean
    isSavingsAccount?: boolean
    accountNumberLast4?: string | null
    accountHolderName?: string | null
    routingNumber?: number | null
    firstName?: string | null
    lastName?: string | null
    address1?: string | null
    address2?: string | null
    cityStateAddress?: string | null
}

const allowDirectDebit = (user: Owner | null): boolean => {
    if (!user) return false
    const countries = ["United States", "Belize", "Mexico", "Costa Rica"]
    return countries.some(
        country => user?.accountInfo.accountingEntityCountry === country
    )
}

const PaymentSettings = observer(() => {
    const { user } = useUser()
    const invalidateContact = useInvalidateContact()
    const allowPaymentOption = allowDirectDebit(user)
    const currency = useUserCurrencyCode()
    const [ownerPaymentDetails, setOwnerPaymentDetails] =
        useState<OwnerPaymentDetails>({})
    const { addToastNotification } = useToastNotification()

    // TODO: Get latest @vacasa/owner-api-models package
    const allowOwnerReserve = user?.reserveBalance
        ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (user.reserveBalance as any).allowUpdates === true
        : true

    useEffect(() => {
        trackPaymentSettingPageViewed()
    }, [])

    useEffect(() => {
        if (user?.accountInfo?.address1) {
            populatePaymentSettings(user)
        }
    }, [user])

    function populatePaymentSettings(owner: Owner): void {
        setOwnerPaymentDetails({
            firstName: owner.firstName,
            lastName: owner.lastName,
            address1: owner.accountInfo?.address1,
            address2: owner.accountInfo?.address2,
            cityStateAddress: `${owner.accountInfo?.city}, ${owner.accountInfo?.state} ${owner.accountInfo?.zip}`,
            accountNumberLast4: owner.accountInfo?.accountNumber?.slice(-4),
            isSavingsAccount: owner.accountInfo?.isSavings,
            routingNumber: null,
            accountHolderName: owner.accountInfo?.accountHolderName,
            directDeposit: owner.accountInfo?.directDepositActive !== 0,
        })
    }

    const onDirectDepositUnenrollSuccess = async () => {
        try {
            await invalidateContact(user?.contactId)
            if (!user) return
            populatePaymentSettings(user)
            addToastNotification({
                content: (
                    <FormattedMessage
                        id="Settings.directDepositRemoved"
                        defaultMessage="Removed from direct deposit"
                    />
                ),
            })
        } catch (e) {
            LoggingService.error({
                message: "Failed to fetch user",
                error: e,
            })
        }
    }

    return (
        <div
            className={styles["payment-setting-main-container"]}
            data-testid="payment-setting-main-container"
        >
            {user === null && <Navigate to="/login" />}
            <div className="container-fluid">
                <div className={styles.navigation}>
                    <Link className="type-body-small label" to={`/statements`}>
                        <ArrowLeft className={styles["back-arrow-button"]} />
                    </Link>

                    <h4
                        className="type-display-small"
                        data-testid="payment-settings-title"
                    >
                        <FormattedMessage
                            id="Settings.title"
                            defaultMessage="Payment Settings"
                        />
                    </h4>
                </div>

                <div className={styles["breadcrumbs-navigator"]}>
                    <Link className="midnight-60" to={`/statements`}>
                        <FormattedMessage
                            id="Settings.linkToStatements"
                            defaultMessage="Statements"
                        />
                    </Link>
                    <ChevronRight className={styles["chevron-right"]} />
                    <p>
                        <FormattedMessage
                            id="Settings.title"
                            defaultMessage="Payment Settings"
                        />
                    </p>
                </div>
                <div className={styles["payment-method-text-container"]}>
                    <span className={styles["inline-container"]}>
                        <CreditCard className={styles["container-icon"]} />
                        <h3>
                            <FormattedMessage
                                id="Settings.paymentMethod"
                                defaultMessage="Payment Method"
                            />
                        </h3>
                    </span>
                    <p>
                        <FormattedMessage
                            id="Settings.paymentMethodText"
                            defaultMessage="Payment delivery depends on the owner's selected payment method."
                        />
                    </p>
                </div>

                {allowPaymentOption && (
                    <PaymentOptions
                        onDirectDepositUnenrollSuccess={
                            onDirectDepositUnenrollSuccess
                        }
                        owner={ownerPaymentDetails}
                    />
                )}
                {allowOwnerReserve && (
                    <div>
                        <ReserveBalance currency={currency} />
                    </div>
                )}
            </div>
        </div>
    )
})

export default PaymentSettings
