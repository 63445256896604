import { useIntl } from "react-intl"
import styles from "./CreateTicketCategory.module.scss"

import { ReactComponent as ArrowLeft } from "../../../../assets/icon-arrow-left.svg"
import { ReactNode } from "react"
import { TicketCategory, TicketCategoryId } from "@vacasa/owner-api-models"
import { trackCreateTicketBackArrowClicked } from "services/segment/supportHub/supportHubTracking"

interface CreateTicketCategoryProps {
    ticketCategories: TicketCategory[]
    updateCategory: (categoryId: TicketCategoryId | undefined) => void
    showArrow: boolean
    ticketCategoryTitle: string
    getIcon: (categoryId: string) => ReactNode
}

export const CreateTicketCategory: React.FC<CreateTicketCategoryProps> = (
    props: CreateTicketCategoryProps
) => {
    const {
        ticketCategories,
        updateCategory,
        showArrow,
        ticketCategoryTitle,
        getIcon,
    } = props
    const onCategoryClick = (categoryId: TicketCategoryId) => {
        updateCategory(categoryId)
    }
    const intl = useIntl()

    const onArrowClick = () => {
        updateCategory(TicketCategoryId.Root)
        trackCreateTicketBackArrowClicked({
            location: "category_selection",
        })
    }

    return (
        <div className={styles.ticketCategoryContent}>
            {showArrow && (
                <div className={styles.arrowLeft} onClick={onArrowClick}>
                    <ArrowLeft />
                </div>
            )}
            <div className={styles.ticketCategoryTitle}>
                {ticketCategoryTitle}
            </div>
            <div className={styles.ticketCategorySubtitle}>
                {intl.formatMessage({
                    id: "Maintenance.categorySubtitle",
                    defaultMessage:
                        "Let us know how we can support you by selecting an option below:",
                })}
            </div>
            {ticketCategories.map(category => (
                <div
                    key={category.id}
                    className={styles.categoryContainer}
                    onClick={() => onCategoryClick(category.id)}
                >
                    <div className={styles.categoryIcon}>
                        {getIcon(category.id)}
                    </div>
                    <div className={styles.categoryName}>
                        {category.displayName}
                    </div>
                    <div className={styles.categoryDescription}>
                        {category.description}
                    </div>
                </div>
            ))}
        </div>
    )
}
