const maintenance = {
    title: "Wartung",
    supportHub: "Support",
    newRequest: "Neue Anfrage",
    open: "Offen",
    closed: "Geschlossen",
    requestNumber: "Anfrage Nr.",
    status: "Status",
    inProgress: "In Bearbeitung",
    rental: "Unterkunft",
    allRentals: "Alle Ferienimmobilien",
    search: "Suchen",
    submitted: "Gesendet",
    updated: "Aktualisiert",

    selectRental: "Unterkunft auswählen",
    newMaintenanceRequest: "New Maintenance Request",
    needSomeHelp: "Need some help?",
    categorySubtitle:
        "Let us know how we can support you by selecting an option below:",
    requestTitle: "Maintenance request title",
    titlePlaceholder: "Clogged drain",
    describeYourRequest: "Describe your request",
    requestDetails: "Describe your request in detail",
    note: "Note",
    notes: "Notes",
    detailsPlaceholder:
        "We noticed a big drainage issue with the primary kitchen sink. It's a super slow drain or it doesn't even drain at all. Please help!",
    clear: "Clear",
    submitRequest: "Anfrage senden",
    areYouSure: "Are you sure?",
    leavingAlertText:
        "Leaving the submission form will cause you to lose any data you may have entered.",
    continueWithSubmission: "Continue with Submission",
    cancelRequest: "Cancel Request",
    requestSubmitted: "Request submitted",
    requestSaved: "Anfrage gespeichert",

    submitNewRequest: "Neue Anfrage senden",
    noOpenRequests: "No Open Requests",
    newRequestWill: "New requests will appear here until they are addressed.",
    noClosedRequests: "No Closed Requests",
    closedRequestsWill:
        "Once an open request is addressed, it will be closed out and will appear here.",

    ariaLabel: "Hier klicken, um die Wartungsanfrage zu senden",

    needHelp: "Brauchst du Hilfe?",
    contactSentenceIfQuestions:
        "Bitte wende dich an deine Kontaktperson {name}, wenn du Fragen hast oder deine Anfrage ändern möchtest.",
    or: "oder",

    failedFetchError: `Leider konnten wir deine Wartungsanfrage nicht laden. Sollte das Problem weiterhin bestehen, versuche es bitte später noch einmal.`,
    failedCreateError: `Leider konnten wir deine Wartungsanfrage nicht speichern. Überprüfe bitte deine Auswahl und versuche es erneut.`,
    reload: `Erneut laden`,
    emptyState:
        "Need help with home maintenance? You’ll be able to submit requests here.",

    submittedOn: "Submitted on {date}",
    closedOn: "Closed on {date}",
    cancelled: "Cancelled",
    comments: {
        comments: "Comments",
        empty: "When Vacasa responds to this request, their comments will appear here.",
        overCharacterLengthLimit:
            "The comment should not be over 1000 characters.",
        failedCreateCommentError:
            "We were unable to save your comment. Please try again later.",
    },
    featureAlertTitle: "NEW! Two-way Ticket Comments",
    featureAlertBody:
        "Add a comment to your ticket to provide more information or follow up with a question, which will streamline communication about a specific ticket.",
}

export default maintenance
