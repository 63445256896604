import { UnitAvailabilityDays } from "@vacasa/owner-api-models"
import { FocusedInputShape } from "react-dates"
import { START_DATE } from "../../../../Constants"

/**
 * Shape of date range related state
 */
export type DateRangeState = {
    errorMessage: string | null
    focusedInput: FocusedInputShape | null
    canEditStartDate: boolean
    startDate: Date | null
    showStartDateError: boolean
    endDate: Date | null
    showEndDateError: boolean
    blockAllDays: boolean
    calendarAvailability: UnitAvailabilityDays
}

export const INITIAL_DATE_RANGE_STATE: DateRangeState = {
    errorMessage: null,
    focusedInput: START_DATE,
    canEditStartDate: false,
    startDate: null,
    showStartDateError: false,
    endDate: null,
    showEndDateError: false,
    blockAllDays: false,
    calendarAvailability: {},
}
