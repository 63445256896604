import { UnitAvailabilityDays } from "@vacasa/owner-api-models"

/**
 * Creates a new UnitAvailabilityDays where the days that match a specific reservation Id are freed up.
 * @param resId Reservation Id to exclude
 * @param availability Unit availability data
 * @returns New instance of UnitAvailabilityDays without the target reservation.
 */
const excludeReservationFromAvailability = (
    resId: string,
    availability: UnitAvailabilityDays
): UnitAvailabilityDays => {
    const dayKeys = Object.keys(availability)
    return dayKeys.reduce((acc, key, i) => {
        const day = availability[key]
        if (day) {
            acc[key] = {
                ...day,
            }

            // day to be removed as it is part of the reservation
            if (day.reservationID === resId) {
                acc[key] = {
                    ...day,
                    isAvailable: true,
                    // assuming reservationID is for the reservation that is the checkin day
                    isCheckinDay: false,
                    isCheckoutDay: false,
                    reservationID: "",
                    reservationType: undefined,
                }
                return acc
            }

            // Check out day of the reservation to remove,
            // which is overlapping with a different reservation checkin day
            const dayKeysIndex = dayKeys[i - 1]
            if (dayKeysIndex) {
                const prevDay = i > 0 && availability[dayKeysIndex]
                if (
                    prevDay &&
                    day.isCheckoutDay &&
                    prevDay.reservationID === resId
                ) {
                    acc[key] = {
                        ...day,
                        isAvailable: true,
                        isCheckoutDay: false,
                    }
                    return acc
                }
            }
        }
        return acc
    }, {} as UnitAvailabilityDays)
}

export { excludeReservationFromAvailability }
