import { JSONAPIResource, Reservation } from "@vacasa/owner-api-models"
import classnames from "classnames"
import { ReferralBanner } from "components/ReferralBanner"
import { isPast } from "date-fns"
import useRouter from "hooks/common/useRouter"
import { useContactId } from "hooks/user"
import {
    useOwnerUnitPreferences,
    useOwnerUnitPreferencesMutation,
} from "hooks/owner-preferences"
import { useReservation } from "hooks/reservation"
import { useKnownSearchParams } from "hooks/router"
import { observer } from "mobx-react"
import React, { useCallback, useEffect, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { Navigate, Outlet, useLocation } from "react-router-dom"
import {
    trackCalendarPageViewed,
    trackLiveUnitCongratsModalDismissClicked,
    trackLiveUnitCongratsModalPopup,
} from "services/segment/calendar/calendarTracking"
import { UnitSelectWrapper } from "views/UnitSelectWrapper"
import ListingLive from "../../assets/listing-live.png"
import { ReactComponent as SpaceSVG } from "../../assets/space-image.svg"
import { Card } from "../../components/Card"
import { EmptyState } from "../../components/EmptyState"
import { ListLayout } from "../../components/Layout/ListLayout"
import { DISPLAY_CONGRATS_MODAL } from "../../constants/preferences.constants"
import { DateRangeProvider } from "../../core/components/dates/state/DateRange.context"
import ActionModal from "../../lib/components/ActionModal/ActionModal"
import Loader from "../../lib/components/Loader/index"
import LoggingService from "../../services/logging/logging.service"
import BookingNotificationUtil from "../../utils/bookingNotification/bookingNotificationUtil"
import { relatedUnits } from "../../utils/unit/relatedUnits/relatedUnits"
import { CalendarHolds } from "../../utils/unit/unitStatus/unitStatus"
import { ReactComponent as EmptyStateImage } from "./CalendarEmptyState.svg"
import { default as OwnerHolds } from "./OwnerHolds/OwnerHolds"
import { ReservationCancelled } from "./ReservationFlyout/ReservationCancelled/ReservationCancelled"
import { Reservations } from "./Reservations"
import { WebinarBanner } from "../../components/WebinarBanner"
import { CongratsModal } from "./modal/congrats-modal"
import { OptimizelyFeature } from "@optimizely/react-sdk"
import { useUser } from "hooks/user"
import DefaultError from "lib/components/ErrorBoundaries/DefaultError"
import { useCurrentUnit, useUnitStatuses, useUnits } from "hooks/units"

const Calendar: React.FC = observer((): JSX.Element => {
    const intl = useIntl()
    const [reservation, setReservation] = useState<
        JSONAPIResource<Reservation> | null | undefined
    >(null)
    const unitsQuery = useUnits()
    const { unitId, unit } = useCurrentUnit()
    const { isActive, calendarHoldStatus } = useUnitStatuses()

    const [showErrorModal, setShowErrorModal] = useState(false)

    const location = useLocation()
    window.onbeforeprint = function () {
        if (location.pathname === "/calendar") {
            LoggingService.log({ message: "Print on Calendar page" })
        }
    }
    const urlParams = new URLSearchParams(location.search)
    const knownParams = useKnownSearchParams()

    const showCancelledFlyout =
        urlParams.has("cancelled") && !!urlParams.get("reservationID")
    const cancelledReservationID = urlParams.get("reservationID")

    const router = useRouter()

    useEffect(() => {
        trackCalendarPageViewed()
    }, [])

    // get reservation based on parameter resId
    const resId = knownParams.resId

    const reservationQuery = useReservation(resId)
    if (resId) {
        // start fetching reservation and set if to false once receive the result
        BookingNotificationUtil.setFetchingReservation(true)
        if (reservationQuery.isSuccess) {
            BookingNotificationUtil.setReservation(reservationQuery.data.data)
            BookingNotificationUtil.setFetchingReservation(false)
        } else if (reservationQuery.isError) {
            BookingNotificationUtil.setReservation(null)
            BookingNotificationUtil.setFetchingReservation(false)
        }
    }

    useEffect(() => {
        const showFlyoutForBookingNotification = (
            reservation: JSONAPIResource<Reservation> | null | undefined
        ) => {
            if (
                !BookingNotificationUtil.getShouldShowBookingNotification(
                    location
                )
            ) {
                return
            }
            viewReservation(reservation)
            setReservation(reservation)
        }
        if (resId) {
            if (reservationQuery.isSuccess) {
                if (BookingNotificationUtil.reservation) {
                    setReservation(BookingNotificationUtil.reservation)
                }

                if (BookingNotificationUtil.getHasError(location)) {
                    setReservation(BookingNotificationUtil.reservation)
                    setShowErrorModal(true)
                    setTimeout(() => {
                        showFlyoutForBookingNotification(reservation)
                    }, 1000)
                }
            } else if (reservationQuery.isError) {
                setShowErrorModal(BookingNotificationUtil.getHasError(location))
                router.navigate("/calendar", {
                    replace: true,
                })
                setTimeout(() => {
                    showFlyoutForBookingNotification(reservation)
                }, 1000)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        location,
        resId,
        reservation,
        reservationQuery.isError,
        reservationQuery.isSuccess,
    ])

    useEffect(() => {
        if (resId) {
            const searchParams = new URLSearchParams(router.location.search)
            router.navigate({
                pathname: `/calendar/hold/${resId}`,
                search: searchParams.toString(),
                hash: router.location.hash,
            })
        }
        // only execute when there is parameter resId in url, execute when once will be enough
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const beginHold = () => {
        router.navigate({
            pathname: "/calendar/hold/create",
            search: router.location.search,
            hash: router.location.hash,
        })
    }

    const rowClick = (
        reservation: JSONAPIResource<Reservation> | null | undefined
    ) => {
        LoggingService.log({ message: `Upcoming stays row click` })
        viewReservation(reservation)
    }

    const viewReservation = (
        reservation: JSONAPIResource<Reservation> | null | undefined
    ) => {
        setReservation(reservation)
        const searchParams = new URLSearchParams(router.location.search)

        // TODO temporary query param, remove when we no longer want hotjar feedback for past reservations
        if (
            reservation?.attributes.endDate &&
            isPast(new Date(reservation?.attributes.endDate))
        ) {
            searchParams.append("past", "true")
        }
        if (reservation?.id) {
            router.navigate({
                pathname: `/calendar/hold/${reservation.id}`,
                search: searchParams.toString(),
                hash: router.location.hash,
            })
        }
    }

    const renderLoader = () => {
        return (
            <Card>
                <Loader />
            </Card>
        )
    }

    const ownerUnitPreferencesMutation = useOwnerUnitPreferencesMutation()
    const closeCongratsModal = () => {
        if (unitId) {
            ownerUnitPreferencesMutation.mutate({
                unitId,
                id: DISPLAY_CONGRATS_MODAL,
                value: false,
            })
        }
        setshouldShowCongratsModal(false)
        trackLiveUnitCongratsModalDismissClicked()
    }

    const onCloseErrorModal = useCallback(() => {
        setShowErrorModal(false)
        router.navigate("/calendar", {
            replace: true,
        })
    }, [router])

    const generateErrorModal = () =>
        // to show error modal like design, add these codes back, then pass in reservation
        // reservation: JSONAPIResource<Reservation> | null | undefined
        {
            const modalTitle = intl.formatMessage({
                id: "CalendarPage.errorMessages.modalTitle",
                defaultMessage: "Oops!",
            })
            const modalMessage = intl.formatMessage({
                id: "CalendarPage.errorMessages.modalMessage",
                defaultMessage:
                    "It looks like this reservation is no longer in our system.",
            })
            const modalIcon = <SpaceSVG />

            return (
                <ActionModal
                    onCloseHandler={onCloseErrorModal}
                    title={modalTitle}
                    description={modalMessage}
                    icon={modalIcon}
                />
            )
        }

    const { user } = useUser()
    if (user === null) {
        return <Navigate to="/login" />
    }

    const shouldRenderNoUnitState =
        unitsQuery.isSuccess &&
        (unitsQuery.data.length === 0 ||
            calendarHoldStatus === CalendarHolds.NotAllowedPlaceholder)

    // conditions for displaying the modal are:
    // `showCongratsModal` was set to true during the setup process,
    // `unitID` is fetched and current unit is now live
    const [shouldShowCongratsModal, setshouldShowCongratsModal] =
        useState(false)
    const contactId = useContactId()
    const ownerUnitPreferencesQuery = useOwnerUnitPreferences(unitId)
    useEffect(() => {
        if (ownerUnitPreferencesQuery.isSuccess) {
            const showModalPref = ownerUnitPreferencesQuery.data?.find(pref => {
                return pref.id === DISPLAY_CONGRATS_MODAL
            })
            if (
                showModalPref?.attributes.value &&
                unitId &&
                isActive &&
                unit?.attributes.display &&
                contactId
            ) {
                setshouldShowCongratsModal(true)
                trackLiveUnitCongratsModalPopup()
            }
        }
    }, [
        unitId,
        ownerUnitPreferencesQuery.data,
        ownerUnitPreferencesQuery.isSuccess,
        contactId,
        unit,
        isActive,
    ])

    return (
        <DateRangeProvider>
            <div>
                <ReferralBanner />
                <OptimizelyFeature feature="dashboard">
                    {isEnabled =>
                        !isEnabled && (
                            <>
                                <WebinarBanner />
                            </>
                        )
                    }
                </OptimizelyFeature>
                <Outlet />
                {showCancelledFlyout && (
                    <ReservationCancelled
                        reservationID={cancelledReservationID}
                        unitID={unit?.id ? `${unit.id}` : null}
                    />
                )}

                {showErrorModal && generateErrorModal()}
                {shouldShowCongratsModal && unitId && (
                    <CongratsModal
                        unitId={unitId}
                        listingLive={ListingLive}
                        onClose={closeCongratsModal.bind(this)}
                    />
                )}

                <ListLayout
                    title={intl.formatMessage({
                        id: "CalendarPage.title",
                        defaultMessage: "Calendar",
                    })}
                    size="lg"
                    actions={<UnitSelectWrapper />}
                    titleTestId="calendar-title"
                >
                    {shouldRenderNoUnitState && (
                        <EmptyState
                            image={<EmptyStateImage />}
                            title={
                                <FormattedMessage
                                    id="Calendar.emptyState.title"
                                    defaultMessage="Booking calendar"
                                />
                            }
                            description={
                                <FormattedMessage
                                    id="Calendar.emptyState.description"
                                    defaultMessage="You’ll be able to reserve owner holds for your personal use and view upcoming reservations at your home."
                                />
                            }
                        />
                    )}
                    {!shouldRenderNoUnitState && unitId && (
                        <div
                            className={classnames("calendar__container", {
                                newCalendar: true,
                            })}
                        >
                            <OwnerHolds placeHold={beginHold.bind(this)} />
                        </div>
                    )}
                    {!shouldRenderNoUnitState && !unitId && renderLoader()}
                    {unitId && contactId && (
                        <Reservations
                            contactId={contactId}
                            unitId={unitId}
                            unitRelationshipId={relatedUnits(unit)}
                            clickHandler={rowClick.bind(this)}
                            updatingCalendar={false}
                        />
                    )}
                </ListLayout>
            </div>
            <DefaultError />
        </DateRangeProvider>
    )
})

export default Calendar
