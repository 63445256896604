import { Card, CardContent } from "../../components/Card"
import { ListLayout } from "components/Layout/ListLayout"
import { useOwnerFeatureFlag } from "hooks/owner-feature-flag"
import { useUserNotificationPreference } from "hooks/user-notification-preference/useUserNotificationPreference"
import { FC, useCallback, useEffect, useState } from "react"
import Loader from "../../lib/components/Loader"
import { FormattedMessage } from "react-intl"
import { isFeatureFlagEnabled } from "utils/feature-flag"
import Notfound from "views/notfound"
import styles from "./NotificationSettings.module.scss"
import { PreferenceGroup } from "./PreferenceGroup"
import {
    PreferenceGroupData,
    transformUserPreference,
} from "utils/notification-center/notificationCenterUtil"
import { ReactComponent as BackToTopIcon } from "../../assets/back-to-top.svg"
import { EmployeeAlert } from "views/profile/EmployeeAlert"
import { SupportedPages } from "views/profile/EmployeeAlert/EmployeeAlert"
import { useIsEmployee } from "hooks/user"

export const NotificationSettings: FC = (): JSX.Element => {
    const isEmployee = useIsEmployee()
    const userNotificationPreferenceUIFlag = useOwnerFeatureFlag(
        "user-notification-preference-ui"
    )
    const isUserNotificationPreferenceUIEnabled = isFeatureFlagEnabled(
        userNotificationPreferenceUIFlag
    )
    const preferenceQuery = useUserNotificationPreference()
    const preferenceData = preferenceQuery.isSuccess
        ? preferenceQuery.data
        : undefined
    const [preferenceGroups, setPreferenceGroups] =
        useState<PreferenceGroupData[]>()
    const [backToTopButtonHidden, setBackToTopButtonHidden] =
        useState<boolean>(true)

    const handleScroll = useCallback(() => {
        setBackToTopButtonHidden(window.scrollY <= 0)
    }, [])

    useEffect(() => {
        setPreferenceGroups(transformUserPreference(preferenceData))
    }, [preferenceData])

    useEffect(() => {
        window.addEventListener("scroll", handleScroll)
        return () => window.removeEventListener("scroll", handleScroll)
    })

    if (!isUserNotificationPreferenceUIEnabled) return <Notfound />

    if (preferenceQuery.isFetching) {
        return (
            <Card>
                <CardContent>
                    <Loader />
                </CardContent>
            </Card>
        )
    }

    return (
        <ListLayout
            title={
                <FormattedMessage
                    id="Notification.title"
                    defaultMessage="Notification Settings"
                />
            }
        >
            {isEmployee && (
                <div>
                    <EmployeeAlert page={SupportedPages.Notifications} />
                </div>
            )}
            <div>
                <div className={styles["subtitle"]}>
                    <FormattedMessage
                        id="Notification.subtitle"
                        defaultMessage="Customize your notification settings by letting us know what you
                    would like to hear about."
                    />
                </div>
                {preferenceGroups?.map(group => (
                    <PreferenceGroup
                        key={group.groupName}
                        groupName={group.groupName}
                        preferences={group.preferences}
                    />
                ))}
                <div className={styles["required-info"]}>
                    *
                    <FormattedMessage
                        id="Notification.requiredAlert"
                        defaultMessage="Opting out of these transactional notices is not available."
                    />
                </div>
                <button
                    className={styles["back-to-top-button"]}
                    hidden={backToTopButtonHidden}
                    onClick={() => {
                        window.scrollTo({ top: 0, behavior: "smooth" })
                    }}
                >
                    <BackToTopIcon className={styles["chevron-icon"]} />
                </button>
            </div>
        </ListLayout>
    )
}
