import { RateTransparencyDataVersion2 } from "@vacasa/owner-api-models"
import React, { useEffect, useState } from "react"
import {
    getChartBaseConfig,
    getChartXAxisForYear,
} from "../MarketRateComparisonChart/utils"
import { Options, PointOptionsObject } from "highcharts"

import HighchartsMore from "highcharts/highcharts-more"
import HighchartsBoost from "highcharts/modules/boost"
import { useIntl } from "react-intl"
import { toRateTransparencyDataMap } from "utils/charts/chartsUtil"
import HighchartsReact from "highcharts-react-official"
import HighchartsStock from "highcharts/highstock"
import { format } from "date-fns"
import styles from "./MinRateChart.module.scss"
import classNames from "classnames"

HighchartsMore(HighchartsStock)
HighchartsBoost(HighchartsStock)

export interface MinRateChartProps {
    data?: RateTransparencyDataVersion2[]
    isLoading?: boolean
    minRate: number
    defaultMinRate: number
}

export const MinRateChart: React.FC<MinRateChartProps> = ({
    data,
    isLoading,
    minRate,
    defaultMinRate,
}) => {
    const intl = useIntl()
    const [options, setOptions] = useState<Options>(
        getChartBaseConfig({
            chartHeight: 217,
            locale: intl.locale,
            messages: intl.messages,
            v2: true,
            chartType: "revenue-simulator",
            tooltipEnabled: false,
            chartAnimation: false,
        })
    )

    useEffect(() => {
        if (isLoading || !data) {
            return
        }

        const marketComp: [number, number, number][] = []
        const yourRates: PointOptionsObject[] = []
        const minRates: PointOptionsObject[] = []

        const xAxisBase = getChartXAxisForYear()

        const isBelowDefaultMinRate = defaultMinRate > minRate

        // Map to an object to enable faster searching
        const rateTransparencyDataMap = toRateTransparencyDataMap(data ?? [])
        xAxisBase.forEach(base => {
            const dailyRate: RateTransparencyDataVersion2 | undefined =
                rateTransparencyDataMap[format(base.x, "yyyy-MM-dd")]

            marketComp.push([
                base.x,
                dailyRate?.avgCompMinRate ?? 0,
                dailyRate?.avgCompMaxRate ?? 0,
            ])

            const unitRate = dailyRate?.rateBasedOnRes ?? 0

            const rate =
                isBelowDefaultMinRate && unitRate === defaultMinRate
                    ? Math.max(dailyRate?.adjustedRate ?? 0, minRate ?? 0)
                    : Math.max(unitRate, minRate ?? 0)

            yourRates.push({
                x: base.x,
                y: rate,
                color: "var(--midnight)",
                data: dailyRate,
            })

            minRates.push({
                x: base.x,
                y: minRate ?? 0,
                color: "var(--alert)",
            })
        })

        setOptions(prevState => {
            return {
                ...prevState,
                series: [
                    {
                        type: "line",
                        data: yourRates,
                        lineWidth: 3,
                        name: "unit",
                        color: {
                            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                            stops: [
                                [0, "var(--gulf-90)"],
                                [1, "var(--midnight)"],
                            ],
                        },
                        zIndex: 3,
                        className: "rates-unit",
                        enableMouseTracking: false,
                    },
                    {
                        name: "Range",
                        id: "range",
                        data: marketComp,
                        type: "arearange",
                        lineWidth: 0,
                        color: "var(--gulf-40)",
                        fillColor: "var(--gulf-40)",
                        enableMouseTracking: false,
                    },
                    {
                        type: "line",
                        data: minRates,
                        lineWidth: 3,
                        color: "var(--alert)",
                        name: "minRate",
                        zIndex: 2,
                        className: "rates-minRate",
                        enableMouseTracking: false,
                    },
                ],
            }
        })
    }, [data, defaultMinRate, isLoading, minRate])

    if (isLoading) {
        return <div className={classNames(styles.loading, "shimmer")} />
    }

    return (
        <HighchartsReact
            highcharts={HighchartsStock}
            constructorType={"stockChart"}
            options={options}
        />
    )
}
