import { Referral } from "@vacasa/owner-api-models"
import { authAxios } from "../../api/authAxios"

export type PostReferralRequest = {
    contactId: string | null
    referral: Referral
    signal?: AbortSignal
}

const postReferral = ({
    contactId,
    referral,
    signal,
}: PostReferralRequest): Promise<number> => {
    const referralData = {
        data: {
            type: "owner-referral",
            attributes: referral,
        },
    }

    return authAxios
        .post<number>(
            `/v1/owners/referral?contactID=${contactId}`,
            referralData,
            {
                signal,
            }
        )
        .then(response => response.status)
}

export { postReferral }
