import { JSONAPIResource, ReservationGuest } from "@vacasa/owner-api-models"
import { authAxios } from "../../api/authAxios"

export type PostReservationGuestRequest = {
    reservationId: string | number | undefined
    guest: Omit<ReservationGuest, "guestID" | "reservationID">
    signal?: AbortSignal
}

const postReservationGuest = ({
    reservationId,
    guest,
    signal,
}: PostReservationGuestRequest): Promise<JSONAPIResource<ReservationGuest>> => {
    const data = {
        data: {
            type: "reservation-guest",
            attributes: guest,
        },
    }

    return authAxios
        .post<{ data: JSONAPIResource<ReservationGuest> }>(
            `/v1/reservations/${reservationId}/guests`,
            data,
            {
                signal,
            }
        )
        .then(response => response.data.data)
}

export { postReservationGuest }
