/* eslint-disable no-template-curly-in-string */
const calendar = {
    vacasa: "Vacasa",
    title: "Kalender",
    interiorDesignBannerText: "Style deine Innenräume um und verdiene mehr",
    covidBannerText:
        "Coronavirus homeowner resource guide - what we're doing to navigate COVID-19 together.",
    covidCtaDestination:
        "https://www.vacasa.com/support/coronavirus-homeowner-resource-guide",
    learnMore: "Mehr erfahren",
    emptyState:
        "You’ll be able to reserve dates for your personal use and view upcoming reservations.",

    OwnerHolds: {
        heading: "Owner holds",
        nonfixedHelperText:
            "Use this calendar to reserve dates for your own personal use.",
        fixedHelperText:
            "To reserve dates for your own personal use, please contact Homeowner Account Support at +1 (971) 254-3001 or",
        inactiveHelperText:
            "Diese Ferienimmobilie ist inaktiv. Eigentümersperren können nur zu aktiven Immobilien hinzugefügt werden.",
        ifHoldQuestions: "Bei Fragen zu dieser Sperre kontaktiere bitte",
        toChangeHold:
            "Falls du deine Termine ändern oder die Sperre aufheben möchtest, schreibe bitte eine E-Mail mit deiner Anfrage an",
        continue: "Weiter",
        ariaLabel: "Klicken, um die Eigentümersperre einzufügen",
        checkIn: "Check-in",
        checkOut: "Check-out",
        notSelected: "Nicht ausgewählt",
        clearDates: "Termine löschen",
        employeeOptions: "Zusätzliche Optionen für Mitarbeiter",
        holdType: "Hold Reason",
        ownerHold: "Eigentümersperre",
        guestStay: "Guest Stay",
        vacasaHold: "Vacasa-Sperre",
        selectOwner: "Eigentümer auswählen",
        scheduleClean:
            "Sollen wir am Aufenthaltsende eine Endreinigung durchführen?",
        yes: "Ja",
        no: "Nein",
        or: "oder",
        warningBanner:
            "One or more of the dates for your hold are unavailable. Please check your selection and try again.",
        datesPlaceholder: "MM/DD/YYYY",
    },

    flyout: {
        vacasaContact: "Vacasa contact",
        contactUsFooter:
            "If you have any questions about this hold, please reach out.",
        back: "Back",
        clear: "Clear",
        holdPlaced: "Hold placed",
        holdUpdated: "Hold updated",
        tempAccessCode: "Temporärer Zugangscode",
        smartLockAria:
            "Klicke diesen Link, um den temporären Smartlock-Code zu erhalten.",
        employeesMustClickToView: `Mitarbeiter müssen <button>hier klicken, um den Code angezeigt zu bekommen</button>. Aus Sicherheitsgründen wird diese Aktion aufgezeichnet.`,
        smartLockCode: "Smartlock-Code",
        notAvailable: "Nicht verfügbar",
        selectOption: "Option auswählen",
        reservation: "Buchung",
        ownerStay: "Eigentümeraufenthalt",
        ownerStaySubtitle: "Eine Reservierunge für Ihre Eigennutzung.",
        complimentaryStay: "Kostenloser Aufenthalt",
        complimentaryStaySubtitle: "Eine Buchung für Familie und Freunde.",
        vacasaHold: "Vacasa-Sperre",
        vacasaHoldSubtitle: "Diese Option ist nur für Mitarbeiter verfügbar.",
        block: "Blockieren",
        blockSubtitle: "Die Unterkunft wird nicht von anderen genutzt.",
        propertyCare: "Immobilien-Betreuung",
        propertyCareSubtitle: "Wartung, Upgrades, Anlieferungen usw.",
        seasonalHold: "Seasonal hold",
        homeForSale: "Home for sale",
        homeForSaleSubtitle: "Activities related to a home sale.",
        other: "Other",
        otherSubtitle: "Does not fit the other categories.",
        terminationHold: "Termination hold",
        terminationHoldSubtitle: "Option only available for employees",
        notes: {
            title: "Notes ",
            titleRequired: "Notes* ",
            optional: "(optional)",
            required: "(required)",
            placeholder:
                "Help us improve our category options by providing a note about this hold",
            none: "N/A",
        },
        housekeeping: "Housekeeping",
        yesHousekeepingSubtitle:
            "A Vacasa housekeeping visit will be scheduled after this hold.",
        yesHousekeepingSubtitleWithCleaningFee:
            "A Vacasa housekeeping visit will be scheduled after this hold and billed to your account in the amount of <strong>${amount}.</strong>",
        noHousekeepingSubtitle:
            "You will be responsible for cleaning and preparing the home for the next guests.",
        ownerResponsibleForCleaning: "Owner responsible for cleaning",
        housekeepingScheduled: "Housekeeping eingeplant",
        noHousekeeping: "Kein Housekeeping",
        numberGuestsMax: `{{number}} Gäste max.`,
        noPets: "Haustiere sind nicht gestattet",
        restrictionsDontApply:
            "Einschränkungen für Gäste gelten nicht für Eigentümer.",
        night: "night",
        nights: "Nächte",
        madeOn: "Booked on {creationDate}",
        madeBy: "Booked on {date} by {name}",
        monthCount:
            "Spans {monthCount, plural,=0 {}one {# month}other {# months}}",
        date: "Date",
        dates: "Termine",
        guests: "Gäste",
        createHold: "Create Hold",
        next: "Next",
        summary: "Summary",
        cancelOwnerHold: "Cancel owner hold",
        cancelAreYouSure:
            "Are you sure you want to cancel? Your hold has not been scheduled.",
        deleteOwnerHold: "Delete owner hold",
        editHold: "Sperre bearbeiten",
        edit: "Bearbeiten",
        editAria: "Zum Bearbeiten hier klicken.",
        pleaseContactToRequestChange:
            "Bitte kontaktiere {{contact}}, um eine Terminänderung anzufragen.",
        saveChanges: "Änderungen speichern",
        save: "Speichern",
        saveAria: "Zum Speichern hier klicken.",

        delete: "Löschen",
        removeHold: "Sperre entfernen",
        deleteHold: "Sperre löschen",
        deleteAria: "Zum Löschen hier klicken.",
        holdRemoved: "Sperre entfernt",
        deleteAreYouSure: "Are you sure you want to delete this?",
        deleteHelperText: "Dies kann nicht rückgängig gemacht werden.",
        deleteConfirm: "Ja, Sperre löschen",
        cancelDelete: "Nein, nicht löschen",
        approvalNeeded: "Genehmigung erforderlich.",
        fixedRentDeletionApproval:
            "Während der Laufzeit eines festen Mietvertrags ist für das Löschen einer Sperre die Genehmigung unseres Support-Teams notwendig.",
        request: "Request",
        requestDelete: "Löschen anfragen",
        deleteRequestConfirmation:
            "Dies ist eine Löschanfrage für eine Eigentümersperre während eines festen Mietvertrags.",
        holdNumberForRequest: "Löschanfrage für Sperre Nr.",
        holdNumber: "Sperren-Nr.: ",
        startDate: "Anfangsdatum: ",
        endDate: "Enddatum: ",

        cancel: "Stornieren",
        done: "Fertig",
        totalNetRent: "Total Net rent",
        revenueBreakdown: "Revenue breakdown",
        fixedRent: "Festmiete",
        grossRent: "Bruttomiete",
        guestReview: "Guest review",
        guestReviewCollapseLabel: "See less",
        guestReviewExpandLabel: "See more",

        inviteGuest: "Gast einladen",
        inviteGuests: "Invite Guests",
        inviteGuestsOptional: "Invite Guests (Optional)",
        inviteAnotherGuest: "Einen weiteren Gast einladen",
        inviteGuestsSubtitle:
            "Add guests to share your trip details and home access information.",
        firstName: "Vorname",
        lastName: "Nachname",
        emailAddress: "E-Mail-Adresse",
        invitationSent: "Einladung wurde versandt",
        invitationsSent: "Einladungen wurden versandt",
        fiveOrMoreInvitationsSent: "Einladungen wurden versandt",
        guestRemoved: "Gast entfernt",
        skip: "Skip",
        skipAria:
            "Klicke diese Schaltfläche, um das Hinzufügen von Gästen zu überspringen",
        inviteAnotherGuestAria:
            "Klicke diese Schaltfläche, um einen weiteren Gast einzuladen",
        noGuestInfo: "Keine Gäste-Informationen",
        guestInfo: "Guest info",
        adults: "Erwachsene",
        fiveOrMoreAdults: "Erwachsene",
        children: "Kinder",
        fiveOrMoreChildren: "Kinder",
        dogs: "Hunde",
        fiveOrMoreDogs: "Hunde",
        housekeepingNotAllowed:
            "Housekeeping not available. You will be responsible for cleaning and preparing the home for the next guests.",
        newBooking: "New booking",
        pastBooking: "Past booking",
        invalidDates: "The selected dates are not available.",
        learnAboutRates: "Learn about rates",
        guestBookingLikely:
            "These nights are highly likely to be booked by guests.",
        response: "Manager response",
        onboardingFooter: {
            title: "What do you want to do next?",
            backToOnboarding: "Go Back to Onboarding",
            addAdditionalOwnerHolds: "Add Another Owner Hold",
        },
        costOfHold:
            "You miss out on <b>{cost}</b> in gross rent by booking these dates.",
        housekeepingRequired:
            "In order to maintain the highest quality guest experience, housekeeping is required for stays with overnight guests.",
        housekeepingRequiedWithCleaningFee:
            "This owner hold requires a post-stay clean, which will be billed to your account after the stay in the amount of <strong>${amount}.</strong>",
        PhoneSections: {
            smsTitle: "Owner contact info",
            smsDescription: 'Details will be sent to this number.',
            smsEditDescription: "Details will be sent to <strong>{number}</strong>.",
            invalidPhoneMessage: 'The phone number is not valid',
            noPhoneNumber: 'No phone number provided'
        }
    },

    UpcomingStays: {
        title: "Reservations",
        emptyStateUpcoming:
            "New reservations and owner holds will show up here.",
        emptyStatePast:
            "You have no previous reservations. When upcoming stays have concluded, they will appear here.",
        upcomingDescription:
            "Upcoming guest reservations and owner holds will be listed below. Click a row to view more details. If you wish to modify an owner hold you can do so by clicking on the corresponding row.",
        pastDescription:
            "Your past reservations and holds in the last 45 days are listed out below.  If you would like to view more details around each stay, click on the detail row. ",
        toModify:
            "Wenn du eine Eigentümersperre bearbeiten möchtest, kannst du dies unten tun.",
        fixedRentModify:
            "Falls du eine Sperre für deine Ferienimmobilie während eines festen Mietvertrags bearbeiten möchtest, schreibe bitte eine E-Mail an",
        details: "Details",
        Night: "Nacht",
        adult: "Erwachsener",
        child: "Kind",
        dog: "Hund",
        at: "im",
        upcomingTabTitle: "Upcoming ({total})",
        previousTabTitle: "Previous",
        adults: "adults",
        children: "children",
    },

    errorMessages: {
        generalError:
            "Leider konnten wir deine Anfrage nicht bearbeiten. Bitte versuche es später erneut. Sollte dieses Problem weiterhin bestehen, kontaktiere bitte {name} {phone}",
        datesNotAvailableError:
            "Einer oder mehrere der Termine für die Sperre sind nicht verfügbar. Überprüfe bitte deine Auswahl und versuche es erneut.",
        createHoldError: "Deine Eigentümersperre konnte nicht angelegt werden.",
        deleteHoldError: "Deine Eigentümersperre konnte nicht entfernt werden.",
        updateHoldError:
            "Deine Eigentümersperre konnte nicht aktualisiert werden.",
        updateHoldCleanError:
            "Dein Reinigungsstatus konnte nicht aktualisiert werden.",
        addGuestToHoldError: "Dein Gast konnte nicht hinzugefügt werden.",
        removeGuestFromHoldError: "Dein Gast konnte nicht entfernt werden.",
        modalTitle: "Oops!",
        modalMessage:
            "It looks like this reservation is no longer in our system.",
        invalidDateError: "This date is unavailable",
        unableToLoadReservations: "We were unable to load your reservations.",
    },

    reservations: {
        reservation: "Reservation",
        vacasaHold: "Vacasa hold",
    },

    switchAccounts: {
        notification: "This reservation is associated with another account",
        description:
            "Please login to the appropriate homeowner account and click the new booking email notification to view the new reservation details.",
        button: "Switch Accounts",
        maybeLater: "Maybe Later",
        descriptionButtom:
            "Interested in linking your accounts into one login? Call <strong>Owner Support</strong> at {phone} to get it set-up.",
    },

    reservationType: {
        ownerHold: "Owner Hold",
        vacasaHold: "Vacasa Hold",
        reservation: "Guest Stay",
    },
}

export default calendar
