import "react-spring-bottom-sheet/dist/style.css"
import styles from "./Select.module.scss"

import { default as classNames, default as classnames } from "classnames"
import { useIsMobile } from "hooks/is-mobile"
import { StarRating } from "lib/components/StarRating/StarRating"
import { CSSProperties, FC, useCallback, useState } from "react"
import { useIntl } from "react-intl"
import { BottomSheet, BottomSheetProps } from "react-spring-bottom-sheet"
import { getClassStyle } from "utils/styles/styleWrapper"
import { ReactComponent as SelectionChevron } from "../../../assets/selection-chevron.svg"
import SelectSearch from "./SelectSearch"
import { ContractStatusBadge, UnitTypeBadge } from "../Badge"

interface SelectContentProps {
    title?: string
    onChange: (value: string) => void
    sections: SelectSection[]
    showItemImage?: boolean
    showRatingIcon?: boolean
    value?: string
}

export interface SelectItem {
    imageURL?: string
    count?: string
    text: string
    displayValue?: string
    value: string
    attributes?: Record<string, unknown>
}

export interface SelectSection {
    title: string
    hideTitle?: boolean
    items: SelectItem[]
}

const displayValue = (
    allRatings: string,
    filterByRating: string,
    selectedItem?: SelectItem
): string => {
    if (selectedItem?.count && selectedItem?.text) {
        return `${selectedItem.text} (${selectedItem.count})`
    }
    if (selectedItem?.value === allRatings) {
        return filterByRating
    }
    return selectedItem?.displayValue ?? selectedItem?.text ?? "Select an item"
}

const SelectContent: FC<SelectContentProps> = props => {
    const { onChange, sections, showItemImage, showRatingIcon, title, value } =
        props

    const intl = useIntl()

    const items = sections.map(section => {
        const sectionItems = section.items.map(item => {
            const isSelected = value === item.value

            const showGuestworksBadge = !!item.attributes?.isGuestworksUnit
            const showOnboardingBadge =
                !!item.attributes?.isOnboarding && !showGuestworksBadge

            return (
                <div
                    key={item.value}
                    className={classNames(styles["select__item"], {
                        [getClassStyle(styles["select__item--selected"])]:
                            isSelected,
                    })}
                    onClick={() => onChange(item.value)}
                >
                    <div className={styles["select__item-details"]}>
                        {showItemImage && (
                            <div className={styles["select__icon"]}>
                                <img
                                    className={styles["select__icon-img"]}
                                    src={item.imageURL}
                                    alt={item.value}
                                />
                            </div>
                        )}
                        {showRatingIcon &&
                            item.value !==
                                intl.formatMessage({
                                    id: "Performance.reviews.allRatings",
                                    defaultMessage: "All Ratings",
                                }) && (
                                <div className={styles["select__star-rating"]}>
                                    <StarRating value={Number(item.value)} />
                                </div>
                            )}
                        <span>{item.text}</span>
                        <div className={styles["select__item-badge"]}>
                            {showGuestworksBadge && (
                                <UnitTypeBadge type="guestworks" />
                            )}
                            {showOnboardingBadge && (
                                <ContractStatusBadge status="onboarding" />
                            )}
                        </div>
                    </div>
                    <div className={styles["select__item-check-wrap"]}>
                        <div
                            className={classNames(
                                styles["select__item-check"],
                                {
                                    [getClassStyle(
                                        styles["select__item-check--selected"]
                                    )]: isSelected,
                                }
                            )}
                        />
                    </div>
                </div>
            )
        })
        return (
            <div key={section.title} className={styles["select__items-wrap"]}>
                {!section.hideTitle && (
                    <div className={styles["select__items-title"]}>
                        {section.title}
                    </div>
                )}
                <div className={styles["select__items"]}>{sectionItems}</div>
            </div>
        )
    })
    return (
        <div className={styles["select__wrap"]}>
            {title && <div className={styles["select__title"]}>{title}</div>}
            {items}
        </div>
    )
}

export type SelectProps = {
    icon?: JSX.Element
    onChange: (value: string) => void
    searchShow?: boolean
    searchPlaceholder?: string
    value?: string
    width?: number
    dropdownEdge?: "left" | "right"
    dropdownCSS?: CSSProperties

    // override snap points for short lists
    defaultSnap?: BottomSheetProps["defaultSnap"]
    snapPoints?: BottomSheetProps["snapPoints"]
} & SelectContentProps

const Select: FC<SelectProps> = props => {
    const {
        icon,
        onChange,
        searchShow,
        searchPlaceholder,
        sections,
        showItemImage,
        showRatingIcon,
        title,
        value,
        width,
        dropdownEdge,
        dropdownCSS,
    } = props

    let { defaultSnap, snapPoints } = props
    const [open, setOpen] = useState(false)
    const isMobile = useIsMobile()
    const [searchTerm, setSearchTerm] = useState("")

    const selectedItem = sections
        .reduce<SelectItem[]>((list, section) => {
            return [...list, ...section.items]
        }, [])
        .find(item => item.value === value)

    const filteredSections = sections.map<SelectSection>(
        ({ title, items }) => ({
            title,
            items: items.filter(
                item =>
                    item.text.toLowerCase().indexOf(searchTerm.toLowerCase()) >
                    -1
            ),
        })
    )

    const renderSearch = useCallback(
        () => (
            <SelectSearch
                show={searchShow}
                onSearchValueChange={value => setSearchTerm(value)}
                placeholder={searchPlaceholder}
                value={searchTerm}
            />
        ),
        [searchPlaceholder, searchShow, searchTerm]
    )

    const onItemSelect = useCallback(
        (value: string) => {
            onChange(value)
            setOpen(false)
        },
        [onChange]
    )

    const renderedContent = useCallback(
        () => (
            <SelectContent
                onChange={onItemSelect}
                sections={filteredSections}
                showItemImage={showItemImage}
                showRatingIcon={showRatingIcon}
                title={title}
                value={value}
            />
        ),
        [
            filteredSections,
            onItemSelect,
            showItemImage,
            showRatingIcon,
            title,
            value,
        ]
    )
    const intl = useIntl()

    if (!defaultSnap) {
        defaultSnap = ({ maxHeight }) => maxHeight / 2
    }

    if (!snapPoints) {
        snapPoints = ({ maxHeight }) => [
            maxHeight - maxHeight / 10,
            maxHeight / 4,
            maxHeight * 0.6,
        ]
    }

    return (
        <>
            <div
                className={classnames(styles["select"], "select")}
                style={{
                    width: `${width}px`,
                }}
            >
                <div
                    className={classnames(
                        styles["select__selection"],
                        "select__selection"
                    )}
                    onClick={() => setOpen(prevValue => !prevValue)}
                    data-testid="select__selection"
                >
                    <div
                        className={classnames(
                            styles["select__selection-text"],
                            "select__selection-text"
                        )}
                    >
                        {(showItemImage || icon) && (
                            <div className={styles["select__icon"]}>
                                {icon ?? (
                                    <img
                                        className={styles["select__icon-img"]}
                                        src={
                                            selectedItem?.imageURL ??
                                            "https://d33zkqzv7i9ae0.cloudfront.net/images/100/0.jpg"
                                        }
                                        alt={selectedItem?.value}
                                    />
                                )}
                            </div>
                        )}
                        <span className="select__display-value">
                            {displayValue(
                                intl.formatMessage({
                                    id: "Performance.reviews.allRatings",
                                    defaultMessage: "All Ratings",
                                }),
                                intl.formatMessage({
                                    id: "Performance.reviews.filterByRating",
                                    defaultMessage: "Filter by Rating",
                                }),
                                selectedItem
                            )}
                        </span>
                    </div>
                    <SelectionChevron
                        className={styles["select__selection-chevron"]}
                    />
                </div>

                {!isMobile && open && (
                    <>
                        <div
                            className={styles["select__dropdown-overlay"]}
                            onClick={() => setOpen(false)}
                        />
                        <div
                            style={dropdownCSS}
                            className={classNames(styles["select__dropdown"], {
                                [getClassStyle(
                                    styles["select__dropdown--left"]
                                )]: dropdownEdge === "left",
                            })}
                        >
                            <div className={styles["select__dropdown-search"]}>
                                {renderSearch()}
                            </div>
                            <div className={styles["select__dropdown-content"]}>
                                {renderedContent()}
                            </div>
                        </div>
                    </>
                )}
            </div>
            {isMobile && (
                <BottomSheet
                    initialFocusRef={false}
                    open={open}
                    onDismiss={() => setOpen(false)}
                    defaultSnap={defaultSnap}
                    snapPoints={snapPoints}
                    header={renderSearch()}
                >
                    {renderedContent()}
                </BottomSheet>
            )}
        </>
    )
}
export default Select
