import { JSONAPIResource } from "@vacasa/owner-api-models"
import { authAxios } from "../../api/authAxios"
import PerformanceMetrics from "@vacasa/owner-api-models/src/PerformanceMetrics"

type FetchPerformanceMetricsRequest = {
    unitId?: string
    intervals?: string
    signal?: AbortSignal
}

const fetchPerformanceMetrics = ({
    unitId,
    intervals,
    signal,
}: FetchPerformanceMetricsRequest): Promise<
    JSONAPIResource<PerformanceMetrics>
> =>
    authAxios
        .get<{ data: JSONAPIResource<PerformanceMetrics> }>(
            `/v1/units/${unitId}/performance-metrics`,
            {
                params: {
                    intervals,
                },
                signal,
            }
        )
        .then(response => response.data.data)

export { fetchPerformanceMetrics }
