import { TicketResponse } from "@vacasa/owner-api-models"
import { authAxios } from "../../api/authAxios"

export type FetchTicketsRequest = {
    contactId: string
    unitId: string
    dispositionIds?: string[]
    signal?: AbortSignal
}

export type FetchTicketsResponse = { data: TicketResponse[], meta:{unreadTickets:number,unreadCompletedTickets:number} }

const fetchTickets = ({
    contactId,
    unitId,
    dispositionIds,
    signal,
}: FetchTicketsRequest): Promise<FetchTicketsResponse> =>
    authAxios
        .get<FetchTicketsResponse>(`/v1/ticket`, {
            params: {
                contactId,
                unitId,
                dispositionId: dispositionIds?.join(","),
            },
            signal,
        })
        .then(response => response.data)

export { fetchTickets }
