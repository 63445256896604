import { JSONAPIResource } from "@vacasa/owner-api-models"
import { Reservation as LegacyReservation } from "@vacasa/owner-api-models/src/legacy"
import { authAxios } from "api/authAxios"
import { RemoveOwnerHoldRequest } from "."

export const deleteOwnerHold = async (params: {
    contactId: string
    unitId: string
    data: RemoveOwnerHoldRequest
    signal?: AbortSignal
}) => {
    const { contactId, data, unitId, signal } = params
    return authAxios
        .delete<{ data: JSONAPIResource<LegacyReservation> }>(
            `/v1/legacy/owners/${contactId}/units/${unitId}/hold`,
            {
                data,
                signal,
            }
        )
        .then(res => res.data.data)
}
