import { createContext, useContext } from "react"

type RevenueChartProviderContext = {
    maxYear: number
    minYear: number
    year: number
    setYear: (year: number) => void
}

export const RevenueChartContext = createContext<RevenueChartProviderContext>({
    maxYear: new Date().getFullYear(),
    minYear: new Date().getFullYear(),
    year: new Date().getFullYear(),
    setYear: () => null,
})

export const useRevenueChartContext = () =>
    useContext<RevenueChartProviderContext>(RevenueChartContext)
