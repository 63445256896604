import { JSONAPIResource, Housekeeping } from "@vacasa/owner-api-models"
import { AxiosError } from "axios"
import { UseQueryResult, useQuery } from "react-query"
import { fetchHousekeeping } from "./fetchHousekeeping"

export const useHousekeeping = (
    params: {
        unitId: string
        contactId: string
    },
    options?: {
        queryKey: string
    }
): UseQueryResult<JSONAPIResource<Housekeeping>, AxiosError> => {
    return useQuery(
        ["housekeeping", params, options?.queryKey],
        ({ signal }) =>
            fetchHousekeeping({
                params,
                signal,
            }),
        {
            enabled: !!params.unitId && !!params.contactId,
            refetchInterval: 1000 * 60 * 10, // 10 minutes
            staleTime: 1000 * 60 * 10, // 10 minutes,
        }
    )
}
