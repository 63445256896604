import {
    Comment,
    MaintenanceTicket,
    RequestedBy,
    TicketCategory,
    TicketCategoryId,
    TicketResponse,
} from "@vacasa/owner-api-models"

const getRequestedBy = (
    requestedBy: string | number | null | undefined
): RequestedBy | null | undefined => {
    if (requestedBy) {
        switch (Number(requestedBy)) {
            case RequestedBy.Owner:
                return RequestedBy.Owner
            case RequestedBy.Guest:
                return RequestedBy.Guest
            case RequestedBy.Employee:
                return RequestedBy.Employee
            default:
                return undefined
        }
    }
    return undefined
}

export const toTicket = (
    maintenanceTicket: MaintenanceTicket
): TicketResponse => {
    const ticket = {
        type: "ticket",
        id: String(maintenanceTicket.TicketID),
        attributes: {
            description: maintenanceTicket.Text,
            title: maintenanceTicket.Title,
            status: maintenanceTicket.Status,
            isComplete: maintenanceTicket.Status >= 9,
            displayStatus: maintenanceTicket.DisplayStatus,
            dispositionId: null,
            unitId: String(maintenanceTicket.UnitID),
            createdBy: String(maintenanceTicket.CreatedBy),
            creationDate: maintenanceTicket.CreationDate,
            completedDate: maintenanceTicket.CompletedDate,
            requestedBy: getRequestedBy(maintenanceTicket.RequestedBy),
            assignedToFirstName: maintenanceTicket.AssignedToFirstName,
            assignedToLastName: maintenanceTicket.AssignedToLastName,
            assignedToEmailAddress: maintenanceTicket.AssignedToEmailAddress,
            assignedToPhone: maintenanceTicket.AssignedToPhone,
            lastReopen:null,
            canBeReopened:false,
            unreadComments:0,
            comments: maintenanceTicket.Comments?.map(comment => {
                const ticketComment = {
                    commentId: comment.CommentID,
                    note: comment.Note,
                    userId: comment.UserID,
                    timestamp: comment.Timestamp,
                    external: comment.External === 1,
                }
                return ticketComment
            }),
        },
    }

    return ticket
}

export const isValidTicketCategory = (
    value: string | null
): value is TicketCategoryId => {
    return Object.values(TicketCategoryId).includes(value as TicketCategoryId)
}

export const getPrepoulatedTicketParams = (
    queryString: string
):
    | { action: undefined; category: undefined }
    | { action: "create"; category: TicketCategoryId } => {
    const params = new URLSearchParams(queryString)
    if (params.get("action") !== "create")
        return { action: undefined, category: undefined }

    const category = params.get("category")

    if (!isValidTicketCategory(category)) {
        return { action: undefined, category: undefined }
    }
    return {
        action: "create",
        category,
    }
}

/**
 * Return the ticket categories as a keyed object, this currently works each category is unique and doesn't appear under multiple parents
 * @param categories
 * @param parentId
 * @param acc
 * @returns {object}
 */
export const toTicketCategoriesKeyedObject = (
    categories: TicketCategory[],
    parentId: TicketCategoryId = TicketCategoryId.Root,
    acc: {
        [key: string]: TicketCategory & { parentId: TicketCategoryId }
    } = {}
) => {
    for (const category of categories) {
        acc[category.id] = {
            ...category,
            parentId: parentId,
        }
        if (category.childCategories.length > 0) {
            toTicketCategoriesKeyedObject(
                category.childCategories,
                category.id,
                acc
            )
        }
    }
    return acc
}

/**
 * Get the number of vacasa creatd comments on a ticket
 * @param ticket
 * @returns Number of vacasa created comments
 */
export const getVacasaCommentsCount = (comments: Comment[]): number => {
    return comments.filter(comment => comment.vacasaCreated).length
}
