import { ReservationType } from "@vacasa/owner-api-models"
import classNames from "classnames"
import { FC } from "react"
import { getClassStyle } from "utils/styles/styleWrapper"
import styles from "./ReservationDate.module.scss"
import { format } from "date-fns"
import { Zap } from "../Icon/utils/react-feature-icon"

export interface Props {
    date: Date
    occupancyType: ReservationType
    showBookingProbabilityIcon?: boolean
}

export const ReservationDate: FC<Props> = ({
    date,
    occupancyType,
    showBookingProbabilityIcon,
}) => {
    return (
        <div
            className={classNames(
                styles.wrap,
                {
                    [getClassStyle(styles.ownerHold)]:
                        occupancyType === ReservationType.OWNERHOLD,
                    [getClassStyle(styles.guestStay)]:
                        occupancyType === ReservationType.RESERVATION,
                    [getClassStyle(styles.vacasaHold)]:
                        occupancyType === ReservationType.VACASAHOLD,
                },
                "reservation-date"
            )}
        >
            <div
                className={classNames(styles.inner, "reservation-date__inner")}
            >
                <span
                    className={classNames(
                        styles.date,
                        "reservation-date__date"
                    )}
                >
                    {date.getDate()}
                </span>
                <span
                    className={classNames(
                        styles.month,
                        "reservation-date__month"
                    )}
                >
                    {format(date, "MMM")}
                </span>
            </div>
            {showBookingProbabilityIcon && (
                <span
                    className={classNames(
                        styles.pobIconWrap,
                        "reservation-date__pob-icon-wrap"
                    )}
                >
                    <Zap
                        className={classNames(
                            styles.pobIcon,
                            "reservation-date__pob-icon"
                        )}
                    />
                </span>
            )}
        </div>
    )
}
