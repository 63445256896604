import { authAxios } from "../../api/authAxios"

export type PostReservationGuestRequest = {
    reservationId: string | number | undefined
    guestId: string
    signal?: AbortSignal
}

const deleteReservationGuests = ({
    reservationId,
    guestId,
    signal,
}: PostReservationGuestRequest): Promise<void> => {
    return authAxios.delete(
        `/v1/reservations/${reservationId}/guests/${guestId}`,

        {
            signal,
        }
    )
}

export { deleteReservationGuests }
