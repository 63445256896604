/* eslint-disable camelcase */
import earningsService from "services/earnings/earnings.service"
import segmentService from "../segment.service"
import {
    BACK_TO_TOP_OPTION,
    CLICK_TRACK_BUTTON_CLICK_EVENT,
    CLICK_TRACK_BUTTON_TYPE,
    FAQ_SECTION_ACTION,
    LINK_CLICK_ACTION,
    NEED_HELP_OPTION,
    OWNER_EARNINGS_REVIEWED_EVENT,
    OWNER_PORTAL_TAG,
    PAYMENT_SETTINGS_OPTION,
    PAYMENT_SETTING_PATH,
    RESERVE_BALANCE_LINE_ENTRY_OPTION,
    SAVE_PAYMENT_METHOD_LABEL,
    STATEMENT_EVENT,
    STATEMENT_PATH,
    TOOL_TIP_CLICK_ACTION,
    UPDATE_RESERVE_BALANCE_LABEL,
} from "./statementTrackingConstants"

export const trackFaqSection = (unitId: string, trackOption?: string): void => {
    segmentService.track(STATEMENT_EVENT, {
        unitId,
        action: FAQ_SECTION_ACTION,
        option: trackOption,
    })
}

export const trackOwnerEarningsReviewed = (contactId: string): void => {
    segmentService.track(OWNER_EARNINGS_REVIEWED_EVENT, {
        category: OWNER_PORTAL_TAG,
        page_version: OWNER_PORTAL_TAG,
        summary_view:
            earningsService.month && earningsService.year
                ? `${earningsService.year}-${earningsService.month}`
                : earningsService.year
                ? earningsService.year
                : false,
        owner_id: contactId,
    })
}

export const trackTooltipClicked = (): void => {
    segmentService.track(STATEMENT_EVENT, {
        action: TOOL_TIP_CLICK_ACTION,
        option: RESERVE_BALANCE_LINE_ENTRY_OPTION,
    })
}

export const trackBackToTopClicked = (): void => {
    segmentService.track(STATEMENT_EVENT, {
        action: LINK_CLICK_ACTION,
        option: BACK_TO_TOP_OPTION,
    })
}

export const trackPaymentSettingClicked = (): void => {
    segmentService.track(STATEMENT_EVENT, {
        action: LINK_CLICK_ACTION,
        option: PAYMENT_SETTINGS_OPTION,
    })
}

export const trackNeedHelpClicked = (): void => {
    segmentService.track(STATEMENT_EVENT, {
        action: LINK_CLICK_ACTION,
        option: NEED_HELP_OPTION,
    })
}

export const trackButtonClicked = (props: { type: string; label: string }) => {
    segmentService.clickTrack(props)
}

export const trackSavePaymentMethodButtonClicked = (
    ownerPaymentDetailsAccountType: string
) => {
    const accountType =
        ownerPaymentDetailsAccountType === "1"
            ? "savings"
            : ownerPaymentDetailsAccountType === "0"
            ? "checking"
            : false
    const evtProps = {
        label: SAVE_PAYMENT_METHOD_LABEL,
        type: CLICK_TRACK_BUTTON_TYPE,
        bank_account: accountType,
    }
    segmentService.clickTrack(evtProps, CLICK_TRACK_BUTTON_CLICK_EVENT)
}

export const trackUpdateReserveBalanceButtonClicked = () => {
    segmentService.clickTrack(
        {
            label: UPDATE_RESERVE_BALANCE_LABEL,
            type: CLICK_TRACK_BUTTON_TYPE,
        },
        CLICK_TRACK_BUTTON_CLICK_EVENT
    )
}

export const trackStatementPageViewed = (): void => {
    segmentService.page({ path: STATEMENT_PATH })
}

export const trackPaymentSettingPageViewed = (): void => {
    segmentService.page({ path: PAYMENT_SETTING_PATH })
}
