import { FC, useCallback, useEffect, useState } from "react"
import { v4 as uuid } from "uuid"
import FullScreenLoader from "./lib/components/Loader/FullScreenLoader"
import App from "./App"
import LoggingService from "./services/logging/logging.service"
import {
    getIdpRefreshUrl,
    redirectToIdp,
    getAccessTokenHash,
} from "./utils/auth/idp"
import "./App.scss"

import {
    PR_S3_BUCKET_URI,
    ATTEMPTED_ROUTE,
    JWT,
    CONTACT_ID,
    UNIT_ID_PARAM,
} from "./Constants"
import { PR_ENVIRONMENT_REDIRECTION_ENABLED } from "./Environment"
import storageService from "services/storage.service"
import { getFromQsOrAttemptedRoute } from "utils/query-string"
import {
    checkAuth,
    checkSession,
    getDecodedToken,
    isAuthIFrame,
    isIdpError,
    isLogout,
    setToken,
} from "utils/auth"
import { useVerifyTokenMutation } from "hooks/verify-token"
import { clearUserCache } from "utils/user"
import { useContacts } from "hooks/contact/useContact"
import { isDefined } from "utils/common"
import { DEFAULT_LOGIN_INFO, LoginInfo, LoginInfoContext } from "contexts/login"
import {
    useOwnerPreferences,
    useOwnerPreferencesMutation,
} from "hooks/owner-preferences"
import { LAST_ACCESSED_CONTACT_ID } from "constants/preferences.constants"
import { Owner } from "@vacasa/owner-api-models"
import { useQueryClient } from "react-query"
import { OWNER_PREFERENCE_QUERY_KEY } from "hooks/owner-preferences/useOwnerPreferences"
import { observer } from "mobx-react"
import { useRootService } from "services"
import {
    checkEmployeeContactIdURL,
    checkForwardMatchesSessionStorage,
    getCurrentUser,
    isSearchForward,
    redirectToOnboardingApp,
    renderOnboardingApp,
} from "utils/app"
import { getSessionConfig } from "utils/session"

const IFRAME_AUTH_INTERVAL = 420000

type AppWrapState = {
    isAuthIframe: boolean
    ready: boolean
    renderAuthIframe: boolean
    idpRefreshUrl?: string
}

/**
 * Responsible for verifying token and refreshing token via an iframe
 */
const AppWrap: FC = observer(() => {
    const [state, setState] = useState<AppWrapState>({
        isAuthIframe: isAuthIFrame(),
        ready: false,
        renderAuthIframe: false,
    })
    const [accessToken] = useState(getAccessTokenHash())

    // Initial unit id to display
    const [unitId] = useState(
        getFromQsOrAttemptedRoute("UnitID") ??
            getFromQsOrAttemptedRoute(UNIT_ID_PARAM) ??
            getSessionConfig().unitId
    )

    // store the contactId from the query string
    const [queryContactId] = useState<string | null>(
        getFromQsOrAttemptedRoute(CONTACT_ID)
    )

    const [loginInfo, setLoginInfo] =
        useState<Omit<LoginInfo, "user" | "users" | "setContactIds">>(
            DEFAULT_LOGIN_INFO
        )

    const verifyTokenMutation = useVerifyTokenMutation()

    useEffect(() => {
        if (state.isAuthIframe) return

        /**
         * This checks if the /forward url "ContactID" parameter matches one stored in session storage.
         * If it doesn't match, we update it to the forwarded one in the url for employees only. This
         * is needed so the portal doesn't load data using the contactId stored in session storage before
         * the /forward url is hit which updates the values.
         */
        checkForwardMatchesSessionStorage()

        // Save attempted route first before any redirect happns
        if (
            window.location.pathname !== "/" &&
            window.location.pathname !== "/logout" &&
            !storageService.localStorage.getItem(ATTEMPTED_ROUTE)
        ) {
            storageService.localStorage.setItem(
                ATTEMPTED_ROUTE,
                window.location.pathname + window.location.search
            )
        }

        // redirect to Idp when there is no token in url and in localStorage
        const shouldRedirectToIdp =
            !accessToken && !storageService.localStorage.getItem(JWT)
        if (shouldRedirectToIdp) {
            redirectToIdp()
            return
        }

        /**
         * Render auth iframe when token is not in url (not coming from a redirect)
         * AND localStorage contains token
         */
        const renderAuthIframe =
            !accessToken && !!storageService.localStorage.getItem(JWT)

        setState({
            renderAuthIframe,
            ready: false,
            isAuthIframe: isAuthIFrame(),
            idpRefreshUrl: getIdpRefreshUrl(),
        })

        window.refresh = refreshToken
        window.displayApp = displayApp
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (state.isAuthIframe) {
            checkForRefreshToken()
        } else {
            if (accessToken) displayApp(accessToken)

            if (isIdpError()) displayApp()

            setRefreshInterval()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const initAuth = async () => {
        // check query string for contact_id
        // TODO: Verify if this is handled correctly still

        const authed = await checkAuth(verifyToken, accessToken)

        /**
         * If there is no token in the url, we check local and session storage for existing values for
         * contactId, contactIds, loginType and forwardURL
         */
        const goodSession = await checkSession(loginInfo => {
            setLoginInfo(prevState => ({
                ...prevState,
                ...loginInfo,
            }))
        })

        if (!authed && !goodSession) {
            clearUserCache()
            redirectToIdp()
            return
        }
    }

    const verifyToken = async (token: string) => {
        await verifyTokenMutation.mutateAsync(token, {
            onSuccess: async response => {
                const data = response.data
                const decodedToken = getDecodedToken(data.token)
                setLoginInfo(prevState => ({
                    ...prevState,
                    loginType: data.loginType,
                    contactIds: data.contactIds.map(String),
                    idpId: decodedToken.sub ?? null,
                }))

                checkEmployeeContactIdURL({
                    isEmployee: data.loginType === "employee",
                    contactId: queryContactId,
                })
            },
        })
    }

    const checkForRefreshToken = (): void => {
        if (!window.parent.displayApp) {
            return
        }

        if (accessToken) {
            LoggingService.log({ message: "Silent auth successful" })
        }
        setState(prevState => ({
            ...prevState,
            renderAuthIframe: false,
        }))
        if (accessToken && window.parent.appDisplayed) {
            window.parent.refresh(accessToken)
        } else {
            window.parent.displayApp(accessToken)
        }
    }

    const displayApp = async (
        token?: string | null
    ): Promise<void | boolean> => {
        if (renderOnboardingApp()) {
            if (token) {
                await initAuth()
                redirectToOnboardingApp()
            }
            return
        }

        const pr = window.location.href.match("pr=([0-9]+)(.+)")

        if (pr !== null && PR_ENVIRONMENT_REDIRECTION_ENABLED) {
            window.location.href = `${PR_S3_BUCKET_URI(Number(pr[1]))}${pr[2]}`
        }

        if (!token && !isSearchForward() && !isIdpError() && !isLogout())
            return redirectToIdp()

        await initAuth()

        setState(prevState => ({
            ...prevState,
            ready: true,
            renderAuthIframe: false,
        }))
        window.appDisplayed = true
    }

    const refreshToken = (token: string): void => {
        setToken(token)
        setState(prevState => ({ ...prevState, renderAuthIframe: false }))
    }

    const setRefreshInterval = (): void => {
        setInterval(() => {
            LoggingService.log({ message: "Refreshing background auth iframe" })
            setState(prevState => ({
                ...prevState,
                idpRefreshUrl: getIdpRefreshUrl(),
                renderAuthIframe: true,
                iframeKey: uuid(),
            }))
        }, IFRAME_AUTH_INTERVAL)
    }

    if (state.isAuthIframe) {
        return null
    }

    const isAppReady = state.ready
    const renderAuthIframe = !state.isAuthIframe && state.renderAuthIframe

    return (
        <>
            {isAppReady && !renderOnboardingApp() ? (
                <AppStart
                    contactIds={loginInfo.contactIds}
                    forwardTo={loginInfo.forwardTo}
                    idpId={loginInfo.idpId}
                    loginType={loginInfo.loginType}
                    userId={loginInfo.userId}
                    queryContactId={queryContactId}
                    unitId={unitId}
                />
            ) : (
                <FullScreenLoader />
            )}
            {renderAuthIframe && (
                <iframe
                    title="auth"
                    key={0} // prevent rerenders with consistent key
                    src={state.idpRefreshUrl}
                    style={{ display: "none" }}
                />
            )}
        </>
    )
})

export default AppWrap

type MobxServiceUserProps = {
    loginType: string
    user: Owner
}

const MobxServiceBridge: FC<MobxServiceUserProps> = ({ loginType, user }) => {
    const { segmentService, earningsService } = useRootService()
    useEffect(() => {
        // Set the services with our user
        // react context cannot be accessed outside react components like mobx, so we have to set it here
        // context may not be the best solution but should work for now. Possible look at a basic state manager like zustand
        segmentService.user = user
        segmentService.isEmployee = loginType === "employee"
        LoggingService.user = user
        earningsService.setUserVariables(user.userId, user.contactId)
    }, [earningsService, loginType, segmentService, user])

    return null
}

type LastAccessedContactUpdateProps = {
    user: Owner | null
    contactIds: string[]
}

/**
 * Component used to update the last accessed contact preference.
 * This is only if the owner does not have a preference set already.
 */
const LastAccessedContactUpdate: FC<LastAccessedContactUpdateProps> = props => {
    const { user, contactIds } = props
    const queryClient = useQueryClient()
    const { mutate, isSuccess, isLoading } = useOwnerPreferencesMutation({
        onSuccess: () => {
            queryClient.invalidateQueries([OWNER_PREFERENCE_QUERY_KEY])
        },
    })

    useEffect(() => {
        if (!user || isSuccess || isLoading) return
        LoggingService.log({
            message: `Assigning first user from user list since no matched - contactId: ${
                user.contactId
            }, contactIds: ${contactIds.join(",")}`,
        })

        mutate({
            id: LAST_ACCESSED_CONTACT_ID,
            value: user.contactId,
        })
    }, [contactIds, isLoading, isSuccess, mutate, user])

    return null
}

type AppStartProps = Omit<LoginInfo, "user" | "users" | "setContactIds"> & {
    queryContactId: string | null
}
/**
 * Start the app by loading user preferences / contacts after token has been verified
 * @param props
 * @returns
 */
const AppStart: FC<AppStartProps> = props => {
    const { queryContactId, ...rest } = props
    const [loginInfo, setLoginInfo] = useState<
        Omit<LoginInfo, "user" | "users">
    >({ ...DEFAULT_LOGIN_INFO, ...rest })

    const contactQueries = useContacts(
        loginInfo.contactIds?.filter(id => String(id) !== "0") ?? []
    )
    const userPreferenceQuery = useOwnerPreferences()

    const setContactIds = useCallback((contactIds: string[]): void => {
        setLoginInfo(prevState => ({
            ...prevState,
            contactIds,
        }))
    }, [])

    const isLoadingContacts = contactQueries.some(c => c.isLoading)

    const isLoading = isLoadingContacts || userPreferenceQuery.isLoading

    const contacts = contactQueries.map(c => c.data).filter(isDefined)
    const { user, hasLastAccessedContact } = getCurrentUser(
        isLoadingContacts ? [] : contacts,
        userPreferenceQuery.data,
        queryContactId
    )

    const updateLastAccessedContact =
        loginInfo.loginType === "owner" &&
        user &&
        !hasLastAccessedContact &&
        !isLoading

    return isLoading ? (
        <FullScreenLoader />
    ) : (
        <>
            {updateLastAccessedContact && (
                <LastAccessedContactUpdate
                    user={user}
                    contactIds={loginInfo.contactIds}
                />
            )}

            {user && loginInfo.loginType && (
                <MobxServiceBridge
                    user={user}
                    loginType={loginInfo.loginType}
                />
            )}
            <LoginInfoContext.Provider
                key="LoginInfoProvider"
                value={{
                    ...loginInfo,
                    users: contacts,
                    user,
                    setContactIds,
                }}
                children={<App />}
            />
        </>
    )
}
