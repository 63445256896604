import * as yup from "yup"

export const useValidation = <T>(schema: yup.Schema<T>) => {
    return {
        validate: async (
            values: T,
            fieldName?: string
        ): Promise<Map<string, string>> => {
            try {
                fieldName
                    ? await schema.validateAt(fieldName, values)
                    : await schema.validate(values, { abortEarly: false })
                return new Map<string, string>()
            } catch (e) {
                return handleValidationError(e)
            }
        },
    }
}

const handleValidationError = (e: unknown): Map<string, string> => {
    const errors = new Map<string, string>()
    if (e instanceof yup.ValidationError) {
        if (e.path && e.message) {
            errors.set(e.path, e.message)
        }
        for (const error of e.inner) {
            if (error.path && error.message) {
                errors.set(error.path, error.message)
            }
        }
    }
    return errors
}
