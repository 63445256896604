import { FocusEvent, useEffect, useState } from "react"
import { Input, InputProps } from "../Input"
import { useIMask } from "react-imask"

type Props = {
    mask: string
} & InputProps

export const MaskedInput = (props: Props) => {
    const { mask, value, onChange, onBlur, onFocus, ...rest } = props
    const [isFocused, setIsFocused] = useState(false)

    const { value: maskedValue, ref: inputRef } = useIMask(
        { mask },
        {
            onAccept: value => {
                onChange?.({
                    target: {
                        name: props.name,
                        value,
                    },
                } as React.ChangeEvent<HTMLInputElement>)
            },
        }
    )

    useEffect(() => {
        onChange?.({
            target: {
                name: props.name,
                value,
            },
        } as React.ChangeEvent<HTMLInputElement>)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const toStarString = () =>
        typeof value === "string" ? value.replaceAll(/[^-]/g, "*") : ""

    /**
     * On focus, show the entered value
     */
    const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
        setIsFocused(true)
        onFocus?.(e)
    }

    /**
     * On blur, replace the entered value with *
     */
    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
        setIsFocused(false)
        onBlur?.(e)
    }

    return (
        <Input
            onFocus={handleFocus}
            onBlur={handleBlur}
            {...rest}
            ref={inputRef as never}
            onChange={() => {}}
            value={isFocused ? maskedValue : toStarString()}
        />
    )
}
