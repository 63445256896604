import React, { useCallback, useEffect, useState } from "react"
import { Legend } from "./Legend"
import { MinRateChart } from "./MinRateChart"
import { MinRateInput } from "./MinRateInput"

import styles from "./RateCalculatorChart.module.scss"
import classNames from "classnames"
import { HomeLineGraph } from "assets/icons"
import { addDays, format } from "date-fns"
import { ISO_FORMAT_DATE_FNS } from "Constants"
import { useRateTransparencyData } from "hooks/rate-transparency"
import { MinRateAnalyzed } from "./MinRateAnalyzed"
import { PotentialImpact } from "./PotentialImpact"
import {
    // useRevenueImpactData,
    useRevenueImpact,
} from "hooks/revenue-impact"
import { FormattedMessage, useIntl } from "react-intl"
import { useUnitStatuses } from "hooks/units"
import { ChartEmptyState } from "../ChartEmptyState"
import { useUnitMinRate } from "hooks/unit-min-rate"
import { RateCalculatorChartSkeleton } from "./RateCalculatorChartSkeleton"
import { useFeatureExperiment } from "hooks/feature-experiment"
import { isDefined } from "utils/common"
import {
    trackRevenueSimulatorFeaturePresented,
    trackRevenueSimulatorResult,
} from "services/segment/dashboard/dashboardTracking"
import { trackOptimizelyEvent } from "utils/feature-experiment"
import { BetaLabel } from "../MarketRateComparisonChart/BetaLabel"
import { RateCalculatorChartFeatureFeedback } from "./RateCalculatorChartFeatureFeedback"
import { useFeatureInteractionAPI } from "contexts/feature-feedback"

export interface RateCalculatorChartProps {
    unitId: string | null
}

export const RateCalculatorChart: React.FC<RateCalculatorChartProps> = ({
    unitId,
}) => {
    const unitMinRate = useUnitMinRate(unitId)
    const { incrementInteractionCount } = useFeatureInteractionAPI()
    const [minRate, setMinRate] = useState(0)
    const [isThumbDown, setIsThumbDown] = useState(false)
    const { isOnboarding } = useUnitStatuses()
    const intl = useIntl()
    const experiment = useFeatureExperiment(
        "revenue-simulator-impact-variations"
    )

    const [dateRange] = useState(() => {
        const start = new Date()
        const end = addDays(start, 366)
        return {
            startDate: format(start, ISO_FORMAT_DATE_FNS),
            endDate: format(end, ISO_FORMAT_DATE_FNS),
        }
    })

    const stakeholder = unitMinRate.data?.attributes.stakeholder
    const defaultMinRate =
        (stakeholder === "owner" || stakeholder === undefined) &&
        unitMinRate.data?.attributes.minRate
            ? unitMinRate.data.attributes.minRate
            : 0

    useEffect(() => {
        setMinRate(defaultMinRate ?? 0)
    }, [defaultMinRate])

    const isDefaultMinRate = useCallback(() => {
        return minRate === defaultMinRate
    }, [defaultMinRate, minRate])

    const data = useRateTransparencyData(
        unitId ?? undefined,
        dateRange.startDate,
        dateRange.endDate
    )

    const query = useRevenueImpact(
        {
            unitId: unitId ?? "",
            minRate,
            ...dateRange,
        },
        {
            enabled: !isDefaultMinRate() && !!unitId && !isThumbDown,
            onSuccess: result => {
                trackRevenueSimulatorResult(result)
                trackOptimizelyEvent("revenue-simulation-completed")
                incrementInteractionCount("minimum-rate-simulator")
            },
        }
    )

    const hasRateTransparencyData = data.isSuccess && !!data.data?.data.length

    // track chart shown with or without data
    useEffect(() => {
        if (!data.isLoading) {
            trackRevenueSimulatorFeaturePresented(hasRateTransparencyData)
        }
    }, [hasRateTransparencyData, data.isLoading])

    if (data.isLoading) {
        return <RateCalculatorChartSkeleton />
    }

    if (isOnboarding || !unitId || !hasRateTransparencyData)
        return (
            <ChartEmptyState
                title={intl.formatMessage({
                    id: "Dashboard.rateCalculator.title",
                    defaultMessage: "Market Rate Comparison",
                })}
                message={intl.formatMessage({
                    id: "Dashboard.rateCalculator.emptyMessage",
                    defaultMessage:
                        "The way your unit's rate compares to the market will appear here once you receive reservations.",
                })}
            />
        )

    // TODO: Get this from owner-api
    const max = Math.floor(
        Math.max(...data.data.data.map(d => d.avgCompMaxRate).filter(isDefined))
    )
    const min = 0

    return (
        <div className={classNames(styles.rateCalculatorChart)}>
            <RateCalculatorChartFeatureFeedback />
            <div className={classNames(styles.header)}>
                <HomeLineGraph />
                <FormattedMessage
                    id="Dashboard.rateCalculator.title"
                    defaultMessage="Explore Minimum Rates"
                />
                <BetaLabel className={styles.betaLabel} />
            </div>
            <div className={classNames("type-body-small", styles.description)}>
                <FormattedMessage
                    id="Dashboard.rateCalculator.description"
                    defaultMessage="Use the slider to explore the revenue impact of Minimum Rates over the next 12 months, whether you have one set or not."
                />
            </div>

            <div className={styles.minRateInput}>
                <MinRateInput
                    defaultValue={defaultMinRate}
                    max={max}
                    min={min}
                    onChange={setMinRate}
                    onThumbDown={() => setIsThumbDown(true)}
                    onThumbUp={() => setIsThumbDown(false)}
                    value={minRate}
                />
            </div>
            <div className={styles.resultRow}>
                <MinRateAnalyzed
                    isLoading={!unitId || unitMinRate.isLoading}
                    minRate={minRate}
                />
                <PotentialImpact
                    isLoading={isThumbDown || query.isLoading}
                    impact={isDefaultMinRate() ? undefined : query.data}
                    variant={experiment.variation ?? undefined}
                />
            </div>
            <MinRateChart
                data={data.data.data}
                minRate={minRate}
                defaultMinRate={defaultMinRate}
            />
            <Legend />
        </div>
    )
}
