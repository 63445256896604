const navigation = {
    onboarding: "Setup",
    supportHub: "Support",
    betaonboarding: "Switch to Onboarding",
    calendar: "Calendario",
    performance: "Rendimiento",
    statements: "Estados",
    maintenance: "Mantenimiento",
    homeInfo: "Información de la propiedad",
    taxes: "Impuestos",
    settings: "Configuraciones",
    new: "Nuevo",
    navigationConfirmationDialog: {
        title: "Before you go",
        message: "Looks like you've made unsaved changes on this page",
        stayButton: "Keep Editing",
        leaveButton: "Discard Changes",
    },
    ownerReferralCard: {
        title: "Refer a friend",
        body: "You get $500, they get $500.",
        action: "Learn more",
    },
    dashboard: "Dashboard",
}

export default navigation
