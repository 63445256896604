import {
    JSONAPIDocument,
    MonthOccupancyMetadata,
} from "@vacasa/owner-api-models"
import { formatISO } from "date-fns"
import { authAxios } from "../../api/authAxios"

export type FetchMonthOccupancyRequest = {
    contactId: string | number
    unitId: string | number
    startDate?: Date
    endDate?: Date
    signal?: AbortSignal
}

const fetchMonthOccupancy = ({
    contactId,
    unitId,
    startDate,
    endDate,
    signal,
}: FetchMonthOccupancyRequest): Promise<MonthOccupancyMetadata> =>
    authAxios
        .get<JSONAPIDocument<MonthOccupancyMetadata>>(
            `/v1/owners/${contactId}/units/${unitId}/month-occupancy`,
            {
                params: {
                    startDate: startDate
                        ? formatISO(startDate, { representation: "date" })
                        : undefined,
                    endDate: endDate
                        ? formatISO(endDate, { representation: "date" })
                        : undefined,
                },
                signal,
            }
        )
        .then(response => response.data.data.attributes)

export { fetchMonthOccupancy }
