import { JWT, LAST_GOOD_SESSION, LOGIN_TYPE, USER_ID } from "Constants"
import { jwtDecode, JwtPayload } from "jwt-decode"
import LoggingService from "services/logging/logging.service"
import storageService from "services/storage.service"
import { handleError, logoutToIdp } from "utils/auth/idp"
import { clearUserCache } from "utils/user"
import { LoginInfo } from "contexts/login"
import { getSessionConfig } from "utils/session"

type CheckAuth = {
    verifyToken: (token: string) => Promise<void>
}

/**
 * Checks if IDP Auth
 * @param verifyToken
 * @returns
 */
export const checkAuth = async (
    verifyToken: CheckAuth["verifyToken"],
    token: string | null
): Promise<boolean> => {
    if (window.location.pathname === "/error") {
        return false
    }
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams && urlParams.get("error")) {
        handleError(
            urlParams.get("error") ?? "",
            urlParams.get("error_description") ?? "",
            null,
            urlParams.get("state")
        )
        return true
    }
    if (token) {
        await verifyToken(token)
        return true
    }
    return false
}

/**
 * Check if the user has a current session by checking for contact ids, user id, and login type
 * @param callback
 * @returns
 */
export const checkSession = async (
    callback: (loginInfo: Partial<LoginInfo>) => void
): Promise<boolean> => {
    const url = new URL(window.location.href)

    const userId = storageService.localStorage.getItem(USER_ID)
    const loginType = storageService.localStorage.getItem(LOGIN_TYPE)
    const sessionConfig = getSessionConfig()

    callback({
        userId: userId,
        contactIds: sessionConfig.contactIds ?? undefined,
        loginType,
    })

    if (!sessionConfig.contactIds) {
        LoggingService.log({
            message: `No contactIds found from session`,
        })
    }

    if (!userId) return false

    // owner requires a contact id, employees don't
    if (loginType === "owner" && !sessionConfig.contactIds) {
        return false
    }

    callback({ forwardTo: url.pathname + url.search })

    return true
}

export const getDecodedToken = (token: string): JwtPayload =>
    jwtDecode<JwtPayload>(token)

/**
 * Set and store the token for the app, this should happen after the token has been verified by `owner-api`
 * @param token
 */
export const setToken = (token: string): void => {
    const decodedToken = getDecodedToken(token)
    const user = decodedToken.sub
        ? decodedToken.sub
        : JSON.stringify(decodedToken)
    storageService.localStorage.setItem(LAST_GOOD_SESSION, user)
    storageService.localStorage.setItem(JWT, token)
}

export const logout = (): void => {
    clearUserCache()
    logoutToIdp()
}

export const isIdpError = (): boolean => window.location.href.includes("?error")

export const isLogout = (): boolean => window.location.href.includes("/logout")

export const isAuthIFrame = () => {
    try {
        return Boolean(window.parent && window.parent.refresh)
    } catch (e: unknown) {
        LoggingService.log({
            message: `iframe check failed (probably because the portal is being loaded in someone else's iframe): ${
                e instanceof Error ? e.message : ""
            }`,
        })
        return false
    }
}
