/* eslint-disable camelcase */
import { format } from "date-fns"
import segmentService from "../segment.service"
import {
    OWNER_HOLD_EDITED_EVENT,
    OWNER_HOLD_CREATED_ACTION,
    OWNER_HOLD_REMOVED_ACTION,
    OWNER_HOLD_UPDATED_ACTION,
    GUEST_INFO_EVENT,
    OWNER_HOLD_GUEST_ADD_ACTION,
    OWNER_HOLD_GUEST_REMOVE_ACTION,
    COST_OF_HOLD_EVENT,
    OWNER_HOLD_ELOCK_VIEWED_EVENT,
    COST_OF_HOLD_PREVIEW_ACTION,
    OWNER_HOLD_GUEST_UPDATE_ACTION,
    OWNER_HOLD_NEXT_BUTTON_CLICKED,
    OWNER_HOLD_SAVE_BUTTON_CLICKED,
    OWNER_HOLD_CANCEL_BUTTON_CLICKED,
    OWNER_HOLD_EDIT_BUTTON_CLICKED,
    OWNER_HOLD_CREATE_HOLD_BUTTON_CLICKED,
} from "./ownerHoldTrackingConstants"

export const trackOwnerHoldCreated = (
    reservation_id: string,
    start_date: string,
    end_date: string,
    clean?: boolean
): void => {
    segmentService.track(OWNER_HOLD_EDITED_EVENT, {
        action: OWNER_HOLD_CREATED_ACTION,
        clean,
        reservation_id,
        start_date,
        end_date,
    })
}

export const trackOwnerHoldRemoved = (
    reservation_id: string,
    clean: boolean
): void => {
    segmentService.track(OWNER_HOLD_EDITED_EVENT, {
        action: OWNER_HOLD_REMOVED_ACTION,
        reservation_id,
        clean,
    })
}

export const trackOwnerHoldUpdated = (
    reservation_id: string,
    clean: boolean
): void => {
    segmentService.track(OWNER_HOLD_EDITED_EVENT, {
        action: OWNER_HOLD_UPDATED_ACTION,
        clean,
        reservation_id,
    })
}

export const trackOwnerHoldGuestAdded = (
    reservation_id: string,
    guest: {
        firstName: string
        lastName: string
        email: string
    }
): void => {
    segmentService.track(GUEST_INFO_EVENT, {
        action: OWNER_HOLD_GUEST_ADD_ACTION,
        email: guest.email,
        name: `${guest.firstName} ${guest.lastName}`,
        reservation_id,
    })
}

export const trackOwnerHoldGuestUpdated = (
    reservation_id: string,
    guest: {
        firstName: string
        lastName: string
        email: string
    }
): void => {
    segmentService.track(GUEST_INFO_EVENT, {
        action: OWNER_HOLD_GUEST_UPDATE_ACTION,
        email: guest.email,
        name: `${guest.firstName} ${guest.lastName}`,
        reservation_id,
    })
}

export const trackOwnerHoldGuestRemoved = (
    reservation_id: string,
    firstName: string,
    lastName: string,
    email: string
): void => {
    segmentService.track(GUEST_INFO_EVENT, {
        action: OWNER_HOLD_GUEST_REMOVE_ACTION,
        email: email,
        name: `${firstName} ${lastName}`,
        reservation_id,
    })
}

export const trackOwnerHoldCost = (
    unit_id: string,
    startDate: Date,
    endDate: Date,
    high_probability_booking_shown: boolean,
    costOfHold: number
): void => {
    segmentService.track(COST_OF_HOLD_EVENT, {
        action: COST_OF_HOLD_PREVIEW_ACTION,
        start_date: format(startDate, "yyyy-MM-dd"),
        end_date: format(endDate, "yyyy-MM-dd"),
        cost_of_hold: costOfHold,
        unit_id,
        high_probability_booking_shown,
    })
}

export const trackOwnerHoldELockViewed = (context: {
    reservationId: string
    event: string
    resource: string
    user: {
        id: string | null
        type: string
    }
}): void => {
    segmentService.track(OWNER_HOLD_ELOCK_VIEWED_EVENT, {
        context,
    })
}

export const trackCreateHoldNextButtonClicked = (
    location: "reservation_form" | "invite_guest_form"
): void => {
    segmentService.track(OWNER_HOLD_NEXT_BUTTON_CLICKED, {
        location,
    })
}

export const trackCreateHoldSaveButtonClicked = (): void => {
    segmentService.track(OWNER_HOLD_SAVE_BUTTON_CLICKED, {
        location: "invite_guest_form",
    })
}

export const trackCreateHoldCancelButtonClicked = (): void => {
    segmentService.track(OWNER_HOLD_CANCEL_BUTTON_CLICKED, {
        location: "invite_guest_form",
    })
}

export const trackCreateHoldEditButtonClicked = (
    item: "dates" | "guest_info" | "notes"
): void => {
    segmentService.track(OWNER_HOLD_EDIT_BUTTON_CLICKED, {
        location: "create_hold_summary",
        item,
    })
}

export const trackCreateHoldButtonClicked = (): void => {
    segmentService.track(OWNER_HOLD_CREATE_HOLD_BUTTON_CLICKED, {})
}
