import React, {
    Fragment,
    MouseEvent,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react"
import {
    addYears,
    endOfMonth,
    format,
    formatISO,
    getMonth,
    getYear,
    setMonth,
    startOfMonth,
    startOfYear,
    subYears,
} from "date-fns"
import IconButton from "@material-ui/core/IconButton"
import { flatten } from "lodash"
import { inject, observer } from "mobx-react"
import { autorun } from "mobx"

import { ReactComponent as TabRightSVG } from "../../assets/icon-tab-right.svg"
import { ReactComponent as TabRightDisabledSVG } from "../../assets/icon-tab-right-disabled.svg"
import { ReactComponent as TabLeftSVG } from "../../assets/icon-tab-left.svg"
import { ReactComponent as TabLeftDisabledSVG } from "../../assets/icon-tab-left-disabled.svg"
import MonthYearDiplay from "./MonthYearDisplay"
import MonthPicker from "../../lib/components/MonthPicker/MonthPicker"
import { getCalendarYearMonths } from "../../utils/calendar/calendarUtil"
import rootService from "../../services/index"
import LoggingService from "../../services/logging/logging.service"
import useRouter from "hooks/common/useRouter"
import { useContactId, useIsEmployee } from "hooks/user"
import { useCurrentUnit } from "hooks/units"

interface Props {
    statementDate: Date
    rootService: typeof rootService
    showSummary: boolean
}

const MonthPickerWrapper: React.FC<Props> = ({
    statementDate,
    rootService: { calendarService, earningsService },
    showSummary,
}: Props) => {
    // Gets months that have active statements
    const getActiveMonths = useCallback(
        (months: Date[]): Date[] => {
            return months.filter(month => {
                if (!earningsService.statements) return false
                return (
                    earningsService.statements[format(month, "yyyyMM")] !==
                    undefined
                )
            })
        },
        [earningsService.statements]
    )

    const initialActiveMonthClass = !showSummary
        ? `active-month month-${format(statementDate, "M")} year-${format(
              statementDate,
              "yyyy"
          )}`
        : null

    const getCurrentYearActiveMonths = useCallback(
        (date: Date) => {
            const currentYearMonths = flatten(getCalendarYearMonths(date))
            const currentYearActiveMonths = getActiveMonths(currentYearMonths)
            return currentYearActiveMonths
        },
        [getActiveMonths]
    )

    const getMonthIndex = (
        currentMonth: string,
        currentYearActiveMonths: Date[]
    ) => {
        const monthIndex = currentYearActiveMonths.findIndex(
            month => format(month, "MMMM") === currentMonth
        )
        return monthIndex
    }

    const initialActiveMonths = getCurrentYearActiveMonths(statementDate)
    const initialMonth = format(statementDate, "MMMM")
    const initialMonthIndex = getMonthIndex(initialMonth, initialActiveMonths)
    calendarService.setDisplayMonth(initialMonth)

    const monthDropdownRef = useRef<HTMLDivElement>(null)
    const [showGrid, setShowGrid] = useState(false)
    const [activeMonthClass, setActiveMonthClass] = useState(
        initialActiveMonthClass
    )
    const [currentMonthIndex, setCurrentMonthIndex] = useState(
        initialMonthIndex < 0 ? 0 : initialMonthIndex
    )
    const [prevMonths, setPrevMonths] = useState([] as Date[])
    const [currentMonths, setCurrentMonths] = useState([] as Date[])
    const [nextMonths, setNextMonths] = useState([] as Date[])
    const router = useRouter()
    const isEmployee = useIsEmployee()
    const contactId = useContactId() ?? ""
    const { unitId } = useCurrentUnit()
    useEffect(() => {
        let unmounted = false
        const onOutsideClick = (event: Event): void => {
            const isTargetRef = (
                ref: React.RefObject<HTMLDivElement>
            ): boolean => {
                return ref.current?.contains(event.target as Node) ?? false
            }
            if (!unmounted && !isTargetRef(monthDropdownRef)) {
                setShowGrid(false)
            }
        }

        window.addEventListener("click", onOutsideClick)
        return (): void => {
            window.removeEventListener("click", onOutsideClick)
            unmounted = true
        }
    }, [])

    useEffect(() => {
        let unmounted = false
        autorun(() => {
            const previousYearMonths = flatten(
                getCalendarYearMonths(subYears(statementDate, 1))
            )
            const previousYearActiveMonths = getActiveMonths(previousYearMonths)
            const currentYearActiveMonths =
                getCurrentYearActiveMonths(statementDate)
            const nextYearMonths = flatten(
                getCalendarYearMonths(addYears(statementDate, 1))
            )
            const nextYearActiveMonths = getActiveMonths(nextYearMonths)

            if (calendarService.displayMonth === "Invalid date") {
                // earningsService.month returns the latest Statement
                // but moment takes it in as an array position, so May
                // would be [5] which shows June, thus the -1
                const displayMonth = Number(earningsService.month) - 1

                calendarService.setDisplayMonth(
                    format(setMonth(new Date(), displayMonth), "MMMM")
                )
            }

            const currentMonth = calendarService.displayMonth
                ? calendarService.displayMonth
                : format(currentYearActiveMonths[0] ?? 0, "MMMM")
            if (!unmounted) {
                const monthIndex = getMonthIndex(
                    currentMonth ?? "",
                    currentYearActiveMonths
                )
                setPrevMonths(previousYearActiveMonths)
                setCurrentMonths(currentYearActiveMonths)
                setNextMonths(nextYearActiveMonths)
                setCurrentMonthIndex(monthIndex)
            }
        })

        return (): void => {
            unmounted = true
        }
    }, [
        calendarService,
        earningsService.month,
        getActiveMonths,
        getCurrentYearActiveMonths,
        statementDate,
    ])

    function renderMonth(month: Date): JSX.Element {
        const monthClass = `active-month month-${format(
            month,
            "M"
        )} year-${format(month, "yyyy")}`
        let activeMonth = false
        if (
            earningsService.statements &&
            Object.keys(earningsService.statements).indexOf(
                format(month, "yyyyMM")
            ) > -1
        )
            activeMonth = true
        if (
            formatISO(startOfMonth(month), { representation: "date" }) >
            formatISO(startOfMonth(new Date()), { representation: "date" })
        )
            activeMonth = false
        if (activeMonth) {
            return (
                <div
                    className={
                        monthClass === activeMonthClass
                            ? `${monthClass} active`
                            : monthClass
                    }
                    onClick={(): void => {
                        setActiveMonthClass(monthClass)
                        router.navigate(
                            `/statements/${getYear(month)}/${format(
                                month,
                                "M"
                            )}`
                        )
                        // selectMonth(month.toDate())
                        fetchStatementsForDay(month)
                    }}
                >
                    {format(month, "MMM")}
                </div>
            )
        }
        return (
            <div
                className="disabled-month"
                onClick={(e: MouseEvent<HTMLElement>): void => {
                    e.preventDefault()
                    e.stopPropagation()
                }}
            >
                {format(month, "MMM")}
            </div>
        )
    }

    function handleForwardTab(): void {
        // nextClickHandler()
        if (nextMonths[0] && showSummary) {
            const nextYear = startOfYear(nextMonths[0])
            if (nextYear) {
                router.navigate(`/statements/${getYear(nextYear)}`)
                fetchStatementsForYear(nextYear)
            }
        } else {
            const eoy = currentMonthIndex === currentMonths.length - 1 // end-of-year
            if (eoy && nextMonths.length === 0) return // End of all months available
            const nextMonth =
                eoy && nextMonths.length > 0
                    ? nextMonths[0]
                    : currentMonths[currentMonthIndex + 1]
            if (nextMonth) {
                router.navigate(
                    `/statements/${getYear(nextMonth)}/${
                        getMonth(nextMonth) + 1
                    }`
                )
            }
            fetchStatementsForDay(nextMonth)
        }
    }

    function handleBackwardTab(): void {
        // previousClickHandler()
        if (showSummary) {
            const prevYear = startOfYear(prevMonths[prevMonths.length - 1] ?? 0)
            if (prevYear) {
                router.navigate(`/statements/${getYear(prevYear)}`)
                fetchStatementsForYear(prevYear)
            }
        } else {
            if (currentMonthIndex === 0 && prevMonths.length === 0) return // End of all months available
            const prevMonth =
                currentMonthIndex === 0
                    ? prevMonths[prevMonths.length - 1]
                    : currentMonths[currentMonthIndex - 1]
            if (prevMonth) {
                router.navigate(
                    `/statements/${getYear(prevMonth)}/${
                        getMonth(prevMonth) + 1
                    }`
                )
            }
            fetchStatementsForDay(prevMonth)
        }
    }

    function fetchStatementsForDay(day: Date | undefined): void {
        if (!contactId) {
            LoggingService.error({
                message: '"contactId" is not set for fetchStatementsForDay',
            })
            return
        }

        if (!day) {
            LoggingService.error({
                message: '"date" is not provided for fetchStatementsForDay',
            })
            return
        }

        calendarService.setSelectedStartDay(
            formatISO(startOfMonth(day), { representation: "date" })
        )
        calendarService.setSelectedEndDay(
            formatISO(endOfMonth(day), { representation: "date" })
        )
        calendarService.setDisplayMonth(format(startOfMonth(day), "MMMM"))
        calendarService.setSelectedYear(format(startOfMonth(day), "yyyy"))
        earningsService
            .fetchStatementByUnitID(
                contactId,
                unitId ?? "",
                calendarService.selectedStartDay,
                calendarService.selectedEndDay,
                null,
                isEmployee
            )
            .catch(reason => {
                // TODO add error UI
                LoggingService.error({
                    message: "Failed to fetch statement by unitId",
                    error: reason,
                })
            })
    }

    function fetchStatementsForYear(year: Date) {
        if (!contactId) {
            LoggingService.error({
                message: '"contactId" is not set for fetchStatementsForYear',
            })
            return
        }

        calendarService.setSelectedYear(format(year, "yyyy"))
        earningsService
            .fetchStatementByUnitID(
                contactId,
                unitId ?? "",
                null,
                null,
                year,
                isEmployee
            )
            .catch(reason => {
                // TODO add error UI
                LoggingService.error({
                    message: "Failed to fetch statement by unitId",
                    error: reason,
                })
            })
    }

    function enablePreviousTab(): boolean {
        if (earningsService.isYearEnd) {
            return prevMonths.length > 0
        } else {
            return currentMonthIndex - 1 >= 0 || prevMonths.length > 0
        }
    }

    function enableNextTab(): boolean {
        if (currentMonthIndex < 0) {
            setCurrentMonthIndex(initialMonthIndex < 0 ? 0 : initialMonthIndex)
            calendarService.setDisplayMonth(initialMonth)
        }
        if (earningsService.isYearEnd) {
            return nextMonths.length > 0
        } else {
            return (
                currentMonthIndex + 1 < currentMonths.length ||
                nextMonths.length > 0
            )
        }
    }
    return (
        <Fragment>
            <div>
                <div
                    className="month-picker-dropdown"
                    onClick={(): void => setShowGrid(true)}
                    ref={monthDropdownRef}
                >
                    <MonthYearDiplay
                        month={
                            !showSummary ? getMonth(statementDate) : undefined
                        }
                        year={getYear(statementDate)}
                    />
                </div>
                {enablePreviousTab() ? (
                    <IconButton
                        aria-label={"Tab Month Left"}
                        className="month-picker-tab-button"
                        onClick={(): void => handleBackwardTab()}
                    >
                        <TabLeftSVG />
                    </IconButton>
                ) : (
                    <IconButton
                        aria-label={"Tab Month Left"}
                        className="month-picker-tab-button"
                        disabled
                    >
                        <TabLeftDisabledSVG />
                    </IconButton>
                )}
                {enableNextTab() ? (
                    <IconButton
                        aria-label={"Tab Month Right"}
                        className="month-picker-tab-button"
                        onClick={(): void => handleForwardTab()}
                    >
                        <TabRightSVG />
                    </IconButton>
                ) : (
                    <IconButton
                        aria-label={"Tab Month Right"}
                        className="month-picker-tab-button"
                        disabled
                    >
                        <TabRightDisabledSVG />
                    </IconButton>
                )}
            </div>
            {showGrid && (
                <MonthPicker
                    onClick={(): void => setShowGrid(false)}
                    initialVisibleYear={() => statementDate}
                    renderMonth={(month: Date): JSX.Element =>
                        renderMonth(month)
                    }
                />
            )}
        </Fragment>
    )
}

export default inject("rootService")(observer(MonthPickerWrapper))
