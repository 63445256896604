const unitSelector = {
    allRentals: "Todas las propiedades",
    search: "buscar",
    searchRentals: "Buscar tus propiedades",
    allActive: "Todas las propiedades activas",
    rentals: "propiedades",
    inactive: "Inactiva",
    inactiveRentals: "Propiedades inactivas",
    unitSelectorAria:
        "Usa este selector de unidad para cambiar qué unidad se encuentra en la vista actual.",
}

export default unitSelector
