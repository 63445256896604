import React, { Fragment, PropsWithChildren } from "react"
import { Row, Col } from "reactstrap"

interface UnitListRowProps {
    title: string
    details: JSX.Element | string
    action?: JSX.Element
    media?: JSX.Element
    small?: boolean
    border?: boolean
    footer?: JSX.Element | null
}

const UnitListRow: React.FunctionComponent<
    PropsWithChildren<UnitListRowProps>
> = ({ title, details, action, media, small, border, children, footer }) => {
    const rowTopStyle = !border ? { borderTop: "none" } : undefined
    if (children)
        return (
            <Fragment>
                <div style={rowTopStyle}>{children}</div>
                <div style={rowTopStyle}>
                    <span className="float-right" />
                </div>
            </Fragment>
        )

    if (details || title)
        return (
            <Row className="unit-list-details" style={rowTopStyle}>
                <Col lg="7" xs="12">
                    {media && <div className="unit-image">{media}</div>}
                    <div className="unit-text">
                        {small && (
                            <Fragment>
                                <p className="unit-info-primary small">
                                    {title}
                                </p>
                                <label className="unit-info-secondary-small">
                                    {details}
                                </label>
                            </Fragment>
                        )}
                        {!small && (
                            <Fragment>
                                <h5 className="unit-info-primary">
                                    <span>{title}</span>
                                </h5>
                                <div className="unit-info-secondary">
                                    {details}
                                </div>
                            </Fragment>
                        )}
                    </div>
                </Col>
                <Col lg="5" xs="12" className="list-right">
                    <div>{action && <Fragment>{action}</Fragment>}</div>
                </Col>
                {footer && footer}
            </Row>
        )
    return null
}

export default UnitListRow
