import React, { Fragment, useCallback, useMemo } from "react"

import { toJS } from "mobx"

// Components
import { Card, CardContent } from "../../components/Card"
import Loader from "../../lib/components/Loader"
import InActiveUnit from "./InActiveUnit"
import UnitSummarySummaryView from "./UnitSummarySummaryView"
import UnitSummaryDetailsView from "./UnitSummryDetailsView/UnitSummaryDetailsView"

import { useRootService } from "../../services"

// import types
import {
    EarningsResponse,
    Unit,
} from "@vacasa/owner-api-models/src/legacy/Earnings"

export type Summary = EarningsResponse["data"] // create type Summary from type EarningsResponse["data"] to make its name more specific
type UnitSummaryProps = {
    currency: string
    scrollToFAQ: () => void
    faqOpenCallback: () => void
    viewOptions: {
        [key: string]: {
            [key: string]: boolean
        }
    }
    updateViewOptions: (unitId: string, key: string, value: boolean) => void
}

const UnitSummary: React.FC<UnitSummaryProps> = props => {
    const {
        earningsService: { updatingSummary, loadingSummary, statement },
    } = useRootService()
    const {
        currency,
        scrollToFAQ,
        faqOpenCallback,
        updateViewOptions,
        viewOptions,
    } = props

    /**
     * Method to check if an unit is in summary view
     */
    const isSummaryView = useCallback(
        (unitSummary: Unit): boolean => {
            return viewOptions[unitSummary.id]?.["summaryView"] === true
        },
        [viewOptions]
    )

    /**
     * Method to change view of an unit from details to summary, and vice verse.
     * No effect if an unit is in detailed view, and user click detail button. Same goes for Summary view
     */
    const changeView = useCallback(
        (showDetails: boolean, id: string) => {
            const unitid = id.replace(/^\D+/g, "")
            updateViewOptions(unitid, "summaryView", !showDetails)
        },
        [updateViewOptions]
    )

    /**
     * Method to render an unit either in detail view or summary view
     */
    const renderUnit = useCallback(
        (unit: Unit, summary: Summary): React.ReactNode => {
            if (!unit.hasContract) {
                return <InActiveUnit unit={unit} />
            }
            // check if unit in summary view mode
            const summaryView = isSummaryView(unit)
            return (
                <>
                    {summaryView ? (
                        <Card>
                            <CardContent>
                                <UnitSummarySummaryView
                                    unit={unit}
                                    changeView={changeView}
                                    viewKey={`view-${unit.id}`}
                                    currency={currency}
                                />
                            </CardContent>
                        </Card>
                    ) : (
                        <Card>
                            <CardContent>
                                <UnitSummaryDetailsView
                                    unit={unit}
                                    changeView={changeView}
                                    viewKey={`view-${unit.id}`}
                                    summary={summary}
                                    currency={currency}
                                    scrollToFAQ={scrollToFAQ}
                                    onFAQOpen={faqOpenCallback}
                                />
                            </CardContent>
                        </Card>
                    )}
                </>
            )
        },
        [isSummaryView, changeView, currency, scrollToFAQ, faqOpenCallback]
    )

    /**
     * Method to render multiple units
     * Skip units that has no contract as of existing behaviour of the legacy code
     */
    const renderAllUnits = useCallback(
        (summary: Summary | null): React.ReactNode => {
            if (!summary) {
                return null
            }

            /**
             * Dont show units that has no contract here. legacy code
             */
            return summary.units
                .filter(unit => unit.hasContract)
                .map((unit, index) => {
                    let marginTopStyle = {}
                    if (index !== 0) {
                        marginTopStyle = { marginTop: "24px" }
                    }
                    return (
                        <div key={index} style={marginTopStyle}>
                            {renderUnit(unit, summary)}
                        </div>
                    )
                })
        },
        [renderUnit]
    )

    /**
     * render UI for UnitSummary component
     */
    const shallowSummary = useMemo(() => toJS(statement), [statement])

    if (loadingSummary) {
        return null
    }
    if (updatingSummary) {
        return <Loader />
    }

    return (
        <Fragment>
            <div key={"unit-summary"} className="unit-summary">
                {renderAllUnits(shallowSummary as Summary)}
            </div>
        </Fragment>
    )
}
export default UnitSummary
