/* eslint-disable camelcase */

import segmentService from "../segment.service"
import { TOOLTIP_CLICKED } from "./tooltipsTrackingConstants"

export const trackTooltipClicked = (
    tooltipName: string,
    params: Record<string, unknown> = {}
) => {
    segmentService.track(
        TOOLTIP_CLICKED.replace("<TooltipName>", tooltipName),
        params
    )
}
