const unitSelector = {
    allRentals: "Összes nyaraló",
    search: "keresés",
    searchRentals: "Keresés a nyaralók között",
    allActive: "Aktív nyaralók",
    rentals: "nyaralók",
    inactive: "Inaktív",
    inactiveRentals: "Inaktív nyaralók",
    unitSelectorAria:
        "Az egységkiválasztó segítségével módosíthatod, hogy melyik egység legyen az aktuális nézetben.",
}

export default unitSelector
