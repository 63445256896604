import React, { ChangeEvent } from "react"
import { ToggleInfo } from "./ToggleType"

interface ToggleProps {
    checked?: boolean
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void
    toggleInfo: ToggleInfo
}

const Toggle: React.FC<ToggleProps> = (props: ToggleProps) => {
    const inputID = props.toggleInfo.inputID
    const inputName = props.toggleInfo.inputName
    const inputValue = props.toggleInfo.inputValue
    const labelID = props.toggleInfo.labelID

    // Allow keyboard users to toggle
    const onKeyDown = (event: React.KeyboardEvent<HTMLLabelElement>): void => {
        if (event.code === "Enter" || event.code === "Space") {
            toggleInput()
        }
    }

    const toggleInput = (): void => {
        const inputElement = document.getElementById(
            inputID
        ) as HTMLInputElement
        inputElement.click()
    }

    return (
        <div className="toggle-container">
            <input
                type="checkbox"
                checked={props.checked}
                onChange={props.onChange}
                id={inputID}
                name={inputName}
                value={inputValue}
            />
            <label
                htmlFor={inputID}
                aria-label={props.toggleInfo.ariaLabel}
                tabIndex={0}
                onKeyDown={onKeyDown}
                id={labelID}
            />
        </div>
    )
}

export default Toggle
