const topbar = {
    myRentals: "Mé rekreační pronájmy",
    myTrips: "Mé pobyty",
    profile: "Profil",
    changePassword: "Změnit heslo",
    logout: "Odhlásit se",
    switchAccounts: "Switch Accounts",
    selectAccount: "Select An Account",
    referral: "Refer a friend",
}

export default topbar
