import { ListItemIcon, ListItemText, MenuItem, Select } from "@material-ui/core"
import { OwnerHoldType } from "@vacasa/owner-api-models"
import classnames from "classnames"
import React from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { Label } from ".."
import { getOwnerHoldTypeIcon } from "../../../../../../utils/holdType/holdTypeUtil"
import styles from "./HoldTypeSelect.module.scss"
import { useIsEmployee } from "hooks/user"

export type DropdownValue = string | number

export interface HoldTypesSelectProps {
    holdType: OwnerHoldType | null
    onChange: (value?: DropdownValue) => void
    enabled?: boolean
}

export const HoldTypeSelect: React.FC<HoldTypesSelectProps> = ({
    holdType,
    onChange,
    enabled,
}) => {
    const intl = useIntl()
    const isEmployee = useIsEmployee()

    const placeholder = {
        value: "",
        title: intl.formatMessage({
            id: "CalendarPage.flyout.selectOption",
            defaultMessage: "Select an option",
        }),
    }

    const options: {
        value: OwnerHoldType
        title: string
        subtitle: string
        testId: string
    }[] = [
        {
            value: "owner stay",
            title: intl.formatMessage({
                id: "CalendarPage.flyout.ownerStay",
                defaultMessage: "Owner stay",
            }),
            subtitle: intl.formatMessage({
                id: "CalendarPage.flyout.ownerStaySubtitle",
                defaultMessage: "A reservation for your own personal use.",
            }),
            testId: "owner-stay-option",
        },
        {
            value: "complimentary stay",
            title: intl.formatMessage({
                id: "CalendarPage.flyout.complimentaryStay",
                defaultMessage: "Complimentary stay",
            }),
            subtitle: intl.formatMessage({
                id: "CalendarPage.flyout.complimentaryStaySubtitle",
                defaultMessage: "A reservation for friends or family.",
            }),
            testId: "complimentary-stay-option",
        },
        {
            value: "property care",
            title: intl.formatMessage({
                id: "CalendarPage.flyout.propertyCare",
                defaultMessage: "Property care",
            }),
            subtitle: intl.formatMessage({
                id: "CalendarPage.flyout.propertyCareSubtitle",
                defaultMessage: "Maintenance, upgrades, deliveries, etc.",
            }),
            testId: "property-care-option",
        },
        {
            value: "seasonal hold",
            title: intl.formatMessage({
                id: "CalendarPage.flyout.seasonalHold",
                defaultMessage: "Seasonal hold",
            }),
            subtitle: intl.formatMessage({
                id: "CalendarPage.flyout.seasonalHoldSubtitle",
                defaultMessage: "Close your home to guests for a season",
            }),
            testId: "seasonal-hold-option",
        },
        {
            value: "home for sale",
            title: intl.formatMessage({
                id: "CalendarPage.flyout.homeForSale",
                defaultMessage: "Home for sale",
            }),
            subtitle: intl.formatMessage({
                id: "CalendarPage.flyout.homeForSaleSubtitle",
                defaultMessage: "Activities related to a home sale.",
            }),
            testId: "home-for-sale-option",
        },
        {
            value: "other",
            title: intl.formatMessage({
                id: "CalendarPage.flyout.other",
                defaultMessage: "Other",
            }),
            subtitle: intl.formatMessage({
                id: "CalendarPage.flyout.otherSubtitle",
                defaultMessage: "Does not fit the other categories.",
            }),
            testId: "other-option",
        },
    ]

    if (isEmployee) {
        options.splice(options.length - 1, 0, {
            value: "termination hold",
            title: intl.formatMessage({
                id: "CalendarPage.flyout.terminationHold",
                defaultMessage: "Termination hold",
            }),
            subtitle: intl.formatMessage({
                id: "CalendarPage.flyout.terminationHoldSubtitle",
                defaultMessage: "Option only available for employees",
            }),
            testId: "termination-hold-option",
        })
    }

    return (
        <div
            className={classnames(
                styles["hold-type-select"],
                "hold-type-select"
            )}
        >
            <Label>
                <FormattedMessage
                    id="Calendar.OwnerHolds.holdType"
                    defaultMessage="Hold Reason"
                />
            </Label>
            <div className={styles["wrapper"]} data-testid="hold-type-select">
                <Select
                    disabled={enabled === false}
                    classes={{
                        select: styles["MuiSelect-select"],
                        icon: styles["MuiSelect-icon"],
                    }}
                    value={holdType ?? ""}
                    onChange={(event): void => {
                        onChange(event.target.value as DropdownValue)
                    }}
                    id="dropdown-select"
                    displayEmpty
                    disableUnderline
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                        },
                        getContentAnchorEl: null,
                        classes: {
                            paper: styles["Select-paper"],
                        },
                        disableScrollLock: true,
                    }}
                >
                    <MenuItem
                        classes={{
                            root: styles["MenuItem-placeholder"],
                        }}
                        key={"dropdown_placeholder"}
                        value={placeholder.value}
                        disabled
                    >
                        <ListItemText
                            classes={{
                                root: styles["ListItemText-root"],
                                primary: styles["ListItemText-placeholder"],
                            }}
                            primary={placeholder.title}
                        />
                    </MenuItem>
                    {options.map(option => {
                        const HoldTypeIcon = getOwnerHoldTypeIcon(option.value)
                        return (
                            <MenuItem
                                classes={{
                                    root: styles["MenuItem-root"],
                                    selected: styles["MenuItem-root--selected"],
                                }}
                                key={option.value}
                                value={option.value}
                            >
                                {HoldTypeIcon && (
                                    <ListItemIcon
                                        classes={{
                                            root: styles["ListItemIcon-root"],
                                        }}
                                    >
                                        <HoldTypeIcon />
                                    </ListItemIcon>
                                )}
                                <ListItemText
                                    classes={{
                                        root: styles["ListItemText-root"],
                                        primary: styles["ListItemText-primary"],
                                        secondary:
                                            styles["ListItemText-secondary"],
                                    }}
                                    primary={option.title}
                                    secondary={option.subtitle}
                                    data-testid={option.testId}
                                />
                            </MenuItem>
                        )
                    })}
                </Select>
            </div>
        </div>
    )
}
