import { uniqueId } from "lodash"
import React, { PropsWithChildren, useState } from "react"
import styles from "./RadioGroup.module.scss"
import classNames from "classnames"

type RadioGroupProps = {
    label?: string
    className?: string
}

const RadioGroup: React.FC<PropsWithChildren<RadioGroupProps>> = ({
    label,
    className,
    children,
}) => {
    const [id] = useState(uniqueId("radio-group"))
    return (
        <div
            className={classNames(styles.radioGroup, className)}
            role="radiogroup"
            id={id}
        >
            <label htmlFor={id}>{label}</label>
            {children}
        </div>
    )
}

export { RadioGroup }
