/* eslint-disable camelcase */
import { Amenities, Bathrooms, Parking } from "@vacasa/owner-api-models"
import { Generic as DropdownOption } from "../../../models/Generic"
import { RoomModel } from "../../../models/Room"
import segmentService from "../segment.service"
import {
    LISTING_DETAILS_UPDATED_EVENT,
    NAVIGATE_TO_ONBOARDING_EVENT,
    NAVIGATION_CLICK_ACTION,
    REVIEW_LISTING_DETAILS,
    SETUP_EVENT,
    SUBMIT_AVAILABILITY_ACTION,
    SUBMIT_AVAILABILITY_ALERT_CLICKED_ACTION,
    SUBMIT_AVAILABILITY_ALERT_CLICKED_EVENT,
    SUBMIT_AVAILABILITY_ALERT_DISPLAYED_ACTION,
    SUBMIT_AVAILABILITY_ALERT_DISPLAYED_EVENT,
    SUBMIT_AVAILABILITY_CALENDAR_DATE_CLICKED_ACTION,
    SUBMIT_AVAILABILITY_CALENDAR_DATE_CLICKED_EVENT,
    SUBMIT_AVAILABILITY_EDIT_NOTES_ACTION,
    SUBMIT_AVAILABILITY_EDIT_NOTES_EVENT,
    SUBMIT_AVAILABILITY_EVENT,
    UPDATE_AMENITIES_ACTION,
    UPDATE_BATHROOMS_ACTION,
    UPDATE_BEDS_ACTION,
    UPDATE_KITCHEN_ACTION,
    UPDATE_PARKING_ACTION,
    VIEW_CALENDAR_ACTION,
} from "./onboardingTrackingConstants"
import { getAddedRemovedCount } from "./onboardingTrackingUtils"

export const trackAlertDisplayed = (unitId: string): void => {
    segmentService.track(SUBMIT_AVAILABILITY_ALERT_DISPLAYED_EVENT, {
        unitId,
        action: SUBMIT_AVAILABILITY_ALERT_DISPLAYED_ACTION,
    })
}

export const trackEditNotes = (unitId: string): void => {
    segmentService.track(SUBMIT_AVAILABILITY_EDIT_NOTES_EVENT, {
        unitId,
        action: SUBMIT_AVAILABILITY_EDIT_NOTES_ACTION,
    })
}

export const trackSubmitAvailability = (unitId: string): void => {
    segmentService.track(SUBMIT_AVAILABILITY_EVENT, {
        unitId,
        action: SUBMIT_AVAILABILITY_ACTION,
    })
}

export const trackSubmitAvailabilityAlertClicked = (unitId: string): void => {
    segmentService.track(SUBMIT_AVAILABILITY_ALERT_CLICKED_EVENT, {
        unitId,
        action: SUBMIT_AVAILABILITY_ALERT_CLICKED_ACTION,
    })
}

export const trackSubmitAvailabilityCalendarDateClicked = (
    unitId: string
): void => {
    segmentService.track(SUBMIT_AVAILABILITY_CALENDAR_DATE_CLICKED_EVENT, {
        unitId,
        action: SUBMIT_AVAILABILITY_CALENDAR_DATE_CLICKED_ACTION,
    })
}

export const trackUpdateAmenities = (
    tempAmenities: Amenities,
    apiAmenities: Amenities,
    hotTubOptions: DropdownOption[],
    unitId: string
): void => {
    segmentService.track(LISTING_DETAILS_UPDATED_EVENT, {
        unitId,
        action: UPDATE_AMENITIES_ACTION,
        dogs: tempAmenities.petsFriendly,
        previous_dogs: apiAmenities.petsFriendly,
        hot_tub: hotTubOptions.find(
            hotTubOption => hotTubOption.id === tempAmenities.hotTub
        )?.name,
        previous_hot_tub: hotTubOptions.find(
            hotTubOption => hotTubOption.id === apiAmenities.hotTub
        )?.name,
    })
}

export const trackUpdateBathrooms = (
    tempBathrooms: Bathrooms,
    apiBathrooms: Bathrooms,
    unitId: string
): void => {
    segmentService.track(LISTING_DETAILS_UPDATED_EVENT, {
        unitId,
        action: UPDATE_BATHROOMS_ACTION,
        bathrooms: tempBathrooms.full,
        previous_bathrooms: apiBathrooms.full,
        half_bathrooms: tempBathrooms.half,
        previous_half_bathrooms: apiBathrooms.half,
    })
}

export const trackUpdateKitchen = (
    kitchenTypes: DropdownOption[],
    unitId: string,
    tempKitchen?: number | null,
    apiKitchen?: number | null
): void => {
    segmentService.track(LISTING_DETAILS_UPDATED_EVENT, {
        unitId,
        action: UPDATE_KITCHEN_ACTION,
        kitchen: kitchenTypes.find(
            kitchenType => kitchenType.id === tempKitchen
        )?.name,
        previous_kitchen: kitchenTypes.find(
            kitchenType => kitchenType.id === apiKitchen
        )?.name,
    })
}

export const trackUpdateParking = (
    tempParking: Parking,
    apiParking: Parking,
    fourWDRequiredOptions: DropdownOption[],
    unitId: string
): void => {
    segmentService.track(LISTING_DETAILS_UPDATED_EVENT, {
        unitId,
        action: UPDATE_PARKING_ACTION,
        parking_spaces: tempParking.total,
        previous_parking_spaces: apiParking.total,
        four_wheel_required: fourWDRequiredOptions.find(
            fourWDRequiredOptions =>
                fourWDRequiredOptions.id === tempParking.fourWheelDriveRequired
        )?.name,
        previous_four_wheel_required: fourWDRequiredOptions.find(
            fourWDRequiredOptions =>
                fourWDRequiredOptions.id === apiParking.fourWheelDriveRequired
        )?.name,
        paid_parking: tempParking.paid,
        previous_paid_parking: apiParking.paid,
        valet_parking: tempParking.valet,
        previous_valet_parking: apiParking.valet,
        accessible_parking: tempParking.accessible,
        previous_accessible_parking: apiParking.accessible,
        street_parking: tempParking.street,
        previous_street_parking: apiParking.street,
    })
}

export const trackUpdateSleepingArrangments = (
    unitId: string,
    tempModel: RoomModel[],
    apiModel: RoomModel[]
): void => {
    const existingRoomsCount = apiModel.length
    const roomsCount = tempModel.length
    const totalBedroomsAdded =
        roomsCount > existingRoomsCount ? roomsCount - existingRoomsCount : 0
    const totalBedroomsRemoved =
        existingRoomsCount > roomsCount ? existingRoomsCount - roomsCount : 0

    const kings = getAddedRemovedCount(1, apiModel, tempModel)
    const queens = getAddedRemovedCount(2, apiModel, tempModel)
    const fulls = getAddedRemovedCount(3, apiModel, tempModel)
    const twins = getAddedRemovedCount(4, apiModel, tempModel)
    const child = getAddedRemovedCount(5, apiModel, tempModel)
    const cribs = getAddedRemovedCount(6, apiModel, tempModel)

    segmentService.track(LISTING_DETAILS_UPDATED_EVENT, {
        unitId,
        action: UPDATE_BEDS_ACTION,
        total_bedrooms_added: totalBedroomsAdded,
        total_bedrooms_removed: totalBedroomsRemoved,
        kings_added: kings[0],
        kings_removed: kings[1],
        queens_added: queens[0],
        queens_removed: queens[1],
        fulls_added: fulls[0],
        fulls_removed: fulls[1],
        twins_added: twins[0],
        twins_removed: twins[1],
        child_added: child[0],
        child_removed: child[1],
        cribs_added: cribs[0],
        cribs_removed: cribs[1],
    })
}

export const trackCalendarViewedFromSetup = (unitId: string): void => {
    segmentService.track(SETUP_EVENT, {
        unitId,
        action: VIEW_CALENDAR_ACTION,
    })
}

export const trackNavigateToOnboardingClicked = (): void => {
    segmentService.track(NAVIGATE_TO_ONBOARDING_EVENT, {
        action: NAVIGATION_CLICK_ACTION,
    })
}

export const trackListingDetails = (
    action: string,
    option?: string,
    current_section?: string,
    unitId?: string | null
): void => {
    const type = LISTING_DETAILS_UPDATED_EVENT
    const trackingObj: { [key: string]: string } = {
        action,
        unit_id: String(unitId),
    }
    if (option) {
        trackingObj["option"] = option
    }
    if (current_section) {
        trackingObj["current_section"] = current_section
    }
    segmentService.track(type, trackingObj)
}

export const trackSetupPage = (): void => {
    segmentService.page(SETUP_EVENT)
}

export const trackListingPage = (): void => {
    segmentService.page(REVIEW_LISTING_DETAILS)
}
