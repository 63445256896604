import React, { Fragment, useEffect, useState } from "react"
import { observer } from "mobx-react"
import { Col, Row } from "reactstrap"
import { flatMap, groupBy, uniqBy, uniqueId } from "lodash"
import deepEqual from "deep-equal"
import { Tooltip, useMediaQuery } from "@material-ui/core"
import classnames from "classnames"

// Components
import Panel from "../OnboardingPanel"
import Loader from "../../../lib/components/Loader"
import AddRoomButtonRow from "../../../lib/components/Onboarding/AddRoomButtonRow"
import RoomRow from "../../../lib/components/Onboarding/RoomRow"
import SubmitButtonRow, {
    ButtonState,
} from "../../../lib/components/Onboarding/SubmitButtonRow"
import ConfirmButtonRow from "../../../lib/components/Onboarding/ConfirmButtonRow"
import ErrorComponent from "../ErrorComponent"

// Models
import { updateArray } from "../../../utils/array/updateArray/updateArray"
import { RoomModel } from "../../../models/Room"
import { Generic as BedSize } from "../../../models/Generic"

// Assets
import { ReactComponent as BedSVG } from "../../../assets/icon-bed.svg"
import { ReactComponent as DoorSVG } from "../../../assets/icon-door.svg"
import { ReactComponent as AlertSVG } from "../../../assets/icon-alert.svg"
import styles from "../../../sass/onboarding.module.scss"
import LoggingService from "../../../services/logging/logging.service"
import { FormattedMessage } from "react-intl"
import { trackUpdateSleepingArrangments } from "services/segment/onboarding/onboardingTracking"
import {
    useUnitRoomOptions,
    useUnitRooms,
    useUnitRoomsMutation,
} from "hooks/units"
import { useContactId } from "hooks/user"
import { isUnauthorizedOrCancelledRequest } from "utils/error/error"

interface SleepingArrangementProps {
    confirmedBedrooms: boolean
    hasUnsavedChangesHandler: (isLoading: boolean) => void
    patchPreference: (preferenceName: string) => Promise<void>
    saveSleepingArrangementsHandler: (enabled: boolean) => void
    unitId: string
}

const SleepingArrangements: React.FC<SleepingArrangementProps> = (
    props: SleepingArrangementProps
): JSX.Element => {
    const UNASSIGNED_BEDS = 10
    const BEDROOM = 8
    const smallScreen = useMediaQuery("(max-width: 520px)")

    const [roomApiModel, setRoomApiModel] = useState<RoomModel[]>([])
    const [roomTempModel, setRoomTempModel] = useState<RoomModel[]>([])

    const [unassignedBedsApiModel, setUnassignedBedsApiModel] = useState<
        BedSize[]
    >([])
    const [unassignedBedsTempModel, setUnassignedBedsTempModel] = useState<
        BedSize[]
    >([])

    const [
        shouldValidateSleepingArrangements,
        setShouldValidateSleepingArrangements,
    ] = useState(false)

    const [
        saveSleepingArrangementsEnabled,
        setSaveSleepingArrangementsEnabled,
    ] = useState(ButtonState.Disabled)

    const {
        confirmedBedrooms,
        hasUnsavedChangesHandler,
        patchPreference,
        saveSleepingArrangementsHandler,
        unitId,
    } = props

    const contactId = useContactId() ?? ""
    const roomsQuery = useUnitRooms(unitId, {
        staleTime: 1000 * 60 * 60,
        onError: error => {
            LoggingService.error({
                message: "Failed to getRooms",
                error,
            })
        },
        onSuccess: data => {
            let modifiedRooms = [
                ...updateArray(
                    roomTempModel,
                    data,
                    (a: RoomModel, b: RoomModel): boolean => a.id === b.id
                ),
            ]

            if (modifiedRooms.length === 0) {
                const tempRoom: RoomModel = {
                    id: null,
                    type: "unit-room",
                    attributes: {
                        beds: [],
                        roomNumber: null,
                        roomType: null,
                    },
                }
                modifiedRooms = [tempRoom]
            }

            reorderRooms(modifiedRooms)
            setRoomApiModel(modifiedRooms)
            setRoomTempModel(modifiedRooms)
        },
    })
    const roomOptionsQuery = useUnitRoomOptions({
        staleTime: 1000 * 60 * 60,
        onError: error => {
            LoggingService.error({
                message: "Failed to getUnitRoomOptions",
                error,
            })
        },
    })
    const unitRoomsMutation = useUnitRoomsMutation()

    const hasData = !!roomTempModel[0]?.attributes.beds.length
    const noChanges = saveSleepingArrangementsEnabled === ButtonState.Disabled
    const shouldShowConfirmButton = hasData && noChanges && !confirmedBedrooms

    useEffect(() => {
        setSaveSleepingArrangementsEnabled(
            !deepEqual(roomTempModel, roomApiModel)
                ? ButtonState.Enabled
                : ButtonState.Disabled
        )
    }, [roomTempModel, roomApiModel])

    useEffect(() => {
        saveSleepingArrangementsHandler(!deepEqual(roomTempModel, roomApiModel))
    }, [roomTempModel, roomApiModel, saveSleepingArrangementsHandler])

    useEffect(() => {
        hasUnsavedChangesHandler(!deepEqual(roomTempModel, roomApiModel))
    }, [roomTempModel, roomApiModel, hasUnsavedChangesHandler])

    useEffect(() => {
        // Sets unassigned bed options model for managing state of what beds have been assigned
        const unassignedRoom = roomApiModel.filter(
            room => room.attributes.roomType?.id === UNASSIGNED_BEDS
        )
        const unassignedBeds = flatMap(
            unassignedRoom,
            room => room.attributes.beds
        )
        const bedSizes = flatMap(unassignedBeds, bed => {
            return bed.bedSize ? [bed.bedSize] : []
        })
        setUnassignedBedsApiModel(bedSizes)
        setUnassignedBedsTempModel(bedSizes)
    }, [roomApiModel])

    async function patchRooms(rooms: RoomModel[]): Promise<void> {
        const assignedRooms = rooms.filter(
            room => room.attributes.roomType?.id !== UNASSIGNED_BEDS
        )
        const assignedBeds = flatMap(
            assignedRooms,
            room => room.attributes.beds
        )
        if (
            rooms.some(room => room.attributes.roomType?.id === UNASSIGNED_BEDS)
        ) {
            const unassignedBeds = rooms.filter(
                room => room.attributes.roomType?.id === UNASSIGNED_BEDS
            )[0]?.attributes.beds

            // Remove assigned beds from unassigned beds
            if (unassignedBeds) {
                assignedBeds.forEach(assignedBed => {
                    if (
                        unassignedBeds.some(
                            unassignedBed =>
                                unassignedBed.bedSize?.name ===
                                assignedBed.bedSize?.name
                        )
                    ) {
                        unassignedBeds.splice(
                            unassignedBeds
                                .map(
                                    unassignedBed => unassignedBed.bedSize?.name
                                )
                                .indexOf(assignedBed.bedSize?.name),
                            1
                        )
                    } else {
                        const unassignedBedsIndex = rooms
                            .map(room => room.attributes.roomType?.id)
                            .indexOf(UNASSIGNED_BEDS)
                        const unassignedRoom = rooms[unassignedBedsIndex]
                        if (rooms[unassignedBedsIndex] && unassignedRoom) {
                            rooms[unassignedBedsIndex] = {
                                ...unassignedRoom,
                                ...(unassignedRoom.attributes
                                    ? {
                                          attributes: {
                                              ...unassignedRoom.attributes,
                                              beds: unassignedBeds,
                                          },
                                      }
                                    : {}),
                            }
                        }
                    }
                })

                // If all unassigned beds are used, remove the unassigned beds room
                if (unassignedBeds.length === 0) {
                    rooms = assignedRooms
                }
            }
        }

        unitRoomsMutation.mutate(
            {
                contactId,
                unitId,
                data: rooms,
            },
            {
                onSuccess: () => {
                    patchPreference("onboarding-confirmed-bedrooms")
                    trackUpdateSleepingArrangments(unitId, rooms, roomApiModel)
                    setShouldValidateSleepingArrangements(false)
                },
                onError: error => {
                    if (!isUnauthorizedOrCancelledRequest(error)) {
                        LoggingService.error({
                            message: "Patching rooms failed",
                            error,
                            tags: {
                                contactID: contactId,
                            },
                        })
                    }
                },
                onSettled: () => {
                    hasUnsavedChangesHandler(false)
                },
            }
        )
    }

    function calculateUnassignedBeds(roomModel: RoomModel[]): void {
        const rooms = roomModel.filter(
            room => room.attributes.roomType?.id !== UNASSIGNED_BEDS
        )
        const beds = flatMap(rooms, room => room.attributes.beds)
        const unassignedBeds = [...unassignedBedsApiModel]
        beds.forEach(bed => {
            if (
                bed.bedSize &&
                unassignedBeds.some(size => size.name === bed.bedSize?.name)
            ) {
                unassignedBeds.splice(
                    unassignedBeds
                        .map(size => size.name)
                        .indexOf(bed.bedSize.name),
                    1
                )
            }
        })
        setUnassignedBedsTempModel(unassignedBeds)
    }

    function renderRooms(): JSX.Element[] {
        const assignedRooms = roomTempModel.filter(
            room => room.attributes.roomType?.id !== UNASSIGNED_BEDS
        )
        if (assignedRooms.length === 0) {
            addRoom()
        }
        const uniqueUnassignedBeds = uniqBy(unassignedBedsTempModel, "name")

        if (!roomOptionsQuery.data) return []

        return assignedRooms.map((room, key) => {
            return (
                <RoomRow
                    id={key}
                    key={uniqueId("room_")}
                    onChangeHandler={(index: number, room: RoomModel): void => {
                        const tempRooms = [...roomTempModel]
                        tempRooms[index] = room
                        calculateUnassignedBeds(tempRooms)
                        setRoomTempModel(tempRooms)
                    }}
                    onDeleteRoomHandler={handleDeleteRoom}
                    room={room}
                    roomOptions={roomOptionsQuery.data}
                    unassignedBeds={uniqueUnassignedBeds}
                    lastRow={key === assignedRooms.length - 1}
                    shouldValidate={shouldValidateSleepingArrangements}
                />
            )
        })
    }

    function renderRoomHeader(): JSX.Element {
        const totalRoomCount = roomTempModel.filter(
            room => room.attributes.roomType?.id
        ).length
        if (totalRoomCount === 0) {
            return (
                <div className={styles.room_header}>
                    <h5 style={{ marginBottom: "6px" }}>
                        <FormattedMessage
                            id="Onboarding.sectionTitles.sleepingArrangements"
                            defaultMessage="Sleeping Arrangements"
                        />
                    </h5>
                    <p className={styles.section_description}>
                        <FormattedMessage
                            id="Onboarding.sleepingArrangements.noBedsDescription"
                            defaultMessage="Let your guests know the number and size of beds in each room."
                        />
                    </p>
                </div>
            )
        }
        const showSmallTooltip =
            smallScreen && unassignedBedsTempModel.length !== 0
        return (
            <div
                className={classnames(
                    styles.room_header,
                    showSmallTooltip ? styles["tooltip-active"] : ""
                )}
            >
                <h5 style={{ marginBottom: "6px" }}>
                    <FormattedMessage
                        id="Onboarding.sectionTitles.sleepingArrangements"
                        defaultMessage="Sleeping Arrangements"
                    />
                </h5>
                <Row>
                    <Col>
                        <div className={styles.section_description}>
                            <FormattedMessage
                                id="Onboarding.sectionTitles.description"
                                defaultMessage="Let your guests know the number and size of beds in each room. We've started filling this in based on what we know so far."
                            />
                        </div>
                    </Col>
                </Row>
                {renderRoomInfo()}
            </div>
        )
    }

    function renderRoomInfo(): JSX.Element {
        const rooms = roomTempModel.filter(
            room => room.attributes.roomType?.id !== UNASSIGNED_BEDS
        )
        const totalBedrooms = rooms.filter(
            room => room.attributes.roomType?.id === BEDROOM
        ).length
        const beds = flatMap(rooms, room => room.attributes.beds)
        const bedSizes = flatMap(beds, bed => {
            return bed.bedSize ? [bed.bedSize] : []
        })
        const totalBedSizes = [...bedSizes, ...unassignedBedsTempModel]
        const bedsGroup = groupBy(totalBedSizes, "name")
        const uniqueBedSizes = uniqBy(totalBedSizes, "name").map(bedSize =>
            bedSize
                ? [`${bedsGroup[bedSize.name]?.length} ${bedSize.name}`]
                : []
        )

        return (
            <Fragment>
                <Row style={{ marginBottom: "8px" }}>
                    <Col md="auto">
                        <DoorSVG className={styles.icon} />
                        <span
                            className="type-heading-small"
                            style={{ fontWeight: "500" }}
                        >
                            <FormattedMessage
                                id="Onboarding.sleepingArrangements.numberOfRooms"
                                defaultMessage="{count, plural, one {# Bedroom} other {# Bedrooms}}"
                                values={{
                                    count: totalBedrooms,
                                }}
                            />
                        </span>
                    </Col>
                </Row>
                <Row style={{ marginBottom: "16px" }}>
                    <Col md="auto">
                        {renderToolTip(
                            <div style={{ display: "inline-block" }}>
                                <BedSVG className={styles.icon} />
                                <span
                                    className="type-heading-small"
                                    style={{
                                        marginBottom: "6px",
                                        fontWeight: "500",
                                    }}
                                >
                                    <FormattedMessage
                                        id="Onboarding.sleepingArrangements.numberOfBeds"
                                        defaultMessage="{count, plural, one {# Bed} other {# Beds}}"
                                        values={{
                                            count: totalBedSizes.length,
                                        }}
                                    />
                                    {uniqueBedSizes.length > 0 && (
                                        <>
                                            <span style={{ margin: "0 6px" }}>
                                                {String.fromCharCode(9679)}
                                            </span>
                                            {uniqueBedSizes.join(", ")}
                                        </>
                                    )}
                                </span>
                            </div>
                        )}
                    </Col>
                </Row>
            </Fragment>
        )
    }

    function renderToolTip(anchorChildren: JSX.Element): JSX.Element {
        return (
            <Tooltip
                arrow
                classes={{
                    arrow: styles["tooltip-arrow"],
                    tooltip: styles["tooltip"],
                    tooltipPlacementBottom: styles["tooltip-placement-bottom"],
                    popper: styles["tooltip-popper"],
                }}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                open={unassignedBedsTempModel.length !== 0}
                placement={smallScreen ? "bottom" : "left"}
                PopperProps={{
                    disablePortal: true,
                }}
                title={
                    <Fragment>
                        <div className={styles["tooltip-content"]}>
                            <AlertSVG className={styles["tooltip-icon"]} />
                            {smallScreen ? (
                                <FormattedMessage
                                    id="Onboarding.sleepingArrangements.unassignedBedsSmall"
                                    defaultMessage="{count} unassigned {count, plural,  one {# bed} other {beds}}."
                                    values={{
                                        count: unassignedBedsTempModel.length,
                                    }}
                                />
                            ) : (
                                <FormattedMessage
                                    id="Onboarding.sleepingArrangements.unassignedBeds"
                                    defaultMessage="You have {count} unassigned {count, plural,  one {bed} other {beds}}."
                                    values={{
                                        count: unassignedBedsTempModel.length,
                                    }}
                                />
                            )}
                        </div>
                    </Fragment>
                }
            >
                {anchorChildren}
            </Tooltip>
        )
    }

    function addRoom(): void {
        const tempRoom: RoomModel = {
            id: null,
            type: "unit-room",
            attributes: {
                beds: [],
                roomNumber: null,
                roomType: null,
            },
        }
        const roomModel = [...roomTempModel, tempRoom]
        reorderRooms(roomModel)
        setRoomTempModel(roomModel)
    }

    function handleDeleteRoom(index: number): void {
        const tempRooms = [...roomTempModel]
        tempRooms.splice(index, 1)
        calculateUnassignedBeds(tempRooms)
        setRoomTempModel(tempRooms)
    }

    function reorderRooms(rooms: RoomModel[]): void {
        // Move unassigned beds to bottom
        const unassignedBedsIndex = rooms.findIndex(
            room => room.attributes.roomType?.id === UNASSIGNED_BEDS
        )
        if (unassignedBedsIndex === -1) return
        const unassignedRoom = rooms.splice(unassignedBedsIndex, 1)[0]
        if (unassignedRoom) {
            rooms.push(unassignedRoom)
        }
    }

    return roomsQuery.isLoading || roomOptionsQuery.isLoading ? (
        <Panel className={styles.panel_content}>
            <Loader className={styles.inline_loader} />
        </Panel>
    ) : (
        <Fragment>
            {renderRoomHeader()}
            {roomOptionsQuery.isError || roomsQuery.isError ? (
                <ErrorComponent
                    errorMessage={
                        <FormattedMessage
                            id="Onboarding.error.loadingSleepingArrangementsFailedErrorMessage"
                            defaultMessage="We had trouble loading sleeping arrangements. If the problem persists, please try again later."
                        />
                    }
                    buttonLabel={
                        <FormattedMessage
                            id="Onboarding.error.tryAgainButtonTitle"
                            defaultMessage="Try Again"
                        />
                    }
                    onClick={(): void => {
                        if (roomsQuery.isError) {
                            roomsQuery.refetch()
                        }

                        if (roomOptionsQuery.isError) {
                            roomOptionsQuery.refetch()
                        }
                    }}
                />
            ) : unitRoomsMutation.isError ? (
                <ErrorComponent
                    onClick={(): void => {
                        setSaveSleepingArrangementsEnabled(ButtonState.Enabled)
                        unitRoomsMutation.reset()
                    }}
                />
            ) : (
                <Panel className={styles.room_panel}>
                    {renderRooms()}
                    <AddRoomButtonRow
                        onClick={(): void => {
                            addRoom()
                        }}
                    />
                    {shouldShowConfirmButton ? (
                        <ConfirmButtonRow
                            buttonState={ButtonState.Enabled}
                            onSubmit={(): void => {
                                patchPreference("onboarding-confirmed-bedrooms")
                            }}
                        />
                    ) : (
                        <SubmitButtonRow
                            buttonState={saveSleepingArrangementsEnabled}
                            onSubmit={(): void => {
                                let showError = false
                                for (let i = 0; i < roomTempModel.length; i++) {
                                    const room = roomTempModel[i]?.attributes
                                    if (!room?.roomType) {
                                        showError = true
                                        break
                                    }
                                    for (let k = 0; k < room.beds.length; k++) {
                                        const bed = room.beds[k]
                                        if (!bed?.bedSize || !bed?.bedType) {
                                            showError = true
                                            break
                                        }
                                    }
                                }
                                if (showError) {
                                    setShouldValidateSleepingArrangements(true)
                                    return
                                }
                                hasUnsavedChangesHandler(true)
                                setSaveSleepingArrangementsEnabled(
                                    ButtonState.Loading
                                )
                                patchRooms(roomTempModel)
                            }}
                            onCancel={(): void => {
                                setShouldValidateSleepingArrangements(false)
                                calculateUnassignedBeds(roomApiModel)
                                setRoomTempModel(roomApiModel)
                                hasUnsavedChangesHandler(false)
                            }}
                        />
                    )}
                </Panel>
            )}
        </Fragment>
    )
}

export default observer(SleepingArrangements)
