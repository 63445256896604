import useWindowWidth from "hooks/common/useWindowSize"

export type RenderFunction = (windowWidth: number) => React.ReactNode

export interface Props {
    children: RenderFunction
}

export const Responsive: React.FC<Props> = ({ children }) => {
    const size = useWindowWidth()
    return <>{children(size)}</>
}
