/* eslint-disable camelcase */
import { UnitAvailabilityDays, JSONAPIDocument } from "@vacasa/owner-api-models"
import { authAxios } from "../../api/authAxios"
import LoggingService from "../../services/logging/logging.service"

type FetchAvailabilityRequest = {
    contactId: string
    unitId: string
    startDate: string
    endDate: string
    signal?: AbortSignal
}

const fetchAvailability = ({
    contactId,
    unitId,
    startDate,
    endDate,
    signal,
}: FetchAvailabilityRequest): Promise<UnitAvailabilityDays> =>
    authAxios
        .get<JSONAPIDocument<UnitAvailabilityDays>>(
            `/v1/owners/${contactId}/units/${unitId}/availability`,
            {
                params: {
                    start_date: startDate,
                    end_date: endDate,
                    include: "bookingProbability",
                },
                signal,
            }
        )
        .then(response => {
            if (!response.data) {
                LoggingService.error({
                    message: "Availability axios data is undefined",
                })
                throw Error("Could not retrieve availability response data")
            }
            if (
                !Object.prototype.hasOwnProperty.call(response.data, "data") ||
                response.data.data === undefined
            ) {
                LoggingService.error({
                    message: "Availability response data is undefined",
                })

                throw Error("Could not retrieve availability data")
            }
            return response.data.data.attributes
        })

export { fetchAvailability }
