import classNames from "classnames"
import { FormattedMessage } from "react-intl"
import { DateInput } from "../../DateInput/DateInput"
import styles from "./CheckInOutInputs.module.scss"
import { format } from "date-fns"

export interface CheckInOutInputsProps {
    startDate: Date | null
    endDate: Date | null
    onDatesChange: (dates: {
        startDate: Date | null | string
        endDate: Date | null | string
    }) => void
    showStartDateError: boolean
    showEndDateError: boolean
    className?: string
    testId?: string
}

export default function CheckInOutInputs(
    props: CheckInOutInputsProps
): JSX.Element {
    const startDateString =
        props.startDate && format(props.startDate, "MM/dd/yyyy")
    const endDateString = props.endDate && format(props.endDate, "MM/dd/yyyy")

    return (
        <div
            data-testid={props.testId}
            className={classNames(props.className, styles.checkInOutInputs)}
        >
            <label htmlFor="start date" className={styles.checkInLabel}>
                <FormattedMessage
                    id="CalendarPage.OwnerHolds.checkIn"
                    defaultMessage="Check In"
                />
            </label>

            <DateInput
                name="start date"
                showError={props.showStartDateError}
                value={startDateString}
                onChange={date => {
                    props.onDatesChange({
                        startDate: date,
                        endDate: props.endDate,
                    })
                }}
                // When clearing start date, also clear end date to match calendar behaviour.
                onClear={() =>
                    props.onDatesChange({
                        startDate: null,
                        endDate: null,
                    })
                }
                inputTestId="start-date-input"
            />
            <label htmlFor="end date" className={styles.checkOutLabel}>
                <FormattedMessage
                    id="CalendarPage.OwnerHolds.checkOut"
                    defaultMessage="Check Out"
                />
            </label>
            <DateInput
                name="end date"
                showError={props.showEndDateError}
                value={endDateString}
                onChange={date => {
                    props.onDatesChange({
                        startDate: props.startDate,
                        endDate: date,
                    })
                }}
                onClear={() =>
                    props.onDatesChange({
                        startDate: props.startDate,
                        endDate: null,
                    })
                }
                inputTestId="end-date-input"
            />
        </div>
    )
}
