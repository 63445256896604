/* eslint-disable no-template-curly-in-string */
const calendar = {
    vacasa: "Vacasa",
    title: "Naptár",
    interiorDesignBannerText: "Növelje bevételét belsőépítészeti átalakítással",
    covidBannerText:
        "Coronavirus homeowner resource guide - what we're doing to navigate COVID-19 together.",
    covidCtaDestination:
        "https://www.vacasa.com/support/coronavirus-homeowner-resource-guide",
    learnMore: "További információk",
    emptyState:
        "You’ll be able to reserve dates for your personal use and view upcoming reservations.",

    OwnerHolds: {
        heading: "Owner holds",
        nonfixedHelperText:
            "Use this calendar to reserve dates for your own personal use.",
        fixedHelperText:
            "To reserve dates for your own personal use, please contact Homeowner Account Support at +1 (971) 254-3001 or",
        inactiveHelperText:
            "Ez a nyaraló inaktív. Tulajdonos általi foglalásokat csak aktív nyaralókhoz adhatsz hozzá.",
        ifHoldQuestions:
            "Amennyiben kérdéseid merülnek fel a foglalással kapcsolatban, lépj kapcsolatba velünk:",
        toChangeHold:
            "Ha változtatni szeretnél a dátumon, vagy törölni szeretnéd az utat, írj a következő e-mail-címre:",
        continue: "Folytatás",
        ariaLabel: "Kattints a tulajdonos általi foglalás aktiválásához",
        checkIn: "Bejelentkezés",
        checkOut: "Kijelentkezés",
        notSelected: "Nincs kiválasztva",
        clearDates: "Dátumok törlése",
        employeeOptions: "További alkalmazotti lehetőségek",
        holdType: "Hold Reason",
        ownerHold: "Tulajdonos általi foglalás",
        guestStay: "Guest Stay",
        vacasaHold: "Vacasa általi foglalás",
        selectOwner: "Tulajdonos kiválasztása",
        scheduleClean: "Szeretnél takarítást kérni a látogatásod után?",
        yes: "Igen",
        no: "Nem",
        or: "vagy",
        warningBanner:
            "One or more of the dates for your hold are unavailable. Please check your selection and try again.",
        datesPlaceholder: "MM/DD/YYYY",
    },

    flyout: {
        vacasaContact: "Vacasa contact",
        contactUsFooter:
            "If you have any questions about this hold, please reach out.",
        back: "Back",
        clear: "Clear",
        holdPlaced: "Hold placed",
        holdUpdated: "Hold updated",
        tempAccessCode: "Ideiglenes hozzáférési kód",
        smartLockAria:
            "Kattints erre a hivatkozásra az ideiglenes Smart Lock kód megjelenítéséhez.",
        employeesMustClickToView: `Az alkalmazottaknak <button>kattintaniuk kell a kód megjelenítéséhez</button>. A biztonság érdekében ezt a műveletet dokumentáljuk.`,
        smartLockCode: "Smart Lock kód",
        notAvailable: "Nem érhető el",
        selectOption: "Válassz a lehetőségek közül...",
        reservation: "Foglalás",
        ownerStay: "Tulajdonos általi tartózkodás",
        ownerStaySubtitle: "Személyes használatra történő foglalás.",
        complimentaryStay: "Ingyenes foglalás",
        complimentaryStaySubtitle:
            "Foglalás a barátok vagy családtagok számára.",
        vacasaHold: "Vacasa általi foglalás",
        vacasaHoldSubtitle: "Csak az alkalmazottak számára elérhető lehetőség.",
        block: "Lefoglalás",
        blockSubtitle: "A nyaralót senki sem fogja használni.",
        propertyCare: "Az ingatlan karbantartása",
        propertyCareSubtitle: "Javítások, felújítások, szállítások stb.",
        seasonalHold: "Seasonal hold",
        homeForSale: "Home for sale",
        homeForSaleSubtitle: "Activities related to a home sale.",
        other: "Other",
        otherSubtitle: "Does not fit the other categories.",
        terminationHold: "Termination hold",
        terminationHoldSubtitle: "Option only available for employees",
        notes: {
            title: "Notes ",
            titleRequired: "Notes* ",
            optional: "(optional)",
            required: "(required)",
            placeholder:
                "Help us improve our category options by providing a note about this hold",
            none: "N/A",
        },
        housekeeping: "Takarítás",
        yesHousekeepingSubtitle:
            "A Vacasa housekeeping visit will be scheduled after this hold.",
        yesHousekeepingSubtitleWithCleaningFee:
            "A Vacasa housekeeping visit will be scheduled after this hold and billed to your account in the amount of <strong>${amount}.</strong>",
        noHousekeepingSubtitle:
            "You will be responsible for cleaning and preparing the home for the next guests.",
        ownerResponsibleForCleaning: "Owner responsible for cleaning",
        housekeepingScheduled: "Takarítás beütemezve",
        noHousekeeping: "Nincs takarítás",
        numberGuestsMax: `Vendégek maximális száma: {{number}}`,
        noPets: "Kisállatok nem hozhatók",
        restrictionsDontApply:
            "A tulajdonosokra nem vonatkoznak a vendégekkel kapcsolatos korlátozások.",
        night: "night",
        nights: "éjszaka",
        madeOn: "Booked on {creationDate}",
        madeBy: "Booked on {date} by {name}",
        monthCount:
            "Spans {monthCount, plural,=0 {}one {# month}other {# months}}",
        date: "Date",
        dates: "Dátum:",
        guests: "Vendégek száma:",
        createHold: "Create Hold",
        next: "Next",
        summary: "Summary",
        cancelOwnerHold: "Cancel owner hold",
        cancelAreYouSure:
            "Are you sure you want to cancel? Your hold has not been scheduled.",
        deleteOwnerHold: "Delete owner hold",
        editHold: "Foglalás szerkesztése",
        edit: "Szerkesztés",
        editAria: "Kattints ide a szerkesztéshez.",
        pleaseContactToRequestChange:
            "Lépj kapcsolatba a következő ügyintézővel: {{contact}}a dátum módosításához.",
        saveChanges: "Módosítások mentése",
        save: "Mentés",
        saveAria: "Kattints ide a mentéshez.",

        delete: "Törlés",
        removeHold: "Foglalás eltávolítása",
        deleteHold: "Foglalás törlése",
        deleteAria: "Kattints ide a törléshez.",
        holdRemoved: "Foglalás eltávolítva",
        deleteAreYouSure: "Are you sure you want to delete this?",
        deleteHelperText: "A művelet nem vonható vissza.",
        deleteConfirm: "Igen, törlöm a foglalást.",
        cancelDelete: "Nem törlöm a foglalást.",
        approvalNeeded: "Jóváhagyásra van szükség.",
        fixedRentDeletionApproval:
            "Bérleti szerződés esetén a foglalás törlését a támogatási csapatnak jóvá kell hagynia.",
        request: "Request",
        requestDelete: "Törlési kérelem",
        deleteRequestConfirmation:
            "A jelen kérelemmel tulajdonos általi foglalás törlését kéred bérleti szerződés esetén.",
        holdNumberForRequest: "A(z) # foglalás törlésére irányuló kérelem",
        holdNumber: "#. foglalás:",
        startDate: "Kezdő dátum:",
        endDate: "Záró dátum:",

        cancel: "Mégse",
        done: "Kész",
        totalNetRent: "Total Net rent",
        revenueBreakdown: "Revenue breakdown",
        fixedRent: "Bérleti szerződés",
        grossRent: "Bruttó lakbér",
        guestReview: "Guest review",
        guestReviewCollapseLabel: "See less",
        guestReviewExpandLabel: "See more",

        inviteGuest: "Vendég meghívása",
        inviteGuests: "Invite Guests",
        inviteGuestsOptional: "Invite Guests (Optional)",
        inviteAnotherGuest: "Újabb vendég meghívása",
        inviteGuestsSubtitle:
            "Add guests to share your trip details and home access information.",
        firstName: "Utónév",
        lastName: "Vezetéknév",
        emailAddress: "E-mail-cím",
        invitationSent: "Meghívó elküldve",
        invitationsSent: "meghívó elküldve",
        fiveOrMoreInvitationsSent: "meghívó elküldve",
        guestRemoved: "Vendég eltávolítva",
        skip: "Skip",
        skipAria: "Kattints erre a gombra a vendégek hozzáadásának átugrásához",
        inviteAnotherGuestAria:
            "Kattints erre a gombra egy újabb vendég meghívásához",
        noGuestInfo: "Nincsenek adatok a vendéggel kapcsolatban",
        guestInfo: "Guest info",
        adults: "felnőtt",
        fiveOrMoreAdults: "felnőtt",
        children: "gyermek",
        fiveOrMoreChildren: "gyermek",
        dogs: "kutya",
        fiveOrMoreDogs: "kutya",
        housekeepingNotAllowed:
            "Housekeeping not available. You will be responsible for cleaning and preparing the home for the next guests.",
        newBooking: "New booking",
        pastBooking: "Past booking",
        invalidDates: "The selected dates are not available.",
        learnAboutRates: "Learn about rates",
        guestBookingLikely:
            "These nights are highly likely to be booked by guests.",
        response: "Manager response",
        onboardingFooter: {
            title: "What do you want to do next?",
            backToOnboarding: "Go Back to Onboarding",
            addAdditionalOwnerHolds: "Add Another Owner Hold",
        },
        costOfHold:
            "You miss out on <b>{cost}</b> in gross rent by booking these dates.",
        housekeepingRequired:
            "In order to maintain the highest quality guest experience, housekeeping is required for stays with overnight guests.",
        housekeepingRequiedWithCleaningFee:
            "This owner hold requires a post-stay clean, which will be billed to your account after the stay in the amount of <strong>${amount}.</strong>",
        PhoneSections: {
            smsTitle: "Owner contact info",
            smsDescription: 'Details will be sent to this number.',
            smsEditDescription: "Details will be sent to <strong>{number}</strong>.",
            invalidPhoneMessage: 'The phone number is not valid',
            noPhoneNumber: 'No phone number provided'
        }
    },

    UpcomingStays: {
        title: "Reservations",
        emptyStateUpcoming:
            "New reservations and owner holds will show up here.",
        emptyStatePast:
            "You have no previous reservations. When upcoming stays have concluded, they will appear here.",
        upcomingDescription:
            "Upcoming guest reservations and owner holds will be listed below. Click a row to view more details. If you wish to modify an owner hold you can do so by clicking on the corresponding row.",
        pastDescription:
            "Your past reservations and holds in the last 45 days are listed out below.  If you would like to view more details around each stay, click on the detail row. ",
        toModify:
            "Ha módosítani szeretnéd a tulajdonos általi foglalást, alább megteheted.",
        fixedRentModify:
            "Ha bérleti szerződési időszakban szerkeszteni szeretnéd a nyaralód tulajdonosi foglalását, írj e-mailt a következőnek:",
        details: "Részletek",
        Night: "éjszaka",
        adult: "felnőtt",
        child: "gyermek",
        dog: "kutya",
        at: "dátum:",
        upcomingTabTitle: "Upcoming ({total})",
        previousTabTitle: "Previous",
        adults: "adults",
        children: "children",
    },

    errorMessages: {
        generalError:
            "A kérelem feldolgozása sikertelen volt. Próbáld meg újból később. Ha a probléma továbbra is fennáll, lépj kapcsolatba az ügyintéződdel: {name} dátum: {phone}",
        datesNotAvailableError:
            "A tulajdonosi foglalás egy vagy több dátuma nem érhető el. Ellenőrizd a kiválasztott dátumokat, és próbáld meg újból.",
        createHoldError: "A tulajdonos általi foglalás létrehozása sikertelen.",
        deleteHoldError: "A tulajdonos általi foglalás eltávolítása.",
        updateHoldError: "A tulajdonos általi foglalás frissítése sikertelen.",
        updateHoldCleanError: "A takarítási állapot frissítése sikertelen.",
        addGuestToHoldError: "A vendég hozzáadása sikertelen.",
        removeGuestFromHoldError: "A vendég eltávolítása sikertelen.",
        modalTitle: "Oops!",
        modalMessage:
            "It looks like this reservation is no longer in our system.",
        invalidDateError: "This date is unavailable",
        unableToLoadReservations: "We were unable to load your reservations.",
    },

    reservations: {
        reservation: "Reservation",
        vacasaHold: "Vacasa hold",
    },

    switchAccounts: {
        notification: "This reservation is associated with another account",
        description:
            "Please login to the appropriate homeowner account and click the new booking email notification to view the new reservation details.",
        button: "Switch Accounts",
        maybeLater: "Maybe Later",
        descriptionButtom:
            "Interested in linking your accounts into one login? Call <strong>Owner Support</strong> at {phone} to get it set-up.",
    },

    reservationType: {
        ownerHold: "Owner Hold",
        vacasaHold: "Vacasa Hold",
        reservation: "Guest Stay",
    },
}

export default calendar
