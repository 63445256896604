import { ReactComponent as AllowedIcon } from "../../assets/allowed-icon.svg"
import { ReactComponent as NotAllowedIcon } from "../../assets/notallowed-icon.svg"
import styles from "./Pill.module.scss"

export type PillProps = {
    text: string
    enabledStyle: boolean
}

const Pill: React.FC<PillProps> = ({ text, enabledStyle }) => {
    const pillStyle = enabledStyle
        ? styles.pill__enabled
        : styles.pill__disabled
    return (
        <div className={styles.pillContainer}>
            <div className={pillStyle}>
                {text}
                {enabledStyle ? (
                    <AllowedIcon className={styles.svgIcon} />
                ) : (
                    <NotAllowedIcon className={styles.svgIcon} />
                )}
            </div>
        </div>
    )
}

export { Pill }
