import { Country, JSONAPIDocument } from "@vacasa/owner-api-models"
import { authAxios } from "../../api/authAxios"

type FetchCountriesRequest = {
    signal?: AbortSignal
}
export type CountriesResponse = JSONAPIDocument<Country, []>

const fetchCountries = async ({
    signal,
}: FetchCountriesRequest): Promise<Country[]> =>
    authAxios
        .get<CountriesResponse>("v1/countries", { signal })
        .then(response => response.data.data.map(country => country.attributes))

export { fetchCountries }
