import { JSONAPIResource, Unit } from "@vacasa/owner-api-models"
import { useLoginInfo } from "contexts/login"
import { useUnits } from "hooks/units"
import FullScreenLoader from "lib/components/Loader/FullScreenLoader"
import {
    FC,
    PropsWithChildren,
    createContext,
    useContext,
    useEffect,
    useState,
} from "react"
import { BrowserRouter } from "react-router-dom"
import LoggingService from "services/logging/logging.service"
import segmentService from "services/segment/segment.service"
import { isUnauthorizedOrCancelledRequest } from "utils/error/error"
import { updateSessionConfig } from "utils/session"
import Error from "views/error"
import { useQueryClient } from "react-query"
import { OWNER_PREFERENCE_V2_QUERY_KEY } from "../../hooks/user-preferences/useUserPreferences"

export type UnitState = {
    setUnit: (unitId: string) => void
    unitId: string | null
    units: {
        [key: string]: JSONAPIResource<Unit>
    }
}

export const DEFAULT_UNIT_STATE: UnitState = {
    setUnit: (_: string) => {
        LoggingService.error({
            message: `"setUnit" not implemented`,
        })
    },
    unitId: "",
    units: {},
}

export const UnitStateContext = createContext<UnitState>(DEFAULT_UNIT_STATE)

export const useUnitState = () => useContext<UnitState>(UnitStateContext)

export const UnitStateProvider: FC<PropsWithChildren> = ({ children }) => {
    const { unitId: initialUnitId } = useLoginInfo()
    const [units, setUnits] = useState<{
        [key: string]: JSONAPIResource<Unit>
    }>({})
    const [unitId, setUnitId] = useState<string | null>(null)
    const queryClient = useQueryClient()
    const unitsQuery = useUnits({
        onError: error => {
            if (isUnauthorizedOrCancelledRequest(error)) return
            LoggingService.error({
                message: `Failed to load owner units during start up`,
                error,
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries([OWNER_PREFERENCE_V2_QUERY_KEY, unitId])
        },
    })
    const setUnit = (id: string) => {
        if (!unitsQuery.isSuccess) setUnitId(null)
        setUnitId(id)
        LoggingService.unitId = id
        segmentService.unitId = id
    }

    useEffect(() => {
        if (unitId || !unitsQuery.isSuccess) return
        const queryUnit = unitsQuery.data.find(
            unit => String(unit.id) === initialUnitId
        )
        const unit = queryUnit ?? unitsQuery.data[0] ?? null

        setUnitId(unit ? String(unit.id) : null)
        setUnits(
            unitsQuery.data.reduce<{
                [key: string]: JSONAPIResource<Unit>
            }>((acc, unit) => {
                acc[String(unit.id)] = unit
                return acc
            }, {})
        )
        LoggingService.unitId = unit ? String(unit.id) : null
        segmentService.unitId = unit ? String(unit.id) : null
    }, [unitId, unitsQuery.isSuccess, unitsQuery.data, initialUnitId])

    useEffect(() => {
        updateSessionConfig({ unitId })
    }, [unitId])

    if (unitsQuery.isLoading) return <FullScreenLoader />

    if (unitsQuery.isError)
        return (
            <BrowserRouter>
                <Error />
            </BrowserRouter>
        )

    return (
        <UnitStateContext.Provider
            value={{
                setUnit,
                units,
                unitId,
            }}
        >
            {children}
        </UnitStateContext.Provider>
    )
}
