/**
 * Returns if a feature flag is enabled.
 * @param featureFlag
 * @returns
 */
export const isFeatureFlagEnabled = (
    featureFlag: FeatureFlag<FeatureFlagId>
) => {
    return featureFlag.clientReady && featureFlag.isEnabled
}

export type FeatureFlagMap = {
    "owner-portal-enable-webinar-banner": {
        message: string
        ctaLabel: string
        ctaUrl: string
    }
    "owner-portal-unit-min-rate": undefined
    "ob-availability-ticket-assignee-logic-update": undefined
    "show-reservation-net-rent": undefined
    "owner-portal-guest-reservation-inherited": undefined
    "owner-portal-upcoming-list-booking-probability": undefined
    "owner-referrals": undefined
    "user-notification-preference-ui": undefined
    dashboard: undefined
    "guest-review-modal-feedback": undefined
    "channel-reviews": undefined
    "smarthome-lock-codes": undefined
    "nights-occupied-modal": undefined
    "nights-occupied-modal-feedback": undefined
    hotjar: undefined
    "unit-support-contacts": {
        enabledStates: string
    }
    "market-rate-comparison": undefined
    "owner-created-ticket-comments": undefined
    "revenue-simulator": undefined
    "core-metrics": undefined
    "market-rates-v2-features": undefined
    "owner-portal-add-phone-number-in-owner-hold-form": undefined
    "owner-portal-reopen-ticket": undefined
}

export type FeatureFlagId = keyof FeatureFlagMap

export type FeatureFlag<Id extends FeatureFlagId> = {
    isEnabled: boolean
    variables: FeatureFlagMap[Id]
    clientReady: boolean
    didTimeout: boolean
}
