import { HOMEOWNER_ACCOUNTS_EMAIL } from "Constants"
import { useW9Window } from "hooks/taxes"
import React, { ReactNode, useCallback } from "react"
import { FormattedMessage } from "react-intl"
import SVGIcon from "../../lib/components/SVGIcon"
import loggingService from "../../services/logging/logging.service"
import { OptimizelyFeature } from "@optimizely/react-sdk"
import { useTaxInformationFlyoutAPI } from "views/taxes/TaxInformationFlyout"

export type SlugsProps = {
    title: ReactNode
    whoItsFor: ReactNode
    irsInfoLink: string
    irsFormLink?: string
    buttonText?: ReactNode
    w9check?: boolean
}
interface Props {
    slugs: SlugsProps
    trackClickEvent: ((props: any, evt?: string) => void) | null // eslint-disable-line @typescript-eslint/no-explicit-any
    w9Url: string | null
    w9Submitted: boolean
}

const TaxAccordionPartialBottom: React.FC<Props> = ({
    slugs,
    trackClickEvent,
    w9Url,
    w9Submitted,
}: Props): JSX.Element => {
    const { open } = useW9Window()
    const { open: openW9Flyout } = useTaxInformationFlyoutAPI()

    const onOpenW9Click = useCallback(() => {
        if (w9Url) {
            open(w9Url)
            loggingService.log({ message: `Fill out W9 button` })
            trackClickEvent &&
                trackClickEvent({
                    type: "button",
                    label: "fill_out_w9",
                })
        }
    }, [open, trackClickEvent, w9Url])

    return (
        <div className="padding-24">
            {!w9Submitted && (
                <div>
                    <h4 className="type-heading-small no-letter-spacing">
                        <FormattedMessage
                            id="Taxes.whoFor"
                            defaultMessage="Who it's for"
                        />
                    </h4>
                </div>
            )}
            {!w9Submitted && (
                <div>
                    <p className="zero-margin-bottom">{slugs.whoItsFor}</p>
                    <a
                        target="blank"
                        href={slugs.irsInfoLink}
                        className="big-link lake label"
                    >
                        <FormattedMessage
                            id="Taxes.learnMore"
                            defaultMessage="Learn more at"
                        />{" "}
                        irs.gov{" "}
                        <SVGIcon
                            svg="icon-feather-external-link"
                            className="w-16 h-16"
                            colorName="lake"
                        />
                    </a>
                    <br />
                </div>
            )}
            <h4 className="type-heading-small no-letter-spacing">
                {w9Submitted ? (
                    <FormattedMessage
                        id="Taxes.makeChanges"
                        defaultMessage="Want to make changes?"
                    />
                ) : (
                    <FormattedMessage
                        id="Taxes.howToSubmit"
                        defaultMessage="How to submit it"
                    />
                )}
            </h4>
            <br />
            {w9Submitted ? (
                <p style={{ margin: "0" }}>
                    <FormattedMessage
                        id="Taxes.w9Support"
                        defaultMessage="To make edits to your W-9 details please reach out to the <b>Account Support team</b> at <t>971-254-3001</t>, <a>homeowner.accounts@vacasa.com</a>"
                        values={{
                            a: (chunk: ReactNode) => (
                                <a href={`mailto:${HOMEOWNER_ACCOUNTS_EMAIL}`}>
                                    {chunk}
                                </a>
                            ),
                            b: (chunk: ReactNode) => <strong>{chunk}</strong>,
                            t: (chunk: ReactNode) => (
                                <a href="tel:971-254-3001">{chunk}</a>
                            ),
                        }}
                    />
                </p>
            ) : slugs.buttonText ? (
                <OptimizelyFeature feature="w9-flyout-workflow">
                    {isEnabled => (
                        <button
                            className="btn btn-secondary btn-wire zero-margin-bottom zero-margin-top"
                            onClick={isEnabled ? openW9Flyout : onOpenW9Click}
                            style={{ marginTop: "24px" }}
                        >
                            {slugs.buttonText}
                        </button>
                    )}
                </OptimizelyFeature>
            ) : (
                <div>
                    <ol className="zero-padding-bottom zero-margin-bottom">
                        <li>
                            <a
                                target="blank"
                                href={slugs.irsFormLink}
                                className="lake label"
                            >
                                <FormattedMessage
                                    id="Taxes.dwnld"
                                    defaultMessage="Download"
                                />{" "}
                                {slugs.title}{" "}
                                <SVGIcon
                                    svg="icon-feather-external-link"
                                    className="w-16 h-16"
                                    colorName="lake"
                                />
                            </a>
                        </li>
                        <li className="zero-padding-bottom zero-margin-bottom">
                            <FormattedMessage
                                id="Taxes.mailForm"
                                defaultMessage="Fill out the form and mail it to:"
                            />
                        </li>
                    </ol>
                    <ul className="zero-margin-bottom no-list-style">
                        <li>Vacasa LLC</li>
                        <li>P.O. Box 1338</li>
                        <li>Boise, ID 83701-1338</li>
                    </ul>
                </div>
            )}
        </div>
    )
}

export default TaxAccordionPartialBottom
