import { OptimizelyFeature } from "@optimizely/react-sdk"
import styles from "./Chart.module.scss"
import { FC, PropsWithChildren, useEffect, useRef } from "react"
import classNames from "classnames"
import { ChartType } from "./types"
import { useChartAPI, useChartState } from "./Chart"
import { FormattedMessage } from "react-intl"
import { trackChartSelectionChanged } from "services/segment/dashboard/dashboardTracking"
import { useCurrentUnit, useUnitStatuses } from "hooks/units"

export const ChartSelector = () => {
    const { isFixedRent } = useUnitStatuses()
    const { unit } = useCurrentUnit()
    const unitMarketCompChartEnabled =
        unit?.attributes.marketCompChartEnabled ?? false
    const scrollRef = useRef<HTMLDivElement>(null)
    const containerRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
        const div = scrollRef.current

        if (!div) return

        div.addEventListener("scroll", () => {
            if (div.scrollLeft === div.scrollWidth - div.clientWidth) {
                containerRef.current?.classList.add(
                    styles.chartSelectorContainerEnd ?? ""
                )
            } else {
                containerRef.current?.classList.remove(
                    styles.chartSelectorContainerEnd ?? ""
                )
            }
        })
    }, [])

    const {
        chartSelector: { activeChart },
    } = useChartState()

    return (
        <div className={styles.chartSelectorContainer} ref={containerRef}>
            <div className={styles.chartSelectorInner} ref={scrollRef}>
                <div className={styles.chartSelector}>
                    <ChartOption
                        active={activeChart === "gross-rent"}
                        type="gross-rent"
                    >
                        <FormattedMessage
                            id="Dashboard.charts.grossRent.tabName"
                            defaultMessage="Gross Rent"
                        />
                    </ChartOption>
                    {!isFixedRent && (
                        <ChartOption
                            active={activeChart === "net-rent"}
                            type="net-rent"
                        >
                            <FormattedMessage
                                id="Dashboard.charts.netRent.tabName"
                                defaultMessage="Net Rent"
                            />
                        </ChartOption>
                    )}
                    <OptimizelyFeature feature="market-rate-comparison">
                        {isEnabled =>
                            isEnabled &&
                            unitMarketCompChartEnabled && (
                                <ChartOption
                                    active={activeChart === "market-comp"}
                                    type="market-comp"
                                >
                                    <FormattedMessage
                                        id="Dashboard.charts.marketRate.tabName"
                                        defaultMessage="Rate Comparison"
                                    />
                                </ChartOption>
                            )
                        }
                    </OptimizelyFeature>
                </div>
            </div>
        </div>
    )
}

type ChartOptionProps = {
    active?: boolean
    type: ChartType
}
const ChartOption: FC<PropsWithChildren<ChartOptionProps>> = ({
    active,
    children,
    type,
}) => {
    const chartAPI = useChartAPI()

    const onClick = () => {
        trackChartSelectionChanged(type)
        chartAPI.setType(type)
    }

    return (
        <div
            onClick={onClick}
            className={classNames(
                "type-heading-extra-small-caps",
                styles.option,
                {
                    [styles.selected as string]: active,
                }
            )}
        >
            {children}
        </div>
    )
}
