import { ReservationType, UnitAvailabilityDay } from "@vacasa/owner-api-models"

const PILL_COLOR_CALENDAR: PillColorMap = {
    1: {
        color: "Grape",
    },
    2: {
        color: "Lime",
    },
    3: {
        color: "Awareness",
    },
}

type PillColorMap = {
    [key in ReservationType]?: {
        color: PillColorNames
    }
}

type PillColorNames = "Lime" | "Grape" | "Awareness"

export const PillColors: { [key in PillColorNames]: string } = {
    Grape: "#5A2458",
    Lime: "#8C9711",
    Awareness: "#DDAD49",
}

type PillProps = {
    isCheckInDay: boolean
    isCheckoutDay: boolean
    prevDayAvailability: UnitAvailabilityDay | undefined
    availability: UnitAvailabilityDay
    isPastDay: boolean
}

const Pill: React.FC<PillProps> = ({
    isCheckInDay,
    isCheckoutDay,
    prevDayAvailability,
    availability,
    isPastDay,
}) => {
    // Same day check out and check in
    const checkOutReservationType: ReservationType | undefined | null =
        isCheckoutDay && isCheckInDay
            ? prevDayAvailability?.reservationType
            : availability?.reservationType

    // Pills
    const checkInPill =
        isCheckInDay &&
        availability?.reservationType &&
        PILL_COLOR_CALENDAR[availability.reservationType as ReservationType]
            ?.color

    const checkOutPill =
        isCheckoutDay &&
        checkOutReservationType &&
        PILL_COLOR_CALENDAR[checkOutReservationType]?.color

    const rectanglePill =
        !isCheckInDay &&
        !isCheckoutDay &&
        availability?.reservationType &&
        PILL_COLOR_CALENDAR[availability.reservationType as ReservationType]
            ?.color

    const pillOpacity = isPastDay ? "0.4" : "1"

    return (
        <div className="reservationPill">
            {checkOutPill && (
                <span
                    data-testid="pill-checkout"
                    className="pill checkOutPill"
                    style={{
                        background: PillColors[checkOutPill],
                        opacity: pillOpacity,
                    }}
                />
            )}
            {checkInPill && (
                <span
                    data-testid="pill-checkin"
                    className="pill checkInPill"
                    style={{
                        background: PillColors[checkInPill],
                        opacity: pillOpacity,
                    }}
                />
            )}
            {rectanglePill && (
                <span
                    data-testid="pill-rectangle"
                    className="pill"
                    style={{
                        background: PillColors[rectanglePill],
                        opacity: pillOpacity,
                    }}
                />
            )}
        </div>
    )
}

export { Pill }
