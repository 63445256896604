const homeInfo = {
    title: "Informazioni sulla casa",
    viewListingAction: "Visualizza annuncio",
    viewOnboardingAction: "Switch to Onboarding",
    active: "attivo",
    inactive: "non attivo",
    onboarding: "onboarding",
    dueToMaintenance: "Per lavori di manutenzione",
    since: "Dal",
    until: "Fino",
    emptyState:
        "Details like your local Vacasa contacts and a link to your listing will show up here.",

    ownerGuide: {
        ownerGuideTitle: "Owner Resource Library",
        viewLink: "View Owner Library",
    },

    dogFriendly: {
        dogsAllowed: "Cani ammessi",
        yes: "Sì",
        no: "No",
        edit: "Modifica",
    },

    homeNotReady: {
        title: "Home not ready",
        description:
            "We’ll let you know as soon as your home’s information is ready to edit.",
    },
    help: {
        haveQuestions: "Have questions? {{emailLink}}",
        contactConsultant: "Contact a home consultant",
    },
    protectionProgram: {
        enrolled: "Enrolled",
        enrolledDescription:
            "You are enrolled with the Accommodations Protection Program. To change this, call {phoneNumber} or email {email}.",
        learnMore: "Learn more",
        notEnrolled: "Not enrolled",
        notEnrolledDescription:
            "You are not enrolled with Accommodations Protection Program. If you want to learn more about enrolling in this program, contact {email}.",
        title: "Accommodations\nProtection Program",
        unableToLoadProgramStatus:
            "We were unable to load your rental program status.",
    },
}

export default homeInfo
