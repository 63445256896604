const taxes = {
    title: "Impuestos",

    fillOutW9: "Completa el Formulario W-9",
    submitW9: "Enviar formulario W-9 en línea",
    newTaxFormsWillShow:
        "Los nuevos formularios impositivos aparecerán aquí a medida que estén disponibles.",
    goPaperless: "Opción electrónica",
    paperlessExplanation:
        "La opción electrónica te permite recibir tus formularios de presentación de impuestos mediante envío electrónico únicamente. No se enviarán copias en papel de tus formularios impositivos.",
    electronicForms:
        "Recibe los formularios de presentación de impuestos mediante envío electrónico únicamente",
    getForms: "Obtén tus formularios de presentación de impuestos.",
    irsFormsElectronically:
        "Recibe los formularios de impuestos del IRS de forma electrónica.",
    mustSubmit:
        "We must have one of the following taxpayer forms on file before we can provide your tax preparation documents. Please complete only the form that best describes your taxpayer status with the IRS. If your taxpayer information has changed, please submit a new form.",

    paperlessNotification:
        "Los formularios impositivos serán enviados electrónicamente",
    paperFormNotification:
        "Los formularios impositivos serán enviados por correo",

    noForms: "Aún no tienes formularios.",

    ifExpecting: "Si estás esperando un",
    willBeAvailable: "estará disponible el",
    form1099title: "Formulario 1099-MISC",
    form1042title: "Formulario 1042-S",
    jan31: "31 de enero",
    march15: "15 de marzo",

    yourInformation: "Provide your taxpayer information",
    whoFor: "Para quién es",
    howToSubmit: "Cómo presentarlo",
    w9title: "Formulario W-9",
    w9whoFor:
        "Enviar si eres un ciudadano estadounidense o un extranjero residente que posee uno de los siguientes tipos de número de identificación impositiva: SSN, EIN o ITIN. Los extranjeros no residentes no deben completar este formulario.",
    w8ECItitle: "Formulario W-8ECI",
    w8ECIwhoFor:
        "Enviar si eres una persona extranjera, un extranjero no residente o una entidad que actualmente posee un SSN, EIN o ITIN y estarás informando este ingreso al completar una declaración impositiva anual ante el IRS.",
    w8BENtitle: "Formulario W-8BEN",
    w8BENwhoFor:
        "Enviar si eres una persona extranjera o un extranjero no residente que no presenta una declaración impositiva anual ante el IRS o deseas solicitar beneficios de tratado impositivo entre los EE. UU. y tu país de residencia.Dado que los beneficios por tratados no aplican para ingresos por alquileres, laspersonas que presenten un formulario W‐8BEN están sujetas a retenciones extranjeras, deducidas del ingreso bruto, a una tasa del 30%.",
    w8BENEtitle: "Formulario W-8BEN-E",
    w8BENEwhoFor:
        "Enviar si eres beneficiario extranjero de ingresos, que no es una persona física, y no presenta una declaración impositiva anual ante el IRS. Los beneficios por tratados sobre el impuesto a la renta también se pueden solicitar en la Parte III de este formulario, Dado que los beneficios por tratados no aplican para ingresos por alquileres, los beneficiarios que presenten un formulario W-8BEN-E están sujetos a retenciones extranjeras, deducidas del ingreso bruto, a una tasa del 30%.",

    learnMore: "Más información en",
    mailForm: "Completa el formulario y envíalo por correo a:",

    dwnld: "Descargar",
    download: "Descargar los datos de tu propiedad",
    preparingNotification: "Preparando tus datos para descarga",
    rentalData: "Datos de la propiedad",
    noDownloads: "Aún no tienes descargas.",
    description: "Los datos para cada año fiscal estarán disponibles el",
    january15: "15 de enero",
    description2:
        "del siguiente año. Si compartes el dominio con uno o más personas, cada uno tendrá datos separados para descargar de modo que tengas la información correcta para declarar.",
    instructions:
        "Los archivos .xls se pueden abrir con la mayoría de las aplicaciones de hojas de cálculo, incluidas Excel, Numbers y Google Sheets.",
    makeChanges: "Want to make changes?",
    w9Support:
        "To make edits to your W-9 details please reach out to the {{HOME_OWNER_SUPPORT}} at {{SUPPORT_NUMBER}}, {{SUPPORT_EMAIL}}",
    taxModal: {
        actionRequired: "Action Required!",
        title: "Enter Taxpayer Info",
        description:
            "We need your taxpayer information on file so we can provide you with accurate tax preparation documents for last year.",
        primaryButton: "Fill it Out",
        secondardButton: "Maybe Later",
    },
    paperless1099Modal: {
        title: "Paperless 1099",
        description:
            "As the new year approaches, be prepared for tax season by receiving your Vacasa 1099 digitally. We'll email you when it's ready.",
        goPaperless: "Go Paperless",
        skipForNow: "Skip for Now",
    },
    w9: {
        taxFormSelect: {
            info1: "Please provide your taxpayer information so we can provide your tax preparation documents. Provide information that best describes your taxpayer status with the IRS.",
            info2: "The name/entity that you provide should be the same name/entity that is listed on your contract with Vacasa.",
        },
        taxClassifications: {
            INP: "Individual/sole proprietor or single-member LLC",
            LLC: "Limited liability company",
            CCO: "C Corporation",
            SCO: "S Corporation",
            PRT: "Partnership",
            TRE: "Trust/estate",
            OTH: "Other",
        },
        llcTaxClassfications: {
            C: "C Corporation",
            S: "S Corporation",
            P: "Partnership",
        },
        taxIdTypes: {
            SSN: "SSN (Social Security Number)",
            EIN: "EIN (Employer Identification Number)",
        },
        forms: {
            agreeAndSign: {
                title: "Agree & Submit",
                termsOfAgreement: "Terms Of Agreement",
                terms: "Under penalties of perjury, I certify that:\n\n1. The number shown on this form is my correct taxpayer identification number (or I am waiting for a number to be issued to me); and \n\n2. I am not subject to backup withholding because: (a) I am exempt from backup withholding, or (b) I have not been notified by the Internal Revenue Service (IRS) that I am subject to backup withholding as a result of a failure to report all interest or dividends, or (c) the IRS has notified me that I am no longer subject to backup withholding; and \n\n3. I am a U.S. citizen or other U.S. person (defined below); and \n\n4. The FATCA code(s) entered on this form (if any) indicating that I am exempt from FATCA reporting is correct. Certification instructions. You must cross out item 2 above if you have been notified by the IRS that you are currently subject to backup withholding because you have failed to report all interest and dividends on your tax return. For real estate transactions, item 2 does not apply. For mortgage interest paid, acquisition or abandonment of secured property, cancellation of debt, contributions to an individual retirement arrangement (IRA), and generally, payments other than interest and dividends, you are not required to sign the certification, but you must provide your correct TIN. See the instructions for Part II, later.",
                iAgree: "I agree to these terms",
                signature: "Signature",
                signaturePlaceholder: "Type fullname",
                signatureHelperText:
                    "By signing this document with an electronic signature, I agree that such signature will be as valid as handwritten signatures to the extent allowed by local law.",
                signedOn: "Signed On",
                validation: {
                    agreeToTermsRequired:
                        "Please agree to our terms of agreement",
                    signatureRequired: "Please enter your signature",
                    signatureMaxLength: "Signature cannot exceed 50 letters",
                },
            },
            taxInfo: {
                validation: {
                    entityTypeRequired: "Entity type is required",
                    entityTypeEmpty: "Entity type must be empty",
                    entityNameRequired: "Entity name is required",
                    taxIDTypeRequired: "Tax ID type is required",
                    taxIDTypeValid: "Tax ID type must be either SSN or EIN",
                    taxIDTypeEIN: "Tax ID type must be EIN",
                    taxClassificationRequired:
                        "Please select a tax classification",
                    taxClassificationValid: "Tax classification invalid",
                    ssnRequired: "SSN is required",
                    ssnLength: "SSN must be 9 digit in XXX-XX-XXXX format",
                    ssnInvalid: "SSN must be 9 digit in XXX-XX-XXXX format",
                    ssnAllZero: "SSN cannot be all 0",
                    ssnEmpty: "SSN must be empty",
                    einRequired: "EIN is required",
                    einLength: "EIN must be 9 digit in XX-XXXXXXX format",
                    einInvalid: "EIN must be 9 digit in XX-XXXXXXX format",
                    einAllZero: "EIN cannot be all 0",
                    einEmpty: "EIN must be empty",
                    llcTypeRequired: "LLC type required",
                    llcTypeValid: "LLC type must be either C, S, or P",
                    llcTypeEmpty: "LLC type must be empty",
                },
            },
            mailingAddress: {
                label: {
                    address: "Address",
                    city: "City",
                    stateOrProvince: "State/Province",
                    zipCode: "Zip/Postal Code",
                },
                placeholder: {
                    addressLine1: "Address Line 1",
                    addressLine2: "Address Line 2",
                    city: "City",
                    stateProvince: "State/Province",
                    zipCode: "5-digit zipcode",
                },
                validation: {
                    addressRequired: "Address is required",
                    cityRequired: "Please enter city",
                    postalCodeRequired: "Postal code is required",
                    provinceRequired: "State/Province is required",
                    postalCodeMaxLength: "Postal code must be 5 digit length",
                    postalCodeAllZero: "Postal code cannot be all 0",
                },
            },
        },
    },
}

export default taxes
