/* eslint-disable camelcase */
import segmentService from "../segment.service"
import {
    ANNUAL_STATEMENTS_ERROR_PRESENTED,
    ANNUAL_STATEMENTS_ERROR_SUPPORT_NUMBER_CLICKED,
    ANNUAL_STATEMENTS_RETRY_CLICKED,
    CSV_LABEL,
    LINK_TYPE,
    OWNER_TAX_FORMS_EVENT,
    TAXES_PAGE_PATH,
} from "./taxesTrackingConstants"

export const trackTaxesPageViewed = (): void => {
    segmentService.page({ path: TAXES_PAGE_PATH })
}

export const trackRentalDataViewed = (year: string): void => {
    segmentService.clickTrack({
        type: LINK_TYPE,
        label: `${year} rental data spreadsheet`,
    })
}

export const trackAnnualDataDownloadXLS = (): void => {
    segmentService.clickTrack({
        type: LINK_TYPE,
        label: CSV_LABEL,
    })
}

export const trackOwnerTaxFormsDeliverMethod = (
    receiveElectronically: boolean
): void => {
    segmentService.clickTrack(
        { deliver_electronically: receiveElectronically },
        OWNER_TAX_FORMS_EVENT
    )
}

export const trackOwnerTaxFormViewed = (
    year: string,
    formName: string
): void => {
    segmentService.clickTrack({
        type: LINK_TYPE,
        label: `${year} ${formName}`,
    })
}

export const trackAnnualStatementsErrorPresented = (): void => {
    segmentService.track(ANNUAL_STATEMENTS_ERROR_PRESENTED, {})
}

export const trackAnnualStatementsErrorSupportNumberClicked = (): void => {
    segmentService.track(ANNUAL_STATEMENTS_ERROR_SUPPORT_NUMBER_CLICKED, {})
}

export const trackAnnualStatementsRetryClicked = () => {
    segmentService.track(ANNUAL_STATEMENTS_RETRY_CLICKED, {})
}
