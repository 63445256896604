import * as React from "react"
import { Check } from "react-feather"
import { FormattedMessage } from "react-intl"
import styles from "./UnitMinRateNotification.module.scss"

export const CompletedView = (): JSX.Element => {
    return (
        <div className={styles.completedBorder} data-testid="completed-view">
            <div className={styles.iconAndMessage}>
                <div className={styles.iconCircle}>
                    <Check className={styles.completedIcon} />
                </div>
                <div>
                    <FormattedMessage
                        id="Performance.minRateCompleted"
                        defaultMessage="<strong>The Owner Support team have been alerted that you would like to adjust your minimum rate.</strong>
                            Someone will reach out to you to discuss and confirm any changes."
                        values={{
                            strong: (chunk: React.ReactNode) => (
                                <strong>{chunk}</strong>
                            ),
                        }}
                    />
                </div>
            </div>
        </div>
    )
}
