/* eslint-disable no-template-curly-in-string */
const calendar = {
    vacasa: "Vacasa",
    title: "Calendario",
    interiorDesignBannerText:
        "Guadagna di più con un nuovo progetto di interior design",
    covidBannerText:
        "Coronavirus homeowner resource guide - what we're doing to navigate COVID-19 together.",
    covidCtaDestination:
        "https://www.vacasa.com/support/coronavirus-homeowner-resource-guide",
    learnMore: "Per sapere di più",
    emptyState:
        "You’ll be able to reserve dates for your personal use and view upcoming reservations.",

    OwnerHolds: {
        heading: "Owner holds",
        nonfixedHelperText:
            "Use this calendar to reserve dates for your own personal use.",
        fixedHelperText:
            "To reserve dates for your own personal use, please contact Homeowner Account Support at +1 (971) 254-3001 or",
        inactiveHelperText:
            "Questa proprietà non è attiva. I soggiorni a uso del proprietario possono essere aggiunti solo alle proprietà attive.",
        ifHoldQuestions: "Se hai domande su questa prenotazione, contatta",
        toChangeHold:
            "Se devi cambiare le tue date o cancellare questa prenotazione, invia la tua richiesta a",
        continue: "Continua",
        ariaLabel: "Clicca per inserire un soggiorno a uso del proprietario",
        checkIn: "Check in",
        checkOut: "Check out",
        notSelected: "Non selezionato",
        clearDates: "Cancella le date",
        employeeOptions: "Opzioni aggiuntive per il dipendente",
        holdType: "Hold Reason",
        ownerHold: "Soggiorno a uso del proprietario",
        guestStay: "Guest Stay",
        vacasaHold: "Prenotazione di Vacasa",
        selectOwner: "Seleziona un proprietario",
        scheduleClean:
            "Vuoi che organizziamo le pulizie alla fine del tuo soggiorno?",
        yes: "Sì",
        no: "No",
        or: "o",
        warningBanner:
            "One or more of the dates for your hold are unavailable. Please check your selection and try again.",
        datesPlaceholder: "MM/DD/YYYY",
    },

    flyout: {
        vacasaContact: "Vacasa contact",
        contactUsFooter:
            "If you have any questions about this hold, please reach out.",
        back: "Back",
        clear: "Clear",
        holdPlaced: "Hold placed",
        holdUpdated: "Hold updated",
        tempAccessCode: "Codice di accesso temporaneo",
        smartLockAria:
            "Clicca su questo link per mostrare il codice temporaneo di Smart lock.",
        employeesMustClickToView: `I dipendenti devono <button>cliccare per visualizzare questo codice</button>. Per motivi di sicurezza, questa azione sarà registrata.`,
        smartLockCode: "Codice Smart Lock",
        notAvailable: "Non disponibile",
        selectOption: "Seleziona un'opzione",
        reservation: "Prenotazione",
        ownerStay: "Soggiorno a uso del proprietario",
        ownerStaySubtitle: "Un soggiorno a uso personale.",
        complimentaryStay: "Soggiorno gratuito",
        complimentaryStaySubtitle: "Una prenotazione per famiglia o amici.",
        vacasaHold: "Prenotazione di Vacasa",
        vacasaHoldSubtitle: "Opzione disponibile solo per i dipendenti.",
        block: "Blocca",
        blockSubtitle: "Nessuno utilizzerà la casa.",
        propertyCare: "Prendersi cura della proprietà",
        propertyCareSubtitle: "Manutenzione, aggiornamenti, consegne, ecc.",
        seasonalHold: "Seasonal hold",
        homeForSale: "Home for sale",
        homeForSaleSubtitle: "Activities related to a home sale.",
        other: "Other",
        otherSubtitle: "Does not fit the other categories.",
        terminationHold: "Termination hold",
        terminationHoldSubtitle: "Option only available for employees",
        notes: {
            title: "Notes ",
            titleRequired: "Notes* ",
            optional: "(optional)",
            required: "(required)",
            placeholder:
                "Help us improve our category options by providing a note about this hold",
            none: "N/A",
        },
        housekeeping: "Servizio di pulizie",
        yesHousekeepingSubtitle:
            "A Vacasa housekeeping visit will be scheduled after this hold.",
        yesHousekeepingSubtitleWithCleaningFee:
            "A Vacasa housekeeping visit will be scheduled after this hold and billed to your account in the amount of <strong>${amount}.</strong>",
        noHousekeepingSubtitle:
            "You will be responsible for cleaning and preparing the home for the next guests.",
        ownerResponsibleForCleaning: "Owner responsible for cleaning",
        housekeepingScheduled: "Servizio di pulizie programmato",
        noHousekeeping: "Nessun servizio di pulizie",
        numberGuestsMax: `{{number}} num. massimo di ospiti`,
        noPets: "Animali non ammessi",
        restrictionsDontApply:
            "Le restrizioni per gli ospiti non si applicano ai proprietari.",
        night: "night",
        nights: "notti",
        madeOn: "Booked on {creationDate}",
        madeBy: "Booked on {date} by {name}",
        monthCount:
            "Spans {monthCount, plural,=0 {}one {# month}other {# months}}",
        date: "Date",
        dates: "Date",
        guests: "Ospiti",
        createHold: "Create Hold",
        next: "Next",
        summary: "Summary",
        cancelOwnerHold: "Cancel owner hold",
        cancelAreYouSure:
            "Are you sure you want to cancel? Your hold has not been scheduled.",
        deleteOwnerHold: "Delete owner hold",
        editHold: "Modifica soggiorno",
        edit: "Modifica",
        editAria: "Clicca qui per modificare.",
        pleaseContactToRequestChange:
            "Contatta {{contact}} per richiedere un cambio di date.",
        saveChanges: "Salva date",
        save: "Salva",
        saveAria: "Clicca qui per salvare.",

        delete: "Cancella",
        removeHold: "Elimina soggiorno",
        deleteHold: "Cancella soggiorno",
        deleteAria: "Clicca qui per cancellare.",
        holdRemoved: "Soggiorno eliminato",
        deleteAreYouSure: "Are you sure you want to delete this?",
        deleteHelperText: "Impossibile completare l'azione.",
        deleteConfirm: "Sì, cancella soggiorno",
        cancelDelete: "No, non cancellare",
        approvalNeeded: "Approvazione necessaria.",
        fixedRentDeletionApproval:
            "Durante un contratto di affitto a canone fisso, la cancellazione di un soggiorno deve essere approvata dal nostro team assistenza.",
        request: "Request",
        requestDelete: "Richiesta di eliminazione",
        deleteRequestConfirmation:
            "Questa è una richiesta per cancellare un soggiorno a uso del proprietario programmato durante un contratto di affitto a canone fisso.",
        holdNumberForRequest: "Richiesta per cancellare il soggiorno num.",
        holdNumber: "Soggiorno num:",
        startDate: "Data di inizio:",
        endDate: "Data di fine:",

        cancel: "Cancella",
        done: "Fatto",
        totalNetRent: "Total Net rent",
        revenueBreakdown: "Revenue breakdown",
        fixedRent: "Affitto a canone fisso",
        grossRent: "Affitto lordo",
        guestReview: "Guest review",
        guestReviewCollapseLabel: "See less",
        guestReviewExpandLabel: "See more",

        inviteGuest: "Invita ospite",
        inviteGuests: "Invite Guests",
        inviteGuestsOptional: "Invite Guests (Optional)",
        inviteAnotherGuest: "Invita un altro ospite",
        inviteGuestsSubtitle:
            "Add guests to share your trip details and home access information.",
        firstName: "Nome",
        lastName: "Cognome",
        emailAddress: "Indirizzo email",
        invitationSent: "Invito inviato",
        invitationsSent: "inviti inviati",
        fiveOrMoreInvitationsSent: "inviti inviati",
        guestRemoved: "Ospite rimosso",
        skip: "Skip",
        skipAria: "Clicca su questo pulsante per saltare l'aggiunta di ospiti",
        inviteAnotherGuestAria:
            "Clicca su questo pulsante per invitare un altro ospite",
        noGuestInfo: "Nessuna informazione sugli ospiti",
        guestInfo: "Guest info",
        adults: "adulti",
        fiveOrMoreAdults: "adulti",
        children: "bambini",
        fiveOrMoreChildren: "bambini",
        dogs: "cani",
        fiveOrMoreDogs: "cani",
        housekeepingNotAllowed:
            "Housekeeping not available. You will be responsible for cleaning and preparing the home for the next guests.",
        newBooking: "New booking",
        pastBooking: "Past booking",
        invalidDates: "The selected dates are not available.",
        learnAboutRates: "Learn about rates",
        guestBookingLikely:
            "These nights are highly likely to be booked by guests.",
        response: "Manager response",
        onboardingFooter: {
            title: "What do you want to do next?",
            backToOnboarding: "Go Back to Onboarding",
            addAdditionalOwnerHolds: "Add Another Owner Hold",
        },
        costOfHold:
            "You miss out on <b>{cost}</b> in gross rent by booking these dates.",
        housekeepingRequired:
            "In order to maintain the highest quality guest experience, housekeeping is required for stays with overnight guests.",
        housekeepingRequiedWithCleaningFee:
            "This owner hold requires a post-stay clean, which will be billed to your account after the stay in the amount of <strong>${amount}.</strong>",
        PhoneSections: {
            smsTitle: "Owner contact info",
            smsDescription: 'Details will be sent to this number.',
            smsEditDescription: "Details will be sent to <strong>{number}</strong>.",
            invalidPhoneMessage: 'The phone number is not valid',
            noPhoneNumber: 'No phone number provided'
        }
    },

    UpcomingStays: {
        title: "Reservations",
        emptyStateUpcoming:
            "New reservations and owner holds will show up here.",
        emptyStatePast:
            "You have no previous reservations. When upcoming stays have concluded, they will appear here.",
        upcomingDescription:
            "Upcoming guest reservations and owner holds will be listed below. Click a row to view more details. If you wish to modify an owner hold you can do so by clicking on the corresponding row.",
        pastDescription:
            "Your past reservations and holds in the last 45 days are listed out below.  If you would like to view more details around each stay, click on the detail row. ",
        toModify:
            "Qui sotto puoi modificare un soggiorno a uso del proprietario.",
        fixedRentModify:
            "Se desideri modificare un soggiorno nella tua proprietà durante un periodo in cui è previsto un affitto a canone fisso, invia un'email a",
        details: "Dettagli",
        Night: "Notte",
        adult: "adulto",
        child: "bambino",
        dog: "cane",
        at: "in",
        upcomingTabTitle: "Upcoming ({total})",
        previousTabTitle: "Previous",
        adults: "adults",
        children: "children",
    },

    errorMessages: {
        generalError:
            "Abbiamo avuto problemi nell'elaborazione della tua richiesta. Ti preghiamo di riprovare più tardi. Se il problema persiste, contattare {name} al numero {phone}",
        datesNotAvailableError:
            "Una o più date per il tuo soggiorno non sono disponibili. Ti preghiamo di controllare la tua scelta e riprovare.",
        createHoldError:
            "Non è stato possibile creare il tuo soggiorno a uso del proprietario.",
        deleteHoldError:
            "Non è stato possibile rimuovere il tuo soggiorno a uso del proprietario.",
        updateHoldError:
            "Non è stato possibile aggiornare il tuo soggiorno a uso del proprietario.",
        updateHoldCleanError:
            "Non è stato possibile aggiornare lo stato delle pulizie.",
        addGuestToHoldError: "Non è stato possibile aggiungere l'ospite.",
        removeGuestFromHoldError: "Non è stato possibile rimuovere l'ospite.",
        modalTitle: "Oops!",
        modalMessage:
            "It looks like this reservation is no longer in our system.",
        invalidDateError: "This date is unavailable",
        unableToLoadReservations: "We were unable to load your reservations.",
    },

    reservations: {
        reservation: "Reservation",
        vacasaHold: "Vacasa hold",
    },

    switchAccounts: {
        notification: "This reservation is associated with another account",
        description:
            "Please login to the appropriate homeowner account and click the new booking email notification to view the new reservation details.",
        button: "Switch Accounts",
        maybeLater: "Maybe Later",
        descriptionButtom:
            "Interested in linking your accounts into one login? Call <strong>Owner Support</strong> at {phone} to get it set-up.",
    },

    reservationType: {
        ownerHold: "Owner Hold",
        vacasaHold: "Vacasa Hold",
        reservation: "Guest Stay",
    },
}

export default calendar
