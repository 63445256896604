import segmentService from "../segment.service"
import {
    NOTIFICATION_SETTINGS_ACTION,
    NOTIFICATION_SETTINGS_ACTION_TOOLTIP,
    NOTIFICATION_SETTINGS_EVENT,
} from "./notificationSettingsTrackingConstants"

export const trackUserNotificationPreferenceToggled = (
    action: NOTIFICATION_SETTINGS_ACTION,
    preferenceId: string,
    preferenceTitle: string,
    preferenceKey: "email" | "push" | "sms"
): void => {
    segmentService.clickTrack(
        {
            preferenceId,
            preferenceTitle,
            preferenceKey,
            action,
        },
        NOTIFICATION_SETTINGS_EVENT
    )
}

export const trackUserNotificationTooltipOpen = (
    action: NOTIFICATION_SETTINGS_ACTION_TOOLTIP
): void => {
    segmentService.clickTrack(
        {
            action,
        },
        NOTIFICATION_SETTINGS_EVENT
    )
}
