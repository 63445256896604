const petsAllowed = {
    title: "Kutyabarát",
    ariaLabel: "Kattints a gombra, hogy kisállatbaráttá tedd a nyaralódat.",
    description:
        "Kutyabaráttá teszi a nyaralót, így még több potenciális vendégnek hirdethetjük az ingatlant. Kutyatartáshoz szükséges tartozékokat teszünk elérhetővé, és biztosítjuk, hogy a nyaraló megfelelően gondozott legyen.",
    learnMore: "További információk",
    earnMore: "Akár 20%-kal is növelheted a bevételedet!",
    earnMoreDescription:
        "A kisállatbarát ingatlanok jelentősen növelhetik a nyereséget és a foglalások számát, különösen az idényen kívüli időszakokban.",
    untitledHome: "Név nélküli nyaraló",
    showAllRentals: "Összes nyaraló megjelenítése",
    showAllAriaLabel:
        "A gomb segítségével megjelenítheted az összes nyaralódat.",
    hideRentals: "Nyaralók elrejtése",
    allowed: "Engedélyezve",
    allow: "Engedélyezés",
    modalHeader: "Szuper! Minden beállítást elvégeztél!",
    modalText:
        "A nyaralód naprakész állapotban van, a helyi csapatodat pedig értesítjük, hogy biztosítsák, hogy ingatlanod készen álljon a kutyák fogadására. A módosítás minden hirdetésben és hirdetési csatornán történő alkalmazásához legfeljebb 24 óra szükséges.",
    modalFooter: "Kattints erre a gombra a bezáráshoz.",
    ok: "OK",
    disclaimer:
        "A módosítás minden hirdetésben és hirdetési csatornán történő alkalmazásához legfeljebb 24 óra szükséges. Amennyiben kérdésed merül fel, vagy nem szeretnéd a továbbiakban engedélyezni a kutyákat a nyaralódban, vedd fel a kapcsolatot az ingatlanodért felelős kapcsolattartóval.",
}

export default petsAllowed
