import React from "react"

interface LoaderProps {
    className?: string
    overlay?: boolean
    spinnerSize?: string
    styles?: { [key: string]: string }
    testId?: string
}

const Loader: React.FC<LoaderProps> = (props: LoaderProps) => {
    const { className, overlay, spinnerSize, styles, testId = "loader" } = props
    return (
        <div
            data-testid={testId}
            className={
                overlay
                    ? `loading-overlay ${className ? className : ""}`
                    : `inline-loader ${className ? className : ""}`
            }
        >
            <div
                style={styles}
                className={spinnerSize ? spinnerSize : "spinner"}
            >
                <div className="bounce1" />
                <div className="bounce2" />
                <div className="bounce3" />
            </div>
        </div>
    )
}

export default Loader
