import React, { CSSProperties, FC, ReactNode } from "react"
import classnames from "classnames"

import { ChevronRight, AlertTriangle, Info, AlertOctagon } from "react-feather"
import type { Icon } from "react-feather"

import styles from "./Alert.module.scss"
import { getClassStyle } from "utils/styles/styleWrapper"

const AlertVariants = {
    alertness: { Icon: AlertTriangle },
    info: { Icon: Info },
} as const

export type Variant = keyof typeof AlertVariants

type Context = { variant: Variant }
const AlertContext = React.createContext<Context | null>(null)
const useAlertContext = (): Context => {
    const context = React.useContext(AlertContext)
    if (context === null) {
        throw new Error(
            "Alert compound components cannot be rendered outside the Alert component"
        )
    }
    return context
}

type IconProps = {
    icon?: Icon
}

const AlertIcon = ({ icon }: IconProps): JSX.Element => {
    const { variant } = useAlertContext()
    const { Icon: VariantIcon } = AlertVariants[variant]
    const IconComponent = icon ?? VariantIcon

    return (
        <div
            className={classnames(styles.iconCircle, {
                [getClassStyle(styles.alertnessIcon)]: variant === "alertness",
                [getClassStyle(styles.infoIcon)]: variant === "info",
            })}
        >
            <IconComponent className={styles.icon} />
        </div>
    )
}

const LinkIcon = (): JSX.Element => <ChevronRight className={styles.linkIcon} />

const Title = React.forwardRef<HTMLDivElement, JSX.IntrinsicElements["span"]>(
    (props, ref) => (
        <span
            {...props}
            ref={ref}
            className={classnames(
                styles.title,
                "extra-small",
                "bold",
                props.className
            )}
        />
    )
)

const Description = React.forwardRef<
    HTMLDivElement,
    JSX.IntrinsicElements["div"]
>((props, ref) => (
    <div
        {...props}
        ref={ref}
        className={classnames(
            styles.description,
            "small",
            "medium",
            props.className
        )}
    />
))

export type Props<COMPONENT extends React.ElementType> = {
    /**
     * Change the contaier element
     */
    as?: COMPONENT
    /**
     * The visual style of the alert
     */
    variant: Variant

    /**
     * Visual style for a clickabled alert
     */
    clickable?: boolean
    disabled?: boolean
    /**
     * The content of the Inline Notification
     */
    children: React.ReactNode
}

/**
 * When there is an `as` prop, add the types from the supplied component to the props
 * e.g adding an `a` tag adds href, target, etc
 */
export type AlertProps<COMPONENT extends React.ElementType> = Props<COMPONENT> &
    Omit<React.ComponentPropsWithoutRef<COMPONENT>, keyof Props<COMPONENT>>

/**
 * @example
 *  <Alert variant="info">
 *    <Alert.Icon />
 *    <Alert.Description><Alert.Title>A lovely title</Alert.Title> some more description</Alert.Description>
 *  </Alert>
 */
const Alert = <C extends React.ElementType>({
    as,
    variant,
    disabled = false,
    clickable = false,
    children,
    ...rest
}: AlertProps<C>): JSX.Element => {
    const Component = as ?? "div"

    return (
        <AlertContext.Provider value={{ variant }}>
            <Component
                {...rest}
                className={classnames(styles.alert, {
                    [getClassStyle(styles.alertness)]: variant === "alertness",
                    [getClassStyle(styles.info)]: variant === "info",
                    [getClassStyle(styles.clickable)]: clickable,
                    [getClassStyle(styles.disabled)]: disabled,
                })}
            >
                {children}
            </Component>
        </AlertContext.Provider>
    )
}

Alert.Icon = AlertIcon
Alert.Title = Title
Alert.Description = Description
Alert.LinkIcon = LinkIcon

interface NewAlertProps {
    className?: string
    icon?: ReactNode
    message: string
    style?: CSSProperties
    variant: "danger" | "warning" | "info"
}

const getAlertIcon = (variant: "danger" | "warning" | "info") => {
    switch (variant) {
        case "danger":
        default:
            return <AlertOctagon />
    }
}

const NewAlert: FC<NewAlertProps> = ({
    className,
    icon,
    message,
    style,
    variant = "danger",
}) => {
    if (variant !== "danger") {
        throw new Error(`Variant: "${variant}", has not be implemented`)
    }

    if (!icon) {
        icon = getAlertIcon(variant)
    }

    return (
        <div
            style={style}
            className={classnames(styles["new-alert"], className, {
                [styles["new-alert--danger"] as string]: variant === "danger",
            })}
        >
            <div className={styles["new-alert__icon"]}>{icon}</div>
            <div className={styles["new-alert__body"]}>{message}</div>
        </div>
    )
}

export { Alert, NewAlert }
