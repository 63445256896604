const survey = {
    title: "PREISE & EINNAHMEN",
    subTitle: "Einnahmestrategie festlegen",
    surveyFollowUp: "Schritt {step} von {totalSteps}",
    errorTitle: "Umfrage nicht verfügbar",
    loadingErrorMessage: "Entschuldigung, die Umfrage konnte nicht geladen werden. Bitte versuchen Sie es später erneut.",
    submitErrorMessage: "Entschuldigung, wir konnten Ihre Umfrage derzeit nicht übermitteln. Bitte versuchen Sie es später erneut.",
    tanksTitle: "Glückwunsch zur Festlegung Ihrer Einnahmestrategie!",
    tanksMessage: "Unser Team arbeitet daran, Ihre Anfragen umzusetzen."
}

export default survey
