import React from "react"
import styles from "./RateCalculatorChartSkeleton.module.scss"
import classNames from "classnames"

export const RateCalculatorChartSkeleton: React.FC = () => {
    return (
        <div className={styles.rateCalculatorSkeleton}>
            <div className={classNames("shimmer", styles.headerSkeleton)} />
            <div
                className={classNames("shimmer", styles.descriptionSkeleton)}
            />
            <div className={styles.resultRowSkeleton}>
                <div
                    className={classNames("shimmer", styles.minRateSkeleton)}
                />
                <div className={classNames("shimmer", styles.impactSkeleton)} />
            </div>
            <div className={classNames("shimmer", styles.inputSkeleton)} />
            <div className={classNames("shimmer", styles.chartSkeleton)} />
            <div className={classNames("shimmer", styles.legendSkeleton)} />
        </div>
    )
}
