import { CardContent } from "@material-ui/core"
import React, { ReactElement, useEffect, useState } from "react"
import { Navigate } from "react-router-dom"
import { Card } from "../components/Card"

import Loader from "../lib/components/Loader"
import LoggingService from "../services/logging/logging.service"
import { useIsEmployee } from "hooks/user"
import { useLoginInfo } from "contexts/login"
import { updateSessionConfig } from "utils/session"

const SearchForward: React.FC = (): ReactElement => {
    const [contactLoaded, setContactLoaded] = useState(false)
    const [redirectUrl, setRedirectUrl] = useState("/")
    const isEmployee = useIsEmployee()
    const { setContactIds, user } = useLoginInfo()

    useEffect(() => {
        const url = new URL(window.location.href)
        const contactId = url.searchParams.get("ContactID")

        const unitId = url.searchParams.get("UnitID")
        const view = url.searchParams.get("view")

        if (!isEmployee) {
            setRedirectUrl(`/${view}?u=${unitId}&c=${contactId}`)
            return
        }
        if (!contactId) {
            LoggingService.log({
                message:
                    "Redirect to /search as no contact id in search params",
            })
            setRedirectUrl(`/search`)
            return
        }

        updateSessionConfig({
            contactIds: [contactId],
        })

        setContactIds([contactId])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!isEmployee || contactLoaded) return
        if (user) setContactLoaded(true)
    }, [contactLoaded, isEmployee, user])

    // Prevent hanging on the loader screen if not employee
    if (!isEmployee) {
        return <Navigate to={redirectUrl} />
    }

    return (
        <div>
            {contactLoaded || redirectUrl === "/search" ? (
                <Navigate to={redirectUrl} />
            ) : (
                <Card className="container-fluid">
                    <CardContent>
                        <Loader />
                    </CardContent>
                </Card>
            )}
        </div>
    )
}

export default SearchForward
