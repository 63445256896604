import { FC, useEffect } from "react"

// Assets
import { ReactComponent as VacasaLogoHorizontal } from "../assets/vacasa-logo-horizontal.svg"
import { ReactComponent as LuggageCat } from "../assets/luggage_cat.svg"
import styles from "./error.module.scss"

// Components
import * as Button from "../lib/components/Buttons/BaseButton"

// Utils
import { logoutToIdp, getEmployeeIdpAuthorizeUrl } from "../utils/auth/idp"

// Consts
import { APP_URL, MY_TRIPS_URL } from "../Environment"
import { useLocation } from "react-router-dom"
import { useIntl } from "react-intl"
import { HOMEOWNER_ACCOUNTS_EMAIL } from "Constants"
import LoggingService from "services/logging/logging.service"
import { trackErrorPageViewed } from "services/segment/error/errorTracking"

interface ErrorUiProps {
    errorDescription: string
    errorType: string
}

const Header = () => (
    <div className={styles["header"]}>
        <VacasaLogoHorizontal
            title="Vacasa Logo"
            height="24"
            onClick={() => (window.location.href = `${APP_URL}`)}
            style={{ cursor: "pointer" }}
        />
    </div>
)

const ErrorMessageWithSupportDetails = (errorMessage: string) => {
    const intl = useIntl()
    const contactEmail = intl.formatMessage(
        {
            id: "NotFound.errorMessageContactEmail",
            defaultMessage: `{homeownerEmail}`,
        },
        { homeownerEmail: HOMEOWNER_ACCOUNTS_EMAIL }
    )
    const contactPhoneNumber = intl.formatMessage({
        id: "NotFound.errorMessagePhoneNumber",
        defaultMessage: `971-254-3001`,
    })

    return (
        <p>
            {errorMessage} <a href={`mailto:${contactEmail}`}>{contactEmail}</a>{" "}
            or <a href="tel:+19712543001">{contactPhoneNumber}</a>.
        </p>
    )
}

const ReLoginButton = (buttonText: string) => {
    return (
        <div className={styles["button-wrapper"]}>
            <Button.Primary onClick={() => logoutToIdp()}>
                {buttonText}
            </Button.Primary>
        </div>
    )
}

const LoginErrorUI = (error: ErrorUiProps) => {
    const intl = useIntl()

    const isNoLegacyIdError = error.errorDescription === "NO_LEGACY_USER_ID"
    const isNoOwnerScopeError =
        error.errorDescription === "Requested scopes not allowed for user"

    let errorTitleText = ""
    let errorMessage: string | JSX.Element = ""
    let buttonText = intl.formatMessage({
        id: "NotFound.GoToLoginButton",
        defaultMessage: "Go to login",
    })

    let moreErrorInfo: JSX.Element | undefined
    let buttonAfterErrorMessage = true
    if (isNoLegacyIdError) {
        errorTitleText = intl.formatMessage({
            id: "NotFound.noLegacyIdErrorTitle",
            defaultMessage: "Oops, looks like we’re missing something",
        })

        errorMessage = ErrorMessageWithSupportDetails(
            intl.formatMessage({
                id: "NotFound.noLegacyIdErrorMessage",
                defaultMessage:
                    "To see your properties in your owner account, please contact us at Owner Support.",
            })
        )
    } else if (isNoOwnerScopeError) {
        errorTitleText = intl.formatMessage({
            id: "NotFound.noOwnerScopeErrorTitle",
            defaultMessage: "You’re signed in with a guest account.",
        })

        errorMessage = ErrorMessageWithSupportDetails(
            intl.formatMessage({
                id: "NotFound.noOwnerScopeErrorMessage",
                defaultMessage:
                    "If you have an owner account, sign in again with that account. Need help? Contact Owner Support at",
            })
        )
    } else {
        errorTitleText = intl.formatMessage({
            id: "NotFound.genericLoginErrorTitle",
            defaultMessage:
                "Hmm, there was an error displaying the owner account.",
        })

        errorMessage = ErrorMessageWithSupportDetails(
            intl.formatMessage({
                id: "NotFound.genericLoginErrorContact",
                defaultMessage:
                    "Still having trouble? Contact Owner Support at",
            })
        )
        buttonText = intl.formatMessage({
            id: "NotFound.tryAgainButtonTitle",
            defaultMessage: "Try again",
        })

        moreErrorInfo = ErrorMessageInfo(error)

        buttonAfterErrorMessage = false
    }

    const errorTitle = <h4>{errorTitleText}</h4>
    const reloginButton = ReLoginButton(buttonText)
    const errorMessageWithButton = buttonAfterErrorMessage ? (
        <>
            {errorMessage}
            {reloginButton}
        </>
    ) : (
        <>
            {reloginButton}
            <p />
            {errorMessage}
            {moreErrorInfo}
        </>
    )
    return (
        <div className={styles["no-homes"]}>
            {errorTitle}
            {errorMessageWithButton}
        </div>
    )
}

const ErrorMessageInfo = (error: ErrorUiProps) => {
    const intl = useIntl()
    const errorCodeMessage = intl.formatMessage(
        {
            id: `Errors.${error.errorDescription}`,
            defaultMessage: "{placeholder}",
        },
        { placeholder: "" }
    )

    if (errorCodeMessage) {
        return (
            <div
                data-testid="error-message-info"
                className={styles["more-error-info"]}
            >
                {intl.formatMessage({
                    id: "NotFound.genericLoginErrorMessage",
                    defaultMessage: "Error Message: ",
                }) + errorCodeMessage}
            </div>
        )
    }
    LoggingService.warn({
        message: `Error Description Not Found: ${error.errorDescription}`,
    })
    return <></>
}

const YouGotNoHomes = () => {
    const intl = useIntl()

    const errorTitle = (
        <h4>
            {intl.formatMessage({
                id: "NotFound.unknownErrorTitle",
                defaultMessage: "Your account has no rental properties",
            })}
        </h4>
    )
    const errorMessage = (
        <p>
            {intl.formatMessage({
                id: "NotFound.unknownErrorMessage",
                defaultMessage:
                    "Try logging in again, or using a different account",
            })}
        </p>
    )
    const reloginButton = ReLoginButton(
        intl.formatMessage({
            id: "NotFound.GoToLoginButton",
            defaultMessage: "Go to login",
        })
    )
    return (
        <div className={styles["no-homes"]}>
            {errorTitle}
            {errorMessage}
            {reloginButton}
        </div>
    )
}

const ErrorUI = (error: ErrorUiProps) => {
    if (error.errorType === "login") {
        return LoginErrorUI(error)
    } else {
        return YouGotNoHomes()
    }
}

const LookingForTrip = () => {
    return (
        <div className={styles["looking-for-trips__wrapper"]}>
            <div className={styles["looking-for-trips__content"]}>
                <h4>Looking for your trips?</h4>
                <p>Easily view and manage all your trips in one place.</p>
                <div className={styles["looking-for-trips__button"]}>
                    <Button.Primary
                        onClick={() => (window.location.href = MY_TRIPS_URL)}
                    >
                        View my trips
                    </Button.Primary>
                </div>
            </div>
            <div className={styles["looking-for-trips__clipart-container"]}>
                <LuggageCat title="Luggage Cat" width="160" />
            </div>
        </div>
    )
}

const Error: FC = () => {
    const location = useLocation()

    useEffect(() => {
        trackErrorPageViewed()
    }, [])

    const errorDescription = String(
        new URLSearchParams(location.search).get("error_description") ?? ""
    )
    const errorType = String(
        new URLSearchParams(location.search).get("error_type") ?? ""
    )

    return (
        <div className={styles["error-view-wrapper"]}>
            <div className={styles["content-wrapper"]}>
                <Header />
                <ErrorUI
                    errorDescription={errorDescription}
                    errorType={errorType}
                />

                <LookingForTrip />
                <div className="full-width onelogin-link">
                    <span className="text-align-left">
                        Vacasa staff? Use{" "}
                        <a href={getEmployeeIdpAuthorizeUrl()}>OneLogin</a>.
                    </span>
                </div>
            </div>
        </div>
    )
}

export default Error
