import { FC, useEffect, useRef, useState } from "react"
import {
    Options,
    PointOptionsObject,
    XrangePointOptionsObject,
} from "highcharts"
import HighchartsStock from "highcharts/highstock"
import HighchartsReact, {
    HighchartsReactRefObject,
} from "highcharts-react-official"
import { addDays, format, isBefore, parseISO, startOfDay } from "date-fns"
import HighchartsRange from "highcharts/modules/xrange"
import HighchartsMore from "highcharts/highcharts-more"
import { FormattedMessage, useIntl } from "react-intl"
import styles from "./MarketRateComparisonChartV2.module.scss"
import HighchartsBoost from "highcharts/modules/boost"
import { MarketRateComparisonChartSkeleton } from "./MarketRateComparisonChartSkeleton"
import { max } from "lodash"
import {
    RateTransparencyDataVersion2,
    TicketCategoryId,
} from "@vacasa/owner-api-models"
import {
    getChartXAxisForYear,
    getMarketCompChartRanges,
    getChartBaseConfig,
    drawInitialMarkers,
    chartOptionsHasData,
} from "./utils"
import {
    trackBetaTooltipRatesSpecialistClicked,
    trackMarketCompChartPresented,
    trackMarketCompRangeChanged,
} from "services/segment/dashboard/dashboardTracking"
import { RangeSelector } from "./RangeSelector"
import { RangeOptions } from "../types"
import { ChartEmptyState } from "../ChartEmptyState"
import { Tooltip } from "lib/components/Tooltip/Tooltip"
import classNames from "classnames"
import { useFeatureInteractionAPI } from "contexts/feature-feedback"
import { Link } from "react-router-dom"
import {
    RateTransparencyResponse,
    useRateTransparencyData,
} from "hooks/rate-transparency"
import { ISO_FORMAT_DATE_FNS } from "Constants"
import {
    mapReservationType,
    toRateTransparencyDataMap,
} from "utils/charts/chartsUtil"
import { ChartFeatureFeedback } from "./ChartFeatureFeedback"
import { RESERVATION_SERIES } from "./constants"
import ProductTour from "lib/components/ProductTour"
import Slide1 from "./assets/slide-1.png"
import Slide2 from "./assets/slide-2.png"
import Slide3 from "./assets/slide-3.png"
import { MARKET_RATES_PRODUCT_TOUR_VIEWED } from "constants/preferences.constants"
import { useCurrentUnit, useUnitStatuses } from "hooks/units"
import { BetaLabel } from "./BetaLabel"
import { LastRefreshedOn } from "./LastRefreshedOn"
import { SubmitQuestion } from "./SubmitQuestion"
import { OptimizelyFeature } from "@optimizely/react-sdk"
import { getClassStyle } from "utils/styles/styleWrapper"

HighchartsRange(HighchartsStock)
HighchartsMore(HighchartsStock)
HighchartsBoost(HighchartsStock)

// Allow for svgs in tooltip - specifically for ChevronRight
HighchartsStock.AST.allowedAttributes.push("points")
HighchartsStock.AST.allowedTags.push("polyline", "path", "svg", "polygon")

export interface MarketRateComparisonChartV2Props {
    onLoaded: (data: RateTransparencyResponse) => void
}

export const MarketRateComparisonChartV2: FC<
    MarketRateComparisonChartV2Props
> = ({ onLoaded }) => {
    const intl = useIntl()
    const chartRef = useRef<HighchartsReactRefObject>(null)

    const [activeRange, setActiveRange] = useState<RangeOptions>("1Y")
    const [options, setOptions] = useState<Options>(
        getChartBaseConfig({
            locale: intl.locale,
            messages: intl.messages,
            v2: true,
        })
    )

    const { isOnboarding } = useUnitStatuses()

    const { incrementInteractionCount } = useFeatureInteractionAPI()

    const { unitId } = useCurrentUnit()
    const [dateRange] = useState(() => {
        const start = new Date()
        const end = addDays(start, 366)
        return {
            startDate: format(start, ISO_FORMAT_DATE_FNS),
            endDate: format(end, ISO_FORMAT_DATE_FNS),
        }
    })

    const rateTransparencyData = useRateTransparencyData(
        unitId ?? undefined,
        dateRange.startDate,
        dateRange.endDate
    )

    // reset the range selector to default when switching units
    useEffect(() => {
        if (!unitId) return
        setActiveRange("1Y")
        if (chartRef.current) {
            const defaultRange = getMarketCompChartRanges()["1Y"]
            chartRef.current?.chart.xAxis[0]?.setExtremes(
                defaultRange.min,
                defaultRange.max,
                true,
                true
            )
        }
    }, [unitId])

    // track chart shown with or without data
    useEffect(() => {
        if (rateTransparencyData.isLoading) return
        trackMarketCompChartPresented(!!rateTransparencyData.data?.data.length)
    }, [rateTransparencyData.data?.data, rateTransparencyData.isLoading])

    // only increment interaction count if we have data for market rates comparison
    useEffect(() => {
        if (rateTransparencyData.isLoading) return
        if (!rateTransparencyData.data?.data.length) return
        incrementInteractionCount("market-rate-comparison-chart")
    }, [
        incrementInteractionCount,
        rateTransparencyData.data?.data,
        rateTransparencyData.isLoading,
    ])

    useEffect(() => {
        if (!rateTransparencyData.isSuccess) return
        onLoaded(rateTransparencyData.data)
        const marketComp: [number, number, number][] = []

        const yourRates: PointOptionsObject[] = []
        const probabilityOfBookingSeries: PointOptionsObject[] = []
        // used for tooltips
        const reservationDates: PointOptionsObject[] = []
        const xAxisBase = getChartXAxisForYear()

        // Map to an object to enable faster searching
        const rateTransparencyDataMap = toRateTransparencyDataMap(
            rateTransparencyData.data?.data
        )

        xAxisBase.forEach(base => {
            const dailyRate: RateTransparencyDataVersion2 | undefined =
                rateTransparencyDataMap[format(base.x, "yyyy-MM-dd")]

            marketComp.push([
                base.x,
                dailyRate?.avgCompMinRate ?? 0,
                dailyRate?.avgCompMaxRate ?? 0,
            ])

            yourRates.push({
                x: base.x,
                y: dailyRate?.rateBasedOnRes ?? 0,
                color: dailyRate?.reservation ? "#5A2458" : "var(--midnight)",
                data: dailyRate,
            })

            probabilityOfBookingSeries.push({
                x: base.x,
                y: 0,
                highBookingProbability:
                    dailyRate?.probabilityBookedCategory === "high" &&
                    !dailyRate?.reservation,
                bookingProbability: !dailyRate?.reservation
                    ? dailyRate?.probabilityBookedCategory
                    : undefined,
            })
            const reservation = dailyRate?.reservation
            if (reservation) {
                reservationDates.push({
                    x: base.x,
                    y: 0,
                    reservation: {
                        id: String(reservation.id),
                        type: mapReservationType(reservation.type),
                        nights: reservation.duration ?? 1,
                        firstNight: reservation.firstNight ?? "",
                    },
                })
            }
        })

        // used for display on the chart
        const reservationsData = rateTransparencyData.data.data
            .filter(data => data.reservation)
            .filter(
                data =>
                    !isBefore(
                        parseISO(data.reservation?.lastNight ?? ""),
                        startOfDay(new Date())
                    )
            )
            .map<XrangePointOptionsObject>(data => ({
                x: max([
                    startOfDay(
                        parseISO(data.reservation?.firstNight ?? "")
                    ).getTime(),
                    startOfDay(new Date()).getTime(),
                ]),
                x2: startOfDay(
                    addDays(parseISO(data.reservation?.lastNight ?? ""), 1)
                ).getTime(),
                y: 0,
                color:
                    data.reservation?.type === "guest"
                        ? "var(--grape)"
                        : data.reservation?.type === "owner"
                        ? "var(--lime)"
                        : "var(--awareness)",
                className: "reservations",
            }))

        setOptions(prevState => {
            return {
                ...prevState,
                series: [
                    {
                        type: "line",
                        data: yourRates,
                        lineWidth: 3,
                        name: "unit",
                        color: {
                            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                            stops: [
                                [0, "var(--gulf-90)"],
                                [1, "var(--midnight)"],
                            ],
                        },
                        marker: {
                            enabled: false,
                            states: {
                                hover: {
                                    enabled: false,
                                },
                                select: {
                                    enabled: false,
                                },
                            },
                        },
                        states: {
                            hover: {
                                lineWidth: 3,
                            },
                        },
                        zIndex: 2,
                        className: "rates-unit",
                        cursor: "pointer",
                    },
                    {
                        name: "Range",
                        id: "range",
                        data: marketComp,
                        type: "arearange",
                        lineWidth: 0,
                        color: "var(--gulf-40)",
                        fillColor: "var(--gulf-40)",
                        enableMouseTracking: true,
                        cursor: "pointer",
                        marker: {
                            enabled: false,
                            states: {
                                hover: {
                                    enabled: false,
                                },
                                select: {
                                    enabled: false,
                                },
                            },
                        },
                    },
                    {
                        name: "booking-probability",
                        type: "line",
                        data: probabilityOfBookingSeries,
                        lineWidth: 0,
                        color: "transparent",
                        states: {
                            hover: {
                                opacity: 1,
                                lineColor: "transparent",
                            },
                        },
                        marker: {
                            enabled: false,
                            states: {
                                hover: {
                                    enabled: false,
                                },
                                select: {
                                    enabled: false,
                                },
                            },
                        },
                        className: "booking-probability",
                    },
                    {
                        type: "xrange",
                        yAxis: "reservations",
                        name: RESERVATION_SERIES,
                        pointWidth: 8,
                        borderRadius: 2,
                        opacity: 1,
                        states: {
                            inactive: {
                                opacity: 1,
                                lineColor: "transparent",
                            },
                            hover: {},
                        },

                        data: reservationsData,
                        className: "reservations",
                    },
                    {
                        name: "reservation-dates",
                        className: "reservation-date",
                        type: "line",
                        data: reservationDates,
                        lineWidth: 0,
                        color: "transparent",
                        states: {
                            hover: {
                                opacity: 1,
                                lineColor: "transparent",
                            },
                        },
                        marker: {
                            enabled: false,
                            states: {
                                hover: {
                                    enabled: false,
                                },
                                select: {
                                    enabled: false,
                                },
                            },
                        },
                    },
                ],
            }
        })
    }, [
        onLoaded,
        intl.locale,
        intl.messages,
        rateTransparencyData.data,
        rateTransparencyData.isSuccess,
    ])

    const onRangeButtonClick = (range: RangeOptions) => {
        // Due to the default behavior of the range selector zoom from right to left,
        // we need to override the behavior to zoom from left to right
        const ranges = getMarketCompChartRanges()
        // min should always be the current day
        let min = ranges["1Y"].min
        let max = ranges["1Y"].max

        switch (range) {
            case "1D": {
                min = ranges["1D"].min
                max = ranges["1D"].max
                trackMarketCompRangeChanged("1 Day")
                break
            }
            case "1W":
                max = ranges["1W"].max
                trackMarketCompRangeChanged("1 Week")
                break
            case "1M":
                max = ranges["1M"].max
                trackMarketCompRangeChanged("1 Month")
                break
            case "3M":
                max = ranges["3M"].max
                trackMarketCompRangeChanged("3 Months")
                break
            case "6M":
                max = ranges["6M"].max
                trackMarketCompRangeChanged("6 Months")
                break
            default:
                trackMarketCompRangeChanged("1 Year")
        }
        incrementInteractionCount("market-rate-comparison-chart")
        chartRef.current?.chart.xAxis[0]?.setExtremes(min, max, true, true)
        if (chartRef.current?.chart) {
            drawInitialMarkers(chartRef.current.chart, true)
        }
        setActiveRange(range)
    }

    if (rateTransparencyData.isLoading)
        return <MarketRateComparisonChartSkeleton />

    const lastRefreshedOn = rateTransparencyData.data?.meta.lastRefreshedOn

    const hasRateTransparencyData = !!rateTransparencyData.data?.data.length

    if (
        isOnboarding ||
        !unitId ||
        !hasRateTransparencyData ||
        !chartOptionsHasData(options)
    )
        return (
            <ChartEmptyState
                title={intl.formatMessage({
                    id: "Dashboard.charts.marketRate.title",
                    defaultMessage: "Market Rate Comparison",
                })}
                message={intl.formatMessage({
                    id: "Dashboard.charts.marketRate.emptyMessage",
                    defaultMessage:
                        "The way your unit's rate compares to the market will appear here once you receive reservations.",
                })}
            />
        )

    return (
        <>
            <div className={styles.chart}>
                <div className={styles.header}>
                    <h3 className={styles.title}>
                        <FormattedMessage
                            id="Dashboard.charts.marketRate.title"
                            defaultMessage="Market Rate Comparison"
                        />
                    </h3>
                    <span className={styles.titleTooltip}>
                        <Tooltip
                            title={intl.formatMessage({
                                id: "Dashboard.charts.marketRate.tooltips.beta.title",
                                defaultMessage: "Early Access Testing",
                            })}
                            body={
                                <FormattedMessage
                                    id="Dashboard.charts.marketRate.tooltips.beta.body"
                                    defaultMessage="This new chart is currently in beta testing with a small group of homeowners. As we fine-tune, you may see some unusual data conditions. If you have any questions or concerns, please reach out to a <a>Rates Specialist</a> for more information."
                                    values={{
                                        a: chunks => (
                                            <Link
                                                to={`/maintenance?action=create&category=${TicketCategoryId.Pricing}`}
                                                onClick={
                                                    trackBetaTooltipRatesSpecialistClicked
                                                }
                                            >
                                                {chunks}
                                            </Link>
                                        ),
                                    }}
                                />
                            }
                        >
                            <BetaLabel />
                        </Tooltip>
                    </span>
                </div>
                <div className={styles.row}>
                    <p
                        className={classNames(
                            styles.description,
                            "antialiased"
                        )}
                    >
                        <FormattedMessage
                            id="Dashboard.charts.marketRate.description"
                            defaultMessage="Hover over the chart to see how your home's nightly rate compares to other vacation rentals in your area with <strong>similar size and features.</strong>"
                            values={{
                                strong: chunks => <strong>{chunks}</strong>,
                            }}
                        />
                    </p>
                    <RangeSelector
                        selected={activeRange}
                        onRangeSelected={onRangeButtonClick}
                        v2
                    />
                </div>
                <ChartFeatureFeedback />
                <HighlightFilter />
                <div className="antialised">
                    <HighchartsReact
                        highcharts={HighchartsStock}
                        constructorType={"stockChart"}
                        options={options}
                        ref={chartRef}
                    />
                </div>
                <OptimizelyFeature feature="core-metrics">
                    {isEnabled => (
                        <div
                            className={classNames(
                                styles.footer,
                                {
                                    [getClassStyle(styles.coreMetricsFooter)]:
                                        isEnabled,
                                },
                                "antialised"
                            )}
                        >
                            {lastRefreshedOn && (
                                <LastRefreshedOn date={lastRefreshedOn} />
                            )}
                            <SubmitQuestion />
                        </div>
                    )}
                </OptimizelyFeature>
            </div>
            <ProductTour
                name="Market Rates Comparison"
                userPreferenceName={MARKET_RATES_PRODUCT_TOUR_VIEWED}
                slides={[
                    {
                        badge: {
                            icon: "Zap",
                            children: "New Product Features",
                        },
                        title: intl.formatMessage({
                            id: "Dashboard.charts.marketRate.productTour.slides.1.title",
                            defaultMessage: "Compare your home to the market",
                        }),
                        body: intl.formatMessage({
                            id: "Dashboard.charts.marketRate.productTour.slides.1.body",
                            defaultMessage:
                                "Refreshed daily, this tool lets you compare your home's nightly rate with similar homes in your area.",
                        }),
                        image: Slide1,
                    },
                    {
                        badge: {
                            icon: "Zap",
                            children: "New Product Features",
                        },
                        title: intl.formatMessage({
                            id: "Dashboard.charts.marketRate.productTour.slides.2.title",
                            defaultMessage: "Explore the latest market data",
                        }),
                        body: intl.formatMessage({
                            id: "Dashboard.charts.marketRate.productTour.slides.2.body",
                            defaultMessage:
                                "Browse current pricing for similar homes on sites like Airbnb and Vrbo - the same data we use to set your home's nightly rate.",
                        }),
                        image: Slide2,
                        largeImage: true,
                    },
                    {
                        badge: {
                            icon: "Zap",
                            children: "New Product Features",
                        },
                        title: intl.formatMessage({
                            id: "Dashboard.charts.marketRate.productTour.slides.3.title",
                            defaultMessage: "Make the most of your home",
                        }),
                        body: intl.formatMessage({
                            id: "Dashboard.charts.marketRate.productTour.slides.3.body",
                            defaultMessage:
                                "Track seasonal changes to plan how you want to use your home.",
                        }),
                        image: Slide3,
                        largeImage: true,
                    },
                ]}
            />
        </>
    )
}

const HighlightFilter = () => {
    return (
        <svg width={0} height={0}>
            <defs>
                <filter
                    id="reservations-shadow-filter"
                    x="-50%"
                    y="-100%"
                    width="200%"
                    height="300%"
                    filterUnits="objectBoundingBox"
                    primitiveUnits="userSpaceOnUse"
                    colorInterpolationFilters="linearRGB"
                >
                    <feDropShadow
                        dx="0"
                        dy="0"
                        stdDeviation="5"
                        floodColor="rgb(0, 51, 73)"
                        floodOpacity=".5"
                    />
                </filter>
            </defs>
        </svg>
    )
}
