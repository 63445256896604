const login = {
    homeownerLogin: "Přihlášení vlastníka",
    clickHere: "klikněte prosím sem",
    guestsClickHereToAccessDetails:
        "Hosté, {{clickHere}}, abyste získali bližší informace o své dovolené.",
    username: "Uživatelské jméno",
    password: "Heslo",
    forgotYourPassword: "Zapomněli jste heslo?",
    login: "Přihlásit se",
    employeeLogin: "Přihlášení zaměstnance",
    contractorLogin: "Přihlášení dodavatele",
}

export default login
