import { authAxios } from "api/authAxios"

export type PostUnitAllowPetsRequest = {
    contactId: string
    unitId: string
    payload: {
        ChangePetStatus: boolean
    }
}
export const postUnitAllowPets = (
    args: PostUnitAllowPetsRequest
): Promise<void> =>
    authAxios.post(
        `v1/legacy/owners/${args.contactId}/units/${args.unitId}/allow-pets`,
        args.payload
    )
