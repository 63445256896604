import { isEmpty } from "lodash"
import React, { useEffect, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { ReactComponent as CalendarIcon } from "../../../../assets/icon-calendar-blank.svg"
import { ReactComponent as ClearIcon } from "../../../../assets/icon-close-circle.svg"
import { ReactComponent as InfoIcon } from "../../../../assets/icon-info.svg"
import styles from "./DateInput.module.scss"
import { isMatch, parse } from "date-fns"

interface DateInputProps {
    name: string
    onChange: (s: Date | string | null) => void
    onClear: () => void
    showError: boolean
    value: string | null
    inputTestId?: string
}

const DateInput: React.FC<DateInputProps> = ({
    name,
    onChange,
    onClear,
    showError,
    value,
    inputTestId,
}: DateInputProps): JSX.Element => {
    const intl = useIntl()
    const [inputValue, setInputValue] = useState<string | null>(value)

    useEffect(() => {
        setInputValue(value)
    }, [value])

    /**
     * Sanitises the date input field.
     * @param rawInput Date input string
     * @returns String containing only numbers, and '/'.
     */
    function sanitiseInput(rawInput: string): string {
        const delKeyPressed = (inputValue || "")?.length > rawInput.length

        if (delKeyPressed) {
            return rawInput
        }

        const sanitisedInput = rawInput.replace(/[^0-9/-]/, "")
        const split = sanitisedInput.split("/")

        const monthPart = split.slice(0, 1)
        let month = monthPart.length ? monthPart[0] : ""

        const dayPart = split.slice(1, 2)
        let day = dayPart.length ? dayPart[0] : ""

        const yearPart = split.slice(2)
        let year = yearPart.length ? yearPart[0] : ""

        // User has manually entered a slash
        const trailingSlash = rawInput.slice(-1) === "/"
        const validPartIndex = [day, month].findIndex(
            s => !delKeyPressed && trailingSlash && s && s.length === 1
        )

        // Prepend day or month with zero when slash entered prematurely
        switch (validPartIndex) {
            case 0:
                day = "0" + day
                break
            case 1:
                month = "0" + month
        }

        // Move extra digit from month to day
        if (month && month.length > 2) {
            const carryOverDigit = month.slice(-1)
            day = carryOverDigit + day
            month = month.slice(0, -1)
        }

        // move extra digit from day to year
        if (day && day.length > 2) {
            const carryOverDigit = day.slice(-1)
            year = carryOverDigit + year
            day = day.slice(0, -1)
        }

        let formatted = month || ""
        if (day?.length) {
            formatted = formatted + "/" + day
        }
        if (year?.length) {
            formatted = formatted + "/" + year
        }

        if (trailingSlash && !delKeyPressed && (day?.length || month?.length)) {
            formatted += "/"
        }

        return formatted
    }

    const formatDate = (dateString: string): Date | string => {
        if (
            !isMatch(dateString, "MM/dd/yyyy") &&
            !isMatch(dateString, "M/d/yyyy")
        )
            return dateString
        const dateObj = parse(dateString, "MM/dd/yyyy", new Date())
        return dateObj
    }

    return (
        <div>
            <div className={styles.inputWrapper}>
                <input
                    className={showError ? styles.error : ""}
                    inputMode={"numeric"}
                    maxLength={10}
                    name={name}
                    placeholder={intl.formatMessage({
                        id: "CalendarPage.OwnerHolds.datesPlaceholder",
                        defaultMessage: "MM/DD/YYYY",
                    })}
                    type="text"
                    value={inputValue || ""}
                    onBlur={() => {
                        onChange(inputValue ? formatDate(inputValue) : null)
                    }}
                    onChange={e => {
                        setInputValue(sanitiseInput(e.currentTarget.value))
                    }}
                    onKeyDown={e => {
                        if (e.key === "Enter")
                            onChange(inputValue ? formatDate(inputValue) : null)
                    }}
                    data-hj-allow
                    data-testid={inputTestId ?? "date-input"}
                />
                <CalendarIcon className={styles.calendarIcon} />
                {!isEmpty(inputValue) && (
                    <ClearIcon
                        className={styles.clearIcon}
                        onClick={e => {
                            e.stopPropagation()
                            setInputValue(null)
                            onClear()
                        }}
                    />
                )}
            </div>
            {showError && (
                <div
                    data-testid="calendar-date-error"
                    className={styles.errorWrapper}
                >
                    <InfoIcon />
                    <p>
                        <FormattedMessage
                            id="CalendarPage.errorMessages.invalidDateError"
                            defaultMessage="This date is unavailable"
                            description="Error message for invalid date"
                        />
                    </p>
                </div>
            )}
        </div>
    )
}
export { DateInput }
