const survey = {
    title: "TARIFAS Y INGRESOS",
    subTitle: "Establecer estrategia de ingresos",
    surveyFollowUp: "Paso {step} de {totalSteps}",
    errorTitle: "Encuesta no disponible",
    loadingErrorMessage: "Lo sentimos, no se pudo cargar la encuesta. Por favor, inténtalo de nuevo más tarde.",
    submitErrorMessage: "Lo sentimos, no pudimos enviar tu encuesta en este momento. Por favor, inténtalo de nuevo más tarde.",
    tanksTitle: "¡Felicidades por establecer tu estrategia de ingresos!",
    tanksMessage: "Nuestro equipo está trabajando para implementar tus solicitudes."
}

export default survey
