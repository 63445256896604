import { WebChatStatus } from "@vacasa/owner-api-models"
import { authAxios } from "../../api/authAxios"

type FetchWebChatStatusParams = {
    contactId: string
    unitId?: string
}
export const fetchWebChatStatus = async (
    params: FetchWebChatStatusParams
): Promise<WebChatStatus> => {
    const response = await authAxios.get(`v1/web-chat/status`, {
        params,
    })
    return response.data.data.attributes
}
