const unitSelector = {
    allRentals: "Toutes les locations",
    search: "chercher",
    searchRentals: "Chercher dans vos locations",
    allActive: "Toutes les locations actives",
    rentals: "locations",
    inactive: "Inactive",
    inactiveRentals: "Locations inactives",
    unitSelectorAria:
        "Utiliser l'outil de sélection des locations pour modifier l'aperçu en cours.",
}

export default unitSelector
