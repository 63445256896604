import { UserPreference } from "@vacasa/owner-api-models"
import { fromUnixTime, isAfter, isWithinInterval, startOfDay } from "date-fns"

export const get1099Window = (): Interval => {
    const now = startOfDay(new Date())
    const year = now.getFullYear()
    // if after the 10th of Jan the current year use the next window
    if (isAfter(now, new Date(`${year}-01-10`))) {
        return {
            start: new Date(`${year}-12-01`),
            end: new Date(`${year + 1}-01-10`),
        }
    }

    return {
        start: new Date(`${year - 1}-12-01`),
        end: new Date(`${year}-01-10`),
    }
}

export const isBetweenWindow = () => {
    const now = startOfDay(new Date()) // to make it inclusive of the start and end dates
    return isWithinInterval(now, get1099Window())
}

export const hasDismissed1099ModalThisPeriod = (
    preference?: UserPreference
) => {
    if (!preference || !preference.value || !preference.updated) return false
    const lastUpdated = fromUnixTime(preference.updated)
    const interval = get1099Window()
    return isWithinInterval(lastUpdated, interval)
}
