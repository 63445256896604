import { Tooltip } from "lib/components/Tooltip/Tooltip"
import React from "react"
import { Info } from "react-feather"
import styles from "./PotentialImpact.module.scss"
import classNames from "classnames"
import { FormattedMessage, useIntl } from "react-intl"
import { TrendingDown, TrendingUp } from "assets/icons"
import { getClassStyle } from "utils/styles/styleWrapper"
import { RevenueImpactData } from "@vacasa/owner-api-models"

export interface PotentialImpactProps {
    isLoading?: boolean
    impact?: RevenueImpactData
    variant?: string
}

export const PotentialImpact: React.FC<PotentialImpactProps> = ({
    isLoading,
    impact,
    variant = "dollar-amount",
}) => {
    const intl = useIntl()

    const renderTooltip = () => {
        return (
            <div className={styles.tooltipContainer}>
                <Tooltip
                    title={intl.formatMessage({
                        id: "Dashboard.rateCalculator.revenueImpact.tooltip.title",
                        defaultMessage: "Potential Impact",
                    })}
                    body={intl.formatMessage({
                        id: "Dashboard.rateCalculator.revenueImpact.tooltip.body",
                        defaultMessage:
                            "The estimated change to your home’s potential rent revenue over the next 12 months, if the analyzed minimum rate is applied.",
                    })}
                >
                    <Info
                        className={styles.tooltipTrigger}
                        width={16}
                        height={16}
                    />
                </Tooltip>
            </div>
        )
    }

    const renderImpact = () => {
        if (!impact) {
            return "n/a"
        }

        const { adjustedImpact, annualPotentialRevenue } = impact

        if (variant === "percent-impact") {
            const percentage = (adjustedImpact / annualPotentialRevenue) * 100

            return `${Math.round(percentage)}%`
        }

        const roundTo = (nearest: number) => (value: number) => {
            return Math.round(value / nearest) * nearest
        }

        const roundedDollarImpact = roundTo(adjustedImpact > 100 ? 100 : 5)(
            adjustedImpact
        )

        return intl.formatNumber(roundedDollarImpact, {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        })
    }

    const TrendingIcon =
        impact === undefined
            ? undefined
            : impact.adjustedImpact > 0
            ? TrendingUp
            : impact.adjustedImpact < 0
            ? TrendingDown
            : undefined

    return (
        <div className={styles.potentialImpact}>
            <div
                className={classNames(
                    "type-heading-extra-small-caps",
                    styles.header
                )}
            >
                <FormattedMessage
                    id="Dashboard.rateCalculator.revenueImpact.title"
                    defaultMessage="Potential Impact"
                />
                {renderTooltip()}
            </div>
            {isLoading && (
                <div
                    className={classNames("shimmer", styles.impactSkeletion)}
                />
            )}
            {!isLoading && (
                <div
                    className={classNames(
                        "type-heading-medium",
                        styles.impact,
                        {
                            [getClassStyle(styles.trendingDown)]:
                                impact && impact.adjustedImpact < 0,
                            [getClassStyle(styles.trendingUp)]:
                                impact && impact.adjustedImpact > 0,
                        }
                    )}
                >
                    <span>{renderImpact()}</span>
                    {TrendingIcon && (
                        <TrendingIcon className={styles.trendingIcon} />
                    )}
                </div>
            )}
        </div>
    )
}
