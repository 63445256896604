const settings = {
    title: "Payment Settings",
    reserveBalance: "Saldo di riserva",
    paymentSettings: "Impostazioni pagamento",
    directDepositTitle: "Bank Account",
    checksByMailTitle: "Paper Checks",
    checksByMailEnrolled: "You are enrolled in paper checks.",
    checksByMailNotEnrolled: "You are not enrolled in paper checks.",
    paymentMethod: "Payment Method",
    paymentMethodText:
        "Payment delivery depends on the owner's selected payment method.",
    directDepositFineprint:
        "Deposits and withdrawals usually appear in the account 2-5 business days after the monthly statement.",
    checksByMailFineprint:
        "Paper checks usually take 5-10 business days to arrive by mail. Please confirm your mailing address in your {ProfileLink}.",
    reserveBalanceEmptyState:
        "Qui puoi visualizzare e aggiornare il tuo saldo di riserva. ",
    reserveBalanceText:
        "If your expenses exceed your revenue in a given month, your Reserve Account will be used to cover the difference. If your reserve account balance is below {balance}, we'll automatically replenish it from revenue you earn.",
    reserveUpdateAria:
        "Clicca su questo pulsante per aggiornare il tuo saldo di riserva.",
    reserveUpdatedNotification: "Saldo di riserva aggiornato",
    reserveMinimumRequired: "This unit requires at least {amount} reserve",
    directDepositEnrolled: "You are enrolled in direct deposit.",
    achDirectDepositEnrolled:
        "You are enrolled in ACH direct deposit and ACH expense debit.",
    directDepositNotEnrolled: "Non sei iscritto per l'accredito diretto.",
    checkingAccount: "Conto corrente",
    savingsAccount: "Libretto di risparmio",
    updatePayment: "Aggiorna metodo di pagamento",
    updateAction: "Aggiorna",
    updatedNotification: "Informazioni sul pagamento aggiornate",
    updatePaymentSub: "Conto bancario per l'accredito diretto",
    receiveByMailSub: "Enroll in Checks",
    receiveByMailText:
        "If selected, your checks will be mailed to your address on file.",
    directDepositRemoved: "Rimosso dall'accredito diretto",

    addBankAccount: "Aggiungi conto bancario",
    checking: "Conto",
    savings: "Risparmi",
    routingNumber: "Numero di conto",
    nineDigitNumber: "Il codice di 9 cifre in basso a sinistra su un assegno",
    mustBeginWith: "Il numero di conto deve cominciare con 0, 1, 2, o 3",
    mustBe9Digits: "Ti preghiamo di inserire un numero di conto a 9 cifre",
    mustBeValid9Digits: "Please enter a valid 9-digit routing number",
    accountNumber: "Numero di conto",
    accountHolderName: "Nome dell'intestatario del conto",
    enterBankAccount: "Please enter your bank account number",
    mustBe5to17Digits: "Please enter a 5 to 17-digit account number",
    byClickingYouAuthorize:
        "Because you chose to pay for expenses via ACH Debit, this account will also be charged if your expenses exceed your rental revenue for the month. By clicking 'Agree and Save', you authorize {accountingEntity} to make payments electronically via direct deposit and to debit the identified account for up to $10,000 per transaction per day. This authority will remain in effect until you change or update your payment method.",
    cancel: "cancella",
    agreeAndSave: "Accetta e salva",
    savePaymentMethod: "Salva metodo di pagamento",
    clickToSaveAria:
        "Clicca su questo pulsante per salvare il tuo metodo di pagamento. ",
    clickToCancelAria: "Clicca su questo pulsante per cancellare.",
    clickToRequestChecksAria:
        "Clicca su questo pulsante per richiedere gli assegni per posta.",
    requestChecksByMail: "Richiedi assegni per posta",
    getYourPaymentsFaster:
        "Ottieni i tuoi pagamenti più velocemente richiedendo l'accredito diretto.",
    errorMessages: {
        updateReserveBalanceError: "Il saldo di riserva è troppo elevato.",
        updateReserveBalanceNegativeError:
            "Il saldo di riserva non può essere negativo.",
        updateReserveBalanceMissingError:
            "È necessario l'importo del saldo di riserva.",
        sendDirectDepositEmailError:
            "Non è stato possibile inviare l'email per cancellare l'iscrizione all'accredito diretto.",
        paymentAccountGeneralError:
            "Si è verificato un errore nell'aggiornamento delle informazioni sull'account, ti preghiamo di riprovare più tardi.",
        paymentAccountNameError:
            "È necessario inserire il nome dell'intestatario del conto.",
        paymentAccountNameLengthError:
            "Il nome dell'intestatario del conto supera i 100 caratteri.",
        paymentAccountRoutingNumError:
            "Le coordinate bancarie non sono valide.",
        paymentAccountNumError: "Il numero di conto non è valido.",
        paymentAccountTypeError: "Il tipo di conto non è valido.",
        sendACHUpdateEmailError:
            "Non è stato possibile inviare l'email per l'aggiornamento dell'ACH.",
        somethingWentWrong:
            "Si è verificato un errore, ti preghiamo di riprovare.",
    },
    emptyState:
        "This is where you can update your reserve balance and payment method. Be sure to set up direct deposit before your first statement posts in order to get your payments faster.",
    linkToStatements: "Statements",
}

export default settings
