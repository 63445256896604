import { Unit } from "@vacasa/owner-api-models/src/legacy/Earnings"
import {
    displayFormattedNumber,
    formatDate,
    isCanadaSalesTaxExpense,
} from "utils/statements/statementsUtil"
import { FormattedMessage } from "react-intl"
import styles from "../statements.module.scss"

const displayCanadaSalesTaxes = (currency: string, unit: Unit) => {
    if (!unit.expenses || unit.expenses.length === 0) {
        return null
    }
    return unit.expenses.map(expense => {
        if (!isCanadaSalesTaxExpense(expense)) {
            return null
        }
        const postDate = formatDate(expense.posting_date)
        return (
            <tr key={expense.id}>
                <td className="gutter" />
                <td className="date">{postDate}</td>
                <td colSpan={4} className="desc-span">
                    {expense.description}
                </td>
                <td className="text-right amount">
                    {displayFormattedNumber(Number(expense.debit), currency)}
                </td>
                <td className={styles["gutter-end"]} />
            </tr>
        )
    })
}

interface UnitDetailsCanadaSalesTaxesProps {
    currency: string
    unit: Unit
}

const UnitDetailsCanadaSalesTaxes: React.FC<
    UnitDetailsCanadaSalesTaxesProps
> = props => {
    const { currency, unit } = props
    return (
        <>
            <div className="table-responsive">
                <table className="open table">
                    <thead className={styles["uppercase-header"]}>
                        <tr>
                            <td className={styles["inset"]} />
                            <td colSpan={5}>
                                <FormattedMessage
                                    id="Statements.canadaSalesTaxesHeader"
                                    defaultMessage="Taxes - Expenses & Management Fees"
                                />
                            </td>
                            <td colSpan={2} className="text-right">
                                {displayFormattedNumber(
                                    Number(unit.total_canada_sales_tax),
                                    currency
                                )}
                            </td>
                            <td className={styles["inset"]} />
                        </tr>
                    </thead>
                </table>
            </div>
            <div className="table-responsive">
                <table className="open table">
                    <tbody
                        className={
                            parseInt(unit.total_canada_sales_tax) !== 0
                                ? ""
                                : styles["hide"]
                        }
                    >
                        {displayCanadaSalesTaxes(currency, unit)}
                    </tbody>
                    <tfoot
                        className={
                            parseInt(unit.total_canada_sales_tax) !== 0
                                ? ""
                                : styles["hide"]
                        }
                    >
                        <tr>
                            <td className="gutter" />
                            <td colSpan={5} className="total-span">
                                <strong>
                                    <FormattedMessage
                                        id="Statements.total"
                                        defaultMessage="Total"
                                    />
                                </strong>
                            </td>
                            <td className="text-right">
                                <strong>
                                    {displayFormattedNumber(
                                        Number(unit.total_canada_sales_tax),
                                        currency
                                    )}
                                </strong>
                            </td>
                            <td className={styles["gutter-end"]} />
                        </tr>
                    </tfoot>
                </table>
            </div>
        </>
    )
}

export default UnitDetailsCanadaSalesTaxes
