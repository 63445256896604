import { useIntl } from "react-intl"
import * as Button from "lib/components/Buttons/BaseButton"
import classnames from "classnames"
import styles from "./ReservationGuestForm.module.scss"
import {
    GuestInfo,
    generateGuestDisplay,
} from "../ReservationForm/ReservationSummary"
import { ReactComponent as PlusCircleSVG } from "../../../../assets/icon-plus-circle.svg"
import StaticList from "lib/components/FlyOut/Holds/StaticList"
import { OccupancyInfo } from "./ReservationGuestForm"

interface AddGuestSummaryProps {
    guests: GuestInfo[]
    occupancyInfo?: OccupancyInfo
    handleShowSkipButton?: (shouldShowSkipButton: boolean) => void
    handleHideForm: (shouldHideForm: boolean) => void
    handleSetNewForm: () => void
    handleEditGuest: (guest: GuestInfo) => void
}

const AddGuestSummary: React.FC<AddGuestSummaryProps> = ({
    guests,
    occupancyInfo,
    handleShowSkipButton,
    handleHideForm,
    handleSetNewForm,
    handleEditGuest,
}: AddGuestSummaryProps): JSX.Element => {
    const intl = useIntl()

    const onInviteGuestClick = () => {
        handleSetNewForm()
        handleHideForm(false)
        if (handleShowSkipButton) {
            handleShowSkipButton(false)
        }
    }

    const guestDisplay = generateGuestDisplay({
        adults: occupancyInfo?.adults ?? 0,
        children: occupancyInfo?.children ?? 0,
        pets: occupancyInfo?.pets ?? 0,
        guests,
        intl,
        editButton: false,
        onEditClick: handleEditGuest,
    })

    return (
        <div>
            {guests.length > 0 && <StaticList items={[guestDisplay]} />}
            <div
                className={classnames(
                    guests.length > 0
                        ? styles.inviteGuestsButtonWithGuests
                        : "",
                    styles.inviteGuestsButtonContainer
                )}
            >
                <Button.Utility
                    className={classnames(
                        styles.inviteGuestsButton,
                        "flex-horizontal-right display-inline button-box",
                        guests.length > 0 ? styles.buttonUnderSummary : ""
                    )}
                    onClick={onInviteGuestClick}
                    dataTestId="invite-guests-button"
                >
                    <p className="zero-margin zero-padding text type-body-tiny">
                        <PlusCircleSVG />
                        {intl.formatMessage({
                            id: "CalendarPage.flyout.inviteGuests",
                            defaultMessage: "Invite Guests",
                        })}
                    </p>
                </Button.Utility>
            </div>
        </div>
    )
}

export default AddGuestSummary
