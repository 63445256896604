import { authAxios } from "../../api/authAxios"

export type PostTicketCommentRequest = {
    contactId: string
    unitId: string
    postTicketCommentData: TicketCommentRequest
}

export interface TicketCommentRequest {
    ticketId: number
    note: string
}

const postTicketComment = ({
    contactId,
    unitId,
    postTicketCommentData,
}: PostTicketCommentRequest): Promise<number> => {
    const ticketCommentData = {
        data: {
            type: "ticket-comment",
            attributes: postTicketCommentData,
        },
    }

    return authAxios
        .post<number>(`/v1/ticket-comment`, ticketCommentData, {
            params: {
                contactId,
                unitId,
            },
        })
        .then(response => response.status)
}

export { postTicketComment }
