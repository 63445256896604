import { useEffect, useState } from "react"
import { useReviews } from "../../../../hooks/review"
import style from "./GuestStarReview.module.scss"
import { StarRating } from "lib/components/StarRating/StarRating"
import { useContactId } from "hooks/user"
import classNames from "classnames"

interface Props {
    reservationId: number
}

const GuestStarReview: React.FC<Props> = ({ reservationId }) => {
    const contactId = useContactId()
    const [overallRating, setOverallRating] = useState<number>()
    const useReviewQuery = useReviews(reservationId, contactId || "")

    useEffect(() => {
        if (useReviewQuery.data && useReviewQuery.data.length) {
            // first review
            setOverallRating(useReviewQuery.data[0]?.attributes.overall)
        }
    }, [useReviewQuery])

    return overallRating !== undefined ? (
        <div className={classNames(style.GuestStarReview, "guest-star-review")}>
            <StarRating value={overallRating} />
        </div>
    ) : null
}

export { GuestStarReview }
