import { AxiosError } from "axios"
import { useMutation, UseMutationResult } from "react-query"
import { PutPaymentAccountResponse } from "./putPaymentAccount"
import {
    putUnitReserveBalance,
    PutUnitReserveBalanceRequest,
    PutUnitReserveBalanceResponse,
} from "./putUnitReserveBalance"

export const useUnitReserveBalanceMutation = (): UseMutationResult<
    PutUnitReserveBalanceResponse,
    AxiosError<{
        errors?: {
            code?: number
        }
    }>,
    PutUnitReserveBalanceRequest,
    void
> => {
    return useMutation<
        PutPaymentAccountResponse,
        AxiosError<{
            errors?: {
                code: number
            }
        }>,
        PutUnitReserveBalanceRequest,
        void
    >(payload => putUnitReserveBalance(payload))
}
