import { action, makeObservable, observable } from "mobx"

export class CalendarService {
    @observable startDate: Date | null = null
    @observable endDate: Date | null = null
    @observable showGrid = false
    @observable selectedYear: string | null = null
    @observable displayMonth: string | null = null
    @observable selectedStartDay: string | null = null
    @observable selectedEndDay: string | null = null

    constructor() {
        makeObservable(this)
        this.init()
    }

    /*
        This function is used to initialize observable properties
        DO NOT initialize class properties outside of this function
    */
    @action init = async () => {
        this.startDate = null
        this.endDate = null
        this.showGrid = false
        this.selectedYear = null
        this.displayMonth = null
        this.selectedStartDay = null
        this.selectedEndDay = null
    }
    @action setDateRange = (startDate: Date | null, endDate: Date | null) => {
        this.startDate = startDate
        this.endDate = endDate
    }

    @action setDisplayMonth = (displayMonth: string) => {
        this.displayMonth = displayMonth
    }

    @action setSelectedStartDay = (selectedStartDay: string) => {
        this.selectedStartDay = selectedStartDay
    }

    @action setSelectedEndDay = (selectedEndDay: string) => {
        this.selectedEndDay = selectedEndDay
    }

    @action setSelectedYear = (selectedYear: string) => {
        this.selectedYear = selectedYear
    }

    @action clearDates = () => {
        this.startDate = null
        this.endDate = null
    }
}

const calendarService = new CalendarService()

export default calendarService
