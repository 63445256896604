const taxes = {
    title: "Steuern",

    fillOutW9: "Bitte fülle das Formular W-9 aus",
    submitW9: "W-9 online abgeben",
    newTaxFormsWillShow:
        "Neue Steuerformulare werden hier angezeigt, sobald sie zur Verfügung stehen.",
    goPaperless: "Papierlose Option",
    paperlessExplanation:
        "Eine papierlose Bearbeitung ermöglicht den Erhalt der Steuerformulare ausschließlich auf elektronischem Weg. Du erhältst dann keine ausgedruckten Papierformulare per Post.",
    electronicForms:
        "Steuerformulare ausschließlich auf elektronischem Weg erhalten",
    getForms: "Formulare für die Steuererklärung",
    irsFormsElectronically:
        "Erhalte die IRS-Steuerformulare auf elektronischem Weg",
    mustSubmit:
        "We must have one of the following taxpayer forms on file before we can provide your tax preparation documents. Please complete only the form that best describes your taxpayer status with the IRS. If your taxpayer information has changed, please submit a new form.",
    paperlessNotification: "Steuerformulare werden elektronisch versandt",
    paperFormNotification: "Steuerformulare werden per Post versandt",

    noForms: "Du hast noch keine Steuerformulare.",
    ifExpecting: "Falls du auf ein",
    willBeAvailable: "wartest, erhältst du es am",
    form1099title: "Formular 1099-MISC",
    form1042title: "Formular 1042-S",
    jan31: "31. Januar",
    march15: "15. März",

    yourInformation: "Provide your taxpayer information",
    whoFor: "Wer muss das Formular ausfüllen",
    howToSubmit: "So übermittelst du das Formular",
    w9title: "Formular W-9",
    w9whoFor:
        "Bitte fülle dieses Formular aus, wenn du ein US-Staatsbürger oder ein in den USA ansässiger Ausländer („resident alien“) mit einer der folgenden Steuerzahler-ID-Nummern bist: SSN, EIN, oder ITIN. Nicht in den USA ansässige Ausländer dürfen dieses Formular nicht ausfüllen.",
    w8ECItitle: "Formular W-8ECI",
    w8ECIwhoFor:
        "Dieses Formular muss von Ausländern, nicht-ansässigen Ausländern oder Personen mit SSN, EIN oder ITIN ausgefüllt werden, die dieses Einkommen bei der IRS im Rahmen einer jährlichen Steuererklärung melden.",
    w8BENtitle: "Formular W-8BEN",
    w8BENwhoFor:
        "Fülle dieses Formular aus, wenn du eine ausländische Person oder nicht-ansässiger Ausländer bist, der keine Steuererklärung bei der IRS einreicht, oder Vorteile von Einkommenssteuerabkommen zwischen den USA und deinem Wohnsitzland geltend machen möchte. Da Vorteile von Steuerabkommen nicht für Mieteinnahmen anwendbar sind, gelten für Personen, die das Formular W-8BEN-E einreichen, ausländische Quellensteuern, die vom Brutto-Einkokmmen zu einem Satz von 30 % abgezogen werden.",
    w8BENEtitle: "Formular W-8BEN-E",
    w8BENEwhoFor:
        "Fülle dieses Formular aus, wenn du ein ausländischer Empfänger von Einnahmen und keine natürliche Person bist, und keine Steuererklärung bei der IRS einreichst. Vorteile von Einkommenssteuerabkommen können im Teil III dieses Formulars geltend gemacht werden. Da Vorteile von Steuerabkommen nicht für Mieteinnahmen anwendbar sind, unterliegen Empfänger, die das Formular W-8BEN-E einreichen, ausländischen Quellensteuern, die vom Brutto-Einkommen zu einem Satz von 30 % abgezogen werden.",

    learnMore: "Weitere Informationen unter",
    mailForm: "Fülle das Formular aus und schicke es an:",

    dwnld: "Herunterladen",
    download: "Lade deine Mietdaten herunter",
    preparingNotification: "Daten werden zum Download vorbereitet",
    rentalData: "Mietdaten",
    noDownloads: "Du hast noch keine Downloads.",
    description: "Die Daten für jedes Steuerjahr sind zum",
    january15: "15. Januar",
    description2:
        "des folgenden Jahres erhältlich. Wenn du einen oder mehrere Miteigentümer hast, stellen wir für alle Personen die jeweiligen Daten zum Download bereit, damit jeder die korrekten Angaben für die Steuererklärung vorliegen hat.",
    instructions:
        "Dateien mit der Endung .xls können mit allen gängigen Tabellenprgrammen geöffnet werden, wie beispielsweise Excel, Numbers und Google Sheets.",
    makeChanges: "Want to make changes?",
    w9Support:
        "To make edits to your W-9 details please reach out to the {{HOME_OWNER_SUPPORT}} at {{SUPPORT_NUMBER}}, {{SUPPORT_EMAIL}}",
    taxModal: {
        actionRequired: "Action Required!",
        title: "Enter Taxpayer Info",
        description:
            "We need your taxpayer information on file so we can provide you with accurate tax preparation documents for last year.",
        primaryButton: "Fill it Out",
        secondardButton: "Maybe Later",
    },
    paperless1099Modal: {
        title: "Paperless 1099",
        description:
            "As the new year approaches, be prepared for tax season by receiving your Vacasa 1099 digitally. We'll email you when it's ready.",
        goPaperless: "Go Paperless",
        skipForNow: "Skip for Now",
    },
    w9: {
        taxFormSelect: {
            info1: "Please provide your taxpayer information so we can provide your tax preparation documents. Provide information that best describes your taxpayer status with the IRS.",
            info2: "The name/entity that you provide should be the same name/entity that is listed on your contract with Vacasa.",
        },
        taxClassifications: {
            INP: "Individual/sole proprietor or single-member LLC",
            LLC: "Limited liability company",
            CCO: "C Corporation",
            SCO: "S Corporation",
            PRT: "Partnership",
            TRE: "Trust/estate",
            OTH: "Other",
        },
        llcTaxClassfications: {
            C: "C Corporation",
            S: "S Corporation",
            P: "Partnership",
        },
        taxIdTypes: {
            SSN: "SSN (Social Security Number)",
            EIN: "EIN (Employer Identification Number)",
        },
        forms: {
            agreeAndSign: {
                title: "Agree & Submit",
                termsOfAgreement: "Terms Of Agreement",
                terms: "Under penalties of perjury, I certify that:\n\n1. The number shown on this form is my correct taxpayer identification number (or I am waiting for a number to be issued to me); and \n\n2. I am not subject to backup withholding because: (a) I am exempt from backup withholding, or (b) I have not been notified by the Internal Revenue Service (IRS) that I am subject to backup withholding as a result of a failure to report all interest or dividends, or (c) the IRS has notified me that I am no longer subject to backup withholding; and \n\n3. I am a U.S. citizen or other U.S. person (defined below); and \n\n4. The FATCA code(s) entered on this form (if any) indicating that I am exempt from FATCA reporting is correct. Certification instructions. You must cross out item 2 above if you have been notified by the IRS that you are currently subject to backup withholding because you have failed to report all interest and dividends on your tax return. For real estate transactions, item 2 does not apply. For mortgage interest paid, acquisition or abandonment of secured property, cancellation of debt, contributions to an individual retirement arrangement (IRA), and generally, payments other than interest and dividends, you are not required to sign the certification, but you must provide your correct TIN. See the instructions for Part II, later.",
                iAgree: "I agree to these terms",
                signature: "Signature",
                signaturePlaceholder: "Type fullname",
                signatureHelperText:
                    "By signing this document with an electronic signature, I agree that such signature will be as valid as handwritten signatures to the extent allowed by local law.",
                signedOn: "Signed On",
                validation: {
                    agreeToTermsRequired:
                        "Please agree to our terms of agreement",
                    signatureRequired: "Please enter your signature",
                    signatureMaxLength: "Signature cannot exceed 50 letters",
                },
            },
            taxInfo: {
                validation: {
                    entityTypeRequired: "Entity type is required",
                    entityTypeEmpty: "Entity type must be empty",
                    entityNameRequired: "Entity name is required",
                    taxIDTypeRequired: "Tax ID type is required",
                    taxIDTypeValid: "Tax ID type must be either SSN or EIN",
                    taxIDTypeEIN: "Tax ID type must be EIN",
                    taxClassificationRequired:
                        "Please select a tax classification",
                    taxClassificationValid: "Tax classification invalid",
                    ssnRequired: "SSN is required",
                    ssnLength: "SSN must be 9 digit in XXX-XX-XXXX format",
                    ssnInvalid: "SSN must be 9 digit in XXX-XX-XXXX format",
                    ssnAllZero: "SSN cannot be all 0",
                    ssnEmpty: "SSN must be empty",
                    einRequired: "EIN is required",
                    einLength: "EIN must be 9 digit in XX-XXXXXXX format",
                    einInvalid: "EIN must be 9 digit in XX-XXXXXXX format",
                    einAllZero: "EIN cannot be all 0",
                    einEmpty: "EIN must be empty",
                    llcTypeRequired: "LLC type required",
                    llcTypeValid: "LLC type must be either C, S, or P",
                    llcTypeEmpty: "LLC type must be empty",
                },
            },
            mailingAddress: {
                label: {
                    address: "Address",
                    city: "City",
                    stateOrProvince: "State/Province",
                    zipCode: "Zip/Postal Code",
                },
                placeholder: {
                    addressLine1: "Address Line 1",
                    addressLine2: "Address Line 2",
                    city: "City",
                    stateProvince: "State/Province",
                    zipCode: "5-digit zipcode",
                },
                validation: {
                    addressRequired: "Address is required",
                    cityRequired: "Please enter city",
                    postalCodeRequired: "Postal code is required",
                    provinceRequired: "State/Province is required",
                    postalCodeMaxLength: "Postal code must be 5 digit length",
                    postalCodeAllZero: "Postal code cannot be all 0",
                },
            },
        },
    },
}

export default taxes
