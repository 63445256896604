const login = {
    homeownerLogin: "Login del proprietario",
    clickHere: "clicca qui",
    guestsClickHereToAccessDetails:
        "Ospiti, per favore {{clickHere}} per accedere ai tuoi dettagli del viaggio.",
    username: "Username",
    password: "Password",
    forgotYourPassword: "Password dimenticata?",
    login: "Login",
    employeeLogin: "Login del dipendente",
    contractorLogin: "Login dell'appaltatore",
}

export default login
