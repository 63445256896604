import { REDIRECT_ONBOARDING } from "Constants"
import { useOnboardingStatus } from "hooks/onboarding/use-onboarding-status"
import Loader from "lib/components/Loader"
import { FC, PropsWithChildren, useEffect, useState } from "react"
import storageService from "services/storage.service"
import { getOnboardingRedirectConfig } from "utils/onboarding"
import styles from "./redirect-to-onboarding.module.scss"

interface RedirectToOnboardingProps {
    redirectRoute?: boolean
    forward?: boolean
}

export const RedirectToOnboarding: FC<
    PropsWithChildren<RedirectToOnboardingProps>
> = ({ children, redirectRoute = true, forward = false }) => {
    const onboarding = useOnboardingStatus()
    const [shouldRedirect, setShouldRedirect] = useState(false)

    useEffect(() => {
        if (!redirectRoute && !forward) {
            storageService.sessionStorage.setItem(
                REDIRECT_ONBOARDING,
                JSON.stringify({
                    ...getOnboardingRedirectConfig(),
                    redirectOnboarding: false,
                })
            )
        }
    }, [forward, redirectRoute])

    useEffect(() => {
        if (onboarding.isLoading || !onboarding.hasUnitsOnboarding) return

        if (
            onboarding.redirectToOnboarding &&
            redirectRoute &&
            !shouldRedirect
        ) {
            setShouldRedirect(true)
        }
    }, [
        onboarding.hasUnitsOnboarding,
        onboarding.isLoading,
        onboarding.redirectToOnboarding,
        redirectRoute,
        shouldRedirect,
    ])

    useEffect(() => {
        if (onboarding.isLoading || !onboarding.hasUnitsOnboarding) return

        if (shouldRedirect && redirectRoute) {
            window.location.replace("/onboarding")
        }
    }, [
        onboarding.hasUnitsOnboarding,
        onboarding.isLoading,
        redirectRoute,
        shouldRedirect,
    ])

    if (onboarding.isLoading || (shouldRedirect && redirectRoute))
        return (
            <div id="onboarding-loader" className={styles.loader}>
                <Loader />
            </div>
        )

    return <>{children}</>
}
