import { LockCode } from "@vacasa/owner-api-models"
import { authAxios } from "../../api/authAxios"

type FetchLockCodeRequest = {
    reservationId: string
    contactId: string
    signal?: AbortSignal
}

const fetchLockcode = ({
    reservationId,
    contactId,
    signal,
}: FetchLockCodeRequest): Promise<LockCode> =>
    authAxios
        .get<LockCode>(
            `v1/legacy/owners/reservations/${reservationId}/lock-code`,
            {
                params: {
                    contactId: contactId,
                },
                signal,
            }
        )
        .then(response => response.data)
        .catch(error => error.message)

export { fetchLockcode }
