import { JSONAPIResource, Housekeeping } from "@vacasa/owner-api-models"
import { authAxios } from "../../api/authAxios"

type FetchHousekeepingRequest = {
    params: {
        contactId: string
        unitId: string
    }
    signal?: AbortSignal
}

const fetchHousekeeping = ({
    params,
    signal,
}: FetchHousekeepingRequest): Promise<JSONAPIResource<Housekeeping>> =>
    authAxios
        .get<{ data: JSONAPIResource<Housekeeping> }>(`/v2/housekeeping`, {
            params,
            signal,
        })
        .then(response => response.data.data)

export { fetchHousekeeping }
