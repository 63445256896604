import { styled, Switch, SwitchProps } from "@material-ui/core"
import classnames from "classnames"
import { FC } from "react"
import { getClassStyle } from "utils/styles/styleWrapper"
import styles from "./OnOffSwitch.module.scss"

const BaseSwitch = styled((props: SwitchProps) => (
    <Switch
        focusVisibleClassName=".Mui-focusVisible"
        disableRipple
        {...props}
    />
))(({ theme }) => ({
    width: 94,
    height: 32,
    padding: 0,
    borderRadius: 100,
    boxShadow: "1px 1px 10px rgba(0, 0, 0, 0.15)",
    "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 0,
        transitionDuration: "300ms",
        transform: "translateX(41px)",
        "&.Mui-checked": {
            color: "var(--white)",
            transform: "translateX(0)",
            "& + .MuiSwitch-track": {
                backgroundColor: "var(--midnight-4)",
                opacity: 1,
                border: 0,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 1,
            },
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.7,
            },
        },
        "& .MuiSwitch-thumb": {
            boxSizing: "border-box",
            width: 53,
            height: 32,
            borderRadius: 100,
            backgroundColor: "var(--midnight)",
        },
        "&.MuiSwitch-track": {
            backgroundColor: "var(--midnight-4)",
            opacity: 1,
            transition: theme.transitions.create(["background-color"], {
                duration: 500,
            }),
        },
    },
    "& .MuiSwitch-track": {
        backgroundColor: "var(--midnight-4)",
    },
}))

interface Props {
    disabled?: boolean
    checked?: boolean
    onChange: (checked: boolean) => void
}

const OnOffSwitch: FC<Props> = ({ checked, disabled, onChange }) => {
    return (
        <div className={styles["on-off-switch"]}>
            <BaseSwitch
                onChange={(_, checked) => onChange(checked)}
                checked={checked}
                disabled={disabled}
            />
            <span
                className={classnames(
                    "text-xxs",
                    "uppercase",
                    "font-extrabold",
                    styles["label"],
                    styles["label--yes"],
                    {
                        [getClassStyle(styles["label--active"])]: checked,
                    }
                )}
            >
                Yes
            </span>
            <span
                className={classnames(
                    "text-xxs",
                    "uppercase",
                    "font-extrabold",
                    styles["label"],
                    styles["label--no"],
                    {
                        [getClassStyle(styles["label--active"])]: !checked,
                    }
                )}
            >
                No
            </span>
        </div>
    )
}

export { OnOffSwitch }
