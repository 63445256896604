import {
    JSONAPIResource,
    RateTransparencyDataVersion2,
    RateTransparencyMetaData,
} from "@vacasa/owner-api-models"
import { authAxios } from "../../api/authAxios"

export type RateTransparencyRequest = {
    unitId: string
    signal?: AbortSignal
}

type RateTransparencyRawResponse = {
    data: JSONAPIResource<RateTransparencyDataVersion2>[]
    meta: RateTransparencyMetaData
}

export type RateTransparencyResponse = {
    data: RateTransparencyDataVersion2[]
    meta: RateTransparencyMetaData
}

const fetchRateTransparencyData = ({
    unitId,
    signal,
}: RateTransparencyRequest): Promise<RateTransparencyResponse> =>
    authAxios
        .get<RateTransparencyRawResponse>(`/v1/rate-transparency-data`, {
            params: {
                unitId,
                acceptVersion: "v2",
            },
            signal,
        })
        .then(response => ({
            data: response.data.data.map(value => value.attributes),
            meta: response.data.meta,
        }))

export { fetchRateTransparencyData }
