import classnames from "classnames"
import { useIntl } from "react-intl"
import { ReactComponent as IllustrationsSVG } from "../../../assets/illustrations.svg"
import styles from "./GeneralError.module.scss"
import { ReactNode } from "react"

export interface GeneralErrorProps {
    errorHeading?: string
    errorMessage?: string | ReactNode
    buttonText?: string
    handleClick?: () => void
    customContainerStyle?: string
    hideHeading?: boolean
}

const GeneralError: React.FC<GeneralErrorProps> = props => {
    const {
        errorHeading,
        errorMessage,
        buttonText,
        handleClick,
        customContainerStyle,
        hideHeading,
    } = props
    const intl = useIntl()
    const defaultHeading = intl.formatMessage({
        id: "Errors.errorLoading",
        defaultMessage: "Error loading",
    })
    const defaultMessage = intl.formatMessage({
        id: "Errors.errorLoadingDescription",
        defaultMessage:
            "We were unable to load your request. Check your connection.",
    })

    const refreshPage = () => window.location.reload()

    return (
        <div className={customContainerStyle ?? styles.errorContainer}>
            <IllustrationsSVG />
            {!hideHeading && (
                <div className={styles.heading}>
                    {errorHeading ?? defaultHeading}
                </div>
            )}
            <div className={classnames("type-body-small", styles.message)}>
                {errorMessage ?? defaultMessage}
            </div>
            <button
                className={classnames("type-body", styles.retry)}
                onClick={handleClick ?? refreshPage}
            >
                {buttonText ??
                    intl.formatMessage({
                        id: "Errors.retry",
                        defaultMessage: "retry",
                    })}
            </button>
        </div>
    )
}

export { GeneralError }
