import React, { ReactNode } from "react"
import { capitalize, compact, isNull, map } from "lodash"
import { FormattedMessage, FormattedNumber } from "react-intl"

// Services
import { JSONAPIResource, UnitProgram } from "@vacasa/owner-api-models"
import { addYears, format, isAfter, parseISO, startOfDay } from "date-fns"
import {
    getLocaleFromLanguageCode,
    isSameOrAfter,
    isSameOrBefore,
    parse,
} from "utils/date"

export interface Plan {
    date: JSX.Element
    description: JSX.Element
    amount: JSX.Element
    id: string | undefined
}

export const generatePlanHistory = (
    data: UnitProgram[],
    currency: string,
    locale: string
): Plan[] => {
    const history = map(data, (plan, index) => {
        const chargeDate = getChargeDate(plan, locale)
        const chargeDescription = getChargeDescription(plan)
        const chargeAmount = getChargeAmount(plan, currency)
        return {
            date: chargeDate,
            description: chargeDescription,
            amount: chargeAmount,
            id: (plan.startDate ?? index).toString(),
        }
    })
    return compact(history)
}

interface ProgramPricing {
    display: JSX.Element
    pricing: JSX.Element | null
    nextMilestone: JSX.Element | null
    active: boolean
    discount: JSX.Element | null
}

export function generatePricingString(
    data: UnitProgram[],
    currency: string,
    locale: string
): ProgramPricing {
    const activeEnrollment = isProgramCurrentlyEnrolled(data)
    if (!activeEnrollment[0]) {
        return {
            display: (
                <FormattedMessage
                    id="LinenService.servicePlan.youAreNotEnrolled"
                    defaultMessage="You are not enrolled"
                />
            ),
            pricing: null,
            nextMilestone: null,
            active: false,
            discount: null,
        }
    }

    const enrollmentDetails = getProgramName(activeEnrollment[0])
    return {
        display: (
            <p>
                <strong>
                    {enrollmentDetails
                        ? parseLinenProgramName(enrollmentDetails)
                        : ""}
                </strong>
            </p>
        ),
        pricing: getProgramCost(activeEnrollment[0], true, true, currency),
        discount: getProgramDiscount(activeEnrollment[0], true, currency),
        nextMilestone: generateMilestoneString(data, locale) ?? null,
        active: activeEnrollment.length > 0,
    }
}

type NotificationString = {
    text: JSX.Element | null
    variant: "alertness" | "info" | null
}

export function generateNotificationString(
    data: UnitProgram[],
    currency: string
): NotificationString {
    const activeEnrollments = isProgramCurrentlyEnrolled(data)
    if (!activeEnrollments[0]) {
        return {
            text: null,
            variant: null,
        }
    }

    // Select the first active enrollment
    const activeEnrollment = activeEnrollments[0]

    const startDate = startOfDay(parse(activeEnrollment.startDate))
    const now = new Date()
    const willStartInFuture = isSameOrBefore(now, startDate)
    const programWillRenew = willRenew(activeEnrollment)

    const setupCost = zeroOrNull(
        activeEnrollment.billingAmountSetupActual,
        true
    ) as number
    const annualCost = zeroOrNull(
        activeEnrollment.billingAmountPerCycleActual,
        true
    ) as number
    let cost = (
        <FormattedNumber
            value={setupCost + annualCost}
            currency={currency}
            style="currency" // eslint-disable-line
        />
    )

    const nextBillingCycleDate = activeEnrollment.nextRenewal
        ? parse(activeEnrollment.nextRenewal)
        : addYears(parse(activeEnrollment.startDate), 1)

    if (willStartInFuture && data.length === 1) {
        cost = (
            <FormattedNumber
                value={parseFloat(
                    activeEnrollment.billingAmountPerCycleActual ?? "0.00"
                )}
                currency={currency}
                style="currency" // eslint-disable-line
            />
        )
        return {
            text: (
                <p className="type-body-small">
                    <FormattedMessage
                        id="LinenService.notificationComponent.planWillBeginWithInitialCharge"
                        defaultMessage="Your plan will begin on <strong>{date, date, medium}</strong> with an initial charge of <strong>{cost}</strong>."
                        values={{
                            date: nextBillingCycleDate,
                            cost,
                            strong: (chunks: ReactNode) => (
                                <strong>{chunks}</strong>
                            ),
                        }}
                    />
                </p>
            ),
            variant: "info",
        }
    }

    if (willStartInFuture && data.length > 1) {
        return {
            text: (
                <p className="type-body-small">
                    <FormattedMessage
                        id="LinenService.notificationComponent.planWillBeginWithCharge"
                        defaultMessage="Your plan will begin on <strong>{date, date, medium}</strong> with a charge of <strong>{cost}</strong>."
                        values={{
                            date: nextBillingCycleDate,
                            cost,
                            strong: (chunks: ReactNode) => (
                                <strong>{chunks}</strong>
                            ),
                        }}
                    />
                </p>
            ),
            variant: "info",
        }
    }

    if (programWillRenew) {
        return {
            text: (
                <p className="type-body-small">
                    <FormattedMessage
                        id="LinenService.notificationComponent.planWillRenewFor"
                        defaultMessage="Your plan will automatically renew on <strong>{date, date, medium}</strong> for <strong>{cost}</strong>"
                        values={{
                            date: nextBillingCycleDate,
                            cost,
                            strong: (chunks: ReactNode) => (
                                <strong>{chunks}</strong>
                            ),
                        }}
                    />
                </p>
            ),
            variant: "info",
        }
    }

    const programWillBillInCurrentCycle = willBillInCurrentCycle(
        activeEnrollments[0]
    )
    const hasSubsequentProgram = !!getNextProgram(
        activeEnrollments,
        activeEnrollments[0]
    )

    // if we're here, we're safely past the renewals. we can assume the program with either expire or display
    // the next program.
    if (!programWillBillInCurrentCycle && hasSubsequentProgram) {
        return {
            text: (
                <p className="type-body-small">
                    <FormattedMessage
                        id="LinenService.notificationComponent.planWillBeginWithCharge"
                        defaultMessage="Your plan will begin on <strong>{date, date, medium}</strong> with a charge of <strong>{cost}</strong."
                        values={{
                            date: nextBillingCycleDate,
                            cost,
                            strong: (chunks: ReactNode) => (
                                <strong>{chunks}</strong>
                            ),
                        }}
                    />
                </p>
            ),
            variant: "info",
        }
    }

    if (!willStartInFuture && !programWillRenew) {
        return {
            text: (
                <p>
                    <FormattedMessage
                        id="LinenService.notificationComponent.planWillEndOn"
                        defaultMessage="Your plan will end on <strong>{date, date, medium}</strong> after which you will no longer receive discounted replacement sheets and towels."
                        values={{
                            date: nextBillingCycleDate,
                            strong: (chunks: ReactNode) => (
                                <strong>{chunks}</strong>
                            ),
                        }}
                    />
                </p>
            ),
            variant: "alertness",
        }
    }
    return {
        text: null,
        variant: null,
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function zeroOrNull(field: any, makeNum = false): string | number {
    if (parseInt(field, 10) === 0 || isNull(field)) {
        return makeNum ? parseFloat("0.00") : "0.00"
    }
    return makeNum ? parseFloat(field) : field
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function hasCost(field: any): boolean {
    if (parseInt(field, 10) === 0 || isNull(field)) {
        return false
    }
    return true
}

function isProgramCurrentlyEnrolled(data: UnitProgram[]) {
    if (!data.length) {
        return []
    }

    return data.filter(d => d.enrolled).reverse()
}

function getStringFromBillingCycle(
    billingCycle: string | null | undefined
): React.ReactNode {
    switch (billingCycle) {
        case "annual":
            return (
                <FormattedMessage
                    id="LinenService.servicePlan.year"
                    defaultMessage="year"
                />
            )
        default:
            return ""
    }
}

function programHasEndDate(programEnrollment: UnitProgram): boolean {
    return !!programEnrollment.endDate
}

function willRenew(programEnrollment: UnitProgram): boolean {
    const hasEndDate = programHasEndDate(programEnrollment)
    if (!hasEndDate) {
        return true
    }

    const endDate = parse(programEnrollment.endDate)

    const renewalDate = programEnrollment.nextRenewal
        ? parse(programEnrollment.nextRenewal)
        : addYears(parse(programEnrollment.startDate), 1)
    return isSameOrBefore(endDate, renewalDate)
}

function willBillInCurrentCycle(programEnrollment: UnitProgram): boolean {
    const hasEndDate = programHasEndDate(programEnrollment)
    if (!hasEndDate) {
        return true
    }

    const programEndDate = parse(programEnrollment.endDate)
    const nextBillingCycleDate = programEnrollment.nextRenewal
        ? parse(programEnrollment.nextRenewal)
        : addYears(parse(programEnrollment.startDate), 1)

    if (isAfter(nextBillingCycleDate, programEndDate)) {
        return false
    }
    return true
}

function generateMilestoneString(
    data: Array<UnitProgram>,
    locale: string
): JSX.Element | undefined {
    const activeEnrollments = isProgramCurrentlyEnrolled(data)
    const hasNonConsecutiveEnrollment = data.length > 1
    const activeEnrollment = activeEnrollments[0]

    const now = new Date()
    const startDate = parse(activeEnrollment?.startDate)
    const endDate = parse(activeEnrollment?.endDate)
    const isStartDateInFuture = isSameOrAfter(startDate, now)

    const startDateFormatted = format(startDate, "PP", {
        locale: getLocaleFromLanguageCode(locale),
    })

    if (isStartDateInFuture && !hasNonConsecutiveEnrollment) {
        return (
            <p>
                <FormattedMessage
                    id="LinenService.begins"
                    defaultMessage="Begins"
                />{" "}
                {startDateFormatted}
            </p>
        )
    }

    // we have previously enrolled programs
    if (isStartDateInFuture && !hasNonConsecutiveEnrollment) {
        return (
            <p>
                <FormattedMessage
                    id="LinenService.servicePlan.nextBillingDate"
                    defaultMessage="Next billing date"
                />{" "}
                {startDateFormatted}
            </p>
        )
    }

    // we have non-consecutive previously enrolled programs
    if (isStartDateInFuture && hasNonConsecutiveEnrollment) {
        return (
            <p>
                <FormattedMessage
                    id="LinenService.servicePlan.newPlanBegins"
                    defaultMessage="New plan begins date"
                />{" "}
                {startDateFormatted}
            </p>
        )
    }

    const programWillRenew = activeEnrollment && willRenew(activeEnrollment)
    const nextRenewalDate = activeEnrollment?.nextRenewal
        ? parse(activeEnrollment.nextRenewal)
        : addYears(parse(activeEnrollment?.startDate), 1)

    // if we have multiple program entries, but we will NOT renew
    if (!isStartDateInFuture && programWillRenew) {
        return (
            <p>
                <FormattedMessage
                    id="LinenService.servicePlan.nextBilling"
                    defaultMessage="Next billing"
                />
                : {getFormattedRenewalDate(nextRenewalDate, locale)}
            </p>
        )
    }

    const programWillBillInCurrentCycle =
        activeEnrollments[0] && willBillInCurrentCycle(activeEnrollments[0])
    const hasSubsequentProgram = !!(
        activeEnrollments[0] &&
        getNextProgram(activeEnrollments, activeEnrollments[0])
    )

    // if we're here, we're safely past the renewals. we can assume the program with either expire or display
    // the next program.
    if (!programWillBillInCurrentCycle && hasSubsequentProgram) {
        const nextProgram = getNextProgram(activeEnrollments, activeEnrollment)

        const isSameYear =
            nextRenewalDate.getFullYear() === new Date().getFullYear()
        const localeDateString = format(nextRenewalDate, "PP", {
            locale: getLocaleFromLanguageCode(locale),
        })
        const formattedDate = isSameYear
            ? localeDateString.substring(0, 5)
            : localeDateString

        const nextProgramStartDateFormatted = nextProgram ? formattedDate : ""

        return (
            <p>
                <FormattedMessage
                    id="LinenService.servicePlan.newPlanBegins"
                    defaultMessage="New plan begins date"
                />{" "}
                : {nextProgramStartDateFormatted}
            </p>
        )
    }

    if (!isStartDateInFuture && !programWillRenew) {
        return (
            <p>
                <FormattedMessage
                    id="LinenService.expires"
                    defaultMessage="Expires"
                />{" "}
                {format(endDate, "PP", {
                    locale: getLocaleFromLanguageCode(locale),
                })}
            </p>
        )
    }
}

const getFormattedRenewalDate = (date: Date, languageCode: string) => {
    const isSameYear = date.getFullYear() === new Date().getFullYear()
    const localeDateString = format(date, "PP", {
        locale: getLocaleFromLanguageCode(languageCode),
    })
    const formattedDate = isSameYear
        ? localeDateString.substring(0, 5)
        : localeDateString
    return formattedDate
}

const getNextProgram = (
    enrollments: UnitProgram[],
    currentEnrollement: UnitProgram | undefined
): UnitProgram | undefined => {
    const endDate = parse(currentEnrollement?.endDate)
    const nextProgram = enrollments.find(enrollment => {
        const enrollmentStartDate = parse(enrollment.startDate)
        return isAfter(enrollmentStartDate, endDate)
    })
    return nextProgram
}

const getProgramName = (data: UnitProgram): string | null => {
    if (!data.programFields || data.programFields.length === 0) {
        return null
    }
    return data.programFields[0]?.data?.toString() ?? null
}

function getProgramCost(
    data: UnitProgram,
    increment = true,
    includeSetupFee = true,
    currency: string
): JSX.Element {
    const hasSetupCost = hasCost(data.billingAmountSetupActual)
    const billingAnnualized = parseFloat(
        data.billingAmountPerCycleActual ?? "0"
    )
    const billingSetup = parseFloat(data.billingAmountSetupActual ?? "0")
    return (
        <div>
            <span className="program-cost">
                <FormattedNumber
                    value={billingAnnualized}
                    currency={currency}
                    style="currency" // eslint-disable-line
                />
                {increment && (
                    <span>
                        &nbsp;/ {getStringFromBillingCycle(data.billingCycle)}
                    </span>
                )}
                {hasSetupCost && includeSetupFee && (
                    <span className="setup-cost">
                        &nbsp;+&nbsp;
                        <FormattedNumber
                            value={billingSetup}
                            currency={currency}
                            style="currency" // eslint-disable-line
                        />{" "}
                        <FormattedMessage
                            id="LinenService.servicePlan.setupFee"
                            defaultMessage="setup fee"
                        />
                    </span>
                )}
            </span>
        </div>
    )
}

function getProgramDiscount(
    data: UnitProgram,
    showPill = true,
    currency: string
): JSX.Element | null {
    const defaultAnnualCost = zeroOrNull(
        data.billingAmountPerCycleDefault,
        true
    ) as number

    const actualAnnualCost = zeroOrNull(
        data.billingAmountPerCycleActual,
        true
    ) as number

    const defaultSetupCost = zeroOrNull(
        data.billingAmountSetupDefault,
        true
    ) as number
    const actualSetupCost = zeroOrNull(
        data.billingAmountSetupActual,
        true
    ) as number

    const actualCosts = actualAnnualCost + actualSetupCost
    const defaultCosts = actualSetupCost + defaultSetupCost

    const hasDiscount = actualCosts < defaultCosts

    let discount = null
    let discountClasses = ["discount"]
    if (showPill) {
        discountClasses = discountClasses.concat(["badge", "badge-pill"])
    }
    if (hasDiscount) {
        const discountAmount =
            defaultAnnualCost +
            defaultSetupCost -
            (actualAnnualCost + actualSetupCost)
        discount = (
            <span className={discountClasses.join(" ")}>
                <FormattedNumber
                    value={discountAmount}
                    currency={currency}
                    style="currency" // eslint-disable-line
                />
                {showPill && (
                    <span>
                        &nbsp;
                        <FormattedMessage
                            id="LinenService.servicePlan.discount"
                            defaultMessage="discount"
                        />
                    </span>
                )}
                {!showPill && (
                    <span>
                        &nbsp;
                        <FormattedMessage
                            id="LinenService.servicePlan.discount"
                            defaultMessage="discount"
                        />
                    </span>
                )}
            </span>
        )
    }
    return discount
}

function parseLinenProgramName(name: string): React.ReactNode {
    if (name === "standard") {
        return (
            <FormattedMessage
                id="LinenService.servicePlan.standard"
                defaultMessage="Standard linen service"
            />
        )
    }

    if (name === "premium") {
        return (
            <FormattedMessage
                id="LinenService.servicePlan.premium"
                defaultMessage="Premium linen service"
            />
        )
    }
    return (
        <FormattedMessage
            id="LinenService.notEnrolled"
            defaultMessage="Not enrolled"
        />
    )
}

const getChargeDate = (plan: UnitProgram, locale: string): JSX.Element => {
    if (!plan.startDate) {
        return <></>
    }

    const startDate = parseISO(plan.startDate)

    if (plan.endDate) {
        const startDateFormatted = format(startDate, "PP", {
            locale: getLocaleFromLanguageCode(locale),
        })
        const endDateFormatted = format(parseISO(plan.endDate), "PP", {
            locale: getLocaleFromLanguageCode(locale),
        })
        return (
            <div className="charge-date">
                {`${startDateFormatted} - ${endDateFormatted}`}
            </div>
        )
    }

    return (
        <div className="charge-date">
            <span>
                {format(startDate, "PP", {
                    locale: getLocaleFromLanguageCode(locale),
                })}{" "}
                -{" "}
                <FormattedMessage
                    id="LinenService.servicePlan.ongoing"
                    defaultMessage="ongoing"
                />
            </span>
        </div>
    )
}

function getChargeDescription(plan: UnitProgram): JSX.Element {
    const renderOutputFragment = (children: React.ReactNode) => {
        return <div className="service-level">{children}</div>
    }

    if (plan.programFields && plan.programFields.length > 0) {
        const serviceType = capitalize(
            plan.programFields[0]?.data?.toString() ?? ""
        )
        if (serviceType === "Standard") {
            return renderOutputFragment(
                <FormattedMessage
                    id="LinenService.servicePlan.standard"
                    defaultMessage="Standard linen service"
                />
            )
        }

        if (serviceType === "Premium") {
            return renderOutputFragment(
                <FormattedMessage
                    id="LinenService.servicePlan.premium"
                    defaultMessage="Premium linen service"
                />
            )
        }
    }

    if (plan.isSetup) {
        return renderOutputFragment(
            <FormattedMessage
                id="LinenService.servicePlan.setup"
                defaultMessage="Setup"
            />
        )
    }

    return renderOutputFragment(
        <FormattedMessage
            id="LinenService.title"
            defaultMessage="Linen service"
        />
    )
}

function getChargeAmount(plan: UnitProgram, currency: string): JSX.Element {
    const cost = getProgramCost(plan, true, false, currency)
    const hasDiscount = getProgramDiscount(plan, false, currency)

    return (
        <div className="cost">
            {cost}
            {hasDiscount && <div className="discount">{hasDiscount}</div>}
        </div>
    )
}

export const isEnrolled = ({
    programFields,
}: UnitProgram): "enrolled" | "opted_out" | "not_enrolled" => {
    const payerProgramField = programFields.find(({ slug }) => slug === "payer")
    if (payerProgramField === undefined) return "not_enrolled"
    if (payerProgramField.data === null) return "opted_out"
    return "enrolled"
}

/**
 * Checks to see if there are any enrolled programs in the list
 * @param programs
 * @returns {boolean}
 */
export const hasEnrolledProgram = (
    programs?: JSONAPIResource<UnitProgram>[]
) => {
    if (!programs) return false
    return programs.some(program => program.attributes.enrolled)
}
