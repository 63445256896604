import styles from "./Spinner.module.scss"

/**
 * Provide a visual indication that something is loading, processing, or changing.
 */
const Spinner = (): JSX.Element => {
    return (
        <div className={styles.spinner}>
            <div className={styles.bounce} />
            <div className={styles.bounce} />
            <div className={styles.bounce} />
        </div>
    )
}

export { Spinner }
