import classnames from "classnames"
import React from "react"
import { Zap } from "react-feather"
import { FormattedMessage } from "react-intl"
import styles from "./BookingProbabilityLegend.module.scss"

const BookingProbabilityLegend: React.FC<{
    legendStyleOverride?: string
    zapStyleOverride?: string
}> = ({ legendStyleOverride, zapStyleOverride }) => {
    return (
        <div
            className={classnames(
                styles.bookingProbabilityLegend,
                legendStyleOverride
            )}
        >
            <Zap className={classnames(styles.zap, zapStyleOverride)} />
            <FormattedMessage
                id="CalendarPage.bookingProbabilityLegend"
                defaultMessage="Home has a high chance of being booked"
            />
        </div>
    )
}

export { BookingProbabilityLegend }
