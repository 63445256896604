import { Referral } from "@vacasa/owner-api-models"
import { AxiosError } from "axios"
import classNames from "classnames"
import { Primary } from "lib/components/Buttons/BaseButton"
import { ButtonLoader } from "lib/components/Loader/ButtonLoader/ButtonLoader"
import { ReactNode, useCallback } from "react"
import { FormattedMessage } from "react-intl"
import { UseMutationResult } from "react-query"
import { FormGroup, Input, Label } from "reactstrap"
import { validateEmail } from "utils/calendar/util"
import { AddressAutoComplete } from "./AddressAutoComplete"
import styles from "./ReferralModal.module.scss"
import { Warning } from "./Warning"
import { trackReferralFormSubmitted } from "services/segment/referral/referralTracking"

interface ReferralFormProps {
    shouldDisableSubmit: boolean
    referral: Referral
    showWarning: boolean
    handleReferral: (key: string, value: string) => void
    emailValidationError: boolean
    handleEmailValidationError: (email?: string) => void
    handleAcknowledgement: (option: boolean) => void
    referralMutation: UseMutationResult<number, AxiosError, Referral, void>
}

interface SubmitButtonProps {
    referral: Referral
    shouldDisableSubmit: boolean
    handleEmailValidationError: (email?: string) => void
    referralMutation: UseMutationResult<number, AxiosError, Referral, void>
}

const handleSaveReferral = (
    referral: Referral,
    referralMutation: UseMutationResult<number, AxiosError, Referral, void>
) => {
    const isEmailValid = referral.email && validateEmail(referral.email)
    if (isEmailValid) {
        referralMutation.mutate(referral, {
            onSuccess: () => {
                trackReferralFormSubmitted({
                    /* eslint-disable camelcase */
                    first_name: referral.firstName,
                    last_name: referral.lastName,
                    email: referral.email,
                    phone: referral.phone,
                    street: referral.street,
                    city: referral.city,
                    state: referral.state,
                    country: referral.country,
                    zipCode: referral.zipCode,
                    /* eslint-enable camelcase */
                })
            },
        })
    }
}

const SubmitButton: React.FC<SubmitButtonProps> = props => {
    const {
        referral,
        shouldDisableSubmit,
        handleEmailValidationError,
        referralMutation,
    } = props

    const handleOnClick = useCallback(async () => {
        handleEmailValidationError(referral.email)
        handleSaveReferral(referral, referralMutation)
    }, [handleEmailValidationError, referral, referralMutation])

    return (
        <Primary
            className={styles.submit}
            onClick={handleOnClick}
            isLoading={referralMutation.isLoading}
            disabled={shouldDisableSubmit || referralMutation.isLoading}
            loader={
                <ButtonLoader
                    indicator={"dot"}
                    indicatorCount={3}
                    animation={"blink"}
                    styleOverride={styles.loading}
                />
            }
            dataTestId="referral-submit-button"
        >
            <FormattedMessage
                id="OwnerReferral.button"
                defaultMessage="Submit"
            />
        </Primary>
    )
}

const ReferralForm: React.FC<ReferralFormProps> = props => {
    const {
        shouldDisableSubmit,
        referral,
        showWarning,
        handleReferral,
        emailValidationError,
        handleEmailValidationError,
        handleAcknowledgement,
        referralMutation,
    } = props

    return (
        <div className={styles.formBody}>
            <div className={styles.bodyParagraph}>
                <div>
                    <FormattedMessage
                        id="OwnerReferral.bodyParagraph1"
                        defaultMessage="Appreciate what Vacasa does for you and your vacation home — from marketing to cleaning, maintenance to 24/7 guest service?"
                    />
                </div>
                <div>
                    <FormattedMessage
                        id="OwnerReferral.bodyParagraph2"
                        defaultMessage="Then tell your friends. To say thanks, we'll add a $500 credit to your next statement and a $500 credit to their first statement when they sign up."
                    />
                    <sup>§</sup>
                </div>
            </div>
            <div className={styles.subtitle}>
                <FormattedMessage
                    id="OwnerReferral.subTitle"
                    defaultMessage="Give us the details:"
                />
            </div>
            <div className={styles.required}>
                <FormattedMessage
                    id="OwnerReferral.required"
                    defaultMessage="* Required"
                />
            </div>
            <FormGroup>
                <div className={styles.names}>
                    <div>
                        <Label for="firstName" className={styles.label}>
                            <FormattedMessage
                                id="OwnerReferral.firstName"
                                defaultMessage="Friend's first name"
                            />
                            *
                        </Label>
                        <Input
                            className={classNames(
                                styles.referralInput,
                                styles.name
                            )}
                            type="text"
                            required
                            name="firstName"
                            value={referral.firstName}
                            onChange={(e): void => {
                                handleReferral(
                                    "firstName",
                                    e.currentTarget.value
                                )
                            }}
                            data-testid="referral-first-name"
                        />
                    </div>
                    <div>
                        <Label for="lastName" className={styles.label}>
                            <FormattedMessage
                                id="OwnerReferral.lastName"
                                defaultMessage="Friend's last name"
                            />
                            *
                        </Label>
                        <Input
                            className={classNames(
                                styles.referralInput,
                                styles.name
                            )}
                            type="text"
                            required
                            name="lastName"
                            value={referral.lastName}
                            onChange={(e): void => {
                                handleReferral(
                                    "lastName",
                                    e.currentTarget.value
                                )
                            }}
                            data-testid="referral-last-name"
                        />
                    </div>
                </div>
                <div className={styles.emailAndPhone}>
                    <div>
                        <Label for="email" className={styles.label}>
                            <FormattedMessage
                                id="OwnerReferral.email"
                                defaultMessage="Friend's email"
                            />
                            *
                        </Label>
                        <Input
                            className={
                                emailValidationError
                                    ? classNames(
                                          styles.errorInput,
                                          styles.email
                                      )
                                    : classNames(
                                          styles.referralInput,
                                          styles.email
                                      )
                            }
                            type="text"
                            required
                            name="email"
                            value={referral.email}
                            onChange={(e): void => {
                                if (!e.currentTarget.value) {
                                    handleEmailValidationError()
                                }
                                handleReferral("email", e.currentTarget.value)
                            }}
                            data-testid="referral-email"
                        />
                        {emailValidationError && (
                            <div className={styles.invalidEmail}>
                                <FormattedMessage
                                    id="OwnerReferral.invalidEmail"
                                    defaultMessage="Please enter a valid email."
                                />
                            </div>
                        )}
                    </div>
                    <div>
                        <Label for="phone" className={styles.label}>
                            <FormattedMessage
                                id="OwnerReferral.phone"
                                defaultMessage="Friend's phone number"
                            />
                        </Label>
                        <Input
                            className={classNames(
                                styles.referralInput,
                                styles.phone
                            )}
                            type="text"
                            name="phone"
                            value={referral.phone}
                            onChange={(e): void => {
                                handleReferral("phone", e.currentTarget.value)
                            }}
                            data-testid="referral-phone"
                        />
                    </div>
                </div>
                <Label for="address" className={styles.label}>
                    <FormattedMessage
                        id="OwnerReferral.address"
                        defaultMessage="Friend's vacation home address"
                    />
                    *
                </Label>
                <AddressAutoComplete
                    handleReferral={handleReferral}
                    showWarning={showWarning}
                />
                {showWarning && (
                    <Warning handleAcknowledgement={handleAcknowledgement} />
                )}
            </FormGroup>
            <SubmitButton
                referral={referral}
                shouldDisableSubmit={shouldDisableSubmit}
                handleEmailValidationError={handleEmailValidationError}
                referralMutation={referralMutation}
            />
            <div className={styles.formFooter}>
                <sup>§</sup>
                <FormattedMessage
                    id="OwnerReferral.footerDescription"
                    defaultMessage="While we welcome referrals of homes in any area, this offer is not available for referrals of homes located in AL, AZ, AR, CT, DE, D.C., HI, IA, KY, LA, MI, MN, MO, MT, NC, ND, NH, NJ, NM, NV, NY, OH, PA, PR, RI, SC, TN, VA, WV, WI, WY or any other location or circumstance where a real estate license is required to provide property management for the referred home. All referred properties that sign up for Guestworks will be compensated a flat rate of $250 once the property is live for booking on Guestworks.com. Click <a>here</a> for full terms and conditions."
                    values={{
                        a: (chunks: ReactNode) => (
                            <a
                                key="footerDescription"
                                href="https://www.vacasa.com/go/refer-a-friend/terms-and-conditions"
                                target="_blank"
                                rel="noreferrer"
                            >
                                {chunks}
                            </a>
                        ),
                    }}
                />
            </div>
        </div>
    )
}

export { ReferralForm }
