import React from "react"
import styles from "./GettingStartedPanelV2.module.scss"
import { ReactComponent as ChecklistSVG } from "../../assets/icon-onboarding.svg"
import { OnboardingProgressBarV2 } from "./OnboardingProgressBarV2"
import { JSONAPIResource, Unit } from "@vacasa/owner-api-models"
import { FormattedMessage } from "react-intl"

interface GettingStartedPanelV2Props {
    isUnderReview?: boolean
    onboardingUnits: JSONAPIResource<Unit>[]
    showProgressBar: boolean
}

const WhatVacasaIsDoing: React.FC = () => {
    return (
        <div className={styles.whatVacasaIsDoing}>
            <h5 className={styles.whatVacasaIsDoingText}>
                <FormattedMessage
                    id="Onboarding.verifyListing.whatVacasaIsDoing"
                    description="What Vacasa is doing"
                    defaultMessage="What Vacasa is doing:"
                />
            </h5>
        </div>
    )
}

export const GettingStartedPanelV2 = ({
    onboardingUnits,
    showProgressBar,
}: GettingStartedPanelV2Props): JSX.Element => {
    return (
        <div className={styles.outerPanel}>
            <div className={styles.staticPanel}>
                <div className={styles.svgContainer}>
                    <ChecklistSVG />
                </div>

                <h3
                    data-testid="onboarding-title"
                    className="type-heading-medium-large"
                >
                    {
                        <FormattedMessage
                            id="Onboarding.verifyListing.title"
                            description="Getting Started Panel Title"
                            defaultMessage="Let's get started"
                        />
                    }
                </h3>
                <p>
                    {
                        <FormattedMessage
                            id="Onboarding.verifyListing.description"
                            description="Getting Started Panel Description"
                            defaultMessage="Review your listing details and calendar below so we can get your home live."
                        />
                    }
                </p>
            </div>

            <WhatVacasaIsDoing />
            {showProgressBar ? (
                <>
                    <OnboardingProgressBarV2
                        onboardingUnit={onboardingUnits[0]}
                    />
                    <div className={styles.additionalDoing}>
                        <FormattedMessage
                            id="Onboarding.verifyListing.additionalDoing"
                            description="Additional things that Vacasa is doing"
                            defaultMessage="We're also working on completing the initial inspection and securing permits (if required) to get your home rental ready."
                        />
                    </div>
                </>
            ) : (
                <>
                    <div className={styles.middleWhitespace} />
                    <div className={styles.additionalDoing}>
                        <FormattedMessage
                            id="Onboarding.verifyListing.additionalDoingMultiUnit"
                            description="Additional things that Vacasa is doing"
                            defaultMessage="In addition to taking photos, writing a custom description, and setting your rates, we're also completing the initial inspection of your home and securing permits (if required) to get your home rental ready."
                        />
                    </div>
                </>
            )}
            <div className={styles.bottomWhiteSpace} />
        </div>
    )
}
