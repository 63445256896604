import { authAxios } from "../../api/authAxios"

type FetchTaxFormsRequest = {
    contactId: string
    signal?: AbortSignal
}

export type TaxForm = {
    formID: string
    formName: string
    url: string
    year: number
}

export const fetchTaxForms = ({
    contactId,
    signal,
}: FetchTaxFormsRequest): Promise<TaxForm[]> => {
    return authAxios
        .get<{ data: TaxForm[] }>(`/v1/legacy/owners/${contactId}/tax-forms`, {
            signal,
        })
        .then(response => response.data.data)
}
