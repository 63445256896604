import { useIntl } from "react-intl"
import styles from "./StaticListItem.module.scss"
import { Secondary } from "lib/components/Buttons/BaseButton"
import { Edit } from "react-feather"
import { OnOffSwitch } from "lib/components/Toggle/OnOffSwitch"

export enum ActionName {
    Edit = "edit",
    Save = "save",
    Switch = "switch",
}

interface ItemProps {
    displayItem?: ActionName
    onEditClick?: () => void
    onSaveClick?: () => void
    isSaveDisabled?: boolean
    onOnOffSwitchClick?: (checked: boolean) => void
    isSwitchChecked?: boolean
    isSwitchDisabled?: boolean
}

const StaticListActionItem: React.FC<ItemProps> = ({
    displayItem,
    onEditClick,
    onSaveClick,
    isSaveDisabled,
    onOnOffSwitchClick,
    isSwitchChecked,
    isSwitchDisabled,
}) => {
    const intl = useIntl()
    return (
        <>
            {displayItem === ActionName.Save && onSaveClick && (
                <div className={styles["Button"]}>
                    <Secondary
                        className={styles["Save"]}
                        typeOf={"link"}
                        onClick={onSaveClick}
                        disabled={isSaveDisabled}
                    >
                        {intl.formatMessage({
                            id: "CalendarPage.flyout.save",
                            defaultMessage: "Save",
                        })}
                    </Secondary>
                </div>
            )}

            {displayItem === ActionName.Edit && onEditClick && (
                <div className={styles["Button"]} onClick={onEditClick}>
                    <Edit />
                </div>
            )}
            {displayItem === ActionName.Switch && onOnOffSwitchClick && (
                <div className={styles["Button"]}>
                    <OnOffSwitch
                        checked={isSwitchChecked}
                        onChange={() =>
                            onOnOffSwitchClick(isSwitchChecked ?? false)
                        }
                        disabled={isSwitchDisabled}
                    />
                </div>
            )}
        </>
    )
}

export default StaticListActionItem
