import { OwnerSearchResult } from "@vacasa/owner-api-models/src/legacy"
import { AxiosError } from "axios"
import { useMutation, UseMutationResult } from "react-query"
import { postOwnerSearch, PostSearchRequest } from "./postOwnerSearch"

type MutationResponse = OwnerSearchResult[] | undefined

const useOwnerSearch = (): UseMutationResult<
    MutationResponse,
    AxiosError,
    PostSearchRequest
> => {
    return useMutation<MutationResponse, AxiosError, PostSearchRequest>(data =>
        postOwnerSearch(data)
    )
}
export { useOwnerSearch }
