const linenService = {
    title: "Bettwäsche-Service",

    notificationComponent: {
        planWillBeginWithInitialCharge:
            "Dein Plan beginnt am <strong>{date, date, medium}</strong> mit einer Erstgebühr von <strong>{cost}</strong>.",
        planWillBeginWithCharge:
            "Dein Plan beginnt am <strong>{date, date, medium}</strong> mit einer Gebühr von <strong>{cost}</strong>.",
        planWillRenewFor:
            "Dein Plan erneuert sich automatisch am <strong>{date, date, medium}</strong> für <strong>{cost}</strong>",
        planWillEndOn:
            "Dein Plan endet am <strong>{date, date, medium}</strong> wonach du keine ermäßigte Bettwäsche und Handtücher mehr erhältst.",
    },

    infoPanel: {
        learnMoreTitle: "Eine einfache, erschwingliche Lösung",
        bodyText:
            "Unser Bettwäsche-Service bietet Bettwäsche in Hotelqualität zu ermäßigten Preisen für die Industrie. Frische Bettwäsche und flauschige Handtücher bedeuten bessere Bewertungen, mehr Buchungen und höhere Mieteinnahmen.",
        learnMore: "Weitere Informationen",
    },

    servicePlan: {
        prepend: "Dein Plan",
        standard: "Bettwäsche-Service Standard",
        premium: "Bettwäsche-Service Premium",
        youAreNotEnrolled: "Du bist nicht angemeldet",
        year: "Jahr",
        nextBillingDate: "Nächstes Rechnungsdatum",
        newPlanBegins: "Anfangsdatum des neuen Plans",
        nextBilling: "Nächste Rechnung",
        discount: "Ermäßigung",
        setup: "Einrichtung",
        setupFee: "Einrichtungsgebühr",
        planHistory: "DE Plan history",
        ongoing: "heute",
        pending: "Ausstehend",
        append: "Jegliche Gebühren für diese Leistung werden als Kosten in deinen monatlichen Abrechnungen aufgeführt.",
    },

    helpPanel: {
        needHelp: "Brauchst du Hilfe?",
        contactSentenceIfQuestions:
            "Bitte melde dich bei deinem Kontakt {contact} wenn du Fragen hast oder deinen Plan aktualisieren möchtest.",
    },

    enrolled: "Angemeldet",
    notEnrolled: "Nicht angemeldet",
    begins: "Beginnt",
    renews: "Verlängert sich",
    expires: "Läuft aus",
}

export default linenService
