import React, { FC, useCallback, useEffect, useState } from "react"
import { ListLayout } from "../../components/Layout/ListLayout"

// Components
import { ContractOwner } from "@vacasa/owner-api-models"
import { PanelType, NoUnitStatusPanel } from "../../lib/components/EmptyState"
import { AverageStarRating } from "./AverageStarRating"
import { NightsOccupied } from "./NightsOccupied"
import { NoAverageStarRating } from "./NoAverageStarRating"
import { Chart } from "./partials/Chart"
import { PerformanceEmptyState } from "./partials/PerformanceEmptyState"
import { ReferralBanner } from "components/ReferralBanner"
import { RevenueChartContext } from "contexts/performance"
import { isValid, parseISO } from "date-fns"
import { useContactId } from "hooks/user"
import { useOwnerFeatureFlag } from "hooks/owner-feature-flag"
import { useRevenue } from "hooks/revenue"
import { useUnitReviews } from "hooks/review"
import { MessageDescriptor, useIntl } from "react-intl"
import {
    trackPerformanceChartCompareAddFilter,
    trackPerformanceChartCompareRemoveFilter,
    trackPerformancePageViewed,
} from "services/segment/performance/performanceTracking"
import { toRevenueData } from "utils/charts/chartsUtil"
import { isFeatureFlagEnabled } from "utils/feature-flag"
import { UnitSelectWrapper } from "views/UnitSelectWrapper"
import styles from "./Performance.module.scss"
import { RevenueData } from "./types"
import { UnitMinRateNotification } from "./unit-min-rate/UnitMinRateNotification"
import { OptimizelyFeature } from "@optimizely/react-sdk"
import { WebinarBanner } from "components/WebinarBanner"
import { default as Charts } from "./components/Chart"
import { useCurrentUnit, useUnitStatuses } from "hooks/units"
import classNames from "classnames"
import { Card, CardContent } from "components/Card"
import { RateCalculatorChart } from "./components/Chart/RateCalculatorChart"
import CoreMetrics from "./components/CoreMetrics"
import { RevenuePreferences } from "./components/RevenuePreferences/RevenuePreferences"

/**
 * Returns if any of the requests are currently fetching data
 * @param args
 * @returns
 */
const anyRequestsInProgress = (...args: boolean[]) =>
    args.some(loading => loading)

/**
 * Checks to see if the owner is not allowed to view performance data
 * @param owners
 * @param contactId
 * @returns boolean
 */
const isPerformanceNotAllowed = (
    owners: ContractOwner[] | undefined,
    contactId: string | null
) => {
    const matchedOwner = owners
        ? owners.find(
              (owner: ContractOwner) => String(owner.contactID) === contactId
          )
        : undefined
    const percentOwnership = matchedOwner
        ? parseFloat(matchedOwner.percentageOwnership)
        : 0
    const taxOwnership = matchedOwner
        ? parseFloat(matchedOwner.taxOwnership)
        : 0
    return percentOwnership === 0 && taxOwnership === 0
}

const getPerformanceStartYear = ({
    acquisitionDate,
    defaultStartYear,
}: {
    acquisitionDate?: string
    defaultStartYear: number
}): number => {
    if (!acquisitionDate) return defaultStartYear
    const date = parseISO(acquisitionDate)
    if (!isValid(date)) return defaultStartYear
    return date.getFullYear()
}

export const Performance: FC = (): JSX.Element => {
    const intl = useIntl()

    const contactId = useContactId()
    const { unit, unitId } = useCurrentUnit()
    const { isOnboarding, isFixedRent } = useUnitStatuses()
    const [showPreviousYear, setShowPreviousYear] = useState(false)

    const [year, setYear] = useState(new Date().getFullYear())

    const selectedYearRevenue = useRevenue(unitId, year)
    const priorYearRevenue = useRevenue(unitId, year - 1)

    const unitReviews = useUnitReviews(unitId)
    const dashboardFeatureFlag = useOwnerFeatureFlag("dashboard")
    const dashboardEnabled = isFeatureFlagEnabled(dashboardFeatureFlag)

    const isFetching = anyRequestsInProgress(
        unitReviews.isLoading,
        selectedYearRevenue.isLoading,
        priorYearRevenue.isLoading,
        !dashboardFeatureFlag.clientReady
    )

    const minRateFeatureFlag = useOwnerFeatureFlag("owner-portal-unit-min-rate")
    const minRateEnabled = isFeatureFlagEnabled(minRateFeatureFlag)
    const marketRateComparisonIsEnabled = isFeatureFlagEnabled(
        useOwnerFeatureFlag("market-rate-comparison")
    )
    const rateSimulatorIsEnabled = isFeatureFlagEnabled(
        useOwnerFeatureFlag("revenue-simulator")
    )

    useEffect(() => {
        trackPerformancePageViewed()
    }, [])

    useEffect(() => {
        setShowPreviousYear(false)
        setYear(new Date().getFullYear())
    }, [unitId])

    const onShowPreviousYearChange = useCallback(
        (showPreviousYear: boolean) => {
            showPreviousYear
                ? trackPerformanceChartCompareAddFilter(year)
                : trackPerformanceChartCompareRemoveFilter(year)
            setShowPreviousYear(showPreviousYear)
        },
        [year]
    )

    const owners = unit?.attributes.contract.owners
    const performanceNotAllowed = isPerformanceNotAllowed(owners, contactId)

    const shouldRenderContent =
        !!unitId && !!contactId && !performanceNotAllowed

    const shouldRenderFirstBooking =
        ((!unitId && contactId) || isOnboarding) &&
        !marketRateComparisonIsEnabled
            ? true
            : false

    const revenueData: RevenueData = toRevenueData(
        selectedYearRevenue,
        priorYearRevenue
    )

    const minYear = getPerformanceStartYear({
        acquisitionDate: revenueData.meta.acquisitionUnitIntegrationDate,
        defaultStartYear: revenueData.meta.availablePerformanceYearsRange.start,
    })

    useEffect(() => {
        if (year === minYear && showPreviousYear) {
            setShowPreviousYear(false)
        }
    }, [minYear, showPreviousYear, year])

    const title: MessageDescriptor = dashboardEnabled
        ? {
              id: "Dashboard.title",
              defaultMessage: "Dashboard",
          }
        : {
              id: "Performance.title",
              defaultMessage: "Performance",
          }

    const showAverageStarRating =
        unitReviews.isSuccess &&
        unitReviews.data.meta.rating?.average !== undefined

    const showRateCalculator =
        marketRateComparisonIsEnabled &&
        rateSimulatorIsEnabled &&
        (unit?.attributes.marketCompChartEnabled || false)

    return (
        <>
            <ReferralBanner />
            <OptimizelyFeature feature="dashboard">
                {isEnabled =>
                    isEnabled && (
                        <>
                            <WebinarBanner />
                        </>
                    )
                }
            </OptimizelyFeature>
            <ListLayout
                title={intl.formatMessage(title)}
                size="lg"
                actions={<UnitSelectWrapper />}
                className={classNames(styles["performance-layout"])}
                listItemOptions={{
                    "0": {
                        excludeBottomMargin: minRateEnabled,
                    },
                }}
            >
                <>
                    <OptimizelyFeature feature="owner-portal-survey">
                        {isEnabled => isEnabled && unitId && <RevenuePreferences unitId={unitId} />}
                    </OptimizelyFeature>
                </>
                {minRateEnabled && <UnitMinRateNotification />}
                {!isFixedRent && (
                    <>
                        <OptimizelyFeature feature="core-metrics">
                            {isEnabled => isEnabled && <CoreMetrics />}
                        </OptimizelyFeature>
                    </>
                )}
                {shouldRenderFirstBooking ? (
                    <PerformanceEmptyState />
                ) : shouldRenderContent ? (
                    <OptimizelyFeature feature="market-rate-comparison">
                        {isEnabled => (
                            <div className={styles["chart-wrapper"]}>
                                {isEnabled ? (
                                    <Charts />
                                ) : (
                                    <RevenueChartContext.Provider
                                        value={{
                                            minYear: minYear,
                                            maxYear:
                                                revenueData.meta
                                                    .availablePerformanceYearsRange
                                                    .end,
                                            year: year,
                                            setYear: year => setYear(year),
                                        }}
                                    >
                                        <Chart
                                            data={revenueData}
                                            isFetching={isFetching}
                                            onShowPreviousYearChange={
                                                onShowPreviousYearChange
                                            }
                                            showPreviousYear={showPreviousYear}
                                        />
                                    </RevenueChartContext.Provider>
                                )}
                            </div>
                        )}
                    </OptimizelyFeature>
                ) : performanceNotAllowed ? (
                    <NoUnitStatusPanel
                        panelType={PanelType.PerformanceNotAllowed}
                    />
                ) : (
                    <></>
                )}
                <div className={styles.performanceGroup}>
                    {showRateCalculator && (
                        <Card className={styles.rateCalculator}>
                            <CardContent>
                                <RateCalculatorChart unitId={unitId} />
                            </CardContent>
                        </Card>
                    )}
                    <div
                        className={
                            showRateCalculator
                                ? styles.performanceCards
                                : styles.performanceCardsRow
                        }
                    >
                        <NightsOccupied
                            unitId={unitId}
                            showNoAverageStarRating={!showAverageStarRating}
                        />
                        {unitReviews.isSuccess &&
                            (unitReviews.data.meta.rating?.average ? (
                                <AverageStarRating
                                    average={
                                        unitReviews.data.meta.rating?.average
                                    }
                                    total={unitReviews.data.meta.filteredTotal}
                                />
                            ) : (
                                <NoAverageStarRating />
                            ))}
                    </div>
                </div>
            </ListLayout>
        </>
    )
}
