import {
    useQuery,
    UseQueryResult,
    UseMutationResult,
    useMutation,
} from "react-query"
import type { AxiosError } from "axios"
import { fetchUnitMinRate, UnitMinRateResponse } from "./fetchUnitMinRate"
import {
    postUnitMinRateChange,
    UnitMinRateChangeRequest,
} from "./postUnitMinRateChange"

const useUnitMinRate = (
    unitId: string | undefined | null
): UseQueryResult<UnitMinRateResponse["data"], AxiosError> => {
    return useQuery(
        ["unit-min-rate", unitId],
        ({ signal }) =>
            fetchUnitMinRate({ unitId, signal }).then(
                response => response.data
            ),
        {
            enabled: !!unitId,
            refetchInterval: 1000 * 60 * 60, // 1 hour
            staleTime: 1000 * 60 * 60, // 1 hour
        }
    )
}

const useUnitMinRateChangeMutation = (): UseMutationResult<
    void,
    AxiosError,
    UnitMinRateChangeRequest,
    unknown
> =>
    useMutation<void, AxiosError, UnitMinRateChangeRequest, unknown>(request =>
        postUnitMinRateChange(request)
    )

export { useUnitMinRate, useUnitMinRateChangeMutation }
