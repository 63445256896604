import segmentService from "../segment.service"
import { REVENUE_PREFERENCE } from "./surveyTrackingConstants"

export enum SurveyTrackingActions {
    SHOW = 'show',
    OPEN = 'open',
    STEP = 'step',
    SUBMIT = 'submit'
}

const trackedSteps: Record<number, boolean> = {}

const trackCommonFields = (action: string): void => {
    segmentService.track(REVENUE_PREFERENCE, {
        category: 'owner',
        // eslint-disable-next-line camelcase
        page_version: 'owner_portal',
        action
    })
}

const generateStepAction = (step: number): string => {
    return `${SurveyTrackingActions.STEP}-${step}`
}

export const trackRevenuePreferenceSteps = (step: number) => {
    if (step < 1) {
        throw new Error("Step number must be greater than 0")
    }

    if (!trackedSteps[step]) {
        const action = generateStepAction(step)
        trackCommonFields(action)

        trackedSteps[step] = true
    }
}

export const trackRevenuePreferenceShow = (): void => trackCommonFields(SurveyTrackingActions.SHOW)
export const trackRevenuePreferenceOpen = (): void => trackCommonFields(SurveyTrackingActions.OPEN)
export const trackRevenuePreferenceSubmit = (): void => trackCommonFields(SurveyTrackingActions.SUBMIT)