import { memo, MouseEvent, useCallback } from "react"
import classnames from "classnames"
import SVGIcon from "../SVGIcon"
import {
    HORIZONTAL_ORIENTATION,
    VERTICAL_ORIENTATION,
    VERTICAL_SCROLLABLE,
} from "../../../Constants"
import { MonthPickerNavigationPhrases } from "defaultPhrases"

type Props = {
    onNextYearClick: () => void
    onPrevYearClick: () => void
    orientation: string
} & typeof defaultProps

const defaultProps = {
    orientation: HORIZONTAL_ORIENTATION,
}

const MonthPickerNavigation = ({
    onNextYearClick,
    onPrevYearClick,
    orientation,
}: Props): JSX.Element => {
    const isVertical = orientation === VERTICAL_ORIENTATION
    const isVerticalScrollable = orientation === VERTICAL_SCROLLABLE

    const onPrevClick = useCallback(
        (e: MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation()
            onPrevYearClick()
        },
        [onPrevYearClick]
    )

    const onNextClick = useCallback(
        (e: MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation()
            onNextYearClick()
        },
        [onNextYearClick]
    )

    const navClassNames = classnames("MonthPickerNavigation", {
        "MonthPickerNavigation--horizontal": !isVertical,
        "MonthPickerNavigation--vertical": isVertical,
        "MonthPickerNavigation--vertical-scrollable": isVerticalScrollable,
    })

    return (
        <div className={navClassNames}>
            {!isVerticalScrollable && (
                <button
                    data-testid={"mpn-prev-btn"}
                    className={"MonthPickerNavigation__prev"}
                    onClick={onPrevClick}
                    type="button"
                    aria-label={MonthPickerNavigationPhrases.jumpToPrevYear}
                >
                    <SVGIcon
                        svg="icon-feather-chevron-left"
                        colorName="info"
                        className="w-24 h-24 empty-state__svg"
                    />
                </button>
            )}
            <button
                data-testid={"mpn-next-btn"}
                className="MonthPickerNavigation__next"
                onClick={onNextClick}
                type="button"
                aria-label={MonthPickerNavigationPhrases.jumpToNextYear}
            >
                <SVGIcon
                    svg="icon-feather-chevron-right"
                    colorName="info"
                    className="w-24 h-24 empty-state__svg"
                />
            </button>
        </div>
    )
}

MonthPickerNavigation.defaultProps = defaultProps
export default memo(MonthPickerNavigation)
