import { JSONAPIDocument, Unit } from "@vacasa/owner-api-models"
import { authAxios } from "../../api/authAxios"

type FetchUnitsRequest = {
    contactId: string
    signal?: AbortSignal
}

type FetchUnitRequest = {
    unitId: string
    contactId: string
    signal?: AbortSignal
}

export type UnitsResponse = JSONAPIDocument<Unit, []>

const fetchUnit = ({
    unitId,
    contactId,
    signal,
}: FetchUnitRequest): Promise<JSONAPIDocument<Unit>> =>
    authAxios
        .get<JSONAPIDocument<Unit>>(`/v1/owners/${contactId}/units/${unitId}`, {
            signal,
        })
        .then(response => response.data)

const fetchUnits = ({
    contactId,
    signal,
}: FetchUnitsRequest): Promise<UnitsResponse> =>
    authAxios
        .get<UnitsResponse>(`/v1/owners/${contactId}/units`, { signal })
        .then(response => response.data)

export { fetchUnit, fetchUnits }
