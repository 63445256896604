import { JSONAPIResource, Unit } from "@vacasa/owner-api-models"
import { OnboardingStatus } from ".."

/**
 * Returns the units onboarding status as an enum
 * @param unit
 */
export const getUnitOnboardingStatus = (
    unit?: JSONAPIResource<Unit> | null
): OnboardingStatus => {
    switch (unit?.attributes.status.onboarding) {
        case OnboardingStatus.None:
            return OnboardingStatus.None
        case OnboardingStatus.InformationRequired:
            return OnboardingStatus.InformationRequired
        case OnboardingStatus.UnderReview:
            return OnboardingStatus.UnderReview
        case OnboardingStatus.PreparingListing:
        default:
            return OnboardingStatus.PreparingListing
    }
}
