import { BottomSheet } from "react-spring-bottom-sheet"
import styles from "./CancelHoldBottomSheet.module.scss"
import { useIntl } from "react-intl"
import { NewButton } from "lib/components/Button"
import { Secondary } from "lib/components/Buttons/BaseButton"
import { useNavigate } from "react-router-dom"

interface CancelHoldBottomSheetProps {
    handleFlyoutClose: () => void
    onCancelClick: () => void
}

const CancelHoldBottomSheet: React.FC<CancelHoldBottomSheetProps> = ({
    handleFlyoutClose,
    onCancelClick,
}) => {
    const intl = useIntl()
    const navigate = useNavigate()

    const onDeleteClick = () => {
        handleFlyoutClose()
        navigate({
            pathname: "/calendar",
        })
    }

    return (
        <BottomSheet
            className={styles.cancelHold}
            initialFocusRef={false}
            open={true}
            onDismiss={() => null}
        >
            <div className={styles.title}>
                {intl.formatMessage({
                    id: "CalendarPage.flyout.cancelOwnerHold",
                    defaultMessage: "Cancel owner hold",
                })}
            </div>
            <div className={styles.text}>
                {intl.formatMessage({
                    id: "CalendarPage.flyout.cancelAreYouSure",
                    defaultMessage:
                        "Are you sure you want to cancel? Your hold has not been scheduled.",
                })}
            </div>
            <NewButton
                className={styles.deleteButton}
                color="inherit"
                variant="primary-alert"
                onClick={onDeleteClick}
            >
                {intl.formatMessage({
                    id: "CalendarPage.flyout.deleteOwnerHold",
                    defaultMessage: "Delete owner hold",
                })}
            </NewButton>
            <Secondary
                className={styles.cancelButton}
                typeOf={"link"}
                onClick={onCancelClick}
            >
                {intl.formatMessage({
                    id: "CalendarPage.flyout.cancel",
                    defaultMessage: "Cancel",
                })}
            </Secondary>
        </BottomSheet>
    )
}

export { CancelHoldBottomSheet }
