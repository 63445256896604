import { MOBILE_BREAKPOINT } from "Constants"
import { useEffect, useState } from "react"

const useIsMobile = (customMobileBreakpoint?: number) => {
    const [isMobileWidth, setIsMobileWidth] = useState(false)
    useEffect(() => {
        const handleResize = () => {
            const isMobileResize =
                window.innerWidth <=
                (customMobileBreakpoint ?? MOBILE_BREAKPOINT)
            setIsMobileWidth(isMobileResize)
        }
        window.addEventListener("resize", handleResize)

        handleResize()
        return () => window.removeEventListener("resize", handleResize)
    }, [customMobileBreakpoint])

    return isMobileWidth
}

export default useIsMobile
