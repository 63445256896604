import React, { InputHTMLAttributes, useState } from "react"
import styles from "./RadioButton.module.scss"
import classnames from "classnames"
import { uniqueId } from "lodash"

type InputProps = InputHTMLAttributes<HTMLInputElement>

type RadioButtonProps = {
    children: string
} & Omit<InputProps, "type">

const RadioButton: React.FC<RadioButtonProps> = ({
    className,
    children,
    ...rest
}) => {
    const [id] = useState(uniqueId("radio-button"))
    return (
        <div className={classnames(styles.radioButton, className)}>
            <input
                className={styles.radioButtonInput}
                type="radio"
                {...rest}
                id={id}
            />
            <label className={styles.radioButtonLabel} htmlFor={id}>
                {children}
            </label>
        </div>
    )
}

export { RadioButton }
