import { Secondary } from "lib/components/Buttons/BaseButton"
import styles from "./StatementRefresh.module.scss"
import { FormattedMessage } from "react-intl"
import { ButtonLoader } from "lib/components/Loader/ButtonLoader/ButtonLoader"
import { ReactComponent as RefreshSVG } from "../../../assets/refresh-cw.svg"
import { ExtraInformationTooltip } from "views/profile/ExtraInformationTooltip/ExtraInformationTooltip"
import { useRefreshStatementMutation } from "hooks/refresh-statement/useRefreshStatement"
import { useContactId } from "hooks/user"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { ReactComponent as CheckCircle } from "../../../assets/check-circle.svg"
import { ReactComponent as CloseCircle } from "../../../assets/icon-close-circle.svg"
import { useIsEmployee } from "hooks/user"

/**
 * Display statement refresh button and tooltip for employee login
 */
export const StatementRefresh: React.FC = (): JSX.Element => {
    const isEmployee = useIsEmployee()
    const [buttonDisabled, setButtonDisabled] = useState<boolean>(false)
    const [displayRequestedButton, setDisplayRequestedButton] =
        useState<boolean>(false)
    const [displayErrorButton, setDisplayErrorButton] = useState<boolean>(false)
    const contactId = useContactId() ?? ""
    const refreshStatementMutation = useRefreshStatementMutation()
    const location = useLocation()
    const [year, setYear] = useState(
        Number(location.pathname.split("statements")[1]?.split("/")[1])
    )
    const [month, setMonth] = useState(
        Number(location.pathname.split("statements")[1]?.split("/")[2])
    )

    useEffect(() => {
        setButtonDisabled(false)
        setDisplayRequestedButton(false)
        setDisplayErrorButton(false)
        setYear(Number(location.pathname.split("statements")[1]?.split("/")[1]))
        setMonth(
            Number(location.pathname.split("statements")[1]?.split("/")[2])
        )
    }, [location])

    useEffect(() => {
        if (refreshStatementMutation.isError) {
            setButtonDisabled(false)
            setDisplayErrorButton(true)
        }
        if (refreshStatementMutation.isSuccess) {
            setButtonDisabled(true)
            setDisplayRequestedButton(true)
        }
    }, [
        refreshStatementMutation.data,
        refreshStatementMutation.isError,
        refreshStatementMutation.isLoading,
        refreshStatementMutation.isSuccess,
    ])

    return (
        <>
            {isEmployee && (
                <div className={styles.refreshFrame}>
                    <Secondary
                        className={styles.refreshButton}
                        onClick={() => {
                            refreshStatementMutation.mutate({
                                contactId,
                                type: isNaN(month) ? "yearly" : "monthly",
                                year,
                                month: isNaN(month) ? undefined : month,
                            })
                        }}
                        isLoading={refreshStatementMutation.isLoading}
                        disabled={buttonDisabled}
                        loader={
                            <ButtonLoader
                                indicator={"dot"}
                                indicatorCount={3}
                                animation={"blink"}
                            />
                        }
                        ariaLabel={"Click to Refresh Statement"}
                    >
                        {displayRequestedButton ? (
                            <div className={styles.refreshText}>
                                <CheckCircle
                                    className={styles.refreshRequestedIcon}
                                />
                                <FormattedMessage
                                    id="StatementRefresh.requested"
                                    defaultMessage="Requested"
                                />
                            </div>
                        ) : displayErrorButton ? (
                            <div className={styles.refreshText}>
                                <CloseCircle
                                    className={styles.refreshErrorIcon}
                                />
                                <FormattedMessage
                                    id="StatementRefresh.retry"
                                    defaultMessage="Retry"
                                />
                            </div>
                        ) : (
                            <div className={styles.refreshText}>
                                <RefreshSVG className={styles.refreshIcon} />
                                <FormattedMessage
                                    id="StatementRefresh.refresh"
                                    defaultMessage="Refresh"
                                />
                            </div>
                        )}
                    </Secondary>
                    <div className={styles.refreshToolTip}>
                        <ExtraInformationTooltip
                            title={"STATEMENT DATA REFRESH"}
                        >
                            <FormattedMessage
                                id="StatementRefresh.tooltips"
                                defaultMessage="Statements are cached when it was generated every month. Clicking refresh button will send out an request to refresh the current statement displayed in this page if there's any update. It's expected that it will take about 1 minute to process. Please refresh the page after 1 minute to see the latest changes. This feature is only available for employee login. It works for both monthly and yearly statements."
                            />
                        </ExtraInformationTooltip>
                    </div>
                </div>
            )}
        </>
    )
}
