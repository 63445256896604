import { HOMEOWNER_ACCOUNTS_EMAIL } from "./../../Constants"

const shared = {
    ownerAccountsContactEmail: HOMEOWNER_ACCOUNTS_EMAIL,
    ownerAccountsPhoneNumber: "+1 971 254 3001",
    productTour: {
        next: "Next",
        previous: "Previous",
        finish: "Finish",
    },
}

export default shared
