import { Referral } from "@vacasa/owner-api-models"
import { AxiosError } from "axios"
import { useReferralMutation } from "hooks/referral"
import Modal from "lib/components/Modal/Modal"
import React, { useCallback, useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import { UseMutationResult } from "react-query"
import {
    trackReferAnotherFriend,
    trackReferralFormClosed,
    trackReferralSuccessClosed,
} from "services/segment/referral/referralTracking"
import { statesNotAvailableForReferral } from "utils/array/statesArray"
import { validateEmail } from "utils/calendar/util"
import { ReactComponent as ReferralHandSVG } from "../../../../assets/referral-hand-white.svg"
import { ErrorScreenBody } from "./ErrorScreenBody"
import { ReferralForm } from "./ReferralForm"
import styles from "./ReferralModal.module.scss"
import { SuccessScreenBody } from "./SuccessScreenBody"

interface ReferralModalProps {
    showReferralModal: (shouldShowReferralModal: boolean) => void
}

const disableSubmit = (
    referral: Referral,
    showWarning: boolean,
    acknowledgement: boolean
): boolean =>
    !referral.firstName ||
    !referral.lastName ||
    !referral.street ||
    !referral.email ||
    (showWarning && !acknowledgement)

const handleSegmentTrackingOnClose = (
    referralMutation: UseMutationResult<number, AxiosError, Referral, void>
): void => {
    if (referralMutation.isSuccess) {
        trackReferralSuccessClosed()
    } else {
        trackReferralFormClosed()
    }
}

const ReferralModal: React.FC<ReferralModalProps> = props => {
    const { showReferralModal } = props
    const referralMutation = useReferralMutation()

    const [referral, setReferral] = useState<Referral>({
        firstName: "",
        lastName: "",
        street: "",
        city: "",
        state: "",
        country: "",
        zipCode: "",
        email: "",
        phone: "",
    })

    const [shouldDisableSubmit, setShouldDisableSubmit] =
        useState<boolean>(true)
    const [showWarning, setShowWarning] = useState<boolean>(false)
    const [emailValidationError, setEmailValidationError] =
        useState<boolean>(false)
    const [acknowledgement, setAcknowledgement] = useState<boolean>(false)
    const [showErrorScreen, setShowErrorScreen] = useState<boolean>(false)

    const handleAcknowledgement = (option: boolean) => {
        setAcknowledgement(option)
    }

    const reset = () => {
        setReferral({
            firstName: "",
            lastName: "",
            street: "",
            city: "",
            state: "",
            country: "",
            zipCode: "",
            email: "",
            phone: "",
        })
        setShouldDisableSubmit(true)
        setShowWarning(false)
        setAcknowledgement(false)
        setEmailValidationError(false)
        setShowErrorScreen(false)
        referralMutation.reset()

        trackReferAnotherFriend()
    }

    const handleReferral = (key: string, value: string) => {
        setReferral(prevState => ({ ...prevState, [key]: value }))
    }

    const handleEmailValidationError = (email?: string) => {
        setEmailValidationError(email ? !validateEmail(email) : false)
    }

    const handleShowErrorScreen = (showError: boolean) => {
        setShowErrorScreen(showError)
    }

    useEffect(() => {
        if (statesNotAvailableForReferral.includes(referral.state)) {
            setShowWarning(true)
        } else {
            setShowWarning(false)
        }
        if (referralMutation.isError) {
            setShowErrorScreen(true)
        }
    }, [referral.state, referralMutation.isError])

    useEffect(() => {
        const submitDisabled = disableSubmit(
            referral,
            showWarning,
            acknowledgement
        )
        setShouldDisableSubmit(submitDisabled)
    }, [
        acknowledgement,
        referral,
        referral.city,
        referral.country,
        referral.email,
        referral.firstName,
        referral.lastName,
        referral.state,
        referral.street,
        showWarning,
    ])

    const handleClose = useCallback(() => {
        handleSegmentTrackingOnClose(referralMutation)
        showReferralModal(false)
    }, [referralMutation, showReferralModal])

    return (
        <Modal
            onCloseHandler={handleClose}
            closeButtonStyleOverride={styles.close}
            modalPanelStyleOverride={styles.modalContainer}
        >
            <div className={styles.formHeader}>
                <ReferralHandSVG className={styles.referralHandIcon} />
                <div className={styles.headerText}>
                    <div
                        className={styles.headerTitle}
                        data-testid="referral-title"
                    >
                        <FormattedMessage
                            id="OwnerReferral.title"
                            defaultMessage="Share the service you love. Get rewarded."
                        />
                    </div>
                    <div className={styles.headerDescription}>
                        <FormattedMessage
                            id="OwnerReferral.headerDescription"
                            defaultMessage="Refer a friend: you get $500, they get $500."
                        />
                    </div>
                </div>
            </div>
            {referralMutation.isSuccess ? (
                <SuccessScreenBody
                    showReferralModal={showReferralModal}
                    reset={reset}
                />
            ) : showErrorScreen ? (
                <ErrorScreenBody
                    referral={referral}
                    referralMutation={referralMutation}
                    handleReferral={handleReferral}
                    handleAcknowledgement={handleAcknowledgement}
                    handleShowErrorScreen={handleShowErrorScreen}
                />
            ) : (
                <ReferralForm
                    shouldDisableSubmit={shouldDisableSubmit}
                    referral={referral}
                    showWarning={showWarning}
                    handleReferral={handleReferral}
                    emailValidationError={emailValidationError}
                    handleEmailValidationError={handleEmailValidationError}
                    handleAcknowledgement={handleAcknowledgement}
                    referralMutation={referralMutation}
                />
            )}
        </Modal>
    )
}

export { ReferralModal }
