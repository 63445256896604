/* eslint-disable camelcase */
// Event
export const OWNER_HOLD_EDITED_EVENT = "Owner Hold Edited"
export const GUEST_INFO_EVENT = "Guest Information"
export const OWNER_HOLD_ELOCK_VIEWED_EVENT = "Owner Hold Elock Viewed"
export const COST_OF_HOLD_EVENT = "Cost of Hold"
export const OWNER_HOLD_NEXT_BUTTON_CLICKED = "Owner Hold Next Button - Clicked"
export const OWNER_HOLD_SAVE_BUTTON_CLICKED = "Owner Hold Save Button - Clicked"
export const OWNER_HOLD_CANCEL_BUTTON_CLICKED =
    "Owner Hold Cancel Button - Clicked"
export const OWNER_HOLD_EDIT_BUTTON_CLICKED = "Owner Hold Edit Button - Clicked"
export const OWNER_HOLD_CREATE_HOLD_BUTTON_CLICKED =
    "Owner Hold Create Hold Button - Clicked"

// Action
export const OWNER_HOLD_CREATED_ACTION = "create"
export const OWNER_HOLD_REMOVED_ACTION = "remove"
export const OWNER_HOLD_UPDATED_ACTION = "update"
export const OWNER_HOLD_GUEST_ADD_ACTION = "added"
export const OWNER_HOLD_GUEST_REMOVE_ACTION = "removed"
export const OWNER_HOLD_GUEST_UPDATE_ACTION = "update"
export const COST_OF_HOLD_PREVIEW_ACTION = "preview"
