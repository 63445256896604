import FullScreenLoader from "lib/components/Loader/FullScreenLoader"
import { observer } from "mobx-react"
import { useEffect } from "react"
import { useRootService } from "services"
import { trackLogoutPageViewed } from "services/segment/logout/logoutTracking"

const Logout: React.FC = () => {
    const rootService = useRootService()

    useEffect(() => {
        trackLogoutPageViewed()
        rootService.logout()
    }, [rootService])

    return <FullScreenLoader />
}

export default observer(Logout)
