const LOCAL_STORAGE_TEST_KEY = "LOCAL_STORAGE_TEST"

class StorageService {
    private storage: Map<string, string> = new Map()
    private session: Map<string, string> = new Map()

    private localStorageEnabled: boolean
    private sessionStorageEnabled: boolean

    constructor() {
        this.localStorageEnabled = this.checkIfLocalStorageIsAvailable()
        this.sessionStorageEnabled = this.checkifSessionStorageIsAvailable()
    }

    localStorage = {
        clear: () => {
            if (this.localStorageEnabled) {
                return window.localStorage.clear()
            }
            this.storage.clear()
        },
        getItem: (key: string) => {
            if (this.localStorageEnabled) {
                return window.localStorage.getItem(key)
            }
            return this.storage.get(key) ?? null
        },
        removeItem: (key: string) => {
            if (this.localStorageEnabled) {
                return window.localStorage.removeItem(key)
            }
            this.storage.delete(key)
        },
        setItem: (key: string, value: string) => {
            if (this.localStorageEnabled) {
                return window.localStorage.setItem(key, value)
            }
            this.storage.set(key, value)
        },
        keys: (): string[] => {
            if (this.localStorageEnabled) {
                return Object.keys({ ...window.localStorage })
            }
            return Array.from(this.storage.keys())
        },
    }

    sessionStorage = {
        clear: () => {
            if (this.sessionStorageEnabled) {
                return window.sessionStorage.clear()
            }
            this.session.clear()
        },
        getItem: (key: string) => {
            if (this.sessionStorageEnabled) {
                return window.sessionStorage.getItem(key)
            }
            return this.session.get(key) ?? null
        },
        removeItem: (key: string) => {
            if (this.sessionStorageEnabled) {
                return window.sessionStorage.removeItem(key)
            }
            this.session.delete(key)
        },
        setItem: (key: string, value: string) => {
            if (this.sessionStorageEnabled) {
                return window.sessionStorage.setItem(key, value)
            }
            this.session.set(key, value)
        },
    }

    private checkIfLocalStorageIsAvailable = () => {
        try {
            window.localStorage.setItem(LOCAL_STORAGE_TEST_KEY, "1")
            window.localStorage.getItem(LOCAL_STORAGE_TEST_KEY)
            window.localStorage.removeItem(LOCAL_STORAGE_TEST_KEY)
            return true
        } catch {
            return false
        }
    }

    private checkifSessionStorageIsAvailable = () => {
        try {
            window.sessionStorage.setItem(LOCAL_STORAGE_TEST_KEY, "1")
            window.sessionStorage.getItem(LOCAL_STORAGE_TEST_KEY)
            window.sessionStorage.removeItem(LOCAL_STORAGE_TEST_KEY)
            return true
        } catch {
            return false
        }
    }
}

const storageService = new StorageService()

export default storageService
