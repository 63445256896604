import { CheckCircle, ChevronRight, Shield } from "react-feather"
import { FormattedMessage, useIntl } from "react-intl"
import { ListImage, ListItem, ListTitle } from "../List"
import { Stack } from "../Stack/Stack"

import { JSONAPIDocument, UnitProgram } from "@vacasa/owner-api-models"
import {
    HOMEOWNER_ACCOUNTS_EMAIL,
    RENTAL_INSURANCE_GW_URL,
    RENTAL_INSURANCE_URL,
} from "Constants"
import classNames from "classnames"
import { isAfter, isBefore, parseISO } from "date-fns"
import { GeneralError } from "lib/components/GeneralError/GeneralError"
import { useState } from "react"
import { trackRentalInsuranceLearnMoreClicked } from "services/segment/homeInfo/homeInfoTracking"
import { Spinner } from "../../../../components/Spinner"
import { useUnitPrograms } from "../../../../hooks/unit-programs"
import styles from "./RentalInsurance.module.scss"
import { isEnrolled } from "utils/programs/programsUtil"
type RentalInsuranceProps = {
    unitId: string
    isGuestworksUnit?: boolean
}

const rentalInsuranceContact = {
    email: HOMEOWNER_ACCOUNTS_EMAIL,
    phoneNumber: "+1 971 254 3001",
} as const

const MailToContact = (): JSX.Element => (
    <a className={styles.email} href={`mailto:${rentalInsuranceContact.email}`}>
        {rentalInsuranceContact.email}
    </a>
)
const TelContact = (): JSX.Element => (
    <a href={`tel:${rentalInsuranceContact.phoneNumber}`}>
        {rentalInsuranceContact.phoneNumber}
    </a>
)

/**
 *
 * @param programs Rental Insurance Programs
 * @param enrolledAt The date to check enrollment
 *
 * @returns Are any of the rental insurance programs enrolled at enrolledAt
 */
const anyEnrolled = (
    programs: JSONAPIDocument<UnitProgram, []>["data"],
    enrolledAt: Date
): boolean => {
    return (
        programs
            // Can remove once query parameters on owner api allow filtering by isSetup https://github.com/Vacasa/owner-api/pull/609
            .filter(({ attributes }) => !attributes.isSetup)
            // Can remove once query parameters on owner api allow filtering by startDate https://github.com/Vacasa/owner-api/pull/609
            .filter(
                ({ attributes: { startDate } }) =>
                    startDate && isAfter(enrolledAt, parseISO(startDate))
            )
            // Can remove once query parameters on owner api allow filtering by endDate https://github.com/Vacasa/owner-api/pull/609
            .filter(({ attributes: { endDate } }) => {
                // endDate is not provided means that the program has no end date and keeps goign
                if (typeof endDate !== "string") return true
                return isBefore(enrolledAt, parseISO(endDate))
            })
            .some(({ attributes }) => isEnrolled(attributes) === "enrolled")
    )
}

const isProtectionsProgramEnrolled = (
    query: ReturnType<typeof useUnitPrograms>,
    date: Date
) => {
    return query.isSuccess ? anyEnrolled(query.data.data, date) : undefined
}

export const FullServiceEnrolledDescription: React.FC<{
    isGuestworksUnit: boolean
}> = ({ isGuestworksUnit }) => {
    if (isGuestworksUnit) {
        return (
            <FormattedMessage
                id="Guestworks.protectionProgram.enrolledDescription"
                defaultMessage="You are enrolled with Accommodations Protection Program."
            />
        )
    }

    return (
        <FormattedMessage
            id="HomeInfo.protectionProgram.enrolledDescription"
            defaultMessage="You are enrolled with Accommodations Protection Program. To change this, call {phoneNumber} or email {email}."
            values={{
                phoneNumber: <TelContact />,
                email: <MailToContact />,
            }}
        />
    )
}

export const FullServiceNotEnrolledDescription: React.FC<{
    isGuestworksUnit: boolean
}> = ({ isGuestworksUnit }) => {
    if (isGuestworksUnit) {
        return (
            <FormattedMessage
                id="Guestworks.protectionProgram.notEnrolledDescription"
                defaultMessage="You are not enrolled with Accommodations Protection Program."
            />
        )
    }

    return (
        <FormattedMessage
            id="HomeInfo.protectionProgram.notEnrolledDescription"
            defaultMessage="You are not enrolled with Accommodations Protection Program. If you want to learn more about enrolling in this program, contact {email}."
            values={{
                phoneNumber: <TelContact />,
                email: <MailToContact />,
            }}
        />
    )
}

const RentalInsurance = ({
    unitId,
    isGuestworksUnit = false,
}: RentalInsuranceProps): JSX.Element => {
    const unitRentalProgramsQuery = useUnitPrograms(unitId, "insurance")
    const [now] = useState(new Date())
    const enrolled = isProtectionsProgramEnrolled(unitRentalProgramsQuery, now)
    const intl = useIntl()

    if (unitRentalProgramsQuery.isError) {
        return (
            <ListItem>
                <div className={styles.insuranceStatusErrorState}>
                    <ListImage
                        aria-hidden
                        fallbackIcon={<Shield size={20} />}
                        className={styles.icon}
                    />
                    <ListTitle className={styles.listTitle}>
                        <FormattedMessage
                            id="HomeInfo.protectionProgram.title"
                            defaultMessage="Accommodations Protection Program"
                        />
                    </ListTitle>
                    <GeneralError
                        errorMessage={intl.formatMessage({
                            id: "HomeInfo.protectionProgram.unableToLoadProgramStatus",
                            defaultMessage:
                                "We were unable to load your rental program status.",
                        })}
                        customContainerStyle={styles.insuranceStatusError}
                    />
                </div>
            </ListItem>
        )
    }

    return (
        <ListItem>
            <ListImage aria-hidden fallbackIcon={<Shield size={20} />} />

            <div className={styles.information}>
                <Stack flex={1}>
                    <ListTitle className={styles.listTitle}>
                        <FormattedMessage
                            id="HomeInfo.protectionProgram.title"
                            defaultMessage="Accommodations Protection Program"
                        />
                        {unitRentalProgramsQuery.isSuccess && enrolled && (
                            <CheckCircle
                                className={classNames(
                                    styles.titleIcon,
                                    styles.titleIconLarge
                                )}
                            />
                        )}
                    </ListTitle>

                    {unitRentalProgramsQuery.isLoading && <Spinner />}
                    <p className={styles.enrolled}>
                        {unitRentalProgramsQuery.isSuccess && enrolled && (
                            <FormattedMessage
                                id="HomeInfo.protectionProgram.enrolled"
                                defaultMessage="Enrolled"
                            />
                        )}
                        {unitRentalProgramsQuery.isSuccess && !enrolled && (
                            <FormattedMessage
                                id="HomeInfo.protectionProgram.notEnrolled"
                                defaultMessage="Not enrolled"
                            />
                        )}
                    </p>
                </Stack>
                {unitRentalProgramsQuery.isSuccess && enrolled && (
                    <Stack flex={1.3} gap="10px">
                        <p className={styles.description}>
                            <FullServiceEnrolledDescription
                                isGuestworksUnit={isGuestworksUnit}
                            />
                        </p>

                        <a
                            href={
                                isGuestworksUnit
                                    ? RENTAL_INSURANCE_GW_URL
                                    : RENTAL_INSURANCE_URL
                            }
                            target="_blank"
                            rel="noreferrer"
                            className={styles.learnMoreLink}
                            onClick={trackRentalInsuranceLearnMoreClicked}
                        >
                            <FormattedMessage
                                id="HomeInfo.protectionProgram.learnMore"
                                defaultMessage="Learn more"
                                description="Link to external Vacasa documentation about the rental insurance program"
                            />
                            <ChevronRight aria-hidden size={16} />
                        </a>
                    </Stack>
                )}
                {unitRentalProgramsQuery.isSuccess && !enrolled && (
                    <Stack flex={1.7} gap="10px">
                        <p className={styles.description}>
                            <FullServiceNotEnrolledDescription
                                isGuestworksUnit={isGuestworksUnit}
                            />
                        </p>

                        <a
                            href={
                                isGuestworksUnit
                                    ? RENTAL_INSURANCE_GW_URL
                                    : RENTAL_INSURANCE_URL
                            }
                            target="_blank"
                            rel="noreferrer"
                            className={styles.learnMoreLink}
                            onClick={trackRentalInsuranceLearnMoreClicked}
                        >
                            <FormattedMessage
                                id="HomeInfo.protectionProgram.learnMore"
                                defaultMessage="Learn more"
                                description="Link to external Vacasa documentation about the rental insurance program"
                            />
                            <ChevronRight aria-hidden size={16} />
                        </a>
                    </Stack>
                )}
            </div>
        </ListItem>
    )
}

export { RentalInsurance }
