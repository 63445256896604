import { FC, useEffect, useRef, useState } from "react"
import { TaxInformationFormProvider } from "./contexts"
import {
    useTaxInformationFlyoutAPI,
    useTaxInformationFlyoutState,
    useTaxInformationForm,
} from "./hooks"
import { Flyout } from "core/components/flyout/flyout"
import classNames from "classnames"
import {
    useMailingAddress,
    useW8UpdateMutation,
    useW9UpdateMutation,
} from "hooks/finances"
import { isUndefined } from "lodash"
import { NewButton as Button } from "lib/components/Button"
import { W8 } from "./W8"
import { TaxFormSelect } from "./TaxFormSelect"
import { W9 } from "./W9/W9"
import { defaultW9Data } from "./W9/constants"
import { toW9Request, toMailingAddress } from "./utils"
import { FormRef, TaxFormTypes, W9Steps, W8Steps } from "./types"
import styles from "./TaxInformationFlyout.module.scss"
import { useToastNotification } from "lib/components/ToastNotification"
import { Alert } from "components/Alert"
import { AlertCircle, X } from "react-feather"
import { useIsEmployee } from "hooks/user"
import { EmployeeAlert } from "views/profile/EmployeeAlert"
import { SupportedPages } from "views/profile/EmployeeAlert/EmployeeAlert"

export const TaxInformationFlyout: FC = () => {
    const { modalOpen } = useTaxInformationFlyoutState()
    if (!modalOpen) return null
    return (
        <TaxInformationFormProvider>
            <TaxInformationFlyoutContent />
        </TaxInformationFormProvider>
    )
}

const TaxInformationFlyoutContent = () => {
    const w9Ref = useRef<FormRef>(null)
    const { close } = useTaxInformationFlyoutAPI()
    const [selectedTaxForm, setSelectedTaxForm] = useState<TaxFormTypes>()
    const [step, setStep] = useState<W9Steps | W8Steps | undefined>(undefined)
    const { data, setData } = useTaxInformationForm()
    const mailingAddress = useMailingAddress()
    const { addToastNotification } = useToastNotification()
    const isEmployee = useIsEmployee()
    const {
        mutate: mutateW8,
        isLoading: isLoadingW8Mutation,
        isError: isErrorW8,
        reset: resetW8Mutation,
    } = useW8UpdateMutation()
    const {
        mutate: mutateW9,
        isError: isErrorW9,
        isLoading: isLoadingW9Mutation,
        reset: resetW9Mutation,
    } = useW9UpdateMutation()

    useEffect(() => {
        if (!mailingAddress.isSuccess) return
        setData(prev => ({
            ...prev,
            mailingAddress: toMailingAddress(
                mailingAddress.data.attributes.address
            ),
        }))
    }, [mailingAddress.isSuccess, mailingAddress.data, setData])

    const taxCardClick = (taxForm: TaxFormTypes) => {
        // reset w9 data if needed, populate mailingAdress with W9Address from finances
        if (
            selectedTaxForm === TaxFormTypes.w9 &&
            selectedTaxForm !== taxForm
        ) {
            setData({
                ...defaultW9Data,
                mailingAddress: toMailingAddress(
                    mailingAddress.data?.attributes.address
                ),
            })
        }
        setSelectedTaxForm(taxForm)
    }

    const onNextClick = async () => {
        if (selectedTaxForm === TaxFormTypes.w9) {
            const valid = (await w9Ref.current?.isFormValid()) ?? false

            switch (step) {
                case undefined:
                    setStep(W9Steps.TaxClassification)
                    break
                case W9Steps.TaxClassification: {
                    if (!valid) return
                    setStep(W9Steps.TaxInfo)
                    break
                }
                case W9Steps.TaxInfo: {
                    if (!valid) return
                    setStep(W9Steps.MailingAddress)
                    break
                }
                case W9Steps.MailingAddress: {
                    if (!valid) return
                    setStep(W9Steps.AgreeAndSign)
                    break
                }
                case W9Steps.AgreeAndSign:
                    if (!valid) return
                    mutateW9(toW9Request(data), {
                        onSuccess: () => {
                            addToastNotification({
                                content:
                                    "Your tax information have been successfully saved!",
                            })
                            close()
                        },
                    })
                    break
            }
        } else if (selectedTaxForm === TaxFormTypes.w8) {
            setStep(W8Steps.Details)
        }
    }

    const onMarkAsCompleteClick = () => {
        mutateW8(undefined, {
            onSuccess: () => {
                addToastNotification({
                    content:
                        "Your tax information have been successfully saved!",
                })
                close()
            },
        })
    }

    const onBackClick = () => {
        switch (step) {
            case W9Steps.TaxClassification: {
                setStep(undefined)
                break
            }
            case W9Steps.TaxInfo: {
                setStep(W9Steps.TaxClassification)
                break
            }
            case W9Steps.MailingAddress: {
                setStep(W9Steps.TaxInfo)
                break
            }
            case W9Steps.AgreeAndSign: {
                setStep(W9Steps.MailingAddress)
                break
            }
        }
    }

    const onDismissError = () => {
        if (selectedTaxForm === "w8") resetW8Mutation()
        if (selectedTaxForm === "w9") resetW9Mutation()
    }

    return (
        <div className={classNames(styles.taxInformationFlyout, "antialiased")}>
            <Flyout onClose={close}>
                <form className={styles.form}>
                    <header className={styles.header}>
                        <div className={styles.heading}>Account</div>
                        <span className={styles.title}>
                            Add tax information
                        </span>
                    </header>

                    <div className={styles.content}>
                        {isEmployee && (
                            <div style={{ margin: "0 0 16px 0" }}>
                                <EmployeeAlert
                                    page={SupportedPages.TaxInformation}
                                />
                            </div>
                        )}

                        {(!selectedTaxForm || isUndefined(step)) && (
                            <TaxFormSelect
                                onClick={taxCardClick}
                                selectedTaxForm={selectedTaxForm}
                            />
                        )}

                        {selectedTaxForm === TaxFormTypes.w8 &&
                            step === W8Steps.Details && <W8 />}

                        {selectedTaxForm === TaxFormTypes.w9 &&
                            !isUndefined(step) && (
                                <W9 step={step} ref={w9Ref} />
                            )}
                    </div>
                    <footer className={styles.footer}>
                        {(isErrorW8 || isErrorW9) && (
                            <FormSubmitError onDismiss={onDismissError} />
                        )}
                        <div className={styles.buttonContainer}>
                            {selectedTaxForm && step !== undefined && (
                                <Button
                                    onClick={onBackClick}
                                    variant="secondary"
                                    type="button"
                                >
                                    Back
                                </Button>
                            )}

                            {/* W8 Complete Button */}
                            {selectedTaxForm === TaxFormTypes.w8 &&
                                step === W8Steps.Details && (
                                    <Button
                                        disabled={
                                            !selectedTaxForm ||
                                            isLoadingW8Mutation
                                        }
                                        variant="primary"
                                        type="button"
                                        onClick={onMarkAsCompleteClick}
                                        loading={isLoadingW8Mutation}
                                    >
                                        Mark as Complete
                                    </Button>
                                )}

                            {(selectedTaxForm !== TaxFormTypes.w8 ||
                                step === undefined) && (
                                <Button
                                    disabled={
                                        !selectedTaxForm ||
                                        isLoadingW9Mutation ||
                                        isEmployee
                                    }
                                    loading={isLoadingW9Mutation}
                                    variant="primary"
                                    type="button"
                                    onClick={onNextClick}
                                >
                                    {step !== W9Steps.AgreeAndSign && "Next"}
                                    {step === W9Steps.AgreeAndSign &&
                                        "Agree & Submit"}
                                </Button>
                            )}
                        </div>

                        <Button variant="white" onClick={close} type="button">
                            Cancel
                        </Button>
                    </footer>
                </form>
            </Flyout>
        </div>
    )
}

type FormSubmitErrorProps = {
    onDismiss?: () => void
}
const FormSubmitError: FC<FormSubmitErrorProps> = ({ onDismiss }) => (
    <div className={styles.formAlert}>
        <Alert variant="alertness">
            <Alert.Icon icon={AlertCircle} />
            <Alert.Description>
                <span className="type-body-small">
                    Something went wrong and your form was not submitted. Please
                    try again.
                </span>
            </Alert.Description>
            <div onClick={onDismiss}>
                <Alert.Icon icon={X} />
            </div>
        </Alert>
    </div>
)
