/* eslint-disable camelcase */
import { NAVIGATE_TO_ONBOARDING_EVENT } from "../onboarding/onboardingTrackingConstants"
import segmentService from "../segment.service"
import {
    BUTTON_CLICK_EVENT,
    EDIT_PET_FRIENDLY_VIEWED_EVENT,
    EMAIL_LINK_TYPE,
    GUEST_REVIEWS_CLICKED,
    HOME_INFO_BUTTON_CLICK_TYPE,
    HOME_INFO_CLICK_ACTION,
    HOME_INFO_PAGE_PATH,
    INSPECTION_PHOTO_CLICKED,
    LINEN_SERVICE_LEARN_MORE_EVENT,
    LINK_TYPE,
    OWNER_LISTING_VIEWED_EVENT,
    PET_FRIENDLY_LABEL,
    PET_FRIENDLY_LEARN_MORE_LABEL,
    PHONE_NUMBER_TYPE,
    SEE_ALL_SPACES_CLICKED,
    SMS_LINK_TYPE,
    VIEW_ALL_PHOTOS_CLICKED,
} from "./homeInfoConstants"

export const trackSwitchToOnboardingClicked = (): void => {
    segmentService.track(NAVIGATE_TO_ONBOARDING_EVENT, {
        action: HOME_INFO_CLICK_ACTION,
    })
}

export const trackOwnerListingViewedClicked = (): void => {
    segmentService.clickTrack(false, OWNER_LISTING_VIEWED_EVENT)
}

export const trackPetFriendlyClicked = (unit_id: string): void => {
    segmentService.clickTrack(
        {
            type: HOME_INFO_BUTTON_CLICK_TYPE,
            label: PET_FRIENDLY_LABEL,
            unit_id,
        },
        BUTTON_CLICK_EVENT
    )
}

export const trackEditPetFriendlyViewed = (): void => {
    segmentService.clickTrack(false, EDIT_PET_FRIENDLY_VIEWED_EVENT)
}

export const trackPhoneNumberClick = (phone: string) => {
    segmentService.clickTrack({
        type: PHONE_NUMBER_TYPE,
        label: phone,
    })
}

export const trackEmailClick = (email: string) => {
    segmentService.clickTrack({
        type: EMAIL_LINK_TYPE,
        label: email,
    })
}

export const trackSMSClick = (sms: string) => {
    segmentService.clickTrack({
        type: SMS_LINK_TYPE,
        label: sms,
    })
}

/**
 * @deprecated - use trackPhoneNumberClick
 * @param phone
 */
export const trackAccountManagerPhoneNumberClicked = (phone: string): void => {
    segmentService.clickTrack({
        type: PHONE_NUMBER_TYPE,
        label: phone,
    })
}

/**
 * @deprecated - use trackPhoneNumberClick
 * @param ownerAccountPhoneNumber
 */
export const trackOwnerAccountSupportPhoneNumberClicked = (
    ownerAccountPhoneNumber: string
): void => {
    segmentService.clickTrack({
        type: PHONE_NUMBER_TYPE,
        label: ownerAccountPhoneNumber,
    })
}

/**
 * @deprecated - use trackEmailClick
 * @param ownerAccountEmail
 */
export const trackOwnerAccountSupportEmailClicked = (
    ownerAccountEmail: string
): void => {
    segmentService.clickTrack({
        type: EMAIL_LINK_TYPE,
        label: ownerAccountEmail,
    })
}

/**
 * @deprecated - use trackPhoneNumberClick
 * @param formattedPhone
 */
export const trackLocalSupportPhoneNumberClicked = (
    formattedPhone: string
): void => {
    segmentService.clickTrack({
        type: PHONE_NUMBER_TYPE,
        label: formattedPhone,
    })
}

/**
 * @deprecated - use trackEmailClick
 * @param email
 */
export const trackLocalSupportEmailClicked = (email?: string): void => {
    segmentService.clickTrack({
        type: EMAIL_LINK_TYPE,
        label: email ?? "invalid email provided",
    })
}

/**
 * @deprecated - use trackEmailClick
 * @param email
 */
export const trackAccountManagerEmailClicked = (email: string): void => {
    segmentService.clickTrack({
        type: EMAIL_LINK_TYPE,
        label: email,
    })
}

export const trackPetFriendlyLearnMoreClicked = (): void => {
    segmentService.clickTrack(
        {
            type: LINK_TYPE,
            label: PET_FRIENDLY_LEARN_MORE_LABEL,
        },
        BUTTON_CLICK_EVENT
    )
}

export const trackLinenServiceLearnMoreClicked = (): void => {
    segmentService.clickTrack(false, LINEN_SERVICE_LEARN_MORE_EVENT)
}

export const trackLinenServiceNeedHelpLomEmailClicked = (
    email: string
): void => {
    segmentService.clickTrack({
        type: EMAIL_LINK_TYPE,
        label: email,
    })
}

export const trackLinenServiceNeedHelpLomPhoneClicked = (
    formattedPhone: string
): void => {
    segmentService.clickTrack({
        type: PHONE_NUMBER_TYPE,
        label: formattedPhone,
    })
}

export const trackHomeInfoPageViewed = (): void => {
    segmentService.page({ path: HOME_INFO_PAGE_PATH })
}

export const trackRentalInsuranceLearnMoreClicked = () => {
    segmentService.track("Program Clicked", {
        program: "Accommodations Protection Program",
        option: "Learn More",
    })
}

export const trackInspectionPhotoClicked = (
    unitId: string,
    photoNumber: number
) => {
    segmentService.track(INSPECTION_PHOTO_CLICKED, {
        unitId,
        photoNumber,
    })
}

export const trackViewAllPhotosClicked = (unitId: string) => {
    segmentService.track(VIEW_ALL_PHOTOS_CLICKED, {
        unitId,
    })
}

export const trackSeeAllSpacesClicked = (unitId: string) => {
    segmentService.track(SEE_ALL_SPACES_CLICKED, {
        unitId,
    })
}

export const trackGuestReviewsClicked = (unitId: string) => {
    segmentService.track(GUEST_REVIEWS_CLICKED, {
        unitId,
    })
}
