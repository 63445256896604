import React from "react"
import { Card, CardContent } from "../../components/Card"
import { Unit } from "@vacasa/owner-api-models/src/legacy/Earnings"
import EmptyState from "../../lib/components/EmptyState"
// Assets
import { ReactComponent as EarningsSvg } from "../../assets/icon-earnings.svg"
import { FormattedMessage } from "react-intl"

type UnitInfo = Pick<
    Unit,
    "image" | "unit_name" | "unit_address" | "unit_address_2"
>
type InActiveUnitProps = {
    unit: UnitInfo
}

/**
 * UI to show unit that has no contract with image, name and address
 */
const InActiveUnit: React.FC<InActiveUnitProps> = ({ unit }) => {
    const unitAddress = unit.unit_address_2
        ? `${unit.unit_address ?? ""}, ${unit.unit_address_2}`
        : `${unit.unit_address ?? ""}`
    return (
        <Card>
            <CardContent>
                <div className="unit-summary-header row">
                    <div className="col-lg-8">
                        <div className="unit-image">
                            <img
                                alt="unit-details-img"
                                className="unit-details-img"
                                src={unit.image}
                                data-testid="unit-details-img"
                            />
                        </div>
                        <div className="unit-details-header-left">
                            <h3 className="unit-details-primary">
                                <span>{unit.unit_name}</span>
                            </h3>
                            <div className="unit-details-secondary">
                                <p>{unitAddress}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <EmptyState
                    snippet={
                        <FormattedMessage
                            id="Statements.emptyStateUnit"
                            defaultMessage="Rental was not active during this period."
                        />
                    }
                >
                    <EarningsSvg />
                </EmptyState>
            </CardContent>
        </Card>
    )
}

export default InActiveUnit
