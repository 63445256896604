import { DetailedHTMLProps, FC, ImgHTMLAttributes } from "react"
import LoggingService from "services/logging/logging.service"

type Props = DetailedHTMLProps<
    ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
> & {
    fallbackImage?: string
}

export const Image: FC<Props> = ({ alt, onError, fallbackImage, ...rest }) => {
    return (
        <img
            {...rest}
            onError={e => {
                LoggingService.error({
                    message: `Failed to load image: ${e.currentTarget?.src}`,
                })
                if (onError) onError(e)
                if (!fallbackImage) return
                const { currentTarget } = e
                currentTarget.src = fallbackImage
            }}
            alt={alt ?? ""}
        />
    )
}
