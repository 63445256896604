/* eslint-disable camelcase */

import segmentService from "../segment.service"
import {
    BUTTON_CLICK_EVENT,
    CALENDAR_PAGE_PATH,
    CALENDAR_RESERVATION_DATE_CLICK,
    HOMEOWNER_WEBINAR_BANNER,
    LINK_TYPE,
    LIVE_UNIT_CONGRATS_EVENT,
    MODAL_DISMISS_ACTION,
    MODAL_POPUP_ACTION,
    VIEW_LISTING_ACTION,
} from "./calendarConstants"

export const trackLiveUnitCongratsModalDismissClicked = (): void => {
    segmentService.track(LIVE_UNIT_CONGRATS_EVENT, {
        action: MODAL_DISMISS_ACTION,
    })
}

export const trackLiveUnitCongratsModalPopup = (): void => {
    segmentService.track(LIVE_UNIT_CONGRATS_EVENT, {
        action: MODAL_POPUP_ACTION,
    })
}

export const trackLiveUnitCongratsViewListing = (): void => {
    segmentService.track(LIVE_UNIT_CONGRATS_EVENT, {
        action: VIEW_LISTING_ACTION,
    })
}

export const trackCalendarPageViewed = (): void => {
    segmentService.page({ path: CALENDAR_PAGE_PATH })
}

export const trackHomeownerWebinarBannerClicked = (): void => {
    segmentService.clickTrack(
        {
            type: LINK_TYPE,
            label: HOMEOWNER_WEBINAR_BANNER,
        },
        BUTTON_CLICK_EVENT
    )
}

export const trackCalendarReservationDayClicked = (
    reservation_id: number | string,
    date: string
) => {
    segmentService.clickTrack(
        { reservation_id, date },
        CALENDAR_RESERVATION_DATE_CLICK
    )
}
