import { TextField } from "@material-ui/core"
import { useLoadScript } from "@react-google-maps/api"
import { GOOGLE_MAPS_API_KEY } from "Environment"
import * as React from "react"
import { useEffect } from "react"
import { mapPlaceResultToAddress } from "utils/google/googleUtil"
import styles from "./AddressAutoComplete.module.scss"

const inputRef = React.createRef<HTMLInputElement>()

const handleAddress = (
    handleReferral: (key: string, value: string) => void,
    result?: google.maps.places.PlaceResult
): void => {
    const address = mapPlaceResultToAddress(result)
    handleReferral("street", result ? address["street"] : "")
    handleReferral("city", result ? address["city"] : "")
    handleReferral("state", result ? address["state"] : "")
    handleReferral("country", result ? address["country"] : "")
    handleReferral("zipCode", result ? address["zipCode"] : "")
}

function configureAutocomplete(
    callback: (result: google.maps.places.PlaceResult) => void
) {
    const input = inputRef.current?.querySelector("input")
    if (input) {
        const autocomplete = new window.google.maps.places.Autocomplete(input, {
            componentRestrictions: {
                country: ["us", "ca", "mx"],
            },
            fields: ["address_components", "geometry"],
            types: ["address"],
        })
        autocomplete.addListener("place_changed", () => {
            callback(autocomplete.getPlace())
        })
    }
}

interface AddressAutoCompleteProps {
    handleReferral: (key: string, value: string) => void
    showWarning: boolean
}

const AddressAutoComplete: React.FC<AddressAutoCompleteProps> = props => {
    const { handleReferral, showWarning } = props

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: String(GOOGLE_MAPS_API_KEY),
        libraries: ["places"],
    })

    useEffect(() => {
        if (isLoaded) {
            configureAutocomplete(result => {
                handleAddress(handleReferral, result)
            })
            if (loadError) {
                throw new Error(
                    "There was an error loading the Google Maps script"
                )
            }
        }
    }, [handleReferral, isLoaded, loadError])

    return (
        <div className={showWarning ? styles.errorAddress : styles.address}>
            <TextField
                classes={{
                    root: styles.root,
                }}
                id="address-autocomplete"
                onChange={e => {
                    if (!e.currentTarget.value) {
                        handleAddress(handleReferral)
                    }
                }}
                required
                ref={inputRef}
                fullWidth
                InputProps={{
                    disableUnderline: true,
                }}
                placeholder=""
                data-testid="address-autocomplete-input"
            />
        </div>
    )
}

export { AddressAutoComplete }
