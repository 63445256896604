import segmentService from "../segment.service"
import {
    REFERRALBANNER_DISMISS_CLICKED,
    REFERRALBANNER_LOADED,
    REFERRALBANNER_VIEW_CLICKED,
} from "./referralBannerTrackingConstants"

export const trackReferralBannerViewClicked = (): void => {
    segmentService.track(REFERRALBANNER_VIEW_CLICKED, {})
}

export const trackReferralBannerDismissClicked = (): void => {
    segmentService.track(REFERRALBANNER_DISMISS_CLICKED, {})
}

export const trackReferralBannerLoaded = (): void => {
    segmentService.track(REFERRALBANNER_LOADED, {})
}
