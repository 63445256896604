import React, { useState, useEffect } from "react"
import deepEqual from "deep-equal"
import { cloneDeep, uniqueId } from "lodash"
import classnames from "classnames"

import { Secondary } from "../Buttons/BaseButton"
import Dropdown from "./Dropdown/Dropdown"
import BedRow from "./BedRow"
import { RoomModel } from "../../../models/Room"
import { RoomOptions } from "../../../models/RoomOption"
import {
    Generic as BedSize,
    Generic as BedType,
    Generic as RoomType,
} from "../../../models/Generic"

import { ReactComponent as PlusIcon } from "../../../assets/icon-plus-circle.svg"
import { ReactComponent as TrashIcon } from "../../../assets/icon-trash.svg"
import styles from "../../../sass/onboarding.module.scss"
import { FormattedMessage } from "react-intl"
import { getClassStyle } from "utils/styles/styleWrapper"

interface Props {
    id: number
    onChangeHandler: (index: number, room: RoomModel) => void
    onDeleteRoomHandler: (index: number) => void
    room: RoomModel
    roomOptions: RoomOptions
    unassignedBeds?: BedSize[]
    lastRow: boolean
    shouldValidate: boolean
}

const RoomRow: React.FC<Props> = (props: Props) => {
    const BED_SIZE_CHILD = 5
    const BED_SIZE_CRIB = 6
    const BED_TYPE_CHILD = 9
    const BED_TYPE_CRIB = 10
    const BED_TYPE_REGULAR = 1
    const [tempRoom, setTempRoom] = useState({ ...props.room })

    useEffect(() => {
        if (!deepEqual(tempRoom, props.room)) {
            props.onChangeHandler(props.id, tempRoom)
        }
    }, [tempRoom, props])

    function renderBeds(): JSX.Element[] {
        if (
            tempRoom.attributes.roomType &&
            tempRoom.attributes.beds.length === 0
        ) {
            addBed()
        }

        const changeBedSizeHandler = (
            index: number,
            bedSize: BedSize
        ): void => {
            const tempBeds = cloneDeep(tempRoom.attributes.beds)
            const tempBed = tempBeds[index]

            if (tempBed) {
                let bedType = props.roomOptions.bedTypes.find(
                    type => type.id === BED_TYPE_REGULAR
                )
                if (bedSize.id === BED_SIZE_CHILD) {
                    bedType = props.roomOptions.bedTypes.find(
                        type => type.id === BED_TYPE_CHILD
                    )
                } else if (bedSize.id === BED_SIZE_CRIB) {
                    bedType = props.roomOptions.bedTypes.find(
                        type => type.id === BED_TYPE_CRIB
                    )
                }
                tempBed.bedSize = bedSize
                tempBed.bedType = bedType
                tempBeds[index] = tempBed
            }

            setTempRoom({
                ...tempRoom,
                attributes: {
                    ...tempRoom.attributes,
                    beds: tempBeds,
                },
            })
        }

        const changeBedTypeHandler = (
            index: number,
            bedType: BedType
        ): void => {
            const tempBeds = cloneDeep(tempRoom.attributes.beds)
            const tempBed = tempBeds[index]

            if (tempBed) {
                tempBed.bedType = bedType

                if (bedType.id === BED_TYPE_CHILD) {
                    tempBed.bedSize = props.roomOptions.bedSizes.find(
                        size => size.id === BED_SIZE_CHILD
                    )
                } else if (bedType.id === BED_TYPE_CRIB) {
                    tempBed.bedSize = props.roomOptions.bedSizes.find(
                        size => size.id === BED_SIZE_CRIB
                    )
                }
                tempBeds[index] = tempBed
            }

            setTempRoom({
                ...tempRoom,
                attributes: {
                    ...tempRoom.attributes,
                    beds: tempBeds,
                },
            })
        }

        const deleteHandler = (index: number): void => {
            const tempBeds = cloneDeep(tempRoom.attributes.beds)
            tempBeds.splice(index, 1)
            setTempRoom({
                ...tempRoom,
                attributes: {
                    ...tempRoom.attributes,
                    beds: tempBeds,
                },
            })
        }

        return tempRoom.attributes.beds.map((bed, key) => {
            return (
                <BedRow
                    id={key}
                    key={uniqueId("bed_")}
                    selectedBedSize={bed.bedSize}
                    selectedBedType={bed.bedType}
                    bedSizes={props.roomOptions.bedSizes}
                    bedTypes={props.roomOptions.bedTypes}
                    unassignedBedSizes={props.unassignedBeds}
                    isNewBed={bed.isNewBed}
                    onChangeBedSizeHandler={changeBedSizeHandler}
                    onChangeBedTypeHandler={changeBedTypeHandler}
                    onDeleteHandler={deleteHandler}
                    shouldValidate={props.shouldValidate}
                    firstBedItem={key === 0 && props.room.id !== null}
                />
            )
        })
    }

    function addBed(): void {
        const tempBed = {
            bedType: null,
            bedSize: null,
            isNewBed: true,
        }
        setTempRoom({
            ...tempRoom,
            attributes: {
                ...tempRoom.attributes,
                beds: [...tempRoom.attributes.beds, tempBed],
            },
        })
    }

    const classes = classnames(
        styles.room_row,
        !props.room.id ? styles.new_room : "",
        props.lastRow ? styles.last_row : ""
    )

    let roomName = props.room.attributes.roomType?.name
    if (props.room.attributes.roomNumber) {
        roomName += ` ${props.room.attributes.roomNumber}`
    }

    return (
        <div className={classes}>
            <div
                className={classnames(
                    styles.room_selection,
                    !props.room.id ? styles.new_room : "",
                    tempRoom.attributes.beds[0]?.isNewBed
                        ? styles.first_new_bed
                        : ""
                )}
            >
                <div className={styles.room_title}>
                    <span
                        className="type-heading-small"
                        style={{ fontWeight: "500" }}
                    >
                        {roomName ?? (
                            <FormattedMessage
                                id="Onbaording.sleepingArrangements.roomTitlePlaceholder"
                                defaultMessage="New Room"
                            />
                        )}
                    </span>
                </div>
                {!props.room.id && (
                    <div
                        className={classnames(
                            styles.dropdown_container,
                            styles.room_selector
                        )}
                    >
                        <Dropdown
                            className={getClassStyle(styles.dropdown)}
                            placeholder={
                                <FormattedMessage
                                    id="Onbaording.sleepingArrangements.roomTypePlaceholder"
                                    defaultMessage="Select a room"
                                />
                            }
                            selectedValue={tempRoom.attributes.roomType}
                            onChangeHandler={(roomType: RoomType): void => {
                                setTempRoom({
                                    ...tempRoom,
                                    attributes: {
                                        ...tempRoom.attributes,
                                        roomType: roomType,
                                    },
                                })
                            }}
                            options={props.roomOptions.roomTypes}
                            shouldValidate={props.shouldValidate}
                            errorText={
                                <FormattedMessage
                                    id="Onbaording.sleepingArrangements.roomTypeError"
                                    defaultMessage="Please select a room"
                                />
                            }
                        />
                        {!tempRoom.attributes.roomType && (
                            <div className={styles["remove-button-container"]}>
                                <Secondary
                                    typeOf={"link"}
                                    ariaLabel={"Remove room"}
                                    iconDirection="left"
                                    onClick={(): void =>
                                        props.onDeleteRoomHandler(props.id)
                                    }
                                >
                                    <>
                                        <TrashIcon
                                            className={styles.link_icon}
                                        />
                                        <FormattedMessage
                                            id="Onbaording.sleepingArrangements.removeRoomButton"
                                            defaultMessage="Remove Room"
                                        />
                                    </>
                                </Secondary>
                            </div>
                        )}
                    </div>
                )}
                {props.room.id && renderBeds()}
            </div>
            {!props.room.id && renderBeds()}
            {tempRoom.attributes.roomType && (
                <div className={styles.room_buttons}>
                    <div className={styles["add-bed-button"]}>
                        <Secondary
                            typeOf={"link"}
                            ariaLabel={"Add bed"}
                            iconDirection="left"
                            onClick={(): void => {
                                addBed()
                            }}
                        >
                            <>
                                <PlusIcon className={styles.link_icon} />
                                <FormattedMessage
                                    id="Onbaording.sleepingArrangements.addBedButton"
                                    defaultMessage="Add Bed"
                                />
                            </>
                        </Secondary>
                    </div>
                    <div>
                        <Secondary
                            typeOf={"link"}
                            ariaLabel={"Remove room"}
                            iconDirection="left"
                            onClick={(): void =>
                                props.onDeleteRoomHandler(props.id)
                            }
                        >
                            <>
                                <TrashIcon className={styles.link_icon} />
                                <FormattedMessage
                                    id="Onbaording.sleepingArrangements.removeRoomButton"
                                    defaultMessage="Remove Room"
                                />
                            </>
                        </Secondary>
                    </div>
                </div>
            )}
        </div>
    )
}

export default RoomRow
