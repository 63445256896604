const settings = {
    title: "Payment Settings",
    reserveBalance: "Zůstatek rezervy",
    paymentSettings: "Nastavení plateb",
    directDepositTitle: "Bank Account",
    checksByMailTitle: "Paper Checks",
    checksByMailEnrolled: "You are enrolled in paper checks.",
    checksByMailNotEnrolled: "You are not enrolled in paper checks.",
    paymentMethod: "Payment Method",
    paymentMethodText:
        "Payment delivery depends on the owner's selected payment method.",
    directDepositFineprint:
        "Deposits and withdrawals usually appear in the account 2-5 business days after the monthly statement.",
    checksByMailFineprint:
        "Paper checks usually take 5-10 business days to arrive by mail. Please confirm your mailing address in your {ProfileLink}.",
    reserveBalanceEmptyState:
        "Zde můžete zobrazit a aktualizovat svůj zůstatek rezervy.",
    reserveBalanceText:
        "If your expenses exceed your revenue in a given month, your Reserve Account will be used to cover the difference. If your reserve account balance is below {balance}, we'll automatically replenish it from revenue you earn.",
    reserveUpdateAria:
        "Kliknutím na toto tlačítko aktualizujete svůj zůstatek rezervy.",
    reserveUpdatedNotification: "Zůstatek rezervy byl aktualizován",
    reserveMinimumRequired: "This unit requires at least {amount} reserve",
    directDepositEnrolled: "You are enrolled in direct deposit.",
    achDirectDepositEnrolled:
        "You are enrolled in ACH direct deposit and ACH expense debit.",
    directDepositNotEnrolled: "Nemáte nastaven přímý vklad.",
    checkingAccount: "Běžný účet",
    savingsAccount: "Spořící účet",
    updatePayment: "Aktualizovat způsob platby",
    updateAction: "Aktualizovat",
    updatedNotification: "Platební informace byly aktualizovány",
    updatePaymentSub: "Bankovní účet pro přímý vklad",
    receiveByMailSub: "Enroll in Checks",
    receiveByMailText:
        "If selected, your checks will be mailed to your address on file.",
    directDepositRemoved: "Přímý vklad odstraněn",

    addBankAccount: "Přidat bankovní účet",
    checking: "Běžný účet",
    savings: "Spořící účet",
    routingNumber: "Směrový kód banky",
    nineDigitNumber: "9-ti místné číslo v levém dolním rohu šeku",
    mustBeginWith: "Směrový kód banky musí začínat čísly 0, 1, 2, nebo 3",
    mustBe9Digits: "Zadejte prosím 9-ti místný směrový kód banky",
    mustBeValid9Digits: "Please enter a valid 9-digit routing number",
    accountNumber: "Číslo účtu",
    accountHolderName: "Jméno držitele účtu",
    enterBankAccount: "Please enter your bank account number",
    mustBe5to17Digits: "Please enter a 5 to 17-digit account number",
    byClickingYouAuthorize:
        "Because you chose to pay for expenses via ACH Debit, this account will also be charged if your expenses exceed your rental revenue for the month. By clicking 'Agree and Save', you authorize {accountingEntity} to make payments electronically via direct deposit and to debit the identified account for up to $10,000 per transaction per day. This authority will remain in effect until you change or update your payment method.",
    cancel: "zrušit",
    agreeAndSave: "Souhlasím a uložit",
    savePaymentMethod: "Uložit způsob platby",
    clickToSaveAria: "Kliknutím na toto tlačítko uložíte váš způsob platby.",
    clickToCancelAria: "Kliknutím na toto tlačítko akci zrušíte.",
    clickToRequestChecksAria:
        "Kliknutím na toto tlačítko požádáte o zasílání šeků poštou.",
    requestChecksByMail: "Alternativně požádejte o zasílání šeků poštou",
    getYourPaymentsFaster:
        "Když si nastavíte přímý vklad, budete dostávat své platby rychleji.",
    errorMessages: {
        updateReserveBalanceError: "Zůstatek rezervy je příliš velký.",
        updateReserveBalanceNegativeError:
            "Zůstatek rezervy nemůže být záporný.",
        updateReserveBalanceMissingError:
            "Je třeba uvést částku zůstatku rezervy.",
        sendDirectDepositEmailError:
            "E-mail o odstranění přímého vkladu se nepodařilo odeslat.",
        paymentAccountGeneralError:
            "Něco se pokazilo při aktualizaci informací o vašem účtu. Zkuste to prosím znovu později.",
        paymentAccountNameError: "Je třeba nastavit jméno držitele účtu.",
        paymentAccountNameLengthError:
            "Jméno držitele účtu má více než 100 znaků.",
        paymentAccountRoutingNumError: "Směrový kód banky není platný.",
        paymentAccountNumError: "Číslo účtu není platné.",
        paymentAccountTypeError: "Typ účtu není platný.",
        sendACHUpdateEmailError:
            "E-mail o ACH aktualizaci se nepodařilo odeslat.",
        somethingWentWrong: "Něco se pokazilo. Zkuste to prosím znovu.",
    },
    emptyState:
        "This is where you can update your reserve balance and payment method. Be sure to set up direct deposit before your first statement posts in order to get your payments faster.",
    linkToStatements: "Statements",
}

export default settings
