const petsAllowed = {
    title: "Hunde erlauben",
    ariaLabel: "Klicke die Schaltfläche, um Haustiere zu erlauben.",
    description:
        "Macht dein Inserat haustierfreundlich, wodurch wir deine Unterkunft bei einem größeren Publikum vermarkten können. Wir stellen Hundebedarf zur Verfügung und achten darauf, dass deine Unterkunft immer gut gepflegt ist.",
    learnMore: "Weitere Informationen",
    earnMore: "Verdiene bis zu 20 % mehr",
    earnMoreDescription:
        "Haustieren Zugang zu gestatten kann die Einnahmen und Auslastung drastisch anheben, besonders in der Nebensaison.",
    untitledHome: "Unbenannte Unterkunft",
    showAllRentals: "Alle Ferienimmobilien anzeigen",
    showAllAriaLabel:
        "Mit dieser Schaltfläche werden alle Ferienimmobilien angezeigt.",
    hideRentals: "Ferienimmobilien ausblenden",
    allowed: "Erlaubt",
    allow: "Erlauben",
    modalHeader: "Super, jetzt bist du startklar!",
    modalText:
        "Dein Inserat wird aktualisiert und das Team vor Ort benachrichtigt, sodass deine Unterkunft auf vierbeinige Gäste vorbereitet werden kann. Die Aktualisierung aller deiner Inserate und Marketing-Kanäle mit dieser Änderung kann bis zu 24 Stunden im Anspruch nehmen.",
    modalFooter: "Klicke diese Schaltfläche, um das Modal zu schließen.",
    ok: "OK",
    disclaimer:
        "Es kann bis zu 24 Stunden dauern, um alle deine Inserate und Marketing-Kanäle mit Änderungen zu aktualisieren. Bitte wende dich bei Fragen oder wenn du keine Hunde mehr erlauben möchtest an den Kontakt für deine Unterkunft.",
}

export default petsAllowed
