import { JSONAPIResource, Reservation, Unit } from "@vacasa/owner-api-models"
import Passcode from "@vacasa/owner-api-models/src/Passcode"
import { isAfter, parseISO } from "date-fns"
import { useOwnerFeatureFlag } from "hooks/owner-feature-flag"
import { useSmartHomeLockCode } from "hooks/smarthome-lockcode"
import { inject, observer } from "mobx-react"
import React, {
    Fragment,
    ReactNode,
    useCallback,
    useEffect,
    useState,
} from "react"
import { FormattedMessage, useIntl } from "react-intl"
import LoggingService from "services/logging/logging.service"
import { trackOwnerHoldELockViewed } from "services/segment/ownerHold/ownerHoldTracking"
import { useLockcode } from "../../../hooks/lockcode"
import { LinkSecondary } from "../Buttons/BaseButton"
import Loader from "../Loader"
import MultipleCodes from "./MultipleCodes"
import style from "./tempLockAccordionPartialBottom.module.scss"
import { useContactId, useIsEmployee } from "hooks/user"

interface TempLockAccordionPartialTopProps {
    reservation: JSONAPIResource<Reservation>
    unit: JSONAPIResource<Unit>
}

const TempLockAccordionPartialTop: React.FC<
    TempLockAccordionPartialTopProps
> = (props: TempLockAccordionPartialTopProps): JSX.Element => {
    const intl = useIntl()
    const isEmployee = useIsEmployee()
    const [loading, setLoading] = useState(!isEmployee)
    const [lockCodeRevealed, setLockCodeRevealed] = useState(false)
    const [unitDevicePasscode, setUnitDevicePasscode] = useState("")
    const [multiPasscodes, setMultiPasscodes] = useState<Passcode | undefined>(
        undefined
    )
    const [lockCode, setLockCode] = useState("")
    const reservationId = String(props.reservation.id)
    const lockCodeQuery = useLockcode(reservationId)
    const unitId = String(props.unit.id)
    const contactId = useContactId()
    const lockCodeFeatureFlag = useOwnerFeatureFlag("smarthome-lock-codes")
    const smartHomeLockQuery = useSmartHomeLockCode(reservationId, unitId)

    useEffect(() => {
        // wait for feature flag
        if (!lockCodeFeatureFlag.clientReady) return
        if (!lockCodeFeatureFlag.isEnabled) {
            if (lockCodeQuery.isSuccess) {
                if (
                    lockCodeQuery.data.data &&
                    lockCodeQuery.data.data.end &&
                    isAfter(parseISO(lockCodeQuery.data.data.end), new Date())
                ) {
                    setLockCode(lockCodeQuery.data.data.passcode ?? "")
                } else {
                    setLockCode("Not available")
                }
            } else if (lockCodeQuery.isError) {
                setLockCode("Not available")
            } else if (lockCodeQuery.isLoading) {
                setLockCode("")
                setLoading(true)
            }
        } else {
            if (smartHomeLockQuery.isSuccess) {
                if (
                    smartHomeLockQuery.data.endDate &&
                    isAfter(
                        parseISO(smartHomeLockQuery.data.endDate),
                        new Date()
                    )
                ) {
                    if (smartHomeLockQuery.data.accessCodes.length > 1) {
                        setMultiPasscodes(smartHomeLockQuery.data)
                    } else {
                        setLockCode(
                            smartHomeLockQuery.data.accessCodes[0]
                                ?.accessCode ?? ""
                        )
                    }
                } else {
                    setLockCode("Not available")
                }
            } else if (smartHomeLockQuery.isError) {
                setLockCode("Not available")
            } else if (smartHomeLockQuery.isLoading) {
                setLoading(true)
            }
        }
    }, [
        lockCodeFeatureFlag.clientReady,
        lockCodeFeatureFlag.isEnabled,
        lockCodeQuery,
        smartHomeLockQuery,
    ])

    const handleLockCodeFetch: () => Promise<void> = useCallback(async () => {
        if (lockCode || multiPasscodes) {
            const logObj = {
                reservationId: reservationId,
                event: "Fetch reservation temporary lock code",
                resource: `/owners/lock-code/${reservationId}`,
                user: {
                    id: contactId,
                    type: isEmployee ? "employee" : "owner",
                },
            }
            LoggingService.log({
                message: `Owner Hold Elock Viewed - reservationId: ${reservationId}`,
                context: logObj,
            })
            trackOwnerHoldELockViewed(logObj)
            setUnitDevicePasscode(lockCode)
            setLockCodeRevealed(true)
            setLoading(false)
        }
    }, [contactId, isEmployee, lockCode, multiPasscodes, reservationId])

    useEffect(() => {
        if (!isEmployee) {
            handleLockCodeFetch()
        }
    }, [handleLockCodeFetch, isEmployee])

    function renderEmployeeBlock(): JSX.Element {
        if (loading) return renderLoader()
        return (
            <div style={{ padding: "0 1px 0 2px" }}>
                <p className="small dusk-lighter zero-margin">
                    <FormattedMessage
                        id="Calendar.flyout.employeesMustClickToView"
                        defaultMessage="Employees must <button>click to view this code</button>. For security, this action will be recorded."
                        values={{
                            button: (chunks: ReactNode) => (
                                <LinkSecondary
                                    typeOf={"link"}
                                    onClick={e => {
                                        setLoading(true)
                                        handleLockCodeFetch()
                                        e?.stopPropagation()
                                    }}
                                    ariaLabel={intl.formatMessage({
                                        id: "CalendarPage.flyout.smartLockAria",
                                        defaultMessage:
                                            "Click this link to reveal the temporary smart lock code.",
                                    })}
                                >
                                    {chunks}
                                </LinkSecondary>
                            ),
                        }}
                    />
                </p>
            </div>
        )
    }
    function renderLoader(): JSX.Element {
        const styles = {
            position: "relative",
            top: 0,
            left: 0,
            marginLeft: 0,
            marginTop: 0,
            textAlign: "left",
        } as unknown as { [key: string]: string }
        return <Loader styles={styles} spinnerSize={"spinner-sm"} />
    }

    function handleLockDisplay(): JSX.Element {
        if (isEmployee && !lockCodeRevealed) {
            return renderEmployeeBlock()
        }
        if (loading) {
            return renderLoader()
        }
        if (multiPasscodes) {
            return <MultipleCodes passcode={multiPasscodes} />
        }
        return (
            <Fragment>
                {unitDevicePasscode &&
                unitDevicePasscode !== "Not available" ? (
                    unitDevicePasscode
                ) : (
                    <div className={style["placeholder"]}>
                        <FormattedMessage
                            id="CalendarPage.flyout.notAvailable"
                            defaultMessage="Your home access code will appear here 3 days prior to your check-in date. Your guests will also be notified."
                        />
                    </div>
                )}
            </Fragment>
        )
    }

    return <Fragment>{handleLockDisplay()}</Fragment>
}
export default inject("rootService")(observer(TempLockAccordionPartialTop))
