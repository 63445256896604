/* eslint-disable camelcase */
import segmentService from "../segment.service"
import {
    ADD_FILTER_ACTION,
    ADJUST_MIN_RATE_OPTION,
    CLICK_NIGHTS_OCCUPIED_COMPONENT_EVENT,
    COMPARE_PERFORMANCE_CHART_EVENT,
    CORE_METRICS_WIDGETS_LOADED,
    EMAIL_US_OPTION,
    GUEST_REVIEW_MODAL_EVENT,
    GUEST_REVIEW_MODAL_RESERVATION_ROW,
    GUEST_STAYS_COMPONENT,
    MARKET_COMP_CHART_LOADED,
    MIN_RATE_NOTIFICATION_TEST_EXPERIMENT,
    NIGHTS_OCCUPIED_MODAL_RESERVATION_ROW,
    NOTIFICATION_SHOWN,
    OWNER_HOLDS_COMPONENT,
    PERFORMANCE_EVENT,
    PERFORMANCE_PAGE_PATH,
    PHONE_NUMBERCLICK_OPTION,
    REMIND_ME_LATER_OPTION,
    REMOVE_FILTER_ACTION,
    SEE_ALL_OPTION,
    SELECT_FILTER_ACTION,
    UNIT_MIN_RATE_ACTION,
    VIEW_RESERVATION_EVENT,
} from "./performanceTrackingConstants"

export const trackPerformancePageViewed = (): void => {
    segmentService.page({ path: PERFORMANCE_PAGE_PATH })
}
export const trackAdjustMinRateClicked = (): void => {
    segmentService.track(PERFORMANCE_EVENT, {
        action: UNIT_MIN_RATE_ACTION,
        option: ADJUST_MIN_RATE_OPTION,
    })
}

export const trackMinRateRemindMeLaterClicked = (): void => {
    segmentService.track(PERFORMANCE_EVENT, {
        action: UNIT_MIN_RATE_ACTION,
        option: REMIND_ME_LATER_OPTION,
    })
}

export const trackMinRatePhoneNumClicked = (): void => {
    segmentService.track(PERFORMANCE_EVENT, {
        action: UNIT_MIN_RATE_ACTION,
        option: PHONE_NUMBERCLICK_OPTION,
    })
}

export const trackMinRateEmailUsClicked = (): void => {
    segmentService.track(PERFORMANCE_EVENT, {
        action: UNIT_MIN_RATE_ACTION,
        option: EMAIL_US_OPTION,
    })
}

export const trackPerformanceChartCompareAddFilter = (year: number): void => {
    segmentService.track(COMPARE_PERFORMANCE_CHART_EVENT, {
        action: ADD_FILTER_ACTION,
        year: String(year),
    })
}

export const trackPerformanceChartCompareRemoveFilter = (
    year: number
): void => {
    segmentService.track(COMPARE_PERFORMANCE_CHART_EVENT, {
        action: REMOVE_FILTER_ACTION,
        year: String(year),
    })
}

export const experimentTrackMinRateNotification = (): void => {
    segmentService.experimentTracking(
        MIN_RATE_NOTIFICATION_TEST_EXPERIMENT,
        NOTIFICATION_SHOWN
    )
}

export const trackGuestReviewSeeAllClicked = (): void => {
    segmentService.track(GUEST_REVIEW_MODAL_EVENT, {
        action: SEE_ALL_OPTION,
    })
}

export const trackGuestReviewViewReservationClicked = (
    reservationId: string
): void => {
    segmentService.track(VIEW_RESERVATION_EVENT, {
        feature_name: GUEST_REVIEW_MODAL_RESERVATION_ROW,
        reservation_id: reservationId,
    })
}

export const trackGuestReviewFilterSelection = (rating: string): void => {
    segmentService.track(GUEST_REVIEW_MODAL_EVENT, {
        action: SELECT_FILTER_ACTION,
        rating: rating,
    })
}

export const trackGuestStaysNightsOccupiedClicked = (): void => {
    segmentService.track(CLICK_NIGHTS_OCCUPIED_COMPONENT_EVENT, {
        feature_name: GUEST_STAYS_COMPONENT,
    })
}

export const trackOwnerHoldsNightsOccupiedClicked = (): void => {
    segmentService.track(CLICK_NIGHTS_OCCUPIED_COMPONENT_EVENT, {
        feature_name: OWNER_HOLDS_COMPONENT,
    })
}

export const trackReservationRowClicked = (reservationId: string): void => {
    segmentService.track(VIEW_RESERVATION_EVENT, {
        feature_name: NIGHTS_OCCUPIED_MODAL_RESERVATION_ROW,
        reservation_id: reservationId,
    })
}

export const trackMarketCompChartLoaded = (): void => {
    segmentService.track(MARKET_COMP_CHART_LOADED, {})
}

export const trackCoreMetricsWidgetsLoaded = (
    minRate?: number,
    averageDailyRate?: number,
    occupancyPercentage?: number
): void => {
    segmentService.track(CORE_METRICS_WIDGETS_LOADED, {
        feature_name: "core-metrics",
        min_rate: minRate,
        average_daily_rate: averageDailyRate,
        occupancy_percentage: occupancyPercentage,
    })
}
