import React from 'react'
import styles from './SurveyFlyout.module.scss'
import { FormattedMessage } from "react-intl"

interface ProgressBarProp {
    step: number
    totalSteps: number
}
const ProgressBar: React.FC<ProgressBarProp> = ({ step, totalSteps }) => {
    const progress = (step / totalSteps) * 100;
    return (
        <>
            <div className={styles["follow-up"]}>
                <FormattedMessage
                    id="Survey.survey_follow_up"
                    defaultMessage="Step {step} of {totalSteps}"
                    values={{ step, totalSteps }}
                />
            </div>
            <div className={styles["progress-bar"]}>
                <div className={styles.progress} style={{ width: `${progress}%` }} />
            </div>
        </>
    );
}

export { ProgressBar }