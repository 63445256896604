import React from "react"
import {
    FormControl,
    FormHelperText,
    ListSubheader,
    MenuItem,
    Select,
} from "@material-ui/core"
import { flatMap, flatten, uniqueId } from "lodash"
import classnames from "classnames"

import { Generic as DropdownOption } from "../../../../models/Generic"

import styles from "./Dropdown.module.scss"

interface DropdownCategory {
    title: string
    options: DropdownOption[]
}

interface Props {
    placeholder: React.ReactNode
    selectedValue: DropdownOption | null | undefined
    onChangeHandler: (option: DropdownOption) => void
    options?: DropdownOption[]
    categorizedOptions?: DropdownCategory[] | null | undefined
    className: string
    shouldValidate?: boolean
    disabled?: boolean
    errorText?: React.ReactNode
}

const Dropdown: React.FC<Props> = (props: Props) => {
    const handleChange = (
        event: React.ChangeEvent<{ value: unknown }>,
        child: React.ReactNode
    ): void => {
        if (event.target.value === null || event.target.value === undefined)
            return
        const selectedOption = child as React.ReactElement
        props.onChangeHandler({
            id: event.target.value as number,
            name: selectedOption.props.children,
        })
    }

    const showError = props.shouldValidate && !props.selectedValue?.id

    function renderOptions(opts?: DropdownOption[]): JSX.Element[] {
        if (!opts) return []
        return opts.map(opt => {
            return (
                <MenuItem
                    key={uniqueId("dropdown_")}
                    value={opt.id}
                    classes={{
                        root: styles.dropdown_item,
                        selected: styles.dropdown_item_selected,
                    }}
                >
                    {opt.name}
                </MenuItem>
            )
        })
    }

    function renderCategorizedOptions(): JSX.Element[] {
        if (!props.categorizedOptions) return []

        return flatten(
            flatMap(props.categorizedOptions, (category, index) => {
                const headerClasses = classnames(
                    styles.category_header,
                    index > 0 ? styles.category_separator : ""
                )
                return [
                    <ListSubheader
                        key={uniqueId("dropdown_")}
                        classes={{ root: headerClasses }}
                    >
                        {category.title}
                    </ListSubheader>,
                    renderOptions(category.options),
                ]
            })
        )
    }

    return (
        <FormControl className={props.className} disabled={props.disabled}>
            <Select
                defaultValue=""
                value={props.selectedValue?.id ?? ""}
                onChange={handleChange}
                id="grouped-select"
                classes={{
                    root: classnames(
                        styles.dropdown,
                        showError ? styles.error_state : "",
                        !props.selectedValue?.id ? styles["default-item"] : ""
                    ),
                    selectMenu: styles.dropdown_menu,
                    disabled: styles.dropdown_disabled,
                    icon: styles["dropdown-icon"],
                }}
                displayEmpty
                disableUnderline
                MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                    },
                    getContentAnchorEl: null,
                }}
            >
                <MenuItem
                    key={uniqueId("dropdown_")}
                    value=""
                    classes={{
                        root: styles.dropdown_item,
                        selected: styles.dropdown_item_selected,
                    }}
                    disabled
                >
                    {props.placeholder}
                </MenuItem>
                {props.categorizedOptions
                    ? renderCategorizedOptions()
                    : renderOptions(props.options)}
            </Select>
            {showError && props.errorText && (
                <FormHelperText classes={{ root: styles.error_label }}>
                    {props.errorText}
                </FormHelperText>
            )}
        </FormControl>
    )
}

export default Dropdown
