import axios, { InternalAxiosRequestConfig } from "axios"
import { JWT } from "../Constants"
import { redirectToIdp } from "../utils/auth/idp"
import { jwtDecode, JwtPayload, InvalidTokenError } from "jwt-decode"
import LoggingService from "../services/logging/logging.service"
import storageService from "services/storage.service"
import { clearUserCache } from "utils/user"
import { getSessionConfig } from "utils/session"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getAccessToken = () => storageService.localStorage.getItem(JWT)

export const getImpersonatedContactId = (): string | undefined => {
    const session = getSessionConfig()
    if (!session.contactIds) {
        return undefined
    }
    return session.contactIds[0]
}

export const addAuthorizationHeader = (
    config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig => {
    const accessToken = getAccessToken()

    if (config.headers) {
        // only set the auth token if it hasn't been provided by the caller
        if (!config.headers.Authorization)
            config.headers.Authorization = `Bearer ${accessToken}`

        const impersonatedContactId = getImpersonatedContactId()
        if (impersonatedContactId) {
            config.headers["X-Authorization-Contact"] = impersonatedContactId
        }

    }
    return config
}

/**
 * Get any jwt issues that might cause a JWT error other than the jwt not being valid
 */
const getUnexpectedJWTIssue = (jwt: string | null) => {
    if (typeof jwt !== "string") return null
    try {
        const { exp } = jwtDecode<JwtPayload>(jwt)
        if (typeof exp !== "number") return "JWT does not have an exp"
        const isExpired = Date.now() >= exp * 1000
        if (!isExpired) return "JWT has not expired"
    } catch (error) {
        if (error instanceof InvalidTokenError) {
            return "JWT is malformed or falsy"
        }
        throw error
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
export const checkAuthorization = (error: any): any => {
    if (axios.isAxiosError(error) && error.response?.status === 401) {
        const unexpectedJWTIssue = getUnexpectedJWTIssue(
            storageService.localStorage.getItem(JWT)
        )
        if (unexpectedJWTIssue) {
            LoggingService.error({ message: unexpectedJWTIssue })
        }

        clearUserCache()
        redirectToIdp()
    }
    return Promise.reject(error)
}
