import classNames from "classnames"
import styles from "./ChartSkeleton.module.scss"

const ChartSkeleton = () => {
    return (
        <div className={styles.wrap}>
            <header className={styles.row}>
                <div className={styles["row__col"]}>
                    <div className={classNames("shimmer", styles.title)} />
                    <div className={classNames("shimmer", styles.revenue)} />
                    <div
                        className={classNames("shimmer", styles.description)}
                    />
                </div>
                <div className={styles["row__col"]}>
                    <div className={classNames("shimmer", styles.title)} />

                    <div
                        className={classNames("shimmer", styles.yearSelector)}
                    />
                </div>
            </header>
            <div className={classNames("shimmer", styles.chart)} />
            <div className={classNames(styles.row, styles["row--last"])}>
                <div className={styles["row__col"]}>
                    <div className={classNames("shimmer", styles.legend)} />
                </div>
                <div className={styles["row__col"]}>
                    <div style={{ display: "flex" }}>
                        <div className={classNames("shimmer", styles.toggle)} />
                        <div
                            className={classNames("shimmer", styles.toggleText)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChartSkeleton
