import React, { useEffect } from "react"
import { CalendarMonthPhrases } from "../../../defaultPhrases"
import calendarService from "../../../services/calendar.service"
import earningsService from "../../../services/earnings/earnings.service"
import { MONTH_WIDTH_SIZE, MONTH_HEIGHT_SIZE } from "../../../Constants"
import LoggingService from "../../../services/logging/logging.service"
import { useContactId, useIsEmployee } from "hooks/user"
import { useCurrentUnit } from "hooks/units"
import { endOfMonth, format, formatISO, startOfMonth } from "date-fns"

type tabIndexType = 0 | -1

interface CalendarYearMonthProps {
    month: Date
    monthWidthSize?: number
    monthHeightSize?: number
    isFocused: boolean
    tabIndex: tabIndexType
    onMonthClick?: (day: Date) => void
    renderMonth?: (month: Date) => JSX.Element
    phrases: typeof CalendarMonthPhrases
}

const CalendarYearMonth: React.FC<CalendarYearMonthProps> = ({
    month = new Date(),
    monthWidthSize = MONTH_WIDTH_SIZE,
    monthHeightSize = MONTH_HEIGHT_SIZE,
    isFocused = false,
    tabIndex = -1,
    onMonthClick,
    renderMonth,
    phrases = CalendarMonthPhrases,
}: CalendarYearMonthProps) => {
    const buttonRef = React.createRef<HTMLButtonElement>()
    const isEmployee = useIsEmployee()
    const contactId = useContactId() ?? ""
    const { unitId } = useCurrentUnit()

    useEffect(() => {
        if (tabIndex === 0 && isFocused) {
            buttonRef.current?.focus()
        }
    }, [tabIndex, isFocused, buttonRef])

    onMonthClick = (day: Date) => {
        calendarService.selectedStartDay = formatISO(startOfMonth(day), {
            representation: "date",
        })
        calendarService.selectedEndDay = formatISO(endOfMonth(day), {
            representation: "date",
        })
        calendarService.displayMonth = format(startOfMonth(day), "MMMM")
        calendarService.selectedYear = format(startOfMonth(day), "yyyy")
        earningsService
            .fetchStatementByUnitID(
                contactId,
                unitId,
                calendarService.selectedStartDay,
                calendarService.selectedEndDay,
                null,
                isEmployee
            )
            .then(() => {
                earningsService.updatingSummary = false
                return false
            })
            .catch(reason => {
                LoggingService.error({
                    message: "Failed to fetch statements by unitId",
                    error: reason,
                })
            })
    }

    const formattedDate = format(month, "MMMM")
    const ariaLabel =
        (phrases.chooseAvailableDate,
        {
            date: formattedDate,
        })
    const ariaLabelStr = ariaLabel.date

    const monthSizeStyles = {
        width: monthWidthSize,
        height: monthHeightSize - 1,
    }

    return (
        <td className="CalendarYearMonth" style={monthSizeStyles}>
            <button
                data-testid={"calendar-year-month"}
                type="button"
                ref={buttonRef}
                className="CalendarYearMonth__button"
                aria-label={ariaLabelStr}
                onMouseUp={e => {
                    e.currentTarget.blur()
                }}
                onClick={(): void => {
                    if (onMonthClick) onMonthClick(month)
                }}
                tabIndex={tabIndex}
            >
                {renderMonth ? renderMonth(month) : format(month, "MMM")}
            </button>
        </td>
    )
}

export default CalendarYearMonth
