/**
 * Checks if a string contains only valid characters for a phone number.
 * Allowed characters are: numbers (0-9), parentheses (), hyphen (-), and plus sign (+).
 *
 * @param input - The string to be checked.
 * @returns true if the string contains only valid characters, false otherwise.
 */
export function containsOnlyValidPhoneCharacters(input: string): boolean {
    const validPhoneRegex = /^[\d()\-+\s]+$/;
    return validPhoneRegex.test(input);
}