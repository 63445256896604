const topbar = {
    myRentals: "Meine Unterkünfte",
    myTrips: "Meine Reisen",
    profile: "Profil",
    changePassword: "Passwort ändern",
    logout: "Abmelden",
    switchAccounts: "Switch Accounts",
    selectAccount: "Select An Account",
    referral: "Refer a friend",
}

export default topbar
