const onboarding = {
    title: "Setup",
    verifyListing: {
        title: "Let's get started",
        underReviewTitle: "We'll take it from here",
        description:
            "Review your listing details and calendar below so we can get your home live.",
        finalTouches:
            "We're putting the final touches on your home listing. You'll hear from us as soon as it's live.",
        itemDescription: "Here's what we're up to:",
        items: {
            inspection: "Doing our initial inspection",
            photography: "Taking professional photos",
            channelListings: "Checking or securing permits",
            andMore: "…and more!",
            seeListLink: "See the full list.",
        },
        whatVacasaIsDoing: "What Vacasa is doing:",
        additionalDoing:
            "We're also working on completing the initial inspection and securing permits (if required) to get your home rental ready.",
        additionalDoingMultiUnits:
            "In addition to taking photos, writing a custom description, and setting your rates, we're also completing the initial inspection of your home and securing permits (if required) to get your home rental ready.",
        thingsYouCanDoNow: "Things you can do now:",
        listingDetails:
            "Help onboard your unit faster by editing your listing details",
        calendarDetails: "Reserve dates for your own personal use",
        initialInspection: "Initial inspection",
        securingPermits: "Securing permits",
        professionalPhotos: "1. Professional photos",
        customDescription: "2. Custom description",
        settingYourRates: "3. Setting your rates",
        progressStatus: {
            notStarted: "Not yet started",
            inProgress: "In progress",
            completed: "Completed",
        },
    },
    needHelp: {
        title: "Need Help?",
    },
    notSet: "Not set",
    notSelected: "Not selected",
    saveButton: "Save",
    confirmButton: "Confirm",
    cancelButton: "Cancel",
    yesOption: "Yes",
    noOption: "No",
    subTitle: "Edit your listing details",
    mainDescription:
        "You can help us get your online listing ready as quickly as possible. Please take a moment to review the basic information about your home, and update anything you need to.",
    sectionTitles: {
        amenities: "Amenities",
        bathrooms: "Bathrooms",
        kitchen: "Kitchen",
        beds: "Beds",
        parking: "Parking",
        sleepingArrangements: "Sleeping Arrangements",
    },
    amenities: {
        dogs: {
            title: "Dogs",
            dogFriendly: "Dog-friendly",
            noDogs: "Not dog-friendly",
            description:
                "We can market your home to an even wider audience if it’s listed as dog-friendly. Plus, we’ll provide pet supplies and keep your home looking great. {{link}}",
            learnMore: "Learn more",
        },
        hotTub: "Hot tub",
    },
    rooms: {
        bedrooms: "Bedrooms",
        bathrooms: "Bathrooms",
        kitchen: "Kitchen",
        lofts: "Loft",
        halfBaths: "Half baths",
    },
    kitchenTypes: {
        noKitchen: "No kitchen",
        kitchenette: "Kitchenette",
        full: "Full kitchen",
        gourmet: "Gourmet kitchen",
    },
    hotTubTypes: {
        noHotTub: "No hot tub",
        private: "Private hot tub",
        shared: "Shared hot tub",
    },
    beds: {
        king: "King",
        queen: "Queen",
        full: "Full",
    },
    parking: {
        spaces: "Parking spaces",
        fourWD: {
            title: "4x4 Required",
            tooltip: "Accessing the home requires a car with four-wheel drive.",
            options: {
                notRequired: "Not required",
                required: "Required",
                inWinter: "Required in winter",
            },
        },
        paid: {
            title: "Paid parking only",
            tooltip: "Guests must pay for parking on the property.",
        },
        valet: {
            title: "Valet parking",
            tooltip:
                "Valet parking is available at this home or complex. Fees may apply.",
        },
        street: "Street parking only",
        accessible: "Accessible (disability) parking",
    },
    sleepingArrangements: {
        description:
            "Let your guests know the number and size of beds in each room. We've started filling this in based on what we know so far.",
        noBedsDescription:
            "Let your guests know the number and size of beds in each room.",
        addBedButton: "Add Bed",
        addRoomButton: "Add Room",
        removeRoomButton: "Remove Room",
        roomTitlePlaceholder: "New Room",
        roomTypePlaceholder: "Select a room",
        roomTypeError: "Please select a room",
        bedSizePlaceholder: "Select a bed",
        bedSizeError: "Please select a bed",
        bedTypePlaceholder: "Select bed type",
        bedTypeError: "Please select bed type",
        unassignedCategory: "Unassigned",
        newCategory: "New",
    },
    revenueReminder: {
        title: "Heads up!",
        description:
            "Before your listing goes live, you can block off nights from being rented as guests (such as dates you’d like to use your home).",
        blockingCalendar:
            "Blocking as few nights as possible helps maximize your revenue.",
        goToCalendarButton: "Go to Calendar",
        maybeLaterButton: "Maybe Later",
    },
    error: {
        loadingSleepingArrangementsFailedErrorMessage:
            "We had trouble loading sleeping arrangements. If the problem persists, please try again later.",
        savingFailedErrorMessage:
            "We had trouble saving your changes. If the problem persists, please try again later.",
        tryAgainButtonTitle: "Try Again",
        trySavingAgainButtonTitle: "Try Saving Again",
        availabilityQueryError: "We were unable to fetch your availability.",
        photographyTicketQueryError:
            "We were unable to fetch your photography tickets",
    },
    welcome: {
        title: "Welcome to Vacasa",
        description: "Thanks for partnering with us—we're glad you're here.",
        description2:
            "This is your home base for managing your vacation rental listing. Right now, we're setting up your home in our system.",
        itemDescription:
            "In the meantime, check out what you can do in your online account.",
        onceReady: "Once your home is ready, you can use your account to:",
        items: {
            setHolds: "Set holds",
            seeBookings: "See bookings",
            trackPerformance: "Track performance",
            reviewStatements: "Review financial statements",
            requestMaintenance: "Request maintenance",
            editHomeInfo: "View your listing and local contact",
            viewHomeDetails: "View home details",
            taxForms: "Find your tax forms",
            reviewTaxForms: "Review tax forms",
            additionalServices: "Explore additional services",
            andMore: "…and more!",
        },
    },
    toDoList: {
        heading: "Your To-Do List",
        unit: "Unit",
        homeNotReady: "Preparing your listing",
        homeNotReadyDescription:
            "Stay tuned! We’ll let you know once your listing details and booking calendar are ready for editing.",
        items: {
            listing: "Listing",
            calendar: "Calendar",
            comingSoon: "Coming soon",
            view: "View",
            edit: "Edit",
            locked: "Under Review",
            notEditable: "This is no longer editable.",
        },
    },
    congrats: {
        title: "Your listing is live!",
        description:
            "Good news—your home is live on Vacasa.com, and you're all set to start getting bookings.",
        buttonText: "Check It Out",
    },
    navigationButtons: {
        back: "Back",
        clear: "Clear",
        next: "Next",
        finish: "Finish",
    },
    sectionDescriptions: {
        amenities:
            "Let us know what extra amenities your rental home includes. This information can help increase occupancy.",
        kitchen: "Does your home have a kitchen? Let your guests know below.",
        bathrooms:
            "Don’t forget to add your bathrooms to let your guests know what is available in your home.",
        parking:
            "Providing parking information for your property can help guests plan ahead for their trip and increase your booking and occupancy rates.",
    },
    finishModal: {
        title: "You’re all set!",
        description:
            "You've completed the listing details—thanks for your help! We'll let you know when your listing is live. Before your listing goes live, you can block off nights from being rented to guests (such as dates you'd like to use your home).",
        goToCalendarButton: "Go to Calendar",
        maybeLaterButton: "Maybe Later",
    },
    getStartedModal: {
        title: "Edit your listing details",
        primaryButtonTitle: "Get Started",
        secondaryButtonTitle: "Maybe Later",
    },
}

export default onboarding
