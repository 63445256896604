import { action, makeObservable, observable } from "mobx"
import { has, isEmpty, pick } from "lodash"
import * as Sentry from "@sentry/browser"
import { ENV, SENTRY_DSN, SENTRY_RELEASE_NAME } from "../Environment"
import { IgnoreError } from "./logging/logging.service"
import { IntlShape, createIntl } from "react-intl"

export class ErrorService {
    @observable hasUserContext = false
    @observable errorMessage = ""
    @observable errorStatus = ""
    @observable showError = false
    @observable intl: IntlShape = createIntl({ locale: "en" }) // set default instance that will be replaced once the app loads

    constructor() {
        makeObservable(this)
        this.init()
    }

    errorCodes = () => {
        return {
            // Owner hold errors
            4000: this.intl.formatMessage({
                id: "CalendarPage.errorMessages.generalError",
                defaultMessage:
                    "A problem prevented us from processing your request. Please try again later. If the problem persists, please call {name} on {phone}",
            }),
            4001: this.intl.formatMessage({
                id: "CalendarPage.errorMessages.datesNotAvailableError",
                defaultMessage:
                    "One or more of the dates for your hold are unavailable. Please check your selection and try again.",
            }),
            4002: this.intl.formatMessage({
                id: "CalendarPage.errorMessages.createHoldError",
                defaultMessage: "Failed to create your owner hold.",
            }),
            4003: this.intl.formatMessage({
                id: "CalendarPage.errorMessages.deleteHoldError",
                defaultMessage: "Failed to remove your owner hold.",
            }),
            4004: this.intl.formatMessage({
                id: "CalendarPage.errorMessages.updateHoldError",
                defaultMessage: "Failed to update your owner hold.",
            }),
            4005: this.intl.formatMessage({
                id: "CalendarPage.errorMessages.updateHoldCleanError",
                defaultMessage: "Failed to update your clean status.",
            }),
            4050: this.intl.formatMessage({
                id: "CalendarPage.errorMessages.addGuestToHoldError",
                defaultMessage: "Failed to add your guest.",
            }),
            4051: this.intl.formatMessage({
                id: "CalendarPage.errorMessages.removeGuestFromHoldError",
                defaultMessage: "Failed to remove your guest.",
            }),
            // Reserve balance errors
            4100: this.intl.formatMessage({
                id: "Settings.errorMessages.updateReserveBalanceError",
                defaultMessage: "Reserve balance too large.",
            }),
            4101: this.intl.formatMessage({
                id: "Settings.errorMessages.updateReserveBalanceNegativeError",
                defaultMessage: "Reserve balance can't be negative.",
            }),
            4102: this.intl.formatMessage({
                id: "Settings.errorMessages.updateReserveBalanceMissingError",
                defaultMessage: "Reserve balance amount is required.",
            }),
            // Direct deposit errors
            4200: this.intl.formatMessage({
                id: "Settings.errorMessages.paymentAccountGeneralError",
                defaultMessage:
                    "Something went wrong updating your account information, please try again later.",
            }),

            4201: this.intl.formatMessage({
                id: "Settings.errorMessages.sendDirectDepositEmailError",
                defaultMessage: "Failed to send Direct Deposit Unenroll email.",
            }),

            // Payment account errors
            4300: this.intl.formatMessage({
                id: "Settings.errorMessages.paymentAccountGeneralError",
                defaultMessage:
                    "Something went wrong updating your account information, please try again later.",
            }),

            4301: this.intl.formatMessage({
                id: "Settings.errorMessages.paymentAccountNameError",
                defaultMessage: "Account holder name must be set.",
            }),
            4302: this.intl.formatMessage({
                id: "Settings.errorMessages.paymentAccountNameLengthError",
                defaultMessage:
                    "Account holder name is greater than 100 characters.",
            }),
            4303: this.intl.formatMessage({
                id: "Settings.errorMessages.paymentAccountRoutingNumError",
                defaultMessage: "Routing number is invalid.",
            }),
            4304: this.intl.formatMessage({
                id: "Settings.errorMessages.paymentAccountNumError",
                defaultMessage: "Account number is invalid",
            }),
            4305: this.intl.formatMessage({
                id: "Settings.errorMessages.paymentAccountTypeError",
                defaultMessage: "Account type is invalid",
            }),
            4310: this.intl.formatMessage({
                id: "Settings.errorMessages.sendACHUpdateEmailError",
                defaultMessage: "Failed to send ACH update email.",
            }),
        }
    }

    @action mapErrorCodeToString = (
        serverErrorCode: number,
        msg: string | null = null
    ) => {
        // Prioritize using param msg, otherwise generate error message based on error code
        if (msg) {
            this.errorMessage = msg
        } else {
            const errorMessage = pick(this.errorCodes(), serverErrorCode)
            if (!isEmpty(errorMessage)) {
                this.errorMessage = Object.values(errorMessage)[0] || ""
            } else {
                this.errorMessage = this.intl.formatMessage({
                    id: "Settings.errorMessages.somethingWentWrong",
                    defaultMessage: "Something went wrong, please try again.",
                })
            }
        }
        return this.errorMessage
    }

    /*
        This function is used to initialize observable properties
        DO NOT initialize class properties outside of this function
    */
    @action init = async () => {
        this.errorMessage = ""
        this.errorStatus = ""
        this.showError = false
        this.hasUserContext = false
        const opts = {
            environment: ENV,
            release: SENTRY_RELEASE_NAME,
            sentry:
                SENTRY_DSN && SENTRY_RELEASE_NAME
                    ? {
                          dsn: SENTRY_DSN,
                          ignoreErrors: [
                              IgnoreError.NetworkError,
                              IgnoreError.SentrySDKError1,
                              IgnoreError.SentrySDKError2,
                          ],
                      }
                    : undefined,
        }

        Sentry.init({
            ignoreErrors: opts.sentry?.ignoreErrors,
            dsn: opts.sentry?.dsn,
            environment: opts.environment,
            release: opts.release,
        })
    }

    @action setErrorMsg = (err: string) => {
        this.errorMessage = err
    }

    @action setErrorStatus = (status: string) => {
        if (!this.errorStatus) {
            this.errorStatus = status
        }
    }

    @action displayError = () => (this.showError = true)

    @action hideError = () => {
        this.showError = false
    }

    @action parseMessage = (err: Error | string | unknown) => {
        let errorMessage: string
        if (err instanceof Error) {
            errorMessage = err.message
        } else if (typeof err === "string") {
            errorMessage = err
        } else {
            errorMessage = "An error has occured."
        }
        return errorMessage
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @action parseStatus = (err: any) => {
        let status = "ERROR"
        if (has(err, "status")) {
            status = `ERROR: ${err.status}`
        }
        return status
    }

    @action captureException = (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        error: any,
        contactId?: string,
        userEmail?: string
    ) => {
        Sentry.captureException(error, {
            tags: {
                contactID: contactId,
                userEmail: userEmail,
            },
        })
    }
}

const errorService = new ErrorService()

export default errorService
