import classnames from "classnames"
import { FC, PropsWithChildren } from "react"
import styles from "./Label.module.scss"

const Label: FC<PropsWithChildren> = ({ children }) => (
    <div
        className={classnames(
            styles.label,
            "font-bold text-xs uppercase tracking-wider"
        )}
    >
        {children}
    </div>
)

export { Label }
