import React, { useEffect, useState } from "react"
import classnames from "classnames"
import deepEqual from "deep-equal"
import { Amenities } from "@vacasa/owner-api-models"

import Panel from "../OnboardingPanel"
import SubmitButtonRow, {
    ButtonState,
} from "../../../lib/components/Onboarding/SubmitButtonRow"
import ConfirmButtonRow from "../../../lib/components/Onboarding/ConfirmButtonRow"
import RowWrapper, {
    RowType,
} from "../../../lib/components/Onboarding/RowWrapper"
import ErrorComponent from "../ErrorComponent"

import { ReactComponent as BathSVG } from "../../../assets/icon-bath.svg"
import styles from "../../../sass/onboarding.module.scss"
import { FormattedMessage } from "react-intl"
import { trackUpdateBathrooms } from "services/segment/onboarding/onboardingTracking"

interface BathroomsSectionProps {
    apiModel: Amenities
    confirmedBathrooms: boolean
    patchPreference: (preferenceName: string) => Promise<void>
    patchUnit: (data: any) => Promise<void> // eslint-disable-line @typescript-eslint/no-explicit-any
    saveBathroomsHandler: (enabled: boolean) => void
    unitId: string
}

const BathroomsSection: React.FC<BathroomsSectionProps> = ({
    apiModel,
    confirmedBathrooms,
    patchPreference,
    patchUnit,
    saveBathroomsHandler,
    unitId,
}: BathroomsSectionProps): JSX.Element => {
    const [errorSavingBathrooms, setErrorSavingBathrooms] = useState(false)
    const [saveBathroomsEnabled, setSaveBathroomsEnabled] = useState(
        ButtonState.Disabled
    )
    const apiBathrooms = apiModel.rooms.bathrooms
    const [tempBathrooms, setTempBathrooms] = useState(apiBathrooms)

    useEffect(() => {
        setSaveBathroomsEnabled(
            !deepEqual(tempBathrooms, apiBathrooms)
                ? ButtonState.Enabled
                : ButtonState.Disabled
        )
    }, [apiBathrooms, tempBathrooms])

    useEffect(() => {
        saveBathroomsHandler(!deepEqual(tempBathrooms, apiBathrooms))
    }, [apiBathrooms, saveBathroomsHandler, tempBathrooms])

    const hasData = tempBathrooms.full > 0 || tempBathrooms.half > 0
    const noChanges = saveBathroomsEnabled === ButtonState.Disabled
    const shouldShowConfirmButton = hasData && noChanges && !confirmedBathrooms

    const fullBathsSlug = (
        <FormattedMessage
            id="Onboarding.bathrooms.full"
            defaultMessage="{count, plural, one {# Bathroom} other {# Bathrooms}}"
            values={{
                count: tempBathrooms.full,
            }}
        />
    )

    const halfBathsSlug = (
        <FormattedMessage
            id="Onboarding.bathrooms.half"
            defaultMessage="{count, plural, one {# Half bath} other {# Half baths}}"
            values={{
                count: tempBathrooms.half,
            }}
        />
    )

    const dot = (
        <span style={{ margin: "0 6px" }}>{String.fromCharCode(9679)}</span>
    )

    function resetBathrooms(): void {
        setSaveBathroomsEnabled(ButtonState.Disabled)
        setTempBathrooms(apiBathrooms)
    }

    return (
        <div>
            <h5>
                <FormattedMessage
                    id="Onboarding.sectionTitles.bathrooms"
                    defaultMessage="Bathrooms"
                />
            </h5>
            <p className={styles.section_description}>
                <FormattedMessage
                    id="Onboarding.sectionDescriptions.bathrooms"
                    defaultMessage="Don’t forget to add your bathrooms to let your guests know what is available in your home."
                />
            </p>
            <div className={styles.user_selection_group}>
                <div
                    className={classnames([
                        styles.user_selection,
                        tempBathrooms.full === 0 && tempBathrooms.half === 0
                            ? styles.not_set
                            : "",
                    ])}
                >
                    <BathSVG className={styles.amenity_icon} />
                    {tempBathrooms.half > 0 ? (
                        <>
                            {fullBathsSlug} {dot} {halfBathsSlug}
                        </>
                    ) : (
                        fullBathsSlug
                    )}
                </div>
            </div>
            {errorSavingBathrooms ? (
                <ErrorComponent
                    onClick={(): void => setErrorSavingBathrooms(false)}
                />
            ) : (
                <Panel className={styles.panel_content}>
                    <RowWrapper
                        type={RowType.Incrementer}
                        title={
                            <FormattedMessage
                                id="Onboarding.rooms.bathrooms"
                                defaultMessage="Bathrooms"
                            />
                        }
                        selectedValue={tempBathrooms.full}
                        maxValue={10}
                        onChangeHandler={(
                            value: number | null | undefined
                        ): void =>
                            setTempBathrooms({
                                ...tempBathrooms,
                                full: value ?? 0,
                            })
                        }
                    />
                    <RowWrapper
                        type={RowType.Incrementer}
                        title={
                            <FormattedMessage
                                id="Onboarding.rooms.halfBaths"
                                defaultMessage="Half baths"
                            />
                        }
                        selectedValue={tempBathrooms.half}
                        maxValue={10}
                        onChangeHandler={(
                            value: number | null | undefined
                        ): void =>
                            setTempBathrooms({
                                ...tempBathrooms,
                                half: value ?? 0,
                            })
                        }
                        lastRow={true}
                    />
                    {shouldShowConfirmButton ? (
                        <ConfirmButtonRow
                            buttonState={ButtonState.Enabled}
                            onSubmit={(): void => {
                                patchPreference(
                                    "onboarding-confirmed-bathrooms"
                                )
                            }}
                        />
                    ) : (
                        <SubmitButtonRow
                            buttonState={saveBathroomsEnabled}
                            onSubmit={(): void => {
                                setSaveBathroomsEnabled(ButtonState.Loading)
                                Promise.all([
                                    patchUnit({
                                        rooms: {
                                            ...apiModel.rooms,
                                            bathrooms: tempBathrooms,
                                        },
                                    }),
                                    patchPreference(
                                        "onboarding-confirmed-bathrooms"
                                    ),
                                ])
                                    .then(() => {
                                        return trackUpdateBathrooms(
                                            tempBathrooms,
                                            apiBathrooms,
                                            unitId
                                        )
                                    })
                                    .catch(() => {
                                        setErrorSavingBathrooms(true)
                                        // reset tempBathrooms to the original value as patch failed
                                        resetBathrooms()
                                    })
                            }}
                            onCancel={(): void => resetBathrooms()}
                        />
                    )}
                </Panel>
            )}
        </div>
    )
}

export default BathroomsSection
