import Layout from "../Layout/Layout"
import { FormattedMessage } from "react-intl"
import { ProgressBar } from "../ProgressBar"
import { NavigationButtons } from "../NavigationButtons"
import React, { useEffect, useRef, useState } from "react"
import useRouter from "../../../../hooks/common/useRouter"
import { Model, Question } from "survey-core"
import { Survey as SurveyJS } from "survey-react-ui"
import { SurveyMutationParam, useSurveyMutation } from "../../../../hooks/survey"
import { surveyTheme } from "./config/theme"
import { debounce } from "lodash"
import {
    trackRevenuePreferenceSteps,
    trackRevenuePreferenceSubmit,
} from "../../../../services/segment/survey/surveyTracking"
import LoggingService from "../../../../services/logging/logging.service"

import style from "../SurveyFlyout.module.scss"
import "survey-core/defaultV2.min.css"

interface SurveyProps {
    surveyModel: Model
    unitId: string
    contactId: string
    submitError: (value: boolean) => void
    isWebView: boolean
}

export enum SurveyAction {
    NEXT = "NEXT",
    BACK = "BACK",
    SUBMIT = "SUBMIT"
}

const Survey = ({ surveyModel, unitId, contactId, submitError, isWebView }: SurveyProps) => {
    const router = useRouter()
    const surveyMutation = useSurveyMutation(unitId)
    const [currentStep, setCurrentStep] = useState<number>(1)
    const [forceLoading, setForceLoading] = useState<boolean>(false)
    const [hideSurvey, setHideSurvey] = useState<boolean>(false)
    const [isNextDisabled, setIsNextDisabled] = useState(true)
    const surveyRef = useRef<HTMLDivElement>(null)
    const totalSteps = surveyModel.pageCount

    useEffect(() => {
        if (surveyRef.current) {
            // Remove button from SurveyJS
            const contentButton = surveyRef.current.querySelector<HTMLInputElement>(".sv-components-column.sv-components-container-contentBottom") as HTMLElement
            if (contentButton) {
                contentButton.style.display = "none"
            }
        }
        // Apply the custom theme
        surveyModel.applyTheme(surveyTheme)

        // Apply default grey border to 'pricing' question
        surveyModel.onAfterRenderQuestion.add((survey, options) => {
            if (options.question.name === "pricing") {
                const questionElement = options.htmlElement;

                if (questionElement) {
                    const choices = questionElement.querySelectorAll(".sd-item.sd-radio.sd-selectbase__item");

                    choices.forEach((choice, _index) => {
                        const textElement = choice.querySelector(".sv-string-viewer") as HTMLHeadingElement | null;

                        if (textElement) {
                            const [title, description] = (textElement.innerText || '').split(" - ");
                            textElement.innerHTML = `
                            <div class="${style['option-content']}">
                                <span class="${style['option-title']}">${title}</span>
                                <span class="${style['option-description']}">${description?.trim()}</span>
                            </div>
                        `
                        }
                    });
                }
            }
        })

        // Custom css to specific question
        surveyModel.onValueChanging.add((survey, options) => {
            if (options.name === "pricing") {
                options.question.cssClasses.itemChecked += ` ${style["question-content-checked"]}`
            }
        })

        // Customize the "Thank you" page HTML
        surveyModel.completedHtml = `
            <div>
                <h3>
                    Congrats on setting your revenue strategy!
                </h3>
                <p>
                    Our team is working to implement your requests.
                </p>
            </div>
          `
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        surveyModel.onValueChanged.add((sender, _options) => {
            checkIfPageIsAnswered(sender)
        })

        surveyModel.onCurrentPageChanged.add((sender, _options) => {
            checkIfPageIsAnswered(sender)
        })

        const checkIfPageIsAnswered = (survey: Model) => {
            const currentPage = survey.currentPage
            // The 'some' method is used because the last page contains an HTML type question meant only for displaying information.
            // If there are multiple questions per page, consider using 'every' instead to ensure all questions meet the required conditions.
            const allQuestionsAnswered = currentPage.questions.some((q: Question) => {
                if (q.getType() === "radiogroup" || q.getType() === "dropdown") {
                    return q.isAnswered;
                } else if (q.getType() === "text" || q.getType() === "comment" || q.getType() === "inputnumber") {
                    return q.value !== null && q.value !== undefined;
                } else {
                    return q.isAnswered;
                }
            })
            setIsNextDisabled(!allQuestionsAnswered)
        };

        checkIfPageIsAnswered(surveyModel)

        return () => {
            surveyModel.onValueChanged.remove(checkIfPageIsAnswered);
            surveyModel.onCurrentPageChanged.remove(checkIfPageIsAnswered);
        }
    }, [surveyModel])

    const handleSurveyCompletion = () => {
        document.body.style.overflow = "auto"

        if (isWebView) {
            // Note: Mobile apps may not detect redirects using `useRouter` in WebViews,
            // as client-side navigation doesn't change the browser in a detectable way.
            window.location.href = `/forward?view=preference&UnitID=${unitId}&contact_id=${contactId}`
        } else {
            router.navigate({ pathname: "/performance" })
        }
    }

    const handleClick = (action: SurveyAction) => {
        const isPageValid = surveyModel.validatePage();
        switch (action) {
            case SurveyAction.NEXT:
                if (!isNextDisabled && isPageValid) {
                    surveyModel.nextPage()
                    setCurrentStep((prevStep) => {
                        const nextStep = prevStep + 1
                        trackRevenuePreferenceSteps(nextStep)
                        return nextStep
                    })
                }
                break
            case SurveyAction.BACK:
                surveyModel.prevPage()
                setCurrentStep((prevStep) => {
                    const nextStep = prevStep - 1
                    trackRevenuePreferenceSteps(nextStep)
                    return nextStep
                })
                break
            case SurveyAction.SUBMIT:
                if (isPageValid) handleSubmitSurvey()
                break
        }
    }

    const handleSubmitSurvey = () => {
        setForceLoading(true)
        const submissionData: SurveyMutationParam = {
            surveyId: "revenue-persona-questions",
            unitId: unitId,
            contactId: contactId,
            surveyRequest: surveyModel.data,
        }
        surveyMutation.mutate(submissionData, {
            onSuccess: () => {
                trackRevenuePreferenceSubmit()
                LoggingService.log({
                    message: "The revenue preference survey was successfully submitted.",
                    context: { unitId, contactId },
                })
                setTimeout(() => {
                    handleSurveyCompletion()
                }, 2000)
            },
            onError: () => {
                setHideSurvey(true)
                submitError(true)
                LoggingService.error({
                    message: "The revenue preference survey failed to submit.",
                    context: { unitId, contactId },
                })
                setTimeout(() => {
                    handleSurveyCompletion()
                }, 2000)
            },
        })
    }
    const onClickNext = () => handleClick(SurveyAction.NEXT)
    const onClickBack = () => handleClick(SurveyAction.BACK)
    const onClickSubmit = debounce(() => {
        handleClick(SurveyAction.SUBMIT)
    }, 300)

    return (
        <>
            <Layout className={`${hideSurvey ? style.hidden : ""}`}>
                <Layout.Header>
                    <div className={style.title}>
                        <FormattedMessage
                            id="Survey.title"
                            defaultMessage="RATES & REVENUE"
                        />
                    </div>
                    <h3>
                        <FormattedMessage
                            id="Survey.subTitle"
                            defaultMessage="Set revenue strategy"
                        />
                    </h3>
                    <ProgressBar step={currentStep} totalSteps={totalSteps} />
                </Layout.Header>
                <Layout.Content>
                    <div className={style["survey-js-container"]} ref={surveyRef} data-testid={"survey-component"}>
                        <SurveyJS model={surveyModel} />
                    </div>
                </Layout.Content>
                <Layout.Footer>
                    <NavigationButtons
                        handleBack={onClickBack}
                        handleNext={onClickNext}
                        handleSubmit={onClickSubmit}
                        handleCancel={handleSurveyCompletion}
                        currentStep={currentStep}
                        isLastStep={currentStep === totalSteps}
                        loading={forceLoading}
                        isNextDisabled={isNextDisabled}
                    />
                </Layout.Footer>
            </Layout>
        </>
    )
}

export { Survey }