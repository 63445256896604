import { AxiosError } from "axios"
import { useContactId } from "hooks/user"
import {
    fetchReviewsByUnitId,
    ReviewsResponse,
} from "hooks/review/fetchReviews"
import { useQuery, UseQueryResult } from "react-query"

export const useUnitReviews = (
    unitId: string | null
): UseQueryResult<ReviewsResponse, AxiosError> => {
    const contactId = useContactId()
    return useQuery(
        ["unit-reviews", contactId, unitId],
        ({ signal }) =>
            fetchReviewsByUnitId({
                contactId: contactId ?? "",
                unitId: unitId ?? "",
                signal,
            }),
        {
            enabled: !!contactId && !!unitId,
        }
    )
}
