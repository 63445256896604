import { W9Address } from "@vacasa/owner-api-models"
import { MailingAddress, W9Data } from "./types"
import { MessageDescriptor } from "react-intl"
import { W9Request } from "hooks/finances"

export const toMailingAddress = (w9Address?: W9Address): MailingAddress => {
    return {
        addressLine1: w9Address?.address1 ?? "",
        addressLine2: w9Address?.address2 ?? "",
        city: w9Address?.city ?? "",
        postalCode: w9Address?.zip ?? "",
        stateOrProvince: w9Address?.state ?? "",
    }
}

export const getTaxClassificationMessageDescriptor = (
    classification: string
): MessageDescriptor => {
    switch (classification) {
        case "INP":
            return {
                id: "Taxes.w9.taxClassification.INP",
                defaultMessage:
                    "Individual/sole proprietor or single-member LLC",
            }
        case "LLC":
            return {
                id: "Taxes.w9.taxClassification.LLC",
                defaultMessage: "Limited liability company",
            }
        case "CCO":
            return {
                id: "Taxes.w9.taxClassification.CCO",
                defaultMessage: "C Corporation",
            }
        case "SCO":
            return {
                id: "Taxes.w9.taxClassification.SCO",
                defaultMessage: "S Corporation",
            }
        case "PRT":
            return {
                id: "Taxes.w9.taxClassification.PRT",
                defaultMessage: "Partnership",
            }
        case "TRE":
            return {
                id: "Taxes.w9.taxClassification.TRE",
                defaultMessage: "Trust/estate",
            }
        case "OTH":
            return {
                id: "Taxes.w9.taxClassification.OTH",
                defaultMessage: "Other",
            }
        default:
            return {
                id: "Taxes.w9.taxClassification.invalid",
                defaultMessage: "Invalid Tax Classification",
            }
    }
}

export const getLLCTaxClassificationMessageDescriptor = (
    classification: string
): MessageDescriptor => {
    switch (classification) {
        case "C":
            return {
                id: "Taxes.w9.llcTaxClassfications.INP",
                defaultMessage: "C Corporation",
            }
        case "S":
            return {
                id: "Taxes.w9.llcTaxClassfications.S",
                defaultMessage: "S Corporation",
            }
        case "P":
            return {
                id: "Taxes.w9.llcTaxClassfications.P",
                defaultMessage: "Partnership",
            }

        default:
            return {
                id: "Taxes.w9.llcTaxClassfications.invalid",
                defaultMessage: "Invalid LLC Tax Classification",
            }
    }
}

export const getTaxIDTypeMessageDescriptor = (type: string) => {
    switch (type) {
        case "SSN":
            return {
                id: "Taxes.w9.taxIDType.SSN",
                defaultMessage: "SSN (Social Security Number)",
            }
        case "EIN":
            return {
                id: "Taxes.w9.taxIDType.EIN",
                defaultMessage: "EIN (Employer Identification Number)",
            }
        default:
            return {
                id: "Taxes.w9.taxIDType.invalid",
                defaultMessage: "Invalid Tax ID type",
            }
    }
}

/**
 * Converts the W9Data to W9Request
 * Copied from: https://github.com/Vacasa/owner-onboarding-web/blob/main/src/utils/w9Util.ts#L9
 * @param data
 * @returns {W9Request}
 */
export const toW9Request = (data: W9Data): W9Request => {
    const {
        addressLine1: address1,
        addressLine2: address2,
        city,
        stateOrProvince: state,
        postalCode: zip,
    } = data.mailingAddress

    const signature = data.agreeAndSign.signature
    const {
        SSN: ssn,
        EIN: ein,
        entityName: name,
        businessName,
        entityType: taxClassificationDescription,
        taxIDType,
    } = data.taxInfo
    const { type: taxClassificationType, llcType: taxClassification } =
        data.taxClassification

    const result: W9Request = {
        name,
        businessName,
        taxClassificationType,
        address: {
            address1,
            address2,
            city,
            state,
            zip,
        },
        signature,
    }

    if (taxIDType === "SSN") {
        result.ssn = ssn
    } else {
        result.ein = ein
    }
    if (taxClassificationType === "LLC") {
        result.taxClassification = taxClassification
    } else if (taxClassificationType === "OTH") {
        result.taxClassificationDescription = taxClassificationDescription
    }
    return result
}
