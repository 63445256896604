
import {authAxios} from "../../api/authAxios";

export type PostTicketCommentReadReceiptRequest = {
    contactId: string
    unitId: string,
    commentIds:string[]
}



 const postTicketCommentReadReceipt = (requestData:PostTicketCommentReadReceiptRequest):Promise<number> =>{

    const ticketCommentReadReceiptData={
        data:{
            type: "ticket-comment-read-receipts",
            attributes: {
                commentIds:requestData.commentIds,
            },
        }

    }

    return authAxios
        .post<number>(`/v1/ticket-comment-read-receipts`, ticketCommentReadReceiptData, {
            params: {
                contactId:requestData.contactId,
                unitId:requestData.unitId,
            },
        })
        .then(response => response.status)
}

export {postTicketCommentReadReceipt}