import axios from "axios"
import LoggingService from "../services/logging/logging.service"

const logErrorToSentry = (error: unknown): unknown => {
    if (axios.isAxiosError(error)) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            LoggingService.addSentryBreadcrumb({
                level: "info",
                category: "axios",
                type: "error",
                message: error.message,
                data: {
                    code: error.code,
                    status: error.response.status,
                    data: JSON.stringify(error.response.data),
                },
            })
        } else if (error.request) {
            // The request was made but no response was received
            LoggingService.addSentryBreadcrumb({
                level: "info",
                category: "axios",
                type: "error",
                message: error.message,
                data: {
                    code: error.code,
                    request: error.request,
                },
            })
        } else {
            // Something happened in setting up the request that triggered an Error
            LoggingService.addSentryBreadcrumb({
                level: "info",
                category: "axios",
                type: "error",
                message: error.message,
                data: {
                    code: error.code,
                    config: JSON.stringify(error.config),
                },
            })
        }
    } else {
        // A non Axios error happened
        LoggingService.addSentryBreadcrumb({
            level: "info",
            category: "axios",
            type: "error",
            message: "Unexpected error",
            data: {
                error: JSON.stringify(error),
            },
        })
    }
    return Promise.reject(error)
}

export { logErrorToSentry }
