import React from "react"
import { FormattedMessage } from "react-intl"
import styles from "./GuestReviewListItem.module.scss"

type ReviewResponseProps = {
    response: string
}

export const GuestReviewResponse: React.FC<ReviewResponseProps> = ({
    response,
}) => {
    return (
        <div className={styles.responseContainer}>
            <h4>
                <FormattedMessage
                    id="CalendarPage.flyout.response"
                    defaultMessage="Manager response"
                />
            </h4>
            <p>{response}</p>
        </div>
    )
}
