import { RefObject, useEffect } from "react"

// reference: https://usehooks.com/useOnClickOutside/
export default function useOnClickOutside(
    ref: RefObject<HTMLElement>,
    handler: (event: MouseEvent | TouchEvent) => void,
    condition?: () => boolean
): void {
    useEffect(() => {
        const listener = (event: MouseEvent | TouchEvent): void => {
            /**
             * Do nothing if condition not met or
             * clicking ref's element or descendent elements
             */
            if (
                (condition && !condition()) ||
                !ref.current ||
                ref.current.contains(event.target as Node)
            ) {
                return
            }

            handler(event)
        }

        document.addEventListener("mousedown", listener)
        document.addEventListener("touchstart", listener)

        return (): void => {
            document.removeEventListener("mousedown", listener)
            document.removeEventListener("touchstart", listener)
        }
    }, [ref, handler, condition])
}
