import {
    displayFormattedNumber,
    formatDate,
} from "utils/statements/statementsUtil"
import { FormattedMessage, IntlShape, useIntl } from "react-intl"
import { Summary } from "../UnitSummary"
import styles from "../statements.module.scss"

const displayPayments = (
    intl: IntlShape,
    currency: string,
    summary: Summary
) => {
    let paymentType: number | string
    let description: string
    if (!summary || !summary.expenses) {
        return null
    }
    return summary.expenses.map(expense => {
        if (expense.payee_account_id !== 0) {
            return null
        }
        let paymentAmount = expense.credit
        // if expense has a negative debit, treat that as a payment to the owner
        if (
            paymentAmount === null &&
            expense.debit != null &&
            Number(expense.debit) < 0
        ) {
            paymentAmount = expense.debit
        }
        // When account_num is 0, don't show it in description
        if (expense.account_num) {
            description = intl.formatMessage({
                id: "Statements.paymentDescription",
                defaultMessage: "Payment to account ending in",
            })
            paymentType = expense.account_num
            if (Number(paymentAmount) > 0) description = expense.description
        } else if (expense.check_number) {
            description = intl.formatMessage({
                id: "Statements.checkNumber",
                defaultMessage: "Check #",
            })
            paymentType = expense.check_number
        } else {
            paymentType = expense.description
        }
        const postDate = formatDate(expense.posting_date)
        return (
            <tr key={expense.id}>
                <td className="gutter" />
                <td className="date">{postDate}</td>
                <td colSpan={4} className="desc-span">
                    {description} {paymentType}
                </td>
                <td className="text-right">
                    {displayFormattedNumber(Number(paymentAmount), currency)}
                </td>
                <td className={styles["gutter-end"]} />
            </tr>
        )
    })
}

interface UnitDetailsPaymentsProps {
    currency: string
    summary: Summary
}

const UnitDetailsPayments: React.FC<UnitDetailsPaymentsProps> = props => {
    const { currency, summary } = props
    const intl = useIntl()
    // It looks like total owner payments doesn't include non-credits.
    // This is a work around to include negative debits in total payments to owners
    const totalOwnerPayments =
        summary.total_owner_payments && summary.total_owner_payments !== null
            ? summary.total_owner_payments
            : summary.total_payments

    return (
        <>
            <div className="table-responsive">
                <table className="open table">
                    <thead className={styles["uppercase-header"]}>
                        <tr>
                            <td className={styles["inset"]} />
                            <td colSpan={5}>
                                <FormattedMessage
                                    id="Statements.payments"
                                    defaultMessage="Payments"
                                />
                            </td>
                            <td colSpan={2} className="text-right">
                                {displayFormattedNumber(
                                    Number(totalOwnerPayments),
                                    currency
                                )}
                            </td>
                            <td className={styles["inset"]} />
                        </tr>
                    </thead>
                </table>
            </div>
            <div className="table-responsive">
                <table className="open table">
                    <tbody
                        className={
                            parseInt(totalOwnerPayments) !== 0 ||
                            displayPayments(intl, currency, summary)
                                ? "show"
                                : styles["hide"]
                        }
                    >
                        {displayPayments(intl, currency, summary)}
                    </tbody>
                    <tfoot
                        className={
                            parseInt(totalOwnerPayments) !== 0
                                ? ""
                                : styles["hide"]
                        }
                    >
                        <tr>
                            <td className="gutter" />
                            <td colSpan={5} className="total-span">
                                <strong>
                                    <FormattedMessage
                                        id="Statements.total"
                                        defaultMessage="Total"
                                    />
                                </strong>
                            </td>
                            <td className="text-right">
                                <strong>
                                    {displayFormattedNumber(
                                        Number(totalOwnerPayments),
                                        currency
                                    )}
                                </strong>
                            </td>
                            <td className={styles["gutter-end"]} />
                        </tr>
                    </tfoot>
                </table>
            </div>
        </>
    )
}

export default UnitDetailsPayments
