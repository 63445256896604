import React from "react"
import { Row, Col } from "reactstrap"
import { Secondary } from "../Buttons/BaseButton"
import { FormattedMessage } from "react-intl"

import { ReactComponent as PlusIcon } from "../../../assets/icon-plus-circle.svg"
import styles from "../../../sass/onboarding.module.scss"

interface Props {
    onClick?: () => void
}

const AddRoomButtonRow: React.FC<Props> = ({ onClick }: Props): JSX.Element => {
    return (
        <Row>
            <Col>
                <div className={`${styles.add_room_button}`}>
                    <Secondary
                        typeOf={"link"}
                        ariaLabel={"Add room"}
                        iconDirection="left"
                        onClick={onClick}
                    >
                        <>
                            <PlusIcon className={styles.link_icon} />
                            <FormattedMessage
                                id="Onboarding.sleepingArrangements.addRoomButton"
                                defaultMessage="Add Room"
                            />
                        </>
                    </Secondary>
                </div>
            </Col>
        </Row>
    )
}
export default AddRoomButtonRow
