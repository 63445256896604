import { isEmpty } from "lodash"

export default function getInitialFromName(
    name: string | null | undefined
): string | null {
    if (isEmpty(name) || !name) {
        return null
    } else {
        return `${name.charAt(0)}`
    }
}
