/* eslint-disable camelcase */

import segmentService from "../segment.service"
import {
    WEBCHAT_ClOSED,
    WEBCHAT_LOADED,
    WEBCHAT_OPENED,
} from "./webchatTrackingConstants"

export const trackEntryPointClicked = (options: {
    expanded: boolean
    withinSupportHours: boolean
}) => {
    segmentService.track(options.expanded ? WEBCHAT_OPENED : WEBCHAT_ClOSED, {
        ...options,
        location: "entry point",
    })
}

export const trackWebchatClosed = (options: {
    withinSupportHours: boolean
}) => {
    segmentService.track(WEBCHAT_ClOSED, {
        ...options,
        location: "close icon",
    })
}

export const trackWebchatLoaded = () => {
    segmentService.track(WEBCHAT_LOADED, {})
}
