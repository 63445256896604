import reservationStrings from "./reservation"

const dashboard = {
    title: "Dashboard",
    charts: {
        grossRent: {
            title: "Gross Rent",
            emptyMessage:
                "YTD gross revenue data will appear here once you receive reservations.",
            tabName: "Gross Rent",
        },
        marketRate: {
            alert: {
                title: "NEW! Market Rates",
                body: "You've been invited to the beta of a brand new account feature.\n\nSee how your home's rates compare with other similar vacation rentals in your market.",
            },
            tabName: "Rate Comparison",
            title: "Market Rate Comparison",
            description:
                "Hover over the chart to see how your home's nightly rate compares to other vacation rentals in your area with <strong>similar size and features.</strong>",
            emptyMessage:
                "Local market data is currently unavailable. Check back soon to compare your home to your market's highs and lows.",
            feedback: {
                title: "New! Market rate comparison Chart",
                ratingsBody:
                    "You can now view how your home is priced in relation to other comparable properties in your area.\n\nDo you find this new Market Rate Comparison feature helpful?",
                commentsBody: "Thanks for your feedback!\nCare to share more?",
            },
            tooltips: {
                bookingProbability: {
                    title: "Probability of Booking",
                    high: "High",
                    medium: "Med",
                    low: "Low",
                },
                description: {
                    title: "Market Data",
                    body: "We source and analyze trusted 3rd-party data which provides advertised rates in real time from sites like Airbnb and Vrbo to inform the pricing of your home.",
                },
                marketRange: {
                    title: "Market Range",
                    body: "The shaded blue area shows the minimum and maximum nightly rates for homes in your area that guests are most likely to compare to yours. This data updates daily.",
                },
                beta: {
                    title: "Early Access Testing",
                    body: "This new chart is currently in beta testing with a small group of homeowners. As we fine-tune, you may see some unusual data conditions. If you have any questions or concerns, please reach out to a <a>Rates Specialist</a> for more information.",
                },
                yourHome: {
                    title: "Your Home",
                    body: "The blue line represents your home's daily rate. It may be higher or lower than comparable homes based on factors like your home's minimum rate, local market conditions and demand, along with many others.",
                },
                rates: {
                    bookedRate: "Booked Rate",
                    similarHomes: "Similar Homes",
                    yourRate: "Your Rate",
                },
            },
            legend: {
                guest: reservationStrings.type.guest,
                guestStay: reservationStrings.type.guestStay,
                marketRange: "Market Range",
                owner: reservationStrings.type.owner,
                ownerHold: reservationStrings.type.ownerHold,
                vacsaa: reservationStrings.type.vacasa,
                vacasaHold: reservationStrings.type.vacasaHold,
                yourHome: "Your Home",
            },
            submitQuestion: {
                text: "Have a question?",
                action: "Ask a Rates Specialist",
            },
            productTour: {
                badge: "New Product Features",
                slides: {
                    1: {
                        title: "Compare your home to the market",
                        body: "Refreshed daily, this tool lets you compare your home's nightly rate with similar homes in your area.",
                    },
                    2: {
                        title: "Explore the latest market data",
                        body: "Browse current pricing for similar homes on sites like Airbnb and Vrbo - the same data we use to set your home's nightly rate.",
                    },
                    3: {
                        title: "Make the most of your home",
                        body: "Track seasonal changes to plan how you want to use your home.",
                    },
                },
            },
        },
        netRent: {
            tabName: "Net Rent",
            title: "Net Rent",
            emptyMessage:
                "YTD net revenue data will appear here once you receive reservations.",
        },
    },
    rateCalculator: {
        title: "Explore Minimum Rates",
        description:
            "Use the slider to explore the revenue impact of Minimum Rates over the next 12 months, whether you have one set or not.",
        emptyMessage:
            "Local market data is currently unavailable. Check back soon to explore Minimum Rates.",
        feedback: {
            title: "New! Explore Minimum Rates",
            ratingsBody: "Do you find this new feature helpful?",
            commentsBody: "Thanks for your feedback!\nCare to share more?",
        },
        minRate: {
            title: "Minimum Rate",
            tooltip: {
                title: "Minimum Rate",
                body: "This number represents the minimum rate for your specific home being analyzed by this tool.",
            },
            legend: {
                title: "Min Rate",
            },
        },
        marketRange: {
            tooltip: {
                title: "Market Range",
                body: "The shaded blue area shows the minimum and maximum nightly rates for homes in your area that guests are most likely to compare to yours. This data updates daily.",
            },
            legend: {
                title: "Similar Homes",
            },
        },
        revenueImpact: {
            title: "Potential Impact",
            tooltip: {
                title: "Potential Impact",
                body: "The estimated change to your home’s potential rent revenue over the next 12 months, if the analyzed minimum rate is applied.",
            },
        },
        yourRate: {
            title: "Your Rate",
        },
    },
    coreMetrics: {
        notAvailable: "Not available",
        minRate: "Minimum rate",
        minRateDescription:
            "This is the lowest rate we will offer for your home to attract bookings at the last minute or in periods of decreased demand.",
        avgBookedRate: "Avg daily rate",
        avgBookedRateDescription:
            "This is the average total rent paid for nights booked over the next 12 months, including nightly rates and guest fees.",
        occupancy: "Occupancy",
        occupancyDescription:
            "This is the ratio of nights booked to the number of nights available over the next 12 months, excluding owner holds.",
        minRateEmptyStateDescription:
            "Your home’s minimum rate is currently unavailable",
        genericEmptyStateDescription:
            "Local market data is currently unavailable.",
    },
    revenuePreferences: {
        title: "Set your revenue strategy",
        description: "Help us understand your goals and expectations regarding revenue, pricing, and occupancy, so we can make sure your unit is set up for success.",
        takeSurvey: "Set strategy",
        tanksTitle: "Congrats on setting your revenue strategy!",
        tanksMessage: "Our team is working to implement your requests."
    }
}
export default dashboard
