import styles from "./Legend.module.scss"
import classNames from "classnames"
import { Tooltip } from "lib/components/Tooltip/Tooltip"
import { FormattedMessage, useIntl } from "react-intl"
import { Info } from "react-feather"

export const Legend: React.FC = () => {
    const intl = useIntl()

    return (
        <div className={styles.legend}>
            <div className={styles.legendKeyItem}>
                <div
                    className={classNames(
                        styles.legendKey,
                        styles.legendKeyHome
                    )}
                />
                <div className="type-heading-extra-small-caps">
                    <div className="flex items-center">
                        <FormattedMessage
                            id="Dashboard.rateCalculator.yourRate.legend.title"
                            defaultMessage="Your Rate"
                        />
                    </div>
                </div>
            </div>
            <div className={styles.legendKeyItem}>
                <div
                    className={classNames(
                        styles.legendKey,
                        styles.legendKeyMarket
                    )}
                />
                <div className="type-heading-extra-small-caps">
                    <div
                        className={classNames(
                            "flex items-center",
                            styles.marketRangeLegend
                        )}
                    >
                        <FormattedMessage
                            id="Dashboard.rateCalculator.marketRange.legend.title"
                            defaultMessage="Similar Homes"
                        />
                        <div
                            className={classNames(
                                styles.tooltipContainer,
                                styles.marketRangeTooltip
                            )}
                        >
                            <Tooltip
                                title={intl.formatMessage({
                                    id: "Dashboard.rateCalculator.marketRange.tooltip.title",
                                    defaultMessage: "Market Range",
                                })}
                                body={
                                    <FormattedMessage
                                        id="Dashboard.rateCalculator.marketRange.tooltip.body"
                                        defaultMessage="The shaded blue area shows the minimum and maximum nightly rates for homes in your area that guests are most likely to compare to yours. This data updates daily."
                                    />
                                }
                            >
                                <Info
                                    className={styles.tooltipTrigger}
                                    width={16}
                                    height={16}
                                />
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.legendKeyItem}>
                <div
                    className={classNames(
                        styles.legendKey,
                        styles.legendKeyMinRate
                    )}
                />
                <div className="type-heading-extra-small-caps">
                    <div
                        className={classNames(
                            "flex items-center",
                            styles.minRateLegend
                        )}
                    >
                        <FormattedMessage
                            id="Dashboard.rateCalculator.minRate.legend.title "
                            defaultMessage="Min Rate"
                        />
                        <div
                            className={classNames(
                                styles.tooltipContainer,
                                styles.minRateTooltip
                            )}
                        >
                            <Tooltip
                                title={intl.formatMessage({
                                    id: "Dashboard.rateCalculator.minRate.tooltip.title",
                                    defaultMessage: "Minimum Rate",
                                })}
                                body={
                                    <FormattedMessage
                                        id="Dashboard.rateCalculator.minRate.tooltip.body"
                                        defaultMessage="This number represents the minimum rate for your specific home being analyzed by this tool."
                                    />
                                }
                            >
                                <Info
                                    className={styles.tooltipTrigger}
                                    width={16}
                                    height={16}
                                />
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
