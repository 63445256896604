import styles from "./CoreMetricsEmptyState.module.scss"
import { ReactComponent as EmptyStateImage } from "../../PerformanceEmptyState.svg"
export const CoreMetricsEmptyState = ({
    message,
    title,
}: {
    message: string
    title: string
}) => {
    return (
        <div className={styles.content}>
            <div className={styles.img}>
                <EmptyStateImage />
            </div>
            <div className={styles.title}>{title}</div>
            <p>{message}</p>
        </div>
    )
}
