import { GUESTWORKS_URL } from "Environment"
import { ReactComponent as GuestworksIcon } from "assets/soap-bucket.svg"
import React from "react"
import { useIntl } from "react-intl"
import { SegmentLocation } from "services/segment/constants"
import { trackGuestworksLinkClicked } from "services/segment/guestworks/guestworksTracking"
import { ReactComponent as ChevronRight } from "../../assets/icon-chevron-right.svg"
import styles from "./GuestworksLink.module.scss"

export const GuestworksLink: React.FC = () => {
    const intl = useIntl()
    const label = intl.formatMessage({
        id: "Guestworks.guestworks",
        defaultMessage: "Guestworks",
        description: `Go to ${GUESTWORKS_URL}`,
    })

    const onClick = () => {
        trackGuestworksLinkClicked(SegmentLocation.NavBar)
        window.open(GUESTWORKS_URL, "_blank")
    }

    return (
        <div className={styles.guestworksLink} onClick={onClick}>
            <GuestworksIcon className={styles.guestworksIcon} />
            <span className={styles.guestworksLabel}>{label}</span>
            <ChevronRight className={styles.chevronRight} />
        </div>
    )
}
