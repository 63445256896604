import { JSONAPIResource, Reservation } from "@vacasa/owner-api-models"
import classNames from "classnames"
import EmptyState from "../../../lib/components/EmptyState"
import { useEffect } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useReservations } from "../../../hooks/reservations"
import { Secondary } from "../../../lib/components/Buttons/BaseButton"
import Loader from "../../../lib/components/Loader"
import { ButtonLoader } from "../../../lib/components/Loader/ButtonLoader/ButtonLoader"
import styles from "./InfiniteReservationsList.module.scss"
import { StayType } from "./Reservations"
import ReservationList from "./ReservationsList"
import SVGIcon from "../../../lib/components/SVGIcon"
import { GeneralError } from "lib/components/GeneralError/GeneralError"
import { parseISO } from "date-fns"

type InfiniteReservationsListProps = {
    query: ReturnType<typeof useReservations>
    onReservationClicked: (reservation: JSONAPIResource<Reservation>) => void
    stayType: StayType
    updatingCalendar: boolean
    isSuperUnit: boolean
    bookingProbabilityEnabled: boolean
    selectedResId?: string | number
}

type ReservationListType = {
    year: string | number
    data: JSONAPIResource<Reservation>[]
}

const InfiniteReservationsList = ({
    query,
    onReservationClicked,
    stayType,
    updatingCalendar,
    isSuperUnit,
    bookingProbabilityEnabled,
    selectedResId,
}: InfiniteReservationsListProps): JSX.Element | null => {
    const { fetchNextPage, data, hasNextPage, isSuccess } = query
    const intl = useIntl()

    useEffect(() => {
        if (data && selectedResId && isSuccess) {
            const reservations = data.pages.flatMap(page => page.data)
            const reservationFound =
                reservations.findIndex(
                    reservation => reservation.id === selectedResId
                ) > -1
            if (reservationFound || !hasNextPage) return
            fetchNextPage()
        }
    }, [fetchNextPage, data, hasNextPage, stayType, selectedResId, isSuccess])

    if (query.isError) {
        return (
            <GeneralError
                errorMessage={intl.formatMessage({
                    id: "CalendarPage.errorMessages.unableToLoadReservations",
                    defaultMessage: "We were unable to load your reservations.",
                })}
            />
        )
    }
    if (query.isLoading) {
        return <Loader />
    }
    if (!query.isSuccess) {
        return null
    }
    const reservationData = query.data.pages.flatMap(page => page.data)

    const reservations: ReservationListType[] = reservationData.reduce(
        (acc: ReservationListType[], reservation) => {
            const { attributes } = reservation
            const startYear = attributes.startDate
                ? parseISO(attributes.startDate).getFullYear()
                : ""
            const list = acc.find(item => item.year === startYear)
            if (list) {
                list.data.push(reservation)
            } else {
                acc.push({
                    year: startYear,
                    data: [reservation],
                })
            }
            return acc
        },
        []
    )
    return (
        <div>
            {reservations.length === 0 ? (
                <EmptyState
                    snippet={
                        stayType === "upcoming"
                            ? intl.formatMessage({
                                  id: "CalendarPage.UpcomingStays.emptyStateUpcoming",
                                  defaultMessage:
                                      "New reservations and owner holds will show up here.",
                                  description:
                                      "There are no upcoming reservations",
                              })
                            : intl.formatMessage({
                                  id: "CalendarPage.UpcomingStays.emptyStatePast",
                                  defaultMessage:
                                      "You have no previous reservations. When upcoming stays have concluded, they will appear here.",
                                  description:
                                      "There are no reservations before today",
                              })
                    }
                >
                    <div className="info-panel">
                        <SVGIcon
                            svg="icon-feather-briefcase"
                            colorName="info-70"
                            className="w-56 h-56 empty-state__svg"
                        />
                    </div>
                </EmptyState>
            ) : (
                reservations.map((reservation: ReservationListType) => (
                    <div key={reservation.year} className={styles.yearBlock}>
                        <div
                            className={classNames(
                                styles.yearTitle,
                                "type-heading-extra-small"
                            )}
                        >
                            {reservation.year} Reservations
                        </div>

                        <ReservationList
                            reservations={reservation.data}
                            stayType={stayType}
                            isSuperUnit={isSuperUnit}
                            updatingCalendar={updatingCalendar}
                            clickHandler={onReservationClicked}
                            bookingProbabilityEnabled={
                                bookingProbabilityEnabled
                            }
                        />
                    </div>
                ))
            )}

            {query.hasNextPage && (
                <div className={styles.loadMoreButtonContainer}>
                    <Secondary
                        onClick={() => query.fetchNextPage()}
                        disabled={query.isFetchingNextPage}
                        isLoading={query.isFetchingNextPage}
                        loader={
                            <ButtonLoader
                                indicator="dot"
                                indicatorCount={3}
                                animation="blink"
                            />
                        }
                    >
                        <FormattedMessage
                            id="ReservationsList.LoadMore"
                            defaultMessage="Load more"
                            description="Load more reservations"
                        />
                    </Secondary>
                </div>
            )}
        </div>
    )
}

export { InfiniteReservationsList }
