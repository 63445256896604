import { Dispatch, SetStateAction } from "react"

export const enum TaxFormTypes {
    "w9" = "w9",
    "w8" = "w8",
}

export const enum W8Steps {
    Details,
}

export const enum W9Steps {
    TaxClassification,
    TaxInfo,
    MailingAddress,
    AgreeAndSign,
}

export type TaxClassification = {
    type: string
    llcType?: string
}

export type TaxInfo = {
    entityName: string
    businessName: string
    taxIDType: string
    SSN: string
    EIN: string
    entityType: string
}

export type MailingAddress = {
    addressLine1: string
    addressLine2: string
    city: string
    stateOrProvince: string
    postalCode: string
}

export type AgreeAndSign = {
    agreeToTerms: boolean
    signature: string
}

export type W9Data = {
    taxClassification: TaxClassification
    taxInfo: TaxInfo
    mailingAddress: MailingAddress
    agreeAndSign: AgreeAndSign
}

export type FormRef = {
    isFormValid: () => Promise<boolean>
}

export type FlyoutState = {
    modalOpen: boolean
}

export type FlyoutAPI = {
    open: () => void
    close: () => void
}

export type FormState = {
    setData: Dispatch<SetStateAction<W9Data>>
    data: W9Data
}
