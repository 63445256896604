import {
    JSONAPIResource,
    OnboardingStatus,
    UserPreference,
} from "@vacasa/owner-api-models"
import type { AxiosError } from "axios"
import { useContactId } from "hooks/user"
import {
    UseQueryOptions,
    UseQueryResult,
    useQuery,
    useQueries,
} from "react-query"
import { isOnboardingUnit } from "utils/unit"
import { UnitsResponse, fetchUnits } from "./fetchUnits"
import { ONBOARDING_COMPLETED } from "constants/preferences.constants"
import { fetchOwnerUnitPreferences } from "hooks/owner-preferences/fetchOwnerUnitPreferences"
import { useUnitState } from "contexts/unit"

export const useUnits = (
    options?: Omit<
        UseQueryOptions<UnitsResponse["data"], AxiosError>,
        "enabled"
    >
): UseQueryResult<UnitsResponse["data"], AxiosError> => {
    const contactId = useContactId()
    return useQuery<UnitsResponse["data"], AxiosError>(
        ["units", contactId],
        ({ signal }) =>
            fetchUnits({
                contactId: contactId as string,
                signal,
            }).then(response => response.data),
        {
            enabled: contactId !== null,
            staleTime: 1000 * 60 * 10,
            refetchInterval: 1000 * 60 * 10,
            ...options,
        }
    )
}

export const useUnitsOnboarding = () => {
    const { units } = useUnitState()
    return Object.values(units).filter(isOnboardingUnit) ?? []
}

export const useUnitsOnboardingInProgressCount = (): {
    ready: boolean
    data: number
} => {
    const unitsQuery = useUnitsOnboarding()
    const unitPreferencesQueries = useQueries(
        unitsQuery
            .filter(
                unit =>
                    unit.attributes.status.onboarding ===
                        OnboardingStatus.InformationRequired &&
                    unit.attributes.hasOwnerEditAccess
            )
            .map<
                UseQueryOptions<JSONAPIResource<UserPreference>[], AxiosError>
            >(unit => {
                return {
                    queryKey: ["owner_unit_preference", String(unit.id)],
                    queryFn: () =>
                        fetchOwnerUnitPreferences({ unitId: String(unit.id) }),
                }
            })
    )
    if (unitsQuery.length === 0) return { ready: true, data: 0 }
    if (unitPreferencesQueries.some(query => query.isLoading))
        return { ready: false, data: 0 }

    const incompletedUnits = unitPreferencesQueries.reduce(
        (acc, preferences) => {
            const completedOnboardingPreference = preferences.data?.find(
                preference => {
                    return preference.id === ONBOARDING_COMPLETED
                }
            )
            if (
                completedOnboardingPreference &&
                !completedOnboardingPreference.attributes.value
            ) {
                acc++
            }

            return acc
        },
        0
    )

    return { ready: true, data: incompletedUnits }
}
