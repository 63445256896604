export const MARKET_COMP_RANGE_CHANGED = "Market Comp Range Changed"
export const ASK_A_RATES_SPECIALIST_CLICKED = "Ask a Rates Specialist Clicked"
export const BETA_TOOLTIP_RATES_SPECIALIST_CLICKED =
    "Beta Tooltip Rates Specialist Clicked"
export const CHART_SELECTION_CHANGED = "Chart Selection Changed"
export const MARKET_RATE_COMPARISON_CHART_PRESENTED =
    "Market Rate Comparison Chart Presented"
export const MARKET_RATE_COMPARISON_CHART_TOOLTIP_VIEWED =
    "Market Rates Comparison Chart Tooltip Viewed"
export const REVENUE_SUMULATOR_CHART_TOOLTIP_VIEWED =
    "Revenue Simulator Chart Tooltip Viewed"
export const REVENUE_SIMULATOR_RESULT = "Revenue Simulator Result"
export const REVENUE_SIMULATOR_FEATURE_PRESENTED =
    "Revenue Simulator Feature Presented"
