import {
    JSONAPIDocument,
    JSONAPIResource,
    Review,
} from "@vacasa/owner-api-models"
import { authAxios } from "../../api/authAxios"

type FetchReviewsRequest = {
    reservationId: string
    contactId: string
    signal?: AbortSignal
}

const fetchReviews = ({
    reservationId,
    contactId,
    signal,
}: FetchReviewsRequest): Promise<JSONAPIResource<Review>[] | undefined> =>
    authAxios
        .get<JSONAPIDocument<Review, []>>(
            `/v1/reservations/${reservationId}/reviews`,
            {
                params: {
                    contactId: contactId,
                },
                signal,
            }
        )
        .then(response => response.data.data)

/**
 * JSON:API is typed incorrectly so we have to create a custom type here
 *
 * @see {@link https://vacasait.atlassian.net/browse/TFP-1156}
 */
export type ReviewsResponse = {
    data: JSONAPIResource<Review>[]
    meta: {
        total: number
        currentPage: number
        lastPage: number
        filteredTotal: number
        rating?: { average: number }
    }
}

type FetchReviewsByUnitIdRequest = {
    unitId: string
    contactId: string
    metaInclude?: string
    signal?: AbortSignal
}

const fetchReviewsByUnitId = ({
    unitId,
    contactId,
    metaInclude = "totalStarRating",
    signal,
}: FetchReviewsByUnitIdRequest): Promise<ReviewsResponse> =>
    authAxios
        .get<ReviewsResponse>(`/v1/reviews`, {
            params: {
                contactId,
                unitId,
                metaInclude,
            },
            signal,
        })
        .then(response => response.data)

export { fetchReviews, fetchReviewsByUnitId }
