import { Unit } from "@vacasa/owner-api-models/src/legacy/Earnings"
import SVGIcon from "lib/components/SVGIcon"
import { Tooltip } from "lib/components/Tooltip/Tooltip"
import { clone, isEmpty, map } from "lodash"
import { FormattedMessage } from "react-intl"
import { displayFormattedNumber } from "utils/statements/statementsUtil"
import styles from "../statements.module.scss"

type Reserve = Unit["reserve"]

const hasReserve = (unit: Unit) => !isEmpty(unit) && unit.reserve !== undefined

// Lets use this method to make sure we are returning an int.
const parseReserveAmount = (reserve: string | number) => {
    return parseFloat(reserve.toString())
}

const getReserveAmount = (isSingleUnit: boolean, reserve: Reserve) => {
    const reserveAdjustmentExists = parseFloat(reserve.adjustment) !== 0.0
    // if there is an adjustment show that for all units
    // if the adjustment doesn't exist check if its a single unit owner
    // if it is a single unit owner we need to show the ending balance.
    const preParsedReserveAmount = (
        reserveAdjustmentExists ? reserve.adjustment : isSingleUnit
    )
        ? reserve.ending_balance
        : null
    // If there isn't an adjustment we really shouldn't show the row.
    return preParsedReserveAmount
        ? parseReserveAmount(preParsedReserveAmount) * -1
        : false
}

const displayReserveActivity = (
    reserve: Reserve,
    lineItemDesc: string,
    currency: string
) => {
    const replacementKeys = {
        // eslint-disable-next-line camelcase
        beginning_balance: (
            <FormattedMessage
                id="Statements.beginningReserveBalance"
                defaultMessage="Beginning Reserve Balance"
            />
        ),
        // eslint-disable-next-line camelcase
        ending_balance: (
            <FormattedMessage
                id="Statements.EndingReserveBalance"
                defaultMessage="Ending Reserve Balance"
            />
        ),
        adjustment: lineItemDesc,
    }
    const activitySection = map(reserve, (value, key) => {
        const lineItemDesc = map(replacementKeys, (v, i) =>
            i === key ? v : null
        )
        if (value === undefined) {
            return null
        }
        return (
            <tr key={key}>
                <td className="gutter" />
                <td colSpan={5} className="desc-span">
                    {lineItemDesc}
                </td>
                <td className="text-right">
                    {displayFormattedNumber(
                        parseReserveAmount(value),
                        currency
                    )}
                </td>
                <td className={styles["gutter-end"]} />
            </tr>
        )
    })
    return activitySection
}

// Only display the line item row if there is some sort of adjustment OR the beginning / ending balance match and are not equal to 0.
const shouldDisplayLineItem = (reserveAmount: number, reserve: Reserve) =>
    reserveAmount !== 0.0 ||
    (Number(reserve.beginning_balance) === reserve.ending_balance &&
        parseFloat(reserve.beginning_balance) !== 0.0)

const displayReserveLineItem = (
    isSingleUnit: boolean,
    unit: Unit,
    onFAQOpen: () => void,
    scrollToFAQ: () => void,
    currency: string
) => {
    if (!hasReserve(unit)) {
        return null
    }

    const reserve = clone(unit.reserve)
    const reserveAmount = getReserveAmount(isSingleUnit, reserve)

    // If the parsed reserve amount comes back as NaN or nothing we dont need to display it.
    if (typeof reserveAmount !== "number") return null
    const lineItemTitle = `Reserve Amount ${
        reserveAmount > 0.0 ? "Used" : "Held"
    }`
    const lineItemDesc = `Amount ${reserveAmount > 0.0 ? "Used" : "Held"}`
    const lineItemRow = isSingleUnit ? (
        <td colSpan={3}>
            <span>
                {lineItemTitle}
                <div className={styles["tooltip-icon"]}>
                    <Tooltip
                        customTooltipStyle={styles["allow-lowercase"]}
                        onOutsideClick={onFAQOpen}
                        title={"RESERVE BALANCE"}
                        body={
                            <FormattedMessage
                                id="Statements.tooltips.reserveBalanceRow"
                                defaultMessage="Your reserve balance carries over from month to month and is automatically replenished as needed. <learnMore>Learn more</learnMore>"
                                values={{
                                    learnMore: chunks => (
                                        <span
                                            key={"link"}
                                            onClick={scrollToFAQ}
                                            className={styles["link"]}
                                        >
                                            {chunks}
                                        </span>
                                    ),
                                }}
                            />
                        }
                    >
                        <SVGIcon
                            svg="icon-feather-info"
                            colorName="dusk"
                            className={styles.info_icon}
                        />
                    </Tooltip>
                </div>
            </span>
        </td>
    ) : (
        <td colSpan={5}>{lineItemTitle}</td>
    )

    return (
        <>
            {shouldDisplayLineItem(reserveAmount, reserve) && (
                <div className="table-responsive">
                    <table className="open table">
                        <thead className={styles["uppercase-header"]}>
                            <tr>
                                <td className={styles["inset"]} />
                                {lineItemRow}
                                <td colSpan={3} className="text-right">
                                    {displayFormattedNumber(
                                        reserveAmount,
                                        currency
                                    )}
                                </td>
                                <td className={styles["inset"]} />
                            </tr>
                        </thead>
                        {!isSingleUnit && (
                            <tbody className={"show"}>
                                {displayReserveActivity(
                                    reserve,
                                    lineItemDesc,
                                    currency
                                )}
                            </tbody>
                        )}
                    </table>
                </div>
            )}
        </>
    )
}

interface UnitDetailsReserveLineItemProps {
    currency: string
    unit: Unit
    singleUnitOwner: boolean
    scrollToFAQ: () => void
    onFAQOpen: () => void
}

const UnitDetailsReserveLineItem: React.FC<
    UnitDetailsReserveLineItemProps
> = props => {
    const { currency, unit, singleUnitOwner, scrollToFAQ, onFAQOpen } = props

    return (
        <>
            {displayReserveLineItem(
                singleUnitOwner,
                unit,
                onFAQOpen,
                scrollToFAQ,
                currency
            )}
        </>
    )
}

export default UnitDetailsReserveLineItem
