import { useContactId } from "hooks/user"
import { useQuery } from "react-query"
import { fetchMailingAddress } from "./fetchMailingAddress"

export const useMailingAddress = () => {
    const contactId = useContactId() ?? ""
    return useQuery(
        ["mailing-address", contactId],
        ({ signal }) => fetchMailingAddress(contactId, { signal }),
        {
            enabled: !!contactId,
            cacheTime: 1000 * 60 * 45, // 45 mins
            refetchInterval: 1000 * 60 * 30, // 30 mins
            staleTime: 1000 * 60 * 30, // 30 mins
        }
    )
}
