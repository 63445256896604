import { BookingProbability, JSONAPIResource } from "@vacasa/owner-api-models"
import { UseQueryResult } from "react-query"

const showBookingProbabilityMessage = (
    query: UseQueryResult<JSONAPIResource<BookingProbability>, unknown>
): boolean => {
    const searchParams = new URLSearchParams(window.location.search)
    return (
        searchParams.has("bookingProbability") ||
        (query.isSuccess && !!query.data?.attributes?.isHighBookingProbability)
    )
}

const showBookingProbabilityIndicator = (
    query: UseQueryResult<JSONAPIResource<BookingProbability>, unknown>
): boolean =>
    query.isSuccess &&
    // data comes back empty for dates too far into the future
    query.data?.attributes &&
    (query.data?.attributes?.isHighBookingProbability ?? false)

export { showBookingProbabilityMessage, showBookingProbabilityIndicator }
