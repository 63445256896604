import React from "react"
import classnames from "classnames"

interface SVGIconProps {
    colorName?: string
    className?: string
    onClick?: () => void
    style?: React.CSSProperties
    svg?: string
}

const SVGIcon: React.FunctionComponent<SVGIconProps> = ({
    colorName,
    className,
    onClick,
    style,
    svg,
}) => {
    const classNames = classnames(
        `feather`,
        className ? `${className}` : null,
        colorName ? `${colorName}` : null,
        svg ? `${svg}` : null
    )

    return (
        <svg
            onClick={onClick}
            className={classNames}
            data-testid={`svg-${svg}`}
            style={style}
        >
            <use xlinkHref={"#" + svg} />
        </svg>
    )
}

export default SVGIcon
