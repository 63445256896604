import { FC } from "react"
import styles from "./TaxCard.module.scss"
import classNames from "classnames"
import { IconComplete } from "assets"

type Props = {
    title: string
    content: string
    onClick: () => void
    selected?: boolean
}
export const TaxCard: FC<Props> = props => {
    const { title, content, onClick, selected } = props
    return (
        <div
            className={classNames(styles.card, {
                [styles.cardSelected as string]: selected,
            })}
            onClick={onClick}
        >
            <div className="bold uppercase">
                {title} {selected && <IconComplete />}
            </div>
            <div>{content}</div>
        </div>
    )
}
