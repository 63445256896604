import { datadogLogs } from "@datadog/browser-logs"
import * as Sentry from "@sentry/browser"
import { createInstance, enums } from "@optimizely/react-sdk"
import {
    DATADOG_ENABLED,
    NODE_ENV,
    OPTIMIZELY_DATAFILE_URL,
    OPTIMIZELY_SDK_KEY,
} from "../Environment"

/**
 * customLogger for optimizely
 * @param {number} level indicating the level of the log message
 * @param {string} message indicating the log message
 */
const customLogger = (level: number, message: string) => {
    const LOG_LEVEL = enums.LOG_LEVEL
    switch (level) {
        case LOG_LEVEL.ERROR:
            if (NODE_ENV !== "production") {
                // eslint-disable-next-line no-console
                console.error(message)
            }
            if (DATADOG_ENABLED) {
                datadogLogs.logger.error(message)
            }
            Sentry.captureMessage(message, "error")
            break
        default:
            // Only capture errors
            // Ignore other log levels
            break
    }
}

export const optimizely = createInstance({
    logger: { log: customLogger },
    logLevel: "error",
    sdkKey: OPTIMIZELY_SDK_KEY,
    datafileOptions: {
        urlTemplate: `${OPTIMIZELY_DATAFILE_URL}/${OPTIMIZELY_SDK_KEY}.json`,
    },
})
