import React from "react"
import { Row, Col } from "reactstrap"
import { useIntl } from "react-intl"

import ToggleGroup from "../Toggle/ToggleGroup"
import styles from "../../../sass/onboarding.module.scss"
import { Tooltip } from "../Tooltip/Tooltip"
import SVGIcon from "../SVGIcon"

interface ToggleRowProps {
    title: React.ReactNode
    description?: string | JSX.Element
    tooltipBody?: React.ReactNode
    tooltipTitle?: string
    selectedValue?: boolean | null
    onChangeHandler: (value: boolean) => void
}

const ToggleRow: React.FunctionComponent<ToggleRowProps> = ({
    title,
    description,
    tooltipBody,
    tooltipTitle,
    selectedValue,
    onChangeHandler,
}: ToggleRowProps): JSX.Element => {
    const intl = useIntl()

    const selectedStringValue =
        typeof selectedValue === "boolean"
            ? selectedValue
                ? intl.formatMessage({
                      id: "Onboarding.yesOption",
                      defaultMessage: "Yes",
                  })
                : intl.formatMessage({
                      id: "Onboarding.noOption",
                      defaultMessage: "No",
                  })
            : undefined

    // TODO using intl values for selection causes breakages when changing language
    // seperate displayed copy from value
    function convert(v: string): void {
        const boolValue =
            v ===
            intl.formatMessage({
                id: "Onboarding.yesOption",
                defaultMessage: "Yes",
            })
                ? true
                : v ===
                  intl.formatMessage({
                      id: "Onboarding.noOption",
                      defaultMessage: "No",
                  })
                ? false
                : undefined
        onChangeHandler(!!boolValue)
    }

    return (
        <Row>
            <Col>
                <div className={styles.outer_row}>
                    <div className={styles.outer_column}>
                        <div>
                            <p className={styles.type_body_inline}>{title}</p>
                            {tooltipBody && tooltipTitle && (
                                <span className={styles.tooltip_wrapper}>
                                    <Tooltip
                                        title={tooltipTitle}
                                        body={tooltipBody}
                                    >
                                        <SVGIcon
                                            svg="icon-feather-info"
                                            colorName="gray-lightest"
                                            className="w-16 h-16 cursor-pointer"
                                            style={{ margin: "auto" }}
                                        />
                                    </Tooltip>
                                </span>
                            )}
                        </div>
                    </div>
                    <ToggleGroup
                        selectedValue={selectedStringValue}
                        toggleValues={[
                            intl.formatMessage({
                                id: "Onboarding.yesOption",
                                defaultMessage: "Yes",
                            }),
                            intl.formatMessage({
                                id: "Onboarding.noOption",
                                defaultMessage: "No",
                            }),
                        ]}
                        onChangeHandler={convert}
                    />
                </div>
                {description && (
                    <div className={styles.row_description}>
                        <span className="type-body-small">{description}</span>
                    </div>
                )}
            </Col>
        </Row>
    )
}

export default ToggleRow
