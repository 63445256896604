import { FormattedMessage } from "react-intl"
import { ReactComponent as ReferralConfirmationSVG } from "../../../../assets/referral-confirmation.svg"
import styles from "./SuccessScreenBody.module.scss"
import * as Button from "../../Buttons/BaseButton"
import { trackReferralFormClosed } from "services/segment/referral/referralTracking"

interface SuccessScreenBodyProps {
    showReferralModal: (shouldShowReferralModal: boolean) => void
    reset: () => void
}

const SuccessScreenBody: React.FC<SuccessScreenBodyProps> = props => {
    const { showReferralModal, reset } = props

    return (
        <div className={styles.successScreen}>
            <ReferralConfirmationSVG />
            <div className={styles.successTitle}>
                <FormattedMessage
                    id="OwnerReferral.successTitle"
                    defaultMessage="Congratulations, your referral is on the way!"
                />
            </div>
            <div className={styles.successDescription}>
                <FormattedMessage
                    id="OwnerReferral.successDescription"
                    defaultMessage="You'll receive your $500 credit 30 days after your friend's home goes live on Vacasa.com."
                />
            </div>
            <div className={styles.buttons}>
                <button className={styles.referAnotherFriend} onClick={reset}>
                    <FormattedMessage
                        id="OwnerReferral.referAnotherFriend"
                        defaultMessage="Refer Another Friend"
                    />
                </button>
                <Button.LinkSecondary
                    typeOf={"link"}
                    onClick={() => {
                        showReferralModal(false)
                        trackReferralFormClosed()
                    }}
                >
                    <FormattedMessage
                        id="OwnerReferral.notRightNow"
                        defaultMessage="Not Right Now"
                    />
                </Button.LinkSecondary>
            </div>
            <div className={styles.successFooter}>
                <FormattedMessage
                    id="OwnerReferral.termsAndConditions"
                    defaultMessage="Terms and Conditions Apply"
                />
            </div>
        </div>
    )
}

export { SuccessScreenBody }
