const petsAllowed = {
    title: "Consenti i cani",
    ariaLabel: "Clicca sul pulsante per ammettere gli animali.",
    description:
        "Rende il tuo annuncio dog-friendly, consentendoci così di presentare la tua casa a un pubblico più ampio. Forniamo noi i prodotti per i cani e ci assicuriamo sempre che la tua casa riceva le giuste attenzioni.",
    learnMore: "Per saperne di più",
    earnMore: "Guadagna fino al 20% in più",
    earnMoreDescription:
        "Ammettere gli animali può aumentare considerevolmente le tue entrate e le prenotazioni, soprattutto in bassa stagione.",
    untitledHome: "Casa senza nome",
    showAllRentals: "Mostra tutti gli alloggi",
    showAllAriaLabel:
        "utilizza questo pulsante per mostrare tutti i tuoi alloggi.",
    hideRentals: "Nascondi alloggi",
    allowed: "Consentito",
    allow: "Consenti",
    modalHeader: "Ottimo, sei pronto!",
    modalText:
        "Il tuo annuncio si sta aggiornando e il tuo team locale riceverà un avviso, per assicurarsi che la tua casa sia pronta ad accogliere animali. Possono essere necessarie fino a 24 ore per l'aggiornamento di questa modifica su tutti i tuoi annunci e i canali di marketing.",
    modalFooter: "Clicca su questo pulsante per chiudere la finestra modale.",
    ok: "OK",
    disclaimer:
        "L'aggiornamento delle modifiche su tutti i tuoi annunci e i canali di marketing può richiedere fino a 24 ore. Ti preghiamo di contattare il responsabile del tuo alloggio in caso di domande o se non desideri più consentire l'ingresso di animali.",
}

export default petsAllowed
