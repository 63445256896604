import {PostTicketResponse} from "@vacasa/owner-api-models";
import {authAxios} from "../../api/authAxios";



export interface ReopenTicketRequestData {
    comment:string
}
export interface ReopenTicketRequest{
    contactId: string
    unitId: string
    ticketId: number
    reopenTicketCommentData:ReopenTicketRequestData

}

export type ReopenTicketResponse = { data: PostTicketResponse }

const reopenTicket =  (
    {
        contactId,
        unitId,
        ticketId,
        reopenTicketCommentData,
    }:ReopenTicketRequest
):Promise<PostTicketResponse> => {

    const reopenTicketData = {
        data:{
            type: "ticket-comment",
            attributes:reopenTicketCommentData
        }
    }

    return authAxios.patch<ReopenTicketResponse>(`/v1/ticket/${ticketId}/reopen`,reopenTicketData, {
        params:{
            contactId,
            unitId
        }
    })
        .then(response => response.data.data)

}

export {reopenTicket}