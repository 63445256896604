const reservation = {
    nights: "{nights, plural, =0 {} one {# night} other {# nights}}",
    type: {
        guest: "Guest",
        guestStay: "Guest Stay",
        owner: "Owner",
        ownerHold: "Owner Hold",
        vacasa: "Vacasa",
        vacasaHold: "Vacasa Hold",
    },
}

export default reservation
