const settings = {
    title: "Payment Settings",
    reserveBalance: "Saldo de reserva",
    paymentSettings: "Configuraciones de pago",
    directDepositTitle: "Bank Account",
    checksByMailTitle: "Paper Checks",
    checksByMailEnrolled: "You are enrolled in paper checks.",
    checksByMailNotEnrolled: "You are not enrolled in paper checks.",
    paymentMethod: "Payment Method",
    paymentMethodText:
        "Payment delivery depends on the owner's selected payment method.",
    directDepositFineprint:
        "Deposits and withdrawals usually appear in the account 2-5 business days after the monthly statement.",
    checksByMailFineprint:
        "Paper checks usually take 5-10 business days to arrive by mail. Please confirm your mailing address in your {ProfileLink}.",
    reserveBalanceEmptyState:
        "Podrás ver y actualizar tu saldo de reserva aquí.",
    reserveBalanceText:
        "If your expenses exceed your revenue in a given month, your Reserve Account will be used to cover the difference. If your reserve account balance is below {balance}, we'll automatically replenish it from revenue you earn.",
    reserveUpdateAria:
        "Haz clic en este botón para actualizar tu saldo de reserva.",
    reserveUpdatedNotification: "Saldo de reserva actualizado",
    reserveMinimumRequired: "This unit requires at least {amount} reserve",
    directDepositEnrolled: "You are enrolled in direct deposit.",
    achDirectDepositEnrolled:
        "You are enrolled in ACH direct deposit and ACH expense debit.",
    directDepositNotEnrolled: "No estás inscripto en el depósito directo.",
    checkingAccount: "Cuenta corriente",
    savingsAccount: "Caja de ahorro",
    updatePayment: "Actualizar método de pago",
    updateAction: "Actualizar",
    updatedNotification: "Información de pago actualizada",
    updatePaymentSub: "Cuenta bancaria para depósito directo",
    receiveByMailSub: "Enroll in Checks",
    receiveByMailText:
        "If selected, your checks will be mailed to your address on file.",
    directDepositRemoved: "Eliminado de depósito directo",

    addBankAccount: "Agregar cuenta bancaria",
    checking: "cuenta corriente",
    savings: "de ahorros",
    routingNumber: "Número de ruta",
    nineDigitNumber:
        "El número de 9 dígitos en la esquina inferior izquierda de un cheque",
    mustBeginWith: "El número de ruta debe comenzar con 0, 1, 2 o 3",
    mustBe9Digits: "Ingresa un número de ruta de 9 dígitos",
    mustBeValid9Digits: "Please enter a valid 9-digit routing number",
    accountNumber: "Número de cuenta",
    accountHolderName: "Nombre del titular de la cuenta",
    enterBankAccount: "Please enter your bank account number",
    mustBe5to17Digits: "Please enter a 5 to 17-digit account number",
    byClickingYouAuthorize:
        "Because you chose to pay for expenses via ACH Debit, this account will also be charged if your expenses exceed your rental revenue for the month. By clicking 'Agree and Save', you authorize {accountingEntity} to make payments electronically via direct deposit and to debit the identified account for up to $10,000 per transaction per day. This authority will remain in effect until you change or update your payment method.",
    cancel: "cancelar",
    agreeAndSave: "Aceptar y guardar",
    savePaymentMethod: "Guardar método de pago",
    clickToSaveAria: "Haz clic en este botón para guardar tu método de pago.",
    clickToCancelAria: "Haz clic en este botón para cancelar.",
    clickToRequestChecksAria:
        "Haz clic en este botón para solicitar cheques por correo.",
    requestChecksByMail: "Solicitar cheques por correo",
    getYourPaymentsFaster:
        "Recibe tus pagos más rápido al inscribirte en el depósito directo",
    errorMessages: {
        updateReserveBalanceError: "La reserva de saldo es demasiado grande",
        updateReserveBalanceNegativeError:
            "La reserva de saldo no puede ser negativa",
        updateReserveBalanceMissingError: "Se necesita reserva de saldo",
        sendDirectDepositEmailError:
            "No se ha enviado el correo de baja de Ingreso en cuenta",
        paymentAccountGeneralError:
            "Se ha producido un error al actualizar la información de tu cuenta, vuelve a intentarlo más tarde.",
        paymentAccountNameError: "Se debe indicar en titular de la cuenta.",
        paymentAccountNameLengthError:
            "El nombre del titular de la cuenta es superior a 100 caracteres.",
        paymentAccountRoutingNumError:
            "El número de enrutamiento es incorrecto.",
        paymentAccountNumError: "El número de cuenta es incorrecto.",
        paymentAccountTypeError: "El tipo de cuenta es incorrecto.",
        sendACHUpdateEmailError:
            "No se ha podido enviar el correo de actualización ACH",
        somethingWentWrong: "Se ha producido un error, vuelve a intentarlo.",
    },
    emptyState:
        "This is where you can update your reserve balance and payment method. Be sure to set up direct deposit before your first statement posts in order to get your payments faster.",
    linkToStatements: "Statements",
}

export default settings
