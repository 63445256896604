import { useLegacyUserId } from "hooks/user"
import { RouteErrorBoundary } from "lib/components/ErrorBoundaries/DefaultError"
import { RedirectToOnboarding } from "lib/hoc/redirect-to-onboarding/redirect-to-onboarding"
import { observer } from "mobx-react"
import { ComponentType, FC, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { gtmPageView } from "utils/gtm"

type Props = {
    page: string
    element: ComponentType
    onboardingRedirect?: boolean
}

const PageRoute: FC<Props> = observer(
    ({ page, element: Element, onboardingRedirect = true }) => {
        const userId = useLegacyUserId()
        const location = useLocation()

        useEffect(() => {
            gtmPageView(page, userId ?? undefined)
        }, [page, userId])

        const content = <Element />

        return (
            <RedirectToOnboarding
                redirectRoute={onboardingRedirect}
                forward={location.pathname === "/forward"}
            >
                <RouteErrorBoundary>{content}</RouteErrorBoundary>
            </RedirectToOnboarding>
        )
    }
)

export default PageRoute
