import { makeObservable, observable } from "mobx"

import { createContext, useContext } from "react"
import CalendarService from "./calendar.service"
import EarningsService from "./earnings/earnings.service"
import ErrorService from "./error.service"
import PreferenceService from "./preferences/preferences.service"
import SegmentService from "./segment/segment.service"
import { logout } from "utils/auth"

export class RootService {
    @observable showSlideOut = false
    @observable errorService: typeof ErrorService
    @observable calendarService: typeof CalendarService
    @observable earningsService: typeof EarningsService
    @observable segmentService: typeof SegmentService
    @observable preferenceService: typeof PreferenceService

    constructor() {
        makeObservable(this)
        this.errorService = ErrorService
        this.calendarService = CalendarService
        this.earningsService = EarningsService
        this.segmentService = SegmentService
        this.preferenceService = PreferenceService
    }

    logout = () => {
        this.resetStores()
        logout()
    }

    resetStores = () => {
        this.errorService.init()
        this.calendarService.init()
        this.earningsService.init()
        this.segmentService.init()
        this.preferenceService.reset()
    }
}

const rootService = new RootService()

export const RootServiceContext = createContext(rootService)
/**
 * @see https://mobx.js.org/react-integration.html#using-external-state-in-observer-components
 */
export const useRootService = (): RootService => useContext(RootServiceContext)

export default rootService
