import { parsePhoneNumberFromString } from "libphonenumber-js"

export const formatPhone = (phoneNumber?: string | null): string | null => {
    if (!phoneNumber) {
        return null
    }
    let cleanPhone = phoneNumber.replace(/\D/g, "")
    cleanPhone = cleanPhone.length === 10 ? `+1${cleanPhone}` : `+${cleanPhone}`
    if (!cleanPhone) {
        return null
    }
    const parsedPhone = parsePhoneNumberFromString(cleanPhone)
    return parsedPhone?.formatInternational() ?? cleanPhone
}
