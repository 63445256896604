import classNames from "classnames"
import React, { useEffect, useState } from "react"

import "../../../sass/dropdown.scss"
import styles from "./Dropdown.module.scss"
interface DropdownItemProps {
    line?: boolean
    className?: string
    children?:
        | string
        | JSX.Element[]
        | JSX.Element
        | string[]
        | (string | JSX.Element)[]
        | null
    onClick?: React.MouseEventHandler<HTMLButtonElement>
    testId?: string
}

export const DropdownItem: React.FC<DropdownItemProps> = (
    props: DropdownItemProps
) => {
    if (props.line) {
        return <div className="line" />
    }

    return (
        <button
            className={classNames("item", "regular", props.className)}
            onClick={e => (props.onClick ? props.onClick(e) : e)}
            data-testid={props.testId}
        >
            {props.children}
        </button>
    )
}

interface DropdownProps {
    initiallyActive?: boolean
    onActiveChange?: (active: boolean) => void
    style?: React.CSSProperties
    classes?: string
    styleOverride?: React.CSSProperties
    onClick?: React.MouseEventHandler<HTMLButtonElement>
    onItemClicked?: React.MouseEventHandler<HTMLDivElement>
    iconComponent?: string | JSX.Element
    label?: string | JSX.Element
    children?: (
        | string
        | false
        | JSX.Element
        | JSX.Element[]
        | null
        | undefined
    )[]
}

export const Dropdown: React.FC<DropdownProps> = (props: DropdownProps) => {
    const [active, setActive] = useState(props.initiallyActive)

    const toggle = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (props.onActiveChange) {
            props.onActiveChange(!active)
            setActive(!active)
        }
        e.stopPropagation()
    }

    useEffect(() => {
        const hide = () => {
            setActive(false)
        }
        window.addEventListener("click", hide)
        return () => {
            window.removeEventListener("click", hide)
        }
    }, [])

    const style =
        typeof props.style !== "undefined" ? Object.assign({}, props.style) : {}

    if (!active) {
        style.opacity = "0"
        style.pointerEvents = "none"
    }
    const classnames = props.classes ? props.classes : "user-name"
    return (
        <div>
            <button
                onClick={e => {
                    toggle(e)
                    if (props.onClick) {
                        props.onClick(e)
                    }
                }}
                className={`${classnames} ${styles.dropdownButton}`}
                style={props.styleOverride}
            >
                <div className={styles.dropdownLabel}>
                    {props.iconComponent} {props.label}
                </div>
            </button>
            <div
                className="dropdown"
                style={style}
                onClick={props.onItemClicked}
            >
                {props.children}
            </div>
        </div>
    )
}
