import {
    ChangeEvent,
    Ref,
    forwardRef,
    useImperativeHandle,
    useState,
} from "react"
import { FormRef } from "../types"
import styles from "./TaxInfoForm.module.scss"
import classNames from "classnames"
import { Input } from "../../../../lib/components/Input"
import { useTaxInformationForm } from "../hooks"
import { Label } from "../../../../lib/components/Label"
import { w9, useValidation } from "hooks/validation"
import { useIntl } from "react-intl"
import {
    getLLCTaxClassificationMessageDescriptor,
    getTaxClassificationMessageDescriptor,
    getTaxIDTypeMessageDescriptor,
} from "../utils"
import { MaskedInput } from "lib/components/Input/MaskedInput/MaskedInput"

export const TaxInfoForm = forwardRef((_, ref: Ref<FormRef>) => {
    const { data, setData } = useTaxInformationForm()
    const [errors, setErrors] = useState(new Map<string, string>())
    const intl = useIntl()
    const { validate } = useValidation(w9.taxInfo(intl))

    let showTaxIDType = true
    if (data.taxInfo && /LLC|CCO|SCO|PRT/.test(data.taxClassification.type)) {
        showTaxIDType = false
    }

    const isFormValid = async (): Promise<boolean> => {
        const formErrors = await validate(data.taxInfo)
        setErrors(formErrors)
        return formErrors.size === 0
    }

    useImperativeHandle(ref, () => ({
        isFormValid,
    }))

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === "SSN" || e.target.name === "EIN") {
            // clear the other tax id type
            setData(prev => ({
                ...prev,
                taxInfo: {
                    ...prev.taxInfo,
                    [e.target.name]: e.target.value,
                    [e.target.name === "SSN" ? "EIN" : "SSN"]: "",
                },
            }))
        } else {
            setData(prev => ({
                ...prev,
                taxInfo: {
                    ...prev.taxInfo,
                    [e.target.name]: e.target.value,
                },
            }))
        }
    }

    const onBlurValidation = async (e: ChangeEvent<HTMLInputElement>) => {
        const fieldErrors = await validate(data.taxInfo, e.target.name)
        setErrors(fieldErrors)
    }

    return (
        <div className={classNames(styles.container, styles.taxInfo)}>
            <div
                className={classNames(
                    "type-heading-extra-small-caps text-midnight-70 font-extrabold",
                    styles.title
                )}
            >
                Tax information
            </div>
            <div className="flex flex-column" style={{ gap: "8px" }}>
                <Input
                    labelText="Tax Classification"
                    readOnly
                    value={intl.formatMessage(
                        getTaxClassificationMessageDescriptor(
                            data.taxClassification.type
                        )
                    )}
                    disabled
                />
                {!!data.taxClassification.llcType && (
                    <Input
                        readOnly
                        value={intl.formatMessage(
                            getLLCTaxClassificationMessageDescriptor(
                                data.taxClassification.llcType!
                            )
                        )}
                        disabled
                    />
                )}
            </div>
            {data.taxClassification.type === "OTH" && (
                <div>
                    <Input
                        labelText="Entity Type"
                        value={data.taxInfo.entityType}
                        name="entityType"
                        onChange={onChange}
                        hint={
                            errors.has("entityType")
                                ? errors.get("entityType")
                                : "Entity Type"
                        }
                        onBlur={onBlurValidation}
                        error={errors.has("entityType")}
                    />
                </div>
            )}
            <div>
                <Input
                    labelText="Name/Entity on Tax Return*"
                    value={data.taxInfo.entityName}
                    name="entityName"
                    onChange={onChange}
                    hint={
                        errors.has("entityName")
                            ? errors.get("entityName")
                            : "Enter your full legal name as shown on your income tax return. This should also match the name/entity listed on your contract with Vacasa."
                    }
                    onBlur={onBlurValidation}
                    error={errors.has("entityName")}
                />
            </div>
            <div>
                <Input
                    labelText="Disregarded business name/entity (If applicable)"
                    value={data.taxInfo.businessName}
                    name="businessName"
                    onChange={onChange}
                />
            </div>
            {showTaxIDType && (
                <>
                    <div className={styles.taxIds}>
                        <Label error={errors.has("taxIDType")}>Tax ID*</Label>
                        <div className={styles.radioGroup}>
                            {["SSN", "EIN"].map(type => (
                                <div key={type}>
                                    <input
                                        id={`tax-id-type-${type}`}
                                        type="radio"
                                        name="taxIDType"
                                        value={type}
                                        onChange={onChange}
                                        onBlur={onBlurValidation}
                                        checked={
                                            type === data.taxInfo.taxIDType
                                        }
                                    />
                                    <label htmlFor={`tax-id-type-${type}`}>
                                        {intl.formatMessage(
                                            getTaxIDTypeMessageDescriptor(type)
                                        )}
                                    </label>
                                </div>
                            ))}
                            {errors.has("taxIDType") && (
                                <div
                                    className={classNames(
                                        "type-body-small-semibold",
                                        "text-sunset"
                                    )}
                                >
                                    {errors.get("taxIDType")}
                                </div>
                            )}
                        </div>
                    </div>
                    {data.taxInfo.taxIDType === "SSN" && (
                        <div>
                            <MaskedInput
                                labelText="SSN*"
                                value={data.taxInfo.SSN}
                                name="SSN"
                                onChange={onChange}
                                onBlur={onBlurValidation}
                                hint={
                                    errors.has("SSN")
                                        ? errors.get("SSN")
                                        : "Enter your 9-digit social security number."
                                }
                                error={errors.has("SSN")}
                                placeholder="XXX-XX-XXXX"
                                mask="000-00-0000"
                            />
                        </div>
                    )}
                    {data.taxInfo.taxIDType === "EIN" && (
                        <div>
                            <MaskedInput
                                mask={"00-0000000"}
                                placeholder="XX-XXXXXXX"
                                onChange={onChange}
                                onBlur={onBlurValidation}
                                labelText="EIN*"
                                value={data.taxInfo.EIN}
                                name="EIN"
                                error={errors.has("EIN")}
                                hint={
                                    errors.has("EIN")
                                        ? errors.get("EIN")
                                        : "Enter your 9-digit employer identification number."
                                }
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    )
})
