import classnames from "classnames"
import { FC } from "react"
import { ChevronLeft } from "react-feather"
import { FormattedMessage } from "react-intl"
import styles from "./ActionBar.module.scss"

interface Props {
    isChangingDates: boolean
    onChangeDatesClick: () => void
    onEditBackClick: () => void
}

export const ActionBar: FC<Props> = ({
    isChangingDates,
    onChangeDatesClick,
    onEditBackClick,
}) => {
    return (
        <div
            className={classnames(styles["action-bar"], {
                [styles["action-bar--edit-mode"] as string]: isChangingDates,
            })}
        >
            <button
                className={classnames(
                    styles["action-bar__button"],
                    "cursor-pointer",
                    "flex",
                    "items-center",
                    {
                        "font-bold": !isChangingDates,
                        "font-semibold": isChangingDates,
                        [styles["action-bar__button--active"] as string]:
                            isChangingDates,
                        "text-base": isChangingDates,
                        "text-sm": !isChangingDates,
                    }
                )}
                onClick={isChangingDates ? onEditBackClick : onChangeDatesClick}
            >
                {isChangingDates ? (
                    <>
                        <ChevronLeft />
                        <span>
                            <FormattedMessage
                                id="Calendar.flyout.back"
                                defaultMessage="Back"
                            />
                        </span>
                    </>
                ) : (
                    "Change Dates"
                )}
            </button>
        </div>
    )
}
