/* eslint-disable no-template-curly-in-string */
const calendar = {
    vacasa: "Vacasa",
    title: "Calendario",
    interiorDesignBannerText:
        "Obtén más dinero al renovar tu diseño de interiores.",
    covidBannerText:
        "Coronavirus homeowner resource guide - what we're doing to navigate COVID-19 together.",
    covidCtaDestination:
        "https://www.vacasa.com/support/coronavirus-homeowner-resource-guide",
    learnMore: "Más información",
    emptyState:
        "You’ll be able to reserve dates for your personal use and view upcoming reservations.",

    OwnerHolds: {
        heading: "Agregar una reserva de propietario",
        nonfixedHelperText:
            "Use this calendar to reserve dates for your own personal use.",
        fixedHelperText:
            "To reserve dates for your own personal use, please contact Homeowner Account Support at +1 (971) 254-3001 or",
        inactiveHelperText:
            "Esta propiedad está inactiva. Las reservas de propietarios solo se pueden agregar a propiedades activas.",
        ifHoldQuestions:
            "Si tienes alguna pregunta sobre esta reserva, contáctate con",
        toChangeHold:
            "Si necesitas cambiar tus fechas o cancelar esta reserva, envía un correo electrónico con tu solicitud a",
        continue: "Continuar",
        ariaLabel: "Haz clic para solicitar una reserva de propietario",
        checkIn: "Check In",
        checkOut: "Check Out",
        notSelected: "No seleccionado",
        clearDates: "Borrar fechas",
        employeeOptions: "Opciones de empleados adicionales",
        holdType: "Hold Reason",
        ownerHold: "Reserva del propietario",
        guestStay: "Guest Stay",
        vacasaHold: "Reserva de Vacasa",
        selectOwner: "Seleccionar un propietario",
        scheduleClean:
            "¿Deseas que programemos una limpieza luego de tu estadía?",
        yes: "Sí",
        no: "No",
        or: "o",
        warningBanner:
            "One or more of the dates for your hold are unavailable. Please check your selection and try again.",
        datesPlaceholder: "MM/DD/YYYY",
    },

    flyout: {
        vacasaContact: "Vacasa contact",
        contactUsFooter:
            "If you have any questions about this hold, please reach out.",
        back: "Back",
        clear: "Clear",
        holdPlaced: "Hold placed",
        holdUpdated: "Hold updated",
        tempAccessCode: "Código de acceso temporal",
        smartLockAria:
            "Haz clic en este enlace para conocer el código temporal de Smart Lock.",
        employeesMustClickToView: `Los empleados deben <button>hacer clic para ver este código</button>. Por seguridad, esta acción será registrada.`,
        smartLockCode: "Código Smart Lock",
        notAvailable: "No disponible",
        selectOption: "Selecciona una opción",
        reservation: "Reserva",
        ownerStay: "Estadía del propietario",
        ownerStaySubtitle: "Una reserva para uso personal.",
        complimentaryStay: "Estadía gratuita",
        complimentaryStaySubtitle: "Una reserva para familiares o amigos.",
        vacasaHold: "Reserva de Vacasa",
        vacasaHoldSubtitle: "Opción solo disponible para empleados.",
        block: "Bloquear",
        blockSubtitle: "Nadie usará la casa.",
        propertyCare: "Cuidado de la propiedad",
        propertyCareSubtitle: "Mantenimiento, mejoras, entregas, etc.",
        seasonalHold: "Seasonal hold",
        homeForSale: "Home for sale",
        homeForSaleSubtitle: "Activities related to a home sale.",
        other: "Other",
        otherSubtitle: "Does not fit the other categories.",
        terminationHold: "Termination hold",
        terminationHoldSubtitle: "Option only available for employees",
        notes: {
            title: "Notes ",
            titleRequired: "Notes* ",
            optional: "(optional)",
            required: "(required)",
            placeholder:
                "Help us improve our category options by providing a note about this hold",
            none: "N/A",
        },
        housekeeping: "Housekeeping",
        yesHousekeepingSubtitle:
            "A Vacasa housekeeping visit will be scheduled after this hold.",
        yesHousekeepingSubtitleWithCleaningFee:
            "A Vacasa housekeeping visit will be scheduled after this hold and billed to your account in the amount of <strong>${amount}.</strong>",
        noHousekeepingSubtitle:
            "You will be responsible for cleaning and preparing the home for the next guests.",
        ownerResponsibleForCleaning: "Owner responsible for cleaning",
        housekeepingScheduled: "Housekeeping programada",
        noHousekeeping: "No housekeeping",
        numberGuestsMax: `{{number}} huéspedes como máximo`,
        noPets: "No se permiten mascotas",
        restrictionsDontApply:
            "Las restricciones para huéspedes no aplican para los propietarios.",
        night: "night",
        nights: "noches",
        madeOn: "Booked on {creationDate}",
        madeBy: "Booked on {date} by {name}",
        monthCount:
            "Spans {monthCount, plural,=0 {}one {# month}other {# months}}",
        date: "Date",
        dates: "Fechas",
        guests: "Huéspedes",
        createHold: "Create Hold",
        next: "Next",
        summary: "Summary",
        cancelOwnerHold: "Cancel owner hold",
        cancelAreYouSure:
            "Are you sure you want to cancel? Your hold has not been scheduled.",
        deleteOwnerHold: "Delete owner hold",
        editHold: "Editar reserva",
        edit: "Editar",
        editAria: "Haz clic aquí para editar.",
        pleaseContactToRequestChange:
            "Contacta a {{contact}} para solicitar un cambio de fecha.",
        saveChanges: "Guardar cambios",
        save: "Guardar",
        saveAria: "Haz clic aquí para guardar",

        delete: "Borrar",
        removeHold: "Eliminar reserva",
        deleteHold: "Borrar reserva",
        deleteAria: "Haz clic aquí para borrar.",
        holdRemoved: "Reserva eliminada",
        deleteAreYouSure: "Are you sure you want to delete this?",
        deleteHelperText: "Esta acción no se puede deshacer.",
        deleteConfirm: "Sí, borrar reserva.",
        cancelDelete: "No, no borrar.",
        approvalNeeded: "Se necesita aprobación.",
        fixedRentDeletionApproval:
            "Durante un contrato de alquiler fijo, se necesita aprobación de nuestro equipo de soporte para borrar una reserva.",
        request: "Request",
        requestDelete: "Solicitud para borrar",
        deleteRequestConfirmation:
            "Esta es una solicitud para borrar una reserva del propietario que se realiza durante un contrato de alquiler fijo.",
        holdNumberForRequest: "Solicitud para borrar reserva Nº.",
        holdNumber: "Nº. de reserva: ",
        startDate: "Fecha de inicio: ",
        endDate: "Fecha de finalización: ",

        cancel: "Cancelar",
        done: "Terminado",
        totalNetRent: "Total Net rent",
        revenueBreakdown: "Revenue breakdown",
        fixedRent: "Alquiler fijo",
        grossRent: "Alquiler bruto",
        guestReview: "Guest review",
        guestReviewCollapseLabel: "See less",
        guestReviewExpandLabel: "See more",

        inviteGuest: "Invitar huésped",
        inviteGuests: "Invite Guests",
        inviteGuestsOptional: "Invite Guests (Optional)",
        inviteAnotherGuest: "Invitar otro huésped",
        inviteGuestsSubtitle:
            "Add guests to share your trip details and home access information.",
        firstName: "Nombre",
        lastName: "Apellido",
        emailAddress: "Dirección de correo electrónico",
        invitationSent: "Invitación enviada",
        invitationsSent: "invitaciones enviadas",
        fiveOrMoreInvitationsSent: "invitaciones enviadas",
        guestRemoved: "Huésped eliminado",
        skip: "Skip",
        skipAria: "Haz clic en este botón para saltear agregar huéspedes",
        inviteAnotherGuestAria:
            "Haz clic en este botón para invitar a otro huésped",
        noGuestInfo: "Sin información de huéspedes",
        guestInfo: "Guest info",
        adults: "adultos",
        fiveOrMoreAdults: "adultos",
        children: "niños",
        fiveOrMoreChildren: "niños",
        dogs: "perros",
        fiveOrMoreDogs: "perros",
        housekeepingNotAllowed:
            "Housekeeping not available. You will be responsible for cleaning and preparing the home for the next guests.",
        newBooking: "New booking",
        pastBooking: "Past booking",
        invalidDates: "The selected dates are not available.",
        learnAboutRates: "Learn about rates",
        guestBookingLikely:
            "These nights are highly likely to be booked by guests.",
        response: "Manager response",
        onboardingFooter: {
            title: "What do you want to do next?",
            backToOnboarding: "Go Back to Onboarding",
            addAdditionalOwnerHolds: "Add Another Owner Hold",
        },
        costOfHold:
            "You miss out on <b>{cost}</b> in gross rent by booking these dates.",
        housekeepingRequired:
            "In order to maintain the highest quality guest experience, housekeeping is required for stays with overnight guests.",
        housekeepingRequiedWithCleaningFee:
            "This owner hold requires a post-stay clean, which will be billed to your account after the stay in the amount of <strong>${amount}.</strong>",
        PhoneSections: {
            smsTitle: "Owner contact info",
            smsDescription: 'Details will be sent to this number.',
            smsEditDescription: "Details will be sent to <strong>{number}</strong>.",
            invalidPhoneMessage: 'The phone number is not valid',
            noPhoneNumber: 'No phone number provided',
        }
    },

    UpcomingStays: {
        title: "Reservations",
        emptyStateUpcoming:
            "New reservations and owner holds will show up here.",
        emptyStatePast:
            "You have no previous reservations. When upcoming stays have concluded, they will appear here.",
        upcomingDescription:
            "Upcoming guest reservations and owner holds will be listed below. Click a row to view more details. If you wish to modify an owner hold you can do so by clicking on the corresponding row.",
        pastDescription:
            "Your past reservations and holds in the last 45 days are listed out below.  If you would like to view more details around each stay, click on the detail row. ",
        toModify:
            "Si deseas modificar una reserva del propietario, puedes hacerlo a continuación.",
        fixedRentModify:
            "Si necesitas editar una reserva en tu propiedad durante un período de alquiler fijo, envía un correo electrónico a",
        details: "Detalles",
        Night: "Noche",
        adult: "adulto",
        child: "niño",
        dog: "perro",
        at: "en",
        upcomingTabTitle: "Upcoming ({total})",
        previousTabTitle: "Previous",
        adults: "adults",
        children: "children",
    },

    errorMessages: {
        generalError:
            "Tuvimos problemas para procesar tu solicitud. Intenta más tarde nuevamente. Si el problema persiste, llama a {name} al {phone}.",
        datesNotAvailableError:
            "Una o más de las fechas de tu reserva no están disponibles. Verifica tu selección e intenta nuevamente.",
        createHoldError: "No se pudo crear tu reserva de propietario.",
        deleteHoldError: "No se pudo eliminar tu reserva de propietario.",
        updateHoldError: "No se pudo actualizar tu reserva de propietario.",
        updateHoldCleanError: "No se pudo actualizar tu estado de limpieza.",
        addGuestToHoldError: "No se pudo añadir a tu huésped.",
        removeGuestFromHoldError: "No se pudo eliminar a tu huésped.",
        modalTitle: "Oops!",
        modalMessage:
            "It looks like this reservation is no longer in our system.",
        invalidDateError: "This date is unavailable",
        unableToLoadReservations: "We were unable to load your reservations.",
    },

    reservations: {
        reservation: "Reservation",
        vacasaHold: "Vacasa hold",
    },

    switchAccounts: {
        notification: "This reservation is associated with another account",
        description:
            "Please login to the appropriate homeowner account and click the new booking email notification to view the new reservation details.",
        button: "Switch Accounts",
        maybeLater: "Maybe Later",
        descriptionButtom:
            "Interested in linking your accounts into one login? Call <strong>Owner Support</strong> at {phone} to get it set-up.",
    },

    reservationType: {
        ownerHold: "Owner Hold",
        vacasaHold: "Vacasa Hold",
        reservation: "Guest Stay",
    },
}

export default calendar
