import { JSONAPIResource, UserPreference } from "@vacasa/owner-api-models"
import { AxiosError } from "axios"
import { LAST_ACCESSED_CONTACT_ID } from "../../constants/preferences.constants"
import {
    useQuery,
    UseQueryResult,
    useMutation,
    UseMutationResult,
    UseMutationOptions,
} from "react-query"
import { fetchOwnerPreferences } from "./fetchOwnerPreferences"
import { patchOwnerUserPreferences } from "./patchOwnerPreferences"
import { PatchUserPreferencesRequest } from "./patchOwnerPreferences"

export const OWNER_PREFERENCE_QUERY_KEY = "owner_preference"

const useOwnerPreferences = (): UseQueryResult<
    JSONAPIResource<UserPreference>[],
    AxiosError
> =>
    useQuery(
        OWNER_PREFERENCE_QUERY_KEY,
        ({ signal }) => fetchOwnerPreferences({ signal }),
        {
            refetchInterval: 1000 * 60 * 60, // 1 hour
            staleTime: 1000 * 60 * 60, // 1 hour
        }
    )

const useOwnerPreferencesMutation = (
    options?: Omit<
        UseMutationOptions<
            void,
            AxiosError,
            PatchUserPreferencesRequest,
            unknown
        >,
        "mutationFn"
    >
): UseMutationResult<
    void,
    AxiosError,
    PatchUserPreferencesRequest,
    unknown
> => {
    return useMutation<void, AxiosError, PatchUserPreferencesRequest, unknown>(
        owner => patchOwnerUserPreferences(owner),
        options
    )
}

type ChangeUser = {
    contactId: string
}

const useChangeUserMutation = (): UseMutationResult<
    void,
    AxiosError,
    ChangeUser,
    unknown
> => {
    return useMutation<void, AxiosError, ChangeUser, unknown>(
        ({ contactId }) =>
            patchOwnerUserPreferences({
                id: LAST_ACCESSED_CONTACT_ID,
                value: contactId,
            }),
        {
            onSuccess: _ => {
                // TODO: Look into this
                // we could potentially switch user's without having to reload the entire portal
                window.location.reload()
            },
        }
    )
}

export {
    useOwnerPreferences,
    useOwnerPreferencesMutation,
    useChangeUserMutation,
}
