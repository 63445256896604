import { PostOwnerSearchRequest } from "@vacasa/owner-api-models/src/legacy"
import { useOwnerSearch } from "hooks/search"
import { observer } from "mobx-react"
import { useCallback, useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useRootService } from "services"
import { trackSearchPageViewed } from "services/segment/search/searchTracking"
import storageService from "services/storage.service"
import {
    filterEmptySearchParams,
    toUnitSearchResults,
} from "views/search/utils"
import { EMPLOYEE_LAST_VIEWED_OWNER_URL } from "../../Constants"
import { SearchForm, SearchResults } from "./components"

import { UnitSearchResult } from "./types"
import { clearOwnerCache } from "utils/user"

const Search = (): JSX.Element => {
    const rootService = useRootService()
    const navigate = useNavigate()
    const location = useLocation()
    const { mutateAsync, data, isLoading, isSuccess } = useOwnerSearch()

    const [unitSearchResults, setUnitSearchResults] =
        useState<UnitSearchResult[]>()

    const backToSearch = useCallback(() => {
        const { resetStores } = rootService
        setUnitSearchResults(undefined)
        resetStores()
    }, [rootService])

    useEffect(() => {
        clearOwnerCache()
    }, [rootService])

    useEffect(() => {
        window.onpopstate = backToSearch
        trackSearchPageViewed()
        storageService.localStorage.setItem(
            EMPLOYEE_LAST_VIEWED_OWNER_URL,
            `${location.pathname}${location.search}`
        )
        return () => {
            window.onpopstate = null
        }
    }, [backToSearch, location])

    useEffect(() => {
        if (data && !isLoading && isSuccess) {
            setUnitSearchResults(
                toUnitSearchResults(Array.isArray(data) ? data : [])
            )
        }
    }, [data, isLoading, isSuccess])

    const onSearch = useCallback(
        async (params: PostOwnerSearchRequest): Promise<void> => {
            await mutateAsync({ data: params })
            window.scrollTo(0, 0)
            const filteredParams = filterEmptySearchParams(params)
            const queryString = new URLSearchParams(filteredParams)
            navigate({ search: queryString.toString() })
        },
        [navigate, mutateAsync]
    )

    const onLogoutClick = useCallback(
        (e: React.MouseEvent<HTMLElement>) => {
            e.preventDefault()
            rootService.logout()
        },
        [rootService]
    )

    return (
        <div className="search-container container">
            <div className="block margin-bottom-24">
                <Link id="logout" onClick={onLogoutClick} to="/logout">
                    Logout
                </Link>
            </div>
            {!unitSearchResults && (
                <SearchForm onSearch={onSearch} isLoading={isLoading} />
            )}
            {unitSearchResults && (
                <SearchResults
                    backToSearch={backToSearch}
                    unitSearchResults={unitSearchResults}
                />
            )}
        </div>
    )
}

export default observer(Search)
