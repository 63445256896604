/* eslint-disable camelcase */
// Page Path
export const PERFORMANCE_PAGE_PATH = "/performance"
// Event
export const PERFORMANCE_EVENT = "Performance"
export const COMPARE_PERFORMANCE_CHART_EVENT = "Compare Perfomance Chart"
export const GUEST_REVIEW_MODAL_EVENT = "Guest Review Modal"

export const CLICK_NIGHTS_OCCUPIED_COMPONENT_EVENT =
    "Nights Occupied Component Clicked"
export const VIEW_RESERVATION_EVENT = "Reservation Viewed"

// Action
export const UNIT_MIN_RATE_ACTION = "unit_min_rate"
export const ADD_FILTER_ACTION = "add_filter"
export const REMOVE_FILTER_ACTION = "remove_filter"
export const SELECT_FILTER_ACTION = "select_filter"
export const SEE_ALL_OPTION = "see_all"

// Option
export const REMIND_ME_LATER_OPTION = "remind_me_later"
export const ADJUST_MIN_RATE_OPTION = "adjust_min_rate"
export const PHONE_NUMBERCLICK_OPTION = "phone_number_click"
export const EMAIL_US_OPTION = "email_us"
// Experiment Track Name
export const MIN_RATE_NOTIFICATION_TEST_EXPERIMENT =
    "min-rate-notification-test"
// Experiment Track Variation
export const NOTIFICATION_SHOWN = "notification-shown"
// Feature Name
export const GUEST_REVIEW_MODAL_RESERVATION_ROW =
    "guest_review_modal_reservation_row"
export const GUEST_STAYS_COMPONENT = "guest_stays_component"
export const OWNER_HOLDS_COMPONENT = "owner_holds_component"
export const NIGHTS_OCCUPIED_MODAL_RESERVATION_ROW =
    "nights_occupied_modal_reservation_row"
export const GUEST_REVIEW_MODAL_FEEDBACK = "guest_review_modal_feedback"

export const MARKET_COMP_CHART_LOADED = "Market Comp Chart Loaded"

export const CORE_METRICS_WIDGETS_LOADED = "Core Metrics Widgets Loaded"
