import { useLoginInfo } from "contexts/login"
import { useOwnerFeatureFlag } from "hooks/owner-feature-flag"
import React, { useEffect } from "react"
import { identifyHotjar, initHotjar } from "utils/hotjar"

export const HotjarProvider: React.FC = () => {
    const { user, idpId } = useLoginInfo()
    const hotjarFeatureFlag = useOwnerFeatureFlag("hotjar")

    const isEnabled = hotjarFeatureFlag.isEnabled ?? false
    const contactId = user?.contactId ?? ""
    const email = user?.email ?? ""
    const legacyUserId = user?.userId ?? ""
    const userId = idpId ?? ""

    useEffect(() => {
        if (isEnabled) {
            const success = initHotjar()
            if (success) {
                identifyHotjar({ contactId, email, legacyUserId, userId })
            }
        }
    }, [contactId, email, isEnabled, legacyUserId, userId])

    return <></>
}
