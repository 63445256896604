import { authAxios } from "api/authAxios"
import { RoomModel } from "models/Room"

export type PostUnitRoomsParameters = {
    contactId: string
    unitId: string
    data: RoomModel[]
}

export const postUnitRooms = (args: PostUnitRoomsParameters): Promise<void> =>
    authAxios.post(`v1/owners/${args.contactId}/units/${args.unitId}/rooms`, {
        data: args.data,
    })
