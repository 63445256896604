import Hotjar from "@hotjar/browser"
import { HOTJAR_SITE_ID, HOTJAR_VERSION } from "Constants"
import { NODE_ENV } from "Environment"
import LoggingService from "services/logging/logging.service"

export function initHotjar() {
    const success = Hotjar.init(HOTJAR_SITE_ID, HOTJAR_VERSION, {
        debug: NODE_ENV === "development",
    })

    if (success) {
        LoggingService.log({
            message: "Successfully initialised Hotjar.",
        })
    } else {
        LoggingService.warn({
            message: "Failed to initialise Hotjar.",
        })
    }

    return success
}
