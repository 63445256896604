import React from "react"
import { FormattedMessage } from "react-intl"
import { Plan } from "utils/programs/programsUtil"
import { Card, CardContent } from "../../../components/Card"

interface PlanHistoryProps {
    plans: Plan[]
}

export const PlanHistory = React.memo(
    ({ plans }: PlanHistoryProps): JSX.Element => {
        if (!plans.length) {
            return <></>
        }

        return (
            <div className="plan-history">
                <div className="prepend type-body-small">
                    <FormattedMessage
                        id="LinenService.servicePlan.planHistory"
                        defaultMessage="Plan history"
                    />
                </div>
                <Card>
                    <CardContent>
                        <ul className="history">
                            {plans.map(plan => (
                                <PlanHistoryItem key={plan.id} plan={plan} />
                            ))}
                        </ul>
                    </CardContent>
                </Card>
            </div>
        )
    }
)

export const PlanHistoryItem = React.memo(
    ({ plan, testId }: { plan: Plan; testId?: string }): JSX.Element => (
        <li data-testid={testId} className={["list-item"].join(" ")}>
            <div className="description">{plan.description}</div>
            <div className="date">{plan.date}</div>
            <div className="charge">{plan.amount}</div>
        </li>
    )
)
