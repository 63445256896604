import { ReactComponent } from "*.svg"
import {
    JSONAPIResource,
    OwnerHoldType,
    Reservation,
    ReservationType,
    VacasaHoldType,
} from "@vacasa/owner-api-models"
import { ReactComponent as OutOfOrderIcon } from "assets/icon-alert-triangle.svg"
import { ReactComponent as PhotographyIcon } from "assets/icon-camera.svg"
import { ReactComponent as GuestReservationIcon } from "assets/icon-guest-reservation.svg"
import { ReactComponent as ComplimentaryStayIcon } from "assets/icon-hold-complimentary-stay.svg"
import { ReactComponent as MaintenanceIcon } from "assets/icon-hold-maintenance.svg"
import { ReactComponent as OtherHoldIcon } from "assets/icon-hold-other.svg"
import { ReactComponent as OwnerStayIcon } from "assets/icon-hold-owner-stay.svg"
import { ReactComponent as PropertyCareIcon } from "assets/icon-hold-property-care.svg"
import { ReactComponent as SeasonalHoldIcon } from "assets/icon-hold-seasonal.svg"
import { ReactComponent as TerminationHoldIcon } from "assets/icon-hold-termination.svg"
import { ReactComponent as HomeForSaleIcon } from "assets/icon-home-for-sale.svg"
import { ReactComponent as HousekeepingIcon } from "assets/icon-spray-bottle.svg"
import { GuestReservationType } from "../../models/GuestReservationType"
import { SVGComponent } from "../../models/SVGComponent"

export function parseReservationSubType(
    reservation: JSONAPIResource<Reservation>
): OwnerHoldType | VacasaHoldType | GuestReservationType {
    switch (reservation.attributes.occupancyType) {
        case ReservationType.RESERVATION:
            return GuestReservationType.GUEST_RESERVATION
        case ReservationType.OWNERHOLD:
            return reservation.attributes.ownerHold?.holdType ?? "other"
        case ReservationType.VACASAHOLD:
            return reservation.attributes.vacasaHold?.holdType ?? "other"
        default:
            return "other"
    }
}

export function getOwnerHoldTypeIcon(
    value?: OwnerHoldType
): typeof ReactComponent | null {
    switch (value) {
        case "owner stay":
            return OwnerStayIcon
        case "complimentary stay":
            return ComplimentaryStayIcon
        case "property care":
            return PropertyCareIcon
        case "seasonal hold":
            return SeasonalHoldIcon
        case "home for sale":
            return HomeForSaleIcon
        case "termination hold":
            return TerminationHoldIcon
        case "block":
        case "other":
            return OtherHoldIcon
        default:
            return null
    }
}

export function getVacasaHoldTypeIcon(
    value?: VacasaHoldType | null
): typeof ReactComponent | null {
    switch (value) {
        case "maintenance":
            return MaintenanceIcon
        case "other":
            return OtherHoldIcon
        case "out of order":
            return OutOfOrderIcon
        case "photography":
            return PhotographyIcon
        case "housekeeping":
            return HousekeepingIcon
        default:
            return null
    }
}

export function getHoldTypeIcon(
    reservation: JSONAPIResource<Reservation>
): typeof ReactComponent | null {
    if (reservation.attributes.occupancyType === ReservationType.RESERVATION) {
        return GuestReservationIcon
    }

    if (reservation.attributes.occupancyType === ReservationType.OWNERHOLD) {
        const ownerHoldType = reservation.attributes.ownerHold?.holdType
        return ownerHoldType ? OwnerStayIcon : OtherHoldIcon
    }

    if (reservation.attributes.occupancyType === ReservationType.VACASAHOLD) {
        const vacasaHoldType = reservation.attributes.vacasaHold?.holdType
        return vacasaHoldType
            ? getVacasaHoldTypeIcon(vacasaHoldType)
            : OtherHoldIcon
    }

    return OtherHoldIcon
}

export interface ReservationDetails {
    title: string
    titleSVG: SVGComponent
    subtitle?: string
    subtitleSVG?: SVGComponent
}

export const isOwnerHoldType = (type: string): type is OwnerHoldType => {
    return [
        "owner stay",
        "complimentary stay",
        "property care",
        "seasonal hold",
        "home for sale",
        "block",
        "other",
        "vacasa hold",
        "termination hold",
    ].includes(type)
}
