/* eslint-disable camelcase */
import { DYNAMIC_PRICING_URL } from "Constants"
import { NAVIGATE_TO_ONBOARDING_EVENT } from "../onboarding/onboardingTrackingConstants"
import segmentService from "../segment.service"
import {
    ADD_ANOTHER_HOLD_ACTION,
    BUTTON_CLICK_ACTION,
    CALCELLATION_FLYOUT_ACTION,
    CALENDAR_EVENT,
    LINK_CLICK_ACTION,
    OWNER_HOLD_CLICK_ACTION,
    RESERVATION_DETAILS_EVENT,
    RESERVATION_DETAILS_VIEWED_ACTION,
    SEE_MORE_OPTION,
    SHOW_CANCELLATION_FLYOUT_VIEWED_EVENT,
} from "./reservationTrackingConstants"

export const trackReservationDetailsViewed = (
    reservation_id: string,
    unit_id: string,
    stay: string,
    high_probability_booking_shown: boolean
): void => {
    segmentService.track(RESERVATION_DETAILS_EVENT, {
        action: RESERVATION_DETAILS_VIEWED_ACTION,
        reservation_id,
        unit_id,
        stay,
        high_probability_booking_shown,
    })
}

export const trackReservationDetailsSeeMoreClicked = (
    reservation_id: string
): void => {
    segmentService.track(RESERVATION_DETAILS_EVENT, {
        action: LINK_CLICK_ACTION,
        option: SEE_MORE_OPTION,
        reservation_id,
    })
}

export const trackReservationDetailsNetRentBreakdown = (
    unit_id: string,
    reservation_id: string,
    trackingEvent: string
): void => {
    segmentService.track(trackingEvent, {
        action: BUTTON_CLICK_ACTION,
        unit_id,
        reservation_id,
        reservation_type: "guest",
    })
}

export const trackReservationDetailsLearnAboutRates = (
    reservation_id: string,
    option: string
): void => {
    segmentService.track(RESERVATION_DETAILS_EVENT, {
        action: LINK_CLICK_ACTION,
        option,
        url_selected: DYNAMIC_PRICING_URL,
        reservation_id,
    })
}

export const trackCancelledReservationFlyout = (
    unitID: string,
    reservationID: string
): void => {
    segmentService.clickTrack(
        {
            action: CALCELLATION_FLYOUT_ACTION,
            unit_id: unitID,
            reservation_id: reservationID,
        },
        SHOW_CANCELLATION_FLYOUT_VIEWED_EVENT
    )
}

export const trackAddAnotherHoldFromOnboardingFooter = (
    unit_id: string,
    contact_id: string
): void => {
    segmentService.clickTrack(
        {
            action: ADD_ANOTHER_HOLD_ACTION,
            unit_id,
            contact_id,
        },
        CALENDAR_EVENT
    )
}

export const trackOwnerHoldBackToOnboardingClicked = (
    unit_id: string,
    contact_id: string
): void => {
    segmentService.clickTrack(
        {
            action: OWNER_HOLD_CLICK_ACTION,
            unit_id,
            contact_id,
        },
        NAVIGATE_TO_ONBOARDING_EVENT
    )
}

export const trackReservationTabsClicked = (newStayType: string): void => {
    segmentService.clickTrack(
        { action: `reservations_${newStayType}_tab` },
        RESERVATION_DETAILS_EVENT
    )
}
