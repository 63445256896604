import classnames from "classnames"
import React from "react"
import { useContext } from "react"
import { NetRentToggleContext } from "../NetRentToggleContext"
import styles from "../Performance.module.scss"

export const RentTypeToggle = (): JSX.Element => {
    const { netRentToggled, setNetRentToggled } =
        useContext(NetRentToggleContext)
    const updateHandler = (value: boolean) => setNetRentToggled(value)
    return (
        <label className={styles["switch"]}>
            <input
                className={styles["switch-input"]}
                type="checkbox"
                checked={netRentToggled}
                disabled={true}
            />
            <span className={styles["slider"]} />
            <span className={styles["switch-grossrent"]}>
                <div
                    className={classnames(
                        styles["switch-text"],
                        !netRentToggled ? styles["toggled-text"] : ""
                    )}
                    onClick={() => {
                        if (netRentToggled) {
                            updateHandler(!netRentToggled)
                        }
                    }}
                >
                    GROSS RENT
                </div>
            </span>
            <span className={styles["switch-netrent"]}>
                <div
                    className={classnames(
                        styles["switch-text"],
                        netRentToggled ? styles["toggled-text"] : ""
                    )}
                    onClick={() => {
                        if (!netRentToggled) {
                            updateHandler(!netRentToggled)
                        }
                    }}
                >
                    NET RENT
                </div>
            </span>
        </label>
    )
}
