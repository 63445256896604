import React, { FunctionComponent, MouseEvent } from "react"
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab"
import styles from "./ToggleGroup.module.scss"

interface ToggleGroupProps {
    toggleValues: string[]
    selectedValue?: string
    disabled?: boolean
    onChangeHandler: (value: string) => void
}

const ToggleGroup: FunctionComponent<ToggleGroupProps> = ({
    toggleValues,
    selectedValue,
    disabled,
    onChangeHandler,
}: ToggleGroupProps): JSX.Element => {
    const handleToggle = (
        _event: MouseEvent<HTMLElement>,
        newToggle: string
    ): void => {
        if (newToggle !== null) {
            onChangeHandler(newToggle)
        }
    }

    return (
        <div>
            <ToggleButtonGroup
                value={selectedValue}
                exclusive
                onChange={handleToggle}
                classes={{ root: styles["ToggleGroup-root"] }}
            >
                {toggleValues.map((option, idx) => (
                    <ToggleButton
                        key={idx}
                        value={option}
                        classes={{
                            root: styles["ToggleButton-root"],
                            selected: styles["ToggleButton-selected"],
                            disabled: styles["ToggleButton-disabled"],
                        }}
                        disabled={disabled}
                    >
                        {option}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
        </div>
    )
}

export default ToggleGroup
