import React, { Fragment, ReactNode } from "react"
import { uniqueId } from "lodash"

import { ReactComponent } from "*.svg"
import classnames from "classnames"
import styles from "./StaticListItem.module.scss"
import StaticListActionItem, { ActionName } from "./StaticListActionItem"

interface ItemProps {
    title: ReactNode
    subtext: string | JSX.Element | null
    pill?: JSX.Element | null
    primary?: boolean
    Icon?: typeof ReactComponent | null
    link?: JSX.Element | null
    Tag?: JSX.Element | null
    displayAction?: boolean
    displayActionItem?: ActionName
    onEditClick?: () => void
    onSaveClick?: () => void
    isSaveDisabled?: boolean
    onOnOffSwitchClick?: (checked: boolean) => void
    isSwitchChecked?: boolean
    isSwitchDisabled?: boolean
    customStyling?: {
        Wrapper?: string | null
        Icon?: string | null
        Title?: string | null
        Subtitle?: string | null
        Link?: string | null
    }
}

const StaticListItem: React.FC<ItemProps> = ({
    title,
    subtext,
    pill,
    primary,
    Icon,
    link,
    Tag,
    displayAction,
    displayActionItem,
    onEditClick,
    onSaveClick,
    isSaveDisabled,
    onOnOffSwitchClick,
    isSwitchChecked,
    isSwitchDisabled,
    customStyling,
}) => {
    return (
        <Fragment>
            <li
                key={uniqueId("listItem_")}
                className={classnames(
                    styles[`Wrapper${primary ? "-primary" : ""}`],
                    customStyling?.Wrapper ?? ""
                )}
            >
                {Icon && (
                    <Icon
                        className={classnames(
                            styles[`Icon${primary ? "-primary" : ""}`],
                            customStyling?.Icon ?? ""
                        )}
                    />
                )}
                <div
                    className={classnames(
                        styles[`Title${primary ? "-primary" : ""}`],
                        customStyling?.Title ?? ""
                    )}
                >
                    <div className={styles.titleText}>{title}</div>
                    {pill}
                    <div className={styles.tag}> {Tag} </div>
                </div>
                {displayAction && (
                    <StaticListActionItem
                        displayItem={displayActionItem}
                        onEditClick={onEditClick}
                        onSaveClick={onSaveClick}
                        isSaveDisabled={isSaveDisabled}
                        onOnOffSwitchClick={onOnOffSwitchClick}
                        isSwitchChecked={isSwitchChecked}
                        isSwitchDisabled={isSwitchDisabled}
                    />
                )}
                <div
                    className={classnames(
                        styles[`Subtitle${primary ? "-primary" : ""}`],
                        customStyling?.Subtitle ?? ""
                    )}
                >
                    {subtext}
                </div>
                <div
                    className={classnames(
                        styles.Link,
                        customStyling?.Link ?? ""
                    )}
                >
                    {link}
                </div>
            </li>
        </Fragment>
    )
}

export default StaticListItem
