import { PropsWithChildren, ReactNode } from "react"

interface ConditionalWrapProps {
    condition: boolean
    wrapper: (children: ReactNode) => JSX.Element
}

export const ConditionalWrap = ({
    condition,
    wrapper,
    children,
}: PropsWithChildren<ConditionalWrapProps>) =>
    condition ? wrapper(children) : <>{children}</>
