import { MARKET_COMP_FEATURE_FEEDBACK_DISMISSED } from "constants/preferences.constants"
import { useOwnerPreferences } from "hooks/owner-preferences"
import { FeatureFeedback } from "lib/components/FeatureFeedback/FeatureFeedback"
import { useIntl } from "react-intl"
import styles from "./ChartFeatureFeedback.module.scss"
export const ChartFeatureFeedback = () => {
    const ownerPreferences = useOwnerPreferences()
    const intl = useIntl()
    const marketCompFeedbackDismissedPreference = ownerPreferences.data?.find(
        pref => pref.id === MARKET_COMP_FEATURE_FEEDBACK_DISMISSED
    )

    if (ownerPreferences.isLoading) {
        return null
    }

    return (
        <FeatureFeedback
            featureId="market-rate-comparison-chart"
            ratingBody={intl.formatMessage({
                id: "Dashboard.charts.marketRate.feedback.ratingsBody",
                defaultMessage:
                    "You can now view how your home is priced in relation to other comparable properties in your area.\n\nDo you find this new Market Rate Comparison feature helpful?",
            })}
            commentsBody={intl.formatMessage({
                id: "Dashboard.charts.marketRate.feedback.commentsBody",
                defaultMessage:
                    "Thanks for your feedback!\nCare to share more?",
            })}
            requiredInteractionCount={3}
            title={intl.formatMessage({
                id: "Dashboard.charts.marketRate.feedback.title",
                defaultMessage: "New! Market rate comparison Chart",
            })}
            userPreference={marketCompFeedbackDismissedPreference}
            pointerPosition="bottom-left"
            wrapper={children => (
                <div className={styles.notificationWrap}>{children}</div>
            )}
        />
    )
}
