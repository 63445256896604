const linenService = {
    title: "Servizio biancheria",

    notificationComponent: {
        planWillBeginWithInitialCharge:
            "Il tuo programma comincerà il <strong>{date, date, medium}</strong> con un addebito iniziale di <strong>{cost}</strong>.",
        planWillBeginWithCharge:
            "Il tuo programma comincerà il <strong>{date, date, medium}</strong> con un addebito di <strong>{cost}</strong>.",
        planWillRenewFor:
            "Il tuo programma si rinnoverà automaticamente il <strong>{date, date, medium}</strong> per <strong>{cost}</strong>",
        planWillEndOn:
            "Il tuo programma terminerà il <strong>{date, date, medium}</strong> dopo il quale non riceverai più sostituzioni scontate di lenzuola e asciugamani.",
    },

    infoPanel: {
        learnMoreTitle: "Una soluzione semplice e conveniente",
        bodyText:
            "Il nostro servizio biancheria fornisce lenzuola della qualità di un hotel a un prezzo scontato per il settore. Lenzuola fresche e asciugamani morbidi per ogni ospite significano migliori recensioni, maggiori prenotazioni e più entrate per te.",
        learnMore: "Per saperne di più",
    },

    servicePlan: {
        prepend: "Il tuo programma",
        standard: "Servizio biancheria standard",
        premium: "Servizio biancheria premium",
        youAreNotEnrolled: "Non sei iscritto",
        year: "anno",
        nextBillingDate: "Prossima data di fatturazione",
        newPlanBegins: "Data di inizio del nuovo programma",
        nextBilling: "Prossima fatturazione",
        discount: "sconto",
        setup: "Configurazione",
        setupFee: "commissione di configurazione",
        planHistory: "Cronologia del programma",
        ongoing: "in corso",
        pending: "sospeso",
        append: "Tutte gli addebiti di questo servizio saranno fatturati come spese sul tuo estratto conto mensile.",
    },

    helpPanel: {
        needHelp: "Serve aiuto?",
        contactSentenceIfQuestions:
            "Ti preghiamo di contattare il tuo responsabile {contact} in caso di domande o se desideri aggiornare il tuo programma.",
    },

    enrolled: "Iscritto",
    notEnrolled: "Non iscritto",
    begins: "Inizia",
    renews: "Rinnovo",
    expires: "Scade",
}

export default linenService
