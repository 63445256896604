import { FC, useCallback } from "react"
import styles from "./SupportContacts.module.scss"
import { Clock, IconVacasaLogoWhite, Mail, Phone } from "assets"
import classNames from "classnames"
import { formatPhone } from "utils/phone/formatPhone"
import {
    trackEmailClick,
    trackPhoneNumberClick,
    trackSMSClick,
} from "services/segment/homeInfo/homeInfoTracking"
import { Unit } from "@vacasa/owner-api-models"
import { Smartphone } from "react-feather"

export type SupportContactsProps = {
    contacts: Unit["contacts"]
}

type ContactCardProps = {
    description: string | null
    displayName: string | null
    email: string | null
    hoursOfOperation: string | null
    phone: string | null
    sms: string | null
    title: string | null
}

export const ContactCard: FC<ContactCardProps> = props => {
    const {
        description,
        displayName,
        email,
        hoursOfOperation,
        phone,
        sms,
        title,
    } = props

    const formattedPhone = formatPhone(phone)

    const onPhoneClick = useCallback((formattedPhone: string) => {
        trackPhoneNumberClick(formattedPhone)
    }, [])

    const onEmailClick = useCallback((email: string) => {
        trackEmailClick(email)
    }, [])

    const onSMSClick = useCallback((sms: string) => {
        trackSMSClick(sms)
    }, [])

    return (
        <div className={classNames(styles.card)}>
            <div className={styles.header}>
                <div className={classNames(styles.icon)}>
                    <IconVacasaLogoWhite />
                </div>
                <div className={styles.info}>
                    <h4
                        className={classNames(styles.title, {
                            [styles.titleOnly as string]: !displayName,
                        })}
                    >
                        {title}
                    </h4>
                    {displayName && (
                        <span
                            className={classNames(
                                styles.name,
                                "type-heading-extra-small-caps"
                            )}
                        >
                            {displayName}
                        </span>
                    )}
                </div>
            </div>
            {description && (
                <div
                    className={classNames(
                        "type-body-small",
                        styles.description
                    )}
                >
                    {description}
                </div>
            )}

            {hoursOfOperation && (
                <ContactItem type="support-hours" value={hoursOfOperation} />
            )}

            {formattedPhone && (
                <ContactItem
                    type="phone"
                    value={formattedPhone}
                    onClick={onPhoneClick}
                />
            )}

            {email && (
                <ContactItem
                    type="email"
                    value={email}
                    onClick={onEmailClick}
                />
            )}

            {sms && (
                <ContactItem
                    displayValue="SMS Text"
                    type="sms"
                    value={sms}
                    onClick={onSMSClick}
                />
            )}
        </div>
    )
}

type ContactItemProps = {
    onClick?: (value: string) => void
    type: "email" | "phone" | "support-hours" | "sms"
    value: string
    displayValue?: string
}
const ContactItem: FC<ContactItemProps> = ({
    displayValue,
    onClick,
    type,
    value,
}) => {
    const onLinkClick = () => {
        if (!onClick) return
        onClick(value)
    }

    const linkPrefix = () => {
        switch (type) {
            case "email":
                return "mailto:"
            case "phone":
                return "tel:"
            case "sms":
                return "sms:"
            default:
                return ""
        }
    }

    return (
        <div
            className={classNames(styles.item, {
                [styles["item--small-icon"] as string]:
                    type === "support-hours",
            })}
        >
            <div className={classNames("flex", "items-center")}>
                <div className={classNames(styles.icon)}>
                    {type === "email" && <Mail />}
                    {type === "phone" && <Phone />}
                    {type === "support-hours" && <Clock />}
                    {type === "sms" && <Smartphone />}
                </div>
            </div>
            <div className={classNames("flex", "items-center", styles.detail)}>
                {(type === "email" || type === "phone" || type === "sms") && (
                    <a
                        className={classNames(styles.link, "ellipsis", "block")}
                        href={`${linkPrefix()}${value}`}
                        target="_blank"
                        rel="noreferrer"
                        onClick={onLinkClick}
                    >
                        {displayValue ?? value}
                    </a>
                )}
                {(type === "support-hours" && displayValue) ?? value}
            </div>
        </div>
    )
}
