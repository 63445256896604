/* eslint-disable camelcase */

// Actions
export const UPDATE_AMENITIES_ACTION = "amenities"
export const UPDATE_BATHROOMS_ACTION = "bathrooms"
export const UPDATE_KITCHEN_ACTION = "kitchen"
export const UPDATE_PARKING_ACTION = "parking"
export const UPDATE_BEDS_ACTION = "beds"
export const VIEW_CALENDAR_ACTION = "view_calendar"
export const SUBMIT_AVAILABILITY_EDIT_NOTES_ACTION =
    "edit_notes_for_submit_onboarding_availability"
export const SUBMIT_AVAILABILITY_ALERT_CLICKED_ACTION =
    "onboarding_availability_alert_clicked"
export const SUBMIT_AVAILABILITY_ACTION = "onboarding_availability_submitted"
export const SUBMIT_AVAILABILITY_CALENDAR_DATE_CLICKED_ACTION =
    "onboarding_availability_calendar_date_clicked"
export const SUBMIT_AVAILABILITY_ALERT_DISPLAYED_ACTION =
    "onboarding_availability_alert_displayed"
export const NAVIGATION_CLICK_ACTION = "navigation_click"

// Events
export const LISTING_DETAILS_UPDATED_EVENT = "Listing Details Updated"
export const SETUP_EVENT = "Setup"
export const SUBMIT_AVAILABILITY_EDIT_NOTES_EVENT =
    "Onboarding Availability Edit Notes"
export const SUBMIT_AVAILABILITY_EVENT = "Onboarding Availability Submitted"
export const SUBMIT_AVAILABILITY_ALERT_CLICKED_EVENT =
    "Onboarding Availability Alert Clicked"
export const SUBMIT_AVAILABILITY_CALENDAR_DATE_CLICKED_EVENT =
    "Onboarding Availability Calendar Date Clicked"
export const SUBMIT_AVAILABILITY_ALERT_DISPLAYED_EVENT =
    "Onboarding Availability Alert Displayed"
export const NAVIGATE_TO_ONBOARDING_EVENT = "Navigate to Onboarding"
export const REVIEW_LISTING_DETAILS = "Review Listing Details"
