import { Link } from "react-router-dom"
import styles from "./W8FormsDownload.module.scss"

export const W8FormsDownload = () => {
    return (
        <div className={styles.container}>
            <span className="type-heading-small-caps">Download your form</span>
            <ul>
                {w8Forms.map(form => (
                    <li key={form.formName}>
                        <Link to={form.href}>{form.formName}</Link>
                    </li>
                ))}
            </ul>
        </div>
    )
}

const w8Forms = [
    {
        formName: "Form W-8BEN",
        href: "https://www.irs.gov/forms-pubs/about-form-w-8-ben",
    },
    {
        formName: "Form W-8BEN-E",
        href: "https://www.irs.gov/forms-pubs/about-form-w-8-ben-e",
    },
    {
        formName: "Form W-8ECI",
        href: "https://www.irs.gov/forms-pubs/about-form-w-8-eci",
    },
    {
        formName: "Form W-8EXP",
        href: "https://www.irs.gov/forms-pubs/about-form-w-8-exp",
    },
    {
        formName: "Form W-8IMY",
        href: "https://www.irs.gov/forms-pubs/about-form-w-8-imy",
    },
]
