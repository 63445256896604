import { defineMessage, MessageDescriptor } from "react-intl"

const guestReservation = "guest.reservation"

interface inheritedMessagesTypes extends MessageDescriptor {
    inheritedTitle: {
        id: string
        defaultMessage: string
    }
    inheritedDescription: {
        id: string
        defaultMessage: string
    }
}

export const inheritedMessages = defineMessage({
    inheritedTitle: {
        id: `${guestReservation}.title`,
        defaultMessage: "Inherited Booking",
    },
    inheritedDescription: {
        id: `${guestReservation}.description`,
        defaultMessage:
            "This booking was received through your previous property management company",
    },
} as inheritedMessagesTypes)
