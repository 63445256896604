import { FormattedMessage } from "react-intl"
import styles from "./Warning.module.scss"
import * as React from "react"
import { FormControl, MenuItem, Select } from "@material-ui/core"
import { ChevronDown } from "react-feather"

interface WarningProps {
    handleAcknowledgement: (option: boolean) => void
}

const Warning: React.FC<WarningProps> = props => {
    const { handleAcknowledgement } = props
    const [option, setOption] = React.useState("")

    return (
        <div className={styles.warning}>
            <FormattedMessage
                id="OwnerReferral.warning"
                defaultMessage={`The state where your referral is located prohibits Vacasa from compensating on the referral unless you have an active real estate license.  To acknowledge that you have read that a referral cannot be granted please select "yes".`}
            />
            <div>
                <FormControl className={styles.yesAndNo}>
                    <Select
                        value={option}
                        onChange={e => {
                            setOption(e.target.value as string)
                            e.target.value === "yes"
                                ? handleAcknowledgement(true)
                                : handleAcknowledgement(false)
                        }}
                        displayEmpty
                        IconComponent={() => (
                            <ChevronDown className="MuiSvgIcon-root MuiSelect-icon" />
                        )}
                        disableUnderline
                        classes={{
                            select: styles.placeholder,
                            icon: styles.icon,
                        }}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                            },
                            getContentAnchorEl: null,
                        }}
                    >
                        <MenuItem value="" disabled>
                            {
                                <div>
                                    <FormattedMessage
                                        id="OwnerReferral.yes"
                                        defaultMessage="Yes"
                                    />
                                    /
                                    <FormattedMessage
                                        id="OwnerReferral.no"
                                        defaultMessage="No"
                                    />
                                </div>
                            }
                        </MenuItem>
                        <MenuItem value="yes">
                            {
                                <div>
                                    <FormattedMessage
                                        id="OwnerReferral.yes"
                                        defaultMessage="Yes"
                                    />
                                </div>
                            }
                        </MenuItem>
                        <MenuItem value="no">
                            {
                                <div>
                                    <FormattedMessage
                                        id="OwnerReferral.no"
                                        defaultMessage="No"
                                    />
                                </div>
                            }
                        </MenuItem>
                    </Select>
                </FormControl>
            </div>
        </div>
    )
}

export { Warning }
