import { AxiosError } from "axios"
import { useContactId } from "hooks/user"
import { UseMutationResult, useMutation } from "react-query"
import { patchW8 } from "./patchW8"

export const useW8UpdateMutation = (): UseMutationResult<
    void,
    AxiosError,
    void,
    unknown
> => {
    const contactId = useContactId() ?? ""
    return useMutation(() => patchW8(contactId))
}
