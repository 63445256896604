import { AxiosError } from "axios"
import { useContactId } from "hooks/user"
import {
    useQuery,
    useMutation,
    useQueryClient,
    UseQueryResult,
    UseMutationResult,
} from "react-query"
import {
    fetchHomeAvailability,
    HomeAvailabilityResponse,
} from "./fetchHomeAvailability"
import {
    HomeAvailabilitySetResponse,
    setHomeAvailability,
} from "./setHomeAvailability"

const HomeAvailabilityKey = "home-availability" as const

const useHomeAvailability = (
    unitId: number
): UseQueryResult<HomeAvailabilityResponse, AxiosError> => {
    const contactId = useContactId()
    return useQuery(
        [HomeAvailabilityKey, unitId],
        () => fetchHomeAvailability({ unitId }),
        {
            enabled: typeof contactId === "string",
        }
    )
}

const useHomeAvailabilityMutation = ({
    contactId,
    unitId,
    assigneeId,
}: {
    contactId: string
    unitId: number
    assigneeId: number | null | undefined
}): UseMutationResult<
    HomeAvailabilitySetResponse,
    AxiosError,
    {
        availableDates: readonly Date[]
        note: string
    },
    void
> => {
    const queryClient = useQueryClient()
    return useMutation<
        HomeAvailabilitySetResponse,
        AxiosError,
        {
            availableDates: readonly Date[]
            note: string
        },
        void
    >(
        ({ availableDates, note }): Promise<HomeAvailabilitySetResponse> =>
            setHomeAvailability({
                contactId,
                unitId,
                availableDates,
                note,
                assigneeId,
            }),
        {
            onSuccess: data => {
                queryClient.setQueryData<HomeAvailabilityResponse>(
                    [HomeAvailabilityKey, unitId.toString()],
                    data
                )
            },
            onSettled: _response => {
                queryClient.invalidateQueries([
                    HomeAvailabilityKey,
                    unitId.toString(),
                ])
            },
        }
    )
}
export { useHomeAvailability, useHomeAvailabilityMutation }
