const statements = {
    faqAriaLabel: "View FAQ",
    title: "Relevés",
    emptyStateCTA: "En savoir plus sur les relevés",
    emptyStateEarnings:
        "De nouveaux relevés seront disponibles le 10 de chaque mois.",

    pdf: "PDF",
    pdfAriaLabel: "Voir le PDF",
    pdfNotification: "Téléchargement de PDF en cours de préparation",
    fileName: "relevé",

    print: "Imprimer",
    printAriaLabel: "Aperçu de l'impression",

    paymentSummary: "Synthèse des paiements : toutes les locations",
    beginningBalance: "Solde initial",
    payment: "Paiement",
    payments: "Paiements",
    proceeds: "Recettes",
    proceedsBalance: "Solde des recettes",
    coOwnerProceeds: "Recettes de copropriété",
    endingBalance: "Solde final",
    details: "Détails",
    summary: "Résumé",
    nightsOccupied: "Nuits logement occupé",
    occupancy: "Taux d'occupation",
    reserveBalance: "Solde de réserve",

    beginningReserveBalance: "Solde de réserve initial",
    EndingReserveBalance: "Solde de réserve final",
    amountHeld: "Montant conservé",
    amountUsed: "Montant utilisé",

    netRent: "Loyer net",
    expense: "Dépense",
    expenses: "Dépenses",

    month: "Mois",
    nights: "Nuits",
    grossRent: "Loyer brut",
    mgmtFee: "Frais de gestion",
    total: "Total",
    credits: "Crédit",

    checkNumber: "N° de chèque",
    paymentDescription: "Paiement compte se terminant par",
    statementYearSummary: "{year} Year Summary",

    canadaSalesTaxesHeader: "Taxes - Expenses & Management Fees",

    tooltips: {
        proceeds: "Amount you'll be paid for this month",
        occupancy:
            "The percentage of available nights this month that you rented to guests (does not include your personal use dates and complimentary stays)",
        nightsOccupied:
            "How many nights this month your home hosted guests (only counts reservations with check-out dates within the month)",
    },
    paymentSettings: "Payment Settings",
    paymentSettingsSmall: "Settings",
    paymentSettingsNotification:
        "Manage your bank details and payment settings here.",
    paymentSettingsNotificationTitle: "Payment Settings",
    checkItOut: "Check it Out",
    settings: "Settings",
    backToTop: "Back to Top",
    needHelp: "Need Help?",
    faqAbbreviation: "FAQ",
    errorDownloading: "Error downloading",
    pdfDownloadError:
        "We were unable to create a PDF of your statement. Please try again later.",
    faq: {
        whereDoISendPayments: {
            answer: {
                canada: "If your home is in Canada",
                allOthers: "All others",
            },
        },
    },
    netRentNoteBegin: "NOTE: ",
    netRentNoteContent:
        "The entire stay spans across {reservationMonthSpan} months ({reservationPeriod}). Monthly revenue will appear on each month’s respective statement(s).",
}

export default statements
