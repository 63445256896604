import { RefObject, useEffect, useState } from "react"
import useElementSize, { Size } from "../../../hooks/common/useElementSize"
import { useMutationObserver } from "../../../hooks/common/useMutationObserver"
import useWindowWidth from "../../../hooks/common/useWindowSize"

/**
 * Calculate the size of each day in calendar.
 * @param containerWidth Width in px of the element containing the calendar
 * @param numMonths Number of months shown by the calendar
 * @returns Size of day in px
 */
function calculateDaySize(containerWidth: number, numMonths: number): number {
    const paddingPx = 13 // padding applied to left and right of calendar
    const leftPx = 9 // left applied to CalendarMonthGrid_horizontal
    const adjustForLeftPx = leftPx * 3
    const daySize = Math.max(
        0, // to prevent negative size when container width is too small
        Math.floor(
            (containerWidth - paddingPx * 2 * numMonths - adjustForLeftPx) /
                (7 * numMonths) +
                1
        )
    )
    // restrict daySize to 57px if showing more than one month,
    // restrict to 77px if showing one month.
    return numMonths > 1 ? Math.min(daySize, 57) : Math.min(daySize, 77)
}

/**
 * Custom hook that returns size of each day in calendar.
 * @param containerRef Ref to container of the calendar
 * @param maxNumMonths Maximum number of months to show
 * @returns Size of each day in calendar in px
 */
export function useDaySize(
    containerRef: RefObject<HTMLElement>,
    maxNumMonths = DEFAULT_MAX_MONTHS
): number {
    const { width } = useElementSize(containerRef)
    return calculateDaySize(width, useNumMonths(maxNumMonths))
}

/**
 * Custom hook that returns number of months to be shown by calendar based on breakpoint.
 * @param maxNumMonths Maximum number of months the calendar should show. Default 2
 * @returns Number of months to be shown by calendar
 */
export function useNumMonths(maxNumMonths = DEFAULT_MAX_MONTHS): number {
    const windowWidth = useWindowWidth()
    return Math.min(maxNumMonths, windowWidth && windowWidth < 1254 ? 1 : 2)
}

const DEFAULT_MAX_MONTHS = 2

/**
 * Returns width and height of the inner div that contains the calendar
 * @param containerRef
 * @returns {Size} Size of the calendar
 */
export function useCalendarSize(
    containerRef: RefObject<HTMLElement>
): Size | undefined {
    const [calendarSize, setCalendarSize] = useState<Size>()
    const mutations = useMutationObserver(
        containerRef,
        {
            attributeFilter: ["style"],
        },
        undefined,
        ".DayPicker[style^='width:']"
    )

    useEffect(() => {
        const mutation = mutations && mutations[0]
        const targetElement = mutation && (mutation.target as HTMLElement)
        if (targetElement) {
            setCalendarSize({
                width: targetElement.offsetWidth,
                height: targetElement.offsetHeight,
            })
        }
    }, [mutations])

    return calendarSize
}
