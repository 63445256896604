import { observer } from "mobx-react"
import { useCallback } from "react"
import { Link } from "react-router-dom"
import { FormattedMessage } from "react-intl"
import { useMediaQuery } from "@material-ui/core"

import { StatementsFAQ } from "../StatementsFAQ/StatementsFAQ"
import { EmptyState } from "../../../components/EmptyState"

import { ReactComponent as ArrowRight } from "../../../assets/icon-arrow-right.svg"
import { ReactComponent as EmptyStateImage } from "./StatementsEmptyState.svg"

import styles from "../../statements/statements.module.scss"
import { trackPaymentSettingClicked } from "services/segment/statement/statementTracking"

export const EmptyStatements = observer((): JSX.Element => {
    const smallScreen = useMediaQuery("(max-width: 520px)")

    const handleSettingsClick = useCallback(() => {
        trackPaymentSettingClicked()
    }, [])

    return (
        <div className="container-fluid print-container">
            <div className={styles["page-title"]}>
                <h1 className={styles["page-title"]}>
                    <FormattedMessage
                        id="Statements.title"
                        defaultMessage="Statements"
                    />
                </h1>
                <Link
                    className={styles["settings-button"]}
                    to="/statements/settings"
                    onClick={handleSettingsClick}
                >
                    {smallScreen ? (
                        <FormattedMessage
                            id="Statements.paymentSettingsSmall"
                            defaultMessage="Settings"
                        />
                    ) : (
                        <FormattedMessage
                            id="Statements.paymentSettings"
                            defaultMessage="Payment Settings"
                        />
                    )}
                    <ArrowRight className={styles["arrow-icon"]} />
                </Link>
            </div>
            <EmptyState
                image={<EmptyStateImage />}
                title={
                    <FormattedMessage
                        id="Statements.emptyState.title"
                        defaultMessage="Financial Statements"
                    />
                }
                description={
                    <FormattedMessage
                        id="Statements.emptyState.description"
                        defaultMessage="Your financial statements will show up here when they become available."
                    />
                }
            />
            <StatementsFAQ />
        </div>
    )
})
