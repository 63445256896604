import type { FC, ReactNode } from "react"
import { AlertTriangle } from "react-feather"
import { FormattedMessage } from "react-intl"

import styles from "./EmployeeAlert.module.scss"

/**
 * Explains the limitations of the profile page when logged in as an employee
 *
 */
export enum SupportedPages {
    Profile = "profile",
    Notifications = "notifications",
    TaxInformation = "tax-information",
}
interface EmployeeAlertProp {
    page: SupportedPages
}
const EmployeeAlert: FC<EmployeeAlertProp> = ({ page }): JSX.Element => (
    <div className={styles.alert}>
        <AlertTriangle className={styles.icon} aria-hidden />
        <p className={styles.text}>
            {page === SupportedPages.Profile && (
                <FormattedMessage
                    id="Profile.employeeAlert"
                    defaultMessage="<b>You are currently viewing this page as an employee.</b> We are unable to display the users first and last name from their IDP login account. Owners can view this information and edit it on this page."
                    values={{
                        b: (chunks: ReactNode) => <b>{chunks}</b>,
                    }}
                />
            )}
            {page === SupportedPages.Notifications && (
                <FormattedMessage
                    id="Notification.employeeAlert"
                    defaultMessage="<b>You are currently viewing this page as an employee.</b> We are unable to display the owner’s current notification preferences from their IDP account, or make changes to those preferences. Owners can view this information and edit it on this page."
                    values={{
                        b: (chunks: ReactNode) => <b>{chunks}</b>,
                    }}
                />
            )}
            {page === SupportedPages.TaxInformation && (
                <FormattedMessage
                    id="Notification.employeeAlertTaxInfo"
                    defaultMessage="<b>You are currently viewing this page as an employee.</b> Only owners can edit their tax information."
                    values={{
                        b: (chunks: ReactNode) => <b>{chunks}</b>,
                    }}
                />
            )}
        </p>
    </div>
)

export { EmployeeAlert }
