import classnames from "classnames"
import React, { PropsWithChildren } from "react"
import styles from "./InfoSubpanel.module.scss"

interface Props {
    className?: string
}

export const InfoSubpanel: React.FC<PropsWithChildren<Props>> = ({
    children,
    className,
}) => {
    return (
        <div className={classnames(styles["info-subpanel"], className)}>
            {children}
        </div>
    )
}
