import React from "react"
import DateRangeInputPicker from "core/components/dates/DateRangeInputPicker/DateRangeInputPicker"
import { ReservationDates } from "./components"
import { ActionBar } from "./components"
import { DEFAULT_DRP_PROPS } from "core/components/dates/DateRangeInputPicker/DateRangeInputPicker.props"
import { NewAlert } from "components/Alert/Alert"
import { CostOfHold } from "lib/components/FlyOut/Holds/OwnerHoldPartial/CostOfHold/CostOfHold"
import {
    DropdownValue,
    HoldTypeSelect,
} from "./components/HoldTypeSelect/HoldTypeSelect"
import { OwnerHoldType, UnitAvailabilityDays } from "@vacasa/owner-api-models"
import { HoldNotes } from "./components/HoldNotes/HoldNotes"
import { HousekeepingSelect } from "./components/HousekeepingSelect/HousekeepingSelect"
import { BookingProbabilityMessage } from "../BookingProbabilityMessage/BookingProbabilityMessage"
import { GuestSelect } from "./components/GuestSelect/GuestSelect"
import { DateRangeState } from "core/components/dates/state/DateRange.state"
import { useIntl } from "react-intl"
import { useUserCurrencyCode, useUser } from "hooks/user"
import { ActiveStatus } from "utils/unit"
import { OccupancyInfo } from "../ReservationGuests/ReservationGuestForm"
import { UnitInfo } from "../UnitInfo"
import { PhoneInput } from "../../../../lib/components/FlyOut/Holds/PhoneInput/PhoneInput"
import { useOwnerFeatureFlag } from "../../../../hooks/owner-feature-flag"

export interface ReservationFormContentProps {
    unitInfo: UnitInfo
    changeDates: boolean
    onChangeDatesClick: () => void
    isHoldInProgress: boolean
    startDate: Date | null
    endDate: Date | null
    dateRangeState: DateRangeState
    availability: UnitAvailabilityDays
    handleDatesChange: (startDate: Date | null, endDate: Date | null) => void
    datesValid: boolean
    costOfHold: number
    holdType: OwnerHoldType | null
    onHoldTypeChange: (stayType?: DropdownValue) => void
    holdNotes?: string
    onHoldNotesChange: (notes: string) => void
    cleanAfterStay?: boolean
    onHousekeepingSelectChange: (value: boolean) => void
    selectedStartDate: Date | null
    selectedEndDate: Date | null
    occupancyInfo: OccupancyInfo
    updateOccupancy: (id: string, count: number) => void
    handleHousekeepingAllowed: (value: boolean) => void
    handleHousekeepingRequired: (value: boolean) => void
    phone: string
    handlePhoneChange: (value: string) => void
    handlePhoneValid: (valid: boolean) => void
    process: "editing" | "creation"
}

const ReservationFormContent: React.FC<ReservationFormContentProps> = ({
    unitInfo,
    changeDates,
    onChangeDatesClick,
    isHoldInProgress,
    startDate,
    endDate,
    dateRangeState,
    availability,
    handleDatesChange,
    datesValid,
    costOfHold,
    holdType,
    onHoldTypeChange,
    holdNotes,
    onHoldNotesChange,
    cleanAfterStay,
    onHousekeepingSelectChange,
    selectedStartDate,
    selectedEndDate,
    occupancyInfo,
    updateOccupancy,
    handleHousekeepingAllowed,
    handleHousekeepingRequired,
    phone,
    handlePhoneChange,
    handlePhoneValid,
    process
}) => {
    const intl = useIntl()
    const currencyCode = useUserCurrencyCode()
    const unitStatusNumber: ActiveStatus = unitInfo.unit.attributes?.active
    const { user } = useUser()

    // Optimizely
    const ownerHoldsV3 = useOwnerFeatureFlag("owner-portal-add-phone-number-in-owner-hold-form")

    const showPhoneModal = ownerHoldsV3.isEnabled && holdType === "owner stay" && process === "editing"

    return (
        <>
            <ActionBar
                onChangeDatesClick={onChangeDatesClick}
                onEditBackClick={onChangeDatesClick}
                isChangingDates={changeDates}
            />

            <ReservationDates
                holdInProgress={isHoldInProgress}
                checkinDate={
                    isHoldInProgress ? startDate : dateRangeState.startDate
                }
                checkoutDate={dateRangeState.endDate}
            />

            {changeDates && (
                <div>
                    <DateRangeInputPicker
                        {...DEFAULT_DRP_PROPS}
                        disableStartDate={isHoldInProgress}
                        initialStartDate={startDate}
                        initialEndDate={endDate}
                        blockAllDays={
                            !unitInfo.allowHolds ||
                            !unitInfo.isCurrentUnitActive
                        }
                        ownerCurrency={currencyCode}
                        calendarAvailability={availability}
                        handleDatesChange={handleDatesChange}
                    />
                </div>
            )}

            {!datesValid && (
                <>
                    <NewAlert
                        style={{
                            margin: "5px 0 20px",
                        }}
                        variant="danger"
                        message={intl.formatMessage({
                            id: "CalendarPage.flyout.invalidDates",
                            defaultMessage:
                                "The selected dates are not available.",
                        })}
                    />
                </>
            )}

            <CostOfHold
                costOfHold={costOfHold}
                unitInfo={unitInfo}
                currency={currencyCode}
            />

            <HoldTypeSelect
                holdType={holdType as OwnerHoldType}
                onChange={onHoldTypeChange}
            />

            {holdType && changeDates && (
                <HoldNotes
                    notes={holdNotes}
                    required={holdType === "other"}
                    onChange={onHoldNotesChange}
                />
            )}

            { showPhoneModal && (
                <PhoneInput
                    phone={phone}
                    onChange={handlePhoneChange}
                    onValidate={handlePhoneValid}
                />
            )}

            <HousekeepingSelect
                unitStatusNumber={unitStatusNumber}
                housekeeping={cleanAfterStay}
                acquisitionID={unitInfo.unit.attributes.acquisitionId}
                isGuestworksUnit={
                    unitInfo.unit.attributes.status.guestworksApp
                        .isGuestworksUnit
                }
                holdType={holdType}
                unitAddress={unitInfo.unit.attributes.address}
                selectChangeHandler={onHousekeepingSelectChange}
                show={!!holdType && !changeDates}
                handleHousekeepingAllowed={handleHousekeepingAllowed}
                handleHousekeepingRequired={handleHousekeepingRequired}
            />

            {selectedStartDate && selectedEndDate && (
                <BookingProbabilityMessage
                    unitId={unitInfo.unitId}
                    contactId={user?.contactId}
                    startDate={selectedStartDate}
                    endDate={selectedEndDate}
                    variant={"dark"}
                />
            )}

            {(holdType === "owner stay" ||
                holdType === "complimentary stay") && (
                <GuestSelect
                    occupancyInfo={occupancyInfo}
                    onChange={updateOccupancy}
                    holdType={holdType}
                />
            )}

            {holdType && !changeDates && (
                <HoldNotes
                    notes={holdNotes}
                    required={holdType === "other"}
                    onChange={onHoldNotesChange}
                />
            )}
        </>
    )
}

export { ReservationFormContent }
