import {
    FC,
    PropsWithChildren,
    createContext,
    useContext,
    useMemo,
    useState,
} from "react"
import { v4 } from "uuid"
import { ToastNotification, ToastNotificationProps } from "../ToastNotification"

type ToastNotificationProviderContext = {
    /**
     * Adds a notification to the queue to be displayed
     * @param toast
     * @returns id of the notification
     */
    addToastNotification: (toast: Omit<ToastNotificationProps, "id">) => string
    /**
     * Marks a notification to be hidden - starts the transition to close
     * @param id
     * @returns void
     */
    hideToastNotification: (id: string) => void
    /**
     * Removes notification from the queue
     * @param id
     * @returns void
     */
    removeToastNotifcation: (id: string) => void
}

const ToastNotificationContext =
    createContext<ToastNotificationProviderContext>(
        {} as ToastNotificationProviderContext
    )

export const ToastNotificationProvider: FC<PropsWithChildren> = ({
    children,
}) => {
    const [notificationsState, setNotifications] = useState<{
        [key: string]: ToastNotificationProps
    }>({})

    const api = useMemo(() => {
        const addToastNotification = (
            toastNotification: Omit<ToastNotificationProps, "id">
        ) => {
            const id = v4()
            setNotifications(prevState => {
                return {
                    ...prevState,
                    [id]: { ...toastNotification, id },
                }
            })
            return id
        }

        const hideToastNotification = (id: string) => {
            setNotifications(prevState => {
                const notification = prevState[id]
                if (!notification) return prevState
                return {
                    ...prevState,
                    [notification.id]: {
                        ...notification,
                        hide: true,
                    },
                }
            })
        }

        const removeToastNotifcation = (id: string) => {
            // Add a small delay to prevent quick flashes
            setTimeout(() => {
                setNotifications(prevState => {
                    const clone = { ...prevState }
                    delete clone[id]
                    return clone
                })
            }, 150)
        }

        return {
            addToastNotification,
            hideToastNotification,
            removeToastNotifcation,
        }
    }, [])

    // May have to add a timestamp prop in the future to if these are not being returned
    // in the order they are queued in but currently we don't have any cases where we queue
    // multiple notification at a time.
    const notifications = useMemo(
        () =>
            Object.keys(notificationsState).map(key => {
                const notification = notificationsState[key]
                if (!notification) return undefined

                const onToastNotificationClose = () => {
                    if (notification.onClose) notification.onClose()
                    api.removeToastNotifcation(notification.id)
                }

                return (
                    <ToastNotification
                        key={key}
                        {...notification}
                        onClose={onToastNotificationClose}
                    />
                )
            }),
        [api, notificationsState]
    )

    return (
        <ToastNotificationContext.Provider value={api}>
            {children}

            {notifications.length > 0 && (
                <div id="notification-popup">{notifications[0]}</div>
            )}
        </ToastNotificationContext.Provider>
    )
}

export const useToastNotification = () =>
    useContext<ToastNotificationProviderContext>(ToastNotificationContext)
