import { UnitAvailabilityDays } from "@vacasa/owner-api-models"
import classNames from "classnames"
import { START_DATE } from "Constants"
import {
    addMonths,
    formatISO,
    getMonth,
    isValid,
    startOfMonth,
    subMonths,
} from "date-fns"
import { add } from "date-fns/fp"
import { omit } from "lodash"
import React, { PropsWithChildren, useEffect, useRef, useState } from "react"
import { DayPickerRangeController, FocusedInputShape } from "react-dates"
import { useIntl } from "react-intl"
import { UseQueryResult } from "react-query"
import { useAvailability } from "../../../../hooks/availibility"
import { usePrefetchAvailability } from "../../../../hooks/availibility/useAvailability"
import { RadioButton, RadioGroup } from "../../../../lib/components/RadioGroup"
import { useCalendarSize, useDaySize, useNumMonths } from "../Calendar.hooks"
import { useDateRangeContext } from "../state/DateRange.context"
import { DateRangeState } from "../state/DateRange.state"
import {
    isDateRangeAvailable,
    isDayBlocked,
} from "../validation/DateValidation"
import { BookingProbabilityLegend } from "./BookingProbabilityLegend/BookingProbabilityLegend"
import {
    CalendarControllerProps,
    OMITTED_PROPS,
} from "./CalendarController.props"
import "./CalendarController.scss"
import CheckInOutInputs from "./CheckInOutInputs/CheckInOutInputs"
import { GetDayContents } from "./DayContents/DayContents"
import PillsLegend from "./PillsLegend/PillsLegend"
import { useContactId, useUserCurrencyCode } from "hooks/user"
import { useCurrentUnit, useUnitStatuses } from "hooks/units"
import moment from "moment"

const getAvailabilityDays = (
    availabilityQuery: UseQueryResult<UnitAvailabilityDays>
): UnitAvailabilityDays => {
    return availabilityQuery.data ?? {}
}

export const CalendarController: React.FC<
    PropsWithChildren<CalendarControllerProps>
> = props => {
    const { blockAllDays, handleDatesChange, onCalendarResize, children } =
        props
    const contactId = useContactId() ?? undefined
    const { unitId } = useCurrentUnit()
    const { isFixedRent } = useUnitStatuses()

    const intl = useIntl()

    const { dateRangeState, dispatchDateRangeAction } = useDateRangeContext()

    useEffect(() => {
        const initialState: DateRangeState = {
            errorMessage: null,
            focusedInput: START_DATE,
            startDate: props.initialStartDate,
            showStartDateError: false,
            endDate: props.initialEndDate,
            showEndDateError: false,
            canEditStartDate: true,
            calendarAvailability: {}, // TODO remove this from state
            blockAllDays,
        }

        dispatchDateRangeAction({
            payload: initialState,
            type: "SET_DATE_RANGE_STATE",
        })
    }, [
        blockAllDays,
        dispatchDateRangeAction,
        props.initialEndDate,
        props.initialStartDate,
        unitId,
    ])

    const [reservationsRatesToggle, setReservationsRatesToggle] = useState<
        "reservations" | "rates"
    >("reservations")

    const [isNavigating, setIsNavigating] = useState<boolean>(false)

    function onDatesChange({
        startDate,
        endDate,
    }: {
        startDate: Date | null | string
        endDate: Date | null | string
    }) {
        if (handleDatesChange) {
            handleDatesChange(startDate, endDate)
        }
        /**
         * Reset start and end dates before updating,
         * meaning every new selection is a fresh new range
         * based on the startDate and endDate arguments in this callback and not previous state
         */
        dispatchDateRangeAction({
            type: "RESET_DATES",
        })
        // Update input focus
        if (!isValid(startDate)) {
            dispatchDateRangeAction({
                type: "UPDATE_FOCUSED_INPUT",
                payload: "startDate",
            })
        } else if (!isValid(endDate)) {
            dispatchDateRangeAction({
                type: "UPDATE_FOCUSED_INPUT",
                payload: "endDate",
            })
        }
        dispatchDateRangeAction({
            type: "UPDATE_ERROR_MESSAGE",
            payload:
                startDate &&
                endDate &&
                !isDateRangeAvailable(
                    typeof startDate === "string" ? null : startDate,
                    typeof endDate === "string" ? null : endDate,
                    blockAllDays,
                    getAvailabilityDays(availabilityQuery)
                )
                    ? intl.formatMessage({
                          id: "CalendarPage.OwnerHolds.warningBanner",
                          defaultMessage:
                              "One or more of the dates for your hold are unavailable. Please check your selection and try again.",
                      })
                    : null,
        })
        dispatchDateRangeAction({
            type: "UPDATE_START_DATE",
            payload: startDate,
        })
        // Return early if selecting new start date, don't update end date
        if (dateRangeState.startDate && dateRangeState.endDate) {
            return
        }
        dispatchDateRangeAction({
            type: "UPDATE_END_DATE",
            payload: endDate,
        })
    }

    function onFocusChange(focusedInput: FocusedInputShape | null) {
        // Force the focusedInput to always be truthy so that dates are always selectable
        dispatchDateRangeAction({
            type: "UPDATE_FOCUSED_INPUT",
            payload: !focusedInput ? START_DATE : focusedInput,
        })
    }

    const omitted = omit(props, OMITTED_PROPS)

    // Visible month
    const [currentVisibleMonth, setCurrentVisibleMonth] = useState<Date>(
        startOfMonth(new Date())
    )

    // Calendar sizing
    const drpContainerRef = useRef(null)
    const daySize = useDaySize(drpContainerRef, props.maxNumMonths)
    const numMonths = useNumMonths(props.maxNumMonths)

    // We only want to fetch the date range of months we are currrently displaying
    const addVisibleNumberOfMonths = React.useMemo(
        () => add({ months: numMonths + 1 }),
        [numMonths]
    )
    const calendarEndDate = addVisibleNumberOfMonths(currentVisibleMonth)

    const availabilityQuery = useAvailability(
        contactId,
        unitId ?? undefined,
        formatISO(subMonths(currentVisibleMonth, 1), {
            representation: "date",
        }),
        formatISO(calendarEndDate, { representation: "date" })
    )

    // Prefetch Left
    usePrefetchAvailability(
        contactId,
        unitId ?? undefined,
        formatISO(subMonths(currentVisibleMonth, 2), {
            representation: "date",
        }),
        formatISO(subMonths(calendarEndDate, 1), { representation: "date" })
    )

    // Prefetch Right
    usePrefetchAvailability(
        contactId,
        unitId ?? undefined,
        formatISO(currentVisibleMonth, {
            representation: "date",
        }),
        formatISO(addMonths(calendarEndDate, 1), { representation: "date" })
    )

    // Update current visible month on click, to trigger tasks needed for displaying next/prev months,
    // for smooth rerendering (prevent calendar from stuttering).
    // This function is called before the callback provided to onNextMonthClick prop of DayPickerRangeController.
    const onNavPrevButtonClick = () => {
        if (isNavigating) {
            return
        }
        setIsNavigating(true)
        setCurrentVisibleMonth(subMonths(currentVisibleMonth, 1))
    }
    const onNavNextButtonClick = () => {
        if (isNavigating) {
            return
        }
        setIsNavigating(true)
        setCurrentVisibleMonth(addMonths(currentVisibleMonth, 1))
    }

    // DayPickerRangeController resize
    const dayPickerRangeControllerRef = useRef(null)
    const calendarSize = useCalendarSize(dayPickerRangeControllerRef)
    useEffect(() => {
        if (onCalendarResize && calendarSize) {
            onCalendarResize(calendarSize)
        }
    }, [calendarSize, onCalendarResize])

    // owner language and currency
    const languageCode = intl.locale
    const currencyCode = useUserCurrencyCode()

    return (
        <div
            ref={drpContainerRef}
            className={classNames("drpContainer", "calendarController", {
                endDateSelected: dateRangeState.endDate,
            })}
        >
            {props.showInputs && !isFixedRent && (
                <CheckInOutInputs
                    className="checkInOutInputs"
                    startDate={dateRangeState.startDate}
                    endDate={dateRangeState.endDate}
                    onDatesChange={({ startDate, endDate }) =>
                        onDatesChange({
                            startDate: startDate,
                            endDate: endDate,
                        })
                    }
                    showStartDateError={dateRangeState.showStartDateError}
                    showEndDateError={dateRangeState.showEndDateError}
                />
            )}
            <RadioGroup className="reservationsToggle">
                <RadioButton
                    name="reservationsToggle"
                    value="Reservations"
                    onChange={_ => setReservationsRatesToggle("reservations")}
                    checked={reservationsRatesToggle === "reservations"}
                >
                    Reservations
                </RadioButton>
                <RadioButton
                    name="reservationsToggle"
                    value="Rates"
                    onChange={_ => setReservationsRatesToggle("rates")}
                    checked={reservationsRatesToggle === "rates"}
                >
                    Rates
                </RadioButton>
            </RadioGroup>

            <div ref={dayPickerRangeControllerRef}>
                <DayPickerRangeController
                    {...omitted}
                    daySize={daySize}
                    initialVisibleMonth={null}
                    numberOfMonths={numMonths}
                    onDatesChange={({ startDate, endDate }) =>
                        onDatesChange({
                            startDate: startDate?.toDate() ?? null,
                            endDate: endDate?.toDate() ?? null,
                        })
                    }
                    onFocusChange={onFocusChange}
                    focusedInput={dateRangeState.focusedInput}
                    startDate={
                        dateRangeState.startDate
                            ? moment(dateRangeState.startDate)
                            : null
                    }
                    endDate={
                        dateRangeState.endDate
                            ? moment(dateRangeState.endDate)
                            : null
                    }
                    renderDayContents={day =>
                        GetDayContents(
                            languageCode || "en",
                            currencyCode || "USD",
                            blockAllDays,
                            getAvailabilityDays(availabilityQuery),
                            reservationsRatesToggle,
                            daySize,
                            dateRangeState.focusedInput === "endDate"
                        )(day.toDate())
                    }
                    monthFormat={"MMM YYYY"}
                    isDayBlocked={date =>
                        isDayBlocked(
                            date.toDate(),
                            blockAllDays,
                            getAvailabilityDays(availabilityQuery),
                            dateRangeState.startDate,
                            dateRangeState.endDate,
                            getMonth(currentVisibleMonth),
                            numMonths
                        )
                    }
                    hideKeyboardShortcutsPanel={true}
                    navPrev={
                        <div
                            className="CustomNavButton CustomNavButton__Prev"
                            onClick={onNavPrevButtonClick}
                        />
                    }
                    navNext={
                        <div
                            className="CustomNavButton CustomNavButton__Next"
                            onClick={onNavNextButtonClick}
                        />
                    }
                    onNextMonthClick={
                        // Callback when navigating to next month has finished (including transition time).
                        () => setIsNavigating(false)
                    }
                    onPrevMonthClick={() => setIsNavigating(false)}
                />
            </div>
            <div
                className="legendAndChildrenContainer"
                style={{
                    width: `${calendarSize?.width}px`,
                }}
            >
                {props.showLegend &&
                    reservationsRatesToggle === "reservations" && (
                        <PillsLegend />
                    )}
                {props.showLegend && reservationsRatesToggle === "rates" && (
                    <BookingProbabilityLegend />
                )}
                {children}
            </div>
        </div>
    )
}

export default CalendarController
