import classnames from "classnames"
import { forwardRef, ReactNode, useState } from "react"
import type { FieldError } from "react-hook-form"
import { v4 as uuidV4 } from "uuid"
import styles from "./Input.module.scss"

const Input = forwardRef<
    HTMLInputElement,
    JSX.IntrinsicElements["input"] & {
        label: ReactNode
        error?: FieldError
        right?: ReactNode
    }
>(({ label, error, right, ...rest }, ref) => {
    const [id] = useState(rest.id ?? uuidV4())
    return (
        <div className={styles.container}>
            <label className={styles.label} htmlFor={id}>
                {label} {rest.required && "*"}
            </label>
            <input
                id={id}
                {...rest}
                className={classnames(styles.input, {
                    [String(styles.inputError)]: error !== undefined,
                })}
                ref={ref}
            />
            {right !== undefined ? (
                <div className={styles.right}>{right}</div>
            ) : null}
            {error !== undefined && (
                <span className={styles.errorMessage}>{error.message}</span>
            )}
        </div>
    )
})

export { Input }
