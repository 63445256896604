import { authAxios } from "../../api/authAxios"

export type PutPaymentAccountRequest = {
    contactId: string
    data: PaymentAccount
    signal?: AbortSignal
}

export type PutPaymentAccountResponse = {
    data: {
        message: string
    }
}

export type PaymentAccount = {
    accountType: string
    routingNumber: string
    accountNumber: string
    accountHolderName: string
}

export const putPaymentAccount = ({
    contactId,
    data,
    signal,
}: PutPaymentAccountRequest): Promise<PutPaymentAccountResponse> => {
    return authAxios
        .put<PutPaymentAccountResponse>(
            `/v1/legacy/owners/${contactId}/payment-account`,
            data,
            {
                signal,
            }
        )
        .then(response => response.data)
}
