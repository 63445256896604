const linenService = {
    title: "Program ložního prádla a ručníků",

    notificationComponent: {
        planWillBeginWithInitialCharge:
            "Váš plán začne dne <strong>{date, date, medium}</strong> - počáteční poplatek činí <strong>{cost}</strong>.",
        planWillBeginWithCharge:
            "Váš plán začne dne <strong>{date, date, medium}</strong> - poplatek činí <strong>{cost}</strong>.",
        planWillRenewFor:
            "Váš plán bude automaticky obnoven dne <strong>{date, date, medium}</strong> za poplatek <strong>{cost}</strong>",
        planWillEndOn:
            "Váš plán bude ukončen dne <strong>{date, date, medium}</strong> Poté již nebudete dostávat zlevněné náhradní povlečení a ručníky.",
    },

    infoPanel: {
        learnMoreTitle: "Jednoduché a úsporné řešení",
        bodyText:
            "Náš program ložního prádla a ručníků vám nabízí ložní prádlo a koupelnové textilie na hotelové úrovni s výraznou slevou. Čisté povlečení a nadýchané ručníky pro každého hosta znamenají lepší hodnocení, více rezervací a vyšší příjmy pro vás.",
        learnMore: "Více informací",
    },

    servicePlan: {
        prepend: "Váš plán",
        standard: "Standardní program ložního prádla a ručníků",
        premium: "Prémiový program ložního prádla a ručníků",
        youAreNotEnrolled: "Nejste zaregistrováni",
        year: "rok",
        nextBillingDate: "Datum příští fakturace",
        newPlanBegins: "Nový plán začíná dne",
        nextBilling: "Příští fakturace",
        discount: "sleva",
        setup: "Vytvořit",
        setupFee: "zřizovací poplatek",
        planHistory: "Historie plánů",
        ongoing: "probíhající",
        pending: "očekávaný",
        append: "Jakékoli poplatky za tuto službu budou účtovány jako výdaje ve vašem měsíčním výkazu.",
    },

    helpPanel: {
        needHelp: "Potřebujete pomoc?",
        contactSentenceIfQuestions:
            "Obraťte se prosím na svou kontaktní osobu, {contact} pokud máte jakékoli dotazy nebo si přejete aktualizovat svůj plán.",
    },

    enrolled: "Zaregistrován",
    notEnrolled: "Nezaregistrován",
    begins: "Začíná",
    renews: "Obnoví se",
    expires: "Vyprší",
}

export default linenService
