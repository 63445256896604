import { JSONAPIResource, Unit } from "@vacasa/owner-api-models"
import { REDIRECT_ONBOARDING } from "Constants"
import { SKIP_ONBOARDING_REDIRECT } from "Environment"
import { useOnboardingStatus } from "hooks/onboarding/use-onboarding-status"
import storageService from "services/storage.service"
import { isUnitLive } from "utils/unit"

interface OnboardRedirectConfig {
    redirectOnboarding: boolean
    redirectOnboardingUrl: string
    redirectToOwnerHolds: boolean
}

export const getOnboardingRedirectConfig = (): OnboardRedirectConfig => {
    const defaults: OnboardRedirectConfig = {
        redirectOnboarding: true,
        redirectOnboardingUrl: "/onboarding",
        redirectToOwnerHolds: false,
    }
    const value = storageService.sessionStorage.getItem(REDIRECT_ONBOARDING)
    if (!value) return defaults
    try {
        const parsedValue = JSON.parse(value)
        return {
            ...defaults,
            ...parsedValue,
        }
    } catch {
        return defaults
    }
}

export const clearOnboardingRedirectConfig = () =>
    storageService.sessionStorage.removeItem(REDIRECT_ONBOARDING)

/**
 * Checks if the owner should use the onboarding app. User preference value takes presidence over
 * any other logic regardless of the feature flag being enabled
 * @param onboardingStatus
 * @returns boolean
 */
export const checkIfOnboardingAppUser = (
    onboardingStatus: ReturnType<typeof useOnboardingStatus>
): boolean => {
    const { isLoading, isOnboardingAppUser } = onboardingStatus
    if (isLoading) return false
    return isOnboardingAppUser
}

export const hasLiveUnits = (units: JSONAPIResource<Unit>[]) => {
    return units.some(unit => isUnitLive(unit))
}

export const shouldRedirectToOnboarding = (
    hasLiveUnits: boolean,
    hasOnboardingAppUnits: boolean
) => {
    // should only be enabled when running locally
    if (SKIP_ONBOARDING_REDIRECT) return false

    if (hasLiveUnits) return false

    return (
        hasOnboardingAppUnits &&
        getOnboardingRedirectConfig().redirectOnboarding
    )
}
