/* eslint-disable no-template-curly-in-string */
const calendar = {
    vacasa: "Vacasa",
    title: "Calendar",
    interiorDesignBannerText: "Earn more with an interior design makeover",
    covidBannerText:
        "Coronavirus homeowner resource guide - what we're doing to navigate COVID-19 together.",
    covidCtaDestination:
        "https://www.vacasa.com/support/coronavirus-homeowner-resource-guide",
    learnMore: "Learn more",
    emptyState:
        "You’ll be able to reserve dates for your personal use and view upcoming reservations.",
    bookingProbabilityLegend: "Home has a high chance of being booked",

    OwnerHolds: {
        heading: "Owner holds",
        nonfixedHelperText:
            "Use this calendar to reserve dates for your own personal use.",
        fixedHelperText:
            "To reserve dates for your own personal use, please contact Homeowner Account Support at +1 (971) 254-3001 or",
        inactiveHelperText:
            "This rental is inactive. Owner holds can only be added to active rentals.",
        ifHoldQuestions:
            "If you have any questions about this hold, please contact",
        toChangeHold:
            "If you need to change your dates or cancel this hold, please email your request to",
        continue: "Continue",
        ariaLabel: "Click to place owner hold",
        checkIn: "Check In",
        checkOut: "Check Out",
        notSelected: "Not selected",
        clearDates: "Clear Dates",
        employeeOptions: "Additional Employee Options",
        holdType: "Hold Reason",
        ownerHold: "Owner Hold",
        guestStay: "Guest Stay",
        vacasaHold: "Vacasa Hold",
        selectOwner: "Select an Owner",
        scheduleClean: "Would you like us to schedule a clean after your stay?",
        yes: "Yes",
        no: "No",
        or: "or",
        warningBanner:
            "One or more of the dates for your hold are unavailable. Please check your selection and try again.",
        datesPlaceholder: "MM/DD/YYYY",
    },

    flyout: {
        vacasaContact: "Vacasa contact",
        contactUsFooter:
            "If you have any questions about this hold, please reach out.",
        back: "Back",
        clear: "Clear",
        holdPlaced: "Hold placed",
        holdUpdated: "Hold updated",
        tempAccessCode: "Temporary access code",
        smartLockAria:
            "Click this link to reveal the temporary smart lock code.",
        smartLockCode: "Smart Lock Code",
        notAvailable:
            "Your home access code will appear here 3 days prior to your check-in date. Your guests will also be notified.",
        selectOption: "Select an option",
        reservation: "Reservation",
        ownerHold: "Owner hold",
        ownerStay: "Owner stay",
        ownerStaySubtitle: "A reservation for your own personal use.",
        complimentaryStay: "Complimentary stay",
        complimentaryStaySubtitle: "A reservation for friends or family.",
        vacasaHold: "Vacasa hold",
        vacasaHoldSubtitle: "Option only available for employees.",
        block: "Block",
        blockSubtitle: "Nobody will be using the home.",
        propertyCare: "Property care",
        propertyCareSubtitle: "Maintenance, upgrades, deliveries, etc.",
        seasonalHold: "Seasonal hold",
        seasonalHoldSubtitle: "Close your home to guests for a season",
        homeForSale: "Home for sale",
        homeForSaleSubtitle: "Activities related to a home sale.",
        other: "Other",
        otherSubtitle: "Does not fit the other categories.",
        terminationHold: "Termination hold",
        terminationHoldSubtitle: "Option only available for employees",
        notes: {
            title: "Notes ",
            titleRequired: "Notes* ",
            optional: "(optional)",
            required: "(required)",
            placeholder:
                "Help us improve our category options by providing a note about this hold",
            none: "N/A",
        },
        housekeeping: "Housekeeping",
        yesHousekeepingSubtitle:
            "A Vacasa housekeeping visit will be scheduled after this hold.",
        yesHousekeepingSubtitleWithCleaningFee:
            "A Vacasa housekeeping visit will be scheduled after this hold and billed to your account in the amount of <strong>${amount}.</strong>",
        noHousekeepingSubtitle:
            "You will be responsible for cleaning and preparing the home for the next guests.",
        ownerResponsibleForCleaning: "Owner responsible for cleaning",
        housekeepingScheduled: "Housekeeping scheduled",
        noHousekeeping: "No housekeeping",
        numberGuestsMax: `{{number}} guests maximum`,
        noPets: "No pets allowed",
        restrictionsDontApply: "Guest restrictions do not apply for owners.",
        night: "night",
        nights: "nights",
        monthCount:
            "Spans {monthCount, plural,=0 {}one {# month}other {# months}}",
        date: "Date",
        dates: "Dates",
        guests: "Guests",
        costOfHold:
            "You miss out on <b>{cost}</b> in gross rent by booking these dates.",
        createHold: "Create Hold",
        next: "Next",
        summary: "Summary",
        cancelOwnerHold: "Cancel owner hold",
        cancelAreYouSure:
            "Are you sure you want to cancel? Your hold has not been scheduled.",
        deleteOwnerHold: "Delete owner hold",
        editHold: "Edit hold",
        edit: "Edit",
        editAria: "Click here to edit.",
        pleaseContactToRequestChange:
            "Please contact {{contact}} to request a date change.",
        saveChanges: "Save changes",
        save: "Save",
        saveAria: "Click here to save.",

        delete: "Delete",
        removeHold: "Remove hold",
        deleteHold: "Delete hold",
        deleteAria: "Click here to delete.",
        holdRemoved: "Hold removed",
        deleteAreYouSure: "Are you sure you want to delete this?",
        deleteHelperText: "This cannot be undone.",
        deleteConfirm: "Yes, Delete Hold",
        cancelDelete: "No, Don't Delete",
        approvalNeeded: "Approval needed.",
        fixedRentDeletionApproval:
            "During a fixed-rent contract, hold deletion must be approved by our support team.",
        request: "Request",
        requestDelete: "Request to Delete",
        deleteRequestConfirmation:
            "This is a request to delete an owner hold that occurs during a fixed-rent contract.",
        holdNumberForRequest: "Request to delete hold #",
        holdNumber: "Hold #: ",
        startDate: "Start date : ",
        endDate: "End date : ",

        cancel: "Cancel",
        done: "Done",
        totalNetRent: "Total Net rent",
        revenueBreakdown: "Revenue breakdown",
        fixedRent: "Fixed rent",
        grossRent: "Gross rent",
        guestReview: "Guest review",
        guestReviewCollapseLabel: "See less",
        guestReviewExpandLabel: "See more",

        inviteGuest: "Invite Guest",
        inviteGuests: "Invite Guests",
        inviteGuestsOptional: "Invite Guests (Optional)",
        inviteAnotherGuest: "Invite Another Guest",
        inviteGuestsSubtitle:
            "Add guests to share your trip details and home access information.",
        firstName: "First name",
        lastName: "Last name",
        emailAddress: "Email address",
        invitationSent: "Invitation sent",
        invitationsSent: "invitations sent",
        fiveOrMoreInvitationsSent: "invitations sent",
        guestRemoved: "Guest removed",
        skip: "Skip",
        skipAria: "Click this button to skip adding guests",
        inviteAnotherGuestAria: "Click this button to invite another guest",
        noGuestInfo: "No guest information",
        guestInfo: "Guest info",
        adults: "adults",
        fiveOrMoreAdults: "adults",
        children: "children",
        fiveOrMoreChildren: "children",
        dogs: "dogs",
        fiveOrMoreDogs: "dogs",
        housekeepingNotAllowed:
            "Housekeeping not available. You will be responsible for cleaning and preparing the home for the next guests.",
        newBooking: "New booking",
        pastBooking: "Past booking",
        invalidDates: "The selected dates are not available.",
        learnAboutRates: "Learn about rates",
        guestBookingLikely:
            "These nights are highly likely to be booked by guests.",
        response: "Manager response",
        onboardingFooter: {
            title: "What do you want to do next?",
            backToOnboarding: "Go Back to Onboarding",
            addAdditionalOwnerHolds: "Add Another Owner Hold",
        },
        housekeepingRequired:
            "In order to maintain the highest quality guest experience, housekeeping is required for stays with overnight guests.",
        housekeepingRequiedWithCleaningFee:
            "This owner hold requires a post-stay clean, which will be billed to your account after the stay in the amount of <strong>${amount}.</strong>",
        PhoneSections: {
            smsTitle: "Owner contact info",
            smsDescription: 'Details will be sent to this number.',
            smsEditDescription: "Details will be sent to <strong>{number}</strong>.",
            invalidPhoneMessage: 'The phone number is not valid',
            noPhoneNumber: 'No phone number provided'
        }

    },

    UpcomingStays: {
        title: "Reservations",
        emptyStateUpcoming:
            "New reservations and owner holds will show up here.",
        emptyStatePast:
            "You have no previous reservations. When upcoming stays have concluded, they will appear here.",
        upcomingDescription:
            "Upcoming guest reservations and owner holds will be listed below. Click a row to view more details. If you wish to modify an owner hold you can do so by clicking on the corresponding row.",
        pastDescription:
            "Your past reservations and holds in the last 45 days are listed out below.  If you would like to view more details around each stay, click on the detail row. ",
        toModify: "If you wish to modify an owner hold you may do so below.",
        fixedRentModify:
            "If you need to edit a hold on your rental during a fixed rent period, please email",
        details: "Details",
        Night: "Night",
        adult: "adult",
        child: "child",
        dog: "dog",
        at: "at",
        upcomingTabTitle: "Upcoming ({total})",
        previousTabTitle: "Previous",
        occupancyCount:
            "{adultCount, plural,=0 {}one {# adult,}other {# adults,}} {childCount, plural,=0 {}one {# child,}other {# children,}} {dogCount, plural,=0 {} one {# dog}other {# dogs}}",
    },

    errorMessages: {
        generalError:
            "A problem prevented us from processing your request. Please try again later. If the problem persists, please call {name} on {phone}",
        datesNotAvailableError:
            "One or more of the dates for your hold are unavailable. Please check your selection and try again.",
        createHoldError: "Failed to create your owner hold.",
        deleteHoldError: "Failed to remove your owner hold.",
        updateHoldError: "Failed to update your owner hold.",
        updateHoldCleanError: "Failed to update your clean status.",
        addGuestToHoldError: "Failed to add your guest.",
        removeGuestFromHoldError: "Failed to remove your guest.",
        modalTitle: "Oops!",
        modalMessage:
            "It looks like this reservation is no longer in our system.",
        invalidDateError: "This date is unavailable",
        unableToLoadReservations: "We were unable to load your reservations.",
    },

    reservations: {
        reservation: "Reservation",
        vacasaHold: "Vacasa hold",
    },

    switchAccounts: {
        notification: "This reservation is associated with another account",
        description:
            "Please login to the appropriate homeowner account and click the new booking email notification to view the new reservation details.",
        button: "Switch Accounts",
        maybeLater: "Maybe Later",
        descriptionButtom:
            "Interested in linking your accounts into one login? Call <strong>Owner Support</strong> at {phone} to get it set-up.",
    },

    reservationType: {
        ownerHold: "Owner Hold",
        vacasaHold: "Vacasa Hold",
        reservation: "Guest Stay",
    },
}

export default calendar
