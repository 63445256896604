const notification = {
    title: "Notification Settings",
    subtitle:
        "Customize your notification settings by letting us know what you would like to hear about.",
    requiredAlert:
        "Opting out of these transactional notices is not available.",
    changesSaved: "Changes saved",
    employeeAlert:
        "<b>You are currently viewing this page as an employee.</b> We are unable to display the owner’s current notification preferences from their IDP account, or make changes to those preferences. Owners can view this information and edit it on this page.",
}

export default notification
