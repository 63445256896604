import { Owner } from "@vacasa/owner-api-models"
import {
    LAST_GOOD_SESSION,
    JWT,
    USER_ID,
    USER,
    EMPLOYEE,
    LOGIN_TYPE,
    EMPLOYEE_LAST_VIEWED_OWNER_URL,
    REDIRECT_ONBOARDING,
    FEAUTRE_TRACKING_INTERACTIONS,
    TWILIO_WEB_CHAT,
} from "Constants"
import rootService from "services"
import realUserMonitoringService from "services/real-user-monitoring/realUserMonitoring.service"
import storageService from "services/storage.service"
import { clearSessionConfig, resetSessionConfig } from "utils/session"

/**
 * Clears all user specific settings stored in local and session storage
 * @returns
 */
export const clearUserCache = (): void => {
    clearOnboardingCache()
    clearSessionConfig()
    realUserMonitoringService.clearUser()
    storageService.localStorage.removeItem(USER_ID)
    storageService.localStorage.removeItem(USER)
    storageService.localStorage.removeItem(EMPLOYEE)
    storageService.localStorage.removeItem(LOGIN_TYPE)
    storageService.localStorage.removeItem(JWT)
    storageService.localStorage.removeItem(EMPLOYEE_LAST_VIEWED_OWNER_URL)
    storageService.sessionStorage.removeItem(REDIRECT_ONBOARDING)
    storageService.localStorage.removeItem(FEAUTRE_TRACKING_INTERACTIONS)
    storageService.localStorage.removeItem(TWILIO_WEB_CHAT)
}

/**
 *  Clears owner specific data - used for employee owner switching
 */
export const clearOwnerCache = () => {
    clearOnboardingCache()
    storageService.localStorage.removeItem(USER)
    resetSessionConfig()
    rootService.resetStores()
}

const clearOnboardingCache = () => {
    const id = storageService.localStorage.getItem(LAST_GOOD_SESSION)
    if (id) {
        storageService.localStorage.removeItem(`${id}_unit_id`)
        storageService.localStorage.removeItem(`${id}_contact_id`)
    }

    const unitIdRegex =
        /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}_UNIT_ID$/
    const contactRegex =
        /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}_CONTACT_ID$/
    const keys = storageService.localStorage.keys()
    for (const key of keys) {
        const upperCaseKey = key.toUpperCase()
        if (unitIdRegex.test(upperCaseKey) || contactRegex.test(upperCaseKey)) {
            storageService.localStorage.removeItem(key)
        }
    }
}

export const isOwnerInEurope = (user: Owner | null): boolean => {
    const euCountries = [
        "Switzerland",
        "Italy",
        "Spain",
        "France",
        "Czechia",
        "Hungary",
        "Croatia",
        "Portugal",
    ]
    if (user && user.accountInfo.accountingEntityCountry) {
        const userCountry = user.accountInfo.accountingEntityCountry
        return euCountries.indexOf(userCountry) > -1
    } else {
        return false
    }
}
