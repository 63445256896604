import { ReservationType } from "@vacasa/owner-api-models"
import classnames from "classnames"
import styles from "./PillsLegend.module.scss"
import { useIntl } from "react-intl"
import classNames from "classnames"
import { FC } from "react"

const PillsLegend: FC = () => {
    const intl = useIntl()

    const legends = [
        {
            label: intl.formatMessage({
                id: "CalendarPage.OwnerHolds.ownerHold",
                defaultMessage: "Owner Hold",
            }),
            type: ReservationType.OWNERHOLD,
        },
        {
            label: intl.formatMessage({
                id: "CalendarPage.OwnerHolds.guestStay",
                defaultMessage: "Guest Stay",
            }),
            type: ReservationType.RESERVATION,
        },
        {
            label: intl.formatMessage({
                id: "CalendarPage.OwnerHolds.vacasaHold",
                defaultMessage: "Vacasa Hold",
            }),
            type: ReservationType.VACASAHOLD,
        },
    ].map(legend => (
        <div
            key={legend.type}
            className={classnames(
                styles.pillLegend,
                "type-heading-small",
                "caps"
            )}
        >
            <Pill type={legend.type} />
            <span data-testid={"pill-reservation-type"}>
                {legend.label.replace(/\s.*/, "")}
            </span>
        </div>
    ))

    return <div className={styles.pillsLegendContainer}>{legends}</div>
}

export default PillsLegend

type PillProps = {
    type?: ReservationType
}

const Pill: FC<PillProps> = ({ type }) => {
    return (
        <span
            data-testid={"pill-color"}
            className={classNames(styles.pill, {
                [styles.pillOwnerHold as string]:
                    type === ReservationType.OWNERHOLD,
                [styles.pillGuestStay as string]:
                    type === ReservationType.RESERVATION,
                [styles.pillVacasaHold as string]:
                    type === ReservationType.VACASAHOLD,
            })}
        />
    )
}
