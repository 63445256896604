import classNames from "classnames"
import { FC, LabelHTMLAttributes, PropsWithChildren } from "react"
import styles from "./Label.module.scss"

type LabelProps = LabelHTMLAttributes<HTMLLabelElement> & { error?: boolean }

export const Label: FC<PropsWithChildren<LabelProps>> = ({
    children,
    className,
    error,
    ...rest
}) => (
    <label
        className={classNames(
            "type-heading-extra-small-caps",
            className,
            styles.label,
            {
                [styles.error as string]: error,
            }
        )}
        {...rest}
    >
        {children}
    </label>
)
