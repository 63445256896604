import { FC } from "react"
import { ReactComponent as StarFilled } from "assets/star-filled.svg"
import { ReactComponent as StarEmpty } from "assets/star-empty.svg"
import { ReactComponent as StarHalf } from "assets/star-half.svg"
import styles from "./StarRating.module.scss"
import classNames from "classnames"

const roundToNearestHalf = (num: number) => {
    return Math.round(num * 2) / 2.0
}

interface Props {
    value: number
}

export const StarRating: FC<Props> = ({ value }) => {
    const roundedValue = roundToNearestHalf(value)

    return (
        <div className={classNames("starRating", styles.starRating)}>
            {[...Array(5)].map((_, index) => {
                const starRating = index + 1

                if (starRating <= roundedValue) {
                    return <StarFilled key={index} />
                }

                if (starRating - 0.5 === roundedValue) {
                    return <StarHalf key={index} />
                }

                return <StarEmpty key={index} />
            })}
        </div>
    )
}
