import classnames from "classnames"
import { observer } from "mobx-react"
import { ChangeEvent, FocusEvent, FormEvent, useEffect, useState } from "react"
import { Input } from "lib/components/Input"
import * as Button from "../../lib/components/Buttons/BaseButton"
import Loader from "../../lib/components/Loader"

import { CardContent } from "@material-ui/core"
import { PaymentAccount, usePaymentAccountMutation } from "hooks/finance"
import { FormattedMessage, useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import { ReactComponent as CheckDefault } from "../../assets/check_sample_default.svg"
import { ReactComponent as CheckAccountHighlighted } from "../../assets/check_sample_highlight_account.svg"
import { ReactComponent as CheckRoutingHighlighted } from "../../assets/check_sample_highlight_routing.svg"
import { Card } from "../../components/Card"

import { JSONAPIResource, Unit } from "@vacasa/owner-api-models"
import { useContactId } from "hooks/user"
import { useUnits } from "hooks/units"
import { useToastNotification } from "lib/components/ToastNotification"
import LoggingService from "services/logging/logging.service"
import { trackSavePaymentMethodButtonClicked } from "services/segment/statement/statementTracking"
import styles from "./settings.module.scss"
import { useInvalidateContact } from "hooks/contact/useContact"

/**
 * Returns the accounting entity name for the owners units
 * @param units
 * @returns
 */
const getAccountEntityName = (units?: JSONAPIResource<Unit>[]) => {
    if (!units) return
    return units.filter(unit => unit.attributes.accountingEntity)[0]?.attributes
        .accountingEntity?.name
}

const UpdatePayment = observer(() => {
    const invalidateContact = useInvalidateContact()
    const updatePaymentMethod = usePaymentAccountMutation({
        onError: error => {
            setPaymentMethod(prevState => ({
                ...prevState,
                routingNumber: "",
            }))
            setFormValid(false)
            setRoutingErrorMessage(
                intl.formatMessage({
                    id: "Settings.mustBeValid9Digits",
                    defaultMessage:
                        "Please enter a valid 9-digit routing number",
                })
            )

            LoggingService.error({
                message: "Failed to setPaymentAccount",
                error,
            })
            setLoading(false)
        },
        onSuccess: async (_, variables) => {
            addToastNotification({
                content: (
                    <FormattedMessage
                        id="Settings.updatedNotification"
                        defaultMessage="Payment information updated"
                    />
                ),
            })
            navigate("/statements/settings")
            invalidateContact(variables.contactId)
        },
    })
    const contactId = useContactId() ?? ""
    const intl = useIntl()
    const unitsQuery = useUnits()
    const accountingEntity = getAccountEntityName(unitsQuery.data)

    const [paymentMethod, setPaymentMethod] = useState<PaymentAccount>({
        accountType: "",
        routingNumber: "",
        accountNumber: "",
        accountHolderName: "",
    })
    const [formValid, setFormValid] = useState(false)
    const [currentFocus, setCurrentFocus] = useState("")
    const [routingErrorMessage, setRoutingErrorMessage] = useState("")
    const [accountErrorMessage, setAccountErrorMessage] = useState("")
    const { addToastNotification } = useToastNotification()
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        const handleRoutingValidation = (): boolean => {
            if (!paymentMethod.routingNumber) {
                return false
            }

            // routing numbers must be 9 digits long
            const isLengthValid = paymentMethod.routingNumber.length === 9

            // routing numbers must start with a 0, 1, 2, 3
            const beginningNumber = Number(paymentMethod.routingNumber[0])
            const isBeginningValid = beginningNumber >= 0 && beginningNumber < 4

            if (!isBeginningValid) {
                setFormValid(false)
                setRoutingErrorMessage(
                    intl.formatMessage({
                        id: "Settings.mustBeginWith",
                        defaultMessage:
                            "Routing number must begin with a 0, 1, 2, or 3",
                    })
                )
                return false
            }
            if (!isLengthValid) {
                setFormValid(false)
                setRoutingErrorMessage(
                    intl.formatMessage({
                        id: "Settings.mustBe9Digits",
                        defaultMessage: "Please enter a 9-digit routing number",
                    })
                )
                return false
            }

            // if this far into validation, no routing error message should appear
            setRoutingErrorMessage("")
            return true
        }

        const handleAccountValidation = (): boolean => {
            if (!paymentMethod.accountNumber) {
                return false
            }
            // account length must be 5 to 17 digits long
            const isAccountLengthValid =
                paymentMethod.accountNumber.length >= 5 &&
                paymentMethod.accountNumber.length <= 17

            if (!isAccountLengthValid) {
                setFormValid(false)
                setAccountErrorMessage(
                    intl.formatMessage({
                        id: "Settings.mustBe5to17Digits",
                        defaultMessage:
                            "Please enter a 5 to 17-digit account number",
                    })
                )
                return false
            }
            setAccountErrorMessage("")
            return true
        }

        const routingIsValid = handleRoutingValidation()
        const accountIsValid = handleAccountValidation()
        if (!paymentMethod.accountNumber || !paymentMethod.accountHolderName) {
            setFormValid(false)
            return
        }

        const accountNameValid = paymentMethod.accountHolderName.length > 0
        const accountTypeValid =
            paymentMethod.accountType === "0" ||
            paymentMethod.accountType === "1"
        // form is valid if all the fields have values

        const isFormComplete =
            accountTypeValid &&
            routingIsValid &&
            accountIsValid &&
            accountNameValid

        setFormValid(isFormComplete)
    }, [intl, paymentMethod])

    const renderSampleCheck = () => {
        if (currentFocus === "routingNumber") {
            return <CheckRoutingHighlighted className="check-responsive" />
        }
        if (currentFocus === "accountNumber") {
            return <CheckAccountHighlighted className="check-responsive" />
        }
        return <CheckDefault className="check-responsive" />
    }

    const onFocus = (e: FocusEvent<HTMLInputElement>) => {
        setCurrentFocus(e.target.name)
    }

    const onBlur = () => {
        setCurrentFocus("")
    }

    const generateRoutingNote = () => {
        // default when no error
        let noteToDisplay = intl.formatMessage({
            id: "Settings.nineDigitNumber",
            defaultMessage:
                "The 9-digit number at the lower left corner of a check",
        })
        const inlineStyle = {
            color: "#4D7080",
            marginTop: "4px",
        }
        // change on error
        if (routingErrorMessage && currentFocus !== "routingNumber") {
            noteToDisplay = routingErrorMessage
            inlineStyle.color = "#D62E4F"
        }

        return (
            <span className="tiny" style={inlineStyle}>
                {noteToDisplay}
            </span>
        )
    }

    const generateAccountNote = () => {
        let accountNoteToDisplay = intl.formatMessage({
            id: "Settings.enterBankAccount",
            defaultMessage: "Please enter your bank account number",
        })
        const inlineStyle = {
            color: "#4D7080",
            marginTop: "4px",
        }
        if (accountErrorMessage && currentFocus !== "accountNumber") {
            accountNoteToDisplay = accountErrorMessage
            inlineStyle.color = "#D62E4F"
        }
        return (
            <span className="tiny" style={inlineStyle}>
                {accountNoteToDisplay}
            </span>
        )
    }

    const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        const fieldsToValidate = ["routingNumber", "accountNumber"]
        setPaymentMethod(prevState => ({
            ...prevState,
            [name]: fieldsToValidate.includes(name)
                ? value.replace(/\D/g, "")
                : value,
        }))
    }

    const onSubmit = (e: FormEvent) => {
        e.preventDefault()
        trackSavePaymentMethodButtonClicked(paymentMethod.accountType ?? "")
        setLoading(true)
        updatePaymentMethod.mutate({
            contactId,
            data: paymentMethod,
        })
    }

    if (unitsQuery.isLoading || updatePaymentMethod.isLoading || loading) {
        return (
            <Card>
                <CardContent className="relative">
                    <Loader className="loader" />
                </CardContent>
            </Card>
        )
    }

    return (
        <div>
            <Card>
                <CardContent>
                    <h3 className="type-heading-medium remove-margin-mobile">
                        <FormattedMessage
                            id="Settings.addBankAccount"
                            defaultMessage="Add bank account"
                        />
                    </h3>
                    <form
                        onSubmit={onSubmit}
                        className="display-flex"
                        style={{
                            flexWrap: "wrap",
                            alignItems: "stretch",
                            flexDirection: "column",
                            flexFlow: "column-wrap",
                        }}
                    >
                        <fieldset
                            className="radio-responsive"
                            style={{ flexBasis: "40%", WebkitFlexGrow: 1 }}
                        >
                            <div className="form-check">
                                <label
                                    className="form-check-label"
                                    style={{ marginLeft: "10px" }}
                                >
                                    <input
                                        data-testid="savingsAccountRadio"
                                        type="radio"
                                        name="accountType"
                                        checked={
                                            paymentMethod.accountType === "0"
                                        }
                                        onChange={onInputChange}
                                        value="0"
                                        className="form-check-input"
                                    />
                                    <FormattedMessage
                                        id="Settings.checking"
                                        defaultMessage="Checking"
                                    />
                                </label>
                                <label className="form-check-label">
                                    <input
                                        type="radio"
                                        name="accountType"
                                        value="1"
                                        checked={
                                            paymentMethod.accountType === "1"
                                        }
                                        onChange={onInputChange}
                                        className="form-check-input"
                                    />
                                    <FormattedMessage
                                        id="Settings.savings"
                                        defaultMessage="Savings"
                                    />
                                </label>
                            </div>
                        </fieldset>

                        <div
                            className="display-flex"
                            style={{
                                flexBasis: "60%",
                                WebkitFlexGrow: 1,
                                flexDirection: "row",
                                alignItems: "stretch",
                                flexWrap: "wrap-reverse",
                            }}
                        >
                            <div
                                style={{
                                    flexBasis: "30%",
                                    WebkitFlexGrow: 1,
                                    minWidth: "60%",
                                }}
                            >
                                <div className="form-group">
                                    <Input
                                        labelText={intl.formatMessage({
                                            id: "Settings.routingNumber",
                                            defaultMessage: "Routing Number",
                                        })}
                                        containerClassName={
                                            styles.inputContainer
                                        }
                                        type="text"
                                        data-testid="routingNumberInput"
                                        required
                                        name="routingNumber"
                                        maxLength={9}
                                        value={paymentMethod.routingNumber}
                                        onChange={onInputChange}
                                        onFocus={onFocus}
                                        onBlur={onBlur}
                                        error={
                                            !!routingErrorMessage &&
                                            currentFocus !== "routingNumber"
                                        }
                                        hint={generateRoutingNote()}
                                    />

                                    <br />

                                    <Input
                                        labelText={intl.formatMessage({
                                            id: "Settings.accountNumber",
                                            defaultMessage: "Account Number",
                                        })}
                                        containerClassName={
                                            styles.inputContainer
                                        }
                                        data-testid="accountNumberInput"
                                        type="text"
                                        required
                                        value={paymentMethod.accountNumber}
                                        name="accountNumber"
                                        minLength={5}
                                        maxLength={17}
                                        onChange={onInputChange}
                                        onFocus={onFocus}
                                        onBlur={onBlur}
                                        error={
                                            !!accountErrorMessage &&
                                            currentFocus !== "accountNumber"
                                        }
                                        hint={generateAccountNote()}
                                    />

                                    <br />

                                    <Input
                                        labelText={intl.formatMessage({
                                            id: "Settings.accountHolderName",
                                            defaultMessage:
                                                "Account Holder Name",
                                        })}
                                        containerClassName={
                                            styles.inputContainer
                                        }
                                        data-testid="accountNameInput"
                                        required
                                        type="text"
                                        name="accountHolderName"
                                        value={paymentMethod.accountHolderName}
                                        onChange={onInputChange}
                                        onBlur={onBlur}
                                    />
                                    <br />
                                </div>
                                <div className="flex-horizontal-right full-width">
                                    <p
                                        className="tiny"
                                        style={{ minWidth: "100%" }}
                                    >
                                        <FormattedMessage
                                            id="Settings.byClickingYouAuthorize"
                                            defaultMessage="Because you chose to pay for expenses via ACH Debit, this account will also be charged if your expenses exceed your rental revenue for the month. By clicking 'Agree and Save', you authorize {accountingEntity} to make payments electronically via direct deposit and to debit the identified account for up to $10,000 per transaction per day. This authority will remain in effect until you change or update your payment method."
                                            values={{
                                                accountingEntity,
                                            }}
                                        />
                                    </p>
                                </div>
                                <div
                                    className="display-flex full-width"
                                    style={{ flexDirection: "row-reverse" }}
                                >
                                    <button
                                        className={classnames(
                                            styles["update-payment-button"],
                                            formValid ? "" : styles["disabled"]
                                        )}
                                        type={"submit"}
                                        aria-label={intl.formatMessage({
                                            id: "Settings.clickToSaveAria",
                                            defaultMessage:
                                                "Click this button to save your payment method.",
                                        })}
                                        disabled={!formValid}
                                        data-testid="updatePaymentSubmit"
                                    >
                                        <FormattedMessage
                                            id="Settings.agreeAndSave"
                                            defaultMessage="Agree and Save"
                                        />
                                    </button>
                                    <Button.LinkSecondary
                                        typeOf={"link"}
                                        onClick={() => {
                                            navigate("/statements/settings")
                                        }}
                                        ariaLabel={intl.formatMessage({
                                            id: "Settings.clickToCancelAria",
                                            defaultMessage:
                                                "Click this button to cancel.",
                                        })}
                                    >
                                        <FormattedMessage
                                            id="Settings.cancel"
                                            defaultMessage="cancel"
                                        />
                                    </Button.LinkSecondary>
                                </div>
                            </div>

                            <div
                                className="align-flex-middle"
                                style={{ WebkitFlexGrow: 1, minWidth: "240px" }}
                            >
                                {renderSampleCheck()}
                            </div>
                        </div>
                    </form>
                </CardContent>
            </Card>
        </div>
    )
})
export default UpdatePayment
