import React, { useState, useEffect, ReactNode } from "react"
import {
    Link,
    // eslint-disable-next-line camelcase
    unstable_useBlocker,
    useLocation,
    useNavigate,
} from "react-router-dom"
import { MobileStepper, useMediaQuery } from "@material-ui/core"
import classnames from "classnames"

import styles from "../../sass/onboarding.module.scss"
// Assets
import { ReactComponent as PlannerSVG } from "../../assets/icon-planner.svg"
import { ReactComponent as NavigateBackSVG } from "../../assets/icon-navigation-back.svg"
import { ReactComponent as NavigateNextSVG } from "../../assets/icon-navigation-next.svg"
import { ReactComponent as SpaceRocketSVG } from "../../assets/space-rocket.svg"
import SVGIcon from "../../lib/components/SVGIcon"

import Panel from "./OnboardingPanel"
import SleepingArrangements from "./ListingContentSections/SleepingArrangements"
import BathroomsSection from "./ListingContentSections/BathroomsSection"
import KitchenSection from "./ListingContentSections/KitchenSection"
import AmenitiesSection from "./ListingContentSections/AmentitiesSection"
import ParkingSection from "./ListingContentSections/ParkingSection"

import Loader from "../../lib/components/Loader"
import { Generic as DropdownOption } from "../../models/Generic"
import { Amenities, Parking } from "@vacasa/owner-api-models"

import { Primary, Secondary } from "../../lib/components/Buttons/BaseButton"
import ActionModal from "../../lib/components/ActionModal/ActionModal"
import ProgressBar from "../../lib/components/ProgressBar/ProgressBar"
import { ONBOARDING_COMPLETED } from "../../constants/preferences.constants"
import LoggingService from "../../services/logging/logging.service"
import { FormattedMessage } from "react-intl"
import {
    trackListingDetails,
    trackListingPage,
} from "services/segment/onboarding/onboardingTracking"
import { observer } from "mobx-react"
import { Fail } from "assets"
import { useCurrentUnit, useUnit, useUnitMutation } from "hooks/units"
import { useContactId } from "hooks/user"
import {
    useOwnerUnitPreferences,
    useOwnerUnitPreferencesMutation,
} from "hooks/owner-preferences"

interface Address {
    address_1: string // eslint-disable-line camelcase
    city: string
    state: string
}

export enum LoadingState {
    initial = -1,
    loading = 1,
    complete = 0,
}

enum DisplayedSection {
    sleepingArrangement = 0,
    bathrooms,
    kitchen,
    amenities,
    parking,
}

type UpdateUnit = Amenities & { parking: Parking }

const ListingDetailsForm: React.FC = (): JSX.Element => {
    const unitMutation = useUnitMutation()
    const unitPreferenceMutation = useOwnerUnitPreferencesMutation()
    const { unit } = useCurrentUnit()
    const searchParams = useLocation().search
    const navigate = useNavigate()

    const unitId = String(
        new URLSearchParams(searchParams).get("u") ??
            String(unit?.id ?? "") ??
            ""
    )
    const preferencesQuery = useOwnerUnitPreferences(unitId)
    const contactId = useContactId()
    const unitQuery = useUnit(unitId, {
        onError: error => {
            LoggingService.error({
                message: `Failed to fetch unit id: ${unitId}, for contact: ${contactId}`,
                error,
            })
        },
        onSuccess: data => {
            setApiModel({
                ...data.attributes.amenities,
                parking: data.attributes.parking,
            })
            setUnitAddress(data.attributes.address)
        },
    })

    const smallScreen = useMediaQuery("(max-width: 520px)")
    const [unitAddress, setUnitAddress] = useState(
        unitId ? ({} as Address) : unit?.attributes.address
    )
    const [saveAmenitiesEnabled, setSaveAmenitiesEnabled] = useState(false)
    const [saveBathroomsEnabled, setSaveBathroomsEnabled] = useState(false)
    const [saveKitchenEnabled, setSaveKitchenEnabled] = useState(false)
    const [saveParkingEnabled, setSaveParkingEnabled] = useState(false)
    const [
        saveSleepingArrangementsEnabled,
        setSaveSleepingArrangementsEnabled,
    ] = useState(false)

    const [confirmedBedrooms, setConfirmedBedrooms] = useState(false)
    const [confirmedKitchen, setConfirmedKitchen] = useState(false)
    const [confirmedAmenities, setConfirmedAmenities] = useState(false)
    const [confirmedParking, setConfirmedParking] = useState(false)
    const [confirmedBathrooms, setConfirmedBathrooms] = useState(false)
    const [nextButtonEnabled, setNextButtonEnabled] = useState(false)
    const [apiModel, setApiModel] = useState<UpdateUnit>({
        beds: {
            bunk: undefined,
            child: undefined,
            double: undefined,
            king: undefined,
            lower: undefined,
            murphy: undefined,
            queen: undefined,
            sofa: undefined,
            upper: undefined,
        },
        hotTub: undefined,
        parking: {
            accessible: undefined,
            fourWheelDriveRequired: undefined,
            notes: undefined,
            paid: undefined,
            street: undefined,
            total: undefined,
            valet: undefined,
        },
        petsFriendly: undefined,
        rooms: {
            bathrooms: {
                full: 0,
                half: 0,
            },
            bedrooms: undefined,
            kitchen: undefined,
            loft: undefined,
        },
        hairDryer: undefined,
        jetTub: undefined,
    })
    const [showFinishModal, setShowFinishModal] = useState(false)
    const [displayedSection, setDisplayedSection] = useState(
        DisplayedSection.sleepingArrangement
    )
    const [unsavedChanges, setUnsavedChanges] = useState(false)
    const [showGettingStartedModal, setGettingStartedShowModal] = useState(true)
    const blocker = unstable_useBlocker(unsavedChanges)

    function getOptionByValue(
        options: DropdownOption[],
        value?: number | null
    ): DropdownOption | null {
        for (const option of options) {
            if (option.id === value) {
                return option
            }
        }
        return null
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async function patchUnit(data: UpdateUnit): Promise<any> {
        unitMutation.mutate(
            {
                unitId,
                data: {
                    id: unitId,
                    type: "unit",
                    attributes: data,
                },
            },
            {
                onError: error => {
                    LoggingService.error({
                        message: "Failed to updateUnit",
                        error,
                    })
                },
                onSuccess: () => {
                    setApiModel({
                        ...apiModel,
                        ...data,
                    })
                },
            }
        )
    }

    async function patchOwnerUnitPreference(
        preferenceName: string,
        value = true
    ): Promise<void> {
        await unitPreferenceMutation.mutateAsync({
            unitId,
            id: preferenceName,
            value,
        })
    }

    useEffect(() => {
        trackListingPage()
    }, [])

    // Need to extract
    // const preferences = preferenceService.getOwnerUnitPreferences(unitId)
    useEffect(() => {
        function findValueByPreferenceName(name: string): boolean {
            const preference = preferencesQuery.data?.find(pref => {
                return pref.id === `onboarding-confirmed-${name}`
            })
            return preference?.attributes.value === true
        }
        setConfirmedBedrooms(findValueByPreferenceName("bedrooms"))
        setConfirmedKitchen(findValueByPreferenceName("kitchen"))
        setConfirmedAmenities(findValueByPreferenceName("amenities"))
        setConfirmedParking(findValueByPreferenceName("parking"))
        setConfirmedBathrooms(findValueByPreferenceName("bathrooms"))
    }, [preferencesQuery.data, unitId])

    useEffect(() => {
        function getNextButtonState(): boolean {
            switch (displayedSection) {
                case DisplayedSection.sleepingArrangement:
                    return confirmedBedrooms && !saveSleepingArrangementsEnabled
                case DisplayedSection.bathrooms:
                    return confirmedBathrooms && !saveBathroomsEnabled
                case DisplayedSection.kitchen:
                    return confirmedKitchen && !saveKitchenEnabled
                case DisplayedSection.amenities:
                    return confirmedAmenities && !saveAmenitiesEnabled
                case DisplayedSection.parking:
                    return confirmedParking && !saveParkingEnabled
            }
        }
        setNextButtonEnabled(getNextButtonState())
    }, [
        confirmedParking,
        confirmedKitchen,
        confirmedAmenities,
        confirmedBathrooms,
        confirmedBedrooms,
        displayedSection,
        saveSleepingArrangementsEnabled,
        saveBathroomsEnabled,
        saveKitchenEnabled,
        saveAmenitiesEnabled,
        saveParkingEnabled,
    ])

    function renderModal(): JSX.Element {
        return (
            <ActionModal
                onCloseHandler={() => setGettingStartedShowModal(false)}
                icon={<PlannerSVG />}
                title={
                    <FormattedMessage
                        id="Onboarding.getStartedModal.title"
                        defaultMessage="Edit your listing details"
                    />
                }
                description={
                    <FormattedMessage
                        id="Onboarding.getStartedModal.description2"
                        defaultMessage="You can help us get your online listing ready as quickly as possible. Please take a moment to review the <b>basic information</b> about your home, and update anything you need to."
                        values={{
                            b: (chunks: ReactNode) => <b>{chunks}</b>,
                        }}
                    />
                }
                primaryButtonTitle={
                    <FormattedMessage
                        id="Onboarding.getStartedModal.primaryButtonTitle"
                        defaultMessage="Get Started"
                    />
                }
                primaryButtonHandler={() => {
                    setGettingStartedShowModal(false)
                    trackListingDetails(
                        "edit_listing_details_popup",
                        "get_started",
                        undefined,
                        unitId
                    )
                }}
                secondaryButtonTitle={
                    <FormattedMessage
                        id="Onboarding.getStartedModal.secondaryButtonTitle"
                        defaultMessage="Maybe Later"
                    />
                }
                secondaryButtonHandler={() => {
                    navigate(-1)
                    trackListingDetails(
                        "edit_listing_details_popup",
                        "maybe_later",
                        undefined,
                        unitId
                    )
                }}
            />
        )
    }

    function renderSleepingArrangementsSection(): JSX.Element {
        return (
            <SleepingArrangements
                confirmedBedrooms={confirmedBedrooms}
                hasUnsavedChangesHandler={(hasUnsavedChanges: boolean) =>
                    setUnsavedChanges(hasUnsavedChanges)
                }
                patchPreference={(preferenceName: string) =>
                    patchOwnerUnitPreference(preferenceName)
                }
                saveSleepingArrangementsHandler={enabled =>
                    setSaveSleepingArrangementsEnabled(enabled)
                }
                unitId={unitId}
            />
        )
    }

    function renderBathroomsSection(): JSX.Element {
        return (
            <BathroomsSection
                apiModel={apiModel}
                confirmedBathrooms={confirmedBathrooms}
                patchPreference={(preferenceName: string) =>
                    patchOwnerUnitPreference(preferenceName)
                }
                patchUnit={patchUnit}
                saveBathroomsHandler={enabled =>
                    setSaveBathroomsEnabled(enabled)
                }
                unitId={unitId}
            />
        )
    }

    function renderKitchenSection(): JSX.Element {
        return (
            <KitchenSection
                apiModel={apiModel}
                confirmedKitchen={confirmedKitchen}
                getOptionByValue={getOptionByValue}
                patchPreference={(preferenceName: string) =>
                    patchOwnerUnitPreference(preferenceName)
                }
                patchUnit={patchUnit}
                saveKitchenHandler={enabled => setSaveKitchenEnabled(enabled)}
                unitId={unitId}
            />
        )
    }

    function renderAmenitiesSection(): JSX.Element {
        return (
            <AmenitiesSection
                apiModel={apiModel}
                confirmedAmenities={confirmedAmenities}
                getOptionByValue={getOptionByValue}
                patchPreference={(preferenceName: string) =>
                    patchOwnerUnitPreference(preferenceName)
                }
                patchUnit={patchUnit}
                saveAmenitiesHandler={enabled =>
                    setSaveAmenitiesEnabled(enabled)
                }
                unitId={unitId}
            />
        )
    }

    function renderParkingSection(): JSX.Element {
        return (
            <ParkingSection
                apiModel={apiModel.parking}
                confirmedParking={confirmedParking}
                getOptionByValue={getOptionByValue}
                patchPreference={(preferenceName: string) =>
                    patchOwnerUnitPreference(preferenceName)
                }
                patchUnit={patchUnit}
                saveParkingHandler={enabled => setSaveParkingEnabled(enabled)}
                unitId={unitId}
            />
        )
    }

    function renderSection(): JSX.Element {
        switch (displayedSection) {
            case DisplayedSection.sleepingArrangement:
                return renderSleepingArrangementsSection()
            case DisplayedSection.bathrooms:
                return renderBathroomsSection()
            case DisplayedSection.kitchen:
                return renderKitchenSection()
            case DisplayedSection.amenities:
                return renderAmenitiesSection()
            case DisplayedSection.parking:
                return renderParkingSection()
        }
    }

    function renderBottomNavigation(): JSX.Element {
        return (
            <MobileStepper
                activeStep={displayedSection}
                classes={{
                    root: styles["bottom_navigation"],
                    dot: styles["dot"],
                    dotActive: styles["active"],
                }}
                position="static"
                steps={5}
                variant="dots"
                nextButton={
                    displayedSection === DisplayedSection.parking ? (
                        <Primary
                            onClick={handleFinishForm}
                            disabled={!nextButtonEnabled}
                            inlineStyle={{
                                width: "132px",
                            }}
                        >
                            <FormattedMessage
                                id="Onboarding.navigationButtons.finish"
                                defaultMessage="Finish"
                            />
                        </Primary>
                    ) : (
                        <Secondary
                            typeOf={"link"}
                            ariaLabel={"Next"}
                            disabled={!nextButtonEnabled}
                            iconDirection="right"
                            onClick={() => {
                                setDisplayedSection(displayedSection + 1)
                                trackListingDetails(
                                    "next",
                                    undefined,
                                    DisplayedSection[displayedSection],
                                    unitId
                                )
                            }}
                        >
                            <>
                                {!smallScreen && (
                                    <FormattedMessage
                                        id="Onboarding.navigationButtons.next"
                                        defaultMessage="Next"
                                    />
                                )}
                                <NavigateNextSVG className={styles.chevron} />
                            </>
                        </Secondary>
                    )
                }
                backButton={
                    <Secondary
                        typeOf={"link"}
                        ariaLabel={"Back"}
                        disabled={
                            displayedSection ===
                            DisplayedSection.sleepingArrangement
                        }
                        iconDirection="left"
                        onClick={() => {
                            setDisplayedSection(displayedSection - 1)
                            trackListingDetails(
                                "back",
                                undefined,
                                DisplayedSection[displayedSection],
                                unitId
                            )
                        }}
                    >
                        <>
                            <NavigateBackSVG className={styles.chevron} />
                            {!smallScreen && (
                                <FormattedMessage
                                    id="Onboarding.navigationButtons.back"
                                    defaultMessage="Back"
                                />
                            )}
                        </>
                    </Secondary>
                }
            />
        )
    }

    function handleFinishForm() {
        patchOwnerUnitPreference(ONBOARDING_COMPLETED)
        setShowFinishModal(true)
        trackListingDetails("finish", undefined, undefined, unitId)
    }

    return (
        <>
            <div className="container-fluid">
                {showFinishModal && (
                    <ActionModal
                        onCloseHandler={(): void => navigate("/setup")}
                        title={
                            <FormattedMessage
                                id="Onboarding.finishModal.title"
                                defaultMessage="You’re all set!"
                            />
                        }
                        description={
                            <FormattedMessage
                                id="Onboarding.finishModal.description"
                                defaultMessage="You've completed the listing details—thanks for your help! We'll let you know when your listing is live. Before your listing goes live, you can block off nights from being rented to guests (such as dates you'd like to use your home)."
                            />
                        }
                        icon={<SpaceRocketSVG />}
                        primaryButtonTitle={
                            <FormattedMessage
                                id="Onboarding.finishModal.goToCalendarButton"
                                defaultMessage="Go to Calendar"
                            />
                        }
                        primaryButtonHandler={(): void => {
                            navigate(`/calendar?u=${unitId}`)
                            trackListingDetails(
                                "go_to_calendar_popup",
                                "go_to_calendar",
                                undefined,
                                unitId
                            )
                        }}
                        secondaryButtonTitle={
                            <FormattedMessage
                                id="Onboarding.finishModal.maybeLaterButton"
                                defaultMessage="Maybe Later"
                            />
                        }
                        secondaryButtonHandler={(): void => {
                            navigate("/setup")
                            trackListingDetails(
                                "go_to_calendar_popup",
                                "maybe_later",
                                undefined,
                                unitId
                            )
                        }}
                    />
                )}
                <div
                    className={classnames(
                        styles.container,
                        styles.listing_content,
                        styles.navigation_inset
                    )}
                >
                    {showGettingStartedModal ? renderModal() : <></>}
                    <div className={styles.navigation}>
                        <Link className="type-body-small label" to={`/setup`}>
                            <SVGIcon
                                svg="icon-feather-arrow-left"
                                className="dusk-lightest h-24 w-24"
                            />
                        </Link>
                        <h3 className="type-heading-medium-large">
                            {unitAddress &&
                                Object.keys(unitAddress).length > 0 &&
                                `${unitAddress?.address_1}, ${unitAddress?.city}, ${unitAddress?.state}`}
                        </h3>
                    </div>
                    <div className={styles.content}>
                        {unitQuery.isLoading ? (
                            <Panel className={styles.panel_content}>
                                <Loader className={styles.inline_loader} />
                            </Panel>
                        ) : (
                            <div>
                                <>
                                    <ProgressBar
                                        steps={[
                                            "sleep",
                                            "bath",
                                            "kitchen",
                                            "amenities",
                                            "parking",
                                        ]}
                                        currentStepIndex={displayedSection}
                                    />
                                    {renderSection()}
                                    {renderBottomNavigation()}
                                </>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {blocker.state === "blocked" && (
                <ActionModal
                    onCloseHandler={(): void => {
                        blocker.reset()
                    }}
                    title={
                        <FormattedMessage
                            id="Navigation.navigationConfirmationDialog.title"
                            defaultMessage="Before you go"
                        />
                    }
                    description={
                        <FormattedMessage
                            id="Navigation.navigationConfirmationDialog.message"
                            defaultMessage="Looks like you've made unsaved changes on this page"
                        />
                    }
                    icon={<Fail />}
                    primaryButtonTitle={
                        <FormattedMessage
                            id="Navigation.navigationConfirmationDialog.stayButton"
                            defaultMessage="Keep Editing"
                        />
                    }
                    primaryButtonHandler={(): void => {
                        blocker.reset()
                    }}
                    secondaryButtonTitle={
                        <FormattedMessage
                            id="Navigation.navigationConfirmationDialog.leaveButton"
                            defaultMessage="Discard Changes"
                        />
                    }
                    secondaryButtonHandler={(): void => {
                        setUnsavedChanges(false)
                        blocker.proceed()
                    }}
                />
            )}
        </>
    )
}
export default observer(ListingDetailsForm)
