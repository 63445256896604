import { authAxios } from "../../api/authAxios"
import type { W9TaxSubmissionResponse } from "@vacasa/owner-api-models/src/legacy/W9TaxSubmission"

type FetchW9TaxSubmissionRequest = {
    contactId: string
    signal?: AbortSignal
}

const fetchW9TaxSubmission = ({
    contactId,
    signal,
}: FetchW9TaxSubmissionRequest): Promise<W9TaxSubmissionResponse["data"]> =>
    authAxios
        .get<W9TaxSubmissionResponse>(
            `v1/legacy/owners/${contactId}/w9-submission`,
            { signal }
        )
        .then(response => response.data.data)

export { fetchW9TaxSubmission }
