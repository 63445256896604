import { FC } from "react"
import styles from "./RateTooltip.module.scss"
import classNames from "classnames"

type RateTooltipProps = {
    rate: number
    type: "min" | "max" | "current"
    side?: "left" | "right"
}
export const RateTooltip: FC<RateTooltipProps> = props => {
    const { rate, type } = props
    return (
        <div
            className={classNames(styles.container, {
                [styles["container--current"] as string]: type === "current",
                [styles["container--right"] as string]: props.side === "right",
            })}
        >
            {type === "min" && "Min"}
            {type === "max" && "Max"}
            {type === "current" && "Your Unit"}
            <strong>${Math.trunc(rate)}</strong>
        </div>
    )
}
