const taxes = {
    title: "Tasse",

    fillOutW9: "Compila il modulo W-9",
    submitW9: "Invia il modulo W-9 online",
    newTaxFormsWillShow:
        "I nuovi moduli fiscali saranno visualizzabili qui appena disponibili.",
    goPaperless: "Opzione elettronica",
    paperlessExplanation:
        "L'opzione elettronica (senza copie cartacee) ti consente di ricevere i moduli per la dichiarazione fiscale con consegna solo in modalità elettronica. Non riceverai più copie cartacee per posta dei moduli fiscali.",
    electronicForms:
        "Ricevi i moduli per la dichiarazione fiscale solo in modalità elettronica",
    getForms: "Ottieni i tuoi moduli per la dichiarazione fiscale",
    irsFormsElectronically:
        "Ricevi i moduli fiscali dell'IRS solo in modalità elettronica",
    mustSubmit:
        "We must have one of the following taxpayer forms on file before we can provide your tax preparation documents. Please complete only the form that best describes your taxpayer status with the IRS. If your taxpayer information has changed, please submit a new form.",

    paperlessNotification: "I moduli fiscali saranno inviati elettronicamente ",
    paperFormNotification: "I moduli fiscali saranno inviati per posta",

    noForms: "Ancora non sono presenti dei moduli.",

    ifExpecting: "Se stai aspettando un",
    willBeAvailable: "sarà disponibile entro il",
    form1099title: "Modulo 1099-MISC",
    form1042title: "Modulo 1042-S",
    jan31: "31 gennaio",
    march15: "15 marzo",

    yourInformation: "Provide your taxpayer information",
    whoFor: "A chi serve",
    howToSubmit: "Come inviarlo",
    w9title: "Modulo W-9",
    w9whoFor:
        "Invia se sei un cittadino statunitense o uno straniero residente che possiede uno dei seguenti numeri di identificazione fiscale: SSN, EIN, o ITIN. Gli stranieri non residenti non devono compilare questo modulo.",
    w8ECItitle: "Modulo W-8ECI",
    w8ECIwhoFor:
        "Invia se sei uno straniero, uno straniero non residente o un'entità attualmente in possesso di un SSN, EIN, o ITIN e dovrai dichiarare questo incasso compilando una dichiarazione dei redditi annuale con l'IRS.",
    w8BENtitle: "Modulo W-8BEN",
    w8BENwhoFor:
        "Invia se sei uno straniero o uno straniero non residente e non dovrai dichiarare questo incasso compilando una dichiarazione dei redditi annuale con l'IRS, o se desideri avvalerti dei benefici fiscali tra gli USA e il Paese in cui risiedi. Poiché i benefici fiscali non si applicano ai guadagni derivanti dagli affitti, coloro che inviano il modulo W-8BEN-E sono soggetti a imposte estere, dedotte dai ricavi lordi con un tasso del 30%.",
    w8BENEtitle: "Modulo W-8BEN-E",
    w8BENEwhoFor:
        "Invia se sei uno straniero che percepisce redditi, non una persona fisica, e non devi presentare una dichiarazione dei redditi annuale con l'IRS. I benefici fiscali possono essere dichiarati nella Parte III di questo modulo. Poiché i benefici fiscali non si applicano ai guadagni derivanti dagli affitti, i beneficiari che inviano il modulo W-8BEN-E sono soggetti a imposte estere, dedotte dai ricavi lordi con un tasso del 30%.",

    learnMore: "Maggiori informazioni su",
    mailForm: "Compila il modulo e invialo per posta a:",

    dwnld: "Download",
    download: "Effettua il download dei dati sulla tua proprietà",
    preparingNotification: "Stiamo preparando i tuoi dati per il download",
    rentalData: "Dati sulla proprietà",
    noDownloads: "Non hai ancora dei download.",
    description: "I dati relativi a ogni anno fiscale saranno disponibili il",
    january15: "15 gennaio",
    description2:
        "dell'anno successivo. Se condividi la proprietà con una o più persone, ognuno di voi dovrà scaricare i propri dati, per ottenere le informazioni corrette per la dichiarazione.",
    instructions:
        "I file .xls possono essere aperti con la maggior parte delle applicazioni per i fogli di calcolo, tra cui Excel, Numbers e Google Sheets.",
    makeChanges: "Want to make changes?",
    w9Support:
        "To make edits to your W-9 details please reach out to the {{HOME_OWNER_SUPPORT}} at {{SUPPORT_NUMBER}}, {{SUPPORT_EMAIL}}",
    taxModal: {
        actionRequired: "Action Required!",
        title: "Enter Taxpayer Info",
        description:
            "We need your taxpayer information on file so we can provide you with accurate tax preparation documents for last year.",
        primaryButton: "Fill it Out",
        secondardButton: "Maybe Later",
    },
    paperless1099Modal: {
        title: "Paperless 1099",
        description:
            "As the new year approaches, be prepared for tax season by receiving your Vacasa 1099 digitally. We'll email you when it's ready.",
        goPaperless: "Go Paperless",
        skipForNow: "Skip for Now",
    },
    w9: {
        taxFormSelect: {
            info1: "Please provide your taxpayer information so we can provide your tax preparation documents. Provide information that best describes your taxpayer status with the IRS.",
            info2: "The name/entity that you provide should be the same name/entity that is listed on your contract with Vacasa.",
        },
        taxClassifications: {
            INP: "Individual/sole proprietor or single-member LLC",
            LLC: "Limited liability company",
            CCO: "C Corporation",
            SCO: "S Corporation",
            PRT: "Partnership",
            TRE: "Trust/estate",
            OTH: "Other",
        },
        llcTaxClassfications: {
            C: "C Corporation",
            S: "S Corporation",
            P: "Partnership",
        },
        taxIdTypes: {
            SSN: "SSN (Social Security Number)",
            EIN: "EIN (Employer Identification Number)",
        },
        forms: {
            agreeAndSign: {
                title: "Agree & Submit",
                termsOfAgreement: "Terms Of Agreement",
                terms: "Under penalties of perjury, I certify that:\n\n1. The number shown on this form is my correct taxpayer identification number (or I am waiting for a number to be issued to me); and \n\n2. I am not subject to backup withholding because: (a) I am exempt from backup withholding, or (b) I have not been notified by the Internal Revenue Service (IRS) that I am subject to backup withholding as a result of a failure to report all interest or dividends, or (c) the IRS has notified me that I am no longer subject to backup withholding; and \n\n3. I am a U.S. citizen or other U.S. person (defined below); and \n\n4. The FATCA code(s) entered on this form (if any) indicating that I am exempt from FATCA reporting is correct. Certification instructions. You must cross out item 2 above if you have been notified by the IRS that you are currently subject to backup withholding because you have failed to report all interest and dividends on your tax return. For real estate transactions, item 2 does not apply. For mortgage interest paid, acquisition or abandonment of secured property, cancellation of debt, contributions to an individual retirement arrangement (IRA), and generally, payments other than interest and dividends, you are not required to sign the certification, but you must provide your correct TIN. See the instructions for Part II, later.",
                iAgree: "I agree to these terms",
                signature: "Signature",
                signaturePlaceholder: "Type fullname",
                signatureHelperText:
                    "By signing this document with an electronic signature, I agree that such signature will be as valid as handwritten signatures to the extent allowed by local law.",
                signedOn: "Signed On",
                validation: {
                    agreeToTermsRequired:
                        "Please agree to our terms of agreement",
                    signatureRequired: "Please enter your signature",
                    signatureMaxLength: "Signature cannot exceed 50 letters",
                },
            },
            taxInfo: {
                validation: {
                    entityTypeRequired: "Entity type is required",
                    entityTypeEmpty: "Entity type must be empty",
                    entityNameRequired: "Entity name is required",
                    taxIDTypeRequired: "Tax ID type is required",
                    taxIDTypeValid: "Tax ID type must be either SSN or EIN",
                    taxIDTypeEIN: "Tax ID type must be EIN",
                    taxClassificationRequired:
                        "Please select a tax classification",
                    taxClassificationValid: "Tax classification invalid",
                    ssnRequired: "SSN is required",
                    ssnLength: "SSN must be 9 digit in XXX-XX-XXXX format",
                    ssnInvalid: "SSN must be 9 digit in XXX-XX-XXXX format",
                    ssnAllZero: "SSN cannot be all 0",
                    ssnEmpty: "SSN must be empty",
                    einRequired: "EIN is required",
                    einLength: "EIN must be 9 digit in XX-XXXXXXX format",
                    einInvalid: "EIN must be 9 digit in XX-XXXXXXX format",
                    einAllZero: "EIN cannot be all 0",
                    einEmpty: "EIN must be empty",
                    llcTypeRequired: "LLC type required",
                    llcTypeValid: "LLC type must be either C, S, or P",
                    llcTypeEmpty: "LLC type must be empty",
                },
            },
            mailingAddress: {
                label: {
                    address: "Address",
                    city: "City",
                    stateOrProvince: "State/Province",
                    zipCode: "Zip/Postal Code",
                },
                placeholder: {
                    addressLine1: "Address Line 1",
                    addressLine2: "Address Line 2",
                    city: "City",
                    stateProvince: "State/Province",
                    zipCode: "5-digit zipcode",
                },
                validation: {
                    addressRequired: "Address is required",
                    cityRequired: "Please enter city",
                    postalCodeRequired: "Postal code is required",
                    provinceRequired: "State/Province is required",
                    postalCodeMaxLength: "Postal code must be 5 digit length",
                    postalCodeAllZero: "Postal code cannot be all 0",
                },
            },
        },
    },
}

export default taxes
