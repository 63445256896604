import { JSONAPIResource, Review } from "@vacasa/owner-api-models"
import classnames from "classnames"
import { format, parseISO } from "date-fns"
import ExpandableText from "lib/components/ExpandableText/ExpandableText"
import { GuestReviewResponse } from "lib/components/FlyOut/Holds/GuestReviewListItem/GuestReviewResponse"
import { StarRating } from "lib/components/StarRating/StarRating"
import { FormattedMessage } from "react-intl"
import { ReactComponent as ReviewsSVG } from "../../../../assets/reviews.svg"
import styles from "./ReviewList.module.scss"

type FormattedReviewListProps = {
    reviewlist: JSONAPIResource<Review>[]
}

type ReviewListProps = {
    reviews: JSONAPIResource<Review>[]
    filter?: string
}

const formatDate = (reviewDate: string) =>
    reviewDate ? format(parseISO(reviewDate), "MM/dd/yyyy") : ""

const FormattedReviewList = ({
    reviewlist,
}: FormattedReviewListProps): JSX.Element | null => {
    if (reviewlist.length === 0) {
        return (
            <div className={styles.noReviews}>
                <ReviewsSVG className={styles.reviewSvg} />
                <div className={styles.reviewsEmptyHeading}>
                    <FormattedMessage
                        id="Performance.reviews.heading"
                        defaultMessage="Guest Reviews"
                    />
                </div>
                <div className={styles.reviewsEmptyParagraph}>
                    <FormattedMessage
                        id="Performance.reviews.reviewModalEmpty"
                        defaultMessage="Your guest ratings and reviews will appear here."
                    />
                </div>
            </div>
        )
    }
    return (
        <div>
            {reviewlist.map((unitReview: JSONAPIResource<Review>) => (
                <div
                    key={unitReview.id}
                    className={styles.container}
                    data-testid="guest-review-list"
                >
                    <div className={styles.reviewHeader}>
                        <div
                            className={classnames(
                                styles.firstInitial,
                                "type-heading-medium"
                            )}
                        >
                            {unitReview.attributes.firstName?.charAt(0)}
                        </div>
                        {unitReview.attributes.firstName &&
                            unitReview.attributes.reviewDate && (
                                <div>
                                    <div className={styles.reviewer}>
                                        {unitReview.attributes.firstName}{" "}
                                        {unitReview.attributes.lastInitial ??
                                            ""}
                                    </div>
                                    <div
                                        className={classnames(
                                            styles.reviewDate,
                                            "type-body-small"
                                        )}
                                    >
                                        {formatDate(
                                            unitReview.attributes.reviewDate
                                        )}
                                    </div>
                                </div>
                            )}
                        {unitReview.attributes.overall !== undefined && (
                            <div
                                className={styles.starRating}
                                data-testid="guest-review-list-star-rating"
                            >
                                <StarRating
                                    value={unitReview.attributes.overall}
                                />
                            </div>
                        )}
                    </div>
                    <div>
                        <ExpandableText
                            collapseLabel={
                                <FormattedMessage
                                    id="CalendarPage.flyout.guestReviewCollapseLabel"
                                    defaultMessage="See less"
                                />
                            }
                            expandLabel={
                                <FormattedMessage
                                    id="CalendarPage.flyout.guestReviewExpandLabel"
                                    defaultMessage="See more"
                                />
                            }
                            text={unitReview.attributes.review ?? ""}
                            reservationId={unitReview.attributes.reservationId}
                        />
                        {unitReview.attributes.response && (
                            <div className={styles.responseContainer}>
                                <GuestReviewResponse
                                    response={unitReview.attributes.response}
                                />
                            </div>
                        )}
                    </div>
                </div>
            ))}
        </div>
    )
}

const ReviewList = ({
    reviews,
    filter,
}: ReviewListProps): JSX.Element | null => {
    const sortedReviews = reviews.sort(function (a, b) {
        return parseISO(b.attributes.reviewDate ?? "") >
            parseISO(a.attributes.reviewDate ?? "")
            ? 1
            : -1
    })
    const filteredReviews = sortedReviews.filter(
        review => review.attributes.overall === Number(filter)
    )

    return (
        <div>
            {!filter && <FormattedReviewList reviewlist={sortedReviews} />}
            {filter && <FormattedReviewList reviewlist={filteredReviews} />}
        </div>
    )
}

export { ReviewList }
