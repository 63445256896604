import classnames from "classnames"
import { FC, memo, useCallback, useEffect, useState } from "react"
import { trackGuestReviewFilterSelection } from "services/segment/performance/performanceTracking"
import { calculatePercentages } from "utils/reviews/reviewsUtil"
import { ReactComponent as StarIcon } from "../../../../assets/icon-star-filled.svg"
import styles from "./RatingChart.module.scss"

interface Props {
    total: number
    rating: number
    percentage: number
    onClick?: (rating: string) => void
    animate?: boolean
}

const ChartBar: FC<Props> = memo(
    ({ animate = true, total, rating, percentage, onClick }) => {
        const [value, setValue] = useState(animate ? -100 : percentage)

        useEffect(() => {
            setValue(percentage)
        }, [percentage])

        const onRatingClick = useCallback(() => {
            if (onClick) {
                onClick(rating.toString())
                trackGuestReviewFilterSelection(rating.toString())
            }
        }, [onClick, rating])

        return (
            <div className={styles.ratingsBar} onClick={onRatingClick}>
                <div
                    className={styles.ratingLabel}
                    data-testid="star-rating-filter"
                >
                    <StarIcon /> {rating}
                </div>
                <div className={styles.ratingsBarWrap}>
                    <div
                        style={{ transform: `translateX(-${100 - value}%)` }}
                        className={styles.ratingBarValue}
                    />
                </div>
                {total > 0 && (
                    <div
                        className={classnames(
                            styles.ratingTextLabel,
                            "font-medium"
                        )}
                    >
                        {percentage}%
                    </div>
                )}
            </div>
        )
    }
)

interface RatingChartProps {
    total: number
    reviewCounts: number[]
    updateFilter: (filter: string) => void
}

const RatingChart: React.FC<RatingChartProps> = memo(props => {
    const { total, reviewCounts, updateFilter } = props

    const reviewsByStarRatingDesc = [...reviewCounts].reverse()
    const reviewPercentages = calculatePercentages(
        reviewsByStarRatingDesc,
        total
    )

    return (
        <div className={styles.chartContainer}>
            {reviewPercentages.map((percentage, index) => (
                <ChartBar
                    total={total}
                    rating={reviewCounts.length - index}
                    percentage={percentage}
                    onClick={updateFilter}
                />
            ))}
        </div>
    )
})

export { RatingChart }
