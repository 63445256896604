const survey = {
    title: "TARIFFE E RICAVI",
    subTitle: "Parlaci dei tuoi obiettivi",
    surveyFollowUp: "Passaggio {step} di {totalSteps}",
    errorTitle: "Survey Not Available",
    loadingErrorMessage: "Sorry, the survey could not be loaded. Please try again later.",
    submitErrorMessage: "Sorry, we could not submit your survey at this time. Please try again later.",
    tanksTitle: "Congratulazioni per aver impostato la tua strategia di guadagno!",
    tanksMessage: "Il nostro team sta lavorando per implementare le tue richieste."

}

export default survey
