import { authAxios } from "../../api/authAxios"

export type PostSurveyRequest = {
    surveyId: string
    unitId: string
    contactId: string
    postSurveyData: SurveyRequest
}

export interface SurveyRequest {
    firstTime: "Yes" | "No";
    pricing: string[];
    minimumRate: number;
    minimumStay: number;
}

const postSurvey = ({
    surveyId,
    unitId,
    contactId,
    postSurveyData
}: PostSurveyRequest): Promise<number> => {
    const surveyData = {
        data: {
            type: "survey-response",
            attributes: {
                contactId,
                unitId,
                surveyData: postSurveyData
            },
        },
    }

    return authAxios
        .post<number>(`/v1/surveys/${surveyId}/responses`,
            surveyData,
            {
                params: {
                    unitId
                }
            }
        )
        .then((response) => {
            // remove this logic and change the response in the backend
            if (response.status === 204) {
                return Number(unitId)
            }
            return response.status
        })
}

export { postSurvey }
