import { OptimizelyProvider } from "@optimizely/react-sdk"
import type { Owner } from "@vacasa/owner-api-models"
import axios from "axios"
import { MOBILE_SESSION } from "Constants"
import "intl"
import { ToastNotificationProvider } from "lib/components/ToastNotification/lib/context"
import { observer, Provider } from "mobx-react"
import { Component } from "react"
import { ReactQueryDevtools } from "react-query/devtools"
import { optimizely } from "services/optimizely.service"
import storageService from "services/storage.service"
import { getFromQsOrAttemptedRoute } from "utils/query-string"
import "./App.scss"
import { RootServiceContext } from "./services"
import RootService from "./services/index"
import LoggingService from "./services/logging/logging.service"
import { MobileNavigationProvider } from "./views/navigation/index"
import AppRoutes from "./views/routes"
import { HotjarProvider } from "components/HotjarProvider/HotjarProvider"
import { LanguageContextProvider } from "contexts/language"
import { FeatureFeedbackProvider } from "contexts/feature-feedback"
import { withHookHocTyped } from "core/hoc/with-hook-hoc"
import { useUser } from "hooks/user"
import { UnitStateProvider } from "contexts/unit"

type AppState = {
    showReferralModal: boolean
}

type HookProps = { user: Owner | null }
type Props = HookProps

@observer
class App extends Component<Props, AppState> {
    constructor(props: Props) {
        super(props)

        this.state = {
            showReferralModal: false,
        }
    }

    setShowReferralModal = (shouldShowReferralModal: boolean): void => {
        this.setState({ showReferralModal: shouldShowReferralModal })
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillMount(): void {
        // RootService.errorService.initRaven();
        axios
            .get<string>("/svg_sprite.html")
            .then(response => {
                const div = document.createElement("div")
                div.innerHTML = response.data
                div.classList.add("d-none")
                document.body.insertBefore(div, document.body.firstChild)
                return div
            })
            .catch(reason => {
                LoggingService.error({
                    message: "Failed to load svg_sprite",
                    error: reason,
                })
            })
    }

    captureMobileSession(): void {
        const userAgentSearchParam = getFromQsOrAttemptedRoute("userAgent")
        if (userAgentSearchParam === "iOSVacasaOwnerApp") {
            storageService.localStorage.setItem(MOBILE_SESSION, "true")
        }
    }

    render(): JSX.Element {
        this.captureMobileSession()

        return (
            <>
                <ReactQueryDevtools position="bottom-right" />
                <LanguageContextProvider>
                    <RootServiceContext.Provider value={RootService}>
                        <OptimizelyProvider
                            optimizely={optimizely}
                            timeout={500}
                            user={
                                this.props.user
                                    ? {
                                          id: this.props.user.contactId,
                                          attributes: {
                                              email: this.props.user.email,
                                          },
                                      }
                                    : undefined
                            }
                        >
                            <Provider rootService={RootService}>
                                <UnitStateProvider>
                                    <FeatureFeedbackProvider>
                                        <ToastNotificationProvider>
                                            <MobileNavigationProvider>
                                                <HotjarProvider />
                                                <AppRoutes
                                                    setShowReferralModal={
                                                        this
                                                            .setShowReferralModal
                                                    }
                                                    showReferralModal={
                                                        this.state
                                                            .showReferralModal
                                                    }
                                                />
                                            </MobileNavigationProvider>
                                        </ToastNotificationProvider>
                                    </FeatureFeedbackProvider>
                                </UnitStateProvider>
                            </Provider>
                        </OptimizelyProvider>
                    </RootServiceContext.Provider>
                </LanguageContextProvider>
            </>
        )
    }
}

export default withHookHocTyped<HookProps>(App, () => ({
    user: useUser().user,
}))
