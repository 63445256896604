import { useUnits } from "hooks/units"
import { useLocation, useNavigate } from "react-router-dom"
import { getUnitById, isOnboardingUnit } from "utils/unit"
import ListingDetailsForm from "./ListingDetailsForm"
import { useEffect } from "react"
import { JSONAPIResource, Unit } from "@vacasa/owner-api-models"

export const Verify = (): JSX.Element => {
    const unitsQuery = useUnits()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const unitId = queryParams.get("u")
    const navigate = useNavigate()

    useEffect(() => {
        // No unit id or query error we redirect
        if (!unitId || unitsQuery.isError) {
            return navigate("/calendar")
        }

        if (unitsQuery.isSuccess) {
            const unit = getUnitById(unitsQuery.data, unitId)
            // If we can't find the unit or it's not an onboarding unit we redirect
            if (!canEditListingDetails(unit)) return navigate("/calendar")
        }
    }, [
        navigate,
        unitId,
        unitsQuery.data,
        unitsQuery.isError,
        unitsQuery.isSuccess,
    ])

    if (unitsQuery.isLoading) return <></>

    return <ListingDetailsForm />
}

// Check if a unit has permission to edit listing details
const canEditListingDetails = (unit?: JSONAPIResource<Unit>) => {
    if (!unit) return false
    if (!isOnboardingUnit(unit)) return false
    return unit.attributes.hasOwnerEditAccess
}
