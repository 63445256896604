import segmentService from "../segment.service"
import {
    EMAIL_LINK_TYPE,
    MAINTENANCE_PAGE_PATH,
    PHONE_NUMBER_TYPE,
} from "./maintenanceTrackingConstants"

export const trackMaintenancePageViewed = (): void => {
    segmentService.page({ path: MAINTENANCE_PAGE_PATH })
}

export const trackMaintenanceNeedHelpEmailClicked = (email: string): void => {
    segmentService.clickTrack({
        type: EMAIL_LINK_TYPE,
        label: email,
    })
}

export const trackMaintenanceNeedHelpPhoneNumberClicked = (
    formattedPhone: string
): void => {
    segmentService.clickTrack({
        type: PHONE_NUMBER_TYPE,
        label: formattedPhone,
    })
}
