import { AxiosError } from "axios"
import { useContactId } from "hooks/user"
import { useMutation, useQueryClient, UseMutationResult } from "react-query"
import { postReferral } from "./postReferral"
import { Referral } from "@vacasa/owner-api-models"

const ReferralKey = "owner-referral"

const useReferralMutation = (): UseMutationResult<
    number,
    AxiosError,
    Referral,
    void
> => {
    const contactId = useContactId()
    const queryClient = useQueryClient()
    return useMutation<number, AxiosError, Referral, void>(
        (referral): Promise<number> =>
            postReferral({
                contactId,
                referral,
            }),
        {
            onSuccess: data => {
                queryClient.setQueryData<number>([ReferralKey, contactId], data)
            },
            onSettled: _response => {
                queryClient.invalidateQueries([ReferralKey, contactId])
            },
        }
    )
}

export { useReferralMutation }
