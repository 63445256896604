import segmentService from "../segment.service"
import { ERROR_PAGE_PATH, NOTFOUND_PAGE_PATH } from "./errorConstants"

export const trackErrorPageViewed = (): void => {
    segmentService.page({ path: ERROR_PAGE_PATH })
}

export const trackNotFoundPageViewed = (): void => {
    segmentService.page({ path: NOTFOUND_PAGE_PATH })
}
