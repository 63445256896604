import classnames from "classnames"
import { PropsWithChildren, ReactNode } from "react"
import { Card, CardContent } from "../Card"

import styles from "./EmptyState.module.scss"

export type EmptyStateProps = {
    image: ReactNode
    title: ReactNode
    description: ReactNode
}

const Image: React.FC<PropsWithChildren> = ({ children }) => (
    <div className={styles.image}>{children}</div>
)

const Title: React.FC<PropsWithChildren> = ({ children }) => (
    <h4 className={classnames(styles.title, "type-heading-small", "caps")}>
        {children}
    </h4>
)

const Description: React.FC<PropsWithChildren> = ({ children }) => (
    <p className={styles.description}>{children}</p>
)

const EmptyState = ({
    image,
    title,
    description,
}: EmptyStateProps): JSX.Element => (
    <Card>
        <CardContent className={styles.content}>
            <Image>{image}</Image>
            <Title>{title}</Title>
            <Description>{description}</Description>
        </CardContent>
    </Card>
)

export { EmptyState }
