import { action, computed, makeObservable, observable } from "mobx"
import ownerAPIService from "../api/owner.api.service"
import { UserPreference, JSONAPIResource } from "@vacasa/owner-api-models"

type UnitPreferences = { [unitId: string]: JSONAPIResource<UserPreference>[] }

/**
 * @deprecated
 * PreferenceService is deprecated. Please use react query in src/hooks/owner-preferences instead.
 */
class PreferenceService {
    @observable private unitPreferences: UnitPreferences = {}

    /**
     * Because @computed getters cannot take parameters, we use a method
     * that takes the unit id and returns a computed value that can be
     * observed by Mobx.
     * @param unitId used to determine the unit preferences.
     */
    getOwnerUnitPreferences(unitId: string) {
        return computed(() => {
            if (!this.unitPreferences[unitId]) {
                this.fetchOwnerUnitPreferences(unitId)
            }
            return this.unitPreferences
        }).get()
    }

    constructor() {
        makeObservable(this)
    }

    @action reset(): void {
        this.unitPreferences = {}
    }

    @action async patchOwnerUnitPreference(
        unitId: string,
        preference: JSONAPIResource<UserPreference>
    ): Promise<void> {
        await ownerAPIService
            .patchOwnerUnitPreference(unitId, preference)
            .then(() => {
                this.fetchOwnerUnitPreferences(unitId)
                return
            })
    }

    private async fetchOwnerUnitPreferences(unitId: string): Promise<void> {
        await ownerAPIService
            .getOwnerUnitPreferences(unitId)
            .then(preferences => {
                this.unitPreferences = {
                    ...this.unitPreferences,
                    [unitId]: preferences,
                }
                return
            })
    }
}

export default new PreferenceService()
