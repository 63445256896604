import { ChevronLeft, ChevronRight } from "react-feather"

import styles from "./NavButtons.module.scss"

/**
 * Content of the button to move forward to th switch to the next month
 *
 * Tied closely to the usage of the react-dates library
 */
const Next = (): JSX.Element => <ChevronRight className={styles.next} />

/**
 * Content of the button to move backward to switch to the previous month.
 *
 * Tied closely to the usage of the react-dates library
 */
const Previous = (): JSX.Element => <ChevronLeft className={styles.previous} />

export { Next, Previous }
