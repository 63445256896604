import { UserNotification } from "@vacasa/owner-api-models"
import { AxiosError } from "axios"
import { useContactId } from "hooks/user"
import {
    useMutation,
    UseMutationResult,
    useQuery,
    UseQueryResult,
} from "react-query"
import { fetchUserNotificationPreference } from "./fetchUserNotificationPreference"
import {
    putUserNotificationPreference,
    PutUserNotificationPreferenceRequest,
} from "./putUserNotificationPreference"

export const useUserNotificationPreference = (): UseQueryResult<
    UserNotification,
    AxiosError
> => {
    const contactId = useContactId()
    return useQuery(
        ["user-notification-preference", contactId],
        () => fetchUserNotificationPreference(),
        {
            enabled: !!contactId,
            refetchInterval: 1000 * 60 * 10, // 10 minutes
            staleTime: 1000 * 60 * 10, // 10 minutes
        }
    )
}

export const useUserNotificationPreferenceMutation = (): UseMutationResult<
    UserNotification,
    AxiosError,
    PutUserNotificationPreferenceRequest,
    unknown
> => {
    return useMutation<
        UserNotification,
        AxiosError,
        PutUserNotificationPreferenceRequest,
        unknown
    >(request => putUserNotificationPreference(request))
}
