import {
    ChangeEvent,
    Ref,
    forwardRef,
    useImperativeHandle,
    useState,
} from "react"
import { FormRef } from "../types"
import styles from "./MailingAddressForm.module.scss"
import classNames from "classnames"
import { Input } from "../../../../lib/components/Input"
import { useTaxInformationForm } from "../hooks"
import { FormattedMessage, useIntl } from "react-intl"
import { w9, useValidation } from "hooks/validation"
import { MenuItem, Select } from "@material-ui/core"
import { statesInUSA } from "utils/array/statesArray"
import { ChevronDown } from "react-feather"
import { Label } from "lib/components/Label"

export const MailingAddressForm = forwardRef((_, ref: Ref<FormRef>) => {
    const { data, setData } = useTaxInformationForm()
    const [errors, setErrors] = useState(new Map<string, string>())

    const intl = useIntl()
    const { validate } = useValidation(w9.mailingAddress(intl))

    const isFormValid = async (): Promise<boolean> => {
        const formErrors = await validate(data.mailingAddress)
        setErrors(formErrors)
        return formErrors.size === 0
    }

    useImperativeHandle(ref, () => ({
        isFormValid,
    }))

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setData(prev => ({
            ...prev,
            mailingAddress: {
                ...prev.mailingAddress,
                [e.target.name]: e.target.value,
            },
        }))
    }

    const onStateProvinceChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        setData(prev => ({
            ...prev,
            mailingAddress: {
                ...prev.mailingAddress,
                stateOrProvince: event.target.value as string,
            },
        }))
    }

    const onBlurValidation = async (e: ChangeEvent<HTMLInputElement>) => {
        const fieldErrors = await validate(data.mailingAddress, e.target.name)
        setErrors(fieldErrors)
    }

    return (
        <div className={classNames(styles.container)}>
            <div
                className={classNames(
                    "type-heading-extra-small-caps text-midnight-70 font-extrabold",
                    styles.title
                )}
            >
                Mailing Address
            </div>
            <div className="flex flex-column" style={{ gap: "8px" }}>
                <Input
                    labelText={intl.formatMessage({
                        id: "Taxes.w9.mailingAddrss.label.address",
                        defaultMessage: "Address",
                    })}
                    value={data.mailingAddress.addressLine1}
                    name="addressLine1"
                    placeholder={intl.formatMessage({
                        id: "Taxes.w9.mailingAddress.placeholder.addressLine1",
                        defaultMessage: "Address Line 1",
                    })}
                    onChange={onChange}
                    onBlur={onBlurValidation}
                    error={errors.has("addressLine1")}
                    hint={errors.get("addressLine1")}
                />
                <Input
                    value={data.mailingAddress.addressLine2}
                    name="addressLine2"
                    placeholder={intl.formatMessage({
                        id: "Taxes.w9.mailingAddress.placeholder.addressLine1",
                        defaultMessage: "Address Line 2",
                    })}
                    onChange={onChange}
                    onBlur={onBlurValidation}
                    error={errors.has("addressLine2")}
                    hint={errors.get("addressLine2")}
                />
            </div>
            <div>
                <Input
                    labelText={intl.formatMessage({
                        id: "Taxes.w9.mailingAddrss.label.city",
                        defaultMessage: "City",
                    })}
                    value={data.mailingAddress.city}
                    name="city"
                    placeholder={intl.formatMessage({
                        id: "Taxes.w9.mailingAddress.placeholder.city",
                        defaultMessage: "City",
                    })}
                    onChange={onChange}
                    onBlur={onBlurValidation}
                    error={errors.has("city")}
                    hint={errors.get("city")}
                />
            </div>
            <div>
                <Label error={errors.has("stateOrProvince")}>
                    <FormattedMessage
                        id="Taxes.w9.mailingAddrss.label.stateOrProvince"
                        defaultMessage="State/Province"
                    />
                </Label>
                <Select
                    displayEmpty
                    value={data.mailingAddress.stateOrProvince}
                    onChange={onStateProvinceChange}
                    name="stateOrProvince"
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                        },
                        getContentAnchorEl: null,
                    }}
                    disableUnderline
                    IconComponent={ChevronDown}
                    error={!!errors.get("type")}
                >
                    <MenuItem value="">
                        <span className="text-dusk-lighter">
                            Select an option
                        </span>
                    </MenuItem>
                    {statesInUSA.map(state => (
                        <MenuItem
                            value={state.abbreviation}
                            key={state.abbreviation}
                        >
                            {state.name}
                        </MenuItem>
                    ))}
                </Select>
                {errors.get("type") && (
                    <div
                        className={classNames(
                            "type-body-small-semibold",
                            "text-sunset"
                        )}
                    >
                        {errors.get("stateOrProvince")}
                    </div>
                )}
            </div>
            <div>
                <Input
                    labelText={intl.formatMessage({
                        id: "Taxes.w9.mailingAddrss.label.zipCode",
                        defaultMessage: "Zip/Postal Code",
                    })}
                    value={data.mailingAddress.postalCode}
                    name="postalCode"
                    placeholder={intl.formatMessage({
                        id: "Taxes.w9.mailingAddress.placeholder.zipCode",
                        defaultMessage: "5-digit zipcode",
                    })}
                    onChange={onChange}
                    onBlur={onBlurValidation}
                    error={errors.has("postalCode")}
                    hint={errors.get("postalCode")}
                />
            </div>
        </div>
    )
})
