import { OWNER_LIBRARY_URL } from "Constants"
import classNames from "classnames"
import { useOwnerFeatureFlag } from "hooks/owner-feature-flag"
import Modal from "lib/components/Modal/Modal"
import SVGIcon from "lib/components/SVGIcon"
import { Tooltip } from "lib/components/Tooltip/Tooltip"
import { useCallback, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import {
    OwnerLibraryLocation,
    SegmentLocation,
} from "services/segment/constants"
import { trackOwnerLibraryLinkClicked } from "services/segment/owner-library/ownerLibraryTracking"
import styles from "./GuestReviewModal.module.scss"
import { ReviewDetails } from "./ReviewDetails"
import { useCurrentUnit } from "hooks/units"

interface GuestReviewModalProps {
    closeGuestReviewModal: () => void
}

const GuestReviewModal: React.FC<GuestReviewModalProps> = props => {
    const { closeGuestReviewModal } = props
    const featureFlag = useOwnerFeatureFlag("channel-reviews")
    const channelReviewsEnabled = featureFlag.isEnabled
    const intl = useIntl()
    const allRatings = intl.formatMessage({
        id: "Performance.reviews.allRatings",
        defaultMessage: "All Ratings",
    })
    const tooltipTitle = intl.formatMessage({
        id: "Performance.reviews.reviewModalTooltipTitle",
        defaultMessage: "Overall Rating & Review list",
    })
    const learnMore = intl.formatMessage({
        id: "Performance.reviews.learnMore",
        defaultMessage: "Learn more",
    })

    const onLearnMore = () => {
        trackOwnerLibraryLinkClicked(
            SegmentLocation.GuestReviewModal,
            OwnerLibraryLocation.GuestReviews
        )
    }

    const tooltipMessage = (
        <FormattedMessage
            id={
                channelReviewsEnabled
                    ? "Performance.reviews.reviewModalTooltipV2"
                    : "Performance.reviews.reviewModalTooltip"
            }
            defaultMessage="Your overall rating is currently calculated based only on Vacasa.com reviews. The reviews shown in this list match what is shown on your unit page on Vacasa.com."
            values={{
                br: <br />,
                learnMore: (
                    <a
                        className={styles.learnMore}
                        onClick={onLearnMore}
                        href={`${OWNER_LIBRARY_URL}reviews`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {learnMore}
                    </a>
                ),
            }}
        />
    )
    const handleClose = useCallback(() => {
        closeGuestReviewModal()
        setFilter(allRatings)
    }, [allRatings, closeGuestReviewModal])

    const { unitId: currentUnitId } = useCurrentUnit()
    const [unitId, setUnitId] = useState(currentUnitId ?? "")
    const [filter, setFilter] = useState(allRatings)
    const updateFilter = (ratingFilter: string) => setFilter(ratingFilter)
    const updateUnitId = (unitId: string) => setUnitId(unitId)

    return (
        <Modal
            onCloseHandler={handleClose}
            modalPanelStyleOverride={styles.modalContainer}
        >
            <div
                className={styles.header}
                data-testid="guest-review-modal-header"
            >
                <FormattedMessage
                    id="Performance.reviews.heading"
                    defaultMessage="Guest Reviews"
                    description="Heading title for average guest rating."
                />
                <Tooltip
                    title={tooltipTitle}
                    body={tooltipMessage}
                    customTooltipStyle={styles.customTooltip}
                >
                    <SVGIcon
                        svg="icon-feather-info"
                        colorName="dusk-lighter"
                        className={classNames(
                            "w-24 h-24 zero-margin cursor-pointer",
                            styles.infoIcon
                        )}
                    />
                </Tooltip>
            </div>
            <ReviewDetails
                unitId={unitId}
                filter={filter}
                updateUnitId={updateUnitId}
                updateFilter={updateFilter}
            />
        </Modal>
    )
}

export { GuestReviewModal }
