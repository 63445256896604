const linenService = {
    title: "Servicio de ropa blanca y toallas",

    notificationComponent: {
        planWillBeginWithInitialCharge:
            "Tu plan comenzará el <strong>{date, date, medium}</strong> con un cargo inicial de <strong>{cost}</strong>.",
        planWillBeginWithCharge:
            "Tu plan comenzará el <strong>{date, date, medium}</strong> con un cargo de <strong>{cost}</strong>.",
        planWillRenewFor:
            "Tu plan se renovará automáticamente el <strong>{date, date, medium}</strong> por <strong>{cost}</strong>.",
        planWillEndOn:
            "Tu plan terminará el <strong>{date, date, medium}</strong> después del cual ya no recibirás sábanas y toallas de reemplazo con descuento.",
    },

    infoPanel: {
        learnMoreTitle: "Una solución sencilla y económica",
        bodyText:
            "Nuestro servicio de ropa blanca y toallas te brinda sábanas y toallas de gran calidad con un descuento para la industria. Sábanas limpias y toallas suaves para cada huésped significan mejores reseñas, más reservas y más ingresos para ti.",
        learnMore: "Más información",
    },

    servicePlan: {
        prepend: "Tu plan",
        standard: "Servicio estándar de ropa blanca y toallas",
        premium: "Servicio superior de ropa blanca y toallas",
        youAreNotEnrolled: "No estás inscripto",
        year: "año",
        nextBillingDate: "Próxima fecha de facturación",
        newPlanBegins: "Fecha de comienzo del nuevo plan",
        nextBilling: "Próxima facturación",
        discount: "descuento",
        setup: "Contratación",
        setupFee: " tarifa de contratación",
        planHistory: "Historial de planes",
        ongoing: "en curso",
        pending: "Pendiente",
        append: "Los cargos por este servicio serán facturados como gastos en tus estados mensuales.",
    },

    helpPanel: {
        needHelp: "¿Necesitas ayuda?",
        contactSentenceIfQuestions:
            "Comunícate con tu contacto {contact} si tienes preguntas o deseas actualizar tu plan.",
    },

    enrolled: "Inscripto",
    notEnrolled: "No inscripto",
    begins: "Comienza",
    renews: "Renueva",
    expires: "Vence",
}

export default linenService

// Setup: "Contratación",
