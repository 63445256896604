import * as React from "react"
import { trackHomeownerWebinarBannerClicked } from "services/segment/calendar/calendarTracking"
import { Banner } from "../Banner"
import { useOwnerFeatureFlag } from "../../hooks/owner-feature-flag"
import { isFeatureFlagEnabled } from "utils/feature-flag"

const handleBannerInteraction = () => trackHomeownerWebinarBannerClicked()

const WebinarBanner: React.FC = () => {
    const featureFlag = useOwnerFeatureFlag(
        "owner-portal-enable-webinar-banner"
    )

    if (!isFeatureFlagEnabled(featureFlag)) return null

    return (
        <Banner
            variant="secondary"
            copy={featureFlag.variables?.message ?? ""}
            ctaLabel={featureFlag.variables?.ctaLabel ?? ""}
            ctaDestination={featureFlag.variables?.ctaUrl ?? ""}
            interactionEvent={handleBannerInteraction}
            newWindow={true}
            destinationHighlight={true}
        />
    )
}

export { WebinarBanner }
