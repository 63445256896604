import React from "react"
import BaseButton from "../Buttons/BaseButton"
import { Card, CardContent } from "../../../components/Card"

interface PromotionProps {
    title: React.ReactNode
    bodyText: React.ReactNode
    actions: Action[]
    img: JSX.Element
}

interface Action {
    text: React.ReactNode
    onClick: () => void
}

const Promotion: React.FC<PromotionProps> = (props: PromotionProps) => (
    <Card>
        <CardContent>
            <div className="flex promotion">
                <div className="content-zone">
                    <h3 className="type-heading-medium-large">{props.title}</h3>
                    <p className="type-body zero-margin">{props.bodyText}</p>
                    <div className="action-zone">
                        {props.actions &&
                            props.actions.map((action, i) => (
                                <span className="button-container" key={i}>
                                    <BaseButton
                                        onClick={action.onClick}
                                        className="btn-top-margin"
                                        buttonType={"button__link--secondary"}
                                        typeOf={"link"}
                                        ariaLabel="Learn more about our Linen and terry program"
                                    >
                                        {action.text}
                                    </BaseButton>
                                </span>
                            ))}
                    </div>
                </div>
                <div className="flex align-end img-zone">{props.img}</div>
            </div>
        </CardContent>
    </Card>
)

export default Promotion
