import { JSONAPIResource, Reservation } from "@vacasa/owner-api-models"
import { AxiosError } from "axios"
import { useContactId } from "hooks/user"
import { useCallback } from "react"
import { useQuery, useQueryClient, UseQueryResult } from "react-query"
import { fetchReservation } from "./fetchReservation"

const RESERVATION_QUERY_KEY = "reservation" as const

export type ReservationResponse = {
    data: JSONAPIResource<Reservation>
}

export const useInvalidateReservation = (): ((id: string) => Promise<void>) => {
    const queryClient = useQueryClient()
    return useCallback(
        (id: string) =>
            queryClient.invalidateQueries([RESERVATION_QUERY_KEY, id]),
        [queryClient]
    )
}

const useReservation = (
    id?: string | null
): UseQueryResult<ReservationResponse, AxiosError> => {
    const contactId = useContactId()
    return useQuery(
        [RESERVATION_QUERY_KEY, id],
        ({ signal }) =>
            fetchReservation({
                id: String(id),
                contactId: String(contactId),
                signal,
            }),
        {
            enabled: !!id && !!contactId,
            refetchInterval: 1000 * 60 * 30, // 30 mins
            staleTime: 1000 * 60 * 30, // 30 mins,
        }
    )
}

export { useReservation }
