import { AxiosError } from "axios"
import { useQuery, UseQueryOptions, UseQueryResult } from "react-query"
import { fetchRevenueImpact, RevenueImpactRequest } from "./fetchRevenueImpact"
import { RevenueImpactData } from "@vacasa/owner-api-models"

export const useRevenueImpact = (
    params: Omit<RevenueImpactRequest, "signal">,
    options: UseQueryOptions<RevenueImpactData, AxiosError> = {}
): UseQueryResult<RevenueImpactData, AxiosError> =>
    useQuery<RevenueImpactData, AxiosError>(
        ["revenue-impact", ...Object.values(params).map(String)],
        ({ signal }) =>
            fetchRevenueImpact({
                ...params,
                signal,
            }),
        {
            cacheTime: 1000 * 60 * 45, // 45 mins
            refetchInterval: 1000 * 60 * 30, // 30 mins
            staleTime: 1000 * 60 * 30, // 30 mins
            ...options,
        }
    )
