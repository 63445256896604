import Passcode from "@vacasa/owner-api-models/src/Passcode"
import { FC } from "react"
import style from "./MultipleCodes.module.scss"

interface MultipleCodesProps {
    passcode: Passcode
}

const MultipleCodes: FC<MultipleCodesProps> = props => {
    const { passcode } = props

    const accessCodes = passcode.accessCodes

    return (
        <>
            {accessCodes.map(accessCode => (
                <div
                    key={accessCode.accessCode}
                    className={style.passcodeContainer}
                >
                    {accessCode.propertyAccessDescription && (
                        <div className={style.propertyDescription}>
                            {accessCode.propertyAccessDescription}
                        </div>
                    )}
                    <div className={style.accessCode}>
                        {accessCode.accessCode}
                    </div>
                </div>
            ))}
        </>
    )
}

export default MultipleCodes
