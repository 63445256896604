import { JSONAPIDocument, Owner } from "@vacasa/owner-api-models"
import { authAxios } from "../../api/authAxios"

type FetchContactRequest = {
    contactId: string
    signal?: AbortSignal
}

const fetchContact = ({
    contactId,
    signal,
}: FetchContactRequest): Promise<JSONAPIDocument<Owner>> =>
    authAxios
        .get(`v1/owners/${contactId}`, { signal })
        .then(response => response.data)

export { fetchContact }
