import { useContext } from "react"
import {
    TaxInformationFlyoutAPIContext,
    TaxInformationFlyoutStateContext,
} from "../contexts"
import { FlyoutState, FlyoutAPI } from "../types"

export const useTaxInformationFlyoutState = () =>
    useContext<FlyoutState>(TaxInformationFlyoutStateContext)

export const useTaxInformationFlyoutAPI = () =>
    useContext<FlyoutAPI>(TaxInformationFlyoutAPIContext)
