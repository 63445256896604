import { VerifyToken } from "@vacasa/owner-api-models"
import { authAxios } from "api/authAxios"

export type PostVerifyTokenRequest = {
    token: string
    signal?: AbortSignal
}

export interface VerifyTokenResponse {
    data: VerifyToken
}

const postVerifyToken = ({
    token,
    signal,
}: PostVerifyTokenRequest): Promise<VerifyTokenResponse> =>
    authAxios
        .post<VerifyTokenResponse>(`/v1/verify-token`, undefined, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            signal,
        })
        .then(response => response.data)

export { postVerifyToken }
