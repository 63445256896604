import { FEAUTRE_TRACKING_INTERACTIONS } from "Constants"
import {
    FC,
    PropsWithChildren,
    createContext,
    useContext,
    useMemo,
    useReducer,
} from "react"
import storageService from "services/storage.service"

export type FeatureId =
    | "guest-review-modal"
    | "homecare"
    | "nights-occupied-modal"
    | "market-rate-comparison-chart"
    | "minimum-rate-simulator"
    | "core-metrics"

type State = {
    [key in FeatureId]: number
}

const DEFAULT_STATE: State = {
    "nights-occupied-modal": 0,
    homecare: 0,
    "guest-review-modal": 0,
    "market-rate-comparison-chart": 0,
    "minimum-rate-simulator": 0,
    "core-metrics": 0,
}

const getLocalStorageState = (): State => {
    const value = storageService.localStorage.getItem(
        FEAUTRE_TRACKING_INTERACTIONS
    )

    try {
        const parsedValue = value
            ? (JSON.parse(value) as { [key in FeatureId]: number })
            : {}
        return {
            ...DEFAULT_STATE,
            ...parsedValue,
        }
    } catch {
        return DEFAULT_STATE
    }
}

type API = {
    incrementInteractionCount: (featureId: FeatureId) => void
}

export const FeatureFeedbackStateContext = createContext<State>(DEFAULT_STATE)
export const FeatureFeedbackAPIContext = createContext<API>({} as API)

export const FeatureFeedbackProvider: FC<PropsWithChildren> = ({
    children,
}) => {
    const [state, dispatch] = useReducer(reducer, getLocalStorageState())

    const api = useMemo(() => {
        const incrementInteractionCount = (feature: FeatureId) => {
            dispatch({ type: "increment", feature })
        }
        return { incrementInteractionCount }
    }, [])

    return (
        <FeatureFeedbackAPIContext.Provider value={api}>
            <FeatureFeedbackStateContext.Provider value={state}>
                {children}
            </FeatureFeedbackStateContext.Provider>
        </FeatureFeedbackAPIContext.Provider>
    )
}

export const useFeatureInteractionState = () =>
    useContext<State>(FeatureFeedbackStateContext)

export const useFeatureInteractionAPI = () =>
    useContext<API>(FeatureFeedbackAPIContext)

// Reducer
type Actions = { type: "increment"; feature: FeatureId }
const reducer = (_: State, action: Actions): State => {
    switch (action.type) {
        case "increment": {
            // Get the latest state from local storage -  this is to allow for multiple tabs to be open
            const state = getLocalStorageState()
            const newState = {
                ...state,
                [action.feature]: state[action.feature] + 1,
            }
            storageService.localStorage.setItem(
                FEAUTRE_TRACKING_INTERACTIONS,
                JSON.stringify(newState)
            )
            return newState
        }
    }
}
