import classNames from "classnames"
import styles from "./MarketRateComparisonChartSkeleton.module.scss"

export const MarketRateComparisonChartSkeleton = () => {
    return (
        <div className={styles.skeleton}>
            <div className={classNames(styles.title, "shimmer")} />
            <div className={classNames(styles.description)}>
                <div className="shimmer" />
                <div className="shimmer" />
                <div className="shimmer" />
            </div>
            <div className={classNames(styles.chart, "shimmer")} />
            <div className={classNames(styles.legend, "shimmer")} />
        </div>
    )
}
