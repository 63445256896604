import { FC, useEffect } from "react"
import { FormattedMessage } from "react-intl"
import { Link } from "react-router-dom"
import { trackPaymentSettingPageViewed } from "services/segment/statement/statementTracking"
import { ReactComponent as ArrowLeft } from "../../assets/icon-arrow-left.svg"
import { ReactComponent as ChevronRight } from "../../assets/icon-chevron-right.svg"
import { ReactComponent as CreditCard } from "../../assets/icon-credit-card.svg"
import UpdatePayment from "./UpdatePayment"
import styles from "./settings.module.scss"

const PaymentSettingsUpdate: FC = () => {
    useEffect(() => {
        trackPaymentSettingPageViewed()
    }, [])

    return (
        <div
            className={styles["payment-setting-main-container"]}
            data-testid="payment-setting-main-container"
        >
            <div className="container-fluid">
                <div className={styles.navigation}>
                    <Link className="type-body-small label" to={`/statements`}>
                        <ArrowLeft className={styles["back-arrow-button"]} />
                    </Link>

                    <h4 className="type-display-small">
                        <FormattedMessage
                            id="Settings.title"
                            defaultMessage="Payment Settings"
                        />
                    </h4>
                </div>

                <div className={styles["breadcrumbs-navigator"]}>
                    <Link className="midnight-60" to={`/statements`}>
                        <FormattedMessage
                            id="Settings.linkToStatements"
                            defaultMessage="Statements"
                        />
                    </Link>
                    <ChevronRight className={styles["chevron-right"]} />
                    <p>
                        <FormattedMessage
                            id="Settings.title"
                            defaultMessage="Payment Settings"
                        />
                    </p>
                </div>
                <div className={styles["payment-method-text-container"]}>
                    <span className={styles["inline-container"]}>
                        <CreditCard className={styles["container-icon"]} />
                        <h3>
                            <FormattedMessage
                                id="Settings.paymentMethod"
                                defaultMessage="Payment Method"
                            />
                        </h3>
                    </span>
                    <p>
                        <FormattedMessage
                            id="Settings.paymentMethodText"
                            defaultMessage="Payment delivery depends on the owner's selected payment method."
                        />
                    </p>
                </div>

                <UpdatePayment />
            </div>
        </div>
    )
}

export default PaymentSettingsUpdate
