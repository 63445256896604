const statements = {
    faqAriaLabel: "View FAQ",
    title: "Estratti conto",
    emptyStateCTA: "Scopri di più sugli estratti conto",
    emptyStateEarnings:
        "I nuovi estratti conto saranno disponibili entro il giorno 10 di ogni mese.",

    pdf: "PDF",
    pdfAriaLabel: "Visualizza PDF",
    pdfNotification: "In preparazione per il download del PDF",
    fileName: "estratto conto",

    print: "Stampa",
    printAriaLabel: "Anteprima di stampa",

    paymentSummary: "Riassunto dei pagamenti: tutti gli alloggi",
    beginningBalance: "Saldo iniziale",
    payment: "Pagamento",
    payments: "Pagamenti",
    proceeds: "Ricavi",
    proceedsBalance: "Saldo ricavi",
    coOwnerProceeds: "Ricavi del comproprietario",
    endingBalance: "Saldo finale",
    details: "Dettagli",
    summary: "Riassunto",
    nightsOccupied: "Notti occupate",
    occupancy: "Capienza",
    reserveBalance: "Saldo di riserva",

    beginningReserveBalance: "Inizio saldo di riserva",
    EndingReserveBalance: "Fine saldo di riserva",
    amountHeld: "Importo detenuto",
    amountUsed: "Importo utilizzato",

    netRent: "Affitto netto",
    expense: "Spesa",
    expenses: "Spese",

    month: "Mese",
    nights: "Notti",
    grossRent: "Affitto lordo",
    mgmtFee: "Commissione di gestione",
    total: "Totale",
    credits: "Accrediti",

    checkNumber: "Assegno num.",
    paymentDescription: "Pagamento verso il conto corrente che finisce con",
    statementYearSummary: "{year} Year Summary",

    canadaSalesTaxesHeader: "Taxes - Expenses & Management Fees",

    tooltips: {
        proceeds: "Amount you'll be paid for this month",
        occupancy:
            "The percentage of available nights this month that you rented to guests (does not include your personal use dates and complimentary stays)",
        nightsOccupied:
            "How many nights this month your home hosted guests (only counts reservations with check-out dates within the month)",
    },
    paymentSettings: "Payment Settings",
    paymentSettingsSmall: "Settings",
    paymentSettingsNotification:
        "Manage your bank details and payment settings here.",
    paymentSettingsNotificationTitle: "Payment Settings",
    checkItOut: "Check it Out",
    settings: "Settings",
    backToTop: "Back to Top",
    needHelp: "Need Help?",
    faqAbbreviation: "FAQ",
    errorDownloading: "Error downloading",
    pdfDownloadError:
        "We were unable to create a PDF of your statement. Please try again later.",
    faq: {
        whereDoISendPayments: {
            answer: {
                canada: "If your home is in Canada",
                allOthers: "All others",
            },
        },
    },
    netRentNoteBegin: "NOTE: ",
    netRentNoteContent:
        "The entire stay spans across {reservationMonthSpan} months ({reservationPeriod}). Monthly revenue will appear on each month’s respective statement(s).",
}

export default statements
