import { Link } from "react-router-dom"
import styles from "./W8Forms.module.scss"
import { ChevronDown, ChevronUp } from "react-feather"
import classNames from "classnames"
import Accordion from "lib/components/Accordion/Accordion"

export const W8Forms = () => {
    return (
        <div className={styles.container}>
            <span className="type-heading-small-caps">Choose your form</span>
            <Link
                className={styles.learnMore}
                to="https://www.irs.gov/"
                target="_blank"
            >
                Learn more
            </Link>
            <div className={styles.forms}>
                {FORMS.map(form => (
                    <div className={styles.accordion}>
                        <Accordion
                            animate
                            key={form.title}
                            partials={{
                                top: (
                                    <span className="type-heading-extra-small-caps text-midnight-70 font-extrabold">
                                        {form.title}
                                    </span>
                                ),
                                bottom: (
                                    <div
                                        className={classNames(
                                            "type-body-small",
                                            styles.accordionBody
                                        )}
                                    >
                                        {form.description}
                                    </div>
                                ),
                            }}
                            closeIcon={
                                <ChevronUp
                                    width={16}
                                    height={16}
                                    color="var(--dusk)"
                                />
                            }
                            openIcon={
                                <ChevronDown
                                    width={16}
                                    height={16}
                                    color="var(--dusk)"
                                />
                            }
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}

const FORMS = [
    {
        title: "Form W-8BEN",
        description:
            "Provide if you are a Foreign individual or nonresident alien who does not file an annual tax return with the IRS or would like to claim income tax treaty benefits between the U.S. and your country of residence. Since treaty benefits do not apply to rental income, individuals providing Form W‐8BEN are subject to foreign withholding, deducted from gross income, at a rate of 30%.",
    },
    {
        title: "Form W-8BEN-E",
        description:
            "Provide if you are a Foreign recipient of income, who is not an individual, and does not file an annual tax return with the IRS. Income tax treaty benefits can also be claimed on Part III of this form. Since treaty benefits do not apply to rental income, recipients providing Form W-8BEN-E are subject to foreign withholding, deducted from gross income, at a rate of 30%.",
    },
    {
        title: "Form W-8ECI",
        description:
            "Provide if you are a Foreign individual, nonresident alien, or entity who currently possesses a SSN, EIN, or ITIN and will be reporting this income by filing an annual tax return with the IRS.",
    },
    {
        title: "Form W-8EXP",
        description:
            "For use by foreign governments, international organizations, foreign central banks of issue, foreign tax-exempt organizations, foreign private foundations, and governments of U.S. possessions.",
    },
    {
        title: "Form W-8IMY",
        description:
            "Certificate of Foreign Intermediary, Foreign Flow-Through Entity, or Certain U.S. Branches for United States Tax Withholding and Reporting",
    },
]
