const linenService = {
    title: "Servicio de ropa para el hogar",

    notificationComponent: {
        planWillBeginWithInitialCharge:
            "Tu plan comienza el <strong>{date, date, medium}</strong> con un coste inicial de <strong>{cost}</strong>.",
        planWillBeginWithCharge:
            "Tu plan comienza el <strong>{date, date, medium}</strong> con un coste de <strong>{cost}</strong>.",
        planWillRenewFor:
            "Tu plan se renovará automáticamente el <strong>{date, date, medium}</strong> por <strong>{cost}</strong>",
        planWillEndOn:
            "Tu plan finalize el <strong>{date, date, medium}</strong> después dejarás de recibir los cambios de sábanas y toallas con descuento.",
    },

    infoPanel: {
        learnMoreTitle: "Una solución sencilla y asequible",
        bodyText:
            "Nuestro servicio de ropa para el hogar te ofrece sábanas y toallas de calidad como en un hotel. Proporcionar sábanas como nuevas y toallas suaves mejora los comentarios y aumenta el número de reservas y los ingresos.",
        learnMore: "Más información",
    },

    servicePlan: {
        prepend: "Tu plan",
        standard: "Servicio de ropa para el hogar estándar",
        premium: "Servicio de ropa para el hogar premium",
        youAreNotEnrolled: "No te has registrado",
        year: "año",
        nextBillingDate: "Próxima fecha de facturación",
        newPlanBegins: "Fecha de comienzo del nuevo plan",
        nextBilling: "Próxima factura",
        discount: "descuento",
        setup: "Contratación",
        setupFee: " tarifa de contratación",
        planHistory: "Historial",
        ongoing: "en curso",
        pending: "Pendiente",
        append: "Los costes de este servicio se facturarán como gastos en tu extracto mensual.",
    },

    helpPanel: {
        needHelp: "¿Necesitas ayuda?",
        contactSentenceIfQuestions:
            "Dirígete a tu persona de contacto {contact} si tienes dudas o deseas actualizar tu plan.",
    },

    enrolled: "Inscrito",
    notEnrolled: "No inscrito",
    begins: "Comienza",
    renews: "Se renueva",
    expires: "Caduca",
}

export default linenService

// Setup: "Contratación",
