import { MonthOccupancyMetadata } from "@vacasa/owner-api-models"
import type { AxiosError } from "axios"
import { useContactId } from "hooks/user"
import { useCallback } from "react"
import { useQuery, useQueryClient, UseQueryResult } from "react-query"
import { fetchMonthOccupancy } from "./fetchMonthOccupancy"

const MONTH_OCCUPANCY_QUERY_KEY = "month-occupancy" as const

const useMonthOccupancy = (
    unitId: string | null,
    startDate?: Date,
    endDate?: Date
): UseQueryResult<MonthOccupancyMetadata, AxiosError> => {
    const contactId = useContactId()

    return useQuery(
        [MONTH_OCCUPANCY_QUERY_KEY, unitId],
        ({ signal }) =>
            fetchMonthOccupancy({
                contactId: String(contactId),
                unitId: unitId as string,
                startDate,
                endDate,
                signal,
            }),
        {
            enabled: !!unitId && !!contactId,
            refetchInterval: 1000 * 60 * 30, // 30 mins
            staleTime: 1000 * 60 * 30, // 30 mins,
        }
    )
}

const useInvalidateMonthOccupancy = (): (() => Promise<void>) => {
    const queryClient = useQueryClient()
    return useCallback(
        () => queryClient.invalidateQueries([MONTH_OCCUPANCY_QUERY_KEY]),
        [queryClient]
    )
}

export { useMonthOccupancy, useInvalidateMonthOccupancy }
