import { observer } from "mobx-react"
import React, { PropsWithChildren } from "react"
import { trackFaqSection } from "services/segment/statement/statementTracking"
import Accordion from "../../../../lib/components/Accordion/Accordion"
import styles from "../StatementsFAQ.module.scss"
import { useCurrentUnit } from "hooks/units"

const Question: React.FC<PropsWithChildren> = ({ children }) => (
    <div className={styles.top}>{children}</div>
)
const Answer: React.FC<PropsWithChildren> = ({ children }) => (
    <div className={styles.bottom}>{children}</div>
)

type FAQProps = {
    children: React.ReactNode
    open?: boolean
    trackOption?: string
}
const FAQContainer = ({
    children,
    open,
    trackOption,
}: FAQProps): JSX.Element => {
    const { unitId } = useCurrentUnit()
    if (React.Children.count(children) !== 2) {
        throw new Error("FAQ requires exactly two children")
    }

    const [question, answer] = React.Children.toArray(children)
    const sendTrackEvent = React.useCallback(() => {
        trackFaqSection(String(unitId) ?? "", trackOption)
    }, [trackOption, unitId])
    return (
        <Accordion
            open={open}
            partials={{
                top: question,
                bottom: answer,
            }}
            onOpen={sendTrackEvent}
        />
    )
}

FAQContainer.Question = Question
FAQContainer.Answer = Answer

export const FAQ = observer(FAQContainer)
