import classNames from "classnames"
import React from "react"
import styles from "./BetaLabel.module.scss"

export interface BetaLabelProps {
    className?: string
}

export const BetaLabel: React.FC<BetaLabelProps> = ({ className }) => {
    return (
        <button
            className={classNames(
                styles.betaBtn,
                "type-heading-tiny-caps",
                className
            )}
        >
            Beta
        </button>
    )
}
