import { UnitAvailabilityDays } from "@vacasa/owner-api-models"
import { MAX_CALENDAR_YEARS } from "../../../../Constants"
import {
    addDays,
    addYears,
    differenceInDays,
    endOfMonth,
    formatISO,
    getMonth,
    isAfter,
    isBefore,
    parseISO,
} from "date-fns"

const shouldCheckReservationBetweenRange = ({
    date,
    startDate,
    visibleMonthIndex,
    numVisibleMonths,
}: {
    date: Date
    startDate?: Date | null
    visibleMonthIndex?: number
    numVisibleMonths?: number
}) => {
    if (!startDate) return false
    return (
        isAfter(date, startDate) &&
        ((getMonth(date) <=
            Number(visibleMonthIndex) + Number(numVisibleMonths) - 1 &&
            getMonth(date) >= Number(visibleMonthIndex)) ||
            (getMonth(date) === 0 && visibleMonthIndex === 11))
    )
}

/**
 * @param date
 * @param blockAllDays whether to block all calendar days
 * @param calendarAvailability available calendar days
 * @param [startDate] selected start date
 * @param [endDate] selected end date
 * @param [visibleMonthIndex] currently visible month
 * @param [numVisibleMonths] how many months are visible
 * @returns whether the day is blocked based on the availability rules
 */
export function isDayBlocked(
    date: Date,
    blockAllDays: boolean,
    calendarAvailability: UnitAvailabilityDays,
    startDate?: Date | null,
    endDate?: Date | null,
    visibleMonthIndex?: number,
    numVisibleMonths?: number,
    disableStartDate?: boolean
): boolean {
    if (
        blockAllDays ||
        // Exceeds max months into the future
        !isBefore(date, endOfMonth(addYears(new Date(), MAX_CALENDAR_YEARS)))
    ) {
        return true
    }

    const isInProgressHold = disableStartDate && startDate && endDate

    // check day is currently visible on screen, for performance reasons
    // add extra case if editing the start date is blocked (hold is in progress)
    if (
        (!!startDate &&
            !endDate &&
            shouldCheckReservationBetweenRange({
                date,
                startDate,
                numVisibleMonths,
                visibleMonthIndex,
            })) ||
        isInProgressHold
    ) {
        return reservationExistsBetweenRange(
            startDate,
            date,
            calendarAvailability
        )
    }

    const dateKey = formatISO(date, { representation: "date" })
    const day = calendarAvailability[dateKey]
    return !!(
        day &&
        // start date not yet selected, and day is a check in day
        ((!startDate && day.isCheckinDay) ||
            // start date selected, and day is a check in day,
            // and day is before the start date,
            // or reservation exists between start date and current date
            (startDate &&
                day.isCheckinDay &&
                (isBefore(date, startDate) ||
                    reservationExistsBetweenRange(
                        startDate,
                        date,
                        calendarAvailability
                    ))) ||
            // both start and end dates selected (meaning next selection will be a start date),
            // and day is after the selected end date, and day is a check in day
            (startDate &&
                endDate &&
                isAfter(date, endDate) &&
                day.isCheckinDay) ||
            // day is not available and not a check in day
            (!day.isAvailable && !day.isCheckinDay) ||
            // is both a check in and out day
            (day.isCheckoutDay && day.isCheckinDay))
    )
}

const reservationExistsBetweenRange = (
    start: Date,
    end: Date,
    calendarAvailability: UnitAvailabilityDays
): boolean =>
    // check there is a reservation between start and end
    !!Object.keys(calendarAvailability)
        .map(key => calendarAvailability[key])
        .find(availability => {
            if (!availability) throw new Error("Invalid availability data")
            // is not an available day and not a check in day
            return (
                ((!availability.isAvailable && !availability.isCheckinDay) ||
                    // is both a check in and out day
                    (availability.isCheckoutDay && availability.isCheckinDay) ||
                    // is an available check out day
                    (availability.isCheckoutDay && availability.isAvailable)) &&
                // unavailable day is between selected start date and end day
                isAfter(parseISO(availability.date), start) &&
                !isAfter(parseISO(availability.date), end)
            )
        })

const isWithinOwnerHoldAllowedRange = (startDate: Date, endDate: Date) => {
    const ownerHoldMaxDate = endOfMonth(
        addYears(new Date(), MAX_CALENDAR_YEARS)
    )
    return (
        !isAfter(startDate, ownerHoldMaxDate) &&
        !isAfter(endDate, ownerHoldMaxDate)
    )
}

/**
 * TODO add unit tests
 * @param startDate start date of the hold
 * @param endDate end date of the hold
 * @param blockAllDays whether to block all calendar days
 * @param calendarAvailability available calendar days
 * @returns whether the date range is available for an owner occupy hold.
 */
export function isDateRangeAvailable(
    startDate: Date | null | undefined,
    endDate: Date | null | undefined,
    blockAllDays: boolean,
    calendarAvailability: UnitAvailabilityDays
): boolean {
    if (
        !startDate ||
        !endDate ||
        !isBefore(startDate, endDate) ||
        !isWithinOwnerHoldAllowedRange(startDate, endDate)
    ) {
        return false
    }

    const intervals = constructArrayOfIntervals(startDate, endDate)
    const numberOfEntries = intervals.length
    for (let index = 0; index < numberOfEntries; index++) {
        const date = intervals[index]
        if (!date) throw new Error("Invalid start or end date")
        if (
            isDayBlocked(
                date,
                blockAllDays,
                calendarAvailability,
                startDate,
                endDate
            )
        ) {
            return false
        }
        // Only the end date can be another hold's check in day
        if (index < numberOfEntries - 1) {
            const dateKey = formatISO(date, { representation: "date" })
            const day = calendarAvailability[dateKey]
            if (day?.isCheckinDay) return false
        }
    }

    return true
}

/**
 * Creates an object with all the dates between two dates inclusive so
 * we could check if any of the dates are unavailable and therefore
 * cannot be an owner occupy hold.
 * @param start start date of the hold
 * @param end end date of the hold
 * @returns array containing the interval dates
 */
function constructArrayOfIntervals(start: Date, end: Date): Date[] {
    const intervals = [start]

    while (differenceInDays(end, start) > 0) {
        const currentEnd = addDays(start, 1)
        intervals.push(currentEnd)
        start = currentEnd
    }
    return intervals
}
