import React, { Fragment } from "react"
import styles from "./loaders.module.scss"

interface LoaderIndicatorProps {
    indicators: string[]
}

const LoaderIndicator: React.FC<LoaderIndicatorProps> = (
    props: LoaderIndicatorProps
) => {
    return (
        <Fragment>
            {props.indicators &&
                props.indicators.map((content, i) => (
                    <div
                        className={styles.button__loader}
                        dangerouslySetInnerHTML={{ __html: content }}
                        key={`loader-${i}`}
                    />
                ))}
        </Fragment>
    )
}

export default LoaderIndicator
