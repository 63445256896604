import classnames from "classnames"
import { ReactNode } from "react"
import { ArrowLeft } from "react-feather"
import flattenChildren from "react-keyed-flatten-children"
import { Link } from "react-router-dom"
import { getClassStyle } from "utils/styles/styleWrapper"
import styles from "./ListLayout.module.scss"

type ListLayoutProps = {
    title: ReactNode
    actions?: ReactNode
    children: ReactNode
    goBackTo?: string
    size?: "sm" | "md" | "lg"
    divider?: boolean
    className?: string
    listItemOptions?: {
        [key: string]: {
            excludeBottomMargin: boolean
        }
    }
    titleTestId?: string
}

const ListLayout = ({
    title,
    actions,
    children,
    goBackTo,
    size = "md",
    divider = false,
    className,
    listItemOptions = {},
    titleTestId,
}: ListLayoutProps): JSX.Element => {
    return (
        <div className={classnames(styles.layout, className)}>
            <div className={styles.titleContainer}>
                <span>
                    {goBackTo ? (
                        <Link to={goBackTo}>
                            <ArrowLeft
                                aria-label="Navigate back"
                                className={styles.backIcon}
                            />
                        </Link>
                    ) : null}
                    <h1 data-testid={titleTestId} className={styles.title}>
                        {title}
                    </h1>
                </span>
                <div className={styles.actions}>{actions}</div>
            </div>
            <ul
                className={classnames(styles.content, {
                    [getClassStyle(styles.small)]: size === "sm",
                    [getClassStyle(styles.medium)]: size === "md",
                    [getClassStyle(styles.large)]: size === "lg",
                })}
            >
                {flattenChildren(children).map((child, index) => (
                    <li
                        key={index}
                        className={classnames(
                            styles.listItem,
                            {
                                [getClassStyle(styles.dividerBottom)]: divider,
                                [getClassStyle(styles["listItem--no-margin"])]:
                                    listItemOptions[`${index}`]
                                        ?.excludeBottomMargin,
                            },
                            "list-layout__list-item"
                        )}
                    >
                        {child}
                    </li>
                ))}
            </ul>
        </div>
    )
}

export { ListLayout }
