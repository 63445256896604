import { authAxios } from "../../api/authAxios"

export type PatchOwnerUnitPreferencesRequest = {
    unitId: string | number
    id: string
    value: string | number | boolean | null | undefined
}

export const patchOwnerUnitPreferences = ({
    unitId,
    id,
    ...patch
}: PatchOwnerUnitPreferencesRequest): Promise<void> =>
    authAxios.patch(`v1/user-preferences/units/${unitId}`, {
        data: {
            type: "user-preference",
            id,
            attributes: {
                ...patch,
            },
        },
    })
