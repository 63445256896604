import { authAxios } from "api/authAxios"
import { W9Request } from "./types"

export const patchW9 = (contactId: string, args: W9Request): Promise<void> =>
    authAxios.patch(`/v1/finances/${contactId}/w9`, {
        data: {
            attributes: {
                ...args,
            },
            type: "w9",
        },
    })
