import { FormattedMessage } from "react-intl"
import { ReactComponent as LostSVG } from "../../../../assets/lost.svg"
import styles from "./ErrorScreenBody.module.scss"
import { Referral } from "@vacasa/owner-api-models"
import { AxiosError } from "axios"
import { UseMutationResult } from "react-query"
import { LinkSecondary, Primary } from "../../Buttons/BaseButton"
import { ButtonLoader } from "lib/components/Loader/ButtonLoader/ButtonLoader"
interface ErrorScreenBodyProps {
    referral: Referral
    referralMutation: UseMutationResult<number, AxiosError, Referral, void>
    handleReferral: (key: string, value: string) => void
    handleAcknowledgement: (option: boolean) => void
    handleShowErrorScreen: (showError: boolean) => void
}

const handleOnClick = (
    referral: Referral,
    referralMutation: UseMutationResult<number, AxiosError, Referral, void>
) => {
    referralMutation.mutate(referral)
}

const ErrorScreenBody: React.FC<ErrorScreenBodyProps> = props => {
    const {
        referral,
        referralMutation,
        handleReferral,
        handleAcknowledgement,
        handleShowErrorScreen,
    } = props

    return (
        <div className={styles.errorScreen}>
            <LostSVG />
            <div className={styles.errorTitle}>
                <FormattedMessage
                    id="OwnerReferral.errorTitle"
                    defaultMessage="Error loading"
                />
            </div>
            <div className={styles.errorDescription}>
                <FormattedMessage
                    id="OwnerReferral.errorDescription"
                    defaultMessage="We had an issue submitting your responses. Please try again."
                />
            </div>
            <div className={styles.buttons}>
                <Primary
                    className={styles.tryAgain}
                    onClick={() => handleOnClick(referral, referralMutation)}
                    disabled={referralMutation.isLoading}
                    isLoading={referralMutation.isLoading}
                    loader={
                        <ButtonLoader
                            indicator={"dot"}
                            indicatorCount={3}
                            animation={"blink"}
                        />
                    }
                >
                    <FormattedMessage
                        id="OwnerReferral.tryAgain"
                        defaultMessage="Try Again"
                    />
                </Primary>
                <LinkSecondary
                    typeOf={"link"}
                    onClick={() => {
                        referralMutation.reset()
                        handleReferral("street", "")
                        handleReferral("city", "")
                        handleReferral("state", "")
                        handleReferral("country", "")
                        handleReferral("zipCode", "")
                        handleAcknowledgement(false)
                        handleShowErrorScreen(false)
                    }}
                >
                    <FormattedMessage
                        id="OwnerReferral.backToForm"
                        defaultMessage="Back to Form"
                    />
                </LinkSecondary>
            </div>
        </div>
    )
}

export { ErrorScreenBody }
