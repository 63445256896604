import classnames from "classnames"
import * as React from "react"
import { Zap } from "react-feather"
import { FormattedMessage } from "react-intl"
import { showBookingProbabilityMessage } from "utils/bookingProbability/bookingProbabilityUtil"
import { getClassStyle } from "utils/styles/styleWrapper"
import { useBookingProbability } from "../../../../hooks/booking-probability"
import styles from "./BookingProbabilityMessage.module.scss"

type BookingProbabilityMessageProps = {
    unitId: string
    contactId: string | undefined
    startDate: Date
    endDate: Date
    className?: string
    variant?: "light" | "dark"
}

const BookingProbabilityMessage: React.FunctionComponent<
    BookingProbabilityMessageProps
> = ({ unitId, contactId, startDate, endDate, className, variant }) => {
    const bookingProbabilityQuery = useBookingProbability(
        unitId,
        startDate,
        endDate
    )

    const show = showBookingProbabilityMessage(bookingProbabilityQuery)

    if (!show || !contactId) {
        return null
    }

    return (
        <HighBookingProbabilityMessage
            variant={variant}
            className={className}
        />
    )
}

/**
 * Raw message component without calling `useBookingProbability`
 * @param param0
 * @returns Component
 */
const HighBookingProbabilityMessage: React.FC<
    Pick<BookingProbabilityMessageProps, "variant" | "className">
> = ({ className, variant }) => {
    return (
        <div
            className={classnames(
                styles.bookingProbabilityMessage,
                {
                    [getClassStyle(styles.darkMode)]: variant === "dark",
                },
                className
            )}
        >
            <Zap aria-hidden="true" className={styles.zap} size={14} />
            <p>
                <FormattedMessage
                    id="CalendarPage.flyout.guestBookingLikely"
                    description="These nights are highly likely to be booked by guests."
                    defaultMessage="These nights are highly likely to be booked by guests."
                />
            </p>
        </div>
    )
}

export { BookingProbabilityMessage, HighBookingProbabilityMessage }
