import { format } from "date-fns"
import { utcToZonedTime } from "date-fns-tz"
import React from "react"
import styles from "./LastRefreshedOn.module.scss"
import classNames from "classnames"

export interface LastRefreshedOnProps {
    date: string
}

export const LastRefreshedOn: React.FC<LastRefreshedOnProps> = ({ date }) => {
    return (
        <div className={classNames(styles.lastRefreshedOn, "antialiased")}>
            Last Updated{" "}
            {format(
                utcToZonedTime(date, "America/Los_Angeles"),
                "MMM dd, yyyy"
            )}
        </div>
    )
}
