import Modal from "../Modal/Modal"
import { ProductTourCard, ProductTourCardProps } from "./ProductTourCard"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import { Keyboard, Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import styles from "./ProductTour.module.scss"
import { ProductTourNavigation } from "./ProductTourNavigation"
import { FC, useEffect, useRef, useState } from "react"

import {
    useOwnerPreferences,
    useOwnerPreferencesMutation,
} from "hooks/owner-preferences"
import { useQueryClient } from "react-query"
import { OWNER_PREFERENCE_QUERY_KEY } from "hooks/owner-preferences/useOwnerPreferences"
import { isUnauthorizedOrCancelledRequest } from "utils/error/error"
import LoggingService from "services/logging/logging.service"
import {
    trackProductTourDismissed,
    trackProductTourPaginationClicked,
    trackProductTourPresented,
} from "services/segment/product-tour/productTourTracking"
import { max } from "lodash"

export type ProductTourSlidesProps = Omit<ProductTourCardProps, "page">

type ProductTourProps = {
    name: string
    userPreferenceName: string
    slides: ProductTourSlidesProps[]
}

export const ProductTour: FC<ProductTourProps> = ({
    name,
    slides,
    userPreferenceName,
}) => {
    const maxSlideIndex = useRef(1)
    const [activeIndex, setActiveIndex] = useState(0)
    const [show, setShow] = useState(false)
    const queryClient = useQueryClient()
    const { mutate } = useOwnerPreferencesMutation({
        onSuccess: () => {
            queryClient.invalidateQueries(OWNER_PREFERENCE_QUERY_KEY)
        },
        onError: err => {
            if (isUnauthorizedOrCancelledRequest(err)) return
            LoggingService.error({
                message: `Failed to update user preference:${userPreferenceName}. Failed with error: ${err.message}`,
                error: err,
            })
        },
    })
    const userPreferencesQuery = useOwnerPreferences()

    useEffect(() => {
        maxSlideIndex.current =
            max([activeIndex + 1, maxSlideIndex.current]) ?? 1
    }, [activeIndex])

    useEffect(() => {
        if (!show) return
        trackProductTourPresented(name)
    }, [name, show])

    useEffect(() => {
        if (show) {
            document.body.style.overflow = "hidden"
        } else {
            document.body.style.overflow = "auto"
        }
    }, [show])

    useEffect(() => {
        if (!userPreferencesQuery.isSuccess) return
        const preference = userPreferencesQuery.data.find(
            userPreference => userPreference.id === userPreferenceName
        )
        if (!preference || preference.attributes.value) return
        setShow(true)
    }, [
        userPreferenceName,
        userPreferencesQuery.data,
        userPreferencesQuery.isSuccess,
    ])

    const onCloseProductTour = (finished?: boolean) => {
        setShow(false)
        mutate({
            id: userPreferenceName,
            value: true,
        })
        if (!finished) trackProductTourDismissed(name)
    }

    if (!show) return null

    return (
        <Modal
            onCloseHandler={onCloseProductTour}
            modalPanelStyleOverride={styles.modal}
            modalContentStyleOverride={styles.modalContent}
        >
            <div className={styles.swiperContainer}>
                <Swiper
                    autoHeight
                    onActiveIndexChange={swiper =>
                        setActiveIndex(swiper.activeIndex)
                    }
                    onInit={swiper => {
                        swiper.pagination.bullets.forEach((element, index) => {
                            element.addEventListener("click", e => {
                                if (maxSlideIndex.current < index) {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    return
                                }
                                trackProductTourPaginationClicked(
                                    name,
                                    index + 1
                                )
                            })
                        })
                    }}
                    modules={[Keyboard, Navigation, Pagination]}
                    pagination={{
                        dynamicBullets: true,
                        dynamicMainBullets: 5,
                        clickable: true,
                    }}
                    keyboard
                >
                    {slides.map((slide, index) => (
                        <SwiperSlide key={slide.title}>
                            <ProductTourCard
                                {...slide}
                                page={`${index + 1}/${slides.length}`}
                            />
                        </SwiperSlide>
                    ))}
                    <ProductTourNavigation
                        name={name}
                        isFirst={activeIndex === 0}
                        isLast={activeIndex === (slides?.length ?? 0) - 1}
                        onFinishClick={onCloseProductTour}
                    />
                </Swiper>
            </div>
            <SVGFilter />
        </Modal>
    )
}

const SVGFilter = () => {
    return (
        <svg width={0} height={0}>
            <defs>
                <filter
                    id="filter0_d_6562_967"
                    x="-14px"
                    y="-14px"
                    width="40"
                    height="40"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="1" dy="1" />
                    <feGaussianBlur stdDeviation="5" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0.2 0 0 0 0 0.286275 0 0 0 1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_6562_967"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_6562_967"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter0_d_6562_14891"
                    x="0"
                    y="0"
                    width="40"
                    height="40"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="2" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.172549 0 0 0 0 0.203922 0 0 0 0 0.223529 0 0 0 0.08 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_6562_14891"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_6562_14891"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    )
}
