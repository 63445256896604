import { OwnerSearchResult } from "@vacasa/owner-api-models/src/legacy"
import { UnitSearchResult } from "views/search/types"

const toUnitSearchResults = (data: OwnerSearchResult[]): UnitSearchResult[] => {
    if (!Array.isArray(data)) {
        throw new Error("'data' is not an Array")
    }
    return data.map(result => toUnitSearchResult(result))
}

const toUnitSearchResult = (data: OwnerSearchResult): UnitSearchResult => {
    return {
        active: data.Active ?? 0,
        address: data.Address2
            ? `${data.Address1 ?? ""}, ${data.Address2}`
            : `${data.Address1 ?? ""}`,
        city: data.City ?? "",
        contactId: data.ContactID ?? 0,
        email: data.Email ?? "",
        emailAddress: data.EmailAddress ?? "",
        firstName: data.FirstName ?? "",
        id: data.UnitID ?? 0,
        lastName: data.LastName ?? "",
        name: data.UnitName ?? "",
        state: data.State ?? "",
        terminated: data.Terminated ?? 0,
        unitCode: data.UnitCode ?? "",
        username: data.UserName ?? "",
        zip: data.Zip ?? "",
        activeContract: data.ActiveContract,
    }
}

const filterEmptySearchParams = (data: { [key: string]: string }) => {
    return Object.keys(data)
        .filter(key => !!data[key])
        .reduce(
            (filteredObject, key) => ({
                ...filteredObject,
                [key]: data[key],
            }),
            {}
        )
}

export { filterEmptySearchParams, toUnitSearchResult, toUnitSearchResults }
