import classnames from "classnames"
import { getClassStyle } from "utils/styles/styleWrapper"

import styles from "./Card.module.scss"
import type { CardContentProps } from "./CardContent/CardContent"
import type { CardHeaderProps } from "./CardHeader/CardHeader"

type CardProps = {
    children:
        | React.ReactElement<CardContentProps | CardHeaderProps>
        | React.ReactElement<CardContentProps | CardHeaderProps>[]
    className?: string
    noBorderOnMobile?: boolean
    testId?: string
}

const Card = ({
    children,
    className,
    noBorderOnMobile,
    testId,
}: CardProps): JSX.Element => (
    <div
        className={classnames(
            styles.card,
            {
                [getClassStyle(styles.hideBorderOnMobile)]: noBorderOnMobile,
            },
            className
        )}
        data-testid={testId}
    >
        {children}
    </div>
)

export { Card }
export type { CardProps }
