import Passcode from "@vacasa/owner-api-models/src/Passcode"
import { AxiosError } from "axios"
import { useQuery, UseQueryResult } from "react-query"
import {
    fetchSmartHomeLockCode,
    PasscodeResponse,
} from "./fetchSmartHomeLockCode"

const PASSCODE_QUERY_KEY = "passcode" as const

const useSmartHomeLockCode = (
    reservationId: string,
    unitId: string
): UseQueryResult<Passcode, AxiosError> => {
    const emptyResponse: PasscodeResponse = {
        data: {
            attributes: {
                accessCodes: [],
                startDate: "",
                endDate: "",
                startTime: "",
                endTime: "",
                timezone: "",
                start: "",
                end: "",
            },
        },
    }

    return useQuery(
        [PASSCODE_QUERY_KEY, reservationId, unitId],
        ({ signal }) =>
            fetchSmartHomeLockCode({
                reservationId: reservationId,
                unitId: unitId,
                signal,
            }).then((value: PasscodeResponse) => {
                return value.data ? value.data.attributes : emptyResponse
            }),
        {
            enabled: !!reservationId && !!unitId,
            refetchInterval: 1000 * 60 * 30, // 30 mins
            staleTime: 1000 * 60 * 30, // 30 mins,
        }
    )
}

export { useSmartHomeLockCode }
