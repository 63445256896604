export const mapPlaceResultToAddress = (
    result: google.maps.places.PlaceResult | undefined
) => {
    const streetNumber = result?.address_components?.find(ac =>
        ac.types.includes("street_number")
    )?.short_name
    const streetName = result?.address_components?.find(ac =>
        ac.types.includes("route")
    )?.long_name
    const city =
        result?.address_components?.find(ac => ac.types.includes("locality"))
            ?.long_name ??
        result?.address_components?.find(ac => ac.types.includes("sublocality"))
            ?.long_name ??
        ""
    const state =
        result?.address_components?.find(ac =>
            ac.types.includes("administrative_area_level_1")
        )?.long_name ?? ""
    const country =
        result?.address_components?.find(ac => ac.types.includes("country"))
            ?.long_name ?? ""
    const zipCode =
        result?.address_components?.find(ac => ac.types.includes("postal_code"))
            ?.long_name ?? ""

    return {
        street: [streetNumber, streetName].filter(i => !!i).join(" "),
        city,
        state,
        country,
        zipCode,
    }
}
