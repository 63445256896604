const settings = {
    title: "Payment Settings",
    reserveBalance: "Höhe der Rückstellung",
    paymentSettings: "Zahlungseinstellungen",
    directDepositTitle: "Bank Account",
    checksByMailTitle: "Paper Checks",
    checksByMailEnrolled: "You are enrolled in paper checks.",
    checksByMailNotEnrolled: "You are not enrolled in paper checks.",
    paymentMethod: "Payment Method",
    paymentMethodText:
        "Payment delivery depends on the owner's selected payment method.",
    directDepositFineprint:
        "Deposits and withdrawals usually appear in the account 2-5 business days after the monthly statement.",
    checksByMailFineprint:
        "Paper checks usually take 5-10 business days to arrive by mail. Please confirm your mailing address in your {ProfileLink}.",
    reserveBalanceEmptyState:
        "Du kannst deine Rückstellung hier einsehen und aktualisieren.",
    reserveBalanceTextPartII: "für jede Ferienimmobilie.",
    reserveBalanceText:
        "If your expenses exceed your revenue in a given month, your Reserve Account will be used to cover the difference. If your reserve account balance is below {balance}, we'll automatically replenish it from revenue you earn.",
    reserveUpdateAria:
        "Klicke auf diese Schaltfläche, um die Höhe deiner Rückstellung zu aktualisieren.",
    reserveUpdatedNotification: "Höhe der Rückstellung aktualisiert",
    reserveMinimumRequired: "This unit requires at least {amount} reserve",
    directDepositEnrolled: "You are enrolled in direct deposit.",
    achDirectDepositEnrolled:
        "You are enrolled in ACH direct deposit and ACH expense debit.",
    directDepositNotEnrolled: "Du hast keine Überweisung eingerichtet.",
    checkingAccount: "Gehaltskonto",
    savingsAccount: "Sparkonto",
    updatePayment: "Zahlungsmethode aktualisieren",
    updateAction: "Aktualisieren",
    updatedNotification: "Zahlungsinformationen aktualisiert",
    updatePaymentSub: "Bankkonto für Überweisungen",
    receiveByMailSub: "Enroll in Checks",
    receiveByMailText:
        "If selected, your checks will be mailed to your address on file.",
    directDepositRemoved: "Überweisung entfernt",

    addBankAccount: "Bankkonto hinzufügen",
    checking: "Gehaltskonto",
    savings: "Sparkonto",
    routingNumber: "Bankleitzahl",
    nineDigitNumber:
        "Die 9 Ziffern lange Nummer in der unteren linken Ecke eines Schecks",
    mustBeginWith: "Die Bankleitzahl muss mit einer 0, 1, 2 oder 3 beginnen",
    mustBe9Digits: "Bitte eine 9 Ziffern lange Bankleitzahl eingeben",
    mustBeValid9Digits: "Please enter a valid 9-digit routing number",
    accountNumber: "Konto-Nr.",
    accountHolderName: "Name des Kontoinhabers",
    enterBankAccount: "Please enter your bank account number",
    mustBe5to17Digits: "Please enter a 5 to 17-digit account number",
    byClickingYouAuthorize:
        "Because you chose to pay for expenses via ACH Debit, this account will also be charged if your expenses exceed your rental revenue for the month. By clicking 'Agree and Save', you authorize {accountingEntity} to make payments electronically via direct deposit and to debit the identified account for up to $10,000 per transaction per day. This authority will remain in effect until you change or update your payment method.",
    cancel: "Abbrechen",
    agreeAndSave: "Akzeptieren und speichern",
    savePaymentMethod: "Zahlungsmethode speichern",
    clickToSaveAria:
        "Klicke diese Schaltfläche, um deine Zahlungsmethode zu speichern.",
    clickToCancelAria: "Klicke diese Schaltfläche, um abzubrechen.",
    clickToRequestChecksAria:
        "Klicke diese Schaltfläche, um Schecks per Post anzufordern.",
    requestChecksByMail: "Stattdessen Schecks per Post anfordern",
    getYourPaymentsFaster:
        "Richte eine Überweisung ein und werde schneller bezahlt",
    errorMessages: {
        updateReserveBalanceError: "Die Höhe der Rückstellung ist zu groß.",
        updateReserveBalanceNegativeError:
            "Die Höhe der Rückstellung kann nicht negativ sein.",
        updateReserveBalanceMissingError:
            "Für die Höhe der Rückstellung ist ein Betrag erforderlich.",
        sendDirectDepositEmailError:
            "Die E-Mail zur Abmeldung von der Direktüberweisung konnte nicht gesendet werden.",
        paymentAccountGeneralError:
            "Bei der Aktualisierung deiner Kontoinformationen ist etwas schiefgegangen. Bitte versuche es später noch einmal.",
        paymentAccountNameError:
            "Der Name des Kontoinhabers muss angegeben werden.",
        paymentAccountNameLengthError:
            "Der Name des Kontoinhabers ist länger als 100 Zeichen.",
        paymentAccountRoutingNumError: "Die Bankleitzahl ist ungültig.",
        paymentAccountNumError: "Die Kontonummer ist ungültig.",
        paymentAccountTypeError: "Die Kontotyp ist ungültig.",
        sendACHUpdateEmailError:
            "Die E-Mail zur ACH-Aktualisierung konnte nicht gesendet werden.",
        somethingWentWrong:
            "Da ist etwas schiefgegangen. Bitte versuche es noch einmal.",
    },
    emptyState:
        "This is where you can update your reserve balance and payment method. Be sure to set up direct deposit before your first statement posts in order to get your payments faster.",
    linkToStatements: "Statements",
}

export default settings
