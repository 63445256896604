export enum SegmentLocation {
    NavBar = "Nav Bar",
    HomeInfo = "Home Info",
    Maintenance = "Maintenance",
    Performance = "Perfomance",
    GuestReviewModal = "Guest Review Modal",
}

export enum OwnerLibraryLocation {
    Default = "Vacasa Owner Library",
    GuestReviews = "Vacasa Guest Reviews",
}
