import { IconCleanliness, IconLargeDiamondBlue } from "assets"
import classNames from "classnames"
import { FC, PropsWithChildren, ReactNode } from "react"
import styles from "./BaseWidget.module.scss"

export const BaseWidget: FC<PropsWithChildren> = ({ children }) => {
    return <div className={styles.container}>{children}</div>
}

export const TitleWidget: FC<PropsWithChildren> = ({ children }) => {
    return <div className={styles.title}>{children}</div>
}

type EmptyStateProps = {
    icon?: "cleanliness" | "diamond"
    message: string
    title: string
    header?: ReactNode
    footer?: ReactNode
}
export const EmptyState: FC<EmptyStateProps> = props => {
    const { footer, header, icon, message, title } = props

    const Icon = () => {
        if (icon === "cleanliness") return <IconCleanliness />
        if (icon === "diamond") return <IconLargeDiamondBlue />
    }

    return (
        <BaseWidget>
            <div
                className={classNames(
                    styles.emptyState,
                    "flex",
                    "justify-center",
                    "flex-col",
                    "items-center"
                )}
            >
                {header}
                {Icon() && <div className={styles.iconBox}>{Icon()}</div>}
                <h3 className={styles.title}>{title}</h3>
                <p className={styles.message}>{message}</p>
                {footer}
            </div>
        </BaseWidget>
    )
}

interface WidgetItemProps {
    gridColumn?: string
    gridRow?: string
}

export const WidgetItem: FC<PropsWithChildren<WidgetItemProps>> = ({
    children,
    gridColumn,
    gridRow,
}) => {
    return (
        <div
            style={{
                gridColumn,
                gridRow,
            }}
            className={styles.container}
        >
            {children}
        </div>
    )
}
